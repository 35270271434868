import { StoreHeader } from '@flipdish/api-client-typescript';
import { getActiveLanguage } from 'react-localize-redux';
import { createSelector } from 'reselect';

const orderReportStores = (state: AppState) => state.reports.orderReports.storeHeaders;

export const getStoreHeadersList = createSelector([orderReportStores], (storeHeaders) => {
  if (storeHeaders) {
    return storeHeaders;
  } else {
    return [] as StoreHeader[];
  }
});

const activeLanguage = (state: AppState) => getActiveLanguage(state.locale);
export const getLocale = createSelector([activeLanguage], (activeLanguage) => {
  if (activeLanguage === 'en') {
    return 'en-GB';
  } else return activeLanguage;
});
