import {
  AppStoreAppSummary,
  RestApiPaginationResultAppStoreAppSummary,
} from '@flipdish/api-client-typescript';

import { appStoreService } from '../components/AppStore/services/appstore.service';
import { appStoreConstants } from '../constants/appstore.constants';
import { action, actionError } from './utils';

const {
  GET_CONFIGURED_APPSTORE_APPS_REQUEST,
  GET_CONFIGURED_APPSTORE_APPS_SUCCESS,
  GET_CONFIGURED_APPSTORE_APPS_FAILURE,
  GET_APPSTORE_APPS_REQUEST,
  GET_APPSTORE_APPS_SUCCESS,
  GET_APPSTORE_APPS_FAILURE,
} = appStoreConstants;

// #region getConfiguredAppStoreAppsForAnApp
export const getConfiguredAppStoreAppsForAppIdRequest = () =>
  action(GET_CONFIGURED_APPSTORE_APPS_REQUEST);

export type GetConfiguredAppStoreAppsSuccess = ReturnType<typeof getConfiguredAppStoreAppsSuccess>;
export const getConfiguredAppStoreAppsSuccess = (configuredAppStoreApps: AppStoreAppSummary[]) =>
  action(GET_CONFIGURED_APPSTORE_APPS_SUCCESS, configuredAppStoreApps);

export const getConfiguredAppStoreAppsFailure = () =>
  actionError(GET_CONFIGURED_APPSTORE_APPS_FAILURE, null, null);

export function getConfiguredAppStoreAppsForApp(appId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    if (getState().appStore.configuredAppStoreAppsLoading) return;

    try {
      dispatch(getConfiguredAppStoreAppsForAppIdRequest());

      const configuredAppStoreApps = await appStoreService.getMyAppStoreApps(appId);
      dispatch(getConfiguredAppStoreAppsSuccess(configuredAppStoreApps.Data));
    } catch (error) {
      dispatch(getConfiguredAppStoreAppsFailure());
    }
  };
}
// #endregion

// #region getAppStoreApps
export const DEFAULT_APP_STORE_APPS_LIMIT = 30;

export const getAppStoreAppsRequest = () => action(GET_APPSTORE_APPS_REQUEST);

export type GetAppStoreAppsSuccess = ReturnType<typeof getAppStoreAppsRequestSuccess>;
export const getAppStoreAppsRequestSuccess = (
  configuredAppStoreAppsResponse: RestApiPaginationResultAppStoreAppSummary
) => action(GET_APPSTORE_APPS_SUCCESS, configuredAppStoreAppsResponse);

export const getAppStoreAppsRequestFailure = () =>
  actionError(GET_APPSTORE_APPS_FAILURE, null, null);

export function getAppStoreApps(
  searchTerm: string,
  page: number,
  limit: number,
  showOnlyVerified: boolean
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    if (getState().appStore.appStoreAppsLoading) return;

    try {
      dispatch(getAppStoreAppsRequest());

      const appStoreApps = await appStoreService.getAppStoreApps(
        searchTerm,
        page,
        limit,
        showOnlyVerified
      );
      dispatch(getAppStoreAppsRequestSuccess(appStoreApps));
    } catch (error) {
      dispatch(getAppStoreAppsRequestFailure());
    }
  };
}
// #endregion
