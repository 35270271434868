import React from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { AuditLog } from './service';

const styles = () =>
  createStyles({
    role: {
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    navLink: {
      textDecoration: 'none',
    },
    customer: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  });

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

type OuterProps = {
  data: AuditLog;
};

type InnerProps = WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

type Props = InnerProps & OuterProps;

const LogUser = ({ data: { User, EventName }, classes, translate }: Props) => {
  return (
    <span>
      {EventName!.includes('customer.consent') ? (
        <span className={classes.customer}>{translate('Customer')}</span>
      ) : User ? (
        User.UserName || User.UserEmail
      ) : (
        'Flipdish'
      )}
      <br />
      <span className={classes.role}>{}</span>
    </span>
  );
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withStyles(styles),
  connect(mapStateToProps)
)(LogUser);

export { EnhancedComponent as LogUser };
