import React from 'react';

import { Product } from '@flipdish/api-client-typescript';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import Select from '@fd/ui/Select/Select';

const useStyles = makeStyles(() => ({
  standardTextField: {
    padding: 0,
  },
}));

export type ProductTypeSelectOption = {
  label: string;
  value: Product.ProductTypeEnum;
};

type ProductTypeSelectProps = {
  autoFocus?: boolean;
  className?: string;
  label: TranslationId;
  onChange: (option: Product.ProductTypeEnum) => void;
  selectedValue: Product.ProductTypeEnum;
  translate: TranslateFunction;
  variant?: 'outlined' | 'standard';
};

const ProductTypeSelect = (props: ProductTypeSelectProps) => {
  const {
    autoFocus = false,
    className,
    translate,
    selectedValue,
    label,
    onChange,
    variant = 'outlined',
  } = props;
  const classes = useStyles();
  const options: ProductTypeSelectOption[] = [
    { value: Product.ProductTypeEnum.Product, label: translate('Product') as string },
    { value: Product.ProductTypeEnum.Modifier, label: translate('Modifier') as string },
  ];
  const selectedOption = options.find(
    (option: ProductTypeSelectOption) => option.value === selectedValue
  );
  const textFieldClasses = variant === 'standard' ? classes.standardTextField : undefined;
  const handleChange = (option: ProductTypeSelectOption) => {
    onChange(option.value);
  };

  return (
    <Select
      autoFocus={autoFocus}
      className={className}
      dataFd="product-type-select"
      options={options}
      textFieldClassName={textFieldClasses}
      TextFieldProps={{
        fdKey: 'product-type-select-dropdown',
        label: translate(label),
        variant: variant,
        style: { zIndex: 0 },
        InputLabelProps: {
          shrink: true,
        },
      }}
      onChange={handleChange}
      value={selectedOption}
    />
  );
};

export { ProductTypeSelect };
