import React, { useEffect, useState } from 'react';

import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';

import PageLayout from '../../../../ui/Layout';
import PaperContainer from '../../../../ui/Layout/PaperContainer';
import {
  createBankAccountAndConnectedAccount,
  getBankAccountDetails,
  getCountriesWithFieldDefinitions,
} from '../banking.actions';
import { getBankAccountListUrl, getLatestBankAccount } from '../banking.selectors';
import AddAccountForm from '../components/AddAccountForm';
import VerifyYourDetailsModal from '../components/VerifyYourDetailsModal';

type OuterProps = {
  hideBackButton?: boolean;
  goToNextStep?: string;
};

export type Props = MappedState & MappedDispatch & OuterProps;
const BankingCreate = (props: Props) => {
  const { bankingRoute, hideBackButton = false, goToNextStep } = props;
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { createConnect, newBankAccount, getCountriesFieldDefinitions } = props;
  const history = useHistory();
  const submitForm = (stripeSupportedCountry: boolean) =>
    stripeSupportedCountry ? setDialogOpen(true) : setSubmitSuccess(true);

  useEffect(() => {
    getCountriesFieldDefinitions();
  }, []);

  if (isSubmitSuccess && bankingRoute) {
    return <Redirect to={bankingRoute} />;
  }
  return (
    <PageLayout
      strictToParent
      toParent={hideBackButton ? '' : './'}
      title={<Translate id="Add_new_bank_account" />}
      documentTitle="Banking"
    >
      <PaperContainer>
        <AddAccountForm submit={createConnect} onSubmitSuccess={submitForm} />
        {newBankAccount && (
          <VerifyYourDetailsModal
            account={newBankAccount}
            isDialogOpen={isDialogOpen}
            setDialogOpen={setDialogOpen}
            onClose={() => {
              goToNextStep && history.push({ search: goToNextStep });
              setSubmitSuccess(true);
            }}
          />
        )}
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const bankingRoute = getBankAccountListUrl(state);
  const newBankAccount = getLatestBankAccount(state);

  return {
    bankingRoute,
    newBankAccount,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    createConnect: (bankAccount) => {
      return dispatch(createBankAccountAndConnectedAccount(bankAccount, true));
    },
    getBankAccountDetails: (accountId) => {
      return dispatch(getBankAccountDetails(accountId));
    },
    getCountriesFieldDefinitions: () => {
      dispatch(getCountriesWithFieldDefinitions());
    },
  };
};

export default compose<Props, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  BankingCreate
);
