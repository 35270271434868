import { StoreHeader } from '@flipdish/api-client-typescript';

import { StoreSearchResult } from '@fd/ui/Filter/StoreByCurrencyFilter';

import { action, actionError } from '../../actions/utils';
import { notify, notifyError } from '../../layouts/Notify/actions';
import {
  downloadCSVService,
  getDeliveryZonesService,
  getHeatmapDatapointsService,
  getLookerDashboardsService,
  getLookerSsoEmbedUrlService,
  getOrderByStoreData,
  getOrderReportDetailService,
  getOrderReportOverviewService,
  getOrdersData,
  getRejectedOrdersByReasonService,
  getRejectedOrdersData,
  getRejectionsByStoreData,
  getStoreSearchService,
  getVouchersService,
} from './OrderReport.services';
import { serializeCategories } from './services.helpers';
import { Bounds, OrderReportDetailFinalData, PeriodPresetEnum, PeriodProps } from './types';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];

//#region orderReportOverview
export const GET_ORDER_REPORT_OVERVIEW = 'GET_ORDER_REPORT_OVERVIEW';
export const GET_ORDER_REPORT_OVERVIEW_REQUEST = 'GET_ORDER_REPORT_OVERVIEW_REQUEST';
export const GET_ORDER_REPORT_OVERVIEW_SUCCESS = 'GET_ORDER_REPORT_OVERVIEW_SUCCESS';
export const GET_ORDER_REPORT_OVERVIEW_FAILURE = 'GET_ORDER_REPORT_OVERVIEW_FAILURE';

export const DEFAULT_ROWS_PER_PAGE = 5;
export const DEFAULT_PAGE = 0;

export const getOrderReportOverviewRequest = () => action(GET_ORDER_REPORT_OVERVIEW_REQUEST);

export type GetOrderReportOverviewSuccess = ReturnType<typeof getOrderReportOverviewSuccess>;
export const getOrderReportOverviewSuccess = (Data) => {
  return action(GET_ORDER_REPORT_OVERVIEW_SUCCESS, Data);
};

export const getOrderReportOverviewFailure = (error: Error) =>
  actionError(GET_ORDER_REPORT_OVERVIEW_FAILURE, undefined, error);

export function getOrderReportOverview(
  MainPeriod: PeriodProps,
  ComparePeriod: {
    value: 0 | 10 | 20;
    url: 'none' | 'previousperiod' | 'lastyear' | 'custom';
    startDate: Date | string;
    endDate: Date | string;
  },
  PeriodType: PeriodPresetEnum,
  StoreId?: number[],
  DeliveryType?: Array<'delivery' | 'collection'>,
  Platform?: AppTypeEnum[],
  VoucherCodes?: string,
  IANATimezone?: string,
  DeliveryZones?: number[]
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getOrderReportOverviewRequest());
      const result = await getOrderReportOverviewService({
        AppId,
        PeriodType,
        MainPeriod,
        ComparePeriod,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        DeliveryZones,
      });
      return result;
    } catch (error) {
      dispatch(getOrderReportOverviewFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
}
//#endregion getOrderReportOverview

//#region orderReportDetail
export const GET_ORDER_REPORT_DETAIL = 'GET_ORDER_REPORT_OVERVIEW';
export const GET_ORDER_REPORT_DETAIL_REQUEST = 'GET_ORDER_REPORT_DETAIL_REQUEST';
export const GET_ORDER_REPORT_DETAIL_SUCCESS = 'GET_ORDER_REPORT_DETAIL_SUCCESS';
export const GET_ORDER_REPORT_DETAIL_FAILURE = 'GET_ORDER_REPORT_DETAIL_FAILURE';

export const getOrderReportDetailRequest = () => action(GET_ORDER_REPORT_DETAIL_REQUEST);

export type GetOrderReportDetailSuccess = ReturnType<typeof getOrderReportDetailSuccess>;
export const getOrderReportDetailSuccess = (Data) => {
  return action(GET_ORDER_REPORT_DETAIL_SUCCESS, Data);
};

export const getOrderReportDetailFailure = (error: Error) =>
  actionError(GET_ORDER_REPORT_DETAIL_FAILURE, undefined, error);

export const getOrderReportDetail = (
  MainPeriod: PeriodProps,
  ComparePeriod: {
    value: 0 | 10 | 20;
    url: 'none' | 'previousperiod' | 'lastyear' | 'custom';
    startDate: Date | string;
    endDate: Date | string;
  },
  PeriodType: PeriodPresetEnum,
  StoreId?: number[],
  DeliveryType?: Array<'delivery' | 'collection'>,
  Platform?: AppTypeEnum[],
  VoucherCodes?: string,
  IANATimezone?: string,
  DeliveryZones?: number[]
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      dispatch(getOrderReportDetailRequest());
      const result = await getOrderReportDetailService({
        AppId,
        PeriodType,
        MainPeriod,
        ComparePeriod,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        DeliveryZones,
      });

      const { Orders_by_platform, Delivery_vs_collection, Cash_vs_paid_online } = result;
      const isCurrentOnly = !ComparePeriod || (ComparePeriod && ComparePeriod.value === 0);
      const finalData: OrderReportDetailFinalData = {
        Orders_by_platform: serializeCategories(Orders_by_platform, isCurrentOnly),
        Delivery_vs_collection: serializeCategories(Delivery_vs_collection, isCurrentOnly),
        Cash_vs_paid_online: serializeCategories(Cash_vs_paid_online, isCurrentOnly),
      };
      return finalData;
    } catch (error) {
      dispatch(getOrderReportDetailFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};
//#endregion getOrderReportDetail

// #region getRejectedOrdersByReason
export const GET_REJECTED_ORDERS_BY_REASON = 'GET_REJECTED_ORDERS_BY_REASON';
export const GET_REJECTED_ORDERS_BY_REASON_REQUEST = 'GET_REJECTED_ORDERS_BY_REASON_REQUEST';
export const GET_REJECTED_ORDERS_BY_REASON_SUCCESS = 'GET_REJECTED_ORDERS_BY_REASON_SUCCESS';
export const GET_REJECTED_ORDERS_BY_REASON_FAILURE = 'GET_REJECTED_ORDERS_BY_REASON_FAILURE';

export const getRejectedOrdersByReasonRequest = () => action(GET_REJECTED_ORDERS_BY_REASON_REQUEST);

export type GetRejectedOrdersByReasonSuccess = ReturnType<typeof getRejectedOrdersByReasonSuccess>;
export const getRejectedOrdersByReasonSuccess = (Data) => {
  return action(GET_REJECTED_ORDERS_BY_REASON_SUCCESS, Data);
};

export const getRejectedOrdersByReasonFailure = (error: Error) =>
  actionError(GET_REJECTED_ORDERS_BY_REASON_FAILURE, undefined, error);

export const getRejectedOrdersByReason = (
  MainPeriod: PeriodProps,
  ComparePeriod: {
    value: 0 | 10 | 20;
    url: 'none' | 'previousperiod' | 'lastyear' | 'custom';
    startDate: Date | string;
    endDate: Date | string;
  },
  PeriodType: PeriodPresetEnum,
  StoreId?: number[],
  DeliveryType?: Array<'delivery' | 'collection'>,
  Platform?: AppTypeEnum[],
  VoucherCodes?: string,
  IANATimezone?: string,
  DeliveryZones?: number[]
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getRejectedOrdersByReasonRequest());
      const result = await getRejectedOrdersByReasonService({
        AppId,
        PeriodType,
        MainPeriod,
        ComparePeriod,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        DeliveryZones,
      });
      dispatch(getRejectedOrdersByReasonSuccess(result));
      const isCurrentOnly = !ComparePeriod || (ComparePeriod && ComparePeriod.value === 0);
      const finalData = serializeCategories(result, isCurrentOnly);
      return finalData;
    } catch (error) {
      dispatch(getRejectedOrdersByReasonFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};
// #endregion

// #region OrderReportTables
// #region Order by stores
export const ORDER_BY_STORES_TABLE_REQUEST = 'ORDER_BY_STORES_TABLE_REQUEST';
export const ORDER_BY_STORES_TABLE_SUCCESS = 'ORDER_BY_STORES_TABLE_SUCCESS';
export const ORDER_BY_STORES_TABLE_FAILURE = 'ORDER_BY_STORES_TABLE_FAILURE';

export const getTableDataOrdersByStoreRequest = () => action(ORDER_BY_STORES_TABLE_REQUEST);

export type GetTableDataOrdersByStoreSuccess = ReturnType<typeof getTableDataOrdersByStoreSuccess>;
export const getTableDataOrdersByStoreSuccess = (data) => {
  return action(ORDER_BY_STORES_TABLE_SUCCESS, {
    result: data.result,
    pagination: data.pagination,
  });
};

export const getTableDataOrdersByStoreFailure = (error: Error) =>
  actionError(ORDER_BY_STORES_TABLE_FAILURE, undefined, error);

export type GetTableDataOrdersByStore = {
  Page?: number;
  PageSize?: number;
  Period: PeriodProps;
  PeriodType: PeriodPresetEnum;
  StoreId?: number[] | string[];
  DeliveryType?: Array<'delivery' | 'collection'>;
  Platform?: AppTypeEnum[];
  VoucherCodes?: string;
  IANATimezone?: string;
  ExportAsCSV?: boolean;
  DeliveryZones?: number[];
  LanguageCode?: string;
};

export function getTableDataOrdersByStore({
  Page = DEFAULT_PAGE,
  PageSize = DEFAULT_ROWS_PER_PAGE,
  Period,
  PeriodType,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: GetTableDataOrdersByStore) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getTableDataOrdersByStoreRequest());
      const result = await getOrderByStoreData({
        AppId,
        PeriodType,
        MainPeriod: Period,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        ExportAsCSV,
        DeliveryZones,
        LanguageCode,
      });
      if (ExportAsCSV) {
        dispatch(notify({ variant: 'success', message: 'Csv_email_success', translate: true }));
        return;
      } else {
        const pagination = {
          page: Page,
          pageSize: PageSize,
          total: result.length,
        };

        dispatch(
          getTableDataOrdersByStoreSuccess({
            result,
            pagination,
          })
        );
      }
    } catch (error) {
      dispatch(getTableDataOrdersByStoreFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
}
// #endregion Order by stores

// #region Orders
export const ORDERS_TABLE_REQUEST = 'ORDERS_TABLE_REQUEST';
export const ORDERS_TABLE_SUCCESS = 'ORDERS_TABLE_SUCCESS';
export const ORDERS_TABLE_FAILURE = 'ORDERS_TABLE_FAILURE';

export const getTableDataOrdersRequest = () => action(ORDERS_TABLE_REQUEST);

export type GetTableDataOrdersSuccess = ReturnType<typeof getTableDataOrdersSuccess>;
export const getTableDataOrdersSuccess = (data) => {
  return action(ORDERS_TABLE_SUCCESS, {
    ordersTableData: data.result,
    ordersTablePagination: data.pagination,
  });
};

export const getTableDataOrdersFailure = (error: Error) =>
  actionError(ORDERS_TABLE_FAILURE, undefined, error);

export type GetTableDataOrders = {
  Page?: number;
  PageSize?: number;
  Period: PeriodProps;
  PeriodType: PeriodPresetEnum;
  StoreId?: number[] | string[];
  DeliveryType?: Array<'delivery' | 'collection'>;
  Platform?: AppTypeEnum[];
  VoucherCodes?: string;
  IANATimezone?: string;
  Columns?: string[];
  SortKey?: string;
  SortDirection?: 'asc' | 'desc';
  ExportAsCSV?: boolean;
  DeliveryZones?: number[];
  LanguageCode?: string;
};
export function getTableDataOrders({
  Page = 1,
  PageSize = 5,
  Period,
  PeriodType,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  Columns,
  SortKey,
  SortDirection,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: GetTableDataOrders) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getTableDataOrdersRequest());
      const result = await getOrdersData({
        AppId,
        Page: Page + 1,
        PageSize,
        Columns,
        PeriodType,
        MainPeriod: Period,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        SortKey,
        SortDirection,
        ExportAsCSV,
        DeliveryZones,
        LanguageCode,
      });
      if (ExportAsCSV) {
        dispatch(notify({ variant: 'success', message: 'Csv_email_success', translate: true }));
        return;
      } else {
        dispatch(getTableDataOrdersSuccess(result));
        return result;
      }
    } catch (error) {
      dispatch(getTableDataOrdersFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
}
// #endregion Orders

// #region Rejected Orders
export const ORDERS_REJECTED_TABLE_REQUEST = 'ORDERS_REJECTED_TABLE_REQUEST';
export const ORDERS_REJECTED_TABLE_SUCCESS = 'ORDERS_REJECTED_TABLE_SUCCESS';
export const ORDERS_REJECTED_TABLE_FAILURE = 'ORDERS_REJECTED_TABLE_FAILURE';

export const getTableDataRejectedOrdersRequest = () => action(ORDERS_REJECTED_TABLE_REQUEST);

export type GetTableDataRejectedOrdersSuccess = ReturnType<
  typeof getTableDataRejectedOrdersSuccess
>;
export const getTableDataRejectedOrdersSuccess = (data) => {
  return action(ORDERS_REJECTED_TABLE_SUCCESS, {
    rejectedOrdersTableData: data.result,
    rejectedOrdersTablePagination: data.pagination,
  });
};

export const getTableDataRejectedOrdersFailure = (error: Error) =>
  actionError(ORDERS_REJECTED_TABLE_FAILURE, undefined, error);

export function getTableDataRejectedOrders({
  Page = 1,
  PageSize = 5,
  Period,
  PeriodType,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  Columns,
  SortKey,
  SortDirection,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: GetTableDataOrders) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getTableDataRejectedOrdersRequest());
      const result = await getRejectedOrdersData({
        AppId,
        Page: Page + 1,
        PageSize,
        Columns,
        PeriodType,
        MainPeriod: Period,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        SortKey,
        SortDirection,
        ExportAsCSV,
        DeliveryZones,
        LanguageCode,
      });
      if (ExportAsCSV) {
        dispatch(notify({ variant: 'success', message: 'Csv_email_success', translate: true }));
        return;
      } else {
        dispatch(getTableDataRejectedOrdersSuccess(result));
        return result;
      }
    } catch (error) {
      dispatch(getTableDataRejectedOrdersFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
}
// #endregion Rejected Orders

// #region Rejected Orders By Store
export const ORDERS_REJECTED_BY_STORE_TABLE_REQUEST = 'ORDERS_REJECTED_BY_STORE_TABLE_REQUEST';
export const ORDERS_REJECTED_BY_STORE_TABLE_SUCCESS = 'ORDERS_REJECTED_BY_STORE_TABLE_SUCCESS';
export const ORDERS_REJECTED_BY_STORE_TABLE_FAILURE = 'ORDERS_REJECTED_BY_STORE_TABLE_FAILURE';

export const getTableDataRejectedOrdersByStoreRequest = () =>
  action(ORDERS_REJECTED_BY_STORE_TABLE_REQUEST);

export type GetTableDataRejectedOrdersByStoreSuccess = ReturnType<
  typeof getTableDataRejectedOrdersByStoreSuccess
>;
export const getTableDataRejectedOrdersByStoreSuccess = (data) => {
  return action(ORDERS_REJECTED_BY_STORE_TABLE_SUCCESS, {
    result: data.result,
    pagination: data.pagination,
  });
};

export const getTableDataRejectedOrdersByStoreFailure = (error: Error) =>
  actionError(ORDERS_REJECTED_BY_STORE_TABLE_FAILURE, undefined, error);

export function getTableDataRejectedOrdersByStore(
  Page: number = DEFAULT_PAGE,
  PageSize: number = DEFAULT_ROWS_PER_PAGE,
  Period: PeriodProps,
  PeriodType: PeriodPresetEnum,
  StoreId?: number[] | string[],
  DeliveryType?: Array<'delivery' | 'collection'>,
  Platform?: AppTypeEnum[],
  VoucherCodes?: string,
  IANATimezone?: string,
  DeliveryZones?: number[]
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getTableDataRejectedOrdersByStoreRequest());
      const result = await getRejectionsByStoreData({
        AppId,
        PeriodType,
        MainPeriod: Period,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        DeliveryZones,
      });

      const pagination = {
        page: Page,
        pageSize: PageSize,
        total: result.length,
      };

      dispatch(
        getTableDataRejectedOrdersByStoreSuccess({
          result,
          pagination,
        })
      );
      return result;
    } catch (error) {
      dispatch(getTableDataRejectedOrdersByStoreFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
}
// #endregion Rejected Orders By Store
// #endregion OrderReportTables

export const getHeatmapDatapoints = (
  MainPeriod: PeriodProps,
  ComparePeriod: {
    value: 0 | 10 | 20;
    url: 'none' | 'previousperiod' | 'lastyear' | 'custom';
    startDate: Date | string;
    endDate: Date | string;
  },
  PeriodType: PeriodPresetEnum,
  MapBounds: Bounds,
  Zoom: number,
  StoreId?: number[] | string[],
  DeliveryType?: Array<'delivery' | 'collection'>,
  Platform?: AppTypeEnum[],
  VoucherCodes?: string,
  IANATimezone?: string
  // Columns?,
  // SortKey?: string,
  // SortDirection?: 'asc' | 'desc',
  // ExportAsCSV?: boolean
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const result = await getHeatmapDatapointsService({
        AppId,
        PeriodType,
        MainPeriod,
        ComparePeriod,
        MapBounds,
        Zoom,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
      });
      const finalData = serializeCoordinates(result);

      return finalData;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};

export const getVouchers = (
  MainPeriod: { startDate: Date | string; endDate: Date | string },
  PeriodType: PeriodPresetEnum,
  StoreIds: number[],
  Search: string,
  MaxResults = 5
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const result = await getVouchersService(
        AppId,
        PeriodType,
        MainPeriod,
        StoreIds,
        Search,
        MaxResults
      );
      return result;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};

const serializeCoordinates = (datapoints) => {
  return datapoints.map((datapoint) => ({
    lat: datapoint.lat,
    lng: datapoint.lon,
    weight: datapoint.weight,
  }));
};

export const GET_STORE_HEADERS = 'GET_STORE_HEADERS';

export type GetStoreHeaders = ReturnType<typeof getStoreHeaders>;
export const getStoreHeaders = (storeHeaders: StoreHeader[]) =>
  action(GET_STORE_HEADERS, storeHeaders);

export const GET_STORE_SEARCH_SUCCESS = 'GET_STORE_SEARCH_SUCCESS';
export const GET_STORE_SEARCH_FAILURE = 'GET_STORE_SEARCH_FAILURE';
export const GET_STORE_SEARCH_REQUEST = 'GET_STORE_SEARCH_REQUEST';
export const RESET_STORE_SEARCH = 'RESET_STORE_SEARCH';

export type GetStoreSearchRequest = ReturnType<typeof getStoreSearchRequest>;
export const getStoreSearchRequest = () => action(GET_STORE_SEARCH_REQUEST);

export type GetStoreSearchSuccess = ReturnType<typeof getStoreSearchSuccess>;
export const getStoreSearchSuccess = () => action(GET_STORE_SEARCH_SUCCESS);

export type GetStoreSearchFailure = ReturnType<typeof getStoreSearchFailure>;
export const getStoreSearchFailure = () => action(GET_STORE_SEARCH_FAILURE);

export const resetStoreSearch = () => action(RESET_STORE_SEARCH);

export type GetStoreSearch = {
  search?: string;
  isReporting: boolean;
  currency?: CurrencyEnum;
  maxPerStoreGroup?: number;
};
export const getStoreSearch = ({
  search,
  isReporting,
  currency,
  maxPerStoreGroup = 10,
}: GetStoreSearch) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      !search && dispatch(getStoreSearchRequest());
      const result = await getStoreSearchService({ AppId, search, currency, maxPerStoreGroup });
      const storeSearchResult: StoreSearchResult[] = result;

      // used to mapStoreId to Name in order reporting tables
      if (isReporting) {
        const storeHeaders: StoreHeader[] = storeSearchResult[0].storeGroups[0].stores.map((s) => {
          return { StoreId: s.id, Name: s.name };
        });
        dispatch(getStoreHeaders(storeHeaders));
      }
      dispatch(getStoreSearchSuccess());
      return storeSearchResult;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      dispatch(getStoreSearchFailure());
      return;
    }
  };
};

export const downloadReportingCSV = (Guid: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      const result = await downloadCSVService(AppId, Guid);
      dispatch(notify({ message: 'CSV_report_downloaded', variant: 'success', translate: true }));
      return result;
    } catch (error) {
      return dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};

export const getDeliveryZones = (StoreId?: number[] | string[]) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const result = await getDeliveryZonesService(AppId, StoreId);
      return result;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};

export const getLookerSsoEmbedUrl = (dashboard: string, filters: { [key: string]: any } = {}) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const result = await getLookerSsoEmbedUrlService(AppId, dashboard, filters);
      return result;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};

export const getLookerDashboards = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const result = await getLookerDashboardsService(AppId);
      return result;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      return;
    }
  };
};
