export const subscriptionConstants = {
  CREATE_SUBSCRIPTION_BEGIN: 'CREATE_SUBSCRIPTION_BEGIN',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAILURE: 'CREATE_SUBSCRIPTION_FAILURE',

  FETCH_SUBSCRIPTION_BEGIN: 'FETCH_SUBSCRIPTION_BEGIN',
  FETCH_SUBSCRIPTION_SUCCESS_FOUND: 'FETCH_SUBSCRIPTION_SUCCESS_FOUND',
  FETCH_SUBSCRIPTION_SUCCESS_NOT_FOUND: 'FETCH_SUBSCRIPTION_SUCCESS_NOT_FOUND',
  FETCH_SUBSCRIPTION_FAILURE: 'FETCH_SUBSCRIPTION_FAILURE',

  FETCH_PLANS_BEGIN: 'FETCH_PLANS_BEGIN',
  FETCH_PLANS_SUCCESS: 'FETCH_PLANS_SUCCESS',
  FETCH_PLANS_FAILURE: 'FETCH_PLANS_FAILURE',

  CARD_REPLACED: 'CARD_REPLACED'

  // SUBSCRIPTION_CANCELED: 'SUBSCRIPTION_CANCELED',
  // SUBSCRIPTION_UPDATED: 'SUBSCRIPTION_UPDATED'
};
