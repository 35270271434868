import React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

const useChartStyles = makeStyles(({ breakpoints }: Theme) => ({
  '@keyframes skeleton': {
    '0%': {
      backgroundPosition: '-200px 0',
    },
    '100%': {
      backgroundPosition: 'calc(200px + 100%) 0',
    },
  },
  root: {
    flexGrow: 1,
    padding: 16,
  },
  wrapper: {
    margin: 8,
    height: 200,
    position: 'relative',
    [breakpoints.down('md')]: {
      height: 'auto',
      paddingTop: '32%',
    },
  },
  grid: {
    width: 300,
    padding: 8,
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  skeleton: {
    backgroundColor: '#eee',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'linear-gradient( 90deg, #eee, #f5f5f5, #eee )',
    animation: '1.2s ease-in-out 0s infinite normal none running $skeleton',
  },
  message: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '22px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

const headerSkeleton = <Skeleton width={260} height={30} />;
const dataSkeletton = <Skeleton width={240} height={50} />;
const infoSkeleton = <Skeleton width={280} height={20} />;

const ReportOverviewSkeleton = ({ loaded }: { loaded: boolean }) => {
  const classes = useChartStyles();

  if (loaded) {
    return (
      <Card className={classes.root}>
        <div className={`${classes.wrapper} ${loaded ? '' : classes.skeleton}`}>
          <div className={classes.message}>
            <Translate id="Report_no_data_available" />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {[0, 1, 2, 3, 4, 5].map((value) => (
            <Grid key={value} item className={classes.grid}>
              <div>{headerSkeleton}</div>
              <div>{dataSkeletton}</div>
              <div>{infoSkeleton}</div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportOverviewSkeleton;
