import React, { useEffect, useState } from 'react';

import {
  AppStoreApp,
  RestApiErrorResult,
  ValidationErrorResult,
} from '@flipdish/api-client-typescript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { History } from 'history';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  closeNotifySaving,
  notifyError,
  notifyInfo,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import PageLayout from '../../../../../ui/Layout';
import { AppStoreAppDetailsLoadingSkeleton } from '../../../components/AppStoreAppDetailsLoadingSkeleton';
import { appStoreService } from '../../../services/appstore.service';
import AppStoreAppForm from '../../components/AppStoreAppForm/AppStoreAppForm';

type Props = RouteComponentProps<{ appStoreAppIdRouteParam: string }>;

type AppStoreAppConfigProps = {
  history: History;
};

const ManageAppStoreApp = ({
  appId,
  appStoreAppIdRouteParam,
  translate,
  closeNotifySaving,
  notifySaved,
  notifySaving,
  notifyError,
  dispatchNotifyInfo,
  history,
}: MappedDispatch & MappedState & AppStoreAppConfigProps) => {
  const [appStoreApp, setAppStoreApp] = useState<AppStoreApp | null>(null);
  const [formErrors, setFormErrors] = useState<ValidationErrorResult[] | undefined>(undefined);

  const queryClient = useQueryClient();

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['getAppStoreAppById', appStoreAppIdRouteParam],
    queryFn: () => appStoreService.getAppStoreAppById(appStoreAppIdRouteParam),
    enabled: !!appStoreAppIdRouteParam,
  });

  useEffect(() => {
    if (data && !appStoreApp) {
      setAppStoreApp(data);
    }
  }, [data, appStoreApp]);

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      const apiErrorMessage =
        (error.response?.data as RestApiErrorResult)?.Message || 'Something went wrong';
      notifyError({ message: apiErrorMessage });
    }
  }, [isError, error, notifyError]);

  const deleteApp = useMutation({
    mutationFn: async (appIds: { Id: string; OAuthAppId: string }) => {
      notifySaving();
      return await appStoreService.deleteAppStoreApp(appIds.Id, appIds.OAuthAppId);
    },
    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      history.replace(`/${appId}/appstore/developers`);
    },
    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        setFormErrors(apiErrorMessage.Errors);
        notifyError({ message: apiErrorMessage.Message });
      }
    },
  });

  const saveApp = useMutation({
    mutationFn: async ({
      updatedAppStoreApp,
      logo,
    }: {
      updatedAppStoreApp: AppStoreApp;
      logo: any;
    }) => {
      notifySaving();
      let savedAppStoreApp: AppStoreApp;
      if (appStoreApp) {
        await appStoreService.updateAppStoreApp(
          updatedAppStoreApp.Id,
          updatedAppStoreApp,
          updatedAppStoreApp.OAuthAppId
        );
        if (appStoreApp.VerificationStatus !== updatedAppStoreApp.VerificationStatus) {
          await appStoreService.updateAppStoreAppVerification(
            updatedAppStoreApp.Id,
            updatedAppStoreApp.VerificationStatus,
            updatedAppStoreApp.OAuthAppId
          );
        }
        savedAppStoreApp = updatedAppStoreApp;
      } else {
        // Defaulting to teammate until the OAuth flow is supported
        updatedAppStoreApp.PermissionsType = AppStoreApp.PermissionsTypeEnum.Teammate;
        const response = await appStoreService.createAppStoreApp(
          updatedAppStoreApp,
          updatedAppStoreApp.OAuthAppId
        );
        savedAppStoreApp = response.Data;
      }

      if (logo) {
        await appStoreService.addAppStoreAppLogo(
          savedAppStoreApp.Id,
          logo,
          updatedAppStoreApp.OAuthAppId
        );
      }

      queryClient.invalidateQueries({ queryKey: [appStoreService.getAppStoreAppByIdKey] });
      const response = await appStoreService.getAppStoreAppById(savedAppStoreApp.Id);
      savedAppStoreApp = response;

      return savedAppStoreApp;
    },
    onSuccess: (savedAppStoreApp) => {
      closeNotifySaving();
      notifySaved();
      queryClient.invalidateQueries({ queryKey: [appStoreService.getAppStoreAppByIdKey] });
      if (!appStoreAppIdRouteParam) {
        history.replace(`/${appId}/appstore/developers/${savedAppStoreApp.Id}`);
      }

      setAppStoreApp(savedAppStoreApp);
    },
    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        setFormErrors(apiErrorMessage.Errors);
        notifyError({ message: apiErrorMessage.Message });
      }
    },
  });

  const {
    data: signingKey,
    isLoading: signingKeyLoading,
    refetch: getSigningKey,
    isError: signingKeyError,
  } = useQuery({
    queryKey: ['getSigningKey', appId, appStoreApp?.Id],
    queryFn: () =>
      appStoreService.getAppStoreAppExternalFunctionSigningKey(
        appStoreApp?.Id || '',
        appStoreApp?.OAuthAppId || ''
      ),
    enabled: false,
  });

  useEffect(() => {
    if (signingKeyError) {
      notifyError({ message: 'Something_went_wrong' });
    }
  }, [signingKeyError]);

  useEffect(() => {
    if (signingKey && !signingKey?.Data) {
      dispatchNotifyInfo({ message: 'No_secret_key_available_for_this_app', translate: true });
    }
  }, [signingKey]);

  const title = () => {
    if (appStoreApp) {
      return appStoreApp.Name;
    }

    if (!appStoreAppIdRouteParam) {
      return translate('Create_app');
    }

    return '';
  };

  const renderContent = () => {
    if (appStoreApp) {
      return (
        <AppStoreAppForm
          appStoreApp={appStoreApp}
          translate={translate}
          onSaveChanges={saveApp.mutate}
          appStoreFormErrors={formErrors}
          deleteAppStoreApp={deleteApp.mutate}
          signingKey={signingKey}
          getSigningKey={getSigningKey}
          signingKeyLoading={signingKeyLoading}
        />
      );
    }

    if (appStoreAppIdRouteParam) {
      if (isPending) {
        return <AppStoreAppDetailsLoadingSkeleton />;
      }
    } else {
      return (
        <AppStoreAppForm
          translate={translate}
          onSaveChanges={saveApp.mutate}
          appStoreFormErrors={formErrors}
        />
      );
    }
  };

  return (
    <PageLayout
      toParent={`/${appId}/appstore/developers`}
      documentTitle="App_Store"
      title={title()}
    >
      {renderContent()}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
    // @ts-ignore
    appStoreAppIdRouteParam: props.match.params.appStoreAppId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifyInfo: (data: NotifyProps) => dispatch(notifyInfo(data)),
  notifySaving: () => dispatch(notifySaving()),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAppStoreApp);
