import React, { Fragment, useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { formatCurrency, formatNumber } from 'fd-react-intl';
import isEmpty from 'lodash/isEmpty';
import { useInView } from 'react-intersection-observer';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { DataProps } from '../types';
import LineGraph from './Graphs/LineChart';
import ReportChartSkeleton from './Graphs/ReportChartSkeleton';
import ReportOverviewSkeleton from './Graphs/ReportOverviewSkeleton';
import GridCell from './GridCell';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    [theme.breakpoints.down('md')]: {
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)',
    },
  },
  lineChart: {
    marginBottom: 30,
  },
}));

type Props = {
  loading?: boolean;
  currency?: string;
  languageCode: string;
  currentPeriod: DataProps[];
  previousPeriod: DataProps[];
};

const OrderOverview = (props: Props) => {
  const { loading, currency, languageCode, currentPeriod, previousPeriod } = props;
  const classes = useStyles();
  const [ref, inView] = useInView({ triggerOnce: true });
  const [selectedCell, setSelectedCell] = useState<number | null>(0);

  const handleClick = useCallback((index: number) => {
    if (selectedCell !== index) {
      setSelectedCell(index);
    } else {
      setSelectedCell(null);
    }
  }, []);

  const formatNumberOrCurrency = useMemo(
    () =>
      (value, style = 'currency', minimumFractionDigits = 2, maximumFractionDigits = 2) => {
        const amount = value ? value : 0;
        if (currency && style === 'currency') {
          return formatCurrency(amount, languageCode, {
            currency: currency,
            minimumFractionDigits,
            maximumFractionDigits,
          });
        }
        if (currency) {
          return formatNumber(amount, languageCode, {
            style,
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
          });
        } else {
          return formatNumber(amount, languageCode, {
            minimumFractionDigits,
            maximumFractionDigits,
          });
        }
      },
    [currency]
  );

  const renderSkeleton = (noCard, title?) => {
    return <ReportChartSkeleton loaded={loading === false} noCard={noCard} title={title} />;
  };

  const renderLineChart = () => {
    if (inView && selectedCell !== null) {
      const currentPeriodHasData = currentPeriod[selectedCell].Data.find((cp) => cp.value !== 0);
      const previousPeriodHasData =
        previousPeriod.length && previousPeriod[selectedCell].Data.find((pp) => pp.value !== 0);
      if (!currentPeriodHasData && !previousPeriodHasData) {
        return renderSkeleton(true, currentPeriod[selectedCell].SectionTitle);
      } else {
        return (
          <Grid item xs={12}>
            <div className={classes.lineChart}>
              <LineGraph
                data={{
                  CurrentPeriod: currentPeriod[selectedCell],
                  PreviousPeriod: previousPeriod.length ? previousPeriod[selectedCell] : [],
                }}
                formatCurrency={formatNumberOrCurrency}
                title={currentPeriod[selectedCell].SectionTitle}
              />
            </div>
          </Grid>
        );
      }
    }
    return null;
  };

  if (loading !== false || isEmpty(currentPeriod) || !currency) {
    return <ReportOverviewSkeleton loaded={loading === false} />;
  }

  return (
    <ErrorBoundary identifier="order-overview">
      <div ref={ref}>
        <Grid container>
          {currentPeriod.map((current, index) => {
            const previous = previousPeriod.find((sp) => sp.SectionTitle === current.SectionTitle);
            return (
              <Fragment key={index}>
                <Grid item xs={12} md={4} className={classes.row}>
                  <GridCell
                    section={current.SectionTitle}
                    type={current.Type}
                    currentTotal={current.Count}
                    previousData={previous ? previous.Data : []}
                    previousTotal={previous ? previous.Count : 0}
                    handleClick={() => handleClick(index)}
                    selectedCell={selectedCell === index}
                    languageCode={languageCode}
                    currency={currency}
                  />
                  {selectedCell === index && <Hidden mdUp>{renderLineChart()}</Hidden>}
                </Grid>
              </Fragment>
            );
          })}
          <Hidden only={['xs', 'sm']}>{renderLineChart()}</Hidden>
        </Grid>
      </div>
    </ErrorBoundary>
  );
};

export default OrderOverview;
