import { DeliveryZone, Store } from '@flipdish/api-client-typescript';
import { fk, Model, ModelWithFields, SessionWithModels } from 'redux-orm';

import * as deliveryZoneActions from '../actions/deliveryzones.actions';
import { deliveryZonesConstants as actionTypes } from '../constants/deliveryzones.constants';
import { storeEventConstants } from '../signalr/hub.events';
import {
  StoreDeliveryZoneCreated,
  StoreDeliveryZoneDeleted,
  StoreDeliveryZoneUpdated,
} from '../signalr/hub.handlers';
import { IOrmState } from './orm';

interface IActionHandler {
  [index: string]: (a: AnyAction, s: SessionWithModels<IOrmState>) => void;
}

const handlers: IActionHandler = {
  [actionTypes.DELIVERY_ZONE_LOAD_ALL_SUCCESS]: handleLoadAllSuccess,

  [actionTypes.DELIVERY_ZONE_CREATE_REQUEST]: handleCreateRequest,
  [actionTypes.DELIVERY_ZONE_CREATE_SUCCESS]: handleCreateSuccess,
  [actionTypes.DELIVERY_ZONE_CREATE_FAILURE]: handleCreateFailure,

  [actionTypes.DELIVERY_ZONE_UPDATE_REQUEST]: handleUpdateRequest,
  [actionTypes.DELIVERY_ZONE_UPDATE_SUCCESS]: handleUpdateSuccess,
  [actionTypes.DELIVERY_ZONE_UPDATE_FAILURE]: handleUpdateFailure,

  [actionTypes.DELIVERY_ZONE_REMOVE_SUCCESS]: handleRemoveSuccess,
  [actionTypes.DELIVERY_ZONE_REMOVE_FAILURE]: handleRemoveFailure,

  [storeEventConstants.DELIVERY_ZONE_CREATED]: handleCreateEvent,
  [storeEventConstants.DELIVERY_ZONE_UPDATED]: handleUpdateEvent,
  [storeEventConstants.DELIVERY_ZONE_DELETED]: handleDeleteEvent,
};

export default class StoreDeliveryZone extends Model<any> {
  public static get modelName() {
    return 'StoreDeliveryZone';
  }

  static get fields() {
    return {
      FlipdishStore: fk('FlipdishStore', 'DeliveryZones'),
    };
  }

  public static options() {
    return { idAttribute: 'Id' };
  }

  public static reducer = (
    action: AnyAction,
    StoreDeliveryZone: ModelWithFields<any>,
    session: SessionWithModels<IOrmState>
  ) => {
    const { type } = action;

    const handler = handlers[type];
    if (handler) {
      handler(action, session);
    }

    return undefined;
  };

  public isEqual(other: DeliveryZone): boolean {
    return (
      other.Id === this.ref.Id &&
      other.DeliveryFee === this.ref.DeliveryFee &&
      other.MinimumDeliveryOrderAmount === this.ref.MinimumDeliveryOrderAmount &&
      other.IsEnabled === this.ref.IsEnabled &&
      other.WellKnownText === this.ref.WellKnownText
    );
  }
}

// #region loadAll
function handleLoadAllSuccess(
  action: ReturnType<typeof deliveryZoneActions.loadAllSuccess>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { storeId, zones },
  } = action;
  const store = session.FlipdishStore.withId(storeId as any);

  zones.forEach((zone, idx) => {
    const existingZone = session.StoreDeliveryZone.withId(zone.Id as any) as Model<any>;
    if (existingZone) {
      existingZone.update({
        ...zone,
      });
    } else {
      createDeliveryZone(session.StoreDeliveryZone, zone, store);
    }
  });
}
// #endregion

// #region create
function handleCreateRequest(
  action: ReturnType<typeof deliveryZoneActions.createRequest>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { storeId, zone, tmpId },
  } = action;
  const store = session.FlipdishStore.withId(storeId as any);
  createDeliveryZone(session.StoreDeliveryZone, { ...zone, Id: tmpId }, store);
}
function handleCreateSuccess(
  action: ReturnType<typeof deliveryZoneActions.createSuccess>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { storeId, zone, tmpId },
  } = action;
  if (zone) {
    const store = session.FlipdishStore.withId(storeId as any);
    const tmpZone = session.StoreDeliveryZone.withId(tmpId as any);
    if (tmpZone) {
      tmpZone.delete();
    }
    createDeliveryZone(session.StoreDeliveryZone, zone, store);
  } else return;
}
function handleCreateFailure(
  action: ReturnType<typeof deliveryZoneActions.createFailure>,
  session: SessionWithModels<IOrmState>
) {
  const {
    meta: { tmpId },
  } = action;
  session.StoreDeliveryZone.withId(tmpId as any).delete();
}
// #endregion

// #region update
let updateActiveRequests = 0;
function handleUpdateRequest(
  action: ReturnType<typeof deliveryZoneActions.updateRequest>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { zone, deliveryZoneId },
  } = action;
  updateActiveRequests++;
  updateDeliveryZone(session.StoreDeliveryZone, {
    ...zone,
    Id: deliveryZoneId,
  });
}
function handleUpdateSuccess(
  action: ReturnType<typeof deliveryZoneActions.updateSuccess>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { zone },
  } = action;
  // take last
  if (updateActiveRequests === 1) {
    updateDeliveryZone(session.StoreDeliveryZone, zone);
  }
  updateActiveRequests--;
}
function handleUpdateFailure(
  action: ReturnType<typeof deliveryZoneActions.updateFailure>,
  session: SessionWithModels<IOrmState>
) {
  const {
    meta: { undo },
  } = action;
  updateDeliveryZone(session.StoreDeliveryZone, undo);
  updateActiveRequests--;
}
// #endregion

// #region remove
function handleRemoveSuccess(
  action: ReturnType<typeof deliveryZoneActions.removeSuccess>,
  session: SessionWithModels<IOrmState>
) {
  const {
    payload: { deliveryZoneId },
  } = action;
  const sessionZone = session.StoreDeliveryZone.withId(deliveryZoneId as any);
  sessionZone && sessionZone.delete();
}
function handleRemoveFailure(
  action: ReturnType<typeof deliveryZoneActions.removeFailure>,
  session: SessionWithModels<IOrmState>
) {
  const {
    meta: { undo },
  } = action;
  updateDeliveryZone(session.StoreDeliveryZone, undo);
}
// #endregion

// #region signalr
function handleCreateEvent(
  action: StoreDeliveryZoneCreated,
  session: SessionWithModels<IOrmState>
) {
  const { DeliveryZone, EventName, StoreId } = action.payload;
  if (!DeliveryZone) {
    return console.error(`[EVENT]:${EventName} > no data provided!`);
  }

  const store = session.FlipdishStore.withId(StoreId as any);
  createDeliveryZone(session.StoreDeliveryZone, DeliveryZone, store);
}
function handleUpdateEvent(
  action: StoreDeliveryZoneUpdated,
  session: SessionWithModels<IOrmState>
) {
  const { DeliveryZone, EventName } = action.payload;
  if (!DeliveryZone) {
    return console.error(`[EVENT]:${EventName} > no data provided!`);
  }
  updateDeliveryZone(session.StoreDeliveryZone, DeliveryZone);
}
function handleDeleteEvent(
  action: StoreDeliveryZoneDeleted,
  session: SessionWithModels<IOrmState>
) {
  const { DeliveryZone, EventName } = action.payload;
  if (!DeliveryZone) {
    return console.error(`[EVENT]:${EventName} > no data provided!`);
  }
  const zone = session.StoreDeliveryZone.withId(DeliveryZone.Id as any);
  if (zone) {
    zone.delete();
  }
}
// #endregion

// #region helpers

function createDeliveryZone(deliveryZoneOrmService, zone: DeliveryZone, store: Store) {
  if (!zone) {
    return console.error(new Error(`Null reference 'zone'`));
  }

  deliveryZoneOrmService.create({
    ...zone,
    MinimumDeliveryOrderAmount: Number(zone.MinimumDeliveryOrderAmount),
    DeliveryFee: Number(zone.DeliveryFee),
    FlipdishStore: store,
  });
}

function updateDeliveryZone(deliveryZoneOrmService, zone: DeliveryZone) {
  if (!zone) {
    return console.error(new Error(`Null reference 'zone'`));
  }
  const deliveryZone = deliveryZoneOrmService.withId(zone.Id);
  if (deliveryZone && !deliveryZone.isEqual(zone)) {
    deliveryZone.update({
      ...zone,
      MinimumDeliveryOrderAmount: Number(zone.MinimumDeliveryOrderAmount),
      DeliveryFee: Number(zone.DeliveryFee),
    });
  }
}

// #endregion helpers
