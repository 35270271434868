import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PercentageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18.92958,3.37318 L20.62662,5.07022 L5.07042,20.62642 L3.37338,18.92938 L18.92958,3.37318 Z M7.5,6 C6.7,6 6,6.7 6,7.5 C6,8.3 6.7,9 7.5,9 C8.3,9 9,8.3 9,7.5 C9,6.7 8.3,6 7.5,6 Z M7.5,4 C9.4,4 11,5.6 11,7.5 C11,9.4 9.4,11 7.5,11 C5.6,11 4,9.4 4,7.5 C4,5.6 5.6,4 7.5,4 Z M16.5,15 C15.7,15 15,15.7 15,16.5 C15,17.3 15.7,18 16.5,18 C17.3,18 18,17.3 18,16.5 C18,15.7 17.3,15 16.5,15 Z M16.5,13 C18.4,13 20,14.6 20,16.5 C20,18.4 18.4,20 16.5,20 C14.6,20 13,18.4 13,16.5 C13,14.6 14.6,13 16.5,13 Z" />
  </SvgIcon>
);

export default PercentageIcon;
