import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { components } from 'react-select';

import Loading from '../../../ui/Loading';

export const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

export const Control = (props) => {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        classes: {
          root: props.selectProps.classes.cssOutlinedInput,
          focused: props.selectProps.classes.cssFocused,
          notchedOutline: props.selectProps.classes.notchedOutline,
        },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon
          style={{
            color: 'rgba(0,0,0,0.54)',
            cursor: 'pointer',
          }}
        />
      </components.DropdownIndicator>
    )
  );
};

export const Menu = (props) => {
  return (
    <Paper square className={props.selectProps.classes.menuPaper} {...props.innerProps}>
      <MenuList>{props.children}</MenuList>
    </Paper>
  );
};

export const ValueContainer = (props) => {
  return (
    <div className={props.selectProps.classes.valueContainer} data-fd="live-orders-store-select">
      {props.children}
    </div>
  );
};
export const MultiValue = (props) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <CancelIcon {...props.removeProps} className={props.selectProps.classes.closeIcon} />
      }
    />
  );
};

export const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-fd={`orders-store-${props.data.value}`}
    >
      {props.children}
    </MenuItem>
  );
};

export const LoadingIndicator = (props) => {
  return <Loading size={20} />;
};

export const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};
