import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import DevicesIcon from '@mui/icons-material/PhonelinkSetup';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useLocation } from 'react-router';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import { KioskIcon } from '../../Kiosks/Components/KioskNestedMenuIcon';
import { RMSSubscriptions } from '../../RMS/utils/rmsSubscriptions';

const StyledPaperContainer = styled(PaperContainer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));

const FlipdishDevicesTab = (props: MappedState) => {
  const { currentApp, translate } = props;
  const { data } = useQueryRMSCommonDataHook(currentApp?.AppId || '', true);
  const { pathname } = useLocation();

  const isOnKiosksPath = pathname?.includes('kiosk');

  let salesChannel = 'kiosk';
  if (!isOnKiosksPath) {
    salesChannel = 'mobile-apps';
  }
  return (
    <StyledPaperContainer fluid>
      <List aria-label={translate('Devices') as string}>
        {isOnKiosksPath && (
          <Permissions permissions={['ViewHydraConfig']}>
            <ListItemLinkButton
              title="Kiosks"
              subtitle="View_the_list_of_Kiosk_devices_and_license_entitle"
              link={`/${currentApp.AppId}/sales-channels/kiosks`}
              iconComponent={<KioskIcon />}
            />
          </Permissions>
        )}
        {(data?.subscriptions || []).includes(RMSSubscriptions.BASE) && (
          <Permissions permissions={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <ListItemLinkButton
              title="KDS_POS_and_Others"
              subtitle="View_and_setup_Kitchen_Display_Screen_POS_and_othe"
              link={`/${currentApp.AppId}/sales-channels/${salesChannel}/devices`}
              iconComponent={<TvOutlinedIcon />}
            />
          </Permissions>
        )}
        <Permissions permissions={['ViewHydraConfig']}>
          <ListItemLinkButton
            title="Terminals"
            subtitle="View_and_setup_your_kitchen_terminals"
            link={`/${currentApp.AppId}/terminals`}
            iconComponent={<DevicesIcon />}
            isLast={true}
          />
        </Permissions>
      </List>
    </StyledPaperContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    currentApp,
  };
};

export default connect(mapStateToProps)(FlipdishDevicesTab);
