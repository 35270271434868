import React, { useEffect } from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation } from 'react-router';
import { compose } from 'recompose';

import { appsActions } from '../../../actions/apps.actions';

type Props = MappedState & MappedDispatch & RouteComponentProps;
const OrdersRedirect: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { currentApp, getApps, setSelectedApp } = props;

  const location = useLocation();

  useEffect(() => {
    getApps();
  }, []);

  useEffect(() => {
    if (currentApp) {
      setSelectedApp(currentApp);
    }
  }, [currentApp]);

  if (currentApp) {
    return <Redirect to={`/${currentApp.AppId}/orders${location.search}`} />;
  }

  return null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  let currentApp = state.currentApp.AppId ? state.currentApp : undefined;
  if (!currentApp && state.apps.appsList && state.apps.appsList.length) {
    currentApp = state.apps.appsList[0];
  }

  return {
    currentApp,
  };
};
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getApps: () => {
    dispatch(appsActions.getApps());
  },
  setSelectedApp: (app: App) => {
    dispatch(appsActions.selectedAppChanged(app));
  },
});

export default compose<Props, RouteComponentProps>(connect(mapStateToProps, mapDispatchToProps))(
  OrdersRedirect
);
