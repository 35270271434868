import React from 'react';

import { Order } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

const styles = (theme: Theme) =>
  createStyles({
    itemsGrid: {
      paddingRight: '24px',
      [theme.breakpoints.down('md')]: {
        margin: '24px 16px 32px 16px',
        paddingRight: 0,
      },
    },
    paper: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      boxShadow: 'none',
    },
    headline5: {
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '0px',
      fontSize: '18px',
      fontWeight: 500,
    },
    section: {
      paddingTop: theme.spacing(2),
    },
    caption: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    dividerLine: {
      listStyleType: 'none',
      margin: theme.spacing(1, 0),
    },
    listItem: {
      padding: '2px 0',
      listStyleType: 'none',
    },
    listItemText: {
      width: '100%',
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    listItemExtrasText: {
      width: '100%',
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: theme.spacing(4),
    },
    price: {
      textAlign: 'right',
      padding: '0',
      color: 'rgba(0, 0, 0, 0.6)',
      minWidth: '18%',
    },
    total: {
      textAlign: 'right',
      fontSize: '18px',
      color: 'rgba(0, 0, 0, 0.56)',
      paddingBottom: '50px',
      paddingRight: '0px',
      marginTop: '16px',
    },
    totalAmount: {
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '0',
    },
  });

const GroupedItems = ({ items, classes, languageCode, currency }) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <List key={index}>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={item.Name}
                classes={{
                  primary: classes.listItemText,
                }}
              />
              {item.Price != 0 && (
                <ListItemText
                  primary={item.Price.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                  primaryTypographyProps={
                    {
                      'data-fd': 'order-details-item-amount',
                    } as any
                  }
                />
              )}
            </ListItem>

            {item.OrderItemOptions.map((extras: any, index: number) => (
              <ListItem className={classes.listItem} key={index}>
                <ListItemText
                  primary={`+ ${extras.Name}`}
                  classes={{
                    primary: classes.listItemExtrasText,
                  }}
                />
                <ListItemText
                  primary={extras.Price.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            ))}
          </List>
        );
      })}
    </>
  );
};

const OrderSection = ({ sectionItems, classes, languageCode, currency }) => {
  return (
    <div className={classes.section}>
      <Typography variant="caption" className={classes.caption}>
        {sectionItems[0][0].MenuSectionName}
        <Divider component="li" className={classes.dividerLine} />
      </Typography>
      {sectionItems.map((groupedItems, index) => (
        <GroupedItems
          key={index}
          items={groupedItems}
          classes={classes}
          languageCode={languageCode}
          currency={currency}
        />
      ))}
    </div>
  );
};

type Props = { orderItems: []; languageCode: string; currency: string } & WithStyles<typeof styles>;

const Items: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { orderItems, classes, languageCode, currency } = props;

  return (
    <Paper square className={classes.paper}>
      <Typography variant="h5" className={classes.headline5}>
        <Translate>{(translate: any) => translate('Order items')}</Translate>
      </Typography>
      {orderItems &&
        orderItems.map((section: [[Order['OrderItems']]], index: number) => (
          <OrderSection
            key={index}
            sectionItems={section}
            classes={classes}
            languageCode={languageCode}
            currency={currency}
          />
        ))}
    </Paper>
  );
};

export default withStyles(styles)(Items);
