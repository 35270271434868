import React from 'react';

import MuiFormHelperText, { type FormHelperTextProps } from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: 16,
  },
  content: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    margin: 0,
    lineHeight: '16px',
    textAlign: 'right',
  },
}));

type Props = FormHelperTextProps;
export const FormHelperText = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiFormHelperText {...props} className={classes.content}>
        {props.children}
      </MuiFormHelperText>
    </div>
  );
};
