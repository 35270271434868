import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';

import usePrevious from '@fd/customHooks/usePrevious';
import { Org } from '@flipdish/orgmanagement';
import SearchIcon from '@mui/icons-material/Search';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';

import generateSrcSet from '@fd/ui/utils/generateSrcSet';

import { appsActions } from '../../../actions/apps.actions';
import { ReactComponent as ForkIcon } from '../../../assets/images/app_logo_fork.svg';
import { notifyError } from '../../../layouts/Notify/actions';
import { getBrandsForOrg, getBrandsForOrgKey, getOrgByBrandId } from '../organisation.services';
import OrgSelector from '../OrgSelector/OrgSelector';
import { setCurrentOrg } from '../rms.actions';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  whiteSpace: 'nowrap',
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenuContainer = styled('div')({
  maxWidth: 400,
  minWidth: 304,
});

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})<{ isCurrent?: boolean }>(({ isCurrent }) => ({
  backgroundColor: isCurrent ? '#eaf2ff' : 'transparent',
  '&:hover': {
    backgroundColor: '#eaf2ff',
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledLoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  marginBottom: theme.spacing(1),
}));

const StyledImg = styled('img')({
  width: 40,
  height: 40,
});

const StyledImgContainer = styled('div')({
  width: 40,
  height: 40,
});

const StyledMessageContainer = styled('div')({
  padding: 16,
});

const SearchContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#fff',
}));

const BrandSelector: React.FC<MapDispatchToPropsType & MapStateToPropsType> = ({
  currentApp,
  dispatchSetCurrentApp,
  dispatchSetCurrentOrg,
  dispatchNotifyError,
  currentOrg,
  translate,
}: MapStateToPropsType & MapDispatchToPropsType) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const previousCurrentOrg = usePrevious(currentOrg);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const params = useParams<{ appId: string }>();

  const { data: orgData, isPending: loadingOrg } = useQuery({
    queryKey: [getOrgByBrandId, params?.appId],
    queryFn: () => getOrgByBrandId(params?.appId || ''),
    retry: 1,
  });

  const orgId = orgData?.data?.data?.[0]?.orgId;

  const {
    data: brandsResponse,
    isLoading: loadingBrands,
    error: brandsForOrgError,
  } = useQuery({
    queryKey: [getBrandsForOrgKey, currentOrg?.orgId],
    queryFn: () => getBrandsForOrg(currentOrg?.orgId),
    enabled: !!currentOrg?.orgId,
    retry: 1,
  });

  useEffect(() => {
    if (brandsForOrgError?.message) {
      dispatchNotifyError(brandsForOrgError.message);
    }
  }, [brandsForOrgError]);

  useEffect(() => {
    if (location?.search.includes('orgSearch')) {
      const params = new URLSearchParams(location.search);
      const orgSearch = params.get('orgSearch');
      orgSearch && setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    // when we update the url directly, we need to update the current app
    if (params?.appId && params?.appId !== currentApp?.AppId) {
      dispatchSetCurrentApp(params?.appId);
    }
  }, [currentApp?.AppId, params?.appId]);

  useEffect(() => {
    // if we change org then the brands response will update, otherwise the appId param is already in the brands list and we shouldn't update the url
    const firstBrandIdFromOrgBrands = brandsResponse?.data?.data?.[0]?.brandId;
    const currentBrandFromOrgBrands = brandsResponse?.data?.data?.some(
      (brand) => brand?.brandId === params?.appId
    );
    if (!loadingBrands && firstBrandIdFromOrgBrands && !currentBrandFromOrgBrands) {
      history.push(`/${firstBrandIdFromOrgBrands}/home`);
    }
  }, [brandsResponse, params?.appId, loadingBrands]);

  useEffect(() => {
    // set the current org on first load only
    if (orgData?.data && !previousCurrentOrg) {
      const org = orgData?.data?.data?.[0];
      org && dispatchSetCurrentOrg(org);
    }
  }, [orgData, dispatchSetCurrentOrg, currentOrg]);

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const debouncedSetSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value.toLowerCase()), 300),
    []
  );
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const brands = brandsResponse?.data?.data || [];

  const filteredBrands = brands.filter(
    (brand) =>
      brand?.name?.toLowerCase()?.includes(searchTerm) ||
      brand?.brandId?.toLowerCase()?.includes(searchTerm)
  );

  const handleChange = (event: MouseEvent<HTMLElement>, brand: any) => {
    const brandId = brand?.brandId;
    if (brandId !== 'switch-org' && brandId !== 'account-details' && brandId !== 'sign-out') {
      const pathArray = history.location.pathname.split('/');
      if (brandId) {
        pathArray[1] = brandId;
        // if pathArray like thins, pathArray = (4) ["", "mexicancafe", "orders", "31368"]
        // we need make sure to remove everything after the module name(eg. "orders", "menus" )
        if (!pathArray.includes('website') && pathArray.length > 2) {
          pathArray.splice(3);
        }
        history.push(pathArray.join('/'));
      }
      handleMenuClose();
    }
  };

  const toggleOrgDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }
    setDrawerOpen(open);
    handleMenuClose();
  };

  const renderBrand = ({ index, style }) => {
    const brand = filteredBrands?.[index];
    return (
      <StyledMenuItem
        isCurrent={brand?.brandId === currentApp?.AppId}
        key={brand?.brandId}
        onClick={(event) => handleChange(event, brand)}
        data-fd={`brand-selector-${brand?.brandId}`}
        style={style}
      >
        <ListItemIcon>
          {brand?.logoImageUrl ? (
            <StyledImg src={brand?.logoImageUrl} alt={brand?.name} />
          ) : (
            <ForkIcon />
          )}
        </ListItemIcon>
        <StyledListItemText
          primary={<Tooltip title={brand?.name}>{<Typography>{brand.name}</Typography>}</Tooltip>}
          secondary={brand?.brandId}
        />
      </StyledMenuItem>
    );
  };

  const filteredBrandsLength = filteredBrands?.length;
  // Calculate the height of the List dynamically
  const itemSize = 60;
  const maxVisibleItems = isMobile ? 6 : 10;
  const listHeight =
    filteredBrandsLength < maxVisibleItems
      ? filteredBrandsLength * itemSize
      : maxVisibleItems * itemSize;

  const renderBrandsOrMessage = () => {
    if (!filteredBrandsLength) {
      return (
        <StyledMessageContainer>
          <Typography variant="body1">
            {translate('No_brands_found', { orgId: currentOrg?.orgId || orgId })}
          </Typography>
        </StyledMessageContainer>
      );
    }

    return (
      <List height={listHeight} itemCount={filteredBrands?.length} itemSize={itemSize} width="100%">
        {renderBrand}
      </List>
    );
  };

  return (
    <>
      <IconButton
        onClick={handleButtonClick}
        color="inherit"
        aria-label="Open App menu"
        data-fd="brand-selector"
        style={{ overflow: 'hidden' }}
      >
        <StyledImgContainer>
          {currentApp?.LogoImageUrl ? (
            <StyledImg
              srcSet={generateSrcSet(currentApp?.LogoImageUrl, { width: 40, png: true })}
              alt={currentApp?.Name}
            />
          ) : (
            <ForkIcon />
          )}
        </StyledImgContainer>
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
      >
        <StyledMenuContainer>
          {brands?.length > maxVisibleItems && (
            <>
              <SearchContainer>
                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  placeholder={translate('Search_brands') as string}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  inputProps={{
                    'data-fd': 'brand-selector-search-input',
                  }}
                />
              </SearchContainer>
              <Divider />
            </>
          )}
          {loadingBrands || loadingOrg ? (
            <StyledLoadingContainer>
              <CircularProgress />
            </StyledLoadingContainer>
          ) : (
            renderBrandsOrMessage()
          )}
          <StyledDivider />
          <StyledMenuItem
            key="switch-org"
            onClick={toggleOrgDrawer(true)}
            data-fd="brand-selector-switch-org"
          >
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <StyledListItemText primary={translate('Switch_organisation')} />
          </StyledMenuItem>
        </StyledMenuContainer>
      </Menu>

      <ClickAwayListener
        onClickAway={() => drawerOpen && setDrawerOpen(false)}
        touchEvent="onTouchStart"
        mouseEvent="onMouseDown"
      >
        <OrgSelector toggleOrgDrawer={() => setDrawerOpen(false)} open={drawerOpen} />
      </ClickAwayListener>
    </>
  );
};

type MapDispatchToPropsType = ReturnType<typeof MapDispatchToProps>;
const MapDispatchToProps = (dispatch: any) => ({
  dispatchSetCurrentApp: (appId: string | undefined) => dispatch(appsActions.setCurrentApp(appId)),
  dispatchSetCurrentOrg: (org: Org) => dispatch(setCurrentOrg(org)),
  dispatchNotifyError: (message) => dispatch(notifyError({ message, translate: false })),
});

type MapStateToPropsType = ReturnType<typeof MapStateToProps>;
const MapStateToProps = (state) => ({
  currentApp: state.currentApp,
  currentOrg: state.rms.currentOrg,
  translate: getTranslate(state.locale),
});

export default connect(MapStateToProps, MapDispatchToProps)(BrandSelector);
