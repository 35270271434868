import React from 'react';

import { Channel, OrderSummary } from '@flipdish/api-client-typescript';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getIanaTimeZoneId } from '../../../helpers/timeZones';
import DateTimeZone from '../../../ui/DateTime/DateTime';
import { Loader } from '../../common/Loader';
import Icon from '../components/Icon';
import { renderPaymentStatus } from '../helpers';

const styles = () => {
  return createStyles({
    root: {
      minWidth: 400,
      height: '100vh',
      overflow: 'scroll',
      padding: '5, 0, 51, 0',
    },
    textStyleRefunded: {
      color: 'rgba(0, 0, 0, 0.56)',
      textTransform: 'lowercase',
    },
    textStylePaid: {
      color: '#25c974',
      textTransform: 'lowercase',
    },
    textStyleUnPaid: {
      color: '#ff395f',
      textTransform: 'lowercase',
    },
    price: {
      maxWidth: '30%',
      flexGrow: 0,
      wordBreak: 'break-all',
      textAlign: 'right',
    },
  });
};

type Props = {
  orderList: OrderSummary[];
  handleSelectedOrderChange: (orderSummary: OrderSummary) => void;
  setPage: (page: number) => void;
  loading: boolean;
  page: number;
} & MappedState &
  WithStyles<typeof styles>;

const OrderLiveViewList: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    orderList,
    selectedOrder,
    handleSelectedOrderChange,
    languageCode,
    setPage,
    page,
    loading,
    classes,
    translate,
  } = props;
  const handleScroll = (e) => {
    const { scrollHeight, clientHeight, scrollTop } = e.target;

    // scroll triggers when the compoment goes from has scroll bar to no scroll bar,
    // if there's no scrollbar, we should not fetch more apps.
    // However this hasScrollBar has its problem, see comment from this https://stackoverflow.com/a/5038256
    // it works for us now because we do not hide the overflow
    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (!loading && hasScrollBar && Math.ceil(scrollTop) >= scrollTopMax) {
      setPage(page + 1);
    }
  };

  return (
    <>
      {!loading && (
        <List className={classes.root} onScroll={handleScroll}>
          {orderList.map((order) => {
            const dataFdPrefix = `order_${order.OrderId}_`;

            return (
              <ListItem
                button
                onClick={() => handleSelectedOrderChange(order)}
                selected={selectedOrder ? order.OrderId === selectedOrder.OrderId : false}
                alignItems="flex-start"
                key={order.OrderId}
              >
                <ListItemAvatar>
                  <Icon
                    size={'small'}
                    deliveryType={order.DeliveryType}
                    OrderState={order.OrderState}
                    channelOrder={order?.Channel?.Source === Channel.SourceEnum.External}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`#${order.OrderId}`}
                  secondary={
                    <DateTimeZone
                      dataFd={`requested_for_time_${order.OrderId}`}
                      value={order.RequestedForTime! as unknown as Date}
                      ianaTimeZone={
                        order.StoreIanaTimeZone && getIanaTimeZoneId(order.StoreIanaTimeZone)
                      }
                    >
                      {({ dtUtils }) => {
                        const timeDate = order.RequestedForTime
                          ? dtUtils.format(order.RequestedForTime, 'p P')
                          : '';

                        return (
                          <>
                            <span>
                              {timeDate}
                              <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                                {' '}
                                {translate('Requested for')})
                              </span>
                              <br />
                            </span>
                            <span>{order.StoreName}</span>
                            <br />
                            {(order.CustomerPhoneNumber || order.CustomerName) && (
                              <>
                                <span>
                                  {order.CustomerPhoneNumber} {order.CustomerName}
                                </span>
                                <br />
                              </>
                            )}

                            <span>
                              {translate(
                                `${order.Channel?.TranslationKey}_${order.Channel?.Source}` as TranslationId
                              )}
                              {order.Channel?.Source === Channel.SourceEnum.External &&
                                order.ChannelOrderDisplayId &&
                                ` #${order.ChannelOrderDisplayId}`}
                            </span>
                          </>
                        );
                      }}
                    </DateTimeZone>
                  }
                  data-fd="list-item"
                />
                <ListItemText
                  className={classes.price}
                  primary={
                    order.Amount &&
                    order.Amount.toLocaleString(languageCode, {
                      style: 'currency',
                      currency: order.Currency!.toString(),
                    })
                  }
                  secondary={renderPaymentStatus(order, classes, `${dataFdPrefix}payment_status`)}
                />
              </ListItem>
            );
          })}
        </List>
      )}
      {loading && <Loader />}
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    selectedOrder: state.orders.order,
    languageCode: getActiveLanguage(state.locale),
    translate: getTranslate(state),
  };
};

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'LiveViewList',
  }),
  connect(mapStateToProps)
)(OrderLiveViewList);

export default EnhancedComponent;
