import React, { useState } from 'react';

import { Order } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import AcceptDialogMobile from '../components/AcceptDialogMobile';
import RejectButton from './RejectOrderButton';

const styles = () => {
  return createStyles({
    acceptButton: {
      width: '156px',
      height: 40,
      fontSize: '16px',
      letterSpacing: '1.4px',
      backgroundColor: '#36d88e',
      color: '#fff',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#25c079',
      },
    },
  });
};

type Props = {
  deliveryType: Order.DeliveryTypeEnum;
  acceptMinsCollection: Array<{ default: boolean; mins: number }>;
  acceptMinsDelivery: Array<{ default: boolean; mins: number }>;
  acceptOrder: (mins: number) => void;
  rejectOrder: (rejectReason: string) => void;
};

const OrderActionButtonsSM: React.FC<React.PropsWithChildren<Props & WithStyles<any, any>>> = (
  props
) => {
  const {
    deliveryType,
    acceptMinsCollection,
    acceptMinsDelivery,
    acceptOrder,
    rejectOrder,
    classes,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <AcceptDialogMobile
        open={isDialogOpen}
        mins={
          deliveryType === Order.DeliveryTypeEnum.Pickup ? acceptMinsCollection : acceptMinsDelivery
        }
        handleAccept={(mins: number) => acceptOrder(mins)}
        handleClose={() => setIsDialogOpen(false)}
      />

      <div>
        <RejectButton handleRejectOrder={(rejectReason: string) => rejectOrder(rejectReason)} />
      </div>
      <div>
        <Button
          variant="contained"
          className={classes.acceptButton}
          onClick={() => setIsDialogOpen(true)}
        >
          <Translate id="Accept" />
          <KeyboardArrowRight />
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(OrderActionButtonsSM);
