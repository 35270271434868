import React, { useMemo, useState } from 'react';

import { BusinessHoursOverride } from '@flipdish/api-client-typescript';
import AppBar from '@mui/material/AppBar';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { useCurrentTime } from '../../../custom-hooks/useDateTime';
import { storeOpeningHourOverrideSelectors, storeOpeningHourSelectors } from '../../../selectors';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import { Button } from '../../../ui/Button';
import { RouteParams } from '../types';
import RemoveDialog from './RemoveDialog';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      [breakpoints.up('sm')]: {},
    },
    bar: {
      backgroundColor: '#ff395f',
      padding: '8px 16px',
    },
    text: {
      color: '#fff',
    },
    actions: {
      textAlign: 'right',
    },
    reopen: {
      color: '#fff',
    },
  });

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (_, ownProps: Props) => {
  return (state: AppState) => {
    const store = getSelectedStore(state);
    const storeName = store?.Name;
    const storeIanaTZ = store?.IanaTimeZone;

    const storeId = store?.StoreId || ownProps.match.params.storeId;
    const overrides = storeOpeningHourOverrideSelectors.overrides(state, {
      storeId,
    });

    const findNextDeliveryOpeningHour = storeOpeningHourSelectors.deliveryNextOpeningHourCalculator(
      state,
      { storeId }
    );
    const findNextPickupOpeningHour = storeOpeningHourSelectors.pickupNextOpeningHourCalculator(
      state,
      { storeId }
    );

    return {
      storeId,
      storeName,
      storeIanaTZ,
      overrides,
      findNextDeliveryOpeningHour,
      findNextPickupOpeningHour,
      dtUtils: dateTimeUtils(state),
    };
  };
};

type Props = WithStyles<typeof styles> & RouteParams;
const ReopenStoreHeader = compose<Props & MappedState, {}>(
  setDisplayName('ReopenStoreHeader'),
  withStyles(styles, {
    name: 'ReopenStoreHeader',
    withTheme: true,
  }),
  withRouter,
  connect(mapStateToPropsFactory, null)
)((props) => {
  const {
    classes,
    storeId,
    storeName,
    storeIanaTZ,
    overrides,
    dtUtils,

    findNextDeliveryOpeningHour,
    findNextPickupOpeningHour,
  } = props;

  const [showDialog, setShowDialog] = useState(false);

  const currentTime = useCurrentTime();

  const nowClosingTimes = useMemo(() => {
    if (!storeIanaTZ || !overrides) {
      return {
        allOpen: [] as typeof openOverrides,
        allClose: [] as typeof closeOverrides,
        delivery: {
          open: [] as typeof deliveryOpenOverrides,
          close: [] as typeof deliveryCloseOverrides,
        },
        pickup: {
          open: [] as typeof pickupOpenOverrides,
          close: [] as typeof pickupCloseOverrides,
        },
      };
    }

    // TODO: how did this ever work before these changes?
    const openOverrides = overrides.Open.filter((o) =>
      dtUtils.isWithinInterval(currentTime, { start: new Date(o.start), end: new Date(o.end) })
    );
    const closeOverrides = overrides.Close.filter((o) => {
      return dtUtils.isWithinInterval(currentTime, {
        start: new Date(o.start),
        end: new Date(o.end),
      });
    });

    const deliveryOpenOverrides = openOverrides.filter((o) => o.type === 'Delivery');
    const deliveryCloseOverrides = closeOverrides
      .filter((o) => o.type === 'Delivery')
      // open override takes presence over closed
      .filter(
        (co) =>
          !deliveryOpenOverrides.some((oo) =>
            dtUtils.areIntervalsOverlapping(
              { start: new Date(oo.start), end: new Date(oo.end) },
              { start: new Date(co.start), end: new Date(co.end) }
            )
          )
      )
      .sort((a, b) => dtUtils.compareAsc(new Date(a.start), new Date(b.start)));

    let deliveryClosedUntil: string | undefined;
    let deliveryClosedPreOrder: boolean | undefined;
    if (deliveryCloseOverrides.length) {
      const override = deliveryCloseOverrides[0];
      const nextOpeningHour = findNextDeliveryOpeningHour(new Date(override.end));
      deliveryClosedPreOrder = override.closedAllowPreOrders;

      if (nextOpeningHour) {
        const start = nextOpeningHour.earlyStart || nextOpeningHour.start!;
        const startLocal = dtUtils.utcToZonedTime(start, storeIanaTZ);
        deliveryClosedUntil = dtUtils.format(
          startLocal,
          dtUtils.isSameDay(start, currentTime)
            ? dtUtils.LOCAL_TIME_FORMAT
            : dtUtils.LOCAL_DATE_TIME_FORMAT
        );
      }
    }

    const pickupOpenOverrides = openOverrides.filter((o) => o.type === 'Pickup');
    const pickupCloseOverrides = closeOverrides
      .filter((o) => o.type === 'Pickup')
      // open override takes presence over closed
      .filter(
        (co) =>
          !pickupOpenOverrides.some((oo) =>
            dtUtils.areIntervalsOverlapping(
              { start: new Date(oo.start), end: new Date(oo.end) },
              { start: new Date(co.start), end: new Date(co.end) }
            )
          )
      )
      .sort((a, b) => dtUtils.compareAsc(new Date(a.start), new Date(b.start)));

    let pickupClosedUntil: string | undefined;
    let pickupClosedPreOrder: boolean | undefined;
    if (pickupCloseOverrides.length) {
      const override = pickupCloseOverrides[0];
      const nextOpeningHour = findNextPickupOpeningHour(new Date(override.end));
      pickupClosedPreOrder = override.closedAllowPreOrders;

      if (nextOpeningHour) {
        const start = nextOpeningHour.earlyStart || nextOpeningHour.start!;
        const startLocal = dtUtils.utcToZonedTime(start, storeIanaTZ);
        pickupClosedUntil = dtUtils.format(
          startLocal,
          dtUtils.isSameDay(start, currentTime)
            ? dtUtils.LOCAL_TIME_FORMAT
            : dtUtils.LOCAL_DATE_TIME_FORMAT
        );
      }
    }

    return {
      allOpen: openOverrides,
      allClose: closeOverrides,
      delivery: {
        open: deliveryOpenOverrides,
        close: deliveryCloseOverrides,
        closeUntil: deliveryClosedUntil,
        preOrder: deliveryClosedPreOrder,
      },
      pickup: {
        open: pickupOpenOverrides,
        close: pickupCloseOverrides,
        closeUntil: pickupClosedUntil,
        preOrder: pickupClosedPreOrder,
      },
    };
  }, [currentTime, overrides, storeIanaTZ, findNextPickupOpeningHour, findNextDeliveryOpeningHour]);

  const nowClosingOverridesObsolete = useMemo(() => {
    return nowClosingTimes.allClose.map((o) => {
      return {
        BusinessHoursOverrideId: o.id,
        DeliveryType: o.type as unknown as BusinessHoursOverride.DeliveryTypeEnum,
        StartTime: o.start,
        EndTime: o.end,
        Type: BusinessHoursOverride.TypeEnum.Closed,
      } as BusinessHoursOverride;
    });
  }, [nowClosingTimes.allClose, dtUtils, storeIanaTZ]);

  const show = !!nowClosingTimes.delivery.closeUntil || !!nowClosingTimes.pickup.closeUntil;

  return (
    <div className={classes.root}>
      <Collapse in={show} mountOnEnter unmountOnExit>
        <AppBar enableColorOnDark position="relative" className={classes.bar}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm>
              <Typography variant="body2" className={classes.text}>
                <Translate>
                  {(translate) => {
                    if (
                      !!nowClosingTimes.delivery.closeUntil &&
                      !!nowClosingTimes.pickup.closeUntil &&
                      nowClosingTimes.delivery.closeUntil !== nowClosingTimes.pickup.closeUntil
                    ) {
                      return translate('Store_is_closed_for_collection_until_and_delivery_until', {
                        storeName: storeName || '',
                        timeToDelivery: nowClosingTimes.delivery.closeUntil,
                        timeToPickup: nowClosingTimes.pickup.closeUntil,
                      });
                    }

                    const type = (
                      translate(
                        nowClosingTimes.delivery.closeUntil && nowClosingTimes.pickup.closeUntil
                          ? 'Collection_and_delivery'
                          : nowClosingTimes.delivery.closeUntil
                            ? 'Delivery'
                            : 'Pickup'
                      ) as string
                    ).toLowerCase();

                    return translate('Store_is_closed_for_delivery_and_collection_until', {
                      storeName: storeName || '',
                      type,
                      timeTo:
                        nowClosingTimes.delivery.closeUntil || nowClosingTimes.pickup.closeUntil!,
                    });
                  }}
                </Translate>
                <Translate>
                  {(translate) => {
                    if (nowClosingTimes.delivery.preOrder && !nowClosingTimes.pickup.preOrder) {
                      return ` ${translate('Pre_order_delivery_enabled')}`;
                    }
                    if (nowClosingTimes.pickup.preOrder && !nowClosingTimes.delivery.preOrder) {
                      return ` ${translate('Pre_order_pickup_enabled')}`;
                    }
                    if (nowClosingTimes.delivery.preOrder && nowClosingTimes.pickup.preOrder) {
                      return ` ${translate('Pre_order_pickup_delivery_enabled')}`;
                    }
                    return;
                  }}
                </Translate>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.actions}>
              <Button
                fdKey="Reopen"
                variant="text"
                className={classes.reopen}
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                <Translate id="Reopen" />
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      </Collapse>

      <RemoveDialog
        open={showDialog}
        storeId={Number(storeId)}
        businessHoursOverride={nowClosingOverridesObsolete}
        onSave={() => {
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
      />
    </div>
  );
});

export default ReopenStoreHeader;
