import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { Box } from '../../../ui/atoms/Box';
import { Typography } from '../../../ui/atoms/Typography';

const useStyles = makeStyles(() => ({
  boxContents: {
    marginTop: '24px',
    height: '236px',
  },
  emptyMessageTitle: {
    fontWeight: 'bold',
  },
}));

export type OrderFulfillmentStatusListNoResultsProps = {
  translate: TranslateFunction;
};

export const OrderFulfillmentStatusListNoResults = ({
  translate,
}: OrderFulfillmentStatusListNoResultsProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.boxContents}>
      <Typography className={classes.emptyMessageTitle}>
        {translate('No_Matching_Apps_Title')}
      </Typography>
      <Typography variant="caption">{translate('No_Matching_Apps_Message')}</Typography>
    </Box>
  );
};
