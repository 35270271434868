import React, { useEffect, useState } from 'react';

import { MenuSummary } from '@flipdish/api-client-typescript';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button, Typography } from '@fd/ui/atoms';
import Checkbox from '@fd/ui/Checkbox';
import { Dialog } from '@fd/ui/molecules/Dialog';

import { menusActions } from '../../../../actions/menus.actions';
import { getAssignedStoresText } from '../../../Menus/utils';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
  listContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    minHeight: '365px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  noMenusContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '365px',
  },
}));

export type PendingChangesDialogProps = {
  open: boolean;
  menusWithChanges: number[];
  onCancel: () => void;
  onPublish: (menuIds: number[]) => void;
  translate: TranslateFunction;
};

const PendingChangesDialog = (
  props: PendingChangesDialogProps & MappedState & { dispatch: ThunkDispatch }
): JSX.Element => {
  const { appId, dispatch, menus, menusAppId, open, onCancel, onPublish, translate } = props;
  const [selectedMenus, setSelectedMenus] = useState<number[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (appId != menusAppId) {
      dispatch(menusActions.setMenusAppId(appId || 'global'));
      dispatch(menusActions.getMenus(appId || 'global'));
    }
  }, [appId]);

  const handleCheckboxClick = (isChecked: boolean, menuId?: number) => {
    if (menuId) {
      if (isChecked) {
        setSelectedMenus([...selectedMenus, menuId]);
      } else {
        const newSelectedMenus = selectedMenus.filter((selectedId) => selectedId !== menuId);
        setSelectedMenus(newSelectedMenus);
      }
    }
  };

  const renderMenuList = () => {
    if (menus.length === 0) {
      return (
        <div className={classes.noMenusContainer}>
          <Typography variant="caption">
            <Translate id="No_menus_with_pending_changes_found" />
          </Typography>
        </div>
      );
    }
    return (
      <List className={classes.listContainer}>
        {menus.map((menu: MenuSummary, index: number) => {
          const { MenuId, Name, StoreNames } = menu;
          const labelId = `checkbox-list-secondary-label-${MenuId}`;
          return (
            <ListItem
              className={classes.listItem}
              divider={index < menus.length - 1}
              key={`menu-${MenuId}`}
            >
              <ListItemText
                id={labelId}
                primary={Name || `${translate('Unnamed')} ${MenuId}`}
                secondary={getAssignedStoresText(StoreNames, translate)}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  dataFd={`checkbox-${MenuId}`}
                  color="primary"
                  checked={MenuId && (selectedMenus.includes(MenuId) as any)}
                  edge="end"
                  inputProps={{
                    'aria-label': `${Name} ${translate('checkbox')}`,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCheckboxClick(e.target.checked, MenuId)
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Dialog
      subtitle={translate('Choose_1_some_or_all_menus_to_publish_to') as string}
      title={translate('Choose_menus') as string}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel"
          fdKey="cancel-update"
          onClick={onCancel}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          disabled={selectedMenus.length === 0}
          key="update"
          fdKey="update"
          onClick={() => onPublish(selectedMenus)}
        >
          <Translate id="Update" />
        </Button>,
      ]}
    >
      {renderMenuList()}
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState, props) {
  const { currentApp, menus } = state;
  const { menusWithChanges } = props;
  return {
    appId: currentApp.AppId,
    menus: menus.menus.filter((menu) => menusWithChanges.includes(menu.MenuId)),
    menusAppId: menus.menusAppId,
  };
}

const EnhancedComponent = connect(mapStateToProps)(PendingChangesDialog);
export { EnhancedComponent as PendingChangesDialog };
