import React, { ComponentType, ReactElement } from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldConfig, type FieldProps, Field } from 'formik';

const useStyles = makeStyles(({ palette: { text }, spacing, breakpoints }: Theme) => ({
  row: {
    marginBottom: spacing(3),
    [breakpoints.only('sm')]: {
      width: '50%',
    },
  },
  description: {
    color: text.secondary,
  },
}));

type Props = {
  primary?: string;
  secondary?: string;
  children: (props: RenderProps) => ComponentType<React.PropsWithChildren<{}>> | ReactElement;
} & FieldConfig;

export type RenderProps = FieldProps & {
  error?: string;
  helperText?: string;
};

const FormItem = ({ primary, secondary, children, ...fieldProps }: Props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.description} ${classes.row}`}>
      <Typography component="h3" variant="caption">
        {primary}
      </Typography>
      <Field {...fieldProps}>
        {(fPRops: FieldProps) => {
          const {
            form: { errors },
            field: { name },
          } = fPRops;
          const error = errors[name] as string | undefined;
          const helperText = error || secondary;

          return (
            <FormControl fullWidth error={!!error}>
              <>{children({ ...fPRops, error, helperText: secondary })}</>
              {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

export default FormItem;
