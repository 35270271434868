import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getDate } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import SummaryOverviewLoader from '@fd/ui/Summary/SummaryOverviewLoader';

import EmptyTable from '../../../../../ui/GenericTable/EmptyTable';
import { PayoutDetailSummary } from '../../types';

type Props = {
  loading: boolean | undefined;
  payoutDetailSummary: PayoutDetailSummary | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    textTransform: 'capitalize',
  },
  title: {
    marginTop: theme.spacing(4),
    textTransform: 'capitalize',
  },
  filter: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
  },
}));

const PayoutDetailsEmptyState = (props: Props) => {
  const { loading, payoutDetailSummary } = props;
  const classes = useStyles();

  if (loading || !payoutDetailSummary || !payoutDetailSummary.CreatedDate) {
    return (
      <>
        {/* Filter */}
        <Box className={classes.filter}>
          <Skeleton width={'50%'} height={40} />
        </Box>
        {/* Summary Title & Summary Component */}
        <Box className={classes.title}>
          <Skeleton width={'20%'} height={24} />
        </Box>
        <Paper className={classes.container}>
          <SummaryOverviewLoader />
          <SummaryOverviewLoader />
          <SummaryOverviewLoader />
        </Paper>
        {/* Payouts by Store Title & Table */}
        <Box className={classes.title}>
          <Skeleton width={'20%'} height={24} />
        </Box>
        <Paper className={classes.container}>
          <Skeleton height={250} />
        </Paper>
        {/* Orders Title, Orders Summary Section & Orders Table */}
        <Skeleton width={'20%'} height={24} />
        <Paper className={classes.container}>
          <Skeleton height={190} />
        </Paper>
        <Paper className={classes.container}>
          <Skeleton height={250} />
        </Paper>
      </>
    );
  }

  return (
    <EmptyTable
      ariaTitle={'Payout_Details'}
      emptyTableTitle={'Payout_details_unavailable'}
      subtitle={'Payout_details_are_only_visible_for_payouts_from_X_January_2021_onwards'}
      subtitleData={{
        date: getDate(new Date(payoutDetailSummary?.CutoffDate as any)).toString(),
      }}
      noLink
    />
  );
};

export default PayoutDetailsEmptyState;
