import React from 'react';

import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import AssignStoresField from '@fd/ui/Filter/AssignStoresField';

import Button from '../../../ui/Button/Button';
import Spacer from '../../../ui/Spacer';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';
import DeviceNameField from './fields/DeviceNameField';
import DevicePinField from './fields/DevicePinField';

export type HydraRegistrationForm = {
  DeviceName: string;
  StoreIds: number[];
  PinCode: number | '';
};
export type DeviceFormProps = {
  initialValues?: HydraRegistrationForm;
  submit: (changes) => Promise<void>;
  onSubmitSuccess: (flag: boolean) => void;
};
export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (
  initialValues: HydraRegistrationForm = {
    DeviceName: '',
    StoreIds: [],
    PinCode: '',
  }
): HydraRegistrationForm => {
  return {
    DeviceName: initialValues.DeviceName || '',
    StoreIds: initialValues.StoreIds || [],
    PinCode: initialValues.PinCode || '',
  };
};

type Props = FormikProps<FormValues> & DeviceFormProps;

const AddDeviceForm = (props: Props) => {
  return (
    <Form>
      <PreventNavigation when={props.dirty} />

      <Grid container>
        <Grid item xs={12}>
          <DeviceNameField />
        </Grid>

        <Grid item xs={12}>
          <AssignStoresField
            dataFd="terminal-store-select"
            enableShrink
            fieldLabel="Store(s)"
            fieldName="StoreIds"
            placeholder=""
          />
        </Grid>

        <Grid item xs={12}>
          <DevicePinField />
        </Grid>

        <Grid item xs={12}>
          <Spacer size={16} variant="horizontal">
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  fdKey="btn-terminal-create"
                  variant="contained"
                  disabled={props.isSubmitting}
                >
                  <Translate id="Add_terminal" />
                </Button>
              </Grid>
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<DeviceFormProps, FormValues>({
  displayName: 'AddTerminalForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.initialValues);
  },
  handleSubmit: (values, formikBag) => {
    const newTerminal: HydraRegistrationForm = {
      ...values,
    };
    formikBag.props
      .submit(newTerminal)
      .then(() => {
        formikBag.resetForm({ values: values });
        formikBag.props.onSubmitSuccess(true);
      })
      .catch(() => {
        formikBag.setSubmitting(false);
      });
  },
})(AddDeviceForm);
