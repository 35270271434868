import axios from 'axios';

export interface IpInfo {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  timezone: string;
}

export const makeAxiosRequest = (url: string, requestHeaders): Promise<IpInfo> => {
  return new Promise<IpInfo>((resolve, reject) => {
    return axios
      .get('/json', {
        baseURL: url,
        withCredentials: false,
        headers: {
          common: requestHeaders,
        },
      })
      .then((data) => {
        const result: IpInfo = data.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
