import React, { useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles(() => ({
  menuOptionsItem: {
    padding: '4px 24px 4px 12px',
  },
  menuOptionsIcon: {
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '12px',
  },
  menuOptionsText: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  optionsIconButton: {
    zIndex: 1,
  },
}));

export type CatalogElementOptionsMenuProps = {
  onArchive: () => void;
  onDuplicate: () => void;
};

export const CatalogElementOptionsMenu = (props: CatalogElementOptionsMenuProps) => {
  const { onArchive, onDuplicate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleArchiveClick = () => {
    handleCloseOptions();
    onArchive();
  };

  const handleDuplicateClick = () => {
    handleCloseOptions();
    onDuplicate();
  };

  return (
    <>
      <IconButton
        className={classes.optionsIconButton}
        onClick={handleOpenOptions}
        data-fd="optionButton"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        id="product-options"
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
      >
        <Permissions allowed={[App.AppResourceSetEnum.CreateCatalogElements]}>
          <MenuItem
            className={classes.menuOptionsItem}
            data-fd="duplicate"
            onClick={handleDuplicateClick}
          >
            <FileCopyIcon className={classes.menuOptionsIcon} />
            <Typography className={classes.menuOptionsText}>
              <Translate id="Duplicate" />
            </Typography>
          </MenuItem>
        </Permissions>
        <Permissions allowed={[App.AppResourceSetEnum.DeleteCatalogElements]}>
          <MenuItem
            className={classes.menuOptionsItem}
            data-fd="archive"
            onClick={handleArchiveClick}
          >
            <ArchiveOutlined className={classes.menuOptionsIcon} />
            <Typography className={classes.menuOptionsText}>
              <Translate id="Archive" />
            </Typography>
          </MenuItem>
        </Permissions>
      </Menu>
    </>
  );
};
