import { AppStoreAppSummary } from '@flipdish/api-client-typescript';

import {
  GetAppStoreAppsSuccess,
  GetConfiguredAppStoreAppsSuccess,
} from '../actions/appstore.actions';
import { appStoreConstants } from '../constants/appstore.constants';

const {
  GET_CONFIGURED_APPSTORE_APPS_REQUEST,
  GET_CONFIGURED_APPSTORE_APPS_SUCCESS,
  GET_CONFIGURED_APPSTORE_APPS_FAILURE,
  GET_APPSTORE_APPS_REQUEST,
  GET_APPSTORE_APPS_SUCCESS,
  GET_APPSTORE_APPS_FAILURE,
} = appStoreConstants;

export interface State {
  configuredAppStoreApps?: AppStoreAppSummary[];
  configuredAppStoreAppsLoading: boolean;
  configuredAppStoreAppsFailed: boolean;
  appStoreApps?: AppStoreAppSummary[];
  appStoreAppsLoading: boolean;
  appStoreAppsFailed: boolean;
  totalAppStoreAppRecords: number;
}
const defaultState: State = {
  configuredAppStoreApps: undefined,
  configuredAppStoreAppsLoading: false,
  configuredAppStoreAppsFailed: false,
  appStoreApps: undefined,
  appStoreAppsLoading: false,
  appStoreAppsFailed: false,
  totalAppStoreAppRecords: 0,
};

export function appStore(state = defaultState, action): State {
  switch (action.type) {
    case GET_CONFIGURED_APPSTORE_APPS_REQUEST: {
      return {
        ...state,
        configuredAppStoreAppsLoading: true,
        configuredAppStoreAppsFailed: false,
      };
    }
    case GET_CONFIGURED_APPSTORE_APPS_SUCCESS: {
      const { payload } = action as GetConfiguredAppStoreAppsSuccess;
      return {
        ...state,
        configuredAppStoreApps: payload,
        configuredAppStoreAppsLoading: false,
        configuredAppStoreAppsFailed: false,
      };
    }
    case GET_CONFIGURED_APPSTORE_APPS_FAILURE: {
      return {
        ...state,
        configuredAppStoreAppsFailed: true,
        configuredAppStoreAppsLoading: false,
      };
    }
    case GET_APPSTORE_APPS_REQUEST: {
      return {
        ...state,
        appStoreAppsLoading: true,
        appStoreAppsFailed: false,
      };
    }
    case GET_APPSTORE_APPS_SUCCESS: {
      const { payload } = action as GetAppStoreAppsSuccess;
      return {
        ...state,
        appStoreApps: payload.Data,
        totalAppStoreAppRecords: payload.TotalRecordCount,
        appStoreAppsLoading: false,
        appStoreAppsFailed: false,
      };
    }
    case GET_APPSTORE_APPS_FAILURE: {
      return {
        ...state,
        appStoreAppsFailed: true,
        appStoreAppsLoading: false,
      };
    }
    default:
      return state;
  }
}
