import React, { ReactNode } from 'react';

import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flex: 1,
    overflowX: 'scroll',
    paddingBottom: theme.spacing(14),
  },
  root: {
    position: 'fixed',
  },
  drawer: {
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      width: 408,
    },
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: '408px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 9,
  },
  headerContainer: {
    width: '100%',
  },
}));

export type DrawerProps = {
  children?: ReactNode;
  footer?: JSX.Element;
  header?: JSX.Element;
  open: boolean;
  onClose: () => void;
};

export const Drawer = (props: DrawerProps): JSX.Element => {
  const { children, footer, header, open, onClose } = props;
  const classes = useStyles();
  const contentStyles = clsx(classes.drawer);
  return (
    <MuiDrawer
      anchor="right"
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      BackdropProps={{ invisible: true }}
      onClose={() => onClose()}
      open={open}
    >
      {header && (
        <div className={classes.headerContainer}>
          {header}
          <Divider />
        </div>
      )}
      <div className={classes.content}>{children}</div>
      {footer && (
        <div className={classes.footer}>
          <Divider />
          {footer}
        </div>
      )}
    </MuiDrawer>
  );
};
