import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import * as orderFulfillmentStatusActions from '../../../actions/orderFulfillmentStatus.actions';
import { flagService } from '../../../services';
import OrderFulfillmentStatusList from './OrderFulfillmentStatusList';
import { OrderFulfillmentStatusListLoadingSkeleton } from './OrderFulfillmentStatusListLoadingSkeleton';

type OrderFulfillmentStatusProps = {};

type Props = OrderFulfillmentStatusProps & MappedState & MappedDispatch & RouteComponentProps;

const OrderFulfillmentStatusConfigs = ({
  appId,
  isOrderFulfillmentStatusOn,
  translate,
  orderFulfillmentStatusConfigurations,
  getOrderFulfillmentStatusConfigs,
  orderFulfillmentStatusLoading,
  totalRecordCount,
}: Props) => {
  useEffect(() => {
    if (isOrderFulfillmentStatusOn) {
      getOrderFulfillmentStatusConfigs(appId);
    }
  }, [appId, isOrderFulfillmentStatusOn]);

  if (!isOrderFulfillmentStatusOn) {
    return null;
  }

  return orderFulfillmentStatusLoading ? (
    <OrderFulfillmentStatusListLoadingSkeleton />
  ) : (
    <OrderFulfillmentStatusList
      limit={30}
      orderFulfillmentStatusConfigurations={orderFulfillmentStatusConfigurations}
      total={totalRecordCount}
      appId={appId}
      isLoading={orderFulfillmentStatusLoading}
      translate={translate}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    isOrderFulfillmentStatusOn: flagService.isFlagOn(state, 'orderFulfillmentStatusConfigurations'),
    translate: getTranslate(locale),
    orderFulfillmentStatusConfigurations:
      state.orderFulfillmentStatusConfig.configuredOrderFulfillmentStatusConfigurations,
    orderFulfillmentStatusLoading:
      state.orderFulfillmentStatusConfig.configuredOrderFulfillmentStatusLoading,
    totalRecordCount: state.orderFulfillmentStatusConfig.totalOrderFulfillmentStatusRecords,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOrderFulfillmentStatusConfigs: (appId: string) =>
    dispatch(orderFulfillmentStatusActions.getConfiguredOrderFulfillmentStatusForApp(appId)),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderFulfillmentStatusConfigs);

export default EnhancedComponent;
