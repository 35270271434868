import { createSelector } from 'reselect';

const selectPermissions = (state: AppState) => state.permissions;

const hasPermissionFactory = (allowed: AppState['permissions']) =>
  createSelector([selectPermissions], (permissions) => {
    return allowed.some((permissionName) => permissions.includes(permissionName));
  });

export const permissionsSelector = { hasPermissionFactory };
