import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useContextualStyles = makeStyles((theme: Theme) => ({
  Button: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    fontSize: '14px',
  },
}));

export default useContextualStyles;
