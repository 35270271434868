import React, { ComponentType, ReactNode, useEffect, useState } from 'react';

import Add from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { Button } from '@fd/ui/atoms';

import { setDrawerClosed } from '../../actions/drawer.actions';
import PausedPayoutsBanner from '../../components/Billing/components/PausedPayoutsBanner';
import { AuditLogsButton } from '../../components/ContextualButton/AuditLogsButton';
import HelpDrawer from '../../components/HelpDrawer/HelpDrawer';
import { removeLastSlug } from '../../helpers/strings';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { showPausedPayouts } from '../../selectors/app.selector';
import Spacer from '../Spacer';
import DocumentTitle from './DocumentTitle';
import FullWidthContainer from './FullWidthContainer';

export const HORIZONTAL_SPACE_CLASSNAME = 'horizontal-content-space';
export const LEFT_HORIZONTAL_SPACE_CLASSNAME = 'left-horizontal-content-space';
export const RIGHT_HORIZONTAL_SPACE_CLASSNAME = 'right-horizontal-content-space';
export const VERTICAL_SPACE_CLASSNAME = 'vertical-content-space';
export const maxWidth = 1112;
const historyMinLength = 3;
const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) => ({
  header: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    paddingTop: spacing(3),
    paddingBottom: spacing(3),

    [breakpoints.down('md')]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(3),
    },
  },
  titleContainer: {
    paddingTop: spacing(2),
  },
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  titleSection: {
    flexGrow: 1,
  },
  caption: {
    lineHeight: '26px',
  },

  contactSupportButton: {
    [breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: spacing(2),
    },
  },
  topSection: {
    maxWidth,
    paddingBottom: spacing(3),

    [breakpoints.down('md')]: {
      paddingBottom: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
  containerWrapper: {
    maxWidth,
    [breakpoints.down('md')]: {
      maxWidth: 'none',
    },
  },
  container: {
    maxWidth,
    marginLeft: spacing(6),
    marginRight: spacing(4),
    paddingBottom: spacing(2),

    [breakpoints.only('md')]: {
      marginLeft: 12,
      marginRight: spacing(3),
    },

    [breakpoints.only('sm')]: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
      maxWidth: 'none',
    },

    [breakpoints.only('xs')]: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
      maxWidth: 'none',
    },
  },
  fullWidth: {
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 0,
  },
  fluid: {
    maxWidth: 'none',
  },
  help: {
    lineHeight: '24px',
  },
  backButton: ({ hasTitleComponent }: { hasTitleComponent: boolean }) => {
    return {
      margin: hasTitleComponent ? spacing(-0.75, 0, -1.5, -1.5) : spacing(-1.5, 0, -1.5, -1.5),

      [breakpoints.only('sm')]: {
        marginLeft: spacing(-2),
      },

      [breakpoints.only('xs')]: {
        marginLeft: spacing(-2),
      },
    };
  },
  actions: {
    position: 'fixed',
    right: spacing(2),
    bottom: spacing(2),
  },
  learnMoreLink: {
    cursor: 'pointer',
    color: palette.primary.main,
    paddingLeft: spacing(2),
  },

  '@global': {
    [`.${HORIZONTAL_SPACE_CLASSNAME}`]: {
      paddingLeft: `${spacing(3)} !important`,
      paddingRight: `${spacing(3)} !important`,

      [breakpoints.only('xs')]: {
        paddingLeft: `${spacing(2)} !important`,
        paddingRight: `${spacing(2)} !important`,
      },
    },
    [`.${LEFT_HORIZONTAL_SPACE_CLASSNAME}`]: {
      paddingLeft: `${spacing(3)} !important`,

      [breakpoints.only('xs')]: {
        paddingLeft: `${spacing(2)} !important`,
      },
    },
    [`.${RIGHT_HORIZONTAL_SPACE_CLASSNAME}`]: {
      paddingRight: `${spacing(3)} !important`,

      [breakpoints.only('xs')]: {
        paddingRight: `${spacing(2)} !important`,
      },
    },
    [`.${VERTICAL_SPACE_CLASSNAME}`]: {
      paddingTop: `${spacing(3)} !important`,
      paddingBottom: `${spacing(3)} !important`,

      [breakpoints.only('xs')]: {
        paddingTop: `${spacing(2)} !important`,
        paddingBottom: `${spacing(2)} !important`,
      },
    },
  },
}));

export const PageTitle = ({ title }: { title: string | ReactNode }) => (
  <Typography variant="h5" component="h2">
    {title}
  </Typography>
);

type History = RouteComponentProps['history'];
type ToParentFn = (props: { history: History }) => void;
export type OuterProps = {
  actionBtnTitle?: TranslationId;
  actions?: ComponentType<React.PropsWithChildren<unknown>>;
  auditLogsFilter?: { type: string; value: string };
  caption?: ReactNode;
  children: ReactNode;
  className?: string;
  contextButtons?: ReactNode[];
  documentTitle?: string;
  fluid?: boolean;
  fullWidth?: boolean;
  header?: ReactNode;
  hideHeader?: boolean;
  hrefBtnLink?: string;
  pageHeader?: ReactNode;
  showActionButton?: boolean;
  showAddIcon?: boolean;
  strictToParent?: boolean;
  title: ReactNode;
  titleComponent?: ComponentType<React.PropsWithChildren<unknown>>;
  toParent?: string | ToParentFn;
  userPermissions?: string;
  onClick?: () => void;
  openLinkInNewTab?: boolean;
};

type InnerProps = RouteComponentProps & MappedState & MappedDispatch;

type Props = InnerProps & OuterProps;

const PageLayout = (props: Props) => {
  const {
    actionBtnTitle,
    actions: Actions,
    auditLogsFilter,
    caption,
    children,
    className = '',
    contextButtons,
    documentTitle,
    fluid,
    fullWidth,
    header,
    hideHeader,
    history,
    hrefBtnLink,
    isHelpDrawerOpen,
    location,
    match,
    onClick,
    openLinkInNewTab = false,
    pageHeader,
    permissions,
    setDrawerClose,
    showActionButton,
    showAddIcon,
    showPausedPayoutsBanner,
    strictToParent,
    title,
    titleComponent: TitleComponent,
    toParent,
    userPermissions,
  } = props;
  const classes = useStyles({ hasTitleComponent: !!TitleComponent });
  const [currentUrl, setCurrentUrl] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const classNames = clsx({
    [classes.container]: true,
    [className]: !!className,
    [classes.fluid]: fluid,
    [classes.fullWidth]: fullWidth,
  });
  const wrapperClassNames = clsx({
    [classes.containerWrapper]: !fluid,
  });

  useEffect(() => {
    setCurrentUrl(location.pathname);

    if (match.url !== currentUrl && isHelpDrawerOpen) {
      setDrawerClose();
    }
  }, [match.url]);

  const handleClickAway = () => {
    if (match.url !== currentUrl && isHelpDrawerOpen) {
      setDrawerClose();
    }
  };

  const hasPermissions = permissions.some((p) => p === userPermissions);

  const modifiedToParent =
    typeof toParent !== 'function' && toParent === './' ? removeLastSlug(match.url) : toParent;
  return (
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => handleClickAway()}>
      <div className={wrapperClassNames}>
        {documentTitle && <DocumentTitle>{documentTitle}</DocumentTitle>}

        {pageHeader ? pageHeader : null}
        <div className={classNames}>
          {pageHeader && <Spacer size={16} variant="vertical" />}
          {!hideHeader && (
            <Grid container className={classes.header} alignItems="center" direction="column">
              <Grid item container direction="row" alignContent="space-between">
                {modifiedToParent && (
                  <Grid item>
                    <IconButton
                      aria-label="Back"
                      data-fd="back_button"
                      className={classes.backButton}
                      component={'button'}
                      onClick={() => {
                        handleClickAway();
                        if (typeof modifiedToParent === 'function') {
                          modifiedToParent({ history });
                          return;
                        }
                        if (modifiedToParent && strictToParent) {
                          history.push(modifiedToParent);
                        } else if (modifiedToParent && history.length < historyMinLength) {
                          history.push(modifiedToParent);
                        } else {
                          history.goBack();
                        }
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid item className={classes.headerContainer}>
                  {contextButtons && contextButtons.map((button) => <>{button}</>)}
                  {auditLogsFilter && <AuditLogsButton auditLogsFilter={auditLogsFilter} />}
                  <HelpDrawer>
                    <span className={classes.learnMoreLink}>
                      <Translate id="Learn_more" />
                    </span>
                  </HelpDrawer>
                </Grid>
              </Grid>
              <Grid item container className={classes.titleContainer}>
                <Grid item className={classes.titleSection}>
                  {TitleComponent ? <TitleComponent /> : <PageTitle title={title} />}
                  {caption && (
                    <Typography className={classes.caption} variant="caption" component="h3">
                      {caption}
                    </Typography>
                  )}
                </Grid>

                {hasPermissions && showActionButton && (
                  <>
                    {isMobile && <Spacer size={56} variant="vertical" />}
                    <Grid item className={classes.contactSupportButton}>
                      <Button
                        fullWidth={true}
                        fdKey={`Action-button-${actionBtnTitle}`}
                        href={hrefBtnLink}
                        target={openLinkInNewTab ? '_blank' : undefined}
                        // @ts-ignore
                        rel={openLinkInNewTab ? 'noopener noreferrer' : undefined}
                        onClick={onClick}
                      >
                        {showAddIcon && <Add />}
                        {actionBtnTitle && <Translate id={actionBtnTitle} />}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              {showPausedPayoutsBanner && <PausedPayoutsBanner />}
            </Grid>
          )}
          <FullWidthContainer fullWidth={fullWidth}>
            {header ? <div className={classes.topSection}>{header}</div> : null}
            <ErrorBoundary identifier={documentTitle || title}>
              <>{children}</>
            </ErrorBoundary>
          </FullWidthContainer>
          {Actions ? (
            <Spacer size={24}>
              <div className={classes.actions}>
                <Actions />
              </div>
            </Spacer>
          ) : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    permissions: state.permissions,
    isHelpDrawerOpen: state.drawer.helpDrawerOpen,
    showPausedPayoutsBanner: showPausedPayouts(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setDrawerClose: () => dispatch(setDrawerClosed()),
  };
}

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PageLayout);
