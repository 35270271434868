import * as React from 'react';

import Button, { type ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { indigo } from '@mui/material/colors';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createLoadingSelector } from '../selectors/loading.selector';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        width: '0%',
      },
    },
    buttonProgress: {
      color: indigo[600],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      width: '100%',
    },
  });

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  const loadingSelector = ownProps.loadingResources
    ? createLoadingSelector(ownProps.loadingResources)
    : () => false;
  return (state: AppState, ownProps: Props) => {
    return {
      isLoading: ownProps.loading ? ownProps.loading : loadingSelector(state),
    };
  };
};
export interface ILoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingResources?: string[];
  wrapperClassName?: string;
  progressClassName?: string;
  buttonRef?: any;
  fdKey?: string;
  // obsolete
  buttonProps?: ButtonProps & { 'data-fd'?: string };
}
type Props = WithStyles<typeof styles> &
  ILoadingButtonProps & { isLoading: boolean } & { dispatch: ThunkDispatch };
const LoadingButton = compose<Props & MappedState, ILoadingButtonProps>(
  withStyles(styles, {
    name: 'FdLoadingButton',
    withTheme: true,
  }),
  connect(mapStateToPropsFactory)
)(
  class LoadingButton extends React.PureComponent<Props, {}> {
    public render() {
      const {
        /* eslint-disable */
        loading,
        loadingResources,
        wrapperClassName,
        dispatch,
        /* eslint-enable */
        buttonRef,
        progressClassName,
        fdKey,
        buttonProps: deprecatedButtonProps,
        classes,
        isLoading,
        children,
        disabled,
        ...buttonProps
      } = this.props;

      return (
        <Button
          {...(deprecatedButtonProps as any)}
          {...buttonProps}
          disabled={disabled || isLoading}
          data-fd={
            fdKey || (deprecatedButtonProps && deprecatedButtonProps['data-fd']) || undefined
          }
          ref={buttonRef}
        >
          {children}
          {isLoading && (
            <CircularProgress
              size={24}
              className={progressClassName ? progressClassName : classes.buttonProgress}
            />
          )}
        </Button>
      );
    }
  }
);

export { LoadingButton };
export default LoadingButton;
