import { Store } from '@flipdish/api-client-typescript';
import { vouchersConstants } from '../constants/vouchers.constants';
import { voucherEventConstants } from '../signalr/hub.events';

import {
  LoadVoucherStoresFailure,
  LoadVoucherStoresRequest,
  LoadVoucherStoresSuccess,
} from '../actions/vouchers.actions';
import {
  ADD_NEW_STORES_SELECTION,
  ADD_NEW_STORES_SUGGESTIONS,
} from '../components/Vouchers/actions';
import { appsConstants } from '../constants/apps.constants';

const vouchersInitialState = {
  // For vouchers on vouchers screen
  vouchers: [],
  vouchersCount: 0,
  vouchersTimestamp: null,
  vouchersLoading: false,
  vouchersQueue: [],
  vouchersAppId: null,

  // For individual voucher on voucher screen
  voucher: null,
  voucherLoading: false,
  voucherTimestamp: null,
  voucherStatistics: [],
  voucherStatisticsTimestamp: null,
  voucherSearchParams: [],
  voucherSearchCustom: true,
  voucherSearchCodeResults: [],
  addNew: {
    request: {
      success: undefined,
      error: undefined,
    },
    stores: {
      ordinal: [],
      suggestionOrdinal: [],
    },
  },
};

export function vouchers(state = vouchersInitialState, action) {
  switch (action.type) {
    case appsConstants.SELECTED_APP_CHANGED: {
      return {
        ...state,
        addNew: {
          request: {
            success: undefined,
            error: undefined,
          },
          stores: {
            ordinal: [],
            suggestionOrdinal: [],
          },
        },
      };
    }
    case ADD_NEW_STORES_SELECTION: {
      return {
        ...state,
        addNew: {
          ...state.addNew,
          stores: {
            ...state.addNew.stores,
            ordinal: action.payload,
          },
        },
      };
    }
    case ADD_NEW_STORES_SUGGESTIONS: {
      return {
        ...state,
        addNew: {
          ...state.addNew,
          stores: {
            ...state.addNew.stores,
            suggestionOrdinal: action.payload,
          },
        },
      };
    }

    case vouchersConstants.CREATE_VOUCHER_REQUEST_RESET: {
      return {
        ...state,
        addNew: {
          ...state.addNew,
          request: {
            success: undefined,
            error: undefined,
          },
        },
        voucherLoading: false,
      };
    }
    case vouchersConstants.CREATE_VOUCHER_REQUEST: {
      return {
        ...state,
        addNew: {
          ...state.addNew,
          request: {
            success: undefined,
            error: undefined,
          },
        },
        voucherLoading: true,
      };
    }
    case vouchersConstants.CREATE_VOUCHER_SUCCESS:
    case vouchersConstants.UPDATE_VOUCHER_SUCCESS: {
      // const vouchers = state.vouchers.concat([action.payload] as any);

      return {
        ...state,
        // vouchers,
        voucher: action.payload.voucher,
        voucherLoading: false,
        addNew: {
          ...state.addNew,
          request: {
            success: true,
            error: undefined,
          },
        },
      };
    }
    case vouchersConstants.CREATE_VOUCHER_FAILURE:
    case vouchersConstants.UPDATE_VOUCHER_FAILURE: {
      return {
        ...state,
        addNew: {
          ...state.addNew,
          request: {
            success: false,
            error: action.payload,
          },
        },
        voucherLoading: false,
      };
    }

    // For vouchers on vouchers screen
    case vouchersConstants.SET_VOUCHERS_APP_ID:
      return {
        ...state,
        vouchersAppId: action.payload,
      };
    case vouchersConstants.CLEAR_VOUCHERS:
      return {
        ...state,
        vouchers: [],
        vouchersLoading: true,
      };
    case vouchersConstants.GET_VOUCHERS:
      return {
        ...state,
        vouchersLoading: true,
      };
    case vouchersConstants.GET_VOUCHERS_FAILURE:
      return {
        ...state,
        vouchersLoading: false,
      };
    case vouchersConstants.GET_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: action.payload.Data,
        vouchersLoading: false,
        vouchersCount: action.payload.TotalRecordCount,
      };
    case vouchersConstants.GET_VOUCHERS_SUCCESS_CONCAT:
      return {
        ...state,
        vouchers: state.vouchers.concat(action.payload.Data),
        vouchersLoading: false,
        vouchersCount: action.payload.TotalRecordCount,
      };
    case vouchersConstants.SET_VOUCHERS_TIMESTAMP:
      return {
        ...state,
        vouchersTimestamp: action.payload,
      };

    // For individual voucher on voucher screen
    case vouchersConstants.GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
        voucherTimestamp: null,
      };
    case vouchersConstants.SET_VOUCHER_TIMESTAMP:
      return {
        ...state,
        voucherTimestamp: action.payload,
      };
    case vouchersConstants.SET_VOUCHER_LOADING:
      return {
        ...state,
        addNew: {
          ...state.addNew,
          request: {
            success: action.payload.success,
            error: undefined,
          },
        },
        voucherLoading: action.payload.loading,
      };
    case vouchersConstants.GET_VOUCHER_STATISTICS:
      return {
        ...state,
        voucherStatistics: action.payload,
        voucherStatisticsTimestamp: null,
      };
    case vouchersConstants.SET_VOUCHER_STATISTICS_TIMESTAMP:
      return {
        ...state,
        voucherStatisticsTimestamp: action.payload,
      };
    case vouchersConstants.SET_VOUCHER_SEARCH_PARAMS:
      return {
        ...state,
        voucherSearchParams: action.payload,
      };
    case vouchersConstants.SET_VOUCHER_SEARCH_CUSTOM:
      return {
        ...state,
        voucherSearchCustom: action.payload,
      };
    case vouchersConstants.SET_VOUCHER_SEARCH_CODE_RESULTS:
      return {
        ...state,
        voucherSearchCodeResults: action.payload,
      };

    // For voucher "events"
    case voucherEventConstants.CREATED: {
      const searchParams = state.voucherSearchParams;
      const searchCustom = state.voucherSearchCustom;

      let addToVouchers = false;

      if (searchParams.length === 0 && !searchCustom) {
        addToVouchers = true;
      } else {
        let codeCheck = false;
        let disabledCheck = false;
        let typeCheck = false;
        let statusCheck = false;
        const customCheck = !searchCustom || action.payload.Voucher.VoucherSubType === 'Custom';

        const codeValues = searchParams.filter((searchParam) =>
          (searchParam as any).type.includes('code:')
        );
        const typeValues = searchParams.filter((searchParam) =>
          (searchParam as any).type.includes('type:')
        );
        const statusValues = searchParams.filter(
          (searchParam) =>
            (searchParam as any).type.includes('status:') &&
            (searchParam as any).type != 'status:disabled'
        );
        const disabledValues = searchParams.filter(
          (searchParam) => (searchParam as any).type === 'status:disabled'
        );

        if (codeValues.length === 0) {
          codeCheck = true;
        } else {
          for (let codeValue of codeValues) {
            if ((codeValue as any).value === action.payload.Voucher.Code) {
              codeCheck = true;
              break;
            }
          }
        }

        if (typeValues.length === 0) {
          typeCheck = true;
        } else {
          for (let typeValue of typeValues) {
            if ((typeValue as any).value === action.payload.Voucher.VoucherType) {
              typeCheck = true;
              break;
            }
          }
        }

        if (statusValues.length === 0) {
          statusCheck = true;
        } else {
          for (let statusValue of statusValues) {
            if ((statusValue as any).value === action.payload.Voucher.Status) {
              statusCheck = true;
              break;
            }
          }
        }

        if (disabledValues.length === 0 || !action.payload.Voucher.IsEnabled) {
          disabledCheck = true;
        }

        addToVouchers = codeCheck && disabledCheck && typeCheck && statusCheck && customCheck;
      }

      if (addToVouchers) {
        return {
          ...state,
          vouchers: [action.payload.Voucher].concat([...state.vouchers.slice(0, -1)]),
        };
      }

      return state;
    }
    case voucherEventConstants.DELETED: {
      let newVouchers: any[] = state.vouchers;
      let index = newVouchers.findIndex(
        (voucher) => (voucher as any).VoucherId === action.payload.VoucherId
      );

      if (index != -1) {
        newVouchers = [...newVouchers.splice(0, index), ...newVouchers.splice(index + 1)];
      }

      return {
        ...state,
        vouchers: newVouchers,
      };
    }
    case voucherEventConstants.UPDATED: {
      let newVoucher = state.voucher;
      let newVouchers = state.vouchers;

      if (
        newVoucher &&
        (newVoucher as any).VoucherId &&
        (newVoucher as any).VoucherId === action.payload.VoucherId
      ) {
        newVoucher = action.payload.Voucher;
      }

      const index = newVouchers.findIndex(
        (voucher) => (voucher as any).VoucherId === action.payload.VoucherId
      );

      if (index != -1) {
        // @ts-ignore let newVouchers: never[] <- should have type in vouchersInitialState
        newVouchers = [
          ...newVouchers.splice(0, index),
          action.payload.Voucher,
          ...newVouchers.splice(index + 1),
        ];
      }

      return {
        ...state,
        voucher: newVoucher,
        vouchers: newVouchers,
      };
    }

    default:
      return state;
  }
}

const reduceLoadAllRequest = (
  state: DefaultVoucherStoresState,
  action: LoadVoucherStoresRequest
): DefaultVoucherStoresState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
      },
    };
  }

  return {
    ...state,
    error: undefined,
  };
};

const reduceLoadAllSuccess = (
  state: DefaultVoucherStoresState,
  action: LoadVoucherStoresSuccess
): DefaultVoucherStoresState => {
  const { query, totalCount } = action.meta;
  if (query) {
    const ids = ((state.search && state.search.item_order_index) || []).concat(
      action.payload.map((s) => s.StoreId as number)
    );
    const uniqueIds = new Set(ids);
    return {
      ...state,
      search: {
        query,
        item_order_index: [...uniqueIds],
        totalCount,
        items: action.payload,
      },
    };
  }

  const ids = state.item_order_index.concat(action.payload.map((s) => s.StoreId as number));
  const uniqueIds = new Set(ids);
  return {
    ...state,
    totalCount,
    item_order_index: [...uniqueIds],
    isEmpty: totalCount === 0,
    showSearch: totalCount > 20,
    search: undefined,
    error: undefined,
  };
};
const reduceLoadAllFailure = (
  state: DefaultVoucherStoresState,
  action: LoadVoucherStoresFailure
): DefaultVoucherStoresState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
        totalCount: (state.search && state.search.totalCount) || undefined,
        error: action.payload,
      },
    };
  }

  return {
    ...state,
    error: action.payload,
  };
};

type DefaultVoucherStoresState = {
  storeGroupId?: number;
  isEmpty: boolean;
  item_order_index: number[];
  totalCount?: number;
  showSearch: boolean;
  error?: Error;
  search?: {
    query: string;
    item_order_index: number[];
    totalCount?: number;
    error?: Error;
    items?: Store[];
  };
};

const defaultVoucherStoresState: DefaultVoucherStoresState = {
  isEmpty: false,
  item_order_index: [],
  showSearch: false,
};

const {
  LOAD_VOUCHER_STORES_REQUEST,
  LOAD_VOUCHER_STORES_SUCCESS,
  LOAD_VOUCHER_STORES_FAILURE,
} = vouchersConstants;

export function voucherStores(
  state = defaultVoucherStoresState,
  action
): DefaultVoucherStoresState {
  switch (action.type) {
    case LOAD_VOUCHER_STORES_REQUEST:
      return reduceLoadAllRequest(state, action);
    case LOAD_VOUCHER_STORES_SUCCESS:
      return reduceLoadAllSuccess(state, action);
    case LOAD_VOUCHER_STORES_FAILURE:
      return reduceLoadAllFailure(state, action);
    default:
      return state;
  }
}
