import React from 'react';

import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';

import {
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../../../layouts/Notify/actions';
import { catalogChangesService } from '../../../../services/catalogChanges.service';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 0,
  },
  container: {
    marginTop: theme.spacing(3),
  },
  contentContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
  },
  hasUpdates: {
    backgroundColor: '#EAF2FF',
  },
}));

export type ProductUpdateProps = {
  appId: string;
  menuId?: number;
  numberOfChanges: number;
  translate: TranslateFunction;
};

const ProductUpdates = (props: ProductUpdateProps & MappedDispatch) => {
  const {
    appId,
    closeNotifyLoading,
    menuId,
    numberOfChanges,
    notify,
    notifyError,
    notifyLoading,
    translate,
  } = props;
  const classes = useStyles();
  const hasChanges = numberOfChanges !== 0;
  const message = hasChanges
    ? translate('Product_updates', { count: numberOfChanges })
    : translate('No_product_updates');
  const containerStyles = clsx(classes.container, hasChanges && classes.hasUpdates);

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: () => {
      notifyLoading();
      return catalogChangesService.publishPendingMenuChanges(appId, null, [menuId]);
    },

    onSuccess: () => {
      closeNotifyLoading();
      notify({
        message: translate('Successfully_updated_menus') as string,
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesSummaryQueryKey],
      });
    },

    onError: () => {
      closeNotifyLoading();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return (
    <div className={containerStyles}>
      <Divider />
      <div className={classes.contentContainer}>
        <Typography variant="caption">{message}</Typography>
        {hasChanges && (
          <Button
            className={classes.button}
            fdKey={`apply-updates-${menuId}`}
            lowercase
            onClick={() => mutate()}
            variant="text"
          >
            <Translate id="Apply" />
          </Button>
        )}
      </div>
    </div>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyLoading: () => dispatch(closeNotifyLoading()),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyLoading: () => dispatch(notifyLoading({ persist: true })),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(ProductUpdates);
export { EnhancedComponent as ProductUpdates };
