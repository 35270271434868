export const nonIBANSupportedCountriesWithCurrency = {
  AU: 'AUD',
  BM: 'BMD',
  BR: 'BRL',
  BS: 'BSD',
  CA: 'CAD',
  GB: 'GBP',
  HK: 'HKD',
  IN: 'INR',
  JP: 'JPY',
  MY: 'MYR',
  MX: 'MXN',
  NZ: 'NZD',
  SG: 'SGD',
  US: 'USD',
};

export const nonVATSupportedCountriesWithCurrency = {
  AU: 'AUD',
  BM: 'BMD',
  BR: 'BRL',
  BS: 'BSD',
  CA: 'CAD',
  HK: 'HKD',
  IN: 'INR',
  JP: 'JPY',
  MY: 'MYR',
  MX: 'MXN',
  NZ: 'NZD',
  SG: 'SGD',
  US: 'USD',
};

export const nonSwiftCountries = (value) =>
  value === 'AU' ||
  value === 'BM' ||
  value === 'BS' ||
  value === 'MY' ||
  value === 'MX' ||
  value === 'US' ||
  value === 'NZ';

export const nonNccCountries = (value) =>
  value === 'IN' || value === 'MY' || value === 'MX' || value === 'GB';

export const nonIBANSupportedCurrencies = Object.values(nonIBANSupportedCountriesWithCurrency);

export const nonIBANSupportedCountries = Object.keys(nonIBANSupportedCountriesWithCurrency);

export const nonVATSupportedCountries = Object.keys(nonVATSupportedCountriesWithCurrency);

export const excludedCountries = ['IQ', 'GF', 'BA'];

export const currencyCountriesOptions = Object.entries(
  nonIBANSupportedCountriesWithCurrency
).reduce(
  (agg, [country, currency]) => {
    agg[currency] = { value: country, label: country };
    return agg;
  },
  {} as { [key: string]: { value: string; label: string } }
);

export const BANKING_URL = {
  getAccountDetailsUrl: (appId, accountId) => `/${appId}/finance/bank-accounts/${accountId}`,
};
