import { useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { TranslateFunction } from 'react-localize-redux';

type UseVoucherSettingsParams = {
  errorMessage?: TranslationId | string;
  getStats: (voucherId: string) => void;
  getVoucher: (voucherId: string) => void;
  isExtraStats: boolean;
  isSaving: boolean;
  isSuccess: boolean;
  isVoucher: boolean;
  match: any;
  resetVoucher: () => void;
  translate: TranslateFunction;
};

export const useVoucherSettings = ({
  errorMessage,
  getStats,
  getVoucher,
  isExtraStats,
  isSaving,
  isSuccess,
  isVoucher,
  match,
  resetVoucher,
  translate,
}: UseVoucherSettingsParams) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarRef = useRef<string | number | null>(null);

  useEffect(() => {
    return () => {
      resetVoucher();
    };
  }, []);

  useEffect(() => {
    if (isSaving && !snackbarRef.current) {
      snackbarRef.current = enqueueSnackbar(translate('Auto_saving'), {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'default',
        persist: true,
      });
    }

    return () => {
      if (snackbarRef.current) {
        closeSnackbar(snackbarRef.current);
      }
    };
  }, [isSaving]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(translate('Success'), {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'success',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errorMessage) {
      let message = translate(errorMessage, undefined, {
        missingTranslationMsg: errorMessage,
      });
      if (message === 'undefined') {
        message = errorMessage;
      }

      enqueueSnackbar(message, {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'error',
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!isVoucher && match.params.voucherId) {
      getVoucher(match.params.voucherId);

      if (isExtraStats) {
        getStats(match.params.voucherId);
      }
    }
  }, [isVoucher]);
};
