import React from 'react';

import { App, Order } from '@flipdish/api-client-typescript';
import AppBar from '@mui/material/AppBar';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import AcceptedOrderActions from './AcceptedOrderActions';
import KioskPendingOrderActions from './KioskPendingOrderActions';
import PendingOrderActions from './PendingOrderActions';

const styles = (theme: Theme) => {
  return createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: '#fff',
      padding: '18px',
      [theme.breakpoints.down('sm')]: { padding: 5 },
      marginTop: 10,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: { padding: 0 },
    },
  });
};

type Props = WithStyles<typeof styles> & { order: Order };

const ActionBar: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, order } = props;

  const renderPendingActions = () => (
    <AppBar
      enableColorOnDark
      position="sticky"
      color="primary"
      className={`${classes.appBar} ${HORIZONTAL_SPACE_CLASSNAME}`}
    >
      <Toolbar className={classes.toolbar}>
        <Permissions allowed={[App.AppResourceSetEnum.UpdateOrdersAccept]}>
          {order.AppType === Order.AppTypeEnum.Kiosk ? (
            <KioskPendingOrderActions />
          ) : (
            <>
              {order.DeliveryType ? (
                <PendingOrderActions deliveryType={order.DeliveryType} />
              ) : null}
            </>
          )}
        </Permissions>
      </Toolbar>
    </AppBar>
  );

  const renderAcceptedActions = () => {
    return (
      <AppBar
        enableColorOnDark
        position="sticky"
        color="primary"
        className={`${classes.appBar} ${HORIZONTAL_SPACE_CLASSNAME}`}
      >
        <Toolbar className={classes.toolbar}>
          <AcceptedOrderActions />
        </Toolbar>
      </AppBar>
    );
  };

  if (order.OrderState === Order.OrderStateEnum.ReadyToProcess) {
    return renderPendingActions();
  } else if (
    order.OrderState === Order.OrderStateEnum.AcceptedByRestaurant ||
    order.OrderState === Order.OrderStateEnum.AcceptedAndRefunded ||
    order.OrderState === Order.OrderStateEnum.Dispatched
  ) {
    return renderAcceptedActions();
  }

  return null;
};

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'ActionBar',
  })
)(ActionBar);

export default EnhancedComponent;
