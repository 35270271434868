import React from 'react';

import { BankAccountCreate } from '@flipdish/api-client-typescript';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import { checkIsCompanyOnly } from '../../utils';
import FieldWrapper from './FieldWrapper';

const BankAccountFormBussinesTypeFields = () => (
  <FieldWrapper>
    <Field name={'BusinessType'}>
      {({ field, form }: FieldProps) => {
        const { isSubmitting, values } = form;
        const isCompanyOnly = checkIsCompanyOnly(values?.BankCountryCode);

        return (
          <FormControl {...field}>
            <FormLabel>
              <Typography variant="caption">
                <Translate id={'Choose_account_type'} />
              </Typography>
            </FormLabel>
            <RadioGroup name={'BusinessType'}>
              <FormControlLabel
                value={BankAccountCreate.BusinessTypeEnum.Company}
                label={
                  <Typography variant="body2">
                    <Translate id={'Company'} />
                  </Typography>
                }
                control={<Radio color="primary" />}
                disabled={isSubmitting || isCompanyOnly}
                checked={
                  field.value === BankAccountCreate.BusinessTypeEnum.Company || isCompanyOnly
                }
              />

              {!isCompanyOnly && (
                <FormControlLabel
                  value={BankAccountCreate.BusinessTypeEnum.Individual}
                  label={
                    <Typography variant="body2">
                      <Translate id={'Individual_sole_proprietorship'} />
                    </Typography>
                  }
                  control={<Radio color="primary" />}
                  disabled={isSubmitting}
                />
              )}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  </FieldWrapper>
);

export default BankAccountFormBussinesTypeFields;
