import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import { getSymbol as getCurrencySymbol } from '../../../../helpers/currency';
import FormItemLayout from '../../components/FormItemLayout';

const validate = (value: number) => {
  if (value <= 0) {
    return 'Value_must_be_greater_than_0';
  }
  return;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiInputAdornment-positionEnd': {
        whiteSpace: 'nowrap',
      },
    },
  })
);

const DiscountAmountField = () => {
  const classes = useStyles();

  return (
    <Field name={'DiscountAmount'} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;

        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <FormItemLayout label={<Translate id="Discount_amount" />}>
            <TextField
              {...field}
              autoComplete="off"
              className={classes.root}
              disabled={isSubmitting}
              error={showError}
              fdKey={'discount-amount'}
              helperText={
                showError ? (
                  <Translate id={fieldError as 'Value_must_be_greater_than_0'} />
                ) : undefined
              }
              minWidth={200}
              inputProps={{
                min: 0,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getCurrencySymbol(form.values.Currency)}
                  </InputAdornment>
                ),
              }}
              type="number"
              variant="outlined"
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};
export default DiscountAmountField;
