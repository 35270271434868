import React, { useEffect, useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { menusActions } from '../../actions/menus.actions';
import { PageTitleWithBeta } from '../../layouts/Portal/PageTitleWithBeta';
import { menusService } from '../../services/menus.service';
import LinkButton from '../../ui/Button/LinkButton';
import PageLayout from '../../ui/Layout';
import FiltersContainer from '../../ui/Layout/FiltersContainer';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import BulkShowHideTable from './components/BulkShowHideTable';
import BulkEditSearchFilter from './components/filters/SearchFilter';
import { menuBulkEditMeta } from './data/MenuBulkEditMeta';
import { BulkEditTableData } from './types';

type InnerProps = MappedState & RouteComponentProps<{ menuId: string; path: string }>;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  menuBulkEditor: {
    width: 'calc(100% + 24px)',
    margin: '-12px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0',
    },
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  textContainer: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  storeNames: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.58)',
  },
  storesMore: {
    color: '#05149e',
  },
  table: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  pageHeader: {
    padding: theme.spacing(2),
  },
  tableHeader: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  menuName: {
    color: '#05149e',
    fontWeight: 400,
  },
  linkBtn: {
    padding: 0,
    marginBottom: '10px',
    height: 21,
  },
  gridMargin: {
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const MenuBulkEditor = (props: Props) => {
  const {
    canBulkEdit,
    currentApp,
    // @ts-ignore
    dispatch,
    languageCode,
    match,
    menuStores,
    history,
    translate,
    menuId,
  } = props;

  const classes = useStyles();
  const [searchHideFilter, setSearchHideFilter] = useState<string | undefined>();
  const [searchShowFilter, setSearchShowFilter] = useState<string | undefined>();
  const [menuTitle, setMenuTitle] = useState<string | undefined>();

  const [hideTableLoading, setHideTableLoading] = useState<boolean>(true);
  const [showTableLoading, setShowTableLoading] = useState<boolean>(true);

  const [hideTableData, setHideTableData] = useState();
  const [showTableData, setShowTableData] = useState();

  const redirectOnShowHideError = (responseArray, itemName) => {
    const successArray = responseArray?.filter(
      (responseItem) => responseItem.ValidationCode === 'Success'
    );
    const failedArray = responseArray?.filter(
      (responseItem) => responseItem.ValidationCode !== 'Success'
    );
    if (failedArray?.length > 0) {
      history.push({
        pathname: `/${currentApp.AppId}/menus/bulk-edit/${menuId}/report`,
        state: {
          successArray,
          failedArray,
          itemName,
        },
      });
    }
  };

  const onClick = ({ row }) => {
    const toggleIsAvailable = !row.IsAvailable;
    dispatch(menusActions.showHideMenuItems(menuId, toggleIsAvailable, row.Items))
      .then((result) => {
        redirectOnShowHideError(result, row.MenuElementName);
      })
      .then(() => {
        getHideTableData();
        getShowTableData();
      });
  };

  useEffect(() => {
    if (match.params.menuId) {
      const menuId = parseInt(match.params.menuId);

      dispatch(menusActions.getMenuCheckpoints(menuId));
      dispatch(menusActions.getMenuStores(menuId));
    }
  }, [match.params.menuId]);

  useEffect(() => {
    getHideTableData();
    getShowTableData();
  }, [menuId]);

  const setMenuTitleFromResponse = (bulkHideResponse) => {
    if (bulkHideResponse?.length > 0) {
      setMenuTitle(bulkHideResponse[0].MenuName);
    }
  };

  const setBulkEditItemAction = (item: BulkEditTableData) => ({
    ...item,
    Action: item?.IsAvailable ? 'Hide' : 'Show',
  });

  const sortByMenuElementName = (a: { MenuElementName: string }, b: { MenuElementName: string }) =>
    a.MenuElementName?.localeCompare(b.MenuElementName);

  const getHideTableData = async () => {
    const isHideTableValue = true;
    setHideTableLoading(true);
    const bulkHideResponse = await menusService.getBulkShowHideList(menuId, isHideTableValue);
    const bulkHideList = bulkHideResponse.Data;
    if (!menuTitle) {
      setMenuTitleFromResponse(bulkHideList);
    }
    if (bulkHideList) {
      //@ts-ignore somebody didn't type this properly
      setHideTableData(bulkHideList.map(setBulkEditItemAction).sort(sortByMenuElementName));
    }
    setHideTableLoading(false);
  };

  const getShowTableData = async () => {
    const isHideTableValue = false;
    setShowTableLoading(true);
    const bulkShowResponse = await menusService.getBulkShowHideList(menuId, isHideTableValue);
    const bulkShowList = bulkShowResponse.Data;
    if (!menuTitle) {
      setMenuTitleFromResponse(bulkShowList);
    }
    if (bulkShowList) {
      //@ts-ignore somebody didn't type this properly
      setShowTableData(bulkShowList.map(setBulkEditItemAction).sort(sortByMenuElementName));
    }
    setShowTableLoading(false);
  };

  const renderMenuStoresList = () => {
    if (menuStores.length === 0) {
      return <>{translate('No_store_associated')}</>;
    }

    return (
      <>
        {menuStores.slice(0, 2).join(', ')}
        {menuStores.length > 2 && (
          <span className={classes.storesMore}>
            {` ${translate('More_stores', { stores: menuStores.length - 2 })}`}
          </span>
        )}
      </>
    );
  };

  const defaultMenuName = `${translate('Unnamed')} ${menuId}`;
  const menuName = menuTitle || defaultMenuName;

  if (!menuId) {
    return (
      <div data-fd="bulk-edit-loading-spinner">
        <AppLoadingCircularProgress />
      </div>
    );
  }

  if (!canBulkEdit) {
    // @ts-ignore
    return <Redirect path={`/${currentApp.AppId}/home/`} />;
  }

  return (
    <PageLayout
      documentTitle={`${translate('Menus')}`}
      toParent={`/${currentApp.AppId}/menus`}
      title={<PageTitleWithBeta translateId={'show_hide_items'} />}
    >
      <Grid container className={classes.menuBulkEditor}>
        <Grid item xs={12} className={classes.pageHeader}>
          <LinkButton
            to={`/${currentApp.AppId}/menus/${menuId}`}
            fdKey={menuName}
            className={classes.linkBtn}
            variant="text"
          >
            <Typography variant="h5" className={classes.menuName}>
              {menuName}
            </Typography>
          </LinkButton>
          <Typography variant="subtitle2" className={classes.storeNames}>
            {renderMenuStoresList()}
          </Typography>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" className={classes.divider}></Divider>

      <Grid container className={classes.table}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography variant="body1" className={classes.tableHeader}>
            <Translate id="Hide_menu_items" />
          </Typography>
          <FiltersContainer>
            <Grid item xs={12} md={6} className={clsx(classes.gridMargin, classes.gridItem)}>
              <BulkEditSearchFilter
                searchFilter={searchHideFilter}
                setSearchFilter={setSearchHideFilter}
                translate={translate}
              />
            </Grid>
          </FiltersContainer>
        </Grid>
        <BulkShowHideTable
          filter={searchHideFilter}
          languageCode={languageCode}
          loading={hideTableLoading}
          metaData={menuBulkEditMeta}
          onRowClick={onClick}
          tableData={hideTableData}
          tableType={'Hide'}
        />
      </Grid>
      <Grid container className={classes.table}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography variant="body1" className={classes.tableHeader}>
            <Translate id="Hidden_items" />
          </Typography>
          <FiltersContainer>
            <Grid item xs={12} md={6} className={clsx(classes.gridMargin, classes.gridItem)}>
              <BulkEditSearchFilter
                searchFilter={searchShowFilter}
                setSearchFilter={setSearchShowFilter}
                translate={translate}
              />
            </Grid>
          </FiltersContainer>
        </Grid>
        <BulkShowHideTable
          filter={searchShowFilter}
          languageCode={languageCode}
          loading={showTableLoading}
          metaData={menuBulkEditMeta}
          onRowClick={onClick}
          tableData={showTableData}
          tableType={'Show'}
        />
      </Grid>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;

function mapStateToProps(state: AppState, props: RouteComponentProps<{ menuId: string }>) {
  const { locale, currentApp, menus } = state;
  const canBulkEdit = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.UpdateMenuItemsHideTemporarily.toString()
  );
  return {
    translate: getTranslate(locale),
    currentApp,
    canBulkEdit,
    languageCode: getActiveLanguage(state.locale),
    menuId: Number(props.match.params.menuId),
    menu: menus.menu,
    menuCheckpoints: menus.menuCheckpoints,
    menuStores: menus.menuStores,
  };
}

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(MenuBulkEditor);
