import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import { Button } from '../../../ui/Button';

export interface IRemoveOverrideButtonProps {
  bhoId: number;
  storeId: number;
  storeName: string;
  isDelivery: boolean;
  onClick(props: { bhoId: number; storeId: number }): Promise<void>;
}
interface FormikValues {
  bhoId: number;
  storeId: number;
}
type Props = FormikProps<FormikValues> & IRemoveOverrideButtonProps;
const RemoveOverrideButton = ({ isDelivery, isSubmitting, storeName }: Props) => (
  <Form>
    <Button
      fdKey={`remove-override-${isDelivery ? 'delivery' : 'collection'}-${storeName}`}
      color="inherit"
      disabled={isSubmitting}
      type="submit"
    >
      <Translate id="Remove_override" />
    </Button>
  </Form>
);

const RemoveOverrideButtonForm = withFormik<IRemoveOverrideButtonProps, FormikValues>({
  mapPropsToValues: (props) => {
    return {
      bhoId: props.bhoId,
      storeId: props.storeId,
    };
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onClick(values).catch((err) => {
      formikBag.setSubmitting(false);
    });
  },
})(RemoveOverrideButton);

export default (props: IRemoveOverrideButtonProps) => (
  <Permissions allowed={[App.AppResourceSetEnum.EditStoresOpeningHoursOverride]}>
    <RemoveOverrideButtonForm {...props} />
  </Permissions>
);
