import { UserEventInfo } from '@flipdish/api-client-typescript';
import { customerEventConstants } from '../../signalr/hub.events';

const initialState = {
  events: new Array<{
    event: string;
    data: UserEventInfo;
    description: string;
    ts: Date;
  }>(),
};

export function customerHub(state = initialState, action) {
  const { events } = state;
  const { type, data, description, ts } = action;
  switch (action.type) {
    case customerEventConstants.CREATED:
    case customerEventConstants.UPDATED:
    case customerEventConstants.CONSENT_UPDATED: {
      const newEvents = events.map((r) => r);
      newEvents.push({ event: type, data, description, ts });
      return { ...state, events: newEvents };
    }
  }
  return state;
}
