import React, { useState } from 'react';

import { Group, Product } from '@flipdish/api-client-typescript';
import Grid, { type GridSize } from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { isNumber } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';

import { VIEW_MODE } from '../../constants';
import { ArchiveDialog } from '../../partials/ArchiveDialog';
import { generateSrcSet } from '../../utils';
import { CatalogElementOptionsMenu } from './CatalogElementOptionsMenu';

const useStyles = makeStyles((theme: Theme) => ({
  bottomRow: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    display: '-webkit-box',
    wordWrap: 'break-word',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  grid: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    justifyContent: 'space-between',
  },
  image: {
    width: '104px',
    height: '104px',
    borderRadius: '5px',
  },
  listImage: {
    width: '72px',
    height: '72px',
  },
  link: {
    textDecoration: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  productCardContents: {
    padding: theme.spacing(1),
    height: '188px',
    minWidth: '295px',
    position: 'relative' as any,
    '&:hover': {
      background: '#eaf2ff',
    },
  },
  listProductCardContents: {
    padding: theme.spacing(2),
    height: '104px',
  },
  title: {
    textOverflow: 'ellipsis',
    marginBottom: '8px',
    fontSize: '1rem',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
  },
  topRow: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  topRowList: {
    justifyContent: 'start',
    overflow: 'initial',
  },
  listActions: {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
    width: 'auto',
  },
  optionsMenuHolder: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(-1.5),
  },
  label: {
    fontSize: theme.typography.pxToRem(9),
    lineHeight: 1.2,
    margin: 0,
    padding: 0,
    textTransform: 'uppercase',
  },
}));

type InnerProps = MappedState;
type OuterProps = {
  CardId: string;
  CardType?: Product.ProductTypeEnum | Group.GroupTypeEnum;
  Description?: string;
  ImageFileName?: string;
  Name: string;
  onArchive: (id: string) => void;
  onDuplicate: (id: string) => void;
  Price?: number;
  Sku: string;
  viewMode: VIEW_MODE;
  PriceFromModifier: number | null;
};

export type CatalogCardProps = InnerProps & OuterProps;

const CatalogCard = (props: CatalogCardProps): JSX.Element => {
  const {
    CardId,
    CardType,
    Description,
    ImageFileName,
    Name,
    onArchive,
    onDuplicate,
    Price,
    translate,
    viewMode,
    PriceFromModifier,
  } = props;
  const [isArchiveDialogVisible, setIsArchiveDialogVisible] = useState<boolean>(false);
  const classes = useStyles();

  const duplicate = () => {
    onDuplicate(CardId);
  };

  const showArchiveDialog = () => {
    setIsArchiveDialogVisible(true);
  };
  const archive = () => {
    setIsArchiveDialogVisible(false);
    onArchive(CardId);
  };

  const getArchiveDialogMessage = (): string => {
    switch (CardType) {
      case Group.GroupTypeEnum.ModifierGroup:
        return translate('ModifierGroups_ArchiveModifierGroupDialog_ArchiveMessage') as string;
      case Product.ProductTypeEnum.Modifier:
        return translate('Modifiers_ArchiveModifierDialog_ArchiveMessage') as string;
      default:
        return translate('Products_ArchiveProductDialog_Message') as string;
    }
  };

  const archiveDialogMessage = getArchiveDialogMessage();

  const isGridViewMode = viewMode === VIEW_MODE.GRID;

  const calculateGridColumns = (
    gridColumns: GridSize,
    imageColumns: GridSize,
    noImageColumns: GridSize
  ): GridSize => {
    if (isGridViewMode) {
      return gridColumns;
    }
    return ImageFileName ? imageColumns : noImageColumns;
  };

  const renderPrice = (price: number, priceFromModifier: number | null) => {
    return (
      <>
        {isNumber(PriceFromModifier) && (
          <Typography className={classes.label} variant="caption">{`${translate(
            'From'
          )}`}</Typography>
        )}
        <Typography variant="h5">
          {isNumber(priceFromModifier) ? priceFromModifier.toFixed(2) : price.toFixed(2)}
        </Typography>
      </>
    );
  };

  return (
    <Box
      className={clsx(classes.productCardContents, {
        [classes.listProductCardContents]: !isGridViewMode,
      })}
    >
      <ArchiveDialog
        open={isArchiveDialogVisible}
        onCancel={() => setIsArchiveDialogVisible(false)}
        onConfirm={archive}
        message={archiveDialogMessage}
        translate={translate}
      />
      <Link
        to={{
          pathname: `${window.location.pathname}/${CardId}`,
          state: { productName: Name },
        }}
        className={classes.link}
        data-fd={`product-card-${CardId}`}
      />
      <Grid className={classes.grid} container>
        <Grid
          item
          container
          spacing={1}
          className={clsx(classes.topRow, { [classes.topRowList]: !isGridViewMode })}
        >
          {ImageFileName && !isGridViewMode && (
            <Grid item>
              <img
                className={clsx(classes.image, classes.listImage)}
                srcSet={generateSrcSet(ImageFileName)}
              />
            </Grid>
          )}
          <Grid
            item
            zeroMinWidth
            xs={calculateGridColumns(12, 8, 9)}
            md={calculateGridColumns(12, 9, 10)}
          >
            <Typography className={classes.title} variant="h5">
              {Name}
            </Typography>
            {Description && (
              <Typography className={classes.description} variant="caption">
                {Description}
              </Typography>
            )}
          </Grid>
          {ImageFileName && isGridViewMode && (
            <Grid item>
              <img className={classes.image} srcSet={generateSrcSet(ImageFileName)} />
            </Grid>
          )}
          {!isGridViewMode && (
            <Grid item container direction="row-reverse" className={classes.listActions}>
              <Grid item className={classes.optionsMenuHolder}>
                <CatalogElementOptionsMenu onArchive={showArchiveDialog} onDuplicate={duplicate} />
              </Grid>
              {Price !== undefined && <Grid item>{renderPrice(Price, PriceFromModifier)}</Grid>}
            </Grid>
          )}
        </Grid>
        {isGridViewMode && (
          <Grid container item direction="row-reverse" className={classes.bottomRow}>
            <Grid item>
              <CatalogElementOptionsMenu onArchive={showArchiveDialog} onDuplicate={duplicate} />
            </Grid>
            {Price !== undefined && <Grid item>{renderPrice(Price, PriceFromModifier)}</Grid>}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(connect(mapStateToProps))(CatalogCard);
export { EnhancedComponent as CatalogCard };
