import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import { lighten, styled } from '@mui/material/styles';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { useInView } from 'react-intersection-observer';
import { getTranslate, Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ResizeObserver from 'resize-observer-polyfill';

import { getDateTimeLocale } from '../../../../../selectors/localeDateTime.selector';
import { getStoreOrderCapacity } from '../../../../../services/store.service';
import { Button } from '../../../../../ui/Button';
import { canEditOrderCapacity } from '../../selectors';
import { FormValues } from '../PreOrderSettingsForm';
import SetCapacityModal from '../SetCapacityModal';

type DayOfTheWeekEnum = Required<Flipdish.Range>['DayOfWeek'];
type WeekDay = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
export type Period = {
  day: WeekDay;
  start: {
    hour: number;
    minute: number;
  };
  end: {
    hour: number;
    minute: number;
  };
  orders: number;
};
export type Periods = { [key in WeekDay]: Period[] };
type PeriodsConfig = { periods?: Periods; storeId?: number; interval?: number };
const EMPTY_PERIODS = () => ({ Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: [] });
const DAY_OF_WEEK_MAP: {
  [key in DayOfTheWeekEnum]: WeekDay;
} & { [key in WeekDay]: DayOfTheWeekEnum } = {
  Friday: 'Fri',
  Monday: 'Mon',
  Saturday: 'Sat',
  Sunday: 'Sun',
  Thursday: 'Thu',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

const ROWS = Array.from(Array(24).keys());
const COLUMNS: WeekDay[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
// 1:mon, ..., 0:sun
const COLUMN_DAY_INDEXES = [1, 2, 3, 4, 5, 6, 0];

//#region NakedExpansionPanel
const NakedExpansionPanel = styled(ExpansionPanel)({
  boxShadow: 'none',
  '& .MuiExpansionPanelSummary-root': {
    padding: 0,
  },
  '& .MuiExpansionPanelDetails-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});
//#endregion
//#region StrictColumnWidthTable
const StrictColumnWidthTable = styled(Table)(({ theme }) => ({
  tableLayout: 'fixed',
  '& > thead > tr > th:first-child': { borderBottom: 'none' },
  '& > thead > tr > th': { textTransform: 'capitalize' },
  '& > tbody': {
    '& > tr ': {
      '& > th': { padding: 0, borderBottom: 'none', height: '24px', verticalAlign: 'top' },
      '& > td:nth-child(n+3)': { borderLeft: `1px solid ${lighten(theme.palette.divider, 0.8)}` },
    },
    '& > tr:nth-child(odd)': {
      '& > td': {
        borderBottom: 'none',
      },
    },
    '& > tr:nth-child(even)': {
      '& > th': {
        color: 'transparent',
      },
    },
  },
}));
//#endregion
//#region SingleHeaderTableCell
const SingleHeaderTableCell = styled(TableCell)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 0,
  paddingRight: 0,
  '&.MuiTableCell-sizeSmall:last-child': {
    paddingRight: 0,
  },
});

const SmallButton = styled(Button)({
  minWidth: 'auto',
  padding: '4px',
});
//#endregion

//#region Table
type BackgroundTableProps = {
  columns: Array<{ label: string; value: WeekDay }>;
  onNext?(): void;
  onPrevious?(): void;
};
const BackgroundTable = React.forwardRef<HTMLTableElement, BackgroundTableProps>(
  (props, forwardedRef) => {
    const { columns, onNext, onPrevious } = props;

    const tableHeaderCells = useMemo(() => {
      if (columns.length === 1) {
        // SINGLE COLUMN MODE
        return columns.map((column) => (
          <SingleHeaderTableCell key={column.value}>
            <SmallButton fdKey="previous_day" variant="outlined" onClick={onPrevious}>
              <ChevronLeftIcon />
            </SmallButton>
            {column.label}
            <SmallButton fdKey="next_day" variant="outlined" onClick={onNext}>
              <ChevronRightIcon />
            </SmallButton>
          </SingleHeaderTableCell>
        ));
      }

      return columns.map((column) => (
        <TableCell align="center" key={column.value}>
          {column.label}
        </TableCell>
      ));
    }, [columns]);

    return (
      <StrictColumnWidthTable size="small" ref={forwardedRef} aria-hidden="true">
        <colgroup>
          <col width="57px" />
          {columns.map((column) => (
            <col key={column.value} />
          ))}
        </colgroup>

        <TableHead>
          <TableRow>
            <TableCell />
            {tableHeaderCells}
          </TableRow>
        </TableHead>
        <TableBody>
          {ROWS.map((rowIdx) => (
            <TableRow key={rowIdx}>
              <TableCell component="th" scope="row">
                {`${rowIdx}:00`}
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.value} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StrictColumnWidthTable>
    );
  }
);
//#endregion

//#region LIST
const useListStyles = makeStyles((theme: Theme) => ({
  list: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    right: 0,
  },
  listItem: {
    position: 'absolute',
    padding: '1px 2px 2px 1px',
    '&:hover': {
      minHeight: '60px',
      zIndex: 1,
    },
  },
  listItemText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffd990',
    margin: 0,
    height: '100%',
    overflow: 'hidden',
    '& > .MuiTypography-root': {
      marginTop: theme.spacing(0.5),
      maxHeight: '100%',
      textAlign: 'center',
      '& > strong': {
        display: 'block',
        fontWeight: 500,
      },
    },
  },
  textLowercase: {
    textTransform: 'lowercase',
  },
}));
const ZoomTransitionsDelay = { transitionDelay: '500ms' };

type OrderCapacityListProps = WeekDaysListProps;
const OrderCapacityList: React.FC<React.PropsWithChildren<OrderCapacityListProps>> = (props) => {
  const { columns } = props;

  if (columns.length === 1) {
    return <WeekDaysPerDayList {...props} day={columns[0].value} />;
  }

  return <WeekDaysList {...props} />;
};

type WeekDaysListProps = {
  inViewPort: boolean;
  columns: Array<{ label: string; value: WeekDay }>;
  cellSize: { width: number; height: number };
  periods?: Periods;
  translate: TranslateFunction;
  style?: React.CSSProperties;
  disabled?: boolean;
  onAdd(period: Period): void;
  onEdit(period: Period): void;
};
const WeekDaysList: React.FC<React.PropsWithChildren<WeekDaysListProps>> = (props) => {
  const { inViewPort, style, cellSize, periods, onAdd, onEdit, disabled, translate } = props;

  const classes = useListStyles();

  const onListClick = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        return;
      }
      const { top, left } = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - left;
      const offsetY = e.clientY - top;
      const cellXLocation = offsetX / cellSize.width;
      const cellYLocation = offsetY / cellSize.height;

      const dayIdx = Math.ceil(cellXLocation) - 1;

      // don't allow 4 time to cross over
      let hour = Math.floor(cellYLocation);
      let minute = Math.round((cellYLocation - hour) * 60);
      // round minutes to nearest 0,15,30,45
      const roundedMinute = (Math.round(minute / 15) * 15) % 60;
      if (minute > 45 && roundedMinute === 0) {
        hour = hour + 1;
      }

      const hourOffset = 1;
      let endHour = hour + hourOffset;
      let endMinute = roundedMinute;
      if (endHour > 23) {
        endHour = 23;
        endMinute = 59;
        hour = 24 - hourOffset;
        minute = 0;
      }

      const period: Period = {
        day: COLUMNS[dayIdx] as WeekDay,
        start: { hour, minute: roundedMinute },
        end: { hour: endHour, minute: endMinute },
        // TODO should be something from somewhere
        orders: 10,
      };

      onAdd && onAdd(period);
    },
    [cellSize, onAdd, disabled]
  );

  return (
    <Zoom in={inViewPort} style={ZoomTransitionsDelay} mountOnEnter>
      <List style={style} className={classes.list} onClick={onListClick}>
        {Object.values(periods || {}).map((dayPeriods, periodIdx) => {
          return dayPeriods.map((period, idx) => {
            const { start, end, day } = period;

            const itemStyle = {
              top:
                cellSize.height *
                // calc in minutes and convert back to hours
                ((start.minute + start.hour * 60) / 60),
              left: periodIdx * cellSize.width,
              width: cellSize.width,
              height:
                cellSize.height *
                // calc in minutes and convert back to hours
                ((end.minute + end.hour * 60 - (start.minute + start.hour * 60)) / 60),
            };
            const oneHourCapacity = itemStyle.height === 24;
            return (
              <ListItem
                key={`${day}.${idx}`}
                button
                disableGutters
                data-fd={`period_${day}_${start.hour}:${start.minute}_${end.hour}:${end.minute}`}
                className={classes.listItem}
                style={itemStyle}
                disabled={disabled}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onEdit && onEdit(period);
                }}
              >
                <ListItemText disableTypography className={classes.listItemText}>
                  <Typography variant="caption" color="textPrimary" noWrap>
                    <strong>{period.orders}</strong>
                    <Typography
                      variant="inherit"
                      style={{ marginTop: oneHourCapacity ? '6px' : '0px' }}
                      color="textSecondary"
                      display="block"
                      className={classes.textLowercase}
                    >
                      {`${translate('Orders')} / ${translate('Interval')}`}
                    </Typography>
                    <Typography variant="inherit" color="textSecondary" display="block">
                      {formatPeriod(period)}
                    </Typography>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          });
        })}
      </List>
    </Zoom>
  );
};

type WeekDaysPerDayListProps = {
  day: WeekDay;
} & WeekDaysListProps;
export const WeekDaysPerDayList: React.FC<React.PropsWithChildren<WeekDaysPerDayListProps>> = (
  props
) => {
  const {
    inViewPort,
    style,
    cellSize,
    day,
    periods: periodConfig,
    onAdd,
    onEdit,
    disabled,
    translate,
  } = props;

  const classes = useListStyles();

  const onListClick = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        return;
      }
      const { top } = e.currentTarget.getBoundingClientRect();
      const offsetY = e.clientY - top;
      const cellYLocation = offsetY / cellSize.height;

      // don't allow 4 time to cross over
      let hour = Math.floor(cellYLocation);
      let minute = Math.round((cellYLocation - hour) * 60);
      // round minutes to nearest 0,15,30,45
      const roundedMinute = (Math.round(minute / 15) * 15) % 60;
      if (minute > 45 && roundedMinute === 0) {
        hour = hour + 1;
      }

      const hourOffset = 1;
      let endHour = hour + hourOffset;
      let endMinute = roundedMinute;
      if (endHour > 23) {
        endHour = 23;
        endMinute = 59;
        hour = 24 - hourOffset;
        minute = 0;
      }

      const period: Period = {
        day,
        start: { hour, minute: roundedMinute },
        end: { hour: endHour, minute: endMinute },
        // TODO should be something from somewhere
        orders: 10,
      };

      onAdd && onAdd(period);
    },
    [cellSize, day, onAdd, disabled]
  );
  return (
    <>
      {Object.entries(periodConfig || {}).map(([key, periods]) => (
        <Slide key={key} in={inViewPort && key === day} direction={'left'} mountOnEnter>
          <List style={style} className={classes.list} onClick={onListClick}>
            {periods.map((period, idx) => {
              const { start, end, day } = period;

              const itemStyle = {
                top:
                  cellSize.height *
                  // calc in minutes and convert back to hours
                  ((start.minute + start.hour * 60) / 60),
                // left: periodIdx * cellSize.width,
                width: cellSize.width,
                height:
                  cellSize.height *
                  // calc in minutes and convert back to hours
                  ((end.minute + end.hour * 60 - (start.minute + start.hour * 60)) / 60),
              };
              const oneHourCapacity = itemStyle.height === 24;
              return (
                <ListItem
                  key={`${day}.${idx}`}
                  button
                  disableGutters
                  data-fd={`period_${day}_${start.hour}:${start.minute}_${end.hour}:${end.minute}`}
                  className={classes.listItem}
                  style={itemStyle}
                  disabled={disabled}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onEdit && onEdit(period);
                  }}
                >
                  <ListItemText disableTypography className={classes.listItemText}>
                    <Typography variant="caption" color="textPrimary" noWrap>
                      <strong>{period.orders}</strong>
                      <Typography
                        variant="inherit"
                        style={{ marginTop: oneHourCapacity ? '6px' : '0px' }}
                        color="textSecondary"
                        display="block"
                        className={classes.textLowercase}
                      >
                        {`${translate('Orders')} / ${translate('Interval')}`}
                      </Typography>
                      <Typography variant="inherit" color="textSecondary" display="block">
                        {formatPeriod(period)}
                      </Typography>
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Slide>
      ))}
    </>
  );
};
//#endregion

//#region ORDER_CAPACITY
const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
  },
}));

type OrderCapacityProps = {
  localDayColumns: Array<{
    value: WeekDay;
    label: string;
  }>;
  deliveryType: 'delivery' | 'collection';
  data: PeriodsConfig;
  canEdit: boolean;
  onChange(data: Required<Flipdish.StoreOrderCapacityConfig>): void;
  translate: TranslateFunction;
};
export const OrderCapacity = (props: OrderCapacityProps) => {
  const { localDayColumns, deliveryType, data, canEdit, onChange, translate } = props;

  const [ref, inViewPort] = useInView({ triggerOnce: true });

  const [modalState, setModalState] = useState<{ action: 'add' | 'edit'; period: Period }>();

  const classes = useStyles();

  //#region TABLE MEASUREMENT
  const tableRef =
    useRef<HTMLTableElement | null>() as React.MutableRefObject<HTMLTableElement | null>;
  const [sizes, setSizes] = useState({
    table: { width: 0, height: 0 },
    cell: { width: 0, height: 0 },
  });
  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;
    if (tableRef.current) {
      // TODO debounce... no need to recalc on every pixel change
      resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const size = {
            table: { width: 0, height: 0 },
            cell: { width: 0, height: 0 },
          };

          const body = entry.target.querySelector('tbody');
          const headerCell = entry.target.querySelector('tbody > tr > th');
          const firstCell = entry.target.querySelector('tbody > tr > td');
          if (body && firstCell) {
            const bodyBounds = body.getBoundingClientRect();
            const headerCellBounds = headerCell ? headerCell.getBoundingClientRect() : { width: 0 };
            const firstCellBounds = firstCell.getBoundingClientRect();

            size.cell.width = firstCellBounds.width;
            size.cell.height = firstCellBounds.height;

            size.table.width = bodyBounds.width - headerCellBounds.width;
            size.table.height = bodyBounds.height;
          }

          console.log('[CAPACITY] TABLE_SIZE', size);

          setSizes(size);
        });
      });

      resizeObserver.observe(tableRef.current);
    }
    return () => {
      resizeObserver && resizeObserver.disconnect();
    };
  }, [tableRef.current]);
  //#endregion

  //#region HANDLERS
  const handleOnAdd = useCallback(
    (period: Period) => {
      if (!canEdit) {
        return;
      }
      // console.log('onAdd', period);
      setModalState(undefined);

      if (data && data.storeId) {
        const nextPeriods: Periods = data.periods || EMPTY_PERIODS();
        // TODO REFACTOR this is mutable on purpose (a no no)
        nextPeriods[period.day] = [...(nextPeriods[period.day] || []), period];

        onChange(
          mapPeriodsConfigToCapacityConfig({
            deliveryType,
            storeId: data.storeId!,
            interval: data.interval!,
            periods: nextPeriods,
          })
        );
      }
    },
    [deliveryType, data, onChange, canEdit]
  );
  const handleOnRemove = useCallback(
    (periodToRemove: Period) => {
      if (!canEdit) {
        return;
      }
      // console.log('onRemove', periodToRemove);
      setModalState(undefined);

      if (data && data.storeId) {
        const nextPeriods: Periods = data.periods || EMPTY_PERIODS();
        // TODO REFACTOR this is mutable on purpose (a no no)
        nextPeriods[periodToRemove.day] = (nextPeriods[periodToRemove.day] || []).filter(
          (p) => !isEqual(p, periodToRemove)
        );

        onChange(
          mapPeriodsConfigToCapacityConfig({
            deliveryType,
            storeId: data.storeId!,
            interval: data.interval!,
            periods: nextPeriods,
          })
        );
      }
    },
    [deliveryType, data, onChange, canEdit]
  );
  const handleOnEdit = useCallback(
    (period: Period, oldPeriod: Period) => {
      if (!canEdit) {
        return;
      }
      // console.log('onEdit', period, oldPeriod);
      setModalState(undefined);

      if (data && data.storeId) {
        const nextPeriods: Periods = data.periods || EMPTY_PERIODS();
        // TODO REFACTOR this is mutable on purpose (a no no)
        if (oldPeriod.day !== period.day) {
          nextPeriods[oldPeriod.day] = (nextPeriods[oldPeriod.day] || []).filter(
            (p) => !isEqual(p, oldPeriod)
          );
          (nextPeriods[period.day] || []).push(period);
        } else {
          nextPeriods[period.day] = (nextPeriods[period.day] || []).map((p) => {
            if (isEqual(p, oldPeriod)) {
              return period;
            }
            return p;
          });
        }

        onChange(
          mapPeriodsConfigToCapacityConfig({
            deliveryType,
            storeId: data.storeId!,
            interval: data.interval!,
            periods: nextPeriods,
          })
        );
      }
    },
    [deliveryType, data, onChange, canEdit]
  );
  //#endregion

  //#region SINGLE/MULTI COLUMN
  const [columns, setColumns] = useState<
    Array<{
      value: WeekDay;
      label: string;
    }>
  >([]);

  useEffect(() => {
    // TODO this 700 number should be backed up by some science...
    if (sizes.table.width < 700) {
      const date = new Date();
      const day = date.getDay();
      // this is needed as week starts Mon-Sun in our app, but in JS start Sun-Mon
      const today = day === 0 ? 6 : day - 1;
      setColumns(() => {
        const col = localDayColumns[today];
        return [col];
      });
    } else {
      setColumns(localDayColumns);
    }
  }, [localDayColumns, sizes.table]);
  const handleOnPrevious = useCallback(() => {
    setColumns((cols) => {
      const idx = localDayColumns.findIndex((c) => cols[0].value === c.value);
      const col = localDayColumns[(((idx - 1) % 7) + 7) % 7];
      return [col];
    });
  }, [localDayColumns]);
  const handleOnNext = useCallback(() => {
    setColumns((cols) => {
      const idx = localDayColumns.findIndex((c) => cols[0].value === c.value);
      const col = localDayColumns[(idx + 1) % 7];
      return [col];
    });
  }, [localDayColumns]);
  //#endregion

  //#region SYNC w IntervalMinutes field
  /** used to store initial order/interval for future calcs when changing interval per minute field */
  const initOrderIntervals = useRef<{ [uId: string]: number }>({});
  const initInterval = useRef<number>();
  // TODO REFACTOR its over-complex and i was tired
  /** handle changes on IntervalMinutes field and calcs next order/intervals */
  useEffect(() => {
    if (!initInterval.current && data.interval) {
      // setting initial interval
      initInterval.current = data.interval;
      // onMount
      return;
    }

    if (data && initInterval.current && data.interval) {
      const ratio = data.interval / initInterval.current;
      // console.log('[CAPACITY] onIntervalChange', initInterval.current, data.interval, ratio);

      const nextPeriods: Periods = data.periods || EMPTY_PERIODS();
      // TODO REFACTOR this is mutable on purpose (a no no)
      Object.entries(nextPeriods).reduce((agg, [day, periods]) => {
        agg[day] = periods.map((p) => {
          let initialOrders = p.orders;
          if (
            !initOrderIntervals.current[
              `${day}${p.start.hour}${p.start.minute}${p.end.hour}${p.end.minute}`
            ]
          ) {
            // save 4 next calcs
            initOrderIntervals.current[
              `${day}${p.start.hour}${p.start.minute}${p.end.hour}${p.end.minute}`
            ] = p.orders;
          } else {
            initialOrders =
              initOrderIntervals.current[
                `${day}${p.start.hour}${p.start.minute}${p.end.hour}${p.end.minute}`
              ];
          }

          return {
            ...p,
            orders: Math.round(initialOrders * ratio),
          };
        });

        return agg;
      }, nextPeriods);

      onChange(
        mapPeriodsConfigToCapacityConfig({
          deliveryType,
          storeId: data.storeId!,
          interval: data.interval!,
          periods: nextPeriods,
        })
      );
    }
  }, [data]);
  //#endregion

  return (
    <div className={classes.root} ref={ref}>
      <BackgroundTable
        ref={tableRef}
        columns={columns}
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
      />

      {sizes.table.width && sizes.table.height ? (
        <OrderCapacityList
          inViewPort={inViewPort}
          columns={columns}
          translate={translate}
          periods={data.periods}
          style={sizes.table}
          cellSize={sizes.cell}
          onAdd={(period) => {
            if (canEdit) {
              setModalState({ action: 'add', period });
            }
          }}
          onEdit={(period) => {
            if (canEdit) {
              setModalState({ action: 'edit', period });
            }
          }}
        />
      ) : null}

      <SetCapacityModal
        interval={data.interval!}
        periods={data.periods}
        localDayNames={localDayColumns}
        modalState={modalState}
        open={!!modalState}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onCancel={() => {
          setModalState(undefined);
        }}
        onRemove={handleOnRemove}
      />
    </div>
  );
};
//#endregion

type OuterProps = {
  appId: string;
  storeId: string;
  type: 'delivery' | 'collection';
  intervalMinutes?: number;
  setHasPeriods: (hasPeriod: boolean) => void;
};
type Props = OuterProps & MapStateToProps & MapDispatchToProps;
export const OrderCapacityFormItems = (props: Props) => {
  const {
    appId,
    storeId,
    type,
    localDayColumns,
    canEdit,
    intervalMinutes,
    translate,
    setHasPeriods,
  } = props;
  const [data, setData] = useState<PeriodsConfig>({});
  const { values, resetForm } = useFormikContext<FormValues>();

  useEffect(() => {
    getStoreOrderCapacity({
      appId,
      storeId: parseInt(storeId, 10),
      deliveryType: type === 'collection' ? 'Pickup' : 'Delivery',
    })
      .then((data) => {
        const periods = mapCapacityConfigToPeriodsConfig(data as Flipdish.StoreOrderCapacityConfig);
        setData(periods);
        // race condition with formik. This will stop form appearing dirty when it's not
        setTimeout(async () => {
          resetForm({ values: { ...values, OrderCapacity: data } });
        }, 100);
        if (data && data.OrderCapacityPeriods) {
          setHasPeriods(data.OrderCapacityPeriods.length > 0);
        }
      })
      .catch(() => {
        // set default
        setData({
          periods: EMPTY_PERIODS(),
          storeId: parseInt(storeId, 10),
          interval: 10,
        });
        // race condition with formik. This will stop form appearing dirty when it's not
        setTimeout(async () => {
          resetForm({ values: values });
        }, 100);
      });
  }, [appId, storeId]);

  // sync IntervalMinutes field w data
  useEffect(() => {
    if (intervalMinutes) {
      setData((d) => ({ ...d, interval: parseInt(`${intervalMinutes}`, 10) }));
    }
  }, [intervalMinutes]);

  return (
    <NakedExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="order-capacity-content"
        id="order-capacity-header"
      >
        <ListItemText
          primary={<Translate id="Set_capacity_limits" />}
          secondary={<Translate id="Manage_busy_times_by_restricting_preorders" />}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Field name="OrderCapacity">
          {({ field, form }: FieldProps<{ OrderCapacity?: Flipdish.StoreOrderCapacityConfig }>) => (
            <OrderCapacity
              localDayColumns={localDayColumns}
              deliveryType={type}
              translate={translate}
              data={data}
              canEdit={canEdit}
              onChange={(data) => {
                form.setFieldValue('OrderCapacity', data);
              }}
            />
          )}
        </Field>
      </ExpansionPanelDetails>
    </NakedExpansionPanel>
  );
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  const canEdit = canEditOrderCapacity(state);

  const localDayColumns = getShortDayNamesLocalised(state);

  return {
    localDayColumns,
    canEdit,
    translate: getTranslate(locale),
  };
};

type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, props: OuterProps) => ({});

export default connect<MapStateToProps, MapDispatchToProps, OuterProps>(
  mapStateToProps,
  mapDispatchToProps
)(OrderCapacityFormItems);

const getShortDayNamesLocalised = createSelector([getDateTimeLocale], (dtLocale) => {
  const localDayColumns = COLUMN_DAY_INDEXES.map((dIdx, idx) => ({
    value: COLUMNS[idx],
    label: dtLocale.localize.day(dIdx, { width: 'abbreviated' }) as string,
  }));

  return localDayColumns;
});

//#endregion

//#region HELPERS

function mapCapacityConfigToPeriodsConfig(data: Flipdish.StoreOrderCapacityConfig) {
  const periods = (
    data.OrderCapacityPeriods || ([] as Flipdish.StoreOrderCapacityPeriod[])
  ).reduce<Periods>((agg, p: Required<Flipdish.StoreOrderCapacityPeriod>) => {
    const day = DAY_OF_WEEK_MAP[p.DayOfTheWeek];

    agg[day].push({
      day,
      start: {
        hour: p.PeriodStartHour,
        minute: p.PeriodStartMinutes,
      },
      end: {
        hour: p.PeriodEndHour,
        minute: p.PeriodEndMinutes,
      },
      orders: p.MaxOrderNumberPerStoreInterval,
    });
    return agg;
  }, EMPTY_PERIODS());
  return { periods, storeId: data.StoreId!, interval: data.StoreIntervalInMinutes! };
}

function mapPeriodsConfigToCapacityConfig(props: {
  deliveryType: OrderCapacityProps['deliveryType'];
  storeId: number;
  interval: number;
  periods: Periods;
}): Required<Flipdish.StoreOrderCapacityConfig> {
  return {
    DeliveryType: props.deliveryType === 'collection' ? 'Pickup' : 'Delivery',
    StoreId: props.storeId,
    StoreIntervalInMinutes: props.interval!,
    OrderCapacityPeriods: Object.values(props.periods).reduce<Flipdish.StoreOrderCapacityPeriod[]>(
      (agg, periods) => {
        periods.forEach((p) => {
          agg.push({
            DayOfTheWeek: DAY_OF_WEEK_MAP[p.day],
            PeriodStartHour: p.start.hour,
            PeriodStartMinutes: p.start.minute,
            PeriodEndHour: p.end.hour,
            PeriodEndMinutes: p.end.minute,
            MaxOrderNumberPerStoreInterval: p.orders,
          });
        });
        return agg;
      },
      []
    ),
  };
}

export function getTimesPerInterval(interval: number, amPm?: boolean) {
  const times: string[] = [];

  const minutesInDay = 24 * 60;

  let tt = 0; // start time
  for (let i = 0; tt < minutesInDay; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format

    const formattedHour = (amPm ? `0${hh == 12 ? 12 : hh % 12}` : `0${hh}`).slice(-2);
    const formattedMinute = `0${mm}`.slice(-2);
    const appendix = amPm ? `${hh < 12 ? 'AM' : 'PM'}` : '';

    times[i] = `${formattedHour}:${formattedMinute}${appendix}`;
    tt = tt + interval;
  }

  const lastPeriod = `${amPm ? '12' : '23'}:${'59'}${amPm ? 'PM' : ''}`;
  if (times[times.length] !== lastPeriod) {
    times.push(lastPeriod);
  }

  return times;
}

function formatTime({ hour, minute }: Period['start']) {
  return `${hour}:${minute < 10 ? '0' + minute : minute}`;
}
function formatPeriod(period: Period) {
  return `${formatTime(period.start)} - ${formatTime(period.end)}`;
}
//#endregion
