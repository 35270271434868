import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import {
  createVoucher,
  createVoucherRequestReset,
  vouchersActions,
} from '../../../actions/vouchers.actions';
import { flagService } from '../../../services/flagService';
import { useVoucherSettings } from '../hooks/useVoucherSettings';
import { getIsCreateLoading } from '../selectors';
import LumpDiscountForm from './components/LumpDiscountForm';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { dispatch: ThunkDispatch };
const LumpDiscount = (props: Props) => {
  useVoucherSettings({
    errorMessage: props.errorMessage,
    getStats: props.getStats,
    getVoucher: props.get,
    isExtraStats: props.extraStats,
    isSaving: props.isSaving,
    isSuccess: props.isSuccess,
    isVoucher: !!props.voucher,
    match: props.match,
    resetVoucher: props.reset,
    translate: props.translate,
  });

  if (props.isSuccess && props.vouchersRoute) {
    return <Redirect to={props.vouchersRoute} />;
  }

  const {
    appId,
    create,
    isLoading,
    isVoucherChannelEnabled, // TODO - remove when voucherChannel is fully released
    isVoucherDiscoverableEnabled, // TODO - remove when voucherDiscoverable is fully released
    isVoucherLimitAvailabilityEnabled, // TODO - remove when voucherLimitAvailability is fully released
    isVoucherValidPeriodEnabled, // TODO - remove when voucherValidPeriod is fully released
    match,
    translate,
    update,
    voucher,
  } = props;
  const editMode = match.params.voucherId ? true : false;

  return (
    <PageLayout
      toParent={`/${appId}/vouchers`}
      documentTitle="Lump_discount"
      title={<Translate id="Lump_discount" />}
    >
      <PaperContainer fluid>
        <LumpDiscountForm
          create={create}
          editMode={editMode}
          isLoading={isLoading}
          isVoucherChannelEnabled={isVoucherChannelEnabled} // TODO - remove when voucherChannel is fully released
          isVoucherDiscoverableEnabled={isVoucherDiscoverableEnabled} // TODO - remove when voucherDiscoverable is fully released
          isVoucherLimitAvailabilityEnabled={isVoucherLimitAvailabilityEnabled} // TODO - remove when voucherLimitAvailability is fully released
          isVoucherValidPeriodEnabled={isVoucherValidPeriodEnabled} // TODO - remove when voucherValidPeriod is fully released
          key={editMode && voucher ? voucher.VoucherId : 'form'}
          translate={translate}
          update={update}
          voucher={voucher}
        />
      </PaperContainer>
    </PageLayout>
  );
};

const mapStateToProps = (state: AppState, ownProps) => {
  const { vouchers, currentApp, permissions } = state;
  const isSaving = getIsCreateLoading(state);

  const isSuccess = vouchers.addNew.request.success === true;
  const error = vouchers.addNew.request.error;
  const errorMessage = error ? error.message : undefined;

  const vouchersRoute = currentApp.AppId ? `/${currentApp.AppId}/vouchers` : undefined;
  const voucher = ownProps.match.params.voucherId ? vouchers.voucher : null;
  const extraStats = permissions.some(
    (p) => p === App.AppResourceSetEnum.ViewVouchersStatistics.toString()
  );

  return {
    appId: currentApp.AppId,
    errorMessage,
    extraStats,
    isLoading: vouchers.voucherLoading,
    isSaving,
    isSuccess,
    isVoucherChannelEnabled: flagService.isFlagOn(state, 'voucherChannel'), // TODO - remove when voucherChannel is fully released
    isVoucherDiscoverableEnabled: flagService.isFlagOn(state, 'voucherDiscoverable'), // TODO - remove when voucherDiscoverable is fully released
    isVoucherLimitAvailabilityEnabled: flagService.isFlagOn(state, 'voucherLimitAvailability'), // TODO - remove when voucherLimitAvailability is fully released
    isVoucherValidPeriodEnabled: flagService.isFlagOn(state, 'voucherValidPeriod'), // TODO - remove when voucherValidPeriod is fully released
    match: ownProps.match,
    translate: getTranslate(state.locale),
    voucher,
    vouchersRoute,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  return {
    create: (voucher) => {
      return dispatch(createVoucher(voucher, true));
    },
    reset: () => {
      dispatch(createVoucherRequestReset());
    },
    get: (voucherId) => {
      dispatch(vouchersActions.getVoucher(voucherId));
    },
    getStats: (voucherId) => {
      dispatch(vouchersActions.getVoucherStatistics(voucherId));
    },
    update: (voucher) => {
      return dispatch(
        vouchersActions.updateVoucher(
          {
            voucherId: parseInt(ownProps.match.params.voucherId),
            voucher,
            lumpValue: voucher.LumpDiscountDetails.DiscountAmount,
          },
          true
        )
      );
    },
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(LumpDiscount);

export default EnhancedComponent;
