import { CreateVoucher, UpdateVoucher, VouchersApiFactory } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const vouchersApi = createApi(VouchersApiFactory);

const baseURL = getApiEndPoint();

export const voucherUpdateApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

export const vouchersService = {
  getVouchers,
  getVoucher,
  getVoucherStatistics,
  updateVoucher,
  create,
};

function getVouchers(
  appId: string,
  pageIndex?: number,
  pageSize?: number,
  searchCodes?: string[],
  statusSearch?: Array<'Valid' | 'Expired' | 'Used'>,
  typeSearch?: Array<'PercentageDiscount' | 'LumpDiscount' | 'AddItem' | 'CreditNote'>,
  subTypeSearch?: Array<'Custom'>,
  storeIds?: number[],
  channelRestrictions?: Array<'Ios' | 'Android' | 'Web' | 'Kiosk' | 'Pos' | 'Google'>
) {
  return vouchersApi
    .getVouchers(
      appId,
      pageIndex,
      pageSize,
      searchCodes,
      statusSearch,
      typeSearch,
      subTypeSearch,
      storeIds,
      channelRestrictions
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getVoucher(voucherId: number) {
  return vouchersApi
    .getVoucherById(voucherId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function updateVoucher(
  voucherId: number,
  voucher: UpdateVoucher,
  percentDiscountDetails?: { percentValue: number; maxDiscountAmount: number },
  lumpValue?: number
) {
  try {
    let queryString = '';

    if (percentDiscountDetails || lumpValue) {
      queryString = percentDiscountDetails
        ? `/?percentValue=${percentDiscountDetails.percentValue}&maxDiscountAmount=${percentDiscountDetails.maxDiscountAmount}`
        : `/?lumpValue=${lumpValue}`;
    }

    const incomingMessage = await voucherUpdateApi.post(
      `vouchers/${voucherId}${queryString}`,
      voucher
    );

    return incomingMessage.data.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

function getVoucherStatistics(voucherId: number) {
  return vouchersApi
    .getVoucherStatsById(voucherId, 'Daily')
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function create(appId: string, voucher: CreateVoucher) {
  try {
    const incomingMessage = await vouchersApi.createVoucher(appId, voucher);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
