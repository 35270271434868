import { MenuBulkEditTableColumns } from '../types';

//#region

export const menuBulkEditMeta: MenuBulkEditTableColumns[] = [
  {
    columnName: 'MenuElementName',
    translationId: 'Menu_item',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'InstanceCount',
    translationId: 'Instances',
    columnType: 'Integer',
    isSortable: false,
    isVisible: true,
    ordinal: 1,
  },
  {
    columnName: 'Action',
    translationId: 'Action',
    columnType: 'Link',
    isSortable: false,
    isVisible: true,
    ordinal: 2,
  },
];

//#endregion

//#region

export const menuBulkReportMeta: MenuBulkEditTableColumns[] = [
  {
    columnName: 'SectionName',
    translationId: 'Section_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'ItemName',
    translationId: 'Item_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 1,
  },
  {
    columnName: 'OptionSetName',
    translationId: 'Option_set_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 2,
  },
  {
    columnName: 'OptionSetItemName',
    translationId: 'Option_set_item_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 3,
  },
];

//#endregion
