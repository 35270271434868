import React from 'react';

import { Group, Product } from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { TranslateFunction } from 'react-localize-redux';

import { Pagination } from '@fd/ui/atoms/Pagination';
import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import { ListBox } from '@fd/ui/molecules/ListBox';

const useStyles = makeStyles((theme: Theme) => ({
  addIcon: {
    color: theme.palette.primary.main,
  },
  addedItem: {
    backgroundColor: theme.palette.grey[100],
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export type AdditionBoxProps = {
  alreadyIncludedItems: Product[] | Group[];
  canAddIncludedItems?: boolean;
  isLoading?: boolean;
  limit: number;
  listItems?: Product[] | Group[];
  noItemsMessage: string;
  onAddItem: (item: Product | Group) => void;
  onPageChange: (selected) => void;
  page: number;
  title: string;
  total: number;
  translate: TranslateFunction;
};

export const AdditionBox = (props: AdditionBoxProps): JSX.Element => {
  const {
    alreadyIncludedItems,
    canAddIncludedItems = false,
    isLoading = false,
    limit,
    listItems = [],
    noItemsMessage,
    onAddItem,
    onPageChange,
    page,
    title,
    total = 0,
    translate,
  } = props;
  const classes = useStyles();

  const handlePageChange = (event: any, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const isAlreadyIncluded = (sku: string) => {
    return alreadyIncludedItems.findIndex((x) => x.Sku === sku) !== -1;
  };

  const getSubtext = (item: Product | Group, itemCount = 0) => {
    if ('GroupType' in item) {
      if (isAlreadyIncluded(item.Sku) && itemCount > 0) {
        return translate('Used_times_on_product', { count: itemCount });
      }
      return translate('Count_modifiers', { count: item.Products?.length || 0 });
    }
    return (item as Product).Price?.toFixed(2);
  };

  const renderListItems = () => {
    const numberOfDuplicates: { [key: string]: number } = {};
    if (canAddIncludedItems) {
      alreadyIncludedItems.forEach((item) => {
        numberOfDuplicates[item.Sku] = (numberOfDuplicates[item.Sku] || 0) + 1;
      });
    }
    return (listItems as Array<Product | Group>).map((item, index) => {
      const disabled = !canAddIncludedItems && isAlreadyIncluded(item.Sku as string);
      const secondaryText = disabled
        ? translate('Already_in_group')
        : getSubtext(item, numberOfDuplicates[item.Sku]);
      const listItemStyles = clsx(classes.listItem, disabled && classes.addedItem);
      return (
        <ListItem
          className={listItemStyles}
          key={item.CatalogItemId}
          divider={index < listItems.length - 1}
        >
          <ListItemText primary={item.Name} secondary={secondaryText} />
          <ListItemSecondaryAction>
            {!disabled && (
              <IconButton
                data-fd={`add-item-${item.CatalogItemId}-button`}
                onClick={() => onAddItem(item)}
              >
                <Add className={classes.addIcon} />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonLoader
          fdKey="modifier-list-skeleton-loader"
          rows={[{ height: '340px', width: '100%' }]}
        />
      ) : (
        <ListBox
          noItemsMessage={noItemsMessage}
          title={title}
          renderListItems={renderListItems}
          isEmpty={listItems.length === 0}
        />
      )}
      <Pagination
        count={total}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={handlePageChange}
      />
    </>
  );
};
