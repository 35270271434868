import React, { ChangeEvent, useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  helperText: {
    color: theme.palette.error.main,
  },
  select: {
    height: '5rem',
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

export type ModifierMinMaxDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (min: number, max: number) => void;
  totalModifiers: number;
  translate: TranslateFunction;
};

export const ModifierMinMaxDialog = (props: ModifierMinMaxDialogProps): JSX.Element => {
  const { open, onCancel, onConfirm, totalModifiers, translate } = props;
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(1);
  const [minMenuItems, setMinMenuItems] = useState<JSX.Element[]>([]);
  const [maxMenuItems, setMaxMenuItems] = useState<JSX.Element[]>([]);
  const isValid = min <= max;
  const classes = useStyles();
  useEffect(() => {
    const newMinMenuItems: JSX.Element[] = [];
    const newMaxMenuItems: JSX.Element[] = [];
    for (let i = 0; i <= totalModifiers; i++) {
      newMinMenuItems.push(
        <MenuItem
          key={`modifiers-min-selection-${i}`}
          value={i}
          data-fd={`modifiers-min-selection-${i}`}
        >
          {i === 0 ? `${i} - optional` : i}
        </MenuItem>
      );
      if (i != 0) {
        newMaxMenuItems.push(
          <MenuItem
            key={`modifiers-max-selection-${i}`}
            value={i}
            data-fd={`modifiers-max-selection-${i}`}
          >
            {i}
          </MenuItem>
        );
      }
    }
    setMinMenuItems(newMinMenuItems);
    setMaxMenuItems(newMaxMenuItems);
    setMin(0);
    setMax(1);
  }, [totalModifiers]);

  return (
    <Dialog
      title={translate('Customers_can_select_between') as string}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="create-product-cancel"
          fdKey="create-product-cancel"
          variant="text"
          onClick={onCancel}
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          disabled={!isValid}
          fdKey="create-product-confirm"
          form="create-product-form"
          key="create-product-confirm"
          onClick={() => onConfirm(min, max)}
          type="submit"
        >
          <Translate id="Done" />
        </Button>,
      ]}
    >
      <div>
        <FormControl className={classes.select}>
          <InputLabel htmlFor="min-items">
            <Translate id="Minimum" />
          </InputLabel>
          <Select
            variant="standard"
            data-fd={'min-select'}
            value={min}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setMin(Number(e.target.value))}
            inputProps={{
              id: 'min-items',
              'data-fd': 'min-items',
            }}
          >
            {minMenuItems}
          </Select>
          {!isValid && (
            <FormHelperText className={classes.helperText} data-fd="min-greater-than-max-warning">
              <Translate id={'Max_value_exceed'} data={{ max: max }} />
            </FormHelperText>
          )}
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.select}>
          <InputLabel htmlFor="max-items">
            <Translate id="Maximum" />
          </InputLabel>
          <Select
            variant="standard"
            data-fd={'max-select'}
            value={max}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setMax(Number(e.target.value))}
            inputProps={{
              id: 'max-items',
              'data-fd': 'max-items',
            }}
          >
            {maxMenuItems}
          </Select>
        </FormControl>
      </div>
    </Dialog>
  );
};
