import React from 'react';

import { Translate } from 'react-localize-redux';
import { Route } from 'react-router';

import { toKebabCase } from '../../helpers/strings';
import RMSPage from '../RMS/components/RMSPage';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import POSPage from './POS/POSPage';

type Props = {
  path: string;
  appId?: string;
};

const PointOfSaleRoutes: React.FC<Props> = ({ path, appId }) => {
  const toPosSettingsPath = `/${appId}/sales-channels/point-of-sale?tab=settings`;

  return (
    <>
      {/* Point of Sale Home */}
      <Route exact path={`${path}/point-of-sale`} component={POSPage} />

      {/* Network Diagram */}
      <Route
        exact
        path={`${path}/point-of-sale/network-diagram`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Network_Diagram" />}
            module={RMSModules.NETWORK_DIAGRAM}
            url={`configurations/system/${RMSModules.NETWORK_DIAGRAM}`}
            includePropertyIdInUrl
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Guests */}
      <Route
        exact
        path={`${path}/point-of-sale/${RMSModules.GUESTS}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Guests" />}
            module={RMSModules.GUESTS}
            url={`configurations/system/${RMSModules.GUESTS}`}
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Print Configuration */}
      <Route
        exact
        path={`${path}/point-of-sale/${toKebabCase(RMSModules.PRINT_CONFIGURATION)}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Printer" />}
            module={RMSModules.PRINT_CONFIGURATION}
            url={`configurations/franchisors/properties/${RMSModules.PRINT_CONFIGURATION}`}
            includePropertyIdInUrl
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Float Configuration */}
      <Route
        exact
        path={`${path}/point-of-sale/${toKebabCase(RMSModules.FLOAT_CONFIGURATION)}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Float_Configuration" />}
            module={RMSModules.FLOAT_CONFIGURATION}
            url={`configurations/franchisors/properties/${RMSModules.FLOAT_CONFIGURATION}`}
            includePropertyIdInUrl
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Tags */}
      <Route
        exact
        path={`${path}/point-of-sale/${RMSModules.TAGS}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Tags" />}
            module={RMSModules.TAGS}
            url={`configurations/system/${RMSModules.TAGS}`}
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Price Bands */}
      <Route
        exact
        path={`${path}/point-of-sale/${toKebabCase(RMSModules.PRICE_BANDS)}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Price_Bands" />}
            module={RMSModules.PRICE_BANDS}
            url={`configurations/system/${RMSModules.PRICE_BANDS}`}
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Payment Methods */}
      <Route
        exact
        path={`${path}/point-of-sale/payment-methods`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Payment_Types" />}
            module={RMSModules.PAYMENT_TYPES}
            url={`configurations/system/${RMSModules.PAYMENT_TYPES}`}
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Freebie Reasons */}
      <Route
        exact
        path={`${path}/point-of-sale/${toKebabCase(RMSModules.FREEBIE_REASONS)}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Freebie_Reasons" />}
            module={RMSModules.FREEBIE_REASONS}
            url={`configurations/system/${RMSModules.FREEBIE_REASONS}`}
            toParent={toPosSettingsPath}
          />
        )}
      />

      {/* Address Mapping */}
      <Route
        exact
        path={`${path}/point-of-sale/${toKebabCase(RMSModules.ADDRESS_MAPPING)}`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Address_Mapping" />}
            module={RMSModules.ADDRESS_MAPPING}
            url={`configurations/system/${RMSModules.ADDRESS_MAPPING}`}
            toParent={toPosSettingsPath}
            includePropertyIdInUrl
          />
        )}
      />

      {/* Layout Configuration */}
      <Route
        exact
        path={`${path}/point-of-sale/layout`}
        render={() => (
          <RMSPage
            subscription={RMSSubscriptions.BASE}
            title={<Translate id="Layout_Configuration" />}
            module={RMSModules.LAYOUT_CONFIGURATION}
            url={`configurations/franchisors/properties/${RMSModules.LAYOUT_CONFIGURATION}`}
            includePropertyIdInUrl
            toParent={toPosSettingsPath}
          />
        )}
      />
    </>
  );
};

export default PointOfSaleRoutes;
