import React, { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import {
  HORIZONTAL_SPACE_CLASSNAME,
  LEFT_HORIZONTAL_SPACE_CLASSNAME,
  RIGHT_HORIZONTAL_SPACE_CLASSNAME,
  VERTICAL_SPACE_CLASSNAME,
} from './index';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  paperContainer: (props: Props) => ({
    width: '100%',
    [breakpoints.down('md')]: props.hasBorderOnMobile
      ? {
          margin: 8,
          width: 'auto',
        }
      : {
          borderRadius: 0,
          boxShadow: 'none',
          borderTop: '1px solid rgba(0, 0, 0, 0.2)',
          borderBottom: props.showBottomBorder ? '1px solid rgba(0, 0, 0, 0.2)' : undefined,
        },
  }),
}));

type Props = {
  className?: string;
  children: ReactNode;
  fluid?: boolean;
  spaceOn?: 'left' | 'right';
  hasBorderOnMobile?: boolean;
  showBottomBorder?: boolean;
  ariaLabel?: string;
};

export default function PaperContainer(props: Props) {
  const { children, className = '', fluid, spaceOn, ariaLabel } = props;
  const classes = useStyles(props);
  const rootClassName = clsx({
    [className]: true,
    [classes.paperContainer]: true,
    [VERTICAL_SPACE_CLASSNAME]: !fluid,
    [HORIZONTAL_SPACE_CLASSNAME]: !fluid && !spaceOn,
    [LEFT_HORIZONTAL_SPACE_CLASSNAME]: !fluid && spaceOn === 'left',
    [RIGHT_HORIZONTAL_SPACE_CLASSNAME]: !fluid && spaceOn === 'right',
  });

  return (
    <Paper className={rootClassName} aria-label={ariaLabel}>
      {children}
    </Paper>
  );
}
