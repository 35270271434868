import React, { useCallback, useEffect, useState } from 'react';

import List from '@mui/material/List';
import { head } from 'lodash';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import EmptyComponent from '@fd/ui/EmptyComponent';
import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import { permissionsSelector } from '../../../../selectors/permissions.selector';
import * as bankingActions from '../banking.actions';
import {
  BankAccountSummaryExtended,
  bankingSelectors,
  getIsBankAccountLoading,
} from '../banking.selectors';
import AccountListItem from '../components/AccountListItem';
import AddAccountLink from '../components/AddAccountLink';
import AddBusinessTypeModal from '../components/AddBusinessTypeModal';
import AlertStripeVerification from '../components/AlertStripeVerification';

type Props = MappedState & MappedDispatch;
const BankingList = (props: Props) => {
  const {
    accounts,
    accountsEmpty,
    bankAccountAlerts,
    currentAppId,
    getBankAccounts,
    hasEditPermission,
    isLoading,
  } = props;
  const [selectedAccount, setSelectedAccount] = useState<BankAccountSummaryExtended>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isFlipdishGlobal = currentAppId === 'flipdish-global';

  useEffect(() => {
    getBankAccounts();
  }, []);

  const handleClick = useCallback((account: BankAccountSummaryExtended) => {
    if (hasEditPermission && !account.BusinessType) {
      setSelectedAccount(account);
      setIsDialogOpen(true);
    }
  }, []);

  const showFirstAlert = head(bankAccountAlerts);

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'bankaccount.*' }}
      caption={<Translate id="Manage_how_we_get_your_money_to_you" />}
      documentTitle="Banking"
      title={<Translate id="Bank_accounts" />}
    >
      {accountsEmpty ? (
        <EmptyComponent title="no_bank_accounts_found_heading" />
      ) : (
        <>
          {!isFlipdishGlobal && showFirstAlert && (
            <AlertStripeVerification account={showFirstAlert} />
          )}
          {selectedAccount && isDialogOpen && (
            <AddBusinessTypeModal
              account={selectedAccount}
              isDialogOpen={isDialogOpen}
              setDialogOpen={setIsDialogOpen}
            />
          )}
          <PaperContainer fluid>
            <List component="nav">
              {isLoading ? (
                <LoadingListItem />
              ) : (
                accounts.map((account, index: number) => {
                  return (
                    <AccountListItem
                      account={account}
                      currentAppId={currentAppId}
                      data-fd={account.Id}
                      isLast={index + 1 === accounts.length}
                      key={account.Id}
                      onClick={handleClick}
                    />
                  );
                })
              )}
            </List>
          </PaperContainer>
        </>
      )}
      <AddAccountLink />
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['ViewBankAccounts']);
  return (state: AppState) => {
    return {
      accounts: bankingSelectors.getBankAccountsExtended(state),
      accountsEmpty: state.banking.isEmpty,
      bankAccountAlerts: bankingSelectors.getBankAccountAlerts(state),
      currentAppId: state.currentApp.AppId,
      hasEditPermission: getPermissionsSelector(state),
      isLoading: getIsBankAccountLoading(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    getBankAccounts: () => {
      dispatch(bankingActions.getBankAccounts());
    },
  };
}

export default compose<Props, {}>(connect(mapStateToPropsFactory, mapDispatchToProps))(BankingList);
