import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import Skeleton from 'react-loading-skeleton';
import { compose } from 'recompose';

import GridDivider from '../components/GridDivider';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: '16px',
      marginBottom: '16px',
      padding: '16px',
    },
    gridColumnRight: {
      marginLeft: '-10px',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    mobile: {
      marginTop: '16px',
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },
    },
    title: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '16px',
      },
    },
  });

type Props = WithStyles<typeof styles>;
const LoadingDetails: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { classes } = props;
  return (
    <div data-fd="banking-loading-details">
      <Grid container spacing={0} className={classes.container} style={{ paddingTop: '58px' }}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'35%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'58%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid className={classes.title}>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <GridDivider />

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'35%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'58%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <GridDivider />

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'60%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'36%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'30%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'36%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <GridDivider />

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'45%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'90%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'36%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <GridDivider />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        item
        xs={12}
        className={classes.container}
      >
        <Grid item md={2} xs={4} sm={2}>
          <Skeleton width={'70%'} height={24} />
        </Grid>
      </Grid>
    </div>
  );
};

export default compose<Props, any>(withStyles(styles))(LoadingDetails);
