export default function copyToClipboard(e, elementId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const field = document.getElementById(elementId) as HTMLInputElement;
    if (field) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(field.value ?? field.textContent)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(new Error(err));
          });
      } else {
        field.select();
        try {
          document.execCommand('copy');
          resolve();
        } catch (err) {
          reject(new Error(err));
        }
      }
    } else {
      const error = new Error('Element not found');
      console.error(error.message);
      reject(error);
    }
  });
}
