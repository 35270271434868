import React, { useEffect, useState } from 'react';

import { BankAccountCreate, StoreGroupExtended } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import { electronicFormatIBAN, extractBIC, extractIBAN } from 'ibantools';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import AssignStoresField from '@fd/ui/Filter/AssignStoresField';

import { storeGroupActions } from '../../../../actions/storegroup.actions';
import { getStoreGroups } from '../../../../selectors/storegroup.selector';
import Button from '../../../../ui/Button/Button';
import Spacer from '../../../../ui/Spacer';
import { bankingSelectors } from '../banking.selectors';
import { nonVATSupportedCountries } from '../constants';
import BankAddressFormFields from './fields/BankAddressFormFields';
import BankHolderAddressFormFields from './fields/BankHolderAddressFormFields';
import DynamicFormFields from './fields/DynamicFormFields';
import GridDivider from './GridDivider';
import PreventNavigation from './PreventNavigation';

export type AddAccountFormProps = {
  initialValues?: BankAccountCreate;
  submit: (changes) => Promise<void>;
  onSubmitSuccess: (flag: boolean) => void;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (
  props: AddAccountFormProps & MappedProps
): BankAccountCreate => {
  const { initialValues = {} } = props;

  const defaultState = {
    BankAddress: initialValues.BankAddress || '',
    BankCountryCode: initialValues.BankCountryCode || '',
    AccountHolderAddress: initialValues.AccountHolderAddress || '',
    AccountHolderCountryCode: initialValues.AccountHolderCountryCode || '',
    VatNumber: initialValues.VatNumber || '',
    AccountName: initialValues.AccountName || '',
    Iban: initialValues.Iban || '',
    Swift: initialValues.Swift || '',
    CurrencyCode: initialValues.CurrencyCode || BankAccountCreate.CurrencyCodeEnum.EUR,
    NationalClearingCode: initialValues.NationalClearingCode || '',
    BankName: initialValues.BankName || '',
    StoreIds: initialValues.StoreIds || [],
    BusinessType: initialValues.BusinessType || BankAccountCreate.BusinessTypeEnum.Company,
    PopulatedAccountFields: initialValues.PopulatedAccountFields || [],
  };

  return defaultState;
};

type Props = FormikProps<FormValues> & AddAccountFormProps & MappedProps & MappedDispatch;
const AddAccountForm = (props: Props) => {
  const { loadAllForApp, selectedCountry, countryFields } = props;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    loadAllForApp().then(() => setIsReady(true));
  }, []);

  return (
    <Form>
      <PreventNavigation when={props.dirty} />

      <Grid container>
        <Grid item xs={12}>
          <BankAddressFormFields />
        </Grid>
        <GridDivider />
        {isReady ? <DynamicFormFields countryFields={countryFields?.FieldDefinitions} /> : null}
        <GridDivider />
        <Grid item xs={12}>
          <BankHolderAddressFormFields
            nonVATCountry={nonVATSupportedCountries}
            country={selectedCountry.value}
          />
        </Grid>
        <GridDivider />
        <Grid item xs={12}>
          <AssignStoresField
            dataFd="banking-store-select"
            description="Assign_stores_description"
            fieldLabel="Assign_stores"
            fieldName="StoreIds"
            placeholder=""
            showValueEndAdornment
          />
        </Grid>
        <Grid item xs={12}>
          <Spacer size={16} variant="horizontal">
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  fdKey="btn-bannk-account-create"
                  variant="contained"
                  disabled={props.isSubmitting}
                >
                  <Translate id="Add_account" />
                </Button>
              </Grid>
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { banking } = state;
  const storeGroups = getStoreGroups(state);
  const currency = storeGroups.length
    ? storeGroups[0].Currency
    : StoreGroupExtended.CurrencyEnum.EUR;
  const selectedCountry = banking.selectedCountry;
  const countryFields = bankingSelectors.getCountriesWithFieldDefinitions(
    selectedCountry.value,
    state
  );
  return {
    selectedCountry,
    storeGroups,
    currency,
    countryFields,
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    loadAllForApp: () => dispatch(storeGroupActions.loadAllForApp({})),
  };
}

export default compose<Props, AddAccountFormProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik<Props, FormValues>({
    displayName: 'AddAccountForm',
    mapPropsToValues: (props) => {
      return getDefaultFormState(props);
    },
    handleSubmit: (values, formikBag) => {
      const isStripeSupportedCountry =
        formikBag.props.countryFields.SupportType === 'supported-by-stripe-cc';
      const bAccount: BankAccountCreate = {
        ...values,
        Iban: electronicFormatIBAN(values.Iban as string),
      };
      formikBag.props
        .submit(bAccount)
        .then(() => {
          formikBag.resetForm(); // reset dirty state
          formikBag.props.onSubmitSuccess(isStripeSupportedCountry);
        })
        .catch((err) => {
          formikBag.setSubmitting(false);
          if (err.message === 'could_not_create_bankaccount_bankcountry_not_supported') {
            formikBag.setFieldError('BankCountryCode', err.message);
          }
        });
    },
    validate: (values, props) => {
      const errors: { [K in keyof BankAccountCreate]: string } = {};
      if (values.Iban && values.Swift) {
        const ibanMeta = extractIBAN(values.Iban);
        const bicMeta = extractBIC(values.Swift);

        if (ibanMeta.valid && bicMeta.valid) {
          if (ibanMeta.countryCode !== bicMeta.countryCode) {
            errors.Swift = 'Invalid-input';
          }
          if (values.BankCountryCode && values.BankCountryCode !== ibanMeta.countryCode) {
            errors.BankCountryCode = 'Invalid-input';
          }
        }
      }
      return errors;
    },
  })
)(AddAccountForm);
