import React from 'react';

import { type RouteComponentProps, Route, Switch } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import Kiosk from '../SalesChannels/Kiosk';
import HomeScreenCustomisation from './Components/HomeScreenCustomisation';
import KioskCreate from './Create/KioskCreate';
import KioskEdit from './Edit/KioskEdit';
import KioskList from './KioskList';
import KioskTelemetry from './Telemetry/KioskTelemetry';

type Props = { path: RouteComponentProps['match']['path'] };

const KioskRoutes = ({ path }: Props) => {
  return (
    <ErrorBoundary identifier="kiosk">
      <Switch>
        <Route
          exact
          path={`${path}/kiosks/homescreen-customisation`}
          component={HomeScreenCustomisation}
        />
        <Route exact path={`${path}/kiosk`} component={Kiosk} />
        <Route exact path={`${path}/kiosks`} component={KioskList} />
        <Route exact path={`${path}/kiosks/new`} component={KioskCreate} />
        <Route exact path={`${path}/kiosks/:id`} component={KioskEdit} />
        <Route exact path={`${path}/kiosks/:id/telemetry`} component={KioskTelemetry} />
      </Switch>
    </ErrorBoundary>
  );
};

export default KioskRoutes;
