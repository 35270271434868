import React from 'react';

import { StoreHeader } from '@flipdish/api-client-typescript';
import { format, subYears } from 'date-fns';
import moment from 'moment';

import { PrevPeriodProps } from './components/Filters/DateRangeSelector';
import { CountProp, PeriodProps } from './types';

const prevYear = subYears(new Date(), 1);
const prevYearYYYY = format(prevYear, 'yyyy');

export const mapStoreIdtoName = (data: any[], stores: StoreHeader[]) => {
  // BE Bug: sometimes responds with 200 and returns a string when it should be a 500
  if (Array.isArray(data)) {
    return data.map((o) => {
      const StoreHeaders = stores && stores.length && stores.find((s) => s.StoreId === o.storeId);
      return {
        ...o,
        StoreName: StoreHeaders ? StoreHeaders.Name : o.storeId,
      };
    });
  } else {
    return [];
  }
};

export const presetRanges = [
  { label: 'Custom', value: 0, url: 'custom' },
  { label: 'Today', value: 1, url: 'today' },
  { label: 'Yesterday', value: 2, url: 'yesterday' },
  { label: 'Last_x_days', period: '7', value: 3, url: 'last7Days' },
  { label: 'Last_x_days', period: '30', value: 4, url: 'last30Days' },
  { label: 'Last_x_days', period: '90', value: 5, url: 'last90Days' },
  { label: 'Last_month', value: 6, url: 'previousMonth' },
  { label: 'Last_year', value: 7, url: 'previousYear' },
  { label: 'Week_to_date', value: 8, url: 'weekToDateMonday' },
  { label: 'Month_to_date', value: 9, url: 'monthToDate' },
  { label: 'Quarter_to_date', value: 10, url: 'quarterToDate' },
  { label: 'Year_to_date', value: 11, url: 'yearToDate' },
  { label: '4th_quarter', year: prevYearYYYY, value: 12, url: '4thquarter' },
  { label: '3rd_quarter', year: prevYearYYYY, value: 13, url: '3rdquarter' },
  { label: '2nd_quarter', year: prevYearYYYY, value: 14, url: '2ndquarter' },
  { label: '1st_quarter', year: prevYearYYYY, value: 15, url: '1stquarter' },
  { label: 'Last_x_days', period: '180', value: 16, url: 'last180Days' },
  { label: 'Last_x_days', period: '365', value: 17, url: 'last365Days' },
  { label: 'Lifetime', value: 18, url: 'lifetime' },
];

export const defaultBarTheme = (index) => {
  switch (Math.round(index) % 5) {
    case 1: {
      return {
        primary: { stroke: '#7480fe', fill: '#7480fe' },
        secondary: { stroke: '#7480ff', fill: 'rgba(116, 128, 255, 0.3)' },
      };
    }
    case 2: {
      return {
        primary: { stroke: '#b06cf1', fill: '#b06cf1' },
        secondary: { stroke: '#b977f3', fill: 'rgba(176, 108, 241, 0.3)' },
      };
    }
    case 3: {
      return {
        primary: { stroke: '#51cced', fill: '#51cced' },
        secondary: { stroke: '#51cced', fill: 'rgba(81, 204, 237, 0.3)' },
      };
    }
    case 4: {
      return {
        primary: { stroke: '#6598f8', fill: '#6598f8' },
        secondary: { stroke: '#6598f8', fill: 'rgba(101, 152, 248, 0.3)' },
      };
    }
    case 5: {
      return {
        primary: { stroke: '#956cf4', fill: '#956cf4' },
        secondary: { stroke: '#a077f5', fill: 'rgba(149, 108, 244, 0.3)' },
      };
    }
    default: {
      return {
        primary: { stroke: '#7480fe', fill: '#7480fe' },
        secondary: { stroke: '#7480ff', fill: 'rgba(116, 128, 255, 0.3)' },
      };
    }
  }
};

export const errorBarTheme = (index) => {
  return {
    primary: { stroke: '#ff617f', fill: '#ff617f' },
    secondary: { stroke: '#ff617f', fill: 'rgba(255, 97, 127, 0.3)' },
  };
};

export const getChartHeight = (
  currentPeriodLength: number,
  previousPeriodLength: number,
  barWidth: number
) => {
  const minLength = currentPeriodLength > 0 ? currentPeriodLength : 1;
  const base = barWidth * 2.5; // (barWidth + offset, where offset is 1.5 * barWidth)
  let height = minLength * (base + barWidth); // default

  if (previousPeriodLength) {
    height = minLength * (base + 2 * barWidth);
  }
  return height;
};

export const getChartPaddings = (currentPeriodLength, previousPeriodLength, barWidth) => {
  const minLength = currentPeriodLength > 0 ? currentPeriodLength : 1;
  const base = barWidth * 2.5; // (barWidth + offset, where offset is 1.5 * barWidth)
  let yPadding = minLength * (base + barWidth);
  let paddingTop = (minLength - 1) * 6;

  if (previousPeriodLength) {
    yPadding = minLength * (base + 2 * barWidth);
    paddingTop = (minLength - 1) * 12;
  }

  return { yPadding, paddingTop };
};

export const renderDateTick = (datum, granularity) => {
  if (granularity === 'hourly') {
    return (
      <>
        <text x={0} y={0} dy={32} textAnchor="middle" fill="#666" transform="rotate(-45)">
          {moment(datum).format('DD MMMM')}
        </text>

        <text x={0} y={0} dy={48} textAnchor="middle" fill="#666" transform="rotate(-45)">
          {moment(datum).format('h:mm A')}
        </text>
      </>
    );
  }
  if (granularity === 'daily') {
    return (
      <text x={0} y={0} dy={32} textAnchor="middle" fill="#666" transform="rotate(-45)">
        {moment(datum).format('DD MMM')}
      </text>
    );
  }
  if (granularity === 'weekly') {
    const weekStart = moment(datum).startOf('week');
    return (
      <text x={0} y={0} dy={32} textAnchor="middle" fill="#666" transform="rotate(-45)">
        {weekStart.format('MMM')}
        {" '"}
        {weekStart.format('YY')}
      </text>
    );
  }
  if (granularity === 'monthly') {
    return (
      <text x={0} y={0} dy={32} textAnchor="middle" fill="#666" transform="rotate(-45)">
        {moment(datum).format('M YYYY')}
      </text>
    );
  }
  return (
    <text x={0} y={0} dy={32} textAnchor="middle" fill="#666" transform="rotate(-45)">
      {moment(datum).format('YYYY')}
    </text>
  );
};

export const getTickValue = (tick, index, length, displayType) => {
  // Above 18 ticks, all the labels are mixed up
  const maxTickNumber = displayType === 'desktop' ? 18 : displayType === 'tablet' ? 12 : 6;
  const interval = Math.ceil(length / maxTickNumber);
  /**
   * We only display a botom axis label for every X datapoint
   * x being the best interval we can have to show up to 18 labels
   * eg: for 52 ticks (one year with a datapoint per week),
   * interval would be 3, so we show only one every 3 labels
   */
  return index % interval === 0 ? tick : null;
};

export const getPreviousPeriod = (
  event: { target: { value: number } },
  start: { moment: moment.Moment },
  end: { moment: moment.Moment },
  preset?: string
): PrevPeriodProps | undefined => {
  switch (event.target.value) {
    // None
    case 0: {
      return {
        value: 0,
        url: undefined,
        startDate: undefined,
        endDate: undefined,
      };
    }
    // Previous period
    case 10: {
      switch (preset) {
        case 'today':
        case 'yesterday': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'day'),
            endDate: moment(end.moment).add(-1, 'day'),
          };
        }
        case 'last7Days': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-7, 'day'),
            endDate: moment(end.moment).add(-7, 'day'),
          };
        }
        case 'last30Days': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-30, 'day'),
            endDate: moment(end.moment).add(-30, 'day'),
          };
        }
        case 'last90Days': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-90, 'day'),
            endDate: moment(end.moment).add(-90, 'day'),
          };
        }
        case 'last180Days': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-180, 'day'),
            endDate: moment(end.moment).add(-180, 'day'),
          };
        }
        case 'last365Days': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-365, 'day'),
            endDate: moment(end.moment).add(-365, 'day'),
          };
        }
        case 'previousMonth': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'month'),
            endDate: moment(end.moment).add(-1, 'month').endOf('month'),
          };
        }
        case 'weekToDateMonday': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'week').startOf('day'),
            endDate: moment(end.moment).add(-1, 'week').endOf('day'),
          };
        }
        case 'monthToDate': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'month').startOf('day'),
            endDate: moment(end.moment).add(-1, 'month').endOf('day'),
          };
        }
        case 'quarterToDate': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'quarter').startOf('day'),
            endDate: moment(end.moment).add(-1, 'quarter').endOf('day'),
          };
        }
        case 'yearToDate': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'year').startOf('day'),
            endDate: moment(end.moment).add(-1, 'year').endOf('day'),
          };
        }
        case 'previousYear': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'year'),
            endDate: moment(end.moment).add(-1, 'year'),
          };
        }
        case '4thquarter':
        case '3rdquarter':
        case '2ndquarter':
        case '1stquarter': {
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-1, 'quarter'),
            endDate: moment(end.moment).add(-1, 'quarter'),
          };
        }
        case 'custom': {
          const periodLength = moment(end.moment).diff(start.moment, 'd') + 1;
          return {
            value: 10,
            url: 'previousperiod',
            startDate: moment(start.moment).add(-periodLength, 'd'),
            endDate: moment(end.moment).add(-periodLength, 'd'),
          };
        }
        case 'lifetime': {
          return {
            value: 0,
            url: '',
            startDate: undefined,
            endDate: undefined,
          };
        }
        default: {
          break;
        }
      }
      return;
    }
    // Last year
    case 20: {
      const startDate = moment(start.moment).add(-1, 'y');
      const endDate = moment(end.moment).add(-1, 'y');
      return {
        value: 20,
        url: 'lastyear',
        startDate,
        endDate,
      };
    }
    default: {
      return;
    }
  }
};

export const onPresetPeriodChange = (
  value: number,
  startDate?: moment.Moment,
  includeToday = false
) => {
  let start: moment.Moment = moment();
  let end: moment.Moment = moment();
  const offset = includeToday ? 0 : 1;
  switch (value) {
    case 0: {
      break;
    }
    case 1: {
      // TODAY
      start = moment(startDate).startOf('day');
      end = moment(startDate).endOf('day');
      break;
    }
    case 2: {
      // YESTERDAY
      start = moment(startDate).add(-1, 'd').startOf('day');
      end = moment(startDate).add(-1, 'd').endOf('day');
      break;
    }
    case 3: {
      // LAST 7 DAYS
      start = moment(startDate).add(-7, 'd').startOf('day');
      end = moment(startDate).subtract(offset, 'days').endOf('day');
      break;
    }
    case 4: {
      // LAST 30 DAYS
      start = moment(startDate).add(-30, 'd').startOf('day');
      end = moment(startDate).subtract(offset, 'days').endOf('day');
      break;
    }
    case 5: {
      // LAST 90 DAYS
      start = moment(startDate).add(-90, 'd').startOf('day');
      end = moment(startDate).subtract(offset, 'days').endOf('day');
      break;
    }
    case 6: {
      // LAST MONTH
      start = moment(startDate)
        .month(moment(startDate).month() - 1)
        .startOf('month');
      end = moment(startDate)
        .month(moment(startDate).month() - 1)
        .endOf('month');
      break;
    }
    case 7: {
      // LAST YEAR
      start = moment(startDate)
        .year(moment(startDate).year() - 1)
        .startOf('year');
      end = moment(startDate)
        .year(moment(startDate).year() - 1)
        .endOf('year');
      break;
    }
    case 8: {
      // WEEK TO DATE
      start = moment(startDate).startOf('week');
      end = moment(startDate).endOf('day');
      break;
    }
    case 9: {
      // MONTH TO DATE
      start = moment(startDate).month(moment(startDate).month()).startOf('month');
      end = moment(startDate).endOf('day');
      break;
    }
    case 10: {
      // QUARTER TO DATE
      start = moment(startDate).quarter(moment(startDate).quarter()).startOf('quarter');
      end = moment(startDate).endOf('day');
      break;
    }
    case 11: {
      // YEAR TO DATE
      start = moment(startDate).year(moment(startDate).year()).startOf('year');
      end = moment(startDate).endOf('day');
      break;
    }
    case 12: {
      // 4TH QUARTER
      start = moment(startDate).add(-1, 'year').quarter(4).startOf('quarter');
      end = moment(startDate).add(-1, 'year').quarter(4).endOf('quarter');
      break;
    }
    case 13: {
      // 3RD QUARTER
      start = moment(startDate).add(-1, 'year').quarter(3).startOf('quarter');
      end = moment(startDate).add(-1, 'year').quarter(3).endOf('quarter');
      break;
    }
    case 14: {
      // 2ND QUARTER
      start = moment(startDate).add(-1, 'year').quarter(2).startOf('quarter');
      end = moment(startDate).add(-1, 'year').quarter(2).endOf('quarter');
      break;
    }
    case 15: {
      // 1ST QUARTER
      start = moment(startDate).add(-1, 'year').quarter(1).startOf('quarter');
      end = moment(startDate).add(-1, 'year').quarter(1).endOf('quarter');
      break;
    }
    case 16: {
      // LAST 180 DAYS
      start = moment(startDate).add(-180, 'd').startOf('day');
      end = moment(startDate).subtract(offset, 'days').endOf('day');
      break;
    }
    case 17: {
      // LAST 365 DAYS
      start = moment(startDate).add(-365, 'd').startOf('day');
      end = moment(startDate).subtract(offset, 'days').endOf('day');
      break;
    }
    case 18: {
      // LIFETIME
      start = moment(startDate).add(-10, 'year').startOf('year');
      end = moment(startDate).endOf('year');
      break;
    }
    default: {
      break;
    }
  }
  return { start, end };
};

export const renderPeriodString = (
  currentPeriodFilter: PeriodProps,
  previousPeriodFilter?: PeriodProps
) => {
  let period1 = '';
  let period2 = '';
  // TODO: Handle comparisons between FP and SP year / month
  if (currentPeriodFilter && currentPeriodFilter.startDate) {
    const FPisSameYear =
      moment(currentPeriodFilter.startDate).format('YYYY') ===
      moment(currentPeriodFilter.endDate).format('YYYY');
    const FPisSameMonth =
      moment(currentPeriodFilter.startDate).format('MMMM') ===
      moment(currentPeriodFilter.endDate).format('MMMM');
    const FPisSameDay =
      moment(currentPeriodFilter.startDate).format('DD') ===
      moment(currentPeriodFilter.endDate).format('DD');
    period1 = `${moment(currentPeriodFilter.startDate).format('DD MMMM YYYY')}-${moment(
      currentPeriodFilter.endDate
    ).format('DD MMMM YYYY')}`;
    if (FPisSameYear) {
      if (FPisSameMonth) {
        if (FPisSameDay) {
          period1 = `${moment(currentPeriodFilter.startDate).format('DD MMMM YYYY')}`;
        } else {
          period1 = `${moment(currentPeriodFilter.startDate).format('DD')}-${moment(
            currentPeriodFilter.endDate
          ).format('DD MMMM YYYY')}`;
          // IF ENTIRE MONTH
          if (
            moment(currentPeriodFilter.startDate)
              .startOf('day')
              .isSame(moment(currentPeriodFilter.startDate).startOf('month')) &&
            moment(currentPeriodFilter.endDate)
              .endOf('day')
              .isSame(moment(currentPeriodFilter.endDate).endOf('month'))
          ) {
            period1 = `${moment(currentPeriodFilter.startDate).format('MMMM')}`;
          }
        }
      } else {
        period1 = `${moment(currentPeriodFilter.startDate).format('DD MMMM')}-${moment(
          currentPeriodFilter.endDate
        ).format('DD MMMM YYYY')}`;
        //  IF ENTIRE YEAR
        if (
          moment(currentPeriodFilter.startDate)
            .startOf('day')
            .isSame(moment(currentPeriodFilter.startDate).startOf('year')) &&
          moment(currentPeriodFilter.endDate)
            .endOf('day')
            .isSame(moment(currentPeriodFilter.endDate).endOf('year'))
        ) {
          period1 = `${moment(currentPeriodFilter.startDate).format('YYYY')}`;
        }
      }
    }
    if (previousPeriodFilter && previousPeriodFilter.startDate) {
      const SPisSameYear =
        moment(previousPeriodFilter.startDate).format('YYYY') ===
        moment(previousPeriodFilter.endDate).format('YYYY');
      const SPisSameMonth =
        moment(previousPeriodFilter.startDate).format('MMMM') ===
        moment(previousPeriodFilter.endDate).format('MMMM');
      const SPisSameDay =
        moment(previousPeriodFilter.startDate).format('DD') ===
        moment(previousPeriodFilter.endDate).format('DD');
      period2 = ` vs ${moment(previousPeriodFilter.startDate).format('DD MMMM YYYY')}-${moment(
        previousPeriodFilter.endDate
      ).format('DD MMMM YYYY')}`;
      if (SPisSameYear) {
        if (SPisSameMonth) {
          if (SPisSameDay) {
            period2 = ` vs ${moment(previousPeriodFilter.startDate).format('DD MMMM YYYY')}`;
          } else {
            period2 = ` vs ${moment(previousPeriodFilter.startDate).format('DD')}-${moment(
              previousPeriodFilter.endDate
            ).format('DD MMMM YYYY')}`;
            // IF ENTIRE MONTH
            if (
              moment(previousPeriodFilter.startDate)
                .startOf('day')
                .isSame(moment(previousPeriodFilter.startDate).startOf('month')) &&
              moment(previousPeriodFilter.endDate)
                .endOf('day')
                .isSame(moment(previousPeriodFilter.endDate).endOf('month'))
            ) {
              period2 = ` vs ${moment(previousPeriodFilter.startDate).format('MMMM')}`;
            }
          }
        } else {
          period2 = ` vs ${moment(previousPeriodFilter.startDate).format('DD MMMM')}-${moment(
            previousPeriodFilter.endDate
          ).format('DD MMMM YYYY')}`;
          //  IF ENTIRE YEAR
          if (
            moment(previousPeriodFilter.startDate)
              .startOf('day')
              .isSame(moment(previousPeriodFilter.startDate).startOf('year')) &&
            moment(previousPeriodFilter.endDate)
              .endOf('day')
              .isSame(moment(previousPeriodFilter.endDate).endOf('year'))
          ) {
            period2 = ` vs ${moment(previousPeriodFilter.startDate).format('YYYY')}`;
          }
        }
      }
    }
  }
  return `${period1}${period2}`;
};

export const getMaxValue = (currentPeriod?: CountProp[], previousPeriod?: CountProp[]) => {
  let value = 0;
  if (currentPeriod) {
    if (previousPeriod) {
      // If we have both periods
      value = Math.max(
        currentPeriod.reduce((max, p) => (p.count && p.count > max ? p.count : max), 0),
        previousPeriod.reduce((max, p) => (p.count && p.count > max ? p.count : max), 0)
      );
    } else {
      // If we only have p1
      value = currentPeriod.reduce((max, p) => (p.count && p.count > max ? p.count : max), 0);
    }
  } else if (previousPeriod) {
    // If we only have p2
    value = previousPeriod.reduce((max, p) => (p.count && p.count > max ? p.count : max), 0);
  }
  return value;
};

enum GranularityProps {
  Hour = 'hourly',
  Day = 'daily',
  Week = 'weekly',
  Month = 'monthly',
  Year = 'yearly',
}

export const getMaxCount = (period1, period2) => {
  let value = 0;
  if (period1.Data.length) {
    if (period2.Data && period2.Data.length) {
      // If we have both periods
      value = Math.max(
        period1.Data.reduce((max, p) => (p.value > max ? p.value : max), 0),
        period2.Data.reduce((max, p) => (p.value > max ? p.value : max), 0)
      );
    } else {
      // If we only have p1
      value = period1.Data.reduce((max, p) => (p.value > max ? p.value : max), 0);
    }
  } else if (period2.Data && period2.Data.length) {
    // If we only have p2
    value = period2.Data.reduce((max, p) => (p.value > max ? p.value : max), 0);
  }
  return value;
};

export const mapDurationToGranularity = (duration: number) => {
  if (duration < 8) {
    // If duration is 1week or less
    return [GranularityProps.Hour, GranularityProps.Day];
  }
  if (duration < 31) {
    // If duration between 1week and 1month
    return [GranularityProps.Day, GranularityProps.Week, GranularityProps.Month];
  }
  if (duration < 200) {
    // If duration between 1 and ~6months
    return [GranularityProps.Day, GranularityProps.Week, GranularityProps.Month];
  }
  if (duration < 365) {
    // If duration between 6months and 1year
    return [GranularityProps.Week, GranularityProps.Month];
  }
  // If duration is one year or more
  return [GranularityProps.Week, GranularityProps.Month, GranularityProps.Year];
};

export const getCellBackgroundColor = (value: number, max: number) => {
  let leftRed;
  let leftGreen;
  let leftBlue;
  let rightRed;
  let rightGreen;
  let rightBlue;
  let offset;

  if (max === 0) {
    return '#d6e7fd';
  }

  if (value > max / 2) {
    leftRed = 134;
    leftGreen = 170;
    leftBlue = 242;

    rightRed = 125;
    rightGreen = 103;
    rightBlue = 208;

    offset = (value - max / 2) / (max / 2);
  } else {
    leftRed = 214;
    leftGreen = 231;
    leftBlue = 253;

    rightRed = 134;
    rightGreen = 170;
    rightBlue = 242;

    offset = value / (max / 2);
  }

  const resultRed = Math.round(offset * rightRed + (1 - offset) * leftRed);
  const resultGreen = Math.round(offset * rightGreen + (1 - offset) * leftGreen);
  const resultBlue = Math.round(offset * rightBlue + (1 - offset) * leftBlue);

  return `rgb(${resultRed},${resultGreen},${resultBlue})`;
};

export const calculateRegionWithPoints = (
  points: Array<{ Latitude: number; Longitude: number }>
) => {
  const p = points.filter((p) => p !== undefined);

  // Calculate new bounds so the map fits all given coordinates
  let minLat;
  let maxLat;
  let minLng;
  let maxLng;

  // Init first point
  ((point) => {
    minLat = point.Latitude;
    maxLat = point.Latitude;
    minLng = point.Longitude;
    maxLng = point.Longitude;
  })(p[0]);

  // Calculate region
  p.map((point) => {
    minLat = Math.min(minLat, point.Latitude);
    maxLat = Math.max(maxLat, point.Latitude);
    minLng = Math.min(minLng, point.Longitude);
    maxLng = Math.max(maxLng, point.Longitude);
  });

  // If there is only one store
  // we increase the bounds by 0.005% on each side
  if (minLat === maxLat) {
    minLat = 0.9995 * minLat;
    maxLat = 1.0005 * maxLat;
    minLng = 1.0005 * minLng;
    maxLng = 0.9995 * maxLng;
  }

  const bounds = {
    nw: {
      lat: minLat,
      lng: maxLng,
    },
    sw: {
      lat: minLat,
      lng: minLng,
    },
    se: {
      lat: maxLat,
      lng: minLng,
    },
    ne: {
      lat: maxLat,
      lng: maxLng,
    },
  };

  return bounds;
};
