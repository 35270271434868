import React, { useCallback, useEffect, useState } from 'react';

import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExitOutlined';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { updateCoordinates } from '../../../actions/store.actions';
import markerSvg from '../../../assets/images/marker.svg';
import { GMAP_API_KEY, libraries } from '../../../constants/map.constant';
import { createStoreSelectorById } from '../../../selectors/store.selector';
import { Button } from '../../../ui/Button';
import type { Store } from '../types';
import SearchByAddress from './SearchByAddress';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  toolbox: {
    position: 'absolute',
    right: '12px',
    bottom: '12px',
    height: '52px',
    borderRadius: '3px',
    backgroundColor: ' rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '8px',
    '& > button': {
      color: '#05149e',
    },
  },
  search: {
    position: 'absolute',
    bottom: '12px',
    left: '12px',
    backgroundColor: ' rgba(255, 255, 255, 0.8)',
  },
}));

const getMapCenter = (storeByIdData: Props['storeByIdData']) => {
  const storeCoords = storeByIdData && storeByIdData.Address && storeByIdData.Address.Coordinates;
  if (storeCoords && storeCoords.Latitude && storeCoords.Longitude) {
    return {
      lat: storeCoords.Latitude,
      lng: storeCoords.Longitude,
    };
  }
  return;
};

export interface StoreLocationMapProps {
  storeId: number;
  defaultMapCenter: { lat: number; lng: number };
  disabled?: boolean;
}
type Props = StoreLocationMapProps & MappedState & MappedDispatch;

const StoreLocationMap = ({
  defaultMapCenter,
  disabled,
  storeByIdData,
  updateCoordinatesAction,
}: Props) => {
  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY ?? '',
    libraries,
  });

  const [fullscreen, setFullscreen] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState(getMapCenter(storeByIdData) || defaultMapCenter);
  const [dirty, setDirty] = useState(false);
  const [position, setPosition] = useState(getMapCenter(storeByIdData) || defaultMapCenter);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    const listener = () => {
      const parentNode = map && (map.getDiv().parentNode as HTMLDivElement);

      if (parentNode && document.fullscreenElement === parentNode) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', listener);

    return () => {
      document.removeEventListener('fullscreenchange', listener);
    };
  }, []);

  //#region handlers
  const onMapDragEnd = () => {
    const newCenter = map?.getCenter()?.toJSON();
    newCenter && setCenter(newCenter);
  };

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    setDirty(true);
    setPosition(e.latLng?.toJSON() || { lat: 0, lng: 0 });
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    const newPosition = e.latLng?.toJSON() || { lat: 0, lng: 0 };
    setCenter(newPosition);
    setPosition(newPosition);
    setDirty(true);
  };

  const handleCancel = () => {
    const newCenter = getMapCenter(storeByIdData) || defaultMapCenter;
    setCenter(newCenter);
    setDirty(false);
    setPosition(center);
  };

  const handleSearch = (result: { lat: number; lng: number }) => {
    setCenter(result);
    setDirty(true);
    setPosition(result);
  };

  const handleSave = () => {
    if (storeByIdData) {
      const { StoreId, StoreGroupId } = storeByIdData;
      updateCoordinatesAction(StoreId, StoreGroupId, {
        Latitude: position.lat,
        Longitude: position.lng,
      });
    }
    setDirty(false);
  };
  //#endregion

  const options = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
    scaleControl: false,
    panControl: false,
    rotateControl: false,
  };

  const mapOptions = disabled ? { ...options, gestureHandling: 'none' } : options;

  return (
    <>
      {isLoaded && (
        <div className={classes.root} data-fd="map-root-div" style={{ height: '100%' }}>
          <GoogleMap
            mapContainerStyle={{ height: '100%' }}
            center={center}
            onDragEnd={disabled ? undefined : onMapDragEnd}
            onClick={handleMapClick}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
            options={{
              ...mapOptions,
              zoomControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
              },
            }}
            zoom={16}
          >
            <Marker
              draggable={disabled ? false : true}
              icon={markerSvg}
              onDragEnd={disabled ? undefined : handleMarkerDragEnd}
              position={position}
            />
          </GoogleMap>

          {!disabled && (
            <div className={classes.toolbox}>
              {dirty && (
                <>
                  <Button fdKey="Cancel" onClick={handleCancel}>
                    <Translate id="Cancel" />
                  </Button>
                  <Button fdKey="Save" onClick={handleSave}>
                    <Translate id="Save" />
                  </Button>
                </>
              )}
              <IconButton
                onClick={() => {
                  const parentNode = map?.getDiv().parentNode as HTMLDivElement;

                  if (parentNode) {
                    if (document.fullscreenElement === parentNode) {
                      document.exitFullscreen();
                    } else {
                      parentNode.requestFullscreen();
                    }
                  }
                }}
              >
                {fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
              </IconButton>
            </div>
          )}
          <div className={classes.search}>
            <SearchByAddress onSearch={handleSearch} />
          </div>
        </div>
      )}
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: StoreLocationMapProps) => {
  const storeByIdSelector = createStoreSelectorById(ownProps.storeId);
  return (state: AppState) => {
    const data = storeByIdSelector(state) as Store | undefined;
    return {
      storeByIdData: data,
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  updateCoordinatesAction: (
    StoreId: number,
    StoreGroupId: number,
    { Latitude, Longitude }: { Latitude: number; Longitude: number }
  ) => {
    dispatch(
      updateCoordinates(StoreId, StoreGroupId, {
        Latitude,
        Longitude,
      })
    );
  },
});

export default connect(mapStateToPropsFactory, mapDispatchToProps)(StoreLocationMap);
