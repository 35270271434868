import { createSelector } from 'reselect';

export const getStoreKioskSettings = createSelector([(state) => state.settings], (settings) => {
  return settings;
});

export const getKioskLogo = createSelector(
  [(state) => state.storeKioskSettings.logoUrl, (state) => state.currentApp.LogoImageUrl],
  (storeLogoUrl, logoImageUrl) => {
    return { storeLogoUrl, wlLogoUrl: logoImageUrl! };
  }
);
