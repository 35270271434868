import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { CheckboxField } from '../../../ui/atoms/CheckboxField';
import DateFilter from '../../DateFilter';
import BulkEditSearchFilter from '../../Menus/components/filters/SearchFilter';
import { customRange } from '../helpers';
import SearchBySubscriptionFilter from './SearchBySubscriptionFilter';

const useStyles = makeStyles((theme: Theme) => ({
  filtersContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  dateFilter: {
    paddingBottom: theme.spacing(2),
  },
  subscriptionContainer: { width: 'calc(100% + 24px)' },
  subscriptionFilter: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  searchContainer: {
    // MUI V4 grid container spacing
    width: 'calc(100% + 24px)',
    marginBottom: theme.spacing(1.5),
  },
}));

type InnerProps = MappedProps;
type OuterProps = {
  onDateChange: (start: moment.Moment, end: moment.Moment, prevPeriod) => void;
  setSubscription: (onChange: string) => void;
  showOnlyMyInvoicesChecked: boolean;
  setShowOnlyMyInvoicesChecked: (setShowOnlyMyInvoicesChecked: boolean) => void;
  searchInvoicesFilter: string | undefined;
  setSearchInvoicesFilter: (onChange: string) => void;
};
type Props = InnerProps & OuterProps;

const InvoiceFilters = (props: Props) => {
  const {
    onDateChange,
    searchInvoicesFilter,
    setSearchInvoicesFilter,
    setShowOnlyMyInvoicesChecked,
    setSubscription,
    showOnlyMyInvoicesChecked,
    translate,
  } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.filtersContainer}>
      <Grid container className={classes.searchContainer} m={-1.5}>
        <Grid item xs={12} md={6} p={1.5}>
          <DateFilter
            includeTodayInDatePeriod
            onChange={onDateChange}
            selectLabel={translate('Date_period') as string}
            datePeriodDefault={'last90Days'}
            isCompare={false}
            customRange={customRange}
          />
        </Grid>

        <Grid item xs={12} md={6} p={1.5} className={classes.dateFilter}>
          <BulkEditSearchFilter
            searchFilter={searchInvoicesFilter}
            setSearchFilter={setSearchInvoicesFilter}
            translate={translate}
          />
        </Grid>
      </Grid>

      <Grid container m={-1.5} className={classes.subscriptionContainer}>
        <Grid item xs={12} md={6} p={1.5} className={classes.subscriptionFilter}>
          <SearchBySubscriptionFilter onChange={setSubscription} />
        </Grid>
        <Grid
          item
          p={1.5}
          xs={12}
          md={3}
          style={{
            alignSelf: 'center',
          }}
        >
          <CheckboxField
            ariaLabel={translate('Checkbox_for_only_showing_invoices_that_you_own') as string}
            checked={showOnlyMyInvoicesChecked}
            fdKey="show-my-invoices-checked"
            label={translate('Show_only_my_invoices') as string}
            onChange={() => setShowOnlyMyInvoicesChecked(!showOnlyMyInvoicesChecked)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(InvoiceFilters);
