import React, { useEffect, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { App, Store, StoreBase, TipConfiguration } from '@flipdish/api-client-typescript';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { EnableSwitch, Typography } from '@fd/ui/atoms';
import { FormSection } from '@fd/ui/molecules';

import { storeActions } from '../../actions/store.actions';
import * as storeConstants from '../../constants/store.constants';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { permissionsSelector } from '../../selectors/permissions.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import PageLayout from '../../ui/Layout';
import HelpDrawer from '../HelpDrawer/HelpDrawer';
import AutoAcceptModal from './components/AutoAcceptModal';
import StoreTippingForm from './Tipping/StoreTippingForm';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderTop: `1px dashed ${theme.palette.grey[600]}`,
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  descriptionContainer: {
    marginBottom: theme.spacing(3),
  },
  flipdishFeatureContainer: {
    backgroundColor: '#EAF2FF',
  },
  list: {
    listStyle: 'disc',
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  listItem: {
    display: 'list-item',
    padding: 0,
    color: theme.palette.grey[700],
  },
  listText: {
    color: 'inherit',
  },
  moreLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: '#061c9d',
    cursor: 'pointer',
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}));

type InnerProps = MappedState;
type OuterProps = MappedDispatch;
type Props = InnerProps & OuterProps;
export type update = (storeId: number, store: StoreBase) => (dispatch: any) => void;

const StoreOrderSettings = (props: Props) => {
  const classes = useStyles();
  const {
    canEdit,
    getTips,
    isFlipdishStaff,
    isLoading,
    loadingError,
    onChecked,
    selectedApp,
    store,
    tipConfig,
    toggleTippingEnabled,
    translate,
    update,
    updateTips,
  } = props;
  const [autoAcceptModal, openAutoAcceptModal] = useState<boolean>(false);
  const [autoAcceptProps, setAutoAcceptProps] = useState<{
    ev: { target: { name: string; checked: boolean } };
    store: StoreBase;
    update: update;
  }>();

  useLoadStoreFromSalesChannelIdIntoRedux(store);

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_orderSettings', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (store?.StoreId) {
      getTips(store.StoreId);
    }
  }, [store]);

  const confirmAutoAccept = (e, store: StoreBase, update: update) => {
    setAutoAcceptProps({
      ev: { target: { name: e.target.name, checked: e.target.checked } },
      store,
      update,
    });
    openAutoAcceptModal(true);
  };

  const handleToggleTippingEnabled = (enabled: boolean) => {
    if (store) {
      toggleTippingEnabled(store, enabled);
    }
  };

  const handleTipsChange = (newTipConfig: TipConfiguration) => {
    if (store?.StoreId) {
      updateTips(store.StoreId, newTipConfig);
    }
  };

  if (loadingError) {
    return <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />;
  }

  const isLoadingDependencies = isLoading || !store || !tipConfig;
  return (
    <PageLayout
      toParent={
        store
          ? `/${selectedApp.AppId}/storegroups/${store.StoreGroupId}/stores/${store.StoreId}`
          : ''
      }
      documentTitle="Order_settings_title"
      caption={(store && store.Name) || ''}
      title={<Translate id="Order_settings_title" />}
    >
      <Paper className={classes.container}>
        <FormSection
          isLoading={isLoadingDependencies}
          sectionTitle={translate('Customer_notes') as string}
        >
          <FormControlLabel
            className={classes.switch}
            control={
              <EnableSwitch
                name="AllowChefNotes"
                checked={store ? store.AllowChefNotes : undefined}
                onChange={(e) => store && onChecked(e, store, update)}
                fdKey="allow_customer_notes"
                disabled={!canEdit}
              />
            }
            label={
              <Typography variant="body2">
                <Translate id="Allow_customer_notes" />
              </Typography>
            }
            labelPlacement="start"
          />
        </FormSection>
        <FormSection
          isLoading={isLoadingDependencies}
          sectionTitle={translate('Phone_numbers') as string}
        >
          <div className={classes.descriptionContainer}>
            <Typography variant="caption">
              {translate('Otp_enabled_description')}{' '}
              <HelpDrawer label="portalhelp:ordersettings-maskcustomerphonenumberonreceipts">
                <span className={classes.moreLink}>{translate('Learn_more')}</span>
              </HelpDrawer>
            </Typography>
          </div>
          <FormControlLabel
            className={classes.switch}
            control={
              <EnableSwitch
                name="GdprCustomerPhoneNumbers"
                checked={store ? store.GdprCustomerPhoneNumbers : undefined}
                onChange={(e) => store && onChecked(e, store, update)}
                fdKey="otp_enabled"
                disabled={!canEdit}
              />
            }
            label={
              <Typography variant="body2">
                <Translate id="Otp_enabled" />
              </Typography>
            }
            labelPlacement="start"
          />
        </FormSection>
        {tipConfig && (
          <FormSection isLoading={isLoadingDependencies} sectionTitle={translate('Tips') as string}>
            <StoreTippingForm
              canEdit={canEdit}
              tippingEnabled={store && store.TipsEnabled}
              tipConfig={tipConfig}
              toggleTippingEnabled={handleToggleTippingEnabled}
              onTipsChange={handleTipsChange}
            />
          </FormSection>
        )}
      </Paper>
      {isFlipdishStaff && (
        <>
          <div className={classes.divider}>
            <Typography variant="caption">
              <Translate id="Access_only_for_flipdishers_description" />
            </Typography>
          </div>
          <Paper className={classes.flipdishFeatureContainer}>
            <FormSection
              isLoading={isLoadingDependencies}
              sectionTitle={translate('Require_orders_to_be_accepted') as string}
              showDivider={false}
            >
              <Typography variant="caption">
                {translate('Require_orders_to_be_accepted_description')}{' '}
                <HelpDrawer label="portalhelp:ordersettings-requireorderstobeaccepted">
                  <span className={classes.moreLink}>{translate('Learn_more')}</span>
                </HelpDrawer>
              </Typography>
              <div>
                <EnableSwitch
                  name="AutomaticallyAcceptOrders"
                  checked={store ? !store.AutomaticallyAcceptOrders : undefined}
                  onChange={(e) =>
                    store && !store.AutomaticallyAcceptOrders
                      ? confirmAutoAccept(e, store, update)
                      : store && onChecked(e, store, update)
                  }
                  inputProps={{
                    'aria-label': translate('Require_orders_to_be_accepted') as string,
                  }}
                  fdKey="automatically_accept_orders"
                  disabled={!canEdit}
                />
              </div>
              <Typography variant="body1">
                <Translate id="OrderSettings_Only_disable_if" />
              </Typography>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_do_not_receive_orders_via_a_Flipdish_Terminal" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_do_not_receive_orders_via_any_integrations" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_do_not_have_a_device_to_receive_orders_on" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_receive_orders_through_email_exclusively" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_do_not_need_to_action_orders_immediately" />
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1">
                <Translate id="OrderSettings_Disabling_will_mean" />
              </Typography>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="All_your_orders_will_be_accepted_automatically" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="You_will_need_to_manually_check_for_new_orders" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="When_you_receive_an_order_you_cannot_fulfill" />
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Typography className={classes.listText} variant="body2">
                    <Translate id="Your_terminal_wont_auto_accept_orders" />
                  </Typography>
                </ListItem>
              </List>
            </FormSection>
          </Paper>
        </>
      )}
      {autoAcceptModal && autoAcceptProps && (
        <AutoAcceptModal
          autoAcceptProps={autoAcceptProps}
          onChecked={onChecked}
          openModal={openAutoAcceptModal}
          translate={translate}
        />
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  const update = storeActions.update(true);
  return {
    getTips: (storeId: number) => dispatch(storeActions.getTips(storeId)),
    onChecked: (event: React.ChangeEvent<HTMLInputElement>, store: Store, update: update) => {
      store &&
        store.StoreId &&
        dispatch(
          update(store.StoreId, {
            ...store,

            [event.target.name]:
              event.target.name === 'AutomaticallyAcceptOrders'
                ? !event.target.checked
                : event.target.checked,
          })
        );
    },
    toggleTippingEnabled: (store, checked) =>
      dispatch(
        update(store.StoreId as number, {
          ...store,
          TipsEnabled: checked,
        })
      ),
    updateTips: debounce(
      (storeId: number, tipConfig: TipConfiguration) =>
        dispatch(storeActions.updateTips({ storeId, tipConfig })),
      1000
    ),
  };
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const updateFunction = storeActions.update(true);
  const canEdit = state.permissions.some((p) => p === App.AppResourceSetEnum.EditStores.toString());
  const getFlipdisherPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'FlipdishStaff',
  ]);
  return {
    canEdit,
    isFlipdishStaff: getFlipdisherPermissionsSelector(state),
    isLoading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    selectedApp: state.currentApp,
    store: getSelectedStore(state),
    tipConfig: state.stores.tipConfig,
    translate: getTranslate(state.locale),
    update: updateFunction,
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  StoreOrderSettings
);
