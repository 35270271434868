import React, { useState } from 'react';

import { BluetoothTerminalStatus } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { Button } from '../../../ui/Button';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';
import { kioskServices } from '../Kiosks.services';

type InnerProps = MappedDispatch;
type OuterProps = {
  appId: string;
  deviceId?: string;
  loading: boolean;
  readerId?: string;
  terminalType?: BluetoothTerminalStatus.DeviceTypeEnum;
};
type Props = InnerProps & OuterProps;

const StripeTerminalRemoveButton = ({
  appId,
  deviceId,
  loading,
  notifyError,
  readerId,
  terminalType,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const bluetoothDevice = useMutation({
    mutationFn: () => kioskServices.unpairStripeTerminal(appId, deviceId),

    onSuccess: () => {
      setDialogOpen(false);
    },

    onError: () => {
      setDialogOpen(false);
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const internetDevice = useMutation({
    mutationFn: () => kioskServices.unregisterStripeTerminal({ appId, deviceId, readerId }),

    onSuccess: () => {
      setDialogOpen(false);
    },

    onError: (e: any) => {
      setDialogOpen(false);
      notifyError({ message: e.response?.data?.Message });
    },
  });

  switch (terminalType) {
    case BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X:
    case BluetoothTerminalStatus.DeviceTypeEnum.WISEPAD3:
      return (
        <Grid item xs={12} style={{ marginTop: -14 }}>
          {loading ? (
            <Skeleton width={'65%'} height={36} />
          ) : (
            <Button
              color="primary"
              disabled={bluetoothDevice.isPending}
              fdKey="remove_stripe_terminal_open_modal"
              onClick={() => setDialogOpen(true)}
              variant="outlined"
            >
              <Translate id="Disconnect_card_reader" />
            </Button>
          )}

          <YesNoDialog
            open={dialogOpen}
            onNo={() => setDialogOpen(false)}
            onYes={() => bluetoothDevice.mutate()}
            titleTextId={'Disconnect_stripe_terminal'}
            yesTextId={'Disconnect'}
            yesButtonVariant={'contained'}
          >
            <Typography variant="body2" style={{ maxWidth: 432 }}>
              <Translate id="Remove_stripe_terminal_modal" />
            </Typography>
          </YesNoDialog>
        </Grid>
      );

    case BluetoothTerminalStatus.DeviceTypeEnum.WISEPOSE:
      return (
        <Grid item xs={12} style={{ marginTop: -14 }}>
          {loading ? (
            <Skeleton width={'65%'} height={36} />
          ) : (
            <Button
              color="primary"
              disabled={internetDevice.isPending}
              fdKey="remove_stripe_terminal_open_modal"
              onClick={() => setDialogOpen(true)}
              variant="outlined"
            >
              <Translate id="Unregister_card_reader" />
            </Button>
          )}

          <YesNoDialog
            open={dialogOpen}
            onNo={() => setDialogOpen(false)}
            onYes={() => internetDevice.mutate()}
            titleTextId={'Unregister_stripe_terminal'}
            yesTextId={'Unregister'}
            yesButtonVariant={'contained'}
          >
            <Typography variant="body2" style={{ maxWidth: 432 }}>
              <Translate id="Remove_stripe_terminal_modal" />
            </Typography>
          </YesNoDialog>
        </Grid>
      );
    default:
      return <></>;
  }
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect<{}, InnerProps>(null, mapDispatchToProps)(StripeTerminalRemoveButton);
