import { RestApiResultVoucherWithStats, VoucherWithStats } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// #region getVoucherById
export type GetByIdProps = {
  voucherId: number;
};
export async function getById({ voucherId }: GetByIdProps): Promise<VoucherWithStats> {
  try {
    const msg = await portalApi.get<RestApiResultVoucherWithStats>(
      `/api/v1.0/vouchers/${encodeURIComponent(voucherId.toString())}`
    );

    return {
      ...msg.data.Data,
      ExpiryDate: new Date(msg.data.Data.ExpiryDate + 'Z'),
      StartDate: new Date(msg.data.Data.StartDate + 'Z'),
    };
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion
