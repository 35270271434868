import React, { useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import { permissionsSelector } from '../../../../selectors/permissions.selector';
import { getColumnsFlipdishStaffOrDefault } from '../../../Customers/helpers';
import { renderPeriodString } from '../../helpers';
import { GetTableDataOrders, getTableDataRejectedOrders } from '../../OrderReport.actions';
import { rejectedOrdersMeta } from '../../OrdersReport/OrdersMetaData';
import { OrdersTableColumns, PeriodPresetEnum, PeriodProps } from '../../types';
import OrderReportTable from './OrderReportTable';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type InnerProps = {
  currentPeriodFilter: PeriodProps;
  storeIds: number[] | string[];
  deliveryTypes: Array<'delivery' | 'collection'>;
  platforms: AppTypeEnum[];
  periodType: PeriodPresetEnum;
  vouchers: string;
  userTimezone: string;
  currency?: CurrencyEnum;
  languageCode: string;
} & MappedState &
  MappedDispatch;

type OuterProps = any;
type Props = InnerProps & RouteComponentProps<{ customerId: string }>;

const columnLocalStorageKey = 'fd-reporting-rejected-orders-columns';
const rowsPerPageLocalStorageKey = 'fd-reporting-rejected-orders-rows-per-page';

const defaultTableColumns = [
  'netAmount',
  'orderId',
  'rejectedReason',
  'requestedForTime',
  'storeName',
];

const RejectedOrdersTable = (props: Props) => {
  const {
    getTableDataRejectedOrders,
    currentPeriodFilter,
    storeIds,
    deliveryTypes,
    platforms,
    periodType,
    AppId,
    vouchers,
    userTimezone,
    currency,
    languageCode,
    rejectedOrdersTableData,
    rejectedOrdersTablePagination,
    isFlipdishStaff,
  } = props;
  const [ref, inView] = useInView({ triggerOnce: true });
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [tableRejectedOrdersColumns, setTableRejectedOrdersColumns] = useState<
    OrdersTableColumns[]
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterState, setFilterState] = useState({
    currentPeriodFilter,
    periodType,
    storeIds,
    deliveryTypes,
    platforms,
    vouchers,
    orderBy: '',
    order: 'asc',
  });
  const [loading, setLoading] = useState<undefined | boolean>();
  const [hasNewColumns, setHasNewColumns] = useState(false);

  useEffect(() => {
    if (rejectedOrdersTableData && firstRender) {
      setFirstRender(false);
    }
  }, [rejectedOrdersTableData]);

  useEffect(() => {
    const lsColumns = localStorage.getItem(columnLocalStorageKey);
    let columns = rejectedOrdersMeta;

    if (firstRender) {
      const defaultColumns = lsColumns ? JSON.parse(lsColumns) : defaultTableColumns;
      columns = columns.map((col) => {
        return {
          ...col,
          isVisible: defaultColumns.indexOf(col.columnName) !== -1,
        };
      });
    } else {
      // Handle refetch with new columns
      setHasNewColumns(true);
    }
    const colsByPermission = getColumnsFlipdishStaffOrDefault(columns, isFlipdishStaff);

    setTableRejectedOrdersColumns(colsByPermission);
  }, [inView]);

  useEffect(() => {
    const rowsPerPage = localStorage.getItem(rowsPerPageLocalStorageKey);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage, 10));
    }
  }, [inView]);

  useEffect(() => {
    if (inView && currency && !loading) {
      const storeFilter =
        Array.isArray(filterState.storeIds) && filterState.storeIds.length
          ? filterState.storeIds
          : currency;
      const params = {
        c: filterState.currentPeriodFilter,
        t: filterState.periodType,
        s: storeFilter as any,
        d: filterState.deliveryTypes,
        p: filterState.platforms,
        v: filterState.vouchers,
        pg: page,
        o: filterState.order as 'asc' | 'desc',
        ob: filterState.orderBy,
        r: rowsPerPage,
        l: languageCode,
      };
      getTableData(params);
    }
  }, [page, rowsPerPage, filterState, currency, hasNewColumns, languageCode]);

  useEffect(() => {
    if (inView && !loading) {
      setFilterState({
        ...filterState,
        currentPeriodFilter,
        periodType,
        storeIds,
        deliveryTypes,
        platforms,
        vouchers,
      });
      setPage(0);
    }
  }, [currentPeriodFilter, periodType, storeIds, deliveryTypes, platforms, vouchers, inView]);

  const getTableData = async (params: {
    c: PeriodProps;
    t: PeriodPresetEnum;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    ob?: string;
    o?: 'asc' | 'desc';
    pg?: number;
    r?: number;
    csv?: boolean;
    l?: string;
  }) => {
    try {
      setLoading(true);
      const { c, t, s, d, p, v, ob, o, pg, r, csv, l } = params;
      const lsColumns = localStorage.getItem(columnLocalStorageKey);
      // Get visible columns from localstorage, only on mount
      const columnNames =
        !hasNewColumns && lsColumns
          ? JSON.parse(lsColumns)
          : tableRejectedOrdersColumns.filter((toc) => toc.isVisible).map((toc) => toc.columnName);
      await getTableDataRejectedOrders({
        Page: pg,
        PageSize: r,
        Period: c,
        PeriodType: t,
        StoreId: s,
        DeliveryType: d,
        Platform: p,
        VoucherCodes: v,
        IANATimezone: userTimezone,
        Columns: columnNames,
        SortKey: ob,
        SortDirection: o,
        ExportAsCSV: csv,
        LanguageCode: l,
      });
      setHasNewColumns(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(new Error(err));
    }
  };

  const onSort = (orderBy: string, order: 'asc' | 'desc') => {
    if (orderBy) {
      setFilterState({ ...filterState, orderBy, order });
      setPage(0);
    }
  };

  const downloadCSV = () => {
    const storeFilter =
      Array.isArray(filterState.storeIds) && filterState.storeIds.length
        ? filterState.storeIds
        : currency;
    getTableData({
      c: currentPeriodFilter,
      t: periodType,
      s: storeFilter as any,
      d: deliveryTypes,
      p: platforms,
      v: vouchers,
      csv: true,
      l: languageCode,
    });
  };
  const onRowClick = ({ row }: { row: Reports.OrderListRow }) => {
    props.history.push(`/${AppId}/orders/${row.orderId}`);
  };
  return (
    <ErrorBoundary identifier="rejected-orders-table">
      <div ref={ref}>
        <OrderReportTable
          inView={inView}
          title="Rejected_orders"
          page={page}
          setPage={setPage}
          onAppRowClick={onRowClick}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          subTitle={renderPeriodString(currentPeriodFilter)}
          data={rejectedOrdersTableData}
          metadata={tableRejectedOrdersColumns}
          pagination={rejectedOrdersTablePagination}
          showColumnSelector
          onSort={onSort}
          AppId={AppId}
          loading={loading}
          currency={currency}
          languageCode={languageCode}
          downloadCSV={downloadCSV}
          columnLocalStorageKey={columnLocalStorageKey}
          rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
          defaultColumns={defaultTableColumns}
        />
      </div>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['FlipdishStaff']);
  return (state: AppState) => {
    const { rejectedOrdersTableData, rejectedOrdersTablePagination } = state.reports.orderReports;
    return {
      AppId: state.currentApp.AppId as string,
      translate: getTranslate(state),
      rejectedOrdersTableData,
      rejectedOrdersTablePagination,
      isFlipdishStaff: getPermissionsSelector(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getTableDataRejectedOrders: ({
    Page,
    PageSize,
    Period,
    PeriodType,
    StoreId,
    DeliveryType,
    Platform,
    VoucherCodes,
    IANATimezone,
    Columns,
    SortKey,
    SortDirection,
    ExportAsCSV,
    LanguageCode,
  }: GetTableDataOrders) =>
    dispatch(
      getTableDataRejectedOrders({
        Page,
        PageSize,
        Period,
        PeriodType,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        Columns,
        SortKey,
        SortDirection,
        ExportAsCSV,
        LanguageCode,
      })
    ),
});

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToProps)
)(RejectedOrdersTable);
