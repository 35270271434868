const htmlCharMapping = {
  "'": '&apos;',
  '"': '&quot;',
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
};

export const encodeHTML = (str?: string) => {
  return str?.replace(/['"<>&]/g, (match) => htmlCharMapping[match]) || '';
};
