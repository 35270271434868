export const vouchersConstants = {
  CLEAR_VOUCHERS: 'CLEAR_VOUCHERS',
  SET_VOUCHERS_TIMESTAMP: 'SET_VOUCHERS_TIMESTAMP',
  GET_VOUCHERS: 'GET_VOUCHERS',
  GET_VOUCHERS_SUCCESS: 'GET_VOUCHERS_SUCCESS',
  GET_VOUCHERS_SUCCESS_CONCAT: 'GET_VOUCHERS_SUCCESS_CONCAT',
  GET_VOUCHERS_FAILURE: 'GET_VOUCHERS_FAILURE',
  GET_VOUCHER_STATISTICS: 'GET_VOUCHER_STATISTICS',
  SET_VOUCHER_STATISTICS_TIMESTAMP: 'SET_VOUCHER_STATISTICS_TIMESTAMP',
  GET_VOUCHER: 'GET_VOUCHER',
  SET_VOUCHER_TIMESTAMP: 'SET_VOUCHER_TIMESTAMP',
  SET_VOUCHER_SEARCH_PARAMS: 'SET_VOUCHER_SEARCH_PARAMS',
  SET_VOUCHER_SEARCH_CODE_RESULTS: 'SET_VOUCHER_SEARCH_CODE_RESULTS',
  SET_VOUCHER_SEARCH_CUSTOM: 'SET_VOUCHER_SEARCH_CUSTOM',
  SET_VOUCHERS_APP_ID: 'SET_VOUCHERS_APP_ID',
  SET_VOUCHER_LOADING: 'SET_VOUCHER_LOADING',
  CREATE_VOUCHER: 'VREATE_VOUCHER',
  CREATE_VOUCHER_REQUEST: 'CREATE_VOUCHER_REQUEST',
  CREATE_VOUCHER_SUCCESS: 'CREATE_VOUCHER_SUCCESS',
  CREATE_VOUCHER_FAILURE: 'CREATE_VOUCHER_FAILURE',
  CREATE_VOUCHER_REQUEST_RESET: 'CREATE_VOUCHER_REQUEST_RESET',
  LOAD_VOUCHER_STORES_REQUEST: 'LOAD_VOUCHER_STORES_REQUEST',
  LOAD_VOUCHER_STORES_SUCCESS: 'LOAD_VOUCHER_STORES_SUCCESS',
  LOAD_VOUCHER_STORES_FAILURE: 'LOAD_VOUCHER_STORES_FAILURE',
  UPDATE_VOUCHER_SUCCESS: 'UPDATE_VOUCHER_SUCCESS',
  UPDATE_VOUCHER_FAILURE: 'UPDATE_VOUCHER_FAILURE',
};
