import {
  BankAccountCreatedEvent,
  BankAccountDeletedEvent,
  BankAccountUpdatedEvent,
  LoyaltyCampaignCreatedEvent,
  LoyaltyCampaignDeletedEvent,
  LoyaltyCampaignUpdatedEvent,
  OrderSummary,
  RetentionCampaignCreatedEvent,
  RetentionCampaignDeletedEvent,
  RetentionCampaignUpdatedEvent,
} from '@flipdish/api-client-typescript';
import merge from 'lodash/merge';
import { createAction } from 'redux-act';

import { action } from '../actions/utils';
import { hubConstants, hubNames } from '../signalr/hub.constants';
import {
  accountEventConstants,
  analyticsEventConstants,
  bankEventConstants,
  customerEventConstants,
  kioskCardReaderConstants,
  loyaltyCampaignConstants,
  orderEventConstants,
  phoneCallEventConstants,
  retentionCampaignConstants,
  storeEventConstants,
  storeGroupEventConstants,
  teammateEventConstants,
  voucherEventConstants,
} from '../signalr/hub.events';
import {
  accountEventHandlers,
  analyticsEventHandlers,
  bankEventHandlers,
  customerEventHandlers,
  kioskCardReaderEventHandlers,
  orderEventHandlers,
  phoneCallEventHandlers,
  StoreDeliveryZoneCreated,
  StoreDeliveryZoneDeleted,
  StoreDeliveryZoneUpdated,
  storeEventHandlers,
  storeGroupEventHandlers,
  StoreOpeningHoursUpdated,
  teammateEventHandlers,
  voucherEventHandlers,
} from '../signalr/hub.handlers';

export type IDeliveryZoneCreatedEventAction = StoreDeliveryZoneCreated;
export type IDeliveryZoneUpdateEventAction = StoreDeliveryZoneUpdated;
export type IDeliveryZoneDeleteEventAction = StoreDeliveryZoneDeleted;
export type IStoreOpeningHoursUpdatedEvent = StoreOpeningHoursUpdated;

export const AnalyticsEvents = {
  Subscribe: {
    Client: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ANALYTICS_HUB,
      eventName: analyticsEventConstants.CLIENT,
      eventHandler: analyticsEventHandlers.client,
    },
  },
  Unsubscribe: {
    Client: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ANALYTICS_HUB,
      eventName: analyticsEventConstants.CLIENT,
    },
  },
};

// region Account
export const AccountEvents = {
  Subscribe: {
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ACCOUNT_HUB,
      eventName: accountEventConstants.UPDATED,
      eventHandler: accountEventHandlers.updated,
    },
  },
  Unsubscribe: {
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ACCOUNT_HUB,
      eventName: accountEventConstants.UPDATED,
      eventHandler: accountEventHandlers.updated,
    },
  },
};

export const CustomerEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.CREATED,
      eventHandler: customerEventHandlers.created,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.UPDATED,
      eventHandler: customerEventHandlers.updated,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.CONSENT_UPDATED,
      eventHandler: customerEventHandlers.consent_updated,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.CREATED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.UPDATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.CUSTOMER_HUB,
      eventName: customerEventConstants.CONSENT_UPDATED,
    },
  },
};

// #region Orders

export type OrderCreated = ReturnType<typeof orderCreated>;
export const orderCreated = (orders: OrderSummary[]) =>
  action(orderEventConstants.CREATED, {
    orders,
  });

export type OrderRejected = ReturnType<typeof orderRejected>;
export const orderRejected = (orders: OrderSummary[]) =>
  action(orderEventConstants.REJECTED, {
    orders,
  });
export type OrderRefunded = ReturnType<typeof orderRefunded>;
export const orderRefunded = (orders: OrderSummary[]) =>
  action(orderEventConstants.REFUNDED, {
    orders,
  });

export type OrderAccepted = ReturnType<typeof orderAccepted>;
export const orderAccepted = (orders: OrderSummary[]) =>
  action(orderEventConstants.ACCEPTED, {
    orders,
  });

export const OrderEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.CREATED,
      eventHandler: orderEventHandlers.created,
    },
    Accepted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.ACCEPTED,
      eventHandler: orderEventHandlers.accepted,
    },
    Refunded: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.REFUNDED,
      eventHandler: orderEventHandlers.refunded,
    },
    Rejected: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.REJECTED,
      eventHandler: orderEventHandlers.rejected,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.CREATED,
    },
    Accepted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.ACCEPTED,
    },
    Refunded: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.REFUNDED,
    },
    Rejected: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.ORDER_HUB,
      eventName: orderEventConstants.REJECTED,
    },
  },
};

// #endregion

export const StoreEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.CREATED,
      eventHandler: storeEventHandlers.created,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.UPDATED,
      eventHandler: storeEventHandlers.updated,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELETED,
      eventHandler: storeEventHandlers.deleted,
    },
    AddressUpdate: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.ADDRESS_UPDATED,
      eventHandler: storeEventHandlers.addressUpdated,
    },
    DeliveryZoneCreated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_CREATED,
      eventHandler: storeEventHandlers.deliveryZoneCreated,
    },
    DeliveryZoneUpdated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_UPDATED,
      eventHandler: storeEventHandlers.deliveryZoneUpdated,
    },
    DeliveryZoneDeleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_DELETED,
      eventHandler: storeEventHandlers.deliveryZoneDeleted,
    },
    OpeningHoursUpdated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.OPENING_HOURS_UPDATED,
      eventHandler: storeEventHandlers.openingHoursUpdated,
    },
    BusinessHoursOverrideCreated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.BUSINESS_HOURS_OVERRIDES_CREATED,
      eventHandler: storeEventHandlers.openingHoursOverrideCreated,
    },
    BusinessHoursOverrideDeleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.BUSINESS_HOURS_OVERRIDES_DELETED,
      eventHandler: storeEventHandlers.openingHoursOverrideDeleted,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.CREATED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.UPDATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELETED,
    },
    AddressUpdate: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.ADDRESS_UPDATED,
      eventHandler: storeEventHandlers.addressUpdated,
    },
    DeliveryZoneCreated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_CREATED,
    },
    DeliveryZoneUpdated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_UPDATED,
    },
    DeliveryZoneDeleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.DELIVERY_ZONE_DELETED,
    },
    OpeningHoursUpdated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.OPENING_HOURS_UPDATED,
    },
    BusinessHoursOverrideCreated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.BUSINESS_HOURS_OVERRIDES_CREATED,
    },
    BusinessHoursOverrideDeleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_HUB,
      eventName: storeEventConstants.BUSINESS_HOURS_OVERRIDES_DELETED,
    },
  },
};

export const StoreGroupEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.CREATED,
      eventHandler: storeGroupEventHandlers.created,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.UPDATED,
      eventHandler: storeGroupEventHandlers.updated,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.DELETED,
      eventHandler: storeGroupEventHandlers.deleted,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.CREATED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.UPDATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.STORE_GROUP_HUB,
      eventName: storeGroupEventConstants.DELETED,
    },
  },
};

export const TeammateEvents = {
  Subscribe: {
    InviteSent: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.INVITE_SENT,
      eventHandler: teammateEventHandlers.inviteSent,
    },
    InviteAccepted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.INVITE_ACCEPTED,
      eventHandler: teammateEventHandlers.inviteAccepted,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.UPDATED,
      eventHandler: teammateEventHandlers.updated,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.DELETED,
      eventHandler: teammateEventHandlers.deleted,
    },
  },
  Unsubscribe: {
    InviteSent: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.INVITE_SENT,
    },
    InviteAccepted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.INVITE_ACCEPTED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.UPDATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.TEAMMATE_HUB,
      eventName: teammateEventConstants.DELETED,
    },
  },
};

export const PhoneCallEvents = {
  Subscribe: {
    Started: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.PHONECALL_HUB,
      eventName: phoneCallEventConstants.STARTED,
      eventHandler: phoneCallEventHandlers.started,
    },
    Ended: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.PHONECALL_HUB,
      eventName: phoneCallEventConstants.ENDED,
      eventHandler: phoneCallEventHandlers.ended,
    },
  },
  Unsubscribe: {
    Started: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.PHONECALL_HUB,
      eventName: phoneCallEventConstants.STARTED,
    },
    Ended: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.PHONECALL_HUB,
      eventName: phoneCallEventConstants.ENDED,
    },
  },
};

export const VoucherEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.CREATED,
      eventHandler: voucherEventHandlers.created,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.DELETED,
      eventHandler: voucherEventHandlers.deleted,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.UPDATED,
      eventHandler: voucherEventHandlers.updated,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.CREATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.DELETED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.VOUCHER_HUB,
      eventName: voucherEventConstants.UPDATED,
    },
  },
};

// #region Bank
export type BankAccountCreated = ReturnType<typeof bankAccountCreated>;
export const bankAccountCreated = (bankAccount: BankAccountCreatedEvent['BankAccount']) =>
  action(bankEventConstants.CREATED, {
    bankAccount,
  });

export type BankAccountDeleted = ReturnType<typeof bankAccountDeleted>;
export const bankAccountDeleted = (bankAccount: BankAccountDeletedEvent['BankAccount']) =>
  action(bankEventConstants.DELETED, {
    bankAccount,
  });

export type BankAccountUpdated = ReturnType<typeof bankAccountUpdated>;
export const bankAccountUpdated = (bankAccount: BankAccountUpdatedEvent['BankAccount']) =>
  action(bankEventConstants.UPDATED, {
    bankAccount,
  });

export const BankEvents = {
  Subscribe: {
    Created: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.CREATED,
      eventHandler: bankEventHandlers.created,
    },
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.UPDATED,
      eventHandler: bankEventHandlers.updated,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.DELETED,
      eventHandler: bankEventHandlers.deleted,
    },
  },
  Unsubscribe: {
    Created: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.CREATED,
    },
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.UPDATED,
    },
    Deleted: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.BANK_HUB,
      eventName: bankEventConstants.DELETED,
    },
  },
};

// #endregion

//#region Loyalty Campaigns
export const createSignalAction = (eventKey, eventName, eventHandler, hubName): TSignalAction => {
  return {
    Subscribe: {
      [eventKey]: {
        type: hubConstants.SIGNALR_SUBSCRIBE,
        hubName,
        eventName,
        eventHandler,
      },
    },
    Unsubscribe: {
      [eventKey]: {
        type: hubConstants.SIGNALR_UNSUBSCRIBE,
        hubName,
        eventName,
      },
    },
  };
};
type TSignalAction = {
  Subscribe: Record<string, TSubscribeAction>;
  Unsubscribe: Record<string, TUnsubscribeAction>;
};

type TSubscribeAction = {
  type: hubConstants.SIGNALR_SUBSCRIBE;
  hubName: hubNames;
  eventName: string;
  eventHandler: (store: any) => (data: any) => void;
};

type TUnsubscribeAction = {
  type: hubConstants.SIGNALR_UNSUBSCRIBE;
  hubName: hubNames;
  eventName: string;
};

export const campaignSignalActions = {
  loyaltyCampaignCreated: createAction<LoyaltyCampaignCreatedEvent>(
    loyaltyCampaignConstants.CREATED
  ),
  loyaltyCampaignUpdated: createAction<LoyaltyCampaignUpdatedEvent>(
    loyaltyCampaignConstants.UPDATED
  ),
  loyaltyCampaignDeleted: createAction<LoyaltyCampaignDeletedEvent>(
    loyaltyCampaignConstants.DELETED
  ),
  retentionCampaignCreated: createAction<RetentionCampaignCreatedEvent>(
    retentionCampaignConstants.CREATED
  ),
  retentionCampaignUpdated: createAction<RetentionCampaignUpdatedEvent>(
    retentionCampaignConstants.UPDATED
  ),
  retentionCampaignDeleted: createAction<RetentionCampaignDeletedEvent>(
    retentionCampaignConstants.DELETED
  ),
};

const createCampaignSignalEvent = (eventKey, eventName, callback) =>
  createSignalAction(eventKey, eventName, callback, hubNames.CAMPAIGN_HUB);

export const LoyaltyCampaignEvents = merge(
  createCampaignSignalEvent(
    'Created',
    loyaltyCampaignConstants.CREATED,
    (store) => (data: LoyaltyCampaignCreatedEvent) =>
      store.dispatch(campaignSignalActions.loyaltyCampaignCreated(data))
  ),

  createCampaignSignalEvent(
    'Updated',
    loyaltyCampaignConstants.UPDATED,
    (store) => (data: LoyaltyCampaignUpdatedEvent) =>
      store.dispatch(campaignSignalActions.loyaltyCampaignUpdated(data))
  ),

  createCampaignSignalEvent(
    'Deleted',
    loyaltyCampaignConstants.DELETED,
    (store) => (data: LoyaltyCampaignDeletedEvent) =>
      store.dispatch(campaignSignalActions.loyaltyCampaignDeleted(data))
  )
) as TSignalAction;

export const RetentionCampaignEvents = merge(
  createCampaignSignalEvent(
    'Created',
    retentionCampaignConstants.CREATED,
    (store) => (data: RetentionCampaignCreatedEvent) =>
      store.dispatch(campaignSignalActions.retentionCampaignCreated(data))
  ),

  createCampaignSignalEvent(
    'Updated',
    retentionCampaignConstants.UPDATED,
    (store) => (data: RetentionCampaignUpdatedEvent) =>
      store.dispatch(campaignSignalActions.retentionCampaignUpdated(data))
  ),

  createCampaignSignalEvent(
    'Deleted',
    retentionCampaignConstants.DELETED,
    (store) => (data: RetentionCampaignDeletedEvent) =>
      store.dispatch(campaignSignalActions.retentionCampaignDeleted(data))
  )
) as TSignalAction;
//#endregion

//#region Kiosk card readers
export const KioskCardReaderEvents = {
  Subscribe: {
    Updated: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.KIOSK_CARDREADER_HUB,
      eventName: kioskCardReaderConstants.UPDATED,
      eventHandler: kioskCardReaderEventHandlers.updated,
    },
    Unpaired: {
      type: hubConstants.SIGNALR_SUBSCRIBE,
      hubName: hubNames.KIOSK_CARDREADER_HUB,
      eventName: kioskCardReaderConstants.UNPAIRED,
      eventHandler: kioskCardReaderEventHandlers.unpaired,
    },
  },
  Unsubscribe: {
    Updated: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.KIOSK_CARDREADER_HUB,
      eventName: kioskCardReaderConstants.UPDATED,
    },
    Unpaired: {
      type: hubConstants.SIGNALR_UNSUBSCRIBE,
      hubName: hubNames.KIOSK_CARDREADER_HUB,
      eventName: kioskCardReaderConstants.UNPAIRED,
    },
  },
};
//#endregion
