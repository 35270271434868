import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();

const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export async function Heartbeat() {
  try {
    const incomingMessage = await portalApi.head(`/api/v1.0/heartbeat`);
    return incomingMessage.data.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
