import { TField } from '../TField';
import { CAMPAIGN_FIELDS, TCampaignFieldNames } from './campaign-fields';
import { ENTITLEMENT_FIELDS, TEntitlementFieldNames } from './entitlement-fields';
import { NOTIFICATION_FIELDS, TNotificationFieldNames } from './notification-fields';

export type TFieldName = TCampaignFieldNames | TNotificationFieldNames | TEntitlementFieldNames;

export const FIELDS: Record<TFieldName, TField> = {
  ...NOTIFICATION_FIELDS,
  ...CAMPAIGN_FIELDS,
  ...ENTITLEMENT_FIELDS,
};
