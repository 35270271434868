import React from 'react';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Translate } from 'react-localize-redux';
import {
  type VictoryTooltipProps,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import Spacer from '../../../ui/Spacer';
import { StoreWithNetSales } from '../selectors';
import { ChartSkeleton } from './StoreDetailsCardLoading';

type NetSalesChartProps = {
  loaded: boolean;
  data?: StoreWithNetSales;
};

const CustomLabel = (props: VictoryTooltipProps & { displayType: string }) => {
  return (
    <VictoryTooltip
      {...props}
      cornerRadius={1}
      pointerLength={5}
      flyoutPadding={
        props.displayType !== 'desktop'
          ? { top: 0, bottom: 0, left: 8, right: 8 }
          : { top: 4, bottom: 4, left: 8, right: 8 }
      }
      flyoutStyle={{
        fill: 'rgba(255, 255, 255, 0.95)',
        strokeWidth: 0.3,
      }}
      style={{
        fontSize: props.displayType === 'mobile' ? 18 : props.displayType === 'tablet' ? 12 : 6,
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.4px',
        color: 'rgba(0, 0, 0, 0.6)',
      }}
      constrainToVisibleArea
    />
  );
};

type Props = NetSalesChartProps;

type DisplayType = 'desktop' | 'tablet' | 'mobile';

const useDisplayType = (): DisplayType => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet';
};

const getChartHeight = (displayType: DisplayType) => (displayType === 'desktop' ? 130 : 160);

const StoreNetSalesLineChart: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, loaded } = props;
  const displayType = useDisplayType();

  if (!data) {
    return <ChartSkeleton loaded={loaded} />;
  }

  const maxValue = Math.ceil(Math.max(data.NetSales.CurrWeekMax, data.NetSales.PrevWeekMax)) * 1.1;

  return (
    <Spacer size={16}>
      <Typography variant="h5">
        {data.NetSales.formatCurrency(data.NetSales.CurrWeekTotal)}
        <Typography component="span" variant="body2" display="inline">
          {' '}
          <Translate id="Total_sales_for_past_7_days" />
        </Typography>
      </Typography>

      <VictoryChart
        height={getChartHeight(displayType)}
        width={500}
        scale={{ x: 'time' }}
        padding={{
          top: displayType === 'desktop' ? 8 : 0,
          bottom: displayType === 'desktop' ? 32 : 8,
          left: 8,
          right: displayType === 'desktop' ? 48 : 8,
        }}
        domainPadding={{ y: [8, 24] }}
        containerComponent={<VictoryVoronoiContainer portalZIndex={1250} />}
      >
        <VictoryAxis
          tickFormat={(t, i) =>
            displayType !== 'mobile' ? data.NetSales.CurrWeek[i].Date.format('DD MMM') : ''
          }
          style={{
            axis: { stroke: 'rgba(0, 0, 0, 0.6)', strokeWidth: displayType !== 'mobile' ? 0.3 : 0 },
            tickLabels:
              displayType !== 'mobile'
                ? {
                    fontSize: displayType === 'desktop' ? 6 : 8,
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.2px',
                  }
                : {},
          }}
          orientation="bottom"
          tickLabelComponent={<VictoryLabel dx={10} />}
        />
        <VictoryAxis
          dependentAxis
          minDomain={{ x: 0, y: 0 }}
          maxDomain={{ x: 0, y: maxValue }}
          tickCount={4}
          tickFormat={(v) => (displayType !== 'mobile' ? data.NetSales.formatCurrency(v) : '')}
          tickLabelComponent={<VictoryLabel dy={4} />}
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'rgba(0, 0, 0, 0.3)', strokeWidth: 0.3 },
            tickLabels:
              displayType !== 'mobile'
                ? {
                    fontSize: displayType === 'desktop' ? 6 : 8,
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.2px',
                  }
                : {},
          }}
          orientation="right"
        />

        <VictoryGroup>
          <VictoryLine
            style={{
              data: {
                stroke: '#9ba1d8',
                strokeWidth: displayType !== 'mobile' ? 0.5 : 4,
                strokeDasharray: '3,5',
              },
              labels: { fontSize: 0 },
            }}
            x="Date, index"
            y="Value"
            data={data.NetSales.PrevWeek}
            /**
             * The animation props causes the linechart to jump when hovering on it
             * We disabled it to remove the issue while finding a better fix
             */
            // animate={{ delay: 500, duration: 1500, onLoad: { duration: 1000 } }}
            labels={(d) => data.NetSales.formatCurrency(d.Value)}
          />

          <VictoryLine
            style={{
              data: { stroke: '#4f94f7', strokeWidth: displayType !== 'mobile' ? 1 : 4 },
              labels: { fontSize: 0 },
            }}
            x="Date, index"
            y="Value"
            data={data.NetSales.CurrWeek}
            /**
             * The animation props causes the linechart to jump when hovering on it
             * We disabled it to remove the issue while finding a better fix
             */
            // animate={{ delay: 500, duration: 1500, onLoad: { duration: 1000 } }}
          />
          <VictoryScatter
            data={data.NetSales.PrevWeek}
            style={{
              data: {
                fill: ({ active }) => (active ? '#9ba1d8' : ''),
                stroke: ({ active }) => (active ? '#9ba1d8' : ''),
                strokeWidth: ({ active }) => (active ? 1 : 0),
              },
            }}
            size={({ active }) => (active ? 1 : 0)}
            x="Date, index"
            y="Value"
            labels={({ datum }) =>
              `${datum.Date.format('DD MMM')}: ${data.NetSales.formatCurrency(datum.Value)}`
            }
            labelComponent={<CustomLabel displayType={displayType} />}
          />
          <VictoryScatter
            data={data.NetSales.CurrWeek}
            style={{
              data: {
                fill: ({ active }) => (active ? '#4f94f7' : ''),
                stroke: ({ active }) => (active ? '#4f94f7' : ''),
                strokeWidth: ({ active }) => (active ? 1 : 0),
              },
            }}
            size={({ active }) => (active ? 1 : 0)}
            x="Date, index"
            y="Value"
            labels={({ datum }) =>
              `${datum.Date.format('DD MMM')}: ${data.NetSales.formatCurrency(datum.Value)}`
            }
            labelComponent={<CustomLabel displayType={displayType} />}
          />
        </VictoryGroup>
      </VictoryChart>
    </Spacer>
  );
};

export default StoreNetSalesLineChart;
