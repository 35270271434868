import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { clsx } from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, Redirect, withRouter } from 'react-router';
import { compose } from 'recompose';

import useRequestSnackBar, {
  useRequestSnackBarIndicators,
} from '../../../custom-hooks/useRequestSnackBar';
import { formatCalendarDate } from '../../../helpers/dateFormats';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import PageLayout from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import GridDivider from '../../AppStore/components/AppStoreAppConfigForm/GridDivider';
import UnregisterDeviceModal from '../Components/UnregisterDeviceModal';
import { TERMINAL_UNREGISTER, unregisterTerminal } from '../Devices.actions';

const unregisteringSelector = createLoadingSelector([TERMINAL_UNREGISTER]);

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  content: {
    marginBottom: spacing(1),
  },
  row: {
    paddingTop: spacing(4),

    '&:first-child': {
      paddingTop: 0,
    },
  },
  storesLabel: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

type InnerProps = MappedState & RouteComponentProps<{ id: string }> & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const DeviceEdit = (props: Props) => {
  const classes = useStyles();
  const { device, appId, unregister, processing, error, dtUtils, translate } = props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const parentUrl = `/${appId}/terminals`;
  const { success, failure } = useRequestSnackBarIndicators(processing, error);
  const localUserTime =
    device &&
    device.LastPollUtc &&
    dtUtils.utcToZonedTime(device.LastPollUtc, dtUtils.userIanaTimeZone);

  useRequestSnackBar({
    success,
    failure,
    failureMessage: error,
    autoSaving: processing,
  });

  if (!device) {
    return <Redirect to={parentUrl} />;
  }

  return (
    <PageLayout title={device.DeviceName} toParent={parentUrl} documentTitle="Terminal">
      <PaperContainer className={classes.content}>
        <GridContainer className={classes.row}>
          <Grid item md={3} className={clsx(classes.storesLabel, classes.gridItem)}>
            <Translate id="Store" />
          </Grid>
          <Grid item md={9} className={classes.gridItem}>
            <span>{(device.StoreNames || []).map((item) => item.StoreName).join(', ')}</span>
          </Grid>
        </GridContainer>

        <GridContainer className={classes.row}>
          <Grid item md={3} className={classes.gridItem}>
            <Translate id="Device_ID" />
          </Grid>
          <Grid item md={9} className={classes.gridItem}>
            <span>{device.DeviceId}</span>
          </Grid>
        </GridContainer>

        <GridContainer className={classes.row}>
          <Grid item md={3} className={classes.gridItem}>
            <Translate id="Serial_number" />
          </Grid>
          <Grid item md={9} className={classes.gridItem}>
            <span>{device.SerialNumber}</span>
          </Grid>
        </GridContainer>

        <GridContainer className={classes.row}>
          <Grid item md={3} className={classes.gridItem}>
            <Translate id="Last_polled" />
          </Grid>
          <Grid item md={9} className={classes.gridItem}>
            <span>{localUserTime && formatCalendarDate(dtUtils, localUserTime, translate)}</span>
          </Grid>

          <GridDivider />

          <Grid item xs={8} className={classes.gridItem}>
            <LoadingButton
              fdKey="unregister_terminal"
              color="secondary"
              onClick={() => setDialogOpen(true)}
              loading={processing}
            >
              <Translate id="Unregister_terminal" />
            </LoadingButton>
          </Grid>
        </GridContainer>
      </PaperContainer>

      {device && device.DeviceId ? (
        <UnregisterDeviceModal
          id={device.DeviceId}
          isDialogOpen={isDialogOpen}
          setDialogOpen={setDialogOpen}
          isLoading={processing}
          submit={unregister}
        />
      ) : null}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return {
    error: state.devices.error,
    appId: state.currentApp.AppId,
    processing: unregisteringSelector(state),
    translate: getTranslate(state.locale),
    dtUtils: dateTimeUtils(state),
    device:
      state.devices.printers.find((item) => item.DeviceId === id) ||
      state.devices.terminals.find((item) => item.DeviceId === id),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  const {
    match: {
      params: { id },
    },
  } = ownProps;

  return {
    unregister: () => {
      if (id !== undefined) {
        dispatch(unregisterTerminal(id));
      }
    },
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DeviceEdit);
