import React, { ChangeEvent, MouseEvent } from 'react';

import { WebhookSubscription } from '@flipdish/api-client-typescript';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import TablePagination from '../../common/TablePagination/TablePagination';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    fontSize: '12px',
    lineHeight: 1.33,
    padding: 0,
    margin: `0 0 ${theme.spacing(2)}`,
  },
  listItem: {
    display: 'flex',
    padding: `20px ${theme.spacing(2)} 12px`,
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  mainMobileContent: {
    flexGrow: 1,
  },
  uri: {
    fontSize: '16px',
    lineHeight: 1.5,
    marginBottom: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subList: {
    margin: 0,
    '& dt': {
      float: 'left',
    },
  },
  copy: {
    position: 'relative',
    cursor: 'pointer',
    '& span:nth-child(2)': {
      display: 'none',
      color: '#05149e',
    },
    '&:hover': {
      '& span:nth-child(1)': {
        visibility: 'hidden',
      },
      '& span:nth-child(2)': {
        position: 'absolute',
        top: '50%',
        left: `${theme.spacing(3)}`,
        transform: 'translateY(-50%)',
        display: 'block',
      },
      [theme.breakpoints.down('md')]: {
        '& span:nth-child(2)': {
          left: 0,
        },
      },
    },
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  icon: {
    marginRight: '12px',
    color: 'rgba(0, 0, 0, 0.54);',
  },
}));

type Props = {
  data: WebhookSubscription[];
  TotalRecordCount: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  copyHandler: (id: number) => void;
  handleClick: (e, item) => void;
};

export default function OAuthAppWebhooksMoileList({
  data,
  TotalRecordCount,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  copyHandler,
  handleClick,
}: Props) {
  const classes = useStyles();

  return (
    <Hidden mdUp>
      <ul className={classes.list}>
        {data.map((item) => {
          return (
            <li className={`${classes.listItem} ${HORIZONTAL_SPACE_CLASSNAME}`} key={item.Id}>
              <div className={classes.mainMobileContent}>
                <span>
                  <Translate id="Callback_url" />
                </span>
                <div
                  className={`${classes.uri} ${classes.copy}`}
                  onClick={() => copyHandler(item.Id as number)}
                >
                  <span>{item.CallbackUrl}</span>
                  <span>
                    <Translate id="Copy_to_clipboard" />
                  </span>
                  <input
                    id={`Webhook_Callback_url${item.Id}`}
                    type="hidden"
                    value={item.CallbackUrl}
                  />
                </div>
                <dl className={classes.subList}>
                  <dt>
                    <Translate id="Owner_user_id" />:
                  </dt>
                  <dd>{item.OwnerUserId}</dd>
                  <dt>
                    <Translate id="Verify_token" />:
                  </dt>
                  <dd>{item.VerifyToken}</dd>
                  <dt>
                    <Translate id="Version" />:
                  </dt>
                  <dd>{item.Version}</dd>
                  <dt>
                    <Translate id="Status" />:
                  </dt>
                  <dd>
                    <Translate id={item.Enabled ? 'Enabled' : 'Disabled'} />
                  </dd>
                </dl>
              </div>
              <div>
                <IconButton
                  aria-label="More"
                  // aria-owns={open ? 'long-menu' : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick(e, item)}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
              <Divider />
            </li>
          );
        })}
      </ul>
      <TablePagination
        component="div"
        labelRowsPerPage=""
        count={TotalRecordCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Hidden>
  );
}
