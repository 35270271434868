import React from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingButton from '@fd/ui/LoadingButton';

import { formikValidate } from '../../../../../helpers/validation';
import LogoField from '../../../components/LogoField';
import AdminOnlyOptions from './AdminOptions';
import CountrySelector from './CountrySelector';
import SettingsField from './SettingsField';
import SettingsOrgId from './SettingsOrgId';

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingRight: 0,
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const StyledFieldItem = styled(Grid)(() => ({
  borderBottom: '1px solid rgba(0,0,0,0.2)',
}));

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-end',
  alignContent: 'center',
  padding: theme.spacing(1),
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export type FormValues = { name: string; email: string; countryCode: any; logo?: File };

export type SettingsFormProps = {
  logoImageUrl: string;
  onSubmit: (values: FormValues) => Promise<void>;
} & FormValues;

const SettingsForm = ({
  dirty,
  isSubmitting,
  handleSubmit,
  logoImageUrl,
}: SettingsFormProps & FormikProps<FormValues>) => {
  return (
    <>
      <Form>
        <PaperContainer fluid>
          <StyledContainer container>
            <StyledFieldItem item xs={12}>
              <SettingsField
                name="name"
                label="Organisation_name"
                placeholder="Enter_name"
                fdKey="Organisation-name"
                validator={(value: string) => {
                  return formikValidate.required(value);
                }}
              />
            </StyledFieldItem>
            <StyledFieldItem item xs={12}>
              <SettingsOrgId />
            </StyledFieldItem>
            <StyledFieldItem item xs={12} pt={1} pb={1}>
              <CountrySelector />
            </StyledFieldItem>
            <StyledFieldItem item xs={12}>
              <SettingsField
                name="email"
                label="Email_address"
                placeholder="Email_address"
                fdKey="Organisation-email"
                validator={(value: string) => {
                  return formikValidate.email(value);
                }}
              />
            </StyledFieldItem>
            <StyledFieldItem item xs={12}>
              <LogoField logoUrl={logoImageUrl} />
            </StyledFieldItem>

            <StyledButtonContainer container>
              <StyledLoadingButton
                fdKey="Save-basic-config"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                disabled={!dirty}
              >
                <Translate id={'Save'} />
              </StyledLoadingButton>
            </StyledButtonContainer>
          </StyledContainer>
        </PaperContainer>
      </Form>
      <Permissions allowed={['FlipdishStaff']}>
        <AdminOnlyOptions />
      </Permissions>
    </>
  );
};

export default withFormik<SettingsFormProps, FormValues>({
  displayName: 'SettingsForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return props;
  },
  handleSubmit: async (values, { props: { onSubmit }, setSubmitting }) => {
    try {
      await onSubmit(values);
    } catch (e) {
      setSubmitting(false);
    }
  },
})(SettingsForm);
