import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const GridDivider = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.gridItem}>
      <Divider />
    </Grid>
  );
};

export default GridDivider;
