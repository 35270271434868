import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { RouterPrompt } from '@fd/ui/molecules/RouterPrompt/RouterPrompt';

type Props = { when: boolean } & MappedState;
const PreventNavigation = ({ message, title, when }: Props) => {
  return (
    <RouterPrompt
      message={message}
      show={when}
      title={title}
      onOk={() => true}
      onCancel={() => false}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const translate = getTranslate(state.locale);
  const message = translate('Are_you_sure_you_want_to_navigate_away') as string;
  const title = translate('Are_you_sure') as string;
  return {
    message,
    title,
  };
};

export default compose<Props, { when: boolean }>(connect(mapStateToProps))(PreventNavigation);
