import * as React from 'react';

import * as Loadable from 'react-loadable';

export const Calendar = Loadable.Map<any, any>({
  loader: {
    Calendar: () => import('./OldCalendar'),
    CalendarStyles: () => {
      // @ts-ignore
      return import('dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css');
    },
  },
  render(loaded, props) {
    const Calendar = loaded.Calendar.default;
    return <Calendar {...props} />;
  },
  loading: (props) => {
    if (props.error) {
      return (
        <div>
          Error! <button onClick={props.retry}>Retry</button>
        </div>
      );
    } else if (props.pastDelay) {
      return <div>Loading...</div>;
    } else {
      return null;
    }
  },
});
