import React, { useMemo } from 'react';

import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  root: {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.54)',
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));
type InnerProps = {};
type OuterProps = {
  searchFilter?: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  translate: Function;
};
type Props = InnerProps & OuterProps;

const BulkEditSearchFilter = ({ searchFilter, setSearchFilter }: Props) => {
  const classes = useStyles();

  const Filter = () => {
    return (
      <Translate>
        {(translate) => (
          <TextField
            type="search"
            autoFocus={!!searchFilter}
            classes={{ root: classes.root }}
            defaultValue={searchFilter}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search className={classes.icon} />
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-fd': 'bulkEdit-search' }}
            label={translate('Search') as string}
            onChange={(e) => {
              onSearchQueryChange(e.target.value);
            }}
            placeholder={translate('Search') as string}
            variant="outlined"
          />
        )}
      </Translate>
    );
  };

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setSearchFilter(query);
      }, 1000),
    []
  );

  return <Filter />;
};

export default BulkEditSearchFilter;
