import type { RestApiPaginationResultStoreGroupExtended } from 'overrides/@flipdish/api-client-typescript/api';

export function findStoreGroupId({
  storeId,
  salesChannelGroupsData,
}: {
  storeId?: number;
  salesChannelGroupsData?: RestApiPaginationResultStoreGroupExtended;
}): number | undefined {
  if (salesChannelGroupsData?.Data?.length && storeId) {
    for (const group of salesChannelGroupsData.Data) {
      if (group?.StoreHeaders?.some((store) => store.StoreId === storeId)) {
        return group?.StoreGroupId;
      }
    }
  }
  return undefined;
}
