import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import LoadingButton from '../../../ui/LoadingButton';

const mapStateToProps = (state) => ({ translate: getTranslate(state.locale) });

const useStyles = makeStyles({
  content: {
    width: '280px',
  },
});

type Props = {
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  loading: boolean;
  submit: (changes) => void;
} & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(function ({
  isDialogOpen,
  setDialogOpen,
  submit,
  loading,
  translate,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent key="dialog-content" className={classes.content}>
        <Typography variant="h6">{translate('Delete_push_notification_dialog_title')}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {translate('Delete_push_notification_dialog_description')}
        </Typography>
      </DialogContent>
      <DialogActions key="actions">
        <Button color="primary" data-fd="Delete_cancel" onClick={() => setDialogOpen(false)}>
          {translate('Cancel')}
        </Button>
        <LoadingButton color="primary" fdKey="Delete_confirm" onClick={submit} loading={loading}>
          {translate('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
