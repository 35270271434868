import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import illust_home_webedit from '../../../assets/images/illust_home_webedit.png';
import { Button } from '../../../ui/Button';
import LinkButton from '../../../ui/Button/LinkButton';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { dismiss as dismissAction } from '../actions';

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    marginBottom: '24px',
    [theme.breakpoints.down(769)]: { marginBottom: '16px' },
    [theme.breakpoints.down(569)]: { marginBottom: 0, borderTop: 0 },
  },
  gridContainer: {
    [theme.breakpoints.down('md')]: { padding: '24px' },
    [theme.breakpoints.down(569)]: { flexDirection: 'column-reverse', padding: '16px' },
    [theme.breakpoints.up('md')]: { padding: '24px' },
    [theme.breakpoints.up('lg')]: { padding: '32px' },
  },
  sectionHeader: {
    fontWeight: 500,
    marginBottom: '20px',
    [theme.breakpoints.down(569)]: { marginBottom: '16px' },
  },
  imgGrid: {
    textAlign: 'end',
    position: 'relative',
    [theme.breakpoints.down(569)]: { textAlign: 'center', marginBottom: '16px' },
  },
  img: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down(769)]: { width: '243px' },
    [theme.breakpoints.down(569)]: { width: '155px' },
  },
  subheading: {
    marginBottom: '20px',
    fontSize: '20px',
    letterSpacing: 'normal',
    lineHeight: 1.4,
    [theme.breakpoints.down(569)]: { marginBottom: '16px', fontSize: '16px' },
  },
  dismissBtn: {
    position: 'absolute',
    display: 'block',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: '0.5px',
    [theme.breakpoints.down(639)]: { top: '89.5%', left: '76%' },
    [theme.breakpoints.down(569)]: { display: 'none' },
    [theme.breakpoints.up(568)]: { top: '89.5%', left: '80%' },
    [theme.breakpoints.up(768)]: { top: '91.5%', left: '80%' },
    [theme.breakpoints.up('md')]: { top: '91.4%', left: '80%' },
    [theme.breakpoints.up('lg')]: { top: '91.4%', left: '84.5%' },
  },
  dismissBtnMobile: {
    display: 'block',
    margin: '8px auto 0 auto',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: '0.5px',
    [theme.breakpoints.up(569)]: { display: 'none' },
  },
  linkBtn: {
    letterSpacing: '1.3px',
    width: '258px',
    height: '56px',
    borderRadius: '28px',
    [theme.breakpoints.down(569)]: { width: '100%' },
  },
  ctaGrid: {
    alignSelf: 'center',
    paddingRight: '25px',
    [theme.breakpoints.down(569)]: { paddingRight: 0, textAlign: 'center' },
  },
}));

type OuterProps = {
  to: string;
  homeActionId: number;
};
type InnerProps = MappedDispatch;
type Props = OuterProps & InnerProps;

const WebsiteCard = (props: Props) => {
  const classes = useStyles();
  const { dismiss, homeActionId, to } = props;
  return (
    <PaperContainer fluid className={classes.paperContainer}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={7} md={7} lg={7} className={classes.ctaGrid}>
          <Typography variant="h3" color="primary" className={classes.sectionHeader}>
            <Translate id="Check_out_your_website!" />
          </Typography>
          <Typography className={classes.subheading}>
            <Translate id="Home_website_card_copy" />
          </Typography>
          <LinkButton
            to={to}
            variant="contained"
            size="medium"
            color="primary"
            fdKey="open-website-editor"
            className={classes.linkBtn}
          >
            <Translate id="Open_live_editor" />
          </LinkButton>
          <Button
            color="primary"
            type="button"
            onClick={() => dismiss(homeActionId)}
            fdKey="website-dismiss"
            className={classes.dismissBtnMobile}
          >
            <Translate id="Dismiss" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} className={classes.imgGrid}>
          <img src={illust_home_webedit} className={classes.img} />
          <Button
            color="primary"
            type="button"
            onClick={() => dismiss(homeActionId)}
            fdKey="website-dismiss"
            className={classes.dismissBtn}
          >
            <Translate id="Dismiss" />
          </Button>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dismiss: (homeActionId: number) => dispatch(dismissAction(homeActionId)),
});

export default compose<InnerProps, OuterProps>(connect(null, mapDispatchToProps))(WebsiteCard);
