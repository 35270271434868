import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import StopIcon from '@mui/icons-material/Stop';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { Translate } from 'react-localize-redux';

const progressionIcon = (type, color) => {
  switch (type) {
    case 'positive': {
      // #36d88e
      return (
        <div style={{ color, marginLeft: '-8px' }}>
          <ArrowDropUpIcon fontSize="large" color="inherit" />
        </div>
      );
    }
    case 'negative': {
      // #ff395f
      return (
        <span style={{ color, marginLeft: '-8px' }}>
          <ArrowDropDownIcon fontSize="large" color="inherit" />
        </span>
      );
    }
    case 'neutral': {
      // #4f94f7

      return (
        <span style={{ color, marginLeft: '-8px' }}>
          <StopIcon color="inherit" />
        </span>
      );
    }
    default: {
      return null;
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  bodyCell: {
    borderTop: 'solid 4px #ffffff',
    boxSizing: 'content-box' as any,
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      backgroundColor: '#eaf2ff',
      borderTop: 'solid 4px #eaf2ff',
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#ffffff',
        borderTop: 'solid 4px #ffffff',
      },
    },
  },
  selected: {
    boxSizing: 'content-box' as any,
    borderTop: 'solid 4px #4f94f7',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#eaf2ff',
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      backgroundColor: '#eaf2ff',
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#ffffff',
      },
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      backgroundColor: '#ffffff',
      borderTop: 'solid 4px #ffffff',
    },
  },
  header: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  info: {
    padding: '8px 0px 4px 0px',
    fontSize: '28px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  extraInfo: {
    display: 'flex',
    flexDirection: 'row' as any,
    verticalAlign: 'middle',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  selectedText: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  row: {
    padding: '24px',
    [theme.breakpoints.down('md')]: {
      padding: '16px 16px 0px 16px',
    },
  },
  valueRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    padding: '8px 0px 4px 0px',
    lineHeight: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

type Props = {
  selectedCell: boolean;
  section: TranslationId;
  type: string;
  previousData?: Array<{ key?: string; value?: number }>;
  currentTotal: number;
  previousTotal: number;
  handleClick: (e: React.MouseEvent) => void;
  languageCode: string;
  currency: string;
};

const GridCell = ({
  selectedCell,
  section,
  type,
  previousData,
  currentTotal,
  previousTotal,
  handleClick,
  languageCode,
  currency,
}: Props) => {
  const classes = useStyles();

  const renderProgressionIcon = (value: number) => {
    const negativeTrends = ['rejectedOrders', 'rejectedOrderAmount', 'refundedOrders'];
    if (value < 0) {
      const color = negativeTrends.indexOf(section) !== -1 ? '#36d88e' : '#ff395f';
      return progressionIcon('negative', color);
    }
    if (value > 0) {
      const color = negativeTrends.indexOf(section) !== -1 ? '#ff395f' : '#36d88e';
      return progressionIcon('positive', color);
    }
    return progressionIcon('neutral', '#4f94f7');
  };

  const renderProgressionData = () => {
    if (!isEmpty(previousData)) {
      const progressionInfo =
        previousTotal > 0
          ? (currentTotal - previousTotal) / previousTotal
          : currentTotal > 0
            ? 1
            : 0;
      return (
        <div
          className={
            selectedCell ? clsx(classes.extraInfo, classes.selectedText) : classes.extraInfo
          }
        >
          {renderProgressionIcon(progressionInfo)}
          {`${progressionInfo.toLocaleString(languageCode, {
            style: 'percent',
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 3,
          })}   ${
            type === 'Value'
              ? previousTotal.toLocaleString(languageCode, {
                  style: 'currency',
                  currency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : previousTotal.toLocaleString(languageCode, {
                  style: 'decimal',
                })
          }`}
        </div>
      );
    }
    return;
  };

  const renderCell = () => {
    return (
      <div className={classes.info}>
        {type === 'Value'
          ? currentTotal.toLocaleString(languageCode, {
              style: 'currency',
              currency,
            })
          : currentTotal.toLocaleString(languageCode, {
              style: 'decimal',
            })}
      </div>
    );
  };

  return (
    <Grid item onClick={handleClick} className={selectedCell ? classes.selected : classes.bodyCell}>
      <div className={classes.row}>
        <div className={selectedCell ? clsx(classes.header, classes.selectedText) : classes.header}>
          <Translate id={section} />
        </div>
        <div className={classes.valueRow}>
          {renderCell()}
          <div>
            <Hidden mdUp>
              {selectedCell ? (
                <KeyboardArrowUp fontSize="large" className={classes.arrow} />
              ) : (
                <KeyboardArrowDown fontSize="large" className={classes.arrow} />
              )}
            </Hidden>
          </div>
        </div>
        {renderProgressionData()}
      </div>
    </Grid>
  );
};

export default GridCell;
