import React, { useState } from 'react';

import { App, Menu, MenuStoreNames } from '@flipdish/api-client-typescript';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import StoresIcon from '@mui/icons-material/StoreOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import moment from 'moment';
import { getTranslate, Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { Button } from '@fd/ui/atoms';
import LinkButton from '@fd/ui/Button/LinkButton';
import Checkbox from '@fd/ui/Checkbox';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';
import Tooltip from '@fd/ui/Tooltip/Tooltip';

import percentage from '../../../assets/images/percentage.svg';
import { flagService } from '../../../services';
import { AssignStoresDialog } from './AssignStoresDialog';
import { MenuTaxRatesDialog } from './MenuTaxRatesDialog';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  addStoresButton: {
    marginTop: spacing(3),
    padding: 0,
    '&:hover': {
      background: 'rgba(255, 255, 255)',
    },
  },
  card: {
    background: '#fff',
    zIndex: 1,
    [breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      padding: '16px',
      paddingTop: '0',
      marginTop: '-12px',
    },
  },
  cardContent: {
    padding: '20px 32px 28px 20px',
    display: 'flex',
    width: '100%',
    [breakpoints.down('lg')]: {
      padding: '0!important',
    },
  },
  cardContentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardLink: {
    textTransform: 'none',
    padding: '0',
    marginTop: spacing(2.5),
    '&:hover': {
      background: 'rgba(255, 255, 255)',
    },
  },
  cardText: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.38px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  cardTextResponsive: {
    marginBottom: '12px',
  },
  cardAction: {
    cursor: 'pointer',
    color: '#05149e',
  },
  cardSwitches: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  cardSwitchLabel: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  storeAndHideButtons: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  storesResponsiveText: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  settingsIcon: {
    fontSize: '18px',
    marginRight: '8.8px',
  },
  settingsCheckboxText: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: '0.21px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  taxRates: {
    marginTop: '10px',
  },
  percentage: {
    height: '18px',
    width: '18px',
    marginRight: '8.8px',
  },
}));

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#25ca75',
    },
    '&$checked + $track': {
      backgroundColor: '#25ca75',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginTop: '20px',
    marginBottom: -1,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0,
    paddingTop: '5.8px',
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

export interface IEditControlProps {
  toggleMenuSectionBehaviour: () => any;
  toggleRevisionMode: () => any;
  toggleEditLock: () => any;
  toggleHideHidden: () => any;
  handleExpand: (e: any, newExpanded: boolean) => any;
  editLock: boolean;
  hideHidden: boolean;
  expanded: boolean;
}

export interface IStateProps {
  currentApp: App;
  permissions: string[];
  menu: Menu;
  menuStores: MenuStoreNames[];
  translate: TranslateFunction;
}

type Props = IEditControlProps & MappedState;

const MenuEditControls = (props: Props) => {
  const [showStores, setShowStores] = useState<boolean>(false);
  const [showTaxDialog, setShowTaxDialog] = useState<boolean>(false);
  const [showAssignStoresDialog, setShowAssignStoresDialog] = useState<boolean>(false);
  const classes = useStyles();
  const {
    // toggleRevisionMode,
    currentApp,
    editLock,
    expanded,
    isAddStoresOn,
    handleExpand,
    hideHidden,
    menu,
    menuStores,
    permissions,
    toggleEditLock,
    toggleHideHidden,
    toggleMenuSectionBehaviour,
    translate,
  } = props;

  const menuLockAllowed =
    permissions.findIndex((permission) => permission === 'UpdateMenuLock') > -1;
  const toggleShowStores = () => {
    setShowStores(!showStores);
  };

  const toggleTaxDialog = () => {
    setShowTaxDialog(!showTaxDialog);
  };

  return (
    <Card className={classes.card}>
      <MenuTaxRatesDialog showTaxDialog={showTaxDialog} toggleTaxDialog={toggleTaxDialog} />
      {menu && (
        <AssignStoresDialog
          menuId={menu.MenuId}
          open={showAssignStoresDialog}
          onClose={() => setShowAssignStoresDialog(false)}
          translate={translate}
        />
      )}
      <CardContent className={classes.cardContent}>
        <div style={{ width: '100%' }}>
          <div className={classes.cardContentHeader}>
            <Hidden lgDown>
              <div>
                {menu && menu.ModifiedTime ? (
                  <React.Fragment>
                    <Typography className={classes.cardText}>
                      {translate('Last_modified')}
                    </Typography>
                    <Typography className={classes.cardText}>
                      {moment(menu.ModifiedTime).format('MM/DD/YYYY hh:mm')}
                    </Typography>
                  </React.Fragment>
                ) : null}
                {/*<Typography
                  className={classNames(classes.cardText, classes.cardAction)}
                  onClick={toggleRevisionMode}
                  data-fd="menu-show-checkpoints"
                >
                  Revision history
                </Typography>*/}
              </div>
            </Hidden>
          </div>
          <div className={classes.cardSwitches}>
            <FormGroup>
              <div className={classes.flexCenter}>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={editLock}
                      value="editLockingSwitch"
                      onChange={toggleEditLock}
                      data-fd="menu-toggle-edit-lock"
                      disabled={!menuLockAllowed}
                    />
                  }
                  label={editLock ? translate('Locked_for_editing') : translate('Lock_for_editing')}
                  classes={{ label: classes.cardSwitchLabel }}
                  aria-describedby="menu-toggle-edit-lock"
                />
                <Tooltip
                  messageId="Lock_menu_editing_tooltip"
                  fdKey="menu-toggle-edit-lock_tooltip"
                  id="menu-toggle-edit-lock"
                >
                  <div>
                    <InfoIcon size={20} />
                  </div>
                </Tooltip>
              </div>
            </FormGroup>
          </div>
          <Hidden lgUp>
            {menu && menu.ModifiedTime ? (
              <Typography className={clsx(classes.cardText, classes.cardTextResponsive)}>
                {`${translate('Last_modified')} ${moment(menu.ModifiedTime).format(
                  'MM/DD/YYYY hh:mm'
                )}`}
              </Typography>
            ) : null}
          </Hidden>
          <Hidden lgDown>
            {(showStores ? menuStores : menuStores.slice(0, 2)).map((menuStore, idx) => (
              <Typography key={idx} className={classes.cardText}>
                {menuStore}
              </Typography>
            ))}
          </Hidden>
          <Hidden lgUp>
            <Typography className={classes.storesResponsiveText}>
              {menuStores.join(', ')}
            </Typography>
          </Hidden>
          <Hidden lgDown>
            {menuStores.length > 2 ? (
              <Typography
                className={clsx(classes.cardText, classes.cardAction)}
                onClick={toggleShowStores}
                data-fd="menu-toggle-show-stores"
              >
                {showStores
                  ? translate('Hide_stores')
                  : translate('More_stores', { stores: menuStores.length - 2 })}
              </Typography>
            ) : null}
          </Hidden>
          {menu ? (
            <>
              <div className={classes.storeAndHideButtons}>
                {isAddStoresOn && (
                  <Button
                    className={clsx(classes.cardText, classes.cardAction, classes.cardLink)}
                    fdKey="assign-stores-button"
                    variant="text"
                    lowercase
                    onClick={() => setShowAssignStoresDialog(true)}
                  >
                    <StoresIcon className={clsx(classes.cardAction, classes.settingsIcon)} />
                    <Typography className={clsx(classes.cardText, classes.cardAction)}>
                      <Translate id="Add_stores" />
                    </Typography>
                  </Button>
                )}
                <Permissions allowed={[App.AppResourceSetEnum.UpdateMenuItemsHideTemporarily]}>
                  <LinkButton
                    to={`/${currentApp.AppId}/menus/bulk-edit/${menu.MenuId}`}
                    fdKey={menu.Name}
                    className={clsx(classes.cardText, classes.cardAction, classes.cardLink)}
                  >
                    <VisibilityOutlinedIcon
                      className={clsx(classes.cardAction, classes.settingsIcon)}
                    />
                    <Typography className={clsx(classes.cardText, classes.cardAction)}>
                      {translate('show_hide_items')}
                    </Typography>
                  </LinkButton>
                </Permissions>
              </div>
              <Permissions allowed={[App.AppResourceSetEnum.UpdateMenu]}>
                <ExpansionPanel square expanded={expanded} onChange={handleExpand}>
                  <ExpansionPanelSummary>
                    <SettingsIcon className={clsx(classes.cardAction, classes.settingsIcon)} />
                    <Typography className={clsx(classes.cardText, classes.cardAction)}>
                      {translate('Menu_options')}
                    </Typography>
                    {expanded ? (
                      <ArrowDropUpIcon className={classes.cardAction} />
                    ) : (
                      <ArrowDropDownIcon className={classes.cardAction} />
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            dataFd="only-allow-one-expanded-section-checkbox"
                            checked={
                              menu.MenuSectionBehaviour ===
                              Menu.MenuSectionBehaviourEnum.ExpandSingle
                            }
                            color="primary"
                            onChange={toggleMenuSectionBehaviour}
                            value="expanded"
                          />
                        }
                        label={translate('Only_allow_one_expanded_section')}
                        classes={{
                          label: classes.settingsCheckboxText,
                        }}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            dataFd="hide-hidden-sections-checkbox"
                            checked={hideHidden}
                            color="primary"
                            onChange={toggleHideHidden}
                            value="hide-hidden"
                          />
                        }
                        label={translate('Hide_hidden_sections_items')}
                        classes={{
                          label: classes.settingsCheckboxText,
                        }}
                      />
                    </FormGroup>
                    <a
                      className={clsx(classes.flexCenter, classes.taxRates)}
                      onClick={toggleTaxDialog}
                    >
                      <img src={percentage} className={classes.percentage} />
                      <Typography className={clsx(classes.cardText, classes.cardAction)}>
                        {translate('Manage_tax_rates')}
                      </Typography>
                    </a>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Permissions>
            </>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale, permissions } = state;
  return {
    currentApp: state.currentApp,
    isAddStoresOn: flagService.isFlagOn(state, 'addStoresButton'),
    translate: getTranslate(locale),
    menu: state.menus.menu,
    menuStores: state.menus.menuStores,
    permissions,
  };
}

const EnhancedComponent = connect(mapStateToProps)(MenuEditControls);
export { EnhancedComponent as EditControls };
