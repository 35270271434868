import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import { Translate } from 'react-localize-redux';

import { Period, Periods } from './Fields/OrderCapacity';
import SetCapacityForm from './Fields/SetCapacityForm';

const useStyles = makeStyles({
  dialogContentStyles: {
    maxWidth: '280px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
    paddingLeft: '16px',
  },
  root: {
    padding: '16px 16px 0',
  },
});

type Props = {
  open: boolean;
  modalState?: {
    action: 'add' | 'edit';
    period: Period;
  };
  periods?: Periods;
  localDayNames: Array<{ label: string; value: string }>;
  interval: number;
  onAdd: (period: Period) => void;
  onCancel: () => void;
  onEdit: (period: Period, oldPeriod: Period) => void;
  onRemove: (period: Period) => void;
};

export default function SetCapacityModal(props: Props) {
  const { open, onCancel, onAdd, onEdit, onRemove, modalState, localDayNames, interval, periods } =
    props;

  const classes = useStyles();

  const [state, setState] = useState(() => modalState && modalState.period);
  const [validationError, setValidationError] = useState<Error>();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onCancel}
    >
      <DialogTitle classes={{ root: classes.root }}>
        <Translate id="Order_limit" />
      </DialogTitle>

      <DialogContent key="dialog-content" className={classes.dialogContentStyles}>
        {modalState ? (
          <SetCapacityForm
            interval={interval}
            localDayNames={localDayNames}
            action={modalState.action}
            period={modalState.period}
            onChange={(period) => {
              const leftStartTime = period.start.hour * 60 + period.start.minute;
              const leftEndTime = period.end.hour * 60 + period.end.minute;

              if (leftStartTime >= leftEndTime) {
                // TODO translation
                setValidationError(new Error(`start can't be after end`));
                return;
              }

              // validate overlapping
              if (periods) {
                const dayPeriods =
                  modalState.action === 'edit'
                    ? periods[period.day].filter((p) => !isEqual(p, modalState.period))
                    : periods[period.day];

                if (
                  dayPeriods.some((p) => {
                    const rightStartTime = p.start.hour * 60 + p.start.minute;
                    const rightEndTime = p.end.hour * 60 + p.end.minute;

                    return leftStartTime < rightEndTime && rightStartTime < leftEndTime;
                  })
                ) {
                  // TODO translation
                  setValidationError(new Error(`no overlapping`));
                  return;
                }
              }

              setValidationError(undefined);
              setState(period);
            }}
          />
        ) : null}
      </DialogContent>

      <DialogActions
        key="actions"
        style={{
          justifyContent: modalState && modalState.action === 'edit' ? 'space-between' : 'flex-end',
        }}
      >
        {modalState && modalState.action === 'add' ? (
          <>
            <Button color="primary" data-fd="set_capacity_cancel" onClick={onCancel}>
              <Translate id="Cancel" />
            </Button>
            <Button
              color="primary"
              data-fd="set_capacity"
              disabled={!!validationError}
              onClick={() => {
                onAdd(state!);
              }}
            >
              <Translate id="Create" />
            </Button>
          </>
        ) : (
          <>
            <Box color="#ff395f">
              <Button
                color="inherit"
                data-fd="set_capacity_remove"
                onClick={() => {
                  onRemove(state!);
                }}
              >
                <Translate id="Remove" />
              </Button>
            </Box>
            <Button
              color="primary"
              data-fd="set_capacity_update"
              disabled={!!validationError}
              onClick={() => {
                onEdit(state!, modalState?.period!);
              }}
            >
              <Translate id="Update" />
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
