import React from 'react';

import RrPermissions from 'react-redux-permissions';

type PermissionsProps = {
  allowed: Required<Flipdish.App>['AppResourceSet'];
};
const Permissions: React.FC<React.PropsWithChildren<PermissionsProps>> = ({
  children,
  ...props
}) => {
  return <RrPermissions {...props}>{children}</RrPermissions>;
};

export default Permissions;
