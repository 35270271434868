export const generalConstants = {
  MD_BREAKPOINT: 960,
  DRAWER_WIDTH: 304,
  APP_HEIGHT: 64,
  APP_LIMIT: 20,
  CIRCULAR_PROGRESS_HEIGHT: 70,
  APP_SEARCH_HEIGHT: 66,
  ADD_APP_BUTTON_HEIGHT: 57,
  NAV_BAR_HEIGHT: 64,
  NAV_BAR_MOBILE_HEIGHT: 40,
  IOS_OFFSET: 103,
  MAX_CHART_WIDTH: 1104,
  VOUCHERS_PAGE_SIZE: 20,
  ORDERS_PAGE_SIZE: 20,
  SCROLLABLE_CONTAINER: 'scrollable-main-container',
};
