import { useEffect, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';

import { getInvoices } from '../components/Billing/billing.service';

type FetchDataProps = {
  appId: string | undefined;
  isEnabled: boolean;
};

export const useInfiniteQueryAllInvoicesHook = (fetchDataProps: FetchDataProps) => {
  const { appId, isEnabled } = fetchDataProps;

  const [overdueInvoices, setOverdueInvoices] = useState<(string | undefined)[]>([]);

  const invoiceFromDate = moment().subtract(6, 'months').format('YYYY-MM-DD');

  const { data, hasNextPage, fetchNextPage, isPending, isFetching, isSuccess } = useInfiniteQuery({
    queryKey: ['getInvoices', appId],
    queryFn: ({ pageParam }) =>
      getInvoices({
        appId: appId || '',
        invoiceNumber: pageParam,
        dateFrom: invoiceFromDate as unknown as Date,
      }),
    initialPageParam: '',
    enabled: isEnabled && !!appId,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  useEffect(() => {
    // only fire for < 10 pages to prevent large memory usage and api calls (edge case)
    if (data && data?.pages?.length < 10 && hasNextPage && !isPending && !isFetching) {
      fetchNextPage();
    }
  }, [data, hasNextPage, isPending, isFetching]);

  useEffect(() => {
    if (data && !hasNextPage) {
      const overdue: (string | undefined)[] = [];
      data.pages?.forEach((page) =>
        page?.forEach((invoice) => invoice?.Overdue && overdue.push(invoice?.SubscriptionId))
      );
      setOverdueInvoices(overdue);
    }
  }, [data, hasNextPage]);

  return {
    overdueInvoices,
    isSuccess,
  };
};
