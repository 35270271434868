import React, { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import chunk from 'lodash/chunk';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import GenericTable from '@fd/ui/GenericTable/GenericTable';

import EmptyTable from '../../../ui/GenericTable/EmptyTable';
import { filterBulkHideList } from '../data/menuDataHelper';
import { BulkEditTableData, MenuBulkEditTableColumns, TablePagination } from '../types';

const useStyles = makeStyles(() => ({
  EmptyTable: {
    width: '100%',
  },
}));

type InnerProps = MappedProps;
type OuterProps = {
  filter: string | undefined;
  languageCode: string;
  loading: boolean;
  metaData: MenuBulkEditTableColumns[];
  onRowClick?: ({ row }: { row: any }) => void;
  tableType: 'Show' | 'Hide' | 'Report';
  tableData: BulkEditTableData[] | undefined;
};
type Props = InnerProps & OuterProps & RouteComponentProps;

const BulkShowHideTable = (props: Props) => {
  const { appId, filter, languageCode, loading, tableType, tableData, onRowClick, metaData } =
    props;
  const classes = useStyles();

  const rowsPerPageLocalStorageKey = `fd-${tableType}-table-rows-per-page`;
  const pageLocalStorageKey = `fd-${tableType}-table-page`;

  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: MenuBulkEditTableColumns }>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [pageData, setPageData] = useState<BulkEditTableData[] | undefined>();
  const [totalDataEntries, setTotalDataEntries] = useState<number>();

  const pagination = {
    page: page,
    pageSize: rowsPerPage,
    total: totalDataEntries,
  };

  //storing page number and rows per page locally.
  useEffect(() => {
    const lsRowsPerPage = Number(localStorage.getItem(rowsPerPageLocalStorageKey));
    const lsPage = Number(localStorage.getItem(pageLocalStorageKey));
    if (lsRowsPerPage) setRowsPerPage(lsRowsPerPage);
    if (lsPage) setPage(lsPage);
  }, []);

  useEffect(() => {
    if (tableData) {
      const filteredArray = filter ? filterBulkHideList(tableData, filter) : tableData;
      const paginatedResults = chunk(filteredArray, rowsPerPage) as BulkEditTableData[][];
      setPageData(paginatedResults[page]);
      setTotalDataEntries(filteredArray.length);
    }
  }, [page, rowsPerPage, tableData, filter]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setColumns(
      new Set(
        metaData
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      metaData.reduce<{ [key: string]: MenuBulkEditTableColumns }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
  }, [metaData]);

  const setAllColumns = (col) => {
    setColumns(col);
  };

  if (!loading && isEmpty(pageData)) {
    return (
      <div className={classes.EmptyTable}>
        <EmptyTable ariaTitle={'No_results'} emptyTableTitle={'No_results'} noLink />
      </div>
    );
  }

  return (
    <GenericTable
      AppId={appId}
      columns={columns}
      data={pageData}
      languageCode={languageCode}
      loading={loading}
      meta={meta}
      metadata={metaData}
      onSortKeyPressed={() => {}}
      onRowClick={onRowClick}
      order={'asc'}
      orderBy={''}
      page={page as number | undefined}
      pageLocalStorageKey={pageLocalStorageKey}
      pagination={pagination as TablePagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey as string | undefined}
      setColumns={setAllColumns}
      setPage={setPage as (n) => void | undefined}
      setRowsPerPage={setRowsPerPage as (n) => void | undefined}
      showTitle={false}
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId as string,
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(BulkShowHideTable);
