import React from 'react';

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../helpers/utilities';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { isFlipdishGlobal } from '../../selectors/app.selector';
import CheckoutSuccess from './Checkout/Authenticated/CheckoutSuccess';
import ExistingSubscriptionAuthenticatedCheckout from './Checkout/Authenticated/ExistingSubscriptionAuthenticatedCheckout';
import InvoicesList from './Invoices/InvoicesList';
import SubscriptionDetails from './Subscriptions/SubscriptionDetails';
import SubscriptionsList from './Subscriptions/SubscriptionsList';

const NewSubscriptionAuthenticatedCheckout = lazyWithRetry(
  () => import('./Checkout/Authenticated/NewSubscriptionAuthenticatedCheckout')
);

type Props = MappedProps & RouteComponentProps;

const BillingRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    isFlipdishGlobal,
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="subscriptions">
      <Switch>
        {!isFlipdishGlobal && (
          <Route exact path={`${path}/subscriptions`} component={SubscriptionsList} />
        )}
        {!isFlipdishGlobal && (
          <Route
            exact
            path={`${path}/subscriptions/:subscriptionId`}
            component={SubscriptionDetails}
          />
        )}
        {!isFlipdishGlobal && <Route exact path={`${path}/invoices`} component={InvoicesList} />}
        {!isFlipdishGlobal && (
          <Route
            exact
            path={`${path}/checkout`}
            component={ExistingSubscriptionAuthenticatedCheckout}
          />
        )}
        {!isFlipdishGlobal && (
          <Route exact path={`${path}/checkout/success`} component={CheckoutSuccess} />
        )}
        {!isFlipdishGlobal && (
          <LazyComponent>
            <Route
              exact
              path={`${path}/checkout/new-subscription`}
              component={NewSubscriptionAuthenticatedCheckout}
            />
          </LazyComponent>
        )}
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    isFlipdishGlobal: isFlipdishGlobal(state),
  };
}

export { BillingRoutes };
export default compose<Props, RouteComponentProps>(connect(mapStateToProps))(BillingRoutes);
