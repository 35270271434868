import React from 'react';

import { Customer } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import HiddenFeature from '../../../HiddenFeature';
import RenderTextButton from '../RenderButton';

const renderSuspiciousActivity = (type: TranslationId) => (
  <Grid item xs={12}>
    <Typography variant="body2" display="inline">
      <Translate id={type} />
      {' -'}
      {'\u00A0'}
    </Typography>
    <Typography variant="body2" display="inline" style={{ color: '#ff395f' }}>
      <Translate id="Blocked" />
    </Typography>
  </Grid>
);

type PropsStyles = { statusShowing?: boolean };
const useStyles = makeStyles((theme: Theme) => ({
  container: ({ statusShowing }: PropsStyles) => {
    return {
      paddingTop: 24,
      paddingRight: 24,
      paddingBottom: statusShowing ? 24 : 17.5,
      paddingLeft: 24,
      [theme.breakpoints.down('md')]: { padding: 16 },
    };
  },
}));

type InnerProps = {};
type OuterProps = {
  customerDetails: Reports.CustomerDetail;
  customerDetailsLoading: boolean;
  customerStatus: Customer;
  customerStatusLoading: boolean;
  isFlipdishStaff: boolean;
  marketingModalOpen: boolean;
  setMarketingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSuspiciousActivityModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  suspiciousActivityModalOpen: boolean;
};
type Props = InnerProps & OuterProps & PropsStyles;
const SideCard = (props: Props) => {
  const {
    customerDetailsLoading,
    customerStatusLoading,
    isFlipdishStaff,
    marketingModalOpen,
    setMarketingModalOpen,
    setSuspiciousActivityModalOpen,
    suspiciousActivityModalOpen,
  } = props;
  const { email, phoneNumber } = props.customerDetails;
  const { CardOrdersEnabled, CashOrdersEnabled, MarketingEnabled } = props.customerStatus;

  const theme = useTheme();
  const isMobileOrTablet = !useMediaQuery(theme.breakpoints.up('md'));

  const classes = useStyles({
    statusShowing: !CashOrdersEnabled || !CardOrdersEnabled,
  });

  const handleClickMarketing = () => {
    setMarketingModalOpen(!marketingModalOpen);
  };

  const handleClickSuspicious = () => {
    setSuspiciousActivityModalOpen(!suspiciousActivityModalOpen);
  };

  return (
    <>
      <Box p={isMobileOrTablet ? 2 : 0} pb={2}>
        <Typography variant="h5">
          <Translate id="Contact_Information" />
        </Typography>
      </Box>
      <Paper>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="caption">
              <Translate id="Contact_number" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <RenderTextButton
              href={phoneNumber}
              hrefType={'tel'}
              loading={customerDetailsLoading}
              fdKey="phonenumber"
              type="phone"
            >
              {phoneNumber}
            </RenderTextButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              <Translate id="Email" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <RenderTextButton
              href={email}
              hrefType={'mailto'}
              loading={customerDetailsLoading}
              fdKey="email"
            >
              {email}
            </RenderTextButton>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" display="block">
              <Translate
                id={MarketingEnabled ? 'Opted_in_for_marketing' : 'Opted_out_of_marketing'}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <RenderTextButton
              loading={customerDetailsLoading}
              fdKey="Opt_out_of_marketing"
              placeholder="blank"
              onClick={handleClickMarketing}
            >
              {MarketingEnabled && <Translate id={'Opt_out'} />}
            </RenderTextButton>
          </Grid>

          {isFlipdishStaff && (
            <>
              <HiddenFeature>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block">
                    <Translate id="Any_suspicious_activity" />
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <RenderTextButton
                    loading={customerDetailsLoading}
                    fdKey="Any_suspicious_activity"
                    placeholder="blank"
                    onClick={handleClickSuspicious}
                  >
                    {
                      <Translate
                        id={
                          !CashOrdersEnabled && !CardOrdersEnabled
                            ? 'Unblock_customer'
                            : 'Block_customer'
                        }
                      />
                    }
                  </RenderTextButton>
                </Grid>

                {!customerStatusLoading && (!CashOrdersEnabled || !CardOrdersEnabled) && (
                  <Divider style={{ width: '100%', marginBottom: 24 }}></Divider>
                )}

                {!customerStatusLoading &&
                  !CashOrdersEnabled &&
                  renderSuspiciousActivity('Paying_with_cash')}
                {!customerStatusLoading &&
                  !CardOrdersEnabled &&
                  renderSuspiciousActivity('Paying_online')}
              </HiddenFeature>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default SideCard;
