import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { Button } from '@fd/ui/atoms';

import { ReactComponent as FlipdishLogo } from '../../../../assets/images/FlipdishLogo.svg';
import { useTracking } from '../../../../services/amplitude/useTracking';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const CheckoutSuccess = ({ appId }: MappedState) => {
  const classes = useStyles();
  const { search } = useLocation();
  const { trackEvent } = useTracking();
  const params = new URLSearchParams(search);
  const sessionId = params.get('session_id');
  const history = useHistory();

  useEffect(() => {
    if (!sessionId) {
      return;
    }
    trackEvent('Loaded success page', { public: false.toString() });
  }, [sessionId]);

  if (!sessionId) {
    return <Redirect to={`/${appId}`} />;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <FlipdishLogo />
      <Typography variant="h3" component="h1" className={classes.title}>
        Checkout Success
      </Typography>
      <Typography variant="body1">Thank you for your purchase!</Typography>
      <Typography variant="body1">
        A Flipdish staff member will be in touch for the next steps
      </Typography>

      <Button
        variant="primary"
        onClick={() => history.push(`/${appId}`)}
        className={classes.button}
        fdKey="home-button"
      >
        Home
      </Button>
    </Container>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId!,
  };
};

export default connect(mapStateToProps)(CheckoutSuccess);
