import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { type FieldProps, Field } from 'formik';

import FormItemLayout from '../../../../../ui/Forms/FormItemLayout';

export type DateTimeFieldProps = {
  fieldData: FdField;
  dateOnly?: boolean;
  timeOnly?: boolean;
};

const DateTimeField = ({ fieldData, dateOnly, timeOnly }: DateTimeFieldProps) => (
  <Field name={fieldData.Key}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout
          label={fieldData.Name}
          description={fieldData.Description}
          showTooltip={!!(fieldData.Tooltip && fieldData.Tooltip.length)}
          tooltipOptions={{
            fdKey: 'appstore_config_input_tooltip',
            labelText: fieldData.Tooltip,
          }}
        >
          <FormControl error={showError}>
            {!dateOnly && !timeOnly && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  {...field}
                  slotProps={{
                    textField: {
                      error: showError,
                      variant: 'standard',
                      inputProps: { placeholder: '' },
                    },
                    popper: { style: { zIndex: 9999 } },
                  }}
                  disabled={isSubmitting}
                  format="MM/DD/YYYY HH:mm"
                  onChange={(datetime) => form.setFieldValue(field.name, datetime, false)}
                />
              </LocalizationProvider>
            )}
            {dateOnly && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  {...field}
                  disabled={isSubmitting}
                  slotProps={{
                    textField: {
                      error: showError,
                      variant: 'standard',
                      inputProps: { placeholder: '' },
                    },
                    popper: { style: { zIndex: 9999 } },
                  }}
                  format="MM/DD/YYYY"
                  onChange={(date) => form.setFieldValue(field.name, date, false)}
                />
              </LocalizationProvider>
            )}
            {timeOnly && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  {...field}
                  slotProps={{
                    textField: {
                      error: showError,
                      variant: 'standard',
                      inputProps: { placeholder: '' },
                    },
                    popper: { style: { zIndex: 9999 } },
                  }}
                  disabled={isSubmitting}
                  format="HH:mm"
                  ampm={false}
                  onChange={(time) => form.setFieldValue(field.name, time, false)}
                />
              </LocalizationProvider>
            )}

            {showError && fieldError && <FormHelperText>{fieldError}</FormHelperText>}
          </FormControl>
        </FormItemLayout>
      );
    }}
  </Field>
);

export default DateTimeField;
