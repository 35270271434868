import React from 'react';

import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import YesNoDialog from '../../../../ui/Dialog/YesNoDialog';

type InnerProps = {};
type OuterProps = {
  onCancel: (modal: 'marketing' | 'suspicious') => void;
  onContinue: () => void;
  open: boolean;
};
type Props = InnerProps & OuterProps;
const MarketingModal = (props: Props) => {
  const { onCancel, onContinue, open } = props;
  return (
    <YesNoDialog
      noTextId={'Cancel'}
      onNo={() => onCancel('marketing')}
      onYes={onContinue}
      open={open}
      titleTextId={'Opt_out_user'}
      yesButtonVariant={'contained'}
      yesTextId={'Continue'}
    >
      <Typography variant="body2" style={{ maxWidth: 432 }}>
        <Translate id="Opt_out_user_modal" />
      </Typography>
    </YesNoDialog>
  );
};
export default MarketingModal;
