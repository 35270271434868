import React, { useState } from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Tooltip from '../../../ui/Tooltip/Tooltip';
import RefundDialog from './RefundDialog';

const useStyles = makeStyles((theme: Theme) => ({
  refundButton: {
    padding: '9px 15px',
    [theme.breakpoints.down('sm')]: { padding: '0,0,0,10px', height: 40 },
    fontSize: '16px',
    letterSpacing: '1.4px',
    color: '#05149e',
  },
  iconColor: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export type Props = {
  allowRefund?: boolean;
  handleRefundOrder: (
    refundAmount: number,
    notifyCustomerRefund: boolean,
    cancelOrder: boolean
  ) => void;
  isLoading: boolean;
  amount?: number;
};

const RefundButton = (props: Props) => {
  const classes = useStyles();
  const { allowRefund, handleRefundOrder, isLoading, amount } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getRefundButtonText = (): JSX.Element => {
    if (amount === 0) {
      return <Translate id="Cancel" />;
    }

    return <Translate id="Refund" />;
  };

  return (
    <div>
      <RefundDialog
        open={isDialogOpen}
        handleRefundOrder={handleRefundOrder}
        handleClose={() => setIsDialogOpen(false)}
      />
      <Tooltip
        messageId={'Payment_account_does_not_allow_refunds'}
        fdKey={'Payment_account_does_not_allow_refunds'}
        disableHoverListener={allowRefund}
        disableTouchListener={allowRefund}
      >
        <div>
          <Button
            data-fd="refund"
            className={classes.refundButton}
            onClick={() => setIsDialogOpen(true)}
            disabled={!allowRefund || isLoading}
          >
            {getRefundButtonText()}
            <KeyboardArrowRight />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default RefundButton;
