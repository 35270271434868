import React, { useMemo, useRef } from 'react';

import { Polygon as GPolygon } from '@react-google-maps/api';
import * as wicket from 'wicket';

import { IMapShapeProps } from '../types';

const Wkt = wicket.Wkt;

export const serialize = (polygon: google.maps.Polygon) => {
  const wkt = new Wkt();
  wkt.fromObject(polygon);
  return wkt.write();
};

export const parse = (wellKnownText: string) => {
  const wkt = new Wkt();
  wkt.read(wellKnownText);
  return wkt.toObject() as google.maps.Polygon;
};

export const isEqual = (a: google.maps.Polygon, b: google.maps.Polygon) => {
  if (a.getPaths().getLength() !== b.getPaths().getLength()) {
    return false;
  }
  return a
    .getPaths()
    .getArray()
    .every((mvca, mvcaIdx) => {
      if (mvca.getArray().length !== b.getPaths().getAt(mvcaIdx).getArray().length) {
        return false;
      }
      return mvca.getArray().every((l, i) => l.equals(b.getPaths().getAt(mvcaIdx).getAt(i)));
    });
};

export const getDefaultPolygon = (center: google.maps.LatLng, distance: number) => {
  const polygon = new google.maps.Polygon();
  polygon.setPaths([
    [
      // compute ne point
      google.maps.geometry.spherical.computeOffset(center, distance, 45),
      // compute se point
      google.maps.geometry.spherical.computeOffset(center, distance, 135),
      // compute sw point
      google.maps.geometry.spherical.computeOffset(center, distance, 225),
      // compute nw point
      google.maps.geometry.spherical.computeOffset(center, distance, 315),
    ],
  ]);
  return serialize(polygon);
};

const Polygon = (props: IMapShapeProps) => {
  const { zone, onSelect, disabled, onChange } = props;

  const { polygon: initialPolygon, initPolygon } = useMemo(() => {
    try {
      return {
        polygon: parse(zone.WellKnownText as string), // !!!MUTABLE!!!
        initPolygon: zone.wkt.toObject() as google.maps.Polygon,
      };
    } catch (error) {
      console.error(error);
      return {
        polygon: undefined,
        initPolygon: undefined,
      };
    }
  }, [zone]);

  const polygonRef = useRef(initialPolygon);

  if (!polygonRef.current || !initPolygon) {
    return null;
  }

  return (
    <GPolygon
      key={zone.Id as number}
      onClick={disabled ? undefined : onSelect}
      onMouseUp={
        disabled
          ? undefined
          : () => {
              if (polygonRef.current && !isEqual(polygonRef.current, initPolygon)) {
                onChange(serialize(polygonRef.current));
              }
            }
      }
      paths={polygonRef.current.getPaths()}
      options={zone.options}
    />
  );
};

export default Polygon;
