import React, { useEffect, useRef, useState } from 'react';

import { App, CreateProduct, Product } from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory, useLocation } from 'react-router';

import { Button } from '@fd/ui/atoms/Button';
import { TextSearchField } from '@fd/ui/atoms/TextSearchField/TextSearchField';
import { Typography } from '@fd/ui/atoms/Typography';

import { notify, notifyError, NotifyProps } from '../../../../../layouts/Notify/actions';
import PageTitleWithBeta from '../../../../../layouts/Portal/PageTitleWithBeta';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import PageLayout from '../../../../../ui/Layout';
import { HelpdeskButton } from '../../../../HelpdeskButton';
import { CatalogList } from '../../../components/CatalogList';
import { NoMatchesFoundMessage } from '../../../components/NoMatchesFoundMessage';
import { ProductListLoadingSkeleton } from '../../../components/ProductListLoadingSkeleton';
import { ToggleViewMode } from '../../../components/ToggleViewMode';
import { VIEW_MODE } from '../../../constants';
import CreateProductDialog from '../../../partials/CreateProductDialog/CreateProductDialog';
import { catalogProductsService } from '../../../services/catalogProducts.service';
import { CatalogItemType } from '../../../types';
import { NoProductsMessage } from '../../components/NoProductsMessage';
import { ProductTypeSelect } from '../../components/ProductTypeSelect';

const DEFAULT_LIMIT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  createButtonContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  field: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
  fieldsContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(3),
  },
}));

type Props = MappedState & MappedDispatch;

const Products = (props: Props) => {
  const { AppId, isProductBasedMenusOn, notify, notifyError, translate } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(1);
  const [isCreateProductOpen, setIsCreateProductOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [productTypeOption, setProductTypeOption] = useState<Product.ProductTypeEnum>(
    Product.ProductTypeEnum.Product
  );
  const [viewMode, setViewMode] = useState(VIEW_MODE.LIST);
  const history = useHistory();
  const { search } = useLocation();
  const theme = useTheme();
  const didMount = useRef(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const urlParams = new URLSearchParams(search);

  useEffect(() => {
    const defaultSearchTerm = urlParams.get('searchTerm') || '';
    const defaultProductType =
      urlParams.get('productType') === 'Modifier'
        ? Product.ProductTypeEnum.Modifier
        : Product.ProductTypeEnum.Product;
    setProductTypeOption(defaultProductType);
    setSearchTerm(defaultSearchTerm);
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    const searchParams = new URLSearchParams(
      `searchTerm=${searchTerm}&productType=${productTypeOption.toString()}`
    );
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [productTypeOption, searchTerm]);

  const { data, isPending, isError } = useQuery({
    queryKey: [
      catalogProductsService.getProductsQueryKey,
      AppId,
      page,
      DEFAULT_LIMIT,
      searchTerm,
      productTypeOption.toString(),
    ],

    queryFn: () =>
      catalogProductsService.getCatalogProducts(AppId, page, DEFAULT_LIMIT, searchTerm, [
        productTypeOption.toString() as CatalogItemType,
      ]),
  });

  useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [isError]);

  const { mutateAsync } = useMutation({
    mutationFn: (newProduct: CreateProduct) =>
      catalogProductsService.createCatalogProduct(AppId, newProduct),

    onSuccess: (data) => {
      const prevList = queryClient.getQueryData([
        catalogProductsService.getProductsQueryKey,
        AppId,
        page,
        DEFAULT_LIMIT,
        searchTerm,
        productTypeOption.toString(),
      ]) as { TotalRecordCount: number; Page: number; Limit: number; Data: Product[] };
      notify({
        message: translate('Successfully_created') as string,
        variant: 'success',
      });
      if (data.Data.ProductType === productTypeOption) {
        const updatedList = [data.Data, ...prevList.Data];
        queryClient.setQueryData(
          [
            catalogProductsService.getProductsQueryKey,
            AppId,
            page,
            DEFAULT_LIMIT,
            searchTerm,
            productTypeOption.toString(),
          ],
          {
            TotalRecordCount: prevList.TotalRecordCount + 1,
            Limit: DEFAULT_LIMIT,
            Page: page,
            Data: updatedList,
          }
        );
      }
    },
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleViewModeChange = (mode: VIEW_MODE) => {
    setViewMode(mode);
  };

  const renderCreateDialog = () => {
    return (
      <CreateProductDialog
        defaultType={productTypeOption}
        open={isCreateProductOpen}
        onClose={() => setIsCreateProductOpen(false)}
        translate={translate}
        onSubmit={mutateAsync}
      />
    );
  };

  const renderContent = () => {
    if (isPending) {
      return <ProductListLoadingSkeleton />;
    }
    if (data?.Data) {
      if (data.Data.length === 0) {
        if (searchTerm !== '') {
          return <NoMatchesFoundMessage message="Products_NoProductMatchesFoundMessage" />;
        }
        return <NoProductsMessage translate={translate} />;
      }

      return (
        <CatalogList
          appId={AppId}
          limit={data.Limit}
          onPageChange={handlePageChange}
          page={page}
          content={data.Data}
          total={data.TotalRecordCount}
          type={productTypeOption}
          viewMode={viewMode}
        />
      );
    }
    return null;
  };

  const handleSearchTerm = (value: string) => {
    setPage(1);
    setSearchTerm(value);
  };

  const handleSetProductType = (option: Product.ProductTypeEnum) => {
    setPage(1);
    setProductTypeOption(option);
  };

  return isProductBasedMenusOn ? (
    <PageLayout documentTitle={'Products'} title={<PageTitleWithBeta translateId="Products" />}>
      {renderCreateDialog()}
      <div className={classes.container}>
        <Typography variant="caption">{translate('Product_PageDescription')}</Typography>
        <HelpdeskButton label="portalhelp:/products" />
        <div className={classes.createButtonContainer}>
          <Permissions allowed={[App.AppResourceSetEnum.CreateCatalogElements]}>
            <Button
              fdKey={'Product_Button_CreateNewProduct'}
              onClick={() => setIsCreateProductOpen(true)}
            >
              <Add />
              {translate('Product_Button_CreateNewProduct')}
            </Button>
          </Permissions>
        </div>
        <Divider className={classes.divider} />
        <Permissions allowed={[App.AppResourceSetEnum.ViewCatalogElements]}>
          <Grid className={classes.fieldsContainer} container direction="row">
            <Grid container item md={10} justifyContent="space-between">
              <TextSearchField
                className={classes.field}
                clearAriaLabel={`${translate('Products_SearchBox_Clear')}`}
                dataFd="input-search"
                defaultValue={urlParams.get('searchTerm') || ''}
                disableLabelAnimation={true}
                label={`${translate('Search')}`}
                onChange={handleSearchTerm}
                placeholder={`${translate('search_product')}`}
                variant="outlined"
              />
              <ProductTypeSelect
                className={classes.field}
                label="Filter"
                onChange={handleSetProductType}
                selectedValue={productTypeOption}
                translate={translate}
              />
            </Grid>
            {!isMobile && (
              <ToggleViewMode viewMode={viewMode} onViewModeChange={handleViewModeChange} />
            )}
          </Grid>
          {renderContent()}
        </Permissions>
      </div>
    </PageLayout>
  ) : null;
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    AppId: currentApp.AppId as string,
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
