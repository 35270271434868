import React from 'react';

import Slide from '@mui/material/Slide';
import { type TransitionProps } from '@mui/material/transitions/transition';

const SlideTransition = (props: TransitionProps) => {
  //@ts-ignore TODO: test this
  return <Slide {...props} direction="down" />;
};

export default SlideTransition;
