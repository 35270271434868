import React, { useEffect, useMemo, useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash.debounce';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { usePrevious } from '../../../../custom-hooks/usePrevious';
import { type IAppState } from '../../../../reducers/root.reducer';

const useStyles = makeStyles({
  search: {
    marginBottom: 10,
    padding: '16px 12px 16px 24px',
    borderTop: '1px solid rgba(0,0,0,0.2088)',
    borderBottom: '1px solid rgba(0,0,0,0.2088)',
    '&>div::before,&>div::after,&>div:hover::before': {
      borderBottom: 'initial!important',
    },
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
});

type Props = {
  open: boolean;
  onChange: (searchQuery: string) => void;
} & MappedProps;

const RequestAppAccessSearch = (props: Props) => {
  const { onChange, translate, open } = props;
  const classes = useStyles();
  const previousOpen = usePrevious(open);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debounceOnChangeHandler = useMemo(
    () =>
      debounce((inputValue: string) => {
        onChange(inputValue);
      }, 500),
    []
  );

  const handleInputChange = (newInputValue: string) => {
    debounceOnChangeHandler(newInputValue);
  };

  useEffect(() => {
    if (open && !previousOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open, previousOpen]);

  return (
    <TextField
      variant="standard"
      inputRef={inputRef}
      className={classes.search}
      type="search"
      fullWidth
      placeholder={`${translate('Request_app_access')}...`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      inputProps={{ 'data-fd': 'request-access-search' }}
      onChange={(e) => handleInputChange(e.target.value)}
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IAppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

const EnhancedComponent = compose<Props, any>(connect(mapStateToProps))(RequestAppAccessSearch);

export { EnhancedComponent as RequestAppAccessSearch };
