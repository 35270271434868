import React, { useMemo } from 'react';

import { Group, Product } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { GridContainer } from '@fd/ui/atoms/GridContainer';
import { Pagination } from '@fd/ui/atoms/Pagination';

import { notify, NotifyProps } from '../../../../layouts/Notify/actions';
import { VIEW_MODE } from '../../constants';
import { catalogGroupsService } from '../../services/catalogGroup.service';
import { catalogProductsService } from '../../services/catalogProducts.service';
import {
  getCatalogListQueryKeyByType,
  getLowestModifierPrice,
  getModifierGroupThatSetsPrice,
} from '../../utils';
import { CatalogCard } from '../CatalogCard';
import { CatalogCardProps } from '../CatalogCard/CatalogCard';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type InnerProps = MappedDispatch & RouteComponentProps;

type OuterProps = {
  appId: string;
  limit: number;
  onPageChange: (newPageNumber: number) => void;
  page: number;
  content: Group[] | Product[];
  total: number;
  type: Group.GroupTypeEnum | Product.ProductTypeEnum;
  viewMode: VIEW_MODE;
  showFromPrice?: boolean;
};

export type CatalogListProps = InnerProps & OuterProps;

const CatalogList = ({
  appId,
  content,
  history,
  limit,
  notify,
  onPageChange,
  page,
  total,
  type,
  viewMode,
  showFromPrice = false,
}: CatalogListProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePageChange = (event: any, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const queryClient = useQueryClient();

  const getCardProps = (item: Product | Group): CatalogCardProps => {
    const cardType = 'ProductType' in item ? item.ProductType : item.GroupType;
    const props = {
      CardId: item.CatalogItemId,
      CardType: cardType,
      ImageFileName: item.ImageFileName,
      Name: item.Name as string,
      onArchive: archiveMutation.mutate,
      onDuplicate: duplicateMutation.mutate,
      Sku: item.Sku as string,
    } as CatalogCardProps;

    if ('Description' in item) {
      props.Description = item.Description;
      props.Price = item.Price;
    }

    if (
      showFromPrice &&
      'ProductType' in item &&
      item.ProductType === Product.ProductTypeEnum.Product &&
      props.Price === 0 &&
      item.Groups
    ) {
      const modifierGroupThatSetsPrice = getModifierGroupThatSetsPrice(item.Groups, ['Group']);
      props.PriceFromModifier = getLowestModifierPrice(modifierGroupThatSetsPrice, ['Group']);
    }
    return props;
  };

  const redirectToDuplicate = (Name?: string, id?: string) => {
    const url = `${window.location.pathname}/${id}`;
    history.push({ pathname: url, state: { productName: Name } });
  };

  const archiveMutation = useMutation({
    mutationFn: (id: string) => {
      if (type === Group.GroupTypeEnum.ModifierGroup) {
        return catalogGroupsService.archiveCatalogGroup(appId, id);
      }
      return catalogProductsService.archiveCatalogProduct(appId, id);
    },

    onSuccess: () => {
      notify({
        variant: 'success',
        translate: true,
        message: 'Products_ToastMessage_ArchiveSuccess',
      });
      // @ts-expect-error: PRJSP-445
      queryClient.invalidateQueries(getCatalogListQueryKeyByType(type));
      //temporary solution while we wait for modifiers page to be removed
      if (type === Product.ProductTypeEnum.Modifier) {
        queryClient.invalidateQueries({
          queryKey: [getCatalogListQueryKeyByType(Product.ProductTypeEnum.Product)],
        });
      }
    },

    onError: () => {
      notify({
        variant: 'error',
        translate: true,
        message: 'Products_ToastMessage_Error',
      });
    },
  });

  const duplicateMutation = useMutation({
    mutationFn: (id: string) => {
      if (type === Group.GroupTypeEnum.ModifierGroup) {
        return catalogGroupsService.duplicateCatalogGroup(appId, id);
      }
      return catalogProductsService.duplicateCatalogProduct(appId, id);
    },

    onSuccess: (data) => {
      notify({
        variant: 'success',
        translate: true,
        message: 'Products_ToastMessage_DuplicateSuccess',
      });
      // @ts-ignore wrong type provided by autogenerated api
      const { Data } = data;
      redirectToDuplicate(Data.Name, Data.CatalogItemId);
    },

    onError: () => {
      notify({
        variant: 'error',
        translate: true,
        message: 'Products_ToastMessage_Error',
      });
    },
  });

  const isGridViewMode = viewMode === VIEW_MODE.GRID || isMobile;
  const effectiveViewMode = isMobile ? VIEW_MODE.GRID : viewMode;
  const cardProps = useMemo(() => {
    return (content as Array<Group | Product>).map((item) => getCardProps(item));
  }, [content]);

  return (
    <GridContainer>
      {cardProps.map((item) => {
        return (
          <Grid
            key={item.Sku}
            item
            xs={12}
            sm={isGridViewMode ? 6 : 12}
            md={isGridViewMode ? 4 : 12}
            className={classes.gridItem}
          >
            <CatalogCard {...item} viewMode={effectiveViewMode} />
          </Grid>
        );
      })}
      <Grid container item lg={12} direction={'row-reverse'} className={classes.gridItem}>
        <Pagination
          count={total}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handlePageChange}
        />
      </Grid>
    </GridContainer>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withRouter,
  connect(null, mapDispatchToProps)
)(CatalogList);
export { EnhancedComponent as CatalogList };
