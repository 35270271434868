import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '112px',
    borderRadius: '8px',
    backgroundColor: '#eaf2ff',
  },
}));

export type IconBannerProps = {
  icon?: React.ReactElement;
  classeName?: string;
};

export const IconBanner = ({ icon, classeName }: IconBannerProps): JSX.Element => {
  const classes = useStyles();
  const bannerClasses = clsx(classes.banner, classeName);
  return <div className={bannerClasses}>{icon}</div>;
};
