import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '@fd/ui/atoms/Box';
import { GridContainer } from '@fd/ui/atoms/GridContainer';
import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';

const useStyles = makeStyles((theme: Theme) => ({
  fullLine: {
    marginTop: '8px',
  },
  halfLine: {
    marginTop: '67px',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));
export const ProductListLoadingSkeleton = (): JSX.Element => {
  const classes = useStyles();

  const renderLoadingSkeleton = () => {
    const cardArray: JSX.Element[] = [];
    for (let i = 0; i < 6; i++) {
      cardArray.push(
        <Grid key={`skeleton-card-${i}`} item xs={12} sm={12} md={4} className={classes.gridItem}>
          <Box>
            <SkeletonLoader
              fdKey={`skeleton-card-${i}`}
              rows={[
                { height: '34px', width: '100%' },
                { height: '34px', width: '100%', className: classes.fullLine },
                { height: '34px', width: '50%', className: classes.halfLine },
              ]}
            />
          </Box>
        </Grid>
      );
    }
    return cardArray;
  };
  return <GridContainer width="md">{renderLoadingSkeleton()}</GridContainer>;
};
