import React from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import FdLoader from '../assets/images/fd-loader.gif';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      flexDirection: 'column',
    },
    fullscreen: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    imgContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '160px',
      height: '160px',
      borderRadius: '80px',
      background: '#fff',
      boxShadow:
        '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
      marginBottom: '32px',
    },
    img: {
      height: '96px',
      width: '96px',
    },
    headline: {
      color: '#3743b1',
      lineHeight: '1.2',
      marginBottom: '4px',
    },
    subHeadline: {
      fontSize: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: '1.4',
      letterSpacing: 'normal',
    },
  });

export interface ILoadingProps {
  fullscreen?: boolean;
  animate?: boolean;
  onAnimationEnd?();
}
type Props = WithStyles<typeof styles> & ILoadingProps;
const Loading = compose<Props, ILoadingProps>(
  withStyles(styles, {
    name: 'FdLoading',
  })
)(({ classes, fullscreen, animate, onAnimationEnd }) => (
  <Fade
    in={animate === undefined ? true : animate}
    mountOnEnter
    unmountOnExit
    onExited={onAnimationEnd}
  >
    <div className={clsx(classes.root, { [classes.fullscreen]: fullscreen })}>
      <div className={classes.imgContainer}>
        <img src={FdLoader} className={classes.img} />
      </div>
      <Typography variant="h2" className={classes.headline}>
        <Translate id="Getting_things_ready" />
      </Typography>
      <Typography className={classes.subHeadline}>
        <Translate id="It_might_take_a_couple_of_seconds" />
      </Typography>
    </div>
  </Fade>
));

export default Loading;
