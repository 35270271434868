import React from 'react';

type HelpDrawerLinkProps = {
  children: React.ReactNode;
  setIsOpen: (boolean) => void;
};

const HelpDrawerLink = ({ children, setIsOpen }: HelpDrawerLinkProps) => {
  return (
    <>
      <a
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(true);
          }
        }}
        onClick={() => setIsOpen(true)}
        tabIndex={0}
      >
        {children}
      </a>
    </>
  );
};

export default HelpDrawerLink;
