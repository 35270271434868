import React, { ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 16px 24px 16px',
  },
}));

type Props = {
  children?: ReactNode;
};
export const ModalActions = (props: Props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};
