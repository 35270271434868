import { splitReducer } from '@splitsoftware/splitio-redux';
import { localeReducer } from 'react-localize-redux';
import { reducer as permissions } from 'react-redux-permissions';
import { type Action, combineReducers } from 'redux';
import { reducer as reducerForm } from 'redux-form';

import { auditLogs } from '../components/AuditLogs/reducer';
import CampaignReducer from '../components/Campaigns/reducer';
import customers from '../components/Customers/Customers.reducer';
import { developers } from '../components/Developers/reducer';
import devicesReducer from '../components/Devices/Devices.reducer';
import bankingReducer from '../components/Finance/Banking/banking.reducer';
import payoutsReducer from '../components/Finance/Payouts/payouts.reducer';
import homeReducer from '../components/Home/reducer';
import kiosksReducer from '../components/Kiosks/Kiosks.reducer';
import {
  AcceptedOrdersReducer,
  OrdersFilterReducer,
  PendingOrdersReducer,
  StoresReducer as LiveViewStoresReducer,
} from '../components/Order/reducer';
import pushNotificationsReducer from '../components/PushNotifications/reducer';
import reportsReducer from '../components/Reports/reducers';
import rmsReducer from '../components/RMS/rms.reducer';
import settingsReducer from '../components/Settings/settings.reducer';
import storeKioskSettingsReducer, {
  type StoreKioskSettingsState,
} from '../components/Stores/KioskSettings/reducer';
import { preOrderSettingsReducer } from '../components/Stores/PreOrderSettings/reducer';
import websiteReducer from '../components/WebsiteAndApp/reducer';
import { appsConstants } from '../constants/apps.constants';
import notifyReducer from '../layouts/Notify/reducers';
import orm, { ormReducer } from '../orm/orm';
import { account } from './account.reducer';
import { apm } from './apm.reducer';
import { apps, currentApp } from './apps.reducer';
import { appStore } from './appstore.reducer';
import { checkout } from './checkout.reducer';
import applicationContextReducer from './context.reducer';
import { deliveryZones } from './deliveryzones.reducer';
import DrawerReducer from './drawer.reducer';
import { flipdish } from './flipdish.reducer';
import { loading } from './loading.reducer';
import localeDateTimeReducer from './localeDateTime.reducer';
import { menus } from './menus.reducer';
import { orders } from './order.reducer';
import { orderFulfillmentStatusConfig } from './orderFulfillmentStatusConfig.reducer';
import routingReducer from './routing.reducer';
import { rootSignalR } from './signalr/signalr.reducer';
import stores from './store.reducer';
import storeGroups from './storeGroup.reducer';
import openingHourOverridesReducer from './storeOpeningHourOverrides.reducer';
import openingHoursReducer from './storeOpeningHours.reducer';
import subscription from './subscription.reducer';
import { teammates } from './teammates.reducer';
import { vouchers, voucherStores } from './vouchers.reducer';

export type Permissions = Array<
  | 'FlipdishStaff'
  | 'ShowHiddenFeatures'
  | NonNullable<Flipdish.App['AppResourceSet']>[number]
  | NonNullable<Flipdish.App['AppAccessLevel']>
  | 'TemporaryCustomerTableTitleShowHiddenFeature'
>;

export interface IAppState {
  acceptedOrders: ReturnType<typeof AcceptedOrdersReducer>;
  account: ReturnType<typeof account>;
  apm: ReturnType<typeof apm>;
  apps: ReturnType<typeof apps>;
  appStore: ReturnType<typeof appStore>;
  auditLogs: ReturnType<typeof auditLogs>;
  banking: ReturnType<typeof bankingReducer>;
  campaigns: ReturnType<typeof CampaignReducer>;
  checkout: ReturnType<typeof checkout>;
  context: ReturnType<typeof applicationContextReducer>;
  currentApp: ReturnType<typeof currentApp>;
  customers: ReturnType<typeof customers>;
  deliveryZones: ReturnType<typeof deliveryZones>;
  developers: ReturnType<typeof developers>;
  devices: ReturnType<typeof devicesReducer>;
  drawer: ReturnType<typeof DrawerReducer>;
  flipdish: ReturnType<typeof flipdish>;
  form: ReturnType<typeof reducerForm>;
  home: ReturnType<typeof homeReducer>;
  kiosks: ReturnType<typeof kiosksReducer>;
  liveViewStores: ReturnType<typeof LiveViewStoresReducer>;
  loading: ReturnType<typeof loading>;
  locale: ReturnType<typeof localeReducer>;
  localeDateTime: ReturnType<typeof localeDateTimeReducer>;
  menus: ReturnType<typeof menus>;
  notify: ReturnType<typeof notifyReducer>;
  openingHourOverrides: ReturnType<typeof openingHourOverridesReducer>;
  openingHours: ReturnType<typeof openingHoursReducer>;
  orders: ReturnType<typeof orders>;
  OrdersFilter: ReturnType<typeof OrdersFilterReducer>;
  orderFulfillmentStatusConfig: ReturnType<typeof orderFulfillmentStatusConfig>;
  orm: ReturnType<typeof ormReducer>;
  payouts: ReturnType<typeof payoutsReducer>;
  pendingOrders: ReturnType<typeof PendingOrdersReducer>;
  permissions: Permissions;
  preOrderSettings: ReturnType<typeof preOrderSettingsReducer>;
  pushNotifiactions: ReturnType<typeof pushNotificationsReducer>;
  reports: ReturnType<typeof reportsReducer>;
  routing: ReturnType<typeof routingReducer>;
  rms: ReturnType<typeof rmsReducer>;
  settings: ReturnType<typeof settingsReducer>;
  signalr: ReturnType<typeof rootSignalR>;
  splitio: ReturnType<typeof splitReducer>;
  storeGroups: ReturnType<typeof storeGroups>;
  storeKioskSettings: StoreKioskSettingsState;
  stores: ReturnType<typeof stores>;
  subscription: ReturnType<typeof subscription>;
  teammates: ReturnType<typeof teammates>;
  vouchers: ReturnType<typeof vouchers>;
  voucherStores: ReturnType<typeof voucherStores>;
  website: ReturnType<typeof websiteReducer>;
}

export const rootReducer = (state: IAppState | undefined, action: Action) => {
  switch (action.type) {
    case appsConstants.SELECTED_APP_CHANGED: {
      const newState: Partial<AppState> = {
        ...(state || {}),
        devices: undefined,
        home: undefined,
        kiosks: undefined,
        orm: orm.getEmptyState(),
        pushNotifiactions: undefined,
        reports: undefined,
        splitio: undefined,
        storeGroups: undefined,
        storeKioskSettings: undefined,
        stores: undefined,
        website: undefined,
      };
      // @ts-ignore
      return allReducers(newState, action);
    }

    default:
      return allReducers(state, action);
  }
};

const allReducers = combineReducers({
  acceptedOrders: AcceptedOrdersReducer,
  account,
  apm,
  apps,
  appStore,
  auditLogs,
  banking: bankingReducer,
  campaigns: CampaignReducer,
  checkout,
  context: applicationContextReducer,
  currentApp,
  customers,
  deliveryZones,
  developers,
  devices: devicesReducer,
  drawer: DrawerReducer,
  flipdish,
  form: reducerForm,
  home: homeReducer,
  kiosks: kiosksReducer,
  liveViewStores: LiveViewStoresReducer,
  loading,
  locale: localeReducer,
  localeDateTime: localeDateTimeReducer,
  menus,
  notify: notifyReducer,
  openingHourOverrides: openingHourOverridesReducer,
  openingHours: openingHoursReducer,
  orders,
  OrdersFilter: OrdersFilterReducer,
  orderFulfillmentStatusConfig: orderFulfillmentStatusConfig,
  orm: ormReducer,
  payouts: payoutsReducer,
  pendingOrders: PendingOrdersReducer,
  permissions,
  preOrderSettings: preOrderSettingsReducer,
  pushNotifiactions: pushNotificationsReducer,
  reports: reportsReducer,
  rms: rmsReducer,
  routing: routingReducer,
  settings: settingsReducer,
  signalr: rootSignalR,
  splitio: splitReducer || {},
  storeGroups,
  storeKioskSettings: storeKioskSettingsReducer,
  stores,
  subscription,
  teammates,
  vouchers,
  voucherStores,
  website: websiteReducer,
});
