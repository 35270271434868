import {
  PushNotificationRequest,
  PushNotificationsApiFactory,
} from '@flipdish/api-client-typescript';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const api = createApi(PushNotificationsApiFactory);
// #region sendPushNotification

export async function sendPushNotification(appId: string, notification: PushNotificationRequest) {
  try {
    const incomingMessage = await api.schedulePushNotification(appId, notification);

    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region updatePushNotification

export async function updatePushNotification(
  appId: string,
  scheduledPushNotificationId: number,
  notification: PushNotificationRequest
) {
  try {
    const incomingMessage = await api.updatePushNotification(
      appId,
      scheduledPushNotificationId,
      notification
    );

    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getPushNotifications

export async function getPushNotifications(appId: string, page: number, limit: number) {
  try {
    const incomingMessage = await api.getPushNotifications(appId, page, limit);

    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region deletePushNotification

export async function deletePushNotification(appId: string, scheduledPushNotificationId: number) {
  try {
    await api.deletePushNotification(appId, scheduledPushNotificationId);
  } catch (incomingMessage) {
    console.log(incomingMessage);
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion
