import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { Field, FieldProps } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { FkSwitch } from '@fd/ui/FkSwitch/FkSwitch';

import { EFieldType } from '../../../../models/EFieldType';
import { Translate } from '../../../../overrides/react-localize-redux';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';

type InnerProps = ReturnType<typeof mapStateToProps>;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const AutomatedTransitionToggle = ({ translate }: Props) => {
  function validate(value: string) {
    if (!value) {
      return <Translate id="Required" />;
    }
  }

  const fieldName = 'AutomaticTransitionsEnabled';
  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        return (
          <FormItemLayout label={translate('Fulfilment_Automated_Transitions')}>
            <FormControlLabel
              value="start"
              control={
                <FkSwitch
                  required={false}
                  fieldType={EFieldType.Boolean}
                  name={fieldName}
                  fdKey={fieldName}
                />
              }
              label={translate('Enable_Automated_Transitions')}
              labelPlacement="start"
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(AutomatedTransitionToggle);
