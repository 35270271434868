import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms';
import LinkButton from '@fd/ui/Button/LinkButton';

import DiscoverHeaderBackground from '../../../../../assets/images/DiscoveryHeaderBackground.svg';
import DiscoverHeaderPhoneImg from '../../../../../assets/images/DiscoveryHeaderPhone.png';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${DiscoverHeaderBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  descriptionContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  enableOrderingContainer: {
    marginBottom: theme.spacing(1),
  },
  phoneImage: {
    maxWidth: '460px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '24px',
  },
}));

export type DiscoveryHeaderProps = {
  translate: TranslateFunction;
};
export const DiscoveryHeader = (props: DiscoveryHeaderProps) => {
  const { translate } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderButton = () => (
    <LinkButton
      className={classes.button}
      fdKey="get-in-touch-button"
      color="primary"
      variant="contained"
      to="#"
      onClick={(e) => {
        window.location.href = 'mailto:help@flipdish.com?subject=Request for mobile app';
        e.preventDefault();
      }}
    >
      <Translate id="Get_in_touch" />
    </LinkButton>
  );

  return (
    <Grid className={classes.container} container direction="row" alignItems="center">
      <Grid className={classes.content} item xs={12} sm={6}>
        <div className={classes.enableOrderingContainer}>
          <Typography variant="body1">
            <Translate id="Enable_cross_channel_ordering" />
          </Typography>
        </div>
        <Typography variant="h3" className={classes.title}>
          <Translate id="Discovery_header_title" />
        </Typography>
        <div className={classes.descriptionContainer}>
          {!isMobile && (
            <Typography variant="body2">
              <Translate id="Discovery_header_description" />
            </Typography>
          )}
        </div>
        {!isMobile && renderButton()}
      </Grid>
      <Grid container item xs={12} sm={6} alignItems="center" justifyContent="center">
        <img
          alt={translate('four_phone_screens_displaying_mobile_ordering_app') as string}
          className={classes.phoneImage}
          src={DiscoverHeaderPhoneImg}
        />
      </Grid>
      {isMobile && renderButton()}
    </Grid>
  );
};
