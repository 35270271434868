import React, { useState } from 'react';

import { PendingMenuChanges } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button, MessageBanner } from '@fd/ui/atoms';

import {
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../../../layouts/Notify/actions';
import { catalogChangesService } from '../../../../services/catalogChanges.service';
import { PendingChangesDialog } from '../PendingChangesDialog';

const useStyles = makeStyles((theme: Theme) => ({
  chooseMenusButton: {
    marginRight: theme.spacing(2),
  },
}));
export type PendingMenuChangesBannerProps = {
  appId: string;
  catalogElementId: string;
  changesList: PendingMenuChanges[];
  translate: TranslateFunction;
};

const PendingMenuChangesBanner = ({
  appId,
  catalogElementId,
  changesList,
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  translate,
}: PendingMenuChangesBannerProps & MappedDispatch): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const menuIds = changesList.map((change) => change.MenuId);
  const classes = useStyles();

  const handlePublishAllClick = () => {
    mutation.mutate([]);
  };

  const handleSelectMenusPublish = (menuIds: number[]) => {
    setIsDialogOpen(false);
    mutation.mutate(menuIds);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (menuIds: number[]) => {
      notifyLoading();
      return catalogChangesService.publishPendingMenuChanges(appId, catalogElementId, menuIds);
    },

    onSuccess: () => {
      closeNotifyLoading();
      notify({
        message: translate('Successfully_updated_menus') as string,
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesQueryKey],
      });
    },
    onError: () => {
      closeNotifyLoading();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });
  return (
    <>
      <PendingChangesDialog
        onCancel={() => setIsDialogOpen(false)}
        onPublish={handleSelectMenusPublish}
        open={isDialogOpen}
        menusWithChanges={menuIds || []}
        translate={translate}
      />
      <MessageBanner
        fdKey="pending-menu-changes-banner"
        message={translate('Catalog_pendingMenuChanges_message') as string}
      >
        <Button
          className={classes.chooseMenusButton}
          fdKey="choose-menus-button"
          onClick={() => setIsDialogOpen(true)}
          variant="secondary"
        >
          <Translate id="Choose_menus" />
        </Button>
        <Button fdKey="apply-all-updates-button" onClick={handlePublishAllClick}>
          <Translate id="Update_in_all_menus" />
        </Button>
      </MessageBanner>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyLoading: () => dispatch(closeNotifyLoading()),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyLoading: () => dispatch(notifyLoading({ persist: true })),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(PendingMenuChangesBanner);
export { EnhancedComponent as PendingMenuChangesBanner };
