import {
  createSelector as ormCreateSelector,
  SessionWithModels
} from 'redux-orm';
import { createSelector } from 'reselect';
import orm, { IOrmState } from '../orm/orm';

export const ormStateSelector = (state: AppState): IOrmState => state.orm;

export function createOrmSelector<R>(
  selector: (state: SessionWithModels<IOrmState>) => R
) {
  return createSelector(
    ormStateSelector,
    ormCreateSelector(orm, selector)
  );
}
