import React from 'react';

import LoadingIcon from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Translate } from 'react-localize-redux';

const Saving = (loadingString?: any) => (
  <Grid container alignItems="center">
    <Grid item>
      <LoadingIcon size={20} style={{ color: '#fff', marginRight: '16px' }} />
    </Grid>
    <Grid item>
      <Translate>
        {(translate) =>
          loadingString ? translate(loadingString) : `${translate('Auto_saving')}...`
        }
      </Translate>
    </Grid>
  </Grid>
);

export default Saving;
