import React, { ComponentType, ReactElement } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldConfig, type FieldProps, Field } from 'formik';

import GridContainer from '../../../../ui/Layout/GridContainer';
import { type FormValues } from './PreOrderSettingsForm';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    padding: `${spacing(2)} 0`,
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

type Props = {
  primary: ReactElement | string;
  secondary?: ReactElement | string;
  divider?: boolean;
  oneLine?: boolean;
  children: (props: RenderProps) => ComponentType<React.PropsWithChildren<{}>> | ReactElement;
} & FieldConfig;

export type RenderProps = FieldProps<FormValues> & {
  error?: string;
};

const FormItem = ({ primary, secondary, children, divider, oneLine, ...fieldProps }: Props) => {
  const classes = useStyles();

  return (
    <>
      <ListItem component="div" className={classes.root}>
        <GridContainer>
          <Grid item xs={oneLine ? 8 : 12} sm={8} className={classes.gridItem}>
            <ListItemText primary={primary} secondary={secondary} />
          </Grid>
          <Grid item xs={oneLine ? 4 : 12} sm={4} className={classes.gridItem}>
            <Field {...fieldProps}>
              {(fPRops: FieldProps) => {
                const {
                  form: { errors },
                  field,
                } = fPRops;
                const fieldError = errors[field.name] as string | undefined;

                return children({ ...fPRops, error: fieldError });
              }}
            </Field>
          </Grid>
        </GridContainer>
      </ListItem>
      {divider && <Divider />}
    </>
  );
};

export default FormItem;
