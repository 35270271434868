import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import GenericTable from '../../../../ui/GenericTable/GenericTable';
import GenericTableSkeleton from '../../../../ui/GenericTable/GenericTableSkeleton';
import { OrdersTableColumns, OrdersTablePagination } from '../../types';

type InnerProps = RouteComponentProps;
type OuterProps = {
  AppId: string;
  columnLocalStorageKey?: string;
  currency?: CurrencyEnum;
  data?: Array<{ [key: string]: any }>;
  defaultColumns?: string[];
  downloadCSV?: (() => Promise<void>) | (() => void);
  inView?: boolean;
  languageCode: string;
  loading?: boolean;
  metadata: OrdersTableColumns[];
  onAppRowClick?: Function;
  onSort: (sortKey: string, sortDirection: 'asc' | 'desc') => void;
  page?: number;
  pageLocalStorageKey?: string;
  pagination?: OrdersTablePagination;
  rowsPerPage?: number;
  rowsPerPageLocalStorageKey?: string;
  setPage?: (n) => void;
  setRowsPerPage?: (n) => void;
  setMetadata?: (n) => void;
  showColumnSelector?: boolean;
  subTitle?: string;
  title?: TranslationId;
  urlId?: string;
};

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type Props = InnerProps & OuterProps;
const OrderReportTable = (props: Props) => {
  const {
    AppId,
    columnLocalStorageKey,
    currency,
    data,
    defaultColumns,
    downloadCSV,
    history,
    inView,
    languageCode,
    loading,
    metadata,
    onAppRowClick,
    onSort,
    page,
    pageLocalStorageKey,
    pagination,
    rowsPerPage,
    rowsPerPageLocalStorageKey,
    setPage,
    setRowsPerPage,
    setMetadata,
    showColumnSelector,
    subTitle,
    title,
    urlId,
  } = props;
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: OrdersTableColumns }>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loading && !isLoaded) {
      setLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    if (inView) {
      onSort(orderBy, order);
    }
  }, [orderBy, order, inView]);

  const setAllColumns = (col) => {
    setColumns(col);
  };

  useEffect(() => {
    if (inView) {
      setColumns(
        new Set(
          metadata
            .filter((m) => m.isVisible)
            .sort((a, b) => a.ordinal - b.ordinal)
            .map((m) => m.columnName)
        )
      );
      setMeta(
        metadata.reduce<{ [key: string]: OrdersTableColumns }>((obj, mData) => {
          obj[mData.columnName] = mData;
          return obj;
        }, {})
      );
    }
  }, [metadata, inView]);

  if (isEmpty(data) || !currency) {
    return <GenericTableSkeleton loaded={loading === false} title={title} subTitle={subTitle} />;
  }

  const onSortKeyPressed = (key) => {
    if (meta![key].isSortable) {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
    }
  };

  const onRowClick = ({ row, hashId = urlId }) => {
    onAppRowClick && onAppRowClick({ AppId, row, hashId, history });
  };

  return (
    <GenericTable
      AppId={AppId}
      columnLocalStorageKey={columnLocalStorageKey}
      columns={columns}
      currency={currency}
      data={data}
      defaultColumns={defaultColumns}
      downloadCSV={downloadCSV}
      languageCode={languageCode}
      loading={loading}
      meta={meta}
      metadata={metadata}
      onRowClick={onAppRowClick ? onRowClick : undefined}
      onSortKeyPressed={onSortKeyPressed}
      order={order}
      orderBy={orderBy}
      page={page}
      pageLocalStorageKey={pageLocalStorageKey}
      pagination={pagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      setColumns={setAllColumns}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setMetadata={setMetadata}
      showColumnSelector={showColumnSelector}
      subTitle={subTitle}
      title={title}
      titlePadding={'small'}
    />
  );
};

export default compose<InnerProps, OuterProps>(withRouter)(OrderReportTable);
