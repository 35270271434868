import React from 'react';

import MuiSwitch, { type SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

type SwitchProps = MuiSwitchProps & {
  fdKey: string;
};

const Switch = ({ fdKey, checked, inputProps = {}, ...rest }: SwitchProps) => (
  <MuiSwitch
    checked={checked}
    color="default"
    {...rest}
    inputProps={
      {
        'data-fd': fdKey,
        'data-fd-value': `${!!checked}`,
        ...inputProps,
      } as React.InputHTMLAttributes<HTMLInputElement>
    }
  />
);

export default Switch;
