import { BusinessHoursOverride } from '@flipdish/api-client-typescript';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import { BusinessHoursPeriod, BusinessHoursPeriods } from '../components/OpeningHours/types';
import { storeSelectors } from '.';
import { DateTimeUtils, dateTimeUtils, getDateTimeFormatter } from './localeDateTime.selector';
import { getSelectedStoreTimezone, SelectedStoreTimezone } from './store.selector';

type DayOfWeekEnum = Required<Flipdish.Range>['DayOfWeek'];

// #region [DEPRECATED] getOpeningHours
type StateOpeningHours = ReturnType<typeof deliveryOpeningHoursSelector>;
const deliveryOpeningHoursSelector = (state: AppState) => state.openingHours.Delivery.data;
const pickupOpeningHoursSelector = (state: AppState) => state.openingHours.Pickup.data;

type OpeningHoursObsolete = ReturnType<typeof getOpeningHoursCombiner>;
const getOpeningHoursCombiner = (bhos: StateOpeningHours) => {
  if (!bhos) {
    return;
  }
  return bhos;
};
export const getDeliveryOpeningHours = createSelector(
  [deliveryOpeningHoursSelector],
  getOpeningHoursCombiner
);
export const getPickupOpeningHours = createSelector(
  [pickupOpeningHoursSelector],
  getOpeningHoursCombiner
);
// #endregion

// #region [DEPRECATED] getOpeningHours

const parseOpeningTimes = (startTime: string, period: string, timezone: string) => {
  if (period === '00:00:00') {
    return [undefined, undefined];
  }
  const start = moment(startTime, 'HH:mm:ss', true).tz(timezone);
  const timeParts = period.split(':');
  const end = start.clone().add(timeParts[0], 'hours').add(timeParts[1], 'minutes');

  return [start, end];
};

type TodayOpeningTimes = ReturnType<typeof getTodayOpeningTimesCombiner>;
const getTodayOpeningTimesCombiner = (
  bhos: OpeningHoursObsolete,
  storeTimeZone: SelectedStoreTimezone
) => {
  if (!bhos || !storeTimeZone) {
    return;
  }
  const todayKey = moment().locale('en').format('dddd');
  const bho: BusinessHoursPeriod = bhos[todayKey];

  const [start, end] = parseOpeningTimes(bho.StartTime, bho.Period, storeTimeZone);
  const [startEarly, endEarly] = parseOpeningTimes(
    bho.StartTimeEarly,
    bho.PeriodEarly,
    storeTimeZone
  );

  return {
    ...bho,
    start,
    end,
    startEarly,
    endEarly,
  };
};
export const getTodayDeliveryOpeningHour = createSelector(
  [deliveryOpeningHoursSelector, (state) => getSelectedStoreTimezone(state)],
  getTodayOpeningTimesCombiner
);

export const getTodayPickupOpeningHour = createSelector(
  [pickupOpeningHoursSelector, (state) => getSelectedStoreTimezone(state)],
  getTodayOpeningTimesCombiner
);
// #endregion

// #region [DEPRECATED] getCurrentlyOpen
const getCurrentlyOpenCombiner = (openings: TodayOpeningTimes, timeZone: SelectedStoreTimezone) => {
  if (!openings || !timeZone) {
    return;
  }

  const now = moment.tz(timeZone);

  const isOpen = now.isAfter(openings.start) && now.isBefore(openings.end);
  const isOpenEarly =
    openings.startEarly && now.isAfter(openings.startEarly) && now.isBefore(openings.endEarly);
  return openings.startEarly ? isOpenEarly || isOpen : isOpen;
};
export const getIsCurrentlyOpenForDelivery = createSelector(
  [
    getTodayDeliveryOpeningHour,
    (state) => getSelectedStoreTimezone(state),
    () => new Date().getMinutes(),
  ],
  getCurrentlyOpenCombiner
);
export const getIsCurrentlyOpenForPickup = createSelector(
  [
    getTodayPickupOpeningHour,
    (state) => getSelectedStoreTimezone(state),
    () => new Date().getMinutes(),
  ],
  getCurrentlyOpenCombiner
);
// #endregion

// #region [DEPRECATED]
export const createOpeningHoursSelector = (storeId: number, deliveryType: 'Delivery' | 'Pickup') =>
  createSelector(
    [(state) => state.openingHours.data[storeId] && state.openingHours.data[storeId][deliveryType]],
    (openingHours) => {
      if (!openingHours && !Object.keys(openingHours || {}).length) {
        return undefined;
      }
      return openingHours;
    }
  );

export const createOpeningHoursByIdSelector = (
  storeId: number,
  deliveryType: 'Delivery' | 'Pickup',
  id: string
) => {
  const openingHoursSelector = createOpeningHoursSelector(storeId, deliveryType);

  return createSelector(
    [
      openingHoursSelector,
      (state) =>
        state.openingHours.data[storeId] &&
        state.openingHours.data[storeId][deliveryType] &&
        state.openingHours.data[storeId][deliveryType][id],
    ],
    (openingHours, openingHour) => {
      if (!openingHour) {
        return undefined;
      }
      return openingHour;
    }
  );
};

export const createNextOpeningHourByIdSelector = (
  storeId: number,
  deliveryType: BusinessHoursOverride.DeliveryTypeEnum,
  day?: string
) =>
  createSelector(
    [
      (state) => state.openingHours.data[storeId] && state.openingHours.data[storeId][deliveryType],
      getDateTimeFormatter,
    ],
    (openingHours, { time }) => {
      if (!Object.keys(openingHours || {}).length) {
        return;
      }
      const today = moment().locale('en');
      if (day) {
        today.day(day);
      }
      for (let index = 0; index < 6; index++) {
        const nextDay = today.add(1, 'day');
        const oh = openingHours[nextDay.format('dddd')];
        if (oh.Period !== '00:00:00') {
          const nextTime = time(oh.StartTimeEarly) as moment.Moment;
          return nextDay
            .hour(nextTime.hour())
            .minute(nextTime.minute())
            .second(nextTime.second())
            .toDate();
        }
      }

      return moment()
        .locale('en')
        .day(day || moment().day())
        .hour(23)
        .minute(59)
        .second(59)
        .toDate();
    }
  );
export const createClosingHourByIdSelector = (
  storeId: number,
  deliveryType: BusinessHoursOverride.DeliveryTypeEnum,
  day?: string
) =>
  createSelector(
    [
      (state) => state.openingHours.data[storeId] && state.openingHours.data[storeId][deliveryType],
      getDateTimeFormatter,
    ],
    (openingHours, { time }) => {
      if (!Object.keys(openingHours || {}).length) {
        return;
      }
      const today = moment().locale('en');
      if (day) {
        today.day(day);
      }
      const oh = openingHours[today.format('dddd')];
      if (oh.Period !== '00:00:00') {
        const startTime: moment.Moment = time(oh.StartTime);
        const period: moment.Moment = time(oh.Period);
        return startTime
          .add(period.hours(), 'hours')
          .add(period.minutes(), 'minutes')
          .add(period.seconds(), 'seconds')
          .milliseconds(0)
          .toDate();
      }

      return moment()
        .locale('en')
        .day(day || moment().day())
        .hour(23)
        .minute(59)
        .second(59)
        .toDate();
    }
  );

// #endregion

// #region

interface OpeningHour {
  start?: Date;
  end?: Date;
  earlyStart?: Date;
  earlyEnd?: Date;
}
type OpeningHours = { [key in DayOfWeekEnum]: OpeningHour };

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}
interface OpeningTime {
  start?: Time;
  period?: Time;
  earlyStart?: Time;
  earlyPeriod?: Time;
}
type OpeningTimes = { [key in DayOfWeekEnum]: OpeningTime };

export const deliveryOpeningHoursForStore = (state: AppState, { storeId }: { storeId: number }) =>
  state.openingHours.data[storeId] && state.openingHours.data[storeId].Delivery;

export const pickupOpeningHoursForStore = (state: AppState, { storeId }: { storeId: number }) =>
  state.openingHours.data[storeId] && state.openingHours.data[storeId].Pickup;

const deliveryOpeningHourTimes = createSelector(
  [(state, props) => deliveryOpeningHoursForStore(state, props)],
  (openingHours) => {
    if (!openingHours || !Object.keys(openingHours).length) {
      return undefined;
    }

    const openingTimes = parseBusinessHoursPeriods(openingHours);

    return openingTimes;
  }
);
const pickupOpeningHourTimes = createSelector(
  [(state, props) => pickupOpeningHoursForStore(state, props)],
  (openingHours) => {
    if (!openingHours || !Object.keys(openingHours).length) {
      return undefined;
    }

    const openingTimes = parseBusinessHoursPeriods(openingHours);

    return openingTimes;
  }
);

export const weekDaysLocal = createSelector([dateTimeUtils], (dtUtils) => {
  const now = new Date();
  return dtUtils
    .eachDayOfInterval({ start: dtUtils.startOfWeek(now), end: dtUtils.endOfWeek(now) })
    .map((d) => ({
      key: dtUtils.format(d, 'eeee', { locale: undefined }),
      val: dtUtils.format(d, 'eee'),
      ordinal: Number(dtUtils.format(d, 'e')),
    }))
    .sort((a, b) => a.ordinal - b.ordinal)
    .map(({ key, val }) => ({ key, val }));
});

// #region 7days
export const delivery7DayOpeningHours = createSelector(
  [
    (state, props) => deliveryOpeningHourTimes(state, props),
    (state, props) => storeSelectors.store(state, props),
    (state, props) => dateTimeUtils(state),
  ],
  (openingTimes, store, dtUtils) => {
    if (!openingTimes || !store) {
      return undefined;
    }

    const weekOpeningHours = calc7DaysOpeningHours(
      openingTimes,
      new Date(),
      store.IanaTimeZone!,
      dtUtils
    );
    return {
      ...weekOpeningHours,
      enabled: store.DeliveryEnabled!,
    };
  }
);
export const pickup7DayOpeningHours = createSelector(
  [
    (state, props) => pickupOpeningHourTimes(state, props),
    (state, props) => storeSelectors.store(state, props),
    (state, props) => dateTimeUtils(state),
  ],
  (openingTimes, store, dtUtils) => {
    if (!openingTimes || !store) {
      return undefined;
    }

    const weekOpeningHours = calc7DaysOpeningHours(
      openingTimes,
      new Date(),
      store.IanaTimeZone!,
      dtUtils
    );
    return {
      ...weekOpeningHours,
      enabled: store.PickupEnabled!,
    };
  }
);
export const store7DayOpeningHours = createSelector(
  [
    (state, props) => delivery7DayOpeningHours(state, props),
    (state, props) => pickup7DayOpeningHours(state, props),
  ],
  (Delivery, Pickup) => {
    return {
      Delivery,
      Pickup,
    };
  }
);
// #endregion

// #region today opening hours

export const todayOpeningHours = createSelector(
  [
    (state, props) => store7DayOpeningHours(state, props),
    (state, props) => storeSelectors.timeZone(state, { storeId: props.storeId }),
    (state, props) => dateTimeUtils(state),
  ],
  ({ Delivery, Pickup }, storeIanaTZ, dtUtils) => {
    if (!Delivery || !Pickup) {
      return {
        Delivery: undefined,
        Pickup: undefined,
      };
    }

    const storeNowLocal = dtUtils.utcToZonedTime(new Date(), storeIanaTZ!);
    const storeDayNameEn = dtUtils.format(storeNowLocal, 'eeee', {
      locale: undefined,
    });
    const deliveryToday = Delivery[storeDayNameEn] as OpeningHour;
    const pickupToday = Pickup[storeDayNameEn] as OpeningHour;

    return {
      Delivery: deliveryToday,
      Pickup: pickupToday,
    };
  }
);
// #endregion

// #region next opening hour from date

export const deliveryNextOpeningHourCalculator = createSelector(
  [
    (state, props) => deliveryOpeningHourTimes(state, props),
    (state, props) => storeSelectors.timeZone(state, props),
    (state, props) => dateTimeUtils(state),
  ],
  (openingTimes, storeIanaTZ, dtUtils) => {
    return (fromDate: Date) => {
      if (!openingTimes || !storeIanaTZ) {
        return undefined;
      }

      const days = calc7DaysOpeningHours(openingTimes, fromDate, storeIanaTZ, dtUtils);
      let nextOpeningHour = findNextOpeningHour(days, fromDate, dtUtils);
      if (!nextOpeningHour) {
        const nextFromDate = dtUtils.addDays(fromDate, 7);
        const days = calc7DaysOpeningHours(openingTimes, nextFromDate, storeIanaTZ, dtUtils);
        nextOpeningHour = findNextOpeningHour(days, fromDate, dtUtils);
      }
      return nextOpeningHour;
    };
  }
);

export const pickupNextOpeningHourCalculator = createSelector(
  [
    (state, props) => pickupOpeningHourTimes(state, props),
    (state, props) => storeSelectors.timeZone(state, props),
    (state, props) => dateTimeUtils(state),
  ],
  (openingTimes, storeIanaTZ, dtUtils) => {
    return (fromDate: Date) => {
      if (!openingTimes || !storeIanaTZ) {
        return undefined;
      }

      const days = calc7DaysOpeningHours(openingTimes, fromDate, storeIanaTZ, dtUtils);
      let nextOpeningHour = findNextOpeningHour(days, fromDate, dtUtils);
      if (!nextOpeningHour) {
        const nextFromDate = dtUtils.addDays(fromDate, 7);
        const days = calc7DaysOpeningHours(openingTimes, nextFromDate, storeIanaTZ, dtUtils);
        nextOpeningHour = findNextOpeningHour(days, fromDate, dtUtils);
      }
      return nextOpeningHour;
    };
  }
);

// #endregion

// #region helpers

function parseTime(time: string) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return { hours, minutes, seconds };
}
function parseTimePeriod(start: string, period: string) {
  return [parseTime(start), parseTime(period)];
}
function parseBusinessHoursPeriod(oh: BusinessHoursPeriod) {
  const parsed: OpeningTime = {};

  if (oh.Period !== '00:00:00') {
    const [start, period] = parseTimePeriod(oh.StartTime, oh.Period);

    parsed.start = start;
    parsed.period = period;
  }

  if (oh.PeriodEarly !== '00:00:00') {
    const [start, period] = parseTimePeriod(oh.StartTimeEarly, oh.PeriodEarly);

    parsed.earlyStart = start;
    parsed.earlyPeriod = period;
  }

  return parsed;
}
function parseBusinessHoursPeriods(openingHours: BusinessHoursPeriods) {
  const openingTimes = Object.entries(openingHours).reduce<OpeningTimes>(
    (agg, [day, oh]) => {
      agg[day] = parseBusinessHoursPeriod(oh);
      return agg;
    },
    {
      Sunday: {},
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
    }
  );

  return openingTimes;
}
function toOpeningHour(
  ot: OpeningTime,
  localDate: Date,
  storeIanaTZ: string,
  dtUtils: DateTimeUtils
) {
  const { addHours, addMinutes, addSeconds, zonedTimeToUtc } = dtUtils;
  const oh: OpeningHour = {};

  if (ot.start && ot.period) {
    const start = dtUtils.set(localDate, ot.start);
    const { hours, minutes, seconds } = ot.period;
    const end = addHours(addMinutes(addSeconds(start, seconds), minutes), hours);

    oh.start = zonedTimeToUtc(start, storeIanaTZ);
    oh.end = zonedTimeToUtc(end, storeIanaTZ);

    if (dtUtils.isAfter(oh.start, oh.end)) {
      oh.end = dtUtils.addDays(oh.end, 1);
    }
  }

  if (ot.earlyStart && ot.earlyPeriod) {
    const start = dtUtils.set(localDate, ot.earlyStart);
    const { hours, minutes, seconds } = ot.earlyPeriod;
    const end = addHours(addMinutes(addSeconds(start, seconds), minutes), hours);

    oh.earlyStart = zonedTimeToUtc(start, storeIanaTZ);
    oh.earlyEnd = zonedTimeToUtc(end, storeIanaTZ);
  }

  return oh;
}

function calc7DaysOpeningHours(
  openingTimes: OpeningTimes,
  onDate: Date,
  storeIanaTZ: string,
  dtUtils: DateTimeUtils
) {
  const startLocal = dtUtils.utcToZonedTime(onDate, storeIanaTZ);

  const nextDays = dtUtils.eachDayOfInterval({
    start: startLocal,
    end: dtUtils.addDays(startLocal, 6),
  });

  const nextOpeningHours = nextDays.reduce<
    OpeningHours & { ordinal: DayOfWeekEnum[]; hasOpeningHour: boolean }
  >(
    (agg, localDate) => {
      const storeDayNameEn = dtUtils.format(localDate, 'eeee', {
        locale: undefined,
      }) as DayOfWeekEnum;

      const ot = openingTimes[storeDayNameEn];

      agg[storeDayNameEn] = toOpeningHour(ot, localDate, storeIanaTZ, dtUtils);

      agg.ordinal.push(storeDayNameEn);

      if (agg[storeDayNameEn].start) {
        agg.hasOpeningHour = true;
      }

      return agg;
    },
    {
      Sunday: {},
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      ordinal: [] as DayOfWeekEnum[],
      hasOpeningHour: false,
    }
  );

  return nextOpeningHours;
}
function findNextOpeningHour(
  days: ReturnType<typeof calc7DaysOpeningHours>,
  fromDate: Date,
  dtUtils: DateTimeUtils
) {
  let next: OpeningHour = {};
  for (const day of days.ordinal) {
    if (days[day].end && days[day].end!.getTime() > fromDate.getTime()) {
      next = days[day];
      break;
    }
  }

  if (!next.start) {
    return;
  }

  /* 
  adjust start time if necessary.
  case start=08:00-20:00, overrideEnd=10:00
  expect start=10:00-20:00
  */
  switch (true) {
    // case earlyStart=08-12, start=16-20, overrideEnd=10
    // expect earlyStart=10, ...
    case next.earlyStart &&
      next.earlyEnd &&
      dtUtils.isWithinInterval(fromDate, { start: next.earlyStart, end: next.earlyEnd }) &&
      dtUtils.isBefore(fromDate, next.earlyEnd): {
      next.earlyStart = fromDate;
      break;
    }
    // case start=16-20, overrideEnd=17
    // expect start=17, no early, ...
    case next.start &&
      next.end &&
      dtUtils.isWithinInterval(fromDate, { start: next.start, end: next.end }): {
      next.start = fromDate;
      next.earlyStart = undefined;
      next.earlyEnd = undefined;
      break;
    }
    // case earlyStart=08-12,start=16-20, overrideEnd=14
    // expect start=16, no early, ...
    case next.earlyStart &&
      next.earlyEnd &&
      next.start &&
      dtUtils.isWithinInterval(fromDate, {
        start: next.earlyEnd,
        end: next.start,
      }): {
      next.earlyStart = undefined;
      next.earlyEnd = undefined;
      break;
    }
  }

  return next;
}
// #endregion

// #endregion
