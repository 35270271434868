import { Customer, CustomerUpdateModel, StoreHeader } from '@flipdish/api-client-typescript';
import { createAction } from 'redux-act';

import {
  closeAllOtherNotifications,
  ERROR_KEY,
  notify,
  notifyError,
  notifySaved,
  SAVED_KEY,
} from '../../layouts/Notify/actions';
import { loadByAppIdHeaders } from '../../services/store.service';
import {
  downloadCSVService,
  downloadCustomerListTableService,
  getCustomerDetails,
  getCustomerDetailsTableService,
  getCustomerListTableService,
  getCustomerMarketingPaymentStatus,
  getCustomerOrderValues,
  setCustomerMarketingPaymentStatus,
} from './Customers.services';
import { CustomerManagementDetailsTableResponse, SerializedCustomerResponse } from './types';

//#region Customer List
export const resetCustomerList = createAction('RESET_CUSTOMER_LIST');

export const getCustomerDataSuccess = createAction<SerializedCustomerResponse['data']>(
  'GET_CUSTOMER_DATA_SUCCESS'
);
export const getCustomerDataFailure = createAction<string | null>('GET_CUSTOMER_DATA_FAILURE');
export const getCustomerDataRequest = createAction('GET_CUSTOMER_DATA_REQUEST');

export const getCustomerListTableData = (
  SortDirection: 'asc' | 'desc',
  SortBy: string,
  Page: number,
  PageSize: number,
  searchTerm?: string,
  stores?: number[] | string,
  languageCode?: string,
  exportAsCsv?: boolean
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const AppId = state.currentApp.AppId as string;

    try {
      if (exportAsCsv) {
        await downloadCustomerListTableService({
          AppId,
          Page: Page + 1,
          PageSize,
          searchTerm,
          stores,
          SortDirection,
          SortBy,
          languageCode,
        });
        return;
      }
      dispatch(getCustomerDataRequest());
      const data = await getCustomerListTableService({
        AppId,
        Page: Page + 1,
        PageSize,
        searchTerm,
        stores,
        SortDirection,
        SortBy,
      });

      dispatch(
        getCustomerDataSuccess({
          result: data.result,
          pagination: data.pagination,
        })
      );
    } catch (error) {
      dispatch(getCustomerDataFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};

export const downloadCustomersCSV = (Guid: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      const result = await downloadCSVService(AppId, Guid);
      dispatch(notify({ message: 'CSV_report_downloaded', variant: 'success', translate: true }));
      return result;
    } catch (error) {
      return dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};

//#endregion

//#region Customer Order Values + Journey
export const getCustomerOrderValuesDataSuccess = createAction<Reports.CustomerOrdersOverview>(
  'GET_CUSTOMER_ORDER_VALUES_SUCCESS'
);
export const getCustomerOrderValuesDataFailure = createAction<string | null>(
  'GET_CUSTOMER_ORDER_VALUES_FAILURE'
);
export const getCustomerOrderValuesLoading = createAction<boolean>(
  'GET_CUSTOMER_ORDER_VALUES_LOADING'
);
export const getCustomerOrderValue = (customerId: string, storeFilter?: number[]) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      dispatch(getCustomerOrderValuesLoading(true));
      const result = await getCustomerOrderValues(AppId, customerId, storeFilter);
      dispatch(getCustomerOrderValuesDataSuccess(result));
      dispatch(getCustomerOrderValuesLoading(false));
    } catch (error) {
      dispatch(getCustomerOrderValuesLoading(false));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(getCustomerOrderValuesDataFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};

export const resetCustomerOrderValues = createAction('RESET_CUSTOMER_ORDER_VALUES_SUCCESS');
//#endregion

//#region Customer Details
//#region GET Customer Details
export const getCustomerDetailsSuccess = createAction<Reports.CustomerDetail>(
  'GET_CUSTOMER_DETAILS_SUCCESS'
);
export const getCustomerDetailsFailure = createAction<string | null>(
  'GET_CUSTOMER_DETAILS_FAILURE'
);
export const getCustomerDetailsLoading = createAction<boolean>('GET_CUSTOMER_DETAILS_LOADING');

export const getCustomerDetail = (customerId: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      dispatch(getCustomerDetailsLoading(true));
      const result = await getCustomerDetails(AppId, customerId);
      dispatch(getCustomerDetailsSuccess(result));
      dispatch(getCustomerDetailsLoading(false));
    } catch (error) {
      dispatch(getCustomerDetailsLoading(false));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(getCustomerOrderValuesDataFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
//#endregion

//#region GET Customer Status
export const getCustomerStatusSuccess = createAction<Customer>('GET_CUSTOMER_STATUS_SUCCESS');
export const getCustomerStatusFailure = createAction<string | null>('GET_CUSTOMER_STATUS_FAILURE');
export const getCustomerStatusLoading = createAction<boolean>('GET_CUSTOMER_STATUS_LOADING');

export const getCustomerStatus = (customerId: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      dispatch(getCustomerStatusLoading(true));
      const result = await getCustomerMarketingPaymentStatus(AppId, Number(customerId));
      dispatch(getCustomerStatusSuccess(result));
      dispatch(getCustomerStatusLoading(false));
    } catch (error) {
      dispatch(getCustomerStatusLoading(false));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(getCustomerStatusFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
//#endregion

//#region SET Customer Status
export const setCustomerStatusSuccess = createAction<CustomerUpdateModel>(
  'SET_CUSTOMER_STATUS_SUCCESS'
);
export const setCustomerStatusFailure = createAction<string | null>('SET_CUSTOMER_STATUS_FAILURE');

export const setCustomerStatus = (customerId: string, updatedCustomer: CustomerUpdateModel) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      const result = await setCustomerMarketingPaymentStatus(
        AppId,
        Number(customerId),
        updatedCustomer
      );
      dispatch(setCustomerStatusSuccess(result));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(setCustomerStatusFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
//#endregion

// #region SET Store Filter
export const setListStoreFilter = createAction<number[]>('CUSTOMER_LIST_SET_STORE_FILTER');
export const setDetailsStoreFilter = createAction<number[]>('CUSTOMER_DETAILS_SET_STORE_FILTER');

export const setCustomerStoresFilter = (stores: number[], component: 'list' | 'details') => {
  return async (dispatch: ThunkDispatch) => {
    if (component === 'list') {
      dispatch(setListStoreFilter(stores));
    } else {
      dispatch(setDetailsStoreFilter(stores));
    }
  };
};
// #endregion

// #region SET Search Filter
export const setListSearchFilter = createAction<string>('CUSTOMER_LIST_SET_SEARCH_FILTER');

export const setCustomerSearchFilter = (query: string) => {
  return async (dispatch: ThunkDispatch) => {
    dispatch(setListSearchFilter(query));
  };
};
// #endregion

//#region Customer Details Table
export const getCustomerDetailsTableSuccess = createAction<
  CustomerManagementDetailsTableResponse['data']
>('GET_CUSTOMER_DETAILS_TABLE_SUCCESS');
export const getCustomerDetailsTableFailure = createAction<string | null>(
  'GET_CUSTOMER_DETAILS_TABLE_FAILURE'
);

export const getCustomerDetailsTableData = (
  customerId: string,
  languageCode: string,
  SortDirection: 'asc' | 'desc',
  SortBy: string,
  Page: number,
  PageSize: number,
  columnNames: string[],
  storeFilter?: number[]
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;

    try {
      const data = await getCustomerDetailsTableService({
        AppId,
        columnNames,
        customerId,
        languageCode,
        Page: Page + 1,
        PageSize,
        stores: storeFilter,
        SortDirection,
        SortBy,
      });
      dispatch(
        getCustomerDetailsTableSuccess({
          result: data.result,
          pagination: data.pagination,
        })
      );
    } catch (error) {
      dispatch(getCustomerDetailsTableFailure(error));
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
//#endregion

//#region GET store headers
export const getStoreHeaders = createAction<StoreHeader[]>('GET_STORE_HEADERS');

export const getStoreHeaderList = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      const storeHeaders = await loadByAppIdHeaders({ appId });
      storeHeaders && dispatch(getStoreHeaders(storeHeaders));
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
//#endregion

//#endregion
