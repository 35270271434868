import {
  QueryFunction,
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';

export function useQuerySalesChannelsHook<T>(
  fetchData: QueryFunction<T>,
  queryKey: [QueryKey],
  enabled: boolean,
  refetchOnMount = false
): {
  isLoading: boolean;
  data?: T;
  isError: boolean;
  isFetched: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<T, unknown>>;
} {
  const {
    isPending: isLoading,
    data,
    isError,
    isFetched,
    refetch,
  } = useQuery<T>({
    queryKey,
    queryFn: fetchData,
    enabled,
    gcTime: 1000 * 30,
    refetchOnMount,
  });

  return {
    isLoading,
    data,
    isError,
    isFetched,
    refetch,
  };
}

export type QueryKey = 'assignedChannels' | 'channel' | 'channels' | 'locationAreas';
