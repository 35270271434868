import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { InputField } from '@fd/ui/atoms/InputField';
import { Typography } from '@fd/ui/atoms/Typography';
import { FormSection } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  descriptionContainer: {
    marginBottom: theme.spacing(3),
  },
  inputField: {
    maxWidth: 146,

    '& input': {
      width: 31,
    },
  },
  leadTimeEndAdornment: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(0.2),
  },
}));

type Props = {
  description: string;
  isDisabled: boolean;
  isLoading: boolean;
  onBlur: (value: number) => void;
  translate: TranslateFunction;
  value?: number;
};

export const LeadTimeFormField = (props: Props) => {
  const { description, isDisabled, isLoading, onBlur, translate, value } = props;
  const classes = useStyles();

  return (
    <FormSection isLoading={isLoading} sectionTitle={translate('Auto_accept_lead_time') as string}>
      <div className={classes.descriptionContainer}>
        <Typography variant="caption">{description}</Typography>
      </div>
      <InputField
        className={classes.inputField}
        defaultValue={value}
        disabled={isDisabled}
        fdKey="lead-time-container"
        inputProps={{
          'data-fd': 'lead-time-input',
        }}
        InputProps={{
          endAdornment: (
            <Typography className={classes.leadTimeEndAdornment}>{translate('mins')}</Typography>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        label={translate('Preorder_settings_lead_time_item_title') as string}
        name="LeadTime"
        onBlur={(e) => onBlur(parseInt(e.target.value) || 0)}
        onInput={(e) => {
          //@ts-ignore
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
        }} // Only way to set a maxLength on a number input
        type="number"
        variant="outlined"
      />
    </FormSection>
  );
};
