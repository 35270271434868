import React from 'react';

import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import { lazyWithRetry } from '../../../helpers/utilities';
import RMSPage from '../../RMS/components/RMSPage';
import { RMSModules } from '../../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../../RMS/utils/rmsSubscriptions';

const SettingsList = lazyWithRetry(() => import('./SettingsList'));
const SalesChannelList = lazyWithRetry(() => import('../../RMS/SalesChannels/SalesChannelList'));

const POSPage = ({ translate, selectedProperty, currentApp }: MappedState) => {
  const history = useHistory();
  const handleChannelClick = (salesChannel) => {
    history.push(
      `/${currentApp?.AppId}/sales-channels/point-of-sale/property/${selectedProperty?.id}/sales-channel/${salesChannel.salesChannelId}`
    );
  };
  const tabItems = [
    {
      label: translate('Sales_Channels') as string,
      component: (
        <LazyComponent>
          <SalesChannelList
            filterByType={[SalesChannelType.POS]}
            onChannelClick={handleChannelClick}
          />
        </LazyComponent>
      ),
      queryString: 'sales-channels',
    },
    {
      label: translate('Devices') as string,
      component: (
        <LazyComponent>
          <RMSPage
            pageLayoutEnabled={false}
            subscription={RMSSubscriptions.BASE}
            title={translate('Devices')}
            module={RMSModules.STATIONS}
            url={`configurations/system/${RMSModules.STATIONS}`}
            includePropertyIdInUrl
          />
        </LazyComponent>
      ),
      queryString: 'devices',
    },
    {
      label: translate('Settings') as string,
      component: (
        <LazyComponent>
          <SettingsList />
        </LazyComponent>
      ),
      queryString: 'settings',
    },
  ];
  return (
    <PageLayout documentTitle={'Point_of_Sale'} title={translate('Point_of_Sale')}>
      <Tabs tabItems={tabItems} />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  translate: getTranslate(state?.locale),
  selectedProperty: state.rms?.selectedProperty,
  currentApp: state.currentApp,
});
export default connect(mapStateToProps)(POSPage);
