import { StoreHeader } from '@flipdish/api-client-typescript';

import { OrdersByStore, RejectionsByStore } from '../components/Tables/types';
import {
  GET_STORE_HEADERS,
  GET_STORE_SEARCH_FAILURE,
  GET_STORE_SEARCH_REQUEST,
  GET_STORE_SEARCH_SUCCESS,
  ORDER_BY_STORES_TABLE_SUCCESS,
  ORDERS_REJECTED_BY_STORE_TABLE_SUCCESS,
  ORDERS_REJECTED_TABLE_SUCCESS,
  ORDERS_TABLE_SUCCESS,
  RESET_STORE_SEARCH,
} from '../OrderReport.actions';
import { OrdersTableData } from '../types';

type DefaultState = typeof defaultState;
const defaultState = {
  storeHeaders: undefined as undefined | StoreHeader[],
  ordersByStoreTable: undefined as undefined | OrdersByStore[],
  ordersByStoreTablePagination: { page: 0, pageSize: 0, total: 0 },
  //#region rejected orders
  rejectedOrdersTableData: undefined as undefined | OrdersTableData[],
  rejectedOrdersTablePagination: { page: 0, pageSize: 0, total: 0 },
  //#endregion
  rejectionsByStoreTable: undefined as undefined | RejectionsByStore[],
  rejectionsByStoreTablePagination: { page: 0, pageSize: 0, total: 0 },
  //#region orders
  ordersTableData: undefined as undefined | OrdersTableData[],
  ordersTablePagination: { page: 0, pageSize: 0, total: 0 },
  //#endregion
  storeCurrencyFilterLoading: true,
};

const buildPagerByPayload = (payload) => {
  const { pagination } = payload;

  const { page, pageSize } = pagination;

  const initial = page * pageSize;

  const end = initial + pageSize;

  return {
    initial,
    end,
  };
};
export default function OrderReportsReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case ORDER_BY_STORES_TABLE_SUCCESS: {
      const { result, pagination } = action.payload;

      const { initial, end } = buildPagerByPayload(action.payload);
      return {
        ...state,
        ordersByStoreTable: result.slice(initial, end),
        ordersByStoreTablePagination: pagination,
      };
    }

    case ORDERS_TABLE_SUCCESS: {
      const { ordersTableData, ordersTablePagination } = action.payload;
      return { ...state, ordersTableData, ordersTablePagination };
    }

    case ORDERS_REJECTED_TABLE_SUCCESS: {
      const { rejectedOrdersTableData, rejectedOrdersTablePagination } = action.payload;
      return { ...state, rejectedOrdersTableData, rejectedOrdersTablePagination };
    }
    case ORDERS_REJECTED_BY_STORE_TABLE_SUCCESS: {
      const { result, pagination } = action.payload;
      const { initial, end } = buildPagerByPayload(action.payload);

      return {
        ...state,
        rejectionsByStoreTable: result.slice(initial, end),
        rejectionsByStoreTablePagination: pagination,
      };
    }

    case GET_STORE_HEADERS:
      return {
        ...state,
        storeHeaders: action.payload,
      };

    case RESET_STORE_SEARCH:
    case GET_STORE_SEARCH_REQUEST:
      return { ...state, storeCurrencyFilterLoading: true };

    case GET_STORE_SEARCH_FAILURE:
    case GET_STORE_SEARCH_SUCCESS:
      return { ...state, storeCurrencyFilterLoading: false };

    default:
      return state;
  }
}
