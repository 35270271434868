import React from 'react';

import { AppStoreApp } from '@flipdish/api-client-typescript';
import { Translate } from 'react-localize-redux';

export const translateAppStoreEnums = (label: string) => {
  switch (label) {
    case String(AppStoreApp.ConfigurationTypeEnum.FlipdishHosted):
      return <Translate id="App_store_app_select_FlipdishHosted" />;
    case String(AppStoreApp.ConfigurationTypeEnum.ExternalLink):
      return <Translate id="App_store_app_select_ExternalLink" />;

    case String(AppStoreApp.StoreSelectorTypeEnum.None):
      return <Translate id="App_store_app_select_None" />;
    case String(AppStoreApp.StoreSelectorTypeEnum.Single):
      return <Translate id="App_store_app_select_Single" />;
    case String(AppStoreApp.StoreSelectorTypeEnum.Multiple):
      return <Translate id="App_store_app_select_Multiple" />;

    case String(AppStoreApp.CategoriesEnum.PointOfSale):
      return <Translate id="Point_of_Sale" />;
    case String(AppStoreApp.CategoriesEnum.DeliveryAndLogistics):
      return <Translate id="Delivery_and_Logistics" />;
    case String(AppStoreApp.CategoriesEnum.CRM):
      return <Translate id="CRM" />;
    case String(AppStoreApp.CategoriesEnum.GiftCard):
      return <Translate id="Gift_Card" />;
    case String(AppStoreApp.CategoriesEnum.Accounting):
      return <Translate id="Accounting" />;
    case String(AppStoreApp.CategoriesEnum.LoyaltyAndMarketing):
      return <Translate id="Loyalty_and_Marketing" />;
    case String(AppStoreApp.CategoriesEnum.Other):
      return <Translate id="Other" />;

    case String(AppStoreApp.CountriesEnum.GB):
      return <Translate id="United_Kingdom_of_Great_Britain_and_Northern_Ireland" />;
    case String(AppStoreApp.CountriesEnum.IE):
      return <Translate id="Country_Ireland" />;
    case String(AppStoreApp.CountriesEnum.FR):
      return <Translate id="Country_France" />;
    case String(AppStoreApp.CountriesEnum.ES):
      return <Translate id="Country_Spain" />;
    case String(AppStoreApp.CountriesEnum.PT):
      return <Translate id="Country_Portugal" />;
    case String(AppStoreApp.CountriesEnum.IT):
      return <Translate id="Country_Italy" />;
    case String(AppStoreApp.CountriesEnum.DE):
      return <Translate id="Country_Germany" />;
    case String(AppStoreApp.CountriesEnum.PL):
      return <Translate id="Country_Poland" />;
    case String(AppStoreApp.CountriesEnum.BE):
      return <Translate id="Country_Belgium" />;
    case String(AppStoreApp.CountriesEnum.LU):
      return <Translate id="Country_Luxembourg" />;
    case String(AppStoreApp.CountriesEnum.NL):
      return <Translate id="Country_Netherlands" />;
    case String(AppStoreApp.CountriesEnum.US):
      return <Translate id="United_States_of_America" />;
    case String(AppStoreApp.CountriesEnum.CA):
      return <Translate id="Country_Canada" />;
    case String(AppStoreApp.CountriesEnum.BG):
      return <Translate id="Country_Bulgaria" />;
    case String(AppStoreApp.CountriesEnum.MX):
      return <Translate id="Country_Mexico" />;
    case String(AppStoreApp.CountriesEnum.AU):
      return <Translate id="Country_Australia" />;
    case String(AppStoreApp.CountriesEnum.NZ):
      return <Translate id="Country_New_Zealand" />;

    case String(AppStoreApp.TeammateAppAccessLevelEnum.Owner):
      return <Translate id="Owner" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.StoreOwner):
      return <Translate id="Store_owner" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.ManagedOwner):
      return <Translate id="Managed_owner" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.Integrator):
      return <Translate id="Integrator" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.StoreManager):
      return <Translate id="Store_manager" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.StoreStaff):
      return <Translate id="Store_staff" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.StoreReadOnlyAccess):
      return <Translate id="Store_readonly_access" />;
    case String(AppStoreApp.TeammateAppAccessLevelEnum.FinanceManger):
      return <Translate id="Finance_manager" />;
    default:
      return label;
  }
};
