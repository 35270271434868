import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import {
  createRedirectUri,
  deleteRedirectUri,
  getOAuthAppById,
  getOAuthAppRedirectUris,
} from '../actions';
import OAuthAppCardLoading from './OAuthAppCardLoading';
import OAuthAppCreateRedirectUriForm from './OAuthAppCreateRedirectUriForm';
import OAuthAppDeleteItemDialog from './OAuthAppDeleteItemDialog';

const useStyles = makeStyles((theme: Theme) => ({
  verticalPadding: {
    paddingTop: '6px',
    paddingBottom: '6px',
    flexWrap: 'wrap',
  },
  zeroPadding: {
    padding: '0px',
  },
  row: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  icon: {
    '&:hover,&:focus': {
      cursor: 'pointer',
      color: '#05149e',
    },
  },
  item: {
    wordWrap: 'break-word',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#ff0046',
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
}));

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const OAuthAppRedirectUris: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    loadRedirectUris,
    redirectUris,
    oauthApp,
    translate,
    isLoading,
    createRedirectUri,
    deleteRedirectUri,
    loadOAuthApp,
    currentApp,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    loadRedirectUris();
    loadOAuthApp();
  }, []);

  useEffect(() => {
    setDeleteDialogOpen(false);
  }, [redirectUris]);

  const [hoveredRow, setHoveredRow] = useState<number | null>();
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUriId, setSelectedUriId] = useState();

  return (
    <PageLayout
      documentTitle="Redirect_uris"
      caption={oauthApp.OauthAppName}
      title={translate('Redirect_uris')}
      toParent={`/${currentApp.AppId}/developers/${oauthApp.OAuthAppId}`}
      actions={() => (
        <Fab
          variant="extended"
          color="secondary"
          disabled={isLoading}
          aria-label={translate('Add_new') as string}
          className={classes.button}
          onClick={() => setCreateDialogOpen(true)}
          data-fd="oauthapp-add"
        >
          <AddIcon className={classes.extendedIcon} />
          {translate('Add_new')}
        </Fab>
      )}
    >
      {!redirectUris.length ? (
        isLoading ? (
          <OAuthAppCardLoading />
        ) : (
          <EmptyComponent
            title="Empty_redirect_uri_list_header"
            subtitle="Empty_redirect_uri_list_subheader"
          />
        )
      ) : (
        <PaperContainer fluid>
          <List className={classes.zeroPadding} onMouseLeave={(e) => setHoveredRow(null)}>
            {redirectUris.map((uri, index) => (
              <div key={index} className={classes.row}>
                {index > 0 && <Divider />}
                <ListItem
                  onMouseEnter={(e) => setHoveredRow(index)}
                  className={`${classes.item} ${HORIZONTAL_SPACE_CLASSNAME}`}
                >
                  <ListItemText primary={uri.Uri} className={classes.verticalPadding} />
                  <ListItemSecondaryAction>
                    {hoveredRow === index && (
                      <IconButton
                        data-fd="delete_redirect_uri"
                        onClick={(e) => {
                          setDeleteDialogOpen(true);
                          setSelectedUriId(uri.Id);
                        }}
                      >
                        <DeleteOutlined className={classes.icon} />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            ))}
          </List>
        </PaperContainer>
      )}
      <OAuthAppCreateRedirectUriForm
        items={redirectUris}
        setDialogOpen={setCreateDialogOpen}
        isDialogOpen={isCreateDialogOpen}
        submit={createRedirectUri}
        isLoading={isLoading}
      />

      <OAuthAppDeleteItemDialog
        setDialogOpen={setDeleteDialogOpen}
        isDialogOpen={isDeleteDialogOpen}
        submit={() => deleteRedirectUri(selectedUriId)}
        isLoading={isLoading}
        title="Delete_redirect_uri"
        description="Confirm_redirect_uri_deletion"
        submitText="Remove"
      />
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  const {
    match: {
      params: { OAuthAppId },
    },
  } = ownProps;
  return {
    loadRedirectUris: () => {
      dispatch(getOAuthAppRedirectUris(OAuthAppId));
    },
    createRedirectUri: (uri) => {
      return dispatch(createRedirectUri(OAuthAppId, uri, true));
    },
    deleteRedirectUri: (uriId) => {
      dispatch(deleteRedirectUri(OAuthAppId, uriId));
    },
    loadOAuthApp: () => dispatch(getOAuthAppById(OAuthAppId)),
  };
};

const mapStateToProps = (state) => {
  const {
    currentApp,
    developers: { oauthApp, redirectUris, isLoading },
    locale,
  } = state;

  return {
    currentApp,
    oauthApp,
    redirectUris,
    isLoading,
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuthAppRedirectUris);
