import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import LoadingButton from '@fd/ui/LoadingButton';

const useStyles = makeStyles((theme: Theme) => ({
  buttonDefault: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '48px',
    },
  },
  buttonPrimary: {
    backgroundColor: '#ff0046',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#b20031',
    },
    '&:active': {
      backgroundColor: '#b20031',
    },
    letterSpacing: '1.4px',
    [theme.breakpoints.down('lg')]: {
      letterSpacing: '1.3px',
    },
  },
  loadingButtonWrapper: {
    margin: 0,
  },

  link: {
    position: 'relative',
    color: '#05149e',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  formActions: {
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row-reverse',
    },
  },
}));

type Props = {
  goBack?: () => void;
  isSubmitting: boolean;
  isValid?: boolean;
  submitForm?: () => Promise<void>;
};

export default function ActionButtonsOnMapForm({ isSubmitting, goBack, submitForm }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.formActions}
      >
        <Grid item xs={12} sm={5}>
          <LoadingButton
            wrapperClassName={classes.loadingButtonWrapper}
            loading={isSubmitting}
            fullWidth
            disabled={isSubmitting}
            color={'primary'}
            variant={'contained'}
            type={submitForm ? 'button' : 'submit'}
            onClick={submitForm && submitForm}
            fdKey="confirm-address"
            size="large"
            className={clsx(classes.buttonDefault, classes.buttonPrimary)}
          >
            <Translate id="Confirm_Address" />
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant={'text'}
            onClick={goBack}
            className={clsx(classes.buttonDefault, classes.link)}
            type="button"
            fdKey="go-back"
            fullWidth
            disabled={isSubmitting}
          >
            <Translate id="Go_back" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
