type Options = {
  width: number;
  height?: number;
  png?: boolean;
};

type BackgroundOptions = {
  width?: number;
  height?: number;
  png?: boolean;
  skip?: boolean;
};

const pxr = Math.round(
  window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth
);

export const getBackground = ({
  src = '',
  options,
}: {
  src?: string;
  options: BackgroundOptions;
}) => {
  const { width, height, png, skip } = options;

  if (skip) {
    return src;
  }

  const dynamicPart = `${src}?${height ? `h=${height}&` : ''}${width ? `w=${width}&` : ''}${
    png ? 'fm=png32' : ''
  }`;

  return `${dynamicPart}&dpr=${pxr}`;
};

export default (src: string, options: Options) => {
  const { width, height, png } = options;
  const dynamicPart = `${src}?w=${width}&h=${height || width}${png ? '&fm=png32' : ''}`;

  return `${dynamicPart} 1x, ${dynamicPart}&dpr=2 2x, ${dynamicPart}&dpr=3 3x`;
};
