import { useMutation } from '@tanstack/react-query';

import { NotifyProps } from '../layouts/Notify/actions';

export function useMutationImageUploadHook(
  postData: (appId: string, newImage: File) => Promise<any>,
  appId: string,
  onImageUploaded: (imageFileName: string) => void,
  notify: (nofifyProps: NotifyProps) => void,
  useFullUrl = false
) {
  return useMutation({
    mutationFn: (newImage: File) => {
      return postData(appId, newImage);
    },

    onSuccess: (data) => {
      const imageFileName = useFullUrl
        ? data?.Data?.ImageUri
        : data?.Data?.ImageUri?.split('/')?.pop();
      onImageUploaded(imageFileName);
    },

    onError: () => {
      notify({
        variant: 'error',
        translate: true,
        message: 'Products_ToastMessage_Error',
      });
    },
  });
}
