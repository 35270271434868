import React from 'react';

import LoadingIcon from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Translate } from 'react-localize-redux';

export default () => (
  <Grid container alignItems="center">
    <Grid item>
      <LoadingIcon size={20} style={{ color: '#fff', marginRight: '16px' }} />
    </Grid>
    <Grid item>
      <Translate>{(translate) => translate('Loading') + '...'}</Translate>
    </Grid>
  </Grid>
);
