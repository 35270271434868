import React, { useState } from 'react';

import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    backgroundColor: 'inherit',
    marginBottom: -1,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      minHeight: 0,
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const useStyles = makeStyles({
  expansionPanel: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  header: { fontWeight: 500 },
  expansionPanelSummary: { marginTop: '8px' },
  expansionPanelDetails: { marginBottom: '16px', padding: 0 },
  listSubHeader: { fontSize: '1rem', color: 'rgba(0, 0, 0, 0.87)', lineHeight: '20px' },
  noPadding: { padding: 0 },
  noMargin: { margin: 0 },
});

type Props = { DeviceType: string };
const PinExpansionPanel = (props: Props) => {
  const { DeviceType } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(true);

  const handleChange = () => {
    setExpanded(!expanded);
    setShowContent(!showContent);
  };

  return (
    <ExpansionPanel onChange={handleChange} classes={{ root: classes.expansionPanel }}>
      <ExpansionPanelSummary
        aria-controls="pin-help-content"
        id="pin-help-header"
        style={{ visibility: showContent ? 'visible' : 'hidden' }}
        className={classes.expansionPanelSummary}
      >
        <Typography variant="caption" color="primary">
          <Translate id={'How_do_I_get_my_pin'} />
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
        <Typography variant="body1" className={classes.header}>
          <Translate id={'To_get_your_pin'} />
        </Typography>
      </ExpansionPanelDetails>

      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
        <List
          subheader={
            <ListSubheader className={classes.listSubHeader} disableGutters>
              <Translate id={'If_you_have_a_Flipdish_'} data={{ DeviceType }} />
            </ListSubheader>
          }
          dense
          component="ol"
          disablePadding
        >
          <ListItem className={classes.noPadding}>
            <ListItemText
              className={classes.noMargin}
              secondary={<Translate id={'Turn_on_'} data={{ DeviceType }} />}
            />
          </ListItem>
          <ListItem className={classes.noPadding}>
            <ListItemText
              className={classes.noMargin}
              secondary={<Translate id={'Wait_for_pin_to_display'} />}
            />
          </ListItem>
        </List>
      </ExpansionPanelDetails>

      {DeviceType === 'kiosk' && (
        <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
          <List
            subheader={
              <ListSubheader className={classes.listSubHeader} disableGutters>
                <Translate id={'If_you_have_your_own_Android_device'} />
              </ListSubheader>
            }
            component="ol"
            disablePadding
          >
            <ListItem className={classes.noPadding}>
              <ListItemText
                className={classes.noMargin}
                secondary={<Translate id={'Download_APK'} />}
              />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemText
                className={classes.noMargin}
                secondary={<Translate id={'Install_APK_on_device'} />}
              />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemText
                className={classes.noMargin}
                secondary={<Translate id={'Launch_kiosk_app'} />}
              />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemText
                className={classes.noMargin}
                secondary={<Translate id={'Wait_for_pin_to_display_android'} />}
              />
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      )}
    </ExpansionPanel>
  );
};

export default PinExpansionPanel;
