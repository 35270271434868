import React, { ReactNode } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { SkeletonLoader } from '@fd/ui/atoms';

import { Typography } from '../../atoms/Typography';

export type StyleProps = {
  textContainerAlignSelf: string | null;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  title: {
    color: theme.palette.grey[600],
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  textContainer: {
    alignSelf: (props) => props.textContainerAlignSelf ?? 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

export type FormSectionProps = {
  isLoading?: boolean;
  sectionTitle: string;
  showDivider?: boolean;
  children: ReactNode;
  textContainerAlignSelf?: string;
};

export const FormSection = (props: FormSectionProps): JSX.Element => {
  const {
    isLoading = false,
    sectionTitle,
    showDivider = true,
    textContainerAlignSelf = null,
    children,
  } = props;

  const classes = useStyles({ textContainerAlignSelf: textContainerAlignSelf });

  return isLoading ? (
    <Grid container direction="row" className={classes.container}>
      <Grid className={classes.textContainer} item xs={12} sm={4}>
        <SkeletonLoader
          fdKey="form-section-title-loader"
          rows={[{ height: '35px', width: '60%' }]}
        />
      </Grid>
      <Grid className={classes.textContainer} item xs={12} sm={8}>
        <SkeletonLoader
          fdKey="form-section-content-loader"
          rows={[{ height: '80px', width: '100%' }]}
        />
      </Grid>
      <Grid container item md={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="row" className={classes.container}>
      <Grid className={classes.textContainer} item xs={12} sm={4}>
        <Typography className={classes.title} variant="body1">
          {sectionTitle}
        </Typography>
      </Grid>
      <Grid className={classes.textContainer} item xs={12} sm={8}>
        {children}
      </Grid>
      {showDivider && (
        <Grid container item md={12}>
          <Divider data-fd={`form-section-${sectionTitle}-divider`} className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};
