import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import useContextualStyles from '../contextualStyle';

type TelemetryButtonProps = {
  kioskId: string;
};

type Props = MappedState & TelemetryButtonProps;

const TelemetryButton = (props: Props) => {
  const { appId, kioskId, translate } = props;
  const classes = useContextualStyles();

  return (
    <div>
      <Link
        underline="none"
        data-fd={`Telemetry_Button`}
        component={RouterLink}
        to={`/${appId}/sales-channels/kiosks/${kioskId}/telemetry`}
      >
        <Typography className={classes.Button} variant="caption">
          {translate('Telemetry')}
        </Typography>
      </Link>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(TelemetryButton);
