import {
  AppCompliance,
  AppConfigUpdateModel,
  AppsApiFactory,
  IndexPageBase,
  Language,
  RestApiResultWebsiteImage,
  WebsiteApiFactory,
  WebsiteImage,
} from '@flipdish/api-client-typescript';
import {
  AppExternalServices,
  AppsApiFactory as AppsPrivateApiFactory,
} from '@flipdish/api-client-typescript/private/api';
import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const extraApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

const photonEditorApi = axios.create({
  baseURL: 'https://editor-api.portal.flipdish.com/api',
  withCredentials: true,
});

const appsApi = createApi(AppsApiFactory);

const websiteApi = createApi(WebsiteApiFactory);

const appsPrivateApi = createApi(AppsPrivateApiFactory);

const setAppConfig = async (appId: string, appConfigUpdate: AppConfigUpdateModel) => {
  try {
    const incomingMessage = await appsApi.setAppConfig(appId, appConfigUpdate);

    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const uploadAppConfigLogo = async (appId: string, data: FormData) => {
  try {
    await extraApi.post(`/api/v1.0/apps/${appId}/logo`, data);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setAppConfigLanguages = async (appId: string, languages: Language[]) => {
  try {
    await appsApi.setAppLanguages(appId, languages);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getWebsiteSettings = async (appId: string) => {
  try {
    const incomingMessage = await websiteApi.getIndexConfiguration(appId);

    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setWebsiteSettings = async (appId: string, indexPage: IndexPageBase) => {
  try {
    const incomingMessage = await websiteApi.setIndexConfiguration(appId, indexPage);

    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export enum ImageLocationEnum {
  IndexHeader = 4,
  IndexGallery = 7,
  IndexOpeningHoursHeader = 8,
  IndexTestimonialsHeader = 9,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GalleryHeader = 4,
  IndexAboutSectionLeft = 5,
  IndexAboutSectionRight = 6,
}

const uploadImage = async (appId: string, imageLocation: WebsiteImage.ImageLocationEnum, data) => {
  try {
    const incomingMessage = await extraApi.post<RestApiResultWebsiteImage>(
      `/api/v1.0/${appId}/website/image/${imageLocation}`,
      data
    );

    return incomingMessage.data.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const deleteImage = async (appId: string, imageId: number) => {
  try {
    await websiteApi.deleteWebsiteImage(appId, imageId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

//#region SetupDomain
const setAppHostName = async (appId: string, hostname: string, isEmbed?: boolean) => {
  try {
    await appsApi.setAppHostname(appId, hostname, isEmbed);
    // Used to update the hostname in the photon ecosystem
    await photonEditorApi.get(`/updateMetaData?appId=${appId}&type=hostname`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const checkDNS = async (appId: string) => {
  try {
    await websiteApi.websiteCheckNow(appId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getDNSStatus = async (appId: string) => {
  try {
    const incomingMessage = await appsApi.getAppHostnameStatus(appId);

    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getPanaceaUrl = async (appId: string) => {
  try {
    const incomingMessage = await appsApi.getPanaceaVanityUrl(appId);

    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setPanaceaUrl = async (appId: string, vanityUrl: string) => {
  try {
    await appsApi.setPanaceaVanityUrl(appId, vanityUrl);
    // Used to update the vanityUrl in the photon ecosystem
    await photonEditorApi.get(`/updateMetaData?appId=${appId}&type=vanityUrl`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getPanaceaUrlStatus = async (vanityUrl: string, appId: string) => {
  try {
    const incomingMessage = await appsApi.isPanaceaVanityUrlAvailable(vanityUrl, appId);
    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const getAppEmbeddedStatus = async (appId: string) => {
  try {
    const response = await appsPrivateApi.isAppEmbedded(appId);
    return response.Data.IsEmbedded;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
//#endregion SetupDomain

const getTrackingCodes = async (appId: string) => {
  try {
    const response = await appsPrivateApi.getExternalServices(appId);
    return response;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setTrackingCodes = async (appId: string, appExternalServices: AppExternalServices) => {
  try {
    await appsPrivateApi.setExternalTrackingCodes(appId, appExternalServices);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getsetSMSRestaurantName = async (appId: string) => {
  try {
    const response = await appsPrivateApi.getSmsRestaurantName(appId);
    return response;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setSMSRestaurantName = async (appId: string, smsRestaurantName: string) => {
  try {
    await appsPrivateApi.setSmsName(appId, smsRestaurantName);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const getCompliance = async (appId: string) => {
  try {
    const incomingMessage = await appsApi.getCompliance(appId);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const setCompliance = async (appId: string, complianceType: AppCompliance.ComplianceTypeEnum) => {
  try {
    // Generated typescript code has bug with wrong typing for enum.
    const incomingMessage = await appsApi.setCompliance(
      appId,
      complianceType as unknown as 'Default' | 'GdprCompliance'
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const toggleNextGenWeb = async (
  appId: string,
  hostname: string,
  vanityUrl: string,
  isNextGenWeb: boolean
) => {
  try {
    return await appsApi.toggleNextGenWeb(appId, hostname, vanityUrl, isNextGenWeb);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const WebsiteAndAppService = {
  setAppConfig,
  uploadAppConfigLogo,
  setAppConfigLanguages,
  getWebsiteSettings,
  setWebsiteSettings,
  uploadImage,
  deleteImage,
  setAppHostName,
  getDNSStatus,
  checkDNS,
  getPanaceaUrl,
  setPanaceaUrl,
  getPanaceaUrlStatus,
  getTrackingCodes,
  setTrackingCodes,
  getAppEmbeddedStatus,
  setSMSRestaurantName,
  getsetSMSRestaurantName,
  getCompliance,
  setCompliance,
  toggleNextGenWeb,
};

export default WebsiteAndAppService;
