import { RestApiPaginationResultOAuthTokenModel } from '@flipdish/api-client-typescript';

import { action } from '../../../actions/utils';
import { developersService } from '../../../services/developers.service';

// #region getAuthTokens

export const GET_OAUTHAPP_AUTH_TOKENS_REQUEST = 'GET_OAUTHAPP_AUTH_TOKENS_REQUEST';
export const GET_OAUTHAPP_AUTH_TOKENS_SUCCESS = 'GET_OAUTHAPP_AUTH_TOKENS_SUCCESS';
export const GET_OAUTHAPP_AUTH_TOKENS_FAILURE = 'GET_OAUTHAPP_AUTH_TOKENS_FAILURE';

export type GetOAuthAppAuthTokensRequest = ReturnType<typeof getOAuthAppAuthTokensRequest>;
export const getOAuthAppAuthTokensRequest = (appId: string, clientId: string) =>
  action(GET_OAUTHAPP_AUTH_TOKENS_REQUEST, { appId, clientId, isLoading: true });

export type GetOAuthAppAuthTokensSuccess = ReturnType<typeof getOAuthAppAuthTokensSuccess>;
export const getOAuthAppAuthTokensSuccess = (AuthTokens: RestApiPaginationResultOAuthTokenModel) =>
  action(GET_OAUTHAPP_AUTH_TOKENS_SUCCESS, AuthTokens);

export type GetOAuthAppAuthTokensFailure = ReturnType<typeof getOAuthAppAuthTokensFailure>;
export const getOAuthAppAuthTokensFailure = (error: Error, clientId: string) =>
  action(GET_OAUTHAPP_AUTH_TOKENS_FAILURE, { clientId, isLoading: false }, { error });

export function getOAuthAppAuthTokens(clientId: string, params?) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppAuthTokensRequest(appId, clientId));
      const result = await developersService.getOauthAppAuthTokens(clientId, appId, params);
      dispatch(getOAuthAppAuthTokensSuccess(result));
    } catch (error) {
      dispatch(getOAuthAppAuthTokensFailure(error, clientId));
    }
  };
}
// #endregion

// Region createAuthToken
export const CREATE_OAUTHAPP_ACCESS_TOKEN_REQUEST = 'CREATE_OAUTHAPP_ACCESS_TOKEN_REQUEST';
export const CREATE_OAUTHAPP_ACCESS_TOKEN_SUCCESS = 'CREATE_OAUTHAPP_ACCESS_TOKEN_SUCCESS';
export const CREATE_OAUTHAPP_ACCESS_TOKEN_FAILURE = 'CREATE_OAUTHAPP_ACCESS_TOKEN_FAILURE';

export type CreateOAuthAppAccessTokenRequest = ReturnType<typeof createAccessTokenRequest>;
export const createAccessTokenRequest = (appId: string, clientId: string) =>
  action(CREATE_OAUTHAPP_ACCESS_TOKEN_REQUEST, { appId, clientId, isLoading: true });

export type CreateOAuthAppAccessTokenSuccess = ReturnType<typeof createAccessTokenSuccess>;
export const createAccessTokenSuccess = (authToken: string) =>
  action(CREATE_OAUTHAPP_ACCESS_TOKEN_SUCCESS, { authToken, isLoading: false });

export type CreateOAuthAppAccessTokenFailure = ReturnType<typeof createAccessTokenFailure>;
export const createAccessTokenFailure = (error: Error, clientId: string) =>
  action(CREATE_OAUTHAPP_ACCESS_TOKEN_FAILURE, { clientId, isLoading: false }, { error });

export function createAccessToken(clientId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(createAccessTokenRequest(appId, clientId));
      const result = await developersService.createOauthAppAccessToken(clientId, appId);
      dispatch(createAccessTokenSuccess(result));
      dispatch(getOAuthAppAuthTokens(clientId));
    } catch (error) {
      dispatch(createAccessTokenFailure(error, clientId));
    }
  };
}
// End region

// Region revokeAuthToken
export const REVOKE_OAUTHAPP_ACCESS_TOKEN_REQUEST = 'REVOKE_OAUTHAPP_ACCESS_TOKEN_REQUEST';
export const REVOKE_OAUTHAPP_ACCESS_TOKEN_SUCCESS = 'REVOKE_OAUTHAPP_ACCESS_TOKEN_SUCCESS';
export const REVOKE_OAUTHAPP_ACCESS_TOKEN_FAILURE = 'REVOKE_OAUTHAPP_ACCESS_TOKEN_FAILURE';

export type RevokeOAuthAppAccessTokenRequest = ReturnType<typeof revokeAccessTokenRequest>;
export const revokeAccessTokenRequest = (appId: string, key: string) =>
  action(REVOKE_OAUTHAPP_ACCESS_TOKEN_REQUEST, { appId, key, isLoading: true });

export type RevokeOAuthAppAccessTokenSuccess = ReturnType<typeof revokeAccessTokenSuccess>;
export const revokeAccessTokenSuccess = (key: string) =>
  action(REVOKE_OAUTHAPP_ACCESS_TOKEN_SUCCESS, { key, isLoading: false });

export type RevokeOAuthAppAccessTokenFailure = ReturnType<typeof revokeAccessTokenFailure>;
export const revokeAccessTokenFailure = (error: Error, key: string) =>
  action(REVOKE_OAUTHAPP_ACCESS_TOKEN_FAILURE, { key, isLoading: false }, { error });

export function revokeAccessToken(key: string, clientId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(revokeAccessTokenRequest(appId, key));
      await developersService.revokeAccessToken(key, appId);
      dispatch(revokeAccessTokenSuccess(key));
      dispatch(getOAuthAppAuthTokens(clientId));
    } catch (error) {
      dispatch(revokeAccessTokenFailure(error, key));
    }
  };
}
// End region
