import React from 'react';

import TableCell, { type TableCellProps } from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import { type History } from 'history';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    display: 'block',
    wordBreak: 'break-all',
    padding: '16px 24px',
    textDecoration: 'none',
    color: 'inherit',
    height: '100%',
    outline: 0,
  },
});

type Props = { to?: History.LocationDescriptor } & TableCellProps;

export default (function ({ padding, to, ...props }: Props) {
  const classes = useStyles();

  return (
    <TableCell padding="none" {...props}>
      {to ? (
        <Link className={classes.link} to={to}>
          {props.children}
        </Link>
      ) : (
        props.children
      )}
    </TableCell>
  );
});
