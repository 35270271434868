import React, { useEffect, useState } from 'react';

import {
  BluetoothTerminalStatus,
  EmvTerminalWithAssignments,
} from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Switch from '@fd/ui/Switch/Switch';

import { notify, notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { kioskServices } from '../Kiosks.services';
import DisableCashModal from './DisableCashModal';
import KioskDetailSection from './KioskDetailSection';

type InnerProps = MappedDispatch;
type OuterProps = {
  appId: string;
  deviceId?: string;
  EMVTerminalsAssignedToApp: EmvTerminalWithAssignments[];
};
type Props = InnerProps & OuterProps;

const CashPaymentOption = ({
  appId,
  deviceId,
  EMVTerminalsAssignedToApp,
  notifyError,
  notifySaved,
}: Props) => {
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [cashEnabled, setCashEnabled] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stripeTerminal = queryClient.getQueryData<BluetoothTerminalStatus>([
      kioskServices.getStripeTerminalDetailsQueryKey,
      appId,
      deviceId,
    ]);

    if (
      !event.target.checked &&
      !stripeTerminal?.SerialNumber &&
      !EMVTerminalsAssignedToApp.length
    ) {
      setDialogOpen(true);
    } else {
      setCashEnabled(event.target.checked);
      mutation.mutate({
        isCashEnabled: event.target.checked,
      });
    }
  };

  const handleDisableCash = () => {
    setCashEnabled(false);
    mutation.mutate({ isCashEnabled: false });
    setDialogOpen(false);
  };

  const { data, isError, isPending } = useQuery({
    queryKey: [kioskServices.getCashPaymentMethodQueryKey, appId, deviceId],

    queryFn: () =>
      kioskServices.getCashPaymentMethod({
        appId,
        deviceId,
      }),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isError) {
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      });
    }

    data && setCashEnabled(data.IsCashVisibleToCustomer);
  }, [data, isError]);

  const mutation = useMutation({
    mutationFn: ({ isCashEnabled }: { isCashEnabled: boolean }) =>
      kioskServices.setCashPaymentMethod({
        appId,
        deviceId,
        isCashEnabled,
      }),

    onSuccess: (_data, { isCashEnabled }) => {
      dialogOpen && setDialogOpen(false);
      queryClient.setQueryData<{ isCashEnabled: boolean }>(
        [kioskServices.getCashPaymentMethodQueryKey],
        { isCashEnabled }
      );
      notifySaved();
    },

    onError: (_error, data) => {
      dialogOpen && setDialogOpen(false);
      setCashEnabled(!data.isCashEnabled);
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      });
    },
  });

  return (
    <>
      <KioskDetailSection
        alignChildren="bottom"
        description="Enable_disable_pay_at_counter"
        isLoading={isPending}
        title="Pay_at_counter"
      >
        {isPending ? (
          <>
            <Grid item xs={6}>
              <Skeleton width={'35%'} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton width={40} height={20} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Translate
                  id={cashEnabled ? 'Pay_at_counter_enabled' : 'Pay_at_counter_disabled'}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                edge="end"
                checked={cashEnabled}
                onChange={handleChange}
                disabled={isPending || mutation.isPending}
                inputProps={{
                  'aria-labelledby': `switch-cash-toggle`,
                }}
                fdKey={`switch-cash-toggle`}
              />
            </Grid>
          </>
        )}
      </KioskDetailSection>

      <DisableCashModal
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onContinue={handleDisableCash}
      />
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaved: () => {
    dispatch(
      notify({
        message: 'Success',
        variant: 'success',
        translate: true,
      })
    );
  },
});

export default connect(null, mapDispatchToProps)(CashPaymentOption);
