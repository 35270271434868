import React, { useState } from 'react';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { FormSection } from '@fd/ui/molecules/FormSection';

import { ReactComponent as NoteIcon } from '../../../../assets/images/StickyNote.svg';
import { NoteDialog } from './NoteDialog/NoteDialog';

const useStyles = makeStyles((theme: Theme) => ({
  addNoteButton: {
    marginTop: theme.spacing(2),
    width: '110px',
  },
  menuOptionsIcon: {
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '12px',
  },
  menuOptionsText: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  note: {
    marginLeft: theme.spacing(3),
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  noteContainer: {
    backgroundColor: '#EAF2FF',
    borderRadius: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  noteIcon: {
    flex: 'none',
    marginTop: theme.spacing(0.65),
  },
  optionsButton: {
    marginTop: theme.spacing(0.65),
  },
  textContainer: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

export type InternalNoteFormSectionProps = {
  canEdit?: boolean;
  onNoteChange: (newNote: string) => void;
  text: string;
  translate: TranslateFunction;
};

export const InternalNoteFormSection = (props: InternalNoteFormSectionProps): JSX.Element => {
  const { canEdit, onNoteChange, text, translate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleSetNote = (newNote: string) => {
    onNoteChange(newNote);
    setIsDialogOpen(false);
  };

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleEditNote = () => {
    setIsDialogOpen(true);
    handleCloseOptions();
  };

  const handleDeleteNote = () => {
    onNoteChange('');
    handleCloseOptions();
  };

  const renderNote = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        className={classes.noteContainer}
        wrap="nowrap"
      >
        <Grid className={classes.textContainer} container direction="row" item wrap="nowrap">
          <NoteIcon className={classes.noteIcon} />
          <Typography className={classes.note} variant="body2">
            {text}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.optionsButton}
            data-fd="note-options-button"
            onClick={handleOpenOptions}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
        <Menu
          elevation={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          id="note-options"
          open={Boolean(anchorEl)}
          onClose={handleCloseOptions}
        >
          <MenuItem data-fd="edit-note" onClick={() => handleEditNote()}>
            <CreateOutlinedIcon className={classes.menuOptionsIcon} />
            <Typography className={classes.menuOptionsText}>
              <Translate id="Edit" />
            </Typography>
          </MenuItem>
          <MenuItem data-fd="delete-note" onClick={() => handleDeleteNote()}>
            <DeleteIcon className={classes.menuOptionsIcon} />
            <Typography className={classes.menuOptionsText}>
              <Translate id="Delete" />
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    );
  };

  const renderAddNote = () => {
    return (
      <Grid container direction="column">
        <Typography variant="caption">{translate('Attach_a_note') as string}</Typography>
        <Button
          className={classes.addNoteButton}
          disabled={canEdit}
          fdKey={'add-internal-note-button'}
          onClick={() => setIsDialogOpen(true)}
          variant="secondary"
        >
          <Translate id="Add_note" />
        </Button>
      </Grid>
    );
  };

  return (
    <FormSection sectionTitle={translate('Internal_notes') as string}>
      <NoteDialog
        onCancel={() => setIsDialogOpen(false)}
        note={text}
        onSave={handleSetNote}
        open={isDialogOpen}
        translate={translate}
      />
      {text !== '' ? renderNote() : renderAddNote()}
    </FormSection>
  );
};
