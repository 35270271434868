import React, { useState } from 'react';

import { ServiceCharge } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { EnableSwitch } from '@fd/ui/atoms';

import warningAmberSVG from '../../../assets/images/warning-amber.svg';
import {
  onlyDecimalNumbersRegExp,
  onlyDecimalNumbersTwoPlacesRegExp,
} from '../../../helpers/validation';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import PercentageField from '../components/PercentageField';
import StoreRadioGroup from '../components/StoreRadioGroup';

const WarningAmber = () => <img src={warningAmberSVG} style={{ width: '24px' }} />;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: `${theme.spacing(5)}!important`,
    '& .MuiInputAdornment-positionStart': {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      paddingTop: '0 !important',
      paddingLeft: `${theme.spacing(3)}!important`,
      paddingRight: `${theme.spacing(3)}!important`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
    },
  },
  warningBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF2D5',
    padding: theme.spacing(2),
    margin: theme.spacing(-3),
    marginBottom: theme.spacing(3),
    '& span': {
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(2),
    },
  },
  sectionTitleGrid: {
    '& label': {
      lineHeight: 1.5,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0!important',
      '& label': {
        color: 'black',
      },
    },
  },
  sectionTitleGridNoDesc: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: `${theme.spacing(2)}!important`,
    },
  },
  percentageField: {
    width: 144,
  },
  sectionDivider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  },
  subSectionDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formHelpText: {
    lineHeight: 1.33,
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
  subSectionLabel: {
    color: 'black',
    lineHeight: 1.5,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = {
  serviceCharge?: ServiceCharge;
  onServiceChargeChange: (newServiceCharge: ServiceCharge) => void;
};

const StoreServiceChargeForm = ({ serviceCharge, onServiceChargeChange }: Props) => {
  const classes = useStyles();

  const [stateServiceCharge, setStateServiceCharge] = useState<ServiceCharge>(
    () =>
      serviceCharge || {
        Enabled: false,
        DisplayWithProcessingFee: false,
        IsOptional: false,
        PercentageValue: 0,
      }
  );

  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const newServiceCharge = {
      ...stateServiceCharge,
      [name]: checked,
    };

    setStateServiceCharge(newServiceCharge);
    onServiceChargeChange(newServiceCharge);
  };

  const handleRadioChange = (e, value) => {
    const IsOptional = value === 'optional';

    let newServiceCharge = {
      ...stateServiceCharge,
      IsOptional,
    };

    if (IsOptional) {
      newServiceCharge = {
        ...newServiceCharge,
        DisplayWithProcessingFee: false,
      };
    }

    setStateServiceCharge(newServiceCharge);
    onServiceChargeChange(newServiceCharge);
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const rate = parseFloat(value);

    if (onlyDecimalNumbersRegExp.test(value) && !onlyDecimalNumbersTwoPlacesRegExp.test(value)) {
      setErrorMessage(<Translate id="Two_decimal_places" />);
      return;
    }

    if (!value || !onlyDecimalNumbersRegExp.test(value) || isNaN(rate) || rate < 0 || rate > 100) {
      setErrorMessage(<Translate id="Tips_validation" />);
      return;
    }

    setErrorMessage(null);

    const newServiceCharge = {
      ...stateServiceCharge,
      [name]: rate,
    };

    setStateServiceCharge(newServiceCharge);
    onServiceChargeChange(newServiceCharge);
  };

  const radioGroupValues = [
    {
      value: 'mandatory',
      label: <Translate id="Always" />,
    },
    {
      value: 'optional',
      label: <Translate id="Optional" />,
    },
  ];

  return (
    <PaperContainer className={classes.root}>
      <div className={classes.warningBanner}>
        <WarningAmber />
        <span>
          <Translate id="Service_charge_only_available" />
        </span>
      </div>
      <GridContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid
          item
          xs={12}
          sm={5}
          className={clsx(
            classes.sectionTitleGrid,
            classes.sectionTitleGridNoDesc,
            classes.gridItem
          )}
        >
          <InputLabel>
            <Translate id="Service_charge_enable" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.gridItem}>
          <EnableSwitch
            name="Enabled"
            checked={stateServiceCharge.Enabled}
            onChange={handleSwitchChange}
            fdKey="servicecharge_enabled"
            autoFocus
          />
        </Grid>
      </GridContainer>
      <Divider className={classes.sectionDivider} />
      <GridContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={5} className={clsx(classes.sectionTitleGrid, classes.gridItem)}>
          <InputLabel>
            <Translate id="Service_charge_preferences" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.gridItem}>
          <FormHelperText className={classes.formHelpText} id="optional-service-charge-group-label">
            <Translate id="Optional_service_charge_desc" />
          </FormHelperText>
          <StoreRadioGroup
            aria-labelledby="optional-service-charge-group-label"
            name="optional-service-charge-group"
            value={stateServiceCharge.IsOptional ? 'optional' : 'mandatory'}
            onChange={handleRadioChange}
            values={radioGroupValues}
          />
        </Grid>
      </GridContainer>
      <Divider className={classes.sectionDivider} />
      <GridContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid
          item
          xs={12}
          sm={5}
          className={clsx(
            classes.sectionTitleGrid,
            classes.sectionTitleGridNoDesc,
            classes.gridItem
          )}
        >
          <InputLabel>
            <Translate id="Set_rates" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.gridItem}>
          <PercentageField
            id="service-charge-rate"
            name="PercentageValue"
            defaultValue={stateServiceCharge.PercentageValue}
            label={<Translate id="Rate" />}
            onChange={handleRateChange}
            className={classes.percentageField}
            error={errorMessage !== null}
            helperText={errorMessage}
          />
          {!stateServiceCharge.IsOptional && (
            <>
              <Divider className={classes.subSectionDivider} />
              <InputLabel className={classes.subSectionLabel}>
                <Translate id="Include_processing_fee" />
              </InputLabel>
              <FormHelperText className={classes.formHelpText}>
                <Translate id="Include_processing_fee_desc" />
              </FormHelperText>
              <EnableSwitch
                name="DisplayWithProcessingFee"
                checked={stateServiceCharge.DisplayWithProcessingFee}
                onChange={handleSwitchChange}
                fdKey="include_processing_fee"
                disabled={stateServiceCharge.IsOptional}
              />
            </>
          )}
        </Grid>
      </GridContainer>
    </PaperContainer>
  );
};

export default StoreServiceChargeForm;
