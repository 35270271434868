import React, { useCallback, useMemo } from 'react';

import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { notify, NotifyProps } from '../../layouts/Notify/actions';
import { copyToClipboard as copy } from '../utils';

const jsonLine =
  /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      fontSize: '11px',
      background: '#f6f6f6',
      border: '1px solid #f6f6f6',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    rootWithHover: {
      '&:hover': {
        cursor: 'pointer',
        borderColor: '#4a90e2',
        background: 'rgba(74, 144, 226, 0.1)',
        '& > span': {
          display: 'block',
        },
      },
    },
    content: {
      padding: theme.spacing(3),
      margin: 0,
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    key: {
      color: '#66079e',
    },
    value: {
      color: '#2c3675',
    },
    copyLabel: {
      display: 'none',
      fontSize: '14px',
      textTransform: 'uppercase',
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: 0,
      letterSpacing: 1.3,
      background: '#4a90e2',
      padding: '4px 5px 4px 8px',
    },
    input: {
      position: 'absolute',
      top: '-99999px',
    },
  });

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

type InnerProps = WithStyles<typeof styles> &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

type OuterProps = {
  data: Record<string, any> | string;
  name?: string;
  className?: string;
  noParse?: boolean;
  copyToClipboard?: boolean;
  inputId: string;
};

type Props = InnerProps & OuterProps;

export default compose<InnerProps, OuterProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(function ({
  classes,
  data,
  className,
  copyToClipboard,
  notify,
  translate,
  name = '',
  noParse,
  inputId,
}: Props) {
  const stringifiedData = useMemo((): string => {
    if (data) {
      if (noParse) {
        return String(data);
      }

      try {
        const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
        return JSON.stringify(parsedData, undefined, 3);
      } catch (err) {
        console.log(err);
        return '';
      }
    }

    return '';
  }, [data]);
  const jsonContent = useMemo(() => {
    const preparedData = stringifiedData
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    if (noParse) {
      return `<span class='${classes.key}'>${preparedData}</span>`;
    }

    return preparedData.replace(jsonLine, (match) => {
      let cls = classes.value;

      if (/^"/.test(match) && /:$/.test(match)) {
        cls = classes.key;
      }

      return `<span class='${cls}'>${match}</span>`;
    });
  }, [data]);

  const rootClassName = clsx({
    [classes.root]: true,
    [classes.rootWithHover]: copyToClipboard,
  });

  return (
    <div
      className={`${rootClassName} ${className}`}
      onClick={useCallback(() => {
        copy(null, inputId);
        notify({
          variant: 'success',
          message: `${name} ${translate('copied_to_clipboard')}`,
        });
      }, [data])}
    >
      <pre className={classes.content}>
        <code
          // dangerouslySetInnerHTML user has no input on this code. It is used to display data from BE like audit logs details.
          dangerouslySetInnerHTML={{
            __html: jsonContent,
          }}
        />
      </pre>
      {copyToClipboard && (
        <>
          <span className={classes.copyLabel}>
            <Translate id="Copy_to_clipboard" />
          </span>
          <input
            className={classes.input}
            id={inputId}
            type="text"
            value={stringifiedData}
            onChange={() => null}
          />
        </>
      )}
    </div>
  );
});
