import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import {
  SalesChannelType,
  useQueryRMSSalesChannels,
} from '@fd/customHooks/useQueryRMSSalesChannels';
import type { SalesChannel } from '@flipdish/orgmanagement';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WebsiteAndAppIcon from '@mui/icons-material/PhonelinkOutlined';
import StayCurrentPortraitOutlinedIcon from '@mui/icons-material/StayCurrentPortraitOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ErrorComponent from '@fd/ui/ErrorComponent';

import { ReactComponent as DeliverooLogo } from '../../../assets/images/deliveroo-filled.svg';
import EmptyIcon from '../../../assets/images/illust_store.svg';
import { ReactComponent as JustEatLogo } from '../../../assets/images/just-eat-filled.svg';
import { ReactComponent as UberEatsLogo } from '../../../assets/images/uber-eats-filled.svg';
import { KioskIcon } from '../../Kiosks/Components/KioskNestedMenuIcon';
import PropertyFilter from '../components/PropertyFilter';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
// #region styles
const StyledContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

const StyledCard = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(2),
  border: '1px solid #ddd',
  borderRadius: '8px',
  width: '300px',
  minHeight: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const StyledAddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const StyledStayCurrentPortraitOutlinedIcon = styled(StayCurrentPortraitOutlinedIcon)(
  ({ theme }) => ({
    fill: theme.palette.action.active,
    width: '35px',
    height: '35px',
  })
);

const StyledTvOutlinedIcon = styled(TvOutlinedIcon)(({ theme }) => ({
  svg: {
    fill: theme.palette.action.active,
  },
  fill: theme.palette.action.active,
  width: '35px',
  height: '35px',
}));

const StyledWebsiteAndAppIcon = styled(WebsiteAndAppIcon)(({ theme }) => ({
  fill: theme.palette.action.active,
  width: '35px',
  height: '35px',
}));

const StyledKioskIcon = styled(KioskIcon)(() => ({
  img: {
    width: '35px!important',
    height: '35px!important',
  },
}));
//#endregion

const SalesChannelIcon = ({ type }) => {
  switch (type) {
    case SalesChannelType.KIOSK:
    case SalesChannelType.FLIPDISH_KIOSK:
      return <StyledKioskIcon width={'35px'} />;
    case SalesChannelType.JUST_EATS:
      return <JustEatLogo />;
    case SalesChannelType.UBER_EATS:
      return <UberEatsLogo />;
    case SalesChannelType.DELIVEROO:
      return <DeliverooLogo />;
    case SalesChannelType.MOBILE_APP:
    case SalesChannelType.FLIPDISH_MOBILE_APP:
      return <StyledStayCurrentPortraitOutlinedIcon />;
    case SalesChannelType.WEB_APP:
    case SalesChannelType.FLIPDISH_WEB_APP:
      return <StyledWebsiteAndAppIcon />;
    default:
      return <StyledTvOutlinedIcon />;
  }
};

const Content = ({ isLoadingSalesChannels, salesChannelError, salesChannels, onChannelClick }) => {
  if (isLoadingSalesChannels) {
    return <CircularProgress />;
  }

  if (salesChannelError) {
    return <ErrorComponent title="Something_went_wrong" />;
  }

  if (!salesChannels || salesChannels.length === 0) {
    return (
      <EmptyComponent
        title="No_sales_channels_found"
        subtitle="Add_sales_channels_to_your_property"
        icon={EmptyIcon}
        noLink
      />
    );
  }
  const handleKeyDown = (event, salesChannel) => {
    if (event.key === 'Enter') {
      onChannelClick(salesChannel);
    }
  };

  return salesChannels?.map((salesChannel: SalesChannel) => (
    <StyledCard
      tabIndex={0}
      key={salesChannel?.salesChannelId}
      onClick={() => onChannelClick(salesChannel)}
      onKeyDown={(e) => handleKeyDown(e, salesChannel)}
      data-fd={salesChannel?.salesChannelId}
    >
      <Grid container>
        <Grid item xs={2} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          <SalesChannelIcon type={salesChannel?.type} />
        </Grid>
        <Grid paddingLeft={1} item xs={10}>
          <Typography variant="h5">{salesChannel.name}</Typography>
          <Typography variant="caption">{salesChannel.type}</Typography>
        </Grid>
      </Grid>
    </StyledCard>
  ));
};

type SalesChannelListProps = {
  filterByType?: SalesChannelType[];
  onChannelClick: (channel) => void;
  hideFilter?: boolean;
} & MappedState;
const SalesChannelList = (props: SalesChannelListProps) => {
  const {
    appId,
    filterByType = [],
    hideFilter,
    onChannelClick,
    selectedPropertyId,
    translate,
    orgId,
  } = props;
  const {
    data: commonData,
    isLoading: isLoadingCommonData,
    error: commonDataError,
  } = useQueryRMSCommonDataHook(appId, true);

  const history = useHistory();
  const params = useParams<{ propertyId: string }>();

  const propertyId = params.propertyId || selectedPropertyId;

  const orgPropertyId =
    (propertyId?.startsWith('p')
      ? propertyId
      : commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.id === propertyId
        )?.code) || propertyId;

  const location = useLocation();
  const {
    isLoading: isLoadingSalesChannels,
    error: salesChannelError,
    data: salesChannels,
  } = useQueryRMSSalesChannels({ orgId, propertyId: orgPropertyId, filterByType });

  let salesChannelType = 'point-of-sale';
  if (location.pathname.includes('website')) {
    salesChannelType = 'website';
  } else if (location.pathname.includes('kiosk')) {
    salesChannelType = 'kiosk';
  } else if (location.pathname.includes('marketplaces')) {
    salesChannelType = 'marketplaces';
  } else if (location.pathname.includes('mobile-apps')) {
    salesChannelType = 'mobile-apps';
  }

  return (
    <StyledContainer container>
      {!hideFilter && (
        <StyledButtonContainer container item xs={12}>
          <Grid item xs={12} md={6}>
            <PropertyFilter subscription={RMSSubscriptions.BASE} />
          </Grid>
          <Grid display={'flex'} justifyContent={'flex-end'} item xs={12} md={6}>
            <StyledButton
              variant="contained"
              onClick={() => {
                history.push(`${salesChannelType}/property/${orgPropertyId}/add-sales-channel`);
              }}
            >
              <StyledAddIcon />

              {translate('Add_sales_channel')}
            </StyledButton>
          </Grid>
        </StyledButtonContainer>
      )}
      <Grid item xs={12} display="flex" flexWrap="wrap" gap={2}>
        <Content
          isLoadingSalesChannels={isLoadingSalesChannels || isLoadingCommonData}
          salesChannelError={salesChannelError || commonDataError}
          salesChannels={salesChannels}
          onChannelClick={onChannelClick}
        />
      </Grid>
    </StyledContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: any) => {
  return {
    appId: state.currentApp.AppId,
    selectedPropertyId: state.rms.selectedProperty?.id,
    translate: getTranslate(state.locale),
    orgId: state.rms.currentOrg?.orgId,
  };
};
export default connect(mapStateToProps)(SalesChannelList);
