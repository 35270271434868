import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../../components/FormItemLayout';

const validate = (value: number) => {
  if (value <= 0 || value > 100) {
    return 'Value_must_be_between_1_and_100_inclusive';
  }
  return;
};

const DiscountPercentageField = () => (
  <Field name={'PercentageDiscount'} validate={validate}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout label={<Translate id="Discount_percentage" />}>
          <TextField
            {...field}
            autoComplete="off"
            disabled={isSubmitting}
            error={showError}
            fdKey={'discount-percentage'}
            helperText={
              showError ? (
                <Translate id={fieldError as 'Value_must_be_between_1_and_100_inclusive'} />
              ) : undefined
            }
            minWidth={200}
            inputProps={{
              min: 0,
              max: 100,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            type="number"
            variant="outlined"
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default DiscountPercentageField;
