import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  radioLabel: {
    fontSize: '0.875rem',
  },
}));

type Props = RadioGroupProps & {
  values: {
    value: string;
    label: React.ReactNode;
  }[];
  fdKey?: string;
  disabled?: boolean;
};

const StoreRadioGroup = ({ values, disabled = false, fdKey, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <RadioGroup {...rest} data-fd={fdKey}>
      {values.map((v) => (
        <FormControlLabel
          key={v.value}
          value={v.value}
          control={<Radio color="primary" disabled={disabled} />}
          label={<span className={classes.radioLabel}>{v.label}</span>}
        />
      ))}
    </RadioGroup>
  );
};
export default StoreRadioGroup;
