import { useEffect } from 'react';

import { type FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { TranslateFunction } from 'react-localize-redux';

interface Props<FormValues> {
  errors: FormikProps<FormValues>['errors'];
  isSubmitting: FormikProps<FormValues>['isSubmitting'];
  translate: TranslateFunction;
}

export const useVoucherForm = <FormValues>(props: Props<FormValues>) => {
  const { errors, isSubmitting, translate } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const isValidationErrorsOnSubmit = Object.entries(errors).length > 0 && isSubmitting;
    if (isValidationErrorsOnSubmit) {
      enqueueSnackbar(translate('One_or_more_fields_have_errors'), {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'error',
      });
    }
  }, [Object.entries(errors).length, isSubmitting]);
};
