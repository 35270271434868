import React from 'react';

import Hidden from '@mui/material/Hidden';

import TablePagination from '../../components/common/TablePagination/TablePagination';

export type Props = {
  page?: number;
  pageLocalStorageKey?: string;
  rowsPerPage?: number;
  rowsPerPageLocalStorageKey?: string;
  rowsPerPageOptions?: number[];
  setPage?: (n: number) => void;
  setRowsPerPage?: (n: number) => void;
  total: number | undefined;
};

const GenericTablePagination = (props: Props) => {
  const {
    rowsPerPage,
    page,
    pageLocalStorageKey,
    setPage,
    total,
    setRowsPerPage,
    rowsPerPageLocalStorageKey,
    rowsPerPageOptions = [5, 25, 50, 100, 250, 500],
  } = props;

  const handleChangePage = (_, newPage: number) => {
    if (setPage) {
      setPage(newPage);
      if (pageLocalStorageKey) {
        localStorage.setItem(pageLocalStorageKey, newPage.toString());
      }
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setPage && setRowsPerPage) {
      const rowsNum = parseInt(event.target.value, 10);
      setRowsPerPage(rowsNum);
      setPage(0);
      if (pageLocalStorageKey) {
        localStorage.setItem(pageLocalStorageKey, '0');
      }
      if (rowsPerPageLocalStorageKey) {
        localStorage.setItem(rowsPerPageLocalStorageKey, rowsNum.toString());
      }
    }
  };

  return (
    <>
      <Hidden mdDown>
        <TablePagination
          component="div"
          count={total ?? 0}
          rowsPerPage={rowsPerPage ?? 0}
          page={page ?? 0}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{ inputProps: { 'data-fd': 'rows-per-page-btn' } }}
        />
      </Hidden>
      <Hidden mdUp>
        <TablePagination
          component="div"
          labelRowsPerPage=""
          count={total ?? 0}
          rowsPerPage={rowsPerPage ?? 0}
          page={page ?? 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{ inputProps: { 'data-fd': 'rows-per-page-btn-mobile' } }}
        />
      </Hidden>
    </>
  );
};

export default GenericTablePagination;
