import { lazy } from 'react';

import { debounce } from 'lodash';
import { Configuration } from 'overrides/@flipdish/api-client-typescript/configuration';

import { getApiEndPoint } from './apibase';

export function createApi<T extends { [key: string]: (...args: any[]) => any }>(
  apiFactory: (configuration: Configuration, fetch: any, basePath: string) => T,
  endpointOverride?: string
) {
  const api = apiFactory({}, fetch, endpointOverride ?? getApiEndPoint());
  return new Proxy(api, {
    get: function (target, prop: string | symbol) {
      if (typeof prop === 'string') {
        const origMethod = target[prop];

        return function (...args) {
          const options = defaultFetchOptions();
          // Get the number of parameters the original method expects
          const numParamsIncludingOptions = origMethod.length;

          // If fewer arguments are passed in than the method expects, fill in the missing arguments with undefined
          args = [
            ...args,
            ...new Array(numParamsIncludingOptions - 1 - args.length).fill(undefined),
          ];

          // generated api is using legacy node:url.format which treats [] as a query. Setting to undefined to avoid this
          const argsModified = args.map((a) =>
            Array.isArray(a) && a.length === 0 ? undefined : a
          );

          // Now append the options - options must be the last argument
          argsModified.push(options);

          return origMethod.apply(this, argsModified);
        };
      }
    },
  });
}

export const defaultFetchOptions = () => {
  let originHeader = 'portal.flipdishdev.com';
  const host = window.location?.host;
  if (host?.includes('portal.flipdish.com')) {
    originHeader = 'portal.flipdish.com';
  }
  // local header ensure sameSite = None
  if (host?.includes('localhost') || host?.includes('local.portal.com')) {
    originHeader = 'local.portal.com';
  }

  return {
    credentials: 'include',
    headers: {
      'X-Flipdish-Source-Origin': originHeader,
      Accept: 'application/json',
    },
  };
};

export const translateTransform = (jsonFile: any) => {
  if (typeof jsonFile !== 'object' || jsonFile === null) {
    throw new Error('jsonFile must be an object');
  }
  const translatedJson: any = {};

  for (const p in jsonFile) {
    if (Object.prototype.hasOwnProperty.call(jsonFile, p)) {
      translatedJson[p] = jsonFile[p].value;
    }
  }
  return translatedJson;
};

export const getDebounceMaybe = (originalCall: any, throttle = false, debounceTime = 250) => {
  if (throttle) {
    return debounce(originalCall, debounceTime);
  } else {
    return originalCall;
  }
};

export const capitalizeFirst = (lower) => {
  return lower.replace(/^\w/, (c) => c.toUpperCase());
};

export const validateEmail = (email) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const { userAgent } = navigator;
export const Android = /Android/i.test(userAgent);
export const iOS = /iPhone|iPad|iPod/i.test(userAgent);
export const iOSPhone = /iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

export const pad = (d, z = 2) => {
  return ('00' + d).slice(-z);
};

export const timeFormat = (time) => {
  // Hours, minutes and seconds
  const hrs = Math.floor(time / 3600);
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  let ret = '';

  if (hrs > 0) {
    ret += hrs + ' hrs ' + (mins != 0 || secs != 0 ? ' ' : '');
  }

  if (mins > 0) {
    ret += mins + ' min' + (secs != 0 ? ' ' : '');
  }

  if (secs > 0) {
    ret += secs + ' sec';
  }

  return ret;
};

// Hack to prevent the need to click twice on links outside of redux form
// Relevant: https://github.com/erikras/redux-form/issues/860#issuecomment-294274650
export const preventReduxFormValidation = (predicate) => {
  return (event) => {
    const { relatedTarget } = event;
    if (!relatedTarget) {
      return;
    }
    if (predicate(relatedTarget)) {
      event.preventDefault();
    }
  };
};

export const formatDuration = (duration: number) => {
  const milliseconds = Math.floor((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(milliseconds, 3);
};

export const generateString = (
  length: number,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const enumToArray = (myEnum) => {
  const arrayOfEnums: string[] = [];
  for (const item in myEnum) {
    if (isNaN(Number(item))) {
      arrayOfEnums.push(item);
    }
  }
  return arrayOfEnums;
};

export const UUID = function (): string {
  let d = new Date().getTime();

  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

// Generate a UUID
// http://stackoverflow.com/a/8809472/249879
export const DeviceId = function (): string {
  const key = 'flipdish-device-id';
  const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  try {
    const deviceId: string | null = localStorage.getItem(key);

    if (deviceId && deviceId.match(uuid)) {
      return deviceId;
    }

    const newDeviceId = UUID();
    localStorage.setItem(key, newDeviceId);
    return newDeviceId;
  } catch (error) {
    console.warn(error);
  }

  // No local storage, make a new device ID each time.
  return UUID();
};

export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.sessionStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.sessionStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
