export const STORE_GROUP_GET = 'STORE_GROUP_GET';
export const STORE_GROUP_GET_REQUEST = 'STORE_GROUP_GET_REQUEST';
export const STORE_GROUP_GET_SUCCESS = 'STORE_GROUP_GET_SUCCESS';
export const STORE_GROUP_GET_FAILURE = 'STORE_GROUP_GET_FAILURE';

export const STORE_GROUP_GET_ALL = 'STORE_GROUP_GET_ALL';
export const STORE_GROUP_GET_ALL_REQUEST = 'STORE_GROUP_GET_ALL_REQUEST';
export const STORE_GROUP_GET_ALL_SUCCESS = 'STORE_GROUP_GET_ALL_SUCCESS';
export const STORE_GROUP_GET_ALL_FAILURE = 'STORE_GROUP_GET_ALL_FAILURE';

export const STORE_GROUP_CREATE = 'STORE_GROUP_CREATE';
export const STORE_GROUP_CREATE_REQUEST = 'STORE_GROUP_CREATE_REQUEST';
export const STORE_GROUP_CREATE_SUCCESS = 'STORE_GROUP_CREATE_SUCCESS';
export const STORE_GROUP_CREATE_FAILURE = 'STORE_GROUP_CREATE_FAILURE';

export const STORE_GROUP_UPDATE = 'STORE_GROUP_UPDATE';
export const STORE_GROUP_UPDATE_REQUEST = 'STORE_GROUP_UPDATE_REQUEST';
export const STORE_GROUP_UPDATE_SUCCESS = 'STORE_GROUP_UPDATE_SUCCESS';
export const STORE_GROUP_UPDATE_FAILURE = 'STORE_GROUP_UPDATE_FAILURE';

export const STORE_GROUP_REMOVE = 'STORE_GROUP_REMOVE';
export const STORE_GROUP_REMOVE_REQUEST = 'STORE_GROUP_REMOVE_REQUEST';
export const STORE_GROUP_REMOVE_SUCCESS = 'STORE_GROUP_REMOVE_SUCCESS';
export const STORE_GROUP_REMOVE_FAILURE = 'STORE_GROUP_REMOVE_FAILURE';

export const STORE_GROUP_SET_SELECTION = 'STORE_GROUP_SET_SELECTION';

export const STORE_GROUP_SET_SEARCH = 'STORE_GROUP_SET_SEARCH';
