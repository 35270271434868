import React, { useState } from 'react';

import { CreateLocationArea } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { TranslateFunction } from 'react-localize-redux';

import GridContainer from '@fd/ui/Layout/GridContainer';
import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';

const useStyles = makeStyles((theme: Theme) => ({
  textFields: {
    paddingBottom: theme.spacing(5),
  },
  subTitle: {
    paddingBottom: theme.spacing(2),
  },
  gridContainer: {
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type AddSectionModalProps = {
  onClose: () => void;
  translate: TranslateFunction;
  createSection: (
    locationArea: CreateLocationArea,
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => void;
  isTablePrefixEnabled: boolean;
};

const AddSectionModal = (props: AddSectionModalProps) => {
  const { onClose, translate, createSection, isTablePrefixEnabled } = props;
  const [numberOfTables, setNumberOfTables] = useState<number>(0);
  const [sectionName, setSectionName] = useState<CreateLocationArea>({ LocationAreaName: '' });
  const [startingNumber, setStartingNumber] = useState<number>(1);
  const [tablePrefix, setTablePrefix] = useState<string>('');
  const styles = useStyles();

  const sectionNumberInputError = numberOfTables > 50;
  const isConfirmDisabled =
    !sectionName.LocationAreaName?.length || sectionNumberInputError || !startingNumber;

  const parseNumber = (value: string): number => {
    return parseInt(value.replace(/\D/g, '')) || 0;
  };

  const handleClose = () => {
    setNumberOfTables(0);
    setSectionName({ LocationAreaName: '' });
    onClose();
  };

  return (
    <Modal open={true} titleTextId={'Add_section'}>
      <ModalContent>
        <GridContainer direction="column">
          <Grid item className={clsx(styles.subTitle, styles.gridItem)}>
            <Typography variant="caption">{translate('Add_section_description')}</Typography>
          </Grid>

          <Grid item className={clsx(styles.textFields, styles.gridItem)}>
            <TextField
              variant="standard"
              fullWidth
              id="section-name-input"
              placeholder={translate('Type') as string}
              label={translate('Name_of_the_section')}
              InputLabelProps={{ shrink: true }}
              value={sectionName.LocationAreaName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSectionName({ LocationAreaName: event.target.value });
              }}
            />
          </Grid>

          <Grid item className={clsx(styles.textFields, styles.gridItem)}>
            <TextField
              variant="standard"
              fullWidth
              id="section-number-input"
              label={translate('Number_of_tables')}
              value={numberOfTables}
              error={sectionNumberInputError}
              helperText={sectionNumberInputError ? translate('Create_section_error') : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNumberOfTables(parseNumber(event.target.value));
              }}
            />
          </Grid>

          {isTablePrefixEnabled && (
            <>
              <Grid item className={clsx(styles.textFields, styles.gridItem)}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="section-starting-number-input"
                  label={translate('Location_starting_number_title')}
                  helperText={translate('Location_starting_number_description')}
                  value={startingNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setStartingNumber(parseNumber(event.target.value))
                  }
                />
              </Grid>
              <Grid item className={clsx(styles.textFields, styles.gridItem)}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="section-prefix-input"
                  placeholder={translate('Location_prefix_placeholder') as string}
                  InputLabelProps={{ shrink: true }}
                  label={translate('Location_prefix_title')}
                  helperText={translate('Location_prefix_description')}
                  value={tablePrefix}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTablePrefix(e.target.value);
                  }}
                />
              </Grid>
            </>
          )}
        </GridContainer>
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonTranslationId="Cancel"
          leftButtonAction={handleClose}
          rightButtonTranslationId="Create_section"
          rightButtonAction={() =>
            createSection(sectionName, numberOfTables, startingNumber, tablePrefix)
          }
          fdKey={'create-section-modal-buttons'}
          rightDisabled={isConfirmDisabled}
        />
      </ModalActions>
    </Modal>
  );
};

export default AddSectionModal;
