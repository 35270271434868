import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router-dom';

const BottomContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  borderRadius: '4px',
  bottom: 0,
  width: '100%',
  backgroundColor: '#EEEBE9',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

const AdminOnlyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  marginLeft: theme.spacing(1),
}));

const AdminOnlyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginLeft: theme.spacing(1),
}));

const StyledAddOrganisationButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  width: '100%',
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  borderColor: 'black',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#c0c0c0',
  },
}));

const StyledButtonContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

type Props = { toggleOrgDrawer: () => void } & MapStateToProps;
const OrgCreateAdminBtn = ({ currentApp, translate, toggleOrgDrawer }: Props) => {
  const history = useHistory();

  const handleAddOrganisation = () => {
    toggleOrgDrawer();
    history.push(`/${currentApp?.AppId}/settings/organisation/add`);
  };

  return (
    <Permissions allowed={['FlipdishStaff']}>
      <BottomContainer data-fd="admin-only-add-org">
        <AdminOnlyContainer>
          <SupportAgentOutlinedIcon />
          <AdminOnlyText variant="body1">{translate('Admin_Only')}</AdminOnlyText>
        </AdminOnlyContainer>
        <StyledAddOrganisationButton variant="outlined" onClick={handleAddOrganisation}>
          <StyledButtonContentContainer>
            <AddIcon /> <Typography variant="body1">{translate('Add_organisation')}</Typography>
          </StyledButtonContentContainer>
        </StyledAddOrganisationButton>
      </BottomContainer>
    </Permissions>
  );
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(OrgCreateAdminBtn);
