import React from 'react';

import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import PaperContainer from '../../../ui/Layout/PaperContainer';
import { CampaignsTypes } from '../Campaigns';

const EmptyCampaignItem = (type: CampaignsTypes) => {
  return (
    <PaperContainer hasBorderOnMobile>
      <Typography variant="subtitle1">
        {type === CampaignsTypes.LOYALTY ? (
          <Translate id="No_loyalty_campaigns_yet" />
        ) : (
          <Translate id="No_retention_campaigns_yet" />
        )}
      </Typography>
      <Typography variant="subtitle1">
        <Translate id="Click_on_right_bottom_corner" />
      </Typography>
      <Typography variant="caption">
        {type === CampaignsTypes.LOYALTY ? (
          <Translate id="Create_loyalty_campaign_description" />
        ) : (
          <Translate id="Create_retention_campaign_description" />
        )}
      </Typography>
    </PaperContainer>
  );
};

export default EmptyCampaignItem;
