import React, { useEffect, useMemo } from 'react';

import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type UnpackICEWP, compose, setDisplayName, withHandlers } from 'recompose';

import Checkbox from '@fd/ui/Checkbox';

import { storeOpeningHourSelectors } from '../../../selectors';
import { dateTimeUtils, getDateTimeLocale } from '../../../selectors/localeDateTime.selector';
import TextField from '../../../ui/TextField/TextField';
import Tooltip from '../../../ui/Tooltip/Tooltip';

type Handlers = UnpackICEWP<typeof useHandlers>;
const useHandlers = withHandlers(() => {
  return {
    onFromChange:
      ({ onChange, data }: Props) =>
      (date: Date) => {
        onChange({ ...data, StartTime: date });
      },
    onToChange:
      ({ onChange, data }: Props) =>
      (date: Date) => {
        onChange({ ...data, EndTime: date });
      },
    onPickupChange:
      ({ onChange, data }: Props) =>
      (e, checked: boolean) => {
        onChange({
          ...data,
          Pickup: checked,
        });
      },
    onDeliveryChange:
      ({ onChange, data }: Props) =>
      (e, checked: boolean) => {
        onChange({
          ...data,
          Delivery: checked,
        });
      },
    onPreOrderPickupChange:
      ({ onChange, data }: Props) =>
      (e, checked: boolean) => {
        onChange({ ...data, PreOrderPickup: checked });
      },
    onPreOrderDeliveryChange:
      ({ onChange, data }: Props) =>
      (e, checked: boolean) => {
        onChange({ ...data, PreOrderDelivery: checked });
      },
  };
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeId }: IOpenCloseFormProps) => {
  const storeOpeningHours = storeOpeningHourSelectors.store7DayOpeningHours(state, {
    storeId,
  });
  return {
    translate: getTranslate(state.locale),
    dtLocale: getDateTimeLocale(state),
    dtUtils: dateTimeUtils(state),
    storeOpeningHours,
  };
};

export type TimeErrors = { startTime?: string; endTime?: string };

export interface IOpenCloseFormProps {
  data;
  style?;
  tab?: number;
  preOrderEnabled?: boolean;
  preOrderPickup?: boolean;
  preOrderDelivery?: boolean;
  storeId: number;
  onChange(data);
  storeIanaTZ: string;
  errors: TimeErrors;
  setErrors: React.Dispatch<React.SetStateAction<TimeErrors>>;
}
type Props = IOpenCloseFormProps & {
  disabled?: boolean;
  onFromChange(date: Date);
  onToChange(date: Date);
  onPickupChange(e, checked: boolean);
  onDeliveryChange(e, checked: boolean);
  onPreOrderPickupChange(e, checked: boolean);
  onPreOrderDeliveryChange(e, checked: boolean);
};

const validate = (startTime: number, endTime: number, currentTime: number) => {
  const afterCurrentErrorKey = 'Should_be_after_current_time';
  const errors = { startTime: '', endTime: '' };

  if (startTime < currentTime) {
    errors.startTime = afterCurrentErrorKey;
  }
  if (endTime < currentTime) {
    errors.endTime = afterCurrentErrorKey;
  }
  return errors;
};

export default compose<Props & Handlers & MappedState, IOpenCloseFormProps>(
  setDisplayName('OpenCloseForm'),
  connect(mapStateToProps),
  useHandlers
)(({
  data,
  errors,
  onFromChange,
  onToChange,
  onPickupChange,
  onDeliveryChange,
  onPreOrderPickupChange,
  onPreOrderDeliveryChange,
  style,
  dtUtils,
  dtLocale,
  preOrderEnabled,
  translate,
  preOrderPickup,
  preOrderDelivery,
  setErrors,
  storeOpeningHours,
  storeIanaTZ,
}) => {
  const startTime = data.StartTime.getTime();
  const endTime = data.EndTime.getTime();

  const currentTime = dtUtils
    .startOfMinute(dtUtils.utcToZonedTime(new Date(), storeIanaTZ).getTime())
    .getTime();
  const hasError = startTime > endTime;
  const minDate = useMemo(() => data.StartTime, []);

  useEffect(() => {
    setErrors(validate(startTime, endTime, currentTime));
  }, [data]);

  let deliveryStoreEnabled = false;
  if (storeOpeningHours && storeOpeningHours.Delivery) {
    deliveryStoreEnabled = storeOpeningHours.Delivery.enabled;
  }

  let pickupStoreEnabled = false;
  if (storeOpeningHours && storeOpeningHours.Pickup) {
    pickupStoreEnabled = storeOpeningHours.Pickup.enabled;
  }

  return (
    <Grid container spacing={2} style={style}>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>
          {translate(
            data.Type === 'Closed'
              ? 'This_will_override_any_open-store_events'
              : 'This_will_override_any_close-store_events'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={{
            marginBottom: 15,
            marginTop: 10,
            width: '100%',
            fontWeight: 'initial',
          }}
        >
          {translate('Functionality')}
        </Typography>

        <FormGroup style={{ display: 'block' }}>
          <FormControlLabel
            disabled={!pickupStoreEnabled}
            control={
              <Checkbox
                dataFd="checkbox_collection_store_data"
                checked={!pickupStoreEnabled ? false : data.Pickup}
                onChange={onPickupChange}
                color="secondary"
              />
            }
            label={
              !pickupStoreEnabled ? (
                <Tooltip messageId={'Collection_disabled'} fdKey="Collection_disabled">
                  <span>{translate('Collection')}</span>
                </Tooltip>
              ) : (
                translate('Collection')
              )
            }
          />
        </FormGroup>

        <FormGroup style={{ display: 'block' }}>
          <FormControlLabel
            disabled={!deliveryStoreEnabled}
            control={
              <Checkbox
                dataFd="checkbox_delivery_store_data"
                checked={!deliveryStoreEnabled ? false : data.Delivery}
                onChange={onDeliveryChange}
                color="secondary"
              />
            }
            label={
              !deliveryStoreEnabled ? (
                <Tooltip messageId={'Delivery_disabled'} fdKey="Delivery_disabled">
                  <span>{translate('Delivery')}</span>
                </Tooltip>
              ) : (
                translate('Delivery')
              )
            }
          />
        </FormGroup>

        {preOrderEnabled && preOrderPickup && pickupStoreEnabled && (
          <FormGroup style={{ display: 'block' }}>
            <FormControlLabel
              control={
                <Checkbox
                  dataFd="allow_preorders_collection"
                  value={`allow_preorders_collection`}
                  color="secondary"
                  inputProps={{
                    'aria-label': 'allow preorders',
                  }}
                  checked={data.PreOrderPickup}
                  onChange={onPreOrderPickupChange}
                  style={{ alignSelf: 'baseline' }}
                />
              }
              label={<div>{translate('Allow_Pickup_preorders')}</div>}
            />
          </FormGroup>
        )}

        {preOrderEnabled && preOrderDelivery && deliveryStoreEnabled && (
          <FormGroup style={{ display: 'block' }}>
            <FormControlLabel
              control={
                <Checkbox
                  dataFd="allow_preorders_delivery"
                  value={`allow_preorders_delivery`}
                  color="secondary"
                  inputProps={{
                    'aria-label': 'allow preorders',
                  }}
                  checked={data.PreOrderDelivery}
                  onChange={onPreOrderDeliveryChange}
                  style={{ alignSelf: 'baseline' }}
                />
              }
              label={<div>{translate('Allow_Delivery_preorders')}</div>}
            />
          </FormGroup>
        )}
      </Grid>

      <Typography
        variant="h5"
        style={{
          marginBottom: 15,
          marginTop: 10,
          paddingLeft: 8,
          width: '100%',
          fontWeight: 'initial',
        }}
      >
        {translate('Closing_Time')}
      </Typography>

      <Grid item xs={12} sm={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns as any} adapterLocale={dtLocale}>
          <DatePicker
            label={translate('From')}
            format={dtUtils.LOCAL_DATE_FORMAT}
            value={data.StartTime ? data.StartTime : undefined}
            onChange={(date, error) => {
              if (!error.validationError) {
                onFromChange(date);
              }
            }}
            minDate={minDate}
            slotProps={{
              textField: {
                variant: 'standard',
                fullWidth: true,
                inputProps: { 'data-fd': 'set_opening_store_date' },
              },
              popper: { style: { zIndex: 9999 } },
            }}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          error={!!errors.startTime}
          value={dtUtils.format(data.StartTime, 'HH:mm')}
          label={translate('Time')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ paddingRight: 0 }}>
                  <TimeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={
            errors.startTime
              ? translate(errors.startTime as TranslationId)
              : translate('Store_hour_overrides_in_local_store_time', {
                  offset: '',
                })
          }
          fdKey="hours_overrides_from_time"
          type="time"
          onChange={(e) => {
            const value = e.target.value;
            const values = value.split(':');

            const date = dtUtils.set(data.StartTime, {
              hours: Number(values[0] || 0),
              minutes: Number(values[1] || 0),
            });
            onFromChange(date);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns as any} adapterLocale={dtLocale}>
          <DatePicker
            label={translate('To')}
            value={data.EndTime}
            format={dtUtils.LOCAL_DATE_FORMAT}
            slotProps={{
              popper: { style: { zIndex: 9999 } },
              textField: {
                variant: 'standard',
                error: hasError,
                fullWidth: true,
                inputProps: { 'data-fd': 'set_closing_store_date' },
                helperText: hasError
                  ? translate('Date-and-time-should-be-after-$-before-date', {
                      before: translate('From') as string,
                    })
                  : undefined,
              },
            }}
            onChange={(date, error) => {
              if (!error.validationError) {
                onToChange(date);
              }
            }}
            minDate={data.StartTime}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <TextField
          fullWidth
          error={!!errors.endTime}
          value={dtUtils.format(data.EndTime, 'HH:mm')}
          label={translate('Time')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ paddingRight: 0 }}>
                  <TimeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={
            errors.endTime
              ? translate(errors.endTime as TranslationId)
              : translate('Store_hour_overrides_in_local_store_time', {
                  offset: '',
                })
          }
          fdKey="hours_overrides_from_time"
          type="time"
          onChange={(e) => {
            const value = e.target.value;
            const values = value.split(':');

            const date = dtUtils.set(data.EndTime, {
              hours: Number(values[0] || 0),
              minutes: Number(values[1] || 0),
            });
            onToChange(date);
          }}
        />
      </Grid>
    </Grid>
  );
});
