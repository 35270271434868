import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, legacy_createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { thunk as thunkMiddleware, ThunkDispatch } from 'redux-thunk';

// eslint-disable-next-line import/extensions
import { signalrMiddleware } from '../middlewares/signalr.middleware';
import { rootReducer } from '../reducers/root.reducer';
import { createReduxHistory, routerMiddleware } from '../routes/reduxHistory';
import { runSaga } from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = [routerMiddleware, sagaMiddleware, thunkMiddleware, signalrMiddleware];

export const store = legacy_createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const history = createReduxHistory(store);

runSaga(sagaMiddleware);

if (process.env.NODE_ENV === 'development') {
  global._store_ = store;
}

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Define AppDispatch to support both Thunks and regular actions
export type AppDispatch = ThunkDispatch<RootState, unknown, any>;
