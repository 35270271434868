import { Order } from '@flipdish/api-client-typescript';
import { orderEventConstants } from '../../signalr/hub.events';

const initialState = {
  events: new Array<{
    event: string;
    data: Order;
    description: string;
    ts: Date;
  }>(),
};

export function orderHub(state = initialState, action) {
  const { events } = state;
  const { type, data, description, ts } = action;
  switch (action.type) {
    case orderEventConstants.CREATED:
    case orderEventConstants.ACCEPTED:
    case orderEventConstants.REFUNDED:
    case orderEventConstants.REJECTED:
    case orderEventConstants.RATING_UPDATED:
    case orderEventConstants.TIP_UPDATED: {
      const newEvents = events.map((r) => r);
      newEvents.push({ event: type, data, description, ts });
      return { ...state, events: newEvents };
    }
  }
  return state;
}
