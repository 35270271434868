import { useQuery } from '@tanstack/react-query';

import { loadByAppId } from '../../../services/store.service';
import { tutorialService } from '../tutorial.service';

const getTutorialStore = async (appId) => {
  const response = await loadByAppId({
    appId,
    page: 1,
    limit: 1,
  });

  return response?.Data[0];
};

export default function useTutorialStore(appId) {
  return useQuery({
    queryKey: [tutorialService.tutorialStoreKey, appId],
    queryFn: () => getTutorialStore(appId),
  });
}
