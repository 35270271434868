import React from 'react';

import { CreateMetafieldDefinition } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router-dom';

import { Typography } from '@fd/ui/atoms/Typography';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import { getEntityTranslationKey } from '../../../../../services/utils/stringConverters';
import PageLayout from '../../../../../ui/Layout';
import { MetafieldForm } from '../../components/MetafieldForm';
import { metafieldsService } from '../../metafields.service';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
  },
  listTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

type Props = RouteComponentProps<{ ownerEntity: string }>;

const CreateMetafield = ({
  appId,
  hasCreateMetafieldPermission,
  history,
  isMetafieldsOn,
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
  ownerEntity,
  translate,
}: MappedState & MappedDispatch & RouteComponentProps) => {
  const classes = useStyles();
  const entityName = getEntityTranslationKey(ownerEntity);

  const redirectMetafieldDetailPage = ({ Key }) => {
    const metafieldUrl = window.location.pathname.replace('create', Key);
    history.push({ pathname: metafieldUrl });
  };

  const { mutateAsync } = useMutation({
    mutationFn: (newDefinition: CreateMetafieldDefinition) => {
      notifySaving();
      return metafieldsService.createMetafieldDefinition(appId, entityName, newDefinition);
    },

    onSuccess: (data) => {
      closeNotifySaving();
      notifySaved();
      redirectMetafieldDetailPage(data.Data);
    },

    onError: () => {
      closeNotifySaving();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return isMetafieldsOn ? (
    <PageLayout
      documentTitle="Add_Definition"
      strictToParent={true}
      title={<Translate id="Add_Definition" />}
      toParent={`/${appId}/settings/metafields/${ownerEntity}`}
    >
      <div className={classes.container}>
        <MetafieldForm
          appId={appId}
          canEdit={hasCreateMetafieldPermission}
          onSaveChanges={mutateAsync}
          ownerEntity={entityName}
          translate={translate}
        />
      </div>
    </PageLayout>
  ) : (
    <Typography>
      <Translate id="Coming_soon" />
    </Typography>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const getCreatePermissionsSelector = permissionsSelector.hasPermissionFactory([
    'CreateMetafieldDefinitions',
  ]);
  return {
    appId: state.currentApp.AppId as string,
    hasCreateMetafieldPermission: getCreatePermissionsSelector(state),
    isMetafieldsOn: isProductBasedMenusEnabled(state),
    ownerEntity: props.match.params.ownerEntity,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateMetafield);
export { EnhancedComponent as CreateMetafield };
