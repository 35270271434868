import React from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

const styles = (theme: Theme) => {
  return createStyles({
    acceptButton: {
      width: '124px',
      marginRight: '16px',
      padding: '10px 24px',
      fontSize: '16px',
      letterSpacing: '1.4px',
      backgroundColor: '#36d88e',
      color: '#fff',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#25c079',
      },
    },
    secondaryButton: {
      height: '48px',
      width: '72px',
      marginRight: theme.spacing(2),
      color: '#36d88e',
      border: '1px solid #36d88e',
      '&:hover': {
        border: '1px solid #36d88e',
        backgroundColor: '#36d88e',
        color: '#fff',
      },
    },
    buttonTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1,
      letterSpacing: '1.4px',
    },
    number: {
      fontSize: '16px',
      fontWeight: 500,
    },
    txt: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  });
};

type Props = { mins: number; selected: boolean; onClick: () => void; fdKey: string } & WithStyles<
  typeof styles
>;

const AcceptButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, mins, selected, onClick, fdKey } = props;
  const renderMinsButton = () => (
    <>
      <span className={classes.number}>+{mins}</span>
      <span className={classes.txt}>
        <Translate id="mins" />
      </span>
    </>
  );

  const renderSelectedButton = () => (
    <>
      <span className={classes.number}>
        <Translate id="Accept" />
      </span>
      <span className={classes.txt}>
        ({mins}
        <Translate id="mins" />)
      </span>
    </>
  );
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      className={selected ? classes.acceptButton : classes.secondaryButton}
      onClick={onClick}
      data-fd={fdKey}
    >
      <div className={classes.buttonTextContainer}>
        {selected ? renderSelectedButton() : renderMinsButton()}
      </div>
    </Button>
  );
};

export default withStyles(styles)(AcceptButton);
