import { PreOrderConfig, StoresApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../../../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from '../../../services/utils/serverErrorMapper';

const storesApi = createApi(StoresApiFactory);

export const getPreOrderConfig = async (storeId: number, preOrderType: 'Delivery' | 'Pickup') => {
  try {
    const incomingMessage = await storesApi.getPreOrderConfig(storeId, preOrderType);

    return incomingMessage.Data;
  } catch (e) {
    if (e.response && e.response.statusCode === 404) {
      return false;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

export const updatePreOrderConfig = async (
  storeId: number,
  preOrderType: 'Delivery' | 'Pickup',
  preOrderConfig: PreOrderConfig
) => {
  try {
    const response = await storesApi.updatePreOrderConfig(storeId, preOrderType, preOrderConfig);
    return response;
  } catch (e) {
    console.log(e?.message);
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

export const setPreOrderStatus = async (
  storeId: number,
  deliveryType: 'Delivery' | 'Pickup',
  status: boolean
) => {
  try {
    await storesApi.setPreOrdeEnabled(storeId, deliveryType, status);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};
