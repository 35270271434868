import { makeAxiosRequest, IpInfo } from './cookieconsent/utils';

import options from './cookieconsent/options/location';
import { getCountryCode } from './cookieconsent/models/Legal';

export const getService = () => {
  const serviceOption = options.services[0];
  return options.serviceDefinitions[serviceOption]();
};

// requires a `service` object that defines at least a `url` and `callback`
export const runService = async (service): Promise<IpInfo> => {
  // const isOperaMini = navigator.userAgent.indexOf('Opera Mini') > -1;

  // if (isOperaMini) {
  //   return;
  // }

  return await makeAxiosRequest(service.url, service.headers);
};

const geoIpLookup = async (): Promise<boolean> => {
  const service = getService();
  const location = await runService(service);

  return getCountryCode(location?.country).hasLaw;
};

export default geoIpLookup;
