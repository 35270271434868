import { HydraDeviceDetails, HydraRegistrationRequest } from '@flipdish/api-client-typescript';

import { action, actionError } from '../../actions/utils';
import {
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
} from '../../layouts/Notify/actions';
import * as deviceService from '../../services/devices.services';
import { HydraRegistrationForm } from './Components/AddDeviceForm';

export const TERMINALS_LOAD_REQUEST = 'TERMINALS_LOAD_REQUEST';
export const TERMINALS_LOAD_SUCCESS = 'TERMINALS_LOAD_SUCCESS';
export const TERMINALS_LOAD_FAILURE = 'TERMINALS_LOAD_FAILURE';

export type GetTerminalsRequest = ReturnType<typeof getTerminalsRequest>;
export const getTerminalsRequest = (isInitial?: boolean) =>
  action(TERMINALS_LOAD_REQUEST, isInitial);

export type GetTerminalsSuccess = ReturnType<typeof getTerminalsSuccess>;
export const getTerminalsSuccess = (data: {
  terminals: HydraDeviceDetails[];
  printers: HydraDeviceDetails[];
  terminalsTotalCount: number;
  printersTotalCount: number;
}) => action(TERMINALS_LOAD_SUCCESS, data);

export function getDevices(page: number, limit: number, isInitial?: boolean) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const { devices } = state;
    const appId = state.currentApp.AppId as string;
    let printers = devices.printers;
    let terminals = devices.terminals;
    let terminalsTotalCount = devices.terminalsTotalCount;
    let printersTotalCount = devices.printersTotalCount;

    try {
      dispatch(getTerminalsRequest(isInitial));

      if (isInitial || printers.length !== printersTotalCount) {
        const { Data, TotalRecordCount } = await deviceService.getDeviceList(
          appId,
          'LegacyPrinter',
          page,
          limit
        );

        printers = Data;
        printersTotalCount = TotalRecordCount;
      }
      if (isInitial || terminals.length !== terminalsTotalCount) {
        const { Data, TotalRecordCount } = await deviceService.getDeviceList(
          appId,
          'Terminal',
          page,
          limit
        );

        terminals = Data;
        terminalsTotalCount = TotalRecordCount;
      }

      dispatch(
        getTerminalsSuccess({
          terminals,
          printers,
          terminalsTotalCount,
          printersTotalCount,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

// #region create terminal
export const TERMINAL_CREATE = 'TERMINAL_CREATE';
export const TERMINAL_CREATE_REQUEST = 'TERMINAL_CREATE_REQUEST';
export const TERMINAL_CREATE_SUCCESS = 'TERMINAL_CREATE_SUCCESS';
export const TERMINAL_CREATE_FAILURE = 'TERMINAL_CREATE_FAILURE';

export const createTerminalRequest = () => action(TERMINAL_CREATE_REQUEST);

export type CreateTerminalSuccess = ReturnType<typeof createTerminalSuccess>;
export const createTerminalSuccess = (props) => action(TERMINAL_CREATE_SUCCESS, props);

export const createTerminalFailure = (error: Error, props) =>
  actionError(TERMINAL_CREATE_FAILURE, props, error);

export function createTerminal(terminal: HydraRegistrationForm, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    const hydraTerminal: HydraRegistrationRequest = {
      PinCode: terminal.PinCode as number,
      StoreIds: terminal.StoreIds,
      DeviceName: terminal.DeviceName,
    };
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(createTerminalRequest());
      const result = await deviceService.createTerminal(appId, hydraTerminal);
      dispatch(createTerminalSuccess(result));

      dispatch(closeNotifySaving());
      dispatch(notifySaved());
    } catch (error) {
      dispatch(createTerminalFailure(error, appId));

      dispatch(closeNotifySaving());
      if (error.message === 'Invalid code') {
        dispatch(notifyError({ message: 'Invalid_code', translate: true }));
      } else {
        dispatch(notifyError({ message: error.message, translate: true }));
      }
      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region unregister terminal
export const TERMINAL_UNREGISTER = 'TERMINAL_UNREGISTER';
export const TERMINAL_UNREGISTER_REQUEST = 'TERMINAL_UNREGISTER_REQUEST';
export const TERMINAL_UNREGISTER_SUCCESS = 'TERMINAL_UNREGISTER_SUCCESS';
export const TERMINAL_UNREGISTER_FAILURE = 'TERMINAL_UNREGISTER_FAILURE';

export const unregisterTerminalRequest = () => action(TERMINAL_UNREGISTER_REQUEST);

export type UnregisterTerminalSuccess = ReturnType<typeof unregisterTerminalSuccess>;
export const unregisterTerminalSuccess = (id: string) => action(TERMINAL_UNREGISTER_SUCCESS, id);

export const unregisterTerminalFailure = (error: Error, props) =>
  actionError(TERMINAL_UNREGISTER_FAILURE, props, error);

export function unregisterTerminal(id: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(unregisterTerminalRequest());

      await deviceService.unregisterTerminal(appId, id);
      dispatch(unregisterTerminalSuccess(id));
    } catch (error) {
      dispatch(notifyError({ message: error.message, translate: true }));
    }
  };
}
// #endregion

// #region set stores filter
export const SET_STORES_FILTER = 'TERMINAL/SET_STORES_FILTER';
export type SetStoresFilter = ReturnType<typeof setStoresFilter>;
export const setStoresFilter = (stores: Array<{ label: string; value: number }>) =>
  action(SET_STORES_FILTER, { stores });
// #endregion
