import React, { ChangeEvent, MouseEvent } from 'react';

import { WebhookSubscription } from '@flipdish/api-client-typescript';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import TablePagination from '../../common/TablePagination/TablePagination';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  cell: {
    wordBreak: 'break-all',
  },
  copy: {
    position: 'relative',
    cursor: 'pointer',
    '& span:nth-child(2)': {
      display: 'none',
      color: '#05149e',
    },
    '&:hover': {
      '& span:nth-child(1)': {
        visibility: 'hidden',
      },
      '& span:nth-child(2)': {
        position: 'absolute',
        top: '50%',
        left: `${theme.spacing(3)}`,
        transform: 'translateY(-50%)',
        display: 'block',
      },
      [theme.breakpoints.down('md')]: {
        '& span:nth-child(2)': {
          left: 0,
        },
      },
    },
  },
}));

type Props = {
  data: WebhookSubscription[];
  TotalRecordCount: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  copyHandler: (id: number) => void;
  handleClick: (e, item) => void;
};

export default function OAuthAppWebhooksList({
  data,
  TotalRecordCount,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  copyHandler,
  handleClick,
}: Props) {
  const classes = useStyles();

  return (
    <Hidden mdDown>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">
              <Translate id="Owner_user_id" />
            </TableCell>
            <TableCell component="th">
              <Translate id="Callback_url" />
            </TableCell>
            <TableCell component="th">
              <Translate id="Verify_token" />
            </TableCell>
            <TableCell component="th">
              <Translate id="Version" />
            </TableCell>
            <TableCell component="th">
              <Translate id="Status" />
            </TableCell>
            <TableCell component="th" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow className={classes.row} key={item.Id}>
                <TableCell className={classes.cell}>{item.OwnerUserId}</TableCell>
                <TableCell
                  className={`${classes.cell} ${classes.copy}`}
                  onClick={() => copyHandler(item.Id as number)}
                >
                  <span>{item.CallbackUrl}</span>
                  <span>
                    <Translate id="Copy_to_clipboard" />
                  </span>
                  <input
                    id={`Webhook_Callback_url${item.Id}`}
                    type="hidden"
                    value={item.CallbackUrl}
                  />
                </TableCell>
                <TableCell className={classes.cell}>{item.VerifyToken}</TableCell>
                <TableCell className={classes.cell}>{item.Version}</TableCell>
                <TableCell className={classes.cell}>
                  <Translate id={item.Enabled ? 'Enabled' : 'Disabled'} />
                </TableCell>
                <TableCell style={{ textAlign: 'right', width: 50 }}>
                  <IconButton
                    aria-label="More"
                    data-fd="webhook-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={TotalRecordCount}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Hidden>
  );
}
