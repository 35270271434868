import React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { type DateTimePickerProps, DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { connect } from 'react-redux';

export type IDateTimePickerProps = DateTimePickerProps<any> & {
  dispatch: any;
};

const DateTimePickerComponent = ({ dispatch, onChange, ...props }: IDateTimePickerProps) => {
  const [selectedDate, handleDateChange] = React.useState(moment());

  const handleChange = (event, context) => {
    handleDateChange(event);
    onChange && onChange(event, context);
  };

  const updatedProps = {
    ...props,
    value: selectedDate,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        format={props.format || 'L LT'}
        onChange={(val, context) => handleChange(val, context)}
        {...updatedProps}
      />
    </LocalizationProvider>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ampm: state.localeDateTime.time.useMeridiem,
  value: moment.isMoment(ownProps.value) ? ownProps.value.toDate() : ownProps.value, // don't use moment instance => won't refresh on locale change
});

DateTimePickerComponent.displayName = 'DateTimePicker';

export default connect(mapStateToProps)(DateTimePickerComponent);
