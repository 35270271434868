import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { type FieldProps, Field } from 'formik';

import FormItemLayout from '../../../../../ui/Forms/FormItemLayout';
import Switch from '../../../../../ui/Switch/Switch';

export type InputFieldProps = {
  fieldData: FdField;
};

const BooleanField = ({ fieldData }: InputFieldProps) => {
  return (
    <Field name={fieldData.Key}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;

        return (
          <FormItemLayout
            label={fieldData.Name}
            description={fieldData.Description}
            showTooltip={!!(fieldData.Tooltip && fieldData.Tooltip.length)}
            tooltipOptions={{
              fdKey: 'appstore_config_input_tooltip',
              labelText: fieldData.Tooltip,
            }}
          >
            <FormControl error={fieldError != undefined}>
              <Switch
                {...field}
                fdKey={'appstore_config_boolean'}
                checked={field.value || false}
                disabled={isSubmitting}
              />

              {fieldError && <FormHelperText>{fieldError}</FormHelperText>}
            </FormControl>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default BooleanField;
