import React, { useEffect, useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { usePrevious } from '../custom-hooks/usePrevious';
import { type IAppState } from '../reducers/root.reducer';
import withRouteSearchParams, { WithRouteSearchParamsProps } from './WithRouteSearchParams';

const useStyles = makeStyles({
  search: {
    marginBottom: 10,
    padding: '16px 12px 16px 24px',
    borderTop: '1px solid rgba(0,0,0,0.2088)',
    borderBottom: '1px solid rgba(0,0,0,0.2088)',
    '&>div::before,&>div::after,&>div:hover::before': {
      borderBottom: 'initial!important',
    },
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
});

type Props = {
  open: boolean;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
} & MappedProps &
  WithRouteSearchParamsProps<string>;

const AppMenuSearch = (props: Props) => {
  const { searchQuery, setSearchQuery, translate, open, setSearch } = props;
  const classes = useStyles();
  const previousOpen = usePrevious(open);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setSearch(query);
  };

  const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    handleSearchQueryChange(query);
  };

  useEffect(() => {
    if (open && !previousOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open, previousOpen]);

  return (
    <TextField
      inputRef={inputRef}
      className={classes.search}
      type="search"
      fullWidth
      placeholder={`${translate('Search')}...`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      inputProps={{ 'data-fd': 'app-selector-search' }}
      onChange={onSearchQueryChange}
      value={searchQuery}
      variant="standard"
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IAppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

const EnhancedComponent = compose<Props, {}>(
  withRouteSearchParams({
    name: 'appSearch',
  }),
  connect(mapStateToProps, null)
)(AppMenuSearch);

export default EnhancedComponent;
