import React from 'react';

import { OrgCountryCodeEnum } from '@flipdish/orgmanagement';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormikErrors, useFormik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import GridContainer from '@fd/ui/Layout/GridContainer';

import { validateEmail } from '../../../helpers/utilities';
import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { addressService } from '../../../services/address.service';
import { CountrySelector } from '../../DynamicAddress/Components/CountrySelector/CountrySelector';
import { createOrg } from '../organisation.services';

type FormValues = {
  organisationName: string;
  email: string;
  countryCode: OrgCountryCodeEnum;
};

const FormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(3),
  border: '1px solid #e0e0e0',
  borderRadius: theme.shape.borderRadius,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  color: '#0000001F',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AddOrganisation: React.FC<MappedState & MappedDispatch> = ({
  currentApp,
  translate,
  language,
  dispatchNotifyError,
  dispatchNotifySaved,
}) => {
  const history = useHistory();
  const {
    data: countries,
    isPending: loadingCountries,
    isFetching: fetchingCountries,
  } = useQuery({
    queryKey: [addressService.getCountriesQueryKey, language],
    queryFn: () => addressService.getCountries(language),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (values: FormValues) => {
      return await createOrg({
        org: {
          name: values.organisationName,
          emailAddress: values.email,
          countryCode: values.countryCode,
        },
      });
    },
    onSuccess: () => {
      dispatchNotifySaved();
      history.push(`/${currentApp?.AppId}/settings/organisation`);
    },
    onError: (error) => {
      dispatchNotifyError({ message: error.message, translate: false });
    },
  });

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.organisationName) {
      errors.organisationName = translate('Required') as string;
    } else if (values.organisationName.length > 30) {
      errors.organisationName = translate('Max_length_30') as string;
    }

    if (!values.email) {
      errors.email = translate('Required') as string;
    } else if (!validateEmail(values.email)) {
      errors.email = translate('Invalid_email') as string;
    }

    if (!values.countryCode) {
      errors.countryCode = translate('Required') as string;
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      organisationName: '',
      email: '',
      countryCode: 'IE',
    },
    validate,
    onSubmit: async (values) => {
      await mutateAsync(values);
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <PageLayout
      strictToParent
      toParent={`/${currentApp?.AppId}/settings/organisation`}
      title={translate('Add_Organisation')}
      documentTitle="Add_organisation"
    >
      <FormContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="organisationName">{translate('Organisation_name')}</InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledTextField
                id="organisationName"
                name="organisationName"
                variant="standard"
                fullWidth
                placeholder={translate('Your_organisation_name') as string}
                value={formik.values.organisationName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.organisationName && Boolean(formik.errors.organisationName)}
                helperText={formik.touched.organisationName && formik.errors.organisationName}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledDivider />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="email">{translate('Email')}</InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledTextField
                id="email"
                name="email"
                variant="standard"
                fullWidth
                placeholder={translate('Your_email') as string}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledDivider />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="country">{translate('Country')}</InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <CountrySelector
                loading={loadingCountries || fetchingCountries}
                translate={translate}
                disabled={isPending}
                hideLabel
                countries={
                  countries?.map((country) => ({
                    label: country?.Label,
                    value: country?.Value,
                  })) || []
                }
                countryCode={formik.values.countryCode}
                handleChange={(value) => formik.setFieldValue('countryCode', value)}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledDivider />
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <StyledButton
                data-fd="add-organisation-button"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isPending || formik.isSubmitting}
                endIcon={isPending ? <CircularProgress size={20} /> : null}
              >
                {translate('Add_organisation')}
              </StyledButton>
            </Grid>
          </GridContainer>
        </form>
      </FormContainer>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: any) => {
  return {
    currentApp: state.currentApp,
    translate: getTranslate(state.locale),
    language: state.account?.language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisation);
