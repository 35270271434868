import { SagaMiddleware, Task } from 'redux-saga';
import { all, call, spawn } from 'redux-saga/effects';

import { currentAppSaga } from './currentApp.saga';
import routingSaga from './routing.saga';

// TODO global logger
const log = (args) => {
  process.env.NODE_ENV === 'development' && console.log(args);
};
const logError = (args) => {
  process.env.NODE_ENV === 'development' && console.error(args);
};

let sagaRunner: Task;

function* rootSaga() {
  const sagas = [routingSaga, currentAppSaga];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            log(`[ROOT_SAGA] STARTING ${saga.name}`);
            yield call(saga);

            log(`[ROOT_SAGA] STOPPING ${saga.name}`);
            break;
          } catch (e) {
            logError(e);
          }
        }
      })
    )
  );
}

export function runSaga(sagaMiddleware: SagaMiddleware<object>) {
  if (process.env.NODE_ENV == 'development' && sagaRunner) {
    sagaRunner.cancel();
  }
  sagaRunner = sagaMiddleware.run(rootSaga);
}
