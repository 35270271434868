import React from 'react';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import Tooltip from '@fd/ui/Tooltip/Tooltip';

const StyledListItemText = styled(ListItemText)(() => ({
  span: {
    fontSize: '18px',
  },
  p: {
    fontSize: '12px',
  },
}));

const StyledListItem = styled(ListItem)(() => ({
  backgroundColor: '#dceafd',
  paddingTop: '17px',
  paddingRight: '24px',
  paddingBottom: '17px',
  paddingLeft: '42px',
}));

const StyledPublishStoreButton = styled(Button)(() => ({
  letterSpacing: '1.25px',
  marginRight: '16px',
}));

const PublishStoreButton = ({ translate, isOwner, handleSubmit }) => (
  <StyledListItem>
    <StyledListItemText
      primary={translate("This_store_isn't_published_yet")}
      secondary={translate('Ready_to_publish_store')}
      style={{ paddingRight: '180px' }}
    />
    <ListItemSecondaryAction>
      <Tooltip
        fdKey={'tooltip-publish-store'}
        messageId={'Only_an_owner_can_publish_or_unpublish_a_store'}
        disableHoverListener={isOwner}
        disableTouchListener={isOwner}
      >
        <div>
          <StyledPublishStoreButton
            data-fd="publish_store_btn"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isOwner}
          >
            {translate('Publish_store')}
          </StyledPublishStoreButton>
        </div>
      </Tooltip>
    </ListItemSecondaryAction>
  </StyledListItem>
);

export default PublishStoreButton;
