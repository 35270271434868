import React, { useEffect, useState } from 'react';

import { Store, StoreHeader } from '@flipdish/api-client-typescript';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../../layouts/Portal/ErrorBoundary';
import { loadByAppIdHeaders, loadById } from '../../../../../services/store.service';
import Select from '../../../../../ui/Select/Select';
import withRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../../../WithRouteSearchParams';

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 9,
  },
}));

type StoreOption = {
  label: string;
  value: number;
};

type StoreOptions = {
  stores: StoreOption[];
  hasSetInitialStores?: boolean;
};

type StoreFilterProps = {
  onSelectStore?: (selectedStores: number[] | number) => void;
  isMulti?: boolean;
  placeholder?: string;
  initialSelectedStoreIds?: number[];
  fieldError: string | undefined;
  variant?: 'outlined' | 'standard';
  disabled?: boolean;
};
type InnerProps = ReturnType<typeof mapStateToProps> & WithRouteSearchParamsProps<string[]>;
type OuterProps = StoreFilterProps;
type Props = InnerProps & OuterProps;

const StoreSelect = (props: Props) => {
  const classes = useStyles();
  const {
    appId,
    isMulti,
    onSelectStore,
    placeholder,
    initialSelectedStoreIds,
    fieldError,
    variant = 'standard',
    disabled = false,
  } = props;
  const [storeOptions, setStoreOptions] = useState<StoreOption[] | undefined>();
  const [selectedStores, setSelectedStores] = useState<StoreOptions>({
    stores: [],
    hasSetInitialStores: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStoresHeaders();
  }, [appId]);

  useEffect(() => {
    if (!storeOptions && initialSelectedStoreIds && initialSelectedStoreIds.length > 0) {
      return;
    }

    const updateInitalSelectedStores = async function () {
      const stores = await getInitialSelectedStores(initialSelectedStoreIds);
      setSelectedStores({
        stores: stores as StoreOption[],
        hasSetInitialStores: true,
      });
    };

    updateInitalSelectedStores();
  }, [initialSelectedStoreIds]);

  const parseStoreHeaders = (storeHeaders: StoreHeader[]) => {
    return storeHeaders.map((header) => ({
      label: header.Name,
      value: header.StoreId,
    }));
  };

  const parseStore = (store: Store) => {
    return {
      label: store.Name,
      value: store.StoreId,
    };
  };

  const getInitialSelectedStores = async (storeIds) => {
    if (Array.isArray(storeIds)) {
      const storeIdsRequest = storeIds.map((id) => loadById(parseInt(id, 10)));
      const storesData = await Promise.all(storeIdsRequest);
      return storesData.map((store) => parseStore(store));
    }
  };

  const getStoresHeaders = async (query?, page?, limit?) => {
    const { hasSetInitialStores } = selectedStores;
    if (appId) {
      setIsLoading(true);
      try {
        const storeHeaders = await loadByAppIdHeaders({
          appId,
          query,
          page,
          limit,
        });
        if (storeHeaders) {
          const newStores = parseStoreHeaders(storeHeaders);
          setStoreOptions(newStores as StoreOption[]);
        }

        if (!hasSetInitialStores && initialSelectedStoreIds) {
          const selectedStores = await getInitialSelectedStores(initialSelectedStoreIds);
          setSelectedStores({
            stores: selectedStores as StoreOption[],
            hasSetInitialStores: true,
          });
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const handleStoresChange = (values) => {
    setSelectedStores({
      stores: values,
      hasSetInitialStores: selectedStores.hasSetInitialStores,
    });
    if (isMulti) {
      const storeIds = values.map((v) => v.value);
      onSelectStore && onSelectStore(storeIds);
    } else {
      onSelectStore && onSelectStore(values ? values.value : null);
    }
  };

  return (
    <ErrorBoundary identifier="store-select">
      <Select
        className={classes.select}
        dataFd="stores-filter"
        isClearable
        isLoading={isLoading}
        isMulti={isMulti}
        onChange={handleStoresChange}
        options={storeOptions}
        placeholder={placeholder}
        TextFieldProps={{
          fdKey: 'stores-dropdown',
          name: 'stores-filter',
        }}
        value={selectedStores.stores}
        variant={variant}
        fieldError={fieldError}
        noErrorTranslate={true}
        isDisabled={disabled}
      />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId,
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'store',
  }),
  connect(mapStateToProps)
)(StoreSelect);

export default EnhancedComponent;
