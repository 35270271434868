import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { Button } from '@fd/ui/Button';
import LinkButton from '@fd/ui/Button/LinkButton';
import Spacer from '@fd/ui/Spacer';

import EmptyKiosks from '../../../assets/images/EmptyKiosks.svg';

type Props = { currentApp: { AppId: string } } & MappedProps;
const NoKiosks = (props: Props) => {
  const { currentApp } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={4}>
        <img src={EmptyKiosks} alt="No Kiosks" />
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography variant="h1">
          <Translate id={'Kiosks_Rock'} />
        </Typography>
        <Typography variant="subtitle1">
          <Translate id={'Kiosks_copy_1'} />
        </Typography>
        <Typography variant="subtitle1">
          <Translate id={'Kiosks_copy_2'} />
        </Typography>
        <Spacer size={24} variant="horizontal">
          {/* Hidden until we have proper help page setup */}
          <Hidden xsUp>
            <Button
              href="https://help.flipdish.com"
              color="primary"
              variant="contained"
              fdKey="flipdish-help"
              style={{ letterSpacing: '1.3px', width: '150px' }}
            >
              <Translate id="Tell_me_more" />
            </Button>
          </Hidden>
        </Spacer>
        <Permissions allowed={[App.AppResourceSetEnum.UpdateHydraConfigManage]}>
          <Spacer size={24} variant="horizontal">
            <LinkButton
              color="primary"
              variant="outlined"
              to={`/${currentApp.AppId}/sales-channels/kiosks/new`}
              type="button"
              fdKey="add-new-kiosk"
              style={{ letterSpacing: '1.3px' }}
            >
              <Translate id="Go_to_setup" />
            </LinkButton>
          </Spacer>
        </Permissions>
      </Grid>
    </Grid>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
});

export default connect(mapStateToProps)(NoKiosks);
