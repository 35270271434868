import { App } from '@flipdish/api-client-typescript';

import {
  SET_APP_CONFIG_LANGUAGES_SUCCESS,
  SET_APP_CONFIG_SUCCESS,
  SET_HOSTNAME_APP_CONFIG_SUCCESS,
} from '../components/WebsiteAndApp/actions';
import { appsConstants } from '../constants/apps.constants';

export { App as IAppState };

type CurrentAppState = App & {
  panaceaVanityUrl?: string;
};
const defaultCurrentAppState: CurrentAppState = {};
export function currentApp(state = defaultCurrentAppState, action): CurrentAppState {
  switch (action.type) {
    case appsConstants.SELECTED_APP_CHANGED:
      return { ...action.selectedApp };
    case SET_APP_CONFIG_SUCCESS:
      return { ...state, ...action.payload.data };
    case SET_APP_CONFIG_LANGUAGES_SUCCESS:
      return { ...state, Languages: action.payload.languages };
    case SET_HOSTNAME_APP_CONFIG_SUCCESS:
      return { ...state, HostName: action.payload.data };
    case appsConstants.SET_APP_PANACEA_VANITY_URL_SUCCESS:
    case appsConstants.GET_APP_PANACEA_VANITY_URL_SUCCESS: {
      return { ...state, panaceaVanityUrl: action.payload };
    }
    default:
      return state;
  }
}

type AppsState = {
  appsList: App[];
  loading: boolean;
  appsQueue: string[];
  searchQuery: string;
  appsTimestamp: string | null;
  totalAppsCount: number;
  queryAppsCount: number;
  page: number;
  appMenuStatus: boolean;
};

const initialState: AppsState = {
  appsList: [],
  loading: false,
  appsQueue: [],
  searchQuery: '',
  appsTimestamp: null,
  totalAppsCount: 0,
  queryAppsCount: 0,
  page: 1,
  appMenuStatus: false,
};

export function apps(state = initialState, action) {
  let index;

  switch (action.type) {
    case appsConstants.GET_TOTAL_APPS_COUNT:
      return {
        ...state,
        totalAppsCount: action.payload,
      };
    case appsConstants.CLEAR_GET_APPS:
      return {
        ...state,
        appsList: [],
        loading: true,
      };
    case appsConstants.GET_APPS:
      return {
        ...state,
        loading: true,
      };
    case appsConstants.GET_APPS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case appsConstants.GET_APPS_SUCCESS:
      return {
        ...state,
        appsList: action.payload.Data || action.payload.apps,
        queryAppsCount: action.payload.TotalRecordCount || action.payload.totalCount,
        loading: false,
      };
    case appsConstants.GET_APPS_SUCCESS_CONCAT:
      return {
        ...state,
        appsList: state.appsList.concat(action.payload.Data),
        queryAppsCount: action.payload.TotalRecordCount,
        loading: false,
      };
    case appsConstants.SET_APPS_TIMESTAMP:
      return {
        ...state,
        appsTimestamp: action.payload,
      };
    case appsConstants.ADD_TO_APPS_QUEUE:
      return {
        ...state,
        appsQueue: state.appsQueue.concat(action.payload),
      };
    case appsConstants.SET_APPS_QUEUE:
      return {
        ...state,
        appsQueue: action.payload,
      };
    case appsConstants.SET_PAGE_NUMBER:
      return {
        ...state,
        page: action.payload,
      };
    case appsConstants.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case appsConstants.TOGGLE_APP_MENU:
      return {
        ...state,
        appMenuStatus: action.payload,
      };
    case SET_APP_CONFIG_SUCCESS:
      index = state.appsList.findIndex((app) => app.AppId === action.payload.id);

      if (index >= 0 && state.appsList[index]) {
        return {
          ...state,
          appsList: [
            state.appsList.slice(0, index),
            { ...state.appsList[index], ...action.payload.data },
            state.appsList.slice(index + 1),
          ],
        };
      }

      return state;
    default:
      return state;
  }
}
