import React, { useRef } from 'react';

import { CreateProduct, Product } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';

import { notify, NotifyProps } from '../../../../layouts/Notify/actions';
import { catalogProductsService } from '../../services/catalogProducts.service';
import { generateSku, validateCatalogItemForm } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: '408px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 9,
  },
  cancelButton: {
    width: '50%',
  },
  createButton: {
    width: '50%',
    marginLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  form: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  field: {
    minHeight: '5rem',
  },
  message: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export type CreateModifierProps = {
  AppId: string;
  onClose: () => void;
  onModifierCreated: (newModifier) => void;
  translate: TranslateFunction;
};

const CreateModifier = (props: CreateModifierProps & MappedDispatch): JSX.Element => {
  const { AppId, onClose, onModifierCreated, notify, translate } = props;
  const classes = useStyles();
  const formRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: (newModifier: CreateProduct) => {
      return catalogProductsService.createCatalogProduct(AppId, newModifier);
    },

    onSuccess: (data) => {
      notify({
        variant: 'success',
        translate: true,
        message: 'Successfully_created',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogProductsService.getModifiersForGroupCreationQueryKey],
      });
      onModifierCreated(data.Data);
    },

    onError: () => {
      notify({
        variant: 'error',
        translate: true,
        message: 'Products_ToastMessage_Error',
      });
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <Typography variant="caption">
          <Translate id="Create_a_modifier_message" />
        </Typography>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          Name: '',
          Sku: generateSku('M'),
          Price: 0.0,
        }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          try {
            const newModifier = {
              ...values,
              Price: Number(values.Price),
              ProductType: Product.ProductTypeEnum.Modifier,
            } as CreateProduct;
            await mutateAsync(newModifier);
            setSubmitting(false);
          } catch (error) {
            console.log('error', error);
            const errorMessage = translate(
              'Product_CreateProductDialog_DuplicateSkuError'
            ) as string;
            error.message.includes('SKU') && setFieldError('Sku', errorMessage);
            setSubmitting(false);
          }
        }}
        validate={(values) => {
          return validateCatalogItemForm(values, translate);
        }}
      >
        {({ handleSubmit }) => (
          <Form id="create-modifier-form" onSubmit={handleSubmit} className={classes.form}>
            <FormikInputField
              className={classes.field}
              fdKey={'create-modifier-form-name'}
              name="Name"
              label={translate('Modifiers_CreateModifierDialog_ModifierName') as string}
            />
            <FormikInputField
              className={classes.field}
              fdKey={'create-modifier-form-sku'}
              name="Sku"
              label="SKU"
            />
            <FormikInputField
              className={classes.field}
              disableLabelAnimation
              fdKey="create-modifier-form-price"
              inputProps={{ inputMode: 'decimal', pattern: '^d*.?d*$', step: '0.01' }}
              name="Price"
              placeholder="0.00"
              type="number"
              label={translate('Product_CreateProductDialog_SetPrice') as string}
              variant="outlined"
            />
          </Form>
        )}
      </Formik>
      <div className={classes.actions}>
        <Divider />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.cancelButton}
            fdKey="create-modfiers-cancel-button"
            onClick={onClose}
            variant="secondary"
          >
            <Translate id="Cancel" />
          </Button>
          <Button
            className={classes.createButton}
            form="create-modifier-form"
            disabled={formRef.current?.isSubmitting}
            type="submit"
            fdKey="add-modifiers-confirm-button"
          >
            <Translate id="Modifiers_CreateModifierDialog_CreateModifier" />
          </Button>
        </div>
      </div>
    </div>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(CreateModifier);
export { EnhancedComponent as CreateModifier };
