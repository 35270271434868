import { useEffect } from 'react';

import { OrderLeadTimes } from '@flipdish/api-client-typescript';

type UseLeadTime = {
  data: number;
};

export const useLeadTime = (
  getStoreLeadTimes: (storeId: number) => void,
  type: 'collection' | 'delivery',
  storeId?: number,
  leadTimes?: OrderLeadTimes,
  isEnabled = false
): UseLeadTime => {
  useEffect(() => {
    if (isEnabled && !leadTimes && storeId) {
      getStoreLeadTimes(storeId);
    }
  }, [isEnabled, leadTimes, storeId]);

  const data = (leadTimes?.LeadTimeMinutes && leadTimes?.LeadTimeMinutes[type]) || 0;

  return {
    data,
  };
};
