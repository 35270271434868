import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

export type TFeatureFlag =
  | 'enable-stripe-cc'
  | 'order-capacity'
  | 'disable-campaign'
  | 'edit-bank-account'
  | 'enable-payout-details';
type MappedProps = ReturnType<typeof mapStateToProps> & Props;
type Props = {
  flag: TFeatureFlag;
  children?: React.ReactNode;
};
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
});

const FeatureFlag: React.FC<React.PropsWithChildren<MappedProps>> = ({
  children,
  currentApp,
  flag,
}) => {
  return <>{currentApp.Features && currentApp.Features.includes(flag) && <>{children}</>}</>;
};

const EnhancedComponent = compose<MappedProps, Props>(connect(mapStateToProps))(FeatureFlag);

export { EnhancedComponent as FeatureFlag };
