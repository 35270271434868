import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { EnableSwitch, Typography } from '@fd/ui/atoms';
import GridContainer from '@fd/ui/Layout/GridContainer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 0,
    paddingRight: theme.spacing(3),
  },
  formHelpText: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = {
  canEdit?: boolean;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: TranslationId;
  description?: TranslationId;
  name: string;
  fdKey?: string;
};

const FormSwitchSection = ({
  canEdit = true,
  checked,
  onChange,
  label,
  description,
  name,
  fdKey,
}: Props) => {
  const classes = useStyles();
  return (
    <GridContainer
      className={classes.container}
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Typography variant="body2">
          <Translate id={label} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        {description && (
          <Typography
            variantMapping={{ caption: 'p' }}
            className={classes.formHelpText}
            variant="caption"
          >
            <Translate id={description} />
          </Typography>
        )}
        <EnableSwitch
          name={name}
          checked={checked}
          disabled={!canEdit}
          onChange={onChange}
          fdKey={fdKey || ''}
          edge="start"
        />
      </Grid>
    </GridContainer>
  );
};

export default FormSwitchSection;
