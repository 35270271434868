import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

type StyleProps = { containerDivider: boolean };

const useStyles = makeStyles(({ palette: { divider }, spacing }: Theme) => ({
  container: ({ containerDivider }: StyleProps) => {
    return {
      borderBottom: containerDivider ? `1px solid ${divider}` : '',
      marginBottom: spacing(4),
      paddingBottom: spacing(4),
    };
  },
  children: { paddingLeft: spacing(1.5) },
  description: { marginBottom: spacing(2.5), paddingRight: spacing(1.5) },
  title: {
    marginBottom: spacing(1),
  },
}));

type Props = {
  alignChildren?: 'right' | 'bottom';
  containerDivider?: boolean;
  description: TranslationId;
  isLoading?: boolean;
  isFullWidthDescription?: boolean;
  title: TranslationId;
};

const KioskDetailSection: React.FC<React.PropsWithChildren<Props>> = ({
  alignChildren,
  containerDivider = true,
  children,
  description,
  isFullWidthDescription,
  isLoading,
  title,
}) => {
  const classes = useStyles({ containerDivider });
  if (isLoading) {
    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Skeleton width={'25%'} height={24} />
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 24, paddingRight: 12 }}>
          <Skeleton width={'100%'} height={48} />
        </Grid>
        {children && alignChildren === 'bottom' ? (
          <Grid container alignItems="center">
            {children}
          </Grid>
        ) : (
          <Grid item xs={6} className={classes.children}>
            {children}
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.title}>
          <Translate id={title} />
        </Typography>
      </Grid>
      <Grid item xs={isFullWidthDescription ? 12 : 6} className={classes.description}>
        <Typography variant="caption" component="p">
          <Translate id={description} />
        </Typography>
      </Grid>
      {children && alignChildren === 'bottom' ? (
        <Grid
          container
          alignItems="center"
          style={{ display: containerDivider ? 'flex' : 'inline' }}
        >
          {children}
        </Grid>
      ) : (
        <Grid item xs={6} className={classes.children}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default KioskDetailSection;
