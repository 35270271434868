import React from 'react';

import Avatar from '@mui/material/Avatar';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3),
    backgroundColor: '#EAF2FF',
    width: '100%',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  descriptionText: {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },
  iconContainer: {
    backgroundColor: '#CDD0EC',
    height: theme.spacing(9),
    marginRight: theme.spacing(3),
    width: theme.spacing(9),
  },
}));

export type PageActionHeaderProps = {
  children: React.ReactNode;
  description: string | React.ReactElement;
  icon?: React.ReactElement;
};

export const PageActionHeader = ({ children, icon, description }: PageActionHeaderProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <div className={classes.content}>
        {icon && (
          <Avatar data-fd="page-header-icon" className={classes.iconContainer}>
            {icon}
          </Avatar>
        )}
        <div>
          <div className={classes.description}>
            <Typography className={classes.descriptionText} variant="h5">
              {description}
            </Typography>
          </div>
        </div>
      </div>
      {children}
    </Box>
  );
};
