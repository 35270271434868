import React, { useState } from 'react';

import CalendarIcon from '@mui/icons-material/InsertInvitationOutlined';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { type FieldProps, Field } from 'formik';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

import Button from '@fd/ui/Button/Button';
import MuiPickersUtilsProvider from '@fd/ui/MuiPickersUtilsProvider';
import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../FormItemLayout';

const neverExpiresDate = moment('2100-01-01 00:00:00').toDate();

const DatePickersDialog = ({ onClose, onSave, StartDate, ExpiryDate }) => {
  const minDate = moment().hour(0).minute(0).second(0);

  const [startDate, setStartDate] = useState(() => moment(StartDate).hour(0).minute(0).second(0));

  const [isNotExpiring, setIsNotExpiring] = useState(() =>
    moment(ExpiryDate).year() === 2100 ? true : false
  );
  const [endDate, setEndDate] = useState(() => moment(ExpiryDate).hour(23).minute(59).second(59));

  return (
    <Dialog open={true} maxWidth="xs" onClose={onClose}>
      <DialogContent>
        <MuiPickersUtilsProvider>
          <Grid container>
            <Grid item xs={12}>
              <DatePicker
                // Hide error message on valid_from field, as any date after today can be selected
                // Error will only be shown on expires_on field if its date is before "from" date
                slotProps={{
                  textField: {
                    variant: 'standard',
                    error: false,
                    helperText: '',
                    fullWidth: true,
                    label: <Translate id="Valid_from" data={{ startDateFormatted: '' }} />,
                    inputProps: { placeholder: '' },
                  },
                  popper: { style: { zIndex: 9999 } },
                }}
                data-fd="voucher-valid-from"
                minDate={minDate}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date as any);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-fd="voucher-doesnt-expire"
                    color="default"
                    name={'IsNotExpiring'}
                    checked={isNotExpiring}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsNotExpiring(e.target.checked);
                    }}
                  />
                }
                label={<Translate id="Doesnt_expire" />}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                data-fd="voucher-expires-on"
                disabled={isNotExpiring}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    fullWidth: true,
                    label: <Translate id="Expires_on" />,
                    inputProps: { placeholder: '' },
                  },
                  popper: { style: { zIndex: 9999 } },
                }}
                minDate={startDate}
                value={endDate}
                onChange={(date) => {
                  setEndDate(date as any);
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" fdKey="voucher-is-valid-cancel">
          <Translate id="Cancel" />
        </Button>

        <Button
          onClick={() => {
            onSave({ StartDate: startDate, ExpiryDate: endDate, IsNotExpiring: isNotExpiring });
          }}
          disabled={moment(startDate).isAfter(moment(endDate)) && !isNotExpiring} // disable "Set" button if expiry date is before "from" date
          color="primary"
          fdKey="voucher-is-valid-set"
        >
          <Translate id="Set" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const IsValidField = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Field name={'ValidDates'}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <FormItemLayout label={<Translate id="Voucher_is_valid" />}>
            <TextField
              {...field}
              autoComplete="off"
              disabled={isSubmitting}
              error={showError}
              fdKey={'voucher-is-valid'}
              helperText={showError ? <Translate id={fieldError as TranslationId} /> : undefined}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
              minWidth={315}
              onChange={() => {
                setVisible(true);
              }}
              onClick={() => {
                setVisible(true);
              }}
              onFocus={(e: React.FocusEvent) => {
                // to prevent switching browser tabs opening this dialog
                if (e.relatedTarget) {
                  setVisible(true);
                }
              }}
              variant="outlined"
            />

            {visible && !form.isSubmitting && (
              <Translate>
                {(translate) => (
                  <DatePickersDialog
                    StartDate={form.values.StartDate}
                    ExpiryDate={form.values.ExpiryDate}
                    onSave={({ StartDate, ExpiryDate, IsNotExpiring }) => {
                      const startText = moment(StartDate).format('L');
                      const expireText = IsNotExpiring
                        ? translate('Doesnt_expire')
                        : moment(ExpiryDate).format('L');
                      const datesFormatted = `${startText} - ${expireText}`;
                      form.setFieldValue('ValidDates', datesFormatted, false);

                      if (ExpiryDate.isBefore(StartDate)) {
                        form.setFieldError(
                          'ValidDates',
                          translate('End_date_should_be_after_start_date') as string
                        );
                      } else {
                        form.setFieldError('ValidDates', '');
                      }

                      form.setFieldValue('StartDate', StartDate, false);
                      form.setFieldValue(
                        'ExpiryDate',
                        IsNotExpiring ? neverExpiresDate : ExpiryDate,
                        false
                      );

                      setVisible(false);
                    }}
                    onClose={() => {
                      setVisible(false);
                    }}
                  />
                )}
              </Translate>
            )}
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default IsValidField;
