import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router-dom';

import { Typography } from '@fd/ui/atoms/Typography';
import LinkButton from '@fd/ui/Button/LinkButton';
import { PageActionHeader } from '@fd/ui/molecules/PageActionHeader';
import { PageHeader } from '@fd/ui/molecules/PageHeader';

import { ReactComponent as MetafieldsIcon } from '../../../../../assets/images/metafields.svg';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { getEntityTranslationKey } from '../../../../../services/utils/stringConverters';
import PageLayout from '../../../../../ui/Layout';
import { MetafieldsTable } from '../../components/MetafieldTable';
import { NoMetafieldDefinitionsMessage } from '../../components/NoMetafieldDefinitionsMessage';
import { metafieldsService } from '../../metafields.service';

const useStyles = makeStyles((theme: Theme) => ({
  addDefinitionButton: {
    width: '100%',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
  },
  listTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

type Props = RouteComponentProps<{ ownerEntity: string }>;

const MetafieldDefinitions = ({ appId, isMetafieldsOn, ownerEntity, translate }: MappedState) => {
  const classes = useStyles();
  const entityTranslationKey = getEntityTranslationKey(ownerEntity);
  const descriptionKey = `${entityTranslationKey}_Metafield_Description` as TranslationId;
  const actionKey = `${entityTranslationKey}_Add_Definition` as TranslationId;

  const { data, isLoading } = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, appId, entityTranslationKey],
    queryFn: () => metafieldsService.getMetafieldsByEntity(appId, entityTranslationKey),
    enabled: isMetafieldsOn,
  });

  return isMetafieldsOn ? (
    <PageLayout
      documentTitle={`${entityTranslationKey}_metafield_definitions`}
      strictToParent={true}
      title={<Translate id={`${entityTranslationKey}_metafield_definitions` as TranslationId} />}
      toParent={`/${appId}/settings/metafields`}
    >
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid className={classes.header} item xs={12} sm={7}>
            <PageHeader
              description={translate(descriptionKey)}
              heading={`${translate(entityTranslationKey)} ${translate('Metafields')}`}
              icon={<MetafieldsIcon color="rgb(139,139,139)" />}
            />
          </Grid>
          <Grid className={classes.header} item xs={12} sm={5}>
            <Permissions allowed={[App.AppResourceSetEnum.CreateMetafieldDefinitions]}>
              <PageActionHeader
                description={translate(actionKey)}
                icon={<MetafieldsIcon color="#05149E" height={24} width={26.67} />}
              >
                <LinkButton
                  className={classes.addDefinitionButton}
                  fdKey="create-metafield-definition-button"
                  variant="contained"
                  color="primary"
                  to={`/${appId}/settings/metafields/${ownerEntity}/create`}
                >
                  <Add />
                  <Translate id="Add_Definition" />
                </LinkButton>
              </PageActionHeader>
            </Permissions>
          </Grid>
        </Grid>
        <Permissions allowed={[App.AppResourceSetEnum.ViewMetafieldDefinitions]}>
          <div className={classes.listTitle}>
            <Typography>
              <Translate id="Custom_metafields" />
            </Typography>
          </div>
          {data?.TotalRecordCount != 0 ? (
            <MetafieldsTable
              fdKey="metafield-definitions-table"
              isLoading={isLoading}
              metafields={data?.Data}
            />
          ) : (
            <NoMetafieldDefinitionsMessage translate={translate} />
          )}
        </Permissions>
      </div>
    </PageLayout>
  ) : (
    <Typography>
      <Translate id="Coming_soon" />
    </Typography>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  return {
    appId: state.currentApp.AppId as string,
    isMetafieldsOn: isProductBasedMenusEnabled(state),
    ownerEntity: props.match.params.ownerEntity,
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = connect(mapStateToProps)(MetafieldDefinitions);
export { EnhancedComponent as MetafieldDefinitions };
