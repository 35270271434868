import { PreOrderConfig, Store } from '@flipdish/api-client-typescript';
import { createAction } from 'redux-act';

import { getSelectedStore } from '../../../selectors/store.selector';
import { getPreOrderConfig, setPreOrderStatus, updatePreOrderConfig } from './service';

// #region preOrderDeliverySettings
export const GET_PRE_ORDER_DELIVERY_SETTINGS = 'GET_PRE_ORDER_DELIVERY_SETTINGS';
export const GET_PRE_ORDER_DELIVERY_SETTINGS_REQUEST = `${GET_PRE_ORDER_DELIVERY_SETTINGS}_REQUEST`;
export const GET_PRE_ORDER_DELIVERY_SETTINGS_SUCCESS = `${GET_PRE_ORDER_DELIVERY_SETTINGS}_SUCCESS`;
export const GET_PRE_ORDER_DELIVERY_SETTINGS_FAILURE = `${GET_PRE_ORDER_DELIVERY_SETTINGS}_FAILURE`;

export const getPreOrderDeliverySettingsRequest = createAction(
  GET_PRE_ORDER_DELIVERY_SETTINGS_REQUEST
);

export const getPreOrderDeliverySettingsRequestSuccess = createAction<PreOrderConfig | false>(
  GET_PRE_ORDER_DELIVERY_SETTINGS_SUCCESS
);

export const getPreOrderDeliverySettingsRequestFailure = createAction<string | null>(
  GET_PRE_ORDER_DELIVERY_SETTINGS_FAILURE
);

export const getPreOrderDeliverySettings = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getPreOrderDeliverySettingsRequest());

      const data = await getPreOrderConfig(storeId, 'Delivery');

      dispatch(getPreOrderDeliverySettingsRequestSuccess(data));
    } catch (e) {
      dispatch(getPreOrderDeliverySettingsRequestFailure(e));
    }
  };
};
// #endregion

// #region update preOrderDeliverySettings
export const UPDATE_PRE_ORDER_DELIVERY_SETTINGS = 'UPDATE_PRE_ORDER_DELIVERY_SETTINGS';
export const UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST = `${UPDATE_PRE_ORDER_DELIVERY_SETTINGS}_REQUEST`;
export const UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST_SUCCESS = `${UPDATE_PRE_ORDER_DELIVERY_SETTINGS}_SUCCESS`;
export const UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST_FAILURE = `${UPDATE_PRE_ORDER_DELIVERY_SETTINGS}_FAILURE`;

export const updatePreOrderDeliverySettingsRequest = createAction(
  UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST
);

export const updatePreOrderDeliverySettingsRequestSuccess = createAction<PreOrderConfig>(
  UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST_SUCCESS
);

export const updatePreOrderDeliverySettingsRequestFailure = createAction<string | null>(
  UPDATE_PRE_ORDER_DELIVERY_SETTINGS_REQUEST_FAILURE
);

export const updatePreOrderDeliverySettings = (storeId: number, config: PreOrderConfig) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updatePreOrderDeliverySettingsRequest());

      await updatePreOrderConfig(storeId, 'Delivery', config);

      dispatch(updatePreOrderDeliverySettingsRequestSuccess(config));
    } catch (e) {
      dispatch(updatePreOrderDeliverySettingsRequestFailure(e));
    }
  };
};
// #endregion

// #region preOrderCollectionSettings
export const GET_PRE_ORDER_COLLECTION_SETTINGS = 'GET_PRE_ORDER_COLLECTION_SETTINGS';
export const GET_PRE_ORDER_COLLECTION_SETTINGS_REQUEST = `${GET_PRE_ORDER_COLLECTION_SETTINGS}_REQUEST`;
export const GET_PRE_ORDER_COLLECTION_SETTINGS_SUCCESS = `${GET_PRE_ORDER_COLLECTION_SETTINGS}_SUCCESS`;
export const GET_PRE_ORDER_COLLECTION_SETTINGS_FAILURE = `${GET_PRE_ORDER_COLLECTION_SETTINGS}_FAILURE`;

export const getPreOrderCollectionSettingsRequest = createAction(
  GET_PRE_ORDER_COLLECTION_SETTINGS_REQUEST
);

export const getPreOrderCollectionSettingsRequestSuccess = createAction<PreOrderConfig | false>(
  GET_PRE_ORDER_COLLECTION_SETTINGS_SUCCESS
);

export const getPreOrderCollectionSettingsRequestFailure = createAction<string | null>(
  GET_PRE_ORDER_COLLECTION_SETTINGS_FAILURE
);

export const getPreOrderCollectionSettings = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getPreOrderCollectionSettingsRequest());

      const data = await getPreOrderConfig(storeId, 'Pickup');

      dispatch(getPreOrderCollectionSettingsRequestSuccess(data));
    } catch (e) {
      dispatch(getPreOrderCollectionSettingsRequestFailure(e));
    }
  };
};
// #endregion

// #region update preOrderDeliverySettings
export const UPDATE_PRE_ORDER_COLLECTION_SETTINGS = 'UPDATE_PRE_ORDER_COLLECTION_SETTINGS';
export const UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST = `${UPDATE_PRE_ORDER_COLLECTION_SETTINGS}_REQUEST`;
export const UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST_SUCCESS = `${UPDATE_PRE_ORDER_COLLECTION_SETTINGS}_SUCCESS`;
export const UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST_FAILURE = `${UPDATE_PRE_ORDER_COLLECTION_SETTINGS}_FAILURE`;

export const updatePreOrderCollectionSettingsRequest = createAction(
  UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST
);

export const updatePreOrderCollectionSettingsRequestSuccess = createAction<PreOrderConfig>(
  UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST_SUCCESS
);

export const updatePreOrderCollectionSettingsRequestFailure = createAction<string | null>(
  UPDATE_PRE_ORDER_COLLECTION_SETTINGS_REQUEST_FAILURE
);

export const updatePreOrderCollectionSettings = (storeId: number, config: PreOrderConfig) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updatePreOrderCollectionSettingsRequest());

      await updatePreOrderConfig(storeId, 'Pickup', config);

      dispatch(updatePreOrderCollectionSettingsRequestSuccess(config));
    } catch (e) {
      dispatch(updatePreOrderCollectionSettingsRequestFailure(e));
    }
  };
};
// #endregion

// #region update setPredOrderSettingsStatus
export const SET_PRE_ORDER_SETTINGS_STATUS = 'SET_PRE_ORDER_SETTINGS_STATUS';
export const SET_PRE_ORDER_SETTINGS_STATUS_REQUEST = `${SET_PRE_ORDER_SETTINGS_STATUS}_REQUEST`;
export const SET_PRE_ORDER_SETTINGS_STATUS_SUCCESS = `${SET_PRE_ORDER_SETTINGS_STATUS}_SUCCESS`;
export const SET_PRE_ORDER_SETTINGS_STATUS_FAILURE = `${SET_PRE_ORDER_SETTINGS_STATUS}_FAILURE`;

export const setPreOrderSettingsStatusRequest = createAction(SET_PRE_ORDER_SETTINGS_STATUS_REQUEST);

export const setPreOrderSettingsStatusRequestSuccess = createAction<{
  store: Store;
  status: boolean;
  type: 'Delivery' | 'Pickup';
}>(SET_PRE_ORDER_SETTINGS_STATUS_SUCCESS);

export const setPreOrderSettingsStatusRequestFailure = createAction<string | null>(
  SET_PRE_ORDER_SETTINGS_STATUS_FAILURE
);

export const setPreOrderSettingsStatus = (
  storeId: number,
  type: 'Delivery' | 'Pickup',
  status: boolean
) => {
  return async (dispatch: ThunkDispatch, getState) => {
    try {
      const store = getSelectedStore(getState());

      if (store) {
        dispatch(setPreOrderSettingsStatusRequest());
        await setPreOrderStatus(storeId, type, status);
        dispatch(setPreOrderSettingsStatusRequestSuccess({ store, status, type }));
      }
    } catch (e) {
      dispatch(updatePreOrderCollectionSettingsRequestFailure(e));
    }
  };
};
// #endregion
