import React, { SyntheticEvent } from 'react';

import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import { LogContent } from './LogContent';
import { LogDate } from './LogDate';
import { LogIcon } from './LogIcon';
import { LogUser } from './LogUser';
import { AuditLog } from './service';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  cellContent: {
    display: 'flex',
    padding: '10px 0',
    alignItems: 'center',
  },
}));

const useMobileStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: `20px ${theme.spacing(2)} 12px`,
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
  },
  content: {
    fontSize: '10px',
    padding: '0 16px',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  logContent: {
    padding: '0 0 8px',
  },
  title: {
    fontSize: '16px',
    marginBottom: '4px',
  },
  date: {
    flexShrink: 0,
    fontSize: '12px',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.6)',
    '& b': {
      fontSize: '14px',
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

type InnerProps = RouteComponentProps & MappedProps;

type OuterProps = {
  data: AuditLog;
};

type Props = InnerProps & OuterProps;

const LogItem = ({ data, history, currentApp, translate }: Props) => {
  const classes = useStyles();

  return (
    <TableRow
      className={classes.root}
      onClick={(event: SyntheticEvent<HTMLTableRowElement>) => {
        if ((event.target as Element).tagName.toLowerCase() !== 'a') {
          data?.FlipdishEventId &&
            history.push(`/${currentApp.AppId}/logs/${data.FlipdishEventId}`);
        }
      }}
    >
      <TableCell>
        <div className={classes.cellContent}>
          {data && (
            <>
              <LogIcon data={data} />
              <LogContent data={data} />
            </>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.cellContent}>{data && <LogUser data={data} />}</div>
      </TableCell>
      <TableCell>
        <div className={classes.cellContent}>
          {data?.CreateTime && <LogDate date={data.CreateTime} translate={translate} />}
        </div>
      </TableCell>
    </TableRow>
  );
};

const MobileLogItem = ({ data, history, currentApp, translate }: Props) => {
  const classes = useMobileStyles();

  return (
    <>
      <div
        className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME}`}
        onClick={(event: SyntheticEvent<HTMLDivElement>) => {
          if ((event.target as Element).tagName.toLowerCase() !== 'a') {
            data.FlipdishEventId &&
              history.push(`/${currentApp.AppId}/logs/${data.FlipdishEventId}`);
          }
        }}
      >
        <div>{data && <LogIcon data={data} />}</div>
        <div className={classes.content}>
          {data && (
            <>
              <LogContent
                classes={{ root: classes.logContent, title: classes.title }}
                data={data}
              />
              <LogUser data={data} />
            </>
          )}
        </div>
        <div className={classes.date}>
          {data?.CreateTime && <LogDate isMobile date={data.CreateTime} translate={translate} />}
        </div>
      </div>
      <Divider />
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
  currentApp: state.currentApp,
});

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(LogItem);
const EnhancedMobileComponent = compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(MobileLogItem);

export { EnhancedComponent as LogItem };
export { EnhancedMobileComponent as MobileLogItem };
