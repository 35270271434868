import React, { ComponentType } from 'react';

import { HomeAction } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Button from '../../../ui/Button/Button';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import Spacer from '../../../ui/Spacer';

const useStyles = makeStyles({
  root: {
    '& a': {
      textDecoration: 'none',
    },
  },
});

export interface IListItemProps {
  data: HomeAction;
  isLast: boolean;
  titleData?: { [key: string]: string };
  descriptionData?: { [key: string]: string };
  descriptionComponent?: ComponentType<React.PropsWithChildren<unknown>>;
  action?: React.ReactElement;
  dataFd: string;
  onDismiss(homeActionId: number);
}

const SetupFieldListItem = (props: IListItemProps) => {
  const classes = useStyles();
  const {
    isLast,
    data,
    action,
    onDismiss,
    dataFd,
    titleData = {},
    descriptionData = {},
    descriptionComponent,
  } = props;

  return (
    <ListItem
      disableGutters
      divider={!isLast}
      className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME}`}
    >
      <Grid container alignContent="flex-end">
        <Grid item xs={12} md={8}>
          <Typography component="div" variant="h5">
            <Translate id={data.TitleKey as TranslationId} data={titleData} />
          </Typography>
          <Typography component="div" variant="body1">
            {descriptionComponent ? (
              <>{descriptionComponent}</>
            ) : (
              <Translate id={data.DescriptionKey as TranslationId} data={descriptionData} />
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {data.Dismissible && (
              <Grid item xs={4} md={3} container justifyContent="flex-end">
                <Spacer size={8} variant="horizontal">
                  <Button
                    fdKey={`dismiss-${dataFd}`}
                    onClick={() => onDismiss(data.HomeActionId as number)}
                    color="primary"
                    variant="text"
                  >
                    <Translate id="Dismiss" />
                  </Button>
                </Spacer>
              </Grid>
            )}

            {data.Dismissible && action ? <Spacer size={16} variant="vertical" /> : null}

            <Grid item xs sm={6} md={8}>
              <Spacer size={8} variant="horizontal">
                {action}
              </Spacer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default SetupFieldListItem;
