import { Store } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

type CurrentApp = ReturnType<typeof currentAppSelector>;
const currentAppSelector = (state: AppState) => state.currentApp;

export const getDeviceListUrl = createSelector([currentAppSelector], (app) =>
  app ? `/${app.AppId}/terminals` : undefined
);

export const getAddNewDeviceUrl = createSelector([currentAppSelector], (app) =>
  app ? `/${app.AppId}/terminals/new` : undefined
);

const deviceStores = (state: AppState) => state.devices.stores;
export const getStoreList = createSelector([deviceStores], (stores: Store[]) => {
  if (stores) {
    return stores.map((store) => {
      return { value: store.StoreId, label: store.Name };
    });
  } else {
    return [];
  }
});

export const enumMapping = (deviceType) => {
  switch (deviceType) {
    case 1:
      return 'Terminal';
    case 2:
      return 'LegacyPrinter';
    case 3:
      return 'Kiosk';
    default:
      return deviceType;
  }
};

export const getDeviceDetailsUrlFactory = (deviceId: string | undefined) =>
  createSelector([currentAppSelector], (app) =>
    app ? `/${app.AppId}/terminals/${deviceId}` : undefined
  );
