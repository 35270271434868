import { useEffect } from 'react';

const useMouseDown = (callback) => {
  useEffect(() => {
    window.addEventListener('mousedown', callback);
    return () => window.removeEventListener('mousedown', callback);
  }, [callback]);
};

export default useMouseDown;
