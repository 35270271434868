import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms/Typography';

import HelpDrawer from '../HelpDrawer/HelpDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  helpdeskButton: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export type HelpdeskButtonProps = {
  label: string;
  text?: TranslationId;
};

export const HelpdeskButton = ({ label, text = 'helpdesk' }: HelpdeskButtonProps) => {
  const classes = useStyles();
  return (
    <HelpDrawer label={label}>
      <Typography className={classes.helpdeskButton} variant="caption">
        <Translate id={text} />
      </Typography>
    </HelpDrawer>
  );
};
