import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

export interface SplitTextProps {
  className?: string;
  top: React.ReactNode;
  bottom: React.ReactNode;
}
const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    fontSize: '12px',
    display: 'block',
    fontWeight: 'normal',
  },
  primaryText: {
    fontWeight: 500,
  },
  textContainer: {
    lineHeight: '1',
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'none',
  },
}));

export const SplitText = ({ top, bottom, className }: SplitTextProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.textContainer, className)}>
      <span className={classes.caption}>{top}</span>
      <span className={classes.primaryText}>{bottom}</span>
    </div>
  );
};
