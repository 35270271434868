export const mapsStyles: google.maps.MapTypeStyle[] = [
  { elementType: 'geometry', stylers: [{ color: '#0d095c' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#0d095c' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#649cef' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a9c6f1' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#649cef' }],
  },
  {
    featureType: 'poi.attraction',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.government',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.medical',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.place_of_worship',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.school',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.sports_complex',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#649cef' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#1d3083' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1d3083' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a9c6f1' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#335eb8' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#335eb8' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a9c6f1' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#192771' }],
  },
  {
    featureType: 'transit.station',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#1d40a7' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#88b3f2' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#1d40a7' }],
  },
];

export const mapStylesQuery = () => {
  return (
    '&style=' +
    mapsStyles
      .filter((m) => m)
      .map((m) => {
        const data = [
          m.featureType ? `feature:${m.featureType}` : undefined,
          m.elementType ? `element:${m.elementType}` : undefined,
          unwrapStylers(m.stylers),
        ].filter((d) => d);
        const dataMaybe = data.length > 0 ? data.join('|') : undefined;
        return dataMaybe ? dataMaybe.substring(0, dataMaybe.length - 1) : dataMaybe;
      })
      .filter((m) => m)
      .join('&style=')
  );
};

function unwrapStylers(stylers): string {
  return stylers
    .filter((m) => m)
    .map((m) => {
      let style = '';
      for (const key in m) {
        if (m[key] !== '') {
          style += `${key}:${m[key].replace('#', '0x')}|`;
        }
      }
      return style.length > 0 ? style : undefined;
    });
}
