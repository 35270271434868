import React from 'react';

import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link';

export interface ExternalLinkProps extends Omit<MuiLinkProps, 'variant'> {
  href: string;
  children: React.ReactNode;
  underline?: 'none' | 'hover' | 'always';
}

export const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
  const { children, href, underline = 'hover' } = props;

  return (
    <MuiLink href={href} rel="noopener noreferrer" target="_blank" underline={underline}>
      {children}
    </MuiLink>
  );
};
