import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import FiltersContainer from '../../../../ui/Layout/FiltersContainer';
import DateFilter from '../../../DateFilter';
import { customRange } from '../helpers';
import { PayoutStatus } from '../types';
import PayoutsAccountNameFilter from './Filters/PayoutsAccountNameFilter';
import PayoutsStatesFilter from './Filters/PayoutsStatesFilter';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type InnerProps = MappedProps;
type OuterProps = {
  onDateChange: (start: moment.Moment, end: moment.Moment, prevPeriod) => void;
  setPayoutStates: (selectedPayoutStates: PayoutStatus[]) => void;
  setAccountIds: (selectedPayoutAccountIds: number[]) => void;
};
type Props = InnerProps & OuterProps;

const PayoutFilters = (props: Props) => {
  const { onDateChange, setPayoutStates, translate, setAccountIds } = props;
  const classes = useStyles();

  return (
    <>
      <FiltersContainer>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <DateFilter
            customRange={customRange}
            datePeriodDefault={'last30Days'} // presetRanges urls from src\components\Reports\helpers.tsx
            includeTodayInDatePeriod
            isCompare={false}
            onChange={onDateChange}
            selectLabel={translate('Date_period') as string}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <PayoutsAccountNameFilter onChange={setAccountIds} />
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <PayoutsStatesFilter onChange={setPayoutStates} />
        </Grid>
      </FiltersContainer>
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(PayoutFilters);
