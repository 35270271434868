import {
  Order,
  OrderDeliveryInformationBase,
  OrderFulfillmentStatusWithConfigurationActions,
  OrderSummary,
} from '@flipdish/api-client-typescript';

import {
  GetDeliveryInfoForOrderByIdSuccess,
  GetFulfillmentStatusForOrderByIdSuccess,
  GetOrdersSummarySuccess,
  GetOrderSuccess,
} from '../actions/order.action';
import { ordersConstants } from '../constants/order.constants';
import { orderEventConstants } from '../signalr/hub.events';

const {
  GET_ORDERS_REQUEST,
  GET_ORDER_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_DELIVERY_INFO_REQUEST,
  GET_ORDER_DELIVERY_INFO_SUCCESS,
  GET_ORDER_DELIVERY_INFO_FAILURE,
  GET_ORDER_FULFILLMENT_STATUS_REQUEST,
  GET_ORDER_FULFILLMENT_STATUS_SUCCESS,
  GET_ORDER_FULFILLMENT_STATUS_FAILURE,
  REJECT_ORDER_REQUEST,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAILURE,
  ACCEPT_ORDER_REQUEST,
  ACCEPT_ORDER_SUCCESS,
  ACCEPT_ORDER_FAILURE,
  REFUND_ORDER_REQUEST,
  REFUND_ORDER_SUCCESS,
  REFUND_ORDER_FAILURE,
  GET_LIVE_VIEW_ORDER_SUCCESS,
} = ordersConstants;

export interface State {
  orders: OrderSummary[];
  ordersIds: Set<number>;
  ordersCount: number;
  ordersLoading: boolean;
  orderLoading: boolean;
  order: Order | OrderSummary;
  orderTimestamp?: number;
  orderDeliveryInfo?: OrderDeliveryInformationBase;
  orderDeliveryInfoLoading: boolean;
  orderDeliveryInfoFailed: boolean;
  orderFulfillmentStatus?: OrderFulfillmentStatusWithConfigurationActions;
  orderFulfillmentStatusLoading: boolean;
  orderFulfillmentStatusFailed: boolean;
  acceptOrderLoading: boolean;
  acceptOrderFailed: boolean;
  rejectOrderLoading: boolean;
  rejectOrderFailed: boolean;
  refundOrderLoading: boolean;
  refundOrderFailed: boolean;
  orderSearchCodeResults: [];
}
const defaultState: State = {
  orders: [],
  ordersIds: new Set(),
  ordersCount: 0,
  ordersLoading: false,
  orderLoading: true,
  order: {
    OrderId: undefined,
  },
  orderTimestamp: undefined,
  orderDeliveryInfo: undefined,
  orderDeliveryInfoLoading: false,
  orderDeliveryInfoFailed: false,
  orderFulfillmentStatus: undefined,
  orderFulfillmentStatusLoading: false,
  orderFulfillmentStatusFailed: false,
  acceptOrderLoading: false,
  acceptOrderFailed: false,
  rejectOrderLoading: false,
  rejectOrderFailed: false,
  refundOrderLoading: false,
  refundOrderFailed: false,
  orderSearchCodeResults: [],
};

const getUniqueOrders = (
  orders: OrderSummary[],
  ordersIds: Set<number>,
  isInitialOrders: boolean
): OrderSummary[] => {
  if (isInitialOrders) {
    ordersIds.clear();
  }

  return orders.filter((order) => {
    const isNew = order.OrderId !== undefined ? !ordersIds.has(order.OrderId) : true;

    if (isNew && order.OrderId !== undefined) {
      ordersIds.add(order.OrderId);
    }

    return isNew;
  });
};

export function orders(state = defaultState, action): State {
  switch (action.type) {
    case ordersConstants.CLEAR_ORDERS:
      return {
        ...state,
        orders: [],
        ordersCount: 0,
        ordersLoading: true,
      };
    case ordersConstants.CLEAR_ORDER:
      return {
        ...state,
        order: {},
      };
    case ACCEPT_ORDER_REQUEST:
      return {
        ...state,
        acceptOrderLoading: true,
        acceptOrderFailed: false,
      };
    case ACCEPT_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          OrderState: Order.OrderStateEnum.AcceptedByRestaurant,
        },
        acceptOrderLoading: false,
        acceptOrderFailed: false,
      };
    case ACCEPT_ORDER_FAILURE:
      return {
        ...state,
        acceptOrderLoading: false,
        acceptOrderFailed: true,
      };
    case REFUND_ORDER_REQUEST:
      return {
        ...state,
        refundOrderLoading: true,
        refundOrderFailed: false,
      };
    case REFUND_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          OrderState: Order.OrderStateEnum.AcceptedAndRefunded,
          RefundedAmount: state.order.RefundedAmount,
        },
        refundOrderLoading: false,
        refundOrderFailed: false,
      };
    case REFUND_ORDER_FAILURE:
      return {
        ...state,
        refundOrderLoading: false,
        refundOrderFailed: true,
      };
    case REJECT_ORDER_REQUEST:
      return {
        ...state,
        rejectOrderLoading: true,
        rejectOrderFailed: false,
      };
    case REJECT_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          OrderState: Order.OrderStateEnum.RejectedByFlipdish,
        },
        rejectOrderLoading: false,
        rejectOrderFailed: false,
      };
    case REJECT_ORDER_FAILURE:
      return {
        ...state,
        rejectOrderLoading: false,
        rejectOrderFailed: true,
      };
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        ordersLoading: true,
      };
    case GET_ORDER_REQUEST:
      return {
        ...state,
        orderLoading: true,
        orderDeliveryInfo: undefined,
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        ordersLoading: false,
      };
    case GET_ORDERS_SUCCESS: {
      const { orders, totalCount } = (action as GetOrdersSummarySuccess).payload;
      const { concat } = (action as GetOrdersSummarySuccess).meta;

      const uniqueOrders = getUniqueOrders(orders, state.ordersIds, !concat);

      return {
        ...state,
        orders: concat ? state.orders.concat(uniqueOrders) : uniqueOrders,
        ordersLoading: false,
        ordersCount: totalCount,
      };
    }

    case GET_ORDER_SUCCESS: {
      const { payload } = action as GetOrderSuccess;
      return {
        ...state,
        order: payload,
        orderTimestamp: undefined,
        orderLoading: false,
      };
    }
    case GET_ORDER_FAILURE:
      return {
        ...state,
        order: {
          OrderId: undefined,
        },
        orderLoading: false,
      };
    case ordersConstants.SET_ORDER_TIMESTAMP:
      return {
        ...state,
        orderTimestamp: action.payload,
      };

    case orderEventConstants.CREATED:
    case orderEventConstants.REJECTED:
    case orderEventConstants.ACCEPTED:
    case orderEventConstants.REFUNDED: {
      return {
        ...state,
        orders: getUniqueOrders(action.payload.orders, state.ordersIds, true),
      };
    }
    case orderEventConstants.RATING_UPDATED:
      return state;

    case orderEventConstants.TIP_UPDATED: {
      let newOrder = state.order;
      let newOrders: any[] = state.orders;

      if (
        newOrder &&
        (newOrder as any).OrderId &&
        (newOrder as any).OrderId === action.data.OrderId
      ) {
        newOrder = action.data;
      }

      const index = newOrders.findIndex((order) => (order as any).OrderId === action.data.OrderId);

      const orderSummary = {} as OrderSummary;
      orderSummary.Amount = action.data.Amount;
      orderSummary.Currency = action.data.Currency;
      orderSummary.CustomerName = action.data.Customer.Name;
      orderSummary.CustomerPhoneNumber = action.data.Customer.PhoneNumber;
      orderSummary.DeliveryType = action.data.DeliveryType;
      orderSummary.OrderId = action.data.OrderId;
      orderSummary.OrderState = action.data.OrderState;
      orderSummary.RequestedForTime = action.data.RequestedForTime;
      orderSummary.StoreName = action.data.Store.Name;
      if (index != -1) {
        newOrders = [...newOrders.splice(0, index), orderSummary, ...newOrders.splice(index + 1)];
      }

      return {
        ...state,
        order: newOrder,
        orders: newOrders,
      };
    }
    case GET_LIVE_VIEW_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
      };
    case GET_ORDER_DELIVERY_INFO_REQUEST: {
      return {
        ...state,
        orderDeliveryInfoLoading: true,
        orderDeliveryInfoFailed: false,
      };
    }
    case GET_ORDER_DELIVERY_INFO_SUCCESS: {
      const { payload } = action as GetDeliveryInfoForOrderByIdSuccess;
      return {
        ...state,
        orderDeliveryInfo: payload,
        orderDeliveryInfoLoading: false,
      };
    }
    case GET_ORDER_DELIVERY_INFO_FAILURE: {
      return {
        ...state,
        orderDeliveryInfoFailed: true,
        orderDeliveryInfoLoading: false,
      };
    }
    case GET_ORDER_FULFILLMENT_STATUS_REQUEST: {
      return {
        ...state,
        orderFulfillmentStatusLoading: true,
        orderFulfillmentStatusFailed: false,
      };
    }
    case GET_ORDER_FULFILLMENT_STATUS_SUCCESS: {
      const { payload } = action as GetFulfillmentStatusForOrderByIdSuccess;
      return {
        ...state,
        orderFulfillmentStatus: payload,
        orderFulfillmentStatusLoading: false,
      };
    }
    case GET_ORDER_FULFILLMENT_STATUS_FAILURE: {
      return {
        ...state,
        orderFulfillmentStatusFailed: true,
        orderFulfillmentStatusLoading: false,
      };
    }
    default:
      return state;
  }
}
