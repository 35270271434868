import React, { useCallback } from 'react';

import { AppConfigUpdateModel } from '@flipdish/api-client-typescript/private/api';
import { connect } from 'react-redux';

import { setAppConfig } from '../../WebsiteAndApp/actions';
import GeneralKioskSettingsForm, { FormValues } from './GeneralKioskSettingsForm';

type Props = MappedState & MappedDispatch;

const GeneralKioskSettings = (props: Props) => {
  const { updateConfig, currentApp } = props;
  const { KioskPrimaryColour, MainColor } = currentApp;

  const saveSettings = useCallback(
    (values: FormValues) => {
      const config: AppConfigUpdateModel = {
        KioskPrimaryColour: values.kioskButtonColour.slice(1),
      };
      updateConfig(config);
    },
    [currentApp, updateConfig]
  );

  return (
    <GeneralKioskSettingsForm
      kioskButtonColour={KioskPrimaryColour}
      webAndAppsButtonColour={MainColor}
      submit={saveSettings}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentApp: state.currentApp,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  updateConfig: (config: AppConfigUpdateModel) => dispatch(setAppConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralKioskSettings);
