import React, { useCallback, useEffect, useState } from 'react';

import { gdpr } from '../../helpers/gdpr';
import geoIpLookup from '../../helpers/geoIpLookup';
import CookieSettings from './CookieSettings';
import NoCookieSettings from './NoCookieSettings';

enum CookieComplianceState {
  GDPRDisabled,
  GDPREnabled,
  HasUserSelection,
  Undefined,
}
const getIfAnnoy = async (): Promise<CookieComplianceState> => {
  if (gdpr.hasUserSelection()) {
    return CookieComplianceState.HasUserSelection;
  }

  const isGDPREnabled = gdpr.isGDPREnabled();

  if (isGDPREnabled === undefined) {
    let GDPREnabledValue: boolean;
    try {
      GDPREnabledValue = await geoIpLookup();
    } catch {
      // default GDPR to on if lookup fails
      GDPREnabledValue = true;
    }

    gdpr.setGDPREnabled(GDPREnabledValue);

    return GDPREnabledValue
      ? CookieComplianceState.GDPREnabled
      : CookieComplianceState.GDPRDisabled;
  }

  return isGDPREnabled === 'true'
    ? CookieComplianceState.GDPREnabled
    : CookieComplianceState.GDPRDisabled;
};

const CookieSettingsInitializer: React.FC<React.PropsWithChildren<{}>> = () => {
  const [annoy, setAnnoy] = useState<CookieComplianceState>(CookieComplianceState.Undefined);

  const getIfAnnoyCallback = useCallback(async () => {
    const annoy = await getIfAnnoy();
    setAnnoy(annoy);
  }, []);

  useEffect(() => {
    getIfAnnoyCallback();
  }, [getIfAnnoyCallback]);

  switch (annoy) {
    case CookieComplianceState.GDPREnabled:
      return <CookieSettings />;
    case CookieComplianceState.GDPRDisabled:
      return <NoCookieSettings />;
    case CookieComplianceState.HasUserSelection:
    default:
      return null;
  }
};

export default CookieSettingsInitializer;
