import { useEffect } from 'react';

import { HomeAction } from '@flipdish/api-client-typescript';

import { logger } from '../../../services/loggerService';

export interface IListItemProps {
  data: HomeAction;
}

const UnsupportedConfigListItem = (props: IListItemProps) => {
  const { data } = props;

  useEffect(() => {
    logger.error(`UnsupportedHomeActionError`, {
      message: data.Action,
      stack: data,
    });
    console.error('UnsupportedHomeActionError', data);
  }, []);

  return null;
};

export default UnsupportedConfigListItem;
