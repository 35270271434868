import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PolygonIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 31 31"
      style={{
        width: '30px',
        height: '30px',
        marginRight: '12px',
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon fill="#E7E8F5" points="16 2 2.331 11.931 7.552 28 24.448 28 29.669 11.931" />
        <polygon stroke="#9CA1D0" points="16 2 2.331 11.931 7.552 28 24.448 28 29.669 11.931" />
        <g fill="#9CA1D0">
          <path d="M31.6689,11.9502 C31.6689,13.0542 30.7729,13.9502 29.6689,13.9502 C28.5649,13.9502 27.6689,13.0542 27.6689,11.9502 C27.6689,10.8462 28.5649,9.9502 29.6689,9.9502 C30.7729,9.9502 31.6689,10.8462 31.6689,11.9502" />
          <path d="M4.3311,11.9419 C4.3311,13.0459 3.4351,13.9419 2.3311,13.9419 C1.2271,13.9419 0.3311,13.0459 0.3311,11.9419 C0.3311,10.8379 1.2271,9.9419 2.3311,9.9419 C3.4351,9.9419 4.3311,10.8379 4.3311,11.9419" />
          <path d="M18,2 C18,3.104 17.104,4 16,4 C14.896,4 14,3.104 14,2 C14,0.896 14.896,0 16,0 C17.104,0 18,0.896 18,2" />
          <path d="M9.5615,28 C9.5615,29.104 8.6655,30 7.5615,30 C6.4575,30 5.5615,29.104 5.5615,28 C5.5615,26.896 6.4575,26 7.5615,26 C8.6655,26 9.5615,26.896 9.5615,28" />
          <path d="M26.4717,28 C26.4717,29.104 25.5757,30 24.4717,30 C23.3677,30 22.4717,29.104 22.4717,28 C22.4717,26.896 23.3677,26 24.4717,26 C25.5757,26 26.4717,26.896 26.4717,28" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PolygonIcon;
