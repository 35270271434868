import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { FabButtonLink } from '@fd/ui/FabButtonLink';

import Spacer from '../../../../ui/Spacer';
import { getAddNewAccountUrl } from '../banking.selectors';

type AddNewLinkButtonProps = {
  fdKey: string;
  to?: string;
  text: TranslationId;
};

export const AddNewLinkButton = ({ to, text, fdKey }: AddNewLinkButtonProps) => {
  if (!to) {
    return null;
  }

  return (
    <Fab
      variant="extended"
      color="secondary"
      to={to}
      data-fd={fdKey}
      component={FabButtonLink}
      style={{
        position: 'fixed',
        right: '24px',
        bottom: '24px',
      }}
    >
      <AddIcon />
      <Spacer size={8} variant="vertical" />
      <Translate id={text} />
    </Fab>
  );
};

export const AddAccountLink = ({ toUrl }: { toUrl?: string }) => (
  <AddNewLinkButton fdKey="Add_account" text="Add_account" to={toUrl} />
);

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const toUrl = getAddNewAccountUrl(state);
  return {
    toUrl,
  };
};

export default compose<MappedState, {}>(connect(mapStateToProps))((props) => (
  <Permissions allowed={[App.AppResourceSetEnum.CreateBankAccounts]}>
    <AddAccountLink {...props} />
  </Permissions>
));
