import React, { useEffect, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import flatten from 'lodash/flatten';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { compose } from 'recompose';

import Loading from '../../../../ui/Loading';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../../WithRouteSearchParams';
import { DeliveryZone, IDeliveryZone } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'flex',
    padding: '8px 0 8px 8px',
    '& >div:nth-child(2) span': {
      display: 'none',
    },
    minHeight: 40,
    height: '100%',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
    height: '32px',
    borderRadius: '16px',
    backgroundColor: '#e0e0e0',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  placeholder: {
    position: 'absolute' as any,
    left: theme.spacing(2),
    height: '24px',
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  menuPaper: {
    position: 'absolute' as any,
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  select: {
    zIndex: 3,
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.54)',
    },
  },
  closeIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
  dropdownIcon: {
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
  },
}));

const customStyles = {
  groupHeading: () => ({
    width: '100%',
    padding: '0px 16px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: 1.33,
    letterSpacing: '0.4px',
    color: 'red',
  }),
};

const useOptionStyles = makeStyles(() => ({
  label: {
    width: '100%',
    height: '20px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

// #region display options
const DropdownIndicator = (props) => {
  const classes = useStyles();
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon className={classes.dropdownIcon} />
      </components.DropdownIndicator>
    )
  );
};

const LoadingIndicator = () => {
  return <Loading size={20} />;
};

const NoOptionsMessage = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" className={classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = (props) => {
  const classes = useOptionStyles();
  const { value } = props.selectProps;
  const isAllAreaSelected = Array.isArray(value) ? value.find((v) => v.AllAreas) : value.allAreas;
  return (
    <MenuItem
      buttonRef={props.innerRef}
      style={{
        width: '100%',
        height: '20px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-fd={`areas-${props.data.value}`}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={props.onChange}
            checked={props.isSelected || isAllAreaSelected}
            data-fd={`areas-${props.data.value}`}
            color="secondary"
          />
        }
        classes={classes}
        label={props.data.label}
      />
    </MenuItem>
  );
};

const Placeholder = (props) => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" className={classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.valueContainer} data-fd="area-select">
      {props.children}
    </div>
  );
};

const MultiValue = (props) => {
  const classes = useStyles();
  return (
    <Chip
      tabIndex={-1}
      label={props.data.chipLabel}
      className={classes.chip}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} className={classes.closeIcon} />}
    />
  );
};

const Menu = (props) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.menuPaper} {...props.innerProps}>
      <MenuList>{props.children}</MenuList>
    </Paper>
  );
};

// #endregion

const customComponents = {
  Control,
  DropdownIndicator,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
  LoadingIndicator,
};

type AreaOption = {
  label: string | undefined;
  value?: number | undefined;
  options?: Array<{
    label: string | undefined;
    value: number | undefined;
  }>;
};

type AreaOptions = {
  areas: AreaOption[];
  hasSetInitialAreas?: boolean;
};

type AreaFilterProps = {
  storeZoneOptions?: IDeliveryZone<DeliveryZone[]>;
  onChange: (selectedZoneIds: number[]) => void;
  placeholderText?: string;
  shouldUseUrlParams?: boolean;
  currency?: CurrencyEnum;
  languageCode: string;
};

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type InnerProps = ReturnType<typeof mapStateToProps> & WithRouteSearchParamsProps<string[]>;
type OuterProps = AreaFilterProps;
type Props = InnerProps & OuterProps;

const allAreasOpt = {
  label: 'All areas',
  options: [
    {
      label: 'All areas',
      chipLabel: 'All areas',
      value: null,
      allAreas: true,
    },
  ],
  allAreas: true,
};

const DeliveryZoneFilter = (props: Props) => {
  const classes = useStyles();
  const {
    onChange,
    // shouldUseUrlParams,
    setSearch,
    storeZoneOptions,
    storeHeaders,
    currency,
    languageCode,
  } = props;
  const [selectedAreas, setSelectedAreas] = useState<AreaOptions>({
    areas: [],
    hasSetInitialAreas: false,
  });
  const [groupedOptions, setGroupedOptions] = useState<any[]>([]);

  const formatGroupLabel = (data) => {
    const store = storeHeaders?.find((header) => header.StoreId === parseInt(data.label, 10));
    return (
      <div
        style={{
          fontSize: '12px',
          color: 'rgba(0, 0, 0, 0.6)',
          lineHeight: '16px',
          letterSpacing: '0.4px',
        }}
      >
        {store ? store.Name : data.allAreas ? '' : `Store ${data.label}`}
      </div>
    );
  };

  const getLabel = (data, isChip: boolean) => {
    const store = storeHeaders?.find((header) => header.StoreId === data.storeId);
    const optionIndex = storeZoneOptions![data.storeId].findIndex(
      (option) => option.id === data.id
    );
    return isChip
      ? `${store ? store.Name : ''} ${optionIndex + 1}`
      : `${store ? store.Name : ''} ${
          optionIndex + 1
        } (min:${data.minimumDeliveryOrderAmount.toLocaleString(languageCode, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}, Fee:${data.deliveryFee.toLocaleString(languageCode, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })})`;
  };

  useEffect(() => {
    if (storeZoneOptions && currency && storeHeaders) {
      const go = Object.keys(storeZoneOptions).map((sz) => {
        return {
          label: sz,
          options: storeZoneOptions[sz].map((opt) => {
            return {
              ...opt,
              label: getLabel(opt, false),
              chipLabel: getLabel(opt, true),
              value: opt.id,
            };
          }),
        };
      });

      const opts = [allAreasOpt, ...go];
      setGroupedOptions(opts);
    }
  }, [storeZoneOptions, currency, storeHeaders]);

  const isMulti = useMemo(() => {
    if (selectedAreas.areas.length) {
      if (selectedAreas.areas[0].value === 0) {
        return false;
      }
    }
    return true;
  }, [selectedAreas.areas]);

  // @ts-ignore
  const setUrlParams = (type) => {
    setSearch(type);
  };

  const handleAreasChange = (values) => {
    let zones = values;
    if (values.find((value) => value.allAreas)) {
      if (values.length > 1 && values[0].allAreas) {
        // If allAreas is selected and we pick a specific zone
        zones = [values[1]];
      } else {
        // If have selected zones, and we pick allAreas
        zones = getAllDeliveryZones();
      }
    }

    onChange(zones.map((v) => v.id));
    setSelectedAreas({
      areas: zones,
      hasSetInitialAreas: selectedAreas.hasSetInitialAreas,
    });
    // TODO: Handle url params
    // if (shouldUseUrlParams) {
    // setUrlParams(values);
    // }
  };

  const getAllDeliveryZones = () =>
    flatten(groupedOptions.map((a) => a.options)).filter((opt) => !opt.allAreas);

  return (
    <Select
      className={classes.select}
      classes={classes}
      styles={customStyles}
      textFieldProps={{
        label: props.translate('All_areas'),
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
          className: classes.label,
        },
      }}
      components={customComponents}
      options={groupedOptions as any}
      value={
        selectedAreas.areas.length ===
        flatten(groupedOptions.map((a) => a.options)).filter((opt) => !opt.allAreas).length
          ? allAreasOpt.options[0]
          : selectedAreas.areas
      }
      isLoading={false}
      onChange={handleAreasChange}
      placeholder={props.placeholderText}
      isMulti={isMulti} // Not "All areas"
      isClearable
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      formatGroupLabel={formatGroupLabel}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  const { currentApp, locale, reports } = state;

  return {
    appId: currentApp.AppId,
    translate: getTranslate(locale),
    storeHeaders: reports.orderReports.storeHeaders,
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'geojson',
  }),
  connect(mapStateToProps)
)(DeliveryZoneFilter);

export default EnhancedComponent;
