import * as React from 'react';

import debounce from 'lodash/debounce';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { type UnpackICEWP, compose, pure, setDisplayName, withHandlers } from 'recompose';
import * as wicket from 'wicket';

import { deliveryZonesColors, disabledColor } from '../../../constants/deliveryzones.constants';
import { createDeliveryZoneByIdSelector } from '../../../selectors/storeDeliveryZone.selector';
import { DeliveryZoneView, IDeliveryZone, IDeliveryZoneMapOptions, IMapShapeProps } from '../types';
import { Circle, MultiPolygon, Polygon } from '.';

const Wkt = wicket.Wkt;

const parse = (wellKnownText: string) => {
  const wkt = new Wkt();
  wkt.read(wellKnownText);
  return wkt;
};

const createDeliveryZoneViewSelector = () =>
  memoize(
    (
      zone: IDeliveryZone,
      selected: boolean,
      styles,
      zIndex: number,
      disabled
    ): DeliveryZoneView<IDeliveryZoneMapOptions> => {
      return {
        ...zone,
        selected,
        wkt: parse(zone.WellKnownText as string),
        options: {
          ...styles,
          zIndex,
          draggable: false,
          editable: !disabled && selected && (zone.Id as number) > 0,
        },
      };
    }
  );

const onShapeClick = ({ onSelect, zone }: IInjectedProps) => {
  if (zone) {
    onSelect(zone.Id as number);
  }
};
const onShapeChange = ({ onChange, zone }: IInjectedProps, wktString: string) => {
  if (zone !== null) {
    onChange({
      Id: zone.Id,
      DeliveryFee: zone.DeliveryFee,
      MinimumDeliveryOrderAmount: zone.MinimumDeliveryOrderAmount,
      IsEnabled: zone.IsEnabled,
      WellKnownText: wktString,
      FeeChargedToStore: zone.FeeChargedToStore,
    });
  }
};

const handlers = () => {
  const onChangeDebounce = debounce(onShapeChange, 1000);
  return {
    onShapeChange: (props: IInjectedProps) => (wktText: string) => {
      try {
        if (onChangeDebounce) {
          onChangeDebounce.cancel();
        }
        onChangeDebounce(props, wktText);
      } catch (error) {
        console.error(error);
      }
    },
    onShapeClick: (props: IInjectedProps) => () => {
      onShapeClick(props);
    },
  };
};
const useHandlers = withHandlers(handlers);
type Handlers = UnpackICEWP<typeof useHandlers>;

type MapStateToProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, { storeId, deliveryZoneId }: IMapItemProps) => {
  const zoneSelector = createDeliveryZoneByIdSelector(deliveryZoneId);
  const viewSelector = createDeliveryZoneViewSelector();

  return (state: AppState, { index, disabled }: IMapItemProps) => {
    const zone = zoneSelector(state) as IDeliveryZone;
    const selected = state.deliveryZones.selectedId === zone.Id;
    const zIndex = selected ? 100 : index + 1;
    const count = deliveryZonesColors.length;
    const styles = zone.IsEnabled ? deliveryZonesColors[index % count] : disabledColor;

    const viewZone = viewSelector(zone, selected, styles, zIndex, disabled);
    return {
      zone: viewZone,
    };
  };
};

export interface IMapItemProps {
  storeId: number;
  deliveryZoneId: number;
  index: number;
  disabled?: boolean;
  onSelect(deliveryZoneId: number);
  onChange(changes: IDeliveryZone);
}
interface IInjectedProps extends IMapItemProps, MapStateToProps {}

const MapItem = compose<IInjectedProps & Handlers, IMapItemProps>(
  setDisplayName('MapItem'),
  connect(mapStateToPropsFactory),
  useHandlers,
  pure
)(({ zone, onShapeClick, onShapeChange, disabled }) => {
  if (!zone) {
    return null;
  }

  let MapItemComponent: React.ComponentClass<IMapShapeProps>;
  switch (zone.wkt.type) {
    case 'multipolygon':
      MapItemComponent = MultiPolygon as any;
      break;
    case 'circle':
      MapItemComponent = Circle as any;
      break;
    default:
      MapItemComponent = Polygon as any;
      break;
  }

  return (
    <MapItemComponent
      zone={zone}
      onSelect={onShapeClick}
      onChange={onShapeChange}
      disabled={disabled}
    />
  );
});

export default MapItem;
