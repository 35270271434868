import React from 'react';

import { Route } from 'react-router';

import WebsiteDomainSetup from '../WebsiteAndApp/components/SetupDomain';
import CookieSettings from '../WebsiteAndApp/CookieSettings/CookieSettings';
import WebsiteSettings from '../WebsiteAndApp/WebSiteSettings/WebsiteSettings';
import StoreVisibilityPage from './Website/StoreVisibilityPage';
import SalesChannels from './Website/WebsiteSettings';

type Props = {
  path: string;
};

const WebsiteRoutes: React.FC<Props> = ({ path }) => {
  return (
    <>
      <Route exact path={`${path}/website`} component={SalesChannels} />
      <Route exact path={`${path}/website/analytics-and-social`} component={WebsiteSettings} />
      <Route exact path={`${path}/website/domain-setup`} component={WebsiteDomainSetup} />
      <Route exact path={`${path}/website/cookie-management`} component={CookieSettings} />
      <Route exact path={`${path}/website/store-visibility`} component={StoreVisibilityPage} />
    </>
  );
};

export default WebsiteRoutes;
