import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();
const newCatalogImagesApis = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

// investigate why exposed TS upload function is broken
async function uploadCatalogImage(appId: string, image: File) {
  try {
    const formData = new FormData();
    formData.append('file', image);
    const result = await newCatalogImagesApis.post(`${appId}/catalog/images`, formData);
    return result.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const catalogImagesService = {
  uploadCatalogImage,
};
