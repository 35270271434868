import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import { Translate } from 'react-localize-redux';

import { oneMinute } from '../../components/common/timeUtils';
import SlideTransition from './components/SlideTransition';
import SnackBarWrapper from './components/SnackBarWrapper';
import { Heartbeat } from './services';

const OfflineDetector = ({ heartbeat = false }: { heartbeat?: boolean }) => {
  const [wasOffline, setWasOffline] = useState(false);
  const [online, setOnlineStatus] = useState(navigator.onLine);

  useEffect(() => {
    // Run only when heartbeat is requested, for example on live view
    let interval;
    if (heartbeat && process.env.NODE_ENV === 'production') {
      interval = setInterval(() => {
        Heartbeat()
          .then(() => isOnline())
          .catch(() => isOffline());
      }, oneMinute);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    // Run only when heartbeat is not requested live on main App
    if (!heartbeat) {
      window.addEventListener('online', isOnline);
      window.addEventListener('offline', isOffline);
    }
    return () => {
      window.removeEventListener('online', isOnline);
      window.removeEventListener('offline', isOffline);
    };
  }, []);

  const isOnline = () => {
    setOnlineStatus(true);
  };
  const isOffline = () => {
    setOnlineStatus(false);
    setWasOffline(true);
  };

  return (
    <>
      <Snackbar
        open={online && wasOffline}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        TransitionComponent={SlideTransition}
        onClose={() => setWasOffline(false)}
      >
        <SnackBarWrapper variant="connected" message={<Translate id="Reconnected" />} />
      </Snackbar>

      <Snackbar
        open={!online && wasOffline}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={isOffline}
      >
        <SnackBarWrapper variant="disconnected" message={<Translate id="Trying_to_reconnect" />} />
      </Snackbar>
    </>
  );
};

export default OfflineDetector;
