import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { LoadingButton } from '../../../../ui/LoadingButton';
import { SettingsType } from './PreOrderSettings';

type Props = {
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  submit: (changes) => void;
  isLoading: boolean | undefined;
  type: SettingsType;
};

export default ({ isDialogOpen, isLoading, setDialogOpen, submit, type }: Props) => {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent key="dialog-content">
        <Typography variant="h6">
          <Translate id="Disable_preorder_dialog_title" data={{ type }} />
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <Translate id="Disable_preorder_dialog_description" data={{ type }} />
        </Typography>
      </DialogContent>
      <DialogActions key="actions">
        <Button
          color="primary"
          data-fd="disable_preorder_cancel"
          onClick={() => setDialogOpen(false)}
        >
          <Translate id="Cancel" />
        </Button>
        <LoadingButton
          color="primary"
          fdKey="disable_preorder_confirm"
          onClick={submit}
          disabled={isLoading}
          loading={isLoading}
        >
          <Translate id="Disable" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
