import React, { useEffect, useState } from 'react';

import { usersService } from '../../../services/users.service';

export const SupportFeature = (props) => {
  const [isSupportUser, setIsSupportUser] = useState<boolean>(false);
  const { children } = props;

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    try {
      const roles = await usersService.getRoles();
      setIsSupportUser(roles.Data?.includes('PortalSupport'));
    } catch (err) {
      console.log('Error checking for user permission', Error(err));
    }
  };

  return isSupportUser ? <div>{children}</div> : null;
};
