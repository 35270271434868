import React, { CSSProperties } from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Tag from '../../components/common/Tag';
import { Translate } from '../../overrides/react-localize-redux';
import { HORIZONTAL_SPACE_CLASSNAME } from '../Layout';
import ListItemLink from './ListItemLink';

type StyleProps = {
  borderBottom?: string;
  iconMargin?: CSSProperties['margin'];
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    borderBottom: (props) => props.borderBottom ?? '1px solid #cecece',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '&:focus': {
      backgroundColor: '#eaf2ff',
    },
  },
  avatar: {
    minHeight: '60px',
  },
  icon: {
    margin: (props) => props.iconMargin || '16px',
    marginLeft: '0',
    padding: '0px',
    minWidth: '0',
  },
  disabledTag: {
    marginTop: theme.spacing(1),
  },
}));

type ListItemLinkButtonProps = {
  classesFromParent?: Record<string, string>;
  customTitle?: string;
  customSubtitle?: React.ReactNode;
  disabled?: boolean;
  title?: TranslationId;
  subtitle?: TranslationId;
  icon?: string;
  link?: string;
  isLast?: boolean;
  onClick?: () => void;
  tagText?: TranslationId;
  borderBottom?: string;
  customButton?: JSX.Element;
  iconComponent?: JSX.Element;
  iconMargin?: CSSProperties['margin'];
  dataFd?: string;
};

type Props = ListItemLinkButtonProps;

export const ListItemLinkButton = (props: Props) => {
  const {
    borderBottom,
    customTitle,
    customSubtitle,
    disabled = false,
    onClick,
    tagText,
    title,
    subtitle,
    icon,
    iconMargin,
    link,
    isLast,
    customButton,
    iconComponent,
    classesFromParent = {},
    dataFd = '',
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hasDivider = !isLast || isMobile;
  const classes = useStyles({ borderBottom: hasDivider ? borderBottom : 'none', iconMargin });

  let titleText: React.ReactNode;
  let subtitleText: React.ReactNode;

  if (customTitle) {
    titleText = customTitle;
  } else if (title) {
    titleText = <Translate id={title} />;
  }

  if (customSubtitle) {
    subtitleText = customSubtitle;
  } else if (subtitle) {
    subtitleText = <Translate id={subtitle} />;
  }

  return (
    <ListItemLink
      classes={classesFromParent}
      divider={hasDivider}
      button
      disableGutters
      className={clsx(classes.root, HORIZONTAL_SPACE_CLASSNAME)}
      key={title}
      fdKey={dataFd || `link-button-${title}`}
      to={link as any}
      onClick={onClick}
      alignItems="center"
      disabled={disabled}
    >
      {icon && <ListItemIcon className={classes.icon}>{<img src={icon} />}</ListItemIcon>}
      {iconComponent && (
        <ListItemIcon classes={{ root: classesFromParent?.logo }} className={classes.icon}>
          {iconComponent}
        </ListItemIcon>
      )}
      <ListItemText
        classes={classesFromParent}
        primary={
          <>
            {tagText ? (
              <>
                <Tag showBetaTag noMargin tagName={tagText} />
                <br />
              </>
            ) : null}
            {titleText}
          </>
        }
        secondary={
          <>
            {subtitleText}
            {disabled && (
              <span className={classes.disabledTag}>
                <br />
                <Tag noMargin showGreyTag tagName={'Disabled'} />
              </span>
            )}
          </>
        }
        style={{ paddingRight: '60px' }}
      />

      {customButton ? (
        customButton
      ) : (
        <IconButton aria-label={`link-button-${title}`}>
          <KeyboardArrowRight />
        </IconButton>
      )}
    </ListItemLink>
  );
};

export default ListItemLinkButton;
