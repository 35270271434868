import * as React from 'react';

import TagFace from '@mui/icons-material/TagFacesOutlined';
import Avatar from '@mui/material/Avatar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

const styles = () =>
  createStyles({
    green: {
      backgroundColor: '#86e8bb',
    },
  });

type Props = WithStyles<typeof styles>;

const Icon = compose<Props, {}>(
  withStyles(styles, {
    name: 'IconStatus',
    withTheme: true,
  })
)((props) => {
  const { classes } = props;

  return (
    <Avatar classes={{ root: classes.green }}>
      <TagFace />
    </Avatar>
  );
});

export default Icon;
