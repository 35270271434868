import React from 'react';

import Grid from '@mui/material/Grid';
import Skeleton from 'react-loading-skeleton';

import Spacer from '../../../ui/Spacer';

const DetailsLoadingMobile: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Spacer size={16}>
      <Grid container>
        <Grid item xs={9}>
          <Skeleton width={'60%'} height={22} />
        </Grid>

        <Grid item xs={3} container direction="row-reverse">
          <Skeleton circle width={40} height={40} />
        </Grid>
      </Grid>
    </Spacer>
  );
};

export default DetailsLoadingMobile;
