import DOMPurify from 'dompurify';

DOMPurify.addHook('uponSanitizeElement', (node: any, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';
    if (!src.match(/^(https?:|)\/\/www.youtube(-nocookie|).com\/embed\//)) {
      return node.parentNode.removeChild(node);
    }
  }
});

export const sanitizeHTML = (untrusted: string) => {
  return DOMPurify.sanitize(untrusted, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });
};

export const getLabel = (match: any) => {
  const pathElements = match.path.split('/');
  const label = match.url
    .split('/')
    .map((urlElement, i) => {
      if (i < 2) {
        return;
      }
      if (
        pathElements[i] &&
        pathElements[i].startsWith(':') &&
        !pathElements[i].startsWith(':type')
      ) {
        return 'id';
      }
      return urlElement;
    })
    .filter((item) => !!item)
    .join('/');

  return `portalhelp:/${label}`;
};
