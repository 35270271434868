import { CatalogChangesApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const catalogChangesApi = createApi(CatalogChangesApiFactory);

const getPendingMenuChangesQueryKey = 'getPendingMenuChanges';
async function getPendingMenuChanges(
  appId: string,
  menuId: number | undefined,
  catalogElementId: string | undefined,
  page: number,
  limit: number
) {
  try {
    const result = await catalogChangesApi.getPendingMenuChanges(
      appId,
      menuId,
      catalogElementId,
      page,
      limit
    );
    return result.Data;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}

const getPendingMenuChangesSummaryQueryKey = 'PendingMenuChangesSummary';
async function getPendingMenuChangesSummary(appId: string) {
  try {
    const result = await catalogChangesApi.getPendingMenuChangesSummaries(appId);
    return result.Data;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}

const publishPendingMenuChanges = async (appId, catalogElementId, menuIds) => {
  try {
    const pendingChanges = {
      CatalogElementId: catalogElementId,
      MenuIds: menuIds,
    };
    const result = await catalogChangesApi.publishPendingMenuChanges(appId, pendingChanges);
    return result;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const catalogChangesService = {
  getPendingMenuChanges,
  getPendingMenuChangesSummary,
  getPendingMenuChangesSummaryQueryKey,
  getPendingMenuChangesQueryKey,
  publishPendingMenuChanges,
};
