import React from 'react';

import List from '@mui/material/List';

import PaperContainer from '../../../ui/Layout/PaperContainer';
import { AppStoreAppConfigCard } from './AppStoreAppConfigCard';

export type AppStoreMultipleConfigsListProps = {
  appStoreApps: any[];
  appId: string;
};

export const AppStoreMultipleConfigsList = ({
  appStoreApps,
  appId,
}: AppStoreMultipleConfigsListProps): JSX.Element => {
  return (
    <PaperContainer fluid>
      <List>
        {appStoreApps.map((appStoreApp, index) => {
          return (
            <AppStoreAppConfigCard
              key={appStoreApp.Id}
              appStoreApp={appStoreApp}
              appId={appId}
              isLast={index === appStoreApps.length - 1}
            />
          );
        })}
      </List>
    </PaperContainer>
  );
};
