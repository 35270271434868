import React, { ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  fieldWrapper: {
    margin: '5px 0 16px',
  },
});

type Props = {
  children: ReactNode;
};

export default function FieldWrapper(props: Props) {
  const { children } = props;
  const classes = useStyles();

  return <div className={classes.fieldWrapper}>{children}</div>;
}
