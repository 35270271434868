import React from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import TextInput from '../fields/TextInput';
import { Dialog } from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: '10px',
    maxWidth: '480px',
  },
  formField: {
    minHeight: '5rem',
  },
  cancelButton: {
    marginRight: '10px',
  },
}));

export type ConfigurationGroupDialogProps = {
  onClose: () => void;
  onSave: (position: number, data: any) => void;
  open: boolean;
  translate: TranslateFunction;
  appStoreApp: any;
  position: number;
  isNew: boolean;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
const getDefaultFormState = (appStoreApp, position) => {
  if (appStoreApp.FieldGroups && appStoreApp.FieldGroups[position]) {
    return appStoreApp.FieldGroups[position];
  }
  return {
    Name: '',
    Description: '',
    Tooltip: '',
  };
};

const ConfigurationGroupDialog = (props: ConfigurationGroupDialogProps & FormikProps<any>) => {
  const { onClose, open, translate, handleSubmit, isNew } = props;
  const classes = useStyles();

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    handleSubmit();
  };
  return (
    <Form>
      <Dialog
        open={open}
        title={
          (isNew
            ? translate('Create_configuration_group')
            : translate('Update_configuration_group')) as string
        }
        actions={[
          <Button
            className={classes.cancelButton}
            key="create-config-group-cancel"
            data-fd="create-config-group-cancel"
            color="primary"
            variant="text"
            onClick={handleCancelClick}
          >
            <Translate id="Cancel" />
          </Button>,
          <Button
            key="create-config-group-confirm"
            data-fd="create-config-group-confirm"
            type="submit"
            onClick={handleSaveClick}
            color="primary"
            variant="contained"
          >
            {isNew ? translate('Create') : translate('Save')}
          </Button>,
        ]}
      >
        <Typography variant="caption">
          {translate('Enter_the_basic_information_for_the_configuration_group')}
        </Typography>
        <TextInput
          fieldName="Name"
          placeholder={translate('Name') as string}
          withoutLayout={true}
          fdKey="configuration-group-name-input"
        />
        <TextInput
          fieldName="Description"
          placeholder={translate('Description') as string}
          multiline
          withoutLayout={true}
          optional={true}
        />
        <TextInput
          fieldName="Tooltip"
          placeholder={translate('Tool_tip_text') as string}
          multiline
          withoutLayout={true}
          optional={true}
        />
      </Dialog>
    </Form>
  );
};

export default withFormik<ConfigurationGroupDialogProps, FormValues>({
  displayName: 'ConfigurationGroupDialog',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.appStoreApp, props.position);
  },
  handleSubmit: async (values, formikBag) => {
    formikBag.props.onSave(formikBag.props.position, values);
    formikBag.props.onClose();
  },
})(ConfigurationGroupDialog);
