import React, { useRef } from 'react';

import {
  MetafieldDefinition,
  MetafieldDefinitionRecommendation,
} from '@flipdish/api-client-typescript';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, Typography } from '@fd/ui/atoms';
import { FormikInputField, FormSection } from '@fd/ui/molecules';

import { metafieldKeyRegExp } from '../../../../../helpers/validation';
import PreventNavigation from '../../../../Finance/Banking/components/PreventNavigation';
import { metafieldsService } from '../../metafields.service';
import { MetafieldFormErrorValues } from '../../types';
import { NameField } from './NameField';

const useStyles = makeStyles((theme: Theme) => ({
  formContent: {
    paddingBottom: theme.spacing(3),
  },
  field: {
    minHeight: '5rem',
    marginTop: theme.spacing(1),
  },
  priceField: {
    width: '200px',
    minHeight: '5rem',
  },
  productId: {
    alignSelf: 'center',
    marginTop: theme.spacing(3),
    marginBottm: theme.spacing(2),
  },
  saveButtonContainer: {
    marginTop: theme.spacing(14),
    paddingRight: theme.spacing(3),
  },
}));

export type MetafieldFormProps = {
  appId: string;
  canEdit: boolean;
  definition?: MetafieldDefinition;
  isEditForm?: boolean;
  isLoading?: boolean;
  onSaveChanges: (newDefinition) => void;
  ownerEntity: string;
  translate: TranslateFunction;
};

export const MetafieldForm = (props: MetafieldFormProps): JSX.Element => {
  const {
    appId,
    canEdit,
    definition,
    isLoading,
    isEditForm = false,
    onSaveChanges,
    ownerEntity,
    translate,
  } = props;

  const formRef = useRef<any>(null);
  const classes = useStyles();

  const { data } = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, appId, ownerEntity],
    queryFn: () => metafieldsService.getMetafieldsByEntity(appId, ownerEntity),
  });

  const handleNameFieldChange = (metafieldName: string) => {
    if (formRef.current) {
      const { setFieldValue, values } = formRef.current;
      if (values.MetafieldDefinitionRecommendationId !== undefined) {
        setFieldValue('Description', '');
        setFieldValue('IsReadOnly', false);
        setFieldValue('Key', '');
        setFieldValue('MetafieldDefinitionRecommendationId', undefined);
      }
      setFieldValue('Name', metafieldName);
    }
  };

  const handleRecommendationSelect = (selected: MetafieldDefinitionRecommendation) => {
    const { Description, Key, MetafieldDefinitionRecommendationId, Name, ValueType } = selected;
    if (formRef.current) {
      const { setFieldValue } = formRef.current;
      setFieldValue('Description', Description);
      setFieldValue('IsReadOnly', true);
      setFieldValue('Key', Key);
      setFieldValue('MetafieldDefinitionRecommendationId', MetafieldDefinitionRecommendationId);
      setFieldValue('Name', Name);
      setFieldValue('ValueType', ValueType);
    }
  };

  return (
    <Paper>
      <Formik
        enableReinitialize={isEditForm}
        innerRef={formRef}
        initialValues={{
          Behaviors: definition?.Behaviors || [],
          Description: definition?.Description || '',
          // IsPrivate: definition?.IsReadOnly || true,
          IsReadOnly: definition?.IsReadOnly || false,
          Key: definition?.Key || '',
          MetafieldDefinitionRecommendationId:
            definition?.MetafieldDefinitionRecommendationId || undefined,
          Name: definition?.Name || '',
          OwnerEntity: props.ownerEntity,
          ValueType: definition?.ValueType || MetafieldDefinition.ValueTypeEnum.SingleLineString,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSaveChanges(values);
            if (isEditForm) {
              setSubmitting(false);
            }
          } catch (error) {
            setSubmitting(false);
          }
        }}
        validate={(values) => {
          const errors: Partial<MetafieldFormErrorValues> = {};
          const { Key } = values;

          if (!Key) {
            errors.Key = translate('Required') as string;
          }
          if (!metafieldKeyRegExp.test(Key)) {
            errors.Key = translate('Metafields_CreateForm_KeyFormatErrorMessage') as string;
          }
          if (!isEditForm) {
            const existingKeyIndex = data?.Data.findIndex(
              (metafieldDefinition) => metafieldDefinition.Key === Key
            );
            if (existingKeyIndex !== -1) {
              errors.Key = translate('Metafields_existing_key_error') as string;
            }
          }
          return errors;
        }}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <Form className={classes.formContent} data-fd="metafield-form">
            <PreventNavigation when={dirty && !isSubmitting} />
            <FormSection sectionTitle={translate('Basic') as string}>
              {isEditForm ? (
                <FormikInputField
                  className={classes.field}
                  disabled={values.MetafieldDefinitionRecommendationId !== undefined}
                  fdKey="metafield-form-name"
                  isLoading={isLoading}
                  name="Name"
                  label={translate('Name') as string}
                  InputProps={{
                    readOnly: !canEdit,
                  }}
                />
              ) : (
                <NameField
                  appId={appId}
                  onInputTextChange={handleNameFieldChange}
                  onRecommendationSelected={handleRecommendationSelect}
                  ownerEntity={ownerEntity}
                  translate={translate}
                />
              )}
              <FormikInputField
                className={classes.field}
                disabled={
                  definition?.Key !== undefined ||
                  values.MetafieldDefinitionRecommendationId !== undefined
                }
                fdKey="metafield-form-key"
                isLoading={isLoading}
                name="Key"
                label={translate('Namespace_and_key') as string}
                InputProps={{
                  readOnly: !canEdit,
                }}
              />
              <FormikInputField
                className={classes.field}
                disabled={values.MetafieldDefinitionRecommendationId !== undefined}
                fdKey="metafield-form-description"
                isLoading={isLoading}
                name="Description"
                label={translate('Description') as string}
                InputProps={{
                  readOnly: !canEdit,
                }}
              />
              <FormControl fullWidth>
                <InputLabel htmlFor="content_type">
                  <Typography variant="body1">
                    <Translate id="Type" />
                  </Typography>
                </InputLabel>
                <Select
                  disabled={
                    !canEdit ||
                    definition?.Key !== undefined ||
                    values.MetafieldDefinitionRecommendationId !== undefined
                  }
                  data-fd="type-select"
                  onChange={(e) => setFieldValue('ValueType', e.target.value)}
                  name="ValueType"
                  value={values.ValueType}
                  variant="standard"
                  inputProps={{
                    id: 'content-type-select',
                    'data-fd': translate('Type') as string,
                  }}
                >
                  <MenuItem value="SingleLineString">
                    <Translate id="SingleLineString" />
                  </MenuItem>
                  <MenuItem value="MultiLineString">
                    <Translate id="MultiLineString" />
                  </MenuItem>
                  <MenuItem value="Boolean">
                    <Translate id="Boolean" />
                  </MenuItem>
                </Select>
              </FormControl>
            </FormSection>
            {/* <AccessLevelFormSection
              isPrivate={values.IsPrivate}
              onAccessLevelUpdate={(newAccessLevel: boolean) =>
                setFieldValue('IsPrivate', newAccessLevel)
              }
              translate={translate}
            /> */}
            <Grid
              container
              className={classes.saveButtonContainer}
              item
              xs={12}
              justifyContent="flex-end"
            >
              {canEdit && (
                <Button fdKey="metafield_save_button" disabled={!dirty || !canEdit} type="submit">
                  {translate('Save')}
                </Button>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
