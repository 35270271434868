import React, { useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { loadStoreHourOverrides, removeStoreHourOverride } from '../actions';
import { getStoreOverridesByIdFactory } from '../selectors';
import RemoveOverrideButton from './RemoveOverrideButton';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state, props: IOpeningHourOverrides) => {
  const getStoreOverridesById = getStoreOverridesByIdFactory(props.storeId);
  return (state: AppState) => {
    const overrides = getStoreOverridesById(state);
    const isEmpty = !(overrides && overrides.length);

    return {
      overrides,
      isEmpty,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch: ThunkDispatch, props: IOpeningHourOverrides) => {
  // debounce it so we don't fire all request at once
  const loadOverridesDeferred = debounce(() => {
    dispatch(loadStoreHourOverrides(props.storeId));
  }, 1000);

  return (dispatch: ThunkDispatch) => ({
    loadOverrides: loadOverridesDeferred,
    removeOverride: (props: { bhoId: number; storeId: number }) => {
      return dispatch(removeStoreHourOverride(props.storeId, props.bhoId, true));
    },
  });
};

export interface IOpeningHourOverrides {
  storeId: number;
  inView?: boolean;
}
type Props = IOpeningHourOverrides;
const OpeningHourOverrides = (props: Props & MappedState & MappedDispatch) => {
  const { storeId, loadOverrides, isEmpty, removeOverride, overrides, inView } = props;

  useEffect(() => {
    return () => {
      loadOverrides.cancel();
    };
  }, []);
  useEffect(() => {
    if (storeId && inView) {
      loadOverrides();
    }
  }, [storeId, inView]);

  if (!storeId || !inView) {
    return null;
  }

  return (
    <Collapse in={!isEmpty}>
      {overrides.map((o) => {
        return (
          <AppBar
            enableColorOnDark
            key={o.bhoId}
            position="static"
            component="div"
            color="secondary"
            style={!o.isClosed ? { backgroundColor: '#36d88e' } : undefined}
            elevation={0}
            square
          >
            <Toolbar>
              <Typography variant="body2" color="inherit">
                <Translate
                  id={
                    o.isClosed
                      ? o.isDelivery
                        ? 'Closed_for_delivery'
                        : 'Closed_for_collection'
                      : o.isDelivery
                        ? 'Open_for_delivery'
                        : 'Open_for_collection'
                  }
                />{' '}
                <Translate id="until_0_due_to_store_hour_override" data={{ until: o.untilTime }} />
              </Typography>

              <div style={{ flexGrow: 1 }} />

              <RemoveOverrideButton
                bhoId={o.bhoId}
                storeName={o.storeName}
                isDelivery={o.isDelivery}
                storeId={o.storeId}
                onClick={removeOverride}
              />
            </Toolbar>
          </AppBar>
        );
      })}
    </Collapse>
  );
};

export default compose<Props, IOpeningHourOverrides>(
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
)(OpeningHourOverrides);
