import React, { useEffect, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import Fullscreen from 'react-full-screen';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import Button from '@fd/ui/Button/Button';
import EmptyComponent from '@fd/ui/EmptyComponent';
import StoreFilterInfiniteScroll from '@fd/ui/Filter/StoreFilterInfiniteScroll';
import PageLayout, { PageTitle } from '@fd/ui/Layout';
import GridContainer from '@fd/ui/Layout/GridContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import WindowInfiniteScroll from '@fd/ui/WindowInfiniteScroll';

import { getOrdersSummary } from '../../../actions/order.action';
import NoOrderSvg from '../../../assets/images/illust_liveview_coffee_list.svg';
import { type IAppState } from '../../../reducers/root.reducer';
import { useTracking } from '../../../services/amplitude/useTracking';
import DateFilter from '../../DateFilter';
import {
  OrderStatesType,
  setChannelFilter,
  setOrdersPage,
  setStateFilter,
  setStoreFilter,
} from '../actions';
import OrdersTableLoading from '../components/OrdersTableLoading';
import OrdersTableLoadingMobile from '../components/OrdersTableLoadingMobile';
import LiveView from '../LiveView/LiveView';
import ChannelFilter from './ChannelFilter';
import OrderListMobile from './ListMobile';
import OrderFilter from './OrderFilter';
import OrderListTable from './Table/Table';

const useStyles = makeStyles((theme: Theme) => ({
  liveViewButton: {
    marginLeft: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
  container: {
    paddingBottom: 0,
    width: '100%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

export const customRange = [
  { label: 'Today', value: 1, url: 'today' },
  { label: 'Last_x_days', period: '7', value: 3, url: 'last7Days' },
  { label: 'Last_x_days', period: '30', value: 4, url: 'last30Days' },
  { label: 'Last_x_days', period: '90', value: 5, url: 'last90Days' },
  { label: 'Last_x_days', period: '180', value: 16, url: 'last180Days' },
];

type Props = MappedDispatch & MappedState & RouteComponentProps;

const loadMore = (setOrdersPage: (page: number) => void, page: number) => {
  setOrdersPage(page + 1);
  return Promise.resolve();
};

const OrderList: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    getOrdersSummary,
    appId,
    ordersLoading,
    orders,
    ordersCount,
    page,
    setOrdersPage,
    location,
    setStoreFilter,
    setStateFilter,
    setChannelFilter,
    translate,
  } = props;

  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const isFirstRender = useRef(true);
  const [fromDate, setFromDate] = useState<Date>();
  const [allFiltersInitalised, setAllFiltersInitalised] = useState<boolean>(false);

  type storeValue = {
    store?: string | string[];
    status?: string | string[];
    channel?: string | string[];
  };

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_orders', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      const values: storeValue = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (!isEmpty(values.store)) {
        let storesFilter: number[] = [];
        if (isArray(values.store)) {
          storesFilter = values.store.map((v) => parseInt(v, 10));
        } else if (values.store) {
          storesFilter.push(parseInt(values.store, 10));
        }
        setStoreFilter(storesFilter);
      }

      if (!isEmpty(values.status)) {
        let statesFilter: string[] = [];
        if (Array.isArray(values.status)) {
          statesFilter = values.status;
        } else if (values.status) {
          statesFilter.push(values.status);
        }

        statesFilter = statesFilter.map((status) => {
          return status === 'Pending'
            ? 'ReadyToProcess'
            : status === 'Accepted'
              ? 'AcceptedByRestaurant'
              : status;
        });

        setStateFilter(statesFilter as OrderStatesType);
      }

      if (!isEmpty(values.channel)) {
        let channelsFilter: string[] = [];
        if (isArray(values.channel)) {
          channelsFilter = values.channel;
        } else if (values.channel) {
          channelsFilter.push(values.channel);
        }

        setChannelFilter(channelsFilter);
      }
    }
  }, []);

  useEffect(() => {
    // if it's not the first rendering
    // it should refetch orders when app changes
    if (!isFirstRender.current) {
      setOrdersPage(1);
      if (page === 1) {
        // if it's on the first page, need to trigger the fetch
        // otherwise, page change should trigger fetching
        getOrdersSummary(appId, page, 20, fromDate);
      }
    }
  }, [appId]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (page === 0) {
        setOrdersPage(1);
      }
    } else {
      // date filter calls getOrdersSummary once date range has been initalised
      if (allFiltersInitalised) {
        getOrdersSummary(appId, page, 20, fromDate);
      }
    }
  }, [page]);

  const onFilterChange = () => {
    if (page !== 1) {
      setOrdersPage(1);
    } else {
      getOrdersSummary(appId, page, 20, fromDate);
    }
  };

  const onStoreFilterChange = (stores) => {
    const { setStoreFilter } = props;
    setStoreFilter(stores);
    if (allFiltersInitalised) {
      onFilterChange();
    }
  };

  const onChannelFilterChange = (channels) => {
    const { setChannelFilter } = props;
    setChannelFilter(channels);
    if (allFiltersInitalised) {
      onFilterChange();
    }
  };

  useEffect(() => {
    !allFiltersInitalised && fromDate && setAllFiltersInitalised(true);
    fromDate && onFilterChange();
  }, [fromDate]);

  const renderOrderList = () => {
    return (
      <>
        <Hidden smDown>
          <PaperContainer fluid>
            <OrderListTable orders={orders} />
          </PaperContainer>
        </Hidden>
        <Hidden smUp>
          <OrderListMobile />
        </Hidden>
      </>
    );
  };
  const renderOrderListLoading = () => {
    return (
      <div className={classes.container}>
        <Hidden smDown>
          <OrdersTableLoading showTHead />
        </Hidden>
        <Hidden smUp>
          <OrdersTableLoadingMobile />
        </Hidden>
      </div>
    );
  };
  const TitleComponent = () => {
    return (
      <div className={classes.title}>
        <PageTitle title={<Translate id="Orders" />} />
        <Hidden mdDown>
          <Button
            style={{ color: '#05149e' }}
            className={classes.liveViewButton}
            variant="outlined"
            fdKey="live-view"
            onClick={() => setIsFullScreen(true)}
          >
            <Translate id="Live_view" />
          </Button>
        </Hidden>
      </div>
    );
  };
  const mdSize = 6;
  const header = (
    <GridContainer>
      <Grid item xs={12} md={mdSize} className={classes.gridItem}>
        <OrderFilter onChange={onFilterChange} />
      </Grid>
      <Grid item xs={12} md={mdSize} className={classes.gridItem}>
        <StoreFilterInfiniteScroll
          zIndex={99}
          onSelectStore={onStoreFilterChange}
          isMulti
          hasAllStoresOption
          passEmptyForAllStoreIds
        />
      </Grid>
      <Grid item xs={12} md={mdSize} className={classes.gridItem}>
        <ChannelFilter onSelectChannel={onChannelFilterChange} />
      </Grid>
      <Grid item xs={12} md={mdSize} className={classes.gridItem}>
        <DateFilter
          includeTodayInDatePeriod
          onChange={(momentFromDate) => {
            momentFromDate && setFromDate(momentFromDate.format('YYYY-MM-DD') as unknown as Date);
          }}
          selectLabel={translate('Date_period') as string}
          datePeriodDefault={'last7Days'} // presetRanges urls from src\components\Reports\helpers.tsx
          isCompare={false}
          customRange={customRange}
        />
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'order.*' }}
      header={header}
      documentTitle="Orders"
      title=""
      titleComponent={TitleComponent}
    >
      {!orders.length && ordersLoading ? (
        renderOrderListLoading()
      ) : orders.length ? (
        <WindowInfiniteScroll
          pageStart={1}
          loadMore={() => loadMore(setOrdersPage, page)}
          hasMore={orders.length < ordersCount}
          initialLoad={false}
          lock={ordersLoading}
          threshold={750}
          className={classes.container}
        >
          {renderOrderList()}
          {ordersLoading && page !== 1 && <OrdersTableLoading showTHead={false} />}
        </WindowInfiniteScroll>
      ) : (
        <div className={classes.container}>
          <EmptyComponent title="No_orders" icon={NoOrderSvg} noLink />
        </div>
      )}

      <Fullscreen
        enabled={isFullScreen}
        onChange={(isFull: boolean) => {
          if (!isFull) {
            setIsFullScreen(isFull);
          }
        }}
      >
        {isFullScreen && <LiveView exit={() => setIsFullScreen(false)} />}
      </Fullscreen>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOrdersSummary: (appId: string, page: number, limit: number, from?: Date) =>
    dispatch(
      getOrdersSummary({
        concat: page !== 1,
        appId,
        page,
        limit,
        from,
      })
    ),
  setOrdersPage: (page: number) => dispatch(setOrdersPage(page)),
  setStoreFilter: (stores: number[]) => dispatch(setStoreFilter({ stores })),
  setStateFilter: (states: OrderStatesType) => dispatch(setStateFilter(states)),
  setChannelFilter: (channels: string[]) => dispatch(setChannelFilter({ channels })),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: IAppState) => {
  return {
    orders: state.orders.orders,
    translate: getTranslate(state.locale),
    ordersCount: state.orders.ordersCount,
    appId: state.currentApp.AppId ? state.currentApp.AppId : '',
    ordersLoading: state.orders.ordersLoading,
    page: state.OrdersFilter.page,
  };
};

const EnhancedComponent = compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderList);

export default EnhancedComponent;
