import React, { ChangeEvent, useState } from 'react';

import { AppStoreAppConfiguration } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { UseMutateFunction } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Dialog } from '@fd/ui/molecules/Dialog';

import LoadingButton from '../../../../ui/LoadingButton';
import TextField from '../../../../ui/TextField/TextField';

type Props = {
  appStoreApp: AppStoreAppConfiguration;
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  deleteAppConfiguration: UseMutateFunction;
} & ReturnType<typeof mapStateToProps>;

const AppStoreDeleteAppDialog = ({
  isDialogOpen,
  setDialogOpen,
  deleteAppConfiguration,
  appStoreApp,
  translate,
}: Props) => {
  const [value, setValue] = useState('');
  const isDisabled = value !== appStoreApp.Name && value.toLowerCase() !== 'Delete'.toLowerCase();

  const handleDelete = () => {
    setDialogOpen(false);
    deleteAppConfiguration();
  };

  const Actions = () => {
    return (
      <>
        <Button color="primary" data-fd="Delete_cancel" onClick={() => setDialogOpen(false)}>
          {translate('Cancel')}
        </Button>
        <LoadingButton
          color="primary"
          fdKey="Delete_confirm"
          onClick={handleDelete}
          disabled={isDisabled}
        >
          {translate('Remove')}
        </LoadingButton>
      </>
    );
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      subtitle={
        translate('Delete_app_configuration_description', { appName: appStoreApp.Name }) as string
      }
      title={translate('Delete_app_configuration') as string}
      actions={<Actions />}
    >
      <FormControl fullWidth>
        <TextField
          fdKey="deleting_app_name"
          value={value}
          placeholder={
            translate('Delete_app_configuration_input_placeholder', {
              appName: appStoreApp.Name,
            }) as string
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
        />
      </FormControl>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ translate: getTranslate(state.locale) });

export default connect(mapStateToProps)(AppStoreDeleteAppDialog);
