import React, { ChangeEvent } from 'react';

import Switch from '@mui/material/Switch';
import { Translate } from 'react-localize-redux';

import FormItem from '../FormItem';

type Props = {
  canEdit: boolean;
  style?: string;
  isCollection: boolean;
};

const RequireExplicitSelectAlways = ({ canEdit, isCollection, style }: Props) => {
  return (
    <FormItem
      divider
      oneLine
      primary={
        <Translate
          id={
            isCollection
              ? 'Force_customer_to_select_collection_time'
              : 'Force_customer_to_select_delivery_time'
          }
        />
      }
      secondary={
        <Translate
          id={
            isCollection
              ? 'Force_customer_to_select_collection_time_helper_text'
              : 'Force_customer_to_select_delivery_time_helper_text'
          }
        />
      }
      name="RequireExplicitSelectAlways"
    >
      {({ field, form }) => (
        <Switch
          className={style}
          value={field.value}
          color="default"
          inputProps={
            {
              'data-fd': 'pre_order_settings_require_explicit_select_always',
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
          checked={!!field.value}
          disabled={!canEdit}
          onChange={(e: ChangeEvent<HTMLInputElement>, value: boolean) => {
            form.setFieldValue(field.name, value);
          }}
        />
      )}
    </FormItem>
  );
};

export default RequireExplicitSelectAlways;
