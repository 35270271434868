import React, { useEffect, useState } from 'react';

import {
  FulfillmentStatusConfigurationItem,
  RestApiErrorResult,
  UpdateFulfillmentStatesConfiguration,
} from '@flipdish/api-client-typescript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { flagService } from '../../../services';
import { SkeletonLoader } from '../../../ui/atoms';
import PageLayout from '../../../ui/Layout';
import { orderFulfillmentStatusService } from '../services/orderFulfillmentStatus.service';
import EditFulfillmentConfigurationStateForm from './EditFulfillmentConfigurationStateForm';

type Props = RouteComponentProps<{ fulfillmentConfigId: string }>;

const EditFulfillmentConfigurationState = ({
  appId,
  closeNotifyFormSaving,
  fulfillmentConfigId,
  isFulfillmentFlagOn,
  notifyFormError,
  notifyFormSaved,
  notifyFormSaving,
  statusId,
  translate,
}: MappedDispatch & MappedState) => {
  const [status, setStatus] = useState<FulfillmentStatusConfigurationItem>({});
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: fulfillmentConfig,
    isPending,
    isFetching,
    isSuccess,
  } = useQuery({
    queryKey: ['fulfillmentConfig', fulfillmentConfigId],
    queryFn: () =>
      orderFulfillmentStatusService.getFulfillmentConfiguration(appId, fulfillmentConfigId),
    enabled: isFulfillmentFlagOn,
  });

  useEffect(() => {
    if (isSuccess) {
      const statusToEdit = fulfillmentConfig?.States?.find((state) => state.StatusId === statusId);
      if (statusToEdit) {
        setStatus(statusToEdit);
      }
    }
  }, [isSuccess, fulfillmentConfig, statusId]);

  const { mutateAsync } = useMutation({
    mutationFn: async (updatedConfiguration: UpdateFulfillmentStatesConfiguration) => {
      notifyFormSaving();

      const result = await orderFulfillmentStatusService.updateFulfillmentConfiguration(
        appId,
        fulfillmentConfigId,
        updatedConfiguration
      );
      // @ts-expect-error: PRJSP-445
      const id = result.Data;
      queryClient.invalidateQueries({
        queryKey: [id],
      });
      return id;
    },

    onSuccess: () => {
      closeNotifyFormSaving();
      notifyFormSaved();
    },

    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        notifyFormError({ message: apiErrorMessage.Message });
      }
    },
  });

  const onSubmit = async (values: FulfillmentStatusConfigurationItem) => {
    const newStates = fulfillmentConfig?.States?.filter(
      (state) => state.StatusId !== values.StatusId
    );
    const states = newStates?.concat(values);
    const updatedConfig = {
      ...fulfillmentConfig,
      States: states,
    };

    const id = await mutateAsync(updatedConfig);
    id ? history.push(`/${appId}/orderFulfillmentStatus/${id}`) : history.goBack();
  };

  const handleTitleChange = (newTitle) => {
    setStatus({ ...status, StatusName: newTitle });
  };

  let Content;
  if (isPending || isFetching || fulfillmentConfig === undefined) {
    Content = (
      <SkeletonLoader
        fdKey={'edit-fulfillment-config-state-skeleton-loader'}
        rows={[
          { height: '126px', width: '100%' },
          { height: '126px', width: '100%' },
          { height: '126px', width: '50%' },
        ]}
      />
    );
  } else {
    Content = (
      <EditFulfillmentConfigurationStateForm
        states={fulfillmentConfig?.States}
        updateTitle={handleTitleChange}
        state={status}
        submit={onSubmit}
        translate={translate}
      />
    );
  }

  if (!isFulfillmentFlagOn) {
    return null;
  }

  return (
    <PageLayout
      toParent={`/${appId}/orderFulfillmentStatus`}
      documentTitle="Edit_Order_Status_Configuration_State"
      title={status.StatusName}
    >
      {Content}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { locale } = state;
  //@ts-ignore
  const { appId, configId, statusId } = props.match.params;
  return {
    appId,
    fulfillmentConfigId: configId,
    isFulfillmentFlagOn: flagService.isFlagOn(state, 'orderFulfillmentStatusConfigurations'),
    translate: getTranslate(locale),
    statusId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyFormSaving: () => dispatch(closeNotifySaving()),
  notifyFormError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyFormSaving: () => dispatch(notifySaving()),
  notifyFormSaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFulfillmentConfigurationState);
