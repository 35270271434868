import React from 'react';

import { BankAccountSummary } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    backgroundColor: '#ffbc2c',
    marginBottom: '16px',
  },
}));

type OuterProps = {
  account: {
    priority?: number;
    alertType?: string;
    bodyText?: {
      id: TranslationId;
      data: {};
    };
  } & BankAccountSummary;
};

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const AlertStripeVerification = (props: Props) => {
  const { account } = props;

  const classes = useStyles();

  const getAlertBodyText = () => {
    if (account && account.bodyText) {
      return <Translate id={account.bodyText.id} data={account.bodyText.data} />;
    } else {
      return <Translate id="You_must_verify_some_of_your_banking_details_to_receive_payouts" />;
    }
  };

  return (
    <Grid container alignItems="center" className={classes.paperWrapper}>
      <Toolbar>
        <Typography variant="body2" color="textPrimary">
          {getAlertBodyText()}
        </Typography>
      </Toolbar>
    </Grid>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(AlertStripeVerification);
