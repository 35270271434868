import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { ReactComponent as TableSettingsIcon } from '../../../../assets/images/tableSettings.svg';
import { Typography } from '../../../../ui/atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: '#EAF2FF',
    height: '100%',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
  },
  cardText: {
    alignSelf: 'center',
  },
  iconContainer: {
    backgroundColor: '#CDD0EC',
    height: theme.spacing(10),
    width: theme.spacing(10),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    width: 50,
    height: 50,
  },
  buttonIcon: {
    marginRight: theme.spacing(1.5),
  },
}));

type Props = {
  onClick: () => void;
  canEdit: boolean;
};

const AddSectionCard = (props: Props) => {
  const { onClick, canEdit } = props;
  const styles = useStyles();

  return (
    <Card className={styles.card}>
      <CardContent>
        <div className={styles.content}>
          <Avatar data-fd="add-section-icon" className={styles.iconContainer}>
            {<TableSettingsIcon className={styles.icon} />}
          </Avatar>
          <Typography variant="button" color="primary" className={styles.cardText}>
            <Translate id="Create_a_new_section_of_tables" />
          </Typography>
        </div>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          id="add-section-component-button"
          onClick={onClick}
          disabled={!canEdit}
        >
          <AddIcon className={styles.buttonIcon} />
          <Translate id="New_section" />
        </Button>
      </CardContent>
    </Card>
  );
};

export default AddSectionCard;
