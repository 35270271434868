import React from 'react';

import { EmvTerminalWithAssignments } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

type OuterProps = {
  deviceId: string;
  EMVTerminalsAssignedToApp: EmvTerminalWithAssignments[];
  EMVTerminalsAssignedToKiosk: EmvTerminalWithAssignments[];
};

type InnerProps = {};
type Props = InnerProps & OuterProps;

const KioskPaymentMethodField: React.FC<React.PropsWithChildren<Props>> = ({
  deviceId,
  EMVTerminalsAssignedToApp,
  EMVTerminalsAssignedToKiosk,
}: Props) => {
  const emv = EMVTerminalsAssignedToKiosk[0];
  const assigned = EMVTerminalsAssignedToKiosk.length === 1 && deviceId === emv.HydraDeviceId;
  return (
    <Field name={'EMV'}>
      {({ field, form }: FieldProps) => {
        const { errors, touched } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);
        return (
          <Grid component="label" container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <Translate id="Card_reader" />
              </Typography>
              <Typography variant="caption">
                <Translate id="Accept_chip_&_pin_as_well_as_contactless_payments" />
                <br />
                <Translate id="Apple_and_Google_pay_also_supported" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {EMVTerminalsAssignedToApp.length === 0 ? (
                <Translate id={'No_card_reader'} />
              ) : (
                <>
                  <Select {...field} fullWidth displayEmpty variant="standard">
                    <MenuItem key={'placeholder'} value="" disabled>
                      {assigned ? (
                        <>
                          <Translate id={'Card_reader_id:'} /> {emv.TerminalId}
                        </>
                      ) : (
                        <Translate id={'Select_card_reader'} />
                      )}
                    </MenuItem>
                    {assigned ? (
                      <MenuItem key={'unassign'} value={'unassign'}>
                        <Translate id={'Unassign_card_reader'} />
                      </MenuItem>
                    ) : (
                      EMVTerminalsAssignedToKiosk.map((e) => (
                        <MenuItem key={e.TerminalId} value={e.EmvTerminalId}>
                          <Translate id={'Card_reader_id:'} /> {e.TerminalId}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {showError && fieldError}
                </>
              )}
            </Grid>
            {assigned && (
              <Grid item xs={12}>
                <Typography variant="caption">
                  <Translate id="To_disable_card_payments_disconnect_card_reader_from_kiosk" />
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }}
    </Field>
  );
};

export default KioskPaymentMethodField;
