import * as amplitude from '@amplitude/analytics-browser';

import { AccountState } from '../../reducers/account.reducer';

const AMPLITUDE_API_KEY = process.env.VITE_AMPLITUDE_API_KEY || '';

export const initializeAmplitude = (disable = false): void => {
  amplitude?.init(AMPLITUDE_API_KEY, {
    flushMaxRetries: 1,
    serverZone: 'EU',
    defaultTracking: true,
  });

  if (disable) {
    optOutAmplitude();
  } else {
    optInAmplitude();
  }

  window.fdAmplitude = { useTracking };
};

export const optInAmplitude = (): void => {
  amplitude?.setOptOut(false);
};

export const optOutAmplitude = (): void => {
  amplitude?.setOptOut(true);
};

export const useTracking = (
  account?: AccountState
): {
  trackEvent: (eventName: string, options?: Record<string, string>) => void;
  identifyUser: () => void;
  trackNavigationEvent: (eventName: string, options?: Record<string, string>) => void;
  trackEditEvent: (eventName: string, options?: Record<string, string>) => void;
  trackPreviewEvent: (eventName: string, options?: Record<string, string>) => void;
} => {
  const setUserProperties = (account?: AccountState): void => {
    if (account) {
      const userId = account.Email || '<NO_EMAIL>';
      amplitude.setUserId(userId);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('id', account.AccountId?.toString() || '<NO_ACCOUNT_ID>');
      identifyEvent.set('name', account.Name!);
      identifyEvent.set('email', account.Email!);
      identifyEvent.set('is_flipdish', account.Email?.includes('@flipdish') ?? false);

      amplitude.identify(identifyEvent);
    }
  };

  const trackEvent = (eventName: string, options?: Record<string, string>): void => {
    setUserProperties(account);
    amplitude?.track(eventName, {
      ...(options || {}),
      id: account?.AccountId?.toString() ?? '<NO_ACCOUNT_ID>',
      name: account?.Name,
      email: account?.Email,
      is_flipdish: account?.Email?.includes('@flipdish') ?? false,
    });
  };

  const trackNavigationEvent = (eventName: string, options: Record<string, string> = {}): void => {
    trackEvent(eventName, { ...options, category: 'Navigation' });
  };

  const trackEditEvent = (eventName: string, options: Record<string, string> = {}): void => {
    trackEvent(eventName, { ...options, category: 'Edit' });
  };

  const trackPreviewEvent = (eventName: string, options: Record<string, string> = {}): void => {
    trackEvent(eventName, { ...options, category: 'Preview' });
  };

  const identifyUser = (): void => {
    setUserProperties(account);
  };

  return {
    identifyUser,
    trackEvent,
    trackEditEvent,
    trackNavigationEvent,
    trackPreviewEvent,
  };
};

export const Amplitude = {
  initialize: initializeAmplitude,
};
