import React, { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StopIcon from '@mui/icons-material/Stop';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { compose } from 'recompose';

import { getMaxCount, mapDurationToGranularity, renderDateTick } from '../../helpers';
import { GranularityProps } from '../../types';

type ChartProps = {
  data?: any;
  title: TranslationId;
  formatCurrency: (value) => string;
};
type DisplayType = 'desktop' | 'tablet' | 'mobile';
type Props = ChartProps;

const getChartHeight = (displayType: DisplayType) =>
  displayType === 'desktop' ? 300 : displayType === 'tablet' ? 250 : 200;

const CustomizedAxisTick = (props) => {
  const { x, y, payload, renderTick } = props;
  return <g transform={`translate(${x},${y})`}>{renderTick(payload.value)}</g>;
};

const useTooltipStyles = makeStyles({
  tooltipSingle: {
    minHeight: '60px',
    padding: '6px',
    minWidth: '144px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    minHeight: '120px',
    padding: '6px',
    minWidth: '144px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    letterSpacing: '0.33px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  info: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  trendIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    height: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#36d88e',
  },
  green: { color: '#36d88e' },
  red: { color: '#ff395f' },
  blue: { color: '#4f94f7' },
});

const CustomTooltip = (props) => {
  const classes = useTooltipStyles();
  const { active, formatCurrency, granularity, payload, type, section } = props;

  const getLabel = (label) => {
    if (granularity === 'hourly') {
      return `${moment(label).format('DD MMMM - h:mm A')}`;
    }
    if (granularity === 'daily') {
      return `${moment(label).format('DD MMMM YYYY')}`;
    }
    if (granularity === 'weekly') {
      const weekStart = moment(label).startOf('week');
      const weekEnd = moment(label).endOf('week');
      return `Week ${moment(label).format('W')}\n(${weekStart.format('DD MMM')} - ${weekEnd.format(
        'DD MMM'
      )})`;
    }
    if (granularity === 'monthly') {
      return `${moment(label).format('M YYYY')}`;
    }
    return `${moment(label).format('YYYY')}`;
  };

  const getTrendInfo = (data) => {
    const { y, y2, z } = data;
    if (z === null) {
      return { trend: null, difference: null };
    }
    if (y === null) {
      // if y is null, we need to use y2 (incomplete data)
      if (z === 0) {
        return { trend: 0, difference: y2 - z };
      }
      return { trend: (y2 - z) / z, difference: y2 - z };
    } else {
      if (z === 0) {
        return { trend: 0, difference: y - z };
      }
      return { trend: (y - z) / z, difference: y - z };
    }
  };

  const progressionIcon = (type, isPositive?) => {
    switch (type) {
      case 'positive': {
        const positiveTrend = clsx({
          [classes.trendIcon]: true,
          [classes.green]: isPositive,
          [classes.red]: !isPositive,
        });
        return (
          <span className={positiveTrend}>
            <ArrowDropUpIcon color="inherit" />
          </span>
        );
      }
      case 'negative': {
        const negativeTrend = clsx({
          [classes.trendIcon]: true,
          [classes.green]: isPositive,
          [classes.red]: !isPositive,
        });
        return (
          <span className={negativeTrend}>
            <ArrowDropDownIcon color="inherit" />
          </span>
        );
      }
      case 'neutral': {
        const neutralTrend = clsx({
          [classes.trendIcon]: true,
          [classes.blue]: true,
        });
        return (
          <span className={neutralTrend}>
            <StopIcon fontSize="small" color="inherit" />
          </span>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderTrendIcon = (value: number) => {
    const negativeTrends = ['rejectedOrders', 'rejectedOrderAmount', 'refundedOrders'];
    if (value < 0) {
      const isPositive = negativeTrends.indexOf(section) !== -1 ? true : false;
      return progressionIcon('negative', isPositive);
    }
    if (value > 0) {
      const isPositive = negativeTrends.indexOf(section) !== -1 ? false : true;
      return progressionIcon('positive', isPositive);
    }
    return progressionIcon('neutral');
  };

  if (active) {
    const progression = getTrendInfo(payload[0].payload);
    return (
      <div className={progression.difference !== null ? classes.tooltip : classes.tooltipSingle}>
        <div style={{ margin: '12px 8px 0' }}>
          <div className={classes.subtitle}>{getLabel(payload[0].payload.x)}</div>
          <div className={classes.info}>
            {type === 'Count'
              ? payload[0].payload.y || payload[0].payload.y2
              : formatCurrency(payload[0].payload.y || payload[0].payload.y2)}
          </div>
        </div>
        {progression.difference !== null && (
          <div style={{ margin: '4px 8px' }}>
            <div className={classes.subtitle}>{getLabel(payload[0].payload.w)}</div>
            <div className={classes.info}>
              {type === 'Count' ? payload[0].payload.z : formatCurrency(payload[0].payload.z)}
            </div>
          </div>
        )}
        {progression.difference !== null && (
          <div style={{ margin: '4px 8px' }}>
            <div className={classes.subtitle}>Difference</div>
            <div className={classes.info}>
              <span>{renderTrendIcon(progression.difference)}</span>
              <span>
                {progression.trend > 0 ? '+' : ''}
                {formatCurrency(progression.trend, 'percent', 0, 0)}
              </span>
              <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'normal' }}>
                {` (${progression.difference > 0 ? '+' : ''}${
                  type === 'Count'
                    ? progression.difference.toFixed(0)
                    : formatCurrency(progression.difference, 'currency', 2, 2)
                })`}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

const LineGraph = (props: Props) => {
  const [maxValue, setMaxValue] = useState<number>();
  const [granularity, setGranularity] = useState<GranularityProps>();
  const { data, formatCurrency, title } = props;
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const getDisplayType = (): DisplayType => {
    if (isMobile) return 'mobile';
    if (isDesktop) return 'desktop';
    return 'tablet';
  };
  const displayType = getDisplayType();

  useEffect(() => {
    getMaxValue(data.CurrentPeriod, data.PreviousPeriod);
    getGranularity();
  }, [data.CurrentPeriod, data.PreviousPeriod]);

  const getMaxValue = (period1, period2) => {
    const value = getMaxCount(period1, period2);
    setMaxValue(value * 1.2);
  };

  const getGranularity = () => {
    let duration;
    if (data.CurrentPeriod.Data.length) {
      duration = moment(data.CurrentPeriod.Data[data.CurrentPeriod.Data.length - 1].key).diff(
        data.CurrentPeriod.Data[0].key,
        'd'
      );
    } else if (data.PreviousPeriod.Data.length) {
      duration = moment(data.PreviousPeriod.Data[data.PreviousPeriod.Data.length - 1].key).diff(
        data.PreviousPeriod.Data[0].key,
        'd'
      );
    }
    const value: GranularityProps[] = mapDurationToGranularity(duration);
    setGranularity(value[0]);
  };

  if (maxValue === undefined) {
    return <div />;
  }

  const getTickFormat = (v) => {
    if (v !== undefined && v !== null) {
      if (data.CurrentPeriod.Type === 'Count') {
        /**
         * We round value here, because the chart
         * sometimes returns unaccurate values
         * ie: 125.00000003 instead of 125
         */
        return Math.round(v);
      }
      return formatCurrency(v);
    }
    return '';
  };

  const renderTick = (tick) => {
    return renderDateTick(tick, granularity);
  };

  const getLabel = (d) => {
    if (granularity === 'hourly') {
      return `${moment(d.x).format('DD MMMM')}: ${getTickFormat(d.y)}\n(${moment(d.x).format(
        'h:mm A'
      )})`;
    }
    if (granularity === 'daily') {
      return `${moment(d.x).format('DD MMM')}: ${getTickFormat(d.y)}`;
    }
    if (granularity === 'weekly') {
      const weekStart = moment(d.x).startOf('week');
      const weekEnd = moment(d.x).endOf('week');
      return `Week ${moment(d.x).format('W')}: ${getTickFormat(d.y)}\n(${weekStart.format(
        'DD MMM'
      )} - ${weekEnd.format('DD MMM')})`;
    }
    if (granularity === 'monthly') {
      return `${moment(d.x).format('M YYYY')}: ${getTickFormat(d.y)}`;
    }
    return `${moment(d.x).format('YYYY')}: ${getTickFormat(d.y)}`;
  };

  const getCompleteData = (data) => {
    if (granularity === 'hourly') {
      return data.filter((cd) =>
        moment(cd.key, 'YYYY-MM-DDTHH:mm:ss[Z]').startOf('h').isBefore(moment().startOf('h'))
      );
    }
    if (granularity === 'daily') {
      return data.filter((cd) =>
        moment(cd.key, 'YYYY-MM-DDTHH:mm:ss[Z]').startOf('d').isBefore(moment().startOf('d'))
      );
    }
    if (granularity === 'weekly') {
      return data.filter((cd) =>
        moment(cd.key, 'YYYY-MM-DDTHH:mm:ss[Z]').startOf('d').isBefore(moment().startOf('d'))
      );
    }
    if (granularity === 'monthly') {
      return data.filter((cd) =>
        moment(cd.key, 'YYYY-MM-DDTHH:mm:ss[Z]')
          .startOf('month')
          .isBefore(moment().startOf('month'))
      );
    }
    return data.filter((cd) =>
      moment(cd.key, 'YYYY-MM-DDTHH:mm:ss[Z]').startOf('d').isBefore(moment().startOf('d'))
    );
  };

  const serializeData = (currData, prevData) => {
    const completeData = getCompleteData(currData);
    const finalData = [] as any;
    for (let i = 0; i < currData.length; i++) {
      const prevY = prevData && prevData.length ? prevData[i] : null;
      const datum = {
        index: i,
        x: currData[i].key, // date current interval
        y: i >= completeData.length ? null : currData[i].value, // value complete current interval
        z: prevY ? prevY.value : null, // date previous interval
        w: prevY ? prevY.key : null, // value previous interval
        y2: i >= completeData.length - 1 ? currData[i].value : null, // value current incomplete interval (null if complete)
        label: getLabel({ x: currData[i].key, y: currData[i].value }),
      };

      finalData.push(datum);
    }
    return finalData;
  };

  const maxTickNumber = displayType === 'desktop' ? 18 : displayType === 'tablet' ? 12 : 8;
  const interval = Math.ceil(data.CurrentPeriod.Data.length / maxTickNumber);

  return (
    <div>
      <Hidden mdDown>
        <Typography
          style={{
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.71',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '0px 0px 0px 18px',
          }}
        >
          <Translate id={title} />
        </Typography>
      </Hidden>

      <ResponsiveContainer width="100%" height={getChartHeight(displayType)}>
        <LineChart
          data={serializeData(data.CurrentPeriod.Data, data.PreviousPeriod.Data)}
          margin={{ top: 8, right: 16, left: 16, bottom: 8 }}
        >
          <XAxis
            dataKey="x"
            tick={<CustomizedAxisTick renderTick={renderTick} />}
            interval={interval}
            axisLine={false}
            height={60}
          />
          <YAxis
            axisLine={false}
            orientation="right"
            allowDataOverflow={true}
            tickLine={false}
            tickFormatter={getTickFormat}
          />
          <CartesianGrid vertical={false} />
          <Tooltip
            content={
              <CustomTooltip
                section={title}
                type={data.CurrentPeriod.Type}
                formatCurrency={formatCurrency}
                granularity={granularity}
              />
            }
          />
          <Line type="linear" dot={false} dataKey="y" stroke="#7480fe" strokeWidth={2.5} />
          <Line
            type="linear"
            dot={false}
            dataKey="y2"
            stroke="#7480fe"
            strokeWidth={2.5}
            strokeDasharray="5 5"
          />
          <Line
            type="linear"
            dot={false}
            dataKey="z"
            stroke="#b06cf1"
            strokeWidth={1.5}
            strokeDasharray="3 5"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default compose<Props, ChartProps>()(LineGraph);
