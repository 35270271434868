import React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Translate } from 'react-localize-redux';

import FormItem from '../FormItem';

type Props = {
  canEdit: boolean;
};

const PreOrderTimeDisplayType = ({ canEdit }: Props) => {
  return (
    <FormItem
      primary={<Translate id="Preorder_settings_display_type_item_title" />}
      secondary={<Translate id="Preorder_settings_display_type_item_helper_text" />}
      name="PreOrderTimeDisplayType"
    >
      {({ field, form }) => (
        <FormControl fullWidth>
          <Select
            variant="standard"
            disabled={!canEdit}
            value={field.value}
            SelectDisplayProps={{
              // @ts-ignore
              'data-fd': 'pre_order_settings_pre_prder_time_display_type',
            }}
            inputProps={{
              'data-fd': 'pre_order_settings_pre_prder_time_display_type_input',
            }}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
            }}
          >
            <MenuItem
              value="SingleTime"
              data-fd="pre_order_settings_pre_prder_time_display_type_signle_time"
            >
              {<Translate id="Single_time" />}
            </MenuItem>
            <MenuItem
              value="StartAndEndTime"
              data-fd="pre_order_settings_pre_prder_time_display_type_start_and_end_time"
            >
              {<Translate id="Start_and_end_time" />}
            </MenuItem>
            <MenuItem
              value="DayOnly"
              data-fd="pre_order_settings_pre_prder_time_display_type_day_only"
            >
              {<Translate id="Day_only" />}
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </FormItem>
  );
};

export default PreOrderTimeDisplayType;
