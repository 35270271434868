import { loadByAppId } from '../../actions/store.actions';
import { action } from '../../actions/utils';

// #region set filter
export const ADD_NEW_STORES_SELECTION = 'VOUCHER/ADD_NEW/STORES/SELECTION';
export type SetStoresSelection = ReturnType<typeof setStoresSelection>;
export const setStoresSelection = (stores: number[] = []) =>
  action(ADD_NEW_STORES_SELECTION, stores);
// #endregion

// #region set search
export const ADD_NEW_STORES_SUGGESTIONS = 'VOUCHER/ADD_NEW/STORES/SUGGESTIONS';
export type SetStoresSearch = ReturnType<typeof setStoresSearch>;
export const setStoresSearch = (stores?: number[]) => action(ADD_NEW_STORES_SUGGESTIONS, stores);
// #endregion

// #region loadStores
export function loadStores(query?: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      const storeIds = getState().orm.FlipdishStore.items as any;
      if (storeIds && storeIds.length) {
        dispatch(setStoresSearch(storeIds));
      } else {
        await dispatch(loadByAppId());
        const storeIds = getState().orm.FlipdishStore.items as any;
        dispatch(setStoresSearch(storeIds));
      }
    } catch (error) {
      console.error(error);
    }
  };
}
// #endregion
