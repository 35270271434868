import { FulfillmentStatesConfigurationSummary } from '@flipdish/api-client-typescript';

import { orderFulfillmentStatusService } from '../components/OrderFulfillmentStatus/services/orderFulfillmentStatus.service';
import { orderFulfillmentStatusConstants } from '../constants/orderFulfillmentStatus.constants';
import { action, actionError } from './utils';

const {
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST,
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS,
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE,
} = orderFulfillmentStatusConstants;

// #region getConfiguredOrderFulfillmentStatus
export const getOrderFulfillmentStatusList = () =>
  action(GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST);

export type GetConfiguredOrderFulfillmentStatusSuccess = ReturnType<
  typeof getConfiguredOrderFulfillmentStatusSuccess
>;
export const getConfiguredOrderFulfillmentStatusSuccess = (
  configuredOrderFulfillmentStatus: FulfillmentStatesConfigurationSummary[]
) => action(GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS, configuredOrderFulfillmentStatus);

export const getConfiguredOrderFulfillmentStatusFailure = () =>
  actionError(GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE, null, null);

export function getConfiguredOrderFulfillmentStatusForApp(appId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getOrderFulfillmentStatusList());
      const configuredOrderFulfillmentStatus = await orderFulfillmentStatusService.getorderFulfillmentStatusList(
        appId
      );
      dispatch(getConfiguredOrderFulfillmentStatusSuccess(configuredOrderFulfillmentStatus.Data));
    } catch (error) {
      dispatch(getConfiguredOrderFulfillmentStatusFailure());
    }
  };
}
// #endregion
