import * as React from 'react';

import { StoreGroup } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { type UnpackICEWP, compose, setDisplayName, withHandlers } from 'recompose';

import { update } from '../../../actions/storegroup.actions';
import withStoreGroupByIdData, {
  WithStoreGroupByIdData,
} from '../../StoreGroups/withStoreGroupByIdData';

const currencyData = ((passedEnum) => {
  const data: Array<{ key: string; value: string }> = [];
  // tslint:disable-next-line:forin
  for (const x in passedEnum) {
    data.push({ key: x, value: x });
  }
  return data;
})(StoreGroup.CurrencyEnum);

const handlers = () => {
  return {
    onSubmit: () => (e) => {
      e.preventDefault();
    },
    onChange:
      ({ storeGroupId, dispatch }: Props) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        const changes = {
          Currency: StoreGroup.CurrencyEnum[e.target.value],
        };
        dispatch(update(storeGroupId, changes));
      },
  };
};
type Handlers = UnpackICEWP<typeof useHandlers>;
const useHandlers = withHandlers(handlers);

export interface IStoreGroupCurrencyEditorProps {
  storeGroupId: number;
  className?: string;
  disabled?: boolean;
}
type Props = IStoreGroupCurrencyEditorProps & WithStoreGroupByIdData;
const StoreGroupCurrencyEditor = compose<Props & Handlers, IStoreGroupCurrencyEditorProps>(
  setDisplayName('StoreGroupCurrencyEditor'),
  withStoreGroupByIdData,
  useHandlers
)(({ onSubmit, onChange, className, storeGroupByIdData, disabled }) => {
  return (
    <form onSubmit={onSubmit} className={className}>
      <TextField
        variant="standard"
        id="select-Currency"
        name="Currency"
        select
        value={storeGroupByIdData && storeGroupByIdData.Currency}
        onChange={onChange as any}
        fullWidth
        margin="dense"
        disabled={disabled}
        InputProps={{
          inputProps: {
            'data-fd': 'select-Currency',
          },
        }}
      >
        {currencyData.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </form>
  );
});

export default StoreGroupCurrencyEditor;
