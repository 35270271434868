import ExtendableError from 'es6-error';

interface IServerErrorStaticProps {
  statusCode: number;
}

export type IServerError = (new (message?: string) => ServerError) &
  IServerErrorStaticProps;
export class ServerError extends ExtendableError {
  constructor(message) {
    super(message);
  }
}

export const Unauthorized: IServerError = class NotFound extends ServerError {
  public static statusCode = 401;
  constructor(message: string = 'Unauthorized') {
    super(message);
  }
};

export const NotFound: IServerError = class NotFound extends ServerError {
  public static statusCode = 404;
  constructor(message: string = 'Not Found') {
    super(message);
  }
};
