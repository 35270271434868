import { BankAccountSummary } from '@flipdish/api-client-typescript';

export const GetLastFourIbanNumbers = ({
  stripeUpdateAccount,
  stripeCardPaymentStatus,
}: {
  stripeUpdateAccount?: BankAccountSummary;
  stripeCardPaymentStatus?: BankAccountSummary;
}) => {
  return stripeUpdateAccount
    ? stripeUpdateAccount.Iban &&
        stripeUpdateAccount.Iban.slice(stripeUpdateAccount.Iban.length - 4)
    : stripeCardPaymentStatus &&
        stripeCardPaymentStatus.Iban &&
        stripeCardPaymentStatus.Iban.slice(stripeCardPaymentStatus.Iban.length - 4);
};
