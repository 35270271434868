import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';

import { action /*, actionError, createAsyncAction, dispatchAsync*/ } from '../../actions/utils';
import { notifyError } from '../../layouts/Notify/actions';
import * as services from './settings.service';

// #region GET TermsAndPolicyData

export const GET_TERMS_AND_POLICY_DATA = 'GET_TERMS_AND_POLICY_DATA';
export const GET_TERMS_AND_POLICY_DATA_REQUEST = `${GET_TERMS_AND_POLICY_DATA}_REQUEST`;
export const GET_TERMS_AND_POLICY_DATA_SUCCESS = `${GET_TERMS_AND_POLICY_DATA}_SUCCESS`;
export const GET_TERMS_AND_POLICY_DATA_FAILURE = `${GET_TERMS_AND_POLICY_DATA}_FAILURE`;

export type GetTermsAndPolicyDataRequest = ReturnType<typeof getTermsAndPolicyDataRequest>;
export const getTermsAndPolicyDataRequest = () => action(GET_TERMS_AND_POLICY_DATA_REQUEST);

export type GetTermsAndPolicyDataRequestSuccess = ReturnType<
  typeof getTermsAndPolicyDataRequestSuccess
>;
export const getTermsAndPolicyDataRequestSuccess = (appId: string, data: TermsAndPolicyData) =>
  action(GET_TERMS_AND_POLICY_DATA_SUCCESS, data, appId);

export type GetTermsAndPolicyDataRequestFailure = ReturnType<
  typeof getTermsAndPolicyDataRequestFailure
>;
export const getTermsAndPolicyDataRequestFailure = (error) =>
  action(GET_TERMS_AND_POLICY_DATA_FAILURE, error);

export const getTermsAndPolicyData = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getTermsAndPolicyDataRequest());

      const { Data } = await services.getTermsAndPolicyData(appId);

      dispatch(getTermsAndPolicyDataRequestSuccess(appId, Data));
    } catch (error) {
      if (
        error.message !==
        'Successful authentication, but authorization has been denied for this request.'
      ) {
        dispatch(getTermsAndPolicyDataRequestFailure(error));
        dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      }
    }
  };
};
//#endregion
