import { DeliveryZone, DeliveryZoneBase } from '@flipdish/api-client-typescript';
import debounce from 'lodash/debounce';

import { deliveryZonesConstants } from '../constants/deliveryzones.constants';
import { getDebounceMaybe } from '../helpers/utilities';
import {
  closeNotifyLoading,
  closeNotifySaving,
  notify,
  notifyError,
  notifyLoading,
  notifySaving,
} from '../layouts/Notify/actions';
import * as storeDeliveryZoneService from '../services/storeDeliveryZone.service';
import { storeActions } from './store.actions';
import { storeGroupActions } from './storegroup.actions';
import { action, actionError } from './utils';

// #region loadAll
const {
  DELIVERY_ZONE_LOAD_ALL_REQUEST,
  DELIVERY_ZONE_LOAD_ALL_SUCCESS,
  DELIVERY_ZONE_LOAD_ALL_FAILURE,
} = deliveryZonesConstants;

export const loadAllRequest = (storeId: number) =>
  action(DELIVERY_ZONE_LOAD_ALL_REQUEST, { storeId });

export const loadAllSuccess = (storeId: number, zones: DeliveryZone[]) =>
  action(DELIVERY_ZONE_LOAD_ALL_SUCCESS, { storeId, zones });

export const loadAllFailure = (storeId: number, error: Error) =>
  actionError(DELIVERY_ZONE_LOAD_ALL_FAILURE, { storeId }, error);

export const loadAll = (
  appId: string,
  storeGroupId: number,
  storeId: number,
  loadDependencies = false
): ThunkAction => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      if (!loadDependencies) {
        return resolve();
      }

      dispatch(notifyLoading());

      return storeGroupActions
        .load(appId, storeGroupId)(dispatch, getState)
        .then(() => storeActions.load(storeId)(dispatch))
        .then(() => resolve())
        .catch((er) => reject(er));
    }).then(() => deliveryZoneServiceCall(dispatch));
  };

  function deliveryZoneServiceCall(dispatch) {
    dispatch(loadAllRequest(storeId));

    return storeDeliveryZoneService.loadAll(storeId).then(
      (zones: DeliveryZone[]) => {
        const nonCommaZones = zones.map((zone) => {
          const coordinatesWithoutCommas = zone.WellKnownText?.replace(
            /([0-9]{1,})(,)([0-9]{1,})/g,
            '$1.$3'
          );
          return {
            ...zone,
            WellKnownText: coordinatesWithoutCommas,
          };
        });

        dispatch(loadAllSuccess(storeId, nonCommaZones));
        dispatch(closeNotifyLoading());

        return nonCommaZones;
      },
      (error) => {
        dispatch(loadAllFailure(storeId, error));
        dispatch(closeNotifyLoading());
        return error;
      }
    );
  }
};
// #endregion loadAll

// #region create
const { DELIVERY_ZONE_CREATE_REQUEST, DELIVERY_ZONE_CREATE_SUCCESS, DELIVERY_ZONE_CREATE_FAILURE } =
  deliveryZonesConstants;

export const createRequest = (storeId: number, zone: DeliveryZoneBase, tmpId: number) =>
  action(DELIVERY_ZONE_CREATE_REQUEST, { storeId, zone, tmpId });

export const createSuccess = (storeId: number, zone: DeliveryZone, tmpId: number) =>
  action(DELIVERY_ZONE_CREATE_SUCCESS, { storeId, zone, tmpId });

export const createFailure = (storeId: number, tmpId: number, error: Error) =>
  actionError(DELIVERY_ZONE_CREATE_FAILURE, { storeId, tmpId }, error);

export const create = (storeId: number, zone: DeliveryZoneBase, tmpId: number): ThunkAction => {
  return (dispatch) => {
    dispatch(setLocalEditFlag(true));
    dispatch(createRequest(storeId, zone, tmpId));
    dispatch(notifySaving());

    storeDeliveryZoneService.create(storeId, zone).then(
      (zone) => {
        dispatch(createSuccess(storeId, zone, tmpId));
        dispatch(select(zone.Id as number));

        resetLocalEditFlagDebounce(dispatch);
        dispatch(closeNotifySaving());
        dispatch(
          notify({
            message: 'store_delivery_zone_created_title',
            translate: true,
            variant: 'success',
          })
        );
      },

      (error) => {
        dispatch(createFailure(storeId, tmpId, error));
        dispatch(setLocalEditFlag(false));
        dispatch(notifyError({ message: error.message }));
      }
    );
  };
};
// #endregion create

// #region update
const { DELIVERY_ZONE_UPDATE_REQUEST, DELIVERY_ZONE_UPDATE_SUCCESS, DELIVERY_ZONE_UPDATE_FAILURE } =
  deliveryZonesConstants;

export const updateRequest = (storeId: number, deliveryZoneId: number, zone: DeliveryZoneBase) =>
  action(DELIVERY_ZONE_UPDATE_REQUEST, { storeId, deliveryZoneId, zone });

export const updateSuccess = (storeId: number, deliveryZoneId: number, zone: DeliveryZone) =>
  action(DELIVERY_ZONE_UPDATE_SUCCESS, { storeId, deliveryZoneId, zone });

export const updateFailure = (
  storeId: number,
  deliveryZoneId: number,
  undo: DeliveryZone,
  error: Error
) => actionError(DELIVERY_ZONE_UPDATE_FAILURE, { storeId, deliveryZoneId, undo }, error);

export const update = (throttle?: boolean) => {
  let executableFunction;
  return function (
    storeId: number,
    storeDeliveryZoneId: number,
    zone: DeliveryZone,
    undo: DeliveryZone
  ): ThunkAction {
    return (dispatch: ThunkDispatch, getState: () => AppState) => {
      if (throttle && executableFunction) {
        executableFunction.cancel();
      }
      if (zone.DeliveryFee == null || zone.MinimumDeliveryOrderAmount == null) {
        dispatch(updateRequest(storeId, zone.Id as number, zone));
      } else {
        executableFunction = getDebounceMaybe(
          () => {
            dispatch(setLocalEditFlag(true));
            dispatch(notifySaving({ persist: true }));
            storeDeliveryZoneService.update(storeId, storeDeliveryZoneId, zone).then(
              (zone) => {
                if (!getState().deliveryZones.isLocalEdit) {
                  dispatch(updateSuccess(storeId, zone.Id as number, zone));
                }

                resetLocalEditFlagDebounce(dispatch);

                dispatch(closeNotifySaving());
                dispatch(
                  notify({
                    message: 'store_delivery_zone_updated_title',
                    translate: true,
                    variant: 'success',
                  })
                );
              },
              (error) => {
                dispatch(updateFailure(storeId, zone.Id as number, undo, error));

                dispatch(setLocalEditFlag(false));

                dispatch(closeNotifySaving());
                dispatch(notifyError({ message: error.message }));
              }
            );
          },
          true,
          1000
        );

        dispatch(updateRequest(storeId, zone.Id as number, zone));
        executableFunction();
      }
    };
  };
};
// #endregion update

// #region remove
const { DELIVERY_ZONE_REMOVE_REQUEST, DELIVERY_ZONE_REMOVE_SUCCESS, DELIVERY_ZONE_REMOVE_FAILURE } =
  deliveryZonesConstants;

export const removeRequest = (storeId: number, deliveryZoneId: number) =>
  action(DELIVERY_ZONE_REMOVE_REQUEST, { storeId, deliveryZoneId });

export const removeSuccess = (storeId: number, deliveryZoneId: number) =>
  action(DELIVERY_ZONE_REMOVE_SUCCESS, { storeId, deliveryZoneId });

export const removeFailure = (
  storeId: number,
  deliveryZoneId: number,
  undo: DeliveryZone,
  error: Error
) => actionError(DELIVERY_ZONE_REMOVE_FAILURE, { storeId, deliveryZoneId, undo }, error);

export const remove = (
  storeId: number,
  deliveryZoneId: number,
  undo: DeliveryZone
): ThunkAction => {
  return (dispatch) => {
    dispatch(removeRequest(storeId, deliveryZoneId));

    storeDeliveryZoneService.remove(storeId, deliveryZoneId).then(
      () => {
        dispatch(removeSuccess(storeId, deliveryZoneId));
        dispatch(
          notify({
            message: 'store_delivery_zone_deleted_title',
            translate: true,
            variant: 'success',
          })
        );
      },
      (error) => {
        dispatch(removeFailure(storeId, deliveryZoneId, undo, error));
        if (error.message) {
          dispatch(notifyError({ message: error.message }));
        } else {
          dispatch(notifyError({ message: 'Something_went_wrong' }));
        }
      }
    );
  };
};
// #endregion remove

// #region reset
const { DELIVERY_ZONE_STATE_RESET } = deliveryZonesConstants;
export const reset = () => action(DELIVERY_ZONE_STATE_RESET);

const { DELIVERY_ZONE_ERROR_RESET } = deliveryZonesConstants;
export const resetError = () => action(DELIVERY_ZONE_ERROR_RESET);
// #endregion reset

// #region select
const { DELIVERY_ZONE_SELECTION_CHANGE } = deliveryZonesConstants;
export const select = (deliveryZoneId: number) =>
  action(DELIVERY_ZONE_SELECTION_CHANGE, deliveryZoneId);
// #endregion select

// #region setLocalEditFlag
const { DELIVERY_ZONE_LOCAL_EDIT_FLAG } = deliveryZonesConstants;
export const setLocalEditFlag = (isEdit: boolean) => action(DELIVERY_ZONE_LOCAL_EDIT_FLAG, isEdit);

const resetLocalEditFlagDebounce = debounce((dispatch: ThunkDispatch) => {
  dispatch(setLocalEditFlag(false));
}, 30 * 1000);
// #endregion select
