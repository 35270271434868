import React, { useEffect } from 'react';

import { OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import { useQueryClient } from '@tanstack/react-query';
import { connect } from 'react-redux';

import { tutorialMilestoneId } from '../../../constants/tutorial.constants';
import { isFlipdishGlobal } from '../../../selectors/app.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import useTutorialData from '../hooks/useTutorialData';
import useTutorialStore from '../hooks/useTutorialStore';
import { tutorialService } from '../tutorial.service';

type TutorialNotifierProps = {
  onboardingItemId: number;
  status: OnboardingItemUpdate;
  storeId?: number;
};

const TutorialNotifier = ({
  appId,
  currentApp,
  isFlipdishGlobal,
  onboardingItemId,
  status,
  storeId,
  hasOnBoardingPermissions,
}: TutorialNotifierProps & MappedState) => {
  const queryClient = useQueryClient();

  const getTutorialStoreIdFromStore = useTutorialStore(currentApp.AppId).data?.StoreId;
  const tutorialStoreId = storeId || getTutorialStoreIdFromStore;
  const tutorialData = useTutorialData(
    appId,
    tutorialStoreId,
    tutorialMilestoneId,
    hasOnBoardingPermissions
  )?.data;
  if (isFlipdishGlobal) {
    return null;
  }

  const itemStatus = (tutorialData: any): string => {
    const onboardingId = onboardingItemId.toString();
    const items = tutorialData?.Items?.[0]?.Items || [];
    const milestoneNumber = Number(onboardingId[0]) - 1;
    const milestoneItems = items[milestoneNumber]?.Items || [];

    if (onboardingItemId.toString().length === 4) {
      const subItemNumber = Number(onboardingId[2]) - 1;

      const subItems = milestoneItems[subItemNumber].Items || [];

      const matchedSubItem = subItems.find((item) => item.OnboardingItemId === onboardingItemId);

      if (matchedSubItem) {
        return matchedSubItem.Status;
      }
    } else {
      const matchedItem = milestoneItems.find((item) => item.OnboardingItemId === onboardingItemId);

      if (matchedItem) {
        return matchedItem.Status;
      }
    }

    return 'null';
  };

  useEffect(() => {
    const notify = async () => {
      await tutorialService
        .updateTutorialItem(appId, tutorialStoreId ?? 0, onboardingItemId, status)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: [tutorialService.getTutorialKey] });
        });
    };

    if (tutorialData && itemStatus(tutorialData) !== 'Completed') {
      notify();
    }
  }, [storeId, appId, tutorialData]);

  return <></>;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const getOnBoardingPermissions = permissionsSelector.hasPermissionFactory([
    'Owner',
    'FlipdishStaff',
  ]);
  return {
    appId: state.currentApp.AppId!,
    currentApp: state.currentApp,
    isFlipdishGlobal: isFlipdishGlobal(state),
    hasOnBoardingPermissions: getOnBoardingPermissions(state),
  };
};

export default connect(mapStateToProps)(TutorialNotifier);
