import {
  BusinessHoursOverride,
  RestApiPaginationResultBusinessHoursOverride,
} from '@flipdish/api-client-typescript';

import actionTypes from '../constants/storeOpeningHourOverrides.constants';
import { notifyError, notifySaved } from '../layouts/Notify/actions';
import { ServerSubmissionError } from '../models';
import * as service from '../services/storeOpeningHourOverrides.service';
import { action, actionError } from './utils';

export interface IPagination {
  page: number;
  limit: number;
}

// #region loadAll
const { LOAD_ALL_REQUEST, LOAD_ALL_SUCCESS, LOAD_ALL_FAILURE } = actionTypes;

export type LoadAllRequest = ReturnType<typeof loadAllRequest>;
export const loadAllRequest = (storeId: number, after?: Date, pagination?: IPagination) =>
  action(LOAD_ALL_REQUEST, { storeId, after, ...pagination });

export type LoadAllSuccess = ReturnType<typeof loadAllSuccess>;
export const loadAllSuccess = (
  storeId: number,
  businessHoursOverrides: RestApiPaginationResultBusinessHoursOverride,
  after?: Date,
  pagination?: IPagination
) =>
  action(LOAD_ALL_SUCCESS, {
    storeId,
    businessHoursOverrides,
    after,
    pagination,
  });

export type LoadAllFailure = ReturnType<typeof loadAllFailure>;
export const loadAllFailure = (
  storeId: number,
  error: Error,
  after?: Date,
  pagination?: IPagination
) => actionError(LOAD_ALL_FAILURE, { storeId, after, pagination }, error);

export const loadAll =
  (storeGroupId: number, storeId: number, after?: Date, pagination?: IPagination): ThunkAction =>
  (dispatch, getState) => {
    if (!storeId) {
      return Promise.reject(`StoreId is required!`);
    }

    dispatch(loadAllRequest(storeId, after, pagination));

    const { page, limit } = pagination || { page: 0, limit: 20 };

    return service
      .loadAll({ storeId, after, page, limit })
      .then((result) => dispatch(loadAllSuccess(storeId, result, after, pagination)))
      .catch((error: Error) => {
        dispatch(loadAllFailure(storeId, error, after, pagination));
      });
  };
// #endregion

// #region create
const { CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE } = actionTypes;

export type CreateRequest = ReturnType<typeof createRequest>;
export const createRequest = (
  storeId: number,
  businessHoursOverride: Flipdish.BusinessHoursOverrideBase
) => action(CREATE_REQUEST, { storeId, businessHoursOverride });

export type CreateSuccess = ReturnType<typeof createSuccess>;
export const createSuccess = (storeId: number, businessHoursOverride: BusinessHoursOverride) =>
  action(CREATE_SUCCESS, { storeId, businessHoursOverride });

export type CreateFailure = ReturnType<typeof createFailure>;
export const createFailure = (
  storeId: number,
  businessHoursOverride: Flipdish.BusinessHoursOverrideBase,
  error: Error
) => actionError(CREATE_FAILURE, { storeId, businessHoursOverride }, error);

export const create =
  (storeId: number, businessHoursOverride: Flipdish.BusinessHoursOverrideBase): ThunkAction =>
  async (dispatch, getState) => {
    try {
      dispatch(createRequest(storeId, businessHoursOverride));
      const result = await service.create(storeId, businessHoursOverride);
      dispatch(notifySaved());
      dispatch(createSuccess(storeId, result));
    } catch (error) {
      dispatch(createFailure(storeId, businessHoursOverride, error));
      dispatch(notifyError({ message: 'Something_went_wrong' }));
      throw error;
    }
  };
// #endregion

// #region remove
const { REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE } = actionTypes;

export type RemoveRequest = ReturnType<typeof removeRequest>;
export const removeRequest = (storeId: number, businessHoursOverrideId: number) =>
  action(REMOVE_REQUEST, { storeId, businessHoursOverrideId });

export type RemoveSuccess = ReturnType<typeof removeSuccess>;
export const removeSuccess = (storeId: number, businessHoursOverrideId: number) =>
  action(REMOVE_SUCCESS, { storeId, businessHoursOverrideId });

export type RemoveFailure = ReturnType<typeof removeFailure>;
export const removeFailure = (
  storeId: number,
  businessHoursOverrideId: number,
  undo: BusinessHoursOverride,
  error: Error
) => actionError(REMOVE_FAILURE, { storeId, businessHoursOverrideId, undo }, error);

export const remove = (
  storeId: number,
  businessHoursOverrideId: number,
  undo: BusinessHoursOverride
): ThunkAction => {
  return (dispatch) => {
    dispatch(removeRequest(storeId, businessHoursOverrideId));

    return service
      .remove(storeId, businessHoursOverrideId)
      .then(() => dispatch(removeSuccess(storeId, businessHoursOverrideId)))
      .catch((error: ServerSubmissionError) => {
        dispatch(removeFailure(storeId, businessHoursOverrideId, undo, error));
        // REFACTOR: throw is used by ui components
        throw error;
      });
  };
};
// #endregion remove

// #region reset
const { RESET } = actionTypes;
export const reset = () => action(RESET);
// #endregion
