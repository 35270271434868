import React from 'react';

import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';

type Props = {
  onCancel: () => void;
  onContinue: () => void;
  open: boolean;
};

const DisableCashModal = (props: Props) => {
  const { onCancel, onContinue, open } = props;
  return (
    <YesNoDialog
      noTextId={'Cancel'}
      onNo={onCancel}
      onYes={onContinue}
      open={open}
      titleTextId={'Are_you_sure'}
      yesButtonVariant={'contained'}
      yesTextId={'Disable_cash_payment'}
    >
      <Typography variant="body2" style={{ maxWidth: 432 }}>
        <Translate id="Disable_cash_warning" />
      </Typography>
    </YesNoDialog>
  );
};
export default DisableCashModal;
