import React, { useCallback } from 'react';

import { App, LoyaltyCampaign, RetentionCampaign } from '@flipdish/api-client-typescript';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import PercentageDiscountIcon from '../../../assets/images/PercentageDiscount.svg';
import { CurrencyEnum } from '../../../ui/GenericTable/types';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Spacer from '../../../ui/Spacer';
import { CampaignsTypes } from '../Campaigns';

const useStyles = makeStyles((theme: Theme) => ({
  campaignItem: {
    flexWrap: 'nowrap',
  },
  campaignContent: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  campaignTitleSection: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2.5),
  },
  fieldTitle: {
    paddingTop: theme.spacing(1.5),
  },
  fieldValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 400,
  },
  modifyButton: {
    [theme.breakpoints.down('md')]: {
      margin: '12px 0 -4px -6px',
    },
  },
  statsDivider: {
    marginTop: theme.spacing(2),
  },
  statisticsContainer: {
    paddingTop: theme.spacing(2.5),

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },

  campaignIcon: {
    alignItems: 'center',
    backgroundColor: '#dbdbdb',
    borderRadius: '100%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    minWidth: 40,
    paddingTop: 2,
    width: 40,

    '&.isEnabled': {
      backgroundColor: '#86e8bb',
    },
    '& .image': {
      height: 18,
      width: 18,
    },
  },
}));

type Props = {
  onEdit: (
    type: CampaignsTypes,
    campaignId: (RetentionCampaign | LoyaltyCampaign)['CampaignId']
  ) => void;
  data: LoyaltyCampaign & RetentionCampaign;
  type: CampaignsTypes;
  storesNames: string[];
  currency: CurrencyEnum;
  languageCode?: string;
};

export const CampaignItem = ({ storesNames, onEdit, data, type }: Props) => {
  const classes = useStyles();
  const onEditCampaign = useCallback(() => {
    onEdit(type, data.CampaignId);
  }, [type, data]);

  const {
    AutoApplyResultingVouchers,
    IncludeDeliveryFee,
    IsEnabled,
    NotifyCustomerAfterMinutes,
    OrdersBeforeReceivingVoucher,
    PercentDiscountAmount,
  } = data;

  const CampaignIcon = ({ campaignType, isEnabled }) => {
    const campaignIconStyles = clsx(classes.campaignIcon, {
      isEnabled: isEnabled,
    });
    const campaignTypeIcon =
      campaignType === CampaignsTypes.LOYALTY ? (
        <FavoriteBorderIcon htmlColor="#fff" fontSize="small" data-fd="campaignIcon-loyalty" />
      ) : (
        <img src={PercentageDiscountIcon} className="image" data-fd="campaignIcon-retention" />
      );

    return <Box className={campaignIconStyles}>{campaignTypeIcon}</Box>;
  };

  return (
    <>
      <Hidden mdDown>
        <Spacer size={8} />
      </Hidden>

      <PaperContainer hasBorderOnMobile>
        <Grid container className={classes.campaignItem}>
          <Hidden mdDown>
            <CampaignIcon campaignType={type} isEnabled={IsEnabled} />
          </Hidden>

          <Grid item className={classes.campaignContent}>
            <Grid container item className={classes.campaignTitleSection}>
              <Grid item>
                {type === CampaignsTypes.LOYALTY ? (
                  <Typography variant="subtitle1">
                    {PercentDiscountAmount ? (
                      <Translate
                        id="Discount_off_after_receiving_orders"
                        data={{
                          discount: PercentDiscountAmount,
                          orders: OrdersBeforeReceivingVoucher,
                        }}
                      />
                    ) : (
                      <Translate
                        id="Free_meal_after_orders"
                        data={{ orders: OrdersBeforeReceivingVoucher }}
                      />
                    )}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    <Translate
                      id="Discount_off_if_no_orders_placed"
                      data={{
                        discount: PercentDiscountAmount,
                        days: NotifyCustomerAfterMinutes,
                      }}
                    />
                  </Typography>
                )}
                <Typography variant="caption">
                  <Translate id={IsEnabled ? 'Enabled' : 'Disabled'} />
                </Typography>
              </Grid>

              <Hidden mdUp>
                <CampaignIcon campaignType={type} isEnabled={IsEnabled} />
              </Hidden>

              <Permissions allowed={[App.AppResourceSetEnum.UpdateCampaignsConfigurations]}>
                <Hidden mdDown>
                  <Button
                    id="modify-campaign-button"
                    color="primary"
                    type="button"
                    onClick={onEditCampaign}
                  >
                    <Translate id="Modify" />
                  </Button>
                </Hidden>
              </Permissions>
            </Grid>

            <Grid item>
              <Typography component="div" variant="caption">
                <Translate id="Stores" />
                {`: `}
                {isEmpty(storesNames) ? <Translate id="None" /> : storesNames.join(', ')}
              </Typography>
              <Typography component="div" variant="caption" style={{ paddingTop: 8 }}>
                <Translate
                  id={IncludeDeliveryFee ? 'Covers_delivery' : 'Does_not_cover_delivery'}
                />{' '}
                <Translate
                  id={
                    AutoApplyResultingVouchers
                      ? 'Applies_automatically_to_orders'
                      : 'Does_not_automatically_apply_to_orders'
                  }
                />
              </Typography>
            </Grid>

            <Permissions allowed={[App.AppResourceSetEnum.UpdateCampaignsConfigurations]}>
              <Hidden mdUp>
                <Button
                  id="modify-campaign-button"
                  className={classes.modifyButton}
                  color="primary"
                  type="button"
                  onClick={onEditCampaign}
                >
                  <Translate id="Modify" />
                </Button>
              </Hidden>
            </Permissions>
          </Grid>
        </Grid>
      </PaperContainer>

      <Hidden mdDown>
        <Spacer size={8} />
      </Hidden>
    </>
  );
};
