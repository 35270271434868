import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../../ui/Layout';
import ListItemLink from '../../../../ui/List/ListItemLink';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  verticalPadding: {
    paddingTop: '6px',
    paddingBottom: '6px',
    marginBottom: spacing(0.75),
    marginTop: spacing(0.75),
  },
  row: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  icon: {
    minWidth: 'auto',
    marginRight: -8,
  },
  divider: {
    marginLeft: 24,

    [breakpoints.only('xs')]: {
      marginLeft: 16,
    },
  },
}));

type Props = {
  url: string;
  fdKey: string;
  title: TranslationId;
  disabled: boolean;
  noDivider?: boolean;
};

const LinkItem = ({ url, fdKey, title, noDivider, disabled }: Props) => {
  const classes = useStyles();

  return (
    <>
      <ListItemLink
        to={url}
        fdKey={fdKey}
        className={`${classes.row} ${HORIZONTAL_SPACE_CLASSNAME}`}
      >
        <ListItemText
          primary={<Translate id={title} />}
          secondary={disabled && <Translate id="Disabled_preorder_helper_text" />}
          className={classes.verticalPadding}
        />
        <ListItemIcon className={classes.icon}>
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        </ListItemIcon>
      </ListItemLink>
      {!noDivider && <Divider className={classes.divider} />}
    </>
  );
};

export default LinkItem;
