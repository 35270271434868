import { get, some } from 'lodash';
import { createSelector } from 'reselect';

const loadingState = (state: AppState) => state.loading;

export const createLoadingSelector = (actions: string[]) =>
  createSelector(loadingState, (loading) => {
    return some(actions, (action) => get(loading, action)) as boolean;
  });

export const createLoadingErrorSelectorFactory = (actions: string[]) =>
  createSelector(loadingState, (loading) => {
    return some(actions.map((a) => a + '_ERROR'), (action) => get(loading, action) as boolean);
  });
