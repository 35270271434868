import React, { ReactNode } from 'react';

import MuiModal from '@mui/material/Modal';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

export type StyleProps = {
  maxWidth?: string;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  modal: {
    position: 'absolute',
    maxHeight: '100%',
    overflow: 'auto',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    width: '100%',
    maxWidth: (props) => props.maxWidth ?? '552px',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    outline: 0,
    boxShadow:
      '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    margin: 0,
    padding: 24,
    paddingBottom: 0,
  },
}));

export type ModalProps = {
  open: boolean;
  titleTextId?: TranslationId;
  disabled?: boolean;
  onClose?: () => void;
  ariaLablledBy?: string;
  children: ReactNode;
  fdKey?: string;
  maxWidth?: string;
};

export const Modal = (props: ModalProps) => {
  const { onClose, open = true, titleTextId, ariaLablledBy, fdKey, maxWidth = undefined } = props;
  const classes = useStyles({ maxWidth: maxWidth });

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby={titleTextId && ariaLablledBy ? ariaLablledBy : 'modal-title'}
    >
      <div className={classes.modal} data-fd={fdKey}>
        {titleTextId ? (
          <>
            <h2 id={ariaLablledBy ? ariaLablledBy : 'modal-title'} className={classes.title}>
              <Translate id={titleTextId} />
            </h2>
          </>
        ) : null}
        <div>{props.children}</div>
      </div>
    </MuiModal>
  );
};
