import { EFieldType } from '../EFieldType';
import { TField } from '../TField';

export type TCampaignFieldNames =
  | 'CampaignStoreIds'
  | 'CampaignIsEnabled'
  | 'CampaignForceDiscount'
  | 'CampaignNotifyCustomerAfterMinutes'
  | 'CampaignPercentDiscountAmount'
  | 'CampaignVoucherValidPeriodDays'
  | 'CampaignIncludeDeliveryFee'
  | 'CampaignIncludeExistingOrders'
  | 'CampaignTypeOfReward'
  | 'CampaignAutomaticallyApplyExistingOrders'
  | 'CampaignOrdersBeforeReceivingVoucher'
  | 'CampaignMaxDiscount';

export const CAMPAIGN_FIELDS: Record<TCampaignFieldNames, TField> = {
  ['CampaignStoreIds']: {
    fieldName: 'CampaignStoreIds',
    required: true,
    fieldType: EFieldType.Array,
  },
  ['CampaignIsEnabled']: {
    fieldName: 'CampaignIsEnabled',
    required: false,
    fieldType: EFieldType.Boolean,
  },
  ['CampaignForceDiscount']: {
    fieldName: 'CampaignForceDiscount',
    required: false,
    fieldType: EFieldType.Boolean,
  },
  ['CampaignNotifyCustomerAfterMinutes']: {
    fieldName: 'CampaignNotifyCustomerAfterMinutes',
    required: false,
    fieldType: EFieldType.Number,
    min: 1,
    max: 1491308,
  },
  ['CampaignPercentDiscountAmount']: {
    fieldName: 'CampaignPercentDiscountAmount',
    required: false,
    min: 1,
    max: 100,
    fieldType: EFieldType.Number,
  },
  ['CampaignVoucherValidPeriodDays']: {
    fieldName: 'CampaignVoucherValidPeriodDays',
    required: false,
    fieldType: EFieldType.Number,
    min: 1,
    max: 300,
  },
  ['CampaignOrdersBeforeReceivingVoucher']: {
    fieldName: 'CampaignOrdersBeforeReceivingVoucher',
    required: false,
    fieldType: EFieldType.Number,
    min: 1,
    max: 300,
  },
  ['CampaignIncludeDeliveryFee']: {
    fieldName: 'CampaignIncludeDeliveryFee',
    required: false,
    fieldType: EFieldType.Boolean,
  },
  ['CampaignIncludeExistingOrders']: {
    fieldName: 'CampaignIncludeExistingOrders',
    required: false,
    fieldType: EFieldType.Boolean,
  },
  ['CampaignAutomaticallyApplyExistingOrders']: {
    fieldName: 'CampaignAutomaticallyApplyExistingOrders',
    required: false,
    fieldType: EFieldType.Boolean,
  },
  ['CampaignTypeOfReward']: {
    fieldName: 'CampaignTypeOfReward',
    required: false,
    fieldType: EFieldType.RadioGroup,
  },
  ['CampaignMaxDiscount']: {
    fieldName: 'CampaignMaxDiscount',
    required: false,
    fieldType: EFieldType.Number,
  },
};
