import React, { useState } from 'react';

import OpenIcon from '@mui/icons-material/Restaurant';
import ClosedIcon from '@mui/icons-material/RestaurantMenu';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { storeOpeningHourOverrideActions } from '../../../actions';
import { storeOpeningHourSelectors, storeSelectors } from '../../../selectors';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import Form, { TimeErrors } from './OpenCloseForm';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state: AppState, props: Props) => {
  const storeId = Number(props.storeId);
  const storeOpeningHours = storeOpeningHourSelectors.store7DayOpeningHours(state, {
    storeId,
  });
  return (state: AppState) => {
    const store = getSelectedStore(state);
    const preOrderEnabled = store && store.PreOrderEnabled;
    return {
      storeIanaTZ: storeSelectors.timeZone(state, { storeId }),
      dtUtils: dateTimeUtils(state),
      preOrderEnabled,
      storeOpeningHours,
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch, ownProps: Props) => {
  const { storeId } = ownProps;
  return {
    create: (bho: Flipdish.BusinessHoursOverrideBase) => {
      dispatch(storeOpeningHourOverrideActions.create(Number(storeId), bho));
    },
  };
};

export interface IQuickCloseSimpleDialog {
  storeId: number | string;
  startDate?: Date;
  endDate?: Date;
  defaultForm?: 'Close' | 'Open';
  preOrderPickup?: boolean;
  preOrderDelivery?: boolean;
  preOrderEnabled?: boolean;
  onSave(data: Flipdish.BusinessHoursOverrideBase[]): void;
  onCancel(): void;
}
type Props = IQuickCloseSimpleDialog;

export default compose<Props & MappedState & MappedDispatch, IQuickCloseSimpleDialog>(
  setDisplayName('OpenCloseFormDialog'),
  connect(mapStateToPropsFactory, mapDispatchToProps)
)((props) => {
  const {
    defaultForm = 'Close',
    onCancel,
    startDate,
    endDate,
    onSave,
    create,
    storeIanaTZ,
    dtUtils,
    preOrderEnabled,
    preOrderPickup,
    preOrderDelivery,
    storeId,
    storeOpeningHours,
  } = props;

  const [errors, setErrors] = useState<TimeErrors>({});

  let deliveryStoreEnabled = false;

  if (storeOpeningHours && storeOpeningHours.Delivery) {
    deliveryStoreEnabled = storeOpeningHours.Delivery.enabled;
  }

  let pickupStoreEnabled = false;
  if (storeOpeningHours && storeOpeningHours.Pickup) {
    pickupStoreEnabled = storeOpeningHours.Pickup.enabled;
  }

  // #region
  const [tab, setTab] = useState(() => (defaultForm === 'Close' ? 0 : 1));

  const [data, setData] = useState(() => ({
    Pickup: pickupStoreEnabled,
    Delivery: deliveryStoreEnabled,
    StartTime: dtUtils.utcToZonedTime(startDate || new Date(), storeIanaTZ!),
    EndTime: dtUtils.utcToZonedTime(endDate || new Date(), storeIanaTZ!),
    Type: 'Open',
  }));
  const [dataClose, setDataClose] = useState(() => ({
    Pickup: pickupStoreEnabled,
    Delivery: deliveryStoreEnabled,
    StartTime: dtUtils.utcToZonedTime(startDate || new Date(), storeIanaTZ!),
    EndTime: dtUtils.utcToZonedTime(endDate || new Date(), storeIanaTZ!),
    Type: 'Closed',
    PreOrderPickup: false,
    PreOrderDelivery: false,
  }));

  const onSaveClick = () => {
    const result: Flipdish.BusinessHoursOverrideBase[] = [];
    const bhoLike = tab === 1 ? data : dataClose;

    const startTime = dtUtils.zonedTimeToUtc(bhoLike.StartTime, storeIanaTZ!);
    const endTime = dtUtils.zonedTimeToUtc(bhoLike.EndTime, storeIanaTZ!);

    const bhoPickup: Flipdish.BusinessHoursOverrideBase = {
      StartTime: startTime.toISOString(),
      EndTime: endTime.toISOString(),
      Type: tab === 1 ? 'Open' : dataClose.PreOrderPickup ? 'ClosedAllowPreOrders' : 'Closed',
      DeliveryType: 'Pickup',
    };
    if (bhoLike.Pickup) {
      result.push(bhoPickup);
    }

    const bhoDelivery: Flipdish.BusinessHoursOverrideBase = {
      ...bhoPickup,
      Type: tab === 1 ? 'Open' : dataClose.PreOrderDelivery ? 'ClosedAllowPreOrders' : 'Closed',
      DeliveryType: 'Delivery',
    };
    if (bhoLike.Delivery) {
      result.push(bhoDelivery);
    }

    result.forEach((b) => create(b));
    onSave(result);
  };

  const selectedTimeBeforeCurrent = !!errors.startTime || !!errors.endTime;

  const errorOnTab =
    tab === 1
      ? (!data.Pickup && !data.Delivery) || data.StartTime.getTime() > data.EndTime.getTime()
      : (!dataClose.Pickup && !dataClose.Delivery) ||
        dataClose.StartTime.getTime() > dataClose.EndTime.getTime();

  const isDisabled = selectedTimeBeforeCurrent || errorOnTab;

  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  // #endregion
  return (
    <Dialog open={true} aria-label="Close hour override" onClose={onCancel}>
      <DialogTitle style={{ paddingTop: '2px', paddingRight: 0, paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={(e, idx) => setTab(idx)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.20)' }}
        >
          <Tab
            data-fd="close_the_store"
            label={<Translate id={isSmallScreen ? 'Close' : 'Close_the_store'} />}
            icon={<ClosedIcon />}
          />

          <Tab
            data-fd="open_the_store"
            label={<Translate id={isSmallScreen ? 'Open' : 'Open_the_store'} />}
            icon={<OpenIcon />}
          />
        </Tabs>
      </DialogTitle>

      <DialogContent>
        {/* TODO: Children are null at runtime causing a crash -> https://github.com/mui/material-ui/pull/29028 */}
        {/* <Slide in={tab === 0} direction="right"> */}
        <Form
          storeIanaTZ={storeIanaTZ!}
          storeId={Number(storeId)}
          data={dataClose}
          errors={errors}
          onChange={setDataClose}
          setErrors={setErrors}
          style={{ display: tab === 0 ? 'flex' : 'none' }}
          tab={tab}
          preOrderEnabled={preOrderEnabled}
          preOrderPickup={preOrderPickup}
          preOrderDelivery={preOrderDelivery}
        />
        {/* </Slide> */}

        {/* <Slide in={tab === 1} direction="left"> */}
        <Form
          storeIanaTZ={storeIanaTZ!}
          storeId={Number(storeId)}
          errors={errors}
          setErrors={setErrors}
          data={data}
          onChange={setData}
          style={{ display: tab === 1 ? 'flex' : 'none' }}
        />
        {/* </Slide>ƒ */}
      </DialogContent>

      <DialogActions>
        <Button data-fd="cancel_store_btn" onClick={onCancel} color="primary">
          <Translate id="Cancel" />
        </Button>
        <Button
          data-fd="close_and_open_store_btn"
          onClick={onSaveClick}
          color="primary"
          disabled={isDisabled}
        >
          <Translate id={tab === 0 ? 'Close_store' : 'Open_store'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
});
