import React, { useEffect, useState } from 'react';

import { orderBy as sort } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import { mapStoreIdtoName, renderPeriodString } from '../../helpers';
import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  GetTableDataOrdersByStore,
  getTableDataOrdersByStore,
} from '../../OrderReport.actions';
import { orderByStoreMeta } from '../../OrdersReport/OrdersMetaData';
import { getStoreHeadersList } from '../../OrdersReport/selectors';
import { OrdersTableData, PeriodPresetEnum, PeriodProps } from '../../types';
import OrderReportTable from './OrderReportTable';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type Props = {
  currentPeriodFilter: PeriodProps;
  storeIds: number[] | string[];
  deliveryTypes: Array<'delivery' | 'collection'>;
  platforms: AppTypeEnum[];
  periodType: PeriodPresetEnum;
  vouchers: string;
  userTimezone: string;
  currency?: CurrencyEnum;
  languageCode: string;
} & MappedProps &
  MappedDispatch;

const rowsPerPageLocalStorageKey = 'fd-reporting-orders-by-store-rows-per-page';

export const OrdersByStoreTable = (props: Props) => {
  const {
    stores,
    ordersByStoreTable,
    pagination,
    getTableDataOrdersByStore,
    currentPeriodFilter,
    storeIds,
    deliveryTypes,
    platforms,
    periodType,
    AppId,
    vouchers,
    userTimezone,
    currency,
    languageCode,
  } = props;
  const [ref, inView] = useInView({ triggerOnce: true });
  const [tableOrderByStoreData, setTableOrderByStoreData] = useState<
    undefined | OrdersTableData[]
  >();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  useEffect(() => {
    if (inView && ordersByStoreTable) {
      setTableOrderByStoreData(mapStoreIdtoName(ordersByStoreTable, stores));
    }
  }, [ordersByStoreTable, stores, inView]);

  useEffect(() => {
    if (inView && currency) {
      getTableData({
        c: currentPeriodFilter,
        t: periodType,
        s: storeIds,
        d: deliveryTypes,
        p: platforms,
        v: vouchers,
        csv: false,
        pg: page,
        r: rowsPerPage,
        l: languageCode,
      });
    }
  }, [
    page,
    rowsPerPage,
    currentPeriodFilter,
    periodType,
    storeIds,
    deliveryTypes,
    platforms,
    vouchers,
    inView,
    languageCode,
  ]);

  useEffect(() => {
    const rowsPerPage = localStorage.getItem(rowsPerPageLocalStorageKey);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage, 10));
    }
  }, [inView]);

  const getTableData = async (params: {
    c: PeriodProps;
    t: PeriodPresetEnum;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    csv: boolean;
    pg?: number;
    r?: number;
    l?: string;
  }) => {
    try {
      setIsLoading(true);
      const { c, t, s, d, p, v, csv, r, pg, l } = params;
      await getTableDataOrdersByStore({
        Page: pg,
        PageSize: r,
        Period: c,
        PeriodType: t,
        StoreId: s,
        DeliveryType: d,
        Platform: p,
        VoucherCodes: v,
        IANATimezone: userTimezone,
        ExportAsCSV: csv,
        LanguageCode: l,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(new Error(err));
    }
  };

  const downloadCSV = () => {
    getTableData({
      c: currentPeriodFilter,
      t: periodType,
      s: storeIds,
      d: deliveryTypes,
      p: platforms,
      v: vouchers,
      csv: true,
      l: languageCode,
    });
  };

  const onSort = (orderBy, order) => {
    if (orderBy && tableOrderByStoreData !== undefined) {
      const orderedItems = sort(tableOrderByStoreData, orderBy, order);
      setTableOrderByStoreData(orderedItems);
    }
  };

  return (
    <ErrorBoundary identifier="orders-by-store-table">
      <div ref={ref}>
        <OrderReportTable
          pagination={pagination}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          inView={inView}
          title="Orders_by_store"
          subTitle={renderPeriodString(currentPeriodFilter)}
          rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
          data={tableOrderByStoreData}
          metadata={orderByStoreMeta}
          onSort={onSort}
          AppId={AppId}
          loading={isLoading}
          currency={currency}
          languageCode={languageCode}
          downloadCSV={downloadCSV}
        />
      </div>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const stores = getStoreHeadersList(state);
  const { ordersByStoreTable, ordersByStoreTablePagination } = state.reports.orderReports;
  return {
    AppId: state.currentApp.AppId as string,
    translate: getTranslate(state),
    stores,
    ordersByStoreTable,
    pagination: ordersByStoreTablePagination,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getTableDataOrdersByStore: ({
    Page,
    PageSize,
    Period,
    PeriodType,
    StoreId,
    DeliveryType,
    Platform,
    VoucherCodes,
    IANATimezone,
    ExportAsCSV,
    LanguageCode,
  }: GetTableDataOrdersByStore) =>
    dispatch(
      getTableDataOrdersByStore({
        Page,
        PageSize,
        Period,
        PeriodType,
        StoreId,
        DeliveryType,
        Platform,
        VoucherCodes,
        IANATimezone,
        ExportAsCSV,
        LanguageCode,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersByStoreTable);
