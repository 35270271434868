import React, { useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import moment from 'moment';
import * as qs from 'qs';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import GenericTableTitle from '../../../ui/GenericTable/GenericTableTitle';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { PrevPeriodProps } from '../components/Filters/DateRangeSelector';
import { HeatMap } from '../components/Graphs/HeatMap';
import OrderCharts from '../components/OrderCharts';
import OrderOverview from '../components/OrderOverview';
import OrdersByStoreTable from '../components/Tables/OrdersByStoreTable';
import OrdersTable from '../components/Tables/OrdersTable';
import RejectedOrdersByStoreTable from '../components/Tables/RejectedOrdersByStoreTable';
import RejectedOrdersTable from '../components/Tables/RejectedOrdersTable';
import { renderPeriodString } from '../helpers';
import {
  getHeatmapDatapoints,
  getOrderReportDetail,
  getOrderReportOverview,
  getRejectedOrdersByReason,
  getVouchers,
} from '../OrderReport.actions';
import {
  Bounds,
  CountProps,
  DataProps,
  DeliveryZone,
  IDeliveryZone,
  OrderReportDetailFinalData,
  PeriodProps,
  VoucherFilterProps,
  VoucherProps,
} from '../types';
import BusyTimesHeatmap from './BusyTimesHeatmap';
import OrderReportFilters from './OrderReportFilters';
import ReasonOfRejection from './ReasonOfRejection';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type Props = MappedDispatch & MappedProps & RouteComponentProps;

const OrdersReport = (props: Props) => {
  const {
    AppId,
    location,
    translate,
    getOrderReportDetail,
    getOrderReportOverview,
    getRejectedOrdersByReason,
    languageCode,
    getHeatmapDatapoints,
    getVouchers,
    timezone,
  } = props;

  //#region filters state
  const [storeIds, setStoreIdsFilter] = useState<number[] | string[]>([]);
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<PeriodProps>({
    startDate: moment(),
    endDate: moment(),
  });
  const [previousPeriodFilter, setPreviousPeriodFilter] = useState<PrevPeriodProps>({
    startDate: undefined,
    endDate: undefined,
    value: 0,
    url: undefined,
  });
  const [deliveryType, setDeliveryType] = useState<Array<'delivery' | 'collection'>>([]);
  const [platform, setPlatform] = useState<AppTypeEnum[]>([]);
  const [vouchers, setVouchers] = useState<VoucherProps[]>([]);
  const [voucherFilter, setVoucherFilter] = useState<VoucherFilterProps[]>([]);
  const [currency, setCurrency] = useState<CurrencyEnum>();
  const [storeZoneOptions, setStoreZoneOptions] = useState<IDeliveryZone<DeliveryZone[]>>({});
  //#endregion

  //#region periods state

  // Platform, type and payment
  const [orderReportDetail, setOrderReportDetail] = useState<OrderReportDetailFinalData>();

  // Order Overview
  const [currentPeriodOverview, setCurrentPeriodOverview] = useState<DataProps[]>([]);
  const [previousPeriodOverview, setPreviousPeriodOverview] = useState<DataProps[]>([]);

  const [reasonsOfRejection, setReasonsOfRejection] = useState<CountProps | undefined>();
  const [heatmapDatapoints, setHeatmapDatapoints] = useState([]);
  const [heatmapBounds, setHeatmapBounds] = useState<{ bounds: Bounds | null; zoom: number }>({
    bounds: null,
    zoom: 13,
  });
  const [storesBounds, setStoresBounds] = useState<{ bounds: Bounds | null; stores: any[] }>({
    bounds: null,
    stores: [],
  });
  const [selectedZoneIds, setSelectedZoneIds] = useState<number[]>([]);
  //#endregion

  const [loading, setLoading] = useState<boolean | undefined>();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const periodType = qs.parse(location.search, { ignoreQueryPrefix: true }).daterange || 'custom';
  const userTimezone = useMemo(
    () => (timezone.displayTimesInUserLocalTimeZone ? timezone.IanaTimeZone : ''),
    [timezone]
  );

  const getRouteSearch = () => {
    if (location.search !== '') {
      const searchProps = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      return searchProps || {};
    }
    return undefined;
  };

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_reports_orders', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    // We only trigger this api call if the initial query is done
    if (!firstRender && !loading && currency) {
      const currentSearch = getRouteSearch();
      const timer = setTimeout(() => {
        const voucherSearch = voucherFilter.map((v) => `&VoucherCodes=${v.label}`).join('');
        if (!currentSearch?.store && (!storeIds || (Array.isArray(storeIds) && !storeIds.length))) {
          // If no store is selected, we empty the data
          setCurrentPeriodOverview([]);
          setPreviousPeriodOverview([]);
          setOrderReportDetail(undefined);
          setCurrency(undefined);
          setReasonsOfRejection(undefined);
          setHeatmapDatapoints([]);
        } else {
          previousPeriodFilter &&
            getOrderReport(
              currentPeriodFilter,
              previousPeriodFilter,
              storeIds,
              deliveryType,
              platform,
              voucherSearch,
              userTimezone,
              selectedZoneIds
            );
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    return;
  }, [
    currency,
    currentPeriodFilter,
    previousPeriodFilter,
    storeIds,
    deliveryType,
    platform,
    voucherFilter,
    userTimezone,
    heatmapBounds,
    selectedZoneIds,
  ]);

  useEffect(() => {
    // We have a hook only for heatmaps as we selectedZoneIds is set before the new bounds
    // which result in heatmap api querying before the bounds change
    // this hooks does not listen to selectedZoneIds
    const currentSearch = getRouteSearch();
    const timer = setTimeout(() => {
      const voucherSearch = voucherFilter.map((v) => `&VoucherCodes=${v.label}`).join('');
      if (
        !currency ||
        (!currentSearch?.store && (!storeIds || (Array.isArray(storeIds) && !storeIds.length)))
      ) {
        // If no store is selected, we empty the data
        setHeatmapDatapoints([]);
      } else {
        previousPeriodFilter &&
          getHeatmap({
            m: currentPeriodFilter,
            c: previousPeriodFilter,
            t: periodType as any,
            h: heatmapBounds,
            s: storeIds,
            d: deliveryType,
            p: platform,
            v: voucherSearch,
            tz: userTimezone,
          });
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    currency,
    currentPeriodFilter,
    previousPeriodFilter,
    storeIds,
    deliveryType,
    platform,
    voucherFilter,
    userTimezone,
    heatmapBounds,
  ]);

  useEffect(() => {
    if (!previousPeriodFilter || !previousPeriodFilter.startDate) {
      // If we select "None" as period to compare
      setPreviousPeriodOverview([]);
    }
  }, [previousPeriodFilter]);

  useEffect(() => {
    // We change firstRender flag when we get a currency
    if (currency && firstRender) {
      setFirstRender(false);
    }
  }, [currency]);

  //#region API calls
  const getOrderReport = async (
    mainPeriod: PeriodProps,
    comparePeriod: PeriodProps,
    storeIds: number[] | string[],
    deliveryTypes: Array<'delivery' | 'collection'>,
    platforms: AppTypeEnum[],
    vouchers: string,
    userTimezone: string,
    zoneIds: number[]
  ) => {
    try {
      setLoading(true);
      await getReportDetail({
        m: mainPeriod,
        c: comparePeriod,
        t: periodType as any,
        s: storeIds,
        d: deliveryTypes,
        p: platforms,
        v: vouchers,
        tz: userTimezone,
        z: zoneIds,
      });
      await getReportOverview({
        m: mainPeriod,
        c: comparePeriod,
        t: periodType as any,
        s: storeIds,
        d: deliveryTypes,
        p: platforms,
        v: vouchers,
        tz: userTimezone,
        z: zoneIds,
      });
      await getReportRejection({
        m: mainPeriod,
        c: comparePeriod,
        t: periodType as any,
        s: storeIds,
        d: deliveryTypes,
        p: platforms,
        v: vouchers,
        tz: userTimezone,
        z: zoneIds,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(new Error(err));
    }
  };

  // Orders Overview
  const getReportOverview = async (params: {
    m: PeriodProps;
    c: PeriodProps;
    t: string;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    tz: string;
    z: number[];
  }) => {
    try {
      const { m, c, t, s, d, p, v, tz, z } = params;
      const respOverview = await getOrderReportOverview(m, c, t, s, d, p, v, tz, z);
      setCurrentPeriodOverview(respOverview?.current as DataProps[]);
      setPreviousPeriodOverview(respOverview?.previous as DataProps[]);
    } catch (err) {
      console.log(new Error(err));
    }
  };

  // Platform, type and payment
  const getReportDetail = async (params: {
    m: PeriodProps;
    c: PeriodProps;
    t: string;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    tz: string;
    z: number[];
  }) => {
    try {
      const { m, c, t, s, d, p, v, tz, z } = params;
      const resp = await getOrderReportDetail(m, c, t, s, d, p, v, tz, z);
      setOrderReportDetail(resp);
    } catch (err) {
      console.log(new Error(err));
    }
  };

  const getReportRejection = async (params: {
    m: PeriodProps;
    c: PeriodProps;
    t: string;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    tz: string;
    z: number[];
  }) => {
    try {
      const { m, c, t, s, d, p, v, tz, z } = params;
      const respRejection = await getRejectedOrdersByReason(m, c, t, s, d, p, v, tz, z);
      setReasonsOfRejection(respRejection);
    } catch (err) {
      console.log(new Error(err));
    }
  };

  const getHeatmap = async (params: {
    m: PeriodProps;
    c: PeriodProps;
    t: string;
    h: { bounds: Bounds | null; zoom: number };
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    tz: string;
  }) => {
    try {
      const { m, c, t, h, s, d, p, v, tz } = params;
      if (h.bounds) {
        // we only query if we have heatmap bounds
        const respHeatmap = await getHeatmapDatapoints(m, c, t, h.bounds, h.zoom, s, d, p, v, tz);
        setHeatmapDatapoints(respHeatmap);
      }
    } catch (err) {
      console.log(new Error(err));
    }
  };

  const getVoucherSearch = async (search: string) => {
    try {
      const respVouchers = await getVouchers(currentPeriodFilter, periodType, storeIds, search);
      setVouchers(respVouchers);
    } catch (err) {
      console.log(new Error(err));
    }
  };
  //#endregion

  //#region handle filters change
  const setStoreFilter = (storeIds: number[]) => {
    setStoreIdsFilter(storeIds);
  };

  const onDateChange = (
    start: moment.Moment,
    end: moment.Moment,
    prevPeriod: PrevPeriodProps | undefined
  ) => {
    setCurrentPeriodFilter({ startDate: start, endDate: end });
    if (prevPeriod) {
      setPreviousPeriodFilter({
        startDate: prevPeriod.startDate,
        endDate: prevPeriod.endDate,
        value: prevPeriod.value,
        url: prevPeriod.url,
      });
    }
  };

  const setBounds = (bounds: Bounds, zoom: number) => {
    setHeatmapBounds({ bounds, zoom });
  };
  //#endregion

  const backToParent = ({ history }) => {
    history.push(`/${AppId}/reports${props.location.search}`);
  };

  const voucherCodes = useMemo(
    () => voucherFilter.map((v) => `&VoucherCodes=${v.label}`).join(''),
    [voucherFilter]
  );

  //#region sections render

  return (
    <PageLayout
      header={
        <OrderReportFilters
          onDateChange={onDateChange}
          setStoreFilter={setStoreFilter}
          setPlatform={setPlatform}
          setDeliveryType={setDeliveryType}
          setVoucherFilter={setVoucherFilter}
          onVoucherSearch={getVoucherSearch}
          vouchers={vouchers}
          setCurrency={setCurrency}
          setStoresBounds={setStoresBounds}
          setStoreZoneOptions={setStoreZoneOptions}
          storeZoneOptions={storeZoneOptions}
          setSelectedZoneIds={setSelectedZoneIds}
          currency={currency}
          languageCode={languageCode}
        />
      }
      title={<Translate id="Orders_report" />}
      documentTitle={'Orders_report'}
      toParent={backToParent}
    >
      <Grid container>
        <Grid item xs={12}>
          <HeatMap
            datapoints={heatmapDatapoints}
            currency={currency}
            languageCode={languageCode}
            setBounds={setBounds}
            storesBounds={storesBounds}
            wlMapCenter={props.wlMapCenter}
            storeZoneOptions={storeZoneOptions}
            setSelectedZoneIds={setSelectedZoneIds}
            selectedZoneIds={selectedZoneIds}
          />
        </Grid>
      </Grid>

      <GenericTableTitle
        title={'Orders_overview'}
        subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
      />
      <PaperContainer fluid>
        <List component="nav" style={{ padding: '0px' }}>
          <OrderOverview
            loading={loading}
            currency={currency}
            languageCode={languageCode}
            currentPeriod={currentPeriodOverview}
            previousPeriod={previousPeriodOverview}
          />
        </List>
      </PaperContainer>
      <GenericTableTitle
        title={'Platform_type_payment'}
        subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
      />
      <ErrorBoundary identifier="orders-report-charts">
        <PaperContainer fluid>
          <List component="nav" style={{ padding: '0px' }}>
            <OrderCharts
              currency={currency}
              currentPeriod={currentPeriodOverview}
              dateString={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
              languageCode={languageCode}
              loading={loading}
              orderReportDetail={orderReportDetail}
              previousPeriod={previousPeriodOverview}
              translate={translate}
            />
          </List>
        </PaperContainer>
      </ErrorBoundary>

      <OrdersByStoreTable
        currentPeriodFilter={currentPeriodFilter}
        storeIds={storeIds}
        deliveryTypes={deliveryType}
        platforms={platform}
        periodType={periodType as any}
        vouchers={voucherCodes}
        userTimezone={userTimezone}
        currency={currency}
        languageCode={languageCode}
      />

      <OrdersTable
        currentPeriodFilter={currentPeriodFilter}
        storeIds={storeIds}
        deliveryTypes={deliveryType}
        platforms={platform}
        periodType={periodType as any}
        vouchers={voucherCodes}
        userTimezone={userTimezone}
        currency={currency}
        languageCode={languageCode}
      />

      <RejectedOrdersTable
        currentPeriodFilter={currentPeriodFilter}
        storeIds={storeIds}
        deliveryTypes={deliveryType}
        platforms={platform}
        periodType={periodType}
        vouchers={voucherCodes}
        userTimezone={userTimezone}
        currency={currency}
        languageCode={languageCode}
      />

      <ReasonOfRejection
        currency={currency}
        currentPeriodFilter={currentPeriodFilter}
        dateString={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
        languageCode={languageCode}
        loading={loading}
        previousPeriodFilter={previousPeriodFilter}
        reasonsOfRejection={reasonsOfRejection}
      />

      <RejectedOrdersByStoreTable
        currentPeriodFilter={currentPeriodFilter}
        storeIds={storeIds}
        deliveryTypes={deliveryType}
        platforms={platform}
        periodType={periodType as any}
        vouchers={voucherCodes}
        userTimezone={userTimezone}
        currency={currency}
        languageCode={languageCode}
      />

      {/* Hidden as this is mocked data for now */}
      <Hidden only={['xs', 'sm', 'md', 'lg', 'xl', 'sm', 'md', 'lg', 'xl']}>
        <GenericTableTitle
          title={'Busy_and_quiet_times'}
          subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
        />
        <ErrorBoundary identifier="orders-report-heatmap">
          <PaperContainer fluid>
            <List component="nav" style={{ padding: '0px' }}>
              <BusyTimesHeatmap />
            </List>
          </PaperContainer>
        </ErrorBoundary>
      </Hidden>
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const dtUtils = dateTimeUtils(state);
  return {
    AppId: state.currentApp.AppId,
    wlMapCenter: state.currentApp.MapCenter,
    languageCode: getActiveLanguage(state.locale),
    translate: getTranslate(state),
    timezone: {
      IanaTimeZone: dtUtils.userIanaTimeZone,
      displayTimesInUserLocalTimeZone: dtUtils.displayTimesInUserLocalTimeZone,
    },
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOrderReportDetail: (m, c, t, s, d, p, v, tz, z) =>
    dispatch(getOrderReportDetail(m, c, t, s, d, p, v, tz, z)),
  getOrderReportOverview: (m, c, t, s, d, p, v, tz, z) =>
    dispatch(getOrderReportOverview(m, c, t, s, d, p, v, tz, z)),
  getRejectedOrdersByReason: (m, c, t, s, d, p, v, tz, z) =>
    dispatch(getRejectedOrdersByReason(m, c, t, s, d, p, v, tz, z)),
  getHeatmapDatapoints: (m, c, t, bounds, zoom, s, d, p, v, tz) =>
    dispatch(getHeatmapDatapoints(m, c, t, bounds, zoom, s, d, p, v, tz)),
  getVouchers: (currentPeriodFilter, periodType, storeIds, search) =>
    dispatch(getVouchers(currentPeriodFilter, periodType, storeIds, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersReport);
