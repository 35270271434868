import React, { MutableRefObject } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Spacer from '../../../ui/Spacer';

type AddNewLinkButtonProps = {
  fdKey: string;
  textId: TranslationId;
  setRef: MutableRefObject<null>;
  onClick: () => void;
};
const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    height: '56px',
    borderRadius: '28px',
    '&:hover': {
      backgroundColor: '#c50036',
    },
  },
}));

export const AddNewCampaignButton = ({ setRef, textId, onClick, fdKey }: AddNewLinkButtonProps) => {
  const classes = useStyles();

  return (
    <Fab
      variant="extended"
      color="secondary"
      data-fd={fdKey}
      component={'button'}
      onClick={onClick}
      ref={setRef}
      className={classes.root}
      style={{
        position: 'fixed',
        right: '24px',
        bottom: '24px',
      }}
    >
      <AddIcon />
      <Spacer size={8} variant="vertical" />
      <Translate id={textId} />
    </Fab>
  );
};
