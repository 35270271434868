import React, { useEffect } from 'react';

import { App } from '@flipdish/api-client-typescript';
import { Field, FieldProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { getSelectedStore } from '../../../selectors/store.selector';
import FormItemLayout from '../../Vouchers/components/FormItemLayout';
import SingleSelect from '../SingleSelect';
import { getAccountDropdownOptions } from './selectors';

const SingleSelectField = (props) => {
  const { loading, accounts, canEdit, attachedBankAccountId, form, suggestions } = props;
  useEffect(() => {
    if (attachedBankAccountId && !isEmpty(accounts)) {
      const account = accounts.find((a) => a.Id === attachedBankAccountId);
      if (account) {
        form.setFieldValue(
          'selectedAccount',
          { label: account.AccountName, value: account.Id },
          false
        );
      }
    }
  }, [accounts]);

  function handleAccountChange(account) {
    form.setFieldValue('selectedAccount', account, false);
  }

  return (
    <SingleSelect
      value={form.values.selectedAccount}
      options={suggestions}
      onChange={handleAccountChange}
      dataFd="Accounts"
      isLoading={loading}
      disabled={!canEdit}
    />
  );
};

const validate = (value) => {
  if (!value) {
    return 'Required';
  }
  return;
};

const StoreBankingSettingsAccountsFormField = (props: Props) => {
  return (
    <FormItemLayout noSpace label={<Translate id="Bank_account" />}>
      <Field name={'selectedAccount'} validate={validate}>
        {(fprops: FieldProps) => <SingleSelectField {...props} {...fprops} />}
      </Field>
    </FormItemLayout>
  );
};

type Props = ReturnType<typeof mapStateToProps> & { dispatch: ThunkDispatch };
function mapStateToProps(state: AppState) {
  const canEdit = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.UpdateBankAccountsAssign.toString()
  );
  return {
    selectedApp: state.currentApp,
    store: getSelectedStore(state),
    accounts: state.banking.accounts,
    suggestions: getAccountDropdownOptions(state),
    attachedBankAccountId: state.stores.bankAccountId,
    canEdit,
  };
}

export default connect(mapStateToProps)(StoreBankingSettingsAccountsFormField);
