import { createSelector } from 'reselect';

import { STORE_LOAD_BY_APP } from '../../constants/store.constants';
import { vouchersConstants } from '../../constants/vouchers.constants';
import { selectDbStores } from '../../selectors/db.selector';
import { createLoadingSelector } from '../../selectors/loading.selector';

const selectStoreSelectionOrdinal = (state: AppState) => state.vouchers.addNew.stores.ordinal;

const selectSuggestionOrdinal = (state: AppState) => state.vouchers.addNew.stores.suggestionOrdinal;

export const getIsStoresLoading = createLoadingSelector([STORE_LOAD_BY_APP]);
export const getIsCreateLoading = createLoadingSelector([vouchersConstants.CREATE_VOUCHER]);

export const getSelectedStores = createSelector(
  [selectStoreSelectionOrdinal, selectDbStores],
  (itemsOrder, stores) => {
    return itemsOrder.map((id) => {
      const s = stores[id];
      if (s) {
        return {
          value: s.StoreId as number,
          label: s.Name as string,
        };
      }

      return {
        value: id,
        label: String(id),
      };
    });
  }
);

export const getSuggestionStores = createSelector(
  [selectSuggestionOrdinal, selectDbStores],
  (itemsOrder, stores) => {
    return itemsOrder.map((id) => {
      const s = stores[id];
      return {
        value: s.StoreId as number,
        label: s.Name as string,
      };
    });
  }
);
