import React, { ReactNode } from 'react';

import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import LinkButton from '../Button/LinkButton';

type PropsStyles = { titlePadding?: 'small' | 'medium' };
const useStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    display: 'block',
    height: 21,
  },
  linkBtn: {
    padding: 0,
    height: 21,
  },
  linkText: {
    color: '#05149e',
  },
  subTitle: {
    width: 191,
    height: 16,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  lwrcase: {
    textTransform: 'capitalize',
  },
  headerContainer: ({ titlePadding }: PropsStyles) => {
    switch (titlePadding) {
      case 'small': {
        return {
          padding: theme.spacing(3, 0, 1.5),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 2, 1.5),
          },
        };
      }
      case 'medium': {
        return {
          padding: theme.spacing(4, 0, 2),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4, 2, 2),
          },
        };
      }
      default:
        return { padding: 0 };
    }
  },
  item: {
    alignItems: 'stretch',
  },
}));

type InnerProps = MappedState;
type OuterProps = {
  fdKey?: string;
  maxGridWidth?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  navTo?: string;
  subTitle?: string;
  title?: TranslationId;
  children?: ReactNode;
  titlePadding?: 'small' | 'medium';
};
type Props = InnerProps & OuterProps & PropsStyles;

const GenericTableTitle = (props: Props) => {
  const {
    currentApp,
    fdKey,
    maxGridWidth = 12,
    navTo,
    subTitle,
    title,
    children,
    titlePadding = 'medium',
  } = props;
  const classes = useStyles({ titlePadding });
  const path = `/${currentApp.AppId}/reports/${navTo}`;

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item xs={maxGridWidth} sm={maxGridWidth} className={classes.item}>
        {title && (
          <>
            {navTo ? (
              <div className={classes.linkWrapper}>
                <LinkButton to={path} fdKey={fdKey ?? ''} classes={{ root: classes.linkBtn }}>
                  <Typography
                    variant="h5"
                    className={classes.linkText}
                    classes={{ root: classes.lwrcase }}
                  >
                    <Translate id={title} />
                  </Typography>
                  <ArrowRightIcon color="primary" fontSize="small" />
                </LinkButton>
              </div>
            ) : (
              <Typography variant="h5">
                <Translate id={title} />
              </Typography>
            )}
          </>
        )}
        {subTitle && (
          <div>
            <Typography variant="caption" className={classes.subTitle}>
              {subTitle}
            </Typography>
          </div>
        )}
      </Grid>
      {children}
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentApp: state.currentApp,
  };
};
export default compose<InnerProps, OuterProps>(connect(mapStateToProps, null))(GenericTableTitle);
