import React, { useEffect, useState } from 'react';

import {
  type Language,
  type OnboardingItemUpdate,
  AppConfigUpdateModel,
} from '@flipdish/api-client-typescript';
import type { GetBrandsByOrgId200Response, Org } from '@flipdish/orgmanagement';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { flagService } from '../../../services/flagService';
import PageLayout from '../../../ui/Layout';
import { getBrandsForOrgKey, getOrgByBrandId, uploadImage } from '../../RMS/organisation.services';
import { setCurrentOrg } from '../../RMS/rms.actions';
import useTutorialStore from '../../Tutorial/hooks/useTutorialStore';
import { TutorialNotifier } from '../../Tutorial/Notifier';
import {
  getSMSRestaurantName,
  setAppConfig,
  setAppConfigLanguages,
  setSMSRestaurantName,
  uploadAppConfigLogo,
} from '../../WebsiteAndApp/actions';
import { getSMSRestaurantNameSelector } from '../../WebsiteAndApp/selectors';
import { getBrandById, getBrandByIdKey, updateBrand } from '../Brands/brands.services';
import GeneralSettingsForm, { type FormValues } from './GeneralSettingsForm';

const CATEGORIES: { [key: string]: AppConfigUpdateModel.ApplicationCategoryEnum } = {
  '0': AppConfigUpdateModel.ApplicationCategoryEnum.Restaurant,
  '1': AppConfigUpdateModel.ApplicationCategoryEnum.Cafe,
  '2': AppConfigUpdateModel.ApplicationCategoryEnum.Convenience,
};

const isLanguagesEqual = (newLanguages: Language[], previousLanguages: Language[]): boolean => {
  if (newLanguages.length !== previousLanguages.length) {
    return false;
  }

  return newLanguages.every((item, index) => {
    const prevItem = previousLanguages[index];

    return prevItem && item.LanguageId === prevItem.LanguageId;
  });
};

const hasChanges = (
  newConfig: AppConfigUpdateModel,
  previousConfig: AppConfigUpdateModel
): boolean => {
  return Object.keys(newConfig).some((key) => {
    const newValue = newConfig[key];
    let previousValue = previousConfig[key];

    if (key === 'ApplicationCategory' && previousValue !== undefined) {
      previousValue = CATEGORIES[previousValue];
    }

    return newValue !== previousValue;
  }, null);
};

type Props = MappedProps & MappedDispatch;

const GeneralSettings = (props: Props) => {
  const {
    getSMSRestaurantName,
    smsRestaurantName,
    currentApp,
    setSMSRestaurantName,
    translate,
    updateConfig,
    setLanguages,
    LogoImageUrl,
    showBrandList,
    currentOrg,
    setCurrentOrg,
    uploadLogo,
  } = props;
  const { MainColor, Name, Languages, ApplicationCategory, IsPanaceaEnabled = false } = currentApp;
  const queryClient = useQueryClient();

  // Cached if navigated from list screen
  const brandCached = queryClient
    .getQueryData<{ data: GetBrandsByOrgId200Response }>([getBrandsForOrgKey, currentOrg?.orgId])
    ?.data?.data?.find((brand) => brand.brandId === currentApp.AppId);

  // Fetch data if page loaded directly
  const { data: brandData } = useQuery({
    queryKey: [getBrandByIdKey, currentApp.AppId, currentOrg?.orgId],
    queryFn: () =>
      getBrandById({ brandId: currentApp.AppId ?? '', orgId: currentOrg?.orgId ?? '' }),
    enabled: showBrandList && !brandCached && !!currentApp.AppId && !!currentOrg?.orgId,
  });

  const brand = brandCached ?? brandData?.data?.data;

  useEffect(() => {
    getSMSRestaurantName();
  }, []);

  const hasUpdatedLogo =
    LogoImageUrl !==
    ('https://flipdish.imgix.net/r6nn7KYuxhnDUUAFAXnTrrvwY.png' ||
      'https://flipdish.imgix.net/gddSky3brWXB4bHWcfax5M130.jpg');
  const hasUpdatedBrandColor = MainColor !== ('fd5a5b' || '000000');
  const storeId = useTutorialStore(currentApp.AppId).data?.StoreId;

  const [updateTutorialLogoTask, setUpdateTutorialLogoTask] = useState<boolean>(hasUpdatedLogo);

  const { data: orgData } = useQuery({
    queryKey: [getOrgByBrandId, currentApp.AppId],
    queryFn: () => getOrgByBrandId(currentApp.AppId || ''),
    enabled: !currentOrg?.orgId && !!currentApp.AppId,
    retry: 1,
  });

  useEffect(() => {
    orgData?.data?.data?.[0] && setCurrentOrg(orgData?.data?.data?.[0]);
  }, [orgData]);

  const saveSettings = async ({
    name,
    SMSRestaurantName,
    languages,
    type,
    webAndAppsButtonColour,
    logo,
    IsPanaceaEnabled,
  }: FormValues & { logo?: File }) => {
    const newLanguages = languages.map((language) => {
      return {
        LanguageId: language.value,
        Name: language.label,
        DisplayOrder: language.displayOrder,
      };
    }) as Language[];

    await setSMSRestaurantName(SMSRestaurantName);

    let logoImageUrl = currentApp.LogoImageUrl;
    if (logo) {
      setUpdateTutorialLogoTask(true);
      if (currentOrg?.orgId) {
        const formDataOrg = new FormData();
        formDataOrg.append('file', logo);
        logoImageUrl = await uploadImage(currentOrg?.orgId, formDataOrg);
      } else {
        const formData = new FormData();
        formData.append('files[]', logo);
        setUpdateTutorialLogoTask(true);
        uploadLogo(formData);
      }
    }

    if (!isLanguagesEqual(newLanguages, currentApp.Languages || [])) {
      await setLanguages(newLanguages);
    }

    const config: AppConfigUpdateModel = {
      Name: name,
      IsPanaceaEnabled,
      ApplicationCategory: type,
      MainColor: webAndAppsButtonColour.slice(1),
      LogoImageUrl: logoImageUrl,
      CountryId: currentApp.CountryId,
    };

    if (hasChanges(config, currentApp)) {
      if (currentOrg?.orgId && currentApp.AppId) {
        await updateBrand({
          brand: {
            name: name,
            logoImageUrl: logoImageUrl,
            brandId: currentApp.AppId,
            countryCode: currentApp.CountryId as any,
          },
          brandId: currentApp.AppId,
          orgId: currentOrg?.orgId,
        });
        queryClient.invalidateQueries({ queryKey: [getBrandsForOrgKey, currentOrg?.orgId] });
      }
      await updateConfig(config);
    }
  };

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'app.*' }}
      documentTitle="Brand_Settings"
      title={translate('Brand_Settings')}
      toParent={showBrandList ? `/${currentApp.AppId}/settings/brands` : undefined}
      strictToParent
    >
      {updateTutorialLogoTask && storeId && (
        <TutorialNotifier
          onboardingItemId={1031}
          status={'Completed' as OnboardingItemUpdate}
          storeId={storeId}
        />
      )}
      {hasUpdatedBrandColor && storeId && (
        <TutorialNotifier
          onboardingItemId={1032}
          status={'Completed' as OnboardingItemUpdate}
          storeId={storeId}
        />
      )}
      <GeneralSettingsForm
        applicationCategory={ApplicationCategory}
        brandName={Name}
        isPanaceaEnabled={IsPanaceaEnabled}
        languages={Languages}
        webAndAppsButtonColour={MainColor}
        smsRestaurantName={smsRestaurantName}
        submit={saveSettings}
        logo={showBrandList ? brand?.logoImageUrl : currentApp.LogoImageUrl}
      />
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setSMSRestaurantName: (smsRestaurantName: string) =>
    dispatch(setSMSRestaurantName(smsRestaurantName)),
  getSMSRestaurantName: () => dispatch(getSMSRestaurantName()),
  updateConfig: (config: AppConfigUpdateModel) => dispatch(setAppConfig(config)),
  setLanguages: (languages: Language[]) => dispatch(setAppConfigLanguages(languages)),
  setCurrentOrg: (org: Org) => dispatch(setCurrentOrg(org)),
  uploadLogo: (data: FormData) => dispatch(uploadAppConfigLogo(data)),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  currentOrg: state.rms.currentOrg,
  translate: getTranslate(state.locale),
  LogoImageUrl: state.currentApp.LogoImageUrl,
  smsRestaurantName: getSMSRestaurantNameSelector(state),
  showBrandList: flagService.isFlagOn(state, 'showBrandList'),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
