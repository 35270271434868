import * as React from 'react';

import * as FlipdishApi from '@flipdish/api-client-typescript';
import Radio from '@mui/material/Radio';
import { type StyledComponentProps, type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import ModifyPermissionLevelSelect from './ModifyPermissionLevelSelect';

type StyleProps = {
  disabled?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  alignStart: {
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
  },
  radio: {
    marginRight: '20px',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  type: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: {
      borderBottom: 'initial',
    },
  },
  typeText: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  descText: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.3px',
  },
}));

type StoreOption = {
  label: string;
  value: number | string;
};

export type InnerProps = {
  classes?: any;
  dataFd: string;
  desc: string;
  disabled?: boolean;
  handlePermissionChange: (permissionLevel: FlipdishApi.TeammateBase.AppAccessLevelEnum) => any;
  handleScroll?: any;
  handleStoresChange: (permissionLevel: FlipdishApi.TeammateBase.AppAccessLevelEnum) => any;
  isLoading?: boolean;
  isNewUser?: boolean;
  onSearchQueryChange?: (eventValue: string) => void;
  permissionLevel: FlipdishApi.TeammateBase.AppAccessLevelEnum;
  secondaryDesc?: string;
  selectedValue: FlipdishApi.TeammateBase.AppAccessLevelEnum | null;
  stores?: StoreOption[] | null;
  storesName?: string;
  suggestions?: StoreOption[];
  theme?: any;
  title: string;
};

export type Props = StyledComponentProps & InnerProps;

const ModifyPermissionLevel = (props: Props) => {
  const {
    dataFd,
    desc,
    disabled,
    handlePermissionChange,
    handleScroll,
    handleStoresChange,
    isLoading,
    isNewUser,
    onSearchQueryChange,
    permissionLevel,
    secondaryDesc,
    selectedValue,
    stores,
    storesName,
    suggestions,
    title,
  } = props;

  const classes = useStyles({ disabled });
  return (
    <div
      className={`${classes.alignStart} ${HORIZONTAL_SPACE_CLASSNAME}`}
      onClick={disabled ? undefined : () => handlePermissionChange(permissionLevel)}
      data-fd={`teammate-permission-${dataFd}`}
    >
      <Radio
        inputProps={{ 'data-fd': `radio-${dataFd}` } as React.InputHTMLAttributes<HTMLInputElement>}
        className={classes.radio}
        checked={selectedValue === permissionLevel}
        disabled={disabled}
        color="secondary"
      />
      <div className={classes.type}>
        <Typography variant="h3" component="h3" className={classes.typeText}>
          {title}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.descText}>
          {desc}
        </Typography>
        {secondaryDesc ? (
          <Typography variant="h4" component="h4" className={classes.descText}>
            {secondaryDesc}
          </Typography>
        ) : null}
        {suggestions && storesName ? (
          <ModifyPermissionLevelSelect
            dataFd={dataFd}
            disabled={disabled}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isLoading={isLoading}
            isNewUser={isNewUser}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={permissionLevel}
            stores={stores}
            storesName={storesName}
            suggestions={suggestions}
          />
        ) : null}
      </div>
    </div>
  );
};

export { ModifyPermissionLevel };
