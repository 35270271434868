import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginTop: -8,
  },
  selectLabel: {
    transform: 'scale(0.75)',
  },
}));

type SelectFieldProps = {
  fieldName: string;
  label?: string;
  optionsEnum: any;
  translate: TranslateFunction;
  tooltip?: string;
  placeholder?: string;
  withoutLayout?: boolean;
};

type Option = { value: string; label: string };

const MuiSelectField = ({
  fieldName,
  label,
  optionsEnum,
  translate,
  tooltip,
  placeholder,
  withoutLayout,
}: SelectFieldProps) => {
  const classes = useStyles();
  const validate = (value: string) => {
    if (!value) {
      return <Translate id="Required" />;
    }
  };

  const translateOptionLabel = (label: string) => {
    switch (label) {
      case String(FdField.FieldTypeEnum.Text):
        return translate('Text');
      case String(FdField.FieldTypeEnum.TextArea):
        return translate('TextArea');
      case String(FdField.FieldTypeEnum.Integer):
        return translate('Integer');
      case String(FdField.FieldTypeEnum.Decimal):
        return translate('Decimal');
      case String(FdField.FieldTypeEnum.DateTime):
        return translate('DateTime');
      case String(FdField.FieldTypeEnum.Date):
        return translate('Date');
      case String(FdField.FieldTypeEnum.Time):
        return translate('Time');
      case String(FdField.FieldTypeEnum.Boolean):
        return translate('Boolean');
      case String(FdField.FieldTypeEnum.Select):
        return translate('Select');
      default:
        return label;
    }
  };

  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { isSubmitting } = form;
        const options: Option[] = Object.keys(optionsEnum).map((option) => ({
          value: option,
          label: translateOptionLabel(option) as string,
        }));

        return (
          <FormItemLayout
            label={label}
            showTooltip={!!tooltip}
            tooltipOptions={{
              fdKey: `appstore_config_tooltip_${fieldName}`,
              labelText: tooltip,
            }}
            mdProportion={withoutLayout ? '12x12' : undefined}
          >
            <div className={classes.selectWrapper}>
              <InputLabel className={classes.selectLabel}>{placeholder}</InputLabel>

              <Select
                {...field}
                variant="standard"
                disabled={isSubmitting}
                data-fd={`app-select-field-${fieldName}`}
                value={field.value || ''}
                fullWidth
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default MuiSelectField;
