import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import ListItemLink from '@fd/ui/List/ListItemLink';
import Spacer from '@fd/ui/Spacer';

import { BankAccountSummaryExtended } from '../banking.selectors';
import { BANKING_URL } from '../constants';
import AccountDetails from './AccountDetails';
import ListItemIconStripe from './ListItemIconStripe';

const styles = () =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
      '&:focus': {
        backgroundColor: '#eaf2ff',
      },
    },
    avatar: {
      minHeight: '60px',
    },
  });

export interface InterfaceAccountListItemProps {
  account: BankAccountSummaryExtended;
  currentAppId: string | undefined;
  isLast: boolean;
  onClick: (account: BankAccountSummaryExtended) => void;
}

type Props = WithStyles<typeof styles> & InterfaceAccountListItemProps;
const AccountListItem = ({ isLast, currentAppId, account, classes, onClick }: Props) => {
  const destinationPath = BANKING_URL.getAccountDetailsUrl(currentAppId, account.Id);

  return (
    <ListItemLink
      alignItems="center"
      button
      className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME}`}
      component={Box}
      disableGutters
      divider={!isLast}
      fdKey={`${account.AccountName}`}
      key={account.Id}
      onClick={() => onClick(account)}
      to={destinationPath}
    >
      <ListItemAvatar className={classes.avatar}>
        <ListItemIconStripe account={account} />
      </ListItemAvatar>
      <AccountDetails account={account} />

      <Hidden mdDown>
        <ListItemSecondaryAction>
          <Spacer size={8}>
            <KeyboardArrowRight color="action" />
          </Spacer>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItemLink>
  );
};

export default compose<Props, InterfaceAccountListItemProps>(withStyles(styles))(AccountListItem);
