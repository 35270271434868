import React from 'react';

import { RestApiErrorResult } from '@flipdish/api-client-typescript';
import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';
import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import { formikValidate } from '../../../helpers/validation';
import { EFieldType } from '../../../models/EFieldType';
import Button from '../../../ui/Button/Button';
import { FkRadioGroup } from '../../../ui/FkRadioGroup/FkRadioGroup';
import Spacer from '../../../ui/Spacer';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';
import * as services from '../settings.service';
import BusinessInformationFields from './fields/BusinessInformationFields';
import PolicyDocuments from './fields/PolicyDocuments';
import GridDivider from './GridDivider';

export type EditTermsAndPolicyDataFormProps = {
  appId: string;
  termsAndPolicyData: TermsAndPolicyData;
  onSubmitSuccess?: (appId: string, data: TermsAndPolicyData) => void;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (data: TermsAndPolicyData) => {
  return {
    ...data,
    UseAdvancedSettings: `${data.UseAdvancedSettings}`,
  };
};

type Props = FormikProps<FormValues> & EditTermsAndPolicyDataFormProps;
const EditTermsAndPolicyDataForm = (props: Props) => {
  return (
    <Form>
      <PreventNavigation when={props.dirty} />

      <Grid container>
        <Grid item xs={12}>
          <FkRadioGroup
            name="UseAdvancedSettings"
            required={true}
            fieldType={EFieldType.Boolean}
            RadioProps={{ color: 'primary' }}
            options={[
              {
                labelId: 'Default_policy_documents',
                value: 'false',
              },
              {
                labelId: 'Custom_policy_documents',
                value: 'true',
              },
            ]}
          />
        </Grid>
        <GridDivider />

        {props.values.UseAdvancedSettings === 'false' ? (
          <BusinessInformationFields />
        ) : (
          <PolicyDocuments />
        )}

        <Grid item xs={12}>
          <Spacer size={16} variant="horizontal">
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  fdKey="btn-bannk-account-create"
                  variant="contained"
                  disabled={props.isSubmitting}
                >
                  <Translate id="Save" />
                </Button>
              </Grid>
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

export default compose<Props, EditTermsAndPolicyDataFormProps>(
  withFormik<Props, FormValues>({
    enableReinitialize: true,
    displayName: 'EditTermsAndPolicyDataForm',
    mapPropsToValues: (props) => {
      return getDefaultFormState(props.termsAndPolicyData);
    },
    handleSubmit: async (values, formikBag) => {
      try {
        const sanitized: TermsAndPolicyData = {
          ...values,
          UseAdvancedSettings: values.UseAdvancedSettings === 'true',
          // reset other form values
          ...(values.UseAdvancedSettings === 'true'
            ? {
                TradingName: undefined,
                CompanyAddress: undefined,
                CompanyOrSoleTraderName: undefined,
                CompanyRegistrationNumber: undefined,
                ContactEmailAddress: undefined,
              }
            : {
                CookiePolicyUrl: undefined,
                PrivacyPolicyUrl: undefined,
                TermsAndConditionsUrl: undefined,
              }),
        };
        if (!sanitized.UseAdvancedSettings) {
          // remove empty strings, backend expects something or nothing
          Object.keys(sanitized).forEach((key) => {
            if (sanitized[key] === '') {
              sanitized[key] = undefined;
            }
          });
        }

        const { Data } = await services.setTermsAndPolicyData(formikBag.props.appId, sanitized);

        if (formikBag.props.onSubmitSuccess) {
          formikBag.props.onSubmitSuccess(formikBag.props.appId, Data);
        }
      } catch (error) {
        if (error && error.response && error.response.body && error.response.body.Errors) {
          const response: RestApiErrorResult = error.response.body;
          const errors: RestApiErrorResult['Errors'] = response.Errors;
          for (const { FieldName, Errors = [] } of errors!) {
            const field = FieldName?.split('.').pop();
            formikBag.setFieldError(field!, Errors[0] || response.Message);
          }
        } else {
          // TODO notification error
        }
        formikBag.setSubmitting(false);
      }
    },
    validate: (values, props) => {
      const errors: { [K in keyof TermsAndPolicyData]: string } = {};
      if (values.UseAdvancedSettings === 'true') {
        // if any of 3 values then all 3 required
        const FIELDS = ['TermsAndConditionsUrl', 'PrivacyPolicyUrl', 'CookiePolicyUrl'];
        const someValues = FIELDS.some((key) => values[key]);
        const allValues = FIELDS.every((key) => values[key]);

        if (someValues && !allValues) {
          FIELDS.forEach((key) => {
            const e = formikValidate.required(values[key]) || validateUrl(values[key]);
            if (e) {
              errors[key] = e;
            }
          });
        } else if (allValues) {
          FIELDS.forEach((key) => {
            const e = validateUrl(values[key]);
            if (e) {
              errors[key] = e;
            }
          });
        }
      }
      return errors;
    },
  })
)(EditTermsAndPolicyDataForm);

function validateUrl(value: string) {
  let valid = false;
  try {
    new URL(value);
    valid = true;
  } catch (error) {}
  if (!valid) {
    return (<Translate id="Uri_not_valid" />) as unknown as string;
  }
  return;
}
