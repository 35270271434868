export default {
  CREATE: `STORE_OPENING_HOUR_OVERRIDES_CREATE`,
  CREATE_REQUEST: `STORE_OPENING_HOUR_OVERRIDES_CREATE_REQUEST`,
  CREATE_SUCCESS: `STORE_OPENING_HOUR_OVERRIDES_CREATE_SUCCESS`,
  CREATE_FAILURE: `STORE_OPENING_HOUR_OVERRIDES_CREATE_FAILURE`,

  REMOVE: `STORE_OPENING_HOUR_OVERRIDES_REMOVE`,
  REMOVE_REQUEST: `STORE_OPENING_HOUR_OVERRIDES_REMOVE_REQUEST`,
  REMOVE_SUCCESS: `STORE_OPENING_HOUR_OVERRIDES_REMOVE_SUCCESS`,
  REMOVE_FAILURE: `STORE_OPENING_HOUR_OVERRIDES_REMOVE_FAILURE`,

  LOAD_ALL: `STORE_OPENING_HOUR_OVERRIDES_LOAD_ALL`,
  LOAD_ALL_REQUEST: `STORE_OPENING_HOUR_OVERRIDES_LOAD_ALL_REQUEST`,
  LOAD_ALL_SUCCESS: `STORE_OPENING_HOUR_OVERRIDES_LOAD_ALL_SUCCESS`,
  LOAD_ALL_FAILURE: `STORE_OPENING_HOUR_OVERRIDES_LOAD_ALL_FAILURE`,

  RESET: `STORE_OPENING_HOUR_OVERRIDES_RESET`
};
