import { EFieldType } from '../EFieldType';
import { TField } from '../TField';

export type TEntitlementFieldNames =
  | 'EntitlementFeatureCode'
  | 'EntitlementFeatureQuantity'
  | 'EntitlementReasonCode'
  | 'EntitlementReasonText';

export const ENTITLEMENT_FIELDS: Record<TEntitlementFieldNames, TField> = {
  ['EntitlementFeatureCode']: {
    fieldName: 'EntitlementFeatureCode',
    required: true,
    fieldType: EFieldType.Text,
  },
  ['EntitlementFeatureQuantity']: {
    fieldName: 'EntitlementFeatureQuantity',
    required: true,
    fieldType: EFieldType.Number,
    min: 1,
  },
  ['EntitlementReasonCode']: {
    fieldName: 'EntitlementReasonCode',
    required: true,
    fieldType: EFieldType.Text,
  },
  ['EntitlementReasonText']: {
    fieldName: 'EntitlementReasonText',
    required: true,
    fieldType: EFieldType.Text,
  },
};
