import React, { ReactNode } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { type Theme } from '@mui/material/styles';
import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { SkeletonLoader } from '../SkeletonLoader';

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    textAlign: 'right',
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
}));

export type InputFieldProps = MuiTextFieldProps & {
  label?: string;
  disableLabelAnimation?: boolean;
  endIcon?: JSX.Element;
  fdKey: string;
  isLoading?: boolean;
  showError?: boolean;
  type?: 'text' | 'search' | 'number';
  variant?: 'outlined' | 'standard';
};

export const InputField = (props: InputFieldProps): JSX.Element => {
  const {
    className,
    endIcon,
    fdKey,
    disableLabelAnimation,
    isLoading = false,
    label = '',
    showError = false,
    type = 'text',
    variant = 'standard',
    ...rest
  } = props;

  const classes = useStyles();
  const inputStyles = clsx(classes.input, className && className);
  const renderEndAdormentElement = (): ReactNode => {
    if (endIcon) {
      return <InputAdornment position="end">{endIcon}</InputAdornment>;
    }
    return null;
  };

  return isLoading ? (
    <SkeletonLoader
      fdKey={`${fdKey}-skeleton-loader`}
      rows={[
        { height: variant === 'outlined' ? '56px' : '78px', width: '100%', className: className },
      ]}
    />
  ) : (
    <MuiTextField
      autoComplete="off"
      className={inputStyles}
      InputProps={{
        inputProps: {
          ...rest.inputProps,
          style: { height: '1.1876em' },
        },

        endAdornment: renderEndAdormentElement(),
      }}
      InputLabelProps={{
        style: { lineHeight: 1 },
        shrink: disableLabelAnimation,
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      fullWidth
      label={label}
      error={showError}
      type={type}
      variant={variant}
      data-fd={fdKey}
      {...rest}
    />
  );
};
