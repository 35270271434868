import React, { useCallback, useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover, { type PopoverOrigin } from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose, setDisplayName } from 'recompose';

import Checkbox from '@fd/ui/Checkbox';

import { storeOpeningHourOverrideActions } from '../../actions';
import { preOrderUpdate } from '../../actions/store.actions';
import { storeOpeningHourSelectors, storeSelectors } from '../../selectors';
import { dateTimeUtils } from '../../selectors/localeDateTime.selector';
import CloseStoreDateTimePickerDialog from './components/CloseStoreDateTimePickerDialog';

const useStyles = makeStyles(() => ({
  btn: {
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
}));

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state, { storeId }: IMenuActionProps) => {
  const storeIanaTZ = storeSelectors.timeZone(state, { storeId });
  const todayOpeningHours = storeOpeningHourSelectors.todayOpeningHours(state, {
    storeId,
  });

  return {
    storeIanaTZ,
    todayOpeningHours,
    loading: todayOpeningHours === undefined,
    dtUtils: dateTimeUtils(state),
  };
};
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch, ownProps: IMenuActionProps) => {
  const { storeId } = ownProps;
  return {
    createDelivery: (bho: Flipdish.BusinessHoursOverrideBase) => {
      dispatch(storeOpeningHourOverrideActions.create(storeId as number, bho));
    },
    createPickup: (bho: Flipdish.BusinessHoursOverrideBase) => {
      dispatch(storeOpeningHourOverrideActions.create(storeId as number, bho));
    },
    updatePreOrderPickup: (
      storeId: number,
      deliveryType: 'Delivery' | 'Pickup',
      preOrderPick: boolean
    ) => dispatch(preOrderUpdate(storeId, deliveryType, preOrderPick)),
    updatePreOrderDelivery: (
      storeId: number,
      deliveryType: 'Delivery' | 'Pickup',
      preOrderDel: boolean
    ) => dispatch(preOrderUpdate(storeId, deliveryType, preOrderDel)),
  };
};

export interface IMenuActionProps {
  storeId: number;
  id: TranslationId;
  deliveryType: Required<Flipdish.BusinessHoursOverride>['DeliveryType'] | 'DeliveryAndPickup';
  disabled?: boolean;
  preOrderEnabled?: boolean;
  preOrderDelivery?: boolean;
  preOrderPickup?: boolean;
}

type Props = IMenuActionProps & MappedState & MappedDispatch;

const QuickCloseMenuAction = compose<Props, IMenuActionProps>(
  setDisplayName('QuickCloseMenuActions'),
  connect(mapStateToProps, mapDispatchToProps)
)((props) => {
  const {
    id,
    loading,
    deliveryType,
    storeIanaTZ,
    createDelivery,
    createPickup,
    dtUtils,
    todayOpeningHours,
    disabled,
    preOrderEnabled,
    preOrderPickup,
    preOrderDelivery,
  } = props;

  // #region pre order settings

  const classes = useStyles();
  const [preOrderPick, setPreOrderPick] = useState<boolean>(false);
  const [preOrderDel, setPreOrderDel] = useState<boolean>(false);

  const handleChange = (
    isEnabled: boolean,
    deliveryType: 'Pickup' | 'Delivery' | 'DeliveryAndPickup'
  ) => {
    switch (deliveryType) {
      case 'Pickup':
        setPreOrderPick(isEnabled);
        break;
      case 'Delivery':
        setPreOrderDel(isEnabled);
        break;
      case 'DeliveryAndPickup':
        setPreOrderPick(isEnabled);
        setPreOrderDel(isEnabled);
        break;
    }
  };
  // #endregion pre order settings

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [showCloseUntilDialog, setShowCloseUntilDialog] = useState(false);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget || event.target);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const onSelectionChange = useCallback(
    (start: Date, end: Date) => {
      const startStr = start.toISOString();
      const endStr = end.toISOString();

      const isDelivery = deliveryType === 'Delivery' || deliveryType === 'DeliveryAndPickup';
      if (isDelivery) {
        createDelivery({
          DeliveryType: 'Delivery',
          Type: preOrderDel ? 'ClosedAllowPreOrders' : 'Closed',
          StartTime: startStr,
          EndTime: endStr,
        });
      }

      const isPickup = deliveryType === 'Pickup' || deliveryType === 'DeliveryAndPickup';
      if (isPickup) {
        createPickup({
          DeliveryType: 'Pickup',
          Type: preOrderPick ? 'ClosedAllowPreOrders' : 'Closed',
          StartTime: startStr,
          EndTime: endStr,
        });
      }

      closeMenu();
    },
    [deliveryType, preOrderDel, preOrderPick]
  );
  const onUntilTomorrowChange = useCallback(() => {
    if (!todayOpeningHours || !todayOpeningHours.Delivery || !todayOpeningHours.Pickup) {
      return;
    }

    const now = new Date();
    const startStr = now.toISOString();

    const isDelivery = deliveryType === 'Delivery' || deliveryType === 'DeliveryAndPickup';
    const deliveryTodayOpeningHour = todayOpeningHours.Delivery;
    if (
      isDelivery &&
      deliveryTodayOpeningHour.end &&
      dtUtils.isAfter(deliveryTodayOpeningHour.end, now)
    ) {
      const endStr = deliveryTodayOpeningHour.end.toISOString();
      createDelivery({
        DeliveryType: 'Delivery',
        Type: preOrderDel ? 'ClosedAllowPreOrders' : 'Closed',
        StartTime: startStr,
        EndTime: endStr,
      });
    }

    const isPickup = deliveryType === 'Pickup' || deliveryType === 'DeliveryAndPickup';
    const pickupTodayOpening = todayOpeningHours.Pickup;
    if (isPickup && pickupTodayOpening.end && dtUtils.isAfter(pickupTodayOpening.end, now)) {
      const endStr = pickupTodayOpening.end.toISOString();
      createPickup({
        DeliveryType: 'Pickup',
        Type: preOrderPick ? 'ClosedAllowPreOrders' : 'Closed',
        StartTime: startStr,
        EndTime: endStr,
      });
    }

    closeMenu();
  }, [deliveryType, dtUtils, todayOpeningHours, preOrderDel, preOrderPick]);

  const onCloseUntilChange = useCallback(
    (closeTime: Date) => {
      const now = new Date();
      const startStr = now.toISOString();
      const endStr = closeTime.toISOString();

      const isDelivery = deliveryType === 'Delivery' || deliveryType === 'DeliveryAndPickup';
      if (isDelivery && dtUtils.isAfter(closeTime, now)) {
        createDelivery({
          DeliveryType: 'Delivery',
          Type: preOrderDel ? 'ClosedAllowPreOrders' : 'Closed',
          StartTime: startStr,
          EndTime: endStr,
        });
      }

      const isPickup = deliveryType === 'Pickup' || deliveryType === 'DeliveryAndPickup';
      if (isPickup && dtUtils.isAfter(closeTime, now)) {
        createPickup({
          DeliveryType: 'Pickup',
          Type: preOrderPick ? 'ClosedAllowPreOrders' : 'Closed',
          StartTime: startStr,
          EndTime: endStr,
        });
      }

      setShowCloseUntilDialog(false);
    },
    [deliveryType, dtUtils, preOrderDel, preOrderPick]
  );

  const onCloseUntilClick = useCallback(() => {
    setAnchorEl(undefined);
    setShowCloseUntilDialog(true);
  }, []);

  const onCloseUntilCancel = useCallback(() => {
    setShowCloseUntilDialog(false);
  }, []);

  const open = Boolean(anchorEl);
  const popOverId = `store_hour_overrides_quick_close-popper_${id}`;
  return (
    <div>
      <Button
        aria-owns={open ? popOverId : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
        fullWidth
        variant="outlined"
        disabled={loading || disabled}
        data-fd={`Openning-hour-overrides-${id}`}
        className={classes.btn}
      >
        <Translate id={id} />
      </Button>
      <Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={closeMenu}
      >
        <MenuList style={{ maxWidth: '250px' }}>
          <MenuItem
            onClick={() => {
              onSelectionChange(new Date(), dtUtils.addMinutes(new Date(), 15));
            }}
            data-fd="hour_overrides_for15minutes"
          >
            <Translate id={'For15minutes'} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onSelectionChange(new Date(), dtUtils.addMinutes(new Date(), 30));
            }}
            data-fd="hour_overrides_for30minutes"
          >
            <Translate id={'For30minutes'} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onSelectionChange(new Date(), dtUtils.addMinutes(new Date(), 45));
            }}
            data-fd="hour_overrides_for45minutes"
          >
            <Translate id={'For45minutes'} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onSelectionChange(new Date(), dtUtils.addMinutes(new Date(), 60));
            }}
            data-fd="hour_overrides_for60minutes"
          >
            <Translate id={'For60minutes'} />
          </MenuItem>
          <MenuItem onClick={onUntilTomorrowChange} data-fd="hour_overrides_until_tomorrow">
            <Translate id={'Until_tomorrow'} />
          </MenuItem>

          <Permissions allowed={[App.AppResourceSetEnum.EditStoresOpeningHoursOverride]}>
            <MenuItem onClick={onCloseUntilClick} data-fd="hour_overrides_other">
              <Translate id={'Other'} />
            </MenuItem>
          </Permissions>

          {preOrderEnabled &&
            ((deliveryType === 'Pickup' && preOrderPickup) ||
              (deliveryType === 'Delivery' && preOrderDelivery)) && (
              <MenuItem data-fd={`allow_preorders_${deliveryType}`}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        dataFd={`allow_preorders_${deliveryType}`}
                        value={`allow_preorders_${deliveryType}`}
                        color="secondary"
                        inputProps={{
                          'aria-label': 'allow preorders',
                        }}
                        checked={
                          deliveryType === 'Pickup'
                            ? preOrderPick
                            : deliveryType === 'Delivery'
                              ? preOrderDel
                              : false
                        }
                        onChange={(e) => {
                          handleChange(e.target.checked, deliveryType);
                        }}
                        style={{ alignSelf: 'baseline' }}
                      />
                    }
                    label={
                      <div
                        style={{
                          position: 'relative',
                          top: '7px',
                          whiteSpace: 'normal',
                        }}
                      >
                        <Translate id={`Allow_preorders`} />
                      </div>
                    }
                  />
                </FormGroup>
              </MenuItem>
            )}

          {false &&
            preOrderEnabled &&
            deliveryType === 'DeliveryAndPickup' &&
            preOrderPickup &&
            preOrderDelivery && (
              <MenuItem data-fd={`allow_preorders_${deliveryType}`}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        dataFd={`allow_preorders_${deliveryType}`}
                        value={`allow_preorders_${deliveryType}`}
                        color="secondary"
                        inputProps={{
                          'aria-label': 'allow preorders',
                        }}
                        checked={preOrderDel}
                        onChange={(e) => {
                          handleChange(e.target.checked, deliveryType as any);
                        }}
                      />
                    }
                    label={<Translate id={'Allow_preorders'} />}
                  />
                </FormGroup>
              </MenuItem>
            )}
        </MenuList>
      </Popover>

      <Permissions allowed={[App.AppResourceSetEnum.EditStoresOpeningHoursOverride]}>
        <CloseStoreDateTimePickerDialog
          open={showCloseUntilDialog}
          onChange={onCloseUntilChange}
          onCancel={onCloseUntilCancel}
          timeZone={storeIanaTZ}
        />
      </Permissions>
    </div>
  );
});
export default QuickCloseMenuAction;
