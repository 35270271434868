import React, { ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';
import { connect } from 'react-redux';

import { useTracking } from '../../../../../services/amplitude/useTracking';
import FeaturePortal from '../../../../Billing/Paywall/components/FeaturePortal';
import { AppStoreAppDetailsLoadingSkeleton } from '../../../components/AppStoreAppDetailsLoadingSkeleton';
import { appStoreService } from '../../../services/appstore.service';

type Props = {
  appstoreAppId: string | undefined;
  isPaid: boolean | undefined;
  children: ReactNode;
};

const PaywallWrapper = ({
  children,
  isPaid,
  appstoreAppId,
  appId,
  countryCode,
}: Props & MappedState) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!isPaid || !appstoreAppId) {
      return;
    }
    trackEvent('Loaded paid App Store app', { appstoreAppId: appstoreAppId });
  }, [isPaid, appstoreAppId]);

  if (isPaid === undefined || !appstoreAppId) {
    return <AppStoreAppDetailsLoadingSkeleton />;
  }

  const getUsage = async () => {
    const result = await appStoreService.getAppStoreAppEntitlements(appId as string, appstoreAppId);
    return {
      curentUsage: result.CurrentUsage ?? 0,
      entitlementQuantity: result.EntitlementQuantity ?? 0,
    };
  };

  if (isPaid) {
    return (
      <>
        <FeaturePortal
          appStoreAppId={appstoreAppId}
          entitlementCode={`appstore.app.${appstoreAppId}`}
          countryCode={countryCode}
          getUsage={getUsage}
        />
        <Box>{children}</Box>
      </>
    );
  }

  return <Box>{children}</Box>;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId!,
    countryCode: currentApp.CountryId!,
  };
};

export default connect(mapStateToProps)(PaywallWrapper);
