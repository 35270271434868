import React, { useEffect, useState } from 'react';

import { History } from 'history';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { permissionsSelector } from '../../../../selectors/permissions.selector';
import { useTracking } from '../../../../services/amplitude/useTracking';
import { getCustomerListTableData } from '../../Customers.actions';
import {
  currencyFilterLoadingSelector,
  customersTableLoadingSelector,
} from '../../Customers.selectors';
import { customersMeta as customersTableColumns } from '../../CustomersMeta';
import { getRouteSearch, setRouteSearch, storeIdOrCurrencyFilter } from '../../helpers';
import { CustomersTableData } from '../../types';
import CustomersDataTable, { CurrencyEnum } from './CustomersDataTable';

const rowsPerPageLocalStorageKey = 'fd-customer-mgmt-list-rows-per-page';
const orderLocalStorageKey = 'fd-customer-mgmt-list-column-order';
const orderByLocalStorageKey = 'fd-customer-mgmt-list-column-order-by';

type InnerProps = MappedProps &
  MappedDispatch & {
    history: History;
  };
type OuterProps = {
  currency?: CurrencyEnum;
  searchFilter?: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  storeIds: number[] | CurrencyEnum;
};
type Props = InnerProps & OuterProps & RouteComponentProps;
const CustomersTable = (props: Props) => {
  const {
    AppId,
    customersTableData,
    customersTablePagination,
    currency,
    currencyFilterLoading,
    customersTableLoading,
    getCustomerListTable,
    hasDownloadCsvPermission,
    history,
    languageCode,
    location,
    searchFilter,
    setSearchFilter,
    storeIds,
  } = props;
  //#region GET PARAMS FROM URL
  const params = getRouteSearch(location);

  const rowsPerPageLS = localStorage.getItem(rowsPerPageLocalStorageKey);
  const rowsAmount = params?.rows
    ? parseInt(params.rows, 10)
    : rowsPerPageLS
      ? parseInt(rowsPerPageLS, 10)
      : 50;

  const orderByLS = localStorage.getItem(orderByLocalStorageKey);
  const orderBy = params?.orderBy ? params.orderBy : orderByLS ? orderByLS : 'lastOrderDate';

  const orderLS = localStorage.getItem(orderLocalStorageKey);
  const order = params?.order ? params.order : orderLS ? (orderLS as 'asc' | 'desc') : 'desc';

  const pageNo = params?.page ? parseInt(params.page, 10) : 0;

  const searchTerm = params?.searchTerm;

  //#endregion

  const [loading, setLoading] = useState<boolean | undefined>();
  const [page, setPage] = useState(pageNo);
  const [rowsPerPage, setRowsPerPage] = useState(rowsAmount);
  const [filterState, setFilterState] = useState({
    searchFilter: searchTerm,
    orderBy,
    order,
  });

  // Amplitude tracking event
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_customers', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    setSearchFilter(searchTerm);
  }, []);

  useEffect(() => {
    const params = {
      rows: rowsPerPage,
      page,
      orderBy: filterState.orderBy,
      order: filterState.order,
      searchTerm: filterState.searchFilter,
    };
    setRouteSearch(history, params);
  }, [rowsPerPage, page, filterState.order, filterState.orderBy, filterState.searchFilter]);

  useEffect(() => {
    if (currency && !loading && storeIds.length) {
      const { order, orderBy, searchFilter } = filterState;
      // storeId will always be a number, e.g. [23417]
      // currency will be a string[], e.g. ["EUR"]
      const storesFilter = storeIdOrCurrencyFilter(storeIds, currency);
      const params = {
        order: order as 'asc' | 'desc',
        orderBy,
        page,
        rowsPerPage,
        searchFilter,
        storeFilter: storesFilter,
      };
      getTableData(params);
    }
  }, [page, rowsPerPage, filterState, currency, storeIds]);

  useEffect(() => {
    if (currency && !loading) {
      setFilterState({ ...filterState, searchFilter });
      // Don't run on first render. If deps change later then we want to setPage back to 0 with our new results
      if (searchFilter) {
        setPage(0);
      }
    }
  }, [currency, searchFilter]);

  const getTableData = async (params: {
    exportAsCsv?: boolean;
    languageCode?: string;
    order: 'asc' | 'desc';
    orderBy: string;
    page: number;
    rowsPerPage: number;
    searchFilter?: string;
    storeFilter?: number[] | string;
  }) => {
    try {
      setLoading(true);
      const {
        exportAsCsv,
        languageCode,
        order,
        orderBy,
        page,
        rowsPerPage,
        searchFilter,
        storeFilter,
      } = params;
      await getCustomerListTable(
        order,
        orderBy,
        page,
        rowsPerPage,
        searchFilter,
        storeFilter,
        languageCode,
        exportAsCsv
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(new Error(err));
    }
  };

  const onRowClick = ({ row }: { row: CustomersTableData }) => {
    if (row.customer?.link) {
      props.history.push(`/${AppId}/customers/${row.customer.link}`);
    }
  };
  const onSort = (orderBy: string, order: 'asc' | 'desc') => {
    if (orderBy) {
      setFilterState({ ...filterState, orderBy, order });
      setPage(page);
    }
  };

  const downloadCSV = async () => {
    if (currency) {
      const storesFilter = storeIdOrCurrencyFilter(storeIds, currency);
      const params = {
        exportAsCsv: true,
        languageCode,
        order: order as 'asc' | 'desc',
        orderBy,
        page,
        rowsPerPage,
        searchFilter,
        storeFilter: storesFilter,
      };
      getTableData(params);
    }
  };

  return (
    <CustomersDataTable
      AppId={AppId}
      columnOrder={filterState.order}
      columnOrderBy={filterState.orderBy}
      currency={currency}
      currencyFilterLoading={currencyFilterLoading}
      currentSearch={params}
      customersTableLoading={customersTableLoading}
      data={customersTableData}
      downloadCSV={hasDownloadCsvPermission ? downloadCSV : undefined}
      languageCode={languageCode}
      loading={loading}
      metadata={customersTableColumns}
      onSort={onSort}
      orderLocalStorageKey={orderLocalStorageKey}
      orderByLocalStorageKey={orderByLocalStorageKey}
      onRowClick={onRowClick}
      page={page}
      pagination={customersTablePagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      title="Customers"
    />
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getCustomerListTable: (
    order: 'asc' | 'desc',
    orderBy: string,
    page: number,
    rowsPerPage: number,
    searchFilter?: string,
    storeFilter?: number[] | string,
    laguageCode?: string,
    exportAsCsv?: boolean
  ) =>
    dispatch(
      getCustomerListTableData(
        order,
        orderBy,
        page,
        rowsPerPage,
        searchFilter,
        storeFilter,
        laguageCode,
        exportAsCsv
      )
    ),
});

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'DownloadCustomerCsvExport',
  ]);

  return (state: AppState) => {
    const { customersTableData, customersTablePagination } = state.customers;

    return {
      AppId: state.currentApp.AppId as string,
      customersTableData,
      customersTableLoading: customersTableLoadingSelector(state),
      customersTablePagination,
      currencyFilterLoading: currencyFilterLoadingSelector(state),
      hasDownloadCsvPermission: getPermissionsSelector(state),
      languageCode: getActiveLanguage(state.locale),
      translate: getTranslate(state),
    };
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToProps)
)(CustomersTable);
