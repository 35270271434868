import React, { useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import moment from 'moment';
import { getTranslate, LocalizeProps } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { menusActions } from '../../../actions/menus.actions';
import {
  closeAllOtherNotifications,
  ERROR_KEY,
  notifyError,
} from '../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../selectors/app.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import { ArchiveMenuDialog } from './ArchiveMenuDialog/ArchiveMenuDialog';
import { MenuOptions } from './MenuOptions';
import { ProductUpdates } from './ProductUpdates/ProductUpdates';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  menuCard: {
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    '&:hover': {
      background: '#eaf2ff',
    },
    cursor: 'pointer',
    height: '100%',
    position: 'relative' as any,
    [breakpoints.down('md')]: {
      boxShadow: 'none',
      borderRadius: '0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.0912)',
    },
  },
  menuCardHeader: {
    paddingLeft: '16px',
    paddingBottom: '12px',
    [breakpoints.down('md')]: {
      paddingLeft: '24px',
    },
    [breakpoints.down('sm')]: {
      paddingLeft: '16px',
    },
  },
  menuCardHeaderAction: {
    marginRight: '-12px!important',
  },
  menuCardHeaderTitle: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
    wordBreak: 'break-all',
  },
  menuCardSubheader: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.4)',
    wordBreak: 'break-all',
  },
  menuCardContent: {
    paddingTop: 0,
    [breakpoints.down('md')]: {
      paddingLeft: '24px',
      paddingRight: '0',
      paddingBottom: '0!important',
    },
    [breakpoints.down('sm')]: {
      paddingLeft: '16px',
    },
  },
  menuCardContentText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  link: {
    textDecoration: 'none',
    position: 'absolute' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  menuCardStoresText: {
    [breakpoints.down('md')]: {
      paddingBottom: '16px',
      paddingRight: '16px',
    },
  },
  menuCardStoresTextLast: {
    [breakpoints.down('md')]: {
      borderBottom: 'none',
    },
  },
}));

export type InnerProps = {
  AppId: string | undefined;
  IsLast: boolean | undefined;
  MenuId: number | undefined;
  ModifiedTime: Date | undefined;
  Name: string | undefined;
  ProductChanges: number;
  StoreNames: string[] | undefined;
};

type Props = InnerProps & MappedState & LocalizeProps & { dispatch: ThunkDispatch };

const MenuCard = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    accessLevel,
    AppId,
    canBulkEditPermission,
    canCreatePermission,
    dispatch,
    IsLast,
    isFlipdishStaff,
    isProductBasedMenusOn,
    MenuId,
    ModifiedTime,
    Name,
    ProductChanges,
    StoreNames,
    translate,
  } = props;
  const classes = useStyles();
  let storesText = '';

  if (StoreNames) {
    storesText = StoreNames.slice(0, 3).join(', ');

    if (StoreNames.length > 3) {
      storesText += ` + ${StoreNames.length - 3} ${translate('more')}`;
    }
  }

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const createDuplicateMenuName = () => {
    if (Name) {
      return `${Name} (${translate('Copy')})`;
    }
    return `${translate('Unnamed')} ${MenuId} (${translate('Copy')})`;
  };

  const duplicateMenu = async () => {
    try {
      const duplicateName = createDuplicateMenuName();
      await dispatch(menusActions.duplicateMenu(MenuId, duplicateName));
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };

  const deleteMenu = () => {
    dispatch(menusActions.deleteMenu(MenuId));
    handleModalClose();
  };

  const renderMenuLinkWithPermissions = () => {
    const isNonFdStaffManagedOwner =
      !isFlipdishStaff && accessLevel === App.AppAccessLevelEnum.ManagedOwner;
    if (!isNonFdStaffManagedOwner && (canCreatePermission || canBulkEditPermission)) {
      return (
        <MenuOptions
          appId={AppId as string}
          menuId={MenuId}
          onArchive={handleModalOpen}
          onDuplicate={duplicateMenu}
        />
      );
    }
    return null;
  };

  return (
    <>
      <ArchiveMenuDialog
        isNotAssignedToStore={!StoreNames || StoreNames!.length === 0}
        onCancel={handleModalClose}
        onConfirm={deleteMenu}
        open={open}
        translate={translate}
      />
      <Card className={classes.menuCard}>
        <Link
          to={'/' + AppId + '/menus/' + MenuId}
          className={classes.link}
          data-fd={`menu-card-${MenuId}`}
        />
        <CardHeader
          className={classes.menuCardHeader}
          title={Name || `${translate('Unnamed')} ${MenuId}`}
          subheader={`${translate('Last_modified')} ${moment(ModifiedTime).fromNow()}`}
          classes={{
            action: classes.menuCardHeaderAction,
            title: classes.menuCardHeaderTitle,
            subheader: classes.menuCardSubheader,
          }}
          action={renderMenuLinkWithPermissions()}
        />
        <CardContent className={classes.menuCardContent}>
          <Typography
            component="p"
            className={clsx(
              classes.menuCardSubheader,
              classes.menuCardContentText,
              classes.menuCardStoresText,
              IsLast ? classes.menuCardStoresTextLast : null
            )}
          >
            {storesText || translate('No_store_associated')}
          </Typography>
        </CardContent>
        {isProductBasedMenusOn && ProductChanges !== 0 && (
          <ProductUpdates
            appId={AppId as string}
            menuId={MenuId}
            numberOfChanges={ProductChanges}
            translate={translate}
          />
        )}
      </Card>
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['FlipdishStaff']);
  return (state: AppState) => {
    const { currentApp, locale, permissions } = state;
    const canCreatePermission = permissions.some(
      (createPerm) => createPerm === App.AppResourceSetEnum.CreateMenu.toString()
    );
    const canBulkEditPermission = permissions.some(
      (bulkEditPerm) =>
        bulkEditPerm === App.AppResourceSetEnum.UpdateMenuItemsHideTemporarily.toString()
    );
    const accessLevel = currentApp.AppAccessLevel;
    return {
      canBulkEditPermission,
      canCreatePermission,
      accessLevel,
      isFlipdishStaff: getPermissionsSelector(state),
      isProductBasedMenusOn: isProductBasedMenusEnabled(state),
      translate: getTranslate(locale),
    };
  };
};

const EnhancedComponent = connect(mapStateToPropsFactory)(MenuCard);
export { EnhancedComponent as MenuCard };
