import { createSelector } from 'reselect';

const menusSelector = (state: AppState) => state.menus.menus || [];

export const getUnassignedMenus = createSelector([menusSelector], (menus) => {
  return menus.filter((menu) => menu.StoreNames === undefined || menu.StoreNames.length === 0);
});

const menuSelector = (state: AppState) => state.menus.menu || [];

export const getIntegrationMetaData = createSelector([menuSelector], (menu) => {
  if (!menu || !menu.MenuSections) {
    return [];
  }
  for (const section of menu.MenuSections) {
    for (const menuItem of section.MenuItems) {
      if (menuItem.Metadata) {
        return menuItem.Metadata;
      }
    }
  }
  return [];
});
