import {
  CampaignsApiFactory,
  LoyaltyCampaignBase,
  RetentionCampaign,
  RetentionCampaignBase,
} from '@flipdish/api-client-typescript';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const api = createApi(CampaignsApiFactory);

export const getAvailableStores = async (appId: string, onlyPublishedStores = false) => {
  try {
    const response = await api.getStoreList(appId, onlyPublishedStores);
    return response.Data;
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const addLoyaltyCampaign = async (appId: string, data: LoyaltyCampaignBase) => {
  try {
    const response = await api.createLoyaltyCampaign(appId, data);
    return response.Data;
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const removeCampaign = async (appId: string, campaignId: number) => {
  try {
    await api.removeCampaign(appId, campaignId);
    return campaignId;
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const updateLoyaltyCampaign = async (
  appId: string,
  campaignId: number,
  data: LoyaltyCampaignBase
) => {
  try {
    const response = await api.updateLoyaltyCampaign(appId, campaignId, data);
    return response.Data;
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const getLoyaltyCampaigns = async (appId: string) => {
  try {
    const response = await api.getLoyaltyCampaignsForApp(appId);
    return response.Data;
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

const MINUTES_IN_DAY = 1440;

const toClientRetentionCamapign = (campaign: RetentionCampaign): RetentionCampaign => {
  return {
    ...campaign,
    NotifyCustomerAfterMinutes: Math.floor(
      (campaign.NotifyCustomerAfterMinutes || 1) / MINUTES_IN_DAY
    ),
  };
};

export const getRetentionCampaigns = async (appId: string) => {
  try {
    const response = await api.getRetentionCampaignsForApp(appId);
    const array = response.Data;
    return array.map((campaign) => {
      return toClientRetentionCamapign(campaign);
    });
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const addRetentionCampaign = async (appId: string, data: RetentionCampaignBase) => {
  const newData: RetentionCampaign = {
    ...data,
    NotifyCustomerAfterMinutes: (data.NotifyCustomerAfterMinutes || 1) * MINUTES_IN_DAY,
  };
  try {
    const response = await api.createRetentionCampaign(appId, newData);
    return toClientRetentionCamapign(response.Data);
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};

export const updateRetentionCampaign = async (
  appId: string,
  campaignId: number,
  data: RetentionCampaignBase
) => {
  const newData: RetentionCampaign = {
    ...data,
    NotifyCustomerAfterMinutes: (data.NotifyCustomerAfterMinutes || 1) * MINUTES_IN_DAY,
  };
  try {
    const response = await api.updateRetentionCampaign(appId, campaignId, newData);
    return toClientRetentionCamapign(response.Data);
  } catch (error) {
    const e = await mapServerError(error);
    throw e;
  }
};
