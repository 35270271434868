import React from 'react';

import { Subscription } from '@flipdish/api-client-typescript';

import Tag from '../../../common/Tag';

export const getStatusTag = (status?: Subscription.StatusEnum, noMargin?: boolean) => {
  switch (status) {
    case Subscription.StatusEnum.Incomplete:
      return (
        <Tag noMargin={noMargin} alignSelf="center" tagName={'Payment_Failed'} showYellowTag />
      );
    case Subscription.StatusEnum.IncompleteExpired:
      return (
        <Tag
          noMargin={noMargin}
          alignSelf="center"
          tagName={'Payment_Failed_Action_Required'}
          showYellowTag
        />
      );
    case Subscription.StatusEnum.Trialing:
      return <Tag noMargin={noMargin} alignSelf="center" tagName={'Trial'} showPurpleTag />;
    case Subscription.StatusEnum.Active:
      return <Tag noMargin={noMargin} alignSelf="center" tagName={'Active'} showGreenTag />;
    case Subscription.StatusEnum.PastDue:
      return <Tag noMargin={noMargin} alignSelf="center" tagName={'Past_Due'} showYellowTag />;
    case Subscription.StatusEnum.Canceled:
      return <Tag noMargin={noMargin} alignSelf="center" tagName={'Cancelled'} />;
    case Subscription.StatusEnum.Unpaid:
      return <Tag noMargin={noMargin} alignSelf="center" tagName={'Unpaid'} showYellowTag />;
    default:
      return null;
  }
};
