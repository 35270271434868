import { FulfillmentStatusTableColumns } from './types';

export const fulfillmentStatusTableMeta: FulfillmentStatusTableColumns[] = [
  {
    columnName: 'StatusName',
    translationId: 'OrderStatus_Table_NameType',
    columnType: 'Subtext',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'Icon',
    translationId: 'OrderStatus_Table_Icon',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 1,
  },
  {
    columnName: 'DisplayName',
    translationId: 'OrderStatus_Table_PublicName',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 2,
  },
  {
    columnName: 'Service',
    translationId: 'OrderStatus_Table_Service',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 3,
  },
  {
    columnName: 'Enabled',
    translationId: 'Enabled',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 4,
  },
  {
    columnName: 'Internal',
    translationId: 'OrderStatus_Table_PublicPrivate',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 5,
  },
  {
    columnName: 'Edit',
    translationId: 'Edit',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 6,
  },
];

export const fulfillmentStatusResponsiveTableMeta: FulfillmentStatusTableColumns[] = [
  {
    columnName: 'StatusName',
    translationId: 'OrderStatus_Table_NameType',
    columnType: 'Subtext',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'Edit',
    translationId: 'Edit',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 1,
  },
];
