import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { gdpr } from '../../helpers/gdpr';
import { notifySaved } from '../../layouts/Notify/actions';
import Button from '../../ui/Button/Button';
import CookiePolicyLink from './CookiePolicyLink';
import CookiePolicyTitle from './CookiePolicyTitle';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    color: theme.palette.text.secondary,
  },
  paperWidthSm: {
    [theme.breakpoints.down('md')]: {
      minWidth: 'calc(100% - 40px)',
    },
  },
  button: {
    margin: `0 ${theme.spacing(1)}`,
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
}));

type InnerProps = {
  handleDone(): void;
  isOpenModal: boolean;
  disableEscape?: boolean;
};
type Props = InnerProps & MappedState & MappedDispatch;

const CookieModalSubtitle: React.FC<
  React.PropsWithChildren<{
    className: string;
    id: TranslationId;
  }>
> = ({ id, className }) => {
  return (
    <Typography className={className} variant="body2">
      <Translate id={id} />
    </Typography>
  );
};

const CookieModal: React.FC<React.PropsWithChildren<Props>> = ({
  handleDone,
  isOpenModal,
  disableEscape,
  gdprState,
  dispatchNotifySaved,
}) => {
  const classes = useStyles();
  const [gdprSelection, setGdprSelection] = useState(gdprState || {});
  const shouldLoadThirdPartyScripts = !location.pathname.includes('/create_password');

  const handleAcceptAllClick = () => {
    const newState = gdpr.getAcceptAllState();
    gdpr.setUserSelection(newState, shouldLoadThirdPartyScripts);

    setTimeout(() => {
      handleDone?.();
      dispatchNotifySaved();
    }, 400);
  };

  const handleChange = (event) => {
    const newState = { ...gdprSelection, [event.target.name]: event.target.checked };
    setGdprSelection(newState);
  };

  const handleDoneClick = () => {
    gdpr.setUserSelection(gdprSelection, shouldLoadThirdPartyScripts);

    handleDone?.();
    dispatchNotifySaved();
  };
  const handleOnEscapeKeyDown = (_: KeyboardEvent) => {
    if (!disableEscape) {
      handleDone();
    }
  };

  const dialogClasses = {
    paperWidthSm: classes.paperWidthSm,
  };

  const setReduxGdprToPreviousSelectionOrDefault = () => {
    // handles case in which user closes modal and has no gdpr cookie set previously
    const preferences = gdpr.getUserSelectionOrDefault();
    gdpr.setUserSelection(preferences);
    setGdprSelection(preferences);
  };

  const onClose = (event: any, reason: string) => {
    if (reason === 'handleEscapeKeyDown') {
      handleOnEscapeKeyDown(event);
    } else {
      handleDone?.();
      setReduxGdprToPreviousSelectionOrDefault();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-describedby="cookie-dialog-description"
      aria-labelledby="cookie-dialog-title"
      classes={dialogClasses}
      fullWidth={true}
      open={isOpenModal}
    >
      <DialogTitle>
        <Typography variant="h5" component="span">
          <Translate id="Cookie_settings" />
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box pb={2}>
          <CookiePolicyLink id="Cookie_Settings_Subtitle" />
        </Box>

        <Box py={0}>
          <CookiePolicyTitle
            checked={gdprSelection?.strictly}
            disabled={true}
            id="Strictly_necessary_cookies"
            name="strictly"
            onChange={handleChange}
          />
          <Box py={0}>
            <CookieModalSubtitle
              className={classes.body}
              id="Strictly_necessary_cookies_subtitle"
            />
          </Box>
          <Box className={classes.divider}>
            <Divider variant="fullWidth" />
          </Box>

          <CookiePolicyTitle
            checked={gdprSelection?.functional}
            id="Functional_cookies"
            name="functional"
            onChange={handleChange}
          />
          <Box py={0}>
            <CookieModalSubtitle className={classes.body} id="Functional_cookies_subtitle" />
          </Box>

          <Box className={classes.divider}>
            <Divider variant="fullWidth" />
          </Box>
          <CookiePolicyTitle
            checked={gdprSelection?.performance}
            id="Performance_cookies"
            name="performance"
            onChange={handleChange}
          />

          <Box py={0}>
            <CookieModalSubtitle className={classes.body} id="Performance_cookies_subtitle_1" />
            <CookieModalSubtitle className={classes.body} id="Performance_cookies_subtitle_2" />
            <CookieModalSubtitle className={classes.body} id="Performance_cookies_subtitle_3" />
            <CookiePolicyLink id="Performance_cookies_subtitle_4" />
          </Box>

          <Box className={classes.divider}>
            <Divider variant="fullWidth" />
          </Box>
          <CookiePolicyTitle
            checked={gdprSelection?.advertising}
            id="Advertising_cookies"
            name="advertising"
            onChange={handleChange}
          />
          <Box py={0}>
            <CookieModalSubtitle className={classes.body} id="Advertising_cookies_body1" />
            <CookieModalSubtitle className={classes.body} id="Advertising_cookies_body2" />
            <CookiePolicyLink id="Advertising_cookies_body3" />
          </Box>
        </Box>
        <Box className={classes.divider}>
          <Divider variant="fullWidth" />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box py={2}>
          <Button
            className={classes.button}
            color="primary"
            fdKey="cookie_policy_cancel"
            onClick={handleDoneClick}
            variant="contained"
          >
            <Translate id="Done" />
          </Button>
          <Button
            onClick={handleAcceptAllClick}
            color="primary"
            variant="contained"
            className={classes.button}
            fdKey="cookie_policy_save"
          >
            <Translate id="Accept_All" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  const { account } = state;
  return { gdprState: { ...account?.gdpr, strictly: true } };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    dispatchNotifySaved: () => dispatch(notifySaved()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieModal);
