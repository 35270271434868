import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Tag from '@fd/ui/atoms/Tag';
import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../../components/FormItemLayout';

const validate = (value: number) => {
  if (value <= 0) {
    return 'Value_must_be_greater_than_0';
  }
  return;
};

const MaxDiscountAmountField = () => (
  <Field name={'MaxDiscountAmount'} validate={validate}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout
          description={<Translate id="Max_discount_amount_description" />}
          label={
            <>
              <Translate id="Max_discount_amount" />
              <Tag label="New" color="green" />
            </>
          }
        >
          <TextField
            {...field}
            autoComplete="off"
            disabled={isSubmitting}
            error={showError}
            fdKey="max-discount-amount"
            helperText={
              showError ? (
                <Translate id={fieldError as 'Value_must_be_greater_than_0'} />
              ) : undefined
            }
            minWidth={200}
            inputProps={{
              min: 0,
            }}
            type="number"
            variant="outlined"
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default MaxDiscountAmountField;
