/**
 * Converts a color from CSS hex format to CSS rgb/rgba format.
 *
 * @param {string} color - Hex color, i.e. #nnnnnn
 * @param {number} alpha - Alpha, i.e. 0.2
 * @returns {string} A CSS rgb color string
 */
export default function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function hexToRGBObject(h: string) {
  let r = '0';
  let g = '0';
  let b = '0';

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return {
    r: Number(r),
    g: Number(g),
    b: Number(b),
  };
}
