import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, Typography } from '@fd/ui/atoms';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

export type DeleteDeliveryZoneDialogProps = {
  open: boolean;
  zoneNumber: number;
  onCancel: () => void;
  onConfirm: () => void;
  translate: TranslateFunction;
};

export const DeleteDeliveryZoneDialog = (props: DeleteDeliveryZoneDialogProps): JSX.Element => {
  const { open, onCancel, onConfirm, translate, zoneNumber } = props;

  const classes = useStyles();

  return (
    <Dialog
      title={translate('Delete_zone') + '?'}
      titleDataFd="delete-zone-title"
      onClose={onCancel}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel-delete-zone"
          fdKey="cancel-delete-zone"
          onClick={onCancel}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button autoFocus key="confirm-delete-zone" fdKey="confirm-delete-zone" onClick={onConfirm}>
          <Translate id="Delete" />
        </Button>,
      ]}
    >
      <Typography className={classes.dialogText} variant="body2">
        <Translate>
          {(translate) =>
            (translate('Delete_zone_description') as string).replace('{index}', String(zoneNumber))
          }
        </Translate>
      </Typography>
    </Dialog>
  );
};
