import React, { ChangeEvent } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { type FieldProps, Field } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import useRenderValidText from '../../../../custom-hooks/useRenderValidText';
import { formikValidate } from '../../../../helpers/validation';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import TextField from '../../../../ui/TextField/TextField';
import { icon } from '../../../WebsiteAndApp/helpers';

const validateName = (value: string) => {
  return formikValidate.brandName(value);
};

type InnerProps = MappedProps;
type OuterProps = {};
type Props = InnerProps & OuterProps;
const BrandNameField = ({ translate }: Props) => {
  return (
    <FormItemLayout label={<Translate id="Brand_name" />}>
      <Field name="name" validate={validateName}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          const helperText = useRenderValidText({
            fieldError,
            showError,
            touched: !!touched[field.name],
            value: field.value,
          });

          return (
            <TextField
              fdKey="brand_name"
              fullWidth
              style={{ paddingRight: 16 }}
              value={field.value}
              error={showError}
              placeholder={translate('Enter_name') as string}
              disabled={isSubmitting}
              helperText={helperText}
              FormHelperTextProps={{
                style: { textAlign: 'right' },
              }}
              inputProps={{ style: { paddingTop: 0, height: '1.1876em' }, maxLength: 30 }}
              InputProps={{
                endAdornment: icon(
                  showError,
                  touched[field.name] as boolean,
                  field.value[field.name]
                ),
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                form.setFieldValue(field.name, e.target.value)
              }
            />
          );
        }}
      </Field>
      <FormHelperText>{translate('max_chars', { count: 30 })}</FormHelperText>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});
export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(BrandNameField);
