import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import FullWidthContainer from '../../../ui/Layout/FullWidthContainer';
import ListItemLink from '../../../ui/List/ListItemLink';
import Spacer from '../../../ui/Spacer';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  verticalPadding: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  zeroPadding: {
    padding: '0px',
  },
  row: {
    cursor: 'pointer',

    [breakpoints.up('md')]: {
      paddingLeft: '0 !important',
    },
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  icon: {
    minWidth: 'auto',
    marginRight: -12,
  },
}));

type LinkItemProps = {
  title: TranslationId;
  classes: any;
  url: string;
  fdKey: string;
};

const LinkItem: React.FC<React.PropsWithChildren<LinkItemProps>> = (props: LinkItemProps) => {
  const { url, fdKey, title, classes } = props;

  return (
    <>
      <ListItemLink
        to={url}
        fdKey={fdKey}
        disableGutters
        className={`${classes.row} ${HORIZONTAL_SPACE_CLASSNAME}`}
      >
        <ListItemText primary={<Translate id={title} />} className={classes.verticalPadding} />
        <ListItemIcon className={classes.icon}>
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        </ListItemIcon>
      </ListItemLink>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

type Props = {
  url: string;
};

const OAuthAppCardLinks: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { url } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} container>
      <Grid item xs container direction="column">
        <Grid item>
          <FullWidthContainer>
            <List className={classes.zeroPadding}>
              <Spacer size={16} variant="horizontal">
                <Divider />
                <LinkItem
                  title={'Redirect_uris'}
                  classes={classes}
                  url={`${url}/redirect_uris`}
                  fdKey={'oauth-redirect-uris-link'}
                />

                <LinkItem
                  title={'Authorization_tokens'}
                  classes={classes}
                  url={`${url}/authorization_tokens`}
                  fdKey={'oauth-auth-tokens-link'}
                />

                <LinkItem
                  title={'Webhooks'}
                  classes={classes}
                  url={`${url}/webhooks`}
                  fdKey={'oauth-webhooks-link'}
                />
              </Spacer>
            </List>
          </FullWidthContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OAuthAppCardLinks;
