import React, { useMemo, useState } from 'react';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOnIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import GridContainer from '../../../ui/Layout/GridContainer';
import { copyToClipboard } from '../../../ui/utils';

const useStyles = makeStyles((theme: Theme) => ({
  headline: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  info: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    height: '24px',
    display: 'inline-flex',
  },
  infoPwd: {
    letterSpacing: 3.5,
  },
  row: {
    display: 'flex',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type InnerProps = ReturnType<typeof mapStateToProps>;

type OuterProps = {
  oauthApp: any;
  onCopy(name: string);
};

type Props = InnerProps & OuterProps;

const OAuthAppCardInfo: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const classes = useStyles();
  const { onCopy, oauthApp, translate } = props;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const appIdTitle = translate('Oauth_app_id') as string;
  const ownerUserIdTitle = translate('Owner_user_id') as string;
  const maskedValue = useMemo(() => {
    if (oauthApp.secretKey) {
      return [...Array(oauthApp.secretKey.length)].map(() => '•').join('');
    }

    return '';
  }, [oauthApp.secretKey]);

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Typography variant="h6" component="h6" className={classes.headline}>
          {appIdTitle}
        </Typography>

        <span className={classes.info}>{oauthApp.OauthAppId}</span>
        <InputBase type="hidden" value={oauthApp.OauthAppId || ''} id="adornment-client-id" />
        <IconButton
          aria-label="Copy client id"
          onClick={(e) => {
            copyToClipboard(e, 'adornment-client-id');
            onCopy(appIdTitle);
          }}
        >
          <CopyIcon fontSize={'small'} className={classes.icon} />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Typography variant="h6" component="h6" className={classes.headline}>
          {ownerUserIdTitle}
        </Typography>
        <span className={classes.info}>{oauthApp.OwnerUserId}</span>
        <InputBase id="adornment-user-id" type="hidden" value={oauthApp.OwnerUserId || ''} />
        <IconButton
          aria-label="Copy user id"
          onClick={(e) => {
            copyToClipboard(e, 'adornment-user-id');
            onCopy(ownerUserIdTitle);
          }}
        >
          <CopyIcon fontSize={'small'} className={classes.icon} />
        </IconButton>
      </Grid>
      <Grid item xs className={classes.gridItem}>
        <Grid item xs container>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6" className={classes.headline}>
              {translate('Secret_key')}
            </Typography>
            <span className={`${classes.info} ${!isPasswordVisible ? classes.infoPwd : ''}`}>
              {isPasswordVisible ? oauthApp.secretKey : maskedValue}
            </span>
            <InputBase
              id="adornment-password"
              type="hidden"
              value={oauthApp.secretKey ? oauthApp.secretKey : ''}
            />
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <VisibilityOnIcon fontSize={'small'} className={classes.icon} />
              ) : (
                <VisibilityOffIcon fontSize={'small'} className={classes.icon} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

const mapStateToProps = ({ locale }) => ({
  translate: getTranslate(locale),
});

export default connect(mapStateToProps)(OAuthAppCardInfo);
