import {
  CLOSE_OTHER_NOTIFICATIONS,
  DEQUEUE_CLEAN_CLOSE_NOTIFICATION,
  DEQUEUE_NOTIFICATION,
  ENQUEUE_CLOSE_NOTIFICATION,
  ENQUEUE_NOTIFICATION,
  EnqueueNotifyProps,
  ERROR_KEY,
  INFO_KEY,
  LOADING_KEY,
  SAVED_KEY,
  SAVING_KEY,
} from './actions';

type DefaultState = {
  queue: EnqueueNotifyProps[];
  closingQueue: Array<string | number>;
};

const defaultState: DefaultState = {
  queue: [],
  closingQueue: [],
};

const allNotifications = [SAVED_KEY, SAVING_KEY, LOADING_KEY, ERROR_KEY, INFO_KEY];

const notificationReducer = (state = defaultState, action): DefaultState => {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        queue: [
          ...state.queue,
          {
            ...action.payload,
          },
        ],
      };

    case DEQUEUE_NOTIFICATION:
      return {
        ...state,
        queue: state.queue.filter((notification) => notification.key !== action.payload.key),
      };

    case ENQUEUE_CLOSE_NOTIFICATION:
      return {
        ...state,
        closingQueue: [...state.closingQueue, action.payload.key],
      };

    case DEQUEUE_CLEAN_CLOSE_NOTIFICATION:
      return {
        ...state,
        closingQueue: state.closingQueue.filter((key) => key !== action.payload.key),
      };

    case CLOSE_OTHER_NOTIFICATIONS:
      return {
        ...state,
        closingQueue: allNotifications.filter((key) => key !== action.payload.key),
      };

    default:
      return state;
  }
};

export default notificationReducer;
