import React from 'react';

import MuiCheckbox, { type CheckboxProps } from '@mui/material/Checkbox';

type Props = CheckboxProps & { dataFd?: string };

const Checkbox = ({ dataFd, ...props }: Props) => {
  const inputProps = { ...props.inputProps, 'data-fd': dataFd };

  return <MuiCheckbox {...props} inputProps={inputProps} />;
};
export default Checkbox;
