import { BusinessHoursPeriodBase, StoresApiFactory } from '@flipdish/api-client-typescript';

import { DeliveryType } from '../components/OpeningHours/types';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const storesApi = createApi(StoresApiFactory);

// #region loadByDeliveryType
export type LoadByDeliveryTypeProps = {
  storeId: number;
  deliveryType: DeliveryType;
};
export async function loadByDeliveryType({ storeId, deliveryType }: LoadByDeliveryTypeProps) {
  try {
    const incomingMessage = await storesApi.getBusinessHours(storeId, deliveryType);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

export function update(
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriod: BusinessHoursPeriodBase
) {
  return storesApi
    .setBusinessHours(storeId, deliveryType, businessHoursPeriod)
    .then((response) => {
      const result = response.Data;
      return Promise.resolve(result);
    })
    .catch((msgOrError) => {
      Promise.resolve(mapServerError(msgOrError)).then((err) => {
        throw err;
      });
      // if (msgOrError instanceof Error) {
      //   throw msgOrError;
      // }
      // // TODO error parser
      // if (msgOrError && msgOrError.response && msgOrError.response.body) {
      //   const message = msgOrError.response.body.Message;
      //   const fieldsErrors = msgOrError.response.body.Errors;
      //   const error = new ServerSubmissionError(message, fieldsErrors);
      //   throw error;
      // }
    });
}
