import React, { useEffect, useMemo, useState } from 'react';

import Search from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import { Translate } from 'react-localize-redux';

import StoreByCurrencyFilter from '@fd/ui/Filter/StoreByCurrencyFilter';

import FiltersContainer from '../../../../ui/Layout/FiltersContainer';
import { getRouteSearch } from '../../helpers';
import { CurrencyEnum } from '../Tables/CustomersDataTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.54)',
    },
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type InnerProps = {};
type OuterProps = {
  currency?: CurrencyEnum;
  location: any;
  searchFilter?: string;
  setCurrency: React.Dispatch<React.SetStateAction<CurrencyEnum | undefined>>;
  setSearchFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStoreIdsFilter: React.Dispatch<React.SetStateAction<number[] | CurrencyEnum>>;
  storeIds?: number[] | CurrencyEnum;
};
type Props = InnerProps & OuterProps;

const CustomerListFilters = ({
  currency,
  location,
  searchFilter,
  setCurrency,
  setSearchFilter,
  setStoreIdsFilter,
  storeIds,
}: Props) => {
  const classes = useStyles();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const Filter = () => {
    return (
      <Translate>
        {(translate) => (
          <TextField
            type="search"
            autoFocus={!!searchFilter}
            classes={{ root: classes.root }}
            defaultValue={searchFilter}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-fd': 'customers-search' }}
            label={translate('Search') as string}
            onChange={(e) => {
              onSearchQueryChange(e.target.value);
            }}
            placeholder={translate('Type_customer_name_phone_etc') as string}
            variant="outlined"
          />
        )}
      </Translate>
    );
  };

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setSearchFilter(query);
      }, 1000),
    []
  );

  useEffect(() => {
    if (currency && firstRender) {
      setFirstRender(false);
    }
  }, [currency]);

  useEffect(() => {
    if (!firstRender && currency) {
      const currentSearch = getRouteSearch(location);
      // If no store is selected, we empty the data
      if (
        currentSearch &&
        !currentSearch.store &&
        (!storeIds || (Array.isArray(storeIds) && !storeIds.length))
      ) {
        setCurrency(undefined);
      }
    }
  }, [currency, storeIds]);

  const setStoreFilter = (storeIds: number[]) => {
    setStoreIdsFilter(storeIds);
  };

  const selectStore = (stores) => {
    setStoreFilter(stores.map((store) => store.value));
    if (stores.length) {
      setCurrency(stores[0].currency);
    }
  };

  const setInitialStores = (stores) => {
    if (stores.length) {
      setCurrency(stores[0].currency);
    }
  };

  const setInitialCurrency = (currency) => {
    setCurrency(currency);
  };

  return (
    <FiltersContainer>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <Filter />
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <StoreByCurrencyFilter
          onSelectStore={selectStore}
          setInitialStores={setInitialStores}
          setInitialCurrency={setInitialCurrency}
          isReporting={false}
        />
      </Grid>
    </FiltersContainer>
  );
};

export default CustomerListFilters;
