import React, { type ChangeEvent } from 'react';

import { type FieldProps, Field } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import TextField from '@fd/ui/TextField/TextField';

type Props = {
  label: TranslationId;
  name: string;
  validator?: (value: string) => JSX.Element | undefined | string;
  fdKey: string;
  placeholder?: TranslationId;
};

const BasicConfigField = ({
  fdKey,
  label,
  name,
  translate,
  validator,
  placeholder,
}: Props & MappedProps) => {
  return (
    <FormItemLayout label={<Translate id={label} />}>
      <Field name={name} validate={validator}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          return (
            <TextField
              value={field.value}
              fdKey={fdKey}
              autoComplete="off"
              fullWidth
              style={{ paddingRight: 16 }}
              placeholder={placeholder ? (translate(placeholder) as string) : ''}
              disabled={isSubmitting}
              error={showError}
              helperText={showError ? fieldError : ''}
              FormHelperTextProps={{
                style: { textAlign: 'right' },
              }}
              inputProps={{
                style: {
                  paddingTop: 0,
                  height: '1.1876em',
                },
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(field.name, e.target.value);
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});
export default connect(mapStateToProps)(BasicConfigField);
