import { PreOrderConfig } from '@flipdish/api-client-typescript';
import { createReducer } from 'redux-act';

import * as actions from './actions';

export type PreOrderSettingsStore = {
  deliverySettings: PreOrderConfig | null | false;
  collectionSettings: PreOrderConfig | null | false;
  loading: boolean;
  error: string | null;
};

const initialState: PreOrderSettingsStore = {
  deliverySettings: null,
  collectionSettings: null,
  loading: false,
  error: null,
};

const preOrderSettingsReducer = createReducer({}, initialState);

//#region COLLECTION
preOrderSettingsReducer.on(actions.getPreOrderCollectionSettingsRequest, (state, payload) => ({
  ...state,
  error: null,
  loading: true,
  deliverySettings: null,
  collectionSettings: null,
}));
preOrderSettingsReducer.on(actions.updatePreOrderCollectionSettingsRequest, (state, payload) => ({
  ...state,
  error: null,
  loading: true,
}));
preOrderSettingsReducer.on(
  actions.getPreOrderCollectionSettingsRequestFailure,
  (state, payload) => ({
    ...state,
    error: payload,
    loading: false,
  })
);
preOrderSettingsReducer.on(
  actions.updatePreOrderCollectionSettingsRequestFailure,
  (state, payload) => ({
    ...state,
    error: payload,
    loading: false,
  })
);

preOrderSettingsReducer.on(
  actions.getPreOrderCollectionSettingsRequestSuccess,
  (state, payload) => ({
    ...state,
    collectionSettings: payload,
    error: null,
    loading: false,
  })
);
preOrderSettingsReducer.on(
  actions.updatePreOrderCollectionSettingsRequestSuccess,
  (state, payload) => ({
    ...state,
    collectionSettings: payload,
    error: null,
    loading: false,
  })
);
//#endregion

//#region DELIVERY
preOrderSettingsReducer.on(actions.getPreOrderDeliverySettingsRequest, (state, payload) => ({
  ...state,
  error: null,
  loading: true,
  deliverySettings: null,
  collectionSettings: null,
}));
preOrderSettingsReducer.on(actions.updatePreOrderDeliverySettingsRequest, (state, payload) => ({
  ...state,
  error: null,
  loading: true,
}));
preOrderSettingsReducer.on(actions.getPreOrderDeliverySettingsRequestFailure, (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
}));
preOrderSettingsReducer.on(
  actions.updatePreOrderDeliverySettingsRequestFailure,
  (state, payload) => ({
    ...state,
    error: payload,
    loading: false,
  })
);

preOrderSettingsReducer.on(actions.getPreOrderDeliverySettingsRequestSuccess, (state, payload) => ({
  ...state,
  deliverySettings: payload,
  error: null,
  loading: false,
}));
preOrderSettingsReducer.on(
  actions.updatePreOrderDeliverySettingsRequestSuccess,
  (state, payload) => ({
    ...state,
    deliverySettings: payload,
    error: null,
    loading: false,
  })
);
//#endregion

export { preOrderSettingsReducer };
