import { LoyaltyCampaign, RetentionCampaign, StoreListItem } from '@flipdish/api-client-typescript';

import { createAsyncAction, dispatchAsync } from '../../actions/utils';
import { LoyaltyCampaignEvents, RetentionCampaignEvents } from '../../signalr/hub.actions';
import * as campaignService from './services';

const ROOT_ACTION_NAME = 'CAMPAIGN';

export const campaignActions = {
  createRetainCampaignActions: createAsyncAction<RetentionCampaign>(
    `${ROOT_ACTION_NAME}:CREATE_RETAIN_CAMPAIGN`
  ),
  modifyRetainCampaignActions: createAsyncAction<RetentionCampaign>(
    `${ROOT_ACTION_NAME}:MODIFY_RETAIN_CAMPAIGN`
  ),
  removeRetainCampaignActions: createAsyncAction<number>(
    `${ROOT_ACTION_NAME}:REMOVE_RETAIN_CAMPAIGN`
  ),
  getCampaignsActions: createAsyncAction<(LoyaltyCampaign[] | RetentionCampaign[])[]>(
    `${ROOT_ACTION_NAME}:GET_CAMPAIGNES`
  ),
  createLoyaltyCampaignActions: createAsyncAction<LoyaltyCampaign>(
    `${ROOT_ACTION_NAME}:CREATE_LOYALTY_CAMPAIGN`
  ),
  modifyLoyaltyCampaignActions: createAsyncAction<LoyaltyCampaign>(
    `${ROOT_ACTION_NAME}:MODIFY_LOYALTY_CAMPAIGN`
  ),
  removeLoyaltyCampaignActions: createAsyncAction<number>(
    `${ROOT_ACTION_NAME}:REMOVE_LOYALTY_CAMPAIGN`
  ),
  getStoresListActions: createAsyncAction<StoreListItem[]>(`${ROOT_ACTION_NAME}:GET_STORES_LIST`),
};

const getCampaigns = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(dispatch, campaignActions.getCampaignsActions, async () => {
      return await Promise.all([
        campaignService.getLoyaltyCampaigns(appId),
        campaignService.getRetentionCampaigns(appId),
      ]);
    });
  };
};

const createLoyaltyCampaign = (data) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.createLoyaltyCampaignActions,
      async () => {
        return await campaignService.addLoyaltyCampaign(appId, data);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const modifyLoyaltyCampaign = (campaignId: number, data) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.modifyLoyaltyCampaignActions,
      async () => {
        return await campaignService.updateLoyaltyCampaign(appId, campaignId, data);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const removeLoyaltyCampaign = (campaignId: number) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.removeLoyaltyCampaignActions,
      async () => {
        return await campaignService.removeCampaign(appId, campaignId);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const createRetainCampaign = (data: RetentionCampaign) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.createRetainCampaignActions,
      async () => {
        return await campaignService.addRetentionCampaign(appId, data);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const modifyRetainCampaign = (campaignId: number, data) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.modifyRetainCampaignActions,
      async () => {
        return await campaignService.updateRetentionCampaign(appId, campaignId, data);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const removeRetainCampaign = (campaignId: number) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      campaignActions.removeRetainCampaignActions,
      async () => {
        return await campaignService.removeCampaign(appId, campaignId);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

const getStoresForCampaigns = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(dispatch, campaignActions.getStoresListActions, async () => {
      return await campaignService.getAvailableStores(appId);
    });
  };
};

const unSubscribeCampaignUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    Object.values(LoyaltyCampaignEvents.Unsubscribe).map(dispatch);
    Object.values(RetentionCampaignEvents.Unsubscribe).map(dispatch);
  };
};

const subscribeCampaignUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    Object.values(LoyaltyCampaignEvents.Subscribe).map(dispatch);
    Object.values(RetentionCampaignEvents.Subscribe).map(dispatch);
  };
};

export const campaignActionsSignalR = {
  unSubscribeCampaignUpdates,
  subscribeCampaignUpdates,
};

export const campaignActionsAsync = {
  createRetainCampaign,
  modifyRetainCampaign,
  removeRetainCampaign,
  removeLoyaltyCampaign,
  modifyLoyaltyCampaign,
  createLoyaltyCampaign,
  getCampaigns,
  getStoresForCampaigns,
};
