import React from 'react';

import { Voucher } from '@flipdish/api-client-typescript';
import { type FieldProps, Field } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import Select, { OptionType as ChannelOption } from '@fd/ui/Select/Select';

import FormItemLayout from '../FormItemLayout';

type Props = {
  enabledChannels?: Voucher.ChannelRestrictionsEnum[];
  isDisabled?: boolean;
  translate: TranslateFunction;
};

const ChannelField = (props: Props) => {
  const { enabledChannels = [], isDisabled = false, translate } = props;

  const getLabel = (channel: Voucher.ChannelRestrictionsEnum): string => {
    const labelMap: { [key in Voucher.ChannelRestrictionsEnum]: TranslationId } = {
      [Voucher.ChannelRestrictionsEnum.Android]: 'Android',
      [Voucher.ChannelRestrictionsEnum.Ios]: 'Ios',
      [Voucher.ChannelRestrictionsEnum.Kiosk]: 'Kiosk',
      [Voucher.ChannelRestrictionsEnum.Web]: 'Web',
      [Voucher.ChannelRestrictionsEnum.Pos]: 'Pos',
    };

    return translate(labelMap[channel]) as string;
  };

  const getOptions = () => {
    const allChannels: Voucher.ChannelRestrictionsEnum[] = [
      Voucher.ChannelRestrictionsEnum.Android,
      Voucher.ChannelRestrictionsEnum.Ios,
      Voucher.ChannelRestrictionsEnum.Kiosk,
      // Voucher.ChannelRestrictionsEnum.Pos, // POS is not supported yet
      Voucher.ChannelRestrictionsEnum.Web,
    ];
    const channels = enabledChannels.length ? enabledChannels : allChannels;
    const channelOptions: ChannelOption[] = [];

    channels.forEach((channel) => {
      channelOptions.push({
        label: getLabel(channel),
        value: channel,
      });
    });

    return channelOptions;
  };

  return (
    <Field name={'ChannelRestrictions'}>
      {({ field, form }: FieldProps) => {
        const isChannelRestrictionsEmpty = form.values.ChannelRestrictions.length === 0;

        const getValues = (): ChannelOption[] => {
          const allChannelsValue = {
            isNonClearable: true,
            label: translate('All_channels') as string,
            value: 'All',
          };

          const values = form.values.ChannelRestrictions?.map(
            (channel: Voucher.ChannelRestrictionsEnum) => ({
              label: getLabel(channel),
              value: channel,
            })
          );

          return isChannelRestrictionsEmpty ? [allChannelsValue] : values;
        };

        const handleOnChange = (values: ChannelOption[]) => {
          const valuesWithoutAll = values.filter((value) => value.value !== 'All');
          form.setFieldValue(
            'ChannelRestrictions',
            valuesWithoutAll.map((value) => value.value)
          );
        };

        return (
          <FormItemLayout
            description={<Translate id="VoucherChannel_description" />}
            label={<Translate id="Channels" />}
          >
            <Select
              {...field}
              dataFd={'channel-restrictions'}
              fullWidth
              isClearable={!isChannelRestrictionsEmpty}
              isDisabled={isDisabled || form.isSubmitting}
              isMulti
              onChange={handleOnChange}
              options={getOptions()}
              placeholder=""
              TextFieldProps={{
                fdKey: 'channel-restrictions-dropdown',
              }}
              value={getValues()}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default ChannelField;
