import { DeliveryZone } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { createOrmSelector } from './selector';

export const createDeliveryZonesSelector = (storeId: number | string) =>
  createOrmSelector<DeliveryZone[]>((session) => {
    const store = session.FlipdishStore.withId(storeId as string);
    return store != null && store.DeliveryZones.count() ? store.DeliveryZones.toRefArray() : [];
  });

export const createDeliveryZoneByIdSelector = (deliveryZoneId: number | string) =>
  createOrmSelector<DeliveryZone>((session) => {
    const deliveryZone = session.StoreDeliveryZone.withId(deliveryZoneId as string);
    return deliveryZone ? deliveryZone.ref : undefined;
  });

export const createDeliveryZoneIdsSelector = (storeId: number) => {
  const zonesSelector = createDeliveryZonesSelector(storeId);

  return createSelector([zonesSelector], (zones): number[] => {
    return zones.map((zone) => zone.Id as number);
  });
};
