/**
 * DO NOT USE - sets routes in state (overrides what ever is there)
 * @private
 */
export const setRoutes = (routes: (RoutePropsExtended | RedirectPropsExtended)[]) => ({
  type: '@@router/SET_ROUTES',
  payload: routes,
});

/**
 * Registers routes
 *
 * @example
 * // registering private route
 * registerRoutes([
 *  {
 *      name: 'MyAwesomeHiddenUniqueRouteName',
 *      group: RouteType.PRIVATE,
 *      permissions: ['FlipdishStaff'],
 *      component: MyAwesomeComponent
 *  },
 * ]);
 *
 * // registering redirect route
 * registerRoutes([
 *  {
 *      name: 'MyAwesomeHiddenUniqueRouteName',
 *      group: RouteType.PRIVATE,
 *      to: '/another/route',
 *      from: '/this/route',
 *  },
 * ]);
 */
const registerRoutes = (routes: (RoutePropsExtended | RedirectPropsExtended)[]) => ({
  type: '@@router/REGISTER_ROUTES',
  payload: routes,
});

/**
 * Removes (unregister) route from state
 * @param {string[]} payload Route names
 */
const removeRoutes = (routes: { name: string; group: RouteGroup }[]) => ({
  type: '@@router/REMOVE_ROUTES',
  payload: routes,
});

export const routingActions = {
  registerRoutes,
  removeRoutes,
};
