import React, { ChangeEvent } from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import useRenderValidText from '../../../../custom-hooks/useRenderValidText';
import { formikValidate } from '../../../../helpers/validation';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import TextField from '../../../../ui/TextField/TextField';
import { icon } from '../../helpers';

const validateNameField = (value: string) => {
  if (value) {
    return formikValidate.googleAnalyticsCode(value);
  }
  return;
};

const tooltipOptions = {
  fdKey: 'Tooltip-Google-analytics-code',
  titleId: 'Google_analytics_code' as TranslationId,
  messageId: 'Google_analytics_tooltip' as TranslationId,
};

const GoogleAnalyticsCodeField = () => {
  return (
    <FormItemLayout
      label={<Translate id="Google_analytics_code" />}
      showTooltip={true}
      tooltipOptions={tooltipOptions}
    >
      <Field name={'GoogleAnalyticsCode'} validate={validateNameField}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          const helperText = useRenderValidText({
            fieldError,
            showError,
            touched: !!touched[field.name],
            value: field.value,
          });

          return (
            <TextField
              value={field.value}
              fdKey={'Google_Analytics_Code'}
              autoComplete="off"
              fullWidth
              style={{ paddingRight: 16 }}
              placeholder={'UA-00000-0 / G-0000000'}
              disabled={isSubmitting}
              error={showError}
              helperText={helperText}
              FormHelperTextProps={{
                children: ' ', // reserves line height for error message
                style: { textAlign: 'right' },
              }}
              inputProps={{ style: { paddingTop: 0 } }}
              InputProps={{
                endAdornment: icon(
                  showError,
                  touched[field.name] as boolean,
                  field.value[field.name]
                ),
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(field.name, e.target.value);
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

export default GoogleAnalyticsCodeField;
