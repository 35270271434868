import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { flagService } from '../../services/flagService';
import Vouchers from '.';
import AddNewVoucher from './AddNewVoucher';
import FreeDeliveryFee from './FreeDeliveryFee';
import Lump from './LumpDiscount';
import MenuItem from './MenuItemDiscount';
import Percentage from './PercentageDiscount';
import { VoucherDetails } from './VoucherDetails';

const { AppResourceSetEnum } = App;

type Props = RouteComponentProps & MappedProps & MappedDispatch;

const VoucherRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    canEdit,
    isFreeDeliveryFeeVoucherEnabled, // TODO - remove when freeDeliveryFeeVoucher is fully released
    isMenuItemVoucherEnabled, // TODO - remove when menuItemVoucher is fully released
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="voucher">
      <Switch>
        {canEdit && (
          <Route
            path={`${path}/new`}
            render={(p) => (
              <Switch>
                <Route exact path={`${p.match.path}/lump`} component={Lump} />
                <Route exact path={`${p.match.path}/percentage`} component={Percentage} />
                {isMenuItemVoucherEnabled && (
                  <Route exact path={`${p.match.path}/menuItem`} component={MenuItem} />
                )}
                {isFreeDeliveryFeeVoucherEnabled && (
                  <Route exact path={`${p.match.path}/freeDelivery`} component={FreeDeliveryFee} />
                )}
                <Route exact path={`${p.match.path}`} component={AddNewVoucher} />

                <Redirect to={`${p.match.path}`} />
              </Switch>
            )}
          />
        )}

        <Route
          path={`${path}/:voucherId(\\d+)`}
          render={(p) => (
            <Switch>
              {canEdit && <Route exact path={`${p.match.path}/lump`} component={Lump} />}
              {canEdit && (
                <Route exact path={`${p.match.path}/percentage`} component={Percentage} />
              )}
              {canEdit && <Route exact path={`${p.match.path}/menuItem`} component={MenuItem} />}
              {canEdit && (
                <Route exact path={`${p.match.path}/freeDelivery`} component={FreeDeliveryFee} />
              )}
              <Route exact path={`${p.match.path}`} component={VoucherDetails} />

              <Redirect to={`${p.match.path}`} />
            </Switch>
          )}
        />

        <Route exact path={`${path}`} component={Vouchers} />

        <Redirect to={`${path}`} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { permissions } = state;
  const canView = checkVisibility(permissions, [AppResourceSetEnum.ViewVouchers], []);
  const canEdit = checkVisibility(permissions, [AppResourceSetEnum.EditVouchers], []);

  return {
    canEdit,
    canView,
    isFreeDeliveryFeeVoucherEnabled: flagService.isFlagOn(state, 'freeDeliveryFeeVoucher'), // TODO - remove when freeDeliveryFeeVoucher is fully released
    isMenuItemVoucherEnabled: flagService.isFlagOn(state, 'menuItemVoucher'), // TODO - remove when menuItemVoucher is fully released
  };
}
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  // TODO: hookup signalr events (if any)
});

export { VoucherRoutes };
export default compose<Props, RouteComponentProps>(connect(mapStateToProps, mapDispatchToProps))(
  VoucherRoutes
);
