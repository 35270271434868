import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Switch from '@fd/ui/Switch/Switch';

import FormItemLayout from '../FormItemLayout';

const AutomaticallyApplyField = ({ disabled = false }) => (
  <Field name={'IsAutomaticallyApplied'}>
    {({ field, form: { isSubmitting, setFieldValue } }: FieldProps) => {
      return (
        <FormItemLayout
          label={<Translate id="Automatically_apply" />}
          description={<Translate id="Automatically_apply_description" />}
        >
          <Switch
            name={field.name}
            value={field.value}
            checked={field.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('TakesPriority', e.target.checked);
              setFieldValue('IsAutomaticallyApplied', e.target.checked);
            }}
            fdKey={'Automatically_apply'}
            disabled={isSubmitting || disabled}
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default AutomaticallyApplyField;
