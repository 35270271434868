import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { Button } from '../../../../ui/Button';
import { LoadingButton } from '../../../../ui/LoadingButton';
import { bankingActionsAsync } from '../banking.actions';
import { BankAccountSummaryExtended, getAccountDetailsUrlFactory } from '../banking.selectors';
import { InterfaceAccountListItemProps } from './AccountListItem';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '480px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },
  dialogHeader: {
    paddingTop: '28px',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4px',
    },
  },
  dialogActions: {
    padding: '30px 0 16px',
  },
}));

type InnerProps = MappedProps & MappedDispatch & RouteComponentProps;
type OuterProps = {
  setDialogOpen: (state: boolean) => void;
  isDialogOpen: boolean;
  account: BankAccountSummaryExtended;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = () => {
  return {
    businessType: 'Company',
  };
};
type Props = InnerProps & OuterProps & FormikProps<FormValues>;

function AddBusinessTypeModal({
  isDialogOpen,
  setDialogOpen,
  account,
  submitBusinessType,
  detailsUrl,
  history,
}: Props) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleOkClick = () => {
    submitBusinessType(value).then(() => {
      history.push(detailsUrl as string);
    });
  };

  const GetAccountNumberOrIBAN =
    account && account.Iban && account.Iban.slice(account.Iban.length - 4);
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogHeader}>
        <Translate id="Choose_account_type" />
      </DialogTitle>

      <DialogContent key="dialog-content">
        <Box mb={2}>
          <Typography variant="subtitle1" color="textSecondary">
            <Translate
              id="We_need_this_information_to_verify_your_account_ending"
              data={{ AccountNumberOrIBAN: GetAccountNumberOrIBAN as string }}
            />
          </Typography>
        </Box>
        <Form>
          <RadioGroup
            aria-label="Reason for Rejection"
            name="rejectionReason"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              style={{ fontSize: '14px' }}
              value="Company"
              control={<Radio data-fd="Company" color="secondary" />}
              label={<Translate id="Company" />}
            />
            <FormControlLabel
              style={{ fontSize: '14px' }}
              value="Individual"
              control={<Radio data-fd="Individual_sole_proprietorship" color="secondary" />}
              label={<Translate id="Individual_sole_proprietorship" />}
            />
          </RadioGroup>
          <DialogActions key="actions" className={classes.dialogActions}>
            <Hidden smDown>
              <Button fdKey={`cancel`} onClick={() => setDialogOpen(false)} color="primary">
                <Translate id="Cancel" />
              </Button>
              <LoadingButton
                color="primary"
                disabled={!value}
                fdKey={'Confirm_choice'}
                type="button"
                variant="contained"
                onClick={handleOkClick}
              >
                <Translate id="Confirm_choice" />
              </LoadingButton>
            </Hidden>
            <Hidden smUp>
              <Grid container direction="column">
                <Grid item>
                  <LoadingButton
                    color="primary"
                    fullWidth
                    disabled={!value}
                    fdKey={'Confirm_choice'}
                    type="button"
                    variant="contained"
                    onClick={handleOkClick}
                  >
                    <Translate id="Confirm_choice" />
                  </LoadingButton>
                </Grid>
                <Box mt={2}>
                  <Grid item>
                    <Button
                      fullWidth
                      fdKey={`cancel`}
                      onClick={() => setDialogOpen(false)}
                      color="primary"
                    >
                      <Translate id="Cancel" />
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Hidden>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
function mapStateToPropsFactory(state, props: InterfaceAccountListItemProps) {
  const getAccountDetailsUrl = getAccountDetailsUrlFactory(props.account.Id as number);
  return (state: AppState) => {
    const detailsUrl = getAccountDetailsUrl(state);
    return {
      detailsUrl,
    };
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: OuterProps) => {
  return {
    submitBusinessType: (businessType) => {
      return dispatch(
        bankingActionsAsync.setBankAccountBussinesTypeAsync({
          BankAccountId: ownProps.account.Id as number,
          BusinessType: businessType,
        })
      );
    },
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToProps as any),
  withFormik<Props, FormValues>({
    displayName: 'AddBusinessTypeModal',
    mapPropsToValues: () => {
      return getDefaultFormState();
    },
    handleSubmit: (values, formikBag) => {
      const { businessType } = values;
      formikBag.props
        .submitBusinessType(businessType)
        .then((resp) => {
          formikBag.resetForm();
          formikBag.setSubmitting(false);
          formikBag.props.setDialogOpen(false);
        })
        .catch((err) => {
          formikBag.setSubmitting(false);
        });
    },
  })
)(AddBusinessTypeModal);
