import { actionChannel, call, fork, put, select, take } from 'redux-saga/effects';
import { routingActions, setRoutes } from '../actions/routing.actions';

function* registerRoutes(action: ReturnType<typeof routingActions.registerRoutes>) {
  const state: AppState = yield select();
  const { routes } = state.routing;

  const uniqueRoutes = Object.values<RoutePropsExtended | RedirectPropsExtended>({
    ...routes.reduce((agg, r) => {
      agg[r.name] = r;
      return agg;
    }, {}),
    ...action.payload.reduce((agg, r) => {
      agg[r.name] = r;
      return agg;
    }, {}),
  });

  yield put(setRoutes(uniqueRoutes));
}

function* removeRoutes(action: ReturnType<typeof routingActions.removeRoutes>) {
  const state: AppState = yield select();
  const { routes } = state.routing;
  const toRemove = action.payload;
  yield put(
    setRoutes(routes.filter((r) => !toRemove.some((t) => t.name === r.name && t.group === r.group)))
  );
}

/**
 * Queues Register and Remove routes actions and process them 1 by 1
 * fyi, not using async actions (for ex. thunk action) to avoid overwriting state if 2 of actions run inside same time scope
 */
export function* routingRegisterFlow() {
  try {
    const chan = yield actionChannel([
      routingActions.registerRoutes.toString(),
      routingActions.removeRoutes.toString(),
    ]);
    while (true) {
      const action = yield take(chan);
      if (action.type === routingActions.registerRoutes.toString()) {
        yield call(registerRoutes, action);
      } else {
        yield call(removeRoutes, action);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* routingSaga() {
  yield fork(routingRegisterFlow);
}
