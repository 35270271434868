import React, { useEffect } from 'react';

import { FulfillmentStatusConfigurationItem } from '@flipdish/api-client-typescript';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import { FkRadioGroup } from '@fd/ui/FkRadioGroup/FkRadioGroup';
import FkSelect from '@fd/ui/FkSelect/FkSelect';
import { FkSwitch } from '@fd/ui/FkSwitch/FkSwitch';
import { FormikInputField, FormSection } from '@fd/ui/molecules';
import { OptionType } from '@fd/ui/Select/Select';

import { formikValidate } from '../../../helpers/validation';
import { EFieldType } from '../../../models/EFieldType';
import { TranslateFunction } from '../../../overrides/react-localize-redux';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import { DefaultNextStatusSelect } from './DefaultNextStatus';

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = ({ state }) => {
  return {
    ...state,
    Enabled: state.Enabled,
    Internal: String(state.Internal),
    OrderTypes: state.OrderTypes || [],
    PublicDescription: state.PublicDescription || '',
    StatusId: state.StatusId || '',
    StatusName: state.StatusName || '',
    NextStatuses: state.NextStatuses,
  };
};
type InnerProps = {};
type OuterProps = {
  updateTitle: (title: string) => void;
  state: FulfillmentStatusConfigurationItem;
  translate: TranslateFunction;
  submit: (values: FormValues) => void;
  states?: FulfillmentStatusConfigurationItem[];
};
type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 30,
    paddingBottom: 24,
  },
  form: {
    marginBottom: 10,
  },
  field: {
    margin: '8px 0',
  },
  select: {
    margin: '16px 0',
  },
  fieldDescription: {
    whiteSpace: 'pre-wrap',
    marginTop: 4,
    marginBottom: 24,
    color: theme.palette.text.secondary,
  },
}));

const EditFulfillmentConfigurationStateForm = ({
  errors,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  translate,
  updateTitle,
  values,
  states,
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles();

  useEffect(() => {
    // filter next statuses based on orderType
    const filteredNextStatus = values.NextStatuses?.filter((status) => {
      const mappedState = states?.find((state) => state?.StatusId === status);
      return values.OrderTypes?.some((type) => {
        return mappedState?.OrderTypes?.includes(type);
      });
    });

    setFieldTouched('NextStatuses', true, false);
    setFieldValue('NextStatuses', filteredNextStatus, true);
  }, [values.OrderTypes]);

  const handleTitleChange = (event) => {
    const { value } = event.target;
    updateTitle(value);
  };

  const handleDefaultNextStatusChange = (updatedStatuses) => {
    setFieldValue('DefaultNextStatus', updatedStatuses);
  };

  const validateStatusName = (value: string): string | undefined => {
    let error;
    if (!value) {
      error = translate('Required');
    } else if (value.length > 200) {
      error = translate('Max_fieldname_length_exceeded', { fieldName: 'Name', max: 200 });
    }
    return error;
  };

  const validateSlug = (value: string): string | undefined => {
    let error;
    if (!value) {
      error = translate('Required');
    } else if (!/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF-]+$/.test(value)) {
      error = translate('Invalid_format');
    }
    return error;
  };

  const hasErrors = Object.keys(errors).length > 0;
  const stateOptions: OptionType[] | any[] =
    states
      ?.filter((state) => {
        // remove itself and non matching order type statuses
        return (
          state?.StatusId !== values?.StatusId &&
          state?.OrderTypes?.some((type) => values.OrderTypes?.includes(type))
        );
      })
      ?.map((state) => ({
        value: state?.StatusId ?? '',
        label: state?.StatusName ?? '',
      })) ?? [];

  return (
    <Form>
      <PaperContainer fluid>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.form}>
            <FormSection sectionTitle={translate('Basic') as string}>
              <FormikInputField
                id="state-name-input"
                className={classes.field}
                fdKey="configuration-state-name"
                name="StatusName"
                label={translate('Name') as string}
                variant="outlined"
                onChange={handleTitleChange}
                validate={validateStatusName}
              />
              <FormikInputField
                className={classes.field}
                fdKey="configuration-state-description"
                name="PublicDescription"
                label={translate('Description') as string}
                variant="outlined"
              />
            </FormSection>
            <FormSection sectionTitle={translate('orderType') as string}>
              <FkSelect
                isMulti
                maxChips={10}
                onChange={(options: any[]) => {
                  setFieldValue(
                    'OrderTypes',
                    options?.map((option) => option?.value)
                  );
                }}
                validation={formikValidate.required}
                fdKey={'order-types-select'}
                removeTopPadding={true}
                noErrorTranslate={true}
                fieldType={EFieldType.Text}
                name={'OrderTypes'}
                options={[
                  { label: translate('Delivery') as string, value: 'Delivery' },
                  { label: translate('Collection') as string, value: 'Collection' },
                  { label: translate('DineIn') as string, value: 'DineIn' },
                ]}
                textFieldProps={(shrink, value) => ({
                  FormHelperTextProps: {
                    style: {
                      alignSelf: 'flex-end',
                    },
                  },
                  label: translate('Type') as TranslationId,
                  InputLabelProps: {
                    shrink: value?.length > 0,
                  },
                })}
                variant="outlined"
              />
            </FormSection>
            <FormSection sectionTitle={translate('Default_to_status') as string}>
              <DefaultNextStatusSelect
                updateDefaultNextStatusValue={handleDefaultNextStatusChange}
                translate={translate}
                values={values}
              />
            </FormSection>
            <FormSection sectionTitle={translate('Next_status') as string}>
              <Typography variant="body2" className={classes.fieldDescription}>
                {translate('Choose_the_list_of_statuses_that_you_want_to_be_ab')}
              </Typography>
              <FkSelect
                maxChips={20}
                isMulti
                onChange={(options: any[]) => {
                  setFieldValue(
                    'NextStatuses',
                    options?.map((option) => option?.value)
                  );
                }}
                fdKey={'next-statuses-select'}
                removeTopPadding={true}
                noErrorTranslate={true}
                fieldType={EFieldType.Text}
                name={'NextStatuses'}
                options={stateOptions}
                textFieldProps={(shrink, value) => {
                  return {
                    FormHelperTextProps: {
                      style: {
                        alignSelf: 'flex-end',
                      },
                    },
                    label: translate('Statuses') as TranslationId,
                    InputLabelProps: {
                      shrink: value?.length > 0,
                    },
                  };
                }}
                variant="outlined"
              />
            </FormSection>
            <FormSection sectionTitle={translate('Slug_uppercase') as string}>
              <FormikInputField
                className={classes.field}
                fdKey="configuration-state-slug"
                name="StatusId"
                label={translate('Unique_ID') as string}
                variant="outlined"
                validate={validateSlug}
              />
            </FormSection>
            <FormSection sectionTitle={translate('Visibility') as string}>
              <FormControlLabel
                value="start"
                control={
                  <FkSwitch required={false} fieldType={EFieldType.Boolean} name={'Enabled'} />
                }
                label={translate('Enable_visibility')}
                labelPlacement="start"
              />
            </FormSection>
            <FormSection sectionTitle={translate('Internal_square_brackets') as string}>
              <FkRadioGroup
                name="Internal"
                fieldType={EFieldType.Boolean}
                options={[
                  {
                    labelId: 'Private_Authenticated',
                    value: 'true',
                  },
                  {
                    labelId: 'Private_and_Public',
                    value: 'false',
                  },
                ]}
              />
            </FormSection>
          </Grid>

          <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
            <Grid item>
              <LoadingButton
                fdKey="Edit_OrderStatusConfigurationState"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                style={{ marginTop: 16, marginRight: 16 }}
                disabled={hasErrors}
              >
                <Translate id="Products_EditProductForm_SaveButtonTitle" />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    </Form>
  );
};
export default withFormik<Props, FormValues>({
  displayName: 'EditFulfillmentConfigurationStateForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return getDefaultFormState(props);
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
    formikBag.setSubmitting(false);
  },
})(EditFulfillmentConfigurationStateForm);
