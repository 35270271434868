import React, { useState } from 'react';

import { Product } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { TextSearchField } from '@fd/ui/atoms/TextSearchField';
import { Typography } from '@fd/ui/atoms/Typography';
import Select, { OptionType } from '@fd/ui/Select/Select';

import { catalogProductsService } from '../../services/catalogProducts.service';
import { CatalogItemType } from '../../types';
import { CatalogItemAdditionBox } from '../AddModifierDrawer/CatalogItemAdditionBox';

const DEFAULT_LIMIT = 30;
const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: '408px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 9,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  closeButton: {
    width: '100%',
  },
  createModifierButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  filter: {
    marginBottom: theme.spacing(3.5),
  },
  search: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

export type FilterOption = {
  option: OptionType;
  types: CatalogItemType[];
};

export type AddModifierToGroupProps = {
  AppId: string;
  currentModifiers: Product[];
  onAddModifier: (modifier: Product) => void;
  onClose: () => void;
  onCreateModifierClick: () => void;
  translate: TranslateFunction;
};

export const AddModifierToGroup = (props: AddModifierToGroupProps): JSX.Element => {
  const { AppId, currentModifiers, onAddModifier, onClose, onCreateModifierClick, translate } =
    props;
  const classes = useStyles();
  const filterOptions = [
    { label: translate('All') as string, value: 'All' },
    { label: translate('Products') as string, value: 'Product' },
    { label: translate('Modifiers') as string, value: 'Modifier' },
  ];
  const [filter, setFilter] = useState<FilterOption>({ types: [], option: filterOptions[0] });
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data, isPending } = useQuery({
    queryKey: [
      catalogProductsService.getModifiersForGroupCreationQueryKey,
      AppId,
      page,
      DEFAULT_LIMIT,
      searchTerm,
      filter.types,
    ],

    queryFn: () =>
      catalogProductsService.getCatalogProducts(
        AppId,
        page,
        DEFAULT_LIMIT,
        searchTerm,
        filter.types
      ),
  });

  const handleSearchTerm = (value: string) => {
    setPage(1);
    setSearchTerm(value);
  };

  const handleFilterChange = (newSelectedOption: OptionType) => {
    const { value } = newSelectedOption;
    const types = value === 'All' ? [] : ([value] as CatalogItemType[]);
    setFilter({ types, option: newSelectedOption });
  };

  return (
    <>
      <Box ml={2} mr={2}>
        <TextSearchField
          className={classes.search}
          clearAriaLabel={`${translate('Products_SearchBox_Clear')}`}
          dataFd="catalog-item-search"
          disableLabelAnimation
          label={`${translate('Search')}`}
          onChange={handleSearchTerm}
          placeholder={`${translate('Name')}`}
        />
        <Select
          className={classes.filter}
          dataFd="catalog-item-type-filter"
          fullWidth
          onChange={handleFilterChange}
          options={filterOptions}
          TextFieldProps={{
            fdKey: 'catalog-item-type-dropdown',
            label: translate('Filter'),
            name: 'catalog-item-type-filter',
          }}
          value={filter.option}
          variant="outlined"
        />
        <CatalogItemAdditionBox
          alreadyIncludedItems={currentModifiers}
          isLoading={isPending}
          limit={DEFAULT_LIMIT}
          listItems={data?.Data}
          onAddItem={onAddModifier}
          onPageChange={setPage}
          page={page}
          total={data?.TotalRecordCount || 0}
          translate={translate}
        />
        <Typography variant="caption">
          <Translate id="Create_modifier_suggestion" />
        </Typography>
        <Button
          className={classes.createModifierButton}
          fdKey="create-new-modifier-button"
          onClick={onCreateModifierClick}
          variant="secondary"
        >
          <Translate id="Create_new_modifier" />
        </Button>
      </Box>
      <div className={classes.actions}>
        <Divider />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.closeButton}
            fdKey="close-drawer-button"
            onClick={onClose}
            variant="secondary"
          >
            <Translate id="Close" />
          </Button>
        </div>
      </div>
    </>
  );
};
