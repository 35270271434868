import React, { useCallback, useMemo, useState } from 'react';

import CalendarIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { dateTimeUtils, getDateTimeLocale } from '../../../selectors/localeDateTime.selector';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';

// #region CloseStoreDateTimePicker
type CloseStoreDateTimePickerProps = {
  value: Date;
  timeZone?: string;
  onChange(date: Date): void;
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  return {
    dtLocale: getDateTimeLocale(state),
    dtUtils: dateTimeUtils(state),
  };
};

const CloseStoreDateTimePicker = compose<
  MappedState & CloseStoreDateTimePickerProps,
  CloseStoreDateTimePickerProps
>(
  setDisplayName('DateTimePicker'),
  connect(mapStateToProps)
)((props) => {
  const { dtLocale, dtUtils, onChange, value, timeZone } = props;

  const val = timeZone ? dtUtils.utcToZonedTime(value, timeZone) : value;
  const minVal = useMemo(() => (timeZone ? dtUtils.utcToZonedTime(value, timeZone) : value), []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns as any} adapterLocale={dtLocale}>
      <DateTimePicker
        autoFocus
        ampm={dtUtils.isAmPm}
        slotProps={{
          textField: { label: <Translate id="Close_until" />, variant: 'standard' },
          popper: { style: { zIndex: 9999 } },
        }}
        format={dtUtils.LOCAL_DATE_TIME_FORMAT}
        value={val as any}
        onChange={(date) => {
          if (date && dtUtils.isValid(date)) {
            if (timeZone) {
              onChange(dtUtils.zonedTimeToUtc(date, timeZone));
            } else {
              onChange(date);
            }
          }
        }}
        minDate={minVal}
      />
    </LocalizationProvider>
  );
});
// #endregion

type CloseStoreDateTimePickerDialogProps = {
  open: boolean;
  timeZone?: string;
  onChange(date: Date): void;
  onCancel(): void;
};
const CloseStoreDateTimePickerDialog = (props: CloseStoreDateTimePickerDialogProps) => {
  const { open, onCancel, onChange, timeZone } = props;

  const [date, setDate] = useState(() => {
    const initDate = new Date();
    initDate.setHours(initDate.getHours() + 1);
    return initDate;
  });

  const handleChange = useCallback((newDate: Date) => {
    setDate(newDate);
  }, []);

  const handleYesClick = useCallback(() => {
    onChange(date);
  }, [date, onChange]);

  return (
    <YesNoDialog
      open={open}
      onYes={handleYesClick}
      onNo={onCancel}
      yesTextId={'Close_store'}
      titleTextId={'Close_store'}
    >
      <CloseStoreDateTimePicker value={date} onChange={handleChange} timeZone={timeZone} />
    </YesNoDialog>
  );
};

export default CloseStoreDateTimePickerDialog;
