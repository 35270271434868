import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  percentageField: {
    width: 88,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& p': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

type Props = {
  error?: boolean;
  id: string;
  label: JSX.Element | string;
  defaultValue?: number;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  helperText?: React.ReactNode;
  className?: string;
  name?: string;
  fdKey?: string;
};

const PercentageField = ({
  disabled = false,
  error,
  id,
  label,
  defaultValue,
  onChange,
  onBlur,
  helperText,
  className,
  name,
  fdKey,
}: Props) => {
  const classes = useStyles();
  return (
    <TextField
      disabled={disabled}
      error={error}
      id={id}
      label={label}
      name={name}
      className={clsx(classes.percentageField, className)}
      variant="outlined"
      defaultValue={defaultValue}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      inputProps={{ 'data-fd': fdKey }}
    />
  );
};
export default PercentageField;
