import React, { useEffect, useState } from 'react';

import { parse as parseQueryString } from 'qs';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import AddDeviceForm from '../Components/AddDeviceForm';
import { getDeviceListUrl } from '../Device.selectors';
import { createTerminal } from '../Devices.actions';

type Props = MappedState & MappedDispatch & RouteComponentProps;
const DeviceCreate = (props: Props) => {
  const { create, deviceRoute, history } = props;

  const [isSubmitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    if (isSubmitSuccess && deviceRoute) {
      history.replace(deviceRoute);
    }
  }, [isSubmitSuccess, deviceRoute]);

  const params = parseQueryString(props.location.search.slice(1));

  const intitialValues = {
    PinCode: Number(params.pin),
    DeviceName: '',
    StoreIds: [],
  };

  return (
    <PageLayout
      strictToParent
      toParent={' '}
      title={<Translate id="Add_new_terminal" />}
      documentTitle="Terminal"
    >
      <PaperContainer>
        <AddDeviceForm
          initialValues={intitialValues}
          submit={create}
          onSubmitSuccess={setSubmitSuccess}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const deviceRoute = getDeviceListUrl(state);

  return {
    deviceRoute,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    create: (terminal) => {
      return dispatch(createTerminal(terminal, true));
    },
  };
};

export default compose<Props, null>(connect(mapStateToProps, mapDispatchToProps))(DeviceCreate);
