import { WebsiteImage } from '@flipdish/api-client-typescript';

export const createFormData = (file) => {
  const formData = new FormData();
  formData.append('files[]', file);

  return formData;
};

export const handleImage = (
  uploadImage: (imageLocation: WebsiteImage.ImageLocationEnum, data: FormData) => void,
  deleteImage: (id: number) => void,
  imageLocation: WebsiteImage.ImageLocationEnum,
  currentImage?: File | WebsiteImage,
  oldImage?: WebsiteImage | File
) => {
  if (currentImage !== oldImage) {
    if (currentImage && currentImage instanceof File) {
      uploadImage(imageLocation, createFormData(currentImage));
    }

    if (oldImage && 'ImageId' in oldImage && oldImage.ImageId) {
      deleteImage(oldImage.ImageId);
    }
  }
};
