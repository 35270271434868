import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import GenericTable from '../../../../ui/GenericTable/GenericTable';
import GenericTableSkeleton from '../../../../ui/GenericTable/GenericTableSkeleton';
import {
  CustomerDetailsTable,
  CustomerManagementPagination,
  CustomersTableColumns,
} from '../../types';

export type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type InnerProps = {};
type OuterProps = {
  AppId: string;
  columnLocalStorageKey?: string;
  columnOrder: 'asc' | 'desc';
  columnOrderBy: string;
  data?: CustomerDetailsTable[];
  defaultColumns?: string[];
  languageCode: string;
  loading?: boolean;
  metadata: CustomersTableColumns[];
  onSort: (sortKey: string, sortDirection: 'asc' | 'desc') => void;
  orderLocalStorageKey: string;
  orderByLocalStorageKey: string;
  page?: number;
  pagination?: CustomerManagementPagination;
  rowsPerPage?: number;
  rowsPerPageLocalStorageKey?: string;
  setPage?: (n) => void;
  setRowsPerPage?: (n) => void;
  title: TranslationId;
  onRowClick: Function;
};
type Props = InnerProps & OuterProps;
export const CustomersDataTable = ({
  AppId,
  columnLocalStorageKey,
  columnOrder,
  columnOrderBy,
  data,
  defaultColumns,
  languageCode,
  loading,
  metadata,
  onRowClick,
  onSort,
  orderLocalStorageKey,
  orderByLocalStorageKey,
  page,
  pagination,
  rowsPerPage,
  rowsPerPageLocalStorageKey,
  setPage,
  setRowsPerPage,
  title,
}: Props) => {
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: CustomersTableColumns }>();
  const [order, setOrder] = useState<'asc' | 'desc'>(columnOrder);
  const [orderBy, setOrderBy] = useState(columnOrderBy);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem(orderLocalStorageKey, order);
  }, [order]);

  useEffect(() => {
    localStorage.setItem(orderByLocalStorageKey, orderBy);
  }, [orderBy]);

  useEffect(() => {
    if (loading && !isLoaded) {
      setLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    onSort(orderBy, order);
  }, [orderBy, order]);

  useEffect(() => {
    setColumns(
      new Set(
        metadata
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      metadata.reduce<{ [key: string]: CustomersTableColumns }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
  }, [metadata]);

  if (isEmpty(data)) {
    return <GenericTableSkeleton loaded={loading === false} title={title} message="No_orders" />;
  }

  const onSortKeyPressed = (key) => {
    if (meta![key].isSortable) {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
    }
  };

  const setAllColumns = (col) => {
    setColumns(col);
  };

  return (
    <GenericTable
      AppId={AppId}
      columns={columns}
      columnLocalStorageKey={columnLocalStorageKey}
      data={data}
      defaultColumns={defaultColumns}
      languageCode={languageCode}
      loading={loading}
      meta={meta}
      metadata={metadata}
      onRowClick={onRowClick}
      onSortKeyPressed={onSortKeyPressed}
      order={order}
      orderBy={orderBy}
      page={page}
      pagination={pagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      setColumns={setAllColumns}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      showColumnSelector
      showTitle={true}
      title={title}
      titlePadding={'small'}
    />
  );
};

export default CustomersDataTable;
