import { Voucher } from '@flipdish/api-client-typescript';
import moment from 'moment';

const DEFAULT_CAN_BE_USED = 'onlyOnce';
export function getCanBeUsed(voucher?: Voucher) {
  if (!voucher) {
    return DEFAULT_CAN_BE_USED;
  }
  // Legacy boolean
  if (voucher.IsValidOnlyOnce) {
    return DEFAULT_CAN_BE_USED;
  }
  // Set IsValidOncePerCustomer to true and MaxRedemptions to 1 is the same as onlyOnce
  if (voucher.IsValidOncePerCustomer && voucher.MaxRedemptions === 1) {
    return DEFAULT_CAN_BE_USED;
  }
  if (voucher.IsValidOncePerCustomer) {
    return 'oncePerCustomer';
  }
  if (voucher.IsValidForFirstOrderOnly) {
    return 'oncePerCustomerOnTheirFirstOrderOnly';
  }

  return 'multipleTimes';
}

const DEFAULT_VALID_TYPE = 'delivery & collection';
export function getValidType(voucher?: Voucher) {
  if (!voucher) {
    return DEFAULT_VALID_TYPE;
  }
  if (voucher.IsValidForDeliveryOrders) {
    if (voucher.IsValidForPickupOrders) {
      return 'delivery & collection';
    } else {
      return 'delivery';
    }
  } else {
    return 'collection';
  }
}

const DEFAULT_VALID_PAYMENT = 'pay online & cash';
export function getValidPayment(voucher?: Voucher) {
  if (!voucher) {
    return DEFAULT_VALID_PAYMENT;
  }
  if (voucher.IsValidForOrdersPayedByCash) {
    if (voucher.IsValidForOrdersPayedOnline) {
      return 'pay online & cash';
    } else {
      return 'cash';
    }
  } else {
    return 'pay online';
  }
}

export function getValidDates(voucher: Voucher) {
  if (voucher.StartDate) {
    if (voucher.ExpiryDate && moment(voucher.ExpiryDate).year() !== 2100) {
      return `${moment(voucher.StartDate).format('L')} - ${moment(voucher.ExpiryDate).format('L')}`;
    } else {
      return `${moment(voucher.StartDate).format('L')} - Doesn't expire`;
    }
  } else {
    return '';
  }
}
