import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import PaperContainer from '@fd/ui/Layout/PaperContainer';

import * as ordersActions from '../../../actions/order.action';

const useStyles = makeStyles((theme: Theme) => ({
  deliveryMapContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  deliveryMap: {
    width: '100%',
    height: 500,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  headline5: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '18px',
    fontWeight: 500,
  },
  GridTop: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
}));

type InnerProps = MappedState & MappedDispatch;

type OuterProps = {
  orderId: number;
};

type Props = InnerProps & OuterProps;

const OrderDeliveryMap = (props: Props) => {
  const { orderId, getDeliveryInfoForOrderById, orderDeliveryInfo, orderDeliveryFailed } = props;
  const classes = useStyles();

  useEffect(() => {
    getDeliveryInfoForOrderById(orderId);
  }, [orderId]);

  if (orderDeliveryFailed || !orderDeliveryInfo?.TrackUrl) {
    return null;
  }

  return (
    <PaperContainer fluid className={classes.deliveryMapContainer}>
      <Grid container className={classes.GridTop}>
        <Typography variant="h5" className={classes.headline5}>
          <Translate id="Delivery_info" />
          {orderDeliveryInfo?.IntegrationName && ` (${orderDeliveryInfo.IntegrationName})`}
        </Typography>

        <iframe
          src={orderDeliveryInfo?.TrackUrl}
          title={orderDeliveryInfo?.IntegrationName}
          className={classes.deliveryMap}
          scrolling="no"
        ></iframe>
      </Grid>
    </PaperContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    orderDeliveryInfo: orders.orderDeliveryInfo,
    orderDeliveryFailed: orders.orderDeliveryInfoFailed,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getDeliveryInfoForOrderById: (id: number) =>
    dispatch(ordersActions.getDeliveryInfoForOrderById(id)),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,

  connect(mapStateToProps, mapDispatchToProps)
)(OrderDeliveryMap);

export default EnhancedComponent;
