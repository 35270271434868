import { History } from 'history';
import { useEffect } from 'react';
import { FilterValue } from '../components/common/Filter';

export default function useURLState(
  history: History,
  page: number,
  limit: number,
  filters?: FilterValue[]
) {
  useEffect(
    () => {
      let query = `?page=${page + 1}&limit=${limit}`;

      if (filters && filters.length) {
        query += `&${filters
          .map((filter) => `filter[${filter.type.split(':')[0]}]=${filter.value}`)
          .join('&')}`;
      }

      if (history.location.search !== query) {
        if (!history.location.search) {
          history.replace(query);
        } else {
          history.push(query);
        }
      }
    },
    [page, limit, filters]
  );
}
