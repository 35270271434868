import {
  BusinessHoursOverride,
  BusinessHoursOverrideBase,
  StoresApiFactory,
} from '@flipdish/api-client-typescript';

import { tryFixApiWrongDateParsingPaginationResult } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from './utils/serverErrorMapper';

const storesApi = createApi(StoresApiFactory);

type loadAllProps = {
  storeId: number;
  after?: Date;
  page?: number;
  limit?: number;
};
export type loadAll = ReturnType<typeof loadAll>;
export async function loadAll({ storeId, after, page, limit }: loadAllProps) {
  try {
    const response = await storesApi.getBusinessHoursOverrideByStoreId(
      storeId,
      after ? after?.toISOString() : (undefined as any),
      page,
      limit
    );

    return tryFixApiWrongDateParsingPaginationResult<BusinessHoursOverride>(
      { body: response },
      ['StartTime', 'EndTime'] as any,
      response as any
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

export async function create(storeId: number, bho: Flipdish.BusinessHoursOverrideBase) {
  try {
    const response = await storesApi.createBusinessHoursOverrideByStoreId(
      storeId,
      bho as unknown as BusinessHoursOverrideBase
    );

    return response.Data;
  } catch (msgOrError) {
    if (msgOrError instanceof Error) {
      throw msgOrError;
    } else {
      const err = await mapServerError(msgOrError);
      throw err;
    }
  }
}

export async function remove(storeId: number, businessHoursOverrideId: number) {
  try {
    await storesApi.deleteBusinessHoursOverride(storeId, businessHoursOverrideId);
  } catch (msgOrError) {
    if (msgOrError instanceof Error) {
      if (KNOWN_ERRORS.includes(msgOrError?.message)) {
        return Promise.resolve();
      } else throw msgOrError;
    }
    if (msgOrError.response.statusCode === 404) {
      return Promise.resolve();
    }
    const err = await mapServerError(msgOrError);
    throw err;
  }
}
