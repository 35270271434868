import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedCurrency } from 'fd-react-intl';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import InfoIcon from '../Tooltip/InfoIcon';
import Tooltip from '../Tooltip/Tooltip';
import SummaryOverviewLoader from './SummaryOverviewLoader';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  container: {
    margin: theme.spacing(2, 1),
  },
  sectionItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    display: 'flex',
  },
  sectionItemFont: {
    fontSize: '13px !important',
  },
  sectionBoldFont: {
    fontWeight: 500,
  },
  sectionLightFont: {
    color: theme.palette.text.secondary,
  },
  background: {
    color: '#FAFAFA',
  },
  icon: {
    display: 'inline',
    marginLeft: theme.spacing(0.6),
  },
}));

type InnerProps = MappedState;
type OuterProps = {
  currency: string;
  languageCode: string;
  summaryData: {}[] | undefined;
  loading?: boolean;
  title: TranslationId;
};

type Props = InnerProps & OuterProps;

const renderCurrency = (currency, languageCode, value) => {
  const formattedValue = (
    <FormattedCurrency value={Math.abs(value)} locales={languageCode} currency={currency} />
  );
  return value < 0 ? <>({formattedValue})</> : formattedValue;
};

const renderTooltip = (classes, toolTipMessage, tooltipId) => (
  <Grid item xs={1} className={classes.icon}>
    <Tooltip messageId={toolTipMessage} fdKey={tooltipId} id={tooltipId}>
      <div className={classes.icon}>
        <InfoIcon size={13} verticalAlign="middle" />
      </div>
    </Tooltip>
  </Grid>
);

const renderLineTitle = (classes, lineTitle, stringData, tooltipContent, translate) => {
  const translatedLine = translate(lineTitle, stringData) as TranslationId;
  const splitByBracket = translatedLine.split('(');
  const lineContent = splitByBracket[0];
  const bracketContent = splitByBracket[1] ? '(' + splitByBracket[1] : null;
  return (
    <Typography
      aria-describedby={tooltipContent || lineTitle}
      component={'span'}
      className={classes.sectionItemFont}
      variant="body2"
      data-fd={'line-title-' + lineTitle}
    >
      {lineContent}
      {bracketContent && (
        <span className={classes.sectionLightFont} data-fd={'line-bracket-content-' + lineTitle}>
          {bracketContent}
        </span>
      )}
      {tooltipContent && renderTooltip(classes, tooltipContent, 'tooltip-' + lineTitle)}
    </Typography>
  );
};

const renderLineItems = (lineItems, currency, languageCode, classes, translate) =>
  lineItems.map((line, index) => {
    const renderedLine = (
      <Box className={classes.sectionItem} py={2.24} key={index} role="listitem">
        <Box flexGrow={1} mx={2}>
          {renderLineTitle(
            classes,
            line.lineTitle,
            line.stringData,
            line.tooltipContent,
            translate
          )}
        </Box>
        <Box mx={2}>
          <Typography
            className={classes.sectionItemFont}
            data-fd={'line-item-value-' + line.lineTitle}
            variant="body2"
          >
            {renderCurrency(currency, languageCode, line.lineTotal)}
          </Typography>
        </Box>
      </Box>
    );
    return line.hideLine ? null : renderedLine;
  });

const renderSummarySection = (sectionData, currency, languageCode, classes, index, translate) => {
  const ariaLabel = sectionData.tooltipContent || sectionData.sectionTitle;
  return (
    <Box key={index}>
      {sectionData.sectionTitle && (
        <Box className={classes.sectionItem} px={2} pt={4} pb={2}>
          <Typography
            aria-describedby={ariaLabel}
            component={'span'}
            className={classes.sectionBoldFont}
            variant="body1"
          >
            <Translate id={sectionData.sectionTitle} />
            {sectionData.tooltipContent &&
              renderTooltip(classes, sectionData.tooltipContent, sectionData.sectionTitle)}
          </Typography>
        </Box>
      )}

      <div role="list">
        {renderLineItems(sectionData.lineItems, currency, languageCode, classes, translate)}
      </div>

      <Box className={classes.sectionItem} bgcolor={'#FAFAFA'} display="flex" py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Typography
            className={classes.sectionBoldFont}
            data-fd={'section-total-name-' + sectionData.sectionTotalTitle}
            variant="body2"
          >
            <Translate id={sectionData.sectionTotalTitle} />
          </Typography>
        </Box>
        <Box mx={2}>
          <Typography
            className={classes.sectionBoldFont}
            data-fd={'section-total-value-' + sectionData.sectionTotalTitle}
            variant="body2"
          >
            {renderCurrency(currency, languageCode, sectionData.sectionTotal)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SummaryOverview = (props: Props) => {
  const classes = useStyles();
  const { currency, languageCode, summaryData, loading, translate, title } = props;

  return (
    <>
      {loading ? (
        <Paper className={classes.container}>
          <SummaryOverviewLoader />
          <SummaryOverviewLoader />
          <SummaryOverviewLoader />
        </Paper>
      ) : (
        <>
          {!isEmpty(summaryData) && !isUndefined(summaryData) && (
            <>
              <Box className={classes.title}>
                <Typography variant="h5">
                  <Translate id={title} />
                </Typography>
              </Box>
              <Paper className={classes.container}>
                {summaryData.map((sectionData, index) => {
                  return renderSummarySection(
                    sectionData,
                    currency,
                    languageCode,
                    classes,
                    index,
                    translate
                  );
                })}
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
}

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(SummaryOverview);
