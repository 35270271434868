import React, { memo, ReactNode } from 'react';

import { connect } from 'react-redux';

import { type TranslateFunction, getTranslate } from './index';

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};
type ChildrenProps = {
  children?: (translate: TranslateFunction) => ReactNode;
};

type Props = ComponentProps | ChildrenProps;
type ComponentProps = {
  id: TranslationId;
  data?: any;
  showMissingTranslationMsg?: boolean;
};

function Translate(props: Props & MapStateToProps) {
  const { translate } = props;
  const { children } = props as ChildrenProps;

  if (children && typeof children === 'function') {
    return <>{children(translate)}</>;
  }
  const { id, data, showMissingTranslationMsg = true } = props as ComponentProps;
  return <>{translate(id, data, { showMissingTranslationMsg })}</>;
}

export default connect<MapStateToProps, {}, Props>(mapStateToProps)(memo(Translate));
