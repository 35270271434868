import React from 'react';

import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';

import { VIEW_MODE } from '../../constants';

const useStyles = makeStyles(() => ({
  selectedItem: {
    backgroundColor: '#CDD0EC',
  },
}));

export type ToggleViewModeProps = {
  viewMode: VIEW_MODE;
  onViewModeChange: (mode: VIEW_MODE) => void;
};

export const ToggleViewMode = (props: ToggleViewModeProps): JSX.Element => {
  const { viewMode, onViewModeChange } = props;
  const classes = useStyles();

  const isGridViewMode = viewMode === VIEW_MODE.GRID;
  return (
    <ButtonGroup variant="outlined">
      <Button
        onClick={() => onViewModeChange(VIEW_MODE.GRID)}
        className={isGridViewMode ? classes.selectedItem : ''}
        data-fd="view-mode-grid"
      >
        {<AppsIcon />}
      </Button>
      <Button
        onClick={() => onViewModeChange(VIEW_MODE.LIST)}
        className={!isGridViewMode ? classes.selectedItem : ''}
        data-fd="view-mode-list"
      >
        {<ListIcon />}
      </Button>
    </ButtonGroup>
  );
};
