import React from 'react';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '480px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '70vw',
    },
  },
  dialogTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}));

export type DialogProps = {
  actions?: JSX.Element[] | JSX.Element;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  open: boolean;
  onClose?: () => void;
  subtitle?: string;
  title: string;
  titleDataFd?: string;
};

export const Dialog = (props: DialogProps): JSX.Element => {
  const {
    actions,
    children,
    className,
    contentClassName,
    onClose,
    open,
    subtitle,
    title,
    titleDataFd,
  } = props;

  const classes = useStyles();
  const dialogStyles = clsx(classes.dialog, className);
  return (
    <MuiDialog onClose={onClose} open={open} PaperProps={{ className: dialogStyles }}>
      <MuiDialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" component="span" data-fd={titleDataFd}>
          {title}
        </Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </MuiDialogTitle>
      <MuiDialogContent className={contentClassName}>{children}</MuiDialogContent>
      <MuiDialogActions className={classes.dialogActions}>{actions}</MuiDialogActions>
    </MuiDialog>
  );
};
