import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles(() => ({
  boxContents: {
    marginTop: '24px',
    height: '236px',
  },
  emptyMessageTitle: {
    fontWeight: 'bold',
  },
}));

export type NoProductsMessageProps = {
  translate: TranslateFunction;
};

export const NoProductsMessage = ({ translate }: NoProductsMessageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.boxContents}>
      <Typography className={classes.emptyMessageTitle}>
        {translate('Product_NoProductsAvailableTitle')}
      </Typography>
      <Typography variant="caption">{translate('Product_NoProductsAvailableMessage')}</Typography>
      <Box bordered={false} mt={3}>
        <Button fdKey="Learn_more" variant="secondary">
          {translate('Learn_more')}
        </Button>
      </Box>
    </Box>
  );
};
