import React from 'react';

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import LoadingButton from '@fd/ui/LoadingButton';

import KioskButtonColourField from '../../Settings/GeneralSettings/Fields/KioskButtonColourField';

const useStyles = makeStyles({
  container: {
    paddingLeft: 24,
  },
});

type Props = {
  kioskButtonColour: string | undefined;
  webAndAppsButtonColour: string | undefined;
  submit: (values: FormValues) => void;
};

const GeneralKioskSettingsForm = ({
  isSubmitting,
  handleSubmit,
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles();
  return (
    <Form>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <KioskButtonColourField />
        </Grid>
        <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
          <Grid item>
            <LoadingButton
              fdKey="save-general-settings"
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              <Translate id="Save" />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
const getDefaultFormState = ({ kioskButtonColour, webAndAppsButtonColour }: Props) => ({
  kioskButtonColour: kioskButtonColour ? `#${kioskButtonColour}` : `#${webAndAppsButtonColour}`,
});

export default withFormik<Props, FormValues>({
  displayName: 'GeneralKioskSettingsForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return getDefaultFormState(props);
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
    formikBag.setSubmitting(false);
  },
})(GeneralKioskSettingsForm);
