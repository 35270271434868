import React, { useEffect, useState } from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import Select from '../../../../ui/Select/Select';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../../WithRouteSearchParams';

const styles = () =>
  createStyles({
    label: {
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.33',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.67)',
    },
    select: {
      zIndex: 6,
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
  });

type Option = { value: 'all' | 'collection' | 'delivery'; label: string };

type Props = WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  WithRouteSearchParamsProps<string>;

type FilterProps = {
  onChange: (option: Array<'collection' | 'delivery'>) => void;
  shouldUseUrlParams?: boolean;
};

const DeliveryTypeFilter = (props: Props & FilterProps) => {
  const { classes, translate, setSearch, onChange, shouldUseUrlParams } = props;
  const [selectedItem, setSelectedItem] = useState<Option | undefined>();

  useEffect(() => {
    if (shouldUseUrlParams) {
      const option = options.find((o) => o.value === props.search);
      if (option) {
        handleChange(option);
      }
    }
  }, []);

  const setUrlParams = (type: Option['value']) => {
    setSearch(type);
  };

  const handleChange = (item: Option) => {
    setSelectedItem(item);
    if (shouldUseUrlParams) {
      setUrlParams(item.value);
      if (item.value !== 'all') {
        onChange([item.value as 'collection' | 'delivery']);
      } else {
        onChange(['collection', 'delivery']);
      }
    }
  };

  const options: Option[] = [
    { value: 'all', label: translate('All') as string },
    { value: 'delivery', label: translate('Delivery') as string },
    { value: 'collection', label: translate('Collection') as string },
  ];

  return (
    <ErrorBoundary identifier="delivery-type-filter">
      <Select
        dataFd="delivery-collection-filter"
        options={options}
        className={classes.select}
        defaultValue={{ value: 'all', label: translate('All') as string }}
        TextFieldProps={{
          fdKey: 'delivery-collection-dropdown',
          label: translate('Delivery_collection'),
          variant: 'outlined',
          style: { zIndex: 0 },
          InputLabelProps: {
            shrink: true,
            className: classes.label,
          },
        }}
        value={selectedItem}
        onChange={handleChange}
      />
    </ErrorBoundary>
  );
};

function mapStateToProps(state: AppState) {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId,
  };
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'DeliveryTypeSelect',
    withTheme: true,
  }),
  withRouteSearchParams({
    name: 'delivery_type',
  }),
  connect(mapStateToProps)
)(DeliveryTypeFilter);

export default EnhancedComponent;
