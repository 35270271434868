import React, { useEffect, useRef, useState } from 'react';

import usePrevious from '@fd/customHooks/usePrevious';
import Grid from '@mui/material/Grid';
import { type FieldProps, Field, useField } from 'formik';
import { Translate } from 'react-localize-redux';

import Tag from '@fd/ui/atoms/Tag';
import Switch from '@fd/ui/Switch/Switch';
import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../../FormItemLayout';

const DEFAULT_VALUE = 25;

const LimitAvailabilityField = () => {
  const [field, _meta, helpers] = useField('MaxRedemptions');
  const [canBeUsed] = useField('CanBeUsed');

  const isOnlyUseOnce = canBeUsed.value === 'onlyOnce';
  const isValidLimit = field.value > 0;

  const getInitialDisplayValue = (): string => {
    if (isOnlyUseOnce) {
      return '1';
    }
    if (isValidLimit) {
      return field.value.toString();
    }

    return '';
  };
  const [displayValue, setDisplayValue] = useState<string>(() => getInitialDisplayValue());
  const [isEnabled, setIsEnabled] = useState<boolean>(isOnlyUseOnce || isValidLimit);

  const previousCanBeUsed = usePrevious(canBeUsed.value);
  const originalValue = useRef<number>(field.value);

  /* 
  The MaxRedemptions field is tightly coupled with the CanBeUsed field
  If CanBeUsed is set to 'onlyOnce', then the MaxRedemptions field should be set to 1
  If CanBeUsed has changed from 'onlyOnce' and HAS an original value 
  - then the MaxRedemptions field is set to previous original value
  If CanBeUsed has changed from 'onlyOnce' and has NO original value 
  - then the MaxRedemptions field is reset to default state (unlimited)
  */
  useEffect(() => {
    const hasCanBeUsedChanged =
      previousCanBeUsed !== canBeUsed.value && previousCanBeUsed !== undefined;
    const hasChangedFromOnlyOnce = previousCanBeUsed === 'onlyOnce' && hasCanBeUsedChanged;
    const hasOriginalValue = originalValue.current > 0;

    if (isOnlyUseOnce) {
      setIsEnabled(true);
      setDisplayValue('1');
      helpers.setValue(1);
    } else if (hasChangedFromOnlyOnce && hasOriginalValue) {
      setIsEnabled(true);
      setDisplayValue(originalValue.current.toString());
      helpers.setValue(originalValue.current);
    } else if (hasChangedFromOnlyOnce) {
      setIsEnabled(false);
      setDisplayValue('');
      helpers.setValue(null);
    }
  }, [canBeUsed.value]);

  const validate = (value: number) => {
    const isUnlimited = value === null || value === undefined;
    if (isUnlimited) {
      return;
    }

    const isValidLimit = value > 0;
    if (!isValidLimit) {
      return 'Value_must_be_greater_than_0';
    }

    return;
  };

  return (
    <Field name={'MaxRedemptions'} validate={validate}>
      {({ form }: FieldProps) => {
        const { errors, touched, isSubmitting, setFieldValue } = form;

        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        const handleEnableChange = (
          _event: React.ChangeEvent<HTMLInputElement>,
          checked: boolean
        ): void => {
          if (checked) {
            const newValue = originalValue.current || DEFAULT_VALUE;
            setDisplayValue(newValue.toString());
            setFieldValue(field.name, newValue);
          } else {
            setDisplayValue('');
            setFieldValue(field.name, null);
          }

          setIsEnabled(checked);
        };

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
          const value = event.target.value;
          const parsedValue = parseFloat(value || '0');
          setDisplayValue(value);
          setFieldValue(field.name, parsedValue);
        };

        return (
          <FormItemLayout
            description={
              <Translate id="Limit_the_total_amount_of_times_this_voucher_can_be_redeemed" />
            }
            label={
              <>
                <Translate id="Limit_availability" />
                <Tag label="Beta" />
              </>
            }
            noSpace
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Switch
                  checked={isEnabled}
                  disabled={isOnlyUseOnce || isSubmitting}
                  fdKey={'voucher-limit-availability-enabled-switch'}
                  onChange={handleEnableChange}
                  value={isEnabled}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  {...field}
                  autoComplete="off"
                  disabled={isOnlyUseOnce || isSubmitting || !isEnabled}
                  error={showError}
                  fdKey="voucher-limit-availability-amount"
                  helperText={
                    showError ? (
                      <Translate id={fieldError as 'Value_must_be_greater_than_0'} />
                    ) : undefined
                  }
                  minWidth={200}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  label={<Translate id="Available_amount" />}
                  onChange={handleValueChange}
                  type="number"
                  value={displayValue}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default LimitAvailabilityField;
