import React, { useEffect, useState } from 'react';

import { TipConfiguration } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { EnableSwitch, Typography } from '@fd/ui/atoms';

import { store } from '../../../helpers/store';
import { onlyDigitsRegExp } from '../../../helpers/validation';
import { flagService } from '../../../services';
import { HelpdeskButton } from '../../HelpdeskButton';
import FormSwitchSection from '../components/FormSwitchSection';
import PercentageField from '../components/PercentageField';

const useStyles = makeStyles((theme: Theme) => ({
  sectionDivider: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  percentageField: {
    width: 88,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& p': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  defaultPercentageField: {
    width: 312,
    marginBottom: theme.spacing(9),
  },
  helperText: {
    lineHeight: 1.5,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  radioGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}));

interface ErrorMessages {
  [key: number]: React.ReactNode;
}

type Props = {
  canEdit?: boolean;
  tippingEnabled?: boolean;
  toggleTippingEnabled: (e: boolean) => void;
  tipConfig: TipConfiguration;
  onTipsChange: (newTipConfig: TipConfiguration) => void;
};

const StoreTippingForm = ({
  canEdit = true,
  onTipsChange,
  tipConfig,
  tippingEnabled,
  toggleTippingEnabled,
}: Props) => {
  const classes = useStyles();

  const [stateTipConfig, setStateTipConfig] = useState<TipConfiguration>(tipConfig);

  const [errorMessagesObject, setErrorMessages] = useState<ErrorMessages>({});

  const showReduceTippingValues = flagService.isFlagOn(store.getState(), 'tippingReduceValues');

  const showCustomTipping = flagService.isFlagOn(store.getState(), 'tippingCustom');

  const showRoundUpTipping = flagService.isFlagOn(store.getState(), 'tippingRoundUp');

  useEffect(() => {
    if (stateTipConfig.AllowEmojis !== tipConfig.AllowEmojis) {
      onTipsChange(stateTipConfig);
    }
  }, [stateTipConfig.AllowEmojis]);

  useEffect(() => {
    if (stateTipConfig.AllowCustomTips !== tipConfig.AllowCustomTips) {
      onTipsChange(stateTipConfig);
    }
  }, [stateTipConfig.AllowCustomTips]);

  useEffect(() => {
    if (stateTipConfig.AllowRoundUp !== tipConfig.AllowRoundUp) {
      onTipsChange(stateTipConfig);
    }
  }, [stateTipConfig.AllowRoundUp]);

  const handleDefaultRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDefaultRate = event.target.value === 'none' ? null : parseInt(event.target.value);
    const defaultRate = stateTipConfig?.DefaultPercentage;

    if (newDefaultRate === defaultRate) {
      return;
    }

    const newTipConfig = {
      ...stateTipConfig,
      DefaultPercentage: newDefaultRate,
    };

    setStateTipConfig(newTipConfig as any);
    onTipsChange(newTipConfig as any);
  };

  const tipRateInvalid = (id: number, rate: number, value: string) => {
    const rateInvalid = isNaN(rate) || !onlyDigitsRegExp.test(value) || rate < 0 || rate > 100;

    if (id <= 2 || !showReduceTippingValues) {
      return !value || rateInvalid;
    }

    return value && rateInvalid;
  };

  const removeKeyFromErroMessagesObject = (index: number) => {
    const newErrorMessagesObj = { ...errorMessagesObject };
    delete newErrorMessagesObj[index];
    setErrorMessages(newErrorMessagesObj);
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    const rate = parseInt(value);

    const tipRates = stateTipConfig?.Percentages || [];
    const defaultRate = stateTipConfig?.DefaultPercentage;

    const rateAlreadyExists =
      tipRates.findIndex((p: number, i: number) => p === rate && i !== index) !== -1;

    if (rateAlreadyExists || tipRateInvalid(index, rate, value)) {
      setErrorMessages({
        ...errorMessagesObject,
        [index]: <Translate id={rateAlreadyExists ? 'Duplicate_tip' : 'Tips_validation'} />,
      });
      return;
    }

    removeKeyFromErroMessagesObject(index);

    let newRates = [...tipRates.slice(0, index), rate, ...tipRates.slice(index + 1)];
    newRates = newRates.filter((rate) => !isNaN(rate));

    let newDefaultRate: number | undefined | null;

    const defaultRateExists =
      defaultRate === null || newRates.findIndex((nr) => nr === defaultRate) !== -1;

    if (defaultRateExists) {
      newDefaultRate = defaultRate;
    } else {
      newDefaultRate = isNaN(rate) ? null : rate;
    }

    setStateTipConfig({
      ...stateTipConfig,
      DefaultPercentage: newDefaultRate as any,
      Percentages: newRates,
    });
  };

  const handleRateBlur = (index: number) => {
    if (index in errorMessagesObject) {
      return;
    }

    const tipRates = stateTipConfig?.Percentages || [];
    const backendTipRates = tipConfig?.Percentages || [];

    if (tipRates[index] === backendTipRates[index]) {
      return;
    }

    onTipsChange(stateTipConfig);
  };

  const handleTipsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateTipConfig({
      ...stateTipConfig,
      [event.target.name]: event.target.checked,
    });
  };

  const handleToggleTippingEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStateTipConfig({
      ...stateTipConfig,
      IsEnabled: checked,
    });
    toggleTippingEnabled(checked);
  };

  const renderPercentageFields = () => {
    const percentageFields: JSX.Element[] = [];

    const getHelperText = (index: number) => {
      if (index in errorMessagesObject) {
        return errorMessagesObject[index];
      } else if (index > 2) {
        return <Translate id="Optional" />;
      }
    };

    for (let x = 0; x < 5; x++) {
      percentageFields.push(
        <PercentageField
          disabled={!canEdit}
          error={x in errorMessagesObject}
          key={x}
          id={`rate-${x}`}
          label={
            <>
              <Translate id="Rate" />
              {` ${x + 1}`}
            </>
          }
          defaultValue={stateTipConfig?.Percentages?.length && stateTipConfig?.Percentages[x]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRateChange(e, x)}
          onBlur={() => handleRateBlur(x)}
          helperText={getHelperText(x)}
          fdKey="percentage-field"
        />
      );
    }

    return percentageFields;
  };

  const defaultPercentageValue =
    stateTipConfig.DefaultPercentage === null ? 'none' : stateTipConfig.DefaultPercentage;

  return (
    <>
      <FormControlLabel
        className={classes.switch}
        control={
          <EnableSwitch
            name="TipsEnabled"
            checked={tippingEnabled}
            disabled={!canEdit}
            onChange={handleToggleTippingEnabled}
            fdKey="accept_tips"
            edge="start"
          />
        }
        label={
          <Typography variant="body2">
            <Translate id="Accept_tips" />
          </Typography>
        }
        labelPlacement="start"
      />
      <Divider className={classes.sectionDivider} />
      <Typography variant="body1">
        <Translate id={showReduceTippingValues ? 'Tipping_preferences' : 'Tipping_percentages'} />
      </Typography>
      <Typography
        variantMapping={{ caption: 'p' }}
        className={classes.helperText}
        variant="caption"
      >
        <Translate
          id={showReduceTippingValues ? 'Tipping_preferences_description' : 'Select_tipping_rates'}
        />{' '}
        <HelpdeskButton label="portalhelp:ordersettings-tipping" text="Learn_more" />
      </Typography>
      {showReduceTippingValues
        ? renderPercentageFields()
        : stateTipConfig?.Percentages?.map((rate: number, idx: number) => (
            <PercentageField
              disabled={!canEdit}
              error={idx in errorMessagesObject}
              key={idx}
              id={`rate-${idx}`}
              label={
                <>
                  <Translate id="Rate" />
                  {` ${idx + 1}`}
                </>
              }
              defaultValue={rate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRateChange(e, idx)}
              onBlur={() => handleRateBlur(idx)}
              helperText={errorMessagesObject[idx]}
            />
          ))}
      <Divider className={classes.sectionDivider} />
      <Typography variant="body1">
        <Translate id="Pre_selected_rate" />
      </Typography>
      <Typography
        className={classes.helperText}
        variantMapping={{ caption: 'p' }}
        variant="caption"
      >
        <Translate id={'Pre_selected_rate_info'} />
      </Typography>
      <TextField
        data-fd="default-rate"
        disabled={!canEdit}
        select
        variant="outlined"
        label={<Translate id="Default_rate" />}
        value={defaultPercentageValue}
        className={classes.defaultPercentageField}
        onChange={handleDefaultRateChange}
      >
        <MenuItem value="none">
          <Translate id="None" />
        </MenuItem>
        {stateTipConfig?.Percentages?.map((rate: number, idx: number) => (
          <MenuItem key={idx} value={rate}>
            {rate}%
          </MenuItem>
        ))}
      </TextField>
      <>
        <Divider className={classes.sectionDivider} />
        <FormSwitchSection
          canEdit={canEdit}
          checked={stateTipConfig.AllowEmojis}
          onChange={handleTipsChange}
          label="Enable_emojis"
          description="Emojis_description"
          name="AllowEmojis"
          fdKey="emojis-enabled"
        />
      </>
      {showCustomTipping && (
        <>
          <Divider className={classes.sectionDivider} />
          <FormSwitchSection
            canEdit={canEdit}
            checked={stateTipConfig.AllowCustomTips}
            onChange={handleTipsChange}
            label="Enable_custom_tip_amount"
            description="Custom_tip_description"
            name="AllowCustomTips"
            fdKey="custom-tips-enabled"
          />
        </>
      )}
      {showRoundUpTipping && (
        <>
          <Divider className={classes.sectionDivider} />
          <FormSwitchSection
            canEdit={canEdit}
            checked={stateTipConfig.AllowRoundUp}
            onChange={handleTipsChange}
            label="Enable_round_up"
            description="Round_up_description"
            name="AllowRoundUp"
            fdKey="round-up-enabled"
          />
        </>
      )}
    </>
  );
};

export default StoreTippingForm;
