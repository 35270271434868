import React from 'react';

import List from '@mui/material/List';
import { useInView } from 'react-intersection-observer';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import GenericTableSkeleton from '../../../ui/GenericTable/GenericTableSkeleton';
import GenericTableTitle from '../../../ui/GenericTable/GenericTableTitle';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import BarChart from '../components/Graphs/BarChart';
import { renderPeriodString } from '../helpers';
import { CountProps, PeriodProps } from '../types';

type Props = {
  currency?: string;
  currentPeriodFilter: PeriodProps;
  dateString: string;
  languageCode: string;
  loading?: boolean;
  previousPeriodFilter?: PeriodProps;
  reasonsOfRejection?: CountProps;
};

const ReasonOfRejection = (props: Props) => {
  const {
    currency,
    dateString,
    currentPeriodFilter,
    languageCode,
    loading,
    previousPeriodFilter,
    reasonsOfRejection,
  } = props;
  const [ref, inView] = useInView({ triggerOnce: true });

  if (
    loading !== false ||
    !reasonsOfRejection ||
    (!reasonsOfRejection.current?.find((c) => c.value !== 0) &&
      !reasonsOfRejection.previous?.find((c) => c.value !== 0))
  ) {
    // If all values are 0, we don't want to show an empty graph
    return (
      <GenericTableSkeleton
        loaded={loading === false && reasonsOfRejection !== undefined}
        title={'Reason_of_rejection'}
        subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
      />
    );
  }

  const formatTickValue = (tick: number) => {
    if (tick) {
      return tick.toLocaleString(languageCode, {
        style: 'decimal',
      });
    }
    return '';
  };

  return (
    <div ref={ref}>
      <GenericTableTitle
        title={'Reason_of_rejection'}
        subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
      />
      <ErrorBoundary identifier="reason-of-rejection-barchart">
        <PaperContainer fluid>
          <List component="nav" style={{ padding: '0px' }}>
            <BarChart
              barTheme={'error'}
              currency={currency}
              CurrentPeriod={reasonsOfRejection.current}
              dateString={dateString}
              formatTickValue={formatTickValue}
              inView={inView}
              PreviousPeriod={reasonsOfRejection.previous}
            />
          </List>
        </PaperContainer>
      </ErrorBoundary>
    </div>
  );
};

export default ReasonOfRejection;
