import React, { useState } from 'react';

import { StoreGroup } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ListIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import Button from '../../../ui/Button/Button';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import ListItemLink from '../../../ui/List/ListItemLink';
import withStoreGroupByIdData, {
  IWithStoreGroupByIdDataProps,
  WithStoreGroupByIdData,
} from '../withStoreGroupByIdData';

const useStyles = makeStyles({
  icon: {
    minWidth: 0,
  },
  more: {
    color: '#05149e',
  },
  smallerFont: {
    fontSize: '12px',
  },
  button: {
    fontSize: '12px',
    textTransform: 'lowercase',
    padding: 0,
    verticalAlign: 'inherit',
    lineHeight: 1.43,
  },
});

type OuterProps = IWithStoreGroupByIdDataProps & { isLast: boolean };
type InnerProps = WithStoreGroupByIdData & RouteComponentProps;

type Props = InnerProps & OuterProps;
const StoreGroupCard = compose<InnerProps, OuterProps>(
  setDisplayName('StoreGroupCard'),
  withRouter,
  withStoreGroupByIdData
)((props: Props) => {
  const classes = useStyles();
  const {
    storeGroupByIdData,
    storeGroupByIdDataStoreCount = 0,
    storeGroupId,
    match,
    isLast,
  } = props;
  const url = `${match.url}/${storeGroupId}/stores`;
  const [viewAllStores, setViewAllStores] = useState(false);

  return (
    <ListItemLink
      button
      divider={!isLast}
      disableGutters
      fdKey={`storeGroup_${storeGroupByIdData && storeGroupByIdData.Name}`}
      className={HORIZONTAL_SPACE_CLASSNAME}
      to={url}
    >
      {storeGroupByIdData ? (
        <Translate>
          {(translate) => {
            return (
              <ListItemText
                classes={{ secondary: classes.smallerFont }}
                primary={
                  <Typography variant="subtitle1" component="p">
                    {storeGroupByIdData.Name}
                  </Typography>
                }
                secondary={
                  <>
                    {StoreGroup.CurrencyEnum &&
                      storeGroupByIdData.Currency &&
                      `${translate('Currency_with_symbol', {
                        symbol: StoreGroup.CurrencyEnum[storeGroupByIdData.Currency].toUpperCase(),
                      })}`}
                    <br />
                    {storeGroupByIdDataStoreCount > 0 ? (
                      <>
                        {translate('Number_of_stores', {
                          count: storeGroupByIdDataStoreCount,
                        })}
                        :{' '}
                        {storeGroupByIdData.StoreHeaders.slice(0, 3)
                          .map((item) => item.Name)
                          .join(', ')}
                        {storeGroupByIdDataStoreCount > 3 ? (
                          !viewAllStores ? (
                            <Button
                              className={classes.button}
                              fdKey={'show-all-stores'}
                              onClick={(e) => {
                                e.preventDefault();
                                setViewAllStores(true);
                              }}
                            >
                              <span className={classes.more}>
                                {translate('More_stores', {
                                  stores: storeGroupByIdDataStoreCount - 3,
                                })}
                              </span>
                            </Button>
                          ) : (
                            <>
                              {', '}
                              {storeGroupByIdData.StoreHeaders.slice(3)
                                .map((item) => item.Name)
                                .join(', ')}
                            </>
                          )
                        ) : null}
                      </>
                    ) : (
                      translate('No_stores_added_yet')
                    )}
                  </>
                }
              />
            );
          }}
        </Translate>
      ) : null}
      <ListIcon className={classes.icon}>
        <KeyboardArrowRight color="action" />
      </ListIcon>
    </ListItemLink>
  );
});

export default StoreGroupCard;
