import { apmConstants } from '../constants/apm.constants';
import { apmService } from '../services/apm.service';

export const apmActions = {
  setCallsPage,
  setCallsRows,
  setCallsStores,
  getStatistics,
  getCalls,
  getStatus,
  getCalendar,
  getCallStatistics,
  getOrderStatistics,
};

function setCallsPage(page) {
  return (dispatch) => {
    dispatch({
      type: apmConstants.SET_CALLS_PAGE,
      payload: page,
    });
  };
}

function setCallsRows(rows) {
  return (dispatch) => {
    dispatch({
      type: apmConstants.SET_CALLS_ROWS,
      payload: rows,
    });
  };
}

function setCallsStores(stores) {
  return (dispatch) => {
    dispatch({
      type: apmConstants.SET_CALLS_STORES,
      payload: stores,
    });
  };
}

function getStatistics(appId, timestamp, storeIds?) {
  return (dispatch, getState) => {
    dispatch(request(timestamp));

    return apmService.getStatistics(appId, storeIds).then(
      (response) => {
        if (getState().apm.statisticsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().apm.statisticsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: apmConstants.SET_STATISTICS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: apmConstants.GET_STATISTICS_SUCCESS,
      payload: response.Data,
    };
  }
}

function getCalls(appId, page, limit, timestamp, storeIds?) {
  return (dispatch, getState) => {
    dispatch(request(timestamp));

    return apmService.getCalls(appId, page, limit, storeIds).then(
      (response) => {
        if (getState().apm.callsTimestamp === timestamp) {
          dispatch(success(response));
          dispatch(totalCallsSuccess(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().apm.callsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: apmConstants.SET_CALLS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: apmConstants.GET_CALLS_SUCCESS,
      payload: response.Data,
    };
  }

  function totalCallsSuccess(response) {
    return {
      type: apmConstants.GET_TOTAL_CALLS_COUNT,
      payload: response.TotalRecordCount,
    };
  }
}

function getCalendar(appId, timestamp, storeIds?) {
  return (dispatch, getState) => {
    dispatch(request(timestamp));
    return apmService.getCalendar(appId, storeIds).then(
      (response) => {
        if (getState().apm.calendarTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().apm.calendarTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: apmConstants.SET_CALENDAR_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: apmConstants.GET_CALENDAR_SUCCESS,
      payload: processData(response.Data),
    };
  }

  function processData(data: any[]) {
    const calendar = {};

    for (const element of data) {
      if (!calendar[element.Day]) {
        calendar[element.Day] = {};
      }
      calendar[element.Day][element.Hour] = element.TotalValue;
    }

    return calendar;
  }
}

function getCallStatistics(appId, aggregateBy, timestamp, storeIds?) {
  return (dispatch, getState) => {
    dispatch(request(timestamp));
    return apmService.getCallStatistics(appId, aggregateBy, storeIds).then(
      (response) => {
        if (getState().apm.callStatisticsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().apm.callStatisticsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: apmConstants.SET_CALL_STATISTICS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: apmConstants.GET_CALL_STATISTICS,
      payload: response.Data,
    };
  }
}

function getStatus(appId) {
  return (dispatch) => {
    dispatch(request());
    return apmService.getStatus(appId).then(
      (response) => {
        const payload = processData(response.Data);
        dispatch(success(payload));
      },
      (error) => {
        dispatch(success(false));
        console.log(error);
      }
    );
  };

  function request() {
    return {
      type: apmConstants.GET_STATUS_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: apmConstants.GET_STATUS_SUCCESS,
      payload,
    };
  }

  function processData(data: any) {
    return data.IsApmEnabled;
  }
}

function getOrderStatistics(appId, aggregateBy, timestamp, storeIds?) {
  return (dispatch, getState) => {
    dispatch(request(timestamp));
    return apmService.getOrderStatistics(appId, aggregateBy, storeIds).then(
      (response) => {
        if (getState().apm.orderStatisticsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().apm.orderStatisticsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: apmConstants.SET_ORDER_STATISTICS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: apmConstants.GET_ORDER_STATISTICS,
      payload: processData(response.Data),
    };
  }

  function processData(data: any[]) {
    const processedData: any[] = [];
    let currency: any[] = [];

    if (data[0]) {
      currency = data[0].CurrencyData.map((currency) => currency.Currency);

      for (let i = 0; i < currency.length; i++) {
        const currencyStats = data.map((datum) => {
          return {
            PeriodStart: datum.PeriodStart,
            PeriodLengthInDays: datum.PeriodLengthInDays,
            Value: datum.CurrencyData[i].Value,
          };
        });
        processedData.push(currencyStats);
      }
    }
    return { orderStatistics: processedData, orderCurrencies: currency };
  }
}
