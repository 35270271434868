import { Store } from '@flipdish/api-client-typescript';
import { fk, Model } from 'redux-orm';
import {
  ASSIGN_MENU_SUCCESS,
  CloneSuccess,
  CreateSuccess,
  LoadSuccess,
} from '../actions/store.actions';
import { SET_PRE_ORDER_SETTINGS_STATUS_SUCCESS } from '../components/Stores/PreOrderSettings/actions';
import * as storeConstants from '../constants/store.constants';
import {
  STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES,
  STORE_PUBLISH,
  STORE_UNPUBLISH,
} from '../constants/store.constants';
import { storeEventConstants } from '../signalr/hub.events';
import {
  StoreAddressUpdated as StoreAddressUpdatedEvent,
  StoreCreated as StoreCreatedEvent,
  StoreDeleted as StoreDeletedEvent,
  StoreUpdated as StoreUpdatedEvent,
} from '../signalr/hub.handlers';

export default class FlipdishStore extends Model<any> {}
FlipdishStore.modelName = 'FlipdishStore';
FlipdishStore.fields = {
  FlipdishStoreGroup: fk('FlipdishStoreGroup', 'FlipdishStores'),
};
FlipdishStore.options = () => ({
  idAttribute: 'StoreId',
});
FlipdishStore.reducer = (action, ormStore, session) => {
  const { type } = action;

  const storeGroupId = action.storeGroupId || (action.payload && action.payload.storeGroupId);
  const storeId =
    (action.store && action.store.StoreId) || (action.payload && action.payload.storeId);
  const store = action.store || (action.payload && action.payload.store);

  const { FlipdishStoreGroup } = session;
  const storeGroup = FlipdishStoreGroup.withId(storeGroupId);

  switch (type) {
    case storeConstants.STORE_CREATE_SUCCESS:
    case storeConstants.STORE_CREATE_CLONE_SUCCESS: {
      const { payload: store } = action as CreateSuccess | CloneSuccess;
      ormStore.upsert({ ...store, FlipdishStoreGroup: storeGroup });
      break;
    }
    case storeConstants.STORE_UPDATE_REQUEST:
    case storeConstants.STORE_UPDATE_SUCCESS:
      if (ormStore.withId(storeId)) {
        ormStore.withId(storeId).update(store);
      }
      break;

    case ASSIGN_MENU_SUCCESS:
      if (ormStore.withId(storeId)) {
        ormStore.withId(storeId).update({ MenuId: action.payload.menuId });
      }
      break;
    case storeConstants.STORE_UPDATE_ADDRESS_SUCCESS:
      if (ormStore.withId(storeId)) {
        ormStore.withId(storeId).update({ Address: action.payload.address });
      }
      break;
    case storeConstants.STORE_UPDATE_ADDRESS_COORDINATES_SUCCESS:
      if (ormStore.withId(storeId)) {
        ormStore.withId(storeId).update({
          Address: {
            ...ormStore.withId(storeId).ref.Address,
            Coordinates: action.payload.coordinates,
          },
        });
      }
      break;

    case storeConstants.STORE_LOAD_ALL_SUCCESS:
    case storeConstants.STORE_LOAD_BY_APP_SUCCESS: {
      const stores = (action as LoadSuccess).payload;
      stores.map((s, idx) => {
        ormStore.upsert(s);
      });
      break;
    }

    case storeConstants.STORE_LOAD_SUCCESS:
      ormStore.upsert({ ...store, FlipdishStoreGroup: storeGroup });
      break;

    case storeEventConstants.CREATED:
      ormStore.upsert((action as StoreCreatedEvent).payload.Store as Store);
      break;
    case storeEventConstants.UPDATED: {
      const { payload: store } = action as StoreUpdatedEvent;
      if (ormStore.withId(store.StoreId as any)) {
        if (store.Store && !store.Store.IsArchived) {
          ormStore.withId(store.StoreId as any).update(store);
        }
      }
      break;
    }
    case storeEventConstants.DELETED: {
      const { payload: store } = action as StoreDeletedEvent;
      if (ormStore.withId(store.StoreId as any)) {
        ormStore.withId(store.StoreId as any).delete();
      }
      break;
    }
    case storeEventConstants.ADDRESS_UPDATED: {
      const { payload: store } = action as StoreAddressUpdatedEvent;
      if (ormStore.withId(store.StoreId as any)) {
        ormStore.withId(store.StoreId as any).update({ Address: store.StoreAddress });
      }
      break;
    }

    case SET_PRE_ORDER_SETTINGS_STATUS_SUCCESS: {
      const fieldName: keyof Store =
        action.payload.type === 'Delivery' ? 'PreOrderDeliveryEnabled' : 'PreOrderPickupEnabled';
      const storeModel = ormStore.withId(store.StoreId as any);

      if (storeModel) {
        console.log(storeModel, action.payload, 12312312);
        storeModel.update({ [fieldName]: action.payload.status });
      }

      break;
    }

    case STORE_PUBLISH: {
      const { payload: storeId } = action;
      if (ormStore.withId(storeId as any)) {
        ormStore.withId(storeId as any).update({ IsPublished: true });
      }
      break;
    }

    case STORE_UNPUBLISH: {
      const { payload: storeId } = action;
      if (ormStore.withId(storeId as any)) {
        ormStore.withId(storeId as any).update({ IsPublished: false });
      }
      break;
    }

    case STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES: {
      const fieldName: keyof Store =
        action.payload.deliveryType === 'Delivery'
          ? 'PreOrderDeliveryEnabled'
          : 'PreOrderPickupEnabled';
      const storeModel = ormStore.withId(action.payload.storeId as any);

      if (storeModel) {
        storeModel.update({ [fieldName]: action.payload.enabled });
      }
      break;
    }
  }
  return undefined;
};
