import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

type Props = RouteComponentProps;

const Accounts = lazyWithRetry(() => import('./Accounts'));
const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

const AccountsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="rms-accounts">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Accounts />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.EXPENSES)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Expenses" />}
                module={RMSModules.EXPENSES}
                url={`modules/accounts/${RMSModules.EXPENSES}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${RMSModules.BILLS}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Bills" />}
                module={RMSModules.BILLS}
                url={`modules/accounts/${RMSModules.BILLS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.CREDIT_ACCOUNT_STATUS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Credit_Account_Status" />}
                module={RMSModules.CREDIT_ACCOUNT_STATUS}
                url={`modules/accounts/${RMSModules.CREDIT_ACCOUNT_STATUS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.ACCOUNT_CATEGORY)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Account_Category" />}
                module={RMSModules.ACCOUNT_CATEGORY}
                url={`modules/accounts/${RMSModules.ACCOUNT_CATEGORY}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.ACCOUNT_SUB_CATEGORY)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Account_sub_category" />}
                module={RMSModules.ACCOUNT_SUB_CATEGORY}
                url={`modules/accounts/${RMSModules.ACCOUNT_SUB_CATEGORY}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.TRANSACTION_ACCOUNTS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Transaction_Accounts" />}
                module={RMSModules.TRANSACTION_ACCOUNTS}
                url={`modules/accounts/${RMSModules.TRANSACTION_ACCOUNTS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.CREDIT_BOOKS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Credit_Books" />}
                module={RMSModules.CREDIT_BOOKS}
                url={`modules/accounts/${RMSModules.CREDIT_BOOKS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.CREDIT_ACCOUNTS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.ACCOUNTS}
                title={<Translate id="Credit_Accounts" />}
                module={RMSModules.CREDIT_ACCOUNTS}
                url={`modules/accounts/${RMSModules.CREDIT_ACCOUNTS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default AccountsRoutes;
