import {
  AssignedBankAccount,
  LocationAreaWithLocations,
  OrderLeadTimes,
  ServiceCharge,
  StoreCreateBase,
  TipConfiguration,
} from '@flipdish/api-client-typescript';
import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';

import {
  ArchiveThisStore,
  CreateFailure,
  CreateRequest,
  CreateSuccess,
  LoadFailure,
  LoadRequest,
  LoadSuccess,
  SetSearch,
  SetSelected,
} from '../actions/store.actions';
import {
  ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS,
  GET_ATTACHED_BANK_ACCOUNT_SUCCESS,
} from '../components/Finance/Banking/banking.actions';
import {
  DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  STORE_ARCHIVE,
  STORE_CREATE_CLONE_SUCCESS,
  STORE_CREATE_FAILURE,
  STORE_CREATE_REQUEST,
  STORE_CREATE_SUCCESS,
  STORE_GET_LEAD_TIMES_SUCCESS,
  STORE_GET_SERVICE_CHARGE_SUCCESS,
  STORE_GET_TIPS_SUCCESS,
  STORE_LOAD_ALL_FAILURE,
  STORE_LOAD_ALL_REQUEST,
  STORE_LOAD_ALL_SUCCESS,
  STORE_SET_SELECTED,
  STORE_UPDATE_LEAD_TIMES_SUCCESS,
  STORE_UPDATE_SERVICE_CHARGE_SUCCESS,
  STORE_UPDATE_TIPS_SUCCESS,
  STORES_RESET,
  STORES_SET_SEARCH,
} from '../constants/store.constants';
import { storeEventConstants } from '../signalr/hub.events';
import { StoreCreated, StoreDeleted } from '../signalr/hub.handlers';

type DefaultState = {
  storeGroupId?: number;
  isEmpty: boolean;
  item_order_index: number[];
  totalCount?: number;
  showSearch: boolean;
  error?: Error;
  search?: {
    query: string;
    item_order_index: number[];
    totalCount?: number;
    error?: Error;
  };
  selection?: {
    storeId: number;
  };
  create?: {
    storeId?: number;
    store?: StoreCreateBase;
    error?: Error;
  };
  bankAccountId?: AssignedBankAccount;
  smsOrderNotifications: StoreOrderNotificationContactDetails[];
  emailOrderNotifications: StoreOrderNotificationContactDetails[];
  tipConfig?: TipConfiguration;
  serviceCharge?: ServiceCharge;
  locationAreas?: LocationAreaWithLocations[];
  leadTimes?: OrderLeadTimes;
};

const defaultState: DefaultState = {
  isEmpty: false,
  item_order_index: [],
  showSearch: false,
  create: {
    storeId: undefined,
    store: {},
    error: undefined,
  },
  smsOrderNotifications: [],
  emailOrderNotifications: [],
  tipConfig: undefined,
  locationAreas: undefined,
};

// #region state reducers

const reduceSetSearch = (state: DefaultState, action: SetSearch): DefaultState => {
  const query = action.payload;
  if (!query) {
    return {
      ...state,
      search: undefined,
    };
  }

  return {
    ...state,
    showSearch: true,
    search: {
      query,
      item_order_index: [],
      totalCount: 0,
    },
  };
};

const reduceLoadAllRequest = (state: DefaultState, action: LoadRequest): DefaultState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
      },
    };
  }

  return {
    ...state,
    error: undefined,
  };
};
const reduceLoadAllSuccess = (state: DefaultState, action: LoadSuccess): DefaultState => {
  const { query, totalCount } = action.meta;
  if (query) {
    const ids = ((state.search && state.search.item_order_index) || []).concat(
      action.payload.map((s) => s.StoreId as number)
    );
    const uniqueIds = new Set(ids);
    return {
      ...state,
      search: {
        query,
        item_order_index: [...uniqueIds],
        totalCount,
      },
    };
  }

  const ids = state.item_order_index.concat(action.payload.map((s) => s.StoreId as number));
  const uniqueIds = new Set(ids);
  return {
    ...state,
    totalCount,
    item_order_index: [...uniqueIds],
    isEmpty: totalCount === 0,
    showSearch: totalCount > 20,
    search: undefined,
    error: undefined,
  };
};
const reduceLoadAllFailure = (state: DefaultState, action: LoadFailure): DefaultState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
        totalCount: (state.search && state.search.totalCount) || undefined,
        error: action.payload,
      },
    };
  }

  return {
    ...state,
    error: action.payload,
  };
};

const reduceCreateRequest = (state: DefaultState, action: CreateRequest): DefaultState => {
  return {
    ...state,
    create: {
      store: action.meta.store,
    },
  };
};
const reduceCreateSuccess = (state: DefaultState, action: CreateSuccess): DefaultState => {
  return {
    ...state,
    totalCount: (state.totalCount || 0) + 1,
    isEmpty: false,
    create: {
      storeId: action.payload.StoreId,
      store: action.meta.store,
    },
    item_order_index: [...state.item_order_index, action.payload.StoreId as number],
  };
};
const reduceCreateFailure = (state: DefaultState, action: CreateFailure): DefaultState => {
  return {
    ...state,
    create: {
      ...state.create,
      error: action.payload,
    },
  };
};

const reduceSetSelected = (state: DefaultState, action: SetSelected): DefaultState => {
  if (!action.payload.storeId) {
    return {
      ...state,
      storeGroupId: action.payload.storeGroupId || state.storeGroupId,
      selection: undefined,
    };
  }
  return {
    ...state,
    storeGroupId: action.payload.storeGroupId,
    selection: {
      storeId: action.payload.storeId,
    },
  };
};

const reduceStoreArchive = (state: DefaultState, action: ArchiveThisStore): DefaultState => {
  const filteredResult = state.item_order_index.filter((storeId) => storeId !== action.payload);
  return {
    ...state,
    item_order_index: [...filteredResult],
    totalCount: (state.totalCount || 1) - 1,
    isEmpty: filteredResult.length === 0 ? true : false,
  };
};
// #endregion

function storesReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case STORES_RESET:
      return { ...defaultState };

    case STORE_SET_SELECTED:
      return reduceSetSelected(state, action);

    case STORES_SET_SEARCH:
      return reduceSetSearch(state, action);

    case STORE_LOAD_ALL_REQUEST:
      return reduceLoadAllRequest(state, action);
    case STORE_LOAD_ALL_SUCCESS:
      return reduceLoadAllSuccess(state, action);
    case STORE_LOAD_ALL_FAILURE:
      return reduceLoadAllFailure(state, action);

    case STORE_CREATE_REQUEST:
      return reduceCreateRequest(state, action);
    case STORE_CREATE_SUCCESS:
    case STORE_CREATE_CLONE_SUCCESS:
      return reduceCreateSuccess(state, action);
    case STORE_CREATE_FAILURE:
      return reduceCreateFailure(state, action);
    case STORE_ARCHIVE:
      return reduceStoreArchive(state, action);

    case storeEventConstants.CREATED: {
      const { Store } = (action as StoreCreated).payload;
      const storeGroupId = (Store && Store.StoreGroupId) || 0;
      if (state.storeGroupId !== storeGroupId) {
        // TODO: should check for if storeId already in memory
        return state;
      }
      if (state.create && state.create.store && Store && state.create.store.Name === Store.Name) {
        // FIXME: monkey patch: only update totalCount if event coming from somewhere else
        return state;
      }
      return {
        ...state,
        isEmpty: false,
        totalCount: (state.totalCount || 0) + 1,
      };
    }
    case storeEventConstants.DELETED: {
      const { Store } = (action as StoreDeleted).payload;
      const storeGroupId = (Store && Store.StoreGroupId) || 0;
      if (state.storeGroupId !== storeGroupId) {
        return state;
      }
      return {
        ...state,
        totalCount: (state.totalCount || 1) - 1,
      };
    }

    case ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS:
    case GET_ATTACHED_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankAccountId: action.payload,
      };
    }
    case GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS: {
      const { smsOrderNotifications, emailOrderNotifications } = action.payload;
      return {
        ...state,
        smsOrderNotifications,
        emailOrderNotifications,
      };
    }
    case SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS:
      if (action.payload.ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Sms) {
        return {
          ...state,
          smsOrderNotifications: [...state.smsOrderNotifications, action.payload],
        };
      }
      if (
        action.payload.ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Email
      ) {
        return {
          ...state,
          emailOrderNotifications: [...state.emailOrderNotifications, action.payload],
        };
      }
      return state;

    case DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS: {
      const { StoreOrderContactDetailId, ContactType } = action.payload;
      if (ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Sms) {
        return {
          ...state,
          smsOrderNotifications: state.smsOrderNotifications.filter(
            (f) => f.StoreOrderContactDetailId !== StoreOrderContactDetailId
          ),
        };
      } else if (ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Email) {
        return {
          ...state,
          emailOrderNotifications: state.emailOrderNotifications.filter(
            (f) => f.StoreOrderContactDetailId !== StoreOrderContactDetailId
          ),
        };
      }
      return {
        ...state,
      };
    }

    case STORE_GET_TIPS_SUCCESS:
    case STORE_UPDATE_TIPS_SUCCESS:
      return {
        ...state,
        tipConfig: action.payload,
      };

    case STORE_GET_SERVICE_CHARGE_SUCCESS:
    case STORE_UPDATE_SERVICE_CHARGE_SUCCESS:
      return {
        ...state,
        serviceCharge: action.payload,
      };

    case STORE_GET_LEAD_TIMES_SUCCESS: {
      return {
        ...state,
        leadTimes: action.payload,
      };
    }
    case STORE_UPDATE_LEAD_TIMES_SUCCESS: {
      return {
        ...state,
        leadTimes: action.payload,
      };
    }
    default:
      return state;
  }
}

export default storesReducer;
