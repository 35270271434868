import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';

import { appsConstants } from '../../constants/apps.constants';
import {
  GET_TERMS_AND_POLICY_DATA_FAILURE,
  GET_TERMS_AND_POLICY_DATA_REQUEST,
  GET_TERMS_AND_POLICY_DATA_SUCCESS,
} from './settings.actions';

type SettingsState = {
  error?: any;
  termsAndPolicyData: TermsAndPolicyData;
};

const initialState: SettingsState = {
  termsAndPolicyData: {
    UseAdvancedSettings: false,
  },
};

export default (state: SettingsState = initialState, action): SettingsState => {
  switch (action.type) {
    case GET_TERMS_AND_POLICY_DATA_SUCCESS:
      return {
        ...state,
        termsAndPolicyData: {
          ...state.termsAndPolicyData,
          ...action.payload,
        },
      };
    case appsConstants.SELECTED_APP_CHANGED:
    case GET_TERMS_AND_POLICY_DATA_REQUEST:
      return initialState;

    //#region errors
    case GET_TERMS_AND_POLICY_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    //#endregion

    default:
      return state;
  }
};
