import React from 'react';

import { Translate } from 'react-localize-redux';

export const CustomSubTitle = ({ line1, line2 }: { line1: string; line2?: number }) => {
  const getSecondaryLine = () => {
    if (line2 === undefined) return undefined;
    if (line2 > 1) return <Translate id="num_sales_channels_selected" data={{ number: line2 }} />;
    if (line2 === 1) return <Translate id="one_sales_channel_selected" />;
    return <Translate id="No_sales_channels_added_yet" />;
  };

  return (
    <span>
      <span>
        <Translate
          id="Currency_with_symbol"
          data={{
            symbol: line1,
          }}
        />
      </span>
      <br />
      <span>{getSecondaryLine()}</span>
    </span>
  );
};
