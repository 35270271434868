import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '../../../../../ui/Forms/FormItemLayout';
import TextField from '../../../../../ui/TextField/TextField';

export type InputFieldProps = {
  fieldData: FdField;
  decimal?: boolean;
  multiline?: boolean;
  type?: 'number' | 'text';
};

const InputField = ({ fieldData, type, decimal, multiline }: InputFieldProps) => {
  const validate = (value: string) => {
    if (!value || !fieldData.ValidationRegex) {
      return;
    }

    if (type === 'text' && !multiline) {
      const regex = new RegExp(fieldData.ValidationRegex);

      if (!regex.test(value)) {
        return <Translate id="Invalid_entry" />;
      }
    }
  };

  const validateNumber = (value: string) => {
    if (!value) {
      return;
    }

    let regex;

    if (decimal) {
      regex = new RegExp(/^[0-9]+\.?[0-9]{0,2}$/);
    } else {
      regex = new RegExp(/^[0-9]+$/);
    }

    if (!regex.test(value)) {
      return <Translate id="Invalid_entry" />;
    }
  };

  return (
    <Field name={fieldData.Key} validate={type === 'number' ? validateNumber : validate}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <FormItemLayout
            label={fieldData.Name}
            description={fieldData.Description}
            showTooltip={!!(fieldData.Tooltip && fieldData.Tooltip.length)}
            tooltipOptions={{
              fdKey: `appstore_config_tooltip_${fieldData.Key}`,
              labelText: fieldData.Tooltip,
            }}
          >
            <TextField
              {...field}
              value={field.value || ''}
              fdKey={`appstore_config_input_${fieldData.Key}`}
              autoComplete="off"
              disabled={isSubmitting}
              error={showError}
              helperText={showError ? fieldError : undefined}
              fullWidth
              type="text"
              multiline={multiline}
              inputProps={decimal ? { step: '0.01' } : {}}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default InputField;
