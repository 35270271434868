/**
 * Currencies that shouldn't have decimal values
 */
export const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

/**
 * Helper fn to override default currency symbol
 */
export const CURRENCY_SYMBOL_OVERRIDES = {
  // standard is CFA but 1 customer says it should be F (nw, we'll come back here later)
  XOF: (formatted: string) => formatted.replace(/F\sCFA|XOF|CFA/, 'F'),
};
