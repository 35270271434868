import * as React from 'react';

const DeliveryZonesEmptyImage = () => (
  <svg
    width="277px"
    height="188px"
    viewBox="0 0 277 188"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <polygon
        id="path-1"
        points="0 0.8925 68.4318 0.8925 68.4318 27.0835 0 27.0835"
      />
    </defs>
    <g
      id="Flipdish-Portal-/-Delivery-zones"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Zone-list-/-Empty"
        transform="translate(-1079.000000, -258.000000)"
      >
        <g id="Group-56" transform="translate(1079.000000, 258.000000)">
          <g id="Group-5" transform="translate(45.000000, 82.603000)">
            <path
              d="M199.063,97.173 C181.46,93.361 162.794,76.421 157.369,59.339"
              id="Stroke-1"
              stroke="#9CA1D0"
              strokeWidth="2.5"
              strokeLinecap="round"
            />
            <path
              d="M61.2012,11.6534 C61.2012,17.9744 47.5862,23.0994 30.7902,23.0994 C13.9952,23.0994 0.3802,17.9744 0.3802,11.6534 C0.3802,5.3324 13.9952,0.2084 30.7902,0.2084 C47.5862,0.2084 61.2012,5.3324 61.2012,11.6534"
              id="Fill-3"
              fillOpacity="0.7"
              fill="#9CA1D0"
            />
          </g>
          <polygon
            id="Fill-6"
            fill="#E1E3F2"
            points="137.4066 -0.0002 134.9666 1.4218 134.9666 100.8068 173.4376 123.0188 235.4456 123.0188 273.9176 145.2298 276.3576 143.8088 276.3576 44.4238 237.8866 22.2118 175.8776 22.2118"
          />
          <polygon
            id="Fill-7"
            fill="#D8D9ED"
            points="173.439 123.019 235.446 123.019 235.446 23.633 173.439 23.633"
          />
          <polygon
            id="Fill-8"
            fill="#E1E3F2"
            points="173.439 123.019 235.446 123.019 235.446 23.633 173.439 23.633"
          />
          <polygon
            id="Fill-9"
            fill="#D8D9ED"
            points="134.9668 1.4215 134.9668 100.8065 173.4388 123.0185 173.4388 23.6325"
          />
          <polygon
            id="Fill-10"
            fill="#D8D9ED"
            points="235.4458 23.6329 235.4458 123.0189 273.9178 145.2309 273.9178 45.8449"
          />
          <g
            id="Group-15"
            transform="translate(110.000000, 100.603000)"
            fill="#9CA1D0"
            fillOpacity="0.7"
          >
            <polyline
              id="Fill-11"
              points="0.3965 0.2037 38.8685 22.4157 63.4385 22.4157 24.9665 0.2037"
            />
            <polyline
              id="Fill-13"
              points="100.876 22.4156 139.348 44.6276 163.918 44.6276 125.446 22.4156"
            />
          </g>
          <polygon
            id="Fill-16"
            fill="#E1E3F2"
            points="87.1563 118.8121 49.7073 68.1391 46.4233 70.3981 19.8143 91.4821 33.3643 135.4391 68.4323 155.6861 71.7173 153.4281"
          />
          <polygon
            id="Fill-17"
            fill="#D8D9ED"
            points="46.4229 70.398 19.8149 91.482 33.3639 135.44 68.4319 155.686 83.8719 121.071"
          />
          <g id="Group-55" transform="translate(0.000000, 20.603000)">
            <g id="Group-20" transform="translate(0.000000, 108.000000)">
              {/* <mask id="mask-2" fill="white">
                                <use xlink: href="#path-1"></use>
                        </mask> */}
              <g id="Clip-19" />
              <polygon
                id="Fill-18"
                fillOpacity="0.7"
                fill="#9CA1D0"
                mask="url(#mask-2)"
                points="33.3638 6.8365 68.4318 27.0835 47.0258 27.0835 -0.0002 10.1275 11.9438 0.8925"
              />
            </g>
            <path
              d="M64.0596,4.8126 L67.4266,2.8556 C72.5616,-0.1274 79.6726,0.2926 87.5216,4.8246 L84.1546,6.7816 C76.3056,2.2496 69.1946,1.8286 64.0596,4.8126"
              id="Fill-21"
              fill="#E1E3F2"
            />
            <path
              d="M84.1543,6.7814 C99.7523,15.7864 112.3533,37.6134 112.3023,55.5364 C112.2523,73.4594 99.5683,80.6914 83.9703,71.6864 C68.3733,62.6814 55.7713,40.8494 55.8223,22.9274 C55.8733,5.0044 68.5563,-2.2236 84.1543,6.7814"
              id="Fill-23"
              fill="#D8D9ED"
            />
            <path
              d="M107.4307,71.6969 L104.0637,73.6539 C109.1337,70.7069 112.2777,64.4409 112.3027,55.5359 C112.3537,37.6139 99.7517,15.7869 84.1547,6.7809 L87.5217,4.8239 C103.1187,13.8299 115.7207,35.6569 115.6697,53.5799 C115.6447,62.4849 112.5007,68.7509 107.4307,71.6969"
              id="Fill-25"
              fill="#E1E3F2"
            />
            <path
              d="M64.0596,4.8126 L67.4266,2.8556 C72.5616,-0.1274 79.6726,0.2926 87.5216,4.8246 L84.1546,6.7816 C76.3056,2.2496 69.1946,1.8286 64.0596,4.8126"
              id="Fill-27"
              fill="#E1E3F2"
            />
            <g
              id="Group-39"
              transform="translate(19.000000, 49.000000)"
              fill="#9CA1D0"
            >
              <path
                d="M6.2271,24.982 C6.2321,23.101 5.3821,20.96 4.0991,19.276 L0.8141,21.879 L2.7341,28.107 C4.7341,28.678 6.2201,27.459 6.2271,24.982"
                id="Fill-29"
              />
              <path
                d="M61.1338,46.4098 C60.2878,46.8838 59.7608,47.9128 59.7568,49.3848 C59.7498,51.6808 61.0178,54.3658 62.7928,56.1288 L64.8718,51.4678 L61.1338,46.4098 Z"
                id="Fill-31"
              />
              <path
                d="M48.9893,80.1778 C46.4423,78.7068 44.3703,79.8868 44.3623,82.8148 C44.3613,82.9298 44.3693,83.0468 44.3753,83.1638 L49.4323,86.0828 L51.2043,82.1098 C50.5483,81.3148 49.7933,80.6418 48.9893,80.1778"
                id="Fill-33"
              />
              <path
                d="M14.354,60.173 C13.666,59.775 13.014,59.577 12.426,59.549 L14.364,65.837 L18.936,68.476 C18.942,68.364 18.952,68.254 18.952,68.136 C18.96,65.209 16.902,61.644 14.354,60.173"
                id="Fill-35"
              />
              <path
                d="M27.1665,6.6832 C28.9965,7.7402 30.5765,7.4222 31.3275,6.0772 L27.4225,0.7952 L24.0465,3.4702 C24.8655,4.8172 25.9595,5.9862 27.1665,6.6832"
                id="Fill-37"
              />
            </g>
            <path
              d="M85.2437,41.9601 C85.2487,40.2631 84.0557,38.1961 82.5777,37.3431 C81.3247,36.6201 80.2727,37.0061 79.9797,38.1771 L55.8477,24.2441 C55.8647,24.8001 55.8927,25.3601 55.9337,25.9221 L80.0187,39.8281 C80.3467,41.2831 81.3527,42.7921 82.5607,43.4891 C84.0377,44.3421 85.2387,43.6571 85.2437,41.9601"
              id="Fill-40"
              fill="#9CA1D0"
            />
            <path
              d="M205.979,67.2174 C202.539,67.2174 199.165,66.5284 196.221,65.2244 C195.632,64.9634 195.366,64.2754 195.626,63.6874 C195.887,63.0984 196.576,62.8334 197.164,63.0934 C199.813,64.2664 202.862,64.8864 205.979,64.8864 C209.097,64.8864 212.145,64.2664 214.794,63.0934 C215.38,62.8334 216.071,63.0984 216.332,63.6874 C216.592,64.2754 216.327,64.9634 215.738,65.2244 C212.794,66.5284 209.419,67.2174 205.979,67.2174"
              id="Fill-42"
              fill="#9CA1D0"
            />
            <g
              id="Group-52"
              transform="translate(182.000000, 36.000000)"
              fill="#9CA1D0"
            >
              <path
                d="M9.5503,5.4518 C7.6923,5.4518 6.1863,6.9578 6.1863,8.8158 C6.1863,10.6738 7.6923,12.1798 9.5503,12.1798 C11.4073,12.1798 12.9143,10.6738 12.9143,8.8158 C12.9143,6.9578 11.4073,5.4518 9.5503,5.4518"
                id="Fill-44"
              />
              <polygon
                id="Fill-46"
                points="9.2837 0.7037 0.8177 4.1097 1.4917 5.7857 9.9577 2.3797"
              />
              <path
                d="M38.4087,5.4518 C36.5507,5.4518 35.0447,6.9578 35.0447,8.8158 C35.0447,10.6738 36.5507,12.1798 38.4087,12.1798 C40.2657,12.1798 41.7717,10.6738 41.7717,8.8158 C41.7717,6.9578 40.2657,5.4518 38.4087,5.4518"
                id="Fill-48"
              />
              <polygon
                id="Fill-50"
                points="38.6743 0.7037 38.0003 2.3797 46.4663 5.7857 47.1413 4.1097"
              />
            </g>
            <polyline
              id="Stroke-53"
              stroke="#9CA1D0"
              strokeWidth="2.5"
              strokeLinecap="round"
              points="238.523 142.5065 245.175 159.0975 228.585 165.7505"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DeliveryZonesEmptyImage;
