import React from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { type NavLinkProps, NavLink } from 'react-router-dom';

const styles = () =>
  createStyles({
    root: {
      textDecoration: 'none',
    },
  });

type Props = WithStyles<typeof styles> & NavLinkProps;

export default withStyles(styles)(({ children, classes, className, ...rest }: Props) => (
  <NavLink className={`${classes.root} ${className || ''}`} {...rest}>
    {children}
  </NavLink>
));
