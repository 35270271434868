import { createReducer, ORM, ORMCommonState, TableState } from 'redux-orm';

import FlipdishStore from './store.model';
import StoreDeliveryZone from './storeDeliveryZone.model';
import FlipdishStoreGroup from './storeGroup.model';
// import { StoreOpeningHourDelivery } from './storeOpeningHours.model';

export interface IOrmState extends ORMCommonState {
  FlipdishStore: TableState;
  FlipdishStoreGroup: TableState;
  StoreDeliveryZone: TableState;
}

const orm = new ORM<IOrmState>();
//@ts-ignore
orm.register(FlipdishStore, FlipdishStoreGroup, StoreDeliveryZone);
export default orm;
export const ormReducer = createReducer(orm);
