import React, { useEffect, useState } from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import Fab from '@mui/material/Fab';
import Hidden from '@mui/material/Hidden';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { type Theme, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { formatDistanceToNow } from 'date-fns';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { FabButtonLink } from '@fd/ui/FabButtonLink';

import { reinviteTeammate } from '../../actions/teammates.actions';
import EmptyIcon from '../../assets/images/empty-teammates.svg';
import { capitalizeFirst } from '../../helpers/utilities';
import { dateTimeUtils } from '../../selectors/localeDateTime.selector';
import { permissionsSelector } from '../../selectors/permissions.selector';
import EmptyComponent from '../../ui/EmptyComponent';
import PageLayout from '../../ui/Layout';
import GridContainer from '../../ui/Layout/GridContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import TableCellLink from '../../ui/TableCellLink/TableCellLink';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import { ListMobile } from './ListMobile';
import { getTeammatesTimestamp } from './selectors';

const AppResourceSetEnum = FlipdishAPI.App.AppResourceSetEnum;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    position: 'fixed' as any,
    bottom: '24px',
    right: '24px',
    backgroundColor: '#ff0046',
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  table: {
    width: '100%',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
    '&:hover': {
      backgroundColor: '#ece6f7',
    },
  },
  rowLink: {
    cursor: 'pointer',
  },
  cell: {
    padding: '16px 24px',
    borderBottomColor: 'rgba(0,0,0,0.21)',
  },
  headCell: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '2',
  },
  bodyCell: {
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.85',
    letterSpacing: 'normal',
    padding: 0,
    height: '100%',
  },
  bodyCellWithPadding: {
    padding: '16px 24px',
  },
  resendInvite: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#ff395f',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  inviteResent: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#27bf79',
  },
  snackbarContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    padding: '16px',
    minWidth: '160px',
    marginBottom: '20px',
    '&>div': {
      padding: '0',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  snackbarSpan: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.3px',
  },
  snackbarIcon: {
    width: '22px',
    marginRight: '12px',
  },
}));

type Props = MappedState & MappedDispatch;
const List = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    currentApp,
    teammates,
    teammatesLoading,
    teammatesTimestamp,
    dtUtils,
    canEditPermission,
    translate,
    reinviteTeammateAction,
  } = props;

  const [invites, setInvites] = useState({});
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (teammates !== null && teammates !== undefined && teammates.length > 0) {
      const newInvites = {};
      teammates.forEach((teammate) => {
        if (teammate.TeammateId && !newInvites[teammate.TeammateId]) {
          newInvites[teammate.TeammateId] = false;
        }
      });
      setInvites(newInvites);
    }
  }, [teammates]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const mapAppAccessLevel = (
    appAccessLevel: FlipdishAPI.TeammateBase.AppAccessLevelEnum | undefined
  ): JSX.Element => {
    switch (appAccessLevel) {
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.Owner:
        return <Translate id="Owner" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.StoreOwner:
        return <Translate id="Store_owner" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.ManagedOwner:
        return <Translate id="Managed_owner" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.StoreManager:
        return <Translate id="Store_manager" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.StoreStaff:
        return <Translate id="Store_staff" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.FinanceManger:
        return <Translate id="Finance_manager" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.StoreReadOnlyAccess:
        return <Translate id="Store_readonly_access" />;
      case FlipdishAPI.TeammateBase.AppAccessLevelEnum.Integrator:
        return <Translate id="Integrator" />;
      default:
        return <Translate id="Owner" />;
    }
  };

  const resendInvite = (
    teammate: FlipdishAPI.Teammate,
    event: React.MouseEvent<HTMLSpanElement>
  ) => {
    event.preventDefault();

    if (!canEditPermission) {
      return;
    }

    setInvites({
      ...invites,
      [teammate.TeammateId as string]: true,
    });

    setShowSnackbar(true);

    reinviteTeammateAction(
      teammate.Email as string,
      teammate.AppAccessLevel as FlipdishAPI.Teammate.AppAccessLevelEnum,
      teammate.StoreIds || []
    );
  };

  const invitePending = (teammate) => {
    return (
      teammate.InvitationStatus === FlipdishAPI.Teammate.InvitationStatusEnum.Pending &&
      teammate.TeammateId &&
      !invites[teammate.TeammateId]
    );
  };

  const getRowLink = (isMe, teammate) =>
    isMe || !canEditPermission
      ? undefined
      : '/' +
        (currentApp != null ? currentApp.AppId : 'global') +
        '/teammates/' +
        teammate.TeammateId;

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'teammate.*' }}
      title={translate('Teammates')}
      documentTitle="Teammates"
    >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          aria-describedby="client-snackbar"
          message={
            <div id="client-snackbar" className={classes.snackbarSpan}>
              <DoneIcon className={classes.snackbarIcon} />
              <Translate id="Invite_resent" />
            </div>
          }
        />
      </Snackbar>
      <PaperContainer fluid>
        <Hidden mdDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {translate('Email')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {translate('Name')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {translate('Role')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {translate('Last_active')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teammatesLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    className={clsx(classes.bodyCell, classes.bodyCellWithPadding, classes.cell)}
                  >
                    <AppLoadingCircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                teammates.map((teammate, index) => {
                  const isMe =
                    currentApp.AppAccessLevel !== FlipdishAPI.App.AppAccessLevelEnum.Owner &&
                    teammate.AppAccessLevel === FlipdishAPI.App.AppAccessLevelEnum.Owner;

                  return (
                    <TableRow
                      key={index}
                      className={clsx(classes.row, {
                        [classes.rowLink]: !isMe && canEditPermission,
                      })}
                      data-fd={`teammate_${teammate.Email}`}
                    >
                      <TableCellLink
                        component="th"
                        scope="row"
                        to={getRowLink(isMe, teammate)}
                        className={clsx(classes.bodyCell, classes.cell)}
                      >
                        <span>{teammate.Email}</span>
                      </TableCellLink>
                      <TableCellLink
                        to={getRowLink(isMe, teammate)}
                        className={clsx(classes.bodyCell, classes.cell)}
                      >
                        <span>{teammate.Name}</span>
                      </TableCellLink>
                      {invitePending(teammate) && isMobile ? (
                        <TableCellLink
                          to={getRowLink(isMe, teammate)}
                          className={clsx(
                            classes.bodyCell,
                            classes.cell,
                            classes.bodyCellWithPadding
                          )}
                        >
                          {mapAppAccessLevel(teammate.AppAccessLevel)}
                        </TableCellLink>
                      ) : (
                        <TableCellLink
                          to={getRowLink(isMe, teammate)}
                          className={clsx(classes.bodyCell, classes.cell)}
                        >
                          <span>{mapAppAccessLevel(teammate.AppAccessLevel)}</span>
                        </TableCellLink>
                      )}
                      {invitePending(teammate) ? (
                        <TableCellLink
                          to={getRowLink(isMe, teammate)}
                          className={clsx(
                            classes.bodyCell,
                            classes.cell,
                            classes.bodyCellWithPadding
                          )}
                        >
                          {teammate.LastActivity
                            ? capitalizeFirst(
                                formatDistanceToNow(
                                  new Date(
                                    dtUtils.utcToZonedTime(
                                      teammate.LastActivity,
                                      dtUtils.userIanaTimeZone
                                    )
                                  )
                                )
                              )
                            : translate('Never')}
                          {canEditPermission && (
                            <span
                              className={classes.resendInvite}
                              onClick={(event) => resendInvite(teammate, event)}
                              data-fd={`teammate-reinvite-${teammate.TeammateId}`}
                            >
                              {translate('Resend_invite')}
                            </span>
                          )}
                        </TableCellLink>
                      ) : (
                        <TableCellLink
                          to={getRowLink(isMe, teammate)}
                          className={clsx(classes.bodyCell, classes.cell)}
                        >
                          <span>
                            {teammate.LastActivity
                              ? capitalizeFirst(
                                  formatDistanceToNow(new Date(teammate.LastActivity))
                                )
                              : translate('Never')}
                            {teammate.InvitationStatus ===
                            FlipdishAPI.Teammate.InvitationStatusEnum.Pending ? (
                              <span className={classes.inviteResent}>
                                <Translate id="Invite_resent" />
                              </span>
                            ) : null}
                          </span>
                        </TableCellLink>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Hidden>
        <Hidden mdUp>
          <ListMobile
            resendInvite={resendInvite}
            teammates={teammates}
            invitePending={invitePending}
            mapAppAccessLevel={mapAppAccessLevel}
          />
        </Hidden>
      </PaperContainer>

      <GridContainer>
        {!teammates.length && canEditPermission && !teammatesTimestamp ? (
          <EmptyComponent
            title="Invite_teammates"
            subtitle="Access_stores"
            noLink
            icon={EmptyIcon}
          />
        ) : null}
      </GridContainer>

      <Permissions allowed={[AppResourceSetEnum.EditTeammates]}>
        <Fab
          variant="extended"
          color="secondary"
          className={classes.button}
          to={'/' + (currentApp != null ? currentApp.AppId : 'global') + '/teammates/new'}
          aria-label="Invite"
          data-fd="teammate-invite"
          component={FabButtonLink}
        >
          <AddIcon className={classes.extendedIcon} />
          {translate('Invite')}
        </Fab>
      </Permissions>
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['EditTeammates']);

  return (state) => {
    const { locale } = state;

    return {
      dtUtils: dateTimeUtils(state),
      canEditPermission: getPermissionsSelector(state),
      currentApp: state.currentApp,
      teammates: state.teammates?.teammates,
      teammatesLoading: state.teammates?.teammateLoading,
      teammatesTimestamp: getTeammatesTimestamp(state),
      translate: getTranslate(locale),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    reinviteTeammateAction: (email, appAccessLevel, storeIds) => {
      dispatch(reinviteTeammate(email, appAccessLevel, storeIds));
    },
  };
};

export default connect(mapStateToPropsFactory, mapDispatchToProps)(List);
