import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Button } from '../Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3.7),
  },
}));

type Props = {
  leftButtonTranslationId: TranslationId;
  leftButtonAction: () => void;
  leftDisabled?: boolean;
  rightButtonTranslationId: TranslationId;
  rightButtonAction: () => void;
  fdKey?: string;
  rightDisabled?: boolean;
};

const ModalButtons = ({
  leftButtonTranslationId,
  leftButtonAction,
  rightButtonAction,
  rightButtonTranslationId,
  fdKey,
  leftDisabled,
  rightDisabled,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="flex-end" className={classes.root} data-fd={fdKey}>
      <Grid item>
        <Button
          color="primary"
          variant="text"
          onClick={leftButtonAction}
          fdKey="modal-left-button"
          disabled={leftDisabled}
        >
          <Translate id={leftButtonTranslationId} />
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={rightButtonAction}
          fdKey="modal-right-button"
          disabled={rightDisabled}
        >
          <Translate id={rightButtonTranslationId} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ModalButtons;
