import * as FlipdishAPI from '@flipdish/api-client-typescript';

import { ASSIGN_MENU_SUCCESS } from '../actions/store.actions';
import { menusConstants } from '../constants/menus.constants';

interface IMenusState {
  // For menus on menus screen
  menus: FlipdishAPI.MenuSummary[];
  menusTimestamp: number | null;
  menusAppId: string | null;

  // For individual menu on menu screen
  menu: FlipdishAPI.Menu | null;
  menuTimestamp: number | null;
  menuCheckpoints: FlipdishAPI.MenuCheckpoint[];
  menuCheckpointsTimestamp: number | null;
  menuStores: FlipdishAPI.MenuStoreNames[];
  menuStoresTimestamp: number | null;
  menuTaxDetails: FlipdishAPI.MenuTaxDetails | null;
  menuTaxDetailsTimestamp: number | null;
  menuTaxSavingTimestamp: number | null;
}

const menusInitialState: IMenusState = {
  // For menus on menus screen
  menus: [],
  menusTimestamp: null,
  menusAppId: null,

  // For individual menu on menu screen
  menu: null,
  menuTimestamp: null,
  menuCheckpoints: [],
  menuCheckpointsTimestamp: null,
  menuStores: [],
  menuStoresTimestamp: null,
  menuTaxDetails: null,
  menuTaxDetailsTimestamp: null,
  menuTaxSavingTimestamp: null,
};

export function menus(state = menusInitialState, action) {
  let oldMenuIndex = -1;
  let menuIndex = -1;
  let newMenus = [] as FlipdishAPI.MenuSummary[];

  switch (action.type) {
    case menusConstants.SET_MENUS_APP_ID:
      return {
        ...state,
        menusAppId: action.payload,
      };
    case menusConstants.GET_MENUS_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        menusTimestamp: null,
      };
    case menusConstants.SET_MENUS_TIMESTAMP:
      return {
        ...state,
        menusTimestamp: action.payload,
      };
    case menusConstants.GET_MENU:
      return {
        ...state,
        menu: action.payload,
        menuTimestamp: null,
      };
    case menusConstants.SET_MENU_TIMESTAMP:
      return {
        ...state,
        menuTimestamp: action.payload,
      };
    case menusConstants.SET_MENU_CHECKPOINTS_TIMESTAMP:
      return {
        ...state,
        menuCheckpointsTimestamp: action.payload,
      };
    case menusConstants.GET_MENU_CHECKPOINTS:
      return {
        ...state,
        menuCheckpoints: action.payload,
        menuCheckpointsTimestamp: null,
      };
    case menusConstants.SET_MENU_STORES_TIMESTAMP:
      return {
        ...state,
        menuStoresTimestamp: action.payload,
      };
    case menusConstants.GET_MENU_STORES:
      return {
        ...state,
        menuStores: action.payload,
        menuStoresTimestamp: null,
      };
    case menusConstants.SET_MENU_TAX_DETAILS_TIMESTAMP:
      return {
        ...state,
        menuTaxDetailsTimestamp: action.payload,
      };
    case menusConstants.SET_MENU_TAX_SAVING_TIMESTAMP:
      return {
        ...state,
        menuTaxSavingTimestamp: action.payload,
      };
    case menusConstants.GET_MENU_TAX_DETAILS: {
      const newPayload = {
        ...action.payload,
        TaxType:
          action.payload.TaxType === 1
            ? FlipdishAPI.MenuTaxDetails.TaxTypeEnum.ExcludedFromBasePrice
            : FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice,
      };
      return {
        ...state,
        menuTaxDetails: newPayload,
        menuTaxDetailsTimestamp: null,
      };
    }
    case menusConstants.DELETE_MENU: {
      const filteredMenus = state.menus.filter((menu) => {
        return (menu as any).MenuId != action.payload;
      });
      return {
        ...state,
        menus: filteredMenus,
      };
    }
    case menusConstants.RESTORE_MENU:
      return {
        ...state,
        menus: [
          ...state.menus.slice(0, action.payload.menuIndex),
          action.payload.menu,
          ...state.menus.slice(action.payload.menuIndex),
        ],
      };
    case menusConstants.SET_MENU_NAME_SUCCESS: {
      const menuIndex = state.menus.findIndex((menu) => menu.MenuId == action.payload.menuId);

      return {
        ...state,
        menus: [
          ...state.menus.slice(0, menuIndex),
          {
            ...state.menus[menuIndex],
            Name: action.payload.name,
          },
          ...state.menus.slice(menuIndex + 1),
        ],
        menu: {
          ...state.menu,
          Name: action.payload.name,
        },
      };
    }
    case menusConstants.SET_LOCK:
      return {
        ...state,
        menu: {
          ...state.menu,
          Locked: action.payload,
        },
      };
    case menusConstants.SET_MENU_EXPAND_BEHAVIOUR:
      return {
        ...state,
        menu: {
          ...state.menu,
          MenuSectionBehaviour: action.payload,
        },
      };
    case menusConstants.INCLUDE_MENU_TAX:
      return {
        ...state,
        menuTaxDetails: {
          ...state.menuTaxDetails,
          TaxType: action.payload,
        },
      };
    case menusConstants.SHOW_MENU_TAX:
      return {
        ...state,
        menuTaxDetails: {
          ...state.menuTaxDetails,
          DisplayTax: action.payload,
        },
      };
    case ASSIGN_MENU_SUCCESS:
      oldMenuIndex = state.menus.findIndex(
        (m) =>
          (m.StoreNames || []).findIndex((storeName) => storeName === action.payload.storeName) !=
          -1
      );
      menuIndex = state.menus.findIndex((m) => m.MenuId === action.payload.menuId);
      newMenus =
        oldMenuIndex !== -1
          ? [
              ...state.menus.slice(0, oldMenuIndex),
              {
                ...state.menus[oldMenuIndex],
                StoreNames: (state.menus[oldMenuIndex].StoreNames || []).filter(
                  (storeName) => storeName !== action.payload.storeName
                ),
              },
              ...state.menus.slice(oldMenuIndex + 1),
            ]
          : [...state.menus];
      if (menuIndex !== -1) {
        newMenus = [
          ...newMenus.slice(0, menuIndex),
          {
            ...newMenus[menuIndex],
            StoreNames: [
              ...(newMenus[menuIndex].StoreNames || []),
              action.payload.storeName || `Store ${action.payload.storeId}`,
            ],
          },
          ...newMenus.slice(menuIndex + 1),
        ].sort((a, b) => (b.StoreNames || []).length - (a.StoreNames || []).length);
      }
      return {
        ...state,
        menus: newMenus,
        menuStores: [...state.menuStores, action.payload.storeName || `Store ${action.payload.storeId}`],
      };
    default:
      return state;
  }
}
