import { ServerError } from '../models/serverErrors';
import { usePrevious } from './usePrevious';

export const useLoadingAsIndicator = (value?: boolean): boolean => {
  const prevValue = usePrevious(value);

  return !!prevValue && value === false;
};

export const useErrorAsIndicator = (value?: string | Error | ServerError): boolean => {
  const prevValue = usePrevious(value);

  return !prevValue && !!value;
};
