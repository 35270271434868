import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type FieldProps, Field } from 'formik';

import FormItemLayout from '../../../../../ui/Forms/FormItemLayout';

export type InputFieldProps = {
  fieldData: FdField;
  decimal?: boolean;
  multiline?: boolean;
  type?: 'number' | 'text';
};

const SelectField = ({ fieldData }: InputFieldProps) => (
  <Field name={fieldData.Key}>
    {({ field, form }: FieldProps) => {
      const { errors, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;

      return (
        <FormItemLayout
          label={fieldData.Name}
          description={fieldData.Description}
          showTooltip={!!(fieldData.Tooltip && fieldData.Tooltip.length)}
          tooltipOptions={{
            fdKey: 'appstore_config_input_tooltip',
            labelText: fieldData.Tooltip,
          }}
        >
          <FormControl error={fieldError != undefined}>
            <Select
              {...field}
              variant="standard"
              disabled={isSubmitting}
              data-fd={'appstore_select'}
              value={field.value || ''}
            >
              {fieldData.ValidValues!.map((validValue) => (
                <MenuItem key={validValue.Code} value={validValue.Code}>
                  {validValue.Name}
                </MenuItem>
              ))}
            </Select>
            {fieldError && <FormHelperText>{fieldError}</FormHelperText>}
          </FormControl>
        </FormItemLayout>
      );
    }}
  </Field>
);

export default SelectField;
