import React from 'react';

import * as FlipdishApi from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Select from '@fd/ui/Select/Select';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { getAllStoresOptionItem } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    marginTop: theme.spacing(1.5),
  },
}));

type StoreOption = {
  label: string;
  value: number | string;
};

type InnerProps = ReturnType<typeof mapStateToProps>;
type OuterProps = {
  dataFd: string;
  disabled?: boolean;
  handleScroll?: any;
  handleStoresChange: (permissionLevel: FlipdishApi.TeammateBase.AppAccessLevelEnum) => any;
  isLoading?: boolean;
  isNewUser?: boolean;
  onSearchQueryChange?: (eventValue: string) => void;
  permissionLevel: FlipdishApi.TeammateBase.AppAccessLevelEnum;
  stores?: StoreOption[] | null;
  storesName: string;
  suggestions: StoreOption[];
  hasAccessToAllStores?: boolean;
};
type Props = InnerProps & OuterProps;

const ModifyPermissionLevelSelect = (props: Props) => {
  const classes = useStyles();
  const {
    dataFd,
    disabled,
    isLoading,
    isNewUser,
    handleScroll,
    handleStoresChange,
    onSearchQueryChange,
    permissionLevel,
    stores,
    suggestions,
    translate,
  } = props;

  const suggestionsWithAllStoresOption = [
    getAllStoresOptionItem(translate('All_stores') as string),
    ...suggestions,
  ];

  return (
    <ErrorBoundary identifier="modify-permission-level-select">
      <Box onScroll={handleScroll}>
        <Select
          className={classes.select}
          isDisabled={disabled}
          dataFd={dataFd}
          isClearable
          isMulti
          maxChips={3}
          onChange={disabled ? undefined : handleStoresChange(permissionLevel)}
          options={suggestionsWithAllStoresOption}
          placeholder={
            isNewUser ? (translate('Select_store(s)') as string) : (translate('None') as string)
          }
          setSearchQuery={onSearchQueryChange}
          TextFieldProps={{
            fdKey: 'stores-dropdown',
            label: translate('Stores') as TranslationId,
            name: 'stores-select',
          }}
          value={stores}
          variant="standard"
          isLoading={isLoading}
        />
      </Box>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(
  ModifyPermissionLevelSelect
);
