import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import CustomerMessageList from './components/CustomerMessageList';

const CustomerMessages = ({ currentBrandId, currentOrgId, translate }: MappedState) => {
  return (
    <PageLayout documentTitle="Customer_messages" title={translate('Customer_messages')}>
      <PaperContainer fluid>
        <CustomerMessageList
          ariaLabel={'Sales_channel_groups'}
          currentBrandId={currentBrandId}
          orgId={currentOrgId}
          translate={translate}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentBrandId: state.currentApp.AppId,
    currentOrgId: state.rms.currentOrg?.orgId,
  };
};

export default connect(mapStateToProps)(CustomerMessages);
