import * as React from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { menusActions } from '../../../actions/menus.actions';
import { MenuCheckpointsSelect } from './MenuCheckpointsSelect';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    menuCheckpoints: {
      [breakpoints.down('md')]: {
        boxShadow: 'none',
      },
    },
    menuCheckpointsHeader: {
      padding: '20px 24px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.21)',
      [breakpoints.down('md')]: {
        border: 'none',
        padding: '20px 12px 0 12px',
      },
    },
    menuCheckpointsHolder: {
      overflowY: 'scroll' as any,
      maxHeight: 'calc(100vh - 290px)',
    },
    button: {
      flex: 1,
      fontSize: '14px',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.14',
      letterSpacing: '1.3px',
    },
    restoreButton: {
      marginRight: '8px',
    },
    cancelButton: {
      marginLeft: '8px',
    },
    checkpointFiller: {
      height: '8px',
      marginLeft: '27px',
      borderLeft: '1px solid rgba(0, 0, 0, 0.21)',
    },
    checkpoint: {
      display: 'flex',
    },
    checkpointText: {
      padding: '12px 28px 12px 0',
    },
    checkpointRadioHolder: {
      width: '56px',
      display: 'flex',
      flexDirection: 'column' as any,
      position: 'relative' as any,
      justifyContent: 'center',
    },
    checkpointRadioLine: {
      marginLeft: '27px',
      borderLeft: '1px solid rgba(0, 0, 0, 0.21)',
      flex: 1,
    },
    checkpointRadioLineTop: {
      marginBottom: '-14px',
    },
    checkpointRadioLineBottom: {
      marginTop: '-14px',
    },
    checkpointHeading: {
      fontSize: '16px',
      color: 'rgba(0,0,0,0.87)',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
    },
    checkpointSubtitle: {
      fontSize: '12px',
      color: 'rgba(0,0,0,0.38)',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.33',
      letterSpacing: '0.4px',
    },
  });

const CheckpointFiller = compose<WithStyles<typeof styles>, {}>(
  withStyles(styles, {
    name: 'CheckpointFiller',
    withTheme: true,
  })
)(({ classes }) => <div className={classes.checkpointFiller} />);

export interface ICheckpointProps {
  MenuCheckpointId: number | undefined;
  Name: string | undefined;
  Time: Date | undefined;
  Index: number;
  selectedCheckpoint: FlipdishAPI.MenuCheckpoint;
  handleCheckpointChange: () => any;
  translate: any;
}

const Checkpoint = compose<WithStyles<typeof styles> & ICheckpointProps, ICheckpointProps>(
  withStyles(styles, {
    name: 'Checkpoint',
    withTheme: true,
  })
)(
  ({
    classes,
    MenuCheckpointId,
    Name,
    Time,
    Index,
    selectedCheckpoint,
    handleCheckpointChange,
    translate,
  }) => (
    <div className={classes.checkpoint} key={MenuCheckpointId}>
      <div className={classes.checkpointRadioHolder}>
        <div className={clsx(classes.checkpointRadioLine, classes.checkpointRadioLineTop)} />
        <Radio
          value={MenuCheckpointId}
          checked={MenuCheckpointId === selectedCheckpoint.MenuCheckpointId}
          color="primary"
          onChange={handleCheckpointChange}
          style={{ width: '56px' }}
          data-fd={`checkpoint-${MenuCheckpointId}`}
        />
        <div className={clsx(classes.checkpointRadioLine, classes.checkpointRadioLineBottom)} />
      </div>
      <div className={classes.checkpointText}>
        <Typography variant="h6" className={classes.checkpointHeading}>
          {`${moment(Time).format('MM/DD/YYYY hh:mm')}${
            Index === 0 ? ` (${translate('Current')})` : ''
          }`}
        </Typography>
        <Typography variant="subtitle1" className={classes.checkpointSubtitle}>
          {Name}
        </Typography>
      </div>
    </div>
  )
);

export interface ICheckpointsProps {
  toggleRevisionMode: () => any;
}

export interface IStateProps {
  menuCheckpoints: FlipdishAPI.MenuCheckpoint[];
  menu: FlipdishAPI.Menu;
  translate: any;
}

type Props = ICheckpointsProps &
  WithStyles<typeof styles> &
  IStateProps & { dispatch: ThunkDispatch };

class MenuCheckpoints extends React.Component<Props, any> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedCheckpoint: null,
    };
  }

  public componentDidMount() {
    const { menuCheckpoints } = this.props;

    if (menuCheckpoints[0]) {
      this.setState({
        selectedCheckpoint: menuCheckpoints[0],
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { menuCheckpoints } = newProps;

    if (menuCheckpoints != this.props.menuCheckpoints && menuCheckpoints[0]) {
      this.setState({
        selectedCheckpoint: menuCheckpoints[0],
      });
    }
  }

  public handleCheckpointChange = (checkpointId: number | undefined) => {
    if (!checkpointId) {
      return;
    }

    const newCheckpoint = this.props.menuCheckpoints.find(
      (checkpoint) => checkpoint.MenuCheckpointId === checkpointId
    );

    if (newCheckpoint) {
      this.setState({
        selectedCheckpoint: newCheckpoint,
      });
    }
  };

  public restoreMenuCheckpoint = () => {
    const { dispatch, menu } = this.props;
    const { selectedCheckpoint } = this.state;

    const menuId = menu.MenuId;
    const checkpointId = selectedCheckpoint.MenuCheckpointId;

    if (menuId && checkpointId) {
      dispatch(menusActions.restoreMenuCheckpoint(menuId, checkpointId));
    }
  };

  public render() {
    const { classes, toggleRevisionMode, menuCheckpoints, translate } = this.props;
    const { selectedCheckpoint } = this.state;

    const checkpoints: JSX.Element[] = [];

    for (let i = 0; i < menuCheckpoints.length; i++) {
      const checkpoint = menuCheckpoints[i];

      checkpoints.push(<CheckpointFiller key={`filler-${checkpoint.MenuCheckpointId}`} />);
      checkpoints.push(
        <Checkpoint
          key={`checkpoint-${checkpoint.MenuCheckpointId}`}
          MenuCheckpointId={checkpoint.MenuCheckpointId}
          Name={checkpoint.Name}
          Time={checkpoint.Time as unknown as Date}
          Index={i}
          selectedCheckpoint={this.state.selectedCheckpoint}
          handleCheckpointChange={() => this.handleCheckpointChange(checkpoint.MenuCheckpointId)}
          translate={translate}
        />
      );
    }

    if (menuCheckpoints.length > 0) {
      checkpoints.push(<CheckpointFiller key="final-filler" />);
    }

    return (
      <Paper className={classes.menuCheckpoints}>
        <Hidden lgUp>
          <MenuCheckpointsSelect
            selectedCheckpoint={selectedCheckpoint}
            handleCheckpointChange={this.handleCheckpointChange}
          />
        </Hidden>
        <div className={classes.menuCheckpointsHeader}>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, classes.restoreButton)}
            onClick={this.restoreMenuCheckpoint}
            data-fd="checkpoints-restore"
          >
            {translate('Restore')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={clsx(classes.button, classes.cancelButton)}
            onClick={toggleRevisionMode}
            data-fd="checkpoints-cancel"
          >
            {translate('Cancel')}
          </Button>
        </div>
        <Hidden lgDown>
          <div className={classes.menuCheckpointsHolder}>{checkpoints}</div>
        </Hidden>
      </Paper>
    );
  }
}

function mapStateToProps(state: AppState) {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
    menuCheckpoints: state.menus.menuCheckpoints,
    menu: state.menus.menu,
  };
}

const EnhancedComponent = compose<{}, ICheckpointsProps>(
  withStyles(styles, {
    name: 'MenuEditControls',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(MenuCheckpoints);

export { EnhancedComponent as Checkpoints };
