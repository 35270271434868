import { combineReducers } from 'redux';

import { accountConstants } from '../../constants/account.constants';
import { Connection, startConnection } from '../../services/signalr';
import { hubConstants, hubNames } from '../../signalr/hub.constants';
import { analyticsHub } from './analyticsHub.reducer';
import { customerHub } from './customerHub.reducer';
import { orderHub } from './orderHub.reducer';

const initialState = {
  active: Connection,
  subscriptions: new Array<string>(),
};

export const rootSignalR = combineReducers({
  connection: signalr,
  [hubNames.ORDER_HUB]: orderHub,
  [hubNames.CUSTOMER_HUB]: customerHub,
  [hubNames.ANALYTICS_HUB]: analyticsHub,
});

function signalr(state = initialState, action) {
  const { subscriptions } = state;
  switch (action.type) {
    case accountConstants.GET_ACCOUNT_SUCCESS:
      startConnection();
      return { ...state, active: Connection };
    case hubConstants.SIGNALR_SUBSCRIBED:
      return {
        ...state,
        subscriptions: [...subscriptions, action.event as string],
      };
    case hubConstants.SIGNALR_UNSUBSCRIBED: {
      const newSubscriptions = subscriptions.map((r) => r);
      const index: number = newSubscriptions.indexOf(action.event, 0);
      if (index > -1) {
        newSubscriptions.splice(index, 1);
        return { ...state, subscriptions: newSubscriptions };
      }
      return state;
    }
    default:
      return state;
  }
}
