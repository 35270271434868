import React from 'react';

import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';

import ErrorComponent from '@fd/ui/ErrorComponent';

import { pricingInformation } from '../data/PricingInformation';
import FeaturePricingInformation from './FeaturePricingInformation';
import FeatureUsage from './FeatureUsage';

type FeaturePricingInformationProps = {
  appStoreAppId: string;
  entitlementCode: string;
  countryCode?: string;
  getUsage?: () => Promise<{ curentUsage: number; entitlementQuantity: number }>;
};

type Props = FeaturePricingInformationProps & MappedState;

const FeaturePortal = ({ entitlementCode, countryCode, getUsage, appId, appStoreAppId }: Props) => {
  const pricingOption = pricingInformation.find(
    (i) =>
      i.entitlementCode.toLowerCase() === entitlementCode.toLowerCase() &&
      (countryCode ? i.countryCode.toLowerCase() === countryCode.toLowerCase() : true)
  );

  if (!pricingOption) {
    return (
      <ErrorComponent
        title="Error_please_try_again_later"
        subtitle="PricingTableError_No_Pricing_Options"
      />
    );
  }

  return (
    <Grid container spacing={2} data-fd="feature-portal">
      <Grid item xs={12} md={6}>
        <FeatureUsage entitlementCode={entitlementCode} getUsage={getUsage} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FeaturePricingInformation
          entitlementPricing={pricingOption}
          returnPathOverride={`/${appId}/appstore/${appStoreAppId}`}
        />
      </Grid>
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId!,
  };
};

export default connect(mapStateToProps)(FeaturePortal);
