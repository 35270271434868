import * as React from 'react';

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { createStoreSelectorById } from '../../selectors/store.selector';
import { Store } from './types';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: IWithStoreByIdDataProps) => {
  const { storeId } = ownProps;
  const storeByIdSelector = createStoreSelectorById(storeId);
  return (state: AppState, ownProps) => {
    const data = storeByIdSelector(state) as Store | undefined;
    return {
      storeByIdData: data,
    };
  };
};

type UnpackType<T> = T extends React.ComponentType<React.PropsWithChildren<infer P>> ? P : T;
export type WithStoreByIdData = UnpackType<ReturnType<typeof withStoreByIdData>> & {
  dispatch: ThunkDispatch;
};
export interface IWithStoreByIdDataProps {
  storeId: number;
}

const withStoreByIdData = <P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<P & MappedState>>
) =>
  compose<P & MappedState, P & MappedState & IWithStoreByIdDataProps>(
    setDisplayName('WithStoreByIdData'),
    connect(mapStateToPropsFactory, null)
  )(Component);

export default withStoreByIdData;
