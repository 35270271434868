import React, { useEffect, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as SupportIcon } from '../../assets/images/menu_support.svg';
import { RequestAppAccessDrawer } from './RequestAppAccessDrawer';
import { SupportFeature } from './SupportFeature';

export const SupportMenu = () => {
  const [open, setOpen] = useState(false);
  const [prevKey, setPrevKey] = useState('');
  const [keyPressed, setKeyPressed] = useState('');
  const toggleSupportMenu = () => {
    setOpen(!open);
  };

  const keyPressHandler = React.useCallback((e: KeyboardEvent) => {
    const { key } = e;
    setKeyPressed(key);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  useEffect(() => {
    if (keyPressed === ']' && prevKey === 'Control') {
      toggleSupportMenu();
    }
    setPrevKey(keyPressed);
  }, [keyPressed]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const renderRequestAccessMenu = () => {
    return (
      <div>
        <SupportFeature>
          <IconButton onClick={toggleSupportMenu}>
            <SupportIcon data-fd="support-icon" />
          </IconButton>
          <RequestAppAccessDrawer open={open} toggleMenuVisibility={toggleSupportMenu} />
        </SupportFeature>
      </div>
    );
  };
  if (isMobile) {
    return renderRequestAccessMenu();
  }
  return (
    <ClickAwayListener onClickAway={handleClickAway}>{renderRequestAccessMenu()}</ClickAwayListener>
  );
};
