import React, { useState } from 'react';

import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { type RouteComponentProps, withRouter } from 'react-router';

import generateSrcSet from '@fd/ui/utils/generateSrcSet';

import { ReactComponent as ForkIcon } from '../../../assets/images/app_logo_fork.svg';
import { appsService } from '../../../services/apps.service';
import { AppLoadingCircularProgress } from '../../AppLoadingCircularProgress';
import { RequestAppAccessSearch } from './RequestAppAccessSearch';

const drawerWidth = 304;
const LIMIT = 50;
const useStyles = makeStyles((theme: Theme) => ({
  appName: {
    wordBreak: 'break-all',
    color: ' rgba(0, 0, 0, 0.87)',
  },
  root: {
    position: 'fixed',
    zIndex: 11,
  },
  drawer: {
    width: drawerWidth,
    wordBreak: 'break-all',
  },
  icon: {
    width: 40,
    height: 40,
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },

    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    right: -drawerWidth,
  },
}));

type Props = {
  open: boolean;
  toggleMenuVisibility: () => void;
};

const RequestAppAccessDrawer = (props: Props & RouteComponentProps) => {
  const { history, open, toggleMenuVisibility } = props;
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isFetchingNextPage, isPending, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['getSupportedApps', searchQuery, LIMIT],
    queryFn: ({ pageParam }) => appsService.getSupportApps(searchQuery, pageParam, LIMIT),
    initialPageParam: 1,
    enabled: true,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: (appId: string) => {
      return appsService.startAppSupport(appId);
    },

    onSuccess: (data) => {
      const pathArray = history.location.pathname.split('/');
      if (data && data.AppId) {
        pathArray[1] = data.AppId;
        if (!pathArray.includes('website') && pathArray.length > 2) {
          pathArray.splice(3);
        }
        history.push(pathArray.join('/'));
      }
    },

    onError: (error) => {
      console.log(error);
    },
  });

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;
    if (hasScrollBar && hasNextPage && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchNextPage();
    }
  };

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      anchor="right"
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      PaperProps={{ elevation: open ? 10 : 0 }}
      open={open}
      onScroll={handleScroll}
      onClose={toggleMenuVisibility}
    >
      <RequestAppAccessSearch open={open} onChange={(value) => setSearchQuery(value)} />
      {data?.pages
        ? data.pages.map((page) => {
            return page.Data.map((app) => {
              return (
                <ListItem button key={app.AppId} onClick={() => mutateAsync(app.AppId)}>
                  <ListItemIcon>
                    {app.LogoImageUrl ? (
                      <img
                        className={classes.icon}
                        srcSet={generateSrcSet(app.LogoImageUrl, {
                          width: 40,
                          png: true,
                        })}
                        src={app.LogoImageUrl}
                      />
                    ) : (
                      <ForkIcon className={classes.icon} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      display: 'block',
                      className: classes.appName,
                    }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                    primary={app.Name}
                    secondary={app.AppId}
                  />
                </ListItem>
              );
            });
          })
        : null}
      {(isPending || isFetchingNextPage) && <AppLoadingCircularProgress />}
    </Drawer>
  );
};
const EnhancedComponent = withRouter(RequestAppAccessDrawer);
export { EnhancedComponent as RequestAppAccessDrawer };
