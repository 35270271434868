import React from 'react';

import { MetafieldDefinition } from '@flipdish/api-client-typescript';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles(() => ({
  cell: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  nameCell: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  row: {
    '&:hover': {
      background: '#eaf2ff',
    },
  },
}));

export type MetafieldsTableProps = {
  fdKey: string;
  isLoading?: boolean;
  metafields?: MetafieldDefinition[];
};

const MetafieldsTable = ({
  fdKey,
  history,
  isLoading,
  metafields,
}: MetafieldsTableProps & RouteComponentProps) => {
  const classes = useStyles();

  const renderSkeletonRows = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <TableRow key={`skeleton-row-${i}`}>
          <TableCell>
            <SkeletonLoader
              fdKey={`skeleton-name-${i}`}
              rows={[{ height: '34px', width: '50%' }]}
            />
          </TableCell>
          <TableCell>
            <SkeletonLoader
              fdKey={`skeleton-type-${i}`}
              rows={[{ height: '34px', width: '100%' }]}
            />
          </TableCell>
          <TableCell align="right">
            <SkeletonLoader
              fdKey={`skeleton-usage-${i}`}
              rows={[{ height: '34px', width: '50%' }]}
            />
          </TableCell>
        </TableRow>
      );
    }
    return rows;
  };

  const renderMetafieldRows = () => {
    return metafields?.map((metafield) => {
      const url = `${window.location.pathname}/${metafield.Key}`;
      return (
        <TableRow className={classes.row} key={metafield.Name}>
          <TableCell className={classes.nameCell}>
            <Link className={classes.link} to={url} />
            {metafield.Name}
            <Typography variant="caption">{metafield.Key}</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Link className={classes.link} to={url} />
            {metafield.ValueType}
          </TableCell>
          <TableCell align="right" className={classes.cell}>
            <Link className={classes.link} to={url} />
            00
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Paper>
      <Table data-fd={fdKey}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate id="Definition_name" />
            </TableCell>
            <TableCell>
              <Translate id="Type" />
            </TableCell>
            <TableCell align="right">
              <Translate id="Usage" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{isLoading ? renderSkeletonRows() : renderMetafieldRows()}</TableBody>
      </Table>
    </Paper>
  );
};

const EnhancedComponent = withRouter(MetafieldsTable);
export { EnhancedComponent as MetafieldsTable };
