import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

type StyleProps = {
  marginTop?: number;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  gridItem: {
    marginTop: (props) => props.marginTop ?? 0,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const GridDivider = ({ marginTop = 0 }) => {
  const classes = useStyles({ marginTop });

  return (
    <Grid item xs={12} className={classes.gridItem}>
      <Divider />
    </Grid>
  );
};

export default GridDivider;
