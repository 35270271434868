import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import EditPushNotification from './components/EditPushNotification';
import PushNotifications from './PushNotifications';

const PushNotificationsRoutes = (props: RouteComponentProps) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="push-notifications">
      <Switch>
        <Route exact path={`${path}`} component={PushNotifications} />
        <Route exact path={`${path}/:id`} component={EditPushNotification} />
      </Switch>
    </ErrorBoundary>
  );
};

export default PushNotificationsRoutes;
