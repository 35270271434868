import React from 'react';

import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Skeleton from 'react-loading-skeleton';

import Spacer from '../../../ui/Spacer';

const ListItemLoading = () => {
  return (
    <ListItem component="div">
      <Grid container alignContent="flex-end">
        <Grid item xs={12} md={8}>
          <Typography component="span" variant="h5">
            <Skeleton width={'60%'} />
          </Typography>
          <Typography component="span" variant="body1">
            <Skeleton width={'50%'} height={16} />
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs sm={6} md={8}>
              <Spacer size={8} variant="horizontal">
                <Skeleton width={'100%'} height={36} />
              </Spacer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ListItemLoading;
