// import * as FlipdishAPI from '@flipdish/api-client-typescript';
import { subscriptionConstants } from '../constants/subscription.constants';

interface ISubscriptionState {
  loading: boolean;
  stripeData: any;
}

export type SubscriptionState = any & ISubscriptionState;

const subscriptionReducerDefault = {
  SubscriptionId: -1,
  StartDate: new Date(),
  Status: undefined,
  Quantity: undefined,
  AppId: undefined,
  Card: undefined,
  SubscriptionPlan: undefined,
  SubscriptionPlanId: undefined,
  UserId: undefined,
  VatCountryCode: undefined,
  VatNumber: undefined,

  stripeData: undefined,

  loading: true,
};

export default (state = subscriptionReducerDefault, action) => {
  switch (action.type) {
    case subscriptionConstants.CREATE_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case subscriptionConstants.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.subscription,
        loading: false,
      };
    case subscriptionConstants.CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case subscriptionConstants.FETCH_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case subscriptionConstants.FETCH_SUBSCRIPTION_SUCCESS_FOUND:
      return {
        ...state,
        ...action.subscription,
        loading: false,
      };
    case subscriptionConstants.FETCH_SUBSCRIPTION_SUCCESS_NOT_FOUND:
      return {
        ...state,
        loading: false,
      };
    case subscriptionConstants.FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case subscriptionConstants.FETCH_PLANS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case subscriptionConstants.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        stripeData: {
          ...action.stripeData,
        },
        loading: true,
      };
    case subscriptionConstants.FETCH_PLANS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case subscriptionConstants.CARD_REPLACED:
      return {
        ...state,
        Card: {
          ...action.newCard,
        },
      };
    // case subscriptionConstants.SUBSCRIPTION_CANCELED:
    //   return {
    //     ...state,
    //     Status: FlipdishAPI.Subscription.StatusEnum.Canceled
    //   };
    // case subscriptionConstants.SUBSCRIPTION_UPDATED:
    //   return {
    //     ...state,
    //     Quantity: action.quantity,
    //     SubscriptionPlanId: action.subscriptionPlanId
    //   };
    default:
      return state;
  }
};
