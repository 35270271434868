import React, { useEffect, useRef, useState } from 'react';

import { ValidityPeriod } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field, useField } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms';
import Tag from '@fd/ui/atoms/Tag';
import Select, { OptionType as DayOption } from '@fd/ui/Select/Select';
import Switch from '@fd/ui/Switch/Switch';
import TimePicker from '@fd/ui/TimePicker';

import FormItemLayout from '../../FormItemLayout';

const DEFAULT_TIME = '00:00';
const EMPTY_HELPER_TEXT = ' ';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(1.5),
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
}));

export type Props = {
  translate: TranslateFunction;
};

const ValidityPeriodsField = (props: Props) => {
  const classes = useStyles();
  const { translate } = props;

  const [field, _meta, helpers] = useField('ValidityPeriods');

  const getEverydayValue = (): DayOption[] => [
    {
      label: translate('Day_Everyday') as string,
      value: 'Everyday',
      isNonClearable: true,
    },
  ];

  const getDayLabel = (day: ValidityPeriod.DayOfWeekEnum): string => {
    const dayLabelMap: { [key in ValidityPeriod.DayOfWeekEnum]: TranslationId } = {
      [ValidityPeriod.DayOfWeekEnum.Monday]: 'Day_Monday',
      [ValidityPeriod.DayOfWeekEnum.Tuesday]: 'Day_Tuesday',
      [ValidityPeriod.DayOfWeekEnum.Wednesday]: 'Day_Wednesday',
      [ValidityPeriod.DayOfWeekEnum.Thursday]: 'Day_Thursday',
      [ValidityPeriod.DayOfWeekEnum.Friday]: 'Day_Friday',
      [ValidityPeriod.DayOfWeekEnum.Saturday]: 'Day_Saturday',
      [ValidityPeriod.DayOfWeekEnum.Sunday]: 'Day_Sunday',
    };

    return translate(dayLabelMap[day]) as string;
  };

  const getDayOptions = (): DayOption[] => {
    return Object.values(ValidityPeriod.DayOfWeekEnum).map((day) => ({
      value: day,
      label: getDayLabel(day as ValidityPeriod.DayOfWeekEnum),
    }));
  };

  const getInitialDaysValue = (validityPeriods: ValidityPeriod[]): DayOption[] => {
    const isDefaultEveryday = validityPeriods.length === 0;
    if (isDefaultEveryday) {
      return getEverydayValue();
    }

    return validityPeriods.map((validityPeriod: ValidityPeriod) => ({
      value: validityPeriod.DayOfWeek as ValidityPeriod.DayOfWeekEnum,
      label: getDayLabel(validityPeriod.DayOfWeek as ValidityPeriod.DayOfWeekEnum),
    }));
  };

  const getInitialStartTimeValue = (validityPeriods: ValidityPeriod[]): string => {
    const isDefaultEveryday = validityPeriods.length === 0;
    if (isDefaultEveryday) {
      return DEFAULT_TIME;
    }

    // For the current implementation, we only need the first element
    return validityPeriods[0].StartTime as string;
  };

  const getInitialFinishTimeValue = (validityPeriods: ValidityPeriod[]): string => {
    const isDefaultEveryday = validityPeriods.length === 0;
    if (isDefaultEveryday) {
      return DEFAULT_TIME;
    }

    // For the current implementation, we only need the first element
    return validityPeriods[0].EndTime as string;
  };

  const [isEnabled, setIsEnabled] = useState<boolean>(field.value.length > 0);
  const [days, setDays] = useState<DayOption[]>(() => getInitialDaysValue(field.value));
  const [startTime, setStartTime] = useState<string>(() => getInitialStartTimeValue(field.value));
  const [finishTime, setFinishTime] = useState<string>(() =>
    getInitialFinishTimeValue(field.value)
  );
  const originalValidityPeriods = useRef<ValidityPeriod[]>(field.value);
  const isEveryday = days.length === 1 && days[0].value === 'Everyday';
  const isClearable = days[0].value !== 'Everyday';

  useEffect(() => {
    const setEverydayWithTime = () =>
      helpers.setValue(
        Object.values(ValidityPeriod.DayOfWeekEnum).map((day) => ({
          DayOfWeek: day as ValidityPeriod.DayOfWeekEnum,
          StartTime: startTime,
          EndTime: finishTime,
        }))
      );
    const setEveryday = () => helpers.setValue([]);
    const setDaysWithTime = () =>
      helpers.setValue(
        days.map((day) => ({
          DayOfWeek: day.value as ValidityPeriod.DayOfWeekEnum,
          StartTime: startTime,
          EndTime: finishTime,
        }))
      );

    const isEverydayWithTime =
      isEveryday && (startTime !== DEFAULT_TIME || finishTime !== DEFAULT_TIME);
    if (isEverydayWithTime) {
      setEverydayWithTime();
    } else if (isEveryday) {
      setEveryday();
    } else {
      setDaysWithTime();
    }
  }, [days.length, isEveryday, startTime, finishTime]);

  const validate = (validityPeriods: ValidityPeriod[]) => {
    const isValidEveryday = validityPeriods.length === 0;
    if (isValidEveryday) {
      return;
    }

    const isValidStartTime = !!validityPeriods[0]?.StartTime;
    const isValidFinishTime = !!validityPeriods[0]?.EndTime;
    if (!isValidFinishTime || !isValidStartTime) {
      return 'Required';
    }
  };

  return (
    <Field name={'ValidityPeriods'} validate={validate}>
      {({ form }: FieldProps) => {
        const { errors, isSubmitting } = form;

        const fieldError = errors[field.name] as string | undefined;
        const isStartTimeError = startTime === '' && !!fieldError;
        const isFinishTimeError = finishTime === '' && !!fieldError;
        const isDisabled = !isEnabled || isSubmitting;

        const handleEnableChange = (
          _event: React.ChangeEvent<HTMLInputElement>,
          isChecked: boolean
        ): void => {
          if (isChecked) {
            setDays(getInitialDaysValue(originalValidityPeriods.current));
            setStartTime(getInitialStartTimeValue(originalValidityPeriods.current));
            setFinishTime(getInitialFinishTimeValue(originalValidityPeriods.current));
          } else {
            setDays(getEverydayValue());
            setStartTime(DEFAULT_TIME);
            setFinishTime(DEFAULT_TIME);
          }

          setIsEnabled(isChecked);
        };

        const handleDaysChange = (days: DayOption[]): void => {
          const isDaysEmpty = days.length === 0;
          const validDays = days.filter((day) => day.value !== 'Everyday');
          setDays(isDaysEmpty ? getEverydayValue() : validDays);
        };

        const handleStartTimeChange = (time: string): void => {
          setStartTime(time);
        };

        const handleFinishTimeChange = (time: string): void => {
          setFinishTime(time);
        };

        return (
          <FormItemLayout
            classes={{ root: classes.root }}
            description={<Translate id="Set_a_period_when_this_voucher_is_valid_to_redeem" />}
            label={
              <>
                <Translate id="Voucher_is_valid_during_period" />
                <Tag label="Beta" />
              </>
            }
            noSpace
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Switch
                  checked={isEnabled}
                  disabled={isSubmitting}
                  fdKey={'voucher-valid-period-enabled-switch'}
                  onChange={handleEnableChange}
                  value={isEnabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  dataFd={'voucher-valid-period-days-select'}
                  isClearable={isClearable}
                  isDisabled={isDisabled}
                  isMulti
                  label={translate('Day_of_the_week') as string}
                  onChange={handleDaysChange}
                  options={getDayOptions()}
                  placeholder=""
                  value={days}
                />
              </Grid>

              <Grid alignItems="center" container direction="row" item spacing={2} xs={12}>
                <Grid item xs>
                  <TimePicker
                    dataFd={'voucher-valid-period-start-time-picker'}
                    helperText={
                      isStartTimeError ? (translate('Required') as string) : EMPTY_HELPER_TEXT
                    }
                    isDisabled={isDisabled}
                    isError={isStartTimeError}
                    label={translate('Start_time') as string}
                    onChange={handleStartTimeChange}
                    value={startTime}
                  />
                </Grid>

                <Grid item sx={{ pb: 2.5 }}>
                  <Typography className={isDisabled ? classes.disabledText : ''}>
                    <Translate id="to" />
                  </Typography>
                </Grid>

                <Grid item xs>
                  <TimePicker
                    dataFd={'voucher-valid-period-finish-time-picker'}
                    helperText={
                      isFinishTimeError ? (translate('Required') as string) : EMPTY_HELPER_TEXT
                    }
                    isDisabled={isDisabled}
                    isError={isFinishTimeError}
                    label={translate('Finish_time') as string}
                    onChange={handleFinishTimeChange}
                    value={finishTime}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default ValidityPeriodsField;
