import { StoreGroupExtended } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';
import { STORE_GROUP_GET_ALL } from '../constants/storegroup.constants';
import { createLoadingSelector } from './loading.selector';
import { createOrmSelector } from './selector';

const selectDbStoreGroups = (state: AppState) => state.orm.FlipdishStoreGroup.itemsById;

const selectItemOrderIndex = (state: AppState) =>
  state.storeGroups.search
    ? state.storeGroups.search.item_order_index
    : state.storeGroups.item_order_index;

const selectTotalCount = (state: AppState) =>
  state.storeGroups.search ? state.storeGroups.search.totalCount : state.storeGroups.totalCount;

export const getStoreGroups = createSelector(
  [selectItemOrderIndex, selectDbStoreGroups],
  (itemsOrder, storeGroups) => {
    return itemsOrder.map((id) => storeGroups[id]);
  }
);

export const getStoreGroupTotalCount = createSelector([selectTotalCount], (totalCount) => {
  return totalCount;
});

export const getStoreGroupsLoading = createLoadingSelector([STORE_GROUP_GET_ALL]);

export const createStoreGroupSelectorById = (storeGroupId: number | string) =>
  createOrmSelector<StoreGroupExtended>((session) => {
    const storeGroup = session.FlipdishStoreGroup.withId(storeGroupId as any);
    if (storeGroup != null) {
      return {
        ...storeGroup.ref,
        Stores: storeGroup.FlipdishStores.toRefArray(),
      };
    }
    return undefined;
  });
