class MemoryStorage implements Storage {
  [key: string]: any;
  public length: number;

  public clear(): void {
    // Object.keys(this)
    //   .filter(x => !(x in this))
    this.length = 0;
  }
  public getItem(key: string): string | null {
    return key in this ? this[key] : null;
  }
  public key(index: number): string | null {
    return null;
    // return Object.keys(this)
    //   .filter(x => !(x in this))
    //   .
  }
  public removeItem(key: string): void {
    if (key in this) {
      delete this[key];
      this.length -= 1;
    }
  }
  public setItem(key: string, value: any): void {
    try {
      this[key] = typeof value === 'string' ? value : JSON.stringify(value);
      this.length += 1;
    } catch (error) {
      console.error(error);
    }
  }
}

export const localStorage: Storage =
  (window && window.localStorage) || new MemoryStorage();
export const sessionStorage: Storage =
  (window && window.sessionStorage) || new MemoryStorage();
export const inMemoryStorage: Storage = new MemoryStorage();

export default localStorage;
