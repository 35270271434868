import React from 'react';

import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: '#fbcf7c',
  },
  isSent: {
    backgroundColor: '#86e8bb',
  },
});

type Props = {
  sent: boolean;
};

const PushNotificationSent = ({ sent }: Props) => {
  const classes = useStyles();

  const className = clsx({
    [classes.root]: true,
    [classes.isSent]: sent,
  });

  return <div className={className}>{sent ? <CheckCircleOutlined /> : <AccessTimeOutlined />}</div>;
};

export default PushNotificationSent;
