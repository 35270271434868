import React from 'react';

import { OAuthApp } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { type FieldProps, type FormikProps, Field, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import { LoadingButton } from '../../../ui/LoadingButton';

type Props = {
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  submit: (changes) => Promise<void>;
  isLoading: boolean | undefined;
};
export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = () => {
  return {
    IsPolicyAgreed: false,
    OauthAppName: '',
  };
};

const validate = (value: string) => {
  if (value.length === 0) {
    return 'Required';
  }
  return;
};

const OAuthAppCreateForm = (props: FormikProps<FormValues> & Props) => {
  const { setDialogOpen, isDialogOpen, resetForm } = props;
  const dialogContent = [
    <DialogTitle key="dialog-title">
      <Translate id="Create_new_oauth_app" />
    </DialogTitle>,
    <DialogContent key="dialog-content">
      <Field name="OauthAppName" validate={validate}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          return (
            <TextField
              variant="standard"
              {...field}
              label={<Translate id="App_name" />}
              disabled={isSubmitting}
              error={showError}
              helperText={
                showError ? (
                  <Translate id={fieldError as 'Required'} />
                ) : (
                  <Translate id="Oauth_create_helper" />
                )
              }
              margin="dense"
              fullWidth
              inputProps={{ 'data-fd': 'Oauth_app_name' }}
            />
          );
        }}
      </Field>
      <Field name="IsPolicyAgreed">
        {({ field }: FieldProps) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  value={props.values.IsPolicyAgreed.toString()}
                  data-fd="agree-to-policy"
                  color="primary"
                />
              }
              label={
                <a
                  style={{
                    color: '#05149e',
                    textDecoration: 'none',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                  href="https://api.flipdish.co/app/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Translate id="Agree_to_policy" />
                </a>
              }
            />
          );
        }}
      </Field>
    </DialogContent>,
    <DialogActions key="actions">
      <Button
        color="primary"
        data-fd="Add_oauth_cancel"
        onClick={() => {
          resetForm();
          setDialogOpen(false);
        }}
      >
        <Translate id="Cancel" />
      </Button>
      <LoadingButton
        color="primary"
        fdKey="Add_oauth_create"
        onClick={(e) => props.handleSubmit()}
        disabled={!props.values.IsPolicyAgreed || props.isSubmitting}
        loading={props.isLoading}
      >
        <Translate id="Create" />
      </LoadingButton>
    </DialogActions>,
  ];

  return (
    <Form>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          props.resetForm();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogContent}
      </Dialog>
    </Form>
  );
};

export default withFormik<Props, FormValues>({
  displayName: 'OAuthAppCreateForm',
  mapPropsToValues: () => {
    return getDefaultFormState();
  },
  handleSubmit: (values, formikBag) => {
    const { OauthAppName } = values;
    const oAuthApp: OAuthApp = {
      OauthAppName,
    };
    formikBag.props
      .submit(oAuthApp)
      .then((resp) => {
        formikBag.resetForm();
        formikBag.setSubmitting(false);
        formikBag.props.setDialogOpen(false);
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        if (err === `OAuth client name ${OauthAppName} is already in use.`) {
          formikBag.setFieldError('OauthAppName', 'OAuth_name_already_in_use');
        }
      });
  },
})(OAuthAppCreateForm);
