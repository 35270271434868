import React from 'react';

import ErrorIcon from '../assets/images/illust_something_went_wrong.svg';
import EmptyComponent from './EmptyComponent';

type Props = {
  title?: TranslationId;
  subtitle?: TranslationId;
  action?: Function;
};

export default function ErrorComponent(props: Props) {
  const { action, title = 'Error_component_title', subtitle = 'Error_component_subtitle' } = props;

  return (
    <EmptyComponent title={title} subtitle={subtitle} icon={ErrorIcon} action={action} noLink />
  );
}
