import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedCurrency, FormattedNumber } from 'fd-react-intl';
import isUndefined from 'lodash/isUndefined';
import Skeleton from 'react-loading-skeleton';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { SummarySectionData, SummarySectionItem } from '../../components/Finance/Payouts/types';

type InnerProps = MappedProps;
type OuterProps = {
  summaryData: SummarySectionData | undefined;
  loading?: boolean;
  currency?: CurrencyEnum;
  title?: TranslationId;
};
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  skeletonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    textTransform: 'capitalize',
  },
  paperContainer: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.18)',
      borderRadius: '0px',
    },
  },
  summaryContainer: {
    padding: theme.spacing(3, 0, 3, 3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 2, 2),
    },
  },
  totalAmount: {
    padding: theme.spacing(1, 0, 0.5, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 0, 0.5, 0),
    },
  },
}));

const SummarySection = (props: Props) => {
  const { summaryData, currency, languageCode, loading, title } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const renderSummaryItems = (summaryItems: SummarySectionItem[]) => {
    const mappedItems = summaryItems.map((summaryItem: SummarySectionItem, index: number) => (
      <Box
        width={isMobile ? '50%' : summaryItem.width}
        order={isMobile && summaryItems.length === 6 ? summaryItem.mobileOrder : undefined}
        className={classes.summaryContainer}
        key={index}
      >
        <Typography variant="caption">
          <Translate id={summaryItem.translationId as TranslationId} />
        </Typography>
        <Typography
          className={classes.totalAmount}
          data-fd={'summary-item-' + summaryItem.translationId}
          variant="h5"
        >
          {summaryItem.noCurrency ? (
            <FormattedNumber value={summaryItem.value as number} />
          ) : (
            <FormattedCurrency
              value={summaryItem.value as number}
              locales={languageCode}
              currency={currency || 'EUR'}
            />
          )}
        </Typography>
      </Box>
    ));
    return mappedItems;
  };

  if (loading) {
    return (
      <>
        <Box className={classes.title}>
          <Skeleton width={'20%'} height={24} />
        </Box>
        <Paper className={classes.skeletonContainer}>
          <Skeleton height={100} />
        </Paper>
      </>
    );
  }

  return (
    <>
      {!isUndefined(summaryData) && summaryData.showSection && (
        <>
          {title && (
            <Typography variant="h5" className={classes.title}>
              <Translate id={title as TranslationId} />
            </Typography>
          )}
          <Paper elevation={isMobile ? 0 : 1} className={classes.paperContainer}>
            <div style={{ width: '100%' }}>
              <Box display="flex" flexWrap="wrap">
                {renderSummaryItems(summaryData.sectionItems)}
              </Box>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    languageCode: getActiveLanguage(state.locale),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(SummarySection);
