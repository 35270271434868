import React, { useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import moment from 'moment';
import { Color } from 'plotly.js';
import { getTranslate } from 'react-localize-redux';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { Typography } from '@fd/ui/atoms';
import GridContainer from '@fd/ui/Layout/GridContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import PageLayout from '../../../ui/Layout';
import GenericContextualButton from '../../ContextualButton/Generic/GenericContextualButton';
import DateFilter from '../../DateFilter';
import { getKiosk } from '../Kiosks.actions';
import { kioskServices } from '../Kiosks.services';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type InnerProps = MappedState & RouteComponentProps<{ id: string }> & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;
type PlotData = {
  timestamps: Date[];
  properties: {
    variable: TelemetryVariable;
    values: (number | TranslationId | null)[];
  }[];
};

const dateRanges = [
  { label: 'Custom', value: 0, url: 'custom' },
  { label: 'Today', value: 1, url: 'today' },
  { label: 'Yesterday', value: 2, url: 'yesterday' },
  { label: 'Last_x_days', period: '7', value: 3, url: 'last7Days' },
  { label: 'Last_x_days', period: '30', value: 4, url: 'last30Days' },
];

type TelemetryVariable = {
  name: string;
  nameTranslationKey: TranslationId;
  unit?: string;
  descriptionTranslationKey?: TranslationId;
  categories?: {
    list: TranslationId[];
    valueToCategory: (value: number) => TranslationId;
    categoryToColour?: (category: TranslationId) => Color;
  };
};
const telemetryVariables: TelemetryVariable[] = [
  {
    name: 'WifiSignalLevel',
    nameTranslationKey: 'Wifi_Signal_Level',
    unit: 'dBm',
    descriptionTranslationKey: 'Wifi_Signal_Level_Description',
  },
  {
    name: 'PrinterStatus',
    nameTranslationKey: 'Printer_status',
    categories: {
      list: ['No_printer', 'Printer_error', 'OK'],
      valueToCategory: (value) => {
        switch (value) {
          case 10:
            return 'OK';
          case 11:
            return 'No_printer';
          default:
            return 'Printer_error';
        }
      },
      categoryToColour: (category) => (category === 'OK' ? 'green' : 'red'),
    },
  },
  {
    name: 'ReaderConnected',
    nameTranslationKey: 'Card_reader_status',
    categories: {
      list: ['Not_Connected', 'Connected'],
      valueToCategory: (value) => (value === 0 ? 'Not_Connected' : 'Connected'),
      categoryToColour: (category) => (category === 'Connected' ? 'green' : 'red'),
    },
  },
  {
    name: 'ReaderConnectionType',
    nameTranslationKey: 'Card_reader_connection_type',
    categories: {
      list: ['Connection_type_bluetooth', 'Connection_type_usb'],
      valueToCategory: (value) =>
        value === 10 ? 'Connection_type_usb' : 'Connection_type_bluetooth',
    },
  },
];

const KioskTelemetry = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const { appId, kiosk, getKioskDetails, translate, notifyError } = props;

  const { DeviceId, DeviceName } = kiosk ?? {};
  const parentUrl = `/${appId}/sales-channels/kiosks/${DeviceId}`;

  const [queryDateRange, setQueryDateRange] = useState<{
    start: moment.Moment | undefined;
    end: moment.Moment | undefined;
  }>({
    start: undefined,
    end: undefined,
  });
  const [zoomedDateRange, setZoomedDateRange] = useState<{
    start: string | undefined;
    end: string | undefined;
  }>({
    start: undefined,
    end: undefined,
  });
  const [queryDataOrdered, setQueryDataOrdered] = useState<PlotData>({
    timestamps: [],
    properties: [],
  });

  const onQueryDateChanged = (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined
  ) => {
    setQueryDateRange({ start: startDate, end: endDate?.endOf('day') });
    setZoomedDateRange({
      start: startDate?.format('YYYY-MM-DD HH:mm:ss'),
      end: endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const setZoomedDateRangeDebounced = useCallback(
    debounce((start: string, end: string) => {
      setZoomedDateRange({ start, end });
    }, 250),
    [setZoomedDateRange]
  );

  const onZoomedDateChanged = (event) => {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      // Called once when zoom on plot
      setZoomedDateRange({
        start: event['xaxis.range[0]'],
        end: event['xaxis.range[1]'],
      });
    } else if (event['xaxis.range']) {
      // Called as range slider is being moved - debounce as this is called many times in quick succession
      setZoomedDateRangeDebounced(event['xaxis.range'][0], event['xaxis.range'][1]);
    }
  };

  const query = useQuery({
    queryKey: [kioskServices.getTelemetryByKioskIdQueryKey, appId, DeviceId, queryDateRange],
    queryFn: () => {
      if (appId && DeviceId && queryDateRange.start && queryDateRange.end) {
        return kioskServices.getTelemetryByKioskId(
          appId,
          DeviceId,
          queryDateRange.start.toDate(),
          queryDateRange.end.toDate(),
          telemetryVariables.map((x) => x.name)
        );
      }
    },
    refetchInterval: 1000 * 60 * 2,
    retry: false,
    enabled: !!appId && !!DeviceId && !!queryDateRange.start && !!queryDateRange.end,
  });
  useEffect(() => {
    if (query.isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [query.isError]);

  useEffect(() => {
    if (query.data) {
      const zipped = query.data.Data.Timestamps!.map((timestamp, i) => ({
        timestamp: new Date(timestamp),
        values: query.data!.Data.Properties!.map((prop) => ({ value: prop.IntValues![i] })),
      }));
      const ordered = zipped.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      const data: PlotData = {
        timestamps: ordered.map((dataPoint) => dataPoint.timestamp),
        properties: query.data!.Data.Properties!.map((prop, i) => ({
          variable: telemetryVariables.find((x) => x.name == prop.Name)!,
          values: ordered.map((dataPoint) => dataPoint.values[i].value),
        })),
      };
      data.properties.forEach((prop) => {
        if (prop.variable.categories) {
          prop.values = prop.values.map((value) =>
            value == null ? null : prop.variable.categories!.valueToCategory(value as number)
          );
        }
      });
      data.properties = data.properties.filter((prop) => prop.values.some((x) => x !== null));
      setQueryDataOrdered(data);
    }
  }, [query.data]);

  if (!kiosk) {
    getKioskDetails();
    return <></>;
  }

  return (
    <PageLayout
      title={`${translate('Telemetry')} - ${DeviceName}`}
      toParent={parentUrl}
      contextButtons={[
        <GenericContextualButton
          key="Flipdish_support_guide"
          buttonText={translate('Flipdish_support_guide') as string}
          useReactRouter={false}
          path="https://help.flipdish.com/s/article/How-to-test-the-internet-and-network-connection-on-a-Kiosk-1697146702487?language=en_GB"
        />,
      ]}
    >
      <GridContainer>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <DateFilter
            datePeriodDefault="today"
            selectLabel={translate('Date_period') as string}
            includeTodayInDatePeriod
            customRange={dateRanges}
            onChange={onQueryDateChanged}
          />
        </Grid>
        {queryDataOrdered.properties.length > 0 &&
          queryDataOrdered.properties.map((prop, i) => (
            <Grid key={i} item xs={12} className={classes.gridItem}>
              <PaperContainer ariaLabel={`${prop.variable.name} plot`}>
                <Plot
                  data={[
                    {
                      type: 'scatter',
                      mode: prop.variable.categories ? 'markers' : 'lines',
                      x: queryDataOrdered.timestamps,
                      y: prop.variable.categories
                        ? prop.values.map((c) => translate(c as TranslationId) as string)
                        : prop.values,
                      xaxis: 'x',
                      yaxis: 'y',
                      line: {
                        width: 1,
                        color: theme.palette.primary.main,
                      },
                      marker: {
                        color: prop.variable.categories?.categoryToColour
                          ? prop.values.map((c) =>
                              prop.variable.categories!.categoryToColour!(c as TranslationId)
                            )
                          : undefined,
                        size: 4,
                      },
                      hovertemplate:
                        '%{x}<br>' +
                        translate(prop.variable.nameTranslationKey) +
                        ': %{y}<extra></extra>',
                    },
                  ]}
                  layout={{
                    autosize: true,
                    title: {
                      text: translate(prop.variable.nameTranslationKey) as string,
                    },
                    font: {
                      family: theme.typography.fontFamily,
                    },
                    xaxis: {
                      type: 'date',
                      range: [zoomedDateRange.start, zoomedDateRange.end],
                      rangeslider: {
                        visible: true,
                        range: [queryDateRange.start!.toDate(), queryDateRange.end!.toDate()],
                        bgcolor: '#F4F4F4',
                      },
                    },
                    yaxis: {
                      fixedrange: true,
                      range: prop.variable.categories
                        ? [-0.3, prop.variable.categories.list.length - 0.7]
                        : undefined,
                      title: {
                        text: prop.variable.unit,
                      },
                      categoryorder: prop.variable.categories ? 'array' : undefined,
                      categoryarray: prop.variable.categories?.list?.map(
                        (c) => translate(c) as string
                      ),
                    },
                    margin: {
                      b: 80,
                      t: 40,
                    },
                  }}
                  useResizeHandler
                  style={{ width: '100%', minHeight: '30rem' }}
                  config={{
                    showTips: false,
                    displayModeBar: false,
                    doubleClick: false,
                  }}
                  onRelayout={onZoomedDateChanged}
                  onDoubleClick={() =>
                    setZoomedDateRange({
                      start: queryDateRange.start?.format('YYYY-MM-DD HH:mm:ss'),
                      end: queryDateRange.end?.format('YYYY-MM-DD HH:mm:ss'),
                    })
                  }
                />
                {prop.variable.descriptionTranslationKey && (
                  <Typography paragraph>
                    {translate(prop.variable.descriptionTranslationKey)}
                  </Typography>
                )}
              </PaperContainer>
            </Grid>
          ))}
        {queryDataOrdered.properties.length === 0 && (
          <Grid item xs={12} className={classes.gridItem}>
            <Typography paragraph>
              {translate('No_data_available_for_the_selected_time_range')}
            </Typography>
          </Grid>
        )}
      </GridContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState, ownProps: RouteComponentProps<{ id: string }>) => ({
    appId: state.currentApp.AppId,
    kiosk: state.kiosks.kiosks.find((item) => item.DeviceId === ownProps.match.params.id),
    translate: getTranslate(state.locale),
  });
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: RouteComponentProps<{ id: string }>
) => {
  return {
    getKioskDetails: () => dispatch(getKiosk(ownProps.match.params.id)),
    notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  };
};

export default compose<InnerProps, OuterProps>(
  connect(mapStateToPropsFactory, mapDispatchToProps),
  withRouter
)(KioskTelemetry);
