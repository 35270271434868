import React, { PropsWithChildren } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

export const historyMinLength = 3;

const styles = () => ({
  titleWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    marginLeft: '-14px',
  },
  title: {
    flexGrow: 1,
  },
});

type OuterProps = PropsWithChildren<{
  backUrl?: string;
  className?: string;
  strictBackUrl?: boolean;
}>;

type InnerProps = RouteComponentProps & WithStyles<typeof styles>;

type Props = InnerProps & OuterProps;

export default compose<InnerProps, OuterProps>(
  withRouter,
  withStyles(styles)
)(({ classes, history, backUrl, children, strictBackUrl, className = '' }: Props) => (
  <div className={classes.titleWrapper}>
    <IconButton
      className={`${classes.backButton} ${className}`}
      aria-label="Back"
      onClick={() => {
        if (backUrl && strictBackUrl) {
          history.push(backUrl);
        } else if (backUrl && history.length < historyMinLength) {
          history.push(backUrl);
        } else {
          history.goBack();
        }
      }}
      data-fd="back_button"
    >
      <ArrowBackIcon />
    </IconButton>
    <div className={classes.title}>{children}</div>
  </div>
));
