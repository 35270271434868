import {
  BusinessHoursOverride,
  StoreBusinessHoursOverrideCreatedEvent,
  StoreBusinessHoursOverrideDeletedEvent,
} from '@flipdish/api-client-typescript';

import {
  CreateSuccess,
  LoadAllRequest,
  LoadAllSuccess,
  RemoveSuccess,
} from '../actions/storeOpeningHourOverrides.actions';
import actionTypes from '../constants/storeOpeningHourOverrides.constants';
import { storeEventConstants } from '../signalr/hub.events';

const {
  LOAD_ALL_REQUEST,
  LOAD_ALL_SUCCESS,
  // LOAD_ALL_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  REMOVE_REQUEST,
  REMOVE_SUCCESS,
  REMOVE_FAILURE,
} = actionTypes;
export type OpeningHourOverridesState = Readonly<{
  [storeId: number]: Readonly<{ [id: number]: BusinessHoursOverride }>;
}>;

const initialState: OpeningHourOverridesState = {};

const reducer = (state = initialState, action): OpeningHourOverridesState => {
  switch (action.type) {
    case LOAD_ALL_REQUEST:
      return loadAllRequest(state, action);
    case LOAD_ALL_SUCCESS:
      return loadAllSuccess(state, action);
    // case LOAD_ALL_FAILURE:
    //   return loadAllError(state, action as ReturnType<
    //     typeof actions.loadAllFailure
    //   >);

    case CREATE_SUCCESS:
      return createSuccess(state, action);
    case CREATE_REQUEST:
    case CREATE_FAILURE:
      return state;

    case REMOVE_SUCCESS:
      return removeSuccess(state, action);
    case REMOVE_REQUEST:
    case REMOVE_FAILURE:
      return state;

    case storeEventConstants.BUSINESS_HOURS_OVERRIDES_CREATED:
      return onCreateEvent(state, action);
    case storeEventConstants.BUSINESS_HOURS_OVERRIDES_DELETED:
      return onRemoveEvent(state, action);

    default:
      return state;
  }
};

export default reducer;

function loadAllRequest(
  state: OpeningHourOverridesState,
  action: LoadAllRequest
): OpeningHourOverridesState {
  const { storeId } = action.payload;
  if (!state[storeId]) {
    return {
      ...state,
      [storeId]: {},
    };
  }
  return state;
}
function loadAllSuccess(
  state: OpeningHourOverridesState,
  action: LoadAllSuccess
): OpeningHourOverridesState {
  const { storeId, businessHoursOverrides } = action.payload;
  const dictionary = businessHoursOverrides.Data.reduce((dict, bho) => {
    dict[bho.BusinessHoursOverrideId as number] = bho;
    return dict;
  }, {});
  return {
    ...state,
    [storeId]: {
      ...state[storeId],
      ...dictionary,
    },
  };
}
// function loadAllError(
//   state: OpeningHourOverridesState,
//   action: ReturnType<typeof actions.loadAllFailure>
// ): OpeningHourOverridesState {
//   const { storeId } = action.meta;
//   return {
//     ...state,
//     [storeId]: {
//       ...state[storeId],
//       error: action.payload,
//     }
//   };
// }

function createSuccess(
  state: OpeningHourOverridesState,
  action: CreateSuccess
): OpeningHourOverridesState {
  const { storeId, businessHoursOverride: bho } = action.payload;
  return {
    ...state,
    [storeId]: {
      ...state[storeId],
      [bho.BusinessHoursOverrideId as number]: bho as unknown as BusinessHoursOverride,
    },
  };
}
export function onCreateEvent(
  state: OpeningHourOverridesState,
  action: ActionPayload<Required<StoreBusinessHoursOverrideCreatedEvent>>
): OpeningHourOverridesState {
  const { StoreId, BusinessHoursOverride } = action.payload;
  const bho: BusinessHoursOverride = {
    ...BusinessHoursOverride,
    StartTime: new Date(BusinessHoursOverride.StartTime),
    EndTime: new Date(BusinessHoursOverride.EndTime),
  };
  let currentBho = state[StoreId] && state[StoreId][bho.BusinessHoursOverrideId as number];

  if (
    currentBho &&
    !(currentBho.StartTime instanceof Date) &&
    !(currentBho.EndTime instanceof Date)
  ) {
    currentBho = {
      ...currentBho,
      StartTime: new Date(currentBho.StartTime),
      EndTime: new Date(currentBho.EndTime),
    };
  }

  if (
    currentBho &&
    currentBho.StartTime.getTime() === bho.StartTime.getTime() &&
    currentBho.EndTime.getTime() === bho.EndTime.getTime()
  ) {
    return state;
  }

  return {
    ...state,
    [StoreId]: {
      ...state[StoreId],
      [bho.BusinessHoursOverrideId as number]: bho,
    },
  };
}

function removeSuccess(
  state: OpeningHourOverridesState,
  action: RemoveSuccess
): OpeningHourOverridesState {
  const { storeId, businessHoursOverrideId: bhoId } = action.payload;
  const { [bhoId]: removed, ...rest } = state[storeId];
  return {
    ...state,
    [storeId]: {
      ...rest,
    },
  };
}
function onRemoveEvent(
  state: OpeningHourOverridesState,
  action: ActionPayload<Required<StoreBusinessHoursOverrideDeletedEvent>>
): OpeningHourOverridesState {
  const { StoreId, BusinessHoursOverride: bho } = action.payload;
  const overrides = state[StoreId];
  if (overrides && overrides[bho.BusinessHoursOverrideId!]) {
    const { [bho.BusinessHoursOverrideId!]: removed, ...rest } = overrides;
    return {
      ...state,
      [StoreId]: {
        ...rest,
      },
    };
  }
  return state;
}
