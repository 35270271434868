import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import LinkButton from '@fd/ui/Button/LinkButton';

const useStyles = makeStyles((theme: Theme) => ({
  boxContents: {
    marginTop: theme.spacing(3),
    height: '236px',
  },
  createModifierGroupButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  message: {
    maxWidth: '440px',
  },
  textButton: {
    fontSize: '0.75rem',
    textTransform: 'none',
  },
}));

export const NoModifierGroupsMessage = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.boxContents}>
      <Typography variant="caption" className={classes.message}>
        <Translate id="ModifierGroups_NoModifierGroupsMessage_Description" />
      </Typography>
      <LinkButton
        className={classes.createModifierGroupButton}
        color="primary"
        fdKey="no-group-create-new-modifer-group"
        to={`modifier-groups/create-modifier-group`}
        variant="outlined"
      >
        <Translate id="CreateNewModifierGroup" />
      </LinkButton>
      <Button href="#" fdKey="showHow" variant="text" className={classes.textButton}>
        <Translate id="Show_me_how" />
      </Button>
    </Box>
  );
};
