import React, { ChangeEvent } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { type FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../../helpers/validation';
import FormItem from '../FormItem';

const leadTimeValidation = (value: number) => {
  const max = 20160;
  const min = 5;

  return (
    formikValidate.required(value) ||
    formikValidate.digits(value) ||
    formikValidate.max(max)(value) ||
    formikValidate.min(min)(value)
  );
};

type Props = {
  canEdit: boolean;
};

const LeadTimeMinutes = ({ canEdit }: Props) => {
  return (
    <FormItem
      divider
      primary={<Translate id="Preorder_settings_lead_time_item_title" />}
      secondary={<Translate id="Preorder_settings_lead_time_item_helper_text" />}
      name="LeadTimeMinutes"
      validate={leadTimeValidation}
    >
      {({ field, form }: FieldProps) => {
        const { errors, touched } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <TextField
            variant="standard"
            fullWidth
            disabled={!canEdit}
            value={field.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              form.setFieldValue(field.name, e.target.value);
            }}
            error={showError}
            helperText={showError ? fieldError : undefined}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  <Translate id="mins" />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 5,
              'data-fd': 'pre_order_settings_lead_time_minutes',
            }}
          />
        );
      }}
    </FormItem>
  );
};

export default LeadTimeMinutes;
