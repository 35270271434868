import React, { useMemo } from 'react';

import { BluetoothTerminalStatus } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { formatCalendarDate } from '../../../helpers/dateFormats';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { getUtcDate } from '../helpers';

const deviceTypeMap = (device?: BluetoothTerminalStatus.DeviceTypeEnum) => {
  let deviceType = '';
  switch (device) {
    case BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X:
      deviceType = 'BBPOS Chipper 2X BT';
      break;
    case BluetoothTerminalStatus.DeviceTypeEnum.WISEPAD3:
      deviceType = 'BBPOS WisePad 3';
      break;
    case BluetoothTerminalStatus.DeviceTypeEnum.WISEPOSE:
      deviceType = 'BBPOS WisePOS E';
      break;
  }
  return deviceType;
};

const useStyles = makeStyles((theme: Theme) => ({
  addMargin: { marginBottom: theme.spacing(2) },
  addPadding: { paddingLeft: theme.spacing(1.5) },
  container: { marginTop: theme.spacing(3.5) },
  root: { color: theme.palette.text.secondary },
}));

type InnerProps = MappedState;
type OuterProps = {
  appId: string;
  deviceId?: string;
  stripeTerminalDetails: BluetoothTerminalStatus;
};
type Props = InnerProps & OuterProps;
const StripeTerminalDetails = ({ dtUtils, stripeTerminalDetails, translate }: Props) => {
  const { BatteryLevel, DeviceType, SoftwareVersion, SerialNumber, Status, UpdateTime } =
    stripeTerminalDetails;
  const classes = useStyles();

  const localUserTime = useMemo(() => {
    if (UpdateTime) {
      const utcDate = getUtcDate(UpdateTime);
      return dtUtils.utcToZonedTime(utcDate, dtUtils.userIanaTimeZone);
    }
  }, [UpdateTime, dtUtils.userIanaTimeZone]);

  const details = useMemo(() => {
    return {
      Model: deviceTypeMap(DeviceType),
      Serial_number: SerialNumber,
      Software_version: SoftwareVersion,
      Connection_status: Status,
      Battery: BatteryLevel?.toString(),
      Last_polled: localUserTime ? formatCalendarDate(dtUtils, localUserTime, translate) : '-',
    };
  }, [Status, BatteryLevel, SerialNumber, localUserTime, SoftwareVersion]);

  return (
    <Grid container className={classes.container}>
      {Object.entries(details).map(([label, value], idx) => {
        if (DeviceType === BluetoothTerminalStatus.DeviceTypeEnum.WISEPOSE && label === 'Battery') {
          return;
        }

        return (
          <Grid container key={idx}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                classes={{ root: classes.root }}
                className={classes.addMargin}
              >
                <Translate id={label as TranslationId} />
              </Typography>
            </Grid>

            <Grid item xs={6} className={classes.addPadding}>
              <Typography
                variant="body2"
                className={classes.addMargin}
                style={{ wordBreak: 'break-word' }}
              >
                {label === 'Connection_status' ? (
                  <Translate id={value as TranslationId} />
                ) : value ? (
                  value
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  dtUtils: dateTimeUtils(state),
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(StripeTerminalDetails);
