import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    bottom: 0,
    left: 0,
    zIndex: 999,
  },
  button: {
    position: 'absolute',
    cursor: 'pointer',
    border: '1px solid black',
    borderRadius: 3,
    padding: 3,
  },
  popup: {
    width: 400,
    position: 'absolute',
    top: 2,
    border: '1px solid black',
    backgroundColor: 'white',
    height: 300,
    overflow: 'auto',
    fontSize: 10,
    display: (props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none'),
  },
});

type TProps = {
  data: any;
};

export const Logger: React.FC<React.PropsWithChildren<TProps>> = (props) => {
  // Use this flag if you want debug. Only in dev mode.
  const debug = false;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles({ isOpen });

  const onButtonClick = () => setIsOpen(!isOpen);
  return (
    <>
      {debug && (
        <div className={classes.container}>
          <div className={classes.button} onClick={onButtonClick} />
          <pre className={classes.popup}>{JSON.stringify(props.data, null, 2)}</pre>
        </div>
      )}
    </>
  );
};
