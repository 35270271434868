import { Store } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

export type DbStores = ReturnType<typeof selectDbStores>;
export const selectDbStores = (state: AppState) =>
  state.orm.FlipdishStore.itemsById as { [index: string]: Store };

export const getStores = createSelector(
  [selectDbStores],
  (stores) => {
    return stores;
  }
);

export const dbSelectors = {
  getStores,
  selectDbStores,
};
