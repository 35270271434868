import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import LinkButton from '../../../ui/Button/LinkButton';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  paperWrapper: {
    backgroundColor: '#EAF2FF',
    marginBottom: spacing(3),
  },
  banner: {
    borderRadius: '8px',
    [breakpoints.down('sm')]: {
      alignContent: 'flex-start',
      marginLeft: spacing(2),
      marginRight: spacing(2),
      width: '93%',
    },
  },
}));

type Props = { toUrl: string };

export const SubscriptionCancelledBanner = (props: Props) => {
  const classes = useStyles();
  const { toUrl } = props;
  return (
    <Grid container alignItems="center" className={clsx(classes.banner, classes.paperWrapper)}>
      <Grid item xs={12}>
        <Toolbar>
          <Typography variant="body2" color="textPrimary">
            <Translate id="Subscription_cancelled" />
          </Typography>

          <div style={{ flexGrow: 1 }} />
          <Hidden smDown>
            <LinkButton
              variant="text"
              color="primary"
              to={toUrl}
              type="button"
              fdKey="Go_to_invoices_btn"
              style={{ letterSpacing: '1.25px' }}
            >
              <Translate id="Go_to_invoices" />
            </LinkButton>
          </Hidden>
        </Toolbar>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12}>
          <Box ml={1} mr={2} mb={1}>
            <LinkButton
              variant="text"
              color="primary"
              to={toUrl}
              type="button"
              fdKey="Go_to_invoices_btn"
              style={{ letterSpacing: '1.25px' }}
            >
              <Translate id="Go_to_invoices" />
            </LinkButton>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};
