import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { CheckboxField } from '@fd/ui/atoms';
import { TextSearchField } from '@fd/ui/atoms/TextSearchField';
import PageLayout from '@fd/ui/Layout';

import * as appStoreActions from '../../../../../actions/appstore.actions';
import { AppStoreAppsList } from '../../../components/AppStoreAppsList';
import { AppStoreAppsListLoadingSkeleton } from '../../../components/AppStoreAppsListLoadingSkeleton';
import { AppStoreAppsListNoResults } from '../../../components/AppStoreAppsListNoResults';

type AppStoreProps = {};

const useStyles = makeStyles((theme: Theme) => ({
  filtersContainer: {
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  searchField: {
    width: '100%',
  },
  sectionSpacingBelow: {
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = AppStoreProps & MappedState & MappedDispatch;

const AppStoreApps = ({
  appId,
  translate,
  getAppStoreApps,
  getConfiguredAppStoreApps,
  appStoreAppsLoading,
  configuredAppStoreAppsLoading,
  appStoreApps,
  totalRecordCount,
  configuredAppStoreApps,
}: Props) => {
  const classes = useStyles();
  const [paginationParams, setPaginationParams] = useState<{
    page: number;
    rowsPerPage: number;
    searchTerm: string;
  }>({
    page: 1,
    rowsPerPage: appStoreActions.DEFAULT_APP_STORE_APPS_LIMIT,
    searchTerm: '',
  });
  const [showBetaApps, setShowBetaApps] = useState(false);

  useEffect(() => {
    const { searchTerm, page, rowsPerPage } = paginationParams;
    getAppStoreApps(searchTerm, page, rowsPerPage, !showBetaApps);
  }, [paginationParams, showBetaApps]);

  useEffect(() => {
    getConfiguredAppStoreApps(appId);
  }, [appId]);

  const handlePageChange = (newPage: number) => {
    setPaginationParams({ ...paginationParams, page: newPage });
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setPaginationParams({ ...paginationParams, page: 1, rowsPerPage: newRowsPerPage });
  };

  const handleSearchTerm = (value: string) => {
    setPaginationParams({ ...paginationParams, searchTerm: value.toLowerCase().trim(), page: 1 });
  };

  const handleBetaAppsChange = (isChecked: boolean) => {
    setShowBetaApps(isChecked);
    setPaginationParams({ ...paginationParams, page: 1 });
  };

  const renderContent = () => {
    if (appStoreAppsLoading || configuredAppStoreAppsLoading) {
      return <AppStoreAppsListLoadingSkeleton />;
    }
    if (appStoreApps) {
      if (appStoreApps.length === 0) {
        return <AppStoreAppsListNoResults />;
      }

      const filteredConfiguredApps = configuredAppStoreApps.filter((app) =>
        app.Name.toLowerCase().includes(paginationParams.searchTerm.toLowerCase())
      );

      return (
        <>
          {filteredConfiguredApps.length > 0 && (
            <>
              <Typography variant="h4" className={classes.sectionSpacingBelow}>
                <Translate id="Installed_Apps" />
              </Typography>
              <AppStoreAppsList
                usePagination={false}
                appStoreApps={filteredConfiguredApps}
                appId={appId}
                configuredAppStoreAppIds={configuredAppStoreApps?.map((app) => app.Id)}
              />
            </>
          )}

          {configuredAppStoreApps.length > 0 && (
            <Typography variant="h4" className={classes.sectionSpacingBelow}>
              <Translate id="All_Apps" />
            </Typography>
          )}
          <AppStoreAppsList
            usePagination={true}
            rowsPerPage={paginationParams.rowsPerPage}
            rowsPerPageOptions={[10, 30, 50, 100]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={paginationParams.page}
            appStoreApps={appStoreApps}
            total={totalRecordCount}
            appId={appId}
            configuredAppStoreAppIds={configuredAppStoreApps?.map((app) => app.Id)}
          />
        </>
      );
    }
    return null;
  };

  return (
    <PageLayout documentTitle="App_Store" title="">
      <div>
        <Typography variant="h2" sx={{ pl: { xs: 2, md: 0 } }}>
          <Translate id="Get_connected" />
        </Typography>
        <Typography
          variant="h2"
          className={classes.sectionSpacingBelow}
          sx={{ pl: { xs: 2, md: 0 } }}
        >
          <Translate id="Flipdish_App_Store_Title_Second_line" />
        </Typography>
        <Permissions allowed={['Owner']}>
          <Grid container m={-1.5} width={`calc(100% + 24px)`} className={classes.filtersContainer}>
            <Grid item xs={12} md={5} className={classes.gridItem}>
              <TextSearchField
                className={classes.searchField}
                clearAriaLabel={`${translate('Search_for_App_Name')}`}
                dataFd="input-search"
                disableLabelAnimation={true}
                onChange={handleSearchTerm}
                placeholder={`${translate('Search_for_App_Name')}`}
                variant="outlined"
              />
            </Grid>
            <Permissions allowed={['FlipdishStaff']}>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  alignSelf: 'center',
                }}
                className={classes.gridItem}
              >
                <CheckboxField
                  ariaLabel="Show beta apps"
                  checked={showBetaApps}
                  fdKey="show-beta-apps-checkbox"
                  label={`${translate('Appstore_Show_Beta_Apps')}`}
                  onChange={handleBetaAppsChange}
                />
              </Grid>
            </Permissions>
          </Grid>
          {renderContent()}
        </Permissions>
      </div>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
    appStoreApps: state.appStore.appStoreApps,
    appStoreAppsLoading: state.appStore.appStoreAppsLoading,
    configuredAppStoreAppsLoading: state.appStore.configuredAppStoreAppsLoading,
    totalRecordCount: state.appStore.totalAppStoreAppRecords,
    configuredAppStoreApps: state.appStore.configuredAppStoreApps ?? [],
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getAppStoreApps: (searchTerm: string, page: number, limit: number, showOnlyVerified: boolean) =>
    dispatch(appStoreActions.getAppStoreApps(searchTerm, page, limit, showOnlyVerified)),
  getConfiguredAppStoreApps: (appId: string) =>
    dispatch(appStoreActions.getConfiguredAppStoreAppsForApp(appId)),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppStoreApps);

export default EnhancedComponent;
