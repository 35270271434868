import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms/Typography';

import { getSelectionType } from '../../utils';
import { ModifierMinMaxDialog } from '../ModifierMinMaxDialog';

const useStyles = makeStyles((theme: Theme) => ({
  selectLabel: {
    paddingTop: theme.spacing(0.875),
  },
}));

export type ModifierGroupSelectionTypeProps = {
  maxValue: number | null;
  minValue: number;
  onChange: (min: number, max: number) => void;
  totalModifiers: number;
  translate: TranslateFunction;
};

export const ModifierGroupSelectionType = (props: ModifierGroupSelectionTypeProps): JSX.Element => {
  const { minValue, maxValue, onChange, totalModifiers, translate } = props;
  const [hasCustomSelection, setHasCustomSelection] = useState<boolean>(false);
  const [isMinMaxSelectDialogOpen, setIsMinMaxSelectDialogOpen] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<string>('one-optional');

  useEffect(() => {
    const selectionType = getSelectionType(minValue, maxValue);
    setSelectionType(selectionType);
    if (selectionType === 'custom') {
      setHasCustomSelection(true);
    }
  }, [minValue, maxValue]);
  const classes = useStyles();

  const handleSelectChange = (e: any) => {
    if (e.target.value === 'other') {
      setIsMinMaxSelectDialogOpen(true);
    } else {
      switch (e.target.value) {
        case 'one-optional':
          onChange(0, 1);
          break;
        case 'one-required':
          onChange(1, 1);
          break;
        case 'any-optional':
          onChange(0, -1);
          break;
        case 'any-required':
          onChange(1, -1);
          break;
      }
    }
  };

  const handleSaveCustomSelection = (minVal: number, maxVal: number) => {
    setIsMinMaxSelectDialogOpen(false);
    onChange(minVal, maxVal);
  };

  return (
    <>
      <ModifierMinMaxDialog
        open={isMinMaxSelectDialogOpen}
        totalModifiers={totalModifiers}
        onCancel={() => setIsMinMaxSelectDialogOpen(false)}
        onConfirm={handleSaveCustomSelection}
        translate={translate}
      />
      <FormControl className={classes.selectLabel} fullWidth>
        <InputLabel htmlFor="customers_can_select">
          <Typography variant="body1">
            <Translate id="CustomersCanSelect" />
          </Typography>
        </InputLabel>
        <Select
          disabled={totalModifiers === 0}
          onChange={handleSelectChange}
          name="customers_can_select"
          variant="standard"
          value={selectionType}
          inputProps={{
            id: 'selection-type-select',
            'data-fd': 'selection-type-select',
          }}
        >
          <MenuItem value="one-optional">
            <Translate id="OneItem_Optional" />
          </MenuItem>
          <MenuItem value="any-optional">
            <Translate id="AnyNumber_Optional" />
          </MenuItem>
          <MenuItem value="one-required">
            <Translate id="OneItem_Required" />
          </MenuItem>
          <MenuItem value="any-required">
            <Translate id="AnyNumber_Required" />
          </MenuItem>
          <MenuItem value="other">
            <Translate id="Other" />
          </MenuItem>
          {hasCustomSelection && (
            <MenuItem value="custom">
              <Translate id="Custom_min_max" data={{ min: minValue, max: maxValue }} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
