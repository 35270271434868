export const menusConstants = {
  GET_MENUS: 'GET_MENUS',
  GET_MENUS_SUCCESS: 'GET_MENUS_SUCCESS',
  SET_MENUS_TIMESTAMP: 'SET_MENUS_TIMESTAMP',
  SET_MENUS_APP_ID: 'SET_MENUS_APP_ID',
  GET_MENU: 'GET_MENU',
  SET_MENU_TIMESTAMP: 'SET_MENU_TIMESTAMP',
  SET_MENU_CHECKPOINTS_TIMESTAMP: 'SET_MENU_CHECKPOINTS_TIMESTAMP',
  GET_MENU_CHECKPOINTS: 'GET_MENU_CHECKPOINTS',
  SET_MENU_STORES_TIMESTAMP: 'SET_MENU_STORES_TIMESTAMP',
  GET_MENU_TAX_DETAILS: 'GET_MENU_TAX_DETAILS',
  SET_MENU_TAX_DETAILS_TIMESTAMP: 'SET_MENU_TAX_DETAILS_TIMESTAMP',
  SET_MENU_TAX_SAVING_TIMESTAMP: 'SET_MENU_TAX_SAVING_TIMESTAMP',
  GET_MENU_STORES: 'GET_MENU_STORES',
  DELETE_MENU: 'DELETE_MENU',
  RESTORE_MENU: 'RESTORE_MENU',

  SET_MENU_NAME: 'SET_MENU_NAME',
  SET_MENU_NAME_REQUEST: 'SET_MENU_NAME_REQUEST',
  SET_MENU_NAME_SUCCESS: 'SET_MENU_NAME_SUCCESS',
  SET_MENU_NAME_FAILURE: 'SET_MENU_NAME_FAILURE',

  SET_LOCK: 'SET_LOCK',
  SET_MENU_EXPAND_BEHAVIOUR: 'SET_MENU_EXPAND_BEHAVIOUR',
  INCLUDE_MENU_TAX: 'INCLUDE_MENU_TAX',
  SHOW_MENU_TAX: 'SHOW_MENU_TAX',
  SHOW_HIDE_MENU_ITEMS_SUCCESS: 'SHOW_HIDE_ITEMS_SUCCESS',
};
