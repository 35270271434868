import React from 'react';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

type Props = {
  title: TranslationId;
  subtitle?: TranslationId;
  loading?: boolean;
  disabled?: boolean;
};

const ExpansionPanelHeader = (props: Props) => {
  const { title, subtitle, loading, disabled } = props;

  return (
    <ListItem component="div" disableGutters dense disabled={disabled}>
      <ListItemText
        primary={
          loading ? (
            <Skeleton />
          ) : (
            <Box fontWeight={500}>
              <Translate id={title} />
            </Box>
          )
        }
        secondary={loading ? <Skeleton /> : subtitle && <Translate id={subtitle} />}
      />
    </ListItem>
  );
};

export default ExpansionPanelHeader;
