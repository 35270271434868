import React, { useEffect } from 'react';

import {
  PushNotificationResponse,
  RestApiResultPushNotificationResponse,
} from '@flipdish/api-client-typescript';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { useLoadingAsIndicator } from '../../../custom-hooks/useAsIndicator';
import { usePrevious } from '../../../custom-hooks/usePrevious';
import useRequestSnackBar from '../../../custom-hooks/useRequestSnackBar';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { UPDATE_PUSH_NOTIFICATION, updatePushNotification } from '../actions';
import PushNotificationsForm from './PushNotificationsForm';

const updateLoadingSelector = createLoadingSelector([UPDATE_PUSH_NOTIFICATION]);
const updateLoadingErrorSelector = createLoadingErrorSelectorFactory([UPDATE_PUSH_NOTIFICATION]);

const mapStateToProps = (state: AppState, props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { data, savingError } = state.pushNotifiactions;

  return {
    savingError,
    appId: state.currentApp.AppId,
    autoSaving: updateLoadingSelector(state),
    autoSavingError: updateLoadingErrorSelector(state),
    data: data.find((item) => item.ScheduledPushNotificationId === Number(id)),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  updateNotification: (notification: PushNotificationResponse) =>
    dispatch(updatePushNotification(notification)),
});

type Props = {
  data?: RestApiResultPushNotificationResponse;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(({
  data,
  appId,
  updateNotification,
  autoSaving,
  autoSavingError,
  savingError,
  history,
}: Props) => {
  const parentUrl = `/${appId}/push_notifications`;
  const successAutoSaving = useLoadingAsIndicator(autoSaving);
  const previousSuccessAutoSaving = usePrevious(successAutoSaving);
  const success = successAutoSaving && !autoSavingError;

  useEffect(() => {
    if (successAutoSaving && !previousSuccessAutoSaving) {
      history.replace(parentUrl);
    }
  }, [previousSuccessAutoSaving, successAutoSaving]);

  useRequestSnackBar({
    success,
    autoSaving,
    failure: autoSavingError,
    failureMessage: savingError,
  });

  return (
    <PageLayout
      toParent={parentUrl}
      title={<Translate id="Edit_push_notification" />}
      documentTitle="Push_notifications"
    >
      <PaperContainer>
        <PushNotificationsForm data={data} onSubmit={updateNotification} saving={autoSaving} />
      </PaperContainer>
      {!data ? <Redirect to={parentUrl} /> : null}
    </PageLayout>
  );
});
