import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiButton from '@mui/material/Button';
import { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import MuiClickAwayListener from '@mui/material/ClickAwayListener';
import MuiGrow from '@mui/material/Grow';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiMenuList from '@mui/material/MenuList';
import MuiPaper from '@mui/material/Paper';
import MuiPopper from '@mui/material/Popper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { OptionType } from '../../Select/Select';

export interface SplitButtonProps extends Omit<MuiButtonProps, 'variant' | 'onClick'> {
  className?: string;
  dropdownOptions: OptionType[];
  defaultButtonOption: string;
  variant?: 'primary' | 'green';
  onClick: (clickedOption: string) => void;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textTransform: 'none',
  },
  buttonGroup: {
    boxShadow: 'none',
    '& button:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.common.white} !important`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  green: {
    color: theme.palette.common.white,
    backgroundColor: '#36d88e',
    border: '1px solid #36d88e !important',
    '&:hover': {
      backgroundColor: '#25c079',
    },
  },
  dropdownContainer: {
    paddingRight: theme.spacing(6),
    width: '100%',
  },
}));

export const SplitButton = (props: SplitButtonProps): JSX.Element => {
  const {
    children,
    dropdownOptions,
    className,
    variant = 'primary',
    defaultButtonOption,
    onClick,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  const handleClick = () => {
    onClick(defaultButtonOption);
  };

  const handleMenuItemClick = (optionId: string) => {
    setOpen(false);
    onClick(optionId);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef?.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const buttonStyles = clsx(
    variant === 'primary' && classes.primary,
    variant === 'green' && classes.green,
    classes.button
  );

  return (
    <>
      <MuiButtonGroup
        className={clsx(className, classes.buttonGroup)}
        variant="contained"
        // @ts-ignore
        ref={anchorRef}
        aria-label="split button"
        component="div"
      >
        <MuiButton className={buttonStyles} onClick={handleClick} component="button">
          {children}
        </MuiButton>
        <MuiButton
          className={buttonStyles}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          component="button"
        >
          <ArrowDropDownIcon />
        </MuiButton>
      </MuiButtonGroup>
      <MuiPopper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <MuiGrow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <MuiPaper>
              <MuiClickAwayListener onClickAway={handleClose}>
                <MuiMenuList id="split-button-menu">
                  {dropdownOptions.map((option) => (
                    <MuiMenuItem
                      className={classes.dropdownContainer}
                      key={option.value}
                      onClick={() => handleMenuItemClick(option.value as string)}
                      component="button"
                    >
                      {option.label}
                    </MuiMenuItem>
                  ))}
                </MuiMenuList>
              </MuiClickAwayListener>
            </MuiPaper>
          </MuiGrow>
        )}
      </MuiPopper>
    </>
  );
};
