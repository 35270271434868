type ColumnTypes = 'String' | 'Link' | 'Subtext';
export type InvoiceTableColumns = {
  columnName: string;
  columnType:
    | 'CurrencyCode'
    | 'DateTime'
    | 'Date'
    | 'Subtext'
    | 'Decimal'
    | 'DecimalWithCurrency'
    | 'Integer'
    | 'String'
    | 'Status'
    | 'TranslatableString'
    | 'Link'
    | 'Unknown';
  isVisible: boolean;
  isSortable: boolean;
  ordinal: number;
  translationId?: TranslationId;
};

export enum PaymentType {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
}

export type InvoiceRequest = {
  appId: string;
  subscriptionId?: string;
  limit?: number;
  pageId?: string;
  excludeNotOwnedInvoices?: boolean;
  dateFrom?: Date | undefined;
  dateTo?: Date | undefined;
  invoiceNumber?: string;
  storeId?: number[];
};
