import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';

import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 76,
      padding: theme.spacing(4, 0),
    },
    textContainer: {
      paddingRight: theme.spacing(3.5),
    },
    label: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },

    [theme.breakpoints.down('md')]: {
      textContainer: {
        padding: theme.spacing(1, 0),
      },
      field: {
        padding: theme.spacing(1, 0),
      },
    },
  });

export interface IFormItemLayoutProps {
  label: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
  noSpace?: boolean;
}

type Props = IFormItemLayoutProps & WithStyles<typeof styles>;
const FormItemLayout = ({ label, description, children, classes, noSpace }: Props) => (
  <Grid
    alignItems="flex-start"
    className={clsx(classes.root, !noSpace && HORIZONTAL_SPACE_CLASSNAME)}
    container
  >
    <Grid item xs={12} md={4} className={classes.textContainer}>
      <Typography className={classes.label} variant="body1" color="textPrimary">
        {label}
      </Typography>

      {description && (
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      )}
    </Grid>

    <Grid item xs={12} md={8} className={classes.field}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles, { name: 'FdFormItemLayout' })(FormItemLayout);
