import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { Button } from '../../../ui/Button';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';
import { kioskServices } from '../Kiosks.services';

type InnerProps = MappedDispatch;
type OuterProps = { appId: string | undefined; deviceId: string | undefined; loading: boolean };
type Props = InnerProps & OuterProps;

const StripeTerminalPairingButton = ({ appId, deviceId, loading, notifyError }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isPending, mutate } = useMutation({
    mutationFn: () =>
      kioskServices.setStripeTerminalParingModeService({
        appId: appId as string,
        deviceId,
      }),

    onSuccess: () => {
      setDialogOpen(false);
    },

    onError: () => {
      setDialogOpen(false);
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });
  return (
    <>
      <Grid item xs={12} style={{ marginTop: -14 }}>
        {loading ? (
          <Skeleton width={'65%'} height={36} />
        ) : (
          <Button
            fdKey="stripe_terminal_open_modal"
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(true)}
            disabled={isPending}
          >
            <Translate id="Card_reader_pairing_mode" />
          </Button>
        )}
      </Grid>

      <YesNoDialog
        open={dialogOpen}
        onNo={() => setDialogOpen(false)}
        onYes={() => mutate()}
        titleTextId={'Connect_card_reader'}
        yesTextId={'OK'}
        yesButtonVariant={'contained'}
      >
        <Typography variant="body2" style={{ maxWidth: 432 }}>
          <Translate id="Enable_Bluetooth_modal" />
        </Typography>
      </YesNoDialog>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect<{}, InnerProps>(null, mapDispatchToProps)(StripeTerminalPairingButton);
