import React, { useCallback, useEffect, useState } from 'react';

import { Group, Product } from '@flipdish/api-client-typescript';
import Close from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TranslateFunction } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';
import LinkIconButton from '@fd/ui/Button/LinkIconButton';
import { DraggableCard } from '@fd/ui/molecules/DraggableCard';
import { DraggableExpansionPanel } from '@fd/ui/molecules/DraggableExpansionPanel';

import { generateSrcSet, getCatalogItemDetailsUrl } from '../../utils';
import { DraggableGroupList } from '../DraggableGroupList';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    borderRadius: '5px',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    backgroundColor: '#e0e0e0',
    border: 'none',
    borderRadius: '5px',
    height: '40px',
    marginRight: theme.spacing(1),
    padding: 0,
    width: '40px',
  },
  imageAndTextContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  lastNestedCard: {
    borderBottomLeftRadius: theme.spacing(0),
    borderBottom: 'none',
  },
  listContainer: {
    width: '100%',
  },
  name: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  nestedCard: {
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    borderRight: 'none',
  },
  rightGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  summaryContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export type DraggableItemListProps = {
  appId: string;
  isEditable?: boolean;
  items: Product[];
  onRemove: (id: string) => void;
  onUpdateOrder: (newOrder: Product[]) => void;
  parentId?: string;
  translate: TranslateFunction;
};
export const DraggableItemList = (props: DraggableItemListProps): JSX.Element => {
  const {
    appId,
    isEditable = true,
    items,
    onRemove,
    onUpdateOrder,
    parentId = '',
    translate,
  } = props;
  const [list, setList] = useState<Product[]>(items);
  const [shouldHighlight, setShouldHighlight] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    setList(items);
  }, [items]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = list[dragIndex];
      const hoverCard = list[hoverIndex];
      const newCards = [...list];

      newCards[dragIndex] = hoverCard;
      newCards[hoverIndex] = dragCard;
      setList(newCards);
      onUpdateOrder(newCards);
    },
    [list]
  );

  const renderItemContents = (item: Product) => {
    const groups = [] as Group[];
    item.Groups?.forEach((group) => {
      if (group.Group) {
        groups.push(group.Group);
      }
    });
    if (groups) {
      return (
        <div
          className={classes.listContainer}
          onMouseOver={() => setShouldHighlight(false)}
          onMouseLeave={() => setShouldHighlight(true)}
        >
          <DraggableGroupList
            appId={appId}
            isEditable={false}
            modifierGroups={groups}
            onRemove={() => {}}
            onUpdateOrder={() => {}}
            parentId={item.CatalogItemId}
            translate={translate}
          />
        </div>
      );
    }
    return null;
  };

  const renderCatalogItemSummary = (item: Product) => {
    const subtext =
      item.Groups && item.Groups.length > 0
        ? translate('number_of_options', { count: item.Groups?.length })
        : '';
    const detailsLink = getCatalogItemDetailsUrl(appId, item.CatalogItemId as string);
    const imageUrl = item.ImageFileName ? generateSrcSet(item.ImageFileName, 'xs') : undefined;
    return (
      <div className={classes.summaryContainer}>
        <div className={classes.imageAndTextContainer}>
          {imageUrl && (
            <Box className={classes.imageContainer}>
              <img className={classes.image} alt="" src={imageUrl} />
            </Box>
          )}
          <div className={classes.textContainer}>
            <Typography className={classes.name}>{item.Name}</Typography>
            <Typography variant="caption">{subtext}</Typography>
          </div>
        </div>
        <div className={classes.rightGroup}>
          <Typography>{item.Price?.toFixed(2)}</Typography>
          {isEditable && (
            <IconButton
              data-fd={`remove-modifier-${item.CatalogItemId}-button`}
              onClick={() => onRemove(item.CatalogItemId as string)}
            >
              <Close />
            </IconButton>
          )}
          <LinkIconButton
            fdKey={`item-${item.CatalogItemId}-link-button`}
            label={translate('Open_in_new_tab') as string}
            onClick={(e) => {
              e.stopPropagation();
            }}
            rel="noopener noreferrer"
            target="_blank"
            to={detailsLink}
          >
            <OpenInNewIcon />
          </LinkIconButton>
        </div>
      </div>
    );
  };

  const renderItems = () => {
    return list.map((item: Product, index) => {
      const cardStyles = clsx(
        !isEditable && classes.nestedCard,
        !isEditable && index === list.length - 1 && classes.lastNestedCard
      );
      return (
        <Grid key={`modifier-${item.CatalogItemId}`} item xs={12}>
          {item.Groups && item.Groups.length > 0 && item.Groups[0].Group ? (
            <DraggableExpansionPanel
              className={cardStyles}
              disabled={list.length < 2 || !isEditable}
              highlightOnHover={shouldHighlight}
              index={index}
              move={moveCard}
              panelId={item.CatalogItemId as string}
              summary={() => renderCatalogItemSummary(item)}
              type={`draggable-card-${parentId}`}
            >
              {renderItemContents(item)}
            </DraggableExpansionPanel>
          ) : (
            <DraggableCard
              cardId={item.CatalogItemId as string}
              className={cardStyles}
              disabled={list.length < 2 || !isEditable}
              highlightOnHover={shouldHighlight}
              index={index}
              moveCard={moveCard}
              type={`draggable-card-${parentId}`}
            >
              {renderCatalogItemSummary(item)}
            </DraggableCard>
          )}
        </Grid>
      );
    });
  };

  return (
    <Grid container spacing={1}>
      <DndProvider backend={HTML5Backend}>{renderItems()}</DndProvider>
    </Grid>
  );
};
