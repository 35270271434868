import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { CreateModifierGroup } from './pages/CreateModifierGroup';
import { ModifierGroupEdit } from './pages/ModifierGroupEdit';
import { ModifierGroups } from './pages/ModifierGroups';

type Props = RouteComponentProps;

const ModifierGroupRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="modifier-group">
      <Switch>
        <Route exact path={`${path}`} component={ModifierGroups} />
        <Route exact path={`${path}/create-modifier-group`} component={CreateModifierGroup} />
        <Route exact path={`${path}/:modifierGroupId`} component={ModifierGroupEdit} />
      </Switch>
    </ErrorBoundary>
  );
};

export default ModifierGroupRoutes;
