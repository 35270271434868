import React, { ReactNode } from 'react';

import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { NavLink, useLocation } from 'react-router-dom';

import { Button } from '@fd/ui/atoms';

import useQueryRMSCommonDataHook from '../../custom-hooks/useQueryRMSCommonDataHook';
import { isProductBasedMenusEnabled } from '../../selectors/app.selector';
import { flagService } from '../../services';
import { menuItemsConfig } from './menuItemsConfig';

const externalLinkRegExp = /^(https:\/\/)./;

const renderNavLink = React.forwardRef(
  (props: { to: string; children: ReactNode; className?: string; title: string }, ref: any) => {
    return externalLinkRegExp.test(props.to) ? (
      <a
        href={props.to}
        ref={ref}
        className={props.className}
        data-fd={props['data-fd']}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    ) : (
      <NavLink {...props} ref={ref} />
    );
  }
);

const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  collapsedListItem: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  active: {
    backgroundColor: '#eaf2ff',
    '& div': {
      color: '#05149e',
    },
  },
});

type Props = ReturnType<typeof mapStateToProps> & {
  setOpenSideNav: (open: boolean) => void;
  toggleSideNav: () => void;
};

const SideNavSubDrawer: React.FC<Props> = (props: Props) => {
  const { currentApp, isProductBasedMenusOn, setOpenSideNav, showRMS, toggleSideNav, translate } =
    props;
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: rmsData } = useQueryRMSCommonDataHook(currentApp?.AppId || '', showRMS);
  const subscriptions = rmsData?.subscriptions;

  const menuSource = rmsData?.associations?.menuSource;

  const menuItems = menuItemsConfig(currentApp, isProductBasedMenusOn, menuSource, subscriptions);
  const activeMenuItem = menuItems.find(
    (item) => item.subDrawerItems && item.subDrawerItems.length > 0
  );

  const itemTitle = activeMenuItem?.title;
  const subDrawerItems = activeMenuItem?.subDrawerItems;

  const onClick = () => {
    if (isMobile) {
      toggleSideNav();
    }
  };

  return (
    <Grid container spacing={2} pt={3} ml={0}>
      <Grid xs={12} pl={1}>
        <Button variant="text" fdKey="button-side-nav-back" onClick={() => setOpenSideNav(false)}>
          <ArrowLeftIcon />
          <Translate id="Back" />
        </Button>
      </Grid>

      {itemTitle && (
        <Grid xs={12} pt={1} pb={1} pl={4}>
          <Typography variant="subtitle2">
            <Translate id={itemTitle} />
          </Typography>
        </Grid>
      )}

      {subDrawerItems
        ?.filter((item) => !item.hidden)
        .map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <Permissions key={item.title} allowed={item.permissions || []}>
              <Grid
                xs={12}
                key={item.title}
                pl={3}
                pb={1}
                pt={1}
                className={clsx(classes.listItem, { [classes.active]: isActive })}
              >
                <ListItem
                  className={clsx(classes.collapsedListItem)}
                  disableGutters={true}
                  // @ts-expect-error: PRJSP-445
                  component={renderNavLink}
                  to={item.keepQuery ? `${item.path}${search}` : item.path}
                  title={translate(item.title) as string}
                  data-fd={`menu-sublink-${item.title}`}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  onClick={onClick}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="subtitle1">
                          <Translate id={item.title} />
                        </Typography>

                        {item.isBeta && (
                          <span
                            style={{
                              marginLeft: '8px',
                              fontSize: '10px',
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              color: '#fff',
                              borderRadius: '3px',
                              padding: '0px 6px',
                            }}
                          >
                            <Translate id="Beta" />
                          </span>
                        )}
                      </>
                    }
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
              </Grid>
            </Permissions>
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    currentApp: state.currentApp,
    translate: getTranslate(state.locale),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    showRMS: flagService.isFlagOn(state, 'showRMS'),
  };
};

export default connect(mapStateToProps)(SideNavSubDrawer);
