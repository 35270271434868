import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import PlaceholderPage from '@fd/ui/PlaceholderPage';

import { apmActions } from '../../actions/apm.actions';
import marketingEngage from '../../assets/images/illust_marketing_engage.png';
import TelephonyPlaceholderImage from '../../assets/images/PMPH-Web.png';
import { selectIsApmEnabled } from '../../selectors/apm.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import LookerDashboard from '../Reports/components/LookerDashboard';

type Props = MappedState & MappedDispatch;

const TelephonyLooker = (props: Props) => {
  const { AppId, getApmStatus, isApmEnabled, translate } = props;
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_phone_marketing', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    getApmStatus(AppId);
  }, []);

  const isApmLoading = isApmEnabled === null;
  if (isApmLoading) {
    return (
      <PageLayout
        title={translate('Flipdish_Phone_Marketing')}
        documentTitle="Flipdish_Phone_Marketing"
      >
        <AppLoadingCircularProgress />
      </PageLayout>
    );
  }

  if (isApmEnabled === false) {
    return (
      <PlaceholderPage
        btnText="Learn_more"
        cardImage={marketingEngage}
        cardText={['marketing_engage_1', 'marketing_engage_2']}
        helpdeskLink="https://help.flipdish.com/en/articles/9585393-flipdish-phone-marketing-overview"
        placeholderImage={TelephonyPlaceholderImage}
        title="Flipdish_Phone_Marketing"
      />
    );
  }

  return (
    <div>
      <LookerDashboard isGlobal={true} />
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    AppId: state.currentApp.AppId,
    isApmEnabled: selectIsApmEnabled(state),
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getApmStatus: (AppId) => dispatch(apmActions.getStatus(AppId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyLooker);
