import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { DeliveryZone, IDeliveryZone } from '../../types';

const useStyles = makeStyles(() => ({
  section: { paddingTop: '8px' },
  header: {
    fontSize: '12px',
    paddingLeft: 10,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  deliveryZoneOption: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
}));

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type Props = {
  storeZoneOptions?: IDeliveryZone<DeliveryZone[]>;
  currency?: CurrencyEnum;
  allShapes: {
    [key: number]: { checked: boolean; shape: google.maps.Polygon | google.maps.Polygon[] };
  };
  languageCode: string;
  setSelectedPolygon: (checked: boolean, zones: DeliveryZone) => void;
} & MappedProps;

const StoreZoneSection = (props: Props) => {
  const classes = useStyles();
  const { storeZoneOptions, currency, allShapes, setSelectedPolygon, languageCode, storeHeaders } =
    props;
  const options = storeZoneOptions
    ? Object.keys(storeZoneOptions).map((sz) => storeZoneOptions[sz])
    : [];

  const renderedSection = options.map((storeZones) => {
    const storeId = storeZones[0].storeId;
    const keyTitle = `Store ${storeId}`;
    const store = storeHeaders?.find((header) => header.StoreId === storeId);
    return (
      <div key={keyTitle} className={classes.section}>
        <div className={classes.header}>{store ? store.Name : keyTitle}</div>
        {storeZones.map((zone, idx) => {
          return (
            <div key={idx} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Checkbox
                color="secondary"
                checked={allShapes[zone.id] ? allShapes[zone.id].checked : false}
                onChange={(evt) => setSelectedPolygon(evt.target.checked, zone)}
                inputProps={{ 'aria-label': 'checkbox with default color' }}
              />
              {currency ? (
                <span className={classes.deliveryZoneOption}>{`${
                  idx + 1
                }. (min: ${zone.minimumDeliveryOrderAmount.toLocaleString(languageCode, {
                  style: 'currency',
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}, Fee:${zone.deliveryFee.toLocaleString(languageCode, {
                  style: 'currency',
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })})`}</span>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  });
  return <>{renderedSection}</>;
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    storeHeaders: state.reports.orderReports.storeHeaders,
  };
};

export default connect(mapStateToProps)(StoreZoneSection);
