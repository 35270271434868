import React from 'react';

import { AppStoreAppConfiguration, RestApiErrorResult } from '@flipdish/api-client-typescript';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { type History } from 'history';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

import { DynamicAlertBanner } from '@fd/ui/molecules/DynamicAlertBanner/DynamicAlertBanner';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import PageLayout from '../../../../../ui/Layout';
import { AppStoreAppDetailCard } from '../../../components/AppStoreAppDetailCard';
import { AppStoreAppDetailsLoadingSkeleton } from '../../../components/AppStoreAppDetailsLoadingSkeleton';
import { AppStoreMultipleConfigsList } from '../../../components/AppStoreMultipleConfigsList';
import { appStoreService } from '../../../services/appstore.service';
import PaywallWrapper from './PaywallWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  appStoreAppConfigs: {
    flexWrap: 'wrap',
    marginTop: 20,
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

type Props = RouteComponentProps<{ appStoreAppid: string }>;

type AppStoreAppDetailsProps = {
  history: History;
};

const AppStoreAppDetails = ({
  appId,
  translate,
  appStoreAppid,
  history,
  notifyError,
}: AppStoreAppDetailsProps & MappedState & MappedDispatch) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const sessionId = params.get('session_id');

  const { data, isPending } = useQuery({
    queryKey: [appStoreService.getAppStoreAppByIdKey, appStoreAppid],
    queryFn: () => appStoreService.getAppStoreAppById(appStoreAppid, appId),
  });

  const { data: configData, isPending: configDataLoading } = useQuery({
    queryKey: [appStoreService.getAppStoreAppConfigsByIdKey, appId, appStoreAppid],
    queryFn: () => appStoreService.getAppStoreAppConfigsById(appId as string, appStoreAppid),
  });

  const createAppStoreAppConfig = async () => {
    return mutateAsync();
  };

  const handleCreateAppStoreConfigSuccess = (appConfiguration: AppStoreAppConfiguration) =>
    history.push(`/${appId}/appstore/${appStoreAppid}/config/${appConfiguration.Id}`);

  const { mutateAsync } = useMutation({
    mutationFn: () => appStoreService.createAppStoreAppConfig(appId, appStoreAppid),

    onSuccess: (appConfigurationData) => {
      queryClient.invalidateQueries({
        queryKey: [appStoreService.getAppStoreAppConfigsByIdKey],
      });
      handleCreateAppStoreConfigSuccess(appConfigurationData.Data);
    },

    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        notifyError({ message: apiErrorMessage.Message });
      }
    },
  });

  const getButtonText = () => {
    if (configData?.Data && configData.Data.length > 0) {
      return translate('Add_a_new_configuration') as string;
    }

    return translate('Install_App') as string;
  };

  const renderContent = () => {
    if (isPending) {
      return <AppStoreAppDetailsLoadingSkeleton />;
    }
    if (data) {
      return (
        <AppStoreAppDetailCard
          appStoreApp={data}
          buttonText={getButtonText()}
          buttonAction={createAppStoreAppConfig}
          isConfigured={configData?.Data.length ? configData.Data.length > 0 : false}
        />
      );
    }
    return null;
  };

  const renderContentConfigs = () => {
    if (configDataLoading) {
      return <AppStoreAppDetailsLoadingSkeleton />;
    }
    if (configData?.Data && configData.Data.length > 0) {
      return <AppStoreMultipleConfigsList appStoreApps={configData.Data} appId={appId} />;
    }
    return <></>;
  };

  return (
    <>
      <PageLayout
        caption={data && data.Name}
        toParent={`/${appId}/appstore/new`}
        documentTitle="App_Store"
        title={translate('App_Store')}
      >
        {sessionId && (
          <DynamicAlertBanner
            backgroundColor="green"
            bannerText={'AppStore_Subscription_Successfully_Created'}
            showButton={false}
          />
        )}
        {renderContent()}

        <PaywallWrapper isPaid={data?.IsPaid} appstoreAppId={data?.Id}>
          <Paper className={classes.appStoreAppConfigs}>{renderContentConfigs()}</Paper>
        </PaywallWrapper>
      </PageLayout>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
    // @ts-ignore
    appStoreAppid: props.match.params.appStoreAppId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppStoreAppDetails);
