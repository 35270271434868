import React, { memo, useCallback, useEffect, useState } from 'react';

import { WebsiteImage } from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { keyBy } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { formikValidate } from '../../../helpers/validation';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import Button from '../../../ui/Button/Button';
import CustomFileInput from '../../../ui/CustomFileInput';
import DragAndDropCard from '../../../ui/DragAndDropCard';
import {
  DELETE_WEBSITE_SETTINGS_IMAGE,
  deleteWebsiteSettingsImage,
  UPLOAD_WEBSITE_SETTINGS_IMAGE,
  uploadWebsiteSettingsImage,
} from '../actions';
import { createFormData } from './utils';

const savingSelector = createLoadingSelector([
  UPLOAD_WEBSITE_SETTINGS_IMAGE,
  DELETE_WEBSITE_SETTINGS_IMAGE,
]);

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -1),

    '& > * > *': {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    width: '25%',
    minWidth: 200,
    marginBottom: 10,
    '& > *': {
      position: 'relative',
      background: '#ccc',
    },
  },
  remove: {
    position: 'absolute',
    top: 6,
    right: 6,
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    padding: 0,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: '#fff',
    },
  },
  add: {
    position: 'relative',

    '& > *': {
      background: 'none',
    },
  },
  addContent: {
    paddingTop: '66%',
  },
  label: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: '1px solid #c4c4c4',
  },
  image: {
    height: '100%',
    paddingTop: '66%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  panelContent: {
    flexDirection: 'column',
  },
  buttonWrapper: {
    textAlign: 'right',
  },
}));

type CardType = WebsiteImage | { id: number; data: string; file: File };

type OuterProps = {
  images: WebsiteImage[];
  fdKeyName: string;
  imageLocation: WebsiteImage.ImageLocationEnum;
  maxImages: number;
  minImages?: number;
};

type Props = OuterProps & MappedState & MappedDispatch;

const EditWebsiteSettingsCarouselImagesPanel = (props: Props) => {
  const {
    fdKeyName,
    maxImages,
    minImages,
    images,
    imageLocation,
    uploadImage,
    deleteImage,
    saving,
  } = props;
  const classes = useStyles();
  const [cards, setCards] = useState<CardType[]>(images);
  const [error, setError] = useState<string | undefined>();

  const loadFiles = async (files: FileList | WebsiteImage[], reset: () => void) => {
    try {
      let newCards: any = files;

      if (files instanceof FileList) {
        const result = await Promise.all(
          Array.from(files).map((file) => {
            return new Promise<string>((resolve) => {
              const fileReader = new FileReader();

              fileReader.readAsDataURL(file);
              fileReader.onload = () => resolve(fileReader.result as string);
            });
          })
        );
        newCards = result.map((item, index) => ({
          id: Date.now() + index,
          file: files[index],
          data: item,
        }));
      }

      setCards([...cards, ...newCards]);
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  const removeCard = (id?: number) => {
    if (id) {
      setCards(
        cards.filter(
          (card) => ('id' in card && card.id !== id) || ('ImageId' in card && card.ImageId !== id)
        )
      );
    }
  };

  const handleSave = useCallback(() => {
    const imagesSize = cards.length;
    const error = minImages
      ? formikValidate.min(minImages, 'Min_images_error_message')(imagesSize)
      : undefined;

    setError(error);

    if (error) {
      return;
    }

    const originalMap = keyBy(images, (item) => item.ImageId);
    // @ts-ignore
    const newMap = keyBy(cards, (item) => item.id || item.ImageId);
    const imagesToUpload: FormData[] = [];
    const imagesToDelete: number[] = [];

    for (let i = 0; i < images.length; i++) {
      const original = images[i];
      const id = original.ImageId;

      if (id) {
        if (id in newMap) {
        } else {
          imagesToDelete.push(id);
        }
      }
    }

    for (let i = 0; i < cards.length; i++) {
      const item = cards[i];
      // @ts-ignore
      const id = item.ImageId || item.id;

      if (id) {
        if (!(id in originalMap) && 'file' in item) {
          imagesToUpload.push(createFormData(item.file));
        }
      }
    }

    if (imagesToUpload.length) {
      uploadImage(imageLocation, imagesToUpload);
    }
    if (imagesToDelete.length) {
      deleteImage(imagesToDelete);
    }
  }, [images, cards, minImages]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      const hoverCard = cards[hoverIndex];
      const newCards = [...cards];

      newCards[dragIndex] = hoverCard;
      newCards[hoverIndex] = dragCard;

      setCards(newCards);
    },
    [cards]
  );
  const handleInputChange = (files: FileList | null, reset: () => void) => {
    if (files) {
      const imagesSize = files.length + cards.length;
      const error =
        formikValidate.extension(files, ['png', 'jpeg', 'jpg']) ||
        formikValidate.max(maxImages)(imagesSize);

      setError(error);

      if (!error) {
        loadFiles(files, reset);
      }
    }
  };

  const renderCard = (card: CardType, index: number) => {
    return (
      <DragAndDropCard
        className={classes.item}
        // @ts-ignore
        key={card.id || card.ImageId}
        index={index}
        // @ts-ignore
        id={card.id || card.ImageId}
        disabled
        moveCard={moveCard}
      >
        <div>
          <div
            className={classes.image}
            // @ts-ignore
            style={{ backgroundImage: `url(${card.ImageUrl || card.data})` }}
          />
          <IconButton
            data-fd={`${fdKeyName}-remove-btn-${index}`}
            className={classes.remove}
            // @ts-ignore
            onClick={() => removeCard(card.id || card.ImageId)}
          >
            <RemoveIcon htmlColor="#ff395f" />
          </IconButton>
        </div>
      </DragAndDropCard>
    );
  };

  useEffect(() => {
    setCards(images);
  }, [images]);

  return (
    <Box>
      <Typography paragraph>
        <Translate id="These_images_change_to_the_next_image_every_15_sec" />
      </Typography>
      <Box className={classes.wrapper}>
        <DndProvider backend={HTML5Backend}>
          {cards.map(renderCard)}
          {cards.length < maxImages ? (
            <div className={`${classes.add} ${classes.item}`}>
              <div>
                <div className={classes.addContent}>
                  <CustomFileInput
                    multiple
                    disabled={saving}
                    className={classes.label}
                    fdKey={`${fdKeyName}-upload-input`}
                    onChange={handleInputChange}
                  >
                    <AddIcon htmlColor="#05149e" />
                  </CustomFileInput>
                </div>
              </div>
            </div>
          ) : null}
        </DndProvider>
      </Box>
      {error ? (
        <FormControl error fullWidth>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      ) : null}
      <Box className={classes.buttonWrapper}>
        <Button
          variant="contained"
          fdKey={`save-image-carousel-${imageLocation}`}
          onClick={handleSave}
          disabled={saving}
          color="primary"
        >
          <Translate id="Save" />
        </Button>
      </Box>
    </Box>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    saving: savingSelector(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  uploadImage: (imageLocation: WebsiteImage.ImageLocationEnum, file: FormData | FormData[]) =>
    dispatch(uploadWebsiteSettingsImage(imageLocation, file)),
  deleteImage: (id: number | number[]) => dispatch(deleteWebsiteSettingsImage(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(EditWebsiteSettingsCarouselImagesPanel));
