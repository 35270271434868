import React, { useEffect, useState } from 'react';

import { AppConfigSalesChannel } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { FormSection } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7),
    },
  },
}));

type Props = {
  isDisabled?: boolean;
  isLoading: boolean;
  onChange: (updatedSetting: Partial<AppConfigSalesChannel>) => void;
  translate: TranslateFunction;
  value?: AppConfigSalesChannel.WebToAppRedirectEnum;
};

export const WebToAppRedirectSelectFilter = ({
  isDisabled,
  isLoading,
  onChange,
  translate,
  value,
}: Props) => {
  const classes = useStyles();
  const [webToAppRedirectState, setWebToAppRedirectState] =
    useState<AppConfigSalesChannel.WebToAppRedirectEnum>(
      value || AppConfigSalesChannel.WebToAppRedirectEnum.NoRedirect
    );

  useEffect(() => {
    if (value) {
      setWebToAppRedirectState(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setWebToAppRedirectState(event.target.value);
    onChange({ WebToAppRedirect: event.target.value });
  };

  return (
    <FormSection sectionTitle={translate('Web_to_app_redirect') as string} isLoading={isLoading}>
      <div className={classes.selectContainer}>
        <TextField
          data-fd={'set-web-to-app-redirect-dropdown'}
          fullWidth
          disabled={isDisabled}
          label={translate('Preference') as string}
          onChange={handleChange}
          select
          value={webToAppRedirectState}
          variant="outlined"
        >
          <MenuItem value={AppConfigSalesChannel.WebToAppRedirectEnum.RedirectAlways}>
            <Translate id="Redirect_always" />
          </MenuItem>
          <MenuItem value={AppConfigSalesChannel.WebToAppRedirectEnum.RedirectOnce}>
            <Translate id="Redirect_once" />
          </MenuItem>
          <MenuItem value={AppConfigSalesChannel.WebToAppRedirectEnum.NoRedirect}>
            <Translate id="No_redirect" />
          </MenuItem>
        </TextField>
      </div>
    </FormSection>
  );
};
