import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button, Drawer, Typography } from '@fd/ui/atoms';

import {
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../../../layouts/Notify/actions';
import { catalogChangesService } from '../../../../services/catalogChanges.service';
import { ChangedCatalogElement } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    width: '65%',
  },
  descriptionContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  elementContainer: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    width: '64px',
    height: '64px',
    borderRadius: '5px',
  },
  listContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  nameContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export type CatalogChangesDrawerProps = {
  appId: string;
  catalogElements: ChangedCatalogElement[];
  menuId: number;
  onChangesPublished: () => void;
  onClose: () => void;
  open: boolean;
  translate: TranslateFunction;
};

const CatalogChangesDrawer = (props: CatalogChangesDrawerProps & MappedDispatch): JSX.Element => {
  const {
    appId,
    catalogElements,
    closeNotifyUpdating,
    menuId,
    showUpdateSuccessMessage,
    notifyPublishError,
    notifyUpdating,
    onChangesPublished,
    onClose,
    open,
    translate,
  } = props;

  const classes = useStyles();
  const noChanges = catalogElements.length === 0;

  const { mutate } = useMutation({
    mutationFn: (catalogElementId: string) => {
      notifyUpdating();
      return catalogChangesService.publishPendingMenuChanges(appId, catalogElementId, [menuId]);
    },

    onSuccess: () => {
      closeNotifyUpdating();
      showUpdateSuccessMessage({
        message: translate('menu_updated_title') as string,
        variant: 'success',
      });
      onChangesPublished();
    },

    onError: () => {
      closeNotifyUpdating();
      notifyPublishError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const handleUpdateAllClick = () => {
    mutate('');
  };

  const handleUpdateElement = (elementId: string) => {
    mutate(elementId);
  };

  const renderCatalogElements = () => {
    if (noChanges) {
      return (
        <Typography>
          <Translate id="CatalogChangesDrawer_noChangesMessage" />
        </Typography>
      );
    }
    return catalogElements.map((element) => {
      return (
        <div key={`element-${element.elementId}`} className={classes.elementContainer}>
          <div className={classes.nameContainer}>
            <Typography>{element.elementName}</Typography>
          </div>
          <Button
            fdKey={`update-button-${element.elementId}`}
            lowercase
            onClick={() => handleUpdateElement(element.elementId)}
            variant="text"
          >
            <Translate id="Update" />
          </Button>
        </div>
      );
    });
  };
  return (
    <Drawer
      onClose={onClose}
      open={open}
      header={
        <div className={classes.headerContainer}>
          <Typography variant="h5">
            <Translate id="Pending_updates" />
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      }
    >
      <div className={classes.descriptionContainer}>
        <Typography className={classes.description} variant="caption">
          <Translate id="CatalogChangesDrawer_description" />
        </Typography>
        <Button
          disabled={noChanges}
          fdKey="update-all-changes-drawer-button"
          onClick={handleUpdateAllClick}
        >
          <Translate id="Update_all" />
        </Button>
      </div>
      <Divider />
      <div className={classes.listContainer}>{renderCatalogElements()}</div>
    </Drawer>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyUpdating: () => dispatch(closeNotifyLoading()),
  showUpdateSuccessMessage: (data: NotifyProps) => dispatch(notify(data)),
  notifyPublishError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyUpdating: () => dispatch(notifyLoading({ persist: true })),
});
const EnhancedComponent = connect(null, mapDispatchToProps)(CatalogChangesDrawer);
export { EnhancedComponent as CatalogChangesDrawer };
