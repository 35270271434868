import { ChannelsApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const channelsApi = createApi(ChannelsApiFactory);

// #region getChannels
const getChannels = async (appId: string) => {
  try {
    const body = await channelsApi.getChannels(appId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region getChannel
const getChannel = async (channelId: number, appId: string) => {
  try {
    const body = await channelsApi.getChannel(channelId, appId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region getAvailableChannels
const getAvailableChannels = async (appId: string) => {
  try {
    const body = await channelsApi.getAvailableChannels(appId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region getAssignedChannels
const getAssignedChannels = async (appId: string) => {
  try {
    const body = await channelsApi.getAssignedChannels(appId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region assignAppIdToSalesChannel
const assignAppIdToSalesChannel = async (appId: string, channelId: number) => {
  try {
    const body = await channelsApi.assignAppIdToSalesChannel(appId, channelId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region getStoresAssignedToChannel
const getStoresAssignedToChannel = async (appId: string, channelId: number) => {
  try {
    const body = await channelsApi.getStoresAssignedToChannel(appId, channelId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

// #region assignStoreToChannel
const assignStoreToChannel = async (storeId: number, appId: string, channelId: number) => {
  try {
    // Generated api does not know the return type
    const body = await channelsApi.assignStoreToChannel(storeId, appId, channelId);
    return body;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
// #endregion

export const channelsService = {
  getChannels,
  getAvailableChannels,
  getAssignedChannels,
  getChannel,
  assignAppIdToSalesChannel,
  getStoresAssignedToChannel,
  assignStoreToChannel,
};
