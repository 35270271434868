import React, { useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

import { formikValidate } from '../../../../../../helpers/validation';
import FileUpload from './FileUpload';

const fileReader = new FileReader();

const LogoField = ({ logoUrl }: { logoUrl?: null | string }) => {
  const [logo, setLogo] = useState<null | string | undefined>(logoUrl);
  const [showErrorImmediately, setShowErrorImmediately] = useState<boolean>(false);

  const validateLogo = (value: File) => {
    if (typeof value === 'string') {
      return;
    }

    const isNotValidExtension = formikValidate.extension(value, ['png', 'jpeg', 'jpg']);

    if (isNotValidExtension) {
      setLogo(null);
      setShowErrorImmediately(true);
      return isNotValidExtension;
    }

    if (value) {
      fileReader.readAsDataURL(value);
      fileReader.onload = () => {
        if (fileReader.result) {
          setLogo(fileReader.result as string);
        }
      };
      fileReader.onerror = () => {
        setLogo(null);
        setShowErrorImmediately(true);
        return <Translate id="Error_please_try_again_later" />;
      };
    } else {
      return <Translate id="Required" />;
    }
  };

  return (
    <Field name="Logo" validate={validateLogo}>
      {({ field, form }: FieldProps) => {
        const { errors, submitCount } = form;
        const fieldError = errors[field.name] as string | undefined;

        const showError =
          !!fieldError &&
          !logo &&
          ((submitCount > 0 || showErrorImmediately) as boolean | undefined);

        return (
          <FormItemLayout label={<Translate id="Logo" />}>
            <FormHelperText>
              <Translate id="App_logo_recommended_size" />
            </FormHelperText>
            <FileUpload
              fdKey="logo"
              url={logo}
              deleteBtn={!!logoUrl}
              onChange={(data?: File) => {
                form.setFieldValue('Logo', data);
                if (!data) {
                  setLogo(null);
                }
              }}
            />

            {fieldError && showError && (
              <FormHelperText error>{showError ? fieldError : ''}</FormHelperText>
            )}
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default LogoField;
