import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Switch from '@fd/ui/Switch/Switch';

import FormItemLayout from '../../FormItemLayout';

const IncludeDeliveryFeeField = ({ disabled = false }) => (
  <Field name={'IncludeDeliveryFee'}>
    {({ field, form: { isSubmitting } }: FieldProps) => {
      return (
        <FormItemLayout
          description={<Translate id="Include_delivery_fee_description" />}
          label={<Translate id="Include_delivery_fee" />}
          noSpace
        >
          <Switch
            name={field.name}
            value={field.value}
            checked={field.value}
            onChange={field.onChange}
            fdKey={'Include_delivery_fee'}
            disabled={isSubmitting || disabled}
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default IncludeDeliveryFeeField;
