import { PushNotificationRequest, PushNotificationResponse } from '@flipdish/api-client-typescript';

import { action, actionError } from '../../actions/utils';
import {
  deletePushNotification as deleteNotification,
  getPushNotifications as getPushNotificationsLogs,
  sendPushNotification as sendNotofication,
  updatePushNotification as updateNotofication,
} from './services';

// #region getPushNotifications

export const GET_PUSH_NOTIFICATIONS = 'GET_PUSH_NOTIFICATIONS';
export const GET_PUSH_NOTIFICATIONS_REQUEST = 'GET_PUSH_NOTIFICATIONS_REQUEST';
export const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_FAILURE = 'GET_PUSH_NOTIFICATIONS_FAILURE';

export type GetPushNotificationsRequest = ReturnType<typeof getPushNotificationsRequest>;
export const getPushNotificationsRequest = () => action(GET_PUSH_NOTIFICATIONS_REQUEST);

export type GetPushNotificationsSuccess = ReturnType<typeof getPushNotificationsSuccess>;
export const getPushNotificationsSuccess = (
  data: PushNotificationResponse[],
  totalRecords: number,
  page: number
) => action(GET_PUSH_NOTIFICATIONS_SUCCESS, { data, totalRecords, page });

export type GetPushNotificationsFailure = ReturnType<typeof getPushNotificationsFailure>;
export const getPushNotificationsFailure = (error: Error) =>
  actionError(GET_PUSH_NOTIFICATIONS_FAILURE, undefined, error);

export function getPushNotifications(page: number, limit: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(getPushNotificationsRequest());

      const result = await getPushNotificationsLogs(appId, page, limit);

      dispatch(getPushNotificationsSuccess(result.Data, result.TotalRecordCount, result.Page));
    } catch (error) {
      dispatch(getPushNotificationsFailure(error));
    }
  };
}
// #endregion

// #region sendPushNotification

export const SEND_PUSH_NOTIFICATION = 'SEND_PUSH_NOTIFICATION';
export const SEND_PUSH_NOTIFICATION_REQUEST = 'SEND_PUSH_NOTIFICATION_REQUEST';
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS';
export const SEND_PUSH_NOTIFICATION_FAILURE = 'SEND_PUSH_NOTIFICATION_FAILURE';

export type SendPushNotificationRequest = ReturnType<typeof sendPushNotificationRequest>;
export const sendPushNotificationRequest = () => action(SEND_PUSH_NOTIFICATION_REQUEST);

export type SendPushNotificationSuccess = ReturnType<typeof sendPushNotificationSuccess>;
export const sendPushNotificationSuccess = (notification: PushNotificationRequest) =>
  action(SEND_PUSH_NOTIFICATION_SUCCESS, notification);

export type SendPushNotificationFailure = ReturnType<typeof sendPushNotificationFailure>;
export const sendPushNotificationFailure = (error: Error) =>
  actionError(SEND_PUSH_NOTIFICATION_FAILURE, undefined, error);

export function sendPushNotification(notification: PushNotificationRequest) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(sendPushNotificationRequest());

      const { Data } = await sendNotofication(appId, notification);

      dispatch(sendPushNotificationSuccess(Data));
    } catch (error) {
      dispatch(sendPushNotificationFailure(error));
    }
  };
}
// #endregion

// #region updatePushNotification

export const UPDATE_PUSH_NOTIFICATION = 'UPDATE_PUSH_NOTIFICATION';
export const UPDATE_PUSH_NOTIFICATION_REQUEST = 'UPDATE_PUSH_NOTIFICATION_REQUEST';
export const UPDATE_PUSH_NOTIFICATION_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_SUCCESS';
export const UPDATE_PUSH_NOTIFICATION_FAILURE = 'UPDATE_PUSH_NOTIFICATION_FAILURE';

export type UpdatePushNotificationRequest = ReturnType<typeof updatePushNotificationRequest>;
export const updatePushNotificationRequest = () => action(UPDATE_PUSH_NOTIFICATION_REQUEST);

export type UpdatePushNotificationSuccess = ReturnType<typeof updatePushNotificationSuccess>;
export const updatePushNotificationSuccess = (notification: PushNotificationRequest) =>
  action(UPDATE_PUSH_NOTIFICATION_SUCCESS, notification);

export type UpdatePushNotificationFailure = ReturnType<typeof updatePushNotificationFailure>;
export const updatePushNotificationFailure = (error: Error) =>
  actionError(UPDATE_PUSH_NOTIFICATION_FAILURE, undefined, error);

export function updatePushNotification(notification: PushNotificationResponse) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      const { ScheduledPushNotificationId, ...sendData } = notification;

      if (ScheduledPushNotificationId) {
        dispatch(updatePushNotificationRequest());

        const { Data } = await updateNotofication(appId, ScheduledPushNotificationId, sendData);

        dispatch(updatePushNotificationSuccess(Data));
      }
    } catch (error) {
      dispatch(updatePushNotificationFailure(error));
    }
  };
}
// #endregion

// #region deletePushNotification

export const DELETE_PUSH_NOTIFICATION = 'DELETE_PUSH_NOTIFICATION';
export const DELETE_PUSH_NOTIFICATION_REQUEST = 'DELETE_PUSH_NOTIFICATION_REQUEST';
export const DELETE_PUSH_NOTIFICATION_SUCCESS = 'DELETE_PUSH_NOTIFICATION_SUCCESS';
export const DELETE_PUSH_NOTIFICATION_FAILURE = 'DELETE_PUSH_NOTIFICATION_FAILURE';

export type DeletePushNotificationRequest = ReturnType<typeof deletePushNotificationRequest>;
export const deletePushNotificationRequest = () => action(DELETE_PUSH_NOTIFICATION_REQUEST);

export type DeletePushNotificationSuccess = ReturnType<typeof deletePushNotificationSuccess>;
export const deletePushNotificationSuccess = (scheduledPushNotificationId: number) =>
  action(DELETE_PUSH_NOTIFICATION_SUCCESS, scheduledPushNotificationId);

export type DeletePushNotificationFailure = ReturnType<typeof deletePushNotificationFailure>;
export const deletePushNotificationFailure = (error: Error) =>
  actionError(DELETE_PUSH_NOTIFICATION_FAILURE, undefined, error);

export function deletePushNotification(scheduledPushNotificationId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(deletePushNotificationRequest());

      await deleteNotification(appId, scheduledPushNotificationId);

      dispatch(deletePushNotificationSuccess(scheduledPushNotificationId));
    } catch (error) {
      dispatch(deletePushNotificationFailure(error));
    }
  };
}
// #endregion
