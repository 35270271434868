import React, { useEffect, useState } from 'react';

import { MetafieldDefinitionRecommendation } from '@flipdish/api-client-typescript';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { TranslateFunction } from 'react-localize-redux';

import { metafieldsService } from '../../../metafields.service';
import { AutoCompleteField } from '../AutoCompleteField';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '5rem',
  },
}));

export type NameFieldProps = {
  appId: string;
  onInputTextChange: (value: string) => void;
  onRecommendationSelected: (value: MetafieldDefinitionRecommendation) => void;
  ownerEntity: string;
  translate: TranslateFunction;
};

type RecommendedMetafieldOption = {
  label: string;
  value: MetafieldDefinitionRecommendation;
};

export const NameField = (props: NameFieldProps) => {
  const { appId, onInputTextChange, onRecommendationSelected, ownerEntity, translate } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [options, setOptions] = useState<RecommendedMetafieldOption[]>([]);
  const classes = useStyles();

  const { data, isPending, isSuccess } = useQuery({
    queryKey: [
      metafieldsService.getOwnerEntityRecommendationsQueryKey,
      appId,
      ownerEntity,
      searchQuery,
    ],
    queryFn: () => metafieldsService.getOwnerEntityRecommendations(appId, ownerEntity, searchQuery),
  });

  useEffect(() => {
    if (isSuccess && data) {
      const newOptions = data.Data?.map((recommendation: MetafieldDefinitionRecommendation) => {
        return {
          label: recommendation.Name,
          value: recommendation,
        };
      });
      setOptions(newOptions || []);
    }
  }, [data, isSuccess]);

  const handleRecommendationSelected = (recommendation?: MetafieldDefinitionRecommendation) => {
    recommendation ? onRecommendationSelected(recommendation) : onInputTextChange('');
  };

  const handleInputTextChange = (newInputValue: string) => {
    setSearchQuery(newInputValue);
    onInputTextChange(newInputValue);
  };

  return (
    <div className={classes.container}>
      <AutoCompleteField
        inputId="react-select"
        isLoading={isPending}
        onInputTextChange={handleInputTextChange}
        onSelectChange={handleRecommendationSelected}
        options={options}
        TextFieldProps={{
          fdKey: 'metafield-form-name',
          variant: 'standard',
          label: translate('Name') as string,
          style: { marginTop: 0 },
        }}
      />
    </div>
  );
};
