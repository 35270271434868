import React from 'react';

import Permissions from 'react-redux-permissions';
import { type RouteComponentProps, Redirect, Route, Switch, withRouter } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import KiosksRoutes from '../Kiosks/Kiosks.routes';
import RMSPage from '../RMS/components/RMSPage';
import SalesChannelRoutes from '../RMS/SalesChannels/SalesChannelRoutes';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import MarketPlacesPage from './MarketPlaces/MarketPlacesPage';
import { MobileApps } from './MobileApps/pages/MobileApps';
import PointOfSaleRoutes from './pos.routes';
import WebsiteRoutes from './website.routes';

type RouteParams = {
  appId?: string;
};

type Props = RouteComponentProps<RouteParams>;
const SalesChannelsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path, params },
  } = props;

  return (
    <ErrorBoundary identifier="sales-channels">
      <Switch>
        <>
          <Route
            exact
            path={`${path}/website-settings`}
            render={() => <Redirect to={`/${params?.appId}/sales-channels/website`} />}
          />
          <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
            <Route exact path={`${path}/mobile-apps`} component={MobileApps} />
            <WebsiteRoutes path={path} />
          </Permissions>
          <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <PointOfSaleRoutes path={path} appId={params?.appId} />
            <Route
              path={`${path}/:type(point-of-sale|website|kiosk|marketplaces|mobile-apps)`}
              component={SalesChannelRoutes}
            />
            <Route exact path={`${path}/marketplaces`} component={MarketPlacesPage} />
            <Route
              exact
              path={`${path}/:type(point-of-sale|website|kiosk|mobile-apps)/devices`}
              render={() => (
                <LazyComponent>
                  <RMSPage
                    toParent="./"
                    subscription={RMSSubscriptions.BASE}
                    title={'Devices'}
                    module={RMSModules.STATIONS}
                    url={`configurations/system/${RMSModules.STATIONS}`}
                    includePropertyIdInUrl
                  />
                </LazyComponent>
              )}
            />
          </Permissions>
          <Permissions allowed={['ViewHydraConfig']}>
            <KiosksRoutes path={path} />
          </Permissions>
        </>
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(SalesChannelsRoutes);
