import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { resetStoreSearch } from '../../Reports/OrderReport.actions';
import CustomerListFilters from '../components/Filters/Filters';
import { CurrencyEnum } from '../components/Tables/CustomersDataTable';
import CustomersTable from '../components/Tables/CustomersTable';
import { resetCustomerList } from '../Customers.actions';
import { getStoreHeadersList } from '../Customers.selectors';

const StyledContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type Props = MappedProps & MappedDispatch;

const CustomerList = ({ resetCustomerList, resetStoreSearch }: Props) => {
  const location = useLocation();
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [storeIds, setStoreIdsFilter] = useState<number[] | CurrencyEnum>([]);
  const [currency, setCurrency] = useState<CurrencyEnum>();

  useEffect(() => {
    return () => {
      resetCustomerList();
      resetStoreSearch();
    };
  }, []);

  return (
    <>
      <StyledContainer>
        <CustomerListFilters
          currency={currency}
          location={location}
          searchFilter={searchFilter}
          setCurrency={setCurrency}
          setSearchFilter={setSearchFilter}
          setStoreIdsFilter={setStoreIdsFilter}
          storeIds={storeIds}
        />
      </StyledContainer>
      <CustomersTable
        currency={currency}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        storeIds={storeIds}
      />
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    storeHeaders: getStoreHeadersList(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  resetCustomerList: () => dispatch(resetCustomerList()),
  resetStoreSearch: () => dispatch(resetStoreSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
