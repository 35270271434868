import {
  AppsApiFactory as AppsPrivateApiFactory,
  TermsAndPolicyData,
} from '@flipdish/api-client-typescript/private/api';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const appsPrivateApi = createApi(AppsPrivateApiFactory);

export const getTermsAndPolicyData = async (appId: string) => {
  try {
    const incomingMessage = await appsPrivateApi.getTermsAndPolicyData(appId);

    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const setTermsAndPolicyData = async (
  appId: string,
  termsAndPolicyData: TermsAndPolicyData
) => {
  // try {
  const incomingMessage = await appsPrivateApi.editTermsAndPolicyData(appId, termsAndPolicyData);

  return incomingMessage;
  // TODO map server validation errors
  // } catch (e) {
  //   if (e && e.response && e.response.body && 'Errors' in e.response.body) {
  //     const errRes: RestApiErrorResult = e.response.body;
  //     console.log(errRes)
  //     debugger
  //   }
  //   throw mapServerError(e);
  // }
};
