import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import SalesChannelGroupList from './components/SalesChannelGroupList';

type Props = MappedState;

const SalesChannelGroups = ({ currentBrandId, currentOrgId, translate }: Props) => {
  const history = useHistory();

  return (
    <PageLayout
      documentTitle="Sales_channel_groups"
      title={translate('Sales_channel_groups')}
      userPermissions={'Owner'}
      showAddIcon
      showActionButton
      actionBtnTitle={'Add_sales_channel_group'}
      onClick={() => {
        history.push(`/${currentBrandId}/settings/sales-channel-groups/add`);
      }}
    >
      <PaperContainer fluid>
        <SalesChannelGroupList
          ariaLabel={'Sales_channel_groups'}
          currentBrandId={currentBrandId}
          orgId={currentOrgId}
          translate={translate}
        />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentBrandId: state.currentApp.AppId,
    currentOrgId: state.rms.currentOrg?.orgId,
  };
};

export default connect(mapStateToProps)(SalesChannelGroups);
