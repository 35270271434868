import React, { useState } from 'react';

import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    paddingBottom: theme.spacing(2),
  },
  textInput: {
    paddingBottom: theme.spacing(2),
  },
}));

type EditLocationNameModalProps = {
  descriptionId: TranslationId;
  onClose: () => void;
  onConfirm: (newName: string) => void;
  name?: string;
  title: TranslationId;
};

const EditLocationNameModal = (props: EditLocationNameModalProps) => {
  const { descriptionId, onClose, onConfirm, name = '', title } = props;
  const [newName, setNewName] = useState<string>(name);
  const styles = useStyles();
  const isConfirmDisabled = newName?.length === 0 || name === newName;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  return (
    <Modal open={true} titleTextId={title}>
      <ModalContent>
        <Typography variant="subtitle1" className={styles.subTitle}>
          <Translate id={descriptionId} />
        </Typography>
        <TextField
          variant="standard"
          className={styles.textInput}
          fullWidth
          id="edit-name-input"
          InputLabelProps={{ shrink: true }}
          label={<Translate id="Name" />}
          value={newName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleNameChange(e);
          }}
        />
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonTranslationId="Cancel"
          leftButtonAction={onClose}
          rightButtonTranslationId="Confirm"
          rightButtonAction={() => onConfirm(newName)}
          rightDisabled={isConfirmDisabled}
          fdKey={'edit-modal-buttons'}
        />
      </ModalActions>
    </Modal>
  );
};

export default EditLocationNameModal;
