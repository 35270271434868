import * as React from 'react';

import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';

export type ButtonPropsInterface = {
  fdKey: string;
  variant?: string;
  component?: React.ElementType;
} & MuiButtonProps;

export function Button({ fdKey, variant, ...props }: ButtonPropsInterface) {
  return (
    <MuiButton
      {...(props as ButtonPropsInterface)}
      data-fd={fdKey}
      variant={variant as ButtonPropsInterface['variant']}
      role="button"
    >
      {props.children}
    </MuiButton>
  );
}

export default Button;
