import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';

export type SkeletonLoaderProps = {
  fdKey: string;
  rows: SkeletonLineProps[];
};

export type SkeletonLineProps = {
  height?: string;
  width?: string;
  className?: string;
};

const useStyles = makeStyles(() => ({
  loaderContainer: {
    flex: 1,
  },

  loaderLine: {
    margin: 2,
  },
}));

export const SkeletonLoader = ({ fdKey, rows }: SkeletonLoaderProps) => {
  const classes = useStyles();

  const renderSkeletonLine = (index: number, row: SkeletonLineProps) => {
    const { width = '100%', height = '100%', className = classes.loaderLine } = row;
    return (
      <div key={index} className={className}>
        <Skeleton width={width} height={height} />
      </div>
    );
  };
  return (
    <div className={classes.loaderContainer} data-fd={fdKey}>
      {rows.map((row, index) => {
        return renderSkeletonLine(index, row);
      })}
    </div>
  );
};
