import React, { useEffect, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { compose } from 'recompose';

import { appsActions } from '../actions/apps.actions';
import { ReactComponent as ForkIcon } from '../assets/images/app_logo_fork.svg';
import { usePrevious } from '../custom-hooks/usePrevious';
import generateSrcSet from '../ui/utils/generateSrcSet';
import AppMenu from './AppMenu';

type Props = MappedDispatch & MappedProps;

const AppSwitcher = (props: Props) => {
  const { search } = useLocation();
  const { setCurrentApp, currentApp } = props;
  const [open, setOpen] = useState(search.includes('appSearch'));
  const { pathname } = useLocation();

  const prevPathname = usePrevious(pathname);

  const [prevKey, setPrevKey] = useState('');
  const [keyPressed, setKeyPressed] = useState('');

  const keyPressHandler = React.useCallback((e: KeyboardEvent) => {
    const { key } = e;
    setKeyPressed(key);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  useEffect(() => {
    if (prevPathname) {
      const prevAppId = prevPathname.split('/')[1];
      const currentAppId = pathname.split('/')[1];

      if (prevAppId !== currentAppId) {
        setCurrentApp(currentAppId);
        setOpen(false);
      }
    }
  }, [pathname]);

  const toggleAppMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (keyPressed === 'Backspace' && prevKey === 'Control') {
      toggleAppMenu();
    }
    setPrevKey(keyPressed);
  }, [keyPressed]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const renderAppMenu = () => {
    return (
      <div>
        <IconButton
          onClick={toggleAppMenu}
          color="inherit"
          aria-label="Open App menu"
          data-fd="app-selector"
        >
          <div style={{ width: 40, height: 40 }}>
            {currentApp.LogoImageUrl ? (
              <img
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: '10%',
                }}
                srcSet={generateSrcSet(currentApp.LogoImageUrl, {
                  width: 40,
                  png: true,
                })}
                src={currentApp.LogoImageUrl}
              />
            ) : (
              <ForkIcon />
            )}
          </div>
        </IconButton>
        <AppMenu open={open} toggleAppMenu={toggleAppMenu} />
      </div>
    );
  };
  if (isMobile) {
    return renderAppMenu();
  }
  return <ClickAwayListener onClickAway={handleClickAway}>{renderAppMenu()}</ClickAwayListener>;
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = ({ currentApp }) => ({ currentApp });

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setCurrentApp: (appId: string) => dispatch(appsActions.setCurrentApp(appId)),
});

const EnhancedComponent = compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(
  AppSwitcher
);

export { EnhancedComponent as AppSwitcher };
