import { isEmpty } from 'lodash';

export const ITAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'IT') {
    return {
      street: '',
      number: '',
      floor: '',
      city: '',
      postalCode: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      street: storeAddress?.Line1 || '',
      number: '',
      floor: '',
      city: storeAddress?.City || '',
      postalCode: storeAddress?.Postcode || '',
    };
  }

  return storeAddress.AddressFields;
};
