import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import ErrorComponent from '@fd/ui/ErrorComponent';
import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps } from '../../../../layouts/Notify/actions';
import { useTracking } from '../../../../services/amplitude/useTracking';
import { billingService } from '../../billing.service';
import CheckoutSummary from '../components/CheckoutSummary';
import SubscriptionPicker from '../components/SubscriptionPicker';

const ExistingSubscriptionAuthenticatedCheckout = ({
  appId,
  languageCode,
  basket,
  dispatchNotifyError,
  permissions,
}: MappedState & MappedDispatch) => {
  const { items, addOns } = basket;
  const history = useHistory();
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(undefined);
  const { trackEvent, trackNavigationEvent } = useTracking();

  const {
    data: subscriptions,
    error,
    isError,
    isPending: isLoading,
    isFetched,
  } = useQuery({
    queryKey: [billingService.getSubscriptionsForAppQueryKey, appId, basket],
    queryFn: async () => {
      const allowAnyActiveSubscription =
        permissions.includes('Owner') || permissions.includes('ManagedOwner');
      return await billingService.getSubscriptionsForApp(
        appId!,
        !allowAnyActiveSubscription,
        undefined,
        false
      );
    },
  });

  useEffect(() => {
    if (isError && error instanceof Error) {
      dispatchNotifyError({ message: error.message, translate: false });
    }
  }, [isError, error]);

  useEffect(() => {
    if (!subscriptions || !isFetched) {
      return;
    }

    trackEvent('Loaded existing subscription checkout');
    const redirectToNewSubscriptionPage = subscriptions.length <= 0;
    if (redirectToNewSubscriptionPage) {
      trackNavigationEvent('Navigate to new subscription', { reason: 'No existing subscription' });
      history.replace(`/${appId}/billing/checkout/new-subscription`);
    }
  }, [subscriptions]);

  const handleSubscriptionSelected = (newId: string) => {
    trackEvent('Subscription selected');
    setSubscriptionId(newId);
  };

  const renderContent = () => {
    if (!items || items.length <= 0) {
      return <ErrorComponent title="Checkout_Invalid_Data" />;
    }

    if (isLoading || (subscriptions?.length ?? 0) <= 0) {
      return <SkeletonLoader fdKey="skeleton-card" rows={[{ height: '80px', width: '100%' }]} />;
    }

    if (!subscriptionId) {
      return (
        <SubscriptionPicker
          subscriptions={subscriptions}
          handleSubscriptionSelect={(newId) => handleSubscriptionSelected(newId)}
          appId={appId}
        />
      );
    }

    return (
      <CheckoutSummary
        appId={appId}
        priceId={items[0]}
        addOns={addOns}
        subscriptionId={subscriptionId}
        languageCode={languageCode}
      />
    );
  };

  return (
    <PageLayout
      title={
        <Typography variant="h4">
          <Translate id="Checkout_Summary" />
        </Typography>
      }
      documentTitle="Checkout"
      toParent={() => {
        history.goBack();
      }}
    >
      {renderContent()}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId!,
    languageCode: getActiveLanguage(state.locale),
    basket: state.checkout.basket,
    permissions: state.permissions,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingSubscriptionAuthenticatedCheckout);
