import React from 'react';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import OrderFulfillmentStatusConfigs from './components/OrderFulfillmentStatusConfigs';
import {
  EditFulfillmentConfiguration,
  EditFulfillmentConfigurationState,
} from './EditConfiguration';

type Props = RouteComponentProps;

const OrderFulfillmentStatusConfigsRoutes = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="order-fulfillment-status">
      <Switch>
        <Route exact path={`${path}`} component={OrderFulfillmentStatusConfigs} />
        <Route exact path={`${path}/:configId`} component={EditFulfillmentConfiguration} />
        <Route
          exact
          path={`${path}/:configId/:statusId`}
          component={EditFulfillmentConfigurationState}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(OrderFulfillmentStatusConfigsRoutes);
