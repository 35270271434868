import React from 'react';

import { App, BankAccountDetail } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import stripeLogoSvg from '../../../../assets/images/stripelogo.svg';
import { permissionsSelector } from '../../../../selectors/permissions.selector';
import PaperContainer from '../../../../ui/Layout/PaperContainer';
import GridDivider from './GridDivider';
import VerifyStripeButton from './VerifyStripeButton';

const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
  },
  title: {
    fontSize: '18px',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  bodyText: {
    marginTop: '16px',
    marginBottom: '24px',
    marginRight: '0',
    [theme.breakpoints.up('sm')]: {
      marginRight: '96px',
    },
  },
}));

type InnerProps = MappedState;
type OuterProps = {
  account: BankAccountDetail;
  showVerifyStripeDetails: boolean;
};

type Props = InnerProps & OuterProps;
const VerifyYourDetails = (props: Props) => {
  const { account, hasWritePermission, showVerifyStripeDetails } = props;
  const classes = useStyles();

  return showVerifyStripeDetails ? (
    <PaperContainer className={classes.paperWrapper}>
      <Grid container justifyContent="space-between">
        <Typography variant="h5" color="textPrimary" className={classes.title}>
          <Translate id="verification_required_to_receive_payouts" />
        </Typography>
        <Hidden smDown>
          <img src={stripeLogoSvg} />
        </Hidden>
      </Grid>
      <Box className={classes.bodyText}>
        <Typography variant="subtitle1" color="textSecondary">
          {hasWritePermission ? (
            <Translate id="We_use_stripe_a_global" />
          ) : (
            <Translate id="We_use_stripe_a_global_no_access" />
          )}
        </Typography>
      </Box>
      <Permissions
        allowed={[
          App.AppResourceSetEnum.CreateBankAccounts,
          App.AppResourceSetEnum.ViewBankAccounts,
        ]}
      >
        <Hidden smDown>
          <GridDivider />
        </Hidden>
        <Hidden smDown>
          <Grid container justifyContent="flex-end">
            <Box mt={4} mb={1.5}>
              <VerifyStripeButton
                stripeAccount={account.StripeConnectedAccountInfo!}
                bankAccountId={account.Id}
              />
            </Box>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <VerifyStripeButton
            stripeAccount={account.StripeConnectedAccountInfo!}
            bankAccountId={account.Id}
          />
        </Hidden>
      </Permissions>
    </PaperContainer>
  ) : null;
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['ViewBankAccounts']);
  return (state: AppState) => {
    return {
      translate: getTranslate(state.locale),
      hasWritePermission: getPermissionsSelector(state),
    };
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToPropsFactory))(VerifyYourDetails);
