import React, { useEffect, useState } from 'react';

import { useMenus } from '@fd/customHooks/useMenus';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Select from '@fd/ui/Select/Select';

import FormItemLayout from '../../components/FormItemLayout';

type Props = {
  appId: string;
};

type MenuOption = { label: string; value: number };

const MenuSelectField = ({ appId }: Props) => {
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);

  const { data: menus, isLoading: isGetMenusLoading } = useMenus(appId, !!appId);

  useEffect(() => {
    if (menus?.length) {
      const menuOptions: MenuOption[] = [];
      menus.forEach((menu) => {
        let label = `${menu.Name} ${menu.MenuId}`;
        if (!menu.Name) {
          label = `${menu.MenuId}`;
        }
        if (menu.MenuId) {
          menuOptions.push({ label, value: menu.MenuId });
        }
      });

      setMenuOptions(menuOptions);
    }
  }, [menus]);

  return (
    <Field name={'Menu'}>
      {({ field, form }: FieldProps) => {
        return (
          <FormItemLayout label={<Translate id="Menu" />}>
            <Select
              {...field}
              dataFd={'voucher-menu'}
              fullWidth
              isDisabled={form.isSubmitting}
              isLoading={isGetMenusLoading}
              onChange={(value) => form.setFieldValue('Menu', value)}
              options={menuOptions}
              placeholder=""
              TextFieldProps={{
                fdKey: 'menu-dropdown',
              }}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default MenuSelectField;
