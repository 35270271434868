import React, { ChangeEvent, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type ColorResult, type RGBColor, SketchPicker } from 'react-color';
import { Translate } from 'react-localize-redux';

import TextField from './TextField/TextField';
import { hexToRGBObject } from './utils/hexToRgb';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    position: 'relative',
    zIndex: 33,
  },
  pickerWrapper: {
    position: 'relative',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  picker: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  color: {
    flexBasis: `${spacing(3)}`,
    flexShrink: 0,
    height: `${spacing(3)}`,
    borderRadius: '50%',
    margin: `0 6px 6px 0`,
  },
}));

type Props = {
  value: string;
  loading?: boolean;
  fdKey?: string;
  error?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
  compatibleColour?: string;
};

const ColorPicker = ({
  value,
  onChange,
  loading,
  compatibleColour,
  fdKey,
  error,
  style,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [warn, setWarn] = useState<TranslationId>();
  const checkColorCompatibility = (value: string | RGBColor) => {
    if (compatibleColour) {
      const colour = hexToRGBObject(compatibleColour);
      const { r, g, b } = typeof value === 'string' ? hexToRGBObject(value) : value;
      const acceptableGap = 20;

      if (
        colour.r - Number(r) < acceptableGap &&
        colour.g - Number(g) < acceptableGap &&
        colour.b - Number(b) < acceptableGap
      ) {
        setWarn('Bad_color');
      } else if (warn) {
        setWarn(undefined);
      }
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        fdKey={fdKey || ''}
        helperText={warn && <Translate id={warn} />}
        value={value}
        style={style}
        InputProps={{
          inputProps: { style: { height: '1.1876em' }, 'data-fd': fdKey || '' },
          startAdornment: (
            <span className={classes.color} style={{ backgroundColor: error ? '' : value }} />
          ),
        }}
        loading={loading}
        onFocus={() => setOpen(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;

          checkColorCompatibility(value);
          onChange(value);
        }}
      />
      {open ? (
        <div className={classes.pickerWrapper}>
          <div className={classes.overlay} onClick={() => setOpen(false)} />
          <div className={classes.picker}>
            <SketchPicker
              color={value}
              disableAlpha={true}
              onChangeComplete={(value: ColorResult) => {
                checkColorCompatibility(value.rgb);
                onChange(value.hex);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
