export const getAssignedStoresText = (storeNames, translate) => {
  let storesText = '';
  if (storeNames) {
    storesText = storeNames.slice(0, 3).join(', ');
    if (storeNames.length > 3) {
      storesText += ` + ${storeNames.length - 3} ${translate('more')}`;
    }
  }
  return storesText || translate('No_store_associated');
};
