import React, { ReactElement } from 'react';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

import { FormattedTimeLine } from '../../helpers';

type StylesProps = { hover: boolean; lastItem?: boolean };
const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down(400)]: {
      height: 106,
    },
  },
  divider: ({ lastItem }: StylesProps) => {
    return {
      backgroundColor: '#cdd0ec',
      position: 'absolute',
      top: '22%',
      [theme.breakpoints.down(400)]: { top: '19%' },
      width: lastItem ? '100%' : '92%',
    };
  },
  iconContainer: ({ hover }: StylesProps) => {
    return {
      margin: '0 auto',
      backgroundColor: hover ? '#3743B1' : '#cdd0ec',
      color: '#fff',
    };
  },
  arrowContainer: {
    position: 'absolute',
    top: '13%',
    [theme.breakpoints.down(400)]: { top: '12%' },
    right: 0,
  },
  arrow: {
    fontSize: 16,
    color: '#cdd0ec',
  },
}));

type PropsSection = {
  icon: ReactElement;
  caption: TranslationId;
  date: string;
  lastItem?: boolean;
  loading: boolean;
  timeAgo: string;
};
export const RenderSection = (props: PropsSection) => {
  const { icon, caption, date, lastItem, loading, timeAgo } = props;

  const [hover, setHover] = React.useState<boolean>(false);
  const handleEnter = () => {
    setHover(true);
  };

  const handleLeave = () => {
    setHover(false);
  };
  const classes = useStyles({ hover, lastItem });
  return (
    <Grid
      item
      md={4}
      xs={4}
      className={classes.gridItem}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Divider className={classes.divider} />
      {!lastItem && (
        <div className={classes.arrowContainer}>
          <ChevronRightIcon className={classes.arrow} />
        </div>
      )}
      <Avatar className={classes.iconContainer} data-fd={`${caption}-icon`}>
        {icon}
      </Avatar>
      <Typography variant="caption">
        <Translate id={caption} />
      </Typography>
      <Typography variant="subtitle2">
        {loading ? <Skeleton width={100} height={20} /> : hover ? date : timeAgo}
      </Typography>
    </Grid>
  );
};

type InnerProps = {};
type OuterProps = {
  customerTimeLine: FormattedTimeLine;
  loading: boolean;
};
type Props = InnerProps & OuterProps;
const TimeLine = (props: Props) => {
  const {
    customerTimeLine: { registrationDate, firstOrder, lastOrder },
    loading,
  } = props;
  const theme = useTheme();
  const isMobileOrTablet = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Paper style={{ marginBottom: isMobileOrTablet ? 0 : 0 }}>
      <Grid container style={{ padding: '40px 0 28px 0' }}>
        <RenderSection
          icon={<PersonOutlineIcon />}
          caption={'Registered'}
          date={registrationDate.date}
          loading={loading}
          timeAgo={registrationDate.timeAgo}
        />
        <RenderSection
          icon={<ShoppingCartIcon />}
          caption={'First_order'}
          date={firstOrder.date}
          loading={loading}
          timeAgo={firstOrder.timeAgo}
        />
        <RenderSection
          icon={<AddShoppingCartIcon />}
          caption={'Most_recent_order'}
          date={lastOrder.date}
          lastItem
          loading={loading}
          timeAgo={lastOrder.timeAgo}
        />
      </Grid>
    </Paper>
  );
};

export default TimeLine;
