export const timeZoneDefinitions: Array<Required<Flipdish.LocalisedTimeZone>> = [
  {
    TimeZoneId: 'Dateline Standard Time',
    DisplayName: '(UTC-12:00) International Date Line West',
    IanaTimeZoneId: 'Etc/GMT+12',
  },
  {
    TimeZoneId: 'UTC-11',
    DisplayName: '(UTC-11:00) Coordinated Universal Time-11',
    IanaTimeZoneId: 'Etc/GMT+11',
  },
  {
    TimeZoneId: 'Aleutian Standard Time',
    DisplayName: '(UTC-10:00) Aleutian Islands',
    IanaTimeZoneId: 'America/Adak',
  },
  {
    TimeZoneId: 'Hawaiian Standard Time',
    DisplayName: '(UTC-10:00) Hawaii',
    IanaTimeZoneId: 'Pacific/Honolulu',
  },
  {
    TimeZoneId: 'Marquesas Standard Time',
    DisplayName: '(UTC-09:30) Marquesas Islands',
    IanaTimeZoneId: 'Pacific/Marquesas',
  },
  {
    TimeZoneId: 'Alaskan Standard Time',
    DisplayName: '(UTC-09:00) Alaska',
    IanaTimeZoneId: 'America/Anchorage',
  },
  {
    TimeZoneId: 'UTC-09',
    DisplayName: '(UTC-09:00) Coordinated Universal Time-09',
    IanaTimeZoneId: 'Etc/GMT+9',
  },
  {
    TimeZoneId: 'Pacific Standard Time (Mexico)',
    DisplayName: '(UTC-08:00) Baja California',
    IanaTimeZoneId: 'America/Tijuana',
  },
  {
    TimeZoneId: 'UTC-08',
    DisplayName: '(UTC-08:00) Coordinated Universal Time-08',
    IanaTimeZoneId: 'Etc/GMT+8',
  },
  {
    TimeZoneId: 'Pacific Standard Time',
    DisplayName: '(UTC-08:00) Pacific Time (US & Canada)',
    IanaTimeZoneId: 'America/Los_Angeles',
  },
  {
    TimeZoneId: 'US Mountain Standard Time',
    DisplayName: '(UTC-07:00) Arizona',
    IanaTimeZoneId: 'America/Phoenix',
  },
  {
    TimeZoneId: 'Mountain Standard Time (Mexico)',
    DisplayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    IanaTimeZoneId: 'America/Chihuahua',
  },
  {
    TimeZoneId: 'Mountain Standard Time',
    DisplayName: '(UTC-07:00) Mountain Time (US & Canada)',
    IanaTimeZoneId: 'America/Denver',
  },
  {
    TimeZoneId: 'Central America Standard Time',
    DisplayName: '(UTC-06:00) Central America',
    IanaTimeZoneId: 'America/Guatemala',
  },
  {
    TimeZoneId: 'Central Standard Time',
    DisplayName: '(UTC-06:00) Central Time (US & Canada)',
    IanaTimeZoneId: 'America/Chicago',
  },
  {
    TimeZoneId: 'Easter Island Standard Time',
    DisplayName: '(UTC-06:00) Easter Island',
    IanaTimeZoneId: 'Pacific/Easter',
  },
  {
    TimeZoneId: 'Central Standard Time (Mexico)',
    DisplayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    IanaTimeZoneId: 'America/Mexico_City',
  },
  {
    TimeZoneId: 'Canada Central Standard Time',
    DisplayName: '(UTC-06:00) Saskatchewan',
    IanaTimeZoneId: 'America/Regina',
  },
  {
    TimeZoneId: 'SA Pacific Standard Time',
    DisplayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    IanaTimeZoneId: 'America/Bogota',
  },
  {
    TimeZoneId: 'Eastern Standard Time (Mexico)',
    DisplayName: '(UTC-05:00) Chetumal',
    IanaTimeZoneId: 'America/Cancun',
  },
  {
    TimeZoneId: 'Eastern Standard Time',
    DisplayName: '(UTC-05:00) Eastern Time (US & Canada)',
    IanaTimeZoneId: 'America/New_York',
  },
  {
    TimeZoneId: 'Haiti Standard Time',
    DisplayName: '(UTC-05:00) Haiti',
    IanaTimeZoneId: 'America/Port-au-Prince',
  },
  {
    TimeZoneId: 'Cuba Standard Time',
    DisplayName: '(UTC-05:00) Havana',
    IanaTimeZoneId: 'America/Havana',
  },
  {
    TimeZoneId: 'US Eastern Standard Time',
    DisplayName: '(UTC-05:00) Indiana (East)',
    IanaTimeZoneId: 'America/Indiana/Indianapolis',
  },
  {
    TimeZoneId: 'Turks And Caicos Standard Time',
    DisplayName: '(UTC-05:00) Turks and Caicos',
    IanaTimeZoneId: 'America/Grand_Turk',
  },
  {
    TimeZoneId: 'Paraguay Standard Time',
    DisplayName: '(UTC-04:00) Asuncion',
    IanaTimeZoneId: 'America/Asuncion',
  },
  {
    TimeZoneId: 'Atlantic Standard Time',
    DisplayName: '(UTC-04:00) Atlantic Time (Canada)',
    IanaTimeZoneId: 'America/Halifax',
  },
  {
    TimeZoneId: 'Venezuela Standard Time',
    DisplayName: '(UTC-04:00) Caracas',
    IanaTimeZoneId: 'America/Caracas',
  },
  {
    TimeZoneId: 'Central Brazilian Standard Time',
    DisplayName: '(UTC-04:00) Cuiaba',
    IanaTimeZoneId: 'America/Cuiaba',
  },
  {
    TimeZoneId: 'SA Western Standard Time',
    DisplayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    IanaTimeZoneId: 'America/La_Paz',
  },
  {
    TimeZoneId: 'Pacific SA Standard Time',
    DisplayName: '(UTC-04:00) Santiago',
    IanaTimeZoneId: 'America/Santiago',
  },
  {
    TimeZoneId: 'Newfoundland Standard Time',
    DisplayName: '(UTC-03:30) Newfoundland',
    IanaTimeZoneId: 'America/St_Johns',
  },
  {
    TimeZoneId: 'Tocantins Standard Time',
    DisplayName: '(UTC-03:00) Araguaina',
    IanaTimeZoneId: 'America/Araguaina',
  },
  {
    TimeZoneId: 'E. South America Standard Time',
    DisplayName: '(UTC-03:00) Brasilia',
    IanaTimeZoneId: 'America/Sao_Paulo',
  },
  {
    TimeZoneId: 'SA Eastern Standard Time',
    DisplayName: '(UTC-03:00) Cayenne, Fortaleza',
    IanaTimeZoneId: 'America/Cayenne',
  },
  {
    TimeZoneId: 'Argentina Standard Time',
    DisplayName: '(UTC-03:00) City of Buenos Aires',
    IanaTimeZoneId: 'America/Argentina/Buenos_Aires',
  },
  {
    TimeZoneId: 'Greenland Standard Time',
    DisplayName: '(UTC-03:00) Greenland',
    IanaTimeZoneId: 'America/Godthab',
  },
  {
    TimeZoneId: 'Montevideo Standard Time',
    DisplayName: '(UTC-03:00) Montevideo',
    IanaTimeZoneId: 'America/Montevideo',
  },
  {
    TimeZoneId: 'Magallanes Standard Time',
    DisplayName: '(UTC-03:00) Punta Arenas',
    IanaTimeZoneId: 'America/Punta_Arenas',
  },
  {
    TimeZoneId: 'Saint Pierre Standard Time',
    DisplayName: '(UTC-03:00) Saint Pierre and Miquelon',
    IanaTimeZoneId: 'America/Miquelon',
  },
  {
    TimeZoneId: 'Bahia Standard Time',
    DisplayName: '(UTC-03:00) Salvador',
    IanaTimeZoneId: 'America/Bahia',
  },
  {
    TimeZoneId: 'UTC-02',
    DisplayName: '(UTC-02:00) Coordinated Universal Time-02',
    IanaTimeZoneId: 'Etc/GMT+2',
  },
  {
    TimeZoneId: 'Mid-Atlantic Standard Time',
    DisplayName: '(UTC-02:00) Mid-Atlantic - Old',
    IanaTimeZoneId: 'Etc/GMT+2',
  },
  {
    TimeZoneId: 'Azores Standard Time',
    DisplayName: '(UTC-01:00) Azores',
    IanaTimeZoneId: 'Atlantic/Azores',
  },
  {
    TimeZoneId: 'Cape Verde Standard Time',
    DisplayName: '(UTC-01:00) Cabo Verde Is.',
    IanaTimeZoneId: 'Atlantic/Cape_Verde',
  },
  {
    TimeZoneId: 'UTC',
    DisplayName: '(UTC) Coordinated Universal Time',
    IanaTimeZoneId: 'Etc/UTC',
  },
  {
    TimeZoneId: 'GMT Standard Time',
    DisplayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    IanaTimeZoneId: 'Europe/London',
  },
  {
    TimeZoneId: 'Greenwich Standard Time',
    DisplayName: '(UTC+00:00) Monrovia, Reykjavik',
    IanaTimeZoneId: 'Atlantic/Reykjavik',
  },
  {
    TimeZoneId: 'W. Europe Standard Time',
    DisplayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    IanaTimeZoneId: 'Europe/Berlin',
  },
  {
    TimeZoneId: 'Central Europe Standard Time',
    DisplayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    IanaTimeZoneId: 'Europe/Budapest',
  },
  {
    TimeZoneId: 'Romance Standard Time',
    DisplayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    IanaTimeZoneId: 'Europe/Paris',
  },
  {
    TimeZoneId: 'Morocco Standard Time',
    DisplayName: '(UTC+01:00) Casablanca',
    IanaTimeZoneId: 'Africa/Casablanca',
  },
  {
    TimeZoneId: 'Central European Standard Time',
    DisplayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    IanaTimeZoneId: 'Europe/Warsaw',
  },
  {
    TimeZoneId: 'W. Central Africa Standard Time',
    DisplayName: '(UTC+01:00) West Central Africa',
    IanaTimeZoneId: 'Africa/Lagos',
  },
  {
    TimeZoneId: 'Jordan Standard Time',
    DisplayName: '(UTC+02:00) Amman',
    IanaTimeZoneId: 'Asia/Amman',
  },
  {
    TimeZoneId: 'GTB Standard Time',
    DisplayName: '(UTC+02:00) Athens, Bucharest',
    IanaTimeZoneId: 'Europe/Bucharest',
  },
  {
    TimeZoneId: 'Middle East Standard Time',
    DisplayName: '(UTC+02:00) Beirut',
    IanaTimeZoneId: 'Asia/Beirut',
  },
  {
    TimeZoneId: 'Egypt Standard Time',
    DisplayName: '(UTC+02:00) Cairo',
    IanaTimeZoneId: 'Africa/Cairo',
  },
  {
    TimeZoneId: 'E. Europe Standard Time',
    DisplayName: '(UTC+02:00) Chisinau',
    IanaTimeZoneId: 'Europe/Chisinau',
  },
  {
    TimeZoneId: 'Syria Standard Time',
    DisplayName: '(UTC+02:00) Damascus',
    IanaTimeZoneId: 'Asia/Damascus',
  },
  {
    TimeZoneId: 'West Bank Standard Time',
    DisplayName: '(UTC+02:00) Gaza, Hebron',
    IanaTimeZoneId: 'Asia/Hebron',
  },
  {
    TimeZoneId: 'South Africa Standard Time',
    DisplayName: '(UTC+02:00) Harare, Pretoria',
    IanaTimeZoneId: 'Africa/Johannesburg',
  },
  {
    TimeZoneId: 'FLE Standard Time',
    DisplayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    IanaTimeZoneId: 'Europe/Kiev',
  },
  {
    TimeZoneId: 'Israel Standard Time',
    DisplayName: '(UTC+02:00) Jerusalem',
    IanaTimeZoneId: 'Asia/Jerusalem',
  },
  {
    TimeZoneId: 'Kaliningrad Standard Time',
    DisplayName: '(UTC+02:00) Kaliningrad',
    IanaTimeZoneId: 'Europe/Kaliningrad',
  },
  {
    TimeZoneId: 'Libya Standard Time',
    DisplayName: '(UTC+02:00) Tripoli',
    IanaTimeZoneId: 'Africa/Tripoli',
  },
  {
    TimeZoneId: 'Namibia Standard Time',
    DisplayName: '(UTC+02:00) Windhoek',
    IanaTimeZoneId: 'Africa/Windhoek',
  },
  {
    TimeZoneId: 'Arabic Standard Time',
    DisplayName: '(UTC+03:00) Baghdad',
    IanaTimeZoneId: 'Asia/Baghdad',
  },
  {
    TimeZoneId: 'Turkey Standard Time',
    DisplayName: '(UTC+03:00) Istanbul',
    IanaTimeZoneId: 'Europe/Istanbul',
  },
  {
    TimeZoneId: 'Arab Standard Time',
    DisplayName: '(UTC+03:00) Kuwait, Riyadh',
    IanaTimeZoneId: 'Asia/Riyadh',
  },
  {
    TimeZoneId: 'Belarus Standard Time',
    DisplayName: '(UTC+03:00) Minsk',
    IanaTimeZoneId: 'Europe/Minsk',
  },
  {
    TimeZoneId: 'Russian Standard Time',
    DisplayName: '(UTC+03:00) Moscow, St. Petersburg',
    IanaTimeZoneId: 'Europe/Moscow',
  },
  {
    TimeZoneId: 'E. Africa Standard Time',
    DisplayName: '(UTC+03:00) Nairobi',
    IanaTimeZoneId: 'Africa/Nairobi',
  },
  {
    TimeZoneId: 'Iran Standard Time',
    DisplayName: '(UTC+03:30) Tehran',
    IanaTimeZoneId: 'Asia/Tehran',
  },
  {
    TimeZoneId: 'Arabian Standard Time',
    DisplayName: '(UTC+04:00) Abu Dhabi, Muscat',
    IanaTimeZoneId: 'Asia/Dubai',
  },
  {
    TimeZoneId: 'Astrakhan Standard Time',
    DisplayName: '(UTC+04:00) Astrakhan, Ulyanovsk',
    IanaTimeZoneId: 'Europe/Astrakhan',
  },
  {
    TimeZoneId: 'Azerbaijan Standard Time',
    DisplayName: '(UTC+04:00) Baku',
    IanaTimeZoneId: 'Asia/Baku',
  },
  {
    TimeZoneId: 'Russia Time Zone 3',
    DisplayName: '(UTC+04:00) Izhevsk, Samara',
    IanaTimeZoneId: 'Europe/Samara',
  },
  {
    TimeZoneId: 'Mauritius Standard Time',
    DisplayName: '(UTC+04:00) Port Louis',
    IanaTimeZoneId: 'Indian/Mauritius',
  },
  {
    TimeZoneId: 'Saratov Standard Time',
    DisplayName: '(UTC+04:00) Saratov',
    IanaTimeZoneId: 'Europe/Saratov',
  },
  {
    TimeZoneId: 'Georgian Standard Time',
    DisplayName: '(UTC+04:00) Tbilisi',
    IanaTimeZoneId: 'Asia/Tbilisi',
  },
  {
    TimeZoneId: 'Caucasus Standard Time',
    DisplayName: '(UTC+04:00) Yerevan',
    IanaTimeZoneId: 'Asia/Yerevan',
  },
  {
    TimeZoneId: 'Afghanistan Standard Time',
    DisplayName: '(UTC+04:30) Kabul',
    IanaTimeZoneId: 'Asia/Kabul',
  },
  {
    TimeZoneId: 'West Asia Standard Time',
    DisplayName: '(UTC+05:00) Ashgabat, Tashkent',
    IanaTimeZoneId: 'Asia/Tashkent',
  },
  {
    TimeZoneId: 'Ekaterinburg Standard Time',
    DisplayName: '(UTC+05:00) Ekaterinburg',
    IanaTimeZoneId: 'Asia/Yekaterinburg',
  },
  {
    TimeZoneId: 'Pakistan Standard Time',
    DisplayName: '(UTC+05:00) Islamabad, Karachi',
    IanaTimeZoneId: 'Asia/Karachi',
  },
  {
    TimeZoneId: 'India Standard Time',
    DisplayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    IanaTimeZoneId: 'Asia/Kolkata',
  },
  {
    TimeZoneId: 'Sri Lanka Standard Time',
    DisplayName: '(UTC+05:30) Sri Jayawardenepura',
    IanaTimeZoneId: 'Asia/Colombo',
  },
  {
    TimeZoneId: 'Nepal Standard Time',
    DisplayName: '(UTC+05:45) Kathmandu',
    IanaTimeZoneId: 'Asia/Kathmandu',
  },
  {
    TimeZoneId: 'Central Asia Standard Time',
    DisplayName: '(UTC+06:00) Astana',
    IanaTimeZoneId: 'Asia/Almaty',
  },
  {
    TimeZoneId: 'Bangladesh Standard Time',
    DisplayName: '(UTC+06:00) Dhaka',
    IanaTimeZoneId: 'Asia/Dhaka',
  },
  {
    TimeZoneId: 'Omsk Standard Time',
    DisplayName: '(UTC+06:00) Omsk',
    IanaTimeZoneId: 'Asia/Omsk',
  },
  {
    TimeZoneId: 'Myanmar Standard Time',
    DisplayName: '(UTC+06:30) Yangon (Rangoon)',
    IanaTimeZoneId: 'Asia/Yangon',
  },
  {
    TimeZoneId: 'SE Asia Standard Time',
    DisplayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    IanaTimeZoneId: 'Asia/Bangkok',
  },
  {
    TimeZoneId: 'Altai Standard Time',
    DisplayName: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    IanaTimeZoneId: 'Asia/Barnaul',
  },
  {
    TimeZoneId: 'W. Mongolia Standard Time',
    DisplayName: '(UTC+07:00) Hovd',
    IanaTimeZoneId: 'Asia/Hovd',
  },
  {
    TimeZoneId: 'North Asia Standard Time',
    DisplayName: '(UTC+07:00) Krasnoyarsk',
    IanaTimeZoneId: 'Asia/Krasnoyarsk',
  },
  {
    TimeZoneId: 'N. Central Asia Standard Time',
    DisplayName: '(UTC+07:00) Novosibirsk',
    IanaTimeZoneId: 'Asia/Novosibirsk',
  },
  {
    TimeZoneId: 'Tomsk Standard Time',
    DisplayName: '(UTC+07:00) Tomsk',
    IanaTimeZoneId: 'Asia/Tomsk',
  },
  {
    TimeZoneId: 'China Standard Time',
    DisplayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    IanaTimeZoneId: 'Asia/Shanghai',
  },
  {
    TimeZoneId: 'North Asia East Standard Time',
    DisplayName: '(UTC+08:00) Irkutsk',
    IanaTimeZoneId: 'Asia/Irkutsk',
  },
  {
    TimeZoneId: 'Singapore Standard Time',
    DisplayName: '(UTC+08:00) Kuala Lumpur, Singapore',
    IanaTimeZoneId: 'Asia/Singapore',
  },
  {
    TimeZoneId: 'W. Australia Standard Time',
    DisplayName: '(UTC+08:00) Perth',
    IanaTimeZoneId: 'Australia/Perth',
  },
  {
    TimeZoneId: 'Taipei Standard Time',
    DisplayName: '(UTC+08:00) Taipei',
    IanaTimeZoneId: 'Asia/Taipei',
  },
  {
    TimeZoneId: 'Ulaanbaatar Standard Time',
    DisplayName: '(UTC+08:00) Ulaanbaatar',
    IanaTimeZoneId: 'Asia/Ulaanbaatar',
  },
  {
    TimeZoneId: 'Aus Central W. Standard Time',
    DisplayName: '(UTC+08:45) Eucla',
    IanaTimeZoneId: 'Australia/Eucla',
  },
  {
    TimeZoneId: 'Transbaikal Standard Time',
    DisplayName: '(UTC+09:00) Chita',
    IanaTimeZoneId: 'Asia/Chita',
  },
  {
    TimeZoneId: 'Tokyo Standard Time',
    DisplayName: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    IanaTimeZoneId: 'Asia/Tokyo',
  },
  {
    TimeZoneId: 'North Korea Standard Time',
    DisplayName: '(UTC+09:00) Pyongyang',
    IanaTimeZoneId: 'Asia/Pyongyang',
  },
  {
    TimeZoneId: 'Korea Standard Time',
    DisplayName: '(UTC+09:00) Seoul',
    IanaTimeZoneId: 'Asia/Seoul',
  },
  {
    TimeZoneId: 'Yakutsk Standard Time',
    DisplayName: '(UTC+09:00) Yakutsk',
    IanaTimeZoneId: 'Asia/Yakutsk',
  },
  {
    TimeZoneId: 'Cen. Australia Standard Time',
    DisplayName: '(UTC+09:30) Adelaide',
    IanaTimeZoneId: 'Australia/Adelaide',
  },
  {
    TimeZoneId: 'AUS Central Standard Time',
    DisplayName: '(UTC+09:30) Darwin',
    IanaTimeZoneId: 'Australia/Darwin',
  },
  {
    TimeZoneId: 'E. Australia Standard Time',
    DisplayName: '(UTC+10:00) Brisbane',
    IanaTimeZoneId: 'Australia/Brisbane',
  },
  {
    TimeZoneId: 'AUS Eastern Standard Time',
    DisplayName: '(UTC+10:00) Canberra, Melbourne, Sydney',
    IanaTimeZoneId: 'Australia/Sydney',
  },
  {
    TimeZoneId: 'West Pacific Standard Time',
    DisplayName: '(UTC+10:00) Guam, Port Moresby',
    IanaTimeZoneId: 'Pacific/Port_Moresby',
  },
  {
    TimeZoneId: 'Tasmania Standard Time',
    DisplayName: '(UTC+10:00) Hobart',
    IanaTimeZoneId: 'Australia/Hobart',
  },
  {
    TimeZoneId: 'Vladivostok Standard Time',
    DisplayName: '(UTC+10:00) Vladivostok',
    IanaTimeZoneId: 'Asia/Vladivostok',
  },
  {
    TimeZoneId: 'Lord Howe Standard Time',
    DisplayName: '(UTC+10:30) Lord Howe Island',
    IanaTimeZoneId: 'Australia/Lord_Howe',
  },
  {
    TimeZoneId: 'Bougainville Standard Time',
    DisplayName: '(UTC+11:00) Bougainville Island',
    IanaTimeZoneId: 'Pacific/Bougainville',
  },
  {
    TimeZoneId: 'Russia Time Zone 10',
    DisplayName: '(UTC+11:00) Chokurdakh',
    IanaTimeZoneId: 'Asia/Srednekolymsk',
  },
  {
    TimeZoneId: 'Magadan Standard Time',
    DisplayName: '(UTC+11:00) Magadan',
    IanaTimeZoneId: 'Asia/Magadan',
  },
  {
    TimeZoneId: 'Norfolk Standard Time',
    DisplayName: '(UTC+11:00) Norfolk Island',
    IanaTimeZoneId: 'Pacific/Norfolk',
  },
  {
    TimeZoneId: 'Sakhalin Standard Time',
    DisplayName: '(UTC+11:00) Sakhalin',
    IanaTimeZoneId: 'Asia/Sakhalin',
  },
  {
    TimeZoneId: 'Central Pacific Standard Time',
    DisplayName: '(UTC+11:00) Solomon Is., New Caledonia',
    IanaTimeZoneId: 'Pacific/Guadalcanal',
  },
  {
    TimeZoneId: 'Russia Time Zone 11',
    DisplayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    IanaTimeZoneId: 'Asia/Kamchatka',
  },
  {
    TimeZoneId: 'New Zealand Standard Time',
    DisplayName: '(UTC+12:00) Auckland, Wellington',
    IanaTimeZoneId: 'Pacific/Auckland',
  },
  {
    TimeZoneId: 'UTC+12',
    DisplayName: '(UTC+12:00) Coordinated Universal Time+12',
    IanaTimeZoneId: 'Etc/GMT-12',
  },
  {
    TimeZoneId: 'Fiji Standard Time',
    DisplayName: '(UTC+12:00) Fiji',
    IanaTimeZoneId: 'Pacific/Fiji',
  },
  {
    TimeZoneId: 'Kamchatka Standard Time',
    DisplayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    IanaTimeZoneId: 'Asia/Kamchatka',
  },
  {
    TimeZoneId: 'Chatham Islands Standard Time',
    DisplayName: '(UTC+12:45) Chatham Islands',
    IanaTimeZoneId: 'Pacific/Chatham',
  },
  {
    TimeZoneId: 'UTC+13',
    DisplayName: '(UTC+13:00) Coordinated Universal Time+13',
    IanaTimeZoneId: 'Etc/GMT-13',
  },
  {
    TimeZoneId: 'Tonga Standard Time',
    DisplayName: "(UTC+13:00) Nuku'alofa",
    IanaTimeZoneId: 'Pacific/Tongatapu',
  },
  {
    TimeZoneId: 'Samoa Standard Time',
    DisplayName: '(UTC+13:00) Samoa',
    IanaTimeZoneId: 'Pacific/Apia',
  },
  {
    TimeZoneId: 'Line Islands Standard Time',
    DisplayName: '(UTC+14:00) Kiritimati Island',
    IanaTimeZoneId: 'Pacific/Kiritimati',
  },
];

const timeZoneDefinitionByTimeZoneId = timeZoneDefinitions.reduce<{
  [key: string]: Required<Flipdish.LocalisedTimeZone>;
}>((agg, def) => {
  agg[def.TimeZoneId] = def;
  return agg;
}, {});

export const getIanaTimeZoneId = (timeZoneId: string) => {
  const def = timeZoneDefinitionByTimeZoneId[timeZoneId];
  if (def) {
    return def.IanaTimeZoneId;
  }
  return undefined;
};

export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset() / 60;

  return offset < 0 ? `+${Math.abs(offset)}` : `-${offset}`;
};
