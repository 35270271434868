import { Options } from './localeReducer';

export const ACTION_PREFIX = '@@localize/';
export const INITIALIZE = `${ACTION_PREFIX}INITIALIZE`;
export const SET_ACTIVE_LANGUAGE = `${ACTION_PREFIX}SET_ACTIVE_LANGUAGE`;
export const ADD_TRANSLATION_FOR_LANGUAGE = `${ACTION_PREFIX}ADD_TRANSLATION_FOR_LANGUAGE`;

export type InitializePayload = { languages: string[]; options?: Options };
export type AddTranslationPayload = { translation: { [key: string]: string }; language: string };

export const initialize = (payload: InitializePayload) => ({
  type: INITIALIZE,
  payload,
});

export const addTranslationForLanguage = (payload: AddTranslationPayload) => ({
  type: ADD_TRANSLATION_FOR_LANGUAGE,
  payload,
});

export const setActiveLanguage = (payload: string) => ({
  type: SET_ACTIVE_LANGUAGE,
  payload,
});
