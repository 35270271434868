import React, { useState } from 'react';

import { StoreValidationConfig } from '@flipdish/api-client-typescript/private/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionActions';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import GenericTablePagination from '@fd/ui/GenericTable/GenericTablePagination';

import InvalidStoreAccordion from './InvalidStoreAccordion';

const ExpansionPanelHeader = withStyles(
  () => ({
    root: {
      backgroundColor: 'rgba(255, 242, 213, 1)',
    },
  }),
  {
    name: 'FdExpansionPanelSummary',
  }
)(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(
  ({ spacing }: Theme) => ({
    root: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  }),
  { name: 'FdExpansionPanelDetails' }
)(MuiExpansionPanelDetails);
//#endregion

type Props = {
  data: Array<StoreValidationConfig>;
  page?: number;
  rowsPerPage: number;
  setPage: (n: number) => void;
  setRowsPerPage: (n: number) => void;
  totalRecords?: number;
};

const InvalidStoresActions: React.FC<React.PropsWithChildren<Props>> = ({
  data,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalRecords,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Grid style={{ marginTop: 38 }}>
      <ExpansionPanel
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        expanded={isExpanded}
        onChange={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <ExpansionPanelHeader
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
        >
          <ReportProblemOutlinedIcon style={{ color: 'rgba(255, 188, 44, 1)', marginRight: 17 }} />
          <Typography variant="body1">
            <Translate id="Kiosk_action_required" />
          </Typography>
        </ExpansionPanelHeader>

        <ExpansionPanelDetails>
          <Grid container>
            <Grid item>
              <Typography variant="caption">
                <Translate id="Kiosk_the_following_stores_are_not_correctly_configured" />
              </Typography>
            </Grid>
            {data.map((store, idx) => (
              <InvalidStoreAccordion data={store} key={idx} page={page} />
            ))}
          </Grid>
        </ExpansionPanelDetails>
        <GenericTablePagination
          page={page}
          pageLocalStorageKey="kioskStoreValidation_page"
          rowsPerPage={rowsPerPage}
          rowsPerPageLocalStorageKey="kioskStoreValidation_rows"
          rowsPerPageOptions={[5, 10, 15, 20, 50]}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          total={totalRecords}
        />
      </ExpansionPanel>
    </Grid>
  );
};

export default InvalidStoresActions;
