import React from 'react';

import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import { useInView } from 'react-intersection-observer';

import { CountProps, DataProps } from '../types';
import BarChart from './Graphs/BarChart';
import ReportChartSkeleton from './Graphs/ReportChartSkeleton';

type Props = {
  currency?: string;
  currentPeriod: DataProps[];
  dateString: string;
  languageCode: string;
  loading?: boolean;
  orderReportDetail?: { [key: string]: CountProps };
  previousPeriod: DataProps[];
  translate: Function;
};
const OrderCharts = (props: Props) => {
  const { currency, loading, orderReportDetail, translate, languageCode, dateString } = props;
  const [ref, inView] = useInView();

  if (loading || !orderReportDetail || isEmpty(orderReportDetail)) {
    return <ReportChartSkeleton loaded={loading === false} noCard />;
  }

  const formatTickValue = (tick: number) => {
    if (tick) {
      return tick.toLocaleString(languageCode, {
        style: 'decimal',
      });
    }
    return '';
  };

  const sections = Object.keys(orderReportDetail!);

  return (
    <div ref={ref}>
      <Grid container spacing={0}>
        {sections.map((section, index) => {
          const { current, previous } = orderReportDetail[section];
          if (!current?.find((c) => c.count !== 0) && !previous?.find((c) => c.count !== 0)) {
            return (
              <ReportChartSkeleton
                key={index}
                loaded={!loading}
                title={section as TranslationId}
                noCard
              />
            );
          }
          return (
            <Grid key={index} item xs={12}>
              <BarChart
                currency={currency}
                inView={inView}
                CurrentPeriod={current}
                PreviousPeriod={previous}
                title={translate(section)}
                formatTickValue={formatTickValue}
                dateString={dateString}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default OrderCharts;
