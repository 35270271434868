import React, { useEffect, useState } from 'react';

import { Group, Product, ProductReference } from '@flipdish/api-client-typescript';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Drawer } from '@fd/ui/atoms/Drawer';
import { Typography } from '@fd/ui/atoms/Typography';

import { DrawerSection, GroupFormValues } from '../../types';
import { AddModifierGroup } from '../AddModifierGroup';
import { CreateModifier } from '../CreateModifier';
import { CreateModifierGroupDrawerSection } from '../CreateModifierGroupDrawerSection';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export type AddModifierGroupDrawerProps = {
  AppId: string;
  currentModifierGroups: Group[];
  translate: TranslateFunction;
  open: boolean;
  onAddModifierGroup: (modifierGroup: Group) => void;
  onClose: () => void;
};

export const AddModifierGroupDrawer = (props: AddModifierGroupDrawerProps): JSX.Element => {
  const { AppId, currentModifierGroups, onAddModifierGroup, onClose, open, translate } = props;
  const [drawerSection, setDrawerSection] = useState<DrawerSection>('add_modifier_group');
  const [newModifierGroupData, setNewModifierGroupData] = useState<GroupFormValues | undefined>(
    undefined
  );

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setDrawerSection('add_modifier_group');
      setNewModifierGroupData(undefined);
    }
  }, [open]);

  const getTitle = () => {
    if (drawerSection === 'add_modifier_group') {
      return 'Add_modifier_group';
    }
    if (drawerSection === 'create_modifier_group') {
      return 'ModifierGroup_NewModifierGroup';
    }
    return 'New_modifier';
  };

  const handleGroupCreated = (newGroup) => {
    onAddModifierGroup(newGroup);
    setNewModifierGroupData(undefined);
    setDrawerSection('add_modifier_group');
  };

  const handleCancelCreate = () => {
    setNewModifierGroupData(undefined);
    setDrawerSection('add_modifier_group');
  };

  const handleCreateModifierClick = (newGroupFormValues: GroupFormValues) => {
    setNewModifierGroupData(newGroupFormValues);
    setDrawerSection('create_modifier');
  };

  const handleModifierCreated = (newModifier: Product) => {
    const newItemRef = {
      CatalogItemId: newModifier.CatalogItemId,
      ProductType: newModifier.ProductType,
      Product: newModifier,
    } as ProductReference;
    const groupValues = newModifierGroupData;
    groupValues?.Products.push(newItemRef);
    setNewModifierGroupData(groupValues);
    setDrawerSection('create_modifier_group');
  };

  const renderDrawerContent = () => {
    switch (drawerSection) {
      case 'add_modifier_group':
        return (
          <AddModifierGroup
            AppId={AppId}
            currentModifierGroups={currentModifierGroups}
            onAddGroup={onAddModifierGroup}
            onClose={onClose}
            onCreateModifierGroupClick={() => setDrawerSection('create_modifier_group')}
            translate={translate}
          />
        );
      case 'create_modifier_group':
        return (
          <CreateModifierGroupDrawerSection
            AppId={AppId}
            formValues={newModifierGroupData}
            onClose={handleCancelCreate}
            onCreateModifierClick={handleCreateModifierClick}
            onGroupCreated={handleGroupCreated}
            translate={translate}
          />
        );
      default:
        return (
          <CreateModifier
            AppId={AppId}
            onClose={() => setDrawerSection('create_modifier_group')}
            onModifierCreated={handleModifierCreated}
            translate={translate}
          />
        );
    }
  };

  const title = getTitle();
  return (
    <Drawer
      onClose={onClose}
      open={open}
      header={
        <div className={classes.headerContainer}>
          <Typography variant="h5">
            <Translate id={title} />
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      }
    >
      {renderDrawerContent()}
    </Drawer>
  );
};
