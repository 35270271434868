import React, { ChangeEvent } from 'react';

import DeleteIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { menusActions } from '../../actions/menus.actions';
import { LoadingButton } from '../../ui/LoadingButton';

const useStyles = makeStyles((theme: Theme) => ({
  textBlack: {
    color: 'black',
  },
  textGray: {
    color: 'gray',
  },
  dialog: {
    minWidth: '480px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },
  dialogHeader: {
    paddingTop: '28px',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4px',
    },
  },
  dialogActions: {
    padding: '30px 0 16px',
  },
  uploadSubtitle: {
    paddingLeft: '31px',
  },
  uploadTextField: {
    [`& fieldset`]: {
      borderRadius: 50,
    },
  },
  uploadTextFieldWidth: {
    minWidth: '250px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      width: 'auto',
    },
  },
  uploadTextResize: {
    fontSize: '0.875em',
  },
  deleteIcon: {
    margin: '0px 5px 0px 0px',
    padding: '2px',
    border: '2px solid #9E9E9E',
    borderRadius: 25,
  },
  uploadButton: {
    padding: '6px 0px',
  },
}));

type OuterProps = {
  setIsDialogOpen: (state: boolean) => void;
  isDialogOpen: boolean;
  createNewMenu: () => void;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = () => {
  return {
    createNewStoreOption: 'new' as string,
    menu: {},
  };
};

type InnerProps = MappedDispatch & MappedState;

type Props = OuterProps & InnerProps & FormikProps<FormValues>;

const CreateMenuDialog = (props: Props) => {
  const { isDialogOpen, setIsDialogOpen, setFieldValue, translate } = props;

  const classes = useStyles();
  const [fileName, setFileName] = React.useState('');
  const [selection, setSelection] = React.useState('');
  const disabled = selection === '' || (selection === 'upload' && fileName === '');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelection(e.target.value);
    setFieldValue('createNewStoreOption', e.target.value);
    if (selection === 'upload') {
      setFileName('');
      setFieldValue('menu', {});
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setFieldValue('menu', formData);
      setFileName(file.name);
      e.target.value = ''; // Resets the input's value and triggers onchange event even if the file is already selected
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogHeader}>
        <Translate id="Menu_creation_options" />
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="subtitle1" color="textSecondary">
            <Translate id="Choose_whether_to_create_your_menu_from_scratch_or_by_uploading_an_excel_file" />
          </Typography>
        </Box>
        <Form>
          <RadioGroup
            value={selection}
            aria-label="Create new store"
            name="createNewMenuOption"
            onChange={handleChange}
          >
            <FormControlLabel
              value="new"
              control={<Radio data-fd="create_new_radio" color="secondary" />}
              label={
                <Typography className={classes.textBlack}>
                  <Translate id={'Create_new_menu'} />
                </Typography>
              }
            />
            <FormControlLabel
              value="upload"
              control={<Radio data-fd="upload_excel_radio" color="secondary" />}
              label={
                <Typography className={classes.textBlack}>
                  <Translate id={'Upload_Excel'} />
                </Typography>
              }
            />
          </RadioGroup>

          <Box mb={2} className={classes.uploadSubtitle}>
            {selection === 'upload' && (
              <Box>
                <Box mb={3}>
                  <Typography variant="body2" className={classes.textGray}>
                    <Translate
                      id="Check_out_our_helpdesk_article_for_a_template"
                      data={{ helpdeskLink: translate('create_menu_from_excel_url') }}
                    />
                  </Typography>
                </Box>
                {!fileName ? (
                  <Box>
                    <input
                      accept=".xls,.xlsx"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleFileChange}
                      disabled={selection !== 'upload'}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        className={classes.uploadButton}
                        color={'primary'}
                        component="span"
                        disabled={selection !== 'upload'}
                      >
                        <Translate id="Choose_File" />
                      </Button>
                    </label>
                  </Box>
                ) : (
                  <Box className={classes.uploadTextField}>
                    <TextField
                      className={classes.uploadTextFieldWidth}
                      value={fileName}
                      variant="outlined"
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                        classes: {
                          input: classes.uploadTextResize,
                        },
                        style: {
                          padding: 0,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.deleteIcon}
                              onClick={() => {
                                setFileName('');
                                setFieldValue('menu', {});
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </Box>
                )}
                <Box mt={1}>
                  <Typography variant="body2" className={classes.textGray}>
                    <Translate id="Uploading_external_images_may_take_a_few_minutes" />
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          <DialogActions key="actions" className={classes.dialogActions}>
            <Hidden smDown>
              <Button data-fd={`cancel`} onClick={() => setIsDialogOpen(false)} color="primary">
                <Translate id="Cancel" />
              </Button>
              <LoadingButton
                color="primary"
                disabled={disabled}
                fdKey={'Confirm'}
                type="submit"
                variant="contained"
              >
                <Translate id="Confirm" />
              </LoadingButton>
            </Hidden>
            <Hidden smUp>
              <Grid container direction="column">
                <Grid item>
                  <LoadingButton
                    color="primary"
                    fullWidth
                    disabled={disabled}
                    fdKey={'Confirm'}
                    type="submit"
                    variant="contained"
                  >
                    <Translate id="Confirm" />
                  </LoadingButton>
                </Grid>
                <Box mt={2}>
                  <Grid item>
                    <Button
                      fullWidth
                      data-fd={`cancel`}
                      onClick={() => setIsDialogOpen(false)}
                      color="primary"
                    >
                      <Translate id="Cancel" />
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Hidden>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state) {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    uploadMenu: (menu) => dispatch(menusActions.uploadMenu(menu)),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFormik<Props, FormValues>({
    displayName: 'CreateMenuDialog',
    mapPropsToValues: () => {
      return getDefaultFormState();
    },
    handleSubmit: (values, formikBag) => {
      if (values.createNewStoreOption === 'upload') {
        formikBag.props
          .uploadMenu(values.menu)
          .then((resp) => {
            formikBag.resetForm();
            formikBag.setSubmitting(false);
            formikBag.props.setIsDialogOpen(false);
          })
          .catch((err) => {
            formikBag.setSubmitting(false);
          });
      } else {
        formikBag.props.createNewMenu();
        formikBag.resetForm();
        formikBag.setSubmitting(false);
        formikBag.props.setIsDialogOpen(false);
      }
    },
  })
)(CreateMenuDialog);
