import React from 'react';

import { Translate } from 'react-localize-redux';

import { EntitlementOverrideRequest } from '../../../overrides/@flipdish/subscriptions-api-client-typescript/api';

export const translateEntitlementEnums = (label: string) => {
  switch (label) {
    case String(EntitlementOverrideRequest.ReasonCodeEnum.Temporary):
      return <Translate id="Temporary" />;
    case String(EntitlementOverrideRequest.ReasonCodeEnum.Legacy):
      return <Translate id="Legacy" />;
    default:
      return label;
  }
};
