import {
  type CustomerMessages,
  type StoreGroupBase,
  StoreGroupsApiFactory,
} from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const storeGroupsApi = createApi(StoreGroupsApiFactory);

export const storeGroupService = {
  get: loadById,
  getAllForApp: load,
  create,
  update,
  remove,
  assignStores,
  setCustomerMessages,
};

// #region load
export type LoadProps = {
  appId: string;
  query?: string;
  page?: number;
  limit?: number;
};
export const getSalesChannelGroupKey = 'getSalesChannelGroup';
export async function load(props: LoadProps) {
  try {
    const incomingMessage = await storeGroupsApi.getStoreGroupsExtended(
      props.appId,
      props.query,
      props.page,
      props.limit
    );
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region loadById
export async function loadById(storeGroupId: number) {
  try {
    const incomingMessage = await storeGroupsApi.getStoreGroup(storeGroupId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region create
export async function create(appId: string, payload: StoreGroupBase) {
  try {
    const incomingMessage = await storeGroupsApi.createStoreGroup(appId, payload);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region create
export async function update(storeGroupId: number, payload: StoreGroupBase) {
  try {
    const incomingMessage = await storeGroupsApi.updateStoreGroup(storeGroupId, payload);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region create
export async function remove(storeGroupId: number) {
  try {
    await storeGroupsApi.removeStoreGroup(storeGroupId);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion
export async function assignStores(appId: string, storeGroupId: number, storeIds: Array<number>) {
  try {
    await storeGroupsApi.assignStoresToStoreGroup(appId, storeGroupId, storeIds);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

export async function setCustomerMessages(
  storeGroupId: number,
  customerMessages: CustomerMessages
) {
  try {
    await storeGroupsApi.setCustomerMessages(storeGroupId, customerMessages);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
