import React, { memo } from 'react';

import { Channel, Order } from '@flipdish/api-client-typescript';
import Avatar from '@mui/material/Avatar';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import ExternalIconArrow from '../../../assets/images/ico_external.svg';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(0.5),
  },
  subCaption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  caption: {
    lineHeight: 'normal',
    margin: theme.spacing(0.5, 0, 0.5, 0),
  },
}));

type Props = {
  order: Order;
  translate: TranslateFunction;
};

const DetailsCaption = ({ order, translate }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.caption}>{order.Store && order.Store.Name}</div>
      <div className={classes.subCaption}>
        {order.Channel?.Source === Channel.SourceEnum.External && (
          <Avatar className={classes.icon}>
            <img src={ExternalIconArrow} data-fd="external-img" />
          </Avatar>
        )}
        {order.Channel?.TranslationKey &&
          order.Channel?.Source &&
          translate(`${order.Channel?.TranslationKey}_${order.Channel?.Source}` as TranslationId)}
        {order.Channel?.Source === Channel.SourceEnum.External &&
          order.ChannelOrderDisplayId &&
          ` #${order.ChannelOrderDisplayId}`}
      </div>
    </div>
  );
};

export default memo(DetailsCaption);
