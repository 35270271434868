import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

type Props = RouteComponentProps;

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const Inventory = lazyWithRetry(() => import('./Inventory'));

const InventoryRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="inventory">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Inventory />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/stock-items`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.INVENTORY}
                title={<Translate id="Stock_Items" />}
                module={RMSModules.STOCK_ITEMS}
                url={`modules/inventory/${RMSModules.STOCK_ITEMS}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/stock-item-categories`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.INVENTORY}
                title={<Translate id="Stock_Item_Categories" />}
                url={`modules/inventory/${RMSModules.STOCK_ITEM_CATEGORIES}`}
                module={RMSModules.STOCK_ITEM_CATEGORIES}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/suppliers`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.INVENTORY}
                title={<Translate id="Suppliers" />}
                url={`modules/inventory/${RMSModules.SUPPLIERS}`}
                module={RMSModules.SUPPLIERS}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/tracking-profiles`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.INVENTORY}
                title={<Translate id="Tracking_Profiles" />}
                url={`modules/inventory/${RMSModules.TRACKING_PROFILES}`}
                module={RMSModules.TRACKING_PROFILES}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/purchase-orders`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.INVENTORY}
                title={<Translate id="Purchase_Orders" />}
                url={`modules/inventory/${RMSModules.PURCHASE_ORDERS}`}
                module={RMSModules.PURCHASE_ORDERS}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default InventoryRoutes;
