import React from 'react';

import { RestaurantVoucherPayGreenConfiguration } from '@flipdish/api-client-typescript';
import CreditCard from '@mui/icons-material/CreditCard';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import ListItemLink from '@fd/ui/List/ListItemLink';
import Spacer from '@fd/ui/Spacer';

import { RESTAURANT_VOUCHERS_URL } from '../constants';
import RestaurantVoucherDetails from './RestaurantVoucherDetails';

const styles = () =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
      '&:focus': {
        backgroundColor: '#eaf2ff',
      },
    },
    successAvatar: {
      backgroundColor: '#52d699',
    },
    avatar: {
      minHeight: '60px',
    },
  });

export type InterfaceRestaurantVoucherListItemProps = {
  restaurantVoucher: RestaurantVoucherPayGreenConfiguration;
  currentAppId: string | undefined;
  isLast: boolean;
  onClick: (restaurantVoucher: RestaurantVoucherPayGreenConfiguration) => void;
};

type Props = WithStyles<typeof styles> & InterfaceRestaurantVoucherListItemProps;
const RestaurantVoucherListItem = ({
  isLast,
  currentAppId,
  restaurantVoucher,
  classes,
  onClick,
}: Props) => {
  const destinationPath = RESTAURANT_VOUCHERS_URL.getRestaurantVoucherDetailsUrl(
    currentAppId,
    restaurantVoucher.PayGreenConfigurationId
  );

  return (
    <ListItemLink
      alignItems="center"
      button
      className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME}`}
      component={Box}
      disableGutters
      divider={!isLast}
      fdKey={`${restaurantVoucher.Name}`}
      key={restaurantVoucher.PayGreenConfigurationId}
      onClick={() => onClick(restaurantVoucher)}
      to={destinationPath}
    >
      <ListItemAvatar className={classes.avatar}>
        <Avatar className={classes.successAvatar} data-fd="avatarDone">
          <CreditCard />
        </Avatar>
      </ListItemAvatar>

      <RestaurantVoucherDetails restaurantVoucher={restaurantVoucher} />

      <Hidden mdDown>
        <ListItemSecondaryAction>
          <Spacer size={8}>
            <KeyboardArrowRight color="action" />
          </Spacer>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItemLink>
  );
};

export default compose<Props, InterfaceRestaurantVoucherListItemProps>(withStyles(styles))(
  RestaurantVoucherListItem
);
