import React from 'react';

import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button, MessageBanner } from '@fd/ui/atoms';

import {
  closeNotifyLoading,
  notify,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../../../layouts/Notify/actions';
import { catalogChangesService } from '../../../../services/catalogChanges.service';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    borderRadius: '5px',
    width: '100%',
  },
  reviewChangesButton: {
    marginRight: theme.spacing(2),
  },
}));
export type PendingMenuChangesBannerProps = {
  appId: string;
  numberOfChanges: number;
  menuId?: number;
  onChangesPublished?: () => void;
  onReviewClick?: () => void;
  showReviewButton?: boolean;
  translate: TranslateFunction;
};

const PendingMenuChangesBanner = ({
  appId,
  closeNotifyLoading,
  menuId,
  notify,
  notifyError,
  notifyLoading,
  numberOfChanges,
  onChangesPublished,
  onReviewClick,
  showReviewButton,
  translate,
}: PendingMenuChangesBannerProps & MappedDispatch): JSX.Element => {
  const classes = useStyles();

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: () => {
      const menuIds = menuId ? [menuId] : [];
      notifyLoading();
      return catalogChangesService.publishPendingMenuChanges(appId, '', menuIds);
    },

    onSuccess: () => {
      closeNotifyLoading();
      notify({
        message: translate('Successfully_updated_menus') as string,
        variant: 'success',
      });
      //Menus page uses summaries api so needs to invalidate a different query
      const queryKeyName = showReviewButton
        ? catalogChangesService.getPendingMenuChangesQueryKey
        : catalogChangesService.getPendingMenuChangesSummaryQueryKey;
      queryClient.invalidateQueries({ queryKey: [queryKeyName] });
      onChangesPublished !== undefined && onChangesPublished();
    },
    onError: () => {
      closeNotifyLoading();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return (
    <MessageBanner
      className={classes.banner}
      fdKey="pending-menu-changes-banner"
      icon={<RefreshIcon />}
      message={translate('Menus_pendingChangesMessage', { count: numberOfChanges }) as string}
    >
      {showReviewButton && onReviewClick && (
        <Button
          className={classes.reviewChangesButton}
          fdKey="review-changes-button"
          onClick={() => onReviewClick()}
          variant="secondary"
        >
          <Translate id="Review_updates" />
        </Button>
      )}
      <Button fdKey="apply-all-updates-button" onClick={() => mutate()}>
        <Translate id="Apply_all_updates" />
      </Button>
    </MessageBanner>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyLoading: () => dispatch(closeNotifyLoading()),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyLoading: () => dispatch(notifyLoading({ persist: true })),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(PendingMenuChangesBanner);
export { EnhancedComponent as PendingMenuChangesBanner };
