import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  headerIcon: {
    paddingRight: 20,
    color: 'rgba(0, 0, 0, 0.58)',
  },
  expandedButton: {
    height: 30,
  },
  title: {
    margin: 0,
    marginBottom: 4,
    fontWeight: 500,
    fontSize: 18,
  },
  titleContainer: {
    flexGrow: 1,
    minHeight: 70,
  },
}));

const LoadingSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer} data-fd="section-loading-skeleton">
      <div className={classes.headerIcon}>
        <Skeleton width={30} height={30} />
      </div>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          <Skeleton width={200} height={30} />
        </div>
        <Skeleton width={200} height={12} />
        <Skeleton width={200} height={12} />
      </div>
      <Skeleton width={30} height={30} />
    </div>
  );
};

export default LoadingSkeleton;
