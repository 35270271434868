import React, { useState } from 'react';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import { copyToClipboard } from '../../../ui/utils';

const styles = () => ({
  info: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    height: '24px',
  },
  row: {
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
});

type Props = {
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  accessToken: string;
};

const OAuthAppAccessTokenDialog = (props: Props & WithStyles<any, any>) => {
  const { setDialogOpen, isDialogOpen, accessToken, classes } = props;
  const [isChecked, setChecked] = useState(false);

  const dialogContent = [
    <DialogContent key="dialog-content">
      <Typography variant="h6">
        <Translate id="Requested_access_token" />?
      </Typography>
      <Typography display="inline" variant="subtitle1" color="textSecondary">
        <Translate id="Requested_access_token_description1" />{' '}
      </Typography>
      <Typography display="inline" variant="subtitle2">
        <Translate id="Requested_access_token_description2" />
      </Typography>
      <Grid item xs={12} className={classes.row}>
        <InputBase
          fullWidth
          classes={{ input: classes.info }}
          id="adornment-access-token"
          type={'text'}
          value={accessToken}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Copy access token"
                onClick={(e) => copyToClipboard(e, 'adornment-access-token')}
              >
                <CopyIcon fontSize={'small'} />
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => setChecked(!isChecked)}
            checked={isChecked}
            data-fd="token_copied_checkbox"
            color="primary"
          />
        }
        label={<Translate id="Confirm_token_copied" />}
      />
    </DialogContent>,
    <DialogActions key="actions">
      <Button
        color="primary"
        disabled={!isChecked}
        data-fd="close_access_token_dialog"
        onClick={() => {
          setChecked(false);
          setDialogOpen(false);
        }}
      >
        <Translate id="Close" />
      </Button>
    </DialogActions>,
  ];

  return (
    <Dialog
      open={isDialogOpen}
      disableEscapeKeyDown
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {dialogContent}
    </Dialog>
  );
};

export default withStyles(styles)(OAuthAppAccessTokenDialog);
