import React, { useMemo } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { Field, FieldProps } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/lib/types';
import { compose } from 'recompose';

import { formikValidate } from '../../../../helpers/validation';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import MultiSelect, { components, MultiValue, OptionType } from '../../../../ui/Select/Select';

type Option = {
  value: string;
  label: string;
  displayOrder: number;
};

const validateLanguages = (value: string[]) => {
  return formikValidate.required(value);
};

const LANGUAGES = [
  {
    LanguageId: 'bg',
    DisplayOrder: 13,
    Name: 'Bulgarian',
  },
  {
    LanguageId: 'ca-ES',
    DisplayOrder: 3,
    Name: 'Català',
  },
  {
    LanguageId: 'de',
    DisplayOrder: 4,
    Name: 'Deutsche',
  },
  {
    LanguageId: 'el',
    DisplayOrder: 5,
    Name: 'Ελληνικά',
  },
  {
    LanguageId: 'en',
    DisplayOrder: 1,
    Name: 'English',
  },
  {
    LanguageId: 'en-US',
    DisplayOrder: 2,
    Name: 'US English',
  },
  {
    LanguageId: 'es',
    DisplayOrder: 6,
    Name: 'Español',
  },
  {
    LanguageId: 'es-MX',
    DisplayOrder: 14,
    Name: 'Español Mexicano',
  },
  {
    LanguageId: 'fi',
    DisplayOrder: 15,
    Name: 'Finnish',
  },
  {
    LanguageId: 'fr',
    DisplayOrder: 7,
    Name: 'Français',
  },
  {
    LanguageId: 'it',
    DisplayOrder: 11,
    Name: 'Italiano',
  },
  {
    LanguageId: 'nl',
    DisplayOrder: 8,
    Name: 'Nederlands',
  },
  {
    LanguageId: 'pl',
    DisplayOrder: 12,
    Name: 'Polski',
  },
  {
    LanguageId: 'pt',
    DisplayOrder: 9,
    Name: 'Portugues',
  },
  {
    LanguageId: 'pt-BR',
    DisplayOrder: 10,
    Name: 'Portugues do Brasil',
  },
];

const languageOptions = LANGUAGES.map((lang) => ({
  value: lang.LanguageId,
  label: lang.Name,
  displayOrder: lang.DisplayOrder,
}));

type InnerProps = MappedProps;
type OuterProps = {};
type Props = InnerProps & OuterProps;
const SelectLanguagesField = ({ translate }: Props) => {
  return (
    <FormItemLayout label={<Translate id="Languages" />}>
      <Field name="languages" validate={validateLanguages}>
        {({ field, form }: FieldProps) => {
          const { errors, touched } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          return (
            <MultiSelect
              dataFd="languages-select"
              isMulti
              isClearable
              maxChips={5}
              components={{
                ...components,
                MultiValue: (props) => {
                  const value = props.getValue() as ValueType<
                    OptionType & { displayOrder: number }
                  >;

                  const isDefault =
                    value && Array.isArray(value) && value[0].value === props.data.value;

                  return (
                    <MultiValue
                      {...props}
                      // eslint-disable-next-line react/no-children-prop
                      children={
                        isDefault
                          ? `${props.children} (${translate('Default') as TranslationId})`
                          : props.children
                      }
                    />
                  );
                },
              }}
              value={field.value}
              onChange={(value) => {
                const orderedValue =
                  value && Array.isArray(value) && value.map((v, i) => ({ ...v, displayOrder: i }));
                form.setFieldValue(field.name, orderedValue);
              }}
              options={languageOptions}
              TextFieldProps={{
                fdKey: 'languages_select',
                style: { paddingRight: 16 },
                error: !!showError,
                helperText: showError ? fieldError : '',
                FormHelperTextProps: {
                  style: { textAlign: 'right' },
                },
              }}
              variant="standard"
            />
          );
        }}
      </Field>
      <FormHelperText>{translate('Languages_item_description') as TranslationId}</FormHelperText>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(SelectLanguagesField);
