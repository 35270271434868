import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import TableQuery from '@fd/ui/GenericTable/TableQuery';

import { useTracking } from '../../../../services/amplitude/useTracking';
import { flagService } from '../../../../services/flagService';
import {
  payoutsDefaultColumns,
  payoutsPreSelectedColumns,
  payoutTableMeta,
} from '../data/PayoutMeta';
import { getPayoutsCSV } from '../payouts.actions';
import { getPayoutTableResults } from '../payouts.service';
import { FetchDataProps, PayoutStatus, PayoutTableCSVParams, PeriodProps } from '../types';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {
  accountIds: number[] | undefined;
  currency?: CurrencyEnum;
  currentPeriodFilter: PeriodProps;
  languageCode: string;
  payoutStates: PayoutStatus[] | undefined;
};
type Props = InnerProps & OuterProps & RouteComponentProps;

const PayoutTable = (props: Props) => {
  const {
    accountIds,
    appId,
    currency,
    currentPeriodFilter,
    history,
    languageCode,
    location,
    payoutStates,
    requestDownloadCSV,
    redirectToNewPayoutReport,
  } = props;
  const { trackNavigationEvent } = useTracking();

  const getTableData = async (props: FetchDataProps) => {
    const data = await getPayoutTableResults({ ...props.filter, ...props });

    return {
      Data: data.result,
      Page: props.page,
      Limit: props.limit,
      TotalRecordCount: data.pagination.total,
    };
  };

  const filterQueryParams = useMemo(
    () => ({
      bankAccountIds: accountIds,
      startDate: currentPeriodFilter.startDate,
      endDate: currentPeriodFilter.endDate,
      states: payoutStates,
    }),
    [accountIds, currentPeriodFilter, payoutStates]
  );

  const onRowClick = ({ row }: { row: Flipdish.Payout }) => {
    const URLparams = new URLSearchParams(location.search);
    if (row.PayeeBankAccountId != null) {
      URLparams.set('bankAccountId', row.PayeeBankAccountId.toString());
    }
    if (redirectToNewPayoutReport) {
      trackNavigationEvent('Navigate to payout report v3');
      history.push(`/${appId}/finance/payoutsv3/${row.PayoutId}?${URLparams.toString()}`);
      return;
    }

    trackNavigationEvent('Navigate to payout report');
    history.push(`/${appId}/finance/payouts/${row.PayoutId}?${URLparams.toString()}`);
  };

  const downloadCSV = () => {
    requestDownloadCSV(filterQueryParams as PayoutTableCSVParams);
  };

  return (
    <TableQuery
      columnLocalStorageKey={'fd-payout-table-columns'}
      currency={currency}
      defaultTableColumns={payoutsDefaultColumns}
      downloadCSV={downloadCSV}
      emptyTableTitle={'No_payouts_found'}
      fetchData={getTableData}
      filter={filterQueryParams}
      languageCode={languageCode}
      metadata={payoutTableMeta}
      onRowClick={onRowClick}
      pageLocalStorageKey={'fd-payouts-table-page'}
      preSelectedTableColumns={payoutsPreSelectedColumns}
      rowsPerPageLocalStorageKey={'fd-payouts-table-rows-per-page'}
      showColumnSelector
      storageKey={'fd-payout-table'}
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId as string,
    redirectToNewPayoutReport: flagService.isFlagOn(state, 'useNewPayoutV3Report'),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  requestDownloadCSV: (payoutTableCSVParams: PayoutTableCSVParams) =>
    dispatch(getPayoutsCSV(payoutTableCSVParams)),
});

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PayoutTable);
