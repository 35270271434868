import React, { useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { CheckboxField } from '@fd/ui/atoms';
import StoreFilterInfiniteScroll, { StoreOption } from '@fd/ui/Filter/StoreFilterInfiniteScroll';

import { loadByAppIdHeaders } from '../../../../services/store.service';

const useStyles = makeStyles((theme: Theme) => ({
  filtersContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

type OuterProps = {
  setSearchStores: (selectedStores: StoreOption[]) => void;
  setViewUnassignedMenus: (setUnassignedMenus: boolean) => void;
  viewUnassignedMenus: boolean;
  customSelectedStores?: StoreOption[];
};

type Props = MappedState & OuterProps;

const MenusFilters = (props: Props) => {
  const {
    AppId,
    menus,
    setSearchStores,
    setViewUnassignedMenus,
    translate,
    viewUnassignedMenus,
    customSelectedStores,
  } = props;
  const classes = useStyles();
  const [getAllWLstores, setGetAllWLstores] = useState<StoreOption[]>([]);

  // #region getAllStores
  const {
    data: getStoreHeaders,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['loadStoresByAppIdHeadersMenus', AppId],
    queryFn: ({ pageParam }) => loadByAppIdHeaders({ appId: AppId, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    gcTime: 15 * 60 * 1000,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (getStoreHeaders?.pages) {
      const setAllStores = getStoreHeaders.pages.reduce<StoreOption[]>((total, page) => {
        page?.forEach((s) =>
          total.push({
            label: s.Name!,
            value: s.StoreId?.toString() ?? '',
          })
        );
        return total;
      }, []);
      setGetAllWLstores(setAllStores);
    }
  }, [getStoreHeaders?.pages]);

  const getAssignedMenuStores = useMemo(() => {
    if (getAllWLstores) {
      const stores = getAllWLstores.filter((all) =>
        menus.some((menu) => menu.StoreNames.includes(all.label))
      );
      return stores;
    }
    return [];
  }, [getAllWLstores, viewUnassignedMenus]);

  // #endregion

  const onStoreFilterChange = (stores, storeOptions) => {
    if (stores === undefined) {
      setSearchStores([]);
    }
    if (storeOptions && viewUnassignedMenus) {
      setViewUnassignedMenus(false);
    }
    storeOptions &&
      setSearchStores(
        storeOptions.map((store) => ({
          label: store.label,
          value: store.value,
        }))
      );
  };

  return (
    <Grid container spacing={3} mb={1.5} className={classes.filtersContainer}>
      <Grid item xs={12} md={5}>
        <StoreFilterInfiniteScroll
          parentSelectedStores={customSelectedStores}
          customStoreOptions={getAssignedMenuStores}
          hasAllStoresOption
          isMulti
          onSelectStore={onStoreFilterChange}
          passEmptyForAllStoreIds
          placeholder={viewUnassignedMenus ? 'No_stores' : 'Select_stores'}
          useCustomStoreOptions
          zIndex={99}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        style={{
          alignSelf: 'center',
        }}
      >
        <CheckboxField
          ariaLabel={translate('View_unassigned_menus') as string}
          checked={viewUnassignedMenus}
          fdKey="view-unassigned-menus-checkbox"
          label={translate('View_unassigned_menus') as string}
          onChange={() => setViewUnassignedMenus(!viewUnassignedMenus)}
        />
      </Grid>
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    AppId: state.currentApp.AppId as string,
    translate: getTranslate(state),
    menus: state.menus.menus,
  };
}

export default connect(mapStateToProps)(MenusFilters);
