// https://github.com/mui-org/material-ui/issues/13218#issuecomment-430694325
import React from 'react';

import { type LinkProps, Link } from 'react-router-dom';

import Button, { ButtonPropsInterface } from './Button';

type ILinkButtonProps = ButtonPropsInterface & LinkProps;
const ButtonLink = Button as React.ComponentType<React.PropsWithChildren<any>>;

const LinkButton: React.FC<React.PropsWithChildren<ILinkButtonProps>> = ({ fdKey, ...rest }) => {
  return <ButtonLink {...rest} fdKey={fdKey} component={Link} role="button" />;
};

export default LinkButton;
