import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router-dom';

import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import cookieSettingsIcon from '../../../assets/images/Cookie.svg';
import storesIcon from '../../../assets/images/storesIcon.svg';
import websiteDomainIcon from '../../../assets/images/website-domain.svg';
import websiteEditIcon from '../../../assets/images/website-edit.svg';
import { lazyWithRetry } from '../../../helpers/utilities';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import { flagService } from '../../../services/flagService';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { RMSSubscriptions } from '../../RMS/utils/rmsSubscriptions';
import { SET_HOSTNAME_APP_CONFIG } from '../../WebsiteAndApp/actions';

const WebsiteDevices = lazyWithRetry(() => import('./WebsiteDevices'));
const SalesChannelList = lazyWithRetry(() => import('../../RMS/SalesChannels/SalesChannelList'));

const StyledPaperContainer = styled(PaperContainer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));

type Props = MappedState & MappedDispatch;

const getNextGenUrl = (appId: string) => {
  return window.location.host.includes('portal.flipdishdev.com')
    ? `https://editor.portal.flipdishdev.com/${appId}`
    : `https://editor.portal.flipdish.com/${appId}`;
};

const domaUpdateLoadingSelector = createLoadingSelector([SET_HOSTNAME_APP_CONFIG]);

const WebsiteSettings = (props: Props) => {
  const { currentApp, translate, permissions, selectedProperty, showSalesChannels } = props;
  const history = useHistory();
  const { data } = useQueryRMSCommonDataHook(currentApp?.AppId || '', true);
  const handleChannelClick = (salesChannel) => {
    history.push(
      `/${currentApp?.AppId}/sales-channels/website/property/${selectedProperty?.id}/sales-channel/${salesChannel.salesChannelId}`
    );
  };

  const websiteBuilderTab = React.useMemo(() => {
    if (permissions?.includes('EditSalesChannels')) {
      return {
        label: translate('Website_Builder') as string,
        component: (
          <StyledPaperContainer fluid>
            <List aria-label={translate('Website') as string}>
              <ListItemLinkButton
                tagText="New"
                title="Edit_site_code"
                subtitle="Manage_your_new_site_code"
                icon={websiteEditIcon}
                onClick={() => {
                  currentApp.AppId &&
                    window.open(getNextGenUrl(currentApp.AppId), '_blank', 'noopener,noreferrer');
                }}
                isLast={true}
              />
            </List>
          </StyledPaperContainer>
        ),
        queryString: 'edit-website',
      };
    }
    return null;
  }, [permissions, currentApp.AppId, translate]);

  const tabItems = [
    ...(showSalesChannels
      ? [
          {
            label: translate('Sales_Channels') as string,
            component: (
              <LazyComponent>
                <SalesChannelList
                  filterByType={[SalesChannelType.FLIPDISH_WEB_APP, SalesChannelType.WEB_APP]}
                  onChannelClick={handleChannelClick}
                />
              </LazyComponent>
            ),
            queryString: 'sales-channels',
          },
        ]
      : []),
    ...(websiteBuilderTab ? [websiteBuilderTab] : []),
    ...(permissions?.some(
      (p) =>
        ['Owner', 'ManagedOwner', 'FlipdishStaff', 'ViewHydraConfig'].includes(p) &&
        data?.subscriptions?.includes(RMSSubscriptions.BASE)
    )
      ? [
          {
            label: translate('Devices') as string,
            component: (
              <LazyComponent>
                <WebsiteDevices />
              </LazyComponent>
            ),
            queryString: 'devices',
          },
        ]
      : []),
    {
      label: translate('Settings') as string,
      component: (
        <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
          <StyledPaperContainer fluid>
            <List aria-label={translate('Website') as string}>
              <ListItemLinkButton
                title="Domain_setup"
                subtitle="Configure_your_website_domain_by_changing"
                link={`/${currentApp.AppId}/sales-channels/website/domain-setup` as string}
                icon={websiteDomainIcon}
              />
              <ListItemLinkButton
                title="Cookie_management"
                subtitle="Select_type_of_cookie_setup_default"
                link={`/${currentApp.AppId}/sales-channels/website/cookie-management` as string}
                icon={cookieSettingsIcon}
              />
              <Permissions allowed={['EditSalesChannels']}>
                <ListItemLinkButton
                  title={'Site_settings'}
                  subtitle="Change_the_visibility_of_the_stores_on_your_website"
                  link={`/${currentApp.AppId}/sales-channels/website/store-visibility` as string}
                  icon={storesIcon}
                  isLast
                />
              </Permissions>
            </List>
          </StyledPaperContainer>
        </Permissions>
      ),
      queryString: 'settings',
    },
  ];

  return (
    <PageLayout
      documentTitle="Website"
      title={<Translate id="Website" />}
      userPermissions="Owner"
      openLinkInNewTab
    >
      <Tabs tabItems={tabItems} />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const getFlipdisherPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'FlipdishStaff',
  ]);
  return {
    permissions: state.permissions,
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
    isFlipdishStaff: getFlipdisherPermissionsSelector(state),
    domaUpdateLoading: domaUpdateLoadingSelector(state),
    selectedProperty: state.rms?.selectedProperty,
    showSalesChannels: flagService.isFlagOn(state, 'showSalesChannels'),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchCloseNotifySaving: () => dispatch(closeNotifySaving()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaving: () => dispatch(notifySaving({ persist: true })),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});
export default connect(mapStateToProps, mapDispatchToProps)(WebsiteSettings);
