import React, { useEffect, useMemo } from 'react';

import { IndexPageBase, WebsiteImage } from '@flipdish/api-client-typescript';
import { FormikProps, withFormik } from 'formik';
import { debounce, isEqual, memoize } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import useIsMounted from '../../../custom-hooks/useIsMounted';
import useRequestSnackBar, {
  useRequestSnackBarIndicators,
} from '../../../custom-hooks/useRequestSnackBar';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import {
  DELETE_WEBSITE_SETTINGS_IMAGE,
  GET_WEBSITE_SETTINGS,
  getWebsiteSettings,
  SET_APP_CONFIG,
  SET_APP_CONFIG_LANGUAGES,
  SET_WEBSITE_SETTINGS,
  setWebsiteSettings,
  UPLOAD_APP_CONFIG_LOGO,
  UPLOAD_WEBSITE_SETTINGS_IMAGE,
  uploadWebsiteSettingsImage,
} from '../../WebsiteAndApp/actions';
import EditWebsiteSettingsImagesPanel from '../../WebsiteAndApp/Edit/EditWebsiteSettingsImagesPanel';
import { ImageLocationEnum } from '../../WebsiteAndApp/service';

const loadingSelector = createLoadingSelector([GET_WEBSITE_SETTINGS]);
const savingSelector = createLoadingSelector([
  SET_APP_CONFIG,
  SET_APP_CONFIG_LANGUAGES,
  UPLOAD_APP_CONFIG_LOGO,
  SET_WEBSITE_SETTINGS,
  DELETE_WEBSITE_SETTINGS_IMAGE,
  UPLOAD_WEBSITE_SETTINGS_IMAGE,
]);
const errorSelector = createLoadingErrorSelectorFactory([
  SET_APP_CONFIG,
  SET_APP_CONFIG_LANGUAGES,
  UPLOAD_APP_CONFIG_LOGO,
]);

type Props = MappedState & MappedDispatch;

const HomeScreenImageSelection = (props: Props & FormikProps<FormValues>) => {
  const { images, getSettings, submitForm, values, dirty, loading, saving, error } = props;
  const isMounted = useIsMounted();

  const submit = useMemo(
    () =>
      debounce(() => {
        if (isMounted()) {
          submitForm();
        }
      }, 3000),
    []
  );

  // @ts-ignore
  const { success, failure } = useRequestSnackBarIndicators(saving, error);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (dirty && values) {
      submit();
    }
  }, [dirty, values]);

  useRequestSnackBar({
    loading,
    success,
    failure,
    failureMessage: String(error),
    autoSaving: saving,
  });

  if (loading) {
    return <Skeleton height={200} />;
  }

  return (
    <EditWebsiteSettingsImagesPanel
      images={images[ImageLocationEnum.IndexHeader]}
      imageLocation={WebsiteImage.ImageLocationEnum.IndexHeader}
      fdKeyName="image-carousel"
      maxImages={6}
      minImages={1}
    />
  );
};

export const processImages = memoize((Images: WebsiteImage[]) => {
  return Images.reduce<{ [key: string]: any }>(
    (res, image) => {
      if (image.ImageLocation) {
        const currentImages = res[image.ImageLocation];
        if (currentImages && Array.isArray(currentImages)) {
          currentImages.push(image);
        } else {
          res[image.ImageLocation] = image;
        }
      }

      return res;
    },
    {
      [ImageLocationEnum.IndexHeader]: [],
      [ImageLocationEnum.IndexGallery]: [],
    } as any
  );
});

type FormValues = ReturnType<typeof mapPropsToValues>;
const mapPropsToValues = (props: Props) => {
  const { settings } = props;

  return settings;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const {
    settings: { Images = [], ...settings },
  } = state.website;

  return {
    images: processImages(Images),
    settings,
    loading: loadingSelector(state),
    saving: savingSelector(state),
    error: errorSelector(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setSettings: (settings: IndexPageBase) => dispatch(setWebsiteSettings(settings)),
  getSettings: () => dispatch(getWebsiteSettings()),
});

export default compose<Props, {}>(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik<Props, FormValues>({
    displayName: 'KioskHomeScreenCustomization',
    enableReinitialize: true,
    mapPropsToValues,
    handleSubmit: (values: FormValues, formikBag) => {
      const {
        props: { settings, setSettings },
      } = formikBag;

      if (!isEqual(values, settings)) {
        setSettings(values);
      }
    },
  })
)(HomeScreenImageSelection);
