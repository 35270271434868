import { useQuery } from '@tanstack/react-query';

import { oneMinute } from '../components/common/timeUtils';
import { getStoreIdAssociations, getStoreIdAssociationsKey } from '../components/RMS/rms.services';

type Props = {
  salesChannelId: string;
  enabled: boolean;
};
const useQueryStoreIdAssociations = ({ salesChannelId, enabled = false }: Props) => {
  const {
    data: storeAssociation,
    isLoading: loadingStoreAssociation,
    isError: loadingStoreAssociationError,
  } = useQuery({
    queryKey: [getStoreIdAssociationsKey, salesChannelId],
    queryFn: () => getStoreIdAssociations(salesChannelId ?? ''),
    staleTime: oneMinute * 60,
    enabled,
  });

  return {
    storeId: storeAssociation?.data?.flipdish?.storeId,
    loadingStoreAssociation,
    loadingStoreAssociationError,
  };
};

export default useQueryStoreIdAssociations;
