import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Button } from '../../../ui/Button';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import SearchTextField from '../../../ui/SearchTextField/SearchTextField';
import Spacer from '../../../ui/Spacer';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../WithRouteSearchParams';
import { loadNextStores, loadStores, reset } from '../actions';
import { getIsGetStoresLoading, getStores } from '../selectors';
import StoreCardListItem from './ListItem';
import StoreDetailsCardLoading from './StoreDetailsCardLoading';

const LOAD_TYPE_SEARCH = 'LOAD_TYPE_SEARCH';
const LOAD_TYPE_MORE = 'LOAD_TYPE_MORE';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  searchWrapper: {
    marginBottom: spacing(3),
    [breakpoints.up('md')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
}));

type Props = MappedState & MappedDispatch & WithRouteSearchParamsProps<string>;
const StoreCardList = (props: Props) => {
  const {
    getStores,
    loadMoreStores,
    stores,
    showSearch,
    search,
    setSearch,
    hasMore,
    loadingMore,
    resetState,
    translate,
  } = props;
  const classes = useStyles();

  const [loadingType, setLoadingType] = useState(LOAD_TYPE_SEARCH);

  useEffect(() => {
    getStores(search);

    return () => {
      resetState();
    };
  }, []);

  const loadingBySearch = loadingMore && loadingType === LOAD_TYPE_SEARCH;
  const loadingByMore = loadingMore && loadingType === LOAD_TYPE_MORE;

  return (
    <Grid container>
      {showSearch && (
        <Grid
          item
          xs={12}
          md={6}
          className={`${HORIZONTAL_SPACE_CLASSNAME} ${classes.searchWrapper}`}
        >
          <SearchTextField
            fdKey="Search_stores"
            placeholder={translate('Search_stores')}
            defaultValue={search}
            onChange={(search: string) => {
              setSearch(search);
              getStores(search);
              setLoadingType(LOAD_TYPE_SEARCH);
            }}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <List disablePadding>
          {loadingBySearch && (
            <ListItem key={'loading'} disableGutters>
              <StoreDetailsCardLoading />
            </ListItem>
          )}
          {!loadingBySearch &&
            stores.map((store) => {
              return <StoreCardListItem key={store.StoreId} storeId={store.StoreId!} />;
            })}
        </List>
      </Grid>
      {!loadingBySearch && hasMore ? (
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="text"
            fdKey="load-more-stores"
            disabled={loadingMore}
            onClick={() => {
              loadMoreStores(search);
              setLoadingType(LOAD_TYPE_MORE);
            }}
          >
            {loadingByMore ? <CircularProgress /> : <Translate id="Load_more" />}
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Spacer size={24} />
      </Grid>
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const stores = getStores(state);
  const { home } = state;
  const showSearch = home.stores.showSearch;
  const hasMore = home.stores.hasMore;
  const loadingMore = getIsGetStoresLoading(state);
  const showLoading = stores.length === 0 && home.stores.isEmpty === false;
  return {
    translate: getTranslate(state.locale),
    stores: stores,
    hasMore,
    loadingMore,
    showSearch,
    showLoading,
  };
};

// SHOW MAX {limit} STORES
const LIMIT = 10;
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getStores: (query?: string) => {
    dispatch(loadStores({ page: 1, limit: LIMIT, query }));
  },
  loadMoreStores: (query?: string) => {
    dispatch(loadNextStores({ limit: LIMIT, query }));
  },
  resetState: () => {
    dispatch(reset());
  },
});

export default compose<Props, {}>(
  withRouteSearchParams({
    name: 'filter',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(StoreCardList);
