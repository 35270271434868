import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import Skeleton from 'react-loading-skeleton';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formikValidate } from '../../../../helpers/validation';
import { Button } from '../../../../ui/Button';
import FileUpload from '../../../../ui/FileUpload';
import PreventNavigation from '../../../Finance/Banking/components/PreventNavigation';
import FormItem, { RenderProps } from '../../../WebsiteAndApp/components/FormItem';
import { addStoreLogo, deleteStoreKioskLogo } from '../actions';
import { getKioskLogo } from '../selectors';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  logoWrapper: {
    width: 114,
  },
  saveButton: {
    [breakpoints.only('sm')]: {
      display: 'flex',
    },
  },
  container: {
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const fileReader = new FileReader();

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OuterProps) => ({
  uploadLogo: (data: FormData) => {
    return dispatch(addStoreLogo(props.storeId, data));
  },
  deleteLogo: () => {
    return dispatch(deleteStoreKioskLogo(props.storeId));
  },
});

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
  getLogo: getKioskLogo(state),
});

type FormValues = {
  logo?: File;
};

type InnerProps = ReturnType<typeof mapStateToProps> &
  FormikProps<FormValues> &
  ReturnType<typeof mapDispatchToProps>;
type OuterProps = {
  title: string;
  storeId: number;
  fdKeyName: string;
  loading?: boolean;
};

type Props = InnerProps & OuterProps;

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik<Props, FormValues>({
    displayName: 'StoreLogo',
    enableReinitialize: true,
    handleSubmit: ({ logo }: FormValues, formikBag: FormikBag<Props, FormValues>) => {
      const {
        props: { uploadLogo, deleteLogo },
      } = formikBag;
      if (logo && logo.size > 0) {
        const formData = new FormData();
        formData.append('files[]', logo);
        uploadLogo(formData)
          .then(() => {
            formikBag.resetForm();
          })
          .catch((err) => {
            formikBag.setFieldError('logo', err.message);
          });
      } else {
        deleteLogo()
          .then(() => {
            formikBag.resetForm();
          })
          .catch((err) => {
            formikBag.setFieldError('logo', err.message);
          });
      }
    },
  })
)(function StoreLogoForKiosk(props: Props) {
  const { translate, submitForm, getLogo, loading } = props;
  const [logo, setLogo] = useState<null | string>(null);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLogo(getLogo.storeLogoUrl || getLogo.wlLogoUrl);
    setDeleteBtn(!!getLogo.storeLogoUrl);
  }, [getLogo]);

  return (
    <Form style={{ width: '100%' }}>
      <PreventNavigation when={props.dirty} />

      <Grid container spacing={2} wrap="nowrap" className={classes.container}>
        <Grid item container xs={12} sm={4} alignContent="flex-start" className={classes.container}>
          <Typography display="block" gutterBottom>
            <Box component="span" fontWeight={500}>
              {translate('Store_logo')}
            </Box>
          </Typography>
          <Typography variant="caption">
            {translate('When_your_store_is_part_of_a_multi_store_kiosk_this_logo_will_display')}
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={12}>
          <Grid>
            <FormItem
              name="logo"
              validate={async (value: File) => {
                const isNotValidExtension = formikValidate.extension(value, ['png', 'jpeg', 'jpg']);

                if (isNotValidExtension) {
                  throw isNotValidExtension;
                }

                if (value && value.size > 0) {
                  fileReader.readAsDataURL(value);
                  fileReader.onload = () => {
                    if (fileReader.result) {
                      setLogo(fileReader.result as string);
                    }
                  };
                }
              }}
            >
              {({ field: { name }, form }: RenderProps) => (
                <div className={classes.logoWrapper}>
                  {loading ? (
                    <Skeleton width={'114px'} height={'114px'} />
                  ) : (
                    <FileUpload
                      fdKey="storeLogo"
                      url={logo}
                      deleteBtn={deleteBtn}
                      onChange={(data?: File) => {
                        if (data) {
                          form.setFieldValue(name, data);
                        } else {
                          form.setFieldValue(name, new File([], 'dummy', { type: 'image/png' }));
                          setLogo(getLogo.wlLogoUrl);
                          setDeleteBtn(false);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </FormItem>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="flex-end">
            <Button
              variant="outlined"
              color="primary"
              fdKey="change-logo"
              className={classes.saveButton}
              disabled={!props.dirty}
              onClick={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              {translate('Save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
});
