import React from 'react';

import { SubscriptionSummary } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { isAfter, isBefore } from 'date-fns';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import Tag from '../../common/Tag';
import { getStatusTag } from './components/GetStatusTag';
import { subscriptionPrice } from './components/SubscriptionPrice';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    lineHeight: '0.5px',
  },
  textAlignRight: {
    textAlign: 'end',
    paddingRight: theme.spacing(3.5),
  },
  justifyContent: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3.5),
  },
  breakWord: {
    overflowWrap: 'break-word',
  },
  paymentAndStatusFields: {
    paddingTop: theme.spacing(1.1),
  },
  fieldGroupPadding: {
    paddingTop: theme.spacing(1),
  },
  nextPayment: {
    paddingTop: theme.spacing(0.5),
  },
  fontWeightBold: {
    fontWeight: 500,
  },
}));

export type SubscriptionListItemDetailsProps = {
  subscription: SubscriptionSummary;
  overdue: boolean;
};

type Props = SubscriptionListItemDetailsProps & MappedState;
const SubscriptionListItemDetails: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { subscription, languageCode, dtUtils, overdue } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showSubscriptionPriceField =
    subscription?.NextInvoiceAmount || subscription?.NextInvoiceAmount === 0;

  const mobilePrimaryContent = () => {
    return (
      <Grid container xs={12} className={classes.fieldGroupPadding}>
        {showSubscriptionPriceField &&
          subscriptionPrice(
            subscription?.NextInvoiceAmount as unknown as number,
            subscription?.Currency as any,
            languageCode,
            'h5'
          )}
        {!!subscription?.Status && getStatusTag(subscription?.Status)}
        {overdue && (
          <Grid item xs={12} className={classes.fieldGroupPadding}>
            <Tag alignSelf="center" tagName={'Overdue_Invoice'} showYellowTag noMargin />
          </Grid>
        )}
      </Grid>
    );
  };

  const nonMobilePrimaryContent = () => {
    return (
      <Grid item xs={6}>
        <Grid
          className={clsx(classes.justifyContent, classes.paymentAndStatusFields)}
          container
          direction="row"
          alignItems="flex-end"
        >
          {!!subscription?.Status && getStatusTag(subscription.Status)}
          {overdue && <Tag alignSelf="center" tagName={'Overdue_Invoice'} showYellowTag />}
          {showSubscriptionPriceField &&
            subscriptionPrice(
              subscription?.NextInvoiceAmount,
              subscription.Currency,
              languageCode,
              'h5'
            )}
        </Grid>
      </Grid>
    );
  };

  const invoiceDate =
    subscription?.NextInvoiceBillingDate &&
    dtUtils.format(new Date(subscription.NextInvoiceBillingDate), dtUtils.LOCAL_DATE_FORMAT);

  const cancellationDate =
    subscription?.CancellationDate &&
    dtUtils.format(new Date(subscription.CancellationDate), dtUtils.LOCAL_DATE_FORMAT);

  const CancellationRequestDate =
    subscription?.CancellationRequestDate &&
    dtUtils.format(new Date(subscription.CancellationRequestDate), dtUtils.LOCAL_DATE_FORMAT);

  const cancellationDateIsAfter =
    subscription?.CancellationDate && isAfter(new Date(subscription.CancellationDate), new Date());

  const cancellationDateIsBefore =
    subscription?.CancellationDate && isBefore(new Date(subscription.CancellationDate), new Date());
  return (
    <ListItemText
      disableTypography
      primary={
        <Grid container>
          <Grid item xs={12} md={6} className={clsx(classes.header, classes.breakWord)}>
            <Typography className={classes.fontWeightBold} variant="subtitle1">
              {subscription.Name}
            </Typography>
          </Grid>
          {!isMobile && nonMobilePrimaryContent()}
        </Grid>
      }
      secondary={
        <Grid container className={classes.breakWord}>
          <Grid item xs={12} md={6} className={isMobile ? classes.fieldGroupPadding : ''}>
            {subscription?.User && <Typography variant="caption">{subscription.User}</Typography>}
            {subscription?.DefaultPaymentDescription && (
              <Grid item xs={12}>
                <Typography variant="caption">{subscription.DefaultPaymentDescription}</Typography>
              </Grid>
            )}
          </Grid>
          {isMobile && mobilePrimaryContent()}
          {cancellationDateIsBefore && (
            <Grid
              item
              xs={12}
              md={6}
              className={clsx(!isMobile ? classes.textAlignRight : '', classes.nextPayment)}
            >
              <Typography variant="caption">
                <Translate id="Cancelled_on" />
                {` ${cancellationDate}`}
              </Typography>
            </Grid>
          )}
          {cancellationDateIsAfter && subscription?.NextInvoiceBillingDate && (
            <Grid
              item
              xs={12}
              md={6}
              className={clsx(!isMobile ? classes.textAlignRight : '', classes.nextPayment)}
            >
              <Typography variant="caption">
                <Translate id="Next_payment_on" />
                {` ${invoiceDate}`}
              </Typography>
            </Grid>
          )}
          {cancellationDateIsAfter && (
            <Grid
              item
              xs={12}
              md={12}
              className={clsx(!isMobile ? classes.textAlignRight : '', classes.nextPayment)}
            >
              <Typography variant="caption">
                <Translate id="Requested_to_cancel_on" />
                {` ${CancellationRequestDate}`}
              </Typography>{' '}
              <Typography variant="caption">
                <Translate id="Scheduled_to_cancel" />
                {` ${cancellationDate}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    languageCode: getActiveLanguage(state.locale),
    dtUtils: dateTimeUtils(state),
  };
};

export default connect(mapStateToProps)(SubscriptionListItemDetails);
