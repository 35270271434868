import React, { ReactNode } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { HORIZONTAL_SPACE_CLASSNAME, VERTICAL_SPACE_CLASSNAME } from './index';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    [breakpoints.only('sm')]: {
      marginLeft: spacing(-3),
      marginRight: spacing(-3),
    },

    [breakpoints.only('xs')]: {
      marginLeft: spacing(-2),
      marginRight: spacing(-2),
    },
  },
  noTopSpace: {
    marginTop: `${spacing(-3)} !important`,

    [breakpoints.only('xs')]: {
      marginTop: `${spacing(-2)} !important`,
    },
  },
  fullSize: {
    [breakpoints.up('sm')]: {
      marginLeft: spacing(-3),
      marginRight: spacing(-3),
    },
  },
  fullWidth: {
    marginLeft: -20,
    marginRight: -20,
  },
}));

type Props = {
  className?: string;
  children: ReactNode;
  fullWidth?: boolean;
  fullSize?: boolean;
  noTopSpace?: boolean;
};

export default function FullWidthContainer(props: Props) {
  const { children, className = '', fullWidth, fullSize, noTopSpace } = props;
  const classes = useStyles();
  const classNames = clsx({
    [classes.root]: true,
    [classes.fullWidth]: fullWidth,
    [classes.fullSize]: fullSize,
    [HORIZONTAL_SPACE_CLASSNAME]: fullSize,
    [VERTICAL_SPACE_CLASSNAME]: fullSize,
    [classes.noTopSpace]: noTopSpace,
    [className]: !!className,
  });

  return <div className={classNames}>{children}</div>;
}
