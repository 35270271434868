import React from 'react';

import { App, CreateVoucher, Voucher as VoucherType } from '@flipdish/api-client-typescript';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeliveryIcon from '@mui/icons-material/DirectionsBike';
import MenusIcon from '@mui/icons-material/FastfoodOutlined';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { type WithSnackbarProps, withSnackbar } from 'notistack';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { MessageBanner } from '@fd/ui/atoms';
import LinkButton from '@fd/ui/Button/LinkButton';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import FullWidthContainer from '@fd/ui/Layout/FullWidthContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import { LoadingButton } from '@fd/ui/LoadingButton';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';

import { getTeammateDetails } from '../../../actions/teammates.actions';
import { vouchersActions } from '../../../actions/vouchers.actions';
import CreditNote from '../../../assets/images/CreditNote.svg';
import PercentageDiscount from '../../../assets/images/PercentageDiscount.svg';
import { vouchersConstants } from '../../../constants/vouchers.constants';
import currencyFormatter from '../../../helpers/currencyFormatter';
import { AppLoadingCircularProgress } from '../../AppLoadingCircularProgress';
import StoreListTruncated from '../../StoreListTruncated';
import VoucherLineChart from './components/VoucherLineChart';

const styles = (theme: Theme) => ({
  banner: {
    borderRadius: '5px',
    marginBottom: '1em',
    width: '100%',
    backgroundColor: '#FFF2D5',
  },
  container: {
    display: 'flex',
  },
  iconColour: {
    color: theme.palette.common.white,
  },
  voucherContent: {
    flex: 1,
  },
  voucherHeader: {
    display: 'flex',
    paddingBottom: '24px',
    paddingRight: '24px',
    marginRight: '-24px',
    borderBottom: '1px solid rgba(0,0,0,0.2088)',
  },
  voucherHeaderText: {
    flex: 1,
    wordBreak: 'break-all' as any,
  },
  type: {
    width: '72px',
    height: '72px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '48px',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  typeValid: {
    backgroundColor: '#76e3b6',
  },
  typeUsed: {
    backgroundColor: '#95bffa',
  },
  typeExpired: {
    backgroundColor: '#fb7c92',
  },
  typeDisabled: {
    backgroundColor: '#dbdbdb',
  },
  typeNotYetValid: {
    backgroundColor: '#05149e',
  },
  editButton: {
    margin: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: '1.3px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  button: {
    margin: theme.spacing(1),
    color: '#fff',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: '1.3px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  infoContainer: {
    padding: 0,
    marginTop: '24px',
    [theme.breakpoints.down('md')]: {
      marginRight: '16px',
    },
  },
  info: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.75,
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  subHeading: {
    letterSpacing: '0.38px',
  },
  category: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    marginBottom: '8px',
  },
  number: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '4px',
  },
  expiredNumber: {
    color: '#ff395f!important',
  },
  blue: {
    color: '#05149e',
    marginBottom: 0,
    cursor: 'pointer',
  },
  voucherInfo: {
    paddingTop: '8px',
    paddingBottom: '24px',
  },
  hyperlink: {
    textDecoration: 'none',
    outline: 'none',
  },
  responsiveVoucherHeader: {
    padding: '24px 16px 16px 24px',
    display: 'flex',
  },
  responsiveVoucherHeaderText: {
    flex: 1,
  },
  responsiveVoucherHeaderValid: {
    backgroundColor: 'rgba(134, 232, 187, 0.15)',
  },
  responsiveVoucherHeaderUsed: {
    backgroundColor: 'rgba(151, 187, 250, 0.3)',
  },
  responsiveVoucherHeaderExpired: {
    backgroundColor: 'rgba(251, 124, 146, 0.3)',
  },
  responsiveVoucherHeaderDisabled: {
    backgroundColor: 'rgba(219, 219, 219, 0.3)',
  },
  responsiveVoucherDesc: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '4px',
  },
  storesTruncated: {
    color: '#05149e',
    fontSize: '14px',
    textTransform: 'capitalize' as any,
    letterSpacing: '0.38px',
    verticalAlign: 'initial',
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: 0,
  },
  responsiveVoucherInfo: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: '0.3px',
    marginBottom: '8px',
  },
  snackbarContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    padding: '16px',
    marginBottom: '20px',
    '&>div': {
      padding: '0',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  snackbarSpan: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.3px',
  },
  snackbarIcon: {
    width: '22px',
    marginRight: '12px',
  },
  failureSnackbarContent: {
    backgroundColor: '#b00020',
  },
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

type Props = MappedDispatch &
  MappedState &
  WithStyles<any> &
  RouteComponentProps<{ voucherId: string }> &
  WithSnackbarProps;

class VoucherDetails extends React.Component<Props, any> {
  public constructor(props) {
    super(props);

    this.state = {
      modifyStartDate: false,
      extendExpiryDate: false,
      startDate: new Date(),
      expiryDate: new Date(),
      showSnackbar: false,
      showFailureSnackbar: false,
      message: '',
      viewAllStores: false,
    };
  }

  public UNSAFE_componentWillMount() {
    const {
      account,
      match,
      extraStats,
      dispatchGetVoucher,
      dispatchGetTeammateDetails,
      dispatchGetVoucherStatistics,
      hasUserAllStoreAccess,
      teammate,
    } = this.props;

    if (match.params.voucherId) {
      dispatchGetVoucher(match.params.voucherId);
      if (extraStats) {
        dispatchGetVoucherStatistics(match.params.voucherId);
      }

      const userEmail = account?.Email;
      if (!hasUserAllStoreAccess && userEmail && teammate?.Email !== userEmail) {
        dispatchGetTeammateDetails(userEmail);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { match, dispatchGetVoucher, dispatchGetVoucherStatistics, voucher, extraStats } =
      newProps;

    if (this.props.match.params.voucherId != newProps.match.params.voucherId) {
      dispatchGetVoucher(match.params.voucherId);
      if (extraStats) {
        dispatchGetVoucherStatistics(match.params.voucherId);
      }
    }

    if (this.props.voucher != voucher) {
      this.setState({
        startDate: new Date(voucher.StartDate),
        expiryDate: new Date(voucher.ExpiryDate),
      });
    }
  }

  public componentDidUpdate(prevProps) {
    const { isSuccess, errorMessage, enqueueSnackbar } = this.props;
    if (!isEqual(prevProps.isSuccess, isSuccess) && isSuccess) {
      enqueueSnackbar('Success', {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'success',
        autoHideDuration: 1000,
      });
    }
    if (!isEqual(prevProps.errorMessage, errorMessage) && errorMessage) {
      enqueueSnackbar(errorMessage, {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }

  public handleUpdateVoucher = (voucher: VoucherType) => {
    const { dispatchUpdateVoucher, match } = this.props;
    let newVoucher = { ...voucher };

    if (voucher.VoucherType === CreateVoucher.VoucherTypeEnum.AddItem) {
      const updateVoucher = {
        ...voucher,
        PromotionAwardMenuItemPublicIds:
          voucher.PromotionDetails?.PromotionAwards?.map((award) => award.MenuItemPublicId || '') ||
          [],
        PromotionId: voucher.PromotionDetails?.PromotionId,
        PromotionName: voucher.PromotionDetails?.Name,
      };

      delete updateVoucher.PromotionDetails;

      newVoucher = updateVoucher;
    }

    dispatchUpdateVoucher(
      {
        voucherId: Number(match.params.voucherId),
        voucher: newVoucher,
        storeId: newVoucher.Stores,
      },
      true
    );
  };

  public toggleEnabled = () => {
    const { voucher } = this.props;
    const newVoucher = { ...voucher, IsEnabled: !voucher.IsEnabled };

    this.handleUpdateVoucher(newVoucher);
  };

  public toggleExtendExpiryDate = () => {
    this.setState({ extendExpiryDate: !this.state.extendExpiryDate });
  };

  public toggleModifyStartDate = () => {
    this.setState({ modifyStartDate: !this.state.modifyStartDate });
  };

  public handleStartDateChange = (date) => {
    const { voucher } = this.props;

    this.setState({ startDate: date, modifyStartDate: false }, () => {
      const newDate = moment(date).format('YYYY-MM-DDTHH:mm:SSZ');
      const newVoucher = { ...voucher, StartDate: newDate };

      this.handleUpdateVoucher(newVoucher);
    });
  };

  public handleExpiryDateChange = (date) => {
    const { voucher } = this.props;

    this.setState({ expiryDate: date, extendExpiryDate: false }, () => {
      const newDate = moment(date).format('YYYY-MM-DDTHH:mm:SSZ');
      const newVoucher = { ...voucher, ExpiryDate: newDate };

      this.handleUpdateVoucher(newVoucher);
    });
  };

  public render() {
    const {
      account,
      activeLanguage,
      canUserEditVoucher,
      classes,
      currentApp,
      translate,
      voucher,
      voucherLoading,
      voucherStatistics,
      voucherTimestamp,
    } = this.props;

    const { extendExpiryDate, expiryDate, startDate, modifyStartDate } = this.state;

    if (voucherTimestamp) {
      return (
        <div>
          <AppLoadingCircularProgress />
        </div>
      );
    }

    if (!voucher) {
      return null;
    }

    const typeIcon = (type) => {
      switch (type) {
        case 'PercentageDiscount':
          return <img src={PercentageDiscount} />;
        case 'LumpDiscount':
          return <AttachMoneyIcon data-fd="lump_discount_icon" style={{ color: '#fff' }} />;
        case 'AddItem':
          return <MenusIcon data-fd="menus-icon" className={classes.iconColour} />;
        case 'FreeDelivery':
          return <DeliveryIcon className={classes.iconColour} data-fd="freeDelivery-icon" />;
        case 'CreditNote':
          return <img src={CreditNote} />;
        default:
          return null;
      }
    };

    const appId = currentApp != null ? currentApp.AppId : 'global';

    const getRoute = () => {
      switch (voucher.VoucherType) {
        case 'PercentageDiscount':
          return `/${appId}/vouchers/${this.props.match.params.voucherId}/percentage`;
        case 'LumpDiscount':
          return `/${appId}/vouchers/${this.props.match.params.voucherId}/lump`;
        case 'AddItem':
          return `/${appId}/vouchers/${this.props.match.params.voucherId}/menuItem`;
        case 'FreeDelivery':
          return `/${appId}/vouchers/${this.props.match.params.voucherId}/freeDelivery`;
        default:
          return '';
      }
    };

    const lang = activeLanguage || account.Language || 'en';

    const dateTimeToLocaleDate = (dateTime: Date) => {
      const convertedDate = new Date(dateTime);
      const isSafari =
        navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent;

      let parsedDate = isSafari
        ? moment(convertedDate)
        : moment(moment(convertedDate).format('YYYY-MM-DDTHH:mm:ss[Z]'));

      parsedDate = parsedDate.locale(navigator.language);
      return parsedDate.format('L');
    };
    const startDateFormatted = dateTimeToLocaleDate(startDate);

    const inFuture = new Date(voucher.StartDate) > new Date();

    const isUsed = voucher.Status === 'Used';
    const isExpired = voucher.Status === 'Expired';

    const isCreditNote = voucher.VoucherType === 'CreditNote';

    const isEnabled = voucher.IsEnabled;

    let infoText = inFuture
      ? translate('Will_be_valid_from', { startDateFormatted: '' }) + startDateFormatted
      : translate('Valid_from', { startDateFormatted: '' }) + startDateFormatted;

    if (!isEnabled) {
      infoText = translate('Disabled') + ', ' + infoText;
    } else if (isUsed) {
      infoText = translate('Used') + ', ' + infoText;
    } else if (isExpired) {
      infoText = translate('Expired') as string;
    }

    const info = (
      <Grid container spacing={0} className={classes.infoContainer}>
        {inFuture ? (
          modifyStartDate ? (
            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      label: translate('Valid_from', { startDateFormatted: '' }),
                    },
                    popper: { style: { zIndex: 9999 } },
                  }}
                  value={startDate ? moment(startDate) : null}
                  onChange={this.handleStartDateChange}
                  format="L"
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            <Grid item sm={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Valid_from', { startDateFormatted: '' })}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {moment(startDate).format('L')}
              </Typography>
              {!isUsed && !voucherLoading ? (
                <Typography
                  variant="h6"
                  component="h6"
                  className={clsx(classes.category, classes.blue)}
                  onClick={this.toggleModifyStartDate}
                >
                  {translate('Modify')}
                </Typography>
              ) : null}
            </Grid>
          )
        ) : null}
        {extendExpiryDate ? (
          <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                    label: isExpired ? translate('Expired_on') : translate('Expires_on'),
                    inputProps: {
                      'data-fd': 'expires_on_pick_date',
                    },
                  },
                  popper: { style: { zIndex: 9999 } },
                }}
                disabled={!canUserEditVoucher}
                value={expiryDate ? moment(expiryDate) : null}
                onChange={this.handleExpiryDateChange}
                format="L"
              />
            </LocalizationProvider>
          </Grid>
        ) : (
          <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
            <Typography variant="h6" component="h6" className={classes.category}>
              {isExpired ? translate('Expired_on') : translate('Expires_on')}
            </Typography>
            <Typography
              variant="h4"
              component="h4"
              className={clsx(classes.number, isExpired ? classes.expiredNumber : null)}
            >
              {moment(expiryDate).format('L')}
            </Typography>

            {canUserEditVoucher && (
              <Permissions allowed={[App.AppResourceSetEnum.EditVouchers]}>
                <Typography
                  data-fd="extend_voucher"
                  variant="h6"
                  component="h6"
                  className={clsx(classes.category, classes.blue)}
                  onClick={this.toggleExtendExpiryDate}
                >
                  {translate('Extend')}
                </Typography>
              </Permissions>
            )}
          </Grid>
        )}

        {!inFuture && (
          <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Customers')}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {(voucher.TotalCustomers || 0).toLocaleString(lang)}
              </Typography>
            </Grid>
          </Permissions>
        )}

        {!inFuture && (
          <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Total_order_amount')}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {currencyFormatter(voucher.TotalAmountFromOrders || 0, lang, {
                  currency: voucher.Currency,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Grid>
          </Permissions>
        )}

        {!inFuture && isCreditNote && (
          <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Remaining_credit')}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {(voucher.CreditNoteDetails.RemainingCredit || 0).toLocaleString(lang, {
                  style: 'currency',
                  currency: voucher.Currency,
                })}
              </Typography>
            </Grid>
          </Permissions>
        )}

        {!inFuture && (
          <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
            {voucher.VoucherType !== 'AddItem' && (
              <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
                <Typography variant="h6" component="h6" className={classes.category}>
                  {translate('Total_discounted')}
                </Typography>
                <Typography variant="h4" component="h4" className={classes.number}>
                  {currencyFormatter(voucher.TotalDiscounted || 0, lang, {
                    currency: voucher.Currency,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Total_times_used')}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {(voucher.TotalUsed || 0).toLocaleString(lang)}
              </Typography>
            </Grid>

            <Grid item xs={6} md={isCreditNote ? 3 : 4} className={classes.voucherInfo}>
              <Typography variant="h6" component="h6" className={classes.category}>
                {translate('Average_order_size')}
              </Typography>
              <Typography variant="h4" component="h4" className={classes.number}>
                {currencyFormatter(voucher.AverageOrderSize || 0, lang, {
                  currency: voucher.Currency,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Grid>
          </Permissions>
        )}
      </Grid>
    );

    return (
      <PageLayout
        auditLogsFilter={{ type: 'VoucherCode', value: `${voucher.Code}` }}
        caption={voucher.Code}
        documentTitle="Voucher_code"
        title={translate('Voucher_code')}
        toParent={`/${currentApp.AppId}/vouchers`}
      >
        {!canUserEditVoucher && (
          <MessageBanner
            className={classes.banner}
            fdKey="cannot-edit-voucher-banner"
            icon={<InfoIcon color="#FFBC2C" />}
            message={<Typography variant="body2">{translate('cannot_edit_voucher')}</Typography>}
          />
        )}
        <PaperContainer className={classes.container}>
          <Grid container spacing={0} style={{ padding: 0 }}>
            <Grid item xs={12} className={classes.wrapper}>
              <Hidden mdDown>
                <div
                  className={clsx(
                    classes.type,
                    voucher.IsEnabled ? classes[`type${voucher.Status}`] : classes.typeDisabled
                  )}
                >
                  {typeIcon(voucher.VoucherType)}
                </div>
                <div className={classes.voucherContent}>
                  <div className={classes.voucherHeader}>
                    <div className={classes.voucherHeaderText}>
                      <Typography variant="h4" component="h4">
                        {voucher.Description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.subHeading}
                      >
                        {infoText}
                      </Typography>
                      {voucher.StoreNames && (
                        <StoreListTruncated
                          storeNames={voucher.StoreNames}
                          textPrefix={true}
                          variant={'body1'}
                          btnFontSize={'16px'}
                          minWidth={'large'}
                          color="textPrimary"
                        />
                      )}
                    </div>
                    <div>
                      {['LumpDiscount', 'PercentageDiscount', 'AddItem', 'FreeDelivery'].indexOf(
                        voucher.VoucherType
                      ) !== -1 && (
                        <Permissions allowed={[App.AppResourceSetEnum.EditVouchers]}>
                          <LinkButton
                            disabled={!canUserEditVoucher}
                            color="primary"
                            aria-label={translate('Edit') as string}
                            className={classes.editButton}
                            to={getRoute()}
                            fdKey="btn-edit-voucher"
                          >
                            {translate('Edit')}
                          </LinkButton>
                        </Permissions>
                      )}

                      <Permissions allowed={['Owner', 'ManagedOwner', 'EditVouchers']}>
                        <LoadingButton
                          buttonProps={{
                            color: isEnabled ? 'secondary' : 'primary',
                            onClick: this.toggleEnabled,
                            'data-fd': 'disable_voucher_button',
                            className: classes.button,
                            variant: 'contained',
                          }}
                          wrapperClassName={classes.button}
                          loadingResources={[vouchersConstants.SET_VOUCHER_LOADING]}
                          loading={voucherLoading}
                          disabled={voucherLoading || !canUserEditVoucher}
                        >
                          {isEnabled ? translate('Disable') : translate('Enable')}
                        </LoadingButton>
                      </Permissions>
                    </div>
                  </div>
                  {info}

                  {voucherStatistics.length > 0 && (
                    <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
                      <VoucherLineChart />
                    </Permissions>
                  )}
                </div>
              </Hidden>
              <Hidden mdUp>
                <FullWidthContainer>
                  <div
                    className={clsx(
                      classes.responsiveVoucherHeader,
                      HORIZONTAL_SPACE_CLASSNAME,
                      voucher.IsEnabled
                        ? classes[`responsiveVoucherHeader${voucher.Status}`]
                        : classes.responsiveVoucherHeaderDisabled
                    )}
                  >
                    <div className={classes.responsiveVoucherHeaderText}>
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.responsiveVoucherDesc}
                      >
                        {voucher.Description}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        className={classes.responsiveVoucherInfo}
                      >
                        {infoText}
                      </Typography>
                      {voucher.StoreNames && (
                        <StoreListTruncated
                          storeNames={voucher.StoreNames}
                          textPrefix={true}
                          btnFontSize={'16px'}
                          variant={'body1'}
                          minWidth={'large'}
                          color={'textPrimary'}
                        />
                      )}

                      <div>
                        {['LumpDiscount', 'PercentageDiscount', 'AddItem', 'FreeDelivery'].indexOf(
                          voucher.VoucherType
                        ) !== -1 && (
                          <Permissions allowed={[App.AppResourceSetEnum.EditVouchers]}>
                            <LinkButton
                              disabled={!canUserEditVoucher}
                              color="primary"
                              aria-label={translate('Edit') as string}
                              className={classes.editButton}
                              to={getRoute()}
                              fdKey="btn-edit-voucher"
                            >
                              {translate('Edit')}
                            </LinkButton>
                          </Permissions>
                        )}

                        <Permissions allowed={['Owner', 'ManagedOwner', 'EditVouchers']}>
                          <LoadingButton
                            buttonProps={{
                              color: isEnabled ? 'secondary' : 'primary',
                              onClick: this.toggleEnabled,
                              'data-fd': 'disable_voucher_button',
                              className: classes.button,
                              variant: 'contained',
                            }}
                            loadingResources={[vouchersConstants.SET_VOUCHER_LOADING]}
                            loading={voucherLoading}
                            disabled={voucherLoading || !canUserEditVoucher}
                          >
                            {isEnabled ? translate('Disable') : translate('Enable')}
                          </LoadingButton>
                        </Permissions>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.type,
                        voucher.IsEnabled ? classes[`type${voucher.Status}`] : classes.typeDisabled
                      )}
                    >
                      {typeIcon(voucher.VoucherType)}
                    </div>
                  </div>
                </FullWidthContainer>
                {info}

                {voucherStatistics.length > 0 && (
                  <Permissions allowed={[App.AppResourceSetEnum.ViewVouchersStatistics]}>
                    <FullWidthContainer>
                      <VoucherLineChart />
                    </FullWidthContainer>
                  </Permissions>
                )}
              </Hidden>
            </Grid>
          </Grid>
        </PaperContainer>
      </PageLayout>
    );
  }
}

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp, vouchers, locale, account, teammates } = state;

  const isSuccess = vouchers.addNew.request.success === true;
  const error = vouchers.addNew.request.error;
  const translate = getTranslate(locale);
  const errorMessage = error ? translate(error.message) : undefined;

  const extraStats = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.ViewVouchersStatistics.toString()
  );

  const hasUserAllStoreAccess =
    currentApp?.AppAccessLevel === App.AppAccessLevelEnum.Owner ||
    currentApp.AppAccessLevel === App.AppAccessLevelEnum.ManagedOwner;

  // storeIds null if hasAccessToAllStores is Truthy
  const hasAccessToEveryStoreOnVoucher =
    teammates.teammate?.HasAccessToAllStores ||
    vouchers.voucher?.Stores?.every((storeId) => teammates.teammate?.StoreIds?.includes(storeId));

  const canUserEditVoucher = hasUserAllStoreAccess || hasAccessToEveryStoreOnVoucher;

  return {
    account,
    activeLanguage: getActiveLanguage(locale),
    canUserEditVoucher,
    currentApp: currentApp,
    errorMessage,
    extraStats,
    hasUserAllStoreAccess,
    isSuccess,
    teammate: teammates.teammate,
    translate,
    voucher: vouchers.voucher,
    voucherLoading: vouchers.voucherLoading || teammates.teammateLoading,
    voucherStatistics: vouchers.voucherStatistics,
    voucherTimestamp: vouchers.voucherTimestamp,
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchUpdateVoucher: (voucher, throwError) =>
    dispatch(vouchersActions.updateVoucher(voucher, throwError)),
  dispatchGetVoucher: (voucherId: string) => dispatch(vouchersActions.getVoucher(voucherId)),
  dispatchGetVoucherStatistics: (voucherId: string) =>
    dispatch(vouchersActions.getVoucherStatistics(voucherId)),
  dispatchGetTeammateDetails: (email: string) => dispatch(getTeammateDetails(email)),
});

const EnhancedComponent = compose<any, any>(
  withRouter,
  withStyles(styles, {
    name: 'Voucher',
    withTheme: true,
  }),
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps)
)(VoucherDetails);

export { EnhancedComponent as VoucherDetails };
