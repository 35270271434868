import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { campaignActionsSignalR } from './actions';
import Campaigns from './Campaigns';

type Props = MappedDispatch & RouteComponentProps;

const CampaignsRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    match: { path },
    // subscribeCampaignUpdates,
    // unSubscribeCampaignUpdates,
  } = props;
  useEffect(() => {
    // subscribeCampaignUpdates();
    // return () => {
    //   unSubscribeCampaignUpdates();
    // };
  }, []);

  return (
    <ErrorBoundary identifier="campaigns">
      <Switch>
        <Route exact path={`${path}`} component={Campaigns} />
        <Redirect to={`${path}`} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  subscribeCampaignUpdates: () => dispatch(campaignActionsSignalR.subscribeCampaignUpdates()),
  unSubscribeCampaignUpdates: () => dispatch(campaignActionsSignalR.unSubscribeCampaignUpdates()),
});

export { CampaignsRoutes };
export default compose<Props, RouteComponentProps>(connect(null, mapDispatchToProps))(
  CampaignsRoutes
);
