import { OauthClientRedirectUri } from '@flipdish/api-client-typescript';

import { action, actionError } from '../../../actions/utils';
import { notify } from '../../../layouts/Notify/actions';
import { developersService } from '../../../services/developers.service';

// Region getRedirectUris
export const GET_OAUTHAPP_REDIRECT_URIS_REQUEST = 'GET_OAUTHAPP_REDIRECT_URIS_REQUEST';
export const GET_OAUTHAPP_REDIRECT_URIS_SUCCESS = 'GET_OAUTHAPP_REDIRECT_URIS_SUCCESS';
export const GET_OAUTHAPP_REDIRECT_URIS_FAILURE = 'GET_OAUTHAPP_REDIRECT_URIS_FAILURE';

export type GetOAuthAppRedirectUrisRequest = ReturnType<typeof getOAuthAppRedirectUrisRequest>;
export const getOAuthAppRedirectUrisRequest = (appId: string, clientId: string) =>
  action(GET_OAUTHAPP_REDIRECT_URIS_REQUEST, { appId, clientId, isLoading: true });

export type GetOAuthAppRedirectUrisSuccess = ReturnType<typeof getOAuthAppRedirectUrisSuccess>;
export const getOAuthAppRedirectUrisSuccess = (redirectUris: OauthClientRedirectUri[]) =>
  action(GET_OAUTHAPP_REDIRECT_URIS_SUCCESS, { redirectUris, isLoading: false });

export type GetOAuthAppRedirectUrisFailure = ReturnType<typeof getOAuthAppRedirectUrisFailure>;
export const getOAuthAppRedirectUrisFailure = (error: Error) =>
  actionError(GET_OAUTHAPP_REDIRECT_URIS_FAILURE, { isLoading: false }, { error });

export function getOAuthAppRedirectUris(clientId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppRedirectUrisRequest(appId, clientId));
      const result = await developersService.getOauthAppRedirectUris(clientId, appId);
      dispatch(getOAuthAppRedirectUrisSuccess(result));
    } catch (error) {
      dispatch(getOAuthAppRedirectUrisFailure(error));
    }
  };
}
// End region

// Region createRedirectUri
export const CREATE_REDIRECT_URI_REQUEST = 'CREATE_REDIRECT_URI_REQUEST';
export const CREATE_REDIRECT_URI_SUCCESS = 'CREATE_REDIRECT_URI_SUCCESS';
export const CREATE_REDIRECT_URI_FAILURE = 'CREATE_REDIRECT_URI_FAILURE';

export type CreateRedirectUriRequest = ReturnType<typeof createRedirectUriRequest>;
export const createRedirectUriRequest = (clientId: string, uri: string) =>
  action(CREATE_REDIRECT_URI_REQUEST, { clientId, uri, isLoading: true });

export type CreateRedirectUriSuccess = ReturnType<typeof createRedirectUriSuccess>;
export const createRedirectUriSuccess = (uri: OauthClientRedirectUri) =>
  action(CREATE_REDIRECT_URI_SUCCESS, { uri, isLoading: false });

export type CreateRedirectUriFailure = ReturnType<typeof createRedirectUriFailure>;
export const createRedirectUriFailure = (error: Error, clientId: string) =>
  action(CREATE_REDIRECT_URI_FAILURE, { clientId, isLoading: false }, { error });

export function createRedirectUri(clientId: string, uri: string, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(createRedirectUriRequest(clientId, uri));
      const result = await developersService.createRedirectUri(clientId, uri, appId);
      dispatch(createRedirectUriSuccess(result));
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(createRedirectUriFailure(error, clientId));
      if (throwError) {
        throw error;
      }
    }
  };
}
// End region

// Region deleteRedirectUri
export const DELETE_REDIRECT_URI_REQUEST = 'DELETE_REDIRECT_URI_REQUEST';
export const DELETE_REDIRECT_URI_SUCCESS = 'DELETE_REDIRECT_URI_SUCCESS';
export const DELETE_REDIRECT_URI_FAILURE = 'DELETE_REDIRECT_URI_FAILURE';

export type DeleteRedirectUriRequest = ReturnType<typeof deleteRedirectUriRequest>;
export const deleteRedirectUriRequest = (clientId: string, uriId: number, appId: string) =>
  action(DELETE_REDIRECT_URI_REQUEST, { clientId, uriId, appId, isLoading: true });

export type DeleteRedirectUriSuccess = ReturnType<typeof deleteRedirectUriSuccess>;
export const deleteRedirectUriSuccess = (uriId: number) =>
  action(DELETE_REDIRECT_URI_SUCCESS, { uriId, isLoading: false });

export type DeleteRedirectUriFailure = ReturnType<typeof deleteRedirectUriFailure>;
export const deleteRedirectUriFailure = (error: Error, clientId: string) =>
  action(DELETE_REDIRECT_URI_FAILURE, { clientId, isLoading: false }, { error });

export function deleteRedirectUri(clientId: string, uriId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(deleteRedirectUriRequest(clientId, uriId, appId));
      await developersService.deleteRedirectUri(clientId, uriId, appId);
      dispatch(deleteRedirectUriSuccess(uriId));
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(deleteRedirectUriFailure(error, clientId));
      dispatch(notify({ message: 'Failure', variant: 'error' }));
    }
  };
}
// End region
