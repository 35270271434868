import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Translate } from 'react-localize-redux';

type Props = {
  handleChange: (val: boolean) => void;
  val: boolean;
};

const NotifyCustomerControl = (props: Props) => {
  const { val, handleChange } = props;
  return (
    <FormControlLabel
      style={{ marginRight: 8 }}
      control={
        <Checkbox
          data-fd="refund_dialog_notify_customer"
          checked={val}
          onChange={(e) => {
            handleChange(e.target.checked);
          }}
          value="notifyCustomer"
          color="primary"
        />
      }
      label={<Translate id="Notify-customer" />}
    />
  );
};

export default NotifyCustomerControl;
