import React, { useCallback, useMemo, useState } from 'react';

import { BusinessHoursOverride } from '@flipdish/api-client-typescript';
import Typography from '@mui/material/Typography';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import * as actions from '../../../actions/storeOpeningHourOverrides.actions';
import { storeSelectors } from '../../../selectors';
import { DateTimeUtils, dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';

type RemoveDialogContentProps = {
  businessHoursOverride: BusinessHoursOverride | BusinessHoursOverride[];
  storeIanaTimeZone: string | undefined;
  dtUtils: DateTimeUtils;
};
const RemoveDialogContent = (props: RemoveDialogContentProps) => {
  const { businessHoursOverride, storeIanaTimeZone, dtUtils } = props;
  const bho = Array.isArray(businessHoursOverride)
    ? businessHoursOverride[0]
    : businessHoursOverride;

  const { from, to } = useMemo(() => {
    let from = '';
    let to = '';

    if (bho && bho.StartTime && bho.EndTime && storeIanaTimeZone) {
      const fromLocal = dtUtils.utcToZonedTime(bho.StartTime, storeIanaTimeZone);
      const toLocal = dtUtils.utcToZonedTime(bho.EndTime, storeIanaTimeZone);

      from = dtUtils.format(
        fromLocal,
        dtUtils.isToday(fromLocal) ? dtUtils.LOCAL_TIME_FORMAT : dtUtils.LOCAL_DATE_TIME_FORMAT
      );

      to = dtUtils.format(
        toLocal,
        dtUtils.isToday(toLocal) || dtUtils.isSameDay(toLocal, fromLocal)
          ? dtUtils.LOCAL_TIME_FORMAT
          : dtUtils.LOCAL_DATE_TIME_FORMAT
      );
    }

    return {
      from,
      to,
    };
  }, [bho, storeIanaTimeZone, dtUtils]);

  if (!bho) {
    return null;
  }

  return (
    <Typography>
      <Translate
        id={
          bho.Type === BusinessHoursOverride.TypeEnum.Closed
            ? 'The_store_is_closed_from'
            : 'The_store_is_open_from'
        }
        data={{
          from,
          to,
        }}
      />
    </Typography>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeId }: Props) => {
  return {
    translate: getTranslate(state.locale),
    storeIanaTimeZone: storeSelectors.timeZone(state, { storeId }),
    dtUtils: dateTimeUtils(state),
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: Props) => {
  return (dispatch, ownProps: Props) => {
    const { storeId } = ownProps;
    return {
      remove: (bho: BusinessHoursOverride) =>
        dispatch(actions.remove(Number(storeId), bho.BusinessHoursOverrideId as number, bho)),
    };
  };
};

export interface IQuickCloseSimpleDialog {
  open: boolean;
  storeId: number;
  businessHoursOverride: BusinessHoursOverride | BusinessHoursOverride[];
  onSave();
  onCancel();
}
type Props = IQuickCloseSimpleDialog & WithSnackbarProps;
const RemoveDialog = compose<Props & MappedState & MappedDispatch, IQuickCloseSimpleDialog>(
  setDisplayName('OpenCloseFormDialog'),
  withSnackbar,
  connect(mapStateToProps, mapDispatchToPropsFactory)
)((props) => {
  const {
    open,
    onCancel,
    enqueueSnackbar,
    translate,
    remove,
    onSave,
    businessHoursOverride,
    ...contentProps
  } = props;

  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (saving || !businessHoursOverride) {
      return;
    }

    setSaving(true);
    Promise.all(
      Array.isArray(businessHoursOverride)
        ? businessHoursOverride.map((b) => remove(b))
        : [remove(businessHoursOverride)]
    )
      .then(() => {
        enqueueSnackbar(translate('Override_removed') as string, {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'success',
        });
        setSaving(false);
        onSave();
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        enqueueSnackbar(err.message, {
          variant: 'error',
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        });
        setSaving(false);
      });
  }, [businessHoursOverride]);

  if (!businessHoursOverride) {
    return null;
  }

  return (
    <YesNoDialog
      open={open}
      onYes={handleSave}
      onNo={onCancel}
      yesTextId={'Remove'}
      titleTextId={'Remove_override_question'}
      disabled={saving}
    >
      <RemoveDialogContent businessHoursOverride={businessHoursOverride} {...contentProps} />
    </YesNoDialog>
  );
});

export default RemoveDialog;
