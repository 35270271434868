import { UsersApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const usersApi = createApi(UsersApiFactory);

async function getRoles() {
  try {
    const result = await usersApi.getRoles();
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const usersService = {
  getRoles,
};
