import React from 'react';

import Divider from '@mui/material/Divider';
import Grid, { type GridDirection } from '@mui/material/Grid';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Typography } from '../../atoms/Typography';

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  box: {
    padding: 0,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
  },
  contentContainer: {
    minHeight: (props) => props.height ?? '269px',
    maxHeight: (props) => props.height ?? '269px',
    overflow: 'auto',
  },
  list: {
    padding: (props) => (props.listPadding ? theme.spacing(props.listPadding) : 0),
  },
  noneMessageContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: (props) => props.height ?? '269px',
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export type ListBoxProps = {
  isEmpty: boolean;
  noItemsMessage: string;
  title?: string;
  renderListItems: () => JSX.Element[];
  customHeader?: JSX.Element;
  direction?: string;
  listPadding?: number;
  height?: string;
};

export type StyleProps = {
  listPadding?: number;
  height?: string;
};

export const ListBox = (props: ListBoxProps): JSX.Element => {
  const {
    title,
    isEmpty,
    noItemsMessage,
    renderListItems,
    direction = 'column',
    customHeader = null,
    listPadding = 0,
    height = undefined,
  } = props;

  const classes = useStyles({ listPadding: listPadding, height: height });

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Grid item xs={12} className={classes.noneMessageContainer}>
          <Typography variant="caption">{noItemsMessage}</Typography>
        </Grid>
      );
    }
    return (
      <Grid className={classes.contentContainer} item xs={12}>
        <List className={classes.list}>{renderListItems()}</List>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.box} direction={direction as GridDirection}>
      {customHeader}
      {!customHeader && title && (
        <Grid item xs={12}>
          <div className={classes.title}>
            <Typography variant="caption">{title}</Typography>
          </div>
          <Divider />
        </Grid>
      )}
      {renderContent()}
    </Grid>
  );
};
