import React from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingButton from '@fd/ui/LoadingButton';

import CustomerMessageField from './CustomerMessageField';
import SalesChannelSelector from './SalesChannelSelector';

// #region styles
const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingRight: 0,
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const StyledFieldItem = styled(Grid)(() => ({
  borderBottom: '1px solid rgba(0,0,0,0.2)',
}));

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-end',
  alignContent: 'center',
  padding: theme.spacing(1),
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));
// #endregion

export type FormValues = {
  collectionMessage: string;
  deliveryMessage: string;
  salesChannels?: Array<{ label: string; value: number; scId: string; storeGroupId: number }>;
};

export type CustomerMessageFormProps = {
  onSubmit: (values: FormValues) => Promise<void>;
} & FormValues;

const CustomerMessageDetailsForm = ({
  handleSubmit,
  isSubmitting,
}: CustomerMessageFormProps & FormikProps<FormValues>) => {
  return (
    <Form>
      <PaperContainer fluid>
        <StyledContainer container>
          <StyledFieldItem item xs={12}>
            <SalesChannelSelector />
          </StyledFieldItem>
          <StyledFieldItem item xs={12}>
            <CustomerMessageField type="delivery" />
          </StyledFieldItem>
          <StyledFieldItem item xs={12}>
            <CustomerMessageField type="collection" />
          </StyledFieldItem>
          <StyledButtonContainer container>
            <StyledLoadingButton
              fdKey="Save_customer_messages"
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              <Translate id={'Save'} />
            </StyledLoadingButton>
          </StyledButtonContainer>
        </StyledContainer>
      </PaperContainer>
    </Form>
  );
};

export default withFormik<CustomerMessageFormProps, FormValues>({
  displayName: 'CustomerMessageDetailsForm',
  mapPropsToValues: (props) => {
    return props;
  },
  handleSubmit: async (values, { props: { onSubmit }, setSubmitting }) => {
    try {
      await onSubmit(values);
    } catch (e) {
      setSubmitting(false);
    }
  },
})(CustomerMessageDetailsForm);
