import { createReducer } from 'redux-act';

import {
  getPayoutDetailsSummaryFailure,
  getPayoutDetailsSummaryRequest,
  getPayoutDetailsSummarySuccess,
  getPayoutSummaryDataFailure,
  getPayoutSummaryDataRequest,
  getPayoutSummaryDataSuccess,
} from './payouts.actions';
import { PayoutDetailSummary, PayoutSummaryItem } from './types';

const defaultState = {
  payoutSummaryLoading: true,
  payoutDetailsSummaryLoading: true,
  payoutTableSummary: [] as PayoutSummaryItem[] | undefined,
  payoutDetailSummary: {} as PayoutDetailSummary | undefined,
};

const reducer = createReducer({}, defaultState);

reducer.on(getPayoutSummaryDataRequest, (state) => ({
  ...state,
  payoutSummaryLoading: true,
}));

reducer.on(getPayoutSummaryDataSuccess, (state, payload) => ({
  ...state,
  payoutTableSummary: payload,
  payoutSummaryLoading: false,
}));

reducer.on(getPayoutSummaryDataFailure, (state) => ({
  ...state,
  payoutSummaryLoading: false,
}));

reducer.on(getPayoutDetailsSummaryRequest, (state) => ({
  ...state,
  payoutDetailsSummaryLoading: true,
}));

reducer.on(getPayoutDetailsSummarySuccess, (state, payload) => ({
  ...state,
  payoutDetailSummary: payload,
  payoutDetailsSummaryLoading: false,
}));

reducer.on(getPayoutDetailsSummaryFailure, (state) => ({
  ...state,
  payoutDetailsSummaryLoading: false,
}));

export default reducer;
