import React, { ReactNode } from 'react';
import { Router } from 'react-router-dom';
import { history } from '../helpers/store';

type Props = {
  children: ReactNode;
};

export default function BrowserRouter(props: Props) {
  const { children } = props;

  return <Router history={history}>{children}</Router>;
}
