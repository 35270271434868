import * as React from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { compose, setDisplayName, UnpackICEWP, withPropsOnChange } from 'recompose';

import { GMAP_API_KEY } from '../../../constants/map.constant';
import { mapStylesQuery } from '../../Auth/Onboarding/MapsStyles';
import MapCardMedia from './MapCardMedia';

const BaseMapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=330x180&scale=4&maptype=roadmap&key=${GMAP_API_KEY}`;
const mapStyles = mapStylesQuery();

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textDecoration: 'none',
  },
  content: {
    position: 'relative',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '30px',
    width: '100%',
    flexGrow: 1,
    minHeight: '100px',
  },
  nav: {
    position: 'absolute',
    right: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  caption: {
    marginTop: '2px',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

type MapData = UnpackICEWP<typeof usePropsOnChange>;
const usePropsOnChange = withPropsOnChange(['mapQueryProps'], ({ mapQueryProps }: Props) => {
  if (mapQueryProps) {
    return {
      mapUrl: `${BaseMapUrl}&${mapQueryProps}${mapStyles}`,
    };
  }
  return {
    mapUrl: undefined,
  };
});

const CardLink = React.forwardRef((props: any, ref) => {
  const { children, href, className, ...other } = props;
  return (
    // @ts-ignore
    <Link to={href} className={className} data-fd={other['data-fd']} innerRef={ref}>
      {children}
    </Link>
  );
});
export interface IMapCardProps {
  fdKey: string;
  title: string;
  href: string;
  caption?: React.ComponentType<React.PropsWithChildren<unknown>>;
  mapQueryProps?: string;
  emptyState?: { title: TranslationId; caption: TranslationId };
  children: React.ReactNode;
}

type Props = IMapCardProps;
const MapCard = compose<Props & MapData, IMapCardProps>(
  setDisplayName('StoreGroupCard'),
  usePropsOnChange
)(({ href, fdKey, title, children, mapUrl, emptyState }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={CardLink}
        href={href}
        className={classes.link}
        data-fd={fdKey}
        title={title}
      >
        {mapUrl ? (
          <CardMedia component="img" image={mapUrl} />
        ) : (
          <MapCardMedia
            title={emptyState && emptyState.title}
            caption={emptyState && emptyState.caption}
          />
        )}

        <CardContent className={classes.content}>
          <NavigateNextIcon className={classes.nav} />

          <Typography variant="h5">{title}</Typography>

          <Typography variant="caption" className={classes.caption}>
            {children}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

const MapCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Card>
      <MapCardMedia />
      <CardContent className={classes.content}>
        <Skeleton />
        <Skeleton />
      </CardContent>
    </Card>
  );
};

export { MapCardSkeleton };

export default MapCard;
