import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import { getSymbol as getCurrencySymbol } from '../../../../../helpers/currency';
import FormItemLayout from '../../FormItemLayout';

const validate = (value: number) => {
  if (value < 0) {
    return 'Value_cannot_be_less_than_0';
  }
  return;
};
const ValidOnOrdersOverField = () => (
  <Field name={'ValidOnOrdersOver'} validate={validate}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout label={<Translate id="Voucher_valid_limit" />} noSpace>
          <TextField
            {...field}
            autoComplete="off"
            disabled={isSubmitting}
            error={showError}
            fdKey={'valid-on-orders-over'}
            helperText={
              showError ? <Translate id={fieldError as 'Value_cannot_be_less_than_0'} /> : undefined
            }
            minWidth={200}
            inputProps={{
              min: 0,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getCurrencySymbol(form.values.Currency)}
                </InputAdornment>
              ),
            }}
            type="number"
            variant="outlined"
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default ValidOnOrdersOverField;
