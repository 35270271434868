import React from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { menusActions } from '../../../actions/menus.actions';
import InfoIcon from '../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../ui/Tooltip/Tooltip';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    dialog: {
      minWidth: '480px',
      [breakpoints.down('sm')]: {
        minWidth: '90vw',
      },
    },
    dialogHeader: {
      paddingTop: '24px',
      paddingBottom: '14px',
      '&>h2': {
        fontSize: '20px',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.25px',
      },
    },
    dialogContent: {
      paddingTop: 0,
      paddingRight: '12px',
    },
    dialogActions: {
      paddingBottom: '20px',
    },
    checkbox: {
      padding: '6px',
      marginLeft: '3px',
      marginRight: '3px',
    },
    checkboxText: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.43,
      letterSpacing: '0.25px',
      color: 'rgba(0,0,0,0.87)',
    },
    addTaxButton: {
      paddingLeft: 0,
      marginTop: '8px',
    },
    actionButton: {
      minWidth: '96px',
    },
    addIcon: {
      marginRight: '8px',
    },
    taxRateTextField: {
      flex: 1,
      justifyContent: 'center',
    },
    percentageTextField: {
      marginLeft: '16px',
      marginRight: '4px',
      width: '62px',
      justifyContent: 'center',
    },
    percentageIcon: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    taxRate: {
      marginBottom: '12px',
      alignItems: 'center',
    },
    displayTax: {
      marginBottom: '8px',
    },
    rightPadding: {
      paddingRight: '48px',
    },
    infoContent: {
      maxWidth: '280px',
    },
    infoHolder: {
      marginLeft: '12px',
      marginRight: '12px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    },
  });

export interface IDialogProps {
  showTaxDialog: boolean;
  toggleTaxDialog: () => any;
}

type StateProps = {
  menu: FlipdishAPI.Menu;
  menuTaxDetails: FlipdishAPI.MenuTaxDetails;
  menuTaxSavingTimestamp: number | null;
  translate: any;
};

type SnackbarProps = {
  enqueueSnackbar: any;
  closeSnackbar: any;
};

type Props = StateProps &
  IDialogProps &
  SnackbarProps &
  WithStyles<typeof styles> & { dispatch: ThunkDispatch };

type State = {
  newTaxRates: FlipdishAPI.MenuTaxRate[];
  existingTaxRates: FlipdishAPI.MenuTaxRate[];
  taxRatesToBeDeleted: number[];
  initialTaxType: FlipdishAPI.MenuTaxDetails.TaxTypeEnum | null;
  initialDisplayTax: boolean | null;
};

class MenuTaxRatesDialog extends React.Component<Props, State> {
  public state = {
    newTaxRates: [],
    existingTaxRates: [],
    taxRatesToBeDeleted: [],
    initialTaxType: null,
    initialDisplayTax: null,
  };

  private snackbar: any;

  public UNSAFE_componentWillMount() {
    const { menuTaxDetails } = this.props;
    const { existingTaxRates, initialTaxType, initialDisplayTax } = this.state;

    if (existingTaxRates.length === 0 && menuTaxDetails && menuTaxDetails.TaxRates) {
      this.setState({ existingTaxRates: menuTaxDetails.TaxRates });
    }

    if (menuTaxDetails && menuTaxDetails.TaxType && !initialTaxType) {
      this.setState({ initialTaxType: menuTaxDetails.TaxType });
    }

    if (
      menuTaxDetails &&
      typeof menuTaxDetails.DisplayTax != 'undefined' &&
      initialDisplayTax === null
    ) {
      this.setState({ initialDisplayTax: menuTaxDetails.DisplayTax });
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { menu, menuTaxDetails, showTaxDialog } = newProps;
    const { existingTaxRates, initialTaxType, initialDisplayTax } = this.state;

    // Close tax modal
    if (menu && !showTaxDialog && this.props.showTaxDialog) {
      // Timeout is to accomodate for the animation time it takes for the dialog to fade
      setTimeout(() => {
        this.setState({
          newTaxRates: [],
          existingTaxRates: [],
          taxRatesToBeDeleted: [],
          initialTaxType: null,
          initialDisplayTax: null,
        });
      }, 200);
    }

    // Open tax modal
    if (menu && showTaxDialog && !this.props.showTaxDialog) {
      newProps.dispatch(menusActions.getMenuTaxDetails(menu.MenuId || 0));

      if (existingTaxRates.length === 0 && menuTaxDetails && menuTaxDetails.TaxRates) {
        this.setState({ existingTaxRates: menuTaxDetails.TaxRates });
      }
    }

    if (menuTaxDetails && menuTaxDetails.TaxType && !initialTaxType) {
      this.setState({ initialTaxType: menuTaxDetails.TaxType });
    }

    if (
      menuTaxDetails &&
      typeof menuTaxDetails.DisplayTax != 'undefined' &&
      initialDisplayTax === null
    ) {
      this.setState({ initialDisplayTax: menuTaxDetails.DisplayTax });
    }

    if (
      this.state.existingTaxRates.length > 0 &&
      newProps.menuTaxDetails &&
      this.props.menuTaxDetails &&
      newProps.menuTaxDetails.TaxRates &&
      this.props.menuTaxDetails.TaxRates &&
      newProps.menuTaxDetails.TaxRates != this.props.menuTaxDetails.TaxRates
    ) {
      this.setState({
        existingTaxRates: newProps.menuTaxDetails.TaxRates,
        newTaxRates: [],
        taxRatesToBeDeleted: [],
      });
    }
  }

  public includeMenuTax = () => {
    const { dispatch, menuTaxDetails } = this.props;

    if (
      menuTaxDetails.TaxType === FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice &&
      !menuTaxDetails.DisplayTax
    ) {
      dispatch(menusActions.showMenuTax(true));
    }

    dispatch(
      menusActions.includeMenuTax(
        menuTaxDetails.TaxType === FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
          ? FlipdishAPI.MenuTaxDetails.TaxTypeEnum.ExcludedFromBasePrice
          : FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
      )
    );
  };

  public showMenuTax = () => {
    const { dispatch, menuTaxDetails } = this.props;

    dispatch(menusActions.showMenuTax(!menuTaxDetails.DisplayTax));
  };

  public addNewTax = () => {
    const newTaxRate: FlipdishAPI.MenuTaxRate = {
      TaxRateId: 0,
      Name: '',
      Rate: 0,
    };

    this.setState({
      newTaxRates: [...this.state.newTaxRates, newTaxRate],
    });
  };

  public deleteNewTax = (index: number) => {
    this.setState({
      newTaxRates: [
        ...this.state.newTaxRates.slice(0, index),
        ...this.state.newTaxRates.slice(index + 1),
      ],
    });
  };

  public handleNewTaxChange = (index: number, type: string, e: any) => {
    const inputText = e.target.value;

    if (type === 'Rate' && !/^\d*[.,]?\d{0,3}$/.test(inputText)) {
      return;
    }

    this.setState({
      newTaxRates: [
        ...this.state.newTaxRates.slice(0, index),
        {
          ...(this.state.newTaxRates[index] as FlipdishAPI.MenuTaxRate),
          [type]: inputText,
        },
        ...this.state.newTaxRates.slice(index + 1),
      ],
    });
  };

  public handleExistingTaxChange = (index: number, type: string, e: any) => {
    const inputText = e.target.value;

    if (type === 'Rate' && !/^\d*[.,]?\d{0,3}$/.test(inputText)) {
      return;
    }

    this.setState({
      existingTaxRates: [
        ...this.state.existingTaxRates.slice(0, index),
        {
          ...(this.state.existingTaxRates[index] as FlipdishAPI.MenuTaxRate),
          [type]: inputText,
        },
        ...this.state.existingTaxRates.slice(index + 1),
      ],
    });
  };

  public deleteExistingTax = (index: number, taxRateId: number) => {
    this.setState({
      existingTaxRates: [
        ...this.state.existingTaxRates.slice(0, index),
        ...this.state.existingTaxRates.slice(index + 1),
      ],
      taxRatesToBeDeleted: [...this.state.taxRatesToBeDeleted, taxRateId],
    });
  };

  public isValid = (): boolean => {
    const { existingTaxRates, newTaxRates } = this.state;

    for (const taxRate of [...existingTaxRates, ...newTaxRates]) {
      if ((taxRate as FlipdishAPI.MenuTaxRate).Name === '') {
        return false;
      }

      const percentage = ((taxRate as FlipdishAPI.MenuTaxRate).Rate || '').toString();

      if (percentage.charAt(percentage.length - 1) === '.') {
        return false;
      } else if (percentage.charAt(0) === '.') {
        return false;
      } else if (percentage === '') {
        return false;
      }
    }

    return true;
  };

  public closeTaxDialog = () => {
    this.props.toggleTaxDialog();
  };

  public saveTaxes = () => {
    const { dispatch, menu, enqueueSnackbar, closeSnackbar, translate, menuTaxDetails } =
      this.props;
    const {
      existingTaxRates,
      newTaxRates,
      taxRatesToBeDeleted,
      initialTaxType,
      initialDisplayTax,
    } = this.state;

    if (
      menu &&
      menu.MenuId &&
      menuTaxDetails.TaxType &&
      typeof menuTaxDetails.DisplayTax != 'undefined' &&
      menuTaxDetails.TaxRates
    ) {
      const newExistingTaxRates = [];

      for (let i = 0; i < existingTaxRates.length; i++) {
        if (!isEqual(existingTaxRates[i], menuTaxDetails.TaxRates[i])) {
          newExistingTaxRates.push(existingTaxRates[i]);
        }
      }

      this.snackbar = enqueueSnackbar(
        <span id="auto_save_notification">
          <CircularProgress size={20} style={{ color: '#fff', marginRight: '12px' }} />
          {translate('Auto_saving') + '...'}
        </span>,
        {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          persist: true,
        }
      );

      const successCallback = () => {
        closeSnackbar(this.snackbar);
        this.closeTaxDialog();
        this.snackbar = enqueueSnackbar(translate('Tax_rates_update_success'), {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000,
          resumeHideDuration: 1000,
        });
      };

      const failureCallback = () => {
        closeSnackbar(this.snackbar);
        this.snackbar = enqueueSnackbar(translate('Tax_rates_update_failure'), {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 3000,
          resumeHideDuration: 1000,
        });
      };

      dispatch(
        menusActions.addMenuTaxes(
          menu.MenuId,
          newTaxRates,
          newExistingTaxRates,
          taxRatesToBeDeleted,
          initialTaxType === menuTaxDetails.TaxType ? null : menuTaxDetails.TaxType,
          initialDisplayTax === menuTaxDetails.DisplayTax ? null : menuTaxDetails.DisplayTax,
          successCallback,
          failureCallback
        )
      );
    }
  };

  public inUse = (TaxRateId: number): boolean => {
    const { menuTaxDetails } = this.props;

    if (!menuTaxDetails) {
      return false;
    }

    for (const [, value] of Object.entries(menuTaxDetails.ItemTaxes as any)) {
      if (value === TaxRateId) {
        return true;
      }
    }

    for (const [, value] of Object.entries(menuTaxDetails.SetItemTaxes as any)) {
      if (value === TaxRateId) {
        return true;
      }
    }

    return false;
  };

  public currentlyAppliedTo = (TaxRateId: number): number => {
    const { menuTaxDetails } = this.props;

    if (!menuTaxDetails) {
      return 0;
    }

    let number = 0;

    for (const [, value] of Object.entries(menuTaxDetails.ItemTaxes as any)) {
      if (value === TaxRateId) {
        number += 1;
      }
    }

    for (const [, value] of Object.entries(menuTaxDetails.SetItemTaxes as any)) {
      if (value === TaxRateId) {
        number += 1;
      }
    }

    return number;
  };

  public render() {
    const {
      showTaxDialog,
      toggleTaxDialog,
      classes,
      menuTaxDetails,
      translate,
      menuTaxSavingTimestamp,
    } = this.props;
    const { existingTaxRates, newTaxRates } = this.state;

    if (!menuTaxDetails) {
      return null;
    }

    return (
      <Dialog
        open={showTaxDialog}
        onClose={toggleTaxDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{
            root: classes.dialogHeader,
          }}
        >
          {translate('Manage_tax_rates')}
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  data-fd="tax-in-price-checkbox"
                  checked={
                    menuTaxDetails.TaxType ===
                    FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
                  }
                  onChange={this.includeMenuTax}
                  classes={{ root: classes.checkbox }}
                  color="secondary"
                />
              }
              label={translate('Tax_included_price')}
              classes={{
                label: classes.checkboxText,
              }}
            />
          </FormGroup>
          <FormGroup row className={classes.displayTax}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuTaxDetails.DisplayTax}
                  onChange={this.showMenuTax}
                  classes={{ root: classes.checkbox }}
                  color="secondary"
                />
              }
              label={translate('Display_tax_checkout')}
              classes={{
                label: classes.checkboxText,
              }}
              disabled={
                menuTaxDetails.TaxType != FlipdishAPI.MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
              }
            />
          </FormGroup>
          {existingTaxRates.map((taxRate: FlipdishAPI.MenuTaxRate, index) => (
            <FormGroup row className={classes.taxRate} key={taxRate.TaxRateId}>
              <TextField
                variant="standard"
                value={taxRate.Name}
                className={classes.taxRateTextField}
                placeholder={translate('Tax_rate_name')}
                onChange={this.handleExistingTaxChange.bind(this, index, 'Name')}
                aria-describedby="tax_rate_tooltip"
              />
              <TextField
                variant="standard"
                value={taxRate.Rate}
                className={classes.percentageTextField}
                InputProps={{
                  endAdornment: <span className={classes.percentageIcon}>%</span>,
                }}
                onChange={this.handleExistingTaxChange.bind(this, index, 'Rate')}
              />
              {!this.inUse(taxRate.TaxRateId || 0) ? (
                <IconButton onClick={this.deleteExistingTax.bind(this, index, taxRate.TaxRateId)}>
                  <DeleteIcon />
                </IconButton>
              ) : null}
              {this.inUse(taxRate.TaxRateId || 0) ? (
                <div className={classes.infoHolder}>
                  <Tooltip
                    id="tax_rate_tooltip"
                    fdKey="tax_rate_tooltip"
                    messageId={'Currently_applied_to'}
                    messageData={{
                      number: this.currentlyAppliedTo(taxRate.TaxRateId || 0).toString(),
                    }}
                  >
                    <div>
                      <InfoIcon size={20} />
                    </div>
                  </Tooltip>
                </div>
              ) : null}
            </FormGroup>
          ))}
          {newTaxRates.map((newTaxRate: FlipdishAPI.MenuTaxRate, index: number) => (
            <FormGroup row className={classes.taxRate} key={`new-tax-rate-${index}`}>
              <TextField
                variant="standard"
                value={newTaxRate.Name}
                className={classes.taxRateTextField}
                placeholder={translate('Tax_rate_name')}
                onChange={this.handleNewTaxChange.bind(this, index, 'Name')}
              />
              <TextField
                variant="standard"
                value={newTaxRate.Rate}
                className={classes.percentageTextField}
                onChange={this.handleNewTaxChange.bind(this, index, 'Rate')}
                InputProps={{
                  endAdornment: <span className={classes.percentageIcon}>%</span>,
                }}
              />
              <IconButton onClick={this.deleteNewTax.bind(this, index)}>
                <DeleteIcon />
              </IconButton>
            </FormGroup>
          ))}
          <Button
            color="primary"
            classes={{
              root: classes.addTaxButton,
            }}
            onClick={this.addNewTax}
          >
            <AddIcon className={classes.addIcon} />
            {translate('Add_tax_rate')}
          </Button>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            onClick={this.closeTaxDialog}
            color="primary"
            classes={{
              root: classes.actionButton,
            }}
          >
            {translate('Cancel')}
          </Button>
          <Button
            color="primary"
            disabled={!this.isValid() || menuTaxSavingTimestamp != null}
            onClick={this.saveTaxes}
            classes={{
              root: classes.actionButton,
            }}
          >
            {translate('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state: AppState) {
  const { menus, locale } = state;

  return {
    translate: getTranslate(locale),
    menuTaxDetails: menus.menuTaxDetails,
    menu: menus.menu,
    menuTaxSavingTimestamp: menus.menuTaxSavingTimestamp,
  };
}

const EnhancedComponent = compose<{}, IDialogProps>(
  withStyles(styles, {
    name: 'MenuTaxRatesDialog',
    withTheme: true,
  }),
  withSnackbar,
  connect(mapStateToProps)
)(MenuTaxRatesDialog);

export { EnhancedComponent as MenuTaxRatesDialog };
