export enum hubConstants {
  SIGNALR_SUBSCRIBE = 'SIGNALR_SUBSCRIBE',
  SIGNALR_UNSUBSCRIBE = 'SIGNALR_UNSUBSCRIBE',
  SIGNALR_SUBSCRIBED = 'SIGNALR_SUBSCRIBED',
  SIGNALR_UNSUBSCRIBED = 'SIGNALR_UNSUBSCRIBED',
}

export enum hubNames {
  ACCOUNT_HUB = 'AccountHub',
  ANALYTICS_HUB = 'AnalyticsHub',
  BANK_HUB = 'BankAccountHub',
  CAMPAIGN_HUB = 'CampaignHub',
  CUSTOMER_HUB = 'CustomerHub',
  KIOSK_CARDREADER_HUB = 'CardReaderHub',
  ORDER_HUB = 'OrderHub',
  PHONECALL_HUB = 'PhoneCallHub',
  STORE_HUB = 'StoreHub',
  STORE_GROUP_HUB = 'StoreGroupHub',
  TEAMMATE_HUB = 'TeammateHub',
  VOUCHER_HUB = 'VoucherHub',
}
