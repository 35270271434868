import React from 'react';

import { Route } from 'react-router';

import CSVExport from './CSVExport';

type CSVRouteProps = {
  downloadCSVAction: (
    Guid: string
  ) => (dispatch: ThunkDispatch, getState: () => AppState) => Promise<string | void>;
  path: string;
  redirectURL: string;
};
const CSVRoute = ({ downloadCSVAction, path, redirectURL }: CSVRouteProps) => {
  return (
    <Route
      exact
      path={path}
      render={(routeProps) => (
        //@ts-ignore
        <CSVExport
          downloadCSVAction={downloadCSVAction}
          redirectURL={redirectURL}
          {...routeProps}
        />
      )}
    />
  );
};

export default CSVRoute;
