import React, { useEffect, useMemo, useState } from 'react';

import { App, OnboardingItemUpdate, StoreAddress } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { accountActions } from '../../actions/account.actions';
import { menusActions } from '../../actions/menus.actions';
import { assignMenuToStore, storeActions, updateAddressSuccess } from '../../actions/store.actions';
import { menusConstants } from '../../constants/menus.constants';
import * as storeConstants from '../../constants/store.constants';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../layouts/Notify/actions';
import { getSupportedCountry } from '../../selectors/account.selector';
import { appMapCenterSelector } from '../../selectors/app.selector';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import { addressService } from '../../services/address.service';
import { useTracking } from '../../services/amplitude/useTracking';
import { flagService } from '../../services/flagService';
import { storeService } from '../../services/store.service';
import PageLayout from '../../ui/Layout';
import GridContainer from '../../ui/Layout/GridContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import { DynamicAddressForm, getInternationalisedAddressFields } from '../DynamicAddress';
import { CountrySelector } from '../DynamicAddress/Components/CountrySelector/CountrySelector';
import useTutorialStore from '../Tutorial/hooks/useTutorialStore';
import { TutorialNotifier } from '../Tutorial/Notifier';
import StoreAddressEditor from './components/StoreAddressEditor';
import StoreGroupCurrencyEditor from './components/StoreGroupCurrencyEditor';
import StoreLocationMap from './components/StoreLocationMap';
import StorePhoneEditor from './components/StorePhoneEditor';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const menusLoadingSelector = createLoadingSelector([menusConstants.GET_MENUS]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const maxWidth = '718px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: maxWidth,
      [theme.breakpoints.down('md')]: {
        margin: '0px',
        width: '100%',
        maxWidth: '100%',
        boxShadow: 'none',
      },
    },
    item: {
      padding: '16px 0px',
      minHeight: '24px',
    },
    mapItem: {
      minHeight: '240px',
    },
    switch: {
      height: '24px',
      '&>:first-child': {
        height: '24px',
      },
    },
    textField: {
      height: '40px',
      margin: '5px',
      padding: '0px 16px 0px 0px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    grey: {
      color: 'rgb(0, 0, 0, 0.6)',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    gridItem: {
      padding: `${theme.spacing(1.5)}!important`,
      [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
    },
  })
);

type Props = ReturnType<typeof mapStateProps> & ReturnType<typeof mapDispatchToProps>;

const StoreGeneralSettings = (props: Props) => {
  const {
    assignMenu,
    canEdit,
    defaultMapCenter,
    dispatchCloseNotifySaving,
    dispatchNotifyError,
    dispatchNotifySaved,
    dispatchNotifySaving,
    dispatchUpdateAddressSuccess,
    getMenus,
    getSupportedCountriesList,
    internationalisedAddressConfig,
    language,
    loading,
    loadingError,
    loadStore,
    menus,
    selectedApp,
    showCurrencyEditor,
    store,
    supportedCountries,
    translate,
  } = props;
  const [countryCode, setCountryCode] = useState(store?.Address?.CountryCode || '');
  const [supportedCountry, setSupportedCountry] = useState<{ [key: string]: any } | undefined>(
    undefined
  );
  const { storeId, storeGroupId } = useParams<{
    appId: string;
    storeGroupId: string;
    storeId: string;
  }>();
  const useDynamicAddressFields =
    internationalisedAddressConfig?.affectedCountries?.includes(countryCode);

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_generalSettings', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (countryCode && supportedCountries) {
      const supportedCountryResult = getSupportedCountry(supportedCountries, countryCode);
      setSupportedCountry(supportedCountryResult);
    }
  }, [countryCode, supportedCountries]);

  useEffect(() => {
    if (!store) {
      loadStore(Number(storeId));
    }
  }, [store, storeId]);

  useEffect(() => {
    if (store?.Address?.CountryCode) {
      setCountryCode(store?.Address?.CountryCode);
    }
  }, [store]);

  const { data, isPending, isFetching, error } = useQuery({
    queryKey: [addressService.getFormByCountryQueryKey, countryCode],
    queryFn: () => addressService.getFormByCountry(countryCode, language),
    enabled: useDynamicAddressFields,
    retry: false,
  });

  const {
    data: countries,
    isPending: loadingCountries,
    isFetching: fetchingCountries,
    isError: countriesError,
  } = useQuery({
    queryKey: [addressService.getCountriesQueryKey, language],
    queryFn: () => addressService.getCountries(language),
  });

  useEffect(() => {
    if (countriesError) {
      dispatchNotifyError({ message: 'Something_went_wrong' });
    }
  }, [countriesError]);

  const classes = useStyles();
  const [assignedMenuId, setAssignedMenuId] = useState<number>(
    store && store.MenuId ? store.MenuId : 0
  );

  const loadingDependencies = loading || !store;
  const menuOptions = useMemo(() => {
    return menus.map((menu) => ({
      value: menu.MenuId,
      label: menu.Name || menu.MenuId,
    }));
  }, [menus]);

  let tutorialStoreId = useTutorialStore(selectedApp.AppId).data?.StoreId;
  tutorialStoreId = store?.StoreId ? store.StoreId : tutorialStoreId;

  const checkStoreDetails = () => {
    if (store) {
      const hasAddress = store.Address?.Line1 !== '';
      const hasCity = store.Address?.City !== '';
      const hasPostCode = store.Address?.Postcode !== '';
      const hasPhoneNumber = store.PhoneNumber !== null;

      return hasAddress && hasCity && hasPostCode && hasPhoneNumber;
    } else {
      return false;
    }
  };
  const storeDetailsComplete = checkStoreDetails();

  useEffect(() => {
    getSupportedCountriesList();
  }, []);

  useEffect(() => {
    getMenus(selectedApp.AppId as string);
  }, []);
  useEffect(() => {
    if (store && store.MenuId) {
      setAssignedMenuId(store.MenuId);
    }
  }, [store]);

  const { mutateAsync } = useMutation({
    mutationFn: (addressFields: { [key: string]: any }) => {
      dispatchNotifySaving();
      return storeService.updateAddressForm(store?.StoreId ?? 0, {
        AddressFields: addressFields,
        CountryCode: countryCode,
      });
    },

    onSuccess: (address) => {
      dispatchUpdateAddressSuccess(
        storeId as unknown as number,
        storeGroupId as unknown as number,
        address
      );
      dispatchCloseNotifySaving();
      dispatchNotifySaved();
    },

    onError: () => {
      dispatchCloseNotifySaving();
      dispatchNotifyError({ message: 'Something_went_wrong' });
    },
  });

  if (loadingError) {
    return <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />;
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await mutateAsync(values);
    } catch (error) {
      // error is "caught" here first and then the onError code in the useMutation hook is executed
    } finally {
      setSubmitting(false);
    }
  };

  const handleCountrySelectorChange = (country) => {
    setCountryCode(country);
  };

  const internationalisedValues = getInternationalisedAddressFields(
    store?.Address,
    data?.CountryCode ?? countryCode
  );

  const renderAddressSection = () => {
    const errorCast = error as any;
    const isError = errorCast && errorCast?.name !== 'NotFound';
    let addressForm;

    if (store?.StoreGroupId && store?.StoreId && countryCode) {
      addressForm = !useDynamicAddressFields ? (
        <StoreAddressEditor
          storeGroupId={store.StoreGroupId}
          storeId={store.StoreId}
          countryCode={countryCode}
          disabled={!canEdit}
          supportedCountry={supportedCountry}
        />
      ) : (
        <DynamicAddressForm
          form={data?.FormData}
          initialValues={internationalisedValues}
          loading={isPending || isFetching || loadingCountries || fetchingCountries}
          error={isError || countriesError}
          translate={translate}
          handleSubmit={handleSubmit}
          disabled={!canEdit}
        />
      );
    }

    return (
      <>
        <CountrySelector
          loading={loadingCountries || fetchingCountries}
          translate={translate}
          disabled={!canEdit}
          countries={
            countries?.map((country) => ({
              label: country?.Label,
              value: country?.Value,
            })) || []
          }
          countryCode={countryCode}
          handleChange={handleCountrySelectorChange}
        />
        {addressForm}
      </>
    );
  };

  return (
    <PageLayout
      toParent={
        store
          ? `/${selectedApp.AppId}/storegroups/${store.StoreGroupId}/${store.StoreId}`
          : undefined
      }
      title={<Translate id="Store_general_settings_title" />}
      caption={(store && store.Name) || ''}
    >
      {loadingDependencies ? (
        <CircularProgress style={{ margin: '10px' }} size={50} />
      ) : store ? (
        <PaperContainer>
          <GridContainer direction="row" justifyContent="space-between" className={classes.item}>
            <Grid item xs={12} sm={5} className={classes.gridItem}>
              <InputLabel htmlFor="stores-dropdown" style={{ transform: 'initial' }}>
                <Translate id="Store_address" />
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={7} className={classes.gridItem}>
              {renderAddressSection()}
            </Grid>
          </GridContainer>
          {storeDetailsComplete && tutorialStoreId && (
            <TutorialNotifier
              storeId={tutorialStoreId}
              onboardingItemId={101}
              status={'Completed' as OnboardingItemUpdate}
            />
          )}

          <GridContainer direction="row" justifyContent="space-between" className={classes.item}>
            {store.StoreId ? (
              <>
                <Grid item xs={12} className={classes.gridItem}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={5} className={classes.gridItem}>
                  <Typography variant={'body1'} className={classes.grey}>
                    <Translate id="Map_location" />
                  </Typography>
                </Grid>
                <Grid item xs={7} className={clsx(classes.alignSelfCenter, classes.gridItem)}>
                  <Typography variant={'caption'}>
                    <Translate id="Choose_the_exact_location_your_store_is_on_a_map" />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={clsx(classes.mapItem, classes.gridItem)}>
                  <StoreLocationMap
                    storeId={store.StoreId}
                    defaultMapCenter={defaultMapCenter}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Divider />
                </Grid>
              </>
            ) : null}
          </GridContainer>

          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.item}
          >
            <Grid item xs={12} sm={5} className={classes.gridItem}>
              <InputLabel htmlFor="PhoneNumber">
                <Translate id="Store_phone" />
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={7} className={classes.gridItem}>
              {store.StoreGroupId && store.StoreId ? (
                <StorePhoneEditor
                  key={store.PhoneNumber}
                  storeGroupId={store.StoreGroupId}
                  storeId={store.StoreId}
                  className={classes.textField}
                  disabled={!canEdit}
                />
              ) : null}
            </Grid>
          </GridContainer>

          {selectedApp.AppAccessLevel === App.AppAccessLevelEnum.Owner && (
            <>
              <Divider />
              <GridContainer
                container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel>
                    <Translate id="Menu" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  {store ? (
                    <FormControl fullWidth>
                      <Select
                        variant="standard"
                        value={assignedMenuId}
                        onChange={(e) => {
                          if (store.StoreId) {
                            const value = e.target.value as number;

                            setAssignedMenuId(value);
                            assignMenu(store.StoreId, value, store.Name);
                          }
                        }}
                      >
                        {menuOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                </Grid>
              </GridContainer>
            </>
          )}

          {showCurrencyEditor && (
            <>
              <Divider />

              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel htmlFor="Currency">
                    <Translate id="Store_currency" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  {store.StoreGroupId ? (
                    <StoreGroupCurrencyEditor
                      storeGroupId={store.StoreGroupId}
                      disabled={!canEdit}
                    />
                  ) : null}
                </Grid>
              </GridContainer>
            </>
          )}

          <Divider />

          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.item}
          >
            <Grid item xs={12} sm={5} className={classes.gridItem}>
              <InputLabel>
                <Translate id="Store_timezone" />
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={7} className={classes.gridItem}>
              <Typography
                className={classes.textField}
                style={{ lineHeight: '40px', marginLeft: 0 }}
              >
                {store.MicrosoftTimeZone}
              </Typography>
            </Grid>
          </GridContainer>
        </PaperContainer>
      ) : null}
    </PageLayout>
  );
};

const mapStateProps = (state: AppState) => {
  const store = getSelectedStore(state);
  const showCurrencyEditor = state.stores.totalCount === 1;
  const defaultMapCenter = appMapCenterSelector(state);
  const canEdit = state.permissions.some((p) => p === App.AppResourceSetEnum.EditStores.toString());
  const supportedCountries = state.account.supportedCountries;
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  return {
    canEdit,
    defaultMapCenter,
    internationalisedAddressConfig,
    language: state.account?.Language,
    loading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    menus: state.menus.menus,
    menusLoading: menusLoadingSelector(state),
    selectedApp: state.currentApp,
    showCurrencyEditor,
    store,
    supportedCountries,
    translate: getTranslate(state.locale),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getSupportedCountriesList: () => {
    dispatch(accountActions.getSupportedCountries());
  },
  getMenus: (appId: string) => dispatch(menusActions.getMenus(appId)),
  assignMenu: debounce(
    (storeId: number, menuId: number, storeName: string | undefined) =>
      dispatch(assignMenuToStore(storeId, menuId, storeName)),
    1000
  ),
  loadStore: (storeId: number) => {
    dispatch(storeActions.load(storeId));
  },
  dispatchUpdateAddressSuccess: (storeId: number, storeGroupId: number, address: StoreAddress) =>
    dispatch(updateAddressSuccess(storeId, storeGroupId, address)),
  dispatchCloseNotifySaving: () => dispatch(closeNotifySaving()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
  dispatchNotifySaving: () => dispatch(notifySaving({ persist: true })),
});

const EnhancedComponent = compose<Props, {}>(
  setDisplayName('StoreGeneralSettings'),
  connect(mapStateProps, mapDispatchToProps)
)(StoreGeneralSettings);

export default EnhancedComponent;
