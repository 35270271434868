import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms';

import { IDeliveryZone } from '../types';
import { DeleteDeliveryZoneDialog } from './DeleteDeliveryZoneDialog';

const useStyles = makeStyles((theme: Theme) => ({
  listItemIcon: {
    marginRight: theme.spacing(1.25),
  },
  listItemText: {
    fontSize: theme.spacing(1.75),
    letterSpacing: '0.3px',
    lineHeight: '1.43',
  },
  icon: {
    fontSize: theme.spacing(2),
  },
}));

export type MoreMenuProps = {
  className?: string;
  disabled?: boolean;
  index: number;
  onChange: (changes: IDeliveryZone) => void;
  onRemove: (deliveryZoneId: number) => void;
  translate: TranslateFunction;
  visible: boolean;
  zone: Required<IDeliveryZone>;
};

const MoreMenu = (props: MoreMenuProps) => {
  const { className, disabled, index, onChange, onRemove, translate, visible, zone } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleRemove = () => {
    setAnchorEl(undefined);
    setShowConfirmDelete(true);
  };

  const handleRemoveAccepted = () => {
    setAnchorEl(undefined);
    setShowConfirmDelete(false);
    onRemove(zone.Id);
  };

  const handleRemoveCanceled = () => {
    setShowConfirmDelete(false);
  };

  const handleDisable = () => {
    onChange({ ...zone, IsEnabled: !zone.IsEnabled });
    handleClose();
  };

  const renderMenuContent = () => {
    const open = Boolean(anchorEl);
    if (visible && !disabled) {
      return (
        <>
          <IconButton
            aria-label={translate('More') as string}
            aria-owns={open ? 'more-menu' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disabled={zone.Id < 0}
            data-fd="delivery_zones_form_list_item_menu"
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu id="more-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              key="Disable"
              dense
              onClick={handleDisable}
              data-fd="delivery_zones_form_list_item_menu_disable"
            >
              <ListItemIcon className={classes.listItemIcon}>
                {!zone.IsEnabled ? (
                  <VisibilityIcon className={classes.icon} fontSize="inherit" />
                ) : (
                  <VisibilityOffIcon className={classes.icon} fontSize="inherit" />
                )}
              </ListItemIcon>
              <Typography className={classes.listItemText} variant="inherit">
                <Translate>
                  {(translate) => translate(zone.IsEnabled ? 'Disable' : 'Enable')}
                </Translate>
              </Typography>
            </MenuItem>
            <MenuItem
              key="Delete"
              dense
              onClick={handleRemove}
              data-fd="delivery_zones_form_list_item_menu_delete"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon className={classes.icon} fontSize="small" />
              </ListItemIcon>
              <Typography className={classes.listItemText} variant="inherit">
                <Translate id="Delete" />
              </Typography>
            </MenuItem>
          </Menu>
        </>
      );
    }
    return null;
  };

  return (
    <div className={className}>
      {renderMenuContent()}
      <DeleteDeliveryZoneDialog
        open={showConfirmDelete}
        onCancel={handleRemoveCanceled}
        onConfirm={handleRemoveAccepted}
        zoneNumber={index}
        translate={translate}
      />
    </div>
  );
};

export default MoreMenu;
