import React from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from 'react-loading-skeleton';

const listSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const OrdersListLoading = () => {
  return (
    <List
      style={{
        minWidth: 400,
        height: '100vh',
        overflow: 'scroll',
        padding: '5, 0, 51, 0',
      }}
    >
      {listSkeletons.map((val: number) => (
        <ListItem button alignItems="flex-start" key={val}>
          <Grid container spacing={0}>
            <Grid item md={2}>
              <Skeleton circle={true} height={50} width={50} />
            </Grid>
            <Grid item md={10}>
              <div>
                <Skeleton width={'100%'} height={10} />
              </div>
              <div>
                <Skeleton width={'60%'} height={10} />
              </div>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default OrdersListLoading;
