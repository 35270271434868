import React, { useEffect, useState } from 'react';

import Link from '@mui/material/Link';
import { connect } from 'react-redux';

type AuditLogsWrapperProps = {
  auditLogsFilter?: { type: string; value: string };
  children?: React.ReactNode;
};

type Props = MappedState & MappedDispatch & AuditLogsWrapperProps;

const AuditLogsWrapper = (props: Props) => {
  const { appId, children, auditLogsFilter } = props;

  const [filterEventType, setFilterEventType] = useState<string>('');

  useEffect(() => {
    if (auditLogsFilter?.type) {
      setFilterEventType(auditLogsFilter.type);
    }
  }, [auditLogsFilter?.type]);

  return (
    <div>
      <Link
        underline="none"
        data-fd={`Audit_logs_${auditLogsFilter?.value}`}
        href={`/${appId}/logs?page=1&limit=25&filter[${filterEventType}]=${auditLogsFilter?.value}`}
      >
        {children}
      </Link>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  return {
    appId: state.currentApp.AppId!,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsWrapper);
