import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles(() => ({
  boxContents: {
    marginTop: '24px',
    height: '236px',
  },
  emptyMessageTitle: {
    fontWeight: 'bold',
  },
}));

export const AppStoreAppsListNoResults = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.boxContents}>
      <Typography className={classes.emptyMessageTitle}>
        <Translate id="No_Matching_Apps_Title" />
      </Typography>
      <Typography variant="caption">
        <Translate id="No_Matching_Apps_Message" />
      </Typography>
    </Box>
  );
};
