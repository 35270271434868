import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { LoadingButton } from '../../../ui/LoadingButton';

type Props = {
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  submit: (changes) => void;
  isLoading: boolean | undefined;
  title: TranslationId;
  description: TranslationId;
  submitText: TranslationId;
};

export default function ({
  isDialogOpen,
  setDialogOpen,
  title,
  description,
  submitText,
  submit,
  isLoading,
}: Props) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent key="dialog-content">
        <Typography variant="h6">
          <Translate id={title} />
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <Translate id={description} />
        </Typography>
      </DialogContent>
      <DialogActions key="actions">
        <Button color="primary" data-fd="Delete_cancel" onClick={() => setDialogOpen(false)}>
          <Translate id="Cancel" />
        </Button>
        <LoadingButton
          color="primary"
          fdKey="Delete_confirm"
          onClick={submit}
          disabled={isLoading}
          loading={isLoading}
        >
          <Translate id={submitText} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
