export const appsConstants = {
  SELECTED_APP: 'SELECTED_APP',
  SELECTED_APP_CHANGED: 'SELECTED_APP_SUCCESS',
  SET_APP_PANACEA_VANITY_URL: 'SET_APP_PANACEA_VANITY_URL',
  SET_APP_PANACEA_VANITY_URL_REQUEST: 'SET_APP_PANACEA_VANITY_URL_REQUEST',
  SET_APP_PANACEA_VANITY_URL_SUCCESS: 'SET_APP_PANACEA_VANITY_URL_SUCCESS',
  SET_APP_PANACEA_VANITY_URL_FAILURE: 'SET_APP_PANACEA_VANITY_URL_FAILURE',
  GET_APP_PANACEA_VANITY_URL: 'GET_APP_PANACEA_VANITY_URL',
  GET_APP_PANACEA_VANITY_URL_REQUEST: 'GET_APP_PANACEA_VANITY_URL_REQUEST',
  GET_APP_PANACEA_VANITY_URL_SUCCESS: 'GET_APP_PANACEA_VANITY_URL_SUCCESS',
  GET_APP_PANACEA_VANITY_URL_FAILURE: 'GET_APP_PANACEA_VANITY_URL_FAILURE',
  GET_APPS: 'GET_APPS',
  GET_APPS_SUCCESS: 'GET_APPS_SUCCESS',
  GET_APPS_SUCCESS_CONCAT: 'GET_APPS_SUCCESS_CONCAT',
  GET_APPS_FAILURE: 'GET_APPS_FAILURE',
  GET_TOTAL_APPS_COUNT: 'GET_TOTAL_APPS_COUNT',
  ADD_TO_APPS_QUEUE: 'ADD_TO_APPS_QUEUE',
  SET_APPS_QUEUE: 'SET_APPS_QUEUE',
  CLEAR_GET_APPS: 'CLEAR_GET_APPS',
  SET_APPS_TIMESTAMP: 'SET_APPS_TIMESTAMP',
  SET_APPS_LOADING: 'SET_APPS_LOADING',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  TOGGLE_APP_MENU: 'TOGGLE_APP_MENU',
};
