import { red } from '@mui/material/colors';
import { type ThemeOptions, createTheme } from '@mui/material/styles';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

function pxToRem(value) {
  return `${value / 16}rem`;
}

export function remToPx(value) {
  return `${value * 16}px`;
}

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 640,
    md: 1000,
    lg: 1340,
    xl: 1800,
  },
});

export const getTheme = (type: 'light' | 'dark' | undefined = 'light') => {
  const theme: ThemeOptions = {
    spacing: 8,
    palette: {
      divider: '#cecece',
      text: {
        secondary: 'rgba(0, 0, 0, 0.6)',
      },
      primary: {
        light: '#4F94F7',
        main: '#05149E',
        dark: '#171460',
        contrastText: '#f1f1f1',
      },
      secondary: {
        light: '#ff738c',
        main: '#FF395F',
        dark: '#c50036',
        // contrastText: '#0f0f0f',
      },
      error: {
        light: red[400],
        main: red[600],
        dark: red[900],
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: pxToRem(48),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '-0.6px',
            color: 'rgba(0, 0, 0, 0.87)',
            [breakpoints.down('md')]: {
              fontSize: pxToRem(36),
              fontWeight: 500,
              letterSpacing: '-0.4px',
            },
          },
          h2: {
            fontSize: pxToRem(42),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '-0.4px',
            color: 'rgba(0, 0, 0, 0.87)',
            [breakpoints.down('md')]: {
              fontSize: pxToRem(28),
              fontWeight: 500,
              letterSpacing: '-0.3px',
            },
          },
          h3: {
            fontSize: pxToRem(36),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: 'rgba(0, 0, 0, 0.87)',
            [breakpoints.down('md')]: {
              fontSize: pxToRem(24),
              fontWeight: 500,
              letterSpacing: 'normal',
            },
          },
          h4: {
            fontSize: pxToRem(28),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.2px',
            color: 'rgba(0, 0, 0, 0.87)',
            [breakpoints.down('md')]: {
              fontSize: pxToRem(21),
              fontWeight: 500,
              letterSpacing: '0.2px',
            },
          },
          h5: {
            fontSize: pxToRem(18),
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '24px',
            letterSpacing: '-0.2px',
            color: 'rgba(0, 0, 0, 0.87)',
            [breakpoints.down('md')]: {
              fontSize: pxToRem(16),
              fontWeight: 500,
              letterSpacing: '0.2px',
            },
          },
          h6: {
            letterSpacing: '0.015em',
          },
          body1: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.75',
            letterSpacing: '0.5px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          body2: {
            fontSize: pxToRem(14),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.43',
            letterSpacing: '0.3px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          caption: {
            fontSize: pxToRem(12),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.33',
            letterSpacing: '0.4px',
            color: 'rgba(0, 0, 0, 0.6)',
          },
          subtitle1: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.5',
            letterSpacing: '0.2px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
          subtitle2: {
            fontSize: pxToRem(14),
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '1.71',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:hover': {
              backgroundColor: '#eaf2ff',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { marginLeft: 0 },
        },
      },

      MuiDialog: {
        styleOverrides: {
          root: {
            // zIndex: 1350, Default is 1300, and setting it higher affects MuiMenu visibility
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            '&.Mui-checked': {
              color: '#25ca74',
            },
            '&.Mui-checked&.Mui-disabled': {
              color: '#84e1b1',
            },
          },
          track: {
            '.Mui-checked.Mui-checked + &': {
              backgroundColor: '#25ca74',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: pxToRem(13),
          },
          head: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: pxToRem(12),
            fontWeight: 'normal',
            letterSpacing: 'normal',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 12,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            lineHeight: '1.1876em',
          },
          input: {
            // fixes padding on standard variant input fields
            paddingTop: '6px',
            paddingBottom: '7px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            lineHeight: 1,
            overflow: 'visible',
            ...(ownerState.shrink &&
              ownerState.variant === 'standard' && {
                transform: 'matrix(0.75, 0, 0, 0.75, 0, 1.5)',
              }),
            ...(!ownerState.shrink &&
              ownerState.variant === 'standard' && {
                transform: 'translate(0, 24px) scale(1)',
              }),
            ...(ownerState.shrink &&
              ownerState.variant === 'outlined' && {
                transform: 'translate(14px, -6px) scale(0.75)',
              }),
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            minHeight: '1.1876em',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            color: 'rgba(0,0,0,0.54)',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: { marginTop: '-8px' },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
    },
    shape: {
      borderRadius: 4,
    },
    breakpoints,
  };

  return createTheme(theme);
};
