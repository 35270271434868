import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import memoizeOne from 'memoize-one';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { matchPath, Redirect, Route } from 'react-router';

import organisationService from '../components/RMS/organisation.services';
import { type AccountState } from '../reducers/account.reducer';
import retryAsync from '../services/utils/retryAsync';
import routes from './index.routes';

export const DEFAULT_MIN_LOADING_TIMEOUT = 1300;

export const isPublicRoute = memoizeOne((pathname: string) =>
  routes.publicRoutes
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);

export const isFirstSetupV2Route = memoizeOne((pathname: string) =>
  routes.firstSetupRoutesV2
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);

export const createRoute = (
  props: RoutePropsExtended | RedirectPropsExtended,
  appPermissions: AppState['permissions']
) => {
  if ('to' in props) {
    // only Redirect has `to` prop required
    const { name, key = '', ...rest } = props;
    return <Redirect {...rest} key={`${name}.${key}`} />;
  }

  if ('permissions' in props) {
    const { permissions, name, key = '', ...rest } = props;
    if (checkVisibility(appPermissions, permissions, [])) {
      return <Route {...rest} key={`${name}.${key}`} />;
    }
    return null;
  }

  const { name, key = '', ...rest } = props;
  return <Route {...rest} key={`${name}.${key}`} />;
};

export const getAppId = (pathBefore: React.MutableRefObject<string | undefined>) => {
  // AppId is in path either by:
  // 1. Put there by the user / followed a link
  // 2. ensureAppIdInUrlPath got it from localstorage and put it in path
  let tryAppId: string | undefined;
  if (pathBefore.current !== undefined) {
    const match = matchPath<{ appId: 'string' }>(pathBefore.current, { path: '/:appId' });
    if (match?.params?.appId) {
      tryAppId = match.params.appId;
    }
  }
  if (!tryAppId) {
    // WARNING!!!! THIS WILL MATCH ANY STRING
    const { pathname } = location;
    if (!isPublicRoute(pathname) && !isFirstSetupV2Route(pathname)) {
      const match = matchPath<{ appId: 'string' }>(pathname, {
        path: '/:appId',
      });
      if (match?.params?.appId) {
        tryAppId = match.params.appId;
      }
    }
  }
  return tryAppId || 'flipdish-global';
};

export const getIsLoggingIn = () => {
  // all log ins/post signup will have 'code' in /callback
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const code = params.get('code');
  return !!code;
};

type CreateOrgAndBrandProps = {
  account: AccountState;
  setCurrentApp: (appId: string | undefined) => Promise<App | undefined>;
};
export const createOrgAndBrand = async ({ account, setCurrentApp }: CreateOrgAndBrandProps) => {
  let currentOrgId = localStorage.getItem('fd-currentOrgId');

  if (!currentOrgId && account.Email) {
    const response = await organisationService.createOrg({
      isSelfServe: true,
      org: {
        emailAddress: account.Email,
        countryCode: 'IE', // Temp - this gets updated by the user in the onboarding MFE
        name: account.Email, // Temp - this gets updated by the user in the onboarding MFE
      },
    });

    currentOrgId = response.data.data?.orgId || '';
    // set the orgId in local storage to prevent duplicate org creation - org creation not limited to one per user!
    localStorage.setItem('fd-currentOrgId', currentOrgId);
  }

  const brandsResponse = await retryAsync({
    fn: () => organisationService.getBrandsForOrg(currentOrgId ?? ''),
    shouldRetry: (res) => {
      return !res?.data?.data?.length;
    },
  });

  if (brandsResponse?.data?.data?.length) {
    await retryAsync({
      fn: () => setCurrentApp(brandsResponse?.data.data?.[0].brandId),
      shouldRetry: (apps) => {
        if (apps?.AppId) {
          return false;
        }
        return true;
      },
    });
  }
};
