import React, { ReactNode } from 'react';

import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = () =>
  createStyles({
    ...[8, 16, 24, 32, 40].reduce((style, size) => {
      style[`${size}`] = {
        padding: `${size}px`,
      };
      style[`horizontal-${size}`] = {
        margin: `${size}px 0 ${size}px 0`,
      };
      style[`horizontal-empty-${size}`] = {
        height: `${size}px`,
      };
      style[`vertical-${size}`] = {
        margin: `0 ${size}px 0 ${size}px`,
      };
      style[`vertical-empty-${size}`] = {
        height: '100%',
        width: `${size}px`,
      };

      return style;
    }, {}),
  });

export interface ISpacerProps {
  className?: string;
  variant?: 'horizontal' | 'vertical';
  size?: 8 | 16 | 24 | 32 | 40 | 56;
  children?: ReactNode;
}
type Props = WithStyles<typeof styles> & ISpacerProps;
const Spacer = withStyles(styles, {
  name: 'FdSpacer',
  withTheme: true,
})(({ classes, className, variant, size = 8, children }: Props) => (
  <div
    className={clsx(
      {
        [classes[`${variant || 'horizontal'}-empty-${size}`]]: !children,
        [classes[`${size}`]]: !!children && !variant,
        [classes[`${variant}-${size}`]]: !!children && variant,
      },
      className
    )}
  >
    {children}
  </div>
));

export default Spacer;
