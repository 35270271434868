import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { compose, setDisplayName } from 'recompose';

import { IButtonProps } from '../../ui/Button';

const styles = () =>
  createStyles({
    root: {
      color: '#fff',
      height: '56px',
      borderRadius: '28px',
    },
    icon: {
      marginRight: '8px',
    },
  });

type OmitButtonProps = Pick<
  IButtonProps,
  Exclude<keyof IButtonProps, 'fdKey' | 'variant' | 'color' | 'size'>
>;
export interface ICreateNewButtonProps {
  translateId: TranslationId;
  onClick();
}
type Props = WithStyles<typeof styles> & ICreateNewButtonProps & IButtonProps;
const CreateNewButton = compose<Props, ICreateNewButtonProps & OmitButtonProps>(
  setDisplayName('CreateNewButton'),
  withStyles(styles, {
    name: 'NewButton',
  })
)(({ classes, translateId, ...props }) => {
  return (
    <Fab
      {...props}
      variant="extended"
      color="secondary"
      size="large"
      className={classes.root}
      data-fd={translateId}
    >
      <AddIcon className={classes.icon} />
      <Translate id={translateId} />
    </Fab>
  );
});

export default CreateNewButton;
