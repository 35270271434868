import { createSelector } from 'reselect';

type AppResourceSetEnum = Required<Flipdish.App>['AppResourceSet'];

export const canEditOrderCapacity = createSelector(
  [(state) => state.permissions as AppResourceSetEnum],
  (permissions) => {
    const canEdit = permissions.includes('EditStoreOrderCapacity');
    return canEdit;
  }
);
