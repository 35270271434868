import React, { useCallback, useEffect, useRef, useState } from 'react';

import { App, LoyaltyCampaign, RetentionCampaign } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import omit from 'lodash/omit';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { flagService } from '../../services/flagService';
import PageLayout from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import { campaignActionsAsync } from './actions';
import { AddNewCampaignButton } from './components/AddCampaignButton';
import CampaignItemsContainer from './components/CampaignItemsContainer';
import { CampaignSelectionMenu } from './components/CampaignSelectionMenu';
import DefaultCampaignAndVoucher from './components/DefaultCampaignAndVoucher';
import EmptyComponent from './components/EmptyComponent';
import LoyaltyCampaignModal, { FormValues, TypesOfReward } from './components/LoyaltyCampaignModal';
import RetentionCampaignModal from './components/RetentionCampaignModal';
import * as selectors from './selectors';

type Props = MappedState & MappedDispatch;

export enum CampaignsTypes {
  LOYALTY = 'Loyalty',
  RETENTION = 'Retention',
}

const menuItems = [
  {
    textId: 'Loyalty',
    value: CampaignsTypes.LOYALTY,
  },
  {
    textId: 'Retention',
    value: CampaignsTypes.RETENTION,
  },
] as Array<{ textId: TranslationId; value: CampaignsTypes }>;

const Campaigns = (props: Props) => {
  const {
    createCampaignAndVoucherBtn,
    currentApp,
    fetchCampaignsList,
    getStoresList,
    isNoCampaigns,
    loyaltyCampaigns,
    retentionCampaigns,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [loyaltyModalOpened, setLoyaltyModalOpen] = useState(false);
  const [retentionModalOpened, setRetentionModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [campaignData, setCampaignData] = useState<LoyaltyCampaign | RetentionCampaign | undefined>(
    undefined
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef(null);

  const isFlipdishGlobal = currentApp.AppId === 'flipdish-global';

  useEffect(() => {
    fetchCampaignsList().then(() => setIsLoading(false));
    getStoresList();
  }, []);

  const createCampaign = useCallback((type: CampaignsTypes) => {
    if (type === CampaignsTypes.LOYALTY) {
      setLoyaltyModalOpen(true);
    }
    if (type === CampaignsTypes.RETENTION) {
      setRetentionModalOpen(true);
    }
    handleCloseMenu();
  }, []);

  const editCampaign = useCallback(
    (type: CampaignsTypes, campaignId: number) => {
      let editData;
      if (type === CampaignsTypes.LOYALTY) {
        setLoyaltyModalOpen(true);
        editData = loyaltyCampaigns.find(
          (campaign: LoyaltyCampaign) => campaignId === campaign.CampaignId
        );
      }
      if (type === CampaignsTypes.RETENTION) {
        setRetentionModalOpen(true);
        editData = retentionCampaigns.find(
          (campaign: RetentionCampaign) => campaignId === campaign.CampaignId
        );
      }

      setCampaignData(editData);
      setEditMode(true);
    },
    [loyaltyCampaigns, retentionCampaigns]
  );

  const onCloseLoyaltyModal = useCallback(() => {
    setLoyaltyModalOpen(false);
    setEditMode(false);
    setCampaignData(undefined);
    getStoresList();
  }, []);

  const onCloseRetentionModal = useCallback(() => {
    setRetentionModalOpen(false);
    setEditMode(false);
    setCampaignData(undefined);
    getStoresList();
  }, []);

  const handleCloseMenu = useCallback(() => setAnchorEl(null), []);
  const addLoyaltyCampaign = useCallback(() => createCampaign(CampaignsTypes.LOYALTY), []);
  const addRetentionCampaign = useCallback(() => createCampaign(CampaignsTypes.RETENTION), []);

  const onSubmitLoyaltyCampaign = useCallback(
    async (values: FormValues) => {
      let data = {
        ...omit(values, ['TypeOfReward']),
        PercentDiscountAmount:
          values.TypeOfReward === TypesOfReward.FREE_MEAL ? null : values.PercentDiscountAmount,
      } as LoyaltyCampaign;

      if (editMode) {
        data = omit(data, ['OrdersBeforeReceivingVoucher', 'IncludeExistingOrders']);
        await props.editLoyaltyCampaign(values.CampaignId as number, data);
      } else {
        await props.addLoyaltyCampaign(data);
      }
      onCloseLoyaltyModal();
    },
    [editMode]
  );

  const onSubmitRetentionCampaign = useCallback(
    async (values: RetentionCampaign) => {
      if (editMode) {
        await props.editRetentionCampaign(values.CampaignId as number, values);
      } else {
        await props.addRetentionCampaign(values);
      }
      onCloseRetentionModal();
    },
    [editMode]
  );

  const onRemoveLoyaltyCampaign = useCallback(async (campaignId: number) => {
    await props.removeLoyaltyCampaign(campaignId);
    onCloseLoyaltyModal();
  }, []);

  const onRemoveRetentionCampaign = useCallback(async (campaignId: number) => {
    await props.removeRetentionCampaing(campaignId);
    onCloseRetentionModal();
  }, []);

  const renderContent = () => {
    if (!isLoading && isNoCampaigns) {
      return (
        <PaperContainer fluid>
          <EmptyComponent
            onCreateLoyaltyCampaign={addLoyaltyCampaign}
            onCreateRetentionCampaign={addRetentionCampaign}
          />
        </PaperContainer>
      );
    }

    return (
      <>
        <CampaignItemsContainer
          type={CampaignsTypes.LOYALTY}
          campaigns={loyaltyCampaigns}
          onEdit={editCampaign}
          isLoading={isLoading}
        />
        <CampaignItemsContainer
          type={CampaignsTypes.RETENTION}
          campaigns={retentionCampaigns}
          onEdit={editCampaign}
          isLoading={isLoading}
        />
      </>
    );
  };

  return (
    <PageLayout
      documentTitle="Campaigns"
      title={<Translate id="Campaigns" />}
      auditLogsFilter={{ type: 'EventType', value: 'campaign.*' }}
    >
      <Hidden smUp>
        <Divider />
      </Hidden>
      {renderContent()}
      {loyaltyModalOpened && (
        <LoyaltyCampaignModal
          campaignData={campaignData}
          editMode={editMode}
          open={loyaltyModalOpened}
          onCreate={onSubmitLoyaltyCampaign}
          onRemove={onRemoveLoyaltyCampaign}
          onCancel={onCloseLoyaltyModal}
        />
      )}
      {retentionModalOpened && (
        <RetentionCampaignModal
          campaignData={campaignData}
          editMode={editMode}
          open={retentionModalOpened}
          onCreate={onSubmitRetentionCampaign}
          onRemove={onRemoveRetentionCampaign}
          onCancel={onCloseRetentionModal}
        />
      )}
      {!isLoading && !isNoCampaigns && (
        <Permissions allowed={[App.AppResourceSetEnum.CreateCampaignsConfigurations]}>
          <CampaignSelectionMenu
            open={Boolean(anchorEl)}
            anchorEl={anchorRef.current}
            items={menuItems}
            onClick={createCampaign}
            onClose={handleCloseMenu}
          />
          <AddNewCampaignButton
            setRef={anchorRef}
            onClick={() => setAnchorEl(anchorRef.current)}
            textId="Add_new"
            fdKey="add-new-campaign-button"
          />
        </Permissions>
      )}
      {!isFlipdishGlobal && createCampaignAndVoucherBtn && isNoCampaigns && (
        <DefaultCampaignAndVoucher />
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchCampaignsList: () => dispatch(campaignActionsAsync.getCampaigns()),
  addLoyaltyCampaign: (values: LoyaltyCampaign) =>
    dispatch(campaignActionsAsync.createLoyaltyCampaign(values)),
  editLoyaltyCampaign: (campaignId: number, values: LoyaltyCampaign) =>
    dispatch(campaignActionsAsync.modifyLoyaltyCampaign(campaignId, values)),
  removeLoyaltyCampaign: (campaignId: number) =>
    dispatch(campaignActionsAsync.removeLoyaltyCampaign(campaignId)),
  addRetentionCampaign: (values: RetentionCampaign) =>
    dispatch(campaignActionsAsync.createRetainCampaign(values)),
  editRetentionCampaign: (campaignId: number, values: RetentionCampaign) =>
    dispatch(campaignActionsAsync.modifyRetainCampaign(campaignId, values)),
  removeRetentionCampaing: (campaignId: number) =>
    dispatch(campaignActionsAsync.removeRetainCampaign(campaignId)),
  getStoresList: () => dispatch(campaignActionsAsync.getStoresForCampaigns()),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    createCampaignAndVoucherBtn: flagService.isFlagOn(state, 'createCampaignAndVoucherBtn'),
    currentApp: state.currentApp,
    isNoCampaigns: selectors.isNoCampaigns(state),
    loyaltyCampaigns: selectors.loyaltyCampaigns(state),
    retentionCampaigns: selectors.retentionCampaigns(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
