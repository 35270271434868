import React, { useState } from 'react';

import { PushNotificationResponse } from '@flipdish/api-client-typescript';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { useLoadingAsIndicator } from '../../../custom-hooks/useAsIndicator';
import useRequestSnackBar from '../../../custom-hooks/useRequestSnackBar';
import { formatFullDateWithTime } from '../../../helpers/dateFormats';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import NavLink from '../../../ui/NavLink/NavLink';
import WindowInfiniteScroll from '../../../ui/WindowInfiniteScroll';
import { DELETE_PUSH_NOTIFICATION, deletePushNotification } from '../actions';
import DeletePushNotificationDialog from './DeletePushNotificationDialog';
import PushNotificationIcon from './PushNotificationIcon';

const deleteLoadingSelector = createLoadingSelector([DELETE_PUSH_NOTIFICATION]);
const deleteLoadingErrorSelector = createLoadingErrorSelectorFactory([DELETE_PUSH_NOTIFICATION]);

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  icon: {
    float: 'left',
    marginRight: `${spacing(2)}`,
  },
  content: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    fontSize: '14px',
  },
  contentWrapper: {
    width: '100%',
    paddingTop: 14,

    [breakpoints.down('sm')]: {
      paddingTop: 18,
    },
  },
  listHeader: {
    padding: spacing(2),
  },
  link: {
    color: 'inherit',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

const mapStateToProps = (state: AppState) => ({
  appId: state.currentApp.AppId,
  deleting: deleteLoadingSelector(state),
  deleteError: deleteLoadingErrorSelector(state),
  deletingError: state.pushNotifiactions.deletingError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  // @ts-ignore
  deleteNotification: (id: number | undefined) => dispatch(deletePushNotification(id)),
});

type Props = {
  hasMore: boolean;
  data: PushNotificationResponse[];
  loadData: (page: number) => any;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(({
  data,
  appId,
  loadData,
  hasMore,
  deleteNotification,
  deleteError,
  deleting,
  deletingError,
}: Props) => {
  const classes = useStyles();
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState<PushNotificationResponse | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const successDeleting = useLoadingAsIndicator(deleting);
  const success = successDeleting && !deletingError;

  useRequestSnackBar({
    success,
    failure: deleteError,
    autoSaving: deleting,
    failureMessage: deletingError,
  });

  const handleClick = (e, item) => {
    setAnchorEl(e.target);
    setActionsOpen(true);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setActionsOpen(false);
    setSelectedItem(null);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
    setActionsOpen(false);
  };

  return (
    <PaperContainer fluid>
      <Hidden smDown>
        <div className={`${HORIZONTAL_SPACE_CLASSNAME} ${classes.listHeader}`}>
          <GridContainer>
            <Grid item xs={3} className={classes.gridItem}>
              <Typography variant="caption">
                <Translate id="Status" /> / <Translate id="When" />
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.gridItem}>
              <Typography variant="caption">
                <Translate id="Message" />
              </Typography>
            </Grid>
          </GridContainer>
        </div>
        <Divider />
      </Hidden>
      <WindowInfiniteScroll
        loadMore={loadData}
        hasMore={hasMore}
        className={classes.contentWrapper}
      >
        <div>
          {data.map((n) => {
            // @ts-ignore
            const date = moment(n.ScheduledTime as string);

            return (
              <div className={HORIZONTAL_SPACE_CLASSNAME} key={n.ScheduledPushNotificationId}>
                <GridContainer>
                  <Grid item xs={2} sm={3} className={classes.gridItem}>
                    <div className={classes.icon}>
                      <PushNotificationIcon sent={!!n.Sent} />
                    </div>
                    <Hidden smDown>
                      <Typography variant="caption">{date.format('L')}</Typography>
                      <br />
                      <Typography variant="caption">{date.format('LT')}</Typography>
                    </Hidden>
                  </Grid>

                  <Grid item xs={10} sm={9} className={classes.gridItem}>
                    <div className={classes.content}>
                      <div>
                        <Hidden smUp>
                          <Typography variant="caption">{formatFullDateWithTime(date)}</Typography>
                        </Hidden>
                        <Typography component="p" variant="caption">
                          {n.Message}
                        </Typography>
                      </div>
                      {!n.Sent ? (
                        <IconButton
                          aria-label="More"
                          aria-haspopup="true"
                          data-fd={`pushnote-${n.Message}`}
                          onClick={(e) => handleClick(e, n)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : null}
                    </div>
                  </Grid>
                </GridContainer>
              </div>
            );
          })}
        </div>
      </WindowInfiniteScroll>
      {selectedItem ? (
        <Menu open={isActionsOpen} anchorEl={anchorEl} keepMounted onClose={handleClose}>
          <MenuList>
            <MenuItem className={classes.menuItem} data-fd="push_notification_edit">
              <NavLink
                className={classes.link}
                to={`/${appId}/push_notifications/${selectedItem.ScheduledPushNotificationId}`}
              >
                <EditIcon fontSize="small" /> <Translate id="Edit" />
              </NavLink>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              data-fd="push_notification_delete"
              onClick={openDialog}
            >
              <DeleteIcon fontSize="small" /> <Translate id="Delete" />
            </MenuItem>
          </MenuList>
        </Menu>
      ) : null}
      <DeletePushNotificationDialog
        isDialogOpen={isDialogOpen}
        submit={() => {
          if (selectedItem && selectedItem.ScheduledPushNotificationId) {
            deleteNotification(selectedItem.ScheduledPushNotificationId);
            setIsDialogOpen(false);
          }
        }}
        loading={deleting}
        setDialogOpen={() => setIsDialogOpen(false)}
      />
    </PaperContainer>
  );
});
