import React from 'react';

import { BluetoothTerminalStatus } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  serialNumber: {
    borderBottom: '1px solid rgba(0,0,0,0.38)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  spacer: { paddingTop: theme.spacing(2.5) },
}));

type OuterProps = {
  serialNumber?: string;
  deviceType?: BluetoothTerminalStatus.DeviceTypeEnum;
};

type InnerProps = {};
type Props = InnerProps & OuterProps;

const StripeTerminalSerialNumber = ({ serialNumber, deviceType }: Props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      {serialNumber ? (
        <>
          <Typography variant="body1" className={classes.serialNumber}>
            <Translate id={'Card_reader_id:'} /> {serialNumber}
          </Typography>
          <Grid item xs={12} className={classes.spacer}>
            <Typography variant="caption">
              <Translate
                id={
                  deviceType === BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X
                    ? 'To_disable_card_payments_disconnect_card_reader_from_kiosk'
                    : 'To_disable_card_payments_unregister_the_card_reader'
                }
              />
            </Typography>
          </Grid>
        </>
      ) : (
        <Translate id={'No_card_reader'} />
      )}
    </Grid>
  );
};

export default StripeTerminalSerialNumber;
