import React from 'react';

import TableRow from '@mui/material/TableRow';

export type TableBodyRowProps = {
  row: any;
  classNameButtonRow?: string;
  classNameRow?: string;
  onRowClick?: Function;
};

type TableBodyRowProp = TableBodyRowProps & { children: React.ReactNode };

const TableBodyRow: React.FC<React.PropsWithChildren<TableBodyRowProp>> = (props) => {
  const { onRowClick, row, classNameButtonRow, classNameRow, children } = props;

  const onClick = (e) => {
    e.preventDefault();
    onRowClick && onRowClick({ row });
  };

  if (onRowClick) {
    return (
      <TableRow role="button" onClick={onClick} className={classNameButtonRow}>
        {children}
      </TableRow>
    );
  }

  return <TableRow className={classNameRow}>{children}</TableRow>;
};

export default TableBodyRow;
