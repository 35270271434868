import React from 'react';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: '#ffbc2c',
    width: '100%',
    padding: 16,
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  container: {
    alignItems: 'center',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  text: {
    marginLeft: 16,
    color: '#000',
    letterSpacing: '0.25px',
  },
}));

type Props = {
  InFraudZone?: boolean;
  UnusualHighValueOrder?: boolean;
};

const DetailsFraudWarnings = (props: Props) => {
  const { InFraudZone, UnusualHighValueOrder } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper}>
      <Grid className={classes.container} container>
        <Grid className={classes.iconContainer} item xs={1} sm={'auto'}>
          <ReportProblemOutlinedIcon />
        </Grid>
        <Grid item xs={11}>
          {InFraudZone && (
            <Typography variant="body2" color="initial" className={classes.text}>
              <Translate id="Warning_cardholder_verification_recommended" />
            </Typography>
          )}
          {UnusualHighValueOrder && (
            <Typography variant="body2" color="initial" className={classes.text}>
              <Translate id="Warning_large_order" />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsFraudWarnings;
