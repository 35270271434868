import React, { useEffect, useState } from 'react';

import { OwnerEntityConfiguration } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Typography } from '@fd/ui/atoms/Typography';
import { NavigationList } from '@fd/ui/molecules/NavigationList';
import { NavigationListItem } from '@fd/ui/molecules/NavigationList/NavigationList';
import { PageHeader } from '@fd/ui/molecules/PageHeader';

import { ReactComponent as MetafieldsIcon } from '../../../../../assets/images/metafields.svg';
import { ReactComponent as ProductIcon } from '../../../../../assets/images/Products.svg';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import PageLayout from '../../../../../ui/Layout';
import { metafieldsService } from '../../metafields.service';
import { getHyphenatedEntityName } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const MetafieldEntities = ({ appId, isMetafieldsOn, translate }: MappedState) => {
  const classes = useStyles();
  const [entities, setEntities] = useState<NavigationListItem[]>([]);

  const { data, isSuccess, isPending } = useQuery({
    queryKey: [metafieldsService.getMetafieldEntitiesQueryKey, appId],
    queryFn: () => metafieldsService.getMetafieldEntitities(appId),
    enabled: isMetafieldsOn,
  });
  useEffect(() => {
    if (isSuccess && data) {
      const entityNavigationList = data.Data.Configurations.map(
        (entity: OwnerEntityConfiguration) => {
          const { OwnerEntity } = entity;
          const entityString = OwnerEntity?.toString() as TranslationId;
          const entityParam = getHyphenatedEntityName(entityString);
          return {
            icon: <ProductIcon color="rgb(108, 107, 107)" />,
            key: OwnerEntity,
            primaryText: <Translate id={entityString} />,
            url: `metafields/${entityParam}`,
          };
        }
      );
      setEntities(entityNavigationList);
    }
  }, [data, isSuccess]);

  return isMetafieldsOn ? (
    <PageLayout documentTitle="Metafields" title={<Translate id="Metafields" />}>
      <PageHeader
        description={translate('Metafields_description')}
        heading={<Translate id="Metafields" />}
        icon={<MetafieldsIcon color="rgb(139,139,139)" />}
      />
      <div className={classes.listTitle}>
        <Typography>
          <Translate id="List_of_metafields" />
        </Typography>
      </div>
      <NavigationList isLoading={isPending} items={entities} />
    </PageLayout>
  ) : (
    <Typography>
      <Translate id="Coming_soon" />
    </Typography>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId as string,
    isMetafieldsOn: isProductBasedMenusEnabled(state),
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = connect(mapStateToProps)(MetafieldEntities);
export { EnhancedComponent as MetafieldEntities };
