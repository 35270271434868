import React, { useEffect, useMemo, useState } from 'react';

import { Coordinates, Store } from '@flipdish/api-client-typescript';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { GetStoreSearch, getStoreSearch } from '../../components/Reports/OrderReport.actions';
import withRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../components/WithRouteSearchParams';
import { loadById } from '../../services/store.service';
import Select from '../Select/Select';

export type StoreSearchResult = {
  currency: Store.CurrencyEnum | undefined;
  storeGroups: Array<{
    id: number;
    name: string;
    stores: Array<{
      id: number;
      name: string;
    }>;
  }>;
};

export type StoreOption = {
  label: string;
  value: number | Store.CurrencyEnum;
  coordinates?: Coordinates;
  currency?: Store.CurrencyEnum;
  isDisabled?: boolean;
  isGroup?: boolean;
  isHidden?: boolean;
};

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 10,
  },
}));

export const parseStoreHeaders = (
  storeHeaders: StoreSearchResult[] | undefined,
  translate: TranslateFunction,
  currency?: Store.CurrencyEnum
) => {
  const storeGroupsByCurrency = [] as Array<Array<StoreOption>>;
  if (storeHeaders && storeHeaders.length) {
    for (const storeHeader of storeHeaders) {
      storeGroupsByCurrency.push([
        {
          value: storeHeader.currency!,
          currency: storeHeader.currency,
          label: `${storeHeader.currency} - ${translate('All_stores')}`,
          isGroup: true,
        },
      ]);

      for (const storeGroup of storeHeader.storeGroups) {
        for (const store of storeGroup.stores) {
          storeGroupsByCurrency.push([
            {
              isGroup: false,
              label: store.name,
              value: store.id,
              currency: storeHeader.currency,
              isDisabled: currency && storeHeader.currency !== currency,
              isHidden: false,
            },
          ]);
        }
      }
    }
    return storeGroupsByCurrency.flat();
  } else {
    return [];
  }
};

export const parseStore = (store: Store, currency?: Store.CurrencyEnum): StoreOption => {
  return {
    label: store.Name!,
    value: store.StoreId!,
    coordinates: store.Address && store.Address.Coordinates,
    isGroup: false,
    currency: store.Currency,
    isDisabled: currency && store.Currency !== currency,
    isHidden: false,
  };
};

export const getStores = async (
  stores: StoreOption[],
  selectedStores: { stores: StoreOption[]; hasSetInitialStores: boolean }
) => {
  const storesToLoad = stores.filter(
    (so) => selectedStores.stores.map((s) => s.value).indexOf(so.value) === -1
  );
  const loadedStores = stores.filter(
    (so) => selectedStores.stores.map((s) => s.value).indexOf(so.value) !== -1
  );
  const storeIdsRequest = storesToLoad
    .filter((store) => typeof store.value === 'number')
    .map((store) => loadById(store.value));

  const storesData: Store[] = await Promise.all(storeIdsRequest);
  return [...loadedStores, ...storesData.map((store) => parseStore(store))];
};

export const disableOtherCurrencyStores = (
  storeWeWantToSelect: StoreOption[],
  storeOptions: StoreOption[]
) => {
  // prev store = storeWeWantToSelect[0]
  // new store = storeWeWantToSelect[1] unless prev value was empty
  // prev could be GBP
  // new one could be EUR
  // if more than 2 here then we have stores from the same currency
  const currencyWeWant =
    storeWeWantToSelect.length && storeWeWantToSelect.length > 1
      ? storeWeWantToSelect[1].currency
      : storeWeWantToSelect[0].currency;

  const disableStores = (storeOption: StoreOption): boolean => {
    if (storeOption.isGroup) {
      return false;
    } else {
      return storeOption.currency !== currencyWeWant ? true : false;
    }
  };

  const newStoreOptions =
    storeOptions.length && storeWeWantToSelect.length
      ? storeOptions.map((storeOption) => {
          return {
            ...storeOption,
            isDisabled: disableStores(storeOption),
            isHidden: false,
          };
        })
      : [];

  return newStoreOptions;
};

export const getUniqueStores = (
  searchResultStores: StoreOption[],
  currentStoreOptions: StoreOption[]
) => {
  const allStores = [...searchResultStores, ...currentStoreOptions];

  return [...new Map(allStores.map((store) => [store.value, store])).values()];
};

let timeOut: NodeJS.Timeout;
type StoreFilterProps = {
  onSelectStore: (selectedStores: StoreOption[]) => void;
  setInitialCurrency: (currency: Store.CurrencyEnum | undefined) => void;
  setInitialStores?: (initialStores: StoreOption[]) => void;
  isReporting?: boolean;
};
type InnerProps = MappedState & WithRouteSearchParamsProps<string[] | string> & MappedDispatch;
type OuterProps = StoreFilterProps;
type Props = InnerProps & OuterProps;

function StoreByCurrencyFilter(props: Props) {
  const classes = useStyles();
  const {
    getStoreSearch,
    isReporting = true,
    translate,
    onSelectStore,
    setInitialStores,
    setInitialCurrency,
  } = props;
  const [storeOptions, setStoreOptions] = useState<StoreOption[]>([]);
  const [selectedStores, setSelectedStores] = useState<{
    stores: StoreOption[];
    hasSetInitialStores: boolean;
  }>({
    stores: [],
    hasSetInitialStores: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    getStoresHeadersSearch(true);
  }, []);

  useEffect(() => {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      getStoresHeadersSearch(false, query);
    }, 600);
    return () => clearTimeout(timeOut);
  }, [query]);

  const setUrlParams = (storeIds: string[]) => {
    const { setSearch } = props;
    setSearch(storeIds);
  };

  const getInitialSelectedStores = async (search: string[] | string) => {
    const selectedCurrency = selectedStores.stores.length
      ? selectedStores.stores[0].currency
      : undefined;
    if (Array.isArray(search)) {
      {
        /* TODO: Replace loadById stores request with request for fetching store headers by Id (no API yet) */
      }
      const storeIdsRequest = search.map((id) => loadById(parseInt(id, 10)));
      const storesData = await Promise.all(storeIdsRequest);
      return storesData.map((store) => parseStore(store, selectedCurrency));
    } else {
      const storeData = await loadById(parseInt(search, 10));
      return [parseStore(storeData, selectedCurrency)];
    }
  };

  const getStoresHeadersSearch = async (initialSearch: boolean, query?: string) => {
    if (!initialSearch && !query) {
      return;
    }
    const { hasSetInitialStores, stores } = selectedStores;
    const selectedCurrency = selectedStores.stores.length
      ? selectedStores.stores[0].currency
      : undefined;
    setIsLoading(true);
    try {
      const storeHeaders: StoreSearchResult[] | undefined = await getStoreSearch({
        search: query,
        isReporting,
      });

      if (query) {
        // if user searched for a store we need to wait until they've selected it before updating anything
        const searchResultStore = parseStoreHeaders(storeHeaders, translate, selectedCurrency);
        const uniqueStoreOptions = getUniqueStores(searchResultStore, storeOptions);
        setStoreOptions(uniqueStoreOptions);
        setIsLoading(false);
        return;
      }

      // grouped by currency
      let initialStoreOptions = parseStoreHeaders(storeHeaders, translate, selectedCurrency);
      if (!hasSetInitialStores && props.search) {
        // Using url params to get selected stores
        let newSelectedStores = [] as any;
        // We need to check if the current search is a currency
        const currencySearch =
          props.search && !Array.isArray(props.search) && !parseInt(props.search, 10);
        if (currencySearch && initialStoreOptions) {
          // If it is, we set it as the initial currency
          setInitialCurrency(props.search as any);
          // Then we find the allStores value for this currency
          newSelectedStores = [initialStoreOptions.find((so) => String(so.value) === props.search)];
          // We disable stores in the same currency because 'all stores' is selected
          initialStoreOptions = disableOtherCurrencyStores(newSelectedStores, initialStoreOptions);
        } else {
          newSelectedStores = await getInitialSelectedStores(props.search);
          setInitialCurrency(newSelectedStores[0].currency);
          initialStoreOptions = disableOtherCurrencyStores(newSelectedStores, initialStoreOptions);
        }
        if (setInitialStores) {
          setInitialStores(newSelectedStores);
        }
        onSelectStore(newSelectedStores);
        setSelectedStores({ stores: newSelectedStores, hasSetInitialStores: true });
        setStoreOptions(initialStoreOptions);
      } else {
        const newStores: StoreOption[] = parseStoreHeaders(
          storeHeaders,
          translate,
          selectedCurrency
        );
        setInitialCurrency(newStores[0].currency);
        if (initialSearch) {
          if (!stores.length) {
            onSelectStore([newStores[0]]);
            setUrlParams([newStores[0].currency!.toString()]);
            initialStoreOptions = disableOtherCurrencyStores(newStores, initialStoreOptions);
            setStoreOptions(initialStoreOptions);
            setSelectedStores({ stores: [initialStoreOptions[0]], hasSetInitialStores: true });
          }
        }
        setStoreOptions(newStores);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  //#region handle change
  const handleStoresChange = (values: StoreOption[]) => {
    if (values.length) {
      let allStoreValue = values.find((v) => v.isGroup);
      if (selectedStores.stores.length && selectedStores.stores[0].isGroup) {
        allStoreValue = values.find((v) => v.currency !== selectedStores.stores[0].currency);
      }
      if (allStoreValue) {
        // if we select all stores from a currency (ie: EUR - All stores)
        handleAllCurrencyStoreSelect(allStoreValue);
      } else {
        // if we select a single value
        const stores = values.filter((v) => !v.isGroup);
        handleStoreSelect(stores);
      }

      if (storeOptions && storeOptions.length) {
        // when we select a store, we want to disable
        // all other stores with a different currency
        setStoreOptions(disableOtherCurrencyStores(values, storeOptions));
      }
    } else {
      // if no store is selected, we re-enable all stores
      handleStoreSelect(values);
      enableAllStores();
    }
  };

  const handleAllCurrencyStoreSelect = async (allStoreValue: StoreOption) => {
    if (storeOptions && storeOptions.length) {
      setSelectedStores({
        stores: [allStoreValue],
        hasSetInitialStores: selectedStores.hasSetInitialStores,
      });
      // We dont want to set 'All currency stores' in the query
      setUrlParams([allStoreValue.value!.toString()]);
      onSelectStore([allStoreValue]);
    }
  };

  const handleStoreSelect = async (stores: StoreOption[]) => {
    const storesData = await getStores(stores, selectedStores);
    setSelectedStores({
      stores: storesData,
      hasSetInitialStores: selectedStores.hasSetInitialStores,
    });
    const storeIds = stores.map((v) => v.value!.toString());
    setUrlParams(storeIds);
    onSelectStore(storesData);
  };

  const enableAllStores = () => {
    const allStoresEnabled =
      storeOptions && storeOptions.length
        ? storeOptions.map((storeOption) => {
            return {
              ...storeOption,
              isDisabled: false,
              isHidden: false,
            };
          })
        : [];
    setStoreOptions(allStoresEnabled);
  };

  const handleInputChange = (value: string) => {
    setQuery(value);
  };
  //#endregion

  const filteredStoreOptions = useMemo(
    () =>
      storeOptions && storeOptions.length
        ? storeOptions.filter((so) => !so.isHidden)
        : storeOptions,
    [storeOptions]
  );

  return (
    <Select
      variant="outlined"
      className={classes.select}
      dataFd="stores-filter"
      isClearable
      isLoading={isLoading}
      isMulti
      onChange={handleStoresChange}
      onInputChange={handleInputChange}
      options={filteredStoreOptions}
      placeholder={translate('Select_stores') as TranslationId}
      TextFieldProps={{
        fdKey: 'stores-dropdown',
        label: translate('Stores'),
        name: 'stores-filter',
      }}
      value={selectedStores.stores}
    />
  );
}

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getStoreSearch: ({ search, isReporting, currency }: GetStoreSearch) =>
    dispatch(getStoreSearch({ search, isReporting, currency })),
});

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'store',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(StoreByCurrencyFilter);
