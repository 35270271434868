import Auth0Login from '../components/Auth/Auth0/Auth0Login';
import Auth0LoginCallback from '../components/Auth/Auth0/Auth0LoginCallback';
import Logout from '../components/Auth/Logout';
import OnboardingRoutes from '../components/OnboardingV2/Onboarding.routes';
import SelfServeRoutes from '../components/SelfServe/SelfServe.routes';
import AcceptInvitation from '../components/Teammates/Invitation/AcceptInvitation';
import { Auth0CallbackPath } from '../constants/auth0.constants';

export const accountRoutesConst = {
  Root: '/',
  Login: '/login',
  Logout: '/logout',
  Signup: '/signup',
  SignupFlow: '/signup/steps',
  SubscriptionsSelfServeMicroFrontend: '/self-serve',
  AcceptInvitation: '/accept_invitation',
  Auth0Login: '/auth0login',
  Auth0LoginCallback: Auth0CallbackPath,
};

export const signupV2Const = {
  BusinessOwnerInfo: '/signup/business-owner-info',
  RestaurantDetails: '/signup/restaurant-details',
  BrandDetails: '/signup/brand-details',
};

export const firstTimeSetupRoutesV2: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'signup_v2.business_owner_info',
    group: 'first_time_setup_V2',
    path: signupV2Const.BusinessOwnerInfo,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup_v2.restaurant_details',
    group: 'first_time_setup_V2',
    path: signupV2Const.RestaurantDetails,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup_v2.brand_details',
    group: 'first_time_setup_V2',
    path: signupV2Const.BrandDetails,
    component: OnboardingRoutes,
    exact: true,
  },
  {
    name: 'signup.redirect_index',
    group: 'first_time_setup_V2',
    path: '*',
    to: signupV2Const.BusinessOwnerInfo,
  },
];

export const publicRoutes: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'accept_invitation',
    group: 'publicAndPrivate',
    path: accountRoutesConst.AcceptInvitation,
    component: AcceptInvitation,
    exact: true,
  },
  {
    name: 'auth.login',
    group: 'public',
    path: accountRoutesConst.Login,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'auth.auth0login',
    group: 'public',
    path: accountRoutesConst.Auth0Login,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'auth.auth0logincallback',
    group: 'public',
    path: accountRoutesConst.Auth0LoginCallback,
    component: Auth0LoginCallback,
    exact: true,
  },
  {
    name: 'auth.signup',
    group: 'public',
    path: accountRoutesConst.Signup,
    component: Auth0Login,
    exact: true,
  },
  {
    name: 'subscriptions.self_serve',
    group: 'public',
    path: accountRoutesConst.SubscriptionsSelfServeMicroFrontend,
    component: SelfServeRoutes,
    exact: false,
  },
  {
    name: 'auth.redirect_index',
    group: 'public',
    path: '*',
    to: accountRoutesConst.Login,
  },
  {
    name: 'auth.logout',
    group: 'publicAndPrivate',
    path: accountRoutesConst.Logout,
    component: Logout,
    exact: true,
  },
];
