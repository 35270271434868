import { useEffect } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

type InnerProps = MappedDispatch & RouteComponentProps<{ appId: string; guid: string }>;
type OuterProps = {
  downloadCSVAction: (
    Guid: string
  ) => (dispatch: ThunkDispatch, getState: () => AppState) => Promise<string | void>;
  redirectURL: string;
};
type Props = InnerProps & OuterProps;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const ReportCSVExport = ({ downloadCSV, redirectURL, match }: Props) => {
  useEffect(() => {
    // Get GUID from url and start download
    const guid = match.params.guid;
    handleCSVDownload(guid);
  }, []);

  const handleCSVDownload = async (guid: string) => {
    try {
      const csv = await downloadCSV(guid);
      // Creating element to download CSV
      if (csv !== undefined) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        element.setAttribute('download', `${match.params.guid}.csv`);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    } catch (err) {
      console.log('handleCSVDownload error', new Error(err));
    } finally {
      sleep(3000).then(() => {
        // Redirecting to once download is over
        document.location.replace(`/${match.params.appId}${redirectURL}`);
      });
    }
  };

  return null;
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, { downloadCSVAction }: OuterProps) => ({
  downloadCSV: (guid: string) => {
    return dispatch(downloadCSVAction(guid));
  },
});

export default connect(null, mapDispatchToProps)(ReportCSVExport);
