import { createSelector } from 'reselect';

import { DNSRecord } from './reducer';

const selectTrackingCodes = (state: AppState) => state.website.trackingCodes;

export const getTrackingCodesSelector = createSelector(
  [selectTrackingCodes],
  (trackingCodes) => trackingCodes
);

export const getDNSRecords = (state: AppState) => state.website.dns;
export const getIsEmbed = (state: AppState) => state.website.isEmbed;
export const getHasSubdomain = (state: AppState) => state.website.hasSubdomain;
export const getSMSRestaurantNameSelector = (state: AppState) => state.website.smsRestaurantName;

const getComplianceType = (state: AppState) => state.website.complianceType;
export const webSiteAndAppSelectors = {
  getComplianceType,
};

// #region getDNSRecordStatus
export const getDNSRecordStatus = createSelector(
  [getDNSRecords],
  (DNSRecords: DNSRecord[]): boolean => {
    if (DNSRecords) {
      return DNSRecords.some((record) => record.Status === true);
    } else {
      return false;
    }
  }
);
// #endregion
