import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import subDays from 'date-fns/subDays';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { dateTimeUtils } from '../../../../../selectors/localeDateTime.selector';
import InfoIcon from '../../../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../../../ui/Tooltip/Tooltip';
import { renderCurrency } from '../../helpers';
import { PayoutDetailSummary, PayoutSummary } from '../../types';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  container: {
    margin: theme.spacing(2, 1),
  },
  sectionItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  sectionItemFont: {
    fontSize: '13px !important',
  },
  sectionBoldFont: {
    fontWeight: 500,
  },
  icon: {
    display: 'inline',
    marginLeft: theme.spacing(0.6),
  },
}));

type OuterProps = {
  currency: string;
  languageCode: string;
  payoutBalanceData: PayoutSummary[] | any;
  payoutDetailSummary?: PayoutDetailSummary | undefined;
};

type Props = MappedState & OuterProps;

const PayoutBalanceAccount = ({
  currency,
  dtUtils,
  languageCode,
  payoutBalanceData,
  payoutDetailSummary,
}: Props) => {
  const classes = useStyles();

  if (!payoutBalanceData) {
    return null;
  }

  function addValues(...values) {
    return values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
  function subtractValues(startingValue, ...valuesToSubtract) {
    return valuesToSubtract.reduce(
      (accumulator, currentValue) => accumulator - currentValue,
      startingValue
    );
  }

  const {
    Amount,
    Balance,
    Chargebacks,
    FlipdishFees,
    OtherCharges,
    Revenue,
    RevenueAdjustments,
    ThirdPartyFees,
  } = payoutBalanceData || {};
  const openingBalance = Balance?.OpeningBalance || 0;

  const closingBalance = Balance?.ClosingBalance || 0;

  const calcRevenueFlipdishFeesAndChargebacks = addValues(
    RevenueAdjustments?.TotalOnlineRevenueAdjustments,
    FlipdishFees?.TotalFees,
    Chargebacks?.TotalChargebackCost,
    OtherCharges?.TotalOtherCharges,
    ThirdPartyFees?.TotalThirdPartyFees
  );

  const revenueTotal = addValues(
    Revenue?.TotalOnlineRevenue,
    calcRevenueFlipdishFeesAndChargebacks || 0
  );

  const revenueForThePeriodTotal = addValues(revenueTotal, openingBalance);
  const payoutAmount = Amount || 0;
  const payoutThisPeriodTotal = subtractValues(payoutAmount, revenueForThePeriodTotal);

  const subOneDayFromPeriodEndTime = subDays(
    new Date(payoutDetailSummary?.PeriodEndTime as any),
    1
  );
  const startTime =
    payoutDetailSummary?.PeriodStartTime &&
    dtUtils.format(payoutDetailSummary?.PeriodStartTime as any, dtUtils.LOCAL_DATE_FORMAT);
  const endTime =
    payoutDetailSummary?.PeriodEndTime &&
    dtUtils.format(subOneDayFromPeriodEndTime, dtUtils.LOCAL_DATE_FORMAT);

  return (
    <>
      <Box className={classes.title}>
        <Typography variant="h5">
          <Translate id="Balance_account" />
        </Typography>
      </Box>
      <Paper className={classes.container}>
        <Table aria-label="payout-balance-account-table">
          <TableHead>
            <TableRow className={classes.sectionItem}>
              <TableCell></TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  <Translate id="Credit" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  <Translate id="Debit" />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  <Translate id="Balance" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.sectionItem}>
              <TableCell>
                <Typography component={'span'} className={classes.sectionBoldFont} variant="body2">
                  <Translate id="Opening_balance" /> ({startTime})
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, openingBalance)}
              </TableCell>
            </TableRow>

            <TableRow className={classes.sectionItem}>
              <TableCell>
                <Typography
                  className={classes.sectionItemFont}
                  component={'span'}
                  data-fd={'line-item-value-'}
                  variant="body2"
                >
                  <Translate id="Payout_credited" />

                  <Tooltip
                    id="Total_revenue_minus_revenue"
                    messageId="Total_revenue_minus_revenue_adjustments_and_Flipdi"
                    fdKey="Total_revenue_minus_revenue"
                  >
                    <div className={classes.icon}>
                      <InfoIcon size={13} verticalAlign="middle" />
                    </div>
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, revenueTotal)}
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, revenueForThePeriodTotal)}
              </TableCell>
            </TableRow>

            <TableRow className={classes.sectionItem}>
              <TableCell>
                <Typography
                  className={classes.sectionItemFont}
                  component={'span'}
                  data-fd={'line-item-value-'}
                  variant="body2"
                >
                  <Translate id="Paid_out_this_period" />
                  <Tooltip
                    id="Payout_due_minus_accrued_adjustments"
                    messageId="Payout_due_minus_accrued_adjustments_See_accrued_a"
                    fdKey="Payout_due_minus_accrued_adjustments"
                  >
                    <div className={classes.icon}>
                      <InfoIcon size={13} verticalAlign="middle" />
                    </div>
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, payoutAmount)}
              </TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, payoutThisPeriodTotal)}
              </TableCell>
            </TableRow>

            <StyledTableRow>
              <TableCell>
                <Typography component={'span'} className={classes.sectionBoldFont} variant="body2">
                  <Translate id="Closing_Balance" /> ({endTime})
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                {renderCurrency(currency, languageCode, closingBalance)}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;

function mapStateToProps(state: AppState) {
  const { locale } = state;
  return {
    dtUtils: dateTimeUtils(state),
  };
}

export default compose<MappedState, OuterProps>(connect(mapStateToProps))(PayoutBalanceAccount);
