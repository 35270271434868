import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@fd/ui/Switch/Switch';

// #region services

const setShowHiddenFeatures = (val: boolean, handleHiddenFeature: (action, role) => void) => {
  if (val) {
    handleHiddenFeature('add', 'ShowHiddenFeatures');
  } else {
    handleHiddenFeature('remove', 'ShowHiddenFeatures');
  }
};
// #endregion

const HiddenFeatureToggle = ({ checked, handleHiddenFeatureChange }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          fdKey="Show_hidden_features"
          checked={checked}
          onChange={(e, checked) => {
            setShowHiddenFeatures(checked, handleHiddenFeatureChange);
          }}
          value={checked}
        />
      }
      label="Show hidden features"
    />
  );
};

export default HiddenFeatureToggle;
