import React from 'react';

import { AccountFieldDefinition } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { type FieldProps, Field } from 'formik';
import get from 'lodash/get';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../../helpers/validation';
import * as dynamicHelpers from '../bankAccFieldTextHelpers/DynamicLabel';
import FormItemLayout from '../FormItemLayout';
import BankAccountFormBussinesTypeFields from './BankAccountFormBussinesTypeFields';
import FieldWrapper from './FieldWrapper';

export interface IPropsDynamicFields {
  countryFields: AccountFieldDefinition[] | undefined;
}
export type Props = IPropsDynamicFields;

const validate = (value: string, fieldName: string) => {
  return (
    formikValidate.required(value) || formikValidate.max_string_custom(100)(value.length, fieldName)
  );
};

const validateDynamic = (
  value: string,
  fieldName: string | undefined,
  maxLength: number | undefined,
  minLength: number | undefined
) => {
  return (
    formikValidate.required(value) ||
    (minLength &&
      fieldName &&
      formikValidate.min_string_custom(minLength)(value.length, fieldName)) ||
    (maxLength && fieldName && formikValidate.max_string_custom(maxLength)(value.length, fieldName))
  );
};

const DynamicFormFields = (props: Props) => {
  const { countryFields = [] } = props;
  const editView = window.location.pathname.endsWith('/edit');

  const renderDynamicContent = () => {
    const dynamicFields = countryFields.map(function (dynamicField, index) {
      return (
        <Grid item xs={12} key={index}>
          <FieldWrapper>
            <Field
              name={`PopulatedAccountFields.${index}.Value`}
              validate={(value: string) =>
                validateDynamic(
                  value,
                  dynamicField.DisplayName,
                  dynamicField.MaxLength,
                  dynamicField.MinLength
                )
              }
            >
              {({ field, form }: FieldProps) => {
                const { errors, isSubmitting, touched } = form;
                const fieldError = get(errors, field.name);
                const showError = editView
                  ? !!fieldError
                  : !!fieldError && (get(touched, field.name) as boolean | undefined);

                return (
                  <TextField
                    variant="standard"
                    {...field}
                    onChange={(e) =>
                      form.setFieldValue(`PopulatedAccountFields.${index}`, {
                        Key: dynamicHelpers.getDynamicKey(dynamicField.Key),
                        Value: e.target.value.replace(/\s/g, ''),
                      })
                    }
                    //@ts-ignore
                    label={<Translate id={`${dynamicHelpers.getDynamicLabel(dynamicField.Key)}`} />}
                    autoComplete="off"
                    fullWidth
                    disabled={isSubmitting}
                    error={showError}
                    inputProps={{
                      'data-fd': `bank_dynamic_fields_${dynamicHelpers.getDynamicLabel(
                        dynamicField.Key
                      )}`,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={showError ? <>{fieldError}</> : undefined}
                    margin="none"
                  />
                );
              }}
            </Field>
          </FieldWrapper>
        </Grid>
      );
    });
    return dynamicFields;
  };

  return (
    <FormItemLayout label={<Translate id="Bank_account" />}>
      {!editView && <BankAccountFormBussinesTypeFields />}
      {editView && (
        <FieldWrapper>
          <Field name={'BusinessType'}>
            {({ field }: FieldProps) => {
              return (
                <TextField
                  variant="standard"
                  {...field}
                  label={<Translate id={'Account_type'} />}
                  fullWidth
                  inputProps={{
                    'data-fd': 'Account_type',
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="none"
                />
              );
            }}
          </Field>
        </FieldWrapper>
      )}

      <FieldWrapper>
        <Field name={'BankName'} validate={(value: string) => validate(value, 'Bank Name')}>
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = get(errors, field.name);
            const showError = editView
              ? !!fieldError
              : !!fieldError && (get(touched, field.name) as boolean | undefined);

            return (
              <TextField
                variant="standard"
                {...field}
                label={<Translate id={'Bank_name'} />}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
                error={showError}
                inputProps={{
                  'data-fd': 'Bank_name',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={showError ? <>{fieldError}</> : undefined}
                margin="none"
              />
            );
          }}
        </Field>
      </FieldWrapper>

      <FieldWrapper>
        <Field name={'AccountName'} validate={(value: string) => validate(value, 'Account Name')}>
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = errors[field.name] as string | undefined;
            const showError = editView
              ? !!fieldError
              : !!fieldError && (get(touched, field.name) as boolean | undefined);

            return (
              <TextField
                variant="standard"
                {...field}
                label={<Translate id={'Account_name'} />}
                autoComplete="off"
                fullWidth
                disabled={isSubmitting}
                error={showError}
                inputProps={{
                  'data-fd': 'Bank_account_name',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={showError ? fieldError : undefined}
                margin="none"
              />
            );
          }}
        </Field>
      </FieldWrapper>
      {renderDynamicContent()}
    </FormItemLayout>
  );
};

export default DynamicFormFields;
