import { DeliveryZoneApiFactory, DeliveryZoneBase } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';

const DeliveryZonesApi = createApi(DeliveryZoneApiFactory);

export const loadAll = (storeId: number) => {
  return DeliveryZonesApi.getDeliveryZones(storeId).then((response) => {
    return Promise.resolve(response.Data);
  });
};

export const create = (storeId: number, zone: DeliveryZoneBase) => {
  return DeliveryZonesApi.addDeliveryZone(storeId, zone).then((response) => {
    return Promise.resolve(response.Data);
  });
};

export const update = (storeId: number, deliveryZoneid: number, zone: DeliveryZoneBase) => {
  return DeliveryZonesApi.updateDeliveryZone(storeId, deliveryZoneid, zone).then((response) => {
    return Promise.resolve(response.Data);
  });
};

export const remove = (storeId: number, deliveryZoneid: number) => {
  return DeliveryZonesApi.removeDeliveryZone(storeId, deliveryZoneid).then((response) => {
    return Promise.resolve();
  });
};
