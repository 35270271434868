import React, { useRef } from 'react';

import { useDrag, useDrop } from 'react-dnd';

const DragAndDropCard = 'card';

export type Props = {
  id: any;
  index: number;
  disabled?: boolean;
  className?: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};
const Card: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  index,
  moveCard,
  children,
  className,
  disabled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: DragAndDropCard,
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    canDrag: !disabled,
    item: { id, index },
    type: DragAndDropCard,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div className={className} ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};

export default Card;
