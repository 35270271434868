import React from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontSize: 14,
    lineHeight: '20px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'center',
  },
  contentWrapper: {
    marginBottom: 12,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    color: '#05149E',
    marginBottom: 8,
  },
}));

const Dismiss = ({ dismiss }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <p>
          <Translate id="If_you_already_know_how_to_set_up_your_Flipdish" />
        </p>
      </div>
      <div>
        <Button variant="contained" color="primary" fullWidth onClick={() => dismiss()}>
          <Translate id="Dismiss_checklist" />
        </Button>
      </div>
    </div>
  );
};

export default Dismiss;
