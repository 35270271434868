import React from 'react';

import Grid from '@mui/material/Grid';

function getSpacing(width: GridContainerWidth): number {
  return width === 'sm' ? 2 : 3;
}

type GridContainerWidth = 'sm' | 'md';
export type GridContainerProps = {
  children: React.ReactNode;
  width?: GridContainerWidth;
  className?: string;
};

export const GridContainer = ({ children, className, width = 'sm' }: GridContainerProps) => {
  // MUI V5 messed up Grid container spacing. This is a workaround to fix it.
  const spacing = getSpacing(width);
  const margin = spacing / 2;
  const containerWidth = `calc(100% + ${8 * spacing}px)`;
  return (
    <Grid className={className} container margin={-margin} width={containerWidth}>
      {children}
    </Grid>
  );
};
