export const apmConstants = {
  GET_STATUS_REQUEST: 'GET_STATUS_REQUEST',
  GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
  GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
  SET_STATISTICS_TIMESTAMP: 'SET_STATISTICS_TIMESTAMP',
  GET_CALLS_SUCCESS: 'GET_CALLS_SUCCESS',
  GET_TOTAL_CALLS_COUNT: 'GET_TOTAL_CALLS_COUNT',
  SET_CALLS_PAGE: 'SET_CALLS_PAGE',
  SET_CALLS_ROWS: 'SET_CALLS_ROWS',
  SET_CALLS_STORES: 'SET_CALLS_STORES',
  SET_CALLS_TIMESTAMP: 'SET_CALLS_TIMESTAMP',
  GET_CALENDAR_SUCCESS: 'GET_CALENDAR_SUCCESS',
  SET_CALENDAR_TIMESTAMP: 'SET_CALENDAR_TIMESTAMP',
  GET_CALL_STATISTICS: 'GET_CALL_STATISTICS',
  SET_CALL_STATISTICS_TIMESTAMP: 'SET_CALL_STATISTICS_TIMESTAMP',
  GET_ORDER_STATISTICS: 'GET_ORDER_STATISTICS',
  SET_ORDER_STATISTICS_TIMESTAMP: 'SET_ORDER_STATISTICS_TIMESTAMP',
};
