import { type AccountFieldKeyValuePair } from '@flipdish/api-client-typescript';

const getDynamicLabel = (
  value: Flipdish.AccountFieldKeyValuePair['Key'] | AccountFieldKeyValuePair.KeyEnum
): TranslationId => {
  {
    switch (value) {
      case 1:
      case 'Iban':
        return 'Iban';
      case 2:
      case 'AccountNumber':
        return 'Account_number';
      case 3:
      case 'RoutingNumber':
        return 'Routing_number';
      case 4:
      case 'BSB':
        return 'BSB_code';
      case 5:
      case 'BranchCode':
        return 'Branch_code';
      case 6:
      case 'BankCode':
        return 'Bank_code';
      case 7:
      case 'InstitutionNumber':
        return 'Institution_number';
      case 8:
      case 'TransitNumber':
        return 'Transit_number';
      case 9:
      case 'ClearingCode':
        return 'Clearing_code';
      case 10:
      case 'Ifsccode':
        return 'IFSC_code';
      case 11:
      case 'Clabe':
        return 'CLABE';
      case 12:
      case 'SortCode':
        return 'Sort_code';
      case 13:
      case 'Swift':
        return 'Swift_bic';
      default:
        return value as TranslationId;
    }
  }
};

const getDynamicKey = (value) => {
  {
    switch (value) {
      case 1:
        return 'Iban';
      case 2:
        return 'AccountNumber';
      case 3:
        return 'RoutingNumber';
      case 4:
        return 'Bsb';
      case 5:
        return 'BranchCode';
      case 6:
        return 'BankCode';
      case 7:
        return 'InstitutionNumber';
      case 8:
        return 'TransitNumber';
      case 9:
        return 'ClearingCode';
      case 10:
        return 'IfscCode';
      case 11:
        return 'Clabe';
      case 12:
        return 'SortCode';
      case 13:
        return 'Swift';
      default:
        return;
    }
  }
};

export { getDynamicKey, getDynamicLabel };
