import axios from 'axios';
import moment from 'moment';
import qs from 'qs';

import { StoreSearchResult } from '@fd/ui/Filter/StoreByCurrencyFilter';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';
import { mapDurationToGranularity } from './helpers';
import {
  fieldToTranslationId,
  getBoundsQuery,
  getQuery,
  getSortQuery,
  getStoreSearchQuery,
  getVoucherQuery,
  serializeOrdersData,
  serializeOverviewData,
  updateLanguageHeader,
} from './services.helpers';
import {
  GranularityProps,
  MapProps,
  OrderReportDetailService,
  PaginationProps,
  PeriodPresetEnum,
  ReportApiProps,
  SortProps,
} from './types';

const baseURL = getApiEndPoint();

const orderReportApi = axios.create({
  baseURL: `${baseURL}/reporting/v1.0`,
  withCredentials: true,
});

const portalApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

export const isUserBetaTester = async () => {
  try {
    const resp = await portalApi.get('/users/roles');
    const isBeta = resp.data.Data.some((d) => d === 'BetaTester');
    return isBeta;
  } catch (err) {
    console.log(new Error(err));
  }
};

export const getOrderReportOverviewService = async ({
  AppId,
  PeriodType,
  MainPeriod,
  ComparePeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  DeliveryZones,
}: ReportApiProps) => {
  try {
    const duration = moment(MainPeriod.endDate).diff(MainPeriod.startDate, 'd');
    const granularity: GranularityProps[] = mapDurationToGranularity(duration);
    // For V1 we arbitratily use the lowest valid granularity setting we can
    const query = getQuery({
      Platform,
      DeliveryType,
      GraphGranularity: granularity[0],
      StoreId,
      PeriodType,
      MainPeriod,
      ComparePeriod,
      IANATimezone,
      DeliveryZones,
    });

    const resp = await orderReportApi.get(`/${AppId}/order/overview?${query}${VoucherCodes || ''}`);
    const isCurrentOnly = !ComparePeriod || (ComparePeriod && ComparePeriod.value === 0);

    const finalData = serializeOverviewData(
      resp.data,
      MainPeriod,
      ComparePeriod,
      granularity[0],
      isCurrentOnly,
      PeriodType
    );
    return finalData;
  } catch (incomingError) {
    console.log('getOrderReportOverviewService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getOrderReportDetailService = async ({
  AppId,
  PeriodType,
  MainPeriod,
  ComparePeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  DeliveryZones,
}: ReportApiProps): Promise<OrderReportDetailService> => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      ComparePeriod,
      IANATimezone,
      DeliveryZones,
    });
    const ordersByPlatformRequest = await orderReportApi.get<Reports.OrdersPerPlatform[]>(
      `/${AppId}/order/platform?${query}${VoucherCodes}`
    );
    const ordersByDeliveryTypeRequest = await orderReportApi.get<Reports.OrdersPerDeliveryType[]>(
      `/${AppId}/order/deliverytype?${query}${VoucherCodes}`
    );
    const ordersByPaymentTypeRequest = await orderReportApi.get<Reports.OrdersPerPaymentType[]>(
      `/${AppId}/order/paymenttype?${query}${VoucherCodes}`
    );
    return {
      Orders_by_platform: ordersByPlatformRequest.data,
      Delivery_vs_collection: ordersByDeliveryTypeRequest.data,
      Cash_vs_paid_online: ordersByPaymentTypeRequest.data,
    };
  } catch (incomingError) {
    console.log('getOrderReportDetailService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getRejectedOrdersByReasonService = async ({
  AppId,
  PeriodType,
  MainPeriod,
  ComparePeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  DeliveryZones,
}: ReportApiProps) => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      ComparePeriod,
      IANATimezone,
      DeliveryZones,
    });

    const resp = await orderReportApi.get(
      `/${AppId}/order/rejectionreason?${query}${VoucherCodes}`
    );

    const response = fieldToTranslationId(resp.data, 'rejectionReason');
    return response;
  } catch (incomingError) {
    console.log('getRejectedOrdersByReasonService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

type TableProps = ReportApiProps & SortProps & PaginationProps & { LanguageCode?: string };
export const getOrderByStoreData = async ({
  AppId,
  PeriodType,
  MainPeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: TableProps) => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      IANATimezone,
      ExportAsCSV,
      DeliveryZones,
    });
    const resp = await orderReportApi.get(`/${AppId}/order/store?${query}${VoucherCodes}`, {
      headers: updateLanguageHeader({ ExportAsCSV, LanguageCode }),
    });
    if (ExportAsCSV) {
      return;
    }
    return resp.data;
  } catch (incomingError) {
    console.log('getOrderByStoreData Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getOrdersData = async ({
  AppId,
  Page,
  PageSize,
  Columns,
  PeriodType,
  MainPeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  SortKey,
  SortDirection,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: TableProps) => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      IANATimezone,
      Page,
      PageSize,
      Columns,
      ExportAsCSV,
      DeliveryZones,
    });
    const sortQuery = getSortQuery(SortKey, SortDirection);
    const resp = await orderReportApi.get(
      `/${AppId}/order/list?${query}${sortQuery}${VoucherCodes}`,
      { headers: updateLanguageHeader({ ExportAsCSV, LanguageCode }) }
    );
    if (ExportAsCSV) {
      return;
    }
    const serializedData = serializeOrdersData(resp.data.result);

    return { result: serializedData, pagination: resp.data.pagination };
  } catch (incomingError) {
    console.log('getOrdersData Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getRejectedOrdersData = async ({
  AppId,
  Page,
  PageSize,
  Columns,
  PeriodType,
  MainPeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  SortKey,
  SortDirection,
  ExportAsCSV,
  DeliveryZones,
  LanguageCode,
}: TableProps) => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      IANATimezone,
      Page,
      PageSize,
      Columns,
      ExportAsCSV,
      DeliveryZones,
    });
    const sortQuery = getSortQuery(SortKey, SortDirection);
    const resp = await orderReportApi.get(
      `/${AppId}/order/rejectedlist?${query}${sortQuery}${VoucherCodes}`,
      { headers: updateLanguageHeader({ ExportAsCSV, LanguageCode }) }
    );
    if (ExportAsCSV) {
      return;
    }

    const result = fieldToTranslationId(resp.data.result, 'rejectedReason');
    return { result, pagination: resp.data.pagination };
  } catch (incomingError) {
    console.log('getRejectedOrdersData Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getRejectionsByStoreData = async ({
  AppId,
  PeriodType,
  MainPeriod,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
  DeliveryZones,
}: TableProps) => {
  try {
    const query = getQuery({
      Platform,
      DeliveryType,
      StoreId,
      PeriodType,
      MainPeriod,
      IANATimezone,
      DeliveryZones,
    });
    const resp = await orderReportApi.get(`/${AppId}/order/store/rejected?${query}${VoucherCodes}`);

    const response = fieldToTranslationId(resp.data, 'mostCommonReason');
    return response;
  } catch (incomingError) {
    console.log('getRejectionsByStoreData Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

type HeatmapProps = ReportApiProps & MapProps;
export const getHeatmapDatapointsService = async ({
  AppId,
  PeriodType,
  MainPeriod,
  ComparePeriod,
  MapBounds,
  Zoom,
  StoreId,
  DeliveryType,
  Platform,
  VoucherCodes,
  IANATimezone,
}: HeatmapProps) => {
  try {
    const duration = moment(MainPeriod.endDate).diff(MainPeriod.startDate, 'd');
    const granularity: GranularityProps[] = mapDurationToGranularity(duration);
    const boundsQuery = getBoundsQuery(MapBounds, Zoom);
    // For V1 we arbitratily use the lowest valid granularity setting we can
    const query = getQuery({
      Platform,
      DeliveryType,
      GraphGranularity: granularity[0],
      StoreId,
      PeriodType,
      MainPeriod,
      ComparePeriod,
      IANATimezone,
    });
    const resp = await orderReportApi.get(
      `/${AppId}/order/heatmap?${query}${boundsQuery}${VoucherCodes}`
    );
    return resp.data;
  } catch (incomingError) {
    console.log('getHeatmapDatapoints Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getVouchersService = async (
  AppId,
  PeriodType: PeriodPresetEnum,
  MainPeriod: { startDate: Date | string; endDate: Date | string },
  StoreId: number[],
  Search: string,
  MaxResults: number
) => {
  try {
    const query = getVoucherQuery(StoreId, PeriodType, MainPeriod, Search, MaxResults);
    const resp = await orderReportApi.get(`/${AppId}/voucher/search?${query}`);
    return resp.data.rows;
  } catch (incomingError) {
    console.log('getVouchersService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export type GetStoreSearchService = {
  AppId: string;
  search?: string;
  currency?: CurrencyEnum;
  maxPerStoreGroup: number;
};
export const getStoreSearchService = async ({
  AppId,
  search,
  currency,
  maxPerStoreGroup,
}: GetStoreSearchService) => {
  try {
    const query = getStoreSearchQuery({ query: search, currency, maxPerStoreGroup });
    const resp = await orderReportApi.get(`/${AppId}/stores/search?${query}`);
    return resp.data as StoreSearchResult[];
  } catch (incomingError) {
    console.log('getStoreSearchService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};
export const downloadCSVService = async (AppId: string, Guid: string) => {
  try {
    const resp = await orderReportApi.get<string>(`/${AppId}/csv/${Guid}`);
    return resp.data;
  } catch (incomingError) {
    console.log('downloadCSVService Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getDeliveryZonesService = async (AppId: string, StoreId?: number[] | string[]) => {
  try {
    const query = qs.stringify(StoreId, {
      skipNulls: true,
      encodeValuesOnly: true,
      indices: false,
    });
    const resp = await orderReportApi.get(`/${AppId}/deliveryzones?${query}`);
    return resp.data;
  } catch (incomingError) {
    console.log('getDeliveryZones Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getLookerSsoEmbedUrlService = async (
  AppId: string,
  dashboard: string,
  filters: { [key: string]: any } = {}
) => {
  try {
    const resp = await portalApi.get(
      `/${AppId}/looker/sso?embedPath=/embed/dashboards/${dashboard}&filters=${JSON.stringify(
        filters
      )}`
    );
    return resp.data.EmbedUrl;
  } catch (incomingError) {
    console.log('getLookerSsoEmbedUrl Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};

export const getLookerDashboardsService = async (AppId: string) => {
  try {
    const resp = await portalApi.get(`/${AppId}/looker/dashboards`);
    return resp.data.Data;
  } catch (incomingError) {
    console.log('getLookerSsoEmbedUrl Error: ', incomingError);
    const err = await mapServerError(incomingError);
    throw err;
  }
};
