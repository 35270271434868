import React from 'react';

import { Field as FdField } from '@flipdish/api-client-typescript';
import { UseMutateFunction } from '@tanstack/react-query';
import { Field } from 'formik';

import { Button } from '@fd/ui/atoms';

export type InputFieldProps = {
  fieldData: FdField;
  onDyanmicButtonClick: UseMutateFunction<any, any, any>;
  isDynamicButtonLoading: boolean;
};

const ActionButtonField = ({
  onDyanmicButtonClick,
  fieldData,
  isDynamicButtonLoading,
}: InputFieldProps) => {
  const isDisabled = fieldData.DefaultValue?.includes('disabled');
  return (
    <Field name={fieldData.Key}>
      {() => {
        return (
          <Button
            variant="secondary"
            disabled={isDisabled || isDynamicButtonLoading}
            onClick={() => {
              onDyanmicButtonClick({ key: fieldData.Key, fieldName: fieldData.Name });
            }}
            fdKey={fieldData.Key}
          >
            {fieldData.Name}
          </Button>
        );
      }}
    </Field>
  );
};

export default ActionButtonField;
