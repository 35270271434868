import * as FlipdishApi from '@flipdish/api-client-typescript';
import { getActiveLanguage } from 'react-localize-redux';

import { accountConstants } from '../constants/account.constants';
import { CookiesPreferences } from '../helpers/gdpr';
import { timeZoneDefinitions } from '../helpers/timeZones';
import { notifyError } from '../layouts/Notify/actions';
import { accountService } from '../services/account.service';
import { getFlipdishAuthorizationToken } from '../services/auth.service';
import { googleAnalytics } from '../services/google_analytics';
import { closeConnection } from '../services/signalr';
import { languageActions } from './language.actions';
import { action, actionError } from './utils';

function setTimeZoneBasedOnLatLng(latitude: number, longitude: number) {
  return (dispatch) => {
    accountService.getIanaTimeZoneFromGmaps(latitude, longitude).then((timeZoneId) => {
      dispatch(update({ TimeZoneInfoId: timeZoneId }));
    });
  };
}

// #region getSupportedCountries
const { GET_SUPPORTED_COUNTRIES } = accountConstants;

export type GetSupCountries = ReturnType<typeof getSupCountries>;
export const getSupCountries = (props) => action(GET_SUPPORTED_COUNTRIES, props);

function getSupportedCountries(throwError = false) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const supportedCountries = await accountService.getSupCountries();
      dispatch(getSupCountries(supportedCountries));
    } catch (error) {
      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region update
const { UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE } = accountConstants;

export type UpdateRequest = ReturnType<typeof updateRequest>;
export const updateRequest = (props: FlipdishApi.AccountDetailBase) =>
  action(UPDATE_REQUEST, props);

export type UpdateSuccess = ReturnType<typeof updateSuccess>;
export const updateSuccess = (props: FlipdishApi.AccountDetailBase) =>
  action(UPDATE_SUCCESS, props);

export type UpdateFailure = ReturnType<typeof updateFailure>;
export const updateFailure = (props: FlipdishApi.AccountDetailBase, error: Error) =>
  actionError(UPDATE_FAILURE, props, error);

function update(props: FlipdishApi.AccountDetailBase) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updateRequest(props));
      await accountService.updateAccount(props);
      dispatch(updateSuccess(props));
      dispatch(getAccountDetails());
    } catch (error) {
      dispatch(updateFailure(props, error));
      throw error;
    }
  };
}
//#endregion

// #region getAccountDetails
const { GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE } = accountConstants;

export const getAccountDetailsRequest = () => ({
  type: GET_ACCOUNT_REQUEST,
});

export const getAccountDetailsSuccess = (details: FlipdishApi.AccountDetail) => ({
  type: GET_ACCOUNT_SUCCESS,
  payload: details,
});

export const getAccountDetailsFailure = (error: Error) => ({
  type: GET_ACCOUNT_FAILURE,
  payload: error,
});

export function getAccountDetails(throwError = false) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getAccountDetailsRequest());

      const account = await accountService.getAccountDetails();
      dispatch(getAccountDetailsSuccess(account));

      if (account.Language) {
        dispatch(languageActions.setLanguage(account.Language));
      }

      return account;
    } catch (error) {
      dispatch(getAccountDetailsFailure(error));
      if (throwError) {
        return Promise.reject(error);
      }
    }
  };
}
// #endregion

// #region Auth0

const { LOGIN_AUTH0_FAILURE } = accountConstants;

export const loginAuth0Failure = (error: Error) => actionError(LOGIN_AUTH0_FAILURE, error, null);

export function loginAuth0(code: string) {
  return async (dispatch: ThunkDispatch) => {
    try {
      await getFlipdishAuthorizationToken(code);
      await dispatch(getAccountDetails());
    } catch (error) {
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
      dispatch(loginAuth0Failure(error));
      throw error;
    }
  };
}
// #endregion

// #region logout
const { LOGOUT_FAILURE } = accountConstants;

export type LogoutFailure = ReturnType<typeof logoutFailure>;
export const logoutFailure = (error: Error) => actionError(LOGOUT_FAILURE, undefined, error);

async function redirectToAuth0ForLogout(languageCode: string) {
  // For the full list of paramters and how logout works, see https://auth0.com/docs/authenticate/login/logout/log-users-out-of-auth0
  const logoutUrl = window.location.origin;
  const { Auth0Domain, Auth0ClientId } = await accountService.getAuth0Config();
  const oidcLogout = `https://${Auth0Domain}/oidc/logout?post_logout_redirect_uri=${logoutUrl}&client_id=${Auth0ClientId}&ui_locales=${languageCode}`;
  window.location.assign(oidcLogout);
}

export function logout() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      closeConnection();
      await accountService.logout();
      await redirectToAuth0ForLogout(getActiveLanguage(getState().locale));
    } catch (error) {
      dispatch(logoutFailure(error));
      throw error;
    }
  };
}
// #endregion

function skipSignupStep(stepEnum: FlipdishApi.SignupStep.ActionEnum) {
  return (dispatch) => {
    dispatch(request());
    accountService.skipSignupStep(stepEnum).then(
      () => {
        dispatch(success());
        dispatch(getAccountDetails());
        googleAnalytics.sendSuccessfulSignUp();
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: accountConstants.SKIP_SIGNUP_STEP_REQUEST };
  }
  function success() {
    return { type: accountConstants.SKIP_SIGNUP_STEP_SUCCESS };
  }
  function failure(error) {
    return { type: accountConstants.SKIP_SIGNUP_STEP_FAILURE, error };
  }
}

function answerSignupQuestion(answerId: number) {
  return (dispatch) => {
    dispatch(request());
    accountService.answerSignupQuestion(answerId).then(
      () => {
        dispatch(success());
        dispatch(getAccountDetails());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_REQUEST };
  }
  function success() {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_SUCCESS };
  }
  function failure(error) {
    return { type: accountConstants.ANSWER_SIGNUP_QUESTION_FAILURE, error };
  }
}

function getLocalisedTimeZones() {
  return (dispatch) => {
    dispatch(request());
    accountService.getLocalisedTimeZones().then(
      (data) => {
        dispatch(success(data));
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: accountConstants.GET_LOCALIZED_TIMEZONES_REQUEST };
  }
  function success(timeZones) {
    return {
      type: accountConstants.GET_LOCALIZED_TIMEZONES_SUCCESS,
      timeZones,
    };
  }
  function failure() {
    return {
      type: accountConstants.GET_LOCALIZED_TIMEZONES_FAILURE,
      timeZones: timeZoneDefinitions,
    };
  }
}

//#region GDPR

export const setGdprSelection = (preferences: CookiesPreferences | undefined) => ({
  type: 'ACCOUNT_SET_GDPR',
  payload: preferences,
});

//#endregion

export const accountActions = {
  answerSignupQuestion,
  getAccountDetails,
  getLocalisedTimeZones,
  getSupportedCountries,
  logout,
  setGdprSelection,
  setTimeZoneBasedOnLatLng,
  skipSignupStep,
  update,
};
