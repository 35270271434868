import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Field, FieldAttributes, FieldProps } from 'formik';

import { ComponentProps } from '../Common';
import { Logger } from '../Logger/Logger';
import { FormHelperText } from './FormHelperText';
import { getValidators, validate, ValidationItem } from './Validation';

// basic height info : 16 top, 34 component height and 16 bottom padding
const useStyles = makeStyles({
  root: ({ removeTopPadding }: Props) =>
    removeTopPadding
      ? {
          height: 'auto',
          paddingTop: 0,
        }
      : {
          height: 66, // 16 top, 34 component height and 16 bottom padding
          paddingTop: 16,
        },
});

type Props = {
  removeTopPadding?: boolean;
  className?: string;
  componentProps: ComponentProps;
  children: FieldAttributes<any>['children'];
  showHelperText?: boolean;
  validateFunc?: any;
};

// Adapter props for preventing copy-paste & use any form-library in future.
export type TFkFieldProps = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  // Field extended properties
  fieldEx: {
    value: unknown;
    fieldError?: string;
    showError: boolean;
    isTouched: boolean;
  };
};

export const FkField = (props: Props) => {
  const classes = useStyles(props);

  const { componentProps } = props;
  const validators = useMemo<ValidationItem<ComponentProps>[]>(
    () => getValidators(componentProps),
    [componentProps]
  );

  const createFkFieldProps = ({ form, field }: FieldProps): TFkFieldProps => {
    const { errors, touched } = form;
    const fieldError = errors[field.name] as string | undefined;
    const isTouched = !!touched[field.name];
    const showError = !!fieldError && isTouched;

    return {
      field,
      form,
      fieldEx: {
        value: field.value,
        fieldError,
        showError,
        isTouched,
      },
    };
  };

  return (
    <Box className={clsx(classes.root, props.className)}>
      <Field
        name={componentProps.name}
        validate={(val) =>
          (!componentProps.disabled && props.validateFunc && props.validateFunc(val)) ||
          (!componentProps.disabled && validate(validators, val, componentProps))
        }
      >
        {(fieldProps: FieldProps) => {
          const fkFieldProps = createFkFieldProps(fieldProps);
          const { showError, fieldError } = fkFieldProps.fieldEx;
          return (
            <>
              <Logger data={{ componentProps: props.componentProps, fieldProps }} />
              {props.children(fkFieldProps)}
              {props.showHelperText && (
                <FormHelperText error={showError}>{showError ? fieldError : null}</FormHelperText>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};
