import React from 'react';

import { type Theme } from '@mui/material/styles';
import withStyles, { type StyleRules, type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

const styles = (theme: Theme): StyleRules => ({
  buttonGroup: {
    '& > button': {
      '&:first-child': {
        borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      },
      '&:only-child': {
        borderRadius: `${theme.shape.borderRadius}px`,
      },
      '&:not(:first-child)': {
        borderRadius: `0`,
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      },
    },
  },
});
type Props = {
  children: React.ReactNode;
  classes: Record<string, string>;
};

export default compose<WithStyles<typeof styles>, {}>(
  withStyles(styles, {
    name: 'FdButtonGroup',
    withTheme: true,
  })
)(({ children, classes }: Props) => {
  return <div className={classes.buttonGroup}>{children}</div>;
});
