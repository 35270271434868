import React, { useEffect, useMemo, useState } from 'react';

import EventIcon from '@mui/icons-material/Event';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as qs from 'qs';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import Select, { components } from 'react-select';
import { compose } from 'recompose';

import ErrorBoundary from '../layouts/Portal/ErrorBoundary';
import Loading from '../ui/Loading';
import DateRangeSelector from './DateRangeSelector';
import { getPreviousPeriod, onPresetPeriodChange, presetRanges } from './Reports/helpers';
import { WithRouteSearchParamsProps } from './WithRouteSearchParams';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'flex',
    padding: theme.spacing(1, 0, 1, 1),
    '& >div:nth-child(2) span': {
      display: 'none',
    },
    minHeight: 40,
    height: '100%',
  },
  inputSelection: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    paddingLeft: theme.spacing(0),
  },
  menuPaper: {
    position: 'absolute' as const,
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: theme.spacing(0),
    right: theme.spacing(0),
  },
  select: {
    zIndex: 9,
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.54)',
    },
  },
  eventIcon: {
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
  },
  menuContainer: {
    zIndex: 1000,
  },
}));

// #region display options
function DropdownIndicator(props) {
  const classes = useStyles();
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <EventIcon className={classes.eventIcon} />
      </components.DropdownIndicator>
    )
  );
}

function LoadingIndicator(props) {
  return <Loading size={20} />;
}
function NoOptionsMessage(props) {
  const classes = useStyles();
  return (
    <Typography className={classes.noOptionsMessage} variant="body1" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const classes = useStyles();
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-fd={`orders-store-${props.data.value}`}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const classes = useStyles();
  return (
    <Typography className={classes.placeholder} variant="body1" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function Menu(props) {
  const classes = useStyles();
  return (
    <Paper square className={classes.menuPaper} {...props.innerProps}>
      <MenuList>{props.children}</MenuList>
    </Paper>
  );
}
// #endregion

const customComponents = {
  Control,
  DropdownIndicator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  LoadingIndicator,
};

type DateOption = {
  label: string | undefined;
  value: number | undefined;
};

type DateFilterProps = {
  onSelectDate?: moment.Moment | undefined;
  selectLabel: string;
  placeholderText?: string;
  datePeriodDefault?: string;
  shouldUseUrlParams?: boolean;
  singleStore?: boolean;
  customRange?;
  maxWidth?: number;
  isCompare?: boolean;
  includeTodayInDatePeriod?: boolean;
  onChange: (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    previousPeriod?
  ) => void;
};

type PrevPeriodProps = {
  value: number;
  url?: string | undefined;
  startDate?: moment.Moment | undefined;
  endDate?: moment.Moment | undefined;
};

type InnerProps = ReturnType<typeof mapStateToProps> & WithRouteSearchParamsProps<string[]>;
type OuterProps = DateFilterProps;
type Props = InnerProps & OuterProps;

const DateFilter = (props: Props & RouteComponentProps) => {
  const classes = useStyles();
  const {
    location,
    history,
    translate,
    customRange,
    isCompare,
    includeTodayInDatePeriod,
    selectLabel,
    placeholderText,
    onChange,
    datePeriodDefault,
  } = props;
  const [selectedDate, setSelectedDate] = useState<DateOption>();
  const [dateOptions, setDateOptions] = useState<DateOption[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const dateRanges = customRange || presetRanges;

  useEffect(() => {
    getDateRanges();
  }, []);

  useEffect(() => {
    //if !custom (anything over 0) and datePicker is not open then apply date
    if (selectedUrlDaterange.value > 0 && datePickerOpen != true) {
      onApplyDate();
    }
  }, [selectedDate]);

  const getRouteSearch = () => {
    if (location.search !== '') {
      const searchProps = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      return searchProps || {};
    }
    return undefined;
  };

  // Get default states for daterange / previous period
  const setup = () => {
    let defaultStartDate;
    let defaultEndDate;
    let defaultPrevPeriod;
    let defaultDaterange;
    const currentSearch = getRouteSearch();
    if (currentSearch) {
      // We get url query params
      const newSearch = currentSearch;
      if (currentSearch.daterange) {
        // If we have daterange params
        const preset = dateRanges.find((pr) => pr.url === currentSearch.daterange);
        const { start, end } = onPresetPeriodChange(
          Number(preset.value),
          undefined,
          includeTodayInDatePeriod
        );
        defaultStartDate = { moment: start };
        defaultEndDate = { moment: end };
        defaultDaterange = { value: preset.value, url: preset.url };
        newSearch.start = start as any;
        newSearch.end = end as any;

        if (currentSearch.comparewith) {
          // Handle previous period
          let urlPrevPeriod;
          if (currentSearch.comparewith === 'lastyear') {
            // Compare with last year
            urlPrevPeriod = {
              value: 20,
              url: currentSearch.comparewith,
              startDate: moment()
                .year(moment(start).year() - 1)
                .startOf('day'),
              endDate: moment()
                .year(moment(end).year() - 1)
                .endOf('day'),
            };
          } else {
            // Compare with previous period
            const event = { target: { value: 10 } };
            const sp = getPreviousPeriod(
              event,
              defaultStartDate,
              defaultEndDate,
              defaultDaterange.url
            );
            urlPrevPeriod = sp;
          }
          newSearch.previousPeriod = urlPrevPeriod;
          defaultPrevPeriod = urlPrevPeriod;
        } else {
          // None
          const event = { target: { value: 0 } };
          const sp = getPreviousPeriod(
            event,
            defaultStartDate,
            defaultEndDate,
            defaultDaterange.url
          );
          defaultPrevPeriod = sp;
        }
      }

      // #region custom daterange
      else if (currentSearch.fp_start) {
        // If there is no daterange, we can have start/end period in the url
        const urlStartDate = moment(currentSearch.fp_start as any, 'YYYY-MM-DD').startOf('day');
        const urlEndDate = moment(currentSearch.fp_end as any, 'YYYY-MM-DD').endOf('day');
        defaultStartDate = { moment: urlStartDate };
        defaultEndDate = { moment: urlEndDate };
        defaultDaterange = { value: 0, url: '' };
        newSearch.start = urlStartDate as any;
        newSearch.end = urlEndDate as any;
        if (currentSearch.comparewith) {
          // Get comparison period start / end
          let urlPrevPeriod;
          if (currentSearch.comparewith === 'lastyear') {
            // Compare with last year
            urlPrevPeriod = {
              value: 20,
              url: currentSearch.comparewith,
              startDate: moment()
                .year(moment(defaultStartDate.moment).year() - 1)
                .startOf('day'),
              endDate: moment()
                .year(moment(defaultEndDate.moment).year() - 1)
                .endOf('day'),
            };
          } else {
            // Compare with previous period
            const event = { target: { value: 10 } };
            const sp = getPreviousPeriod(
              event,
              defaultStartDate,
              defaultEndDate,
              defaultDaterange.url ? defaultDaterange.url : 'custom'
            );
            urlPrevPeriod = sp;
          }
          newSearch.previousPeriod = urlPrevPeriod;
          defaultPrevPeriod = urlPrevPeriod;
        } else {
          // None
          const event = { target: { value: 0 } };
          const sp = getPreviousPeriod(
            event,
            defaultStartDate,
            defaultEndDate,
            defaultDaterange.url ? defaultDaterange.url : 'custom'
          );
          defaultPrevPeriod = sp;
        }
      } else {
        //#region set default filters
        const presetDateRange = datePeriodDefault
          ? dateRanges.find((pr) => pr.url === datePeriodDefault)
          : { value: 4, url: 'last30Days' };
        defaultDaterange = { value: presetDateRange.value, url: presetDateRange.url };
        const { start, end } = onPresetPeriodChange(
          Number(defaultDaterange.value),
          moment(),
          includeTodayInDatePeriod
        );
        defaultStartDate = { moment: start };
        defaultEndDate = { moment: end };
        const event = { target: { value: 10 } };
        const sp = getPreviousPeriod(event, defaultStartDate, defaultEndDate, defaultDaterange.url);
        defaultPrevPeriod = sp;
        newSearch.start = start as any;
        newSearch.end = end as any;
        newSearch.previousPeriod = defaultPrevPeriod;
        //#endregion

        //#region set default search
        const defaultSearch = isCompare
          ? {
              daterange: defaultDaterange.url,
              comparewith: 'previousperiod',
            }
          : {
              daterange: defaultDaterange.url,
            };
        const search = qs.stringify(defaultSearch, {
          skipNulls: true,
          encodeValuesOnly: true,
          indices: false,
        });
        const newLocation = { ...location, search };
        history.replace(newLocation);
        //#endregion
      }
      // #endregion
      // We call onChange to trigger a filter update / api call with current params
      onChange(newSearch.start as any, newSearch.end as any, newSearch.previousPeriod);
    } else {
      // If no query params, we set some default ones
      // (datePeriodDefault || last 30 days / compare with previous period)

      //#region set default filters
      const presetDateRange = datePeriodDefault
        ? dateRanges.find((pr) => pr.url === datePeriodDefault)
        : { value: 4, url: 'last30Days' };
      defaultDaterange = { value: presetDateRange.value, url: presetDateRange.url };
      const defaultPeriod = onPresetPeriodChange(
        Number(defaultDaterange.value),
        undefined,
        includeTodayInDatePeriod
      );
      defaultStartDate = { moment: defaultPeriod.start };
      defaultEndDate = { moment: defaultPeriod.end };
      const event = { target: { value: 10 } };
      const sp = getPreviousPeriod(event, defaultStartDate, defaultEndDate, defaultDaterange.url);
      defaultPrevPeriod = sp;
      //#endregion

      //#region set default search
      const defaultSearch = isCompare
        ? {
            daterange: defaultDaterange.url,
            comparewith: 'previousperiod',
          }
        : {
            daterange: defaultDaterange.url,
          };
      const search = qs.stringify(defaultSearch, {
        skipNulls: true,
        encodeValuesOnly: true,
        indices: false,
      });
      const newLocation = { ...location, search };

      history.replace(newLocation);

      //#endregion
      onChange(defaultStartDate.moment, defaultEndDate.moment, defaultPrevPeriod);
    }
    return {
      defaultStartDate,
      defaultEndDate,
      defaultPrevPeriod,
      defaultDaterange,
    };
  };

  const { defaultStartDate, defaultEndDate, defaultPrevPeriod, defaultDaterange } = useMemo(
    () => setup(),
    []
  );

  const defaultPeriod = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  };

  // #region filters
  const [startDate, setStartDate] = useState({
    moment: defaultPeriod.startDate.moment,
    isValid: true,
  });
  const [endDate, setEndDate] = useState({
    moment: defaultPeriod.endDate.moment,
    isValid: true,
  });
  const [selectedUrlDaterange, setSelectedUrlDaterange] = useState(defaultDaterange);
  const [previousPeriod, setPreviousPeriod] = useState<PrevPeriodProps | undefined>(
    defaultPrevPeriod
  );
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  //#endregion

  const toggleDatePicker = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const updateQueryParams = (start, end, previousPeriod, isCustom) => {
    const currentSearch = getRouteSearch();
    let newSearch = currentSearch;
    if (selectedUrlDaterange.value === 0 || isCustom) {
      // If the user selects a custom daterange
      // we set first and second period start/end dates in url
      let fp_start; // first period start
      let fp_end; // first period end
      newSearch = { ...newSearch, ...{ daterange: undefined } };
      fp_start = moment(start).format('YYYY-MM-DD');
      fp_end = moment(end).format('YYYY-MM-DD');
      newSearch = { ...newSearch, ...{ fp_start, fp_end } };
    } else {
      // Otherwise, we just add the daterange keyword
      if (selectedUrlDaterange) {
        newSearch = { ...newSearch, ...{ daterange: selectedUrlDaterange.url } };
        if (newSearch.fp_start) {
          newSearch.fp_start = undefined;
          newSearch.fp_end = undefined;
        }
      }
    }
    if (isCompare) {
      if (previousPeriod && previousPeriod.startDate) {
        newSearch = { ...newSearch, ...{ comparewith: previousPeriod.url } };
      } else {
        if (newSearch?.comparewith) {
          newSearch.comparewith = undefined;
        }
      }
    }
    const search = qs.stringify(newSearch, {
      skipNulls: true,
      encodeValuesOnly: true,
      indices: false,
    });
    const newLocation = { ...location, search };
    history.replace(newLocation);
  };

  // Only runs on calendar date change NOT keyboard change
  const changeDateRange = (
    dates: { startDate: moment.Moment; endDate: moment.Moment },
    preset?: boolean
  ) => {
    if (!preset) {
      // Custom daterange selected from calendar
      setSelectedUrlDaterange({ value: 0, url: 'custom' });
    }
    if (dates.startDate) {
      setStartDate({ moment: dates.startDate, isValid: true });
    }
    if (dates.endDate) {
      if (moment(dates.startDate).isAfter(dates.endDate, 'day')) {
        setEndDate({ ...endDate, isValid: false });
      } else {
        setEndDate({ moment: dates.endDate, isValid: true });
      }
    }
    // endDate = null when user selects startDate > endDate, this lets user select new endDate
    if (!dates.endDate && moment(dates.startDate).isAfter(endDate.moment, 'day')) {
      setEndDate({ ...endDate, isValid: false });
    }
  };

  const onApplyDate = async () => {
    if (!startDate.isValid || !endDate.isValid) {
      return;
    } else {
      if (previousPeriod && selectedUrlDaterange) {
        const event = { target: { value: previousPeriod.value } };
        const prevPeriod = getPreviousPeriod(event, startDate, endDate, selectedUrlDaterange.url);

        setPreviousPeriod(prevPeriod);
        // If user selects first date by calendar and second date by keyboard input then the formatting of dates can be different
        // so we convert them before submitting
        onChange(
          moment(moment(startDate.moment).format('YYYY-MM-DD')),
          moment(moment(endDate.moment).format('YYYY-MM-DD')),
          prevPeriod
        );
        setDatePickerOpen(false);

        updateQueryParams(
          startDate.moment,
          endDate.moment,
          prevPeriod,
          selectedUrlDaterange.value === 0
        );
      }
    }
  };

  const handleDatesChange = (event) => {
    setSelectedDate(event);
    const { value, url } = presetRanges[event.value];
    setSelectedUrlDaterange({ value, url });
    // if its not custom
    if (value !== 0) {
      const { start, end } = onPresetPeriodChange(
        Number(event.value),
        undefined,
        includeTodayInDatePeriod
      );
      changeDateRange({ startDate: start, endDate: end }, true);
    }
    if (event.value === 0) {
      setDatePickerOpen(true);
    }
  };

  //#region parsing
  const getDateRanges = async () => {
    const { appId } = props;
    if (appId) {
      setIsLoading(true);
      try {
        const newDates = parseDateRanges();
        setDateOptions(newDates);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
  };

  const parseDateRanges = () => {
    return dateRanges.map((item) => ({
      label: parseDate(item) as string,
      value: item.value,
    }));
  };

  const parseDate = (item) => {
    return translate(item.label, { period: item.period, year: item.year });
  };
  //#endregion

  return (
    <ErrorBoundary identifier="date-filter">
      <div onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Select
          className={classes.select}
          classes={classes}
          textFieldProps={{
            fdKey: 'Date_filter_select',
            label: selectLabel,
            variant: 'outlined',
            InputLabelProps: {
              shrink: true,
            },
          }}
          components={customComponents}
          options={dateOptions}
          value={selectedDate}
          onChange={handleDatesChange}
          placeholder={
            placeholderText ||
            (parseDate(
              dateRanges.find((pr) => pr.value === selectedUrlDaterange.value)!
            ) as string) ||
            (translate('Select_date') as string)
          }
          isLoading={isLoading}
          isSearchable={false}
        />
      </div>
      {datePickerOpen && (
        <Popper
          open={datePickerOpen}
          anchorEl={anchorEl}
          className={classes.menuContainer}
          disablePortal
          placement="bottom-start"
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              enabled: false,
              options: {
                boundariesElement: 'disabled',
              },
            },
            {
              name: 'hide',
              enabled: false,
            },
          ]}
        >
          <DateRangeSelector
            translate={translate}
            isCompare={isCompare}
            labelText={selectLabel}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedUrlDaterange={selectedUrlDaterange}
            setSelectedUrlDaterange={setSelectedUrlDaterange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            previousPeriod={previousPeriod}
            setPreviousPeriod={setPreviousPeriod}
            customRange={customRange}
            toggleDatePicker={toggleDatePicker}
            onApplyDate={onApplyDate}
            changeDateRange={changeDateRange}
          />
        </Popper>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId,
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(DateFilter);

export default EnhancedComponent;
