import React, { useState } from 'react';

import { Order } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import AcceptButton from './AcceptButton';
import AcceptDialog from './AcceptDialog';
import RejectButton from './RejectOrderButton';

const styles = (theme: Theme) => {
  return createStyles({
    secondaryButton: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
      padding: '9px 24.5px',
      fontSize: '16px',
      letterSpacing: '1.4px',
      color: '#36d88e',
      border: '1px solid #36d88e',
      '&:hover': {
        backgroundColor: '#36d88e',
        color: '#fff',
      },
    },
    otherBtn: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
      padding: '9px 20.25px',
      fontSize: '16px',
      letterSpacing: '1.4px',
      color: '#36d88e',
      border: '1px solid #36d88e',
      '&:hover': {
        border: '1px solid #36d88e',
        backgroundColor: '#36d88e',
        color: '#fff',
      },
    },
  });
};

type Props = {
  deliveryType: Order.DeliveryTypeEnum;
  acceptMinsCollection: Array<{ default: boolean; mins: number }>;
  acceptMinsDelivery: Array<{ default: boolean; mins: number }>;
  acceptOrder: (mins: number) => void;
  rejectOrder: (rejectReason: string) => void;
  deliveryTimeTitle: string;
  collectionTimeTitle: string;
};

const OrderActionButtons: React.FC<React.PropsWithChildren<Props & WithStyles<typeof styles>>> = (
  props
) => {
  const {
    deliveryType,
    acceptMinsCollection,
    acceptMinsDelivery,
    acceptOrder,
    rejectOrder,
    deliveryTimeTitle,
    collectionTimeTitle,
    classes,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <AcceptDialog
        open={isDialogOpen}
        labelText={
          deliveryType === Order.DeliveryTypeEnum.Pickup
            ? (collectionTimeTitle as TranslationId)
            : (deliveryTimeTitle as TranslationId)
        }
        handleAccept={(mins: number) => acceptOrder(mins)}
        handleClose={() => setIsDialogOpen(false)}
      />
      <div>
        {deliveryType === Order.DeliveryTypeEnum.Pickup
          ? acceptMinsCollection.map((mins) => {
              return (
                <AcceptButton
                  key={mins.mins}
                  fdKey={mins.default ? `Accept (${mins.mins}mins)` : `+${mins.mins}Mins`}
                  mins={mins.mins}
                  selected={mins.default}
                  onClick={() => acceptOrder(mins.mins)}
                />
              );
            })
          : acceptMinsDelivery.map((mins) => {
              return (
                <AcceptButton
                  key={mins.mins}
                  fdKey={mins.default ? `Accept (${mins.mins}mins)` : `+${mins.mins}Mins`}
                  mins={mins.mins}
                  selected={mins.default}
                  onClick={() => acceptOrder(mins.mins)}
                />
              );
            })}

        <Button
          variant="outlined"
          data-fd={'other'}
          className={classes.otherBtn}
          onClick={() => setIsDialogOpen(true)}
        >
          <Translate id="Other" />
          <KeyboardArrowRight />
        </Button>
      </div>
      <div>
        <RejectButton handleRejectOrder={(rejectReason: string) => rejectOrder(rejectReason)} />
      </div>
    </>
  );
};

export default withStyles(styles)(OrderActionButtons);
