import axios from 'axios';

const baseURL = 'https://support.flipdish.com';

const zendeskHelpContentApi = axios.create({
  baseURL: `${baseURL}/api/v2/help_center/`,
  withCredentials: false,
});

const getHelpContentKey = 'getHelpDrawerContent';
async function getHelpContent(label: string, language: string) {
  try {
    const result = await zendeskHelpContentApi.get(
      `/${language}/articles.json?label_names=${label}`
    );
    return result.data;
  } catch (e) {}
}

export const helpDrawerService = {
  baseURL,
  getHelpContentKey,
  getHelpContent,
};
