/**
 *
 * WARNING:
 * DO NOT USE it to SANITIZE HTML
 * This should ONLY be USED to STRIP HTML
 */
export const stripHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};
