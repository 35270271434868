import {
  AppConfigSalesChannel,
  RestApiResultAppConfigSalesChannel,
} from '@flipdish/api-client-typescript';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { mobileAppsService } from '../components/SalesChannels/MobileApps/mobileApps.services';
import { getSalesChannelConfigQueryKey } from './useQuerySalesChannelConfigHook';

export const useMutationUpdateSalesChannelConfigHook = (
  appId: string,
  onSuccess: () => void,
  onError: (error) => void
) => {
  const queryClient = useQueryClient();
  const prevConfig = queryClient.getQueryData([
    getSalesChannelConfigQueryKey,
    appId,
  ]) as RestApiResultAppConfigSalesChannel;
  return useMutation({
    mutationFn: (newConfig: Partial<AppConfigSalesChannel>) => {
      const updatedConfig = { ...prevConfig.Data, ...newConfig };
      return mobileAppsService.updateSalesChannelConfig(appId, updatedConfig);
    },

    onSuccess: (_data, variables) => {
      queryClient.setQueryData([getSalesChannelConfigQueryKey, appId], {
        Data: {
          ...prevConfig.Data,
          ...variables,
        },
      });
      onSuccess();
    },

    onError: (error: AxiosError) => {
      onError(error);
    },
  });
};
