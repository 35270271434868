import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import Spacer from '../../../ui/Spacer';

const NoDevices = () => {
  return (
    <Spacer variant={'horizontal'} size={24}>
      <Paper>
        <Spacer size={24}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h5">
                  <Translate id={'no_devices_header'} data={{ deviceType: 'Terminals' }} />
                </Typography>
                <Typography variant="subtitle1">
                  <Translate id="Add_a_new_terminal_today_to_help_manage_orders_and" />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Spacer>
      </Paper>
    </Spacer>
  );
};

export default NoDevices;
