import React, { useEffect, useMemo, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../../layouts/Portal/ErrorBoundary';
import Select from '../../../../../ui/Select/Select';
import withRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../../../WithRouteSearchParams';
import { bankingSelectors } from '../../../Banking/banking.selectors';

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 6,
  },
}));

type Option = {
  value: string;
  label: string;
};

type InnerProps = MappedState & WithRouteSearchParamsProps<string[]>;
type OuterProps = {
  onChange: (selectedItems: number[] | undefined) => void;
};
type Props = InnerProps & OuterProps;

const PayoutsAccountNameFilter = (props: Props) => {
  const classes = useStyles();
  const { accounts, translate, setSearch, onChange, search } = props;
  const [selectedItems, setSelectedItems] = useState<Option[] | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (accounts.length && search) {
      const optionsArray = [] as Option[];
      options.forEach((option) => {
        if (search.includes(option.value)) optionsArray.push(option);
      });

      setSelectedItems(optionsArray);
      handleChange(optionsArray);
    }

    setIsLoading(false);
  }, [accounts]);

  const setUrlParams = (value: string[]) => {
    setSearch(value);
  };

  const handleChange = (options: Option[]) => {
    const optionValues = options.map((option: Option) => option.value);
    const optionNumValues = optionValues.map(Number);

    setSelectedItems(options);
    setUrlParams(optionValues);
    onChange(optionNumValues);
  };

  const options = useMemo(
    () =>
      [
        ...accounts.map((account) => ({
          value: account.Id!.toString(),
          label: account.AccountName!,
        })),
      ] as Option[],
    [accounts]
  );

  return (
    <ErrorBoundary identifier="payouts-account-name-filter">
      <Select
        className={classes.select}
        dataFd="Account-name-filter"
        isClearable
        isMulti
        isLoading={isLoading}
        onChange={handleChange}
        options={options}
        placeholder={translate('All') as TranslationId}
        TextFieldProps={{
          fdKey: 'Account_name_filter',
          label: translate('Bank_account'),
        }}
        value={selectedItems}
      />
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    accounts: bankingSelectors.getBankAccountsExtended(state),
    translate: getTranslate(state.locale),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'bankAccount',
  }),
  connect(mapStateToProps)
)(PayoutsAccountNameFilter);
