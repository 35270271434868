import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

export interface Props {
  showGreenTag?: boolean;
  showBigTag?: boolean;
  showYellowTag?: boolean;
  showPurpleTag?: boolean;
  showBetaTag?: boolean;
  showGreyTag?: boolean;
  noMargin?: boolean;
  alignSelf?: string;
  tagName: TranslationId;
}

type StyleProps = {
  noMargin?: boolean;
  alignSelf?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  tagStyles: {
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    borderRadius: theme.shape.borderRadius,
    color: '#ffffff',
    fontSize: '12px',
    margin: (props) => (props.noMargin ? 0 : theme.spacing(0, 1)),
    padding: theme.spacing(0.5, 2),
    alignSelf: (props) => props.alignSelf ?? 'inherit',
  },
  greenTagStyles: {
    backgroundColor: '#20C26E',
    fontSize: '13px',
  },
  yellowTagStyles: {
    backgroundColor: '#FFBC2C',
    fontSize: '13px',
    color: '#212121',
  },
  purpleTagStyles: {
    backgroundColor: '#DEACFA',
    fontSize: '13px',
  },
  bigTagStyles: {
    backgroundColor: '#20C26E',
    fontSize: '20px',
  },
  betaTagStyles: {
    backgroundColor: '#05149E',
    borderRadius: '3px',
    color: '#ffffff',
    padding: theme.spacing(0.3, 1),
    margin: theme.spacing(0, 1),
    fontSize: '12px',
  },
  greyTagStyles: {
    backgroundColor: '#00000061 38%',
    color: '#ffffff',
    fontSize: '13px',
  },
}));

const Tag = ({
  showGreenTag,
  showYellowTag,
  showPurpleTag,
  showBigTag,
  showBetaTag,
  showGreyTag,
  tagName,
  alignSelf,
  noMargin,
}: Props) => {
  const classes = useStyles({ alignSelf, noMargin });

  const tagStyles = clsx(
    'TagStyles',
    classes.tagStyles,
    showGreenTag && classes.tagStyles && classes.greenTagStyles,
    showBigTag && classes.greenTagStyles && classes.bigTagStyles,
    showYellowTag && classes.tagStyles && classes.yellowTagStyles,
    showPurpleTag && classes.tagStyles && classes.purpleTagStyles,
    showBetaTag && classes.tagStyles && classes.betaTagStyles,
    showGreyTag && classes.tagStyles && classes.greyTagStyles
  );

  return (
    <>
      <span className={tagStyles}>
        <Translate id={tagName} />
      </span>
    </>
  );
};

export default Tag;
