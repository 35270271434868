const POST_LOGIN_REDIRECT_KEY = 'redirectUrl';

export const storePostLoginRedirect = (url: string | null): void => {
  if (!url || !isValidRedirectUrl(url)) {
    return;
  }
  sessionStorage.setItem(POST_LOGIN_REDIRECT_KEY, url);
};

export const executePostLoginRedirect = () => {
  const redirectUrl = sessionStorage.getItem(POST_LOGIN_REDIRECT_KEY);
  if (redirectUrl && isValidRedirectUrl(redirectUrl)) {
    sessionStorage.removeItem(POST_LOGIN_REDIRECT_KEY);
    window.location.href = redirectUrl;
  }
};

const isValidRedirectUrl = (url: string): boolean => {
  if (!url) return false;
  try {
    const parsedUrl = new URL(url);
    const validHosts = ['portal.flipdishdev.com', 'portal.flipdish.com', 'flipdish.dev'];
    return validHosts.some((validHost) => parsedUrl.hostname.includes(validHost));
  } catch (e) {
    return false;
  }
};
