import React, { ChangeEvent } from 'react';

import { type FieldProps, Field } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import TextField from '@fd/ui/TextField/TextField';

import { formikValidate } from '../../../../helpers/validation';

const validateNameField = (value: string) => {
  return formikValidate.max(4000)(value.length);
};

type Props = {
  type: 'delivery' | 'collection';
} & MappedProps;

const CustomerMessageField = ({ type, translate }: Props) => {
  const label: TranslationId = type === 'delivery' ? 'Delivery_message' : 'Collection_message';
  const placeholder: TranslationId =
    type === 'delivery' ? 'Enter_delivery_message' : 'Enter_collection_message';

  return (
    <FormItemLayout label={<Translate id={label} />}>
      <Field name={`${type}Message`} validate={validateNameField}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          return (
            <TextField
              value={field.value}
              fdKey={placeholder}
              autoComplete="off"
              fullWidth
              style={{ paddingRight: 16 }}
              placeholder={translate(placeholder) as string}
              disabled={isSubmitting}
              error={showError}
              helperText={showError ? fieldError : ''}
              FormHelperTextProps={{
                style: { textAlign: 'right' },
              }}
              inputProps={{
                style: {
                  paddingTop: 0,
                  height: '1.1876em',
                },
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(field.name, e.target.value);
              }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});
export default connect(mapStateToProps)(CustomerMessageField);
