import React, { useEffect, useState } from 'react';

import { FulfillmentStatusConfigurationItem } from '@flipdish/api-client-typescript';
import EditIcon from '@mui/icons-material/CreateOutlined';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import EmptyTable from '@fd/ui/GenericTable/EmptyTable';
import GenericTable from '@fd/ui/GenericTable/GenericTable';

import {
  fulfillmentStatusResponsiveTableMeta,
  fulfillmentStatusTableMeta,
} from './FulfillmentStatusTableMeta';
import { FulfillmentStatusTableColumns, FulfillmentStatusTableData } from './types';

const useStyles = makeStyles(() => ({
  Table: {
    width: '100%',
  },
  icon: {
    width: 30,
    height: 30,
  },
}));

type InnerProps = MappedProps;
type OuterProps = {
  loading: boolean;
  tableData: FulfillmentStatusConfigurationItem[] | undefined;
};
type Props = InnerProps & OuterProps & RouteComponentProps;

const FulfillmentStatusTable = (props: Props) => {
  const { loading, tableData, translate, appId } = props;
  const classes = useStyles();
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: FulfillmentStatusTableColumns }>();
  const [pageData, setPageData] = useState<FulfillmentStatusTableData[] | undefined>();
  const theme = useTheme();
  const tableMeta = useMediaQuery(theme.breakpoints.up('md'))
    ? fulfillmentStatusTableMeta
    : fulfillmentStatusResponsiveTableMeta;

  useEffect(() => {
    if (tableData) {
      const newData = tableData.map((d) => {
        return {
          StatusName: { mainText: d.StatusName, subtext: d.StatusId },
          Service: d?.OrderTypes?.join(', '),
          Icon: d.Icon ? <img src={d.Icon} className={classes.icon} /> : undefined,
          DisplayName: d.DisplayName,
          Enabled: d.Enabled ? translate('Enabled') : translate('Disabled'),
          Edit: (
            <IconButton aria-label="edit-status" onClick={() => handleEditButtonClick(d.StatusId)}>
              <EditIcon color="action" />
            </IconButton>
          ),
          Internal: d.Internal
            ? translate('OrderStatus_Table_Private')
            : translate('OrderStatus_Table_Public'),
        };
      }) as FulfillmentStatusTableData[];

      setPageData(newData);
    }
  }, [tableData, tableMeta]);

  useEffect(() => {
    setColumns(
      new Set(
        tableMeta
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      tableMeta.reduce<{ [key: string]: FulfillmentStatusTableColumns }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
  }, [tableMeta]);

  const handleEditButtonClick = (statusId: string | undefined) => {
    const { history, match } = props;
    if (statusId) {
      history.push(`${match.url}/${statusId}`);
    }
  };

  if (!loading && isEmpty(tableData)) {
    return (
      <div className={classes.Table}>
        <EmptyTable ariaTitle={'No_results'} emptyTableTitle={'No_results'} noLink />
      </div>
    );
  }

  return (
    <div className={classes.Table}>
      <GenericTable
        columns={columns}
        data={pageData}
        loading={loading}
        meta={meta}
        metadata={tableMeta}
        onSortKeyPressed={() => undefined}
        page={0}
        rowsPerPage={500}
        showTitle={true}
        title={'Status'}
        AppId={appId}
        languageCode={''}
        order={'desc'}
        orderBy={''}
        setColumns={setColumns}
      />
    </div>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    appId: state.currentApp.AppId as string,
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(FulfillmentStatusTable);
