import React, { useEffect, useState } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import StoreByCurrencyFilter from '@fd/ui/Filter/StoreByCurrencyFilter';

import { Button } from '../../../ui/Button';
import GridContainer from '../../../ui/Layout/GridContainer';
import HiddenFeature from '../../HiddenFeature';
import DateRangeSelector from '../components/Filters/DateRangeSelector';
import DeliveryTypeFilter from '../components/Filters/DeliveryTypeFilter';
import DeliveryZoneFilter from '../components/Filters/DeliveryZoneFilter';
import PlatformFilter from '../components/Filters/PlatformFilter';
import VoucherFilter from '../components/Filters/VoucherFilter';
import { calculateRegionWithPoints } from '../helpers';
import { getDeliveryZones } from '../OrderReport.actions';
import { DeliveryZone, IDeliveryZone, VoucherFilterProps, VoucherProps } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  btnText: {
    color: '#05149e',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: '1.43px',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type Props = {
  onDateChange: (start: moment.Moment, end: moment.Moment, prevPeriod) => void;
  setStoreFilter: (selectedStores: number[] | number) => void;
  setPlatform: (selectedPlatforms: AppTypeEnum[]) => void;
  setDeliveryType: (selectedDeliveryTypes: Array<'delivery' | 'collection'>) => void;
  setVoucherFilter: (voucherFilters: VoucherFilterProps[]) => void;
  onVoucherSearch: (search) => void;
  vouchers: VoucherProps[];
  setCurrency: (currency) => void;
  setStoresBounds: ({ bounds: Bounds, stores }) => void;
  storeZoneOptions?: IDeliveryZone<DeliveryZone[]>;
  setStoreZoneOptions: (opts: IDeliveryZone<DeliveryZone[]>) => void;
  currency?: CurrencyEnum;
  languageCode: string;
  setSelectedZoneIds: (zoneIds: number[]) => void;
} & MappedProps &
  MappedDispatch;

const OrderReportFilters = (props: Props) => {
  const classes = useStyles();
  const {
    onDateChange,
    setStoreFilter,
    setPlatform,
    setDeliveryType,
    translate,
    onVoucherSearch,
    vouchers,
    setVoucherFilter,
    setCurrency,
    setStoresBounds,
    getDeliveryZones,
    storeZoneOptions,
    setStoreZoneOptions,
    currency,
    languageCode,
    setSelectedZoneIds,
  } = props;
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    getDeliveryZonesSearch();
  }, []);

  const getDeliveryZonesSearch = async (search?: number[]) => {
    try {
      const deliveryZones = await getDeliveryZones(search);
      // @ts-ignore keep this here because thunk is not resolving type properly
      const groupedDeliveryZones = groupBy<DeliveryZone>(deliveryZones, 'storeId');
      setStoreZoneOptions(groupedDeliveryZones);
    } catch (err) {
      console.log('err', err);
    }
  };

  const toggleFilters = () => setShowAdvancedFilters(!showAdvancedFilters);

  const selectStore = (stores) => {
    setStoreFilter(stores.map((store) => store.value));
    setCurrencyAndBounds({ stores });
  };

  const setCurrencyAndBounds = ({ stores }) => {
    if (stores.length) {
      setCurrency(stores[0].currency);
      if (stores.find((s) => s.coordinates)) {
        const coords = stores.map((store) => store.coordinates);
        const newBounds = calculateRegionWithPoints(coords);
        setStoresBounds({ bounds: newBounds, stores });
      }
    }
  };

  const setInitialStores = (stores) => {
    if (stores.length) {
      setCurrencyAndBounds({ stores });
    }
  };

  const setInitialCurrency = (currency) => {
    setCurrency(currency);
  };

  const Filters = (
    <>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <DeliveryTypeFilter shouldUseUrlParams onChange={setDeliveryType} />
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <PlatformFilter onChange={setPlatform} />
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridItem}>
        <VoucherFilter
          onChange={setVoucherFilter}
          onSearch={onVoucherSearch}
          voucherSearchCodeResults={vouchers}
        />
      </Grid>
      <Hidden lgUp>
        <HiddenFeature>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <DeliveryZoneFilter
              onChange={setSelectedZoneIds}
              storeZoneOptions={storeZoneOptions}
              currency={currency}
              languageCode={languageCode}
            />
          </Grid>
        </HiddenFeature>
      </Hidden>
    </>
  );

  return (
    <GridContainer>
      <Grid item xs={12} md={6} style={{ zIndex: 10 }} className={classes.gridItem}>
        <DateRangeSelector translate={translate} onChange={onDateChange} />
      </Grid>
      <Grid item xs={12} md={6} style={{ zIndex: 9 }} className={classes.gridItem}>
        <StoreByCurrencyFilter
          onSelectStore={selectStore}
          setInitialStores={setInitialStores}
          setInitialCurrency={setInitialCurrency}
        />
      </Grid>

      <Hidden smUp>
        <Grid item container xs={12} alignItems="center" className={classes.gridItem}>
          {showAdvancedFilters ? (
            <KeyboardArrowUp color="primary" />
          ) : (
            <KeyboardArrowDown color="primary" />
          )}
          <Button
            color="primary"
            type="button"
            fdKey="show/hide-advanced-filters"
            onClick={toggleFilters}
            variant="text"
          >
            <Typography className={classes.btnText}>
              <Translate
                id={showAdvancedFilters ? 'Hide_advanced_filters' : 'Show_advanced_filters'}
              />
            </Typography>
          </Button>
        </Grid>
        {showAdvancedFilters ? Filters : null}
      </Hidden>

      <Hidden smDown>{Filters}</Hidden>
    </GridContainer>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDeliveryZones,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderReportFilters);
