import React, { useState } from 'react';

import clsx from 'clsx';

type HighlightScrollbarProps = {
  classes: Record<any, any>;
  children: React.ReactNode;
  handleScroll?: () => void;
  setIsScrolling?: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollStarted?: React.Dispatch<React.SetStateAction<boolean>>;
};

const HighlightScrollbar: React.FC<React.PropsWithChildren<HighlightScrollbarProps>> = (props) => {
  const { classes, children, handleScroll, setIsScrolling, setScrollStarted } = props;
  const [hoveredTable, setHoveredTable] = useState(false);

  const highlightScrollbar = () => {
    setHoveredTable(true);
  };

  const fadeScrollbar = () => {
    setHoveredTable(false);
    setIsScrolling && setIsScrolling(false);
    setScrollStarted && setScrollStarted(false);
  };

  const divClass = clsx({
    [classes.container]: true,
    [classes.hovered]: hoveredTable,
  });

  return (
    <div
      onMouseEnter={highlightScrollbar}
      onMouseLeave={fadeScrollbar}
      className={divClass}
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};

export default HighlightScrollbar;
