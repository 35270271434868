import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import MuiList from '@mui/material/List';
import MuiListSubheader from '@mui/material/ListSubheader';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import ExpansionPanelHeader from './ExpansionPanelHeader';
import ListItem, { ListItemProps } from './ListItem';
import Options, { OptionsProps } from './Options';

// #region style overrides
const ExpansionPanel = withStyles(
  ({ spacing, breakpoints }: Theme) => ({
    root: {
      // #region HORIZONTAL_SPACE_CLASSNAME
      paddingLeft: `${spacing(3)}`,
      paddingRight: `${spacing(3)}`,
      [breakpoints.only('xs')]: {
        paddingLeft: `${spacing(2)}`,
        paddingRight: `0`,
      },
      // #endregion
    },
  }),
  { name: 'FdExpansionPanel' }
)(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles(
  ({ breakpoints, spacing }: Theme) => ({
    root: {
      paddingLeft: `0`,
      paddingRight: `0`,
      [breakpoints.only('xs')]: {
        paddingRight: `${spacing(2)}`,
      },
    },
  }),
  {
    name: 'FdExpansionPanelSummary',
  }
)(MuiExpansionPanelSummary);
const ExpansionPanelDetails = withStyles(
  () => ({
    root: {
      paddingLeft: `0`,
      paddingRight: `0`,
    },
  }),
  { name: 'FdExpansionPanelDetails' }
)(MuiExpansionPanelDetails);

const List = withStyles(
  () => ({
    root: {
      width: `100%`,
    },
  }),
  { name: 'FdList' }
)(MuiList);
const ListSubheader = withStyles(
  () => ({
    root: {
      lineHeight: '200%',
    },
  }),
  { name: 'FdListSubheader' }
)(MuiListSubheader);
// #endregion

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ options }: { options: boolean }) => {
    return {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: options ? 0 : theme.spacing(3),
    };
  },
}));

type Props = {
  data: FlipdishPrivate.StoreKioskSetting[];
  disabled?: boolean;
  expanded: boolean;
  loading?: boolean;
  onChange: ListItemProps['onChange'];
  onExpandChange(title: string | false): void;
  options?: OptionsProps;
  subtitle?: TranslationId;
  title: TranslationId;
  valueResolver: ListItemProps['valueResolver'];
};

const CollapseList = (props: Props) => {
  const {
    options,
    title,
    subtitle,
    data,
    loading,
    disabled,
    expanded,
    onExpandChange,
    valueResolver,
    onChange,
  } = props;
  const styles = useStyles({ options: !!options });

  return (
    <ExpansionPanel
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      expanded={expanded}
      onChange={(event, nowExpanded) => {
        onExpandChange(nowExpanded ? title : false);
      }}
    >
      <ExpansionPanelSummary
        expandIcon={loading || disabled ? undefined : <ExpandMoreIcon />}
        aria-controls={`panel-${title}-content`}
        id={`panel${title}-header`}
      >
        <ExpansionPanelHeader
          title={title}
          subtitle={subtitle}
          loading={loading}
          disabled={disabled}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: styles.root }}>
        <List
          disablePadding
          subheader={
            <ListSubheader disableGutters>
              <Translate id="Kiosks" />
            </ListSubheader>
          }
        >
          <Divider variant="fullWidth" component="li" />

          {data.map((settings, idx) => (
            <React.Fragment key={idx}>
              <ListItem
                data={settings}
                divider={!options}
                onChange={onChange}
                valueResolver={valueResolver}
              />
              {options && <Options {...options} ariaLabel={title} storeKioskSettings={settings} />}
              {options && idx !== data.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CollapseList;
