import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

import { close, dequeueCloseNotify, dequeueNotify } from './actions';

type Props = MapStateToProps & MapDispatchToProps;
const Notifier = ({ queue, closingQueue, dequeueNotify, dequeueCloseNotify }: Props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    queue.forEach((notification) => {
      const { message, ...options } = notification;
      // Display snackbar using notistack
      enqueueSnackbar(message, options);
      // remove from queue
      dequeueNotify(notification.key);
    });
  }, [queue]);
  useEffect(() => {
    closingQueue.forEach((key) => {
      closeSnackbar(key);
      // remove from queue
      dequeueCloseNotify(key);
    });
  }, [closingQueue]);

  return null;
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (store: AppState) => ({
  queue: store.notify.queue,
  closingQueue: store.notify.closingQueue,
});

type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch) => ({
  dequeueNotify: (key) => {
    dispatch(dequeueNotify(key));
  },
  closeNotify: (key) => {
    dispatch(close(key));
  },
  dequeueCloseNotify: (key) => {
    dispatch(dequeueCloseNotify(key));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
