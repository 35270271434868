import React from 'react';

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { load, setSearch } from '../../actions/store.actions';
import { getIsStoresLoading, getStores } from '../../selectors/store.selector';
import { Store, StoresRouteParams } from './types';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState: AppState, ownProps) => {
  return (state: AppState, ownProps) => {
    const loading = getIsStoresLoading(state);
    const stores = getStores(state) as Store[];
    let storesTotalCount = state.stores.totalCount;
    let isEmpty = state.stores.isEmpty;
    let error = state.stores.error;
    const storesShowSearch = state.stores.showSearch;

    if (state.stores.search) {
      storesTotalCount = state.stores.search.totalCount;
      isEmpty = false;
      error = state.stores.search.error;
    }

    return {
      storesData: stores,
      storesDataIsEmpty: isEmpty,
      storesTotalCount,
      storesDataLoading: loading,
      storesShowSearch,
      storesDataError: error,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: StoresRouteParams) => {
  return (dispatch: ThunkDispatch, ownProps) => {
    return {
      loadNext: async (page: number, itemsPerPage: number) => {
        await dispatch(load(page, itemsPerPage));
      },
      storesSetSearch: (query?: string) => {
        dispatch(setSearch(query));
      },
    };
  };
};

export type StoresDataComponentProps<P = {}> = P & MappedState & MappedDispatch;
const withStoresData = <P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<StoresDataComponentProps<P>>>
) =>
  compose<P & MappedState & MappedDispatch, P & MappedState>(
    setDisplayName('WithStoresData'),
    connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
  )(Component);

export default withStoresData;
