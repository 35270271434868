import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import {
  OrderStatesType,
  setOrdersPage,
  setStateFilter,
  setStoreFilter,
  subscribeOrderUpdates,
  unSubscribeOrderUpdates,
} from './actions';
import Order from './Details/Details';
import OrderList from './List/List';

type Props = MappedDispatch & RouteComponentProps;

const OrdersRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
    subscribeOrderUpdates,
    unSubscribeOrderUpdates,
    setStoreFilter,
    setOrdersPage,
    setStateFilter,
  } = props;

  useEffect(() => {
    subscribeOrderUpdates();
    return () => {
      unSubscribeOrderUpdates();
      setStoreFilter([]);
      setStateFilter([]);
      setOrdersPage(0);
    };
  }, []);

  return (
    <ErrorBoundary identifier="order">
      <Switch>
        <Route exact path={`${path}/:orderId`} component={Order} />
        <Route path={`${path}`} component={OrderList} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  subscribeOrderUpdates: () => dispatch(subscribeOrderUpdates()),
  unSubscribeOrderUpdates: () => dispatch(unSubscribeOrderUpdates()),
  setOrdersPage: (page: number) => dispatch(setOrdersPage(page)),
  setStoreFilter: (stores: number[]) => dispatch(setStoreFilter({ stores })),
  setStateFilter: (states: OrderStatesType) => dispatch(setStateFilter(states)),
});

const EnhancedComponent = compose<Props, {}>(
  withRouter,
  connect(null, mapDispatchToProps)
)(OrdersRoutes);

export default EnhancedComponent;
