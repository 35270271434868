import React, { useState } from 'react';

import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import * as ordersActions from '../../../actions/order.action';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  acceptButton: {
    width: '124px',
    marginRight: '16px',
    padding: '10px 24px',
    fontSize: '16px',
    letterSpacing: '1.4px',
    backgroundColor: '#36d88e',
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#25c079',
    },
  },
});

type Props = MappedDispatch;

const KioskPendingOrderActions: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { acceptOrder } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  return (
    <div className={classes.container}>
      <Button
        variant={'contained'}
        className={classes.acceptButton}
        disabled={loading}
        onClick={() => {
          setLoading(true);
          acceptOrder(0).finally(() => setLoading(false));
        }}
        data-fd="Accept"
      >
        <Translate id="OK" />
      </Button>
    </div>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  acceptOrder: (mins?: number) => dispatch(ordersActions.acceptOrder(mins)),
});

export default connect(null, mapDispatchToProps)(KioskPendingOrderActions);
