import React from 'react';

import TowlieSvg from '../../assets/images/illust_liveview_coffee_list.svg';

const ErrorScreen = ({ translate }) => {
  return (
    <div>
      <div style={{ height: '80%', padding: '20px', display: 'grid', textAlign: 'center' }}>
        <div style={{ maxWidth: '90%' }}>
          <h2>{translate('Something_went_wrong_please_refresh_the_page')}</h2>
        </div>
        <div style={{ maxWidth: '90%' }}>
          <img src={TowlieSvg} alt={translate('Something_went_wrong')} />
        </div>
      </div>
    </div>
  );
};
export default ErrorScreen;
