import { Component, ReactNode } from 'react';

import { type RouteComponentProps, withRouter } from 'react-router';

import { generalConstants } from '../../constants/general.constants';

const { SCROLLABLE_CONTAINER } = generalConstants;

export interface IScrollToTopProps {
  onMount?: boolean;
  children?: ReactNode;
}
class ScrollToTop extends Component<RouteComponentProps & IScrollToTopProps, IScrollToTopProps> {
  private $unlistenHistory;

  public componentDidMount() {
    this.$unlistenHistory = this.props.history.listen(this.handleHistoryChange);
    if (this.props.onMount) {
      this.scrollToTop();
    }
  }
  public componentWillUnmount() {
    if (this.$unlistenHistory) {
      this.$unlistenHistory();
    }
  }
  public render() {
    return this.props.children;
  }

  public scrollToTop() {
    setTimeout(() => {
      const container = document.getElementById(SCROLLABLE_CONTAINER);
      if (container) {
        if (typeof container.scrollTo === 'function') {
          container.scrollTo(0, 0);
        } else {
          // Fallback for browsers that do not support scrollTo
          container.scrollTop = 0;
        }
      } else {
        console.error(`Element with ID ${SCROLLABLE_CONTAINER} not found.`);
      }
    }, 0);
  }

  private handleHistoryChange = (location, action) => {
    switch (action) {
      case 'PUSH':
        // handle Link navigation
        this.scrollToTop();
        break;
      case 'REPLACE':
        // maybe we should do something... future will show
        break;
      case 'POP':
        // handle Back/Forward buttons
        // do nothing
        break;
    }
  };
}

export default withRouter(ScrollToTop);
