import React, { ComponentType } from 'react';

import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import BankAccountIcon from '@mui/icons-material/AttachMoneyOutlined';
import VoucherIcon from '@mui/icons-material/CardGiftcardOutlined';
import CampaignIcon from '@mui/icons-material/ContactMailOutlined';
import AppIcon from '@mui/icons-material/DashboardOutlined';
import WebhookIcon from '@mui/icons-material/DeviceHubOutlined';
import MenuIcon from '@mui/icons-material/FastfoodOutlined';
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import TeammateIcon from '@mui/icons-material/PeopleOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PrinterIcon from '@mui/icons-material/PrintOutlined';
import DefaultIcon from '@mui/icons-material/ReportProblemOutlined';
import OrderIcon from '@mui/icons-material/ShoppingCartOutlined';
import PushNotificationIcon from '@mui/icons-material/SmartphoneOutlined';
import StoreIcon from '@mui/icons-material/StoreOutlined';
import CustomerIcon from '@mui/icons-material/TagFacesOutlined';
import SmsIcon from '@mui/icons-material/TextsmsOutlined';
import Avatar from '@mui/material/Avatar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';

import { ReactComponent as CatalogIcon } from '../../assets/images/Products.svg';
import { AuditLog } from './service';

const LOG_GROUP_ICONS: { [key: string]: ComponentType<React.PropsWithChildren<unknown>> } = {
  app: AppIcon,
  bankaccount: BankAccountIcon,
  campaign: CampaignIcon,
  catalog: CatalogIcon,
  clients: PhoneIphoneIcon,
  customer: CustomerIcon,
  menu: MenuIcon,
  order: OrderIcon,
  phone_call: PhoneIcon,
  printer: PrinterIcon,
  sms: SmsIcon,
  store: StoreIcon,
  teammate: TeammateIcon,
  user: UserIcon,
  voucher: VoucherIcon,
  webhook_subscription: WebhookIcon,
  push_notification: PushNotificationIcon,
};

const styles = () =>
  createStyles({
    positive: {
      backgroundColor: '#86e8bb',
    },
    negative: {
      backgroundColor: '#fb7c92',
    },
    neutral: {
      backgroundColor: '#cdd0ec',
    },
  });

type Props = {
  data: AuditLog;
} & WithStyles<typeof styles>;

function getLogIconByName(name: string): ComponentType<React.PropsWithChildren<unknown>> {
  const category = name.split('.')[0];

  return LOG_GROUP_ICONS[category] || DefaultIcon;
}

const isPositiveLog = ({ EventName, Enabled }: Flipdish.CustomerConsentUpdatedEvent) => {
  if (EventName === 'customer.consent.updated') {
    return Enabled;
  }

  return EventName!.includes('.created');
};

const isNegativeLog = ({ EventName, Enabled }: Flipdish.CustomerConsentUpdatedEvent) => {
  if (EventName === 'customer.consent.updated') {
    return !Enabled;
  }

  return EventName!.includes('.deleted') || EventName!.includes('.archived');
};

const LogIcon = ({ data, classes }: Props) => {
  const Icon = getLogIconByName(data.EventName!);
  const isPositive = isPositiveLog(data);
  const isNegative = isNegativeLog(data);
  const className = clsx({
    [classes.positive]: isPositive,
    [classes.negative]: isNegative,
    [classes.neutral]: !isPositive && !isNegative,
  });

  return (
    <Avatar data-fd="log-icon-container" className={className}>
      <Icon data-fd="log-icon" />
    </Avatar>
  );
};

const EnhancedComponent = withStyles(styles)(LogIcon);

export { EnhancedComponent as LogIcon };
