import React, { ReactNode, useEffect, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Translate, TranslateFunction } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { getLocationPathname } from './helpers';
import { type ItemMetaData } from './menuItemsConfig';
import { SideNavContext } from './SideNav';

const externalLinkRegExp = /^(https:\/\/)./;

const renderNavLink = React.forwardRef(
  (props: { to: string; children: ReactNode; className: string; title: string }, ref: any) => {
    return externalLinkRegExp.test(props.to) ? (
      <a
        href={props.to}
        ref={ref}
        className={props.className}
        data-fd={props['data-fd']}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    ) : (
      <NavLink {...props} innerRef={ref} />
    );
  }
);

const useStyles = makeStyles({
  listItem: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '& .MuiListItemText-root': {
      marginLeft: '1px',
    },
    height: '48px',
    margin: 0,
    padding: 0,
  },
  collapsedListItem: {
    '&:hover': {
      backgroundColor: '#eaf2ff',

      '& .MuiListItem-root': {
        backgroundColor: '#eaf2ff',
      },
    },
    '& .MuiExpansionPanelSummary-root': {
      background: 'none',

      '&:hover': {
        background: 'none',
      },
    },

    '& .MuiListItemIcon-root': {
      margin: '0 12px',
    },
  },
  active: {
    backgroundColor: '#eaf2ff',
    '& svg, div>div>span': {
      //To do: need to fix our svg icons, when active to accept this color
      // color: '#05149e',
    },
  },
  collapsedActiveItem: {
    '& .MuiListItemIcon-root': {
      backgroundColor: '#eaf2ff',
    },
    backgroundColor: '#eaf2ff',
    '& .MuiListItem-root': {
      backgroundColor: '#eaf2ff',
    },
  },
  listIcon: {
    height: 48,
    width: 48,
    margin: '0 20px 0 12px',
    minWidth: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subListIcon: {
    height: 48,
    width: 48,
    margin: '0 20px 0 12px',
    minWidth: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    height: '48px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  iconWrapper: {
    width: '12px',
    height: '100%',
    opacity: 0.4,
    position: 'absolute',
  },
  beta: {
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    color: '#ffffff',
    borderRadius: '3px',
    padding: '0px 6px',
    margin: '0px 8px',
    fontSize: '10px',
  },
});

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: '20px',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    height: 'inherit',
    '&$expanded': {
      margin: '0px 0 2px 0',
      minHeight: 0,
    },
    '&:hover': {
      '& .MuiListItem-root': {
        padding: 0,
        backgroundColor: '#eaf2ff',
      },
    },
  },
  content: {
    '&$expanded': {
      margin: '0 0 0 0',
    },
    height: '48px',
    borderRadius: '0 24px 24px 0',
    margin: '0px 0 2px 0',
  },
  expanded: {
    margin: '2px 0 2px 0',
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    padding: '0 0 0 36px',
  },
})(MuiExpansionPanelDetails);

type PanelProps = {
  value: {
    toggleSideNav: () => void;
    showTooltip: boolean;
  };
  isMobile: boolean;
  expanded: string;
  setExpanded: (str) => void;
  handleExpansion: (val) => void;
  setOpenSideNav: (open: boolean) => void;
};

type InnerProps = {
  translate: TranslateFunction;
};

type OuterProps = PanelProps & ItemMetaData & RouteComponentProps;

type Props = InnerProps & OuterProps;

const ExpansionPanelItem: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    title,
    icon,
    iconAnimated,
    subItems,
    subDrawerItems,
    value,
    isMobile,
    expanded,
    setExpanded,
    setOpenSideNav,
    handleExpansion,
    translate,
  } = props;
  const drawerOpen = !value.showTooltip;
  const [drawerStateOnItemClick, setDrawerStateOnItemClick] = useState(!drawerOpen);
  const classes = useStyles();

  const getSubMenuMatchingPath = getLocationPathname(props.location.pathname);

  const matchingPath =
    subItems &&
    subItems.find(
      (si) => si.path === props.location.pathname || si.path === getSubMenuMatchingPath
    );

  const handleExpansionChange = (val) => {
    handleExpansion(val);
    // Set the value for the drawer when we expand the section
    // If it was collapsed before expanding, we'll close it again when clicking on a subMenu
    setDrawerStateOnItemClick(drawerOpen);
  };

  const onClick = () => {
    if (subDrawerItems) {
      setOpenSideNav(true); // Open the sub-drawer
    }
    if (!drawerOpen) {
      return value.toggleSideNav();
    }
    return undefined;
  };

  const openSubItem = () => {
    if (!drawerStateOnItemClick || isMobile) {
      value.toggleSideNav();
    }
  };

  useEffect(() => {
    if (value.showTooltip) {
      setExpanded('');
    }
  }, [value.showTooltip]);

  const renderListItem = (item) => {
    const toLink = item.keepQuery ? `${item.path}${props.location.search}` : item.path;
    return (
      <ListItem
        onClick={() => openSubItem()}
        className={clsx(classes.listItem, value.showTooltip && classes.collapsedListItem)}
        disableGutters={true}
        // @ts-ignore
        component={renderNavLink}
        to={toLink}
        title={translate(item.title) as string}
        data-fd={`menu-sublink-${item.title}`}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        {item.icon ? (
          <ListItemIcon className={title === 'More' ? classes.listIcon : ''}>
            {item.icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={
            <>
              <Translate id={item.title} />
              {item.isBeta ? (
                <span className={classes.beta}>
                  <Translate id="Beta" />
                </span>
              ) : null}
            </>
          }
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    );
  };

  return (
    <ExpansionPanel
      className={drawerOpen ? '' : classes.collapsedListItem}
      expanded={expanded === title && drawerOpen}
      onChange={() => handleExpansionChange(title)}
    >
      <ExpansionPanelSummary>
        <Tooltip title={<Translate id={title} />} disableHoverListener={drawerOpen}>
          <ListItem
            onClick={onClick}
            key={title}
            className={
              matchingPath || expanded === title
                ? drawerOpen
                  ? classes.active
                  : classes.collapsedActiveItem
                : undefined
            }
            disableGutters={true}
            // @ts-ignore
            component={renderNavLink}
            to={props.location}
            data-fd={`menu-link-${title}`}
          >
            {title !== 'Settings' && (
              <div
                className={classes.iconWrapper}
                style={{
                  display: drawerOpen && !['More', 'Add_ons'].includes(title) ? 'block' : 'none',
                }}
              >
                {!drawerOpen || expanded !== title ? (
                  <ArrowRight className={classes.arrow} />
                ) : (
                  <ArrowDropDown className={classes.arrow} />
                )}
              </div>
            )}
            <ListItemIcon
              className={classes.subListIcon}
              style={{ marginLeft: '12px', minWidth: 0 }}
            >
              {!drawerOpen || expanded !== title ? icon : iconAnimated ? iconAnimated : icon}
            </ListItemIcon>

            <ListItemText
              primary={<Translate id={title} />}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        </Tooltip>
      </ExpansionPanelSummary>

      {subItems
        ?.filter((item) => !item.hidden)
        .map((item) => (
          <Permissions key={item.title} allowed={item.permissions || []}>
            <ExpansionPanelDetails
              className={
                item.title === (matchingPath && matchingPath.title) ? classes.active : undefined
              }
              key={item.title}
              style={{ paddingLeft: title === 'More' ? '0px' : '36px' }}
            >
              {renderListItem(item)}
            </ExpansionPanelDetails>
          </Permissions>
        ))}
    </ExpansionPanel>
  );
};

export const EPI = withRouter(ExpansionPanelItem);

const MenuItem = (
  props: ItemMetaData &
    InnerProps & {
      isMobile: boolean;
      expanded: string;
      handleExpansion: (val) => void;
      setExpanded: (val) => void;
      setOpenSideNav: (open: boolean) => void;
    }
) => {
  const {
    title,
    path,
    icon,
    isMobile,
    subItems,
    subDrawerItems,
    expanded,
    handleExpansion,
    setExpanded,
    isBeta,
    setOpenSideNav, // Destructure the new prop
  } = props;
  const classes = useStyles();
  const handleClick = (event, value) => {
    if (isMobile) {
      value?.toggleSideNav();
    } else {
      handleExpansion(event);
    }
  };

  if (subItems) {
    return (
      <SideNavContext.Consumer>
        {(value) => (
          <EPI
            {...props}
            value={value}
            expanded={expanded}
            setExpanded={setExpanded}
            handleExpansion={handleExpansion}
          />
        )}
      </SideNavContext.Consumer>
    );
  }
  if (subDrawerItems) {
    return (
      <SideNavContext.Consumer>
        {(value) => (
          <EPI
            {...props}
            value={value}
            expanded={expanded}
            setExpanded={setExpanded}
            handleExpansion={handleExpansion}
            setOpenSideNav={setOpenSideNav}
          />
        )}
      </SideNavContext.Consumer>
    );
  }

  return (
    <SideNavContext.Consumer>
      {(value) => (
        <Tooltip title={<Translate id={title} />} disableHoverListener={!value.showTooltip}>
          <ListItem
            onClick={(event) => handleClick(event, value)}
            className={clsx(classes.listItem, value.showTooltip && classes.collapsedListItem)}
            key={title}
            disableGutters={true}
            // button={true}
            // @ts-ignore
            component={renderNavLink}
            to={path}
            activeClassName={`${classes.active} ${
              value.showTooltip ? classes.collapsedActiveItem : ''
            }`}
            data-fd={`menu-link-${title}`}
          >
            <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Translate id={title} />
                  {isBeta ? (
                    <span className={classes.beta}>
                      <Translate id="Beta" />
                    </span>
                  ) : null}
                </>
              }
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        </Tooltip>
      )}
    </SideNavContext.Consumer>
  );
};

export default MenuItem;
