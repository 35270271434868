import React, { useEffect } from 'react';

import { useInfiniteQueryAllInvoicesHook } from '@fd/customHooks/useInfiniteQueryAllInvoices';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { showPausedPayouts } from '../../selectors/app.selector';
import { permissionsSelector } from '../../selectors/permissions.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import { flagService } from '../../services/flagService';
import PageLayout from '../../ui/Layout';
import DynamicAlertBanner from '../../ui/molecules/DynamicAlertBanner/DynamicAlertBanner';
import Spacer from '../../ui/Spacer';
import { getBankAccounts } from '../Finance/Banking/banking.actions';
import { bankingSelectors } from '../Finance/Banking/banking.selectors';
import AlertBankVerification from './Configs/AlertBankVerification';
import SetupConfigList from './Configs/List';
import Savings from './Savings/Savings';
import { getWelcomeTitle } from './selectors';
import StoreCardList from './Stores/List';

type InnerProps = MappedState & MappedDispatch;
type Props = ReturnType<typeof mapStateToPropsFactory> & InnerProps;

const Home = (props: Props) => {
  const {
    appId,
    bankAccountAlerts,
    currentApp,
    hasBankingPermissions,
    hasInvoicePermissions,
    isAlertBannerInvoiceOn,
    loadSetup,
    showPausedPayouts = false,
    title,
  } = props;
  const isFlipdishGlobal = currentApp.AppId === 'flipdish-global';

  useEffect(() => {
    if (hasBankingPermissions) {
      loadSetup();
    }
  }, [hasBankingPermissions]);

  //#region DynamicAlertBanner
  const { overdueInvoices } = useInfiniteQueryAllInvoicesHook({
    appId: appId,
    isEnabled: hasInvoicePermissions,
  });

  const showBanner =
    !isFlipdishGlobal &&
    isAlertBannerInvoiceOn &&
    !showPausedPayouts &&
    overdueInvoices?.length > 0;
  //#endregion

  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('portal_home', {
      action: 'logged_in',
    });
  }, []);

  return (
    <PageLayout title={title}>
      <Grid container>
        {!isFlipdishGlobal &&
          bankAccountAlerts.map((acc) => (
            <Grid item xs={12} key={acc.priority}>
              <AlertBankVerification account={acc} />
            </Grid>
          ))}
        {showBanner && (
          <DynamicAlertBanner
            backgroundColor="yellow"
            bannerText={'You_have_1_or_more_invoices_overdue_payment'}
            buttonText={'Go_to_invoices'}
            showButton={true}
            toUrl={`/${appId}/billing/invoices`}
          />
        )}
        <Grid item xs={12}>
          <Savings />
        </Grid>

        <Grid item xs={12}>
          <SetupConfigList />
          <Spacer size={24} />
        </Grid>

        <Grid item xs={12}>
          <StoreCardList />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getBankingPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'Owner',
    'ManagedOwner',
    'FinanceManger',
    'FlipdishStaff',
  ]);
  const getInvoicePermissionsSelector = permissionsSelector.hasPermissionFactory([
    'Owner',
    'StoreOwner',
    'ManagedOwner',
    'StoreManager',
    'FinanceManger',
    'FlipdishStaff',
  ]);
  return (state: AppState) => {
    return {
      appId: state.currentApp.AppId!,
      bankAccountAlerts: bankingSelectors.getBankAccountAlerts(state),
      currentApp: state.currentApp,
      isAlertBannerInvoiceOn: flagService.isFlagOn(state, 'alertBannerInvoice'),
      showPausedPayouts: showPausedPayouts(state),
      title: getWelcomeTitle(state),
      hasBankingPermissions: getBankingPermissionsSelector(state),
      hasInvoicePermissions: getInvoicePermissionsSelector(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadSetup: () => {
    dispatch(getBankAccounts());
  },
});

export default compose<InnerProps, {}>(connect(mapStateToPropsFactory, mapDispatchToProps))(Home);
