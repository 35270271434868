import { apmConstants } from '../constants/apm.constants';
import { phoneCallEventConstants } from '../signalr/hub.events';

const apmInitialState = {
  statistics: {
    EstimatedMinutesSaved: 0,
    CurrencyData: [],
  },
  statisticsTimestamp: null,
  calls: [],
  totalCalls: 0,
  callsPage: 1,
  callsRows: 50,
  callsStores: [],
  callsTimestamp: null,
  calendar: {},
  calendarTimestamp: null,
  callStatistics: [],
  callStatisticsTimestamp: null,
  orderCurrencies: [],
  orderStatistics: [],
  orderStatisticsTimestamp: null,
  IsApmEnabled: null,
};

export function apm(state = apmInitialState, action) {
  switch (action.type) {
    case apmConstants.SET_CALLS_PAGE:
      return {
        ...state,
        callsPage: action.payload,
      };
    case apmConstants.SET_CALLS_ROWS:
      return {
        ...state,
        callsRows: action.payload,
      };
    case apmConstants.SET_CALLS_STORES:
      return {
        ...state,
        callsStores: action.payload,
      };
    case apmConstants.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        statisticsTimestamp: null,
      };
    case apmConstants.SET_STATISTICS_TIMESTAMP:
      return {
        ...state,
        statisticsTimestamp: action.payload,
      };
    case apmConstants.GET_CALLS_SUCCESS:
      return {
        ...state,
        calls: action.payload,
        callsTimestamp: null,
      };
    case apmConstants.GET_TOTAL_CALLS_COUNT:
      return {
        ...state,
        totalCalls: action.payload,
      };
    case apmConstants.SET_CALLS_TIMESTAMP:
      return {
        ...state,
        callsTimestamp: action.payload,
      };
    case apmConstants.GET_CALENDAR_SUCCESS:
      return {
        ...state,
        calendar: action.payload,
        calendarTimestamp: null,
      };
    case apmConstants.SET_CALENDAR_TIMESTAMP:
      return {
        ...state,
        calendarTimestamp: action.payload,
      };

    case apmConstants.GET_STATUS_REQUEST:
      return {
        ...state,
        IsApmEnabled: null,
      };
    case apmConstants.GET_STATUS_SUCCESS:
      return {
        ...state,
        IsApmEnabled: action.payload,
      };
    case apmConstants.GET_CALL_STATISTICS:
      return {
        ...state,
        callStatistics: action.payload,
        callStatisticsTimestamp: null,
      };
    case apmConstants.SET_CALL_STATISTICS_TIMESTAMP:
      return {
        ...state,
        callStatisticsTimestamp: action.payload,
      };
    case apmConstants.GET_ORDER_STATISTICS:
      return {
        ...state,
        orderStatistics: action.payload.orderStatistics,
        orderCurrencies: action.payload.orderCurrencies,
        orderStatisticsTimestamp: null,
      };
    case apmConstants.SET_ORDER_STATISTICS_TIMESTAMP:
      return {
        ...state,
        orderStatisticsTimestamp: action.payload,
      };
    case phoneCallEventConstants.STARTED:
      if (action.appId === action.payload.AppId) {
        // Check if the store the call belongs to is currently being displayed on screen
        const storeIndex =
          state.callsStores.length > 0
            ? state.callsStores.findIndex(
                (store) => (store as any).value === action.payload.StoreId
              )
            : 1;

        // If on first page and store is being displayed append at top and remove last element
        if (state.callsPage === 1 && storeIndex != -1) {
          return {
            ...state,
            calls: [action.payload.PhoneCall].concat([...state.calls.slice(0, -1)]),
          };
        }
      }
      return state;
    case phoneCallEventConstants.ENDED:
      if (action.appId === action.payload.AppId) {
        const callIndex = state.calls.findIndex(
          (call) => (call as any).PhoneCallId === action.payload.PhoneCall.PhoneCallId
        );

        if (callIndex != -1) {
          return {
            ...state,
            calls: [
              ...state.calls.slice(0, callIndex),
              action.payload.PhoneCall,
              ...state.calls.slice(callIndex + 1),
            ],
          };
        }
      }
      return state;
    default:
      return state;
  }
}
