import React, { useEffect, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Checkbox from '@fd/ui/Checkbox';
import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';

import { CustomerStatus } from '../../types';

const useStyles = makeStyles(() => ({
  label: { marginLeft: 0 },
}));

type InnerProps = {};
type OuterProps = {
  onCancel: (modal: 'marketing' | 'suspicious') => void;
  onContinue: ({ isCardEnabled, isCashEnabled }: CustomerStatus) => void;
  open: boolean;
  CardOrdersEnabled?: boolean;
  CashOrdersEnabled?: boolean;
};
type Props = InnerProps & OuterProps;
const SuspiciousActivityModal = (props: Props) => {
  const { onCancel, onContinue, open, CardOrdersEnabled, CashOrdersEnabled } = props;
  const classes = useStyles();

  const [isCardEnabled, setIsCardEnabled] = useState<boolean | undefined>(CardOrdersEnabled);
  const [isCashEnabled, setIsCashEnabled] = useState<boolean | undefined>(CashOrdersEnabled);

  useEffect(() => {
    setIsCardEnabled(CardOrdersEnabled);
    setIsCashEnabled(CashOrdersEnabled);
  }, [open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'cash' | 'card') => {
    if (type === 'cash') {
      setIsCashEnabled(!e.target.checked);
    } else if (type === 'card') {
      setIsCardEnabled(!e.target.checked);
    }
  };

  return (
    <YesNoDialog
      noTextId={'Cancel'}
      onNo={() => {
        onCancel('suspicious');
      }}
      onYes={() => onContinue({ isCashEnabled, isCardEnabled })}
      open={open}
      overflow={'hidden'}
      titleTextId={'Prevent_customer_from'}
      yesButtonVariant={'contained'}
      yesTextId={'Continue'}
    >
      <MenuItem data-fd={`paying_with_cash`} disableGutters>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                dataFd="paying_with_cash"
                value={`paying_with_cash`}
                color="secondary"
                inputProps={{
                  'aria-label': 'pay with cash',
                }}
                checked={!isCashEnabled}
                onChange={(e) => handleChange(e, 'cash')}
                style={{ alignSelf: 'baseline', padding: 4 }}
              />
            }
            label={<Translate id={'Paying_with_cash'} />}
            classes={{ root: classes.label }}
          />
        </FormGroup>
      </MenuItem>

      <MenuItem data-fd={`paying_with_card`} disableGutters>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                dataFd="paying_online"
                value={'paying_online'}
                color="secondary"
                inputProps={{
                  'aria-label': 'pay online',
                }}
                checked={!isCardEnabled}
                onChange={(e) => {
                  handleChange(e, 'card');
                }}
                style={{ alignSelf: 'baseline', padding: 4 }}
              />
            }
            label={<Translate id={'Paying_online'} />}
            classes={{ root: classes.label }}
          />
        </FormGroup>
      </MenuItem>
      <div style={{ marginTop: 8, marginBottom: 16 }}>
        <Typography variant="caption" style={{ color: 'rgba(0,0,0,0.87)' }}>
          <Translate id={'Suspicious_activity_warning'} />
        </Typography>
      </div>
    </YesNoDialog>
  );
};
export default SuspiciousActivityModal;
