import React from 'react';

import { Channel, OrderSummary } from '@flipdish/api-client-typescript';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { FormattedCurrency } from '../../../../../packages/react-intl';
import { getIanaTimeZoneId } from '../../../../helpers/timeZones';
import DateTimeZone from '../../../../ui/DateTime/DateTime';
import Icon from '../../components/Icon';
import { displayCustomerNameForOrder, renderPaymentStatus } from '../../helpers';

const TBodyRowStyles = () => {
  return createStyles({
    cellLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      height: '100%',
      outline: 0,
      padding: '0 10px',
    },
    firstCell: {
      paddingLeft: '24px',
    },
    customerName: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.15',
      letterSpacing: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
      wordBreak: 'break-word',
    },
    customerPhone: {
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    orderNumber: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    cellContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
    },
    bodyCell: {
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.85',
      letterSpacing: 'normal',
      height: '68px',
      borderBottomColor: 'rgba(0,0,0,0.21)',
      padding: 0,
    },
    orderStatus: {
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    orderDispatched: {
      backgroundColor: 'rgba(222, 172, 250,0.12)',
    },
    orderRejected: {
      color: '#fff',
      backgroundColor: 'rgba(255, 215, 223,0.12)',
    },
    orderReadyToProcess: {
      color: '#fff',
      backgroundColor: 'rgb(222, 172, 250,0.12)',
    },
    orderAccepted: {
      color: '#fff',
      backgroundColor: '#fff',
    },
    textStyleRefunded: {
      color: 'rgba(0, 0, 0, 0.56)',
      textTransform: 'capitalize',
    },
    textStylePaid: {
      color: '#25c974',
      textTransform: 'capitalize',
    },
    textStyleUnPaid: {
      color: '#ff395f',
      textTransform: 'capitalize',
    },
  });
};

const tableRowClasses = (orderState: OrderSummary.OrderStateEnum | undefined, classes: any) => {
  return clsx(classes.row, classes.bodyCell, {
    [classes.orderReadyToProcess]: orderState === OrderSummary.OrderStateEnum.ReadyToProcess,
    [classes.orderAccepted]:
      orderState === OrderSummary.OrderStateEnum.AcceptedAndRefunded ||
      orderState === OrderSummary.OrderStateEnum.AcceptedByRestaurant ||
      orderState === OrderSummary.OrderStateEnum.Dispatched,
    [classes.orderRejected]:
      orderState === OrderSummary.OrderStateEnum.RejectedByFlipdish ||
      orderState === OrderSummary.OrderStateEnum.RejectedByStore ||
      orderState === OrderSummary.OrderStateEnum.RejectedAfterBeingAccepted ||
      orderState === OrderSummary.OrderStateEnum.Cancelled ||
      orderState === OrderSummary.OrderStateEnum.RejectedAutomatically,
  });
};

const orderStatus = (type: OrderSummary.OrderStateEnum | undefined, classes, dataFd: string) => {
  switch (type) {
    case OrderSummary.OrderStateEnum.ReadyToProcess:
    case OrderSummary.OrderStateEnum.Cancelled:
    case OrderSummary.OrderStateEnum.AcceptedAndRefunded:
    case OrderSummary.OrderStateEnum.AcceptedByRestaurant:
    case OrderSummary.OrderStateEnum.Created:
    case OrderSummary.OrderStateEnum.Delivered:
    case OrderSummary.OrderStateEnum.ManualReview:
    case OrderSummary.OrderStateEnum.PlacedCanBeCancelled:
      return (
        <div className={classes.orderStatus} data-fd={dataFd}>
          <Translate id={type.toString() as TranslationId} />
        </div>
      );
    case OrderSummary.OrderStateEnum.Dispatched:
      return (
        <div className={classes.orderStatus} data-fd={dataFd}>
          <Translate
            id={OrderSummary.OrderStateEnum.AcceptedByRestaurant.toString() as TranslationId}
          />
        </div>
      );
    case OrderSummary.OrderStateEnum.RejectedAutomatically:
    case OrderSummary.OrderStateEnum.RejectedAfterBeingAccepted:
    case OrderSummary.OrderStateEnum.RejectedByFlipdish:
    case OrderSummary.OrderStateEnum.RejectedByStore:
      return (
        <div className={classes.orderStatus} data-fd={dataFd}>
          <Translate id={'Rejected'} />
        </div>
      );
    default:
      return null;
  }
};

type props = { order: OrderSummary };
type injectedProps = MappedState & RouteComponentProps & WithStyles<typeof TBodyRowStyles>;
const TBodyRow: React.FC<React.PropsWithChildren<props & injectedProps>> = (props) => {
  const { order, languageCode, match, classes } = props;
  const url = match.url.replace(/\/$/, '');
  const dataFdPrefix = `order_${order.OrderId}_`;

  const renderOrderAmount = () => {
    if (order.Amount !== undefined && order.Amount >= 0 && order.Currency) {
      return (
        <FormattedCurrency
          value={order.Amount as number}
          locales={languageCode}
          currency={order.Currency.toString()}
        />
      );
    }
    return null;
  };

  const customerName =
    order.CustomerName && order.CustomerName?.length > 150
      ? displayCustomerNameForOrder(order.CustomerName, 150)
      : order.CustomerName;

  return (
    <TableRow className={tableRowClasses(order.OrderState, classes)}>
      {/* TODO: classes.statusCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link to={`${url}/${order.OrderId}`} className={`${classes.cellLink} ${classes.firstCell}`}>
          <Icon
            size={'small'}
            deliveryType={order.DeliveryType}
            OrderState={order.OrderState}
            channelOrder={order.Channel?.Source === Channel.SourceEnum.External}
          />
          <div className={classes.cellContent}>
            <div className={classes.orderNumber}>#{order.OrderId}</div>
            {orderStatus(order.OrderState, classes, `${dataFdPrefix}status`)}
          </div>
        </Link>
      </TableCell>
      {/* TODO: classes.statusCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link to={`${url}/${order.OrderId}`} className={classes.cellLink}>
          <div className={classes.cellContent}>
            <div className={classes.orderNumber}>
              <Translate
                id={`${order.Channel?.TranslationKey}_${order.Channel?.Source}` as TranslationId}
              />
            </div>
            {order.Channel?.Source === Channel.SourceEnum.External && (
              <div className={classes.orderStatus}>{order.ChannelOrderDisplayId}</div>
            )}
          </div>
        </Link>
      </TableCell>
      {/* TODO: classes.timeCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link
          to={`${url}/${order.OrderId}`}
          className={classes.cellLink}
          data-fd={`${dataFdPrefix}requested_date`}
        >
          <DateTimeZone
            dataFd={`requested_for_time_${order.OrderId}`}
            value={order.RequestedForTime! as unknown as Date}
            ianaTimeZone={order.StoreIanaTimeZone && getIanaTimeZoneId(order.StoreIanaTimeZone)}
          >
            {({ dtUtils, value }) => {
              const date = value ? dtUtils.format(value, 'P') : '';
              const time = value ? dtUtils.format(value, 'p') : '';

              return `${time} ${date}`;
            }}
          </DateTimeZone>
        </Link>
      </TableCell>
      {/* TODO: classes.storeCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link
          to={`${url}/${order.OrderId}`}
          className={classes.cellLink}
          data-fd={`${dataFdPrefix}store_name`}
        >
          {order.StoreName}
        </Link>
      </TableCell>
      {/* TODO: classes.amountCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link
          to={`${url}/${order.OrderId}`}
          className={classes.cellLink}
          data-fd={`${dataFdPrefix}amount`}
        >
          {renderOrderAmount()}
        </Link>
      </TableCell>
      {/* TODO: classes.paymentCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link to={`${url}/${order.OrderId}`} className={classes.cellLink}>
          {renderPaymentStatus(order, classes, `${dataFdPrefix}payment_status`)}
        </Link>
      </TableCell>
      {/* TODO: classes.customerCell, doesn't seem to exist, check this */}
      <TableCell className={clsx(classes.bodyCell)} role={'td'}>
        <Link to={`${url}/${order.OrderId}`} className={classes.cellLink}>
          <div className={classes.cellContent}>
            <div className={classes.customerName}>{customerName}</div>
            <div className={classes.customerPhone} data-fd={`${dataFdPrefix}phone`}>
              {order.CustomerPhoneNumber}
            </div>
          </div>
        </Link>
      </TableCell>
    </TableRow>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  const language = getActiveLanguage(locale);
  return {
    languageCode: language,
  };
};

const EnhancedTBodyRow = compose<injectedProps, props>(
  withStyles(TBodyRowStyles, {
    name: 'TBodyRow',
  }),
  withRouter,
  connect(mapStateToProps)
)(TBodyRow);

export default EnhancedTBodyRow;
