import React, { useMemo, useState } from 'react';

import 'react-dates/initialize';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import MuiSelect from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import * as qs from 'qs';
import { DayPickerRangeController } from 'react-dates';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import { Button } from '../../../../ui/Button';
import EnhancedLocalizationProvider from '../../../../ui/MuiPickersUtilsProvider';
import Select from '../../../../ui/Select/Select';
import { getPreviousPeriod, onPresetPeriodChange, presetRanges } from '../../helpers';
import { getLocale } from '../../OrdersReport/selectors';
import DateRangeSelectorWindowsHandler from './DateRangeSelectorWindowsHandler';

import './react_dates_overrides.css';
import 'react-dates/lib/css/_datepicker.css';

type StyleProps = {
  focusedDateRange: 'startDate' | 'endDate';
};

const useStyles = makeStyles((theme: Theme) => ({
  dateRangeMenuContainer: {
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    overflowX: 'hidden',
    maxHeight: 'calc(95vH - 176px)', // position top + margin + padding + page header - 5vh
    marginTop: 10,
    backgroundColor: 'white',
    padding: 16,
    maxWidth: 683,
    minWidth: 300,
    '&::-webkit-scrollbar': { display: 'none' },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vH - 50px)',
      width: '92vW',
      padding: 0,
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  container: {
    padding: 16,
    [theme.breakpoints.down('md')]: {
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  dateRangeSelection: {
    width: '100%',
  },
  selectDropdown: {
    marginTop: theme.spacing(2),
    zIndex: 3000,
  },
  inputFocusedStart: ({ focusedDateRange }: StyleProps) => {
    return {
      backgroundColor: focusedDateRange === 'startDate' ? '#eaf2ff' : '#ffffff',
      cursor: 'text',
    };
  },
  inputFocusedEnd: ({ focusedDateRange }: StyleProps) => {
    return {
      backgroundColor: focusedDateRange === 'endDate' ? '#eaf2ff' : '#ffffff',
      cursor: 'text',
    };
  },
  inputSelection: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  cancelApplySection: {
    zIndex: 100,
    marginTop: 15,
    marginBottom: 10,
    padding: '0px 16px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      height: '10vH',
      alignContent: 'center',
      padding: '0px 16px',
      margin: 0,
    },
  },
  buttonLeft: {
    margin: '0 18px 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '0',
      width: 'calc(50% - 8px)',
      marginRight: '8px',
    },
  },
  buttonRight: {
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 8px)',
      marginLeft: '8px',
    },
  },
  compareToSection: {
    marginTop: 20,
  },
  menuContainer: {
    zIndex: 1000,
  },
  keyboardIcon: {
    display: 'none',
  },
}));

type InnerProps = MappedState & RouteComponentProps;
type OuterProps = {
  customRange?;
  hideCompare?: boolean;
  translate: TranslateFunction;
  onChange: (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    previousPeriod: PrevPeriodProps | undefined
  ) => void;
};

export type PrevPeriodProps = {
  value: number;
  url?: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
};

type Props = InnerProps & OuterProps;
const DateRangeSelector = (props: Props) => {
  const { location, history, activeLanguage } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const dateRanges = presetRanges;

  const getRouteSearch = () => {
    if (location.search !== '') {
      const searchProps = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      return searchProps || {};
    }
    return undefined;
  };
  const handleOutsideDataRange = () => {
    setMenuIsOpen(false);
  };

  type CurrentSearch = {
    comparewith?: 'none' | 'previousperiod' | 'lastyear' | 'custom';
    daterange?: string;
    end?: moment.Moment;
    fp_start?: string;
    fp_end?: string;
    previousPeriod: PrevPeriodProps | undefined;
    start?: moment.Moment;
    store?: moment.Moment;
  };

  // Get default states for daterange / previous period
  const setup = () => {
    let defaultStartDate: { moment: moment.Moment } | undefined;
    let defaultEndDate: { moment: moment.Moment } | undefined;
    let defaultPrevPeriod: PrevPeriodProps | undefined;
    let defaultDaterange: PrevPeriodProps | undefined;
    const currentSearch: CurrentSearch = getRouteSearch() as any;
    if (currentSearch) {
      // We get url query params
      const newSearch = currentSearch;
      if (currentSearch.daterange) {
        // If we have daterange params
        const preset = dateRanges.find((pr) => pr.url === currentSearch.daterange);
        if (preset) {
          const { start, end } = onPresetPeriodChange(preset.value);
          defaultStartDate = { moment: start };
          defaultEndDate = { moment: end };
          defaultDaterange = { value: preset.value, url: preset.url };
          newSearch.start = start;
          newSearch.end = end;

          if (currentSearch.comparewith) {
            // Handle previous period
            let urlPrevPeriod: PrevPeriodProps | undefined;
            if (currentSearch.comparewith === 'lastyear') {
              // Compare with last year
              urlPrevPeriod = {
                value: 20,
                url: currentSearch.comparewith,
                startDate: moment(start)
                  .year(moment(start).year() - 1)
                  .startOf('day'),
                endDate: moment(end)
                  .year(moment(end).year() - 1)
                  .endOf('day'),
              };
            } else {
              // Compare with previous period
              const event = { target: { value: 10 } };
              const sp = getPreviousPeriod(
                event,
                defaultStartDate,
                defaultEndDate,
                defaultDaterange.url
              );
              urlPrevPeriod = sp;
            }
            newSearch.previousPeriod = urlPrevPeriod;
            defaultPrevPeriod = urlPrevPeriod;
          } else {
            // None
            const event = { target: { value: 0 } };
            const sp = getPreviousPeriod(
              event,
              defaultStartDate,
              defaultEndDate,
              defaultDaterange.url
            );

            defaultPrevPeriod = sp;
          }
        }
      }

      // #region custom daterange
      else if (currentSearch.fp_start) {
        // If there is no daterange, we can have start/end period in the url
        const urlStartDate = moment(currentSearch.fp_start, 'YYYY-MM-DD').startOf('day');
        const urlEndDate = moment(currentSearch.fp_end, 'YYYY-MM-DD').endOf('day');
        defaultStartDate = { moment: urlStartDate };
        defaultEndDate = { moment: urlEndDate };
        defaultDaterange = { value: 0, url: 'custom' };
        newSearch.start = urlStartDate;
        newSearch.end = urlEndDate;
        if (currentSearch.comparewith) {
          // Get comparison period start / end
          let urlPrevPeriod: PrevPeriodProps | undefined;
          if (currentSearch.comparewith === 'lastyear') {
            // Compare with last year
            urlPrevPeriod = {
              value: 20,
              url: currentSearch.comparewith,
              startDate: moment()
                .year(moment(defaultStartDate.moment).year() - 1)
                .startOf('day'),
              endDate: moment()
                .year(moment(defaultEndDate.moment).year() - 1)
                .endOf('day'),
            };
          } else {
            // Compare with previous period
            const event = { target: { value: 10 } };
            const sp = getPreviousPeriod(
              event,
              defaultStartDate,
              defaultEndDate,
              defaultDaterange.url
            );
            urlPrevPeriod = sp;
          }
          newSearch.previousPeriod = urlPrevPeriod;
          defaultPrevPeriod = urlPrevPeriod;
        } else {
          // None
          const event = { target: { value: 0 } };
          const sp = getPreviousPeriod(
            event,
            defaultStartDate,
            defaultEndDate,
            defaultDaterange.url
          );
          defaultPrevPeriod = sp;
        }
      } else {
        //#region set default filters
        defaultDaterange = { value: 4, url: 'last30Days' };
        const { start, end } = onPresetPeriodChange(4, moment());
        defaultStartDate = { moment: start };
        defaultEndDate = { moment: end };
        const event = { target: { value: 10 } };
        const sp = getPreviousPeriod(event, defaultStartDate, defaultEndDate, defaultDaterange.url);
        defaultPrevPeriod = sp;
        newSearch.start = start;
        newSearch.end = end;
        newSearch.previousPeriod = defaultPrevPeriod;
        //#endregion

        //#region set default search
        const defaultSearch = {
          daterange: 'last30Days',
          comparewith: 'previousperiod',
        };
        const search = qs.stringify(defaultSearch, {
          skipNulls: true,
          encodeValuesOnly: true,
          indices: false,
        });
        const newLocation = { ...location, search };
        history.replace(newLocation);
        //#endregion
      }
      // #endregion
      // We call onChange to trigger a filter update / api call with current params
      props.onChange(newSearch.start, newSearch.end, newSearch.previousPeriod);
    } else {
      // If no query params, we set some default ones
      // (last 30 days / compare with previous period)

      //#region set default filters
      defaultDaterange = { value: 4, url: 'last30Days' };
      const defaultPeriod = onPresetPeriodChange(4);
      defaultStartDate = { moment: defaultPeriod.start };
      defaultEndDate = { moment: defaultPeriod.end };
      const event = { target: { value: 10 } };
      const sp = getPreviousPeriod(event, defaultStartDate, defaultEndDate, defaultDaterange.url);
      defaultPrevPeriod = sp;
      //#endregion

      //#region set default search
      const defaultSearch = {
        daterange: 'last30Days',
        comparewith: 'previousperiod',
      };
      const search = qs.stringify(defaultSearch, {
        skipNulls: true,
        encodeValuesOnly: true,
        indices: false,
      });
      const newLocation = { ...location, search };
      history.replace(newLocation);
      //#endregion
      props.onChange(defaultStartDate.moment, defaultEndDate.moment, defaultPrevPeriod);
    }
    return {
      defaultStartDate,
      defaultEndDate,
      defaultPrevPeriod,
      defaultDaterange,
    };
  };

  const { defaultStartDate, defaultEndDate, defaultPrevPeriod, defaultDaterange } = useMemo(
    () => setup(),
    []
  );

  const defaultPeriod = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  };

  // #region useState
  const [menuOpen, setMenuIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<{
    moment: moment.Moment;
    isValid: boolean;
  }>({
    moment: defaultPeriod.startDate ? defaultPeriod.startDate.moment : moment(),
    isValid: true,
  });
  const [endDate, setEndDate] = useState<{
    moment: moment.Moment;
    isValid: boolean;
  }>({
    moment: defaultPeriod.endDate ? defaultPeriod.endDate.moment : moment(),
    isValid: true,
  });

  const [focusedDateRange, setFocusedDateRange] = useState<'startDate' | 'endDate'>('startDate');
  const [previousPeriod, setPreviousPeriod] = useState<PrevPeriodProps | undefined>(
    defaultPrevPeriod
  );
  const [selectedDaterange, setSelectedDaterange] = useState(defaultDaterange);
  const classes = useStyles({ focusedDateRange });
  //#endregion

  const updateQueryParams = (start, end, previousPeriod, isCustom) => {
    const currentSearch = getRouteSearch();
    let newSearch = currentSearch;
    if ((selectedDaterange && selectedDaterange.value === 0) || isCustom) {
      // If the user selects a custom daterange
      // we set first and second period start/end dates in url
      let fp_start; // first period start
      let fp_end; // first period end
      newSearch = { ...newSearch, ...{ daterange: undefined } };
      fp_start = moment(start).format('YYYY-MM-DD');
      fp_end = moment(end).format('YYYY-MM-DD');
      newSearch = { ...newSearch, ...{ fp_start, fp_end } };
    } else {
      // Otherwise, we just add the daterange keyword
      if (selectedDaterange) {
        newSearch = { ...newSearch, ...{ daterange: selectedDaterange.url } };
        if (newSearch.fp_start) {
          newSearch.fp_start = undefined;
          newSearch.fp_end = undefined;
        }
      }
    }
    if (previousPeriod && previousPeriod.startDate) {
      newSearch = { ...newSearch, ...{ comparewith: previousPeriod.url } };
    } else {
      if (newSearch?.comparewith) {
        newSearch.comparewith = undefined;
      }
    }
    const search = qs.stringify(newSearch, {
      skipNulls: true,
      encodeValuesOnly: true,
      indices: false,
    });
    const newLocation = { ...location, search };
    history.replace(newLocation);
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuOpen);
    setFocusedDateRange('startDate');
  };

  const onCalendarDatesChange = (
    dates: { startDate: moment.Moment; endDate: moment.Moment },
    preset?: boolean
  ) => {
    if (!preset) {
      // Custom daterange selected from calendar
      setSelectedDaterange({ value: 0, url: 'custom' });
      if (dates.startDate) {
        setStartDate({ moment: dates.startDate, isValid: true });
      }
      if (dates.endDate) {
        if (moment(dates.startDate).isAfter(dates.endDate, 'day')) {
          setEndDate({ ...endDate, isValid: false });
        } else {
          setEndDate({ moment: dates.endDate, isValid: true });
        }
      }
      // endDate = null when user selects startDate > endDate, this lets user select new endDate
      if (!dates.endDate && moment(dates.startDate).isAfter(endDate.moment, 'day')) {
        setEndDate({ ...endDate, isValid: false });
      }
    } else {
      setStartDate({ moment: dates.startDate, isValid: true });
      setEndDate({ moment: dates.endDate, isValid: true });
    }
  };

  const renderHelperText = useMemo(() => {
    if (startDate.moment.isAfter(endDate.moment, 'day')) {
      return props.translate('End_date_warning');
    }

    if (!endDate.isValid) {
      if (startDate.moment.isAfter(endDate.moment, 'day')) {
        return props.translate('End_date_warning');
      }
      return props.translate('Invalid_format');
    }
    return '';
  }, [startDate, endDate]);

  const onCalendarFocusChange = (focusedInput: 'endDate' | null) => {
    setFocusedDateRange('endDate');
  };

  const onPreviousPeriodChange = (e) => {
    const prev =
      selectedDaterange && getPreviousPeriod(e, startDate, endDate, selectedDaterange.url);
    if (prev) {
      setPreviousPeriod(prev);
    }
  };

  const onApplyDate = async () => {
    if (!startDate.isValid || !endDate.isValid) {
      return;
    }
    if (previousPeriod && selectedDaterange) {
      const event = { target: { value: previousPeriod.value } };
      const prevPeriod = getPreviousPeriod(event, startDate, endDate, selectedDaterange.url);

      setPreviousPeriod(prevPeriod);
      // If user selects first date by calendar and second date by keyboard input then the formatting of dates can be different
      // so we convert them before submitting
      props.onChange(
        moment(moment(startDate.moment).format('YYYY-MM-DD HH:mm:ss')),
        moment(moment(endDate.moment).format('YYYY-MM-DD HH:mm:ss')),
        prevPeriod
      );
      toggleMenu();
      updateQueryParams(
        startDate.moment,
        endDate.moment,
        prevPeriod,
        selectedDaterange.value === 0
      );
    }
  };

  const handlePresetPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, url } = presetRanges[event.target.value];
    setSelectedDaterange({ value, url });
    if (value === 17) {
      // Set previous period to None if user selects "Lifetime"
      setPreviousPeriod({
        value: 0,
        url: undefined,
        startDate: undefined,
        endDate: undefined,
      });
    }
    // if its not custom
    if (value !== 0) {
      const { start, end } = onPresetPeriodChange(Number(event.target.value));
      onCalendarDatesChange({ startDate: start, endDate: end }, true);
    }
  };

  //#region parsing
  const renderPeriodWithTranslation = (item) => {
    return props.translate(item.label, { period: item.period, year: item.year });
  };

  const getTranslatedLabel = (item) => {
    return {
      value: item.value,
      label: renderPeriodWithTranslation(item) as string,
    };
  };

  const getInitialMonth = () => {
    return moment(startDate.moment);
  };
  //#endregion

  //#region starting/ending dates input
  const onKeyboardStartDateChange = (start: moment.Moment) => {
    if (start && start.isValid()) {
      setSelectedDaterange({ value: 0, url: 'custom' });
      setStartDate({ moment: start, isValid: true });
    }
    if (start.isAfter(endDate.moment, 'day')) {
      setEndDate({ ...endDate, isValid: false });
    }
  };

  const onKeyboardEndDateChange = (end: moment.Moment) => {
    if (!end.isValid()) {
      setEndDate({ moment: end, isValid: false });
    }
    if (end && end.isValid()) {
      setSelectedDaterange({ value: 0, url: 'custom' });
      if (end.isBefore(startDate.moment, 'day')) {
        setEndDate({ moment: end, isValid: false });
      } else {
        setEndDate({ moment: end, isValid: true });
      }
    }
  };

  const onKeyboardStartDateBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      !moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isValid()
    ) {
      setStartDate({ ...startDate, isValid: false });
    }
    if (
      moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isAfter(
        endDate.moment,
        'day'
      )
    ) {
      setEndDate({ ...endDate, isValid: false });
    } else {
      setEndDate({ ...endDate, isValid: true });
    }
  };

  const onKeyboardEndDateBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      !moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isValid()
    ) {
      setEndDate({ ...endDate, isValid: false });
    }
  };
  //#endregion

  return (
    <ErrorBoundary identifier="date-range-selector">
      <div onClick={(event) => setAnchorEl(event.currentTarget)}>
        <DateRangeSelectorWindowsHandler handleClickOutside={handleOutsideDataRange}>
          <Select
            variant="outlined"
            menuIsOpen={menuOpen}
            isSearchable={false}
            onMenuOpen={toggleMenu}
            value={
              selectedDaterange &&
              getTranslatedLabel(dateRanges.find((pr) => pr.value === selectedDaterange.value))
            }
            TextFieldProps={{
              fdKey: 'date-range-dropdown',
              label: props.translate('Date_period'),
              variant: 'outlined',
              style: { zIndex: 0 },
              InputLabelProps: {
                shrink: true,
              },
            }}
            noOptionsMessage={() => null}
          />
          {menuOpen && anchorEl && (
            <Popper
              open={menuOpen}
              anchorEl={anchorEl}
              className={classes.menuContainer}
              disablePortal
              placement="bottom-start"
              modifiers={[
                {
                  name: 'flip',
                  enabled: false,
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                  options: {
                    boundariesElement: 'disabled',
                  },
                },
                {
                  name: 'hide',
                  enabled: false,
                },
              ]}
            >
              <div className={classes.dateRangeMenuContainer}>
                <div className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <FormControl className={classes.dateRangeSelection}>
                        <InputLabel shrink id="date-range-label" variant="standard">
                          <Translate id="Date_range" />
                        </InputLabel>
                        <MuiSelect
                          variant="standard"
                          id="date-range-picker-label"
                          value={selectedDaterange && selectedDaterange.value}
                          onChange={handlePresetPeriodChange}
                          displayEmpty
                          className={classes.selectDropdown}
                        >
                          {dateRanges.map((pr, idx) => (
                            <MenuItem key={idx} value={pr.value}>
                              {renderPeriodWithTranslation(pr)}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                    {selectedDaterange && selectedDaterange.value === 0 ? (
                      <>
                        <Grid className={classes.inputSelection} item md={6} sm={6} xs={6}>
                          <FormControl className={classes.inputSelection}>
                            <EnhancedLocalizationProvider>
                              <DatePicker
                                label={props.translate('Starting')}
                                value={startDate.moment}
                                slotProps={{
                                  inputAdornment: { classes: { root: classes.keyboardIcon } },
                                  textField: {
                                    className: classes.inputFocusedStart,
                                    error: !startDate.isValid,
                                    helperText: !startDate.isValid
                                      ? props.translate('Invalid_format')
                                      : '',
                                    variant: 'standard',
                                    inputProps: {
                                      onFocus: () => setFocusedDateRange('startDate'),
                                      onBlur: onKeyboardStartDateBlur,
                                      className: classes.inputFocusedStart,
                                      'data-fd': 'starting-date-input',
                                      id: 'starting-date-input',
                                    },
                                  },
                                  popper: { style: { zIndex: 9999 } },
                                }}
                                onChange={onKeyboardStartDateChange}
                                open={false}
                                format={moment.localeData(activeLanguage).longDateFormat('L')}
                              />
                            </EnhancedLocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid className={classes.inputSelection} item md={6} sm={6} xs={6}>
                          <FormControl className={classes.inputSelection}>
                            <EnhancedLocalizationProvider>
                              <DatePicker
                                label={props.translate('Ending')}
                                value={endDate.moment}
                                slotProps={{
                                  inputAdornment: { classes: { root: classes.keyboardIcon } },
                                  textField: {
                                    className: classes.inputFocusedEnd,
                                    error: !endDate.isValid,
                                    helperText: renderHelperText,
                                    variant: 'standard',
                                    inputProps: {
                                      onFocus: () => setFocusedDateRange('endDate'),
                                      onBlur: onKeyboardEndDateBlur,
                                      classes: { root: classes.inputFocusedEnd },
                                      className: classes.inputFocusedEnd,
                                      'data-fd': 'ending-date-input',
                                      id: 'ending-date-input',
                                    },
                                  },
                                  popper: { style: { zIndex: 9999 } },
                                }}
                                onChange={onKeyboardEndDateChange}
                                open={false}
                                format={moment.localeData(activeLanguage).longDateFormat('L')}
                              />
                            </EnhancedLocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <DayPickerRangeController
                            minimumNights={0} // enables selecting same start and end dates
                            startDate={startDate.moment} // momentPropTypes.momentObj or null,
                            endDate={endDate.moment} // momentPropTypes.momentObj or null,
                            onDatesChange={onCalendarDatesChange} // PropTypes.func.isRequired,
                            focusedInput={focusedDateRange} // focus on start date when opened
                            onFocusChange={onCalendarFocusChange} // PropTypes.func.isRequired,
                            initialVisibleMonth={getInitialMonth}
                            numberOfMonths={window.innerWidth < 768 ? 1 : 2} // PropTypes.func or null,
                            renderMonthElement={({ month }) =>
                              moment(month).locale(activeLanguage).format('MMMM YYYY')
                            }
                          />
                        </Grid>
                      </>
                    ) : null}
                    {props.hideCompare ? null : (
                      <Grid item xs={12} sm={12} md={12} className={classes.compareToSection}>
                        <FormControl className={classes.dateRangeSelection}>
                          <InputLabel shrink id="previous-period-label" variant="standard">
                            {props.translate('Previous_period_desc')}
                          </InputLabel>
                          <MuiSelect
                            variant="standard"
                            id="previous-period-label-dropdown"
                            value={previousPeriod && previousPeriod.value}
                            onChange={onPreviousPeriodChange}
                            displayEmpty
                            // This is disabled if user selects "Lifetime"
                            disabled={selectedDaterange && selectedDaterange.value === 17}
                            className={classes.selectDropdown}
                          >
                            <MenuItem value={0}>{props.translate('None')}</MenuItem>
                            <MenuItem value={10}>{props.translate('Previous_period')}</MenuItem>
                            <MenuItem value={20}>{props.translate('Last_year')}</MenuItem>
                          </MuiSelect>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </div>

                <Grid container className={classes.cancelApplySection}>
                  <Grid item className={classes.buttonLeft}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      fdKey="cancel-date-period-btn"
                      onClick={toggleMenu}
                    >
                      {props.translate('Cancel')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.buttonRight}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      fdKey="apply-date-period-btn"
                      onClick={onApplyDate}
                      disabled={!startDate.isValid || !endDate.isValid}
                    >
                      {props.translate('Apply')}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Popper>
          )}
        </DateRangeSelectorWindowsHandler>
      </div>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    activeLanguage: getLocale(state),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(DateRangeSelector);
