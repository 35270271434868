import React from 'react';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '480px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '70vw',
    },
    maxHeight: 'min(720px, 100% - 96px)',
  },
  dialogTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  dialogContent: {
    paddingTop: '4px',
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}));

export type DialogProps = {
  children: React.ReactNode;
  title: string;
  open: boolean;
  actions?: JSX.Element[] | JSX.Element;
};

export const Dialog = (props: DialogProps): JSX.Element => {
  const { title, children, actions, open } = props;

  const classes = useStyles();

  return (
    <MuiDialog open={open} PaperProps={{ className: classes.dialog }}>
      <MuiDialogTitle className={classes.dialogTitle}>
        <Typography variant="h5" component="span">
          {title}
        </Typography>
      </MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
      <MuiDialogActions className={classes.dialogActions}>{actions}</MuiDialogActions>
    </MuiDialog>
  );
};
