import { StoreGroup } from '@flipdish/api-client-typescript';
import {
  CreateFailure,
  CreateRequest,
  CreateSuccess,
  LoadFailure,
  LoadRequest,
  LoadSuccess,
  SetSearch,
} from '../actions/storegroup.actions';
import {
  STORE_GROUP_CREATE_FAILURE,
  STORE_GROUP_CREATE_REQUEST,
  STORE_GROUP_CREATE_SUCCESS,
  STORE_GROUP_GET_ALL_FAILURE,
  STORE_GROUP_GET_ALL_REQUEST,
  STORE_GROUP_GET_ALL_SUCCESS,
  STORE_GROUP_SET_SEARCH,
  STORE_GROUP_UPDATE_FAILURE,
  STORE_GROUP_UPDATE_REQUEST,
} from '../constants/storegroup.constants';

type DefaultState = {
  item_order_index: number[];
  totalCount?: number;
  error?: Error;
  showSearch: boolean;
  search?: {
    query: string;
    item_order_index: number[];
    totalCount?: number;
    error?: Error;
  };
  create?: {
    storeGroup?: StoreGroup;
    error?: Error;
  };
};
const defaultState: DefaultState = {
  item_order_index: [],
  showSearch: false,
};

// #region state reducers

const reduceSetSearch = (state: DefaultState, action: SetSearch): DefaultState => {
  const query = action.payload;
  if (!query) {
    return {
      ...state,
      search: undefined,
    };
  }

  return {
    ...state,
    showSearch: true,
    search: {
      query,
      item_order_index: [],
    },
  };
};

const reduceGetAllRequest = (state: DefaultState, action: LoadRequest): DefaultState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      error: undefined,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
      },
    };
  }

  return {
    ...state,
    error: undefined,
  };
};
const reduceGetAllSuccess = (state: DefaultState, action: LoadSuccess): DefaultState => {
  const { query, totalCount } = action.meta;
  if (query) {
    const ids = ((state.search && state.search.item_order_index) || []).concat(
      action.payload.map((g) => g.StoreGroupId as number)
    );
    const uniqueIds = new Set(ids);
    return {
      ...state,
      search: {
        query,
        item_order_index: [...uniqueIds],
        totalCount,
      },
    };
  }

  const ids = state.item_order_index.concat(action.payload.map((g) => g.StoreGroupId as number));
  const uniqueIds = new Set(ids);
  return {
    ...state,
    totalCount,
    item_order_index: [...uniqueIds],
    showSearch: totalCount > 20,
    search: undefined,
    error: undefined,
  };
};
const reduceGetAllFailure = (state: DefaultState, action: LoadFailure): DefaultState => {
  const { query } = action.meta;
  if (query) {
    return {
      ...state,
      search: {
        query,
        item_order_index: (state.search && state.search.item_order_index) || [],
        totalCount: (state.search && state.search.totalCount) || undefined,
        error: action.payload,
      },
    };
  }

  return {
    ...state,
    error: action.payload,
  };
};

const reduceCreateRequest = (state: DefaultState, action: CreateRequest): DefaultState => {
  return {
    ...state,
    error: undefined,
    create: {
      storeGroup: action.payload.storeGroup,
    },
  };
};
const reduceCreateSuccess = (state: DefaultState, action: CreateSuccess): DefaultState => {
  return {
    ...state,
    totalCount: (state.totalCount || 0) + 1,
    create: {
      storeGroup: action.payload.storeGroup,
    },
  };
};
const reduceCreateFailure = (state: DefaultState, action: CreateFailure): DefaultState => {
  return {
    ...state,
    create: {
      ...state.create,
      error: (action as CreateFailure).payload,
    },
  };
};
// #endregion

export default function storeGroups(state = defaultState, action): DefaultState {
  switch (action.type) {
    case STORE_GROUP_SET_SEARCH:
      return reduceSetSearch(state, action);
    case STORE_GROUP_GET_ALL_REQUEST:
      return reduceGetAllRequest(state, action);
    case STORE_GROUP_GET_ALL_SUCCESS:
      return reduceGetAllSuccess(state, action);
    case STORE_GROUP_GET_ALL_FAILURE:
      return reduceGetAllFailure(state, action);
    // case storeGroupEventConstants.CREATED:
    // should check if creation is for currentApp
    //   return {
    //     ...state,
    //     create: {
    //       storeGroupId: action.data.StoreGroupId,
    //     },
    //   };
    case STORE_GROUP_CREATE_REQUEST:
      return reduceCreateRequest(state, action);
    case STORE_GROUP_CREATE_SUCCESS:
      return reduceCreateSuccess(state, action);
    case STORE_GROUP_CREATE_FAILURE:
      return reduceCreateFailure(state, action);
    case STORE_GROUP_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case STORE_GROUP_UPDATE_REQUEST:
      return {
        ...state,
        error: undefined,
      };
    default:
      return state;
  }
}
