import { useEffect, useState } from 'react';
import { usePrevious } from './usePrevious';

export type KeyPressType = { key: string };

type Options = {
  el: HTMLElement | Window;
  keys?: string[];
  preventDefault?: boolean;
  active: boolean;
};

export default ({ el, keys, preventDefault, active }: Options) => {
  const previousActive = usePrevious(active);
  const [keyPressed, setKeyPressed] = useState<KeyPressType>({ key: '' });

  const keyPressHandler = (e: KeyboardEvent) => {
    const { key } = e;

    if (active && (!keys || (keys && keys.includes(key)))) {
      if (preventDefault) {
        e.preventDefault();
      }
      setKeyPressed({ key });
    }
  };

  useEffect(
    () => {
      el.addEventListener('keydown', keyPressHandler, false);

      return () => {
        el.removeEventListener('keydown', keyPressHandler);
      };
    },
    [active, previousActive]
  );

  return keyPressed;
};
