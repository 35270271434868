import * as React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  bckSvg: {
    width: '100%',
    height: 'auto',

    '& > rect': {
      fill: '#1d40a7',
    },
    '& > text': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fill: 'rgba(255, 255, 255, 0.6)',
      textAnchor: 'middle',
      '&:last-child': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: 1.43,
        letterSpacing: theme.typography.pxToRem(0.3),
      },
    },
  },
}));

export interface IMapCardMediaProps {
  title?: TranslationId;
  caption?: TranslationId;
}

type Props = IMapCardMediaProps;
const MapCardMedia = ({ title, caption }: Props) => {
  const classes = useStyles();

  return (
    <svg width={660} height={360} xmlns="http://www.w3.org/2000/svg" className={classes.bckSvg}>
      <rect width="100%" height="100%" />
      {title && (
        <text x="50%" y="46%">
          <Translate id={title} />
        </text>
      )}
      {caption && (
        <text x="50%" y="60%">
          <Translate id={caption} />
        </text>
      )}
    </svg>
  );
};

export default MapCardMedia;
