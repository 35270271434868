import React from 'react';

import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { GetCheckoutSummaryResponse } from '../../overrides/@flipdish/subscriptions-api-client-typescript/api';

export const customRange = [
  { label: 'Last_x_days', period: '90', value: 5, url: 'last90Days' },
  { label: 'Last_x_days', period: '180', value: 16, url: 'last180Days' },
  { label: 'Last_x_days', period: '365', value: 17, url: 'last365Days' },
  { label: 'Custom', value: 0, url: 'custom' },
];

export const translateInterval = (
  interval: GetCheckoutSummaryResponse.IntervalEnum,
  version: 'singular' | 'plural',
  caseType: 'lower' | 'upper'
) => {
  let result: string | React.ReactNode = interval.toString();
  if (version === 'singular') {
    switch (interval) {
      case GetCheckoutSummaryResponse.IntervalEnum.Day:
        result = <Translate id="Day" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Week:
        result = <Translate id="Week" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Month:
        result = <Translate id="Month" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Year:
        result = <Translate id="Year" />;
        break;
    }
  }
  if (version === 'plural') {
    switch (interval) {
      case GetCheckoutSummaryResponse.IntervalEnum.Day:
        result = <Translate id="Daily" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Week:
        result = <Translate id="Weekly" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Month:
        result = <Translate id="Monthly" />;
        break;
      case GetCheckoutSummaryResponse.IntervalEnum.Year:
        result = <Translate id="Yearly" />;
        break;
    }
  }

  return caseType === 'lower' ? (
    <Typography sx={{ textTransform: 'lowercase' }} variant="inherit" component="span">
      {result}
    </Typography>
  ) : (
    result
  );
};

const developmentCancellableProducts = [
  'prod_P6fBPkHUt5bpeZ', // Customer Feedback Messaging
  'prod_MgqQW58b7D4Gx5', // Kiosk Subscription unit test
];
const productionCancellableProducts = [
  'prod_OljzsPVhnVvStT', // Customer Feedback Messaging
];

const isProduction = process.env.ENVIRONMENT === 'production';
const cancellableProducts = isProduction
  ? productionCancellableProducts
  : developmentCancellableProducts;

export const canCancelProduct = (productId: string) => {
  return true; // Temporary while testing
  return cancellableProducts.includes(productId);
};
