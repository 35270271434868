import { UnknownAction } from 'redux';

import {
  ADD_TRANSLATION_FOR_LANGUAGE,
  AddTranslationPayload,
  INITIALIZE,
  InitializePayload,
  SET_ACTIVE_LANGUAGE,
} from './actions';
import { Translation } from './index';

export type TransFormFunction = (
  data: Record<string, string>,
  languageCodes: string[]
) => Translation;
export type MissingTranslationCallback = (key: string, languageCode: string) => any;

export type Options = {
  renderInnerHtml?: boolean;
  defaultLanguage?: string;
  showMissingTranslationMsg?: boolean;
  missingTranslationMsg?: string;
  missingTranslationCallback?: MissingTranslationCallback;
  translationTransform?: TransFormFunction;
  ignoreTranslateChildren?: boolean;
};

export type LocaleState = typeof defaultState;

const defaultState = {
  languages: {} as Translation,
  activeLanguage: 'en' as string,
  options: {
    renderInnerHtml: true,
    ignoreTranslateChildren: false,
    showMissingTranslationMsg: true,
    missingTranslationMsg: 'Missing translation key ${key} for language ${code}',
  } as Options,
};

const localeReducer = (state = defaultState, action: UnknownAction) => {
  switch (action.type) {
    case INITIALIZE: {
      const { languages = [], options = {} } = action.payload as InitializePayload;
      const filteredLanguages =
        process.env.GIT_BRANCH === 'master'
          ? languages.filter((lang) => lang !== 'dev-lang')
          : languages;

      return {
        ...state,
        languages: filteredLanguages.reduce((res, lang) => {
          res[lang] = {};
          return res;
        }, {}),
        ...(options && options.defaultLanguage
          ? {
              activeLanguage: options.defaultLanguage,
            }
          : { activeLanguage: 'en' }),
        options: { ...state.options, ...(options || {}) },
      };
    }
    case ADD_TRANSLATION_FOR_LANGUAGE: {
      const { language, translation } = action.payload as AddTranslationPayload;
      if (process.env.GIT_BRANCH === 'master' && language === 'dev-lang') {
        return state;
      }
      return {
        ...state,
        languages: { ...state.languages, [language]: translation },
      };
    }
    case SET_ACTIVE_LANGUAGE: {
      return {
        ...state,
        activeLanguage: action.payload as string,
      };
    }
    default:
      return state;
  }
};

export default localeReducer;
