import React from 'react';

import Hidden from '@mui/material/Hidden';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Skeleton from 'react-loading-skeleton';

import { type TDataTableProps } from './index';
import { StyledTableCell } from './table-cell';

export function DataTableBody<T>({
  isLoading,
  data,
  headers,
  uniqueFieldName,
}: TDataTableProps<T>) {
  const renderTableCell = (row: T) =>
    headers.map((header) => {
      const { renderDataCell } = header;
      const value = row[header.name];
      return (
        <Hidden key={header.name} {...header.hiddenProps}>
          <StyledTableCell variant="body">
            {isLoading ? (
              <Skeleton />
            ) : (
              (renderDataCell && renderDataCell({ header, row, value })) || <>{value}</>
            )}
          </StyledTableCell>
        </Hidden>
      );
    });

  const rows = data.map((row: T) => {
    const id = row[uniqueFieldName];
    assert(!!id, "unique field name can't be empty");
    // Conversion of type 'T[keyof T]' to type 'string' may be a mistake
    return <TableRow key={id as any}>{renderTableCell(row)}</TableRow>;
  });

  return <TableBody>{rows}</TableBody>;
}
