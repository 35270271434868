import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  boxContents: {
    marginTop: theme.spacing(3),
    height: '236px',
  },
  emptyMessageTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));

export type NoMetafieldDefinitionsMessageProps = {
  translate: TranslateFunction;
};

export const NoMetafieldDefinitionsMessage = ({
  translate,
}: NoMetafieldDefinitionsMessageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box data-fd="no-metafield-definitions-message" centeredContent className={classes.boxContents}>
      <Typography className={classes.emptyMessageTitle}>
        {translate('No_metafield_definitions')}
      </Typography>
    </Box>
  );
};
