import React, { useMemo, useState } from 'react';

import { LoyaltyCampaign, RetentionCampaign, StoreListItem } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { CurrencyEnum } from '../../../ui/GenericTable/types';
import { CampaignsTypes } from '../Campaigns';
import { getStores } from '../selectors';
import { CampaignItem } from './CampaignItem';
import EmptyCampaignItem from './EmptyCampaignItem';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(2, 0, 1),

    [theme.breakpoints.down('md')]: {
      paddingLeft: 18,
    },
  },
  loader: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
}));

type Props = {
  type: CampaignsTypes;
  campaigns: (RetentionCampaign | LoyaltyCampaign)[];
  onEdit: (
    type: CampaignsTypes,
    campaignId: (RetentionCampaign | LoyaltyCampaign)['CampaignId']
  ) => void;
  allStores?: Array<StoreListItem>;
  isLoading: boolean;
  languageCode?: string;
};

const CampaignItemsContainer = ({
  allStores,
  campaigns,
  onEdit,
  type,
  isLoading,
  languageCode,
}: Props) => {
  const classes = useStyles();
  //TODO a BE needs to return currency
  const [currency, setCurrency] = useState<CurrencyEnum>('EUR');

  const CampaignList = useMemo(() => {
    return campaigns.map((campaign) => {
      const storesNames = intersectionWith(
        allStores,
        campaign.StoreIds as number[],
        (store, id) => store.StoreId === id
      ).map((store) => store.StoreName) as string[];

      return (
        <CampaignItem
          storesNames={storesNames}
          type={type}
          data={campaign}
          key={campaign.CampaignId}
          onEdit={onEdit}
          currency={currency}
          languageCode={languageCode}
        />
      );
    });
  }, [campaigns, type, allStores]);

  const renderContent = <>{isEmpty(campaigns) ? EmptyCampaignItem(type) : CampaignList}</>;

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        <Translate id={type === CampaignsTypes.LOYALTY ? 'Loyalty' : 'Retention'} />
      </Typography>

      {isLoading ? (
        <div className={classes.loader}>
          <Skeleton height={160} />
        </div>
      ) : (
        renderContent
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    allStores: getStores(state),
    languageCode: getActiveLanguage(state.locale),
  };
};

export default connect(mapStateToProps, null)(CampaignItemsContainer);
