import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import PageLayout from '../../../../ui/Layout';
import PaperContainer from '../../../../ui/Layout/PaperContainer';
import {
  bankingActionsAsync,
  getBankAccountDetails,
  getCountriesWithFieldDefinitions,
  removeBankAccount,
} from '../banking.actions';
import { getBankAccountListUrl } from '../banking.selectors';
import EditAccountForm from '../components/EditAccountForm';
import RemoveBankAccountButton from '../components/RemoveBankAccountButton';
import LoadingDetails from '../Details/LoadingDetails';

type Props = RouteComponentProps<{ appId: string; accountId: string }>;
const BankingEdit = (props: Props & mapStateToProps & mapDispatchToProps) => {
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const {
    updateBankAccount,
    account,
    getCountriesFieldDefinitions,
    accountId,
    isReady,
    removeBankAccount,
  } = props;

  useEffect(() => {
    getCountriesFieldDefinitions();
  }, []);

  // back to bank account page
  if (isSubmitSuccess && props.bankingRoute) {
    return <Redirect to={props.bankingRoute} />;
  }

  return (
    <PageLayout toParent="./" title={account && account.AccountName} documentTitle="Banking">
      <PaperContainer>
        {!isReady ? (
          <LoadingDetails />
        ) : (
          <EditAccountForm
            submit={updateBankAccount}
            onSubmitSuccess={setSubmitSuccess}
            account={account}
            accountId={accountId}
          />
        )}
      </PaperContainer>
      <Box pt={1} pb={2}>
        <RemoveBankAccountButton storeNames={account.StoreNames} handleRemove={removeBankAccount} />
      </Box>
    </PageLayout>
  );
};

type mapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { banking } = state;
  const accountId = Number(props.match.params.accountId);
  const account = banking.accountById[accountId];
  const isLoading = account === undefined;
  const isReady = !isLoading && !isEmpty(banking.countriesfieldDefinitions);
  const bankingRoute = getBankAccountListUrl(state);
  return {
    isReady,
    accountId,
    account,
    bankingRoute,
    isLoading,
  };
};

type mapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: Props) => {
  return {
    updateBankAccount: (bankAccount) => {
      return dispatch(
        bankingActionsAsync.updateBankAccountAsync(
          Number(ownProps.match.params.accountId),
          bankAccount
        )
      ).then(() => {
        dispatch(getBankAccountDetails(Number(ownProps.match.params.accountId)));
      });
    },
    removeBankAccount: () =>
      dispatch(removeBankAccount(Number(ownProps.match.params.accountId))).then(() => {
        ownProps.history.replace(`/${ownProps.match.params.appId}/finance/bank-accounts/`);
      }),
    getCountriesFieldDefinitions: () => {
      dispatch(getCountriesWithFieldDefinitions());
    },
  };
};

export default compose<Props, RouteComponentProps<{ appId: string; accountId: string }>>(
  connect(mapStateToProps, mapDispatchToProps)
)(BankingEdit);
