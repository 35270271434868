import React from 'react';

import {
  RestaurantVoucherAssignedStore,
  RestaurantVoucherPayGreenConfiguration,
} from '@flipdish/api-client-typescript';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import StoreListTruncated from '../../../StoreListTruncated';

const useStyles = makeStyles(() => ({
  voucher: {
    lineHeight: '0.5px',
  },
}));

function getAssignedStoreNames(
  assignedStores: RestaurantVoucherAssignedStore[] | undefined
): string[] {
  if (!assignedStores) {
    return [];
  }

  return assignedStores.map((assignedStore) => assignedStore.Name ?? 'N/A');
}

export type RestaurantVoucher = {
  restaurantVoucher: RestaurantVoucherPayGreenConfiguration;
};

type Props = RestaurantVoucher;
const RestaurantVoucherDetails: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { restaurantVoucher } = props;
  const voucherName = restaurantVoucher.Name || '';
  const voucherPaygreenId = restaurantVoucher.PayGreenId || '';
  const voucherAssignedStoreNames = getAssignedStoreNames(restaurantVoucher.AssignedStores);
  const classes = useStyles();

  return (
    <ListItemText
      primary={
        <div className={classes.voucher} data-fd={voucherName}>
          <Typography variant="subtitle1">{voucherName}</Typography>
          <Typography variant="caption">{voucherPaygreenId} </Typography>
          {voucherAssignedStoreNames && (
            <StoreListTruncated
              storeNames={voucherAssignedStoreNames}
              textPrefix={true}
              variant={'caption'}
              btnFontSize={'12px'}
              minWidth={'small'}
              color="textSecondary"
            />
          )}
        </div>
      }
    />
  );
};

export default RestaurantVoucherDetails;
