import React from 'react';

import Switch, { type SwitchProps } from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  switch: {
    '& .MuiSwitch-thumb': {
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '-1px',
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="13" viewBox="0 0 13 10"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.50117 7.501L1.72117 4.721L0.777832 5.66434L4.50117 9.38767L12.5012 1.38767L11.5578 0.444336L4.50117 7.501Z"/></svg>')`,
      },
    },
  },
}));

type Props = SwitchProps & {
  fdKey: string;
};

export const EnableSwitch = ({ fdKey, checked, inputProps = {}, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Switch
      checked={checked}
      color="default"
      edge="start"
      className={classes.switch}
      inputProps={
        {
          'data-fd': fdKey,
          'data-fd-value': `${!!checked}`,
          ...inputProps,
        } as React.InputHTMLAttributes<HTMLInputElement>
      }
      {...rest}
    />
  );
};
