export const getHyphenatedEntityName = (entityName?: string) => {
  if (entityName) {
    return entityName
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace('.', '-')
      .toLowerCase();
  }
  return undefined;
};

export const geKeyFromUrlParam = (keyParam: string): string => {
  return keyParam.replace('-', '.');
};

export const getBehaviourTranslationKey = (behaviour: string): TranslationId => {
  switch (behaviour) {
    case 'SendToMenu':
      return 'Send_to_menus';
    case 'SendToOrder':
      return 'Send_to_orders';
    default:
      return 'Unknown';
  }
};
