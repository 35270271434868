import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import SearchIcon from '@mui/icons-material/SearchOutlined';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import useKeyPress from '../../../../custom-hooks/useKeyPress';
import { VoucherFilterProps } from '../../types';

const getFilterId = ({ label, value }: VoucherFilterProps): string => `${label}/${value}`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    margin: '-7px 0px 8px 0px',
    zIndex: 4,
    [theme.breakpoints.down('md')]: {
      marginBottom: '8px',
    },
  },
  wrapper: {
    borderRadius: '4px',
    position: 'relative',
    paddingRight: '8px 14px 8px 8px',
    border: 'solid 1px rgba(0, 0, 0, 0.54)',
  },
  wrapperFocused: {
    border: 'solid 2px #05149E',
  },
  content: {
    flexDirection: 'row',
    display: 'flex',
  },
  label: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.67)',
  },
  input: {
    zIndex: 1,
    flexGrow: 1,
    height: 'auto',
    width: 'auto',
    border: 'none',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.54)',
    overflow: 'hidden',
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: '4px',
    marginTop: '5px',
  },
  dropdown: {
    marginTop: theme.spacing(1),
    width: '100%',
    position: 'absolute',
    top: '100%',
  },
  list: {
    maxHeight: '300px',
    overflow: 'auto',
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
  },
  listItem: {
    fontSize: '14px',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    margin: theme.spacing(0.25, 0.25),
  },
  nestedChip: {
    margin: theme.spacing(0.25, 0.25),
    color: 'rgb(0, 0, 0, 0.6)',
  },
}));

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

type OuterProps = {
  options: VoucherFilterProps[];
  isLoading: boolean;
  placeholder: string;
  label: string;
  selectedValue?: VoucherFilterProps[];
  onInputChange: (newValue: string) => void;
  onChange: (values) => void;
  setUrlParams: (params) => void;
};

const Filter = ({
  translate,
  options,
  isLoading,
  onInputChange,
  placeholder,
  label,
  onChange,
  selectedValue,
  setUrlParams,
}: Props) => {
  const classes = useStyles();
  const [selectedFilters, setSelectedFilters] = useState<VoucherFilterProps[]>(selectedValue || []);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [focused, setFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>();
  const listRef = useRef<HTMLDivElement>(null);

  const openDropdown = () => setFocused(true);
  const closeDropdown = () => setFocused(false);
  const clickDropdownItem = (option: VoucherFilterProps) => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
    closeDropdown();
    addFilter(option);
  };

  const focusHandler = () => {
    openDropdown();
  };

  const blurHandler = () => {
    closeDropdown();
  };

  const updateSearchValue = (value: string) => {
    setSearchValue(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    updateSearchValue(value);
  };

  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      const { key } = event;

      if (key === 'Backspace' && !value && selectedFilters.length) {
        removeFilter();
      }
    }
  };

  const removeFilter = (filter?: VoucherFilterProps) => {
    if (filter) {
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    }
    setSelectedFilters(selectedFilters.slice(0, selectedFilters.length - 1));
  };

  const addFilter = (filter: VoucherFilterProps) => {
    const newFilters = [...selectedFilters, filter];
    setSelectedFilters(newFilters);
    updateSearchValue('');
  };

  const visibleOptions = useMemo(() => {
    return (options || []).filter((option) => {
      return (
        !searchValue ||
        (searchValue && option.label.toLowerCase().includes(searchValue.toLowerCase()))
      );
    });
  }, [searchValue, options]);

  const selectableOptions = useMemo(
    () =>
      visibleOptions.filter(
        (option) => selectedFilters.map((filter) => filter.value).indexOf(option.value) === -1
      ),
    [visibleOptions, selectedFilters]
  );

  const renderOption = (option: VoucherFilterProps, index?: number) => {
    return (
      <MenuItem
        className={classes.listItem}
        key={getFilterId(option)}
        selected={selectedIndex === index}
        onMouseEnter={index === undefined ? undefined : () => setSelectedIndex(index)}
        onClick={index === undefined ? undefined : () => clickDropdownItem(option)}
      >
        <span>{option.label}</span>
      </MenuItem>
    );
  };

  const renderedOptions = useMemo(
    () => selectableOptions.map(renderOption),
    [selectableOptions, selectedIndex]
  );

  const renderedFilters = useMemo(() => {
    return selectedFilters.map((filter, ind) => (
      <Chip
        key={ind}
        className={classes.chip}
        label={filter.label}
        onDelete={() => {
          removeFilter(filter);
        }}
      />
    ));
  }, [selectedFilters]);

  const keyPressed = useKeyPress({
    el: window,
    keys: ['ArrowDown', 'ArrowUp', 'Enter'],
    active: focused,
  });

  useEffect(() => {
    if (keyPressed.key === 'ArrowDown' && selectedIndex < selectableOptions.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
    if (keyPressed.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
    if (keyPressed.key === 'Enter' && visibleOptions[selectedIndex]) {
      const option = visibleOptions[selectedIndex];
      clickDropdownItem(option);
    }
  }, [keyPressed]);

  useEffect(() => {
    if (!firstRender) {
      onChange(selectedFilters);
      setUrlParams(selectedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={blurHandler}>
        <div>
          <fieldset
            className={clsx({
              [classes.wrapper]: true,
              [classes.wrapperFocused]: focused,
            })}
          >
            <legend className={classes.label}>{label}</legend>
            <div className={classes.content}>
              <div>{renderedFilters}</div>
              <div className={classes.input}>
                <InputBase
                  fullWidth
                  value={searchValue}
                  inputRef={inputRef}
                  onChange={changeHandler}
                  onKeyDown={keyDownHandler}
                  placeholder={selectedFilters.length ? '' : placeholder}
                  onFocus={focusHandler}
                />
              </div>
            </div>
            <SearchIcon className={classes.searchIcon} onClick={focusHandler} />
          </fieldset>
          {focused && (
            <div className={classes.dropdown}>
              <Paper>
                <div ref={listRef} className={classes.list}>
                  {!isLoading && renderedOptions.length ? (
                    renderedOptions
                  ) : (
                    <MenuItem className={classes.listItem}>
                      {isLoading ? translate('Loading') : translate('No_option')}
                    </MenuItem>
                  )}
                </div>
              </Paper>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;

const mapStateToProps = ({ locale }) => ({
  translate: getTranslate(locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(Filter);
