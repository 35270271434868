import React from 'react';

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { load, setSearch } from '../../actions/storegroup.actions';
import {
  getStoreGroups,
  getStoreGroupsLoading,
  getStoreGroupTotalCount,
} from '../../selectors/storegroup.selector';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState) => {
    const loading = getStoreGroupsLoading(state);
    const data = getStoreGroups(state);
    const storeGroupeTotalCount = getStoreGroupTotalCount(state);
    const isEmpty = storeGroupeTotalCount === 0;
    const storeGroupShowSearch = state.storeGroups.showSearch;

    return {
      storeGroupData: data,
      storeGroupDataIsEmpty: isEmpty,
      storeGroupeTotalCount,
      storeGroupDataLoading: loading,
      storeGroupShowSearch,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = () => {
  return (dispatch: ThunkDispatch) => {
    return {
      loadNext: async (page: number, itemsPerPage: number) => {
        await dispatch(load({ page, limit: itemsPerPage }));
      },
      storeGroupSetSearch: (query?: string) => {
        dispatch(setSearch(query));
      },
    };
  };
};

type UnpackType<T> = T extends React.ComponentType<React.PropsWithChildren<infer P>> ? P : T;
export type WithStoreGroupData = UnpackType<ReturnType<typeof withStoreGroupData>> & {
  dispatch: ThunkDispatch;
};

export type StoreGroupDataComponentProps<P = {}> = P & MappedState & MappedDispatch;
const withStoreGroupData = <P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<StoreGroupDataComponentProps<P>>>
) =>
  compose<P & MappedState & MappedDispatch, P & MappedState>(
    setDisplayName('WithStoreGroupData'),
    connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
  )(Component);

export default withStoreGroupData;
