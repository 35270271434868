import React, { useMemo } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

type MenuItem = {
  textId: TranslationId;
  value: string;
};

type Props = {
  items: MenuItem[];
  open: boolean;
  onClick: (value: string) => void;
  onClose: () => void;
  anchorEl?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    menu: {
      boxShadow:
        '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
    },
  })
);

export const CampaignSelectionMenu = ({
  open = false,
  anchorEl,
  items,
  onClick,
  onClose,
}: Props) => {
  const classes = useStyles();

  const List = useMemo(() => {
    return items.map(({ value, textId }) => (
      <MenuItem
        id={`add-${value}-campaign-menu-item`}
        key={value}
        value={value}
        onClick={() => onClick(value)}
      >
        <Translate id={textId} />
      </MenuItem>
    ));
  }, [items]);

  if (anchorEl === null) {
    return null;
  }

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="top-end"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            top: -12,
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper className={classes.menu}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList>{List}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
