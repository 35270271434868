import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../FormItemLayout';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const validateAsync = (value: string) => {
  return sleep(1000).then(() => {
    if (value === undefined || value.length === 0) {
      return 'Required';
    }
    if (value.length > 20) {
      return 'Max_length_20';
    }
    return undefined;
  });
};

const CodeField = ({ disabled }) => (
  <Field name={'Code'} validate={(value: string) => validateAsync(value)}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout
          description={<Translate id="Voucher_code_description" />}
          label={<Translate id="Voucher_code" />}
        >
          <TextField
            {...field}
            autoComplete="off"
            disabled={isSubmitting || disabled}
            error={showError}
            fdKey={'voucher-code'}
            helperText={showError ? <Translate id={fieldError as TranslationId} /> : undefined}
            minWidth={200}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 20) {
                field.onChange(e);
              }
            }}
            variant="outlined"
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default CodeField;
