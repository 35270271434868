export type TValidationType = {
  [validationType in EValidationType]?: TranslationId;
};

export enum EValidationType {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  invalidValue = 'invalidValue',
  minValue = 'minValue',
  maxValue = 'maxValue',
  email = 'email',
  phoneNumber = 'phoneNumber',
}
