import { createAction } from 'redux-act';

import {
  closeAllOtherNotifications,
  ERROR_KEY,
  notifyError,
  notifySaved,
  notifySaving,
  SAVED_KEY,
  SAVING_KEY,
} from '../../../layouts/Notify/actions';
import uploadLogo, { storeService } from '../../../services/store.service';

export const setStoreKioskSettings = createAction<FlipdishPrivate.StoreKioskSetting[]>(
  'SET_STORE_KIOSK_SETTINGS'
);

export const updateStoreKioskSetting = createAction<FlipdishPrivate.StoreKioskSetting>(
  'UPDATE_STORE_KIOSK_SETTINGS'
);
export const setStoreKioskLogo = createAction<string | undefined>('GET_STORE_KIOSK_LOGO');

export const deleteStoreLogo = createAction<string | undefined>('DELETE_STORE_KIOSK_LOGO');

// #region getStoreKioskSettings

export const getStoreKioskSettings = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    const ks = await storeService.getStoreKioskSettings(storeId);
    dispatch(setStoreKioskSettings(ks.KioskSettings || []));
    dispatch(setStoreKioskLogo(ks.StoreLogoUrl));
  };
};
// #endregion

// #region addLogoForKioskStore

export const addStoreLogo = (storeId: number, data: FormData) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      await uploadLogo.addLogoImage(storeId, data);
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
      throw e;
    }
  };
};
// #endregion

// #region deleteStoreKioskLogo

export const deleteStoreKioskLogo = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      await storeService.deleteStoreLogoImage(storeId);
      dispatch(deleteStoreLogo(undefined));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
      throw e;
    }
  };
};
// #endregion
