import React, { useMemo, useRef, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { maxWidth } from '../Layout';
import ColumnSelector from './ColumnSelector';
import DownLoadCsvPdf from './DownloadCsvPdf';
import GenericTableBody, { getAlignmentByColumnType } from './GenericTableBody';
import GenericTablePagination from './GenericTablePagination';
import GenericTableTitle from './GenericTableTitle';
import HighlightScrollbar from './HighlightScrollbar';
import { CurrencyEnum, GenericTableColumns, GenericTablePaginationType, StyleProps } from './types';

const MAX_COLUMNS_MOBILE = 3;
const MAX_COLUMNS_DESKTOP = 6;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      padding: '2px 0',
      height: '6px',
      border: '1px solid #d5d5d5',
    },
    '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
    '&::-webkit-scrollbar-thumb': { background: '#f5f5f5', borderRadius: '3px' },
  },
  hovered: {
    '&::-webkit-scrollbar-thumb': { background: 'rgba(5, 20, 158, 0.3)' },
    '&::-webkit-scrollbar-thumb:horizontal:active': { background: 'rgba(5, 20, 158, 0.6)' },
    '&::-webkit-scrollbar-thumb:vertical:active': { background: 'rgba(5, 20, 158, 0.6)' },
    '&:hover $sticky': {
      boxShadow: ({ isScrolling }: StyleProps) => {
        return isScrolling ? '0px 2px 6px rgba(0, 0, 0, 0.5)' : 'none';
      },
      transition: 'box-shadow 200ms',
    },
  },
  table: ({ tableLayout }: StyleProps) => {
    return {
      maxWidth: maxWidth,
      boxSizing: 'content-box',
      borderCollapse: 'separate', // removes bottom border in firefox from 'sticky' element if set to 'collapse' - the default for Material UI
      tableLayout: tableLayout,
    };
  },
  cells: ({ tableLayout, maxCellWidthProp }: StyleProps) => {
    return {
      boxSizing: 'border-box',
      fontSize: 13,
      height: theme.spacing(7),
      letterSpacing: 0.4,
      minWidth: theme.spacing(11),
      maxWidth: maxCellWidthProp ?? '',
      paddingTop: theme.spacing(1.25),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(2),
      whiteSpace: tableLayout === 'fixed' ? 'normal' : 'nowrap',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    };
  },
  cursor: {
    '&:hover': { cursor: 'pointer' },
  },
  rowHover: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      '&:hover td': {
        backgroundColor: 'rgb(234,242,255)',
      },
    },
  },
  rowHoverWarning: {
    [theme.breakpoints.up('sm')]: {
      '&:hover td': {
        backgroundColor: 'rgba(255,72,107,0.2)!important',
      },
    },
  },
  titleCell: { background: '#FAFAFA!important', height: `${theme.spacing(2)} !important` },
  highlightWarning: {
    backgroundColor: 'rgba(255, 215, 223, 0.2)!important',
  },
  failedFont: {
    color: '#FF2346',
  },
  paidFont: {
    color: '#20C26E',
  },
  selectedCol: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  col: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  link: {
    color: '#05149e',
    '&:hover': { cursor: 'pointer' },
  },
  // do not remove this 'unused' arg, $sticky selector relies on it
  sticky: (_) => {
    return {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: '#FFFFFF',
    };
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  cellSubtext: {
    fontSize: 13,
    lineHeight: '18px',
    color: 'rgba(0,0,0,0.5)',
  },
}));

type InnerProps = {};
type OuterProps = {
  AppId: string;
  columns: Set<string>;
  columnLocalStorageKey?: string;
  currency?: CurrencyEnum;
  data?: any[];
  defaultColumns?: string[];
  downloadCSV?: (() => Promise<void>) | (() => void);
  languageCode: string;
  loading?: boolean;
  meta?: { [key: string]: GenericTableColumns };
  metadata: GenericTableColumns[];
  onRowClick?: Function;
  onSortKeyPressed: (key: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  page?: number;
  pageLocalStorageKey?: string;
  pagination?: GenericTablePaginationType;
  rowsPerPage?: number;
  rowsPerPageLocalStorageKey?: string;
  rowsPerPageOptions?: number[];
  setColumns: (set: Set<string>) => void;
  setMetadata?: (n) => void;
  setPage?: (n: number) => void;
  setRowsPerPage?: (n: number) => void;
  showColumnSelector?: boolean;
  showTimeAgo?: boolean;
  showTitle?: boolean;
  subTitle?: string;
  title?: TranslationId;
  titlePadding?: 'small' | 'medium';
  tableLayoutProp?: 'auto' | 'fixed';
  maxCellWidthProp?: string;
};

type Props = InnerProps & OuterProps;

const GenericTable = (props: Props) => {
  const {
    AppId,
    columns,
    columnLocalStorageKey,
    currency,
    data,
    defaultColumns,
    downloadCSV,
    languageCode,
    loading,
    metadata,
    meta,
    onRowClick,
    onSortKeyPressed,
    order,
    orderBy,
    page,
    pageLocalStorageKey,
    pagination,
    rowsPerPage,
    rowsPerPageLocalStorageKey,
    rowsPerPageOptions,
    setColumns,
    setPage,
    setRowsPerPage,
    setMetadata,
    showColumnSelector,
    showTimeAgo = false,
    showTitle = true,
    subTitle,
    title,
    titlePadding,
    tableLayoutProp,
    maxCellWidthProp,
  } = props;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const cellRef = useRef<HTMLDivElement>(null);

  const tableLayout = useMemo<StyleProps['tableLayout']>(() => {
    if (tableLayoutProp) return tableLayoutProp;
    if (isMobile) {
      if (columns.size <= MAX_COLUMNS_MOBILE) {
        return 'fixed';
      } else {
        return 'auto';
      }
    } else {
      if (columns.size <= MAX_COLUMNS_DESKTOP) {
        return 'fixed';
      } else {
        return 'auto';
      }
    }
  }, [columns.size, isMobile]);

  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [scrollStarted, setScrollStarted] = useState<boolean>(false);

  const handleScroll = () => {
    if (!scrollStarted && !isMobile) {
      setIsScrolling(true);
      setScrollStarted(true);
    }
  };

  // We never want sticky on mobile - only on desktop
  const firstColumnStylePosition = isMobile ? 'normal' : 'sticky';

  const classes = useStyles({ tableLayout, isScrolling, maxCellWidthProp });

  const onCheckBoxTicked = (colName: string, checked: boolean) => {
    const newColumns = checked
      ? new Set(
          Array.from(columns)
            .concat([colName])
            .map((col) => meta![col])
            .sort((a, b) => a.ordinal - b.ordinal)
            .map((c) => c.columnName)
        )
      : new Set(
          Array.from(columns)
            .filter((c) => c !== colName)
            .map((col) => meta![col])
            .sort((a, b) => a.ordinal - b.ordinal)
            .map((c) => c.columnName)
        );
    setColumns(newColumns);
    if (columnLocalStorageKey) {
      localStorage.setItem(columnLocalStorageKey, JSON.stringify(Array.from(newColumns)));
    }
    if (setMetadata) {
      const newMetadata = metadata.map((col) => {
        return {
          ...col,
          isVisible: [...newColumns].indexOf(col.columnName) !== -1,
        };
      });
      setMetadata(newMetadata);
    }
  };

  const renderHeader = (titlePadding: Props['titlePadding']) => {
    return (
      showTitle && (
        <GenericTableTitle
          maxGridWidth={8}
          title={title}
          subTitle={subTitle}
          titlePadding={titlePadding}
        >
          {showColumnSelector && (
            <Grid item xs={downloadCSV ? 2 : 4} sm={downloadCSV ? 2 : 4} className={classes.item}>
              <ColumnSelector
                onChange={onCheckBoxTicked}
                selected={columns}
                metadata={metadata}
                isMobile={isMobile}
                defaultColumns={defaultColumns || []}
              />
            </Grid>
          )}
          {downloadCSV && (
            <Grid
              item
              xs={showColumnSelector ? 2 : 4}
              sm={showColumnSelector ? 2 : 4}
              className={classes.item}
            >
              <DownLoadCsvPdf onClick={downloadCSV} isMobile={isMobile} />
            </Grid>
          )}
        </GenericTableTitle>
      )
    );
  };
  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {Array.from(columns).map((prop, idx) => {
            const { columnName, translationId, isSortable, columnType } = meta![prop];
            const align = getAlignmentByColumnType(columnType);
            const colClass = clsx({
              [classes.cells]: true,
              [classes.sticky]: firstColumnStylePosition === 'sticky' && idx === 0,
              [classes.cursor]: isSortable,
            });
            return (
              <TableCell
                size={columnName === 'Product' ? 'medium' : 'small'}
                ref={cellRef}
                key={idx}
                align={align}
                classes={{ root: colClass }}
                sortDirection={orderBy === columnName ? order : false}
                onClick={() => onSortKeyPressed(prop)}
              >
                <div className={orderBy === columnName ? classes.selectedCol : classes.col}>
                  <span>
                    {isSortable && align === 'right' && (
                      <TableSortLabel
                        data-fd={`${prop}-sort-btn`}
                        IconComponent={order === 'asc' ? ArrowDropUp : ArrowDropDown}
                        active={orderBy === columnName}
                      />
                    )}
                    <Translate id={translationId ? translationId : (columnName as TranslationId)} />
                    {isSortable && align === 'left' && (
                      <TableSortLabel
                        data-fd={`${prop}-sort-btn`}
                        IconComponent={order === 'asc' ? ArrowDropUp : ArrowDropDown}
                        active={orderBy === columnName}
                      />
                    )}
                  </span>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <div>
      {renderHeader(titlePadding)}

      <Paper>
        <HighlightScrollbar
          classes={classes}
          handleScroll={handleScroll}
          setIsScrolling={setIsScrolling}
          setScrollStarted={setScrollStarted}
        >
          <Table className={classes.table} aria-label={title} data-fd={`${title}-table`}>
            {renderTableHeader()}
            <GenericTableBody
              type={firstColumnStylePosition}
              onRowClick={onRowClick}
              AppId={AppId}
              currency={currency}
              data={data}
              columns={columns}
              meta={meta}
              page={page}
              rowsPerPage={rowsPerPage}
              languageCode={languageCode}
              loading={loading}
              showTimeAgo={showTimeAgo}
              classes={classes}
            />
          </Table>
        </HighlightScrollbar>

        <HighlightScrollbar classes={classes}>
          {pagination && (
            <GenericTablePagination
              total={pagination.total}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              setPage={setPage}
              pageLocalStorageKey={pageLocalStorageKey}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
            />
          )}
        </HighlightScrollbar>
      </Paper>
    </div>
  );
};

export default GenericTable;
