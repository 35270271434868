import React from 'react';

import { TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { dateTimeUtils } from '../../selectors/localeDateTime.selector';

type Props = {
  date: string;
  isMobile?: boolean;
  translate: TranslateFunction;
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    dtUtils: dateTimeUtils(state),
  };
}

const LogDate = compose<MappedState, Props>(connect(mapStateToProps))(({
  date: dateString,
  translate,
  isMobile,
  dtUtils,
}: Props & MappedState) => {
  const date = dtUtils.utcToZonedTime(dateString, dtUtils.userIanaTimeZone);
  let formatted: React.ReactNode;

  if (isMobile) {
    switch (true) {
      case dtUtils.isToday(date):
        formatted = (
          <>
            <b>{translate('Today')}</b>
            <br />
            {`${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`}
          </>
        );
        break;
      case dtUtils.isYesterday(date):
        formatted = (
          <>
            <b>{translate('Yesterday')}</b>
            <br />
            {`${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`}
          </>
        );
        break;
      case dtUtils.isTomorrow(date):
        formatted = (
          <>
            <b>{translate('Tomorrow')}</b>
            <br />
            {`${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`}
          </>
        );
        break;
      default: {
        formatted = (
          <>
            <b>{dtUtils.format(date, dtUtils.LOCAL_DATE_FORMAT)}</b>
            <br />
            {`${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`}
          </>
        );
        break;
      }
    }
  } else {
    switch (true) {
      case dtUtils.isToday(date):
        formatted = `${translate('Today')} ${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`;
        break;
      case dtUtils.isYesterday(date):
        formatted = `${translate('Yesterday')} ${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`;
        break;
      case dtUtils.isTomorrow(date):
        formatted = `${translate('Tomorrow')} ${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`;
        break;
      default:
        formatted = `${dtUtils.format(date)}`;
        break;
    }
  }

  return <span>{formatted}</span>;
});

export { LogDate };
