import React from 'react';

import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  rowSkeletonStyles: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    display: 'flex',
  },
}));

const SummaryTableLoader = () => {
  const classes = useStyles();

  return (
    <div data-fd="payouts-skeleton-loader">
      <Box className={classes.rowSkeletonStyles} py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Skeleton width={'30%'} height={24} />
        </Box>
      </Box>
      <Box className={classes.rowSkeletonStyles} py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Skeleton width={'100%'} height={24} />
        </Box>
      </Box>

      <Box className={classes.rowSkeletonStyles} py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Skeleton width={'100%'} height={24} />
        </Box>
      </Box>

      <Box className={classes.rowSkeletonStyles} py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Skeleton width={'100%'} height={24} />
        </Box>
      </Box>
      <Box bgcolor={'#FAFAFA'} display="flex" py={2.24}>
        <Box flexGrow={1} mx={2}>
          <Skeleton width={'100%'} height={24} />
        </Box>
      </Box>
    </div>
  );
};

export default SummaryTableLoader;
