import React from 'react';

import { Order, OrderSummary } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { permissionsSelector } from '../../../selectors/permissions.selector';
import { flagService } from '../../../services/flagService';
import AdditionalDetails from '../components/AdditionalDetails';
import OrderDeliveryInfo from '../components/OrderDeliveryInfo';
import { AdditionalDetailsSkeleton } from '../components/OrderDetailsLoading';
import ActionBar from './ActionBar';
import { DepositReturnFeeSummary } from './DepositReturnFeeSummary';
import DetailsFraudWarnings from './DetailsFraudWarnings';
import GeneralInfo from './GeneralInfo';
import OrderItems from './OrderItems';
import OrderDetailsSummary from './Summary';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    maxWidth: 1200,
  },
  twoCols: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('md')]: { margin: '0' },
  },
  itemsGrid: {
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '24px 16px 32px 16px',
      paddingRight: 0,
    },
  },
  additionalDetailsGrid: {
    margin: '0',
  },
  dividerSpacing: {
    margin: theme.spacing(2, 0, 1),
    listStyleType: 'none',
    width: '100%',
  },
}));

type InnerProps = MappedState;
type OuterProps = { order: Order | OrderSummary };
type Props = InnerProps & OuterProps;
const OrderDetailsView = (props: Props) => {
  const classes = useStyles();
  const { appType, canViewCustomers, currentApp, languageCode, order, translate } = props;

  let currency = 'EUR';
  let storeIdOrTimeZone: string | number | undefined;

  const isOrderInstance = 'OrderItems' in order;

  if ('Store' in order) {
    if (order.Store) {
      if (order.Store.Currency && order.Store.Currency.toString()) {
        currency = order.Store.Currency.toString();
      }
      if (order.Store.Id) {
        storeIdOrTimeZone = order.Store.Id;
      }
    }
  } else if ('Currency' in order) {
    currency = String(order.Currency);

    if (order.StoreIanaTimeZone) {
      storeIdOrTimeZone = order.StoreIanaTimeZone;
    }
  }

  return (
    <>
      <Grid container spacing={0} className={classes.grid}>
        {order.InFraudZone || order.UnusualHighValueOrder ? (
          <DetailsFraudWarnings
            InFraudZone={order.InFraudZone}
            UnusualHighValueOrder={order.UnusualHighValueOrder}
          />
        ) : null}
        <GeneralInfo
          order={order}
          languageCode={languageCode}
          currency={currency}
          storeIdOrTimeZone={storeIdOrTimeZone}
        />

        <Divider component="li" style={{ listStyleType: 'none', width: '100%' }} />

        <Grid container className={classes.twoCols}>
          <Grid item xs={12} lg={8} className={classes.itemsGrid}>
            <OrderItems order={order} languageCode={languageCode} currency={currency} />

            {isOrderInstance && order.OrderItems && (
              <DepositReturnFeeSummary
                currency={currency}
                languageCode={languageCode}
                orderItems={order.OrderItems}
                translate={translate}
              />
            )}

            {order && (
              <OrderDetailsSummary order={order} languageCode={languageCode} currency={currency} />
            )}
          </Grid>

          <Grid item xs={12} lg={4} className={classes.additionalDetailsGrid}>
            {isOrderInstance ? (
              <AdditionalDetails
                appId={currentApp.AppId}
                customerId={order.Customer?.Id}
                showCustomerInfo={appType !== Order.AppTypeEnum.Kiosk}
                chefNote={order.ChefNote}
                deliveryNote={
                  (order.DeliveryLocation && order.DeliveryLocation.DeliveryInstructions) ||
                  undefined
                }
                name={order.Customer && order.Customer.Name ? order.Customer.Name : ''}
                email={
                  order.Customer && order.Customer.EmailAddress ? order.Customer.EmailAddress : ''
                }
                canViewCustomers={canViewCustomers}
              />
            ) : (
              <AdditionalDetailsSkeleton />
            )}

            {order.DeliveryType === Order.DeliveryTypeEnum.Delivery && (
              <>
                <Divider className={classes.dividerSpacing} />
                <OrderDeliveryInfo orderId={order.OrderId} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ActionBar order={order} />
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'ViewCustomerStatistics',
  ]);
  return (state: AppState) => {
    const { orders, locale } = state;

    return {
      appType: (orders.order as Order).AppType,
      canViewCustomers: getPermissionsSelector(state),
      currentApp: state.currentApp,
      languageCode: getActiveLanguage(state.locale),
      translate: getTranslate(locale),
    };
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(connect(mapStateToPropsFactory, null))(
  OrderDetailsView
);

export default EnhancedComponent;
