import React from 'react';

import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';

import { ComponentProps, getAutoFdKey } from '../Common';
import { FkField, TFkFieldProps } from '../FkField/FkField';

const useStyles = makeStyles({
  root: {
    height: 56,
    paddingTop: 8,
  },
});

// Fk - Formik. Warning!!! Don't extend from SwitchProps props - all UI parts must be inside component
// Don't add value field!!! work only over formik.
type Props = {
  onChange?: (val: boolean) => void;
} & ComponentProps;

export const FkSwitch = (props: Props) => {
  const classes = useStyles();
  const { fieldType, fieldName, fdKey, disabled } = props;

  return (
    <FkField componentProps={props} removeTopPadding className={classes.root}>
      {({ field, form }: TFkFieldProps) => {
        return (
          <Switch
            {...field}
            color="default"
            disabled={disabled}
            data-fd={fdKey || getAutoFdKey(fieldType, fieldName)}
            checked={field.value}
            onChange={(e, value: boolean) => {
              props.onChange && props.onChange(value);
              form.setFieldValue(field.name, value);
            }}
          />
        );
      }}
    </FkField>
  );
};

export default FkSwitch;
