import { OnboardingApiFactory } from 'overrides/@flipdish/api-client-typescript/api';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const onBoardingApi = createApi(OnboardingApiFactory);

export type GetOnboardingConfigs = {
  Data: Array<{
    OnboardingConfigId: number;
    IsEnabled: boolean;
    IsWelcomeScreenEnabled: boolean;
    WebActivationWizardMilestoneId?: number;
    ConfigType: 'Tutorial' | 'OnboardingWizard';
  }>;
};
export const getOnboardingConfigsQueryKey = 'getOnboardingConfigs';
export async function getOnboardingConfigs(appId: string) {
  try {
    const result: GetOnboardingConfigs = await onBoardingApi.onboardingGetOnboardingConfigs(appId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

type Status = 'Pending' | 'InProgress' | 'Completed';
type ItemType = 'Milestone' | 'Section' | 'Item';

export type GetOnboardingItems = {
  Data: {
    RootItems: Array<{
      OnboardingItemId: number;
      Title: string;
      Description: string;
      Status: Status;
      Type: ItemType;
      Items: Array<{
        OnboardingItemId: number;
        Title: string;
        Description: string;
        Status: Status;
        Type: ItemType;
        Items: Array<{
          OnboardingItemId: number;
          Title: string;
          Description: string;
          Status: Status;
          Type: ItemType;
        }>;
      }>;
    }>;
  };
};

export const getOnboardingItemsQueryKey = 'getOnboardingItems';
export async function getOnboardingItems(appId: string, milestoneId?: number) {
  try {
    const result: GetOnboardingItems = await onBoardingApi.onboardingGetOnboardingItemsV2(
      appId,
      undefined,
      milestoneId
    );
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const onboardingService = {
  getOnboardingItems,
  getOnboardingItemsQueryKey,
  getOnboardingConfigs,
  getOnboardingConfigsQueryKey,
};

export default onboardingService;
