import { useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import {
  type AssociationResponse,
  getAssociationsKey,
  getRMSTokenFromFDAuthorizationKey,
  RMSExchangeResponse,
} from '../../RMS/rms.services';

const useStoreToSalesChannelTransformer = () => {
  const appId = useSelector((state: AppState) => state.currentApp.AppId) ?? '';

  const queryClient = useQueryClient();
  const token = queryClient.getQueryData<RMSExchangeResponse>([
    getRMSTokenFromFDAuthorizationKey,
    appId,
  ]);
  const associations = queryClient.getQueryData<AssociationResponse>([
    getAssociationsKey,
    token?.data.token,
    appId,
  ]);

  return useMemo(() => {
    const associationSalesChannels: Array<{
      salesChannelName: string;
      salesChannelId: string;
      storeId: number;
    }> = [];

    associations?.data?.orgHierarchy?.properties?.forEach((property) => {
      property.storefronts.forEach((storefront) => {
        if (storefront.fieldAssociations?.flipdish?.storeId) {
          associationSalesChannels.push({
            salesChannelName: storefront.name,
            salesChannelId: storefront.code,
            storeId: storefront.fieldAssociations?.flipdish?.storeId,
          });
        }
      });
    });
    return associationSalesChannels;
  }, [associations]);
};

export default useStoreToSalesChannelTransformer;
