export const orderFulfillmentStatusConstants = {
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST: 'GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST',
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS: 'GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS',
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE: 'GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE',
  CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST:
    'CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST',
  CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS:
    'CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS',
  CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE:
    'CREATE_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE',
};
