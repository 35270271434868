import { Group, GroupReference, Metafield, Product } from '@flipdish/api-client-typescript';
import { get, isNumber } from 'lodash';
import { TranslateFunction } from 'react-localize-redux';

import { priceRegExp } from '../../helpers/validation';
import {
  CATALOG_ITEM_DESCRIPTION_MAX_LENGTH,
  CATALOG_ITEM_NAME_MAX_LENGTH,
  CATALOG_ITEM_SKU_MAX_LENGTH,
  IMAGE_URL_PREFIX,
} from './constants';
import { catalogGroupsService } from './services/catalogGroup.service';
import { catalogProductsService } from './services/catalogProducts.service';
import { CatalogItemFormErrorValues, ModifierFormErrorValues } from './types';

export const generateSku = (prefix: string): string => {
  let sku = prefix;
  const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
  for (let i = 0; i < 8; i++) {
    sku += allowedChars.charAt(Math.floor(Math.random() * allowedChars.length));
  }
  return sku;
};

export const getCatalogItemDetailsUrl = (appId: string, id: string): string => {
  return `/${appId}/products/${id}`;
};

export const getImageUrl = (imageName: string | undefined, size?: string): string => {
  if (!imageName) {
    return '';
  }
  const url = IMAGE_URL_PREFIX + imageName + `?auto=format`;
  if (size === 'large') {
    return url + '&ar=5:3&h=160';
  }
  if (size === 'xs') {
    return url + '&ar=1:1&h=40';
  }
  return url + '&ar=1:1&h=88';
};

export const generateSrcSet = (imageName: string | undefined, size?: string): string => {
  if (!imageName) {
    return '';
  }
  const src = getImageUrl(imageName, size);
  return `${src} 1x, ${src}&dpr=2 2x, ${src}&dpr=3 3x`;
};

export const getCatalogListQueryKeyByType = (
  type?: Group.GroupTypeEnum | Product.ProductTypeEnum
): string => {
  switch (type) {
    case Group.GroupTypeEnum.ModifierGroup:
      return catalogGroupsService.getModifierGroupsQueryKey;
    case Product.ProductTypeEnum.Modifier:
      return catalogProductsService.getModifiersQueryKey;
    default:
      return catalogProductsService.getProductsQueryKey;
  }
};

export const getProductByIdQueryKeyByType = (productType?: Product.ProductTypeEnum): string => {
  if (productType === Product.ProductTypeEnum.Modifier) {
    return catalogProductsService.getModifierByIdQueryKey;
  }
  return catalogProductsService.getProductByIdQueryKey;
};

export const getSelectionType = (min: number, max: number | null): string => {
  if (min === 0 && max === 1) {
    return 'one-optional';
  }
  if (min === 1 && max === 1) {
    return 'one-required';
  }
  if (min === 0 && (max === -1 || max === null)) {
    return 'any-optional';
  }
  if (min === 1 && (max === -1 || max === null)) {
    return 'any-required';
  }
  return 'custom';
};

export const setMetafield = (key: string, value: string, prevMetafields?: Metafield[]) => {
  const newMetafields = [...(prevMetafields || [])] as Metafield[];
  const metafieldIndex = newMetafields.findIndex((metafield) => metafield.Key === key);
  if (metafieldIndex !== -1 && value === '') {
    newMetafields.splice(metafieldIndex, 1);
  } else {
    const newMetafield = {
      Key: key,
      Value: value,
    };
    newMetafields.push(newMetafield);
  }
  return newMetafields;
};

export const validateCatalogItemForm = (
  values,
  translate: TranslateFunction
): Partial<CatalogItemFormErrorValues> => {
  const { Name, Price, Description, Sku } = values;
  const errors: Partial<CatalogItemFormErrorValues> = {};
  if (!Name) {
    errors.Name = translate('Required') as string;
  }
  if (Name.length > CATALOG_ITEM_NAME_MAX_LENGTH) {
    errors.Name = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: CATALOG_ITEM_NAME_MAX_LENGTH,
    }) as string;
  }
  if (Description && Description.length > CATALOG_ITEM_DESCRIPTION_MAX_LENGTH) {
    errors.Description = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: CATALOG_ITEM_DESCRIPTION_MAX_LENGTH,
    }) as string;
  }
  if (!Sku) {
    errors.Sku = translate('Required') as string;
  }
  if (Sku.length > CATALOG_ITEM_SKU_MAX_LENGTH) {
    errors.Sku = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: CATALOG_ITEM_SKU_MAX_LENGTH,
    }) as string;
  }
  if (!priceRegExp.test(String(Price)) || Price === '') {
    errors.Price = translate('Product_CreateProductDialog_PriceMustBeNumber') as string;
  }
  return errors;
};

export const validateModifierGroupForm = (
  values,
  translate: TranslateFunction
): Partial<ModifierFormErrorValues> => {
  const { Name, Sku } = values;
  const errors: Partial<ModifierFormErrorValues> = {};
  if (!Name) {
    errors.Name = translate('Required') as string;
  }
  if (Name.length > CATALOG_ITEM_NAME_MAX_LENGTH) {
    errors.Name = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: CATALOG_ITEM_NAME_MAX_LENGTH,
    }) as string;
  }
  if (!Sku) {
    errors.Sku = translate('Required') as string;
  }
  if (Sku.length > CATALOG_ITEM_SKU_MAX_LENGTH) {
    errors.Sku = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: CATALOG_ITEM_SKU_MAX_LENGTH,
    }) as string;
  }
  return errors;
};

/**
 * Return the group that sets the product price (part of implementation of legacy masterOptionSet).
 *
 * @param modiferGroups - Product list page passes GroupReference[], Edit Product page passes Group[]
 * @param groupPath - A optional array of object paths passed to lodash GET
 * @return group || {} - The group that sets the price, otherwise a empty object
 *
 * @example modifierGroups: Group[]
 *     getModifierGroupThatSetsPrice(modifierGroups, ['Group'])
 */
export const getModifierGroupThatSetsPrice = (
  modiferGroups: Group[] | GroupReference[],
  groupPath: string[] = []
): Group | {} => {
  const useGroupPath = groupPath.length;
  return (
    [...modiferGroups]?.find((group) => {
      const getGroupResult = useGroupPath ? get(group, groupPath) : group;
      return getGroupResult?.MinSelectCount === 1 && getGroupResult?.MaxSelectCount === 1;
    }) || {}
  );
};

/**
 * Return the lowest price contained in a modifier group
 *
 * @param modiferGroup - Group that sets the product price or {}
 * @param groupPath - A optional array of object paths passed to lodash GET
 * @return number || null - The lowest price in the modifier group or null
 *
 * @example modifierGroup: Group
 *
 *     getLowestModifierPrice(modifierGroupThatSetsPrice, ['Group'])
 */
export const getLowestModifierPrice = (
  modifierGroup: Group | GroupReference | {},
  groupPath: string[] = []
): number | null => {
  const useGroupPath = groupPath.length;
  if (!modifierGroup || Object.keys(modifierGroup).length === 0) return null;
  const getGroupResult = useGroupPath ? get(modifierGroup, groupPath) : modifierGroup;
  const price = getGroupResult?.Products?.reduce((acc, curr) => {
    const currPrice = curr?.Product?.Price;
    const nextProductPrice = isNumber(currPrice) ? currPrice : Number.MAX_VALUE;
    return acc > nextProductPrice ? nextProductPrice : acc;
  }, Number.MAX_VALUE);
  return price === undefined || price === Number.MAX_VALUE ? null : price;
};
