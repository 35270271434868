import React from 'react';

import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';

import { DNSRecord } from './reducer';

export const domainFormatCheck = (domain: string, hasSubdomain?: boolean) => {
  if (!domain) {
    return;
  }

  const domainHasWWW = domain.match(/^www/);
  const containsProtocol = domain.match(/^http:\/\/|^https:\/\//g);

  if (containsProtocol) {
    // remove http:// or https://
    domain = domain.replace(containsProtocol[0], '');
  }
  if (domainHasWWW && hasSubdomain) {
    // e.g. domain = www.my.site.com
    // return www.my
    return `www.${domain.split('.')[1]}`;
  }
  if (!domainHasWWW && hasSubdomain) {
    // eg orders.mytest.com
    // return orders
    return domain.split('.')[0];
  }
  return 'www';
};

export const domainNameFormat = (domain: string, hasSubdomain?: boolean) => {
  const containsProtocol = domain.match(/^http:\/\/|^https:\/\//g);

  if (containsProtocol) {
    // remove http:// or https://
    domain = domain.replace(containsProtocol[0], '');
  }
  if (!domain.match(/^www/) && !hasSubdomain) {
    // mysite.com
    return (domain = `www.${domain}`);
  }
  if (!domain.match(/^www/) && hasSubdomain) {
    // orders.mysite.com
    return domain;
  }
  // www.my.site.com
  return domain;
};

export const icon = (showError: boolean | undefined, isTouched: boolean, value: string) => {
  if (isTouched && showError) {
    return (
      <span>
        <CancelIcon color="error" />
      </span>
    );
  } else if (isTouched && !showError && value) {
    return (
      <span>
        <CheckIcon style={{ color: '#1dc798' }} />
      </span>
    );
  }
  return;
};

type localDNSRecords = {
  records: DNSRecord[];
  domainName: string;
  hasSubdomain?: boolean;
};

export const getLocalDNSRecords = ({ records, domainName, hasSubdomain }: localDNSRecords) => {
  let DNSRecords = records.map((item) => {
    if (domainName && item.Target === 'websites-api.flipdish.com') {
      return {
        ...item,
        Host: `${item.Host}.${domainFormatCheck(domainName, hasSubdomain)}`,
      };
    }
    return item;
  });

  const d = domainFormatCheck(domainName, hasSubdomain);
  // filter out 'A' record if it's a subdomain
  // non subdomains are always returned from the API with www
  if (!d?.startsWith('www')) {
    DNSRecords = records.filter((r) => r.Type !== 'A');
  }
  DNSRecords = DNSRecords.filter((r) => r.Target !== 'websites-api.flipdish.com').map((r) => {
    if (r.Host === 'www') {
      return {
        ...r,
        Host: d,
      };
    }
    if (r.Host === '@') {
      return {
        ...r,
        Host: d === 'www' ? r.Host : d?.split('.')[1],
      };
    }
    return r;
  });

  return DNSRecords;
};
