import React, { useEffect } from 'react';

import { KioskSettings } from '@flipdish/api-client-typescript/private/api';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Switch from '@fd/ui/Switch/Switch';

import { notify, notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { kioskServices } from '../Kiosks.services';
import KioskDetailSection from './KioskDetailSection';

type OuterProps = {
  appId: string;
  deviceId: string;
};
type Props = MappedDispatch & OuterProps;

const HideStoreLogoOption = ({ appId, deviceId, notifyError, notifySaved }: Props) => {
  const queryClient = useQueryClient();

  const handleChange = async (settings: KioskSettings) => {
    await mutation.mutateAsync(settings);
  };

  const {
    data: kioskSettingsData,
    isError,
    isPending,
  } = useQuery({
    queryKey: [kioskServices.getKioskSettingsV1QueryKey, appId, deviceId],
    queryFn: () => kioskServices.getKioskSettingsV1(appId, deviceId),
    enabled: !!appId && !!deviceId,
  });
  useEffect(() => {
    if (isError) {
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      });
    }
  }, [isError]);

  const mutation = useMutation({
    mutationFn: (settings: KioskSettings) =>
      kioskServices.setKioskSettingsV1({
        appId,
        deviceId,
        settings,
      }),

    onSuccess: (_data, settings) => {
      queryClient.setQueryData<KioskSettings>(
        [kioskServices.getKioskSettingsV1QueryKey, appId, deviceId],
        settings
      );
      notifySaved();
    },

    onError: (_error, data) => {
      queryClient.setQueryData<KioskSettings>(
        [kioskServices.getKioskSettingsV1QueryKey, appId, deviceId],
        { ...kioskSettingsData, HideLogoFromFrontPage: !data.HideLogoFromFrontPage }
      );
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      });
    },
  });

  const { HideLogoFromFrontPage, TwoColumnMenuLayout } = kioskSettingsData ?? {};

  return (
    <>
      <KioskDetailSection
        alignChildren="bottom"
        description="Hide_show_store_logo"
        isLoading={isPending}
        title="HideShowStoreLogo"
      >
        {isPending ? (
          <>
            <Grid item xs={6}>
              <Skeleton width={'35%'} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton width={40} height={20} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Translate
                  id={HideLogoFromFrontPage ? 'Store_logo_hidden' : 'Store_logo_visible'}
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                edge="end"
                checked={HideLogoFromFrontPage}
                onChange={(changeEvent) =>
                  handleChange({
                    ...kioskSettingsData,
                    HideLogoFromFrontPage: changeEvent.target.checked,
                  })
                }
                disabled={isPending || mutation.isPending}
                inputProps={{
                  'aria-labelledby': `switch-store-logo-toggle`,
                }}
                fdKey={`switch-store-logo-toggle`}
              />
            </Grid>
          </>
        )}
      </KioskDetailSection>

      <KioskDetailSection
        alignChildren="bottom"
        description="Kiosk_column_menu_layout_description"
        isLoading={isPending}
        title="Kiosk_column_menu_layout_title"
      >
        {isPending ? (
          <>
            <Grid item xs={6}>
              <Skeleton width={'35%'} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton width={40} height={20} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="body2">
                {TwoColumnMenuLayout ? 2 : 1} <Translate id="Kiosk_column_layout" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                edge="end"
                checked={TwoColumnMenuLayout}
                onChange={(changeEvent) =>
                  handleChange({
                    ...kioskSettingsData,
                    TwoColumnMenuLayout: changeEvent.target.checked,
                  })
                }
                disabled={isPending || mutation.isPending}
                inputProps={{
                  'aria-labelledby': `switch-menu-column-layout-toggle`,
                }}
                fdKey={`switch-menu-column-layout-toggle`}
              />
            </Grid>
          </>
        )}
      </KioskDetailSection>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaved: () => {
    dispatch(
      notify({
        message: 'Success',
        variant: 'success',
        translate: true,
      })
    );
  },
});

export default connect(null, mapDispatchToProps)(HideStoreLogoOption);
