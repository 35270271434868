import { enqueueNotification } from '../actions/context.actions';

export interface IState {
  notification: {
    show: boolean;
    message?: string;
    translateId?: TranslationId;
  };
}
const initialState: IState = {
  notification: {
    show: false,
    message: '',
  },
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'ENQUEUE_NOTIFICATION':
      return {
        ...state,
        notification: {
          show: true,
          ...(action as ReturnType<typeof enqueueNotification>).payload,
        },
      };

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notification: { ...initialState.notification },
      };
    default:
      return state;
  }
};
