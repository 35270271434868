import React from 'react';

import { AppConfigUpdateModel } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import FormItem, { RenderProps } from '../../components/FormItem';

const useStyles = makeStyles({
  select: {
    marginRight: 16,
    lineHeight: '1.1876em',
  },
});

type InnerProps = MappedProps;
type OuterProps = {};
type Props = InnerProps & OuterProps;
const StoreTypeField = ({ translate }: Props) => {
  const classes = useStyles();
  return (
    <FormItemLayout label={translate('Store_type')}>
      <FormItem name="type">
        {({ field, form }: RenderProps) => (
          <Select
            variant="standard"
            className={classes.select}
            data-fd="Store_type_dropdown"
            value={field.value}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
            }}
          >
            <MenuItem
              data-fd="radio_restaurant"
              value={AppConfigUpdateModel.ApplicationCategoryEnum.Restaurant}
            >
              {translate('Restaurant')}
            </MenuItem>
            <MenuItem
              data-fd="radio_cafe"
              value={AppConfigUpdateModel.ApplicationCategoryEnum.Cafe}
            >
              {translate('Cafe')}
            </MenuItem>
            <MenuItem
              data-fd="radio_convenience_store"
              value={AppConfigUpdateModel.ApplicationCategoryEnum.Convenience}
            >
              {translate('Convenience_store')}
            </MenuItem>
          </Select>
        )}
      </FormItem>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});
export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(StoreTypeField);
