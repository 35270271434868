import React from 'react';

import { WebhookSubscription } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import { Loader } from '../../common/Loader';

const styles = () => ({
  description: {
    marginBottom: '20px',
  },
  list: {
    maxHeight: '200px',
    overflow: 'auto',
  },
});

type Props = {
  eventNames: string[];
  data: WebhookSubscription;
  onClose: () => void;
  onCreate: (id: number, name: string) => void;
  onDelete: (id: number, name: string) => void;
  isDialogOpen: boolean;
  isLoading: boolean | undefined;
} & WithStyles<typeof styles>;

export default withStyles(styles)(function ({
  isDialogOpen,
  onClose,
  data,
  eventNames,
  classes,
  onCreate,
  isLoading,
  onDelete,
}: Props) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent key="dialog-content">
        <Typography variant="h6">
          <Translate id="Subscribed_events" />
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
          <Translate
            id="Subscribed_events_descriptions"
            data={{ url: data.CallbackUrl as string }}
          />
        </Typography>
        <div className={classes.list}>
          {isLoading && <Loader />}
          {eventNames.map((event) => {
            return (
              <div key={event}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(data.EventNames || []).includes(event)}
                      value={event}
                      onChange={(e, checked) => {
                        if (checked) {
                          onCreate(data.Id as number, event);
                        } else {
                          onDelete(data.Id as number, event);
                        }
                      }}
                      data-fd="event-name"
                      color="primary"
                    />
                  }
                  label={event}
                />
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions key="actions">
        <Button color="primary" data-fd="Delete_cancel" onClick={() => onClose()}>
          <Translate id="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});
