import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';

const styles = () =>
  createStyles({
    root: {
      minHeight: '76px',
      padding: '16px 0',
    },
  });

export interface IFormItemLayoutProps {
  label: React.ReactNode;
  description?: React.ReactNode;
  children: React.ReactNode;
}

type Props = IFormItemLayoutProps & WithStyles<typeof styles>;
const FormItemLayout = ({ label, description, children, classes }: Props) => (
  <Grid container alignItems="flex-start" className={classes.root}>
    <Grid item xs={12} md={4}>
      <Typography variant="subtitle1" component="h3" color="textPrimary">
        {label}
      </Typography>
      <div style={{ paddingRight: '14px' }}>
        {description && (
          <Typography variant="caption" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
    </Grid>
    <Grid item xs={12} md={8}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles, { name: 'FdFormItemLayout' })(FormItemLayout);
