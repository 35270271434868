import React from 'react';

import { Order, OrderSummary } from '@flipdish/api-client-typescript';
import { Translate } from 'react-localize-redux';

import { FormattedCurrency } from '../../../packages/react-intl/src/FormattedCurrency';

export const rejectedStates = [
  Order.OrderStateEnum.RejectedByFlipdish,
  Order.OrderStateEnum.RejectedByStore,
  Order.OrderStateEnum.RejectedAfterBeingAccepted,
  Order.OrderStateEnum.RejectedAutomatically,
];
export const isRejected = (state: Order.OrderStateEnum) => rejectedStates.some((r) => r === state);

export const renderPaymentStatus = (order: Order | OrderSummary, classes, dataFd: string) => {
  const orderState = order.OrderState;
  const paymentType = order.PaymentAccountType;
  const paymentStatus = order.PaymentStatus;

  if (orderState === undefined || paymentType === undefined) {
    return null;
  }

  if (isRejected(orderState)) {
    if (!order.RefundedAmount) {
      return null;
    }
    // rejected orders, payment status is "refunded"
    return (
      <span className={classes.textStyleRefunded} data-fd={dataFd}>
        <Translate id="refunded" />
      </span>
    );
  } else if (orderState === Order.OrderStateEnum.AcceptedAndRefunded) {
    // refunded orders
    const orderAmount = order.Amount ? order.Amount : 0;
    const refundedAmount = order.RefundedAmount ? order.RefundedAmount : 0;
    if (refundedAmount < orderAmount) {
      // when the refunded amount is less than total amount, payment status is "partially refunded"
      return (
        <span className={classes.textStyleRefunded} data-fd={dataFd}>
          <Translate id="PartiallyRefunded" />
        </span>
      );
    } else {
      // when the refunded amount is equal total amount, payment status is "refunded"
      return (
        <span className={classes.textStyleRefunded} data-fd={dataFd}>
          <Translate id="refunded" />
        </span>
      );
    }
  } else if (
    paymentType === Order.PaymentAccountTypeEnum.Cash ||
    paymentStatus === Order.PaymentStatusEnum.Unpaid
  ) {
    // cash, payment status is "unpaid"
    return (
      <span className={classes.textStyleUnPaid} data-fd={dataFd}>
        <Translate id="Unpaid" />
      </span>
    );
  } else {
    // when the order is not rejected or refunded and it's not a cash order,
    // payment status is "paid"
    return (
      <span className={classes.textStylePaid} data-fd={dataFd}>
        <Translate id="Paid" />
      </span>
    );
  }
};

export const renderTotalAmount = ({
  amount,
  refundedAmount,
  languageCode,
  currency,
}: {
  amount: Order['Amount'];
  refundedAmount: Order['RefundedAmount'];
  languageCode: string;
  currency: string;
}) => {
  if (amount !== undefined && amount >= 0) {
    const totalAmount = refundedAmount ? amount - refundedAmount : amount;
    return <FormattedCurrency value={totalAmount} locales={languageCode} currency={currency} />;
  }
  return null;
};

export const displayCustomerNameForOrder = (name: string, number: number) => {
  return name.slice(0, number) + '...';
};
