import React from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const EnhancedLocalizationProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} {...props}>
      {children}
    </LocalizationProvider>
  );
};

export default EnhancedLocalizationProvider;
