import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { storeArchive } from '../../../actions/store.actions';
import LoadingButton from '../../../ui/LoadingButton';
import { type CardData } from './DangerZoneList';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '228px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
}));

type InnerProps = MappedDispatch;
type OuterProps = {
  card: CardData;
  index: number;
  storeDetails: {
    appId: string | undefined;
    storeGroupId: number;
    storeName: string;
    storeId: number;
  };
};
type Props = InnerProps & OuterProps;

const ArchiveStoreButton = (props: Props) => {
  const classes = useStyles();
  const {
    archiveStore,
    card,
    index,
    storeDetails: { appId, storeGroupId, storeId, storeName },
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [textField, setTextField] = useState<string>();
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const openDialog = () => {
    !isArchived && setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (textField === storeName) {
        setSubmitting(true);
        await archiveStore(storeId);
        setIsArchived(true);
      } else {
        setValidationError(true);
      }
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  if (isArchived) {
    return <Redirect to={`/${appId}/storegroups/${storeGroupId}/stores`} />;
  }

  return (
    <>
      <Button
        data-fd={`${card.buttonText}_btn`}
        variant="outlined"
        className={classes.button}
        key={index}
        onClick={openDialog}
        disabled={isArchived || isSubmitting}
      >
        <Translate id={card.buttonText} />
      </Button>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="Archive_Store">
        <DialogTitle id="Archive_Store">
          <Translate id="Archive_Store?" />
        </DialogTitle>

        <DialogContent style={{ maxWidth: '280px' }}>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              <Translate id="If_you_are_sure_you_want_to_archive" />{' '}
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{storeName}</span>
              {', '}
              <Translate id="please_type_in_the_name_of_the_store_to_confirm" />
            </Typography>
          </DialogContentText>

          <TextField
            variant="standard"
            inputProps={{ 'data-fd': 'archive-store-textfield' }}
            autoFocus
            disabled={isSubmitting}
            required
            placeholder={storeName}
            error={validationError}
            margin="none"
            style={{ width: '100%' }}
            onChange={(e) => setTextField(e.target.value)}
            helperText={validationError ? <Translate id="Store_name_does_not_match" /> : ''}
          />
        </DialogContent>
        <DialogActions className={classes.addPadding}>
          <Button
            data-fd={`cancel-archive-store`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-archive-store`}
            onClick={handleSubmit}
            color="secondary"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Translate id="Archive" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  archiveStore: (storeId: number) => dispatch(storeArchive(storeId)),
});

export default compose<InnerProps, OuterProps>(connect(null, mapDispatchToProps))(
  ArchiveStoreButton
);
