import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import CodeView from '../../../ui/CodeView';
import { getDNSRecordStatus } from '../selectors';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  content: {
    '& > *': {
      marginBottom: spacing(2),
    },
    '& a': {
      textDecoration: 'none',
    },
  },
}));

type InnerProps = MappedState;
type OuterProps = {
  domain: string;
};
type Props = InnerProps & OuterProps;

const WebEmbedCode = ({ translate, currentApp }: Props) => {
  const classes = useStyles();

  const webOrderUrl = window.location.host.includes('portal.flipdishdev.com')
    ? 'https://web-order.flipdishdev.com/production/aa-a/latest/main.js'
    : 'https://web-order.flipdish.co/client/productionwlbuild/latest/static/js/main.js';

  const codeData = `<div  id="flipdish-menu" data-restaurant="${currentApp.AppId}"></div><script id="flipdish-script" type="text/javascript" charset="UTF-8" src="${webOrderUrl}"></script>`;

  return (
    <div className={classes.content}>
      <CodeView
        inputId="web-embed-0001"
        noParse
        copyToClipboard
        data={codeData}
        name={translate('Code') as string}
      />
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  translate: getTranslate(state.locale),
  dnsRecordStatus: getDNSRecordStatus(state),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(WebEmbedCode);
