import Cookies from 'js-cookie';

const useGaDebug = !!JSON.parse(process.env.VITE_GA_DEBUG || '0');

const GA_ID =
  process.env.NODE_ENV === 'development' || window.location.host.includes('portal.flipdishdev.com')
    ? process.env.VITE_GOOGLE_ANALYTICS_DEV_ID
    : process.env.VITE_GOOGLE_ANALYTICS_ID;

if (useGaDebug) {
  window['ga_debug'] = { trace: true };
}

let _disabled_ = false;
let _initialized_: boolean;

const initialize = (disable = false) => {
  _disabled_ = disable;
  if (document.cookie.indexOf('_ga=') === -1 && disable) {
    return;
  }

  // Remove cookies
  const removeCookies = (cookieName: string) => {
    const domain = document.domain;
    Cookies.remove(cookieName, { path: '/', domain });
  };

  if (disable) {
    if (window.gtag) {
      window.gtag('config', GA_ID, { send_page_view: false });
      window.gtag('event', 'remove', { send_to: GA_ID });
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        wait_for_update: 500,
      });
      window['ga-disable-' + GA_ID] = true;
    }
    removeCookies('_ga');
    removeCookies('_gid');
    removeCookies('_gat');

    _initialized_ = false;

    return;
  }

  if (!GA_ID) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[GA] to enable google.analytics in dev time add 'VITE_GOOGLE_ANALYTICS_ID='{dev_token_here}'' to '.env.development.local' file`
      );
    }
    return;
  }

  if (_initialized_) {
    return;
  }
  _initialized_ = true;
  window['ga-disable-' + GA_ID] = false;
  // Initialize GA4 script
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
  document.head.appendChild(script);

  const eventScript = document.createElement('script');
  eventScript.innerHTML = `window.dataLayer = window.dataLayer || [];
 function gtag(){dataLayer.push(arguments);}
 gtag('js', new Date());
 gtag('config', '${GA_ID.toString()}');`;

  document.head.appendChild(eventScript);
};

const sendPageView = (pageName: string) => {
  if (window.gtag && !_disabled_) {
    window.gtag('event', 'page_view', {
      page_title: pageName,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
};

const sendSuccessfulSignUp = () => {
  if (window.gtag && !_disabled_) {
    window.gtag('event', 'conversion', {
      send_to: GA_ID,
      event_category: 'Success',
      event_action: 'Sign Up',
      event_label: 'Portal Self Serve New User',
    });
  }
};

export const googleAnalytics = {
  initialize,
  sendPageView,
  sendSuccessfulSignUp,
};
