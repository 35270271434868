import { isEmpty } from 'lodash';

export const CAAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'CA') {
    return {
      address: '',
      apartmentNumber: '',
      city: '',
      province: '',
      postalCode: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      address: storeAddress?.Line1 || '',
      apartmentNumber: '',
      city: storeAddress?.City || '',
      province: '',
      postalCode: storeAddress?.Postcode || '',
    };
  }
  return storeAddress.AddressFields;
};
