import React from 'react';

import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
//@ts-ignore
export interface ButtonProps extends Omit<MuiButtonProps, 'variant'> {
  autoFocus?: boolean;
  className?: string;
  component?: string;
  children: React.ReactNode;
  disabled?: boolean;
  form?: string;
  fullWidth?: boolean;
  lowercase?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  onKeyPress?: (e: any) => void;
  fdKey: string;
  variant?: 'primary' | 'secondary' | 'text' | 'rounded';
  target?: '_blank';
}
const useStyles = makeStyles((theme: Theme) => ({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
  lowerCase: {
    fontWeight: 'normal',
    textTransform: 'none',
  },
  rounded: {
    borderRadius: '100px',
  },
  secondary: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'white',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  '&:focus': {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
}));

export const Button = (props: ButtonProps): JSX.Element => {
  const {
    autoFocus,
    children,
    className,
    component = 'button',
    disabled = false,
    fdKey,
    form,
    fullWidth = false,
    href,
    lowercase = false,
    onClick,
    onKeyPress = null,
    type = 'button',
    variant = 'primary',
    target,
  } = props;

  const getMuiVariant = (fdVariant) => {
    switch (fdVariant) {
      case 'secondary':
        return 'outlined';
      case 'text':
        return 'text';
      case 'primary':
      default:
        return 'contained';
    }
  };

  const classes = useStyles();
  const buttonStyles = clsx(
    variant === 'secondary' && classes.secondary,
    variant === 'rounded' && classes.rounded,
    lowercase && classes.lowerCase,
    className
  );

  const color = variant === 'rounded' ? 'secondary' : 'primary';
  return (
    <MuiButton
      autoFocus={autoFocus}
      className={buttonStyles}
      color={color}
      component={component}
      data-fd={fdKey}
      disabled={disabled}
      form={form}
      fullWidth={fullWidth}
      href={href}
      target={target}
      onClick={onClick}
      // @ts-ignore
      onKeyPress={onKeyPress}
      type={type}
      variant={getMuiVariant(variant)}
    >
      <div className={classes.buttonContent}>{children}</div>
    </MuiButton>
  );
};
