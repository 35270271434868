import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import ChecklistHeaderBackground from '../../../../assets/images/checklist-header-bg.svg';
import CompleteMilestone from '../../../../assets/images/complete-milestone.svg';
import IncompleteMilestone from '../../../../assets/images/incomplete-milestone.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(2),
    color: '#FFFFFF',
    backgroundImage: 'url("' + ChecklistHeaderBackground + '")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  detail: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
  },
  tutorialText: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  storeName: {
    fontWeight: 600,
    marginTop: 0,
    marginBottom: theme.spacing(1.5),
  },
  dots: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completeSection: {
    display: 'inline-block',
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: '#05149E',
  },
  incompleteSection: {
    display: 'inline-block',
    height: 16,
    width: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  description: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    textAlign: 'center',
  },
}));

const Progress = ({ tutorialData, translate, storeData }) => {
  const classes = useStyles();
  let remainingTasks;
  let remainingTasksMilestoneIndex;

  const completedSteps = {};
  tutorialData?.Items?.forEach((milestoneData, index) => {
    completedSteps[milestoneData.Title] = 0;
    milestoneData.Items.forEach((section) => {
      if (!section.Items.find((item) => item.Status !== 'Completed')) {
        completedSteps[milestoneData.Title] += 1;
      }
    });

    if (completedSteps[milestoneData.Title] < milestoneData.Items.length && !remainingTasks) {
      remainingTasks = milestoneData.Items.length - completedSteps[milestoneData.Title];
      remainingTasksMilestoneIndex = index;
    }
  });

  const MilestoneProgress = ({ milestoneData }) => (
    <>
      {milestoneData.Items.map((section, i) => {
        const completedStepsCount = completedSteps[milestoneData.Title];
        if (i === milestoneData.Items.length - 1) {
          if (i === completedStepsCount - 1) {
            return <img key={i} src={CompleteMilestone} data-fd="milestone-complete-icon" />;
          } else {
            return <img key={i} src={IncompleteMilestone} data-fd="milestone-incomplete-icon" />;
          }
        } else if (i < completedStepsCount) {
          return (
            <span key={i} className={classes.completeSection} data-fd="section-complete-icon" />
          );
        } else {
          return (
            <span key={i} className={classes.incompleteSection} data-fd="section-incomplete-icon" />
          );
        }
      })}
    </>
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>
          <Translate id="Checklist" />
        </h3>
        <p className={classes.subtitle}>
          <Translate id="This_setup_will_guide_you_through_the_steps_needed_to_start_taking_orders_with_Flipdish" />
        </p>
        <p className={classes.tutorialText}>
          <Translate id="Tutorial_only_applies_to_store" />
        </p>
        <p className={classes.storeName}>{storeData.Name}</p>
      </div>
      <div className={classes.detail}>
        <div className={classes.dots}>
          {tutorialData?.Items?.map((milestoneData) => (
            <MilestoneProgress milestoneData={milestoneData} key={milestoneData.Title} />
          ))}
        </div>
        <div className={classes.description}>
          {remainingTasks === 1 && (
            <Translate
              id="1_task_remains_to_complete_milestone"
              data={{
                count: remainingTasks,
                milestone: translate(`${remainingTasksMilestoneIndex + 1}_milestone`),
              }}
            />
          )}
          {remainingTasks > 1 && (
            <Translate
              id="n_tasks_remain_to_complete_milestone"
              data={{
                count: remainingTasks,
                milestone: translate(`${remainingTasksMilestoneIndex + 1}_milestone`),
              }}
            />
          )}
          {!remainingTasks && <Translate id="Milestone_complete" />}
        </div>
      </div>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps)(Progress);
