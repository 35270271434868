import { Order, OrderSummary, Store } from '@flipdish/api-client-typescript';

import { ordersConstants } from '../../constants/order.constants';
import {
  ACCEPTED_ORDERS_REQUEST,
  ACCEPTED_ORDERS_SUCCESS,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  OrderStatesType,
  PENDING_ORDERS_REQUEST,
  PENDING_ORDERS_SUCCESS,
  SET_CHANNEL_FILTER,
  SET_ORDERS_PAGE,
  SET_PENGING_STORES_FILTER,
  SET_STATE_FILTER,
  SET_STORE_FILTER,
} from './actions';

const { REJECT_ORDER_SUCCESS, ACCEPT_ORDER_SUCCESS, SET_ORDER_SEARCH_CODE_RESULTS } =
  ordersConstants;

type DefaultState = {
  stores: number[];
  states: OrderStatesType;
  page: number;
  pendingStores: [];
  orderSearchCodeResults: number;
  channels: string[];
};

const defaultState: DefaultState = {
  stores: [],
  states: [],
  page: 0,
  pendingStores: [],
  orderSearchCodeResults: 0,
  channels: [],
};

export function OrdersFilterReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case SET_STORE_FILTER:
    case SET_CHANNEL_FILTER:
    case SET_STATE_FILTER:
    case SET_ORDERS_PAGE:
    case SET_PENGING_STORES_FILTER:
      return { ...state, ...action.payload };
    case SET_ORDER_SEARCH_CODE_RESULTS:
      return {
        ...state,
        orderSearchCodeResults: action.payload,
      };
    default:
      return state;
  }
}

type DefaultPendingOrdersState = {
  orders: OrderSummary[];
  ordersCount: number;
  getPendingOrdersLoading: boolean;
};

const defaultPendingOrdersState: DefaultPendingOrdersState = {
  orders: [],
  ordersCount: 0,
  getPendingOrdersLoading: false,
};

export function PendingOrdersReducer(
  state = defaultPendingOrdersState,
  action
): DefaultPendingOrdersState {
  switch (action.type) {
    case PENDING_ORDERS_REQUEST: {
      return { ...state, getPendingOrdersLoading: true };
    }
    case PENDING_ORDERS_SUCCESS: {
      const { orders, ordersCount, concat } = action.payload;
      return {
        ...state,
        ordersCount,
        orders: concat ? [...state.orders, ...orders] : orders,
        getPendingOrdersLoading: false,
      };
    }
    case ACCEPT_ORDER_SUCCESS:
    case REJECT_ORDER_SUCCESS:
      return {
        ...state,
        ordersCount: state.ordersCount - 1,
        orders: state.orders.filter((order) => order.OrderId !== action.payload.orderId),
      };
    default:
      return state;
  }
}

type DefaultAcceptedOrdersState = {
  orders: Order[];
  ordersCount: number;
  getAcceptedOrdersLoading: boolean;
};

const defaultAcceptedOrdersState: DefaultAcceptedOrdersState = {
  orders: [],
  ordersCount: 0,
  getAcceptedOrdersLoading: false,
};

export function AcceptedOrdersReducer(
  state = defaultAcceptedOrdersState,
  action
): DefaultAcceptedOrdersState {
  switch (action.type) {
    case ACCEPTED_ORDERS_REQUEST: {
      return { ...state, getAcceptedOrdersLoading: true };
    }
    case ACCEPTED_ORDERS_SUCCESS: {
      const { orders, ordersCount, concat } = action.payload;
      return {
        ...state,
        ordersCount,
        orders: concat ? [...state.orders, ...orders] : orders,
        getAcceptedOrdersLoading: false,
      };
    }
    default:
      return state;
  }
}

type DefaultStoresState = {
  stores: Store[];
  getStoresLoading: boolean;
};

const defaultStoresState: DefaultStoresState = {
  stores: [],
  getStoresLoading: false,
};

export function StoresReducer(state = defaultStoresState, action): DefaultStoresState {
  switch (action.type) {
    case GET_STORES_REQUEST:
      return { ...state, getStoresLoading: true };
    case GET_STORES_SUCCESS: {
      const { stores } = action.payload;
      return {
        stores,
        getStoresLoading: false,
      };
    }

    default:
      return state;
  }
}
