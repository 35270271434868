import React from 'react';

import { LocationAreaLocation } from '@flipdish/api-client-typescript';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import rearrange from '../../../../assets/images/Rearrange.svg';

const useStyles = makeStyles((theme: Theme) => ({
  dragIcon: {
    marginRight: theme.spacing(2),
  },
  listCell: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

type LocationListItemProps = {
  location: LocationAreaLocation;
  canEdit: boolean;
  onClickDelete: (location: LocationAreaLocation) => void;
  onClickEdit: (location: LocationAreaLocation) => void;
  isDraggable?: boolean;
  innerRef?: (element?: HTMLElement | null | undefined) => any;
};

const LocationListItem = (props: LocationListItemProps) => {
  const {
    location,
    canEdit,
    onClickDelete,
    onClickEdit,
    isDraggable = false,
    innerRef,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <ListItem className={classes.listCell} ref={innerRef} {...rest}>
      {isDraggable && <img data-fd="drag-icon" src={rearrange} className={classes.dragIcon} />}
      <ListItemText primary={location.LocationName} />
      <IconButton onClick={() => onClickEdit(location)} disabled={!canEdit}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onClickDelete(location)} disabled={!canEdit}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

export default LocationListItem;
