import { History } from 'history';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { FilterValue, generateFilter } from '../components/common/Filter';
import { usePrevious } from './usePrevious';
import useQueryParams from './useQueryParams';
import useURLState from './useURLState';

const parseFilters = (
  params: { [key: string]: string },
  withTime = true,
  multipleFilters: string[] = []
): FilterValue[] => {
  if (params.filter) {
    const keys = Object.keys(params.filter);

    return keys.reduce<FilterValue[]>((res, key) => {
      const value = params.filter[key];
      const isMultipleValue = Array.isArray(value);
      const filters =
        isMultipleValue && multipleFilters.includes(key)
          ? value.map((item) => generateFilter(key, item, withTime))
          : [generateFilter(key, isMultipleValue ? value[0] : value, withTime)];

      return res.concat(filters);
    }, []);
  }

  return [];
};

const useBasePagination = (history: History) => {
  const params = useQueryParams(history.location.search);
  const previousParams = usePrevious(params);
  const paramsPage = Number(params.page);
  const previousParamsPage =
    previousParams && previousParams.page ? Number(previousParams.page) : null;
  const [page, setPage] = useState(paramsPage - 1 || 0);
  const [rowsPerPage, setRowsPerPage] = useState(Number(params.limit) || 25);

  useEffect(() => {
    if (previousParamsPage && paramsPage && paramsPage !== previousParamsPage) {
      const currentPage = page + 1;

      if (paramsPage !== currentPage) {
        setPage(paramsPage - 1);
      }
    }
  }, [paramsPage, previousParamsPage, page]);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page);
    },
    []
  );
  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target) {
        setPage(0);
        setRowsPerPage(Number(event.target.value));
      }
    },
    []
  );

  return {
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    params,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export const usePagination = (history: History) => {
  const result = useBasePagination(history);

  useURLState(history, result.page, result.rowsPerPage);

  return result;
};

export default function useFilterablePagination(
  history: History,
  withTime?: boolean,
  multipleFilters?: string[]
) {
  const {
    params,
    setPage,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
  } = useBasePagination(history);
  const [filters, setFilters] = useState<FilterValue[]>(
    parseFilters(params, withTime, multipleFilters)
  );

  const handleFilter = useCallback((filters: FilterValue[]) => {
    setFilters(filters);
    setPage(0);
  }, []);

  useURLState(history, page, rowsPerPage, filters);

  useEffect(() => {
    setFilters(parseFilters(params, withTime, multipleFilters));
  }, [params]);

  return {
    rowsPerPage,
    filters,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    params,
  };
}
