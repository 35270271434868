import { App } from '@flipdish/api-client-typescript';
import without from 'lodash/without';
import { add as addRPermissions, clear as clearPermissions } from 'react-redux-permissions';
import { createAction } from 'redux-act';

import { appsConstants } from '../constants/apps.constants';
import { generalConstants } from '../constants/general.constants';
import { history } from '../routes/history';
import { appsService } from '../services/apps.service';
import { action, actionError } from './utils';

export const appsActions = {
  selectedAppChanged,
  getApps: getAppsLegacy,
  getAppsNew,
  processAppsQueue,
  setPageNumber,
  setSearchQuery,
  getAppsWithQuery,
  setCurrentApp,
  toggleAppMenu,
  setPanaceaVanityUrl,
  setPanaceaVanityUrlSuccess,
  getPanaceaVanityUrlSuccess,
  getPanaceaVanityUrlFailure,
  setPanaceaVanityUrlFailure,
};

const setCurrentAppIdInStorage = (appId: string) => {
  localStorage.setItem('fd-currentAppId', appId);
};

function selectedAppChanged(app: App) {
  return (dispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId;
    if (appId === app.AppId) {
      console.log(`AppID '${appId}' already selected!`);
      return;
    }

    if (app.AppId) {
      setCurrentAppIdInStorage(app.AppId);
    }
    const features = [
      ...app.Features!,
      // 'disable-campaign',
    ];
    dispatch({
      type: appsConstants.SELECTED_APP_CHANGED,
      selectedApp: { ...app, Features: features },
    });

    const email = getState().account.Email;
    const isFlipdish = /@flipdish\.[ie|com]/i.test(email as string);
    const showHiddenFeatures = getState().account.ShowHiddenFeatures;

    let permissions: AppState['permissions'] = [...app.AppResourceSet!, app.AppAccessLevel!] as any;
    if (isFlipdish) {
      permissions.push('FlipdishStaff');
    }
    if (showHiddenFeatures) {
      permissions.push('ShowHiddenFeatures');
    }

    /** TEMPORARY quick unhide Customer Table Download CSV in prod (quicker for me coz don't have time to research split sht) */
    if (features.some((f) => f === 'portal-temporary-customer-table-header-unhide')) {
      permissions.push('TemporaryCustomerTableTitleShowHiddenFeature');
    }

    if (app.AppId === 'flipdish-global') {
      permissions = without(
        permissions,
        'CreateStoreGroups',
        'EditVouchers',
        'EditTeammates',
        'ViewTeammates',
        'ViewBankAccounts',
        'UpdateBankAccounts',
        'CreateBankAccounts',
        'SendPushNotificationToCustomer'
      );
    }

    dispatch(clearPermissions());
    dispatch(addRPermissions(permissions));
  };
}

// #region getApps
const { GET_APPS, GET_APPS_SUCCESS, GET_APPS_FAILURE } = appsConstants;

export const getAppsRequest = (query?: string, page?: number, limit?: number) =>
  action(GET_APPS, { query, page, limit });

export const getAppsSuccess = (
  apps: App[],
  totalCount: number,
  query?: string,
  page?: number,
  limit?: number
) => action(GET_APPS_SUCCESS, { apps, totalCount }, { query, page, limit });

export const getAppsFailure = (error: Error, query?: string, page?: number, limit?: number) =>
  actionError(GET_APPS_FAILURE, { query, page, limit }, error);

export const getApps =
  (query?: string | undefined, page?: number, limit?: number): ThunkAction =>
  (dispatch, getState) => {
    dispatch(getAppsRequest(query, page, limit));
    return appsService
      .getApps(query, page, limit)
      .then((result) => {
        dispatch(getAppsSuccess(result.Data, result.TotalRecordCount, query, page, limit));
      })
      .catch((error) => {
        dispatch(getAppsFailure(error, query, page, limit));
      });
  };
// #endregion
function getAppsLegacy(
  currentAppId?: string | undefined,
  concat?: boolean,
  query?: string | undefined,
  page?: number,
  limit?: number
) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(setTimestamp(timestamp));

    if (getState().apps.loading) {
      dispatch(addToAppsQueue(currentAppId, concat, query, page, limit));
      return null;
    }

    if (!concat) {
      dispatch(clearAndRequest());
    } else {
      dispatch(request());
    }
    return appsService.getApps(query, page, limit).then(
      (response) => {
        if (getState().apps.appsTimestamp === timestamp) {
          dispatch(setTimestamp(null));
        }

        if (concat) {
          dispatch(concatSuccess(response));
        } else {
          dispatch(success(response));
        }

        // The total number of results should be stored only with the empty query
        if (!query) {
          dispatch(totalAppsSuccess(response));
        }
      },
      (error) => {
        console.log(error);

        if (getState().apps.appsTimestamp === timestamp) {
          dispatch(setTimestamp(null));
        }

        dispatch(failure());
      }
    );
  };

  function addToAppsQueue(
    currentAppId?: string | undefined,
    concat?: boolean,
    query?: string | undefined,
    page?: number,
    limit?: number
  ) {
    const app = {
      currentAppId: undefined,
      concat: false,
      query: undefined,
      page: 1,
      limit: generalConstants.APP_LIMIT,
    };

    if (currentAppId) {
      (app as any).currentAppId = currentAppId;
    }

    if (concat) {
      app.concat = concat;
    }

    if (query) {
      (app as any).query = query;
    }

    if (page) {
      app.page = page;
    }

    if (limit) {
      app.limit = limit;
    }

    return {
      type: appsConstants.ADD_TO_APPS_QUEUE,
      payload: app,
    };
  }

  function setTimestamp(timestamp) {
    return {
      type: appsConstants.SET_APPS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function clearAndRequest() {
    return {
      type: appsConstants.CLEAR_GET_APPS,
    };
  }

  function request() {
    return {
      type: appsConstants.GET_APPS,
    };
  }

  function failure() {
    return {
      type: appsConstants.GET_APPS_FAILURE,
    };
  }

  function success(response) {
    return {
      type: appsConstants.GET_APPS_SUCCESS,
      payload: response,
    };
  }

  function concatSuccess(response) {
    return {
      type: appsConstants.GET_APPS_SUCCESS_CONCAT,
      payload: response,
    };
  }

  function totalAppsSuccess(response) {
    return {
      type: appsConstants.GET_TOTAL_APPS_COUNT,
      payload: response.TotalRecordCount,
    };
  }
}

function processAppsQueue() {
  return (dispatch, getState) => {
    const appsQueue = getState().apps.appsQueue;

    if (appsQueue && appsQueue.length > 0) {
      const appQuery = appsQueue.shift();
      dispatch(
        getAppsLegacy(
          appQuery.currentAppId,
          appQuery.concat,
          appQuery.query,
          appQuery.page,
          appQuery.limit
        )
      );
      dispatch(setAppsQueue(appsQueue));
    }
  };

  function setAppsQueue(appsQueue) {
    return {
      type: appsConstants.SET_APPS_QUEUE,
      payload: appsQueue,
    };
  }
}

function getAppsNew(page: number, limit: number, query: string | undefined) {
  return (dispatch: ThunkDispatch) => {
    dispatch({ type: appsConstants.GET_APPS });
    const searchQuery = query === '' ? undefined : query;
    appsService.getApps(searchQuery, page, limit).then((response) => {
      dispatch({
        type: appsConstants.GET_APPS_SUCCESS_CONCAT,
        payload: response,
      });
    });
  };
}

const getAppsSucess = createAction<any>(appsConstants.GET_APPS_SUCCESS);

function getAppsWithQuery(query: string) {
  return (dispatch: ThunkDispatch) => {
    dispatch({ type: appsConstants.GET_APPS });
    dispatch(setSearchQuery(query));
    dispatch(appsActions.setPageNumber(1));
    const searchQuery = query === '' ? undefined : query;
    appsService.getApps(searchQuery, 1, 20).then((response) => {
      dispatch(getAppsSucess(response));
    });
  };
}

function setPageNumber(page: number) {
  return action(appsConstants.SET_PAGE_NUMBER, page);
}

function setSearchQuery(query: string) {
  return action(appsConstants.SET_SEARCH_QUERY, query);
}

function setCurrentApp(appId: string | undefined) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const response = await appsService.getApps(appId);
      if (response.Data.length) {
        const apps = response;
        if (appId) {
          dispatch(setSearchQuery(appId));
        }
        dispatch(getAppsSucess(apps));
        const currentApp = apps.Data.find((a) => a.AppId === appId) || apps.Data[0];
        if (currentApp.AppId) {
          setCurrentAppIdInStorage(currentApp.AppId);
        }
        dispatch(selectedAppChanged(currentApp));
        return currentApp;
      }
    } catch (error) {
      history.replace('/');
    }
  };
}

function toggleAppMenu(status: boolean) {
  return {
    type: appsConstants.TOGGLE_APP_MENU,
    payload: status,
  };
}

// #region panacea vanity url

export type SetPanaceaVanityUrl = ReturnType<typeof setPanaceaVanityUrl>;

function setPanaceaVanityUrl(appId: string, url: string) {
  return action(appsConstants.SET_APP_PANACEA_VANITY_URL_REQUEST, { url, appId });
}

function setPanaceaVanityUrlSuccess(url: string) {
  return action(appsConstants.SET_APP_PANACEA_VANITY_URL_SUCCESS, url);
}

function setPanaceaVanityUrlFailure(e) {
  return action(appsConstants.GET_APP_PANACEA_VANITY_URL_FAILURE, e);
}

function getPanaceaVanityUrlSuccess(url: string) {
  return action(appsConstants.GET_APP_PANACEA_VANITY_URL_SUCCESS, url);
}

function getPanaceaVanityUrlFailure(e) {
  return action(appsConstants.GET_APP_PANACEA_VANITY_URL_FAILURE, e);
}

// #end region
