import { useEffect, useState } from 'react';

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(
    () => {
      // sync timer w system minute start
      const systemSeconds = new Date().getSeconds();
      const systemMilliseconds = systemSeconds < 5 ? 0 : systemSeconds * 1000;
      const timeout = 1000 * 60;
      const syncTimeout = systemSeconds < 5 ? timeout : timeout - systemMilliseconds;

      const timer = setTimeout(() => {
        setCurrentTime(new Date());
      }, syncTimeout);

      return () => {
        clearTimeout(timer);
      };
    },
    [currentTime]
  );

  return currentTime;
};
