import React from 'react';

import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';

import { toKebabCase } from '../../../helpers/strings';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { RMSModules } from '../utils/rmsModules';

type POSChannelSettingsListProps = {
  baseUrl: string;
  salesChannelType: SalesChannelType;
};
const POSChannelSettingsList = (props: POSChannelSettingsListProps) => {
  const { baseUrl, salesChannelType } = props;
  return (
    <>
      <ListItemLinkButton
        title="General_settings"
        subtitle="Set_name_price_bands_and_menu_View_associated_bran"
        link={`${baseUrl}/edit`}
        iconComponent={<SettingsOutlinedIcon />}
      />
      <ListItemLinkButton
        title="Operating_Configuration"
        subtitle="Configure_operating_configuration"
        link={`${baseUrl}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
        iconComponent={<SettingsApplicationsOutlinedIcon />}
      />
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Payout_Configuration"
          subtitle="Configure_payout_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.PAYOUT_CONFIGURATION)}`}
          iconComponent={<CreditCardOutlinedIcon />}
        />
      )}
      <ListItemLinkButton
        title="Opening_Hours"
        subtitle="Configure_sales_channel_opening_hours"
        link={`${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS)}`}
        iconComponent={<AccessTimeOutlinedIcon />}
      />
      <ListItemLinkButton
        title="Opening_Hours_Overrides"
        subtitle="Configure_sales_channel_opening_hours_overrides"
        link={`${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS_OVERRIDES)}`}
        iconComponent={<AccessTimeFilledOutlinedIcon />}
      />
      <ListItemLinkButton
        title="Delivery_Charges"
        subtitle="Configure_delivery_charges"
        link={`${baseUrl}/${toKebabCase(RMSModules.DELIVERY_CHARGES)}`}
        iconComponent={<MopedOutlinedIcon />}
      />
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Tip_Configuration"
          subtitle="Configure_tip_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.TIP_CONFIGURATION)}`}
          iconComponent={<AttachMoneyOutlinedIcon />}
        />
      )}
      {[
        SalesChannelType.UBER_EATS,
        SalesChannelType.JUST_EATS,
        SalesChannelType.DELIVEROO,
        SalesChannelType.FLIPDISH_KIOSK,
        SalesChannelType.FLIPDISH_MOBILE_APP,
        SalesChannelType.FLIPDISH_WEB_APP,
        SalesChannelType.EXTERNAL_APP,
      ].includes(salesChannelType) && (
        <ListItemLinkButton
          title="Order_Ingest"
          subtitle="Configure_order_ingest"
          link={`${baseUrl}/${toKebabCase(RMSModules.ORDER_INGEST)}`}
          iconComponent={<ZoomInMapOutlinedIcon />}
        />
      )}
      <ListItemLinkButton
        title="Timings_Configuration"
        subtitle="Configure_timings_configuration"
        link={`${baseUrl}/${toKebabCase(RMSModules.TIMINGS_CONFIGURATION)}`}
        iconComponent={<MoreTimeOutlinedIcon />}
        isLast={
          ![SalesChannelType.POS, SalesChannelType.WEB_APP, SalesChannelType.MOBILE_APP].includes(
            salesChannelType
          )
        }
      />
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Over_Phone_Payment"
          subtitle="Configure_over_phone_payment"
          link={`${baseUrl}/${toKebabCase(RMSModules.OVER_PHONE_PAYMENT)}`}
          iconComponent={<LocalPhoneOutlinedIcon />}
          isLast={true}
        />
      )}
      {salesChannelType === SalesChannelType.WEB_APP && (
        <ListItemLinkButton
          title="Web_App_Configuration"
          subtitle="Configure_web_app_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.WEB_APP_CONFIGURATION)}`}
          iconComponent={<WebOutlinedIcon />}
          isLast={true}
        />
      )}
      {salesChannelType === SalesChannelType.MOBILE_APP && (
        <ListItemLinkButton
          title="Mobile_App_Configuration"
          subtitle="Configure_mobile_app_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.MOBILE_APP_CONFIGURATION)}`}
          iconComponent={<PhoneIphoneOutlinedIcon />}
          isLast={true}
        />
      )}
    </>
  );
};

export default POSChannelSettingsList;
