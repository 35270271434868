import React, { useEffect, useState } from 'react';

import { type App } from '@flipdish/api-client-typescript';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { appsActions } from '../actions/apps.actions';
import { ReactComponent as ForkIcon } from '../assets/images/app_logo_fork.svg';
import generateSrcSet from '../ui/utils/generateSrcSet';

const useStyles = makeStyles({
  icon: {
    width: 40,
    height: 40,
  },
  appName: {
    wordBreak: 'break-all',
    color: ' rgba(0, 0, 0, 0.87)',
  },
  item: {
    minHeight: 50,
    flexShrink: 0,
  },
  activeListItem: {
    minHeight: 50,
    flexShrink: 0,
    backgroundColor: 'rgba(63,38,178,0.07)',
  },
});

type KeyPressType = { key: string };
type Props = MappedState & MappedDispatch & RouteComponentProps;
type OutterProps = { toggleAppMenu: () => void; open: boolean; apps: App[] };

const AppMenuItems = (props: Props & OutterProps) => {
  const classes = useStyles();
  const [refs, setRefs] = useState<Array<React.RefObject<HTMLDivElement>>>([]);
  const [currentFocusedAppIndex, setCurrentFocusedAppIndex] = useState(-1);
  const { apps, currentApp, open } = props;

  const [keyPressed, setKeyPressed] = useState<KeyPressType>({ key: '' });

  const keyPressHandler = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'Escape') {
      e.preventDefault();
      setKeyPressed({ key });
    }
  };

  useEffect(() => {
    if (!open) {
      setCurrentFocusedAppIndex(-1);
    }
  }, [open]);
  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', keyPressHandler);
    }

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [open]);

  useEffect(() => {
    const refArray: Array<React.RefObject<HTMLDivElement>> = [];
    for (let i = 0; i < apps.length; i++) {
      refArray.push(React.createRef());
    }
    setRefs(refArray);
  }, [apps.length]);

  useKeyboardNavigation(
    apps,
    refs,
    keyPressed,
    currentFocusedAppIndex,
    setCurrentFocusedAppIndex,
    props.toggleAppMenu
  );

  const itemSelected = (app) => {
    const { history } = props;

    const pathArray = history.location.pathname.split('/');
    if (app.AppId) {
      pathArray[1] = app.AppId;
      // if pathArray like thins, pathArray = (4) ["", "mexicancafe", "orders", "31368"]
      // we need make sure to remove everthing afert the module name(eg. "orders", "menus" )
      if (!pathArray.includes('website') && pathArray.length > 2) {
        pathArray.splice(3);
      }

      history.push(pathArray.join('/'));
    }
  };

  return (
    <>
      {apps &&
        apps.map((app: App, index: number) => {
          return (
            <ListItem
              key={app.AppId}
              ref={refs[index]}
              button
              onClick={() => itemSelected(app)}
              className={currentApp.AppId === app.AppId ? classes.activeListItem : classes.item}
            >
              <ListItemIcon>
                {app.LogoImageUrl ? (
                  <img
                    className={classes.icon}
                    srcSet={generateSrcSet(app.LogoImageUrl, {
                      width: 40,
                      png: true,
                    })}
                    src={app.LogoImageUrl}
                  />
                ) : (
                  <ForkIcon className={classes.icon} />
                )}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  display: 'block',
                  className: classes.appName,
                }}
                secondaryTypographyProps={{ variant: 'caption' }}
                primary={app.Name}
                secondary={app.AppId}
              />
            </ListItem>
          );
        })}
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    currentApp: currentApp,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  selectedAppChanged: (app: App) => dispatch(appsActions.selectedAppChanged(app)),
});

const EnhancedComponent = compose<Props, OutterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppMenuItems);

export default EnhancedComponent;

function useKeyboardNavigation(
  apps: App[],
  refs: Array<React.RefObject<HTMLDivElement>>,
  keyPressed: KeyPressType,
  currentFocusedAppIndex: number,
  setCurrentFocusedAppIndex: (val: number) => void,
  toggleAppMenu: () => void
) {
  useEffect(() => {
    if (apps.length) {
      // up arrow
      if (keyPressed.key === 'ArrowUp') {
        if (currentFocusedAppIndex === -1 || currentFocusedAppIndex === 0) {
          const lastElement = refs[refs.length - 1];
          setCurrentFocusedAppIndex(refs.length - 1);
          if (lastElement.current) {
            lastElement.current.focus();
          }
        } else {
          const newFocusedItem = refs[currentFocusedAppIndex - 1];
          setCurrentFocusedAppIndex(currentFocusedAppIndex - 1);
          if (newFocusedItem.current) {
            newFocusedItem.current.focus();
          }
        }
      }

      // down arrow
      if (keyPressed.key === 'ArrowDown') {
        if (currentFocusedAppIndex < refs.length - 1) {
          const newFocusedItem = refs[currentFocusedAppIndex + 1];
          setCurrentFocusedAppIndex(currentFocusedAppIndex + 1);
          if (newFocusedItem.current) {
            newFocusedItem.current.focus();
          }
        } else {
          setCurrentFocusedAppIndex(0);
          if (refs[0].current) {
            refs[0].current.focus();
          }
        }
      }
    }

    // ESC Key
    if (keyPressed.key === 'Escape') {
      toggleAppMenu();
    }
  }, [keyPressed]);
}
