import React, { useCallback, useEffect, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { App } from '@flipdish/api-client-typescript';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router';

import useRequestSnackBar from '../../../custom-hooks/useRequestSnackBar';
import { getSelectedStore } from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import * as actions from './actions';
import CollapseList from './components/CollapseList';
import NoDataAddKioskLink from './components/NoDataAddKioskLink';
import StoreLogo from './components/StoreLogo';
import * as selectors from './selectors';

type Props = RouteComponentProps<{ storeId }>;
type InnerProps = MappedState & MappedDispatch;

const StoreKioskSettings = (props: Props & InnerProps) => {
  const { fetchStoreKioskSettings, settings, store } = props;
  const { storeId } = useLoadStoreFromSalesChannelIdIntoRedux(store);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_kioskSettings', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (storeId) {
      fetchStoreKioskSettings(storeId)
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
  }, [storeId]);

  useEffect(() => {
    if (!loading && !error) {
      if (settings.length) {
        setExpanded((expanded) => (expanded === false ? 'Require_Customer_Name' : expanded));
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    }
  }, [loading, error, settings]);

  useRequestSnackBar({
    loading,
    failure: !!error,
    failureMessage: error,
  });

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/kiosksettings', '');
    history.push(parent);
  }, []);

  const handleExpandChange = useCallback(
    (title: any) => {
      if (!loading) {
        setExpanded(title);
      }
    },
    [loading]
  );

  return (
    <PageLayout
      toParent={backToParent}
      caption={<Translate id="Configure_how_this_store_appears_on_your_kiosk" />}
      documentTitle="Store_Kiosk_Settings"
      title={<Translate id="Store_Kiosk_Settings" />}
      pageHeader={
        <Permissions allowed={[App.AppResourceSetEnum.ViewHydraConfig]}>
          <NoDataAddKioskLink isEmpty={isEmpty} />
        </Permissions>
      }
    >
      <PaperContainer>
        <StoreLogo
          loading={loading}
          storeId={Number(storeId)}
          fdKeyName="basic_settings"
          title="Basic_settings"
        />
      </PaperContainer>

      <CollapseList
        title="Require_Customer_Name"
        subtitle="Customers_will_be_required_enter_name_before_completing"
        data={settings}
        loading={loading}
        disabled={isEmpty}
        expanded={expanded === 'Require_Customer_Name'}
        onExpandChange={handleExpandChange}
        valueResolver={(s) => s.RequireCustomerName!}
        onChange={(s) => ({
          PhysicalRestaurantId: s.PhysicalRestaurantId!,
          KioskSettingId: s.KioskSettingId!,
          RequireCustomerName: !s.RequireCustomerName,
        })}
      />

      <CollapseList
        title="Kiosk_request_customer_phone_number"
        subtitle="Kiosk_customers_will_be_asked_for_phone_number"
        data={settings}
        loading={loading}
        disabled={isEmpty}
        expanded={expanded === 'Kiosk_request_customer_phone_number'}
        onExpandChange={handleExpandChange}
        valueResolver={(s) => s.RequireCustomerPhoneNumber!}
        onChange={(s) => {
          return {
            PhysicalRestaurantId: s.PhysicalRestaurantId!,
            KioskSettingId: s.KioskSettingId!,
            RequireCustomerPhoneNumber: !s.RequireCustomerPhoneNumber!,
          };
        }}
        options={{
          onChange: (s) => {
            return {
              PhysicalRestaurantId: s.PhysicalRestaurantId!,
              KioskSettingId: s.KioskSettingId!,
              RequestCustomerPhoneNumber: !s.RequestCustomerPhoneNumber,
            };
          },
          radioButtonLabels: [
            {
              label: 'Kiosk_customer_phone_number_required_option',
              value: 'required',
            },
            {
              label: 'Kiosk_customer_phone_number_optional_option',
              value: 'optional',
            },
          ],
          defaultValueResolver: (s) => (s.RequestCustomerPhoneNumber ? 'optional' : 'required'),
          disabledValueResolver: (s) => !s.RequireCustomerPhoneNumber!,
        }}
      />

      <CollapseList
        title="Request_Table_Number"
        subtitle="If_you_offer_table_service_enable_this_setting"
        data={settings}
        loading={loading}
        disabled={isEmpty}
        expanded={expanded === 'Request_Table_Number'}
        onExpandChange={handleExpandChange}
        valueResolver={(s) => s.RequestTableNumber!}
        onChange={(s) => ({
          PhysicalRestaurantId: s.PhysicalRestaurantId!,
          KioskSettingId: s.KioskSettingId!,
          RequestTableNumber: !s.RequestTableNumber,
        })}
      />

      <CollapseList
        title="Offer_dine_in"
        subtitle="Customers_will_be_asked_if_they_like_to_dine_in_or_take_out"
        data={settings}
        loading={loading}
        disabled={isEmpty}
        expanded={expanded === 'Offer_dine_in'}
        onExpandChange={handleExpandChange}
        valueResolver={(s) => s.OfferDineInOrTakeawayOption!}
        onChange={(s) => ({
          PhysicalRestaurantId: s.PhysicalRestaurantId!,
          KioskSettingId: s.KioskSettingId!,
          OfferDineInOrTakeawayOption: !s.OfferDineInOrTakeawayOption,
        })}
      />
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchStoreKioskSettings: (storeId) => {
    return dispatch(actions.getStoreKioskSettings(Number(storeId)));
  },
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    store: getSelectedStore(state),
    settings: selectors.getStoreKioskSettings(state.storeKioskSettings),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreKioskSettings);
