import debounce from 'lodash/debounce';

/**
 * OBSOLETE - IT'S A BUG - NOT SAVING DEBOUNCE INSTANCE
 * @deprecated
 * */
const useDebouncedFn = (fn: any, wait = 100, dependencies?) => {
  const debounced = debounce(fn, wait);
  return debounced();
};

export default useDebouncedFn;
