import { FulfillmentStatesConfigurationSummary } from '@flipdish/api-client-typescript';

import { GetConfiguredOrderFulfillmentStatusSuccess } from '../actions/orderFulfillmentStatus.actions';
import { orderFulfillmentStatusConstants } from '../constants/orderFulfillmentStatus.constants';

const {
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST,
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS,
  GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE,
} = orderFulfillmentStatusConstants;

export interface State {
  [x: string]: any;
  configuredOrderFulfillmentStatusConfigurations?: FulfillmentStatesConfigurationSummary[];
  configuredOrderFulfillmentStatusLoading: boolean;
  configuredOrderFulfillmentStatussFailed: boolean;
  totalOrderFulfillmentStatusRecords: number;
}
const defaultState: State = {
  configuredOrderFulfillmentStatusConfigurations: undefined,
  configuredOrderFulfillmentStatusLoading: false,
  configuredOrderFulfillmentStatussFailed: false,
  totalOrderFulfillmentStatusRecords: 0,
};

export function orderFulfillmentStatusConfig(state = defaultState, action): State {
  switch (action.type) {
    case GET_CONFIGURED_ORDERFULFILLMENTSTATUS_REQUEST: {
      return {
        ...state,
        configuredOrderFulfillmentStatusLoading: true,
        configuredOrderFulfillmentStatussFailed: false,
      };
    }

    case GET_CONFIGURED_ORDERFULFILLMENTSTATUS_SUCCESS: {
      const { payload } = action as GetConfiguredOrderFulfillmentStatusSuccess;

      return {
        ...state,
        configuredOrderFulfillmentStatusConfigurations: payload,
        configuredOrderFulfillmentStatusLoading: false,
        configuredOrderFulfillmentStatussFailed: false,
        totalOrderFulfillmentStatusRecords: payload.length,
      };
    }

    case GET_CONFIGURED_ORDERFULFILLMENTSTATUS_FAILURE: {
      return {
        ...state,
        configuredOrderFulfillmentStatusLoading: false,
        configuredOrderFulfillmentStatussFailed: true,
      };
    }
    default:
      return state;
  }
}
