import React from 'react';

import { DynamicFormField } from '@flipdish/api-client-typescript';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { TranslateFunction } from 'react-localize-redux';

import { MessageBanner, SkeletonLoader } from '@fd/ui/atoms';
import { DynamicInputFields } from '@fd/ui/molecules/DynamicInputFields/DynamicInputFields';

import ActionButtonsOnMapForm from '../Auth/Onboarding/ActionButtonsOnMapForm';

export type DynamicAddressFormProps = {
  form?: DynamicFormField[];
  initialValues?: { [key: string]: any };
  loading?: boolean;
  error?: boolean;
  translate: TranslateFunction;
  handleSubmit: (values: any, { setSubmitting }: { setSubmitting: any }) => Promise<void>;
  disabled?: boolean;
  showActionButtonsOnMapForm?: boolean;
  displayFullWidthForm?: boolean;
  goBack?: () => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isValid?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  saveButtonContainer: {
    marginTop: theme.spacing(8),
  },
  dynamicFieldSkeleton: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  banner: {
    borderRadius: '5px',
    backgroundColor: 'rgb(202, 0, 48)',
  },
  bannerContent: {
    color: 'rgb(255, 255, 255)',
  },
  form: {
    width: '100%',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
}));

export const DynamicAddressForm = ({
  form,
  initialValues,
  loading = false,
  error = false,
  translate,
  handleSubmit,
  disabled = false,
  showActionButtonsOnMapForm = false,
  displayFullWidthForm = false,
  menuPlacement = 'bottom',
  goBack,
  isValid,
}: DynamicAddressFormProps) => {
  const classes = useStyles();

  const renderFormBody = () => {
    if (error) {
      return (
        <>
          <MessageBanner
            titleClassName={classes.bannerContent}
            className={classes.banner}
            title={translate('User_creation_failed') as string}
            icon={<ErrorOutlineIcon className={classes.bannerContent} />}
            fdKey="dynamic-form-error"
          ></MessageBanner>
        </>
      );
    }

    if (loading) {
      return (
        <SkeletonLoader
          fdKey="skeleton-form"
          rows={[
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
          ]}
        />
      );
    }

    if (!form) {
      return <></>;
    }

    return <DynamicInputFields form={form} disabled={disabled} menuPlacement={menuPlacement} />;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {({ isSubmitting, submitForm }) => (
        <Form className={`${displayFullWidthForm ? classes.form : ''}`}>
          {renderFormBody()}
          {showActionButtonsOnMapForm ? (
            <ActionButtonsOnMapForm
              isSubmitting={isSubmitting}
              goBack={goBack}
              isValid={isValid}
              submitForm={submitForm}
            />
          ) : (
            <Grid
              container
              className={classes.saveButtonContainer}
              item
              xs={12}
              justifyContent="flex-end"
            >
              {
                <Button
                  color="primary"
                  variant="contained"
                  data-fd="dynamic-address-save-button"
                  disabled={isSubmitting || disabled || error || loading}
                  type="button"
                  onClick={submitForm}
                >
                  {translate('Products_EditProductForm_SaveButtonTitle')}
                </Button>
              }
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};
