import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import PaperContainer from '@fd/ui/Layout/PaperContainer';

const useStyles = makeStyles((theme: Theme) => ({
  configurationGroup: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  configurationGroupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  configurationGroupMenuButton: {
    marginTop: -12,
    height: 48,
  },
  configurationField: {
    padding: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: theme.spacing(3),
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuOptionsItem: {
    padding: '4px 12px 4px 12px',
  },
  menuOptionsIcon: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '12px',
  },
  menuOptionsText: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  errorMessage: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(-3),
  },
  formControl: {
    display: 'block',
  },
}));

const ConfigurationGroups = ({
  values,
  setConfigurationGroupDialogOpen,
  setConfigurationFieldDialogOpen,
  translate,
  setFieldValue,
  isSubmitting,
  fieldName,
}) => {
  const classes = useStyles();

  const ConfigurationGroup = ({ fieldGroup, index }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<any>(null);
    const newFieldPosition = fieldGroup.Fields ? fieldGroup.Fields.length : 0;

    return (
      <PaperContainer fluid>
        <Grid item xs={12} className={classes.configurationGroup}>
          <div className={classes.configurationGroupHeader}>
            <div>
              <Typography variant="body1">{fieldGroup.Name}</Typography>
              <Typography variant="caption">{fieldGroup.Description}</Typography>
            </div>
            <IconButton
              className={classes.configurationGroupMenuButton}
              onClick={(event) => {
                event.preventDefault();
                setMenuAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>

          <Menu
            id="menu-options"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem
              onClick={() => setConfigurationGroupDialogOpen(index)}
              data-fd="duplicate-menu"
            >
              <EditIcon className={classes.menuOptionsIcon} />
              <Typography component="p" className={classes.menuOptionsText}>
                {translate('Edit')}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                setFieldValue(
                  'FieldGroups',
                  values.FieldGroups.filter((group) => group !== fieldGroup),
                  false
                )
              }
              data-fd="delete-menu"
            >
              <DeleteIcon className={classes.menuOptionsIcon} />
              <Typography component="p" className={classes.menuOptionsText}>
                {translate('Remove')}
              </Typography>
            </MenuItem>
          </Menu>

          {fieldGroup.Fields && !!fieldGroup.Fields.length && (
            <>
              {fieldGroup.Fields.map((field, fieldIndex) => (
                <div key={fieldIndex} className={classes.configurationField}>
                  <Typography variant="body1">{field.Name}</Typography>
                  <div>
                    <IconButton
                      onClick={() => {
                        const fieldGroupIndex = values.FieldGroups.indexOf(fieldGroup);
                        fieldGroup.Fields = fieldGroup.Fields.filter(
                          (currentField) => currentField !== field
                        );
                        setFieldValue(`FieldGroups[${fieldGroupIndex}]`, fieldGroup, false);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      data-fd={'edit-field'}
                      onClick={() =>
                        setConfigurationFieldDialogOpen({ fieldGroup, position: fieldIndex })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
            </>
          )}

          <Button
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
            fullWidth
            onClick={() =>
              setConfigurationFieldDialogOpen({ fieldGroup, position: newFieldPosition })
            }
          >
            <Translate id="Add_a_configuration_field" />
          </Button>
        </Grid>
      </PaperContainer>
    );
  };

  return (
    <Field name={fieldName}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;

        if (!values.FieldGroups || !values.FieldGroups.length) {
          return null;
        }
        return (
          <FormControl error={fieldError != undefined} className={classes.formControl}>
            {values.FieldGroups.map((fieldGroup, index) => (
              <ConfigurationGroup fieldGroup={fieldGroup} index={index} key={index} />
            ))}
            {fieldError && (
              <FormHelperText className={classes.errorMessage}>{fieldError}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default ConfigurationGroups;
