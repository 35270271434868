import React, { useEffect } from 'react';

import { StoreValidationConfig } from '@flipdish/api-client-typescript/private/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoresIcon from '@mui/icons-material/StoreOutlined';
import ExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

import InvalidStoreActionItem from './InvalidStoreActionItem';
import { kioskLocalStorageKeys } from './KioskStoresEdit';

//#region STYLES
const ExpansionPanelHeader = withStyles(
  ({ spacing }: Theme) => ({
    root: {
      minHeight: '24px!important',
      paddingLeft: spacing(2),
    },
    content: {
      marginTop: '16px!important',
      marginBottom: '16px!important',
    },
  }),
  {
    name: 'FdExpansionPanelSummary',
  }
)(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(
  ({ spacing }: Theme) => ({
    root: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
  }),
  { name: 'FdExpansionPanelDetails' }
)(MuiExpansionPanelDetails);
//#endregion

const mapActionTypeToTranlsation: { [key: string]: TranslationId } = {
  PickupEnabled: 'Accept_pickup_orders',
  BankAccountAttached: 'Kiosk_assign_bank_account_to_store',
  MenuAssigned: 'Kiosk_assign_menu_to_store',
  HasFullAddress: 'Kiosk_add_store_address',
  PickupHours: 'Kiosk_set_opening_hours',
  IsPublished: 'Publish_store',
};

const useStyles = makeStyles((theme: Theme) => ({
  storeIcon: { color: 'rgba(0, 0, 0, 0.58)', marginRight: theme.spacing(2.125) },
}));

type Props = { data: StoreValidationConfig; page?: number };

const InvalidStoreAccordion: React.FC<React.PropsWithChildren<Props>> = ({
  data: { Name, StoreGroupId, StoreId, ConfigValidation },
  page,
}) => {
  const styles = useStyles();
  const isExpandedLocalStorage = Number(localStorage.getItem(kioskLocalStorageKeys.isExpanded));
  const [isThisExpanded, setIsThisExpanded] = React.useState<boolean>(false);

  useEffect(() => {
    if (isExpandedLocalStorage === StoreId) {
      setIsThisExpanded(true);
    }
    return () => setIsThisExpanded(false);
  }, [page, StoreId, isExpandedLocalStorage]);

  return (
    <Grid item style={{ marginTop: 24, width: '100%' }}>
      <ExpansionPanel
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        expanded={isThisExpanded}
        onChange={(_, expanded) => {
          if (expanded && StoreId) {
            localStorage.setItem(kioskLocalStorageKeys.isExpanded, StoreId.toString());
          }
          setIsThisExpanded(!isThisExpanded);
        }}
      >
        <ExpansionPanelHeader
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
          data-fd={`${Name}-expansion-panel`}
        >
          <StoresIcon className={styles.storeIcon} />
          <Typography variant="body1">{Name}</Typography>
        </ExpansionPanelHeader>
        <Divider variant="fullWidth" />
        <ExpansionPanelDetails>
          <Grid container>
            {ConfigValidation &&
              StoreGroupId &&
              StoreId &&
              Object.entries(ConfigValidation).map(
                ([action, isValid], idx) =>
                  !isValid && (
                    <Grid container style={{ marginBottom: 24 }} key={idx}>
                      <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">
                          <Translate id={mapActionTypeToTranlsation[action]} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <InvalidStoreActionItem
                          action={action as keyof StoreValidationConfig['ConfigValidation']}
                          storeGroupId={StoreGroupId}
                          storeId={StoreId}
                        />
                      </Grid>
                    </Grid>
                  )
              )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default InvalidStoreAccordion;
