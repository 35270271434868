import moment from 'moment';
import { createAction } from 'redux-act';

import { notify, notifyError } from '../../../layouts/Notify/actions';
import {
  exportPayoutsAsCSV,
  exportSectionAsCSV,
  getPayoutDetailsSummary,
  getPayoutTableSummary,
} from './payouts.service';
import { PayoutDetailSummary, PayoutStatus, PayoutSummaryItem } from './types';

export const DEFAULT_ROWS_PER_PAGE = 5;
export const DEFAULT_PAGE = 0;

// #region getPayoutSummaryData
export const getPayoutSummaryDataSuccess = createAction<PayoutSummaryItem[]>(
  'GET_PAYOUT_SUMMARY_DATA_SUCCESS'
);
export const getPayoutSummaryDataFailure = createAction<string | null>(
  'GET_PAYOUT_SUMMARY_DATA_FAILURE'
);
export const getPayoutSummaryDataRequest = createAction('GET_PAYOUT_SUMMARY_DATA_REQUEST');

export const getPayoutSummaryData = (payoutSummaryParams: {
  bankAccountIds?: number[] | undefined;
  endDate: moment.Moment | string | undefined;
  startDate: moment.Moment | string | undefined;
  states?: PayoutStatus[] | undefined;
}) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const { bankAccountIds, startDate, endDate, states } = payoutSummaryParams;

    try {
      dispatch(getPayoutSummaryDataRequest());
      const data = await getPayoutTableSummary({
        appId,
        bankAccountIds,
        startDate,
        endDate,
        states,
      });
      dispatch(getPayoutSummaryDataSuccess(data));
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
// #endregion

// #region getPayoutDetailsSummary
export const getPayoutDetailsSummarySuccess = createAction<PayoutDetailSummary>(
  'GET_PAYOUT_DETAILS_SUMMARY_SUCCESS'
);
export const getPayoutDetailsSummaryFailure = createAction<string | null>(
  'GET_PAYOUT_DETAILS_SUMMARY_FAILURE'
);
export const getPayoutDetailsSummaryRequest = createAction('GET_PAYOUT_DETAILS_SUMMARY_REQUEST');

export const getPayoutDetailsSummaryData = (bankAccountId: number, payoutId: number) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(getPayoutDetailsSummaryRequest());
      const data = await getPayoutDetailsSummary({
        appId,
        bankAccountId,
        payoutId,
      });
      dispatch(getPayoutDetailsSummarySuccess(data as any));
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
// #endregion

//#region Download CSV
export const getPayoutsCSV = (payoutTableCSVParams: {
  bankAccountIds?: number[];
  endDate?: moment.Moment | string;
  startDate?: moment.Moment | string;
  states?: PayoutStatus[];
}) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const { bankAccountIds, startDate, endDate, states } = payoutTableCSVParams;

    try {
      dispatch(
        notify({
          variant: 'default',
          message: 'Your_file_is_being_prepared_for_download',
          translate: true,
        })
      );
      await exportPayoutsAsCSV({ appId, bankAccountIds, startDate, endDate, states });
      dispatch(
        notify({
          variant: 'success',
          message: 'Your_file_will_download_shortly',
          translate: true,
        })
      );
      return;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};

export const getPayoutSummaryCSV = (
  bankAccountId: number,
  payoutId: number,
  payoutSection: string,
  storeIdsFilter?: number[] | number
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      await exportSectionAsCSV({ appId, bankAccountId, payoutId, payoutSection, storeIdsFilter });
      dispatch(
        notify({ variant: 'success', message: 'Your_file_will_download_shortly', translate: true })
      );
      return;
    } catch (error) {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
    }
  };
};
//#endregion
