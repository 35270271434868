import React from 'react';

import { debounce } from 'lodash';

import { generalConstants } from '../constants/general.constants';

const { SCROLLABLE_CONTAINER } = generalConstants;

const isPassiveSupported = () => {
  let passive = false;

  const testOptions = {
    get passive() {
      return (passive = true);
    },
  };

  try {
    const listener = () => {
      return;
    };
    document.addEventListener('test', listener, testOptions);
    // @ts-ignore
    document.removeEventListener('test', listener, testOptions);
  } catch (e) {
    console.log('no support for passive events');
  }
  return passive;
};
const passive = isPassiveSupported();

export interface IWindowInfiniteScrollProps {
  hasMore: boolean;
  scrollableElId: string;
  lock?: boolean;
  initialLoad?: boolean;
  loader?: React.ComponentType<React.PropsWithChildren<unknown>>;
  pageStart: number;
  threshold?: number;
  loadMore?: (pageToLoad: number) => Promise<void>;
  className?: string;
  children: React.ReactNode;
}

class WindowInfiniteScroll extends React.Component<IWindowInfiniteScrollProps> {
  public static defaultProps = {
    scrollableElId: SCROLLABLE_CONTAINER,
    hasMore: false,
    initialLoad: true,
    pageStart: 0,
    threshold: 250,
    loader: null,
    lock: false,
  };

  private pageLoaded: number;
  private containerRef = React.createRef<HTMLDivElement>();
  private $lock = false;

  public constructor(props) {
    super(props);
    this.scrollListener = debounce(this.scrollListener, 100) as () => Promise<void>;
  }

  public componentDidMount() {
    this.pageLoaded = this.props.pageStart;
    this.attachMousewheelListener();
    this.attachResizeListener();
    this.attachScrollListener();

    if (this.props.initialLoad) {
      this.loadMore();
    }
  }

  public componentWillUnmount() {
    this.detachMousewheelListener();
    this.detachResizeListener();
    this.detachScrollListener();
  }

  public render() {
    const { children, hasMore, loader: Loader, className } = this.props;

    return (
      <div ref={this.containerRef} className={className}>
        {children}
        {Loader && hasMore && <Loader />}
      </div>
    );
  }

  private attachMousewheelListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.addEventListener('mousewheel', this.mousewheelListener, false);
    console.log('Attaching mousewheel');
  };
  private detachMousewheelListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.removeEventListener('mousewheel', this.mousewheelListener, false);
    console.log('Detaching mousewheel');
  };
  private mousewheelListener = () => {
    // Prevents Chrome hangups.
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    // if (e.deltaY === 1) {
    //   e.preventDefault();
    // }
  };

  private attachScrollListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.addEventListener('scroll', this.scrollListener, passive ? { passive: true } : false);
    console.log('Attaching scroll');
  };
  private detachScrollListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.removeEventListener('scroll', this.scrollListener, false);
    console.log('Detaching scroll');
  };

  private attachResizeListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.addEventListener('resize', this.scrollListener, passive ? { passive: true } : false);
    console.log('Attaching resize');
  };
  private detachResizeListener = () => {
    document
      .getElementById(this.props.scrollableElId)
      ?.removeEventListener('resize', this.scrollListener, false);
    console.log('Detaching resize');
  };

  private scrollListener = async () => {
    const { threshold, hasMore, lock, scrollableElId } = this.props;
    if (this.$lock || !hasMore || lock) {
      return;
    }

    const scrollableElement = document.getElementById(scrollableElId);

    if (!scrollableElement) {
      return;
    }

    const scrollTop = scrollableElement.scrollTop;

    const offset = this.calculateOffset(scrollableElement, scrollTop);

    if (offset < Number(threshold)) {
      await this.loadMore();
    }
  };
  private loadMore = async () => {
    const { loadMore } = this.props;
    if (loadMore != null) {
      this.$lock = true;
      await loadMore((this.pageLoaded += 1));
      this.$lock = false;
    }
  };

  private calculateOffset(el: HTMLElement, scrollTop: number) {
    if (!el) {
      return 0;
    }

    return (
      this.calculateTopPosition(this.containerRef.current!) +
      (this.containerRef.current!.offsetHeight - scrollTop - el.clientHeight)
    );
  }

  private calculateTopPosition(el: HTMLElement | Element | null) {
    if (!el) {
      return 0;
    }
    return (
      (el as HTMLElement).offsetTop + this.calculateTopPosition((el as HTMLElement).offsetParent)
    );
  }
}

export default WindowInfiniteScroll;
