import { ApmApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';

const apmApi = createApi(ApmApiFactory);

export const apmService = {
  getStatistics,
  getCalls,
  getCalendar,
  getCallStatistics,
  getOrderStatistics,
  getStatus,
};

function getStatistics(appId, storeIds?) {
  return apmApi
    .getBasicStatistics(appId, storeIds)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getStatus(appId) {
  return apmApi
    .getApmStatus(appId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getCalls(appId, page, limit, storeIds?) {
  return apmApi
    .getPaginatedCallList(appId, page, limit, storeIds)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getCalendar(appId, storeIds?) {
  return apmApi
    .getCalendarWeekStatistics(appId, storeIds)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getCallStatistics(appId, aggregateBy, storeIds?) {
  return apmApi
    .getCallsStatistics(appId, aggregateBy, undefined, storeIds)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getOrderStatistics(appId, aggregateBy, storeIds?) {
  return apmApi
    .getOrderStatistics(appId, aggregateBy, undefined, storeIds)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
