import {
  BankAccountDetail,
  BankAccountSummary,
  CountryWithAccountFieldsDefinitions,
} from '@flipdish/api-client-typescript';
import { createReducer } from 'redux-act';

import { appsConstants } from '../../../constants/apps.constants';
import {
  APPROVE_BANK_ACCOUNT,
  ATTACH_BANK_ACCOUNT_TO_STORE_RESET,
  ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS,
  BANKING_CREATE_SUCCESS,
  BANKING_CREATE_WITH_CONNECT_ACCOUNT_SUCCESS,
  BANKING_DETAILS_LOAD_SUCCESS,
  BANKING_LOAD_FAILURE,
  BANKING_LOAD_SUCCESS,
  BANKING_REMOVE_SUCCESS,
  BANKING_RESET_STATE,
  BANKING_STRIPE_GET_LINK_SUCCESS,
  bankingActions,
  CreateBankAccountAndConnectedAccountSuccess,
  CreateBankAccountSuccess,
  DISAPPROVE_BANK_ACCOUNT,
  GET_COUNTRIES_WITH_FIELD_DEFINITIONS_SUCCESS,
  GetBankAccountDetailsSuccess,
  GetBankAccountsSuccess,
  GetCountriesWithFieldDefinitionsSuccess,
  RemoveBankAccountSuccess,
  SET_BANK_ACCOUNT_COUNTRY,
} from './banking.actions';

type DefaultState = typeof defaultState;

const defaultState = {
  accounts: [] as BankAccountSummary[],
  // tslint:disable-next-line: no-object-literal-type-assertion
  accountById: {} as { [accId: number]: BankAccountDetail },
  isEmpty: false,
  isSuccess: false,
  selectedCountry: { value: '', label: '' },
  stores: [],
  getStoresLoading: false,
  stripeLinkUrl: '',
  countriesfieldDefinitions: {} as
    | { [key: string]: CountryWithAccountFieldsDefinitions }
    | undefined,
};

function BankingReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case appsConstants.SELECTED_APP_CHANGED:
    case BANKING_RESET_STATE:
      return {
        ...defaultState,
      };
    case BANKING_LOAD_SUCCESS: {
      const { payload } = action as GetBankAccountsSuccess;
      return {
        ...state,
        accounts: payload,
        isEmpty: payload.length === 0 ? true : false,
      };
    }
    case BANKING_LOAD_FAILURE: {
      return {
        ...state,
        accounts: [],
        isEmpty: true,
      };
    }
    case BANKING_DETAILS_LOAD_SUCCESS: {
      const { payload } = action as GetBankAccountDetailsSuccess;
      return {
        ...state,
        accountById: {
          ...state.accountById,
          [payload.Id as number]: payload,
        },
      };
    }
    case BANKING_CREATE_SUCCESS: {
      const { payload } = action as CreateBankAccountSuccess;
      return {
        ...state,
        accounts: state.accounts.concat([
          {
            AccountName: payload.AccountName,
            AccountState: payload.AccountState,
            Iban: payload.Iban,
            Id: payload.Id,
            StoreNames: payload.StoreNames,
            Swift: payload.Swift,
            CurrencyCode: payload.CurrencyCode!.toString(),
          },
        ]),
        isEmpty: false,
        accountById: {
          ...state.accountById,
          [payload.Id as number]: { ...payload, StoreNames: payload.StoreNames || [] },
        },
      };
    }
    case BANKING_REMOVE_SUCCESS: {
      const accountId = (action as RemoveBankAccountSuccess).payload;
      const filteredResult = state.accounts.filter((account) => account.Id !== accountId);
      return { ...state, accounts: filteredResult };
    }
    case ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
      };
    }
    case ATTACH_BANK_ACCOUNT_TO_STORE_RESET: {
      return {
        ...state,
        isSuccess: false,
      };
    }
    case APPROVE_BANK_ACCOUNT: {
      const { payload } = action;
      return {
        ...state,
        accounts: state.accounts.map((b) => {
          if (b.Id === payload) {
            return {
              ...b,
              AccountState: BankAccountDetail.AccountStateEnum.Verified,
            };
          }
          return b;
        }),
        accountById: {
          ...state.accountById,
          [payload as number]: {
            ...state.accountById[payload],
            AccountState: BankAccountDetail.AccountStateEnum.Verified,
          },
        },
      };
    }
    case DISAPPROVE_BANK_ACCOUNT: {
      const { payload } = action;
      return {
        ...state,
        accounts: state.accounts.map((b) => {
          if (b.Id === payload) {
            return {
              ...b,
              AccountState: BankAccountDetail.AccountStateEnum.Unverified,
            };
          }
          return b;
        }),
        accountById: {
          ...state.accountById,
          [payload as number]: {
            ...state.accountById[payload],
            AccountState: BankAccountDetail.AccountStateEnum.Unverified,
          },
        },
      };
    }
    case SET_BANK_ACCOUNT_COUNTRY: {
      return {
        ...state,
        selectedCountry: action.payload,
      };
    }
    case BANKING_STRIPE_GET_LINK_SUCCESS:
      return {
        ...state,
        stripeLinkUrl: action.payload,
      };
    case BANKING_CREATE_WITH_CONNECT_ACCOUNT_SUCCESS: {
      const { payload } = action as CreateBankAccountAndConnectedAccountSuccess;
      return {
        ...state,
        accounts: state.accounts.concat([
          {
            AccountName: payload.AccountName,
            AccountState: payload.AccountState,
            Iban: payload.Iban,
            Id: payload.Id,
            StoreNames: payload.StoreNames,
            Swift: payload.Swift,
            CurrencyCode: payload.CurrencyCode!.toString(),
            StripeConnectedAccountInfo: payload.StripeConnectedAccountInfo,
          },
        ]),
        isEmpty: false,
        accountById: {
          ...state.accountById,
          [payload.Id as number]: { ...payload, StoreNames: payload.StoreNames || [] },
        },
      };
    }
    case GET_COUNTRIES_WITH_FIELD_DEFINITIONS_SUCCESS: {
      const { payload } = action as GetCountriesWithFieldDefinitionsSuccess;
      return {
        ...state,
        countriesfieldDefinitions: payload,
      };
    }
    default:
      return reducer(state, action);
  }
}

export default BankingReducer;

const reducer = createReducer({}, defaultState);
reducer.on(
  bankingActions.setBusinessTypeActions.success,
  (
    state,
    payload: { BankAccountId: number; BusinessType: BankAccountSummary.BusinessTypeEnum }
  ) => {
    const { BankAccountId, BusinessType } = payload;
    const idx = state.accounts.findIndex((el) => el.Id === BankAccountId);
    const updatedAccount = [...state.accounts];
    updatedAccount[idx] = {
      ...updatedAccount[idx],
      BusinessType: BusinessType,
    };
    return {
      ...state,
      accounts: updatedAccount,
    };
  }
);
reducer.on(bankingActions.updateBankAccountActions.success, (state, payload) => {
  const idx = state.accounts.findIndex((el) => el.Id === payload);
  const updatedAccount = [...state.accounts];
  updatedAccount[idx] = {
    ...updatedAccount[idx],
  };
  return {
    ...state,
    accounts: updatedAccount,
  };
});
