import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AppStoreApp,
  AppStoreAppConfiguration,
  Field,
  UpdateAppStoreAppConfiguration,
  ValidationErrorResult,
} from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { UseMutateFunction } from '@tanstack/react-query';
import { type FormikProps, Form, withFormik } from 'formik';
import moment from 'moment';
import { Translate, TranslateFunction } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';
import StoreFilterInfiniteScroll from '@fd/ui/Filter/StoreFilterInfiniteScroll';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

import { loadById } from '../../../../services/store.service';
import PaperContainer from '../../../../ui/Layout/PaperContainer';
import AppStoreDeleteAppDialog from '../../Developers/components/AppStoreDeleteAppDialog';
import { RedirectData } from '../../types';
import ExternalInstructions from './ExternalInstructions';
import ActionButtonField from './fields/ActionButtonField';
import BooleanField from './fields/BooleanField';
import DateTimeField from './fields/DateTimeField';
import InputField from './fields/InputField';
import SelectField from './fields/SelectField';
import GridDivider from './GridDivider';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 30,
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 24,
    marginBottom: 30,
  },
  formHeaderContainer: {
    paddingLeft: 24,
  },
  formHeader: {
    paddingTop: 16,
    paddingBottom: 18,
  },
  field: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 4,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 24,
    },
  },
  actionButton: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 4,
  },
  storesField: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 16,
  },
  enableButtonContainer: {
    padding: 24,
  },
  button: {
    marginLeft: 12,
  },
}));

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (appStoreApp: AppStoreAppConfiguration) => {
  const values = {
    Stores: appStoreApp.StoreIds,
  };

  appStoreApp?.FieldGroups?.forEach((group) => {
    group.Fields?.forEach((field) => {
      const existingConfig = appStoreApp.Settings?.find((setting) => setting.Key == field.Key);

      if (existingConfig != undefined) {
        values[field.Key] = settingToFieldValue(appStoreApp, field.Key, existingConfig.Value!);
      } else if (field.DefaultValue != null) {
        values[field.Key] = settingToFieldValue(appStoreApp, field.Key, field.DefaultValue);
      }
    });
  });

  return values;
};

export type AppStoreAppConfigFormProps = {
  appStoreApp: AppStoreAppConfiguration;
  onDyanmicButtonClick: UseMutateFunction<any, any, any>;
  deleteAppConfiguration: UseMutateFunction;
  setRedirectData: React.Dispatch<React.SetStateAction<RedirectData>>;
  translate: TranslateFunction;
  onSaveChanges: (updatedAppStoreAppConfig: UpdateAppStoreAppConfiguration) => void;
  isEnabled: boolean;
  appStoreFormErrors?: ValidationErrorResult[];
  redirectData: RedirectData;
  isDynamicButtonLoading: boolean;
};

const AppStoreAppConfigForm = (props: AppStoreAppConfigFormProps & FormikProps<any>) => {
  const {
    appStoreApp,
    appStoreFormErrors,
    deleteAppConfiguration,
    onDyanmicButtonClick,
    redirectData,
    setRedirectData,
    isDynamicButtonLoading,
    handleSubmit,
    isEnabled,
    isSubmitting,
    setFieldError,
    setFieldValue,
    translate,
    values,
  } = props;

  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedStores, setSelectedStores] = useState<any>(null);
  const getInitialSelectedStores = useCallback(async () => {
    if (Array.isArray(appStoreApp.StoreIds)) {
      const storeIdsRequest = appStoreApp.StoreIds.map((id) => loadById(id));
      const storesData = await Promise.all(storeIdsRequest);
      setSelectedStores(
        storesData.map((store) => ({
          label: store.Name,
          value: store.StoreId,
        }))
      );
    }
  }, []);

  useEffect(() => {
    getInitialSelectedStores();
  }, [getInitialSelectedStores]);

  useEffect(() => {
    appStoreFormErrors?.forEach((err) => {
      setFieldError(err.FieldName!, err.Errors!.join('. '));
    });
  }, [appStoreFormErrors]);

  // Using `Array.find` function for each field would slow down render.
  // It's enough to memoizing those items into one object previously and each time
  // reference has changed is enough to receive updates
  const memoizedSettingsByKey = useMemo<{ [key: string]: string }>(() => {
    return (
      appStoreApp?.Settings?.reduce(
        (arr, next) => {
          // Check Value against to null and undefined if it's empty string this means it's valid
          if (next?.Key && next.Value !== undefined && next.Value !== null) {
            arr[next.Key] = next.Value;
          }
          return arr;
        },
        {} as { [key: string]: string }
      ) || {}
    );
  }, [appStoreApp?.Settings]);

  // Wrapper function in order to add direct query to data source
  const getSettingOfField = (key: string): string | undefined => {
    return memoizedSettingsByKey[key];
  };

  const renderFieldContainer = (field: Field) => {
    if (field.FieldType === Field.FieldTypeEnum.ActionButton) {
      const fieldMeta = getSettingOfField(field.Key);
      const isFieldMetaExists = fieldMeta !== undefined && fieldMeta !== null;

      if (
        // If field meta is specified we must rely on always
        // We had to check against to being null and undefined
        // Because settings default value is empty string and this means field is visible
        // JS Engine takes empty string as false
        (isFieldMetaExists && fieldMeta?.includes('hidden')) ||
        // In case field meta didn't created yet and form is in create state use DefaultValue of field
        (!isFieldMetaExists && field.DefaultValue?.includes('hidden'))
      ) {
        return null;
      }
      return (
        <React.Fragment key={field.Key}>
          <Grid item className={classes.actionButton}>
            {renderField(field)}
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={field.Key}>
          <Grid item xs={12} className={classes.field}>
            {renderField(field)}
          </Grid>
          <GridDivider />
        </React.Fragment>
      );
    }
  };

  const renderFieldGroup = (fieldGroup) => {
    return (
      <React.Fragment key={fieldGroup.Name}>
        {fieldGroup.Name && (
          <>
            <Grid item xs={12} className={classes.formHeaderContainer}>
              <Typography variant="h5" className={classes.formHeader}>
                {fieldGroup.Name}
              </Typography>
            </Grid>
            <GridDivider />
          </>
        )}

        {fieldGroup.Fields.map((field) => renderFieldContainer(field))}
        {fieldGroup.Fields.every(
          (field) => field.FieldType === Field.FieldTypeEnum.ActionButton
        ) && <GridDivider marginTop={20} />}
      </React.Fragment>
    );
  };

  const renderField = (field) => {
    switch (field.FieldType) {
      case Field.FieldTypeEnum.Text:
        return <InputField fieldData={field} type="text" />;
      case Field.FieldTypeEnum.TextArea:
        return <InputField fieldData={field} type="text" multiline />;
      case Field.FieldTypeEnum.Integer:
        return <InputField fieldData={field} type="number" />;
      case Field.FieldTypeEnum.Decimal:
        return <InputField fieldData={field} type="number" decimal />;
      case Field.FieldTypeEnum.DateTime:
        return <DateTimeField fieldData={field} />;
      case Field.FieldTypeEnum.Date:
        return <DateTimeField fieldData={field} dateOnly />;
      case Field.FieldTypeEnum.Time:
        return <DateTimeField fieldData={field} timeOnly />;
      case Field.FieldTypeEnum.Boolean:
        return <BooleanField fieldData={field} />;
      case Field.FieldTypeEnum.Select:
        return <SelectField fieldData={field} />;
      case Field.FieldTypeEnum.ActionButton:
        return (
          <ActionButtonField
            isDynamicButtonLoading={isDynamicButtonLoading}
            onDyanmicButtonClick={onDyanmicButtonClick}
            fieldData={field}
          />
        );
    }
  };

  const submitForm = ({ enable }) => {
    values.IsEnabling = enable;
    handleSubmit();
  };

  const onSelectStore = (stores, storeOptions) => {
    setSelectedStores(storeOptions);
    setFieldValue('Stores', stores);
  };

  const primaryActions = () => {
    if (isEnabled) {
      return (
        <span>
          <Button
            onClick={() => submitForm({ enable: true })}
            color="primary"
            className={classes.button}
            disabled={isSubmitting}
          >
            <Translate id="Save" />
          </Button>
          <Button
            onClick={() => submitForm({ enable: false })}
            color="secondary"
            className={classes.button}
            disabled={isSubmitting}
          >
            <Translate id="Disable" />
          </Button>
        </span>
      );
    } else {
      return (
        <span>
          <Button
            onClick={() => submitForm({ enable: false })}
            color="primary"
            className={classes.button}
            disabled={isSubmitting}
          >
            <Translate id="Save" />
          </Button>
          <Button
            onClick={() => submitForm({ enable: true })}
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isSubmitting}
          >
            <Translate id="Save_and_Enable" />
          </Button>
        </span>
      );
    }
  };

  const deleteButton = () => {
    return (
      <>
        <Button
          onClick={() => setDeleteDialogOpen(!deleteDialogOpen)}
          variant="contained"
          color="secondary"
          className={classes.button}
          disabled={isSubmitting}
          data-fd="configuration-delete-button"
        >
          <Translate id="Delete" />
        </Button>
        <AppStoreDeleteAppDialog
          appStoreApp={appStoreApp}
          isDialogOpen={deleteDialogOpen}
          deleteAppConfiguration={deleteAppConfiguration}
          setDialogOpen={setDeleteDialogOpen}
        />
      </>
    );
  };

  const content = () => {
    return (
      <>
        <YesNoDialog
          noTextId={'Cancel'}
          onNo={() => setRedirectData(null)}
          onYes={() => {
            redirectData?.url && window.open(redirectData.url, '_blank', 'noopener, noreferrer');
            setRedirectData(null);
          }}
          open={!!redirectData}
          titleText={redirectData?.fieldName}
          yesTextId={'Continue'}
        >
          <Typography variant="body1">
            <Translate id="Clicking_continue_will_open_a_new_tab" />
          </Typography>
        </YesNoDialog>
        {appStoreApp.StoreSelectorType !== AppStoreAppConfiguration.StoreSelectorTypeEnum.None && (
          <>
            <Grid item xs={12} className={classes.storesField}>
              <FormItemLayout label={translate('Stores')} alignItems="center">
                <StoreFilterInfiniteScroll
                  hasAllStoresOption={false}
                  label={appStoreApp.IsPaid ? 'Stores' : 'Select_store'}
                  placeholder={appStoreApp.IsPaid ? 'Stores' : 'Select_store'}
                  isClearable
                  isMulti
                  onSelectStore={onSelectStore}
                  variant="standard"
                  preSelectedStores={selectedStores}
                  // Backend is not accepting empty array as 'All Stores'
                  // It must be all the storeIds that WL has
                  passEmptyForAllStoreIds={false}
                />
              </FormItemLayout>
            </Grid>
            <GridDivider />
          </>
        )}
        {appStoreApp.FieldGroups!.map((fieldGroup) => renderFieldGroup(fieldGroup))}
      </>
    );
  };

  return (
    <Form>
      <PaperContainer fluid>
        <Grid container>{content()}</Grid>
        {appStoreApp.ConfigurationType === AppStoreApp.ConfigurationTypeEnum.ExternalLink && (
          <>
            <Grid item xs={12} className={classes.field}>
              <ExternalInstructions appStoreApp={appStoreApp} />
            </Grid>
            <GridDivider />
          </>
        )}
        <Permissions allowed={['Owner']}>
          <Grid
            container
            className={classes.enableButtonContainer}
            item
            xs={12}
            justifyContent={appStoreApp.IsPaid ? 'flex-end' : 'space-between'}
          >
            {deleteButton()}
            {primaryActions()}
          </Grid>
        </Permissions>
      </PaperContainer>
    </Form>
  );
};

const fieldTypeFromKey = (appStoreApp: AppStoreAppConfiguration, key: string): string => {
  let fieldType;

  appStoreApp.FieldGroups!.forEach((fieldGroup) => {
    fieldGroup.Fields!.forEach((field) => {
      if (field.Key === key) {
        fieldType = field.FieldType;
      }
    });
  });

  return fieldType;
};

const settingToFieldValue = (
  appStoreApp: AppStoreAppConfiguration,
  key: string,
  value: string
): any => {
  switch (fieldTypeFromKey(appStoreApp, key)) {
    case 'DateTime':
      return moment(value, 'YYYY-MM-DDTHH:mm');
    case 'Date':
      return moment(value, 'YYYY-MM-DD');
    case 'Time':
      return moment(value, 'HH:mm');
    case 'Boolean':
      return value === 'true';
    default:
      return value.toString();
  }
};

const fieldValueToString = (
  appStoreApp: AppStoreAppConfiguration,
  key: string,
  value: any
): string => {
  switch (fieldTypeFromKey(appStoreApp, key)) {
    case 'DateTime':
      return moment(value).format('YYYY-MM-DDTHH:mm');
    case 'Date':
      return moment(value).format('YYYY-MM-DD');
    case 'Time':
      return moment(value).format('HH:mm');
    default:
      return value.toString();
  }
};

const processForm = (
  appStoreApp: AppStoreAppConfiguration,
  formValues
): UpdateAppStoreAppConfiguration => {
  const settings = Object.keys(formValues)
    .filter((key) => !['Stores', 'IsEnabling'].includes(key))
    .map((key) => ({
      Key: key,
      Value: fieldValueToString(appStoreApp, key, formValues[key]),
    }));

  return {
    IsEnabled: formValues.IsEnabling,
    StoreIds: formValues.Stores,
    Settings: settings,
  };
};

export default withFormik<AppStoreAppConfigFormProps, FormValues>({
  displayName: 'AppStoreAppConfigForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.appStoreApp);
  },
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.onSaveChanges(processForm(formikBag.props.appStoreApp, values));
    formikBag.setSubmitting(false);
  },
})(AppStoreAppConfigForm);
