import React from 'react';

import { BusinessHoursPeriodBase } from '@flipdish/api-client-typescript';
import memoize from 'memoize-one';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { getDateTimeFormatter2 } from '../../selectors/localeDateTime.selector';
import { createOpeningHoursByIdSelector } from '../../selectors/storeOpeningHours.selector';
import { formatOpeningHour } from '../common/formatTime';
import { createFormField } from './components/FormField';
import { DeliveryType } from './types';

const getFormId = (ownProps: Props) => `${ownProps.id}.${ownProps.name}`;

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  const openingHoursByIdSelector = createOpeningHoursByIdSelector(
    Number(ownProps.storeId),
    ownProps.deliveryType,
    ownProps.name
  );
  const formId = getFormId(ownProps);
  const component = createFormField(formId);
  const openingHourFormatted = memoize(formatOpeningHour);

  return (state: AppState) => {
    const translate = getTranslate(state.locale);
    const openingHourById = openingHoursByIdSelector(state);
    const openingHour = openingHourFormatted(
      openingHourById!,
      getDateTimeFormatter2(state),
      translate
    );

    const loading = !openingHour;

    return {
      openingHour,
      component,
      loading,
    };
  };
};

export interface IOpeningHoursFormItemProps {
  storeId: number | string;
  label: string;
  name: string;
  deliveryType: DeliveryType;
  id: string;
  autoFocus?: boolean;
  disabled?: boolean;
  isLast?: boolean;
  update(data: BusinessHoursPeriodBase);
}
type Props = IOpeningHoursFormItemProps;

export default compose<Props & MappedState, IOpeningHoursFormItemProps>(
  setDisplayName('OpeningHoursFormItem'),
  connect(mapStateToPropsFactory)
)(({
  label,
  name,
  update,
  openingHour,
  component: FormField,
  loading,
  autoFocus,
  disabled,
  isLast,
  id,
}) => {
  return (
    <FormField
      id={id}
      label={label}
      name={name}
      update={update}
      openingHour={openingHour}
      loading={loading}
      autoFocus={autoFocus}
      disabled={disabled}
      isLast={isLast}
    />
  );
});
