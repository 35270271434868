import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../FormItemLayout';

const validate = (value: string) => {
  if (value.length > 100) {
    return 'Max_length_100';
  }
  return;
};

const DescriptionField = () => (
  <Field name={'Description'} validate={validate}>
    {({ field, form }: FieldProps) => {
      const { errors, touched, isSubmitting } = form;

      const fieldError = errors[field.name] as string | undefined;
      const showError = !!fieldError && (touched[field.name] as boolean | undefined);

      return (
        <FormItemLayout
          description={<Translate id="Voucher_description_description" />}
          label={<Translate id="Voucher_form_description" />}
        >
          <TextField
            {...field}
            autoComplete="off"
            disabled={isSubmitting}
            error={showError}
            fdKey={'Voucher_form_description'}
            fullWidth
            helperText={showError ? <Translate id={fieldError as 'Max_length_100'} /> : undefined}
            variant="outlined"
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default DescriptionField;
