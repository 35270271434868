import React from 'react';

import Refresh from '@mui/icons-material/Refresh';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms';

import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(7),
  },
  buttonContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  replayIcon: {
    marginRight: theme.spacing(1.5),
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
  },
}));

export type FailedResponseMessageProps = {
  title: TranslationId;
  description: TranslationId;
  showRetryButton?: boolean;
  onRetry?: () => void;
};

export const FailedResponseMessage = ({
  title,
  description,
  onRetry,
  showRetryButton = true,
}: FailedResponseMessageProps) => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.box}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">
          <Translate id={title} />
        </Typography>
      </div>
      <Typography variant="caption" variantMapping={{ caption: 'p' }}>
        <Translate id={description} />
      </Typography>
      {showRetryButton && (
        <Button
          className={classes.buttonContainer}
          fdKey={`${title}_retry-button`}
          variant="secondary"
          onClick={onRetry}
        >
          <Refresh className={classes.replayIcon} />
          <Translate id="Try_again" />
        </Button>
      )}
    </Box>
  );
};
