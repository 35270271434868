import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { AppConfigUpdateModel } from '@flipdish/api-client-typescript/private/api';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Checkbox from '@fd/ui/Checkbox';

import { appsConstants } from '../../../constants/apps.constants';
import { myFlipdish } from '../../../helpers/externalLinks';
import { formikValidate } from '../../../helpers/validation';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { Button } from '../../../ui/Button';
import LoadingButton from '../../../ui/LoadingButton';
import TextField from '../../../ui/TextField/TextField';
import { domainNameFormat, getLocalDNSRecords } from '../helpers';
import { RequestStatus } from '../reducer';
import { getDNSRecords } from '../selectors';
import DnsRecords from './DnsRecords';
import WebEmbedCode from './WebEmbedCode';

const updatePanaceaLoadingSelector = createLoadingSelector([
  appsConstants.SET_APP_PANACEA_VANITY_URL,
  appsConstants.GET_APP_PANACEA_VANITY_URL,
]);

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  subHeader: {
    display: 'block',
    marginBottom: 12,
  },
  fieldWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& > *': {
      marginBottom: 20,
    },
  },
  inputField: {
    marginRight: 12,
    marginBottom: 12,
  },
  input: {
    padding: 8,
    height: 20,
  },
  postfix: {
    width: '100%',
    maxWidth: 480,
    marginRight: 20,
  },
  helperText: {
    margin: 0,
    position: 'absolute',
    bottom: -16,
    left: 0,
  },
  btn: {
    marginRight: 12,
    [theme.breakpoints.down(384)]: {
      marginBottom: 12,
    },
  },
  btngroup: {
    marginRight: 12,
    [theme.breakpoints.down(797)]: {
      marginBottom: 12,
    },
  },
  checkboxText: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
}));
type InnerProps = MappedState;
type OuterProps = {
  activeStep: number;
  appId?: string;
  available: boolean;
  checking: boolean;
  currentPanacea?: string;
  getDNSStatus: () => Promise<void>;
  helperText: null | ReactNode;
  icon: null | ReactNode;
  panaceaUrl?: string;
  resetDnsRecords: () => Action;
  setUrl: (appId: string, url: string) => ActionPayload<{ appId: string; url: string }>;
  setUseOwnDomain: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setHostName: (hostname: string, isEmbed: boolean, hasSubdomain: boolean) => Promise<void>;
  setPanaceaUrl: React.Dispatch<React.SetStateAction<string>>;
  setStepsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  setChecking: React.Dispatch<React.SetStateAction<boolean>>;
  setAppConfig: (config: AppConfigUpdateModel) => Promise<void>;
  useOwnDomain: boolean;
  dispatchResetWebsiteStatusFields: React.Dispatch<React.SetStateAction<void>>;
  reconfigureDomainSetupClicked: boolean;
};
type Props = InnerProps & OuterProps;

const Steps = (props: Props) => {
  const {
    activeStep,
    appId,
    available,
    checking,
    currentPanacea,
    getDNSStatus,
    helperText,
    icon,
    panaceaUrl,
    panaceaLoading,
    records,
    resetDnsRecords,
    setActiveStep,
    setAppConfig,
    setChecking,
    setHostName,
    setPanaceaUrl,
    setStepsCompleted,
    setUseOwnDomain,
    setUrl,
    translate,
    useOwnDomain,
    setHostnameRequestStatus,
    dispatchResetWebsiteStatusFields,
    reconfigureDomainSetupClicked,
  } = props;

  const [isEmbed, setIsEmbed] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [domainName, setDomainName] = useState<string>('');
  const [hasSubdomain, setHasSubdomain] = useState<boolean>(false);

  const validateDomain = (value: string) => {
    return formikValidate.domain(value);
  };
  const isValidDomain = useMemo(() => {
    return !validateDomain(textFieldValue);
  }, [textFieldValue]);

  const resetSteps = () => {
    setStepsCompleted(true);
    setActiveStep(0);
  };

  useEffect(() => {
    if (!reconfigureDomainSetupClicked) {
      if (setHostnameRequestStatus === RequestStatus.SUCCESS) {
        setAppConfig({ IsPanaceaEnabled: false });
        resetSteps();
      } else if (setHostnameRequestStatus === RequestStatus.ERROR) {
        dispatchResetWebsiteStatusFields();
        resetSteps();
      }
    }
  }, [setHostnameRequestStatus]);

  const renderBackBtn = (index: number) => (
    <Button
      fdKey="previous_step"
      color="primary"
      variant="text"
      style={{
        backgroundColor: 'transparent',
        padding: 0,
        minWidth: 0,
        marginTop: 12,
        textTransform: 'capitalize',
      }}
      size="small"
      onClick={() => {
        setActiveStep(index - 1);
      }}
    >
      {translate('Back')}
    </Button>
  );

  const classes = useStyles();

  const steps: TranslationId[] = [
    'Do_you_have_a_domain',
    useOwnDomain
      ? 'How_would_you_like_to_use_Flipdish_Online_Ordering'
      : 'Customise_your_my_flipdish_com_domain',
    'Enter_domain',
    isEmbed ? 'Copy_the_embed_code' : 'Add_these_records_and_youre_good_to_go',
  ];

  const stepsInfo = (index: number) => {
    switch (index) {
      case 0:
        return (
          activeStep >= 0 && (
            <StepContent TransitionProps={{ in: true }} style={{ marginTop: 12 }}>
              {/* CHOICES */}
              {activeStep === 0 && (
                <>
                  <Button
                    fdKey="yes_have_own_domain_btn"
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                    onClick={() => {
                      setUseOwnDomain(true);
                      setActiveStep(1);
                    }}
                  >
                    {translate('Yes_I_have_my_own_domain')}
                  </Button>
                  <Button
                    fdKey="no_does_not_have_own_domain_btn"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setUseOwnDomain(false);
                      setActiveStep(1);
                    }}
                  >
                    {translate('No')}
                  </Button>
                </>
              )}
              {/* ANSWER */}
              {activeStep >= 1 && (
                <Typography variant="caption" className={classes.subHeader}>
                  {useOwnDomain ? translate('Yes_I_have_my_own_domain') : translate('No')}
                </Typography>
              )}
            </StepContent>
          )
        );

      // will show form if !useOwnDomain otherwise buttons
      case 1:
        return activeStep >= 1 && useOwnDomain ? (
          <StepContent TransitionProps={{ in: true }} style={{ marginTop: 12 }}>
            {/* CHOICES */}
            {activeStep === 1 && (
              <>
                <Button
                  fdKey="use_embed_code_btn"
                  variant="outlined"
                  color="primary"
                  className={classes.btngroup}
                  onClick={() => {
                    setIsEmbed(true);
                    setActiveStep(2);
                  }}
                >
                  {translate('I_have_my_own_website_use_embed_code')}
                </Button>
                <Button
                  fdKey="use_flipdish_site_btn"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsEmbed(false);
                    setActiveStep(2);
                  }}
                >
                  {translate('use_flipdish_generated_site')}
                </Button>
              </>
            )}
            {activeStep === index && <div>{renderBackBtn(index)}</div>}

            {/* ANSWER */}
            {activeStep >= 2 && (
              <Typography variant="caption" className={classes.subHeader}>
                {isEmbed
                  ? translate('I_have_my_own_website_use_embed_code')
                  : translate('use_flipdish_generated_site')}
              </Typography>
            )}
          </StepContent>
        ) : (
          <StepContent TransitionProps={{ in: true }} style={{ marginTop: 12 }}>
            <Typography variant="caption" className={classes.subHeader}>
              {activeStep === index ? translate('Add_domain_for_website') : panaceaUrl}
            </Typography>
            {/* PANACEA URL FORM */}
            {activeStep === index && (
              <>
                <div className={classes.fieldWrapper}>
                  <Typography variant="caption" style={{ marginRight: 5 }}>
                    {myFlipdish()}
                  </Typography>
                  <FormControl className={classes.postfix}>
                    <TextField
                      fullWidth
                      value={panaceaUrl}
                      onChange={(e) => {
                        const value = e.target.value || '';
                        setChecking(true);
                        setPanaceaUrl(value);
                      }}
                      helperText={helperText}
                      FormHelperTextProps={{
                        classes: { root: classes.helperText },
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                        endAdornment: icon,
                      }}
                      variant="outlined"
                      error={false}
                      fdKey="panacea_input"
                    />
                  </FormControl>
                </div>
                <div style={{ width: 160 }}>
                  <LoadingButton
                    loading={panaceaLoading || checking}
                    variant="contained"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      if (appId && panaceaUrl) {
                        if (panaceaUrl !== currentPanacea) {
                          setUrl(appId, panaceaUrl);
                        }

                        setStepsCompleted(true);
                        setAppConfig({ IsPanaceaEnabled: true });
                      }
                    }}
                    disabled={
                      (!available && panaceaUrl !== currentPanacea) ||
                      (checking && panaceaUrl !== currentPanacea)
                    }
                    fdKey="set_panacea_btn"
                  >
                    {translate('Save')}
                  </LoadingButton>
                </div>
              </>
            )}
            {activeStep === index && <div>{renderBackBtn(index)}</div>}
          </StepContent>
        );

      case 2:
        return (
          activeStep >= 2 && (
            <StepContent TransitionProps={{ in: true }} style={{ marginTop: 12 }}>
              <Typography variant="caption" className={classes.subHeader}>
                {activeStep === index
                  ? translate('Add_domain_for_website')
                  : `${domainName} ${translate('Set').toString().toLowerCase()}`}
              </Typography>
              {/* FORM */}
              {activeStep === index && (
                <>
                  <div className={classes.fieldWrapper}>
                    <FormControl className={classes.postfix}>
                      <TextField
                        fullWidth
                        value={textFieldValue}
                        onChange={(e) => {
                          const value = e.target.value || '';
                          setTextFieldValue(value);
                        }}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputProps={{
                          classes: { input: classes.input },
                        }}
                        variant="outlined"
                        fdKey="add_domain_input"
                      />
                      {!isEmbed && (
                        <Box pt={2.25} pb={0.5}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                dataFd="check_if_website_has_subdomain"
                                checked={hasSubdomain}
                                onChange={(e) => setHasSubdomain(e.target.checked)}
                                inputProps={{
                                  'aria-label': `${translate('Check_this_box_if_website_has_subdomain')}`,
                                }}
                                value={'check_if_website_has_subdomain'}
                                color="primary"
                              />
                            }
                            label={translate('Check_this_box_if_website_has_subdomain')}
                            classes={{
                              label: classes.checkboxText,
                            }}
                          />
                        </Box>
                      )}
                    </FormControl>
                  </div>
                  <div style={{ width: 160 }}>
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        resetDnsRecords();
                        const domain = domainNameFormat(textFieldValue, hasSubdomain);
                        setDomainName(domain); // only used locally so we can show it in the answer field without obvious re render
                        setActiveStep(3);
                      }}
                      disabled={!isValidDomain}
                      fdKey="add_domain_btn"
                    >
                      {translate('Save')}
                    </LoadingButton>
                  </div>
                </>
              )}
              {activeStep === index && <div>{renderBackBtn(index)}</div>}
            </StepContent>
          )
        );
      case 3: {
        const DNSRecords = getLocalDNSRecords({ records, domainName, hasSubdomain });
        return (
          activeStep >= 3 && (
            <StepContent TransitionProps={{ in: true }} style={{ marginTop: 12 }}>
              {!isEmbed && (
                <DnsRecords
                  getDNSStatus={getDNSStatus}
                  visibleRecords={DNSRecords}
                  showTitle={false}
                />
              )}
              {isEmbed && domainName && <WebEmbedCode domain={domainName} />}
              <div style={{ width: 160 }}>
                <Button
                  fdKey="complete_steps"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    // this api will not update parent
                    setHostName(domainName, isEmbed, hasSubdomain);
                  }}
                >
                  {translate('Done')}
                </Button>
              </div>
              {activeStep === index && <div>{renderBackBtn(index)}</div>}
            </StepContent>
          )
        );
      }
      default:
        return <div />;
    }
  };
  return (
    <Stepper classes={{ root: classes.stepper }} activeStep={activeStep} orientation="vertical">
      {steps.map((title, index) => {
        return (
          activeStep >= index && (
            <Step key={index}>
              <StepButton disableRipple onClick={() => setActiveStep(index)}>
                {translate(title)}
              </StepButton>
              {stepsInfo(index)}
            </Step>
          )
        );
      })}
    </Stepper>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  panaceaLoading: updatePanaceaLoadingSelector(state),
  records: getDNSRecords(state),
  translate: getTranslate(state.locale),
  setHostnameRequestStatus: state.website?.setHostnameRequestStatus,
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(Steps);
