import {
  AppsApiFactory,
  HomeApiFactory,
  RestApiArrayResultHomeAction,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint, setIdentifyApiEndpoint } from '../../helpers/apibase';
import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const api = createApi(HomeApiFactory);

const appsApi = createApi(AppsApiFactory);

const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

setIdentifyApiEndpoint();

// #region setupConfigs
export async function loadSetupConfigs(appId: string) {
  try {
    // const msg = await api.getHomeActions(appId);
    const msg = await portalApi.get<RestApiArrayResultHomeAction>(
      `/api/v1.0/${encodeURIComponent(appId)}/home`
    );

    return msg.data.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region dismissButton
export async function dismiss(appId: string, homeActionCardId: number) {
  try {
    await api.completeHomeAction(appId, homeActionCardId, true);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region Savings
export async function loadSavings(appId: string) {
  try {
    const msg = await api.getHomeStatistics(appId);
    return msg.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion
