import React, { ReactNode } from 'react';

import MuiBox from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { type SpacingProps } from '@mui/system';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  solidBorder: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    padding: theme.spacing(1),
  },

  dashedBorder: {
    border: '1px dashed rgba(0, 0, 0, 0.38)',
  },
  centeredContent: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    height: '100%',
  },
}));

export type BoxProps = SpacingProps & {
  children: ReactNode;
  className?: string;
  bordered?: boolean;
  borderType?: 'dashed' | 'solid';
  centeredContent?: boolean;
};

export const Box = (props: BoxProps): JSX.Element => {
  const {
    bordered = true,
    borderType = 'solid',
    children,
    className,
    centeredContent = false,
    ...rest
  } = props;
  const classes = useStyles();
  const borderStyle = clsx(borderType === 'solid' ? classes.solidBorder : classes.dashedBorder);
  const boxStyles = clsx(bordered && borderStyle, className);
  const boxContentStyles = clsx(
    classes.content,
    centeredContent === true && classes.centeredContent
  );
  return (
    <MuiBox className={boxStyles} {...rest}>
      <div className={boxContentStyles}>{children}</div>
    </MuiBox>
  );
};
