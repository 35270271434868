/* eslint-disable import/no-anonymous-default-export */
import { SET_HELP_DRAWER_CLOSED, SET_HELP_DRAWER_OPEN } from '../actions/drawer.actions';

type DrawerState = {
  helpDrawerOpen: boolean;
};

const initialState: DrawerState = {
  helpDrawerOpen: false,
};

export default (state: DrawerState = initialState, action): DrawerState => {
  switch (action.type) {
    case 'SET_HELP_DRAWER_OPEN':
      return {
        ...state,
        helpDrawerOpen: true,
      };

    case 'SET_HELP_DRAWER_CLOSED':
      return {
        ...state,
        helpDrawerOpen: false,
      };
    default:
      return state;
  }
};
