import React from 'react';

import { Reject } from '@flipdish/api-client-typescript';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { capitalize, snakeCase } from 'lodash';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

const styles = (theme: Theme) => {
  return createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 76,
    },
    customMins: {
      display: 'flex',
      alignItems: 'center',
    },
    rejectMenuItem: {
      color: '#ff395f',
    },
    content: {
      padding: '0 !important',
    },
  });
};

const enumToArray = (myEnum) => {
  const arrayOfEnums: string[] = [];
  for (const item in myEnum) {
    if (isNaN(Number(item))) {
      arrayOfEnums.push(capitalize(snakeCase(item)));
    }
  }
  return arrayOfEnums;
};
type Props = {
  open: boolean;
  rejectOrder: (reason: Reject.RejectReasonEnum) => void;
  handleClose: () => void;
} & WithStyles<typeof styles>;

const RejectDialogMobile: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, open, rejectOrder, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={classes.content}>
        <MenuList>
          {enumToArray(Reject.RejectReasonEnum).map((reason, index) => (
            <MenuItem
              onClick={() => {
                rejectOrder(Reject.RejectReasonEnum[reason]);
                handleClose();
              }}
              className={classes.rejectMenuItem}
              key={index}
            >
              <Translate id={`${reason}` as TranslationId} />
            </MenuItem>
          ))}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'acceptDialogMobile',
  })
)(RejectDialogMobile);

export default EnhancedComponent;
