import { StoreListItem } from '@flipdish/api-client-typescript';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

type Campaigns = ReturnType<typeof campaignsSelector>;
const campaignsSelector = (state: AppState) => state.campaigns;

export const getStoreSelectOptions = (store: StoreListItem) => {
  return {
    value: store.StoreId as number,
    label: store.StoreName as string,
  };
};

export const isNoCampaigns = createSelector([campaignsSelector], (campaigns) => {
  const { loyalty, retention } = campaigns;
  return isEmpty(loyalty) && isEmpty(retention);
});

export const loyaltyCampaigns = createSelector([campaignsSelector], (campaigns) => {
  return campaigns.loyalty;
});

export const retentionCampaigns = createSelector([campaignsSelector], (campaigns) => {
  return campaigns.retention;
});

export const getStores = createSelector([campaignsSelector], (campaigns) => {
  return campaigns.stores;
});

// TODO: Uncomment the code when need to select store groups
/*export const getLoyaltyStoreGroupOptions = createSelector([campaignsSelector], (campaigns) => {
  const stores = campaigns.stores;

  // At least one store in group doesn't have loyalty campaign
  const groups = filter(campaigns.storeGroups, ({ storesCount }) => {
    const { all, inLoyaltyCampaigns } = storesCount;
    return all !== inLoyaltyCampaigns && all !== 1;
  });

  const options = [
    ...stores.map(({ StoreName, HasLoyaltyCampaign, StoreId }) => {
      let label = StoreName;

      if (HasLoyaltyCampaign) {
        label = `${StoreName} (added to antother campaign)`;
      }

      return {
        value: `Store-${StoreId}`,
        label,
      };
    }),
    ...groups.map(({ StoreGroupId, storesCount, StoreGroupName }) => {
      const { all, inLoyaltyCampaigns } = storesCount;
      let label = `${StoreGroupName} (${all} stores)`;

      if (inLoyaltyCampaigns !== 0) {
        label = `${inLoyaltyCampaigns} out of ${all} stores in other campaigns`;
      }

      return {
        value: `StoreGroup-${StoreGroupId}`,
        label,
      };
    }),
  ];
  return sortBy(options, ['label']);
});*/
