import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { FabButtonLink } from '@fd/ui/FabButtonLink';

import Button from '../../../../ui/Button/Button';
import { getEditAccountUrlFactory } from '../banking.selectors';

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    editBtn: {
      minWidth: '96px',
      backgroundColor: '#05149e',
      '&:hover': { backgroundColor: '#05149e' },
      [breakpoints.down('md')]: {
        width: '100%',
      },
    },
  })
);

type EditAccountLinkProps = {
  accountId: number;
};
type Props = EditAccountLinkProps & MappedProps;
function EditAccountLink({ editAccountUrl }: Props) {
  const classes = useStyles();

  if (!editAccountUrl) {
    return null;
  }
  return (
    <Permissions allowed={[App.AppResourceSetEnum.CreateBankAccounts]}>
      <Button
        className={classes.editBtn}
        variant="contained"
        color="secondary"
        // @ts-ignore
        to={editAccountUrl}
        fdKey={'Edit'}
        component={FabButtonLink}
      >
        <Translate id={'Edit'} />
      </Button>
    </Permissions>
  );
}

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state: AppState, ownProps: EditAccountLinkProps) => {
  const getEditAccountUrl = getEditAccountUrlFactory(ownProps.accountId as number);
  return (state: AppState) => {
    const editAccountUrl = getEditAccountUrl(state);
    return {
      editAccountUrl,
    };
  };
};

export default compose<MappedProps, EditAccountLinkProps>(connect(mapStateToPropsFactory))(
  EditAccountLink
);
