import { OrdersTableColumns } from '../Reports/types';
import { CustomersTableColumns } from './types';

export const customersMeta: CustomersTableColumns[] = [
  {
    columnName: 'customer',
    columnType: 'Subtext',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
    translationId: 'Customer',
  },
  {
    columnName: 'totalOrders',
    columnType: 'Integer',
    isSortable: true,
    isVisible: true,
    ordinal: 10,
    translationId: 'Order_count',
  },
  {
    columnName: 'totalOrderValue',
    columnType: 'Decimal',
    isSortable: true,
    isVisible: true,
    ordinal: 20,
    translationId: 'Total_order_value',
  },
  {
    columnName: 'averageOrderValue',
    columnType: 'Decimal',
    isSortable: true,
    isVisible: true,
    ordinal: 30,
    translationId: 'Average_order_value',
  },
  {
    columnName: 'lastOrderDate',
    columnType: 'DateTime',
    isSortable: true,
    isVisible: true,
    ordinal: 40,
    translationId: 'Last_activity_time',
  },
  {
    columnName: 'customerId',
    columnType: 'Integer',
    isSortable: false,
    isVisible: false,
    ordinal: 100,
  },
  {
    columnName: 'phoneNumber',
    columnType: 'String',
    isSortable: false,
    isVisible: false,
    ordinal: 105,
    translationId: 'Customer',
  },
  {
    columnName: 'name',
    columnType: 'String',
    isSortable: false,
    isVisible: false,
    ordinal: 110,
  },
  {
    columnName: 'currency',
    columnType: 'CurrencyCode',
    isSortable: false,
    isVisible: false,
    ordinal: 120,
    translationId: 'Currency',
  },
];

export const customerDetailsTableMeta: OrdersTableColumns[] = [
  {
    columnName: 'orderId',
    translationId: 'Order_id',
    isVisible: true,
    ordinal: 0,
    isSortable: false,
    columnType: 'String',
  },
  //#region App/Store info
  {
    columnName: 'appId',
    translationId: 'AppId',
    isVisible: false,
    ordinal: 10,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'storeId',
    translationId: 'Store_ID',
    isVisible: false,
    ordinal: 30,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'StoreName',
    translationId: 'Store-name',
    isVisible: false,
    ordinal: 32,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region Customer info
  {
    columnName: 'customerId',
    translationId: 'User_id',
    isVisible: false,
    ordinal: 40,
    isSortable: true,
    columnType: 'Integer',
  },
  {
    columnName: 'customerName',
    translationId: 'Customer_name',
    isVisible: false,
    ordinal: 50,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region order payment/status/time info
  {
    columnName: 'orderState',
    translationId: 'Order_status',
    isVisible: true,
    ordinal: 60,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'orderType',
    translationId: 'Delivery_collection',
    isVisible: false,
    ordinal: 70,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'channel',
    translationId: 'Channel',
    isVisible: false,
    ordinal: 80,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountType',
    translationId: 'Payment_account_type',
    isVisible: false,
    ordinal: 90,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountDescription',
    translationId: 'Payment_account_description',
    isVisible: false,
    ordinal: 110,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'placedAtTimeLocal',
    translationId: 'Placed_at_time_local',
    isVisible: false,
    ordinal: 130,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'requestedForTimeLocal',
    translationId: 'Requested_for_time_local',
    isVisible: false,
    ordinal: 150,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'acceptedAtTimeLocal',
    translationId: 'Accepted_at_time_local',
    isVisible: false,
    ordinal: 170,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'currency',
    translationId: 'Currency',
    isVisible: false,
    ordinal: 190,
    isSortable: false,
    columnType: 'CurrencyCode',
  },
  //#endregion
  //#region amounts
  {
    columnName: 'customerAmount',
    translationId: 'Customer_amount',
    isVisible: false,
    ordinal: 200,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'subtotal',
    translationId: 'Subtotal',
    isVisible: false,
    ordinal: 210,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'tipAmount',
    translationId: 'Tip_amount',
    isVisible: false,
    ordinal: 220,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'deliveryCharge',
    translationId: 'Delivery_amount',
    isVisible: false,
    ordinal: 230,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'netAmount',
    translationId: 'Net_amount',
    isVisible: true,
    ordinal: 240,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'refundAmount',
    translationId: 'Refund_amount',
    isVisible: false,
    ordinal: 250,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'tipAmountTax',
    translationId: 'Tip_amount_tax',
    isVisible: false,
    ordinal: 260,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'deliveryChargeTax',
    translationId: 'Delivery_charge_tax',
    isVisible: false,
    ordinal: 270,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'subtotalTax',
    translationId: 'Subtotal_tax',
    isVisible: false,
    ordinal: 280,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'totalTax',
    translationId: 'Total_tax',
    isVisible: false,
    ordinal: 290,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'customerFee',
    translationId: 'Customer_fee',
    isVisible: false,
    ordinal: 310,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'customerFeeReclaim',
    translationId: 'Customer_fee_reclaim',
    isVisible: false,
    ordinal: 320,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'storeFee',
    translationId: 'Store_fee',
    isVisible: false,
    ordinal: 330,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  {
    columnName: 'storeFeeIncludingVat',
    translationId: 'Store_fees_vat',
    isVisible: false,
    ordinal: 340,
    isSortable: true,
    columnType: 'DecimalWithCurrency',
  },
  //#endregion
  //#region location/notes
  {
    columnName: 'customerLocation',
    translationId: 'Customer_location',
    isVisible: false,
    ordinal: 350,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'deliveryLocation',
    translationId: 'Delivery_location',
    isVisible: false,
    ordinal: 360,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'building',
    translationId: 'Building',
    isVisible: false,
    ordinal: 370,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'street',
    translationId: 'Street',
    isVisible: false,
    ordinal: 380,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'town',
    translationId: 'Town',
    isVisible: false,
    ordinal: 390,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'customerOrderNotes',
    translationId: 'Customer_order_notes',
    isVisible: false,
    ordinal: 400,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
];
