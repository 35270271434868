import * as React from 'react';

import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import * as moment from 'moment';
import LoadingMask from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, pure, setDisplayName } from 'recompose';

import {
  getDateTimeFormatter,
  IDateTimeFormatter,
} from '../../../selectors/localeDateTime.selector';

const styles = () =>
  createStyles({
    text: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.6)',
    },
  });
const useStyles = withStyles(styles, {
  name: 'OpeningHours',
});

const mapStateToProps = (state: AppState) => {
  const { time, timePeriodBetweenLocal } = getDateTimeFormatter(state);

  return {
    time,
    timePeriodBetweenLocal,
  };
};

export interface LegendProps {
  loading?: boolean;
}

type Props = IDateTimeFormatter &
  LegendProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps>;

const Legend = compose<Props, LegendProps>(
  setDisplayName('Legend'),
  useStyles,
  connect(mapStateToProps),
  pure
)(({ time, timePeriodBetweenLocal, classes, loading }) => {
  const earlyTime = timePeriodBetweenLocal(
    time('08:00:00') as moment.Moment,
    time('05:00:00') as moment.Moment
  );
  const lateTime = timePeriodBetweenLocal(
    time('18:00:00') as moment.Moment,
    time('01:00:00') as moment.Moment
  );

  return (
    <Typography className={classes.text}>
      {loading ? (
        <LoadingMask height="120px" />
      ) : (
        <>
          <Translate id="Opening_hours_legend" /> {earlyTime} {' | '}
          {lateTime} {' | '}
          <Translate>
            {(translate) => ((translate('All_day') as string) || '').toLowerCase()}
          </Translate>
          {' | '}
          <Translate>
            {(translate) => ((translate('Closed') as string) || '').toLowerCase()}
          </Translate>
        </>
      )}
    </Typography>
  );
});

export default Legend;
