import React, { useEffect, useState } from 'react';

import { Channel } from '@flipdish/api-client-typescript';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Select from '@fd/ui/Select/Select';

import { channelsService } from '../../../services/channels.service';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../WithRouteSearchParams';

type ChannelOption = {
  label: string;
  value: string;
  isGroup?: boolean;
};

type ChannelOptions = {
  channels: ChannelOption[];
};

type InnerProps = MappedState & WithRouteSearchParamsProps<string[]>;
type OuterProps = {
  onSelectChannel: (selectedChannels: string[]) => void;
};
type Props = InnerProps & OuterProps;

const ChannelFilter = ({ appId, history, onSelectChannel, setSearch, translate }: Props) => {
  const [selectedChannels, setSelectedChannels] = useState<ChannelOptions>({
    channels: [],
  });

  const [channelOptions, setChannelOptions] = useState<ChannelOption[]>([]);

  const { data, isPending, isSuccess } = useQuery({
    queryKey: ['channels'],
    queryFn: () => channelsService.getChannels(appId!),

    enabled: appId !== undefined,
  });

  useEffect(() => {
    if (isSuccess && data) {
      const channels = data.Data;

      if (channels) {
        setChannelOptions([allChannelsOption, ...getChannelOptions(channels)]);

        const searchParams = new URLSearchParams(history?.location?.search);
        const channelSearchParams = searchParams.getAll('channel');

        if (channelSearchParams.length) {
          const searchChannels = getChannelOptions(channels).filter((x) =>
            channelSearchParams.includes(x.value)
          );
          setSelectedChannels({ channels: searchChannels });
        } else {
          setSelectedChannels({ channels: [allChannelsOption] });
        }
      }
    }
  }, [isSuccess, data]);

  const allChannelsOption: ChannelOption = {
    label: translate('All_channels') as TranslationId,
    value: 'All',
    isGroup: true,
  };

  const getChannelOptions = (orderChannels: Channel[]) => {
    const parsedChannelOptions: ChannelOption[] = [];

    orderChannels.map((orderChannel) => {
      parsedChannelOptions.push({
        label: translate(
          `${orderChannel.TranslationKey}_${orderChannel.Source}` as TranslationId
        ) as string,
        value: orderChannel.TranslationKey ? orderChannel.TranslationKey : '',
      });
    });

    return parsedChannelOptions;
  };

  const handleChannelsChange = (values: ChannelOption[]) => {
    let channels: string[] = [];
    let channelOptions: ChannelOption[] = [];

    if (values.length) {
      const latestValue = values[values.length - 1];

      if (latestValue.value === 'All') {
        channelOptions = [latestValue];
      } else {
        channelOptions = values.filter((store) => store.value !== 'All');
        channels = channelOptions.map((v) => v.value);
      }
    }

    setSelectedChannels({
      channels: channelOptions,
    });
    setSearch(channels);
    onSelectChannel(channels);
  };

  return (
    <Select
      isClearable
      isLoading={isPending}
      isMulti
      onChange={handleChannelsChange}
      options={channelOptions}
      placeholder={translate('All_channels') as TranslationId}
      TextFieldProps={{
        fdKey: 'channels-dropdown',
        label: translate('Channels') as TranslationId,
        name: 'channel-filter',
      }}
      value={selectedChannels.channels}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale, currentApp } = state;
  return {
    appId: currentApp.AppId,
    translate: getTranslate(locale),
  };
}

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'channel',
  }),
  connect(mapStateToProps)
)(ChannelFilter);
