import * as React from 'react';

import { type Theme } from '@mui/material/styles';
import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';

export type TextFieldProps = MuiTextFieldProps & {
  fdKey: string;
  loading?: boolean;
  minWidth?: 200 | 315 | 'auto';
  variant?: 'standard' | 'outlined';
};

type StyleProps = {
  minWidth: string;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    minWidth: (props) => props.minWidth,
    maxWidth: '100%',
  },
}));

const TextField = ({
  fdKey,
  loading: isLoading,
  minWidth = 'auto',
  variant = 'standard',
  ...rest
}: TextFieldProps) => {
  const constrainMinWidth = (minWidth: TextFieldProps['minWidth']) => {
    if (minWidth === 'auto') {
      return 'auto';
    }

    // Constrain minWidth to a maximum of 100% of the container width
    return `min(${minWidth}px, 100%)`;
  };

  const constrainedMinWidth = constrainMinWidth(minWidth);
  const classes = useStyles({ minWidth: constrainedMinWidth });

  if (isLoading) {
    return <Skeleton width="100%" height="32px" />;
  }

  return (
    <MuiTextField
      {...rest}
      className={classes.root}
      inputProps={{
        ...rest.inputProps,
        'data-fd': fdKey,
      }}
      variant={variant}
    />
  );
};

export default TextField;
