import React, { useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Select from '@fd/ui/Select/Select';

import { getOAuthApps } from '../../../../../Developers/actions';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginTop: -16,
  },
}));

type Props = { isDisabled?: boolean } & MappedState & MappedDispatch;

const OauthAppField = ({ isDisabled, isLoading, oauthApps, loadOAuthApps, translate }: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (!(oauthApps && oauthApps.length)) {
      loadOAuthApps();
    }
  }, []);

  const validate = (value: string) => {
    if (!value) {
      return <Translate id="Required" />;
    }
  };

  return (
    <Field name="OAuthAppId" validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, submitCount } = form;
        const fieldError = errors['OAuthAppId'] as string | undefined;

        type Option = { value: string; label: string };

        const options: Option[] = oauthApps.map((option) => ({
          value: option.OauthAppId!,
          label: option.OauthAppName!,
        }));

        const selectedValue = options.find((option: Option) => option.value === field.value);

        const showError =
          !!fieldError && !selectedValue && ((submitCount > 0) as boolean | undefined);

        return (
          <FormItemLayout label={translate('OAuth_app')}>
            <div className={classes.selectWrapper}>
              <Select
                dataFd="app-select-filter-oauth-app-id"
                placeholder={
                  isLoading
                    ? (translate('Loading') as string)
                    : (translate('Select_OAuth_app') as string)
                }
                TextFieldProps={{
                  fdKey: 'app-select-dropdown-oauth-app-id',
                  variant: 'standard',
                }}
                value={selectedValue}
                options={options}
                onChange={(option: Option) => form.setFieldValue('OAuthAppId', option.value, false)}
                isLoading={isLoading}
                fieldError={showError ? fieldError : undefined}
                isDisabled={isDisabled}
              />
            </div>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadOAuthApps: () => {
    dispatch(getOAuthApps());
  },
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const {
    developers: { oauthApps, isLoading },
    locale,
  } = state;
  return {
    translate: getTranslate(locale),
    oauthApps,
    isLoading,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OauthAppField);
