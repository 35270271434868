import React from 'react';

import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { DateTimeUtils, dateTimeUtils } from '../../selectors/localeDateTime.selector';
import InfoIcon from '../Tooltip/InfoIcon';
import Tooltip from '../Tooltip/Tooltip';

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    dtUtils: dateTimeUtils(state),
  };
};

// #region DateTime

type DateTimeProps = {
  value?: Date;
  dateOnly?: boolean;
  timeOnly?: boolean;
  format?: string;
  children?: (props: { value: Date; format: string; dtUtils: DateTimeUtils }) => React.ReactNode;
};
const DateTime = compose<DateTimeProps & MappedState, DateTimeProps>(connect(mapStateToProps))((
  props
) => {
  const { children, dtUtils, value = new Date(), dateOnly, timeOnly, format } = props;

  let dateTimeFormat: string;
  switch (true) {
    case !!format:
      dateTimeFormat = format!;
      break;
    case dateOnly:
      dateTimeFormat = dtUtils.LOCAL_DATE_FORMAT;
      break;
    case timeOnly:
      dateTimeFormat = dtUtils.LOCAL_TIME_FORMAT;
      break;
    default:
      dateTimeFormat = dtUtils.LOCAL_DATE_TIME_FORMAT;
      break;
  }

  const local = dtUtils.utcToZonedTime(value, dtUtils.userIanaTimeZone);

  if (typeof children === 'function') {
    return <>{children({ dtUtils, value: local, format: dateTimeFormat })}</>;
  }

  return <>{dtUtils.format(local, dateTimeFormat)}</>;
});
// #endregion

export { DateTime };

// #region DateTimeZone
type DateTimeTimeZoneProps = {
  dataFd: string;
  ianaTimeZone?: string;
  value: Date;
  dateOnly?: boolean;
  timeOnly?: boolean;
  format?: string;
  children?: (props: { value: Date; format: string; dtUtils: DateTimeUtils }) => React.ReactNode;
};
const DateTimeZone = compose<DateTimeTimeZoneProps & MappedState, DateTimeTimeZoneProps>(
  connect(mapStateToProps)
)((props) => {
  const { dataFd, ianaTimeZone, ...rest } = props;

  if (!ianaTimeZone || !rest.value) {
    return <DateTime {...rest} />;
  }

  const { children, dtUtils, value, dateOnly, timeOnly, format } = props;

  let dateTimeFormat: string;
  switch (true) {
    case !!format:
      dateTimeFormat = format!;
      break;
    case dateOnly:
      dateTimeFormat = dtUtils.LOCAL_DATE_FORMAT;
      break;
    case timeOnly:
      dateTimeFormat = dtUtils.LOCAL_TIME_FORMAT;
      break;
    default:
      dateTimeFormat = dtUtils.LOCAL_DATE_TIME_FORMAT;
      break;
  }

  const local = dtUtils.utcToZonedTime(value, dtUtils.userIanaTimeZone);
  const storeLocal = dtUtils.utcToZonedTime(value, ianaTimeZone);

  const tooltipMessage = dtUtils.isEqual(local, storeLocal) ? '' : ` ${dtUtils.format(storeLocal)}`;

  return (
    <>
      {typeof children === 'function'
        ? children({ dtUtils, value: local, format: dateTimeFormat })
        : dtUtils.format(value, dateTimeFormat)}

      {tooltipMessage && (
        <Tooltip
          id={'Store_timezone_tooltip'}
          messageId={'Store_timezone_tooltip'}
          messageData={{ timezone: ianaTimeZone, storeTime: tooltipMessage }}
          fdKey={dataFd}
        >
          <Box component="span" ml={0.5}>
            <InfoIcon size={13} verticalAlign="text-top" />
          </Box>
        </Tooltip>
      )}
    </>
  );
});

// #endregion

export default DateTimeZone;
