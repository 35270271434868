import { TableColumns } from '../types';

export const payoutTableMeta: TableColumns[] = [
  {
    columnName: 'CreatedDate',
    translationId: 'Date',
    columnType: 'Date',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'DestinationBank',
    translationId: 'Bank_account',
    columnType: 'Subtext',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'PayoutStatus',
    translationId: 'Status',
    columnType: 'Status',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'OnlineSales',
    translationId: 'Online_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 30,
  },
  {
    columnName: 'OnlineSalesTax',
    translationId: 'Online_sales_tax',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'DeliveryCharges',
    translationId: 'Delivery_charges',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
  {
    columnName: 'TipsOnOnlineSales',
    translationId: 'Tips_on_online_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 60,
  },
  {
    columnName: 'TotalRevenue',
    translationId: 'Total_revenue',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 70,
  },
  {
    columnName: 'RefundsOnOnlineSales',
    translationId: 'Refunds_on_online_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 80,
  },
  {
    columnName: 'CashFeesChargedToCustomer',
    translationId: 'Cash_fees_charged_to_customer',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 90,
  },
  {
    columnName: 'TotalRevenueAdjustments',
    translationId: 'Total_revenue_adjustments',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 100,
  },
  {
    columnName: 'OnlineSalesFees',
    translationId: 'Online_sales_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 110,
  },
  {
    columnName: 'CashSalesFees',
    translationId: 'Cash_sales_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 120,
  },
  {
    columnName: 'RefundedFees',
    translationId: 'Refunded_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 130,
  },
  {
    columnName: 'VATonFees',
    translationId: 'VAT_on_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 140,
  },
  {
    columnName: 'TotalFees',
    translationId: 'Total_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 150,
  },
  {
    columnName: 'ChargebackAmount',
    translationId: 'Chargeback_amount',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 160,
  },
  {
    columnName: 'RefundedFeesOnChargebacks',
    translationId: 'Refunded_fees_on_chargebacks',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 170,
  },
  {
    columnName: 'TotalChargebackCost',
    translationId: 'Total_chargebacks_cost',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 180,
  },
  {
    columnName: 'TotalOtherCharges',
    translationId: 'Total_other_charges',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 190,
  },
  {
    columnName: 'OpeningBalance',
    translationId: 'Opening_balance',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 200,
  },
  {
    columnName: 'ClosingBalance',
    translationId: 'Closing_balance',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 210,
  },
  {
    columnName: 'PayoutAmount',
    translationId: 'Payout_amount',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 220,
  },
  {
    columnName: 'PosSalesAmount',
    translationId: 'POS_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 230,
  },
  {
    columnName: 'PosSalesTax',
    translationId: 'POS_sales_tax',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 240,
  },
  {
    columnName: 'TipsOnPosSales',
    translationId: 'Tips_on_POS_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 250,
  },
  {
    columnName: 'PosSalesRefundedAmount',
    translationId: 'Refunds_on_POS_sales',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 260,
  },
  {
    columnName: 'PosSalesFees',
    translationId: 'POS_sales_fees',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 270,
  },
  {
    columnName: 'PosSalesChargebackAmount',
    translationId: 'POS_chargeback_amount',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 280,
  },
];

export const payoutsDefaultColumns = ['CreatedDate', 'DestinationBank'];

export const payoutsPreSelectedColumns = ['PayoutStatus', 'PayoutAmount'];
