import { useEffect } from 'react';

import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  appName: state.currentApp.Name,
  translate: getTranslate(state.locale),
  activeLanguage: getActiveLanguage(state.locale),
});

type Props = {
  children: string;
} & ReturnType<typeof mapStateToProps>;

const DEFAULT_TITLE = 'Flipdish Portal';

const DocumentTitle = ({ children, appName, translate, activeLanguage }: Props) => {
  useEffect(() => {
    document.title = `${translate(children, undefined, {
      missingTranslationMsg: children,
    })} | ${appName} | Flipdish`;
    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [activeLanguage]);

  return null;
};

export default connect(mapStateToProps)(DocumentTitle);
