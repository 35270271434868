import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../../helpers/validation';
import TextField from '../../../../../ui/TextField/TextField';
import { countryCodeToCountry } from '../../banking.selectors';
import FormItemLayout from '../FormItemLayout';
import CountrySelectorFormField from './CountrySelectorFormField';
import FieldWrapper from './FieldWrapper';

const validate = (value: string, fieldName: string) => {
  return (
    formikValidate.required(value) || formikValidate.max_string_custom(100)(value.length, fieldName)
  );
};

export type Props = {
  nonVATCountry: string[];
  country: string;
};

const BankHolderAddressFormFields = (props: Props) => {
  const { nonVATCountry, country } = props;
  const editView = window.location.pathname.endsWith('/edit');

  return (
    <FormItemLayout label={<Translate id="Account_holder_address" />}>
      <FieldWrapper>
        <Field
          name={'AccountHolderAddress'}
          validate={(value: string) => validate(value, 'Address')}
        >
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = errors[field.name] as string | undefined;
            const showError = editView
              ? !!fieldError
              : !!fieldError && (touched[field.name] as boolean | undefined);

            return (
              <TextField
                {...field}
                fdKey={'Bank_account_holder_address'}
                label={<Translate id="Address" />}
                autoComplete="off"
                fullWidth
                multiline
                maxRows="3"
                disabled={isSubmitting}
                error={showError}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={showError ? fieldError : undefined}
              />
            );
          }}
        </Field>
      </FieldWrapper>

      <FieldWrapper>
        {!editView && (
          <CountrySelectorFormField
            name={'AccountHolderCountryCode'}
            fdKey={'Bank_account_holder_address_county_code'}
          />
        )}
        {editView && (
          <Field name={'BankCountryCode'}>
            {({ field }: FieldProps) => {
              return (
                <TextField
                  defaultValue={countryCodeToCountry(field.value)}
                  fdKey={'Bank_address_country_code'}
                  label={<Translate id="Country" />}
                  fullWidth
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          </Field>
        )}
      </FieldWrapper>
      {nonVATCountry.includes(country) ? (
        ''
      ) : (
        <FieldWrapper>
          <Field name={'VatNumber'}>
            {({ field, form }: FieldProps) => {
              const { errors, touched, isSubmitting } = form;
              const fieldError = errors[field.name] as string | undefined;
              const showError = editView
                ? !!fieldError
                : !!fieldError && (touched[field.name] as boolean | undefined);

              return (
                <TextField
                  {...field}
                  fdKey={'Bank_account_holder_address_vat'}
                  label={<Translate id="Vat_number_EU_only" />}
                  autoComplete="off"
                  fullWidth
                  disabled={isSubmitting}
                  error={showError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError ? <Translate id={fieldError as TranslationId} /> : undefined
                  }
                  margin="none"
                />
              );
            }}
          </Field>
        </FieldWrapper>
      )}
    </FormItemLayout>
  );
};

export default BankHolderAddressFormFields;
