import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  circular: {
    width: '100%',
    textAlign: 'center' as any,
  },
});

export interface IProps {
  classes?: any;
  scrollDown?: () => any;
}

export type Props = IProps;

class AppLoadingCircularProgress extends React.Component<Props, any> {
  public componentDidMount() {
    if (this.props.scrollDown) {
      this.props.scrollDown();
    }
  }

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.circular}>
        <CircularProgress data-fd={'circular-progress'} style={{ margin: '10px' }} size={50} />
      </div>
    );
  }
}

const EnhancedComponent = withStyles(styles, {
  name: 'AppLoadingCircularProgress',
  withTheme: true,
})(AppLoadingCircularProgress);

export { EnhancedComponent as AppLoadingCircularProgress };
