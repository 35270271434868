import React, { useEffect, useMemo, useState } from 'react';

import { Subscription } from '@flipdish/api-client-typescript';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FormattedCurrency } from 'fd-react-intl';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import GenericTable from '@fd/ui/GenericTable/GenericTable';

import { productTableMeta } from '../../data/ProductsMeta';
import { canCancelProduct } from '../../helpers';
import { PaymentType } from '../../types';
import ActionsButton from './ActionsButton';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 500,
  },
  breakWord: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  modalButton: {
    marginTop: '2rem',
    alignSelf: 'end',
  },
}));

const mapPaymentTypeToString = (paymentType) => {
  switch (paymentType) {
    case PaymentType.DAY:
      return 'Daily';
    case PaymentType.MONTH:
      return 'Monthly';
    case PaymentType.WEEK:
      return 'Weekly';
    case PaymentType.YEAR:
      return 'Yearly';
    default:
      return undefined;
  }
};

type Props = { subscription: Subscription | undefined; isLoading: boolean } & MappedState;

const ProductTable: React.FC<React.PropsWithChildren<Props>> = ({
  subscription,
  languageCode,
  translate,
  isLoading,
  appId,
}: Props) => {
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: any }>();
  const [productTableData, setProductTableData] = useState<any>([]);
  const classes = useStyles();

  const createProductRows = () => {
    return subscription?.Products?.map((product) => {
      const paymentType = mapPaymentTypeToString(product?.PaymentFrequency);
      return {
        Product: (
          <Typography variant="body2" className={clsx(classes.boldText, classes.breakWord)}>
            {product?.Name}
          </Typography>
        ),
        Qty: (
          <Typography variant="body2" className={classes.breakWord}>
            {product?.Quantity}
          </Typography>
        ),
        Amount: (
          <>
            <Typography variant="body2" className={classes.breakWord}>
              {' '}
              <FormattedCurrency
                value={product?.PriceTotal as number}
                locales={languageCode}
                currency={subscription?.Currency.toString() || 'EUR'}
              />
            </Typography>
            {product?.PricePerUnit && product?.Quantity > 1 && (
              <Typography variant="caption" className={classes.breakWord}>
                <FormattedCurrency
                  value={product?.PricePerUnit}
                  locales={languageCode}
                  currency={subscription?.Currency.toString() || 'EUR'}
                />{' '}
                {translate('each')}
              </Typography>
            )}
          </>
        ),
        PaymentType: paymentType && (
          <>
            {' '}
            <Typography variant="body2" className={classes.breakWord}>
              {paymentType}
            </Typography>{' '}
            <Typography variant="caption" className={classes.breakWord}>
              {translate('Recurring')}
            </Typography>
          </>
        ),
        Actions: (
          <ActionsButton
            refreshSubscription={() => product.ProductId}
            subscriptionId={subscription.SubscriptionId}
            productId={product.ProductId}
          />
        ),
      };
    });
  };

  const tableMeta = useMemo(() => {
    let tableMeta = productTableMeta;
    const isActiveSubscription = subscription?.Status === Subscription.StatusEnum.Active;
    const showActionColumn =
      isActiveSubscription &&
      subscription?.Products?.some((product) => canCancelProduct(product.ProductId));
    if (!showActionColumn) {
      tableMeta = tableMeta.map((meta) => {
        if (meta.columnName === 'Actions') {
          return { ...meta, isVisible: false };
        }
        return meta;
      });
    }

    return tableMeta;
  }, [subscription, productTableMeta]);

  useEffect(() => {
    setColumns(
      new Set(
        tableMeta
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      tableMeta.reduce<{ [key: string]: any }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
    setProductTableData(createProductRows());
  }, [subscription, isLoading, tableMeta]);

  const setAllColumns = (col) => {
    setColumns(col);
  };

  return (
    <GenericTable
      maxCellWidthProp="300px"
      tableLayoutProp="auto"
      showTitle={false}
      rowsPerPage={100}
      page={0}
      loading={isLoading}
      AppId={appId || ''}
      columns={columns}
      data={productTableData ?? [{}]}
      languageCode={languageCode}
      meta={meta}
      metadata={tableMeta}
      onSortKeyPressed={() => undefined}
      order={'asc'}
      orderBy={'asc'}
      setColumns={setAllColumns}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp?.AppId,
    translate: getTranslate(state),
    languageCode: getActiveLanguage(state.locale),
  };
};

export default connect(mapStateToProps)(ProductTable);
