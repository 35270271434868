import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[600],
      },
    },
  },
  label: {
    lineHeight: 'inherit',
  },
}));

export type Props = {
  dataFd?: string;
  helperText?: string;
  is24hr?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isFullWidth?: boolean;
  label?: string;
  onChange: (value: string) => void;
  value: string; // format: 'HH:mm'
  variant?: 'outlined' | 'standard';
};

export default function TimePicker(props: Props) {
  const classes = useStyles();
  const {
    dataFd = 'time-picker',
    helperText = '',
    is24hr = true,
    isDisabled = false,
    isError = false,
    isFullWidth = true,
    label = '',
    onChange,
    value,
    variant = 'outlined',
  } = props;

  const handleChange = (newValue: moment.Moment) => {
    if (!newValue) {
      return onChange('');
    }

    const formattedTime = newValue.format('HH:mm');
    if (formattedTime === 'Invalid date') {
      return onChange('');
    }

    onChange(formattedTime);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        ampm={!is24hr}
        className={classes.root}
        data-fd={dataFd}
        disabled={isDisabled}
        label={label}
        onChange={handleChange}
        slotProps={{
          textField: {
            error: isError,
            fullWidth: isFullWidth,
            helperText: helperText,
            InputLabelProps: {
              className: classes.label,
              shrink: true,
            },
            variant: variant,
          },
        }}
        // 1900-01-01 is arbitrary but is needed for moment, the time value is what matters
        value={value ? moment(`1900-01-01 ${value}`, 'YYYY-MM-DD HH:mm') : null}
      />
    </LocalizationProvider>
  );
}
