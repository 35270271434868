import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import Skeleton from 'react-loading-skeleton';

const styles = (theme: Theme) =>
  createStyles({
    cardWrapper: {
      width: '50%',
      margin: '12px',
      [theme.breakpoints.down('md')]: { width: '100%' },
    },
    cardContent: {
      padding: '24px',
    },
  });

type OAuthAppCardLoadingProps = {
  fullWidth?: boolean;
};

type Props = WithStyles<typeof styles> & OAuthAppCardLoadingProps;
const OAuthAppCardLoading: React.FC<React.PropsWithChildren<Props>> = ({ classes }: Props) => {
  return (
    <Card className={classes.cardWrapper}>
      <CardHeader
        avatar={<Skeleton width={40} height={40} />}
        title={<Skeleton width={'100%'} height={20} />}
      />
      <CardContent className={classes.cardContent}>
        <Skeleton width={'100%'} height={15} />
        <Skeleton width={'100%'} height={15} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(OAuthAppCardLoading);
