import React, { useMemo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StopIcon from '@mui/icons-material/Stop';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { formatCurrency, formatNumber } from 'fd-react-intl';
import { TranslateFunction } from 'react-localize-redux';
// eslint-disable-next-line import/named
import { DataKey, TooltipFormatter } from 'recharts';

const useTooltipStyles = makeStyles({
  tooltipSingle: {
    minHeight: '60px',
    padding: '6px',
    minWidth: '180px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    minHeight: '120px',
    padding: '6px',
    minWidth: '180px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '12px',
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    letterSpacing: '0.33px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  subtitle2: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  info: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  trendIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    height: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#36d88e',
  },
  green: { color: '#36d88e' },
  red: { color: '#ff395f' },
  blue: { color: '#4f94f7' },
});
type TooltipPayload = {
  name: string;
  value: string | number | ReadonlyArray<string | number>;
  unit?: string;
  color?: string;
  fill?: string;
  dataKey?: DataKey;
  formatter?: TooltipFormatter;
  payload?: {
    currentValue: number;
    index: number;
    label: TranslationId;
    previousValue: number;
    x: TranslationId;
    y: number;
    y2: number;
    z: number;
  };
};

type CustomToolTipProps = {
  active?: boolean;
  currency?: string;
  payload?: ReadonlyArray<TooltipPayload>;
  dateString: string;
  section?: string;
  translate: TranslateFunction;
  activeLanguage: string;
};
const CustomTooltip = (props: CustomToolTipProps) => {
  const classes = useTooltipStyles();
  const { active, activeLanguage, currency, payload, section, dateString, translate } = props;

  const formatNumberOrCurrency = useMemo(
    () =>
      (value, style = 'currency', minimumFractionDigits = 2, maximumFractionDigits = 2) => {
        const amount = value ? value : 0;
        if (currency && style === 'currency') {
          return formatCurrency(amount, activeLanguage, {
            currency: currency!,
            minimumFractionDigits,
            maximumFractionDigits,
          });
        }
        if (currency) {
          return formatNumber(amount, activeLanguage, {
            style,
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
          });
        } else {
          return formatNumber(amount, activeLanguage, {
            minimumFractionDigits,
            maximumFractionDigits,
          });
        }
      },
    []
  );

  const getTrendInfo = (data: TooltipPayload['payload']) => {
    if (data) {
      const { currentValue, y2, previousValue } = data;
      if (previousValue === null) {
        return { trend: null, difference: null };
      }
      if (currentValue === null) {
        // if previous is null, we need to use y2 (incomplete data)
        if (previousValue === 0) {
          return { trend: 0, difference: y2 - previousValue };
        }
        return { trend: (y2 - previousValue) / previousValue, difference: y2 - previousValue };
      } else {
        if (previousValue === 0) {
          return { trend: 0, difference: currentValue - previousValue };
        }
        return {
          trend: (currentValue - previousValue) / previousValue,
          difference: currentValue - previousValue,
        };
      }
    }
  };

  const progressionIcon = (type, isPositive?) => {
    switch (type) {
      case 'positive': {
        const positiveTrend = clsx({
          [classes.trendIcon]: true,
          [classes.green]: isPositive,
          [classes.red]: !isPositive,
        });
        return (
          <span className={positiveTrend}>
            <ArrowDropUpIcon color="inherit" />
          </span>
        );
      }
      case 'negative': {
        const negativeTrend = clsx({
          [classes.trendIcon]: true,
          [classes.green]: isPositive,
          [classes.red]: !isPositive,
        });
        return (
          <span className={negativeTrend}>
            <ArrowDropDownIcon color="inherit" />
          </span>
        );
      }
      case 'neutral': {
        const neutralTrend = clsx({
          [classes.trendIcon]: true,
          [classes.blue]: true,
        });
        return (
          <span className={neutralTrend}>
            <StopIcon fontSize="small" color="inherit" />
          </span>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderTrendIcon = (value: number) => {
    const negativeTrends = ['rejectedOrders', 'rejectedOrderAmount', 'refundedOrders'];
    if (value < 0) {
      const isPositive = negativeTrends.indexOf(section!) !== -1 ? true : false;
      return progressionIcon('negative', isPositive);
    }
    if (value > 0) {
      const isPositive = negativeTrends.indexOf(section!) !== -1 ? false : true;
      return progressionIcon('positive', isPositive);
    }
    return progressionIcon('neutral');
  };

  if (active && payload && payload.length && payload[0].payload) {
    const progression = getTrendInfo(payload[0].payload);
    return (
      <div className={progression?.difference !== null ? classes.tooltip : classes.tooltipSingle}>
        <div className={classes.title}>
          {translate(payload[0].payload.label).toString().toUpperCase()}
        </div>

        <div style={{ margin: '12px 8px 0' }}>
          <div className={classes.subtitle}>{dateString.split(' vs ')[0]}</div>
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <span className={classes.info}>
              {formatNumberOrCurrency(payload[0].payload.currentValue)}
            </span>
          </div>
        </div>
        {progression && progression?.difference !== null && (
          <div style={{ margin: '12px 8px' }}>
            <div className={classes.subtitle}>{translate('Difference_compared_to')}</div>
            <div className={classes.subtitle}>{dateString.split(' vs ')[1]}</div>
            <div className={classes.info}>
              <span>{progression && renderTrendIcon(progression?.difference)}</span>
              <span>
                {progression.trend > 0 ? '+' : ''}
                {formatNumberOrCurrency(progression.trend, 'percent', 0, 0)}
              </span>
              <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'normal' }}>
                {` (${progression.difference > 0 ? '+' : ''}${formatNumberOrCurrency(
                  progression.difference,
                  'currency',
                  2,
                  2
                )})`}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
