import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

export type ArchiveDialogProps = {
  message: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  translate: TranslateFunction;
};

export const ArchiveDialog = (props: ArchiveDialogProps): JSX.Element => {
  const { message, open, onCancel, onConfirm, translate } = props;

  const classes = useStyles();

  return (
    <Dialog
      title={translate('Products_ArchiveProductDialog_Title') as string}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel-archive"
          fdKey="cancel-archive"
          onClick={onCancel}
          variant="text"
        >
          {translate('Products_ArchiveProductDialog_Cancel')}
        </Button>,
        <Button key="confirm-archive" fdKey="confirm-archive" onClick={onConfirm}>
          {translate('Archive')}
        </Button>,
      ]}
    >
      <Typography className={classes.dialogText} variant="body2">
        {message}
      </Typography>
    </Dialog>
  );
};
