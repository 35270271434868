import React from 'react';

import { OrderItem } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

type DepositReturnFee = {
  fee: number;
  quantity: number;
};

type Props = {
  currency: string;
  languageCode: string;
  orderItems: OrderItem[];
  translate: TranslateFunction;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5, 0, 1.25),
  },
  dividerLine: {
    listStyleType: 'none',
    margin: theme.spacing(1, 0),
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(0.25, 0),
  },
  listItemText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  price: {
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'right',
  },
}));

export function DepositReturnFeeSummary(props: Props): JSX.Element | null {
  const classes = useStyles();
  const { currency, languageCode, orderItems, translate } = props;

  const getDRFs = () => {
    const feesWithCount: DepositReturnFee[] = [];
    const fees: number[] = [];

    orderItems.forEach((orderItem) => {
      if (orderItem.DepositReturnFee) {
        fees.push(orderItem.DepositReturnFee);
      }

      if (orderItem.OrderItemOptions) {
        orderItem.OrderItemOptions.forEach((option) => {
          if (option.DepositReturnFee) {
            fees.push(option.DepositReturnFee);
          }
        });
      }
    });

    fees.forEach((fee) => {
      const existingFee = feesWithCount.find((drfFee) => drfFee.fee === fee);
      if (existingFee) {
        existingFee.quantity += 1;
      } else {
        feesWithCount.push({ fee, quantity: 1 });
      }
    });

    return feesWithCount;
  };

  const calculateTotalDRF = () => {
    let totalDepositReturnFee = 0;

    orderItems.forEach((item) => {
      if (item.DepositReturnFee) {
        totalDepositReturnFee += item.DepositReturnFee;
      }

      item.OrderItemOptions?.forEach((option) => {
        if (option.DepositReturnFee) {
          totalDepositReturnFee += option.DepositReturnFee;
        }
      });
    });

    // this makes sure it is calculated to the nearest 2 decimal places
    return Math.round(totalDepositReturnFee * 100) / 100;
  };

  const renderDRFListItems = (): JSX.Element[] => {
    const depositReturnFees = getDRFs();

    return depositReturnFees.map((deposit) => {
      const drfLabel = `${deposit.quantity} x ${deposit.fee.toLocaleString(languageCode, {
        style: 'currency',
        currency: currency,
      })}`;
      const drfLocalizedTotal = (deposit.fee * deposit.quantity).toLocaleString(languageCode, {
        style: 'currency',
        currency: currency,
      });

      return (
        <ListItem className={classes.listItem} key={drfLabel}>
          <ListItemText
            primary={drfLabel}
            classes={{
              primary: classes.listItemText,
            }}
          />
          <ListItemText
            primary={drfLocalizedTotal}
            classes={{
              primary: classes.price,
              root: classes.price,
            }}
          />
        </ListItem>
      );
    });
  };

  const renderDRFTotalListItem = (): JSX.Element => {
    const drfLocalizedTotal = totalDepositReturnFee.toLocaleString(languageCode, {
      style: 'currency',
      currency: currency,
    });

    return (
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={translate('Total_Deposit_Return_Fee')}
          classes={{
            primary: classes.listItemText,
          }}
        />
        <ListItemText
          primary={drfLocalizedTotal}
          classes={{
            primary: classes.price,
            root: classes.price,
          }}
        />
      </ListItem>
    );
  };

  const totalDepositReturnFee = calculateTotalDRF();

  if (totalDepositReturnFee === 0) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Typography variant="caption">{translate('Deposit_return_fee')}</Typography>

      <Divider component={'li'} className={classes.dividerLine} />

      <List className={classes.list}>
        {renderDRFListItems()}
        {renderDRFTotalListItem()}
      </List>
    </Box>
  );
}
