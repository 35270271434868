import { MenuSummary } from '@flipdish/api-client-typescript';
import { useQuery } from '@tanstack/react-query';

import { getMenusKey, menusService } from '../services/menus.service';

export function useMenus(
  appId: string,
  enabled: boolean,
  refetchOnMount = false
): {
  isLoading: boolean;
  data?: MenuSummary[];
  isError: boolean;
} {
  const { isLoading, data, isError } = useQuery({
    queryKey: [getMenusKey, appId],
    queryFn: () => menusService.getMenus(appId),
    gcTime: 1000 * 30,
    enabled: enabled,
    refetchOnMount: refetchOnMount,
  });
  return {
    isLoading,
    data,
    isError,
  };
}
