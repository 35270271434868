import React from 'react';

import Check from '@mui/icons-material/Check';
import LoadingIcon from '@mui/material/CircularProgress';
import SnackbarContent from '@mui/material/SnackbarContent';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
    width: '20px !important',
    height: '20px !important',
    color: '#fff',
  },
  connected: {
    backgroundColor: '#2ad07f',
  },
  disconnected: {
    backgroundColor: '#ff395f',
  },
  root: {
    minWidth: '187px',
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
    },
  },
}));

export type Props = {
  message?: React.ReactElement | string;
  variant: keyof typeof variantIcon;
  className?: string;
  // ref: React.RefObject<HTMLInputElement>;
};

const variantIcon = {
  connected: Check,
  disconnected: LoadingIcon,
};

const SnackBarWrapper = React.forwardRef((props: Props, ref) => {
  const { variant, message, ...other } = props;
  const Icon = variantIcon[variant];
  const classes = useStyles({}); // TS complaining

  return (
    <SnackbarContent
      ref={ref as any}
      classes={{ root: classes.root }}
      className={classes[variant]}
      message={
        <div id="client-snackbar" className={classes.message}>
          <Icon className={classes.iconVariant} />
          {message}
        </div>
      }
      {...other}
    />
  );
});

export default SnackBarWrapper;
