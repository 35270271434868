import React, { useEffect, useState } from 'react';

import { InvoiceItem, InvoicePeriod, Subscription } from '@flipdish/api-client-typescript';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FormattedCurrency } from 'fd-react-intl';
import { groupBy, sortBy, toArray } from 'lodash';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import GenericTable from '@fd/ui/GenericTable/GenericTable';

import { dateTimeUtils } from '../../../../selectors/localeDateTime.selector';
import { TableColumns } from '../../../Finance/Payouts/types';
import {
  upcomingInvoiceNoTaxTableMeta,
  upcomingInvoiceTableMeta,
} from '../../data/UpcomingInvoiceMeta';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 500,
  },
  cellText: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    color: '#000000',
  },
}));

type Props = { subscription: Subscription | undefined; isLoading: boolean } & MappedState;

const UpcomingInvoiceTable: React.FC<React.PropsWithChildren<Props>> = ({
  subscription,
  languageCode,
  translate,
  isLoading,
  appId,
  dtUtils,
}: Props) => {
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: any }>();
  const [upcomingInvoiceTableData, setUpcomingInvoiceTableData] = useState<any>([]);
  const classes = useStyles();

  const createUpcomingInvoiceRows = () => {
    if (!subscription) return null;
    const upcomingInvoiceDiscounts =
      subscription &&
      subscription?.UpcomingInvoiceDiscounts?.length !== 0 &&
      subscription?.UpcomingInvoiceDiscounts?.map((discount) =>
        discount.Name && discount.Amount
          ? { Description: discount.Name, Amount: -discount.Amount }
          : {}
      );
    const groupedItemsByPeriod = toArray(
      groupBy(
        subscription.UpcomingInvoice?.Items,
        (item) => `${item.Period.Start}-${item.Period.End}`
      )
    );
    const rowsData: (InvoiceItem | InvoicePeriod)[] = [];
    sortBy(groupedItemsByPeriod, (groupedItem) => groupedItem?.[0]?.Period?.Start)?.forEach(
      (groupedItem) => {
        // add active period row before upcoming item based on grouping
        groupedItem?.[0] && rowsData.push(groupedItem[0].Period);
        groupedItem?.forEach((upcomingItem) => rowsData.push(upcomingItem));
      }
    );
    if (upcomingInvoiceDiscounts) {
      rowsData.push(...(upcomingInvoiceDiscounts as any));
    }

    return rowsData?.map((row) => {
      if ('Start' in row || 'End' in row) {
        const start = row.Start ? dtUtils.format(row.Start, dtUtils.LOCAL_DATE_FORMAT) : '';
        const end = row.End ? dtUtils.format(row.End, dtUtils.LOCAL_DATE_FORMAT) : '';
        return {
          hasTitle: true,
          AmountIncTax: '',
          Amount: '',
          Tax: '',
          UnitPrice: '',
          Qty: '',
          Product: (
            <Typography
              data-fd="active_period"
              variant="caption"
              className={clsx(classes.boldText, classes.cellText)}
            >
              {translate('Active_Period')}: {start} - {end}
            </Typography>
          ),
        };
      } else if ('Amount' in row) {
        return {
          Product: (
            <Typography variant="body2" className={clsx(classes.cellText)}>
              {row?.Description}
            </Typography>
          ),
          Qty: (
            <Typography variant="body2" className={classes.cellText}>
              {row?.Quantity}
            </Typography>
          ),
          UnitPrice: (row?.UnitAmountExcludingTax || row?.UnitAmountExcludingTax === 0) && (
            <>
              <Typography variant="body2" className={classes.cellText}>
                {' '}
                <FormattedCurrency
                  value={row.UnitAmountExcludingTax}
                  locales={languageCode}
                  currency={subscription?.Currency.toString() ?? 'EUR'}
                />
              </Typography>
            </>
          ),
          Tax: (row?.Tax || row?.Tax === 0) && (
            <>
              <Typography variant="body2" className={classes.cellText}>
                {' '}
                <FormattedCurrency
                  value={row.Tax}
                  locales={languageCode}
                  currency={subscription?.Currency.toString() ?? 'EUR'}
                />
              </Typography>
            </>
          ),
          AmountIncTax: (row?.Amount || row?.Amount === 0) && (
            <>
              <Typography variant="body2" className={classes.cellText}>
                {' '}
                <FormattedCurrency
                  value={row.Amount}
                  locales={languageCode}
                  currency={subscription?.Currency.toString() ?? 'EUR'}
                />
              </Typography>
            </>
          ),
          Amount: (row?.Amount || row?.Amount === 0) && (
            <>
              <Typography variant="body2" className={classes.cellText}>
                {' '}
                <FormattedCurrency
                  value={row.Amount}
                  locales={languageCode}
                  currency={subscription?.Currency.toString() ?? 'EUR'}
                />
              </Typography>
            </>
          ),
        };
      }
    });
  };

  const total = subscription?.UpcomingInvoice?.Amount;
  const totalExcludingTax = subscription?.UpcomingInvoice?.TotalExcludingTax ?? 0;

  const showTaxRow = subscription?.UpcomingInvoice?.Tax ?? 0;
  const totalTax =
    (subscription?.UpcomingInvoice?.Tax ?? 0) > 0 ? subscription?.UpcomingInvoice?.Tax : 0;

  useEffect(() => {
    const calculateColumns = (meta) => {
      return meta
        .filter((m) => m.isVisible)
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((m) => m.columnName);
    };

    const createMetaObject = (meta: TableColumns[]) => {
      return meta.reduce<{ [key: string]: any }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {});
    };

    const createTotalRow = (total) => {
      return {
        Qty: '',
        Product: '',
        ActivePeriod: '',
        UnitPrice: (
          <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
            {translate('Total')}
          </Typography>
        ),
        AmountIncTax: (total || total === 0) && (
          <>
            <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
              {' '}
              <FormattedCurrency
                value={total}
                locales={languageCode}
                currency={subscription?.Currency.toString() ?? 'EUR'}
              />
            </Typography>
          </>
        ),
      };
    };

    const createTotalRowNoTax = (total) => {
      return {
        Product: '',
        Qty: '',
        ActivePeriod: '',
        UnitPrice: (
          <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
            {translate('Total')}
          </Typography>
        ),
        Amount: (total || total === 0) && (
          <>
            <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
              {' '}
              <FormattedCurrency
                value={total}
                locales={languageCode}
                currency={subscription?.Currency.toString() ?? 'EUR'}
              />
            </Typography>
          </>
        ),
      };
    };

    const createTotalExcludeTax = (totalExcludingTax) => {
      return {
        Product: '',
        Qty: '',
        ActivePeriod: '',
        UnitPrice: (
          <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
            {translate('Total_excluding_tax')}
          </Typography>
        ),
        Amount: totalExcludingTax && (
          <>
            <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
              {' '}
              <FormattedCurrency
                value={totalExcludingTax}
                locales={languageCode}
                currency={subscription?.Currency.toString() ?? 'EUR'}
              />
            </Typography>
          </>
        ),
        AmountIncTax: totalExcludingTax && (
          <>
            <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
              {' '}
              <FormattedCurrency
                value={totalExcludingTax}
                locales={languageCode}
                currency={subscription?.Currency.toString() ?? 'EUR'}
              />
            </Typography>
          </>
        ),
      };
    };

    const createTotalTaxCalc = (totalTax) => {
      return {
        Product: '',
        Qty: '',
        ActivePeriod: '',
        UnitPrice: (
          <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
            {translate('Tax')}
          </Typography>
        ),
        AmountIncTax: showTaxRow && totalTax && (
          <>
            <Typography variant="body2" className={clsx(classes.cellText, classes.boldText)}>
              {' '}
              <FormattedCurrency
                value={totalTax}
                locales={languageCode}
                currency={subscription?.Currency.toString() ?? 'EUR'}
              />
            </Typography>
          </>
        ),
      };
    };

    const createRowsWithTotalRow = (rows, totalRow, totalExcludeTax, totalTaxCalc) => {
      const rowsWithTotalRow = [...rows, totalExcludeTax];

      if (showTaxRow) {
        rowsWithTotalRow.push(totalTaxCalc);
      }

      rowsWithTotalRow.push(totalRow);

      return rowsWithTotalRow;
    };

    const totalRowWithTax = createTotalRow(total);
    const totalRowNoTax = createTotalRowNoTax(total);
    const totalExcludeTax = createTotalExcludeTax(totalExcludingTax);
    const totalTaxCalc = createTotalTaxCalc(totalTax);
    const rows = !isLoading ? createUpcomingInvoiceRows() : [];
    const totalRow = showTaxRow ? totalRowWithTax : totalRowNoTax;
    let rowsWithTotalRow;

    if (rows && rows.length > 0) {
      rowsWithTotalRow = createRowsWithTotalRow(rows, totalRow, totalExcludeTax, totalTaxCalc);
    }

    setColumns(
      new Set(
        showTaxRow
          ? calculateColumns(upcomingInvoiceTableMeta)
          : calculateColumns(upcomingInvoiceNoTaxTableMeta)
      )
    );
    setMeta(
      showTaxRow
        ? createMetaObject(upcomingInvoiceTableMeta)
        : createMetaObject(upcomingInvoiceNoTaxTableMeta)
    );
    setUpcomingInvoiceTableData(rowsWithTotalRow);
  }, [subscription, isLoading]);

  const setAllColumns = (col) => {
    setColumns(col);
  };

  return (
    <GenericTable
      AppId={appId || ''}
      columns={columns}
      columnLocalStorageKey={'fd-upcoming-invoices-table-columns'}
      data={upcomingInvoiceTableData ?? [{}]}
      languageCode={languageCode}
      loading={isLoading}
      maxCellWidthProp="300px"
      meta={meta}
      metadata={showTaxRow ? upcomingInvoiceTableMeta : upcomingInvoiceNoTaxTableMeta}
      onSortKeyPressed={() => undefined}
      order={'asc'}
      orderBy={'asc'}
      page={0}
      rowsPerPage={100}
      setColumns={setAllColumns}
      showTitle={false}
      tableLayoutProp="auto"
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp?.AppId,
    translate: getTranslate(state),
    languageCode: getActiveLanguage(state.locale),
    dtUtils: dateTimeUtils(state),
  };
};

export default connect(mapStateToProps)(UpcomingInvoiceTable);
