import { BusinessHoursPeriod } from '@flipdish/api-client-typescript';

import { DateTimeUtils } from '../../selectors/localeDateTime.selector';

export const formatOpeningHour = (bhp: BusinessHoursPeriod, dtUtils: DateTimeUtils, translate) => {
  if (!bhp) {
    return undefined;
  }
  if (bhp.Period === '00:00:00') {
    return translate('Closed').toLowerCase();
  }

  if (bhp.StartTime === '00:00:00' && bhp.Period === '23:59:59') {
    return translate('All_day').toLowerCase();
  }

  let formattedTime = '';
  const startTime = dtUtils.parse(bhp.StartTime!, 'HH:mm:ss', new Date());
  const period = dtUtils.parse(bhp.Period!, 'HH:mm:ss', new Date());

  const fStart = dtUtils.format(startTime, dtUtils.LOCAL_TIME_FORMAT);
  let endTime = dtUtils.addHours(startTime, period.getHours());
  endTime = dtUtils.addMinutes(endTime, period.getMinutes());
  endTime = dtUtils.addSeconds(endTime, period.getSeconds());
  const fEnd = dtUtils.format(endTime, dtUtils.LOCAL_TIME_FORMAT);
  formattedTime = `${fStart}-${fEnd}`;

  if (bhp.PeriodEarly !== '00:00:00') {
    const startTimeEarly = dtUtils.parse(bhp.StartTimeEarly!, 'HH:mm:ss', new Date());
    const periodEarly = dtUtils.parse(bhp.PeriodEarly!, 'HH:mm:ss', new Date());

    const fStartEarly = dtUtils.format(startTimeEarly, dtUtils.LOCAL_TIME_FORMAT);
    let endTimeEarly = dtUtils.addHours(startTimeEarly, periodEarly.getHours());
    endTimeEarly = dtUtils.addMinutes(endTimeEarly, periodEarly.getMinutes());
    endTimeEarly = dtUtils.addSeconds(endTimeEarly, periodEarly.getSeconds());
    const fEndEarly = dtUtils.format(endTimeEarly, dtUtils.LOCAL_TIME_FORMAT);

    formattedTime = `${fStartEarly}-${fEndEarly}, ${formattedTime}`;
  }

  return formattedTime;
};
