import React, { useState } from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import TablePagination from '../../common/TablePagination/TablePagination';

type OAuthAppAuthTokensMobileProps = {
  authTokens: FlipdishAPI.OAuthTokenModel[];
  count: number;
  page: number;
  rowsPerPage: number;
  setSelectedTokenKey: (value: string | undefined) => void;
  rowPerPageOptions: number[];
  handleChangePage: (e, value: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  setDialogOpen: (value: boolean) => void;
};

type Props = OAuthAppAuthTokensMobileProps;

const OAuthAppAuthTokensMobile = (props: Props) => {
  const {
    authTokens,
    count,
    page,
    rowsPerPage,
    setSelectedTokenKey,
    rowPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    setDialogOpen,
  } = props;

  const [hoveredRow, setHoveredRow] = useState<number | null>();

  return (
    <>
      <List onMouseLeave={() => setHoveredRow(null)}>
        {authTokens.map((token, index) => (
          <ListItem
            key={index}
            onMouseEnter={() => setHoveredRow(index)}
            button
            selected={hoveredRow === index}
            className={HORIZONTAL_SPACE_CLASSNAME}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Translate id="User" />: {token.SubjectId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  <Translate id="Expiry" />: {moment(token.Expiry).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </Grid>
              <Grid item>
                <ListItemSecondaryAction>
                  <IconButton
                    data-fd="revoke-token-button-icon"
                    style={{ visibility: hoveredRow === index ? 'visible' : 'hidden' }}
                    onMouseEnter={(e) => {
                      setSelectedTokenKey(token.Key);
                    }}
                    onClick={() => setDialogOpen(true)}
                  >
                    <DeleteOutlined color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        ))}
        <TablePagination
          component="div"
          labelRowsPerPage=""
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </List>
    </>
  );
};

export default compose<Props, OAuthAppAuthTokensMobileProps>()(OAuthAppAuthTokensMobile);
