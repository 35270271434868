import React, { useEffect, useMemo, useState } from 'react';

import { EmvTerminalWithAssignments } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { Translate } from 'react-localize-redux';

import useIsMounted from '../../../custom-hooks/useIsMounted';
import { KNOWN_ERRORS } from '../../../services/utils/serverErrorMapper';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';
import { getEMVsList } from '../Kiosks.selectors';
import { assignEmv, getEMVTerminals, unAssignEmv } from '../Kiosks.services';
import KioskPaymentMethodField from './fields/KioskPaymentMethodField';

export type FormValues = { EMV: string };
export const getDefaultFormState = () => {
  return { EMV: '' };
};

type OuterProps = {
  appId: string;
  deviceId: string;
  EMVTerminalsAssignedToApp: EmvTerminalWithAssignments[];
  hydraConfigId: number;
  setEMVTerminalsAssignedToApp: React.Dispatch<React.SetStateAction<EmvTerminalWithAssignments[]>>;
};

type Props = FormikProps<FormValues> & OuterProps;

export default withFormik<OuterProps, FormValues>({
  displayName: 'AddEMVForm',
  mapPropsToValues: () => {
    return getDefaultFormState();
  },
  mapPropsToStatus: () => ({ success: false, error: null }),
  handleSubmit: async (values, formikBag) => {
    if (values.EMV === 'unassign') {
      try {
        await unAssignEmv(formikBag.props.appId, formikBag.props.hydraConfigId);
        formikBag.resetForm();
        formikBag.setStatus({ success: true, error: null });
      } catch (error) {
        formikBag.setStatus({ success: false, error });
      }
    } else {
      try {
        await assignEmv(formikBag.props.appId, formikBag.props.hydraConfigId, Number(values.EMV));
        formikBag.resetForm();
        formikBag.setStatus({ success: true, error: null });
      } catch (error) {
        // TODO select prevoius value
        formikBag.setStatus({ success: false, error });
      }
    }
  },
})((props: Props & FormikProps<FormValues>) => {
  const {
    appId,
    dirty,
    deviceId,
    EMVTerminalsAssignedToApp,
    isSubmitting,
    setEMVTerminalsAssignedToApp,
    setStatus,
    status,
    submitForm,
    values,
  } = props;
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();

  const { enqueueSnackbar } = useSnackbar();

  const getTerminals = async (appId: string) => {
    try {
      const data = await getEMVTerminals(appId);
      setEMVTerminalsAssignedToApp(data!);
      setStatus({ success: false, error: null });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let err = error;
      if (KNOWN_ERRORS.includes(error.message)) {
        err = new Error('An error occurred');
      }
      enqueueSnackbar(`Error: ${err.message}`, {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'error',
        disableWindowBlurListener: true,
      });
    }
  };

  useEffect(() => {
    if (appId) {
      getTerminals(appId);
    }
  }, [appId]);

  useEffect(() => {
    if (status.success) {
      enqueueSnackbar('Success', {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'success',
        disableWindowBlurListener: true,
      });

      getTerminals(appId);
    } else if (status.error) {
      enqueueSnackbar(`Error: ${status.error.message}`, {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'error',
        disableWindowBlurListener: true,
      });
    }
  }, [status.success, status.error]);

  const submit = useMemo(
    () =>
      debounce(() => {
        if (isMounted()) {
          submitForm();
        }
      }, 1000),
    []
  );

  useEffect(() => {
    if (dirty && values) {
      submit();
    }
  }, [dirty, values]);

  return (
    <Form>
      <PreventNavigation when={dirty && isSubmitting} />

      <Grid container>
        <Grid item xs={12}>
          {loading ? (
            <>
              <Translate id="Loading" />
              {'...'}
            </>
          ) : (
            <KioskPaymentMethodField
              deviceId={deviceId}
              EMVTerminalsAssignedToApp={EMVTerminalsAssignedToApp}
              EMVTerminalsAssignedToKiosk={getEMVsList(EMVTerminalsAssignedToApp, deviceId)}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
});
