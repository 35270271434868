import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import Select from '@fd/ui/Select/Select';

import { FormValues } from '../EditFulfillmentConfigurationStateForm';

type Props = {
  updateDefaultNextStatusValue: (updatedStatuses) => void;
  values: FormValues;
  translate: TranslateFunction;
};

const useStyles = makeStyles({
  select: {
    margin: '16px 0',
  },
});

const orderTypes = ['Delivery', 'Collection', 'DineIn'];
const DefaultNextStatusSelect = ({ translate, updateDefaultNextStatusValue, values }: Props) => {
  const classes = useStyles();

  const formattedDefaultNextStatusOptions = values.NextStatuses
    ? values.NextStatuses.map((status) => ({
        label: status,
        value: status,
      }))
    : [];

  const handleChange = (orderType: string, change) => {
    const updatedStatuses = values.DefaultNextStatus.map((nextStatus) =>
      nextStatus.OrderType === orderType ? { ...nextStatus, NextStatus: change.value } : nextStatus
    );

    updateDefaultNextStatusValue(updatedStatuses);
  };

  const isDefaultNextStatusDisabled = (orderType: string): boolean => {
    if (!values.NextStatuses) return true;

    return values.NextStatuses.length <= 0 && !values.OrderTypes.includes(orderType);
  };

  const getValueForOrderType = (orderType) => {
    const obj = values?.DefaultNextStatus?.find((status) => status.OrderType === orderType);
    if (!obj) return null;

    return { label: obj.NextStatus, value: obj.NextStatus };
  };

  return (
    <>
      {orderTypes.map((orderType) => {
        const orderTypeLower = orderType.toLowerCase();
        return (
          <Select
            key={orderType}
            className={classes.select}
            dataFd={`default-to-${orderTypeLower}-select`}
            isClearable
            options={formattedDefaultNextStatusOptions}
            TextFieldProps={{
              fdKey: `default-to-${orderTypeLower}-dropdown`,
              // @ts-ignore
              label: translate(orderType) as TranslationId,
              name: `default-to-${orderTypeLower}-select`,
            }}
            isDisabled={isDefaultNextStatusDisabled(orderType)}
            onChange={(value) => handleChange(orderType, value)}
            value={getValueForOrderType(orderType)}
          />
        );
      })}
    </>
  );
};

export default DefaultNextStatusSelect;
