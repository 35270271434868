import React, { useMemo } from 'react';

import { OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { useCurrentTime } from '../../../custom-hooks/useDateTime';
import SubPageTitle from '../../../layouts/Portal/SubPageTitle';
import { storeSelectors } from '../../../selectors';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { TutorialNotifier } from '../../Tutorial/Notifier';

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeId }: Props) => {
  const envTimeZones = storeSelectors.envTimeZones(state, { storeId });

  return {
    envTimeZones,
    dtUtils: dateTimeUtils(state),
  };
};

type Props = { storeId: number };
const Title = compose<Props & MappedState, Props>(connect(mapStateToProps))((props) => {
  const { storeId } = props;

  return (
    <>
      <SubPageTitle translateId="Opening_hour_overrides_title" />
      {!!storeId && (
        <TutorialNotifier
          storeId={storeId}
          onboardingItemId={601}
          status={'Completed' as OnboardingItemUpdate}
        />
      )}
    </>
  );
});

export default Title;
