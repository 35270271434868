import React from 'react';

import IconButton from '@mui/material/IconButton';
import MuiTableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps as TableCellPropsType } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';
import { formatCurrency } from 'fd-react-intl';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import PDFIcon from '../../assets/images/pdfIcon.svg';
import { DateTimeUtils, dateTimeUtils } from '../../selectors/localeDateTime.selector';
import { Button } from '../atoms';
import GenericTableBodyRow, { TableBodyRowProps } from './GenericTableBodyRow';
import { CurrencyEnum, GenericTableColumns, Status } from './types';

export const getAlignmentByColumnType = (
  columnType: GenericTableColumns['columnType']
): TableCellPropsType['align'] => {
  switch (columnType) {
    case 'String':
    case 'Status':
    case 'DateTime':
    case 'Date':
    case 'DynamicTableCell':
    case 'Subtext':
    case 'TranslatableString':
    case 'Link':
      return 'left';
    case 'Integer':
    case 'InvoiceAction':
    case 'Unknown':
    case 'Decimal':
    case 'DecimalWithCurrency':
    default:
      return 'right';
  }
};

export type TableCellProps = {
  AppId: string;
  classes: Record<any, any>;
  colIdx: any;
  colName: string;
  currency?: CurrencyEnum;
  dtUtils: DateTimeUtils;
  hasWarning?: boolean;
  hasTitle?: boolean;
  languageCode: string;
  meta?: { [key: string]: GenericTableColumns };
  showTimeAgo?: boolean;
  type: 'sticky' | 'normal';
} & TableBodyRowProps;

const generateLink = (prop, colName, AppId) => {
  switch (colName) {
    case 'orderId': {
      return <Link to={`/${AppId}/orders/${prop}`}>#{prop}</Link>;
    }
    case 'Action': {
      return (
        <div style={{ color: '#05149E' }}>
          <Translate id={prop} />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

const getPayBtnOrPDF = (prop, invoicePDFLink, invoicePayLink, InvoiceStatus) => {
  switch (InvoiceStatus) {
    case true: {
      return (
        <Button
          fdKey={`Generic-table-button-${invoicePayLink}`}
          target={'_blank'}
          //@ts-ignore
          rel={'noopener noreferrer'}
          onClick={() => {
            invoicePayLink && window.open(invoicePayLink, '_blank', 'noopener, noreferrer');
          }}
        >
          <Translate id="Pay_now" />
        </Button>
      );
    }
    case false: {
      return (
        <IconButton
          onClick={() => {
            invoicePDFLink && window.open(invoicePDFLink, '_blank', 'noopener, noreferrer');
          }}
        >
          <img src={PDFIcon} className="image" data-fd="invoice-pdf-link" />
        </IconButton>
      );
    }
    default: {
      return null;
    }
  }
};

const formatCurrencyWithFractions = (
  value: number | string,
  languageCode: string,
  currency: Flipdish.OrderSummary['Currency']
) => {
  const numVal = typeof value === 'string' ? parseFloat(value) : value;
  return formatCurrency(numVal, languageCode, {
    currency: currency as string,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const RenderTableCell: React.FC<React.PropsWithChildren<TableCellProps>> = (props) => {
  const {
    AppId,
    classes,
    colIdx,
    colName,
    currency,
    dtUtils: {
      format,
      formatDistanceToNow,
      userIanaTimeZone,
      utcToZonedTime,
      LOCAL_DATE_TIME_FORMAT,
      LOCAL_DATE_FORMAT,
    },
    hasWarning,
    hasTitle,
    languageCode,
    meta,
    row,
    showTimeAgo = false,
    type,
  } = props;

  const prop = row[colName];

  const colClass = clsx({
    [classes.cells]: true,
    [classes.link]: meta![colName].columnType === 'Link',
    [classes.highlightWarning]: hasWarning,
    [classes.titleCell]: hasTitle,
    [classes.sticky]: type === 'sticky' && colIdx === 0,
  });

  const getStatusFontColor = (status: Status) => {
    switch (status) {
      case 'Failed':
      case 'Cancelled':
        return classes.failedFont;
      case 'Paid':
        return classes.paidFont;
      default:
        return classes.col;
    }
  };

  const align = getAlignmentByColumnType(meta![colName].columnType);
  const tableCellProps = {
    align: align,
    classes: { root: colClass },
    key: colIdx,
  };

  switch (meta![colName].columnType) {
    case 'CurrencyCode': {
      return (
        <TableCell {...tableCellProps}>{prop !== undefined ? prop.toUpperCase() : '-'}</TableCell>
      );
    }
    case 'String': {
      return <TableCell {...tableCellProps}>{prop !== undefined ? prop : '-'}</TableCell>;
    }
    case 'Status': {
      const { status } = prop;
      return (
        <TableCell {...tableCellProps}>
          <span className={getStatusFontColor(status)} data-fd={'status-' + status}>
            {status !== undefined ? <Translate id={status} /> : '-'}
          </span>
        </TableCell>
      );
    }
    case 'Subtext': {
      const { mainText, subtext } = prop;
      return (
        <TableCell {...tableCellProps}>
          <span data-fd={'maintext-' + mainText}>{mainText}</span>
          <br />
          <span className={classes.cellSubtext} data-fd={'subtext-' + subtext}>
            {subtext}
          </span>
        </TableCell>
      );
    }
    case 'Link': {
      return (
        <TableCell {...tableCellProps}>
          {prop !== undefined ? generateLink(prop, colName, AppId) : '-'}
        </TableCell>
      );
    }
    case 'Integer':
    case 'Unknown': {
      // TEMPORARY, because rejectedByUserUd returns 'Unknown' type (from API)
      return <TableCell {...tableCellProps}>{prop !== undefined ? prop : '-'}</TableCell>;
    }
    case 'Decimal': {
      return (
        <TableCell {...tableCellProps}>
          {prop !== undefined
            ? `${formatCurrencyWithFractions(prop, languageCode, currency)}`
            : '-'}
        </TableCell>
      );
    }
    // handles tables that return rows from multiple stores with different currencies
    case 'DecimalWithCurrency': {
      return (
        <TableCell
          align={align}
          classes={{ root: colClass }}
          data-fd={'amount-' + prop}
          key={colIdx}
        >
          {prop !== undefined ? prop : '-'}
        </TableCell>
      );
    }
    case 'DateTime': {
      const renderContent = (
        <>
          {prop !== undefined
            ? `${format(utcToZonedTime(prop, userIanaTimeZone), LOCAL_DATE_TIME_FORMAT)}`
            : '-'}
          {showTimeAgo && (
            <>
              <br />
              <span className={classes.cellSubtext}>
                {prop !== undefined ? `${formatDistanceToNow(prop, { addSuffix: true })}` : '-'}
              </span>
            </>
          )}
        </>
      );
      return <TableCell {...tableCellProps}>{renderContent} </TableCell>;
    }
    case 'Date': {
      const renderContent = (
        <>
          {prop !== undefined
            ? `${format(utcToZonedTime(prop, userIanaTimeZone), LOCAL_DATE_FORMAT)}`
            : '-'}
        </>
      );
      return <TableCell {...tableCellProps}>{renderContent} </TableCell>;
    }
    case 'InvoiceAction': {
      const invoicePDFLink = row?.PdfLink;
      const invoicePayLink = row?.HostedUrl;
      const InvoiceStatus = row?.Overdue;
      return (
        <TableCell {...tableCellProps}>
          {prop !== undefined
            ? getPayBtnOrPDF(prop, invoicePDFLink, invoicePayLink, InvoiceStatus)
            : '-'}
        </TableCell>
      );
    }
    case 'DynamicTableCell': {
      return <TableCell {...tableCellProps}>{prop !== undefined ? prop : '-'} </TableCell>;
    }
    case 'TranslatableString':
    default: {
      return (
        <TableCell {...tableCellProps}>
          {prop !== undefined ? (
            typeof prop !== 'string' ? (
              prop.name
            ) : (
              <Translate id={prop.replace(/^\w/, (c) => c.toUpperCase()) as TranslationId} />
            )
          ) : (
            '-'
          )}{' '}
        </TableCell>
      );
    }
  }
};

type TableBodyProps = {
  AppId: string;
  classes: Record<any, any>;
  columns: Set<string>;
  currency?: CurrencyEnum;
  data?: any[];
  languageCode: string;
  loading?: boolean;
  meta?: { [key: string]: GenericTableColumns };
  onRowClick?: Function;
  page?: number;
  rowsPerPage?: number;
  showTimeAgo?: boolean;
  type: 'sticky' | 'normal';
};

const TableBody: React.FC<React.PropsWithChildren<TableBodyProps & MappedState>> = (props) => {
  const { loading, columns, classes, data, page, rowsPerPage, dtUtils } = props;

  if (!loading && data && page !== undefined && rowsPerPage !== undefined) {
    const { type, onRowClick, meta, AppId, languageCode, currency, showTimeAgo } = props;
    let warningRow = false;
    let titleRow = false;
    const rowClass = clsx({
      [classes.rowHover]: type === 'sticky', // only normal on mobile
    });

    return (
      <MuiTableBody>
        {data.map((row, rowIdx) => {
          warningRow = row.hasWarning;
          titleRow = row.hasTitle;
          return (
            <GenericTableBodyRow
              classNameButtonRow={`${rowClass} ${warningRow ? classes.rowHoverWarning : ''}`}
              key={rowIdx}
              onRowClick={onRowClick}
              row={row}
            >
              {Array.from(columns).map((colName, colIdx) => {
                return (
                  <RenderTableCell
                    AppId={AppId}
                    classes={classes}
                    colIdx={colIdx}
                    colName={colName}
                    currency={currency}
                    dtUtils={dtUtils}
                    hasWarning={warningRow}
                    hasTitle={titleRow}
                    key={colName}
                    languageCode={languageCode}
                    meta={meta}
                    row={row}
                    showTimeAgo={showTimeAgo}
                    type={type}
                  />
                );
              })}
            </GenericTableBodyRow>
          );
        })}
      </MuiTableBody>
    );
  }

  //#region SKELETON LOADER
  return (
    <MuiTableBody data-fd="generic-table-skeleton-loader">
      {Array.from(
        Array(data && data.length !== 0 && data.length < rowsPerPage! ? data.length : rowsPerPage)
      ).map((row, rowIdx) => {
        return (
          <TableRow key={rowIdx}>
            {Array.from(columns).map((_colName, colIdx) => (
              <TableCell key={colIdx} align="left" className={classes.cells}>
                <Skeleton width={'90%'} height={22} />
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </MuiTableBody>
  );
  //#endregion
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({ dtUtils: dateTimeUtils(state) });

export default compose<any, any>(connect(mapStateToProps))(TableBody);
