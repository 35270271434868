import React from 'react';

import Done from '@mui/icons-material/Done';
import HelpOutline from '@mui/icons-material/HelpOutline';
import NotInterested from '@mui/icons-material/NotInterested';

export const Verified = () => <Done style={{ color: '#86e8bb' }} />;

export const Unverified = () => <NotInterested style={{ color: '#fb7c92' }} />;

export const AwaitingVerification = () => <HelpOutline style={{ color: '#fbcf7c' }} />;
