import React, { ReactNode, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { type Theme } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { useTracking } from '../../services/amplitude/useTracking';

const TOOLTIP_OFFSET = 55;
type StyleProps = { arrowLeftPosition?: string };

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  body: {
    maxWidth: 200,
    width: 200,
    padding: 12,
    position: 'relative',
    backgroundColor: '#fff',
    cursor: 'pointer',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: 4,
  },
  title: {
    color: 'rgba(0,0,0,0.87)',
    display: 'block',
    fontWeight: 500,
    marginBottom: 8,
  },
  message: {
    color: 'rgba(0,0,0,0.87)',
    '& a': {
      color: '#rgba(5, 20, 158, 0.8)',
      textDecoration: 'none',
    },
  },
  arrow: {
    backgroundColor: '#fff',
    boxShadow: '-2px 1.8px 3px 0 rgba(0, 0, 0, 0.25)',
    bottom: '-3.7px',
    left: (props) => (props.arrowLeftPosition ? '70%' : '42px'),
    height: 8,
    position: 'absolute',
    transform: 'matrix(0.71, -0.71, 0.71, 0.71, 0, 0)',
    width: 8,
  },
  popper: {
    '&[data-popper-placement*="bottom"] $arrow': {
      backgroundColor: '#fff',
      boxShadow: '1px -1px 1px 0px rgba(0, 0, 0, 0.18)',
      top: '-3.7px',
      height: 8,
      position: 'absolute',
      transform: 'matrix(0.71, -0.71, 0.71, 0.71, 0, 0)',
      width: 8,
      left: (props) => (props.arrowLeftPosition ? '70%' : '42px'),
    },
  },
}));

type TooltipProps = {
  arrowLeftPosition?: string;
  children: React.ReactElement;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  fdKey: string;
  interactive?: boolean;
  labelText?: string;
  messageData?: Record<string, string>;
  messageId?: TranslationId;
  messageSuffix?: ReactNode;
  titleData?: Record<string, string>;
  titleId?: TranslationId;
  /** Used for accessibilty. Set an ID to associate content with the tooltip unless, the children are the content in which case Material-ui creates an ID and attaches it to the children
   * @example
   * <Typography id="Estimated_Annual_Value" aria-describedby="eav-tooltip"/>
   * <Tooltip id="eav-tooltip" messageId="EAV_description">
   *   <div>
   *     <InfoIcon/>
   *   </div>
   * </Tooltip>
   */
  id?: string;
  trackPreviewEvent?: boolean;
};

type Props = TooltipProps;

const Tooltip: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    arrowLeftPosition,
    children,
    disableHoverListener,
    disableTouchListener,
    fdKey,
    id,
    interactive,
    labelText,
    messageData,
    messageId,
    messageSuffix,
    titleData,
    titleId,
    trackPreviewEvent = false,
  } = props;

  const classes = useStyles({ arrowLeftPosition });

  const tooltipId = id ? id : undefined;

  const [shownOnMobile, setShownOnMobile] = useState(false);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  const { trackPreviewEvent: trackEvent } = useTracking();

  const renderMessageWithTitle = () => (
    <>
      <Typography variant="caption" className={classes.title}>
        <Translate id={titleId as TranslationId} data={titleData} />
      </Typography>
      <Typography variant="caption" className={classes.message}>
        <Translate id={messageId as TranslationId} data={messageData}></Translate>
      </Typography>
    </>
  );

  const handleTooltipClose = () => {
    setShownOnMobile(false);
  };

  const handleTooltipOpenMobile = () => {
    !disableTouchListener && setShownOnMobile(true);

    if (trackPreviewEvent) {
      trackEvent('Preview Tooltip', {
        fdKey,
        id: id ?? '',
        mobile: true.toString(),
      });
    }
  };

  const handleTooltipOpenDesktop = () => {
    if (trackPreviewEvent) {
      trackEvent('Preview Tooltip', {
        fdKey,
        id: id ?? '',
        mobile: false.toString(),
      });
    }
  };

  const contentSuffix = () => {
    if (!messageSuffix) {
      return null;
    }

    return (
      <Typography variant="caption" className={classes.message}>
        {' '}
        {messageSuffix}
      </Typography>
    );
  };

  const contentText = () => {
    if (titleId && messageId) {
      return renderMessageWithTitle();
    }
    if (titleId) {
      return (
        <Typography variant="caption" className={classes.title}>
          <Translate id={titleId} data={titleData} />;
        </Typography>
      );
    }
    if (messageId) {
      return (
        <Typography variant="caption" className={classes.message}>
          <Translate id={messageId} data={messageData} />
        </Typography>
      );
    }
    if (labelText) {
      return (
        <Typography variant="caption" className={classes.message}>
          {labelText}
        </Typography>
      );
    }
    return;
  };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <>
      {isMobile ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <MuiTooltip
            disableInteractive={!interactive}
            tabIndex={0}
            id={tooltipId}
            disableTouchListener={disableTouchListener}
            disableFocusListener
            disableHoverListener
            data-fd={fdKey}
            open={shownOnMobile}
            onClose={handleTooltipClose}
            classes={{
              tooltip: classes.body,
              popper: classes.popper,
            }}
            placement="top"
            enterTouchDelay={200}
            PopperProps={{
              disablePortal: true,
              modifiers: [
                {
                  name: 'offset',
                  enabled: true,
                  options: { offset: [TOOLTIP_OFFSET] },
                },
                {
                  name: 'arrow',
                  enabled: !!arrowRef,
                  options: {
                    element: arrowRef,
                  },
                },
              ],
            }}
            title={
              <>
                {contentText()}
                {contentSuffix()}
                <span className={classes.arrow} ref={setArrowRef} />
              </>
            }
            leaveDelay={interactive ? 500 : 0} // needed for interactive tooltips on safari
            leaveTouchDelay={10000}
          >
            <span onClick={() => handleTooltipOpenMobile()}>{children}</span>
          </MuiTooltip>
        </ClickAwayListener>
      ) : (
        <MuiTooltip
          tabIndex={0}
          id={tooltipId}
          disableHoverListener={disableHoverListener}
          data-fd={fdKey}
          classes={{
            tooltip: classes.body,
            popper: classes.popper,
          }}
          placement="top"
          enterTouchDelay={2000}
          disableInteractive={!interactive}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: { offset: [TOOLTIP_OFFSET] },
              },
              {
                name: 'arrow',
                enabled: !!arrowRef,
                options: {
                  element: arrowRef,
                },
              },
            ],
          }}
          title={
            <>
              {contentText()}
              {contentSuffix()}
              <span className={classes.arrow} ref={setArrowRef} />
            </>
          }
          onOpen={() => handleTooltipOpenDesktop()}
        >
          {children}
        </MuiTooltip>
      )}
    </>
  );
};

export default Tooltip;
