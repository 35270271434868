import type { SupportedCountry } from '@flipdish/api-client-typescript';

export const getAccount = (state: AppState) => state.account;

export const getSupportedCountry = (
  supportedCountries?: SupportedCountry[],
  storeCountryCode?: string
) => {
  return supportedCountries && supportedCountries.find((c) => c.CountryCode === storeCountryCode);
};
