import React, { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Circle as GCircle, InfoWindow } from '@react-google-maps/api';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { IMapShapeProps } from '../types';

export const serialize = (circleRef: google.maps.Circle) => {
  const r = circleRef.getRadius();
  const radius = Math.round(r ?? 0);
  const center = circleRef.getCenter();
  const lat = center ? center.lat() : null;
  const lng = center ? center.lng() : null;
  return `CIRCLE((${lng} ${lat}, ${radius}))`;
};

export const parse = (wktText: string) => {
  const circleExtractor = /\(\((.+)\)\)/g;
  const digits = circleExtractor.exec(wktText);
  if (digits && digits.length > 1) {
    const [txtLng, txtLat, txtRadius] = digits[1].split(/ |,[ ]/);

    return new google.maps.Circle({
      radius: Number(txtRadius),
      center: new google.maps.LatLng(Number(txtLat), Number(txtLng)),
    });
  }
  return undefined;
};

const coordinatesSelector = createSelector(
  [(props: IMapShapeProps) => props.zone.WellKnownText],
  (WellKnownText) => {
    try {
      return parse(WellKnownText);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
);

const useStyles = makeStyles(() =>
  createStyles({
    infoText: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

const mapStateToProps = (state: AppState, ownProps) => ({
  initialCoords: coordinatesSelector(ownProps),
  translate: getTranslate(state.locale),
});

interface IProps extends IMapShapeProps {
  initialCoords: GCircle;
}

const Circle = ({
  zone,
  onSelect,
  disabled,
  onChange,
  initialCoords: coords,
  translate,
}: IProps & ReturnType<typeof mapStateToProps>) => {
  let infoBox: React.ReactElement<HTMLElement> | null = null;
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);

  const [circleRef, setCircleRef] = useState<google.maps.Circle>();

  const onLoad = useCallback((circle: google.maps.Circle) => {
    setCircleRef(circle);
  }, []);

  const onCircleChange = useCallback(() => {
    if (circleRef) {
      const wktText = serialize(circleRef);
      onChange(wktText);
    }
  }, [circleRef, onChange]);

  if (!coords) {
    return null;
  }

  if (zone.selected && !dragging) {
    try {
      const radius = (circleRef || coords).getRadius();
      const center = (circleRef || coords).getCenter();

      const radiusInKm = (radius as number) / 1000;
      const radiusInMl = radiusInKm * 0.621371;

      const centerLat = center?.lat();
      const centerLng = center?.lng();

      let validCenter = false;
      if (centerLat && centerLng) {
        validCenter = centerLat >= -90 && centerLat <= 90 && centerLng >= -180 && centerLng <= 180;
      }

      infoBox =
        validCenter && center ? (
          <InfoWindow position={center}>
            <Typography align="center" className={classes.infoText}>
              <span>{translate('Radius')}</span>
              <span>
                {radiusInMl.toFixed(2)} {translate('Miles')}
              </span>
              <span>
                {radiusInKm.toFixed(2)} {translate('Kilometers')}
              </span>
            </Typography>
          </InfoWindow>
        ) : null;
    } catch (error) {
      infoBox = null;
      console.error(error);
    }
  }

  return (
    coords && (
      <>
        <GCircle
          onLoad={onLoad}
          key={zone.Id}
          onRadiusChanged={disabled ? undefined : onCircleChange}
          onCenterChanged={disabled ? undefined : onCircleChange}
          onDragStart={
            disabled
              ? undefined
              : () => {
                  setDragging(true);
                }
          }
          onDragEnd={
            disabled
              ? undefined
              : () => {
                  setDragging(false);
                  onCircleChange();
                }
          }
          onClick={disabled ? undefined : onSelect}
          radius={coords.getRadius()}
          center={coords.getCenter() ?? { lat: 0, lng: 0 }}
          options={zone.options}
        />
        {infoBox}
      </>
    )
  );
};

export default connect(mapStateToProps)(Circle);
