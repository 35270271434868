import React, { useCallback, useState } from 'react';

import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { deleteStoreOrderNotificationContacts } from '../../../actions/store.actions';
import { notify, NotifyProps, SAVED_KEY } from '../../../layouts/Notify/actions';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';

const useStyles = makeStyles({
  content: {
    maxWidth: 232,
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = () => {
  return (dispatch, ownProps: Props) => {
    const { storeId } = ownProps;
    return {
      notify: (data: NotifyProps) => dispatch(notify(data)),
      remove: (orderNotification: StoreOrderNotificationContactDetails) => {
        return dispatch(deleteStoreOrderNotificationContacts(storeId, orderNotification));
      },
    };
  };
};

export interface IRemoveNotificationDialog {
  open: boolean;
  storeId: number;
  orderNotification: StoreOrderNotificationContactDetails;
  onRemove();
  onCancel();
}
type Props = IRemoveNotificationDialog;

export const RemoveNotificationDialog = compose<
  Props & MappedState & MappedDispatch,
  IRemoveNotificationDialog
>(
  setDisplayName('OpenCloseFormDialog'),
  connect(mapStateToProps, mapDispatchToPropsFactory)
)((props) => {
  const { open, onCancel, notify, translate, remove, onRemove, orderNotification } = props;

  const classes = useStyles();
  const [removing, setRemoving] = useState(false);

  const handleSave = useCallback(() => {
    if (removing) {
      return;
    }

    setRemoving(true);
    Promise.resolve(remove(orderNotification))
      .then(() => {
        const msg =
          orderNotification.ContactType ===
          StoreOrderNotificationContactDetails.ContactTypeEnum.Email
            ? translate('Email_removed')
            : translate('Phone_number_removed');

        notify({
          key: SAVED_KEY,
          message: msg as string,
          variant: 'success',
        });

        onRemove();
        setRemoving(false);
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        notify({
          key: SAVED_KEY,
          message: err.message,
          variant: 'error',
        });
        setRemoving(false);
      });
  }, [orderNotification]);

  if (!orderNotification) {
    return null;
  }

  return (
    <YesNoDialog
      open={open}
      onYes={handleSave}
      onNo={onCancel}
      yesTextId={'Remove'}
      titleTextId={'Are_you_sure'}
      disabled={removing}
    >
      <Typography className={classes.content}>
        <Translate
          id="This_email_or_number_will_not_longer_be_notified"
          data={{
            emailOrPhoneNumber:
              props.orderNotification.ContactType ===
              StoreOrderNotificationContactDetails.ContactTypeEnum.Email
                ? translate('Email').toString().toLowerCase()
                : translate('Phone_number').toString().toLowerCase(),
          }}
        />
      </Typography>
    </YesNoDialog>
  );
});
