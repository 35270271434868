import React, { useCallback, useMemo } from 'react';

import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { dictionary } from '../../../helpers/dictionary';
import { DataTable, TDataTableHeader } from '../../../ui/DataTable';

type InnerProps = MappedState;
type OuterProps = {
  emailOrderNotifications: StoreOrderNotificationContactDetails[];
  onRemove: (notification: StoreOrderNotificationContactDetails) => void;
};
type Props = InnerProps & OuterProps;

export type StoreOrderNotificationContactDetailsWithLabel = {
  whenLabel: string;
  removeColumn: string;
} & StoreOrderNotificationContactDetails;

export const getNotificationsWithLabel = (
  translate,
  notifications
): StoreOrderNotificationContactDetailsWithLabel[] => {
  const options = dictionary.getEmailAndPhoneNumberOptions(translate);
  return notifications.map((item) => {
    const [first] = options.filter((f) => Number(f.value) === item.SecondsUntilNotification);
    return {
      ...item,
      whenLabel: (first && first.label) || '',
    } as StoreOrderNotificationContactDetailsWithLabel;
  });
};

const EmailTable = ({ translate, onRemove, emailOrderNotifications }: Props) => {
  const notifications = useMemo<StoreOrderNotificationContactDetailsWithLabel[]>(
    () => getNotificationsWithLabel(translate, emailOrderNotifications),
    [emailOrderNotifications]
  );

  const tableHeaders = useMemo(() => {
    const result: Array<TDataTableHeader<StoreOrderNotificationContactDetailsWithLabel>> = [
      {
        labelId: 'Email',
        name: 'ContactDetail',
        renderDataCell: ({ value, row }) => (
          <>
            <Box style={{ lineHeight: '1.85' }}>{value}</Box>
            <Hidden smUp={true}>
              <Box style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{row.whenLabel}</Box>
            </Hidden>
          </>
        ),
      },
      {
        labelId: 'When',
        name: 'whenLabel',
        hiddenProps: { mdDown: true },
        width: '60%',
        renderDataCell: ({ value }) => <Box style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{value}</Box>,
      },
      {
        labelId: 'Remove',
        width: '10%',
        name: 'removeColumn',
        renderDataCell: (arg) => (
          <Box style={{ textAlign: 'center' }}>
            <IconButton
              style={{ padding: 4 }}
              onClick={() => handleDeleteClick(arg)}
              color="inherit"
            >
              <DeleteOutlineIcon fontSize={'small'} />
            </IconButton>
          </Box>
        ),
      },
    ];
    return result;
  }, []);

  const handleDeleteClick = useCallback((arg: any) => {
    onRemove(arg.row);
  }, []);

  return (
    <DataTable<StoreOrderNotificationContactDetailsWithLabel>
      uniqueFieldName="StoreOrderContactDetailId"
      headers={tableHeaders}
      data={notifications}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(EmailTable);
