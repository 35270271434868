import React from 'react';

import useWidth from '@fd/customHooks/useWidth';
import Grid, { type GridTypeMap } from '@mui/material/Grid';
import { type Breakpoint, type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

function getSpacing(width: Breakpoint): number {
  if (width === 'xs' || width === 'sm') {
    return 2;
  }

  return 3;
}

export const useCardStyles = makeStyles(({ breakpoints }: Theme) => ({
  card: {
    [breakpoints.up('md')]: {
      maxWidth: 416,
    },
  },
}));

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  cardsSpace: {
    [breakpoints.only('xs')]: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },

    [breakpoints.only('sm')]: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
  },
}));

type Props = {
  children: React.ReactNode;
  width?: Breakpoint;
  cards?: boolean;
  className?: string;
};

export default (function GridContainer({
  children,
  cards,
  className = '',
  ...props
}: Props & GridTypeMap['props']) {
  const classes = useStyles();
  const width = useWidth();

  // MUI V5 messed up Grid container spacing. This is a workaround to fix it.
  const spacing = getSpacing(width);
  const margin = spacing / 2;
  const containerWidth = `calc(100% + ${8 * spacing}px)`;

  return (
    <Grid
      container
      {...props}
      className={`${cards ? classes.cardsSpace : ''} ${className}`}
      margin={-margin}
      width={containerWidth}
    >
      {children}
    </Grid>
  );
});
