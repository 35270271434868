import React from 'react';

import Grid from '@mui/material/Grid';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Button from '@fd/ui/Button/Button';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';
import Spacer from '@fd/ui/Spacer';

import { notify, NotifyProps } from '../../../../layouts/Notify/actions';
import FieldWrapper from '../../Banking/components/fields/FieldWrapper';
import FormItemLayout from '../../Banking/components/FormItemLayout';
import GridDivider from '../../Banking/components/GridDivider';
import { validateRestaurantVoucherForm } from '../../utils';
import { restaurantVouchersService } from '../RestaurantVouchers.service';
import RestaurantVoucherAssignStoresField from './Fields/RestaurantVoucherAssignStoresField';

export type RestaurantVoucherFormValues = {
  Name: string;
  PayGreenId: string;
  PayGreenPrivateKey: string;
  AssignedStores: number[];
};

export type RestaurantVoucherError = {
  Message: string;
  ErrorCode: number;
  StackTrace?: any;
  Errors?: any;
};

type Props = MappedDispatch & MappedState;

const AddRestaurantVoucherForm = (props: Props): JSX.Element => {
  const { notify, appId, translate } = props;

  const { mutateAsync } = useMutation({
    mutationFn: (newRestaurantVoucher: RestaurantVoucherFormValues) => {
      return restaurantVouchersService.createPaygreenConfiguration(appId, newRestaurantVoucher);
    },

    onSuccess: () => {
      notify({
        variant: 'success',
        translate: true,
        message: 'Successfully_added_restaurant_voucher',
      });
    },

    onError: () => {
      console.log('Error submit form');
      notify({
        variant: 'error',
        translate: true,
        message: 'Error_PayGreen_credentials',
      });
    },
  });

  return (
    <Formik
      initialValues={{
        Name: '',
        PayGreenId: '',
        PayGreenPrivateKey: '',
        AssignedStores: [],
      }}
      onSubmit={async (values) => {
        try {
          const newCreateRestaurantVoucher = {
            ...values,
            Name: values.Name,
            PayGreenId: values.PayGreenId,
            PayGreenPrivateKey: values.PayGreenPrivateKey,
            AssignedStores: values.AssignedStores,
          };
          await mutateAsync(newCreateRestaurantVoucher);
        } catch (error) {
          console.log('error', error);
        }
      }}
      validate={(values) => {
        return validateRestaurantVoucherForm(values, translate);
      }}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormItemLayout label={<Translate id="Name" />}>
              <FieldWrapper>
                <Translate id="PayGreen_name_details" />
                <FormikInputField
                  fdKey="Name"
                  label={translate('Name') as string}
                  name="Name"
                  description="PayGreen_name_details"
                />
              </FieldWrapper>
            </FormItemLayout>
          </Grid>
          <GridDivider />
          <Grid item xs={12}>
            <FormItemLayout label={<Translate id="PayGreen_account_details" />}>
              <FieldWrapper>
                <FormikInputField
                  fdKey="PayGreen_ID"
                  label={translate('Id') as string}
                  name="PayGreenId"
                />

                <FormikInputField
                  fdKey="PayGreen_private_key"
                  label={translate('Private_key') as string}
                  name="PayGreenPrivateKey"
                />
              </FieldWrapper>
            </FormItemLayout>
          </Grid>
          <GridDivider />
          <Grid item xs={12}>
            <RestaurantVoucherAssignStoresField
              dataFd="restaurantVoucher-store-select"
              description="Assign_PayGreen_store_description"
              fieldLabel="Assign_stores"
              fieldName="AssignedStores"
              placeholder=""
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer size={16} variant="horizontal">
              <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    fdKey="btn-restaurant-voucher-account-create"
                    variant="contained"
                  >
                    <Translate id="Add_account" />
                  </Button>
                </Grid>
              </Grid>
            </Spacer>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(AddRestaurantVoucherForm);
export { EnhancedComponent as AddRestaurantVoucherForm };
