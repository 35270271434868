import { subscribe, unsubscribe } from '../services/signalr';
import { hubConstants } from '../signalr/hub.constants';

function checkState(state): boolean {
  return (
    state === undefined ||
    state.signalr === undefined ||
    state.signalr.connection === undefined ||
    state.signalr.connection.active === undefined
  );
}

export const signalrMiddleware = (store) => (next) => (action) => {
  const { type, hubName, eventName, eventHandler } = action;
  const state = store.getState();
  if (
    !(
      type === hubConstants.SIGNALR_SUBSCRIBE ||
      type === hubConstants.SIGNALR_UNSUBSCRIBE ||
      hubName ||
      eventName
    ) &&
    !checkState(state)
  ) {
    return next(action);
  }

  switch (type) {
    case hubConstants.SIGNALR_SUBSCRIBE:
      if (!eventHandler) {
        return next(action);
      }
      subscribe<object>(state.signalr.connection.active, hubName, eventName, eventHandler(store));
      return next({
        type: hubConstants.SIGNALR_SUBSCRIBED,
        event: eventName,
      });
    case hubConstants.SIGNALR_UNSUBSCRIBE:
      unsubscribe(state.signalr.connection.active, hubName, eventName);
      return next({
        type: hubConstants.SIGNALR_UNSUBSCRIBED,
        event: eventName,
      });
  }

  return next(action);
};
