import React, { useRef, useState } from 'react';

import { CreateGroup, Group, Product, ProductReference } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms/Button';
import { TextSearchField } from '@fd/ui/atoms/TextSearchField';
import { Typography } from '@fd/ui/atoms/Typography';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';

import { notify, NotifyProps } from '../../../../layouts/Notify/actions';
import { catalogGroupsService } from '../../services/catalogGroup.service';
import { catalogProductsService } from '../../services/catalogProducts.service';
import { CatalogError, GroupFormValues } from '../../types';
import { generateSku, validateModifierGroupForm } from '../../utils';
import { ModifierGroupSelectionType } from '../ModifierGroupSelectionType';
import { ModifierSelector } from './ModifierSelector';

const DEFAULT_LIMIT = 50;
const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: '408px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 9,
  },
  cancelButton: {
    width: '50%',
  },
  createButton: {
    width: '50%',
    marginLeft: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  createModifierButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  form: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  field: {
    minHeight: '5rem',
  },
  message: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  search: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

export type CreateModifierGroupDrawerSectionProps = {
  AppId: string;
  formValues?: GroupFormValues;
  onClose: () => void;
  onCreateModifierClick: (newModifierGroupFormValues: GroupFormValues) => void;
  onGroupCreated: (newGroup: Group) => void;
  translate: TranslateFunction;
};

const CreateModifierGroupDrawerSection = (
  props: CreateModifierGroupDrawerSectionProps & MappedDispatch
): JSX.Element => {
  const { AppId, formValues, onClose, notify, onCreateModifierClick, onGroupCreated, translate } =
    props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const formRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const classes = useStyles();

  const { data, isPending } = useQuery({
    queryKey: [
      catalogProductsService.getModifiersForGroupCreationQueryKey,
      AppId,
      page,
      DEFAULT_LIMIT,
      searchTerm,
    ],

    queryFn: () =>
      catalogProductsService.getCatalogProducts(AppId, page, DEFAULT_LIMIT, searchTerm, [
        'Modifier',
      ]),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (newModifierGroup: CreateGroup) => {
      return catalogGroupsService.createCatalogGroup(AppId, newModifierGroup);
    },

    onSuccess: (data) => {
      notify({
        variant: 'success',
        translate: true,
        message: 'Successfully_created',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogGroupsService.getModifiersGroupForProductQueryKey],
      });
      onGroupCreated(data.Data);
      if (formRef.current) {
        formRef.current.setSubmitting(false);
      }
    },

    onError: (error: CatalogError) => {
      notify({
        variant: 'error',
        translate: true,
        message: 'Products_ToastMessage_Error',
      });
      if (formRef.current) {
        formRef.current.setSubmitting(false);
        const errorMessage = translate('Product_CreateProductDialog_DuplicateSkuError') as string;
        error.Message.includes('SKU') && formRef.current.setFieldError('Sku', errorMessage);
      }
    },
  });

  const handleCreateModifierClick = () => {
    if (formRef.current) {
      onCreateModifierClick(formRef.current.values);
    }
  };

  const onAddModifier = (newModifier: Product) => {
    if (formRef.current) {
      const newItemRef = {
        CatalogItemId: newModifier.CatalogItemId,
        ProductType: ProductReference.ProductTypeEnum.Modifier,
        Product: newModifier,
      };
      formRef.current.setFieldValue('Products', [...formRef.current.values.Products, newItemRef]);
    }
  };

  const onRemoveModifier = (id: string) => {
    if (formRef.current) {
      const itemRefIndex = formRef.current.values.Products.findIndex((x) => x.CatalogItemId == id);
      const newItemRefs = [...formRef.current.values.Products];
      newItemRefs.splice(itemRefIndex, 1);
      formRef.current.setFieldValue('Products', newItemRefs);
    }
  };

  const handleMinMaxChange = (min: number, max: number) => {
    if (formRef.current) {
      formRef.current.setFieldValue('MinSelectCount', min);
      formRef.current.setFieldValue('MaxSelectCount', max);
    }
  };

  return (
    <div className={classes.container}>
      <Formik
        innerRef={formRef}
        initialValues={{
          MinSelectCount: formValues?.MinSelectCount || 0,
          MaxSelectCount: formValues?.MaxSelectCount || 1,
          Name: formValues?.Name || '',
          Sku: formValues?.Sku || generateSku('MG'),
          Products: formValues?.Products || [],
        }}
        onSubmit={async (values, { setFieldError }) => {
          try {
            const newModifierGroup = {
              ...values,
              GroupType: Group.GroupTypeEnum.ModifierGroup,
            };
            await mutateAsync(newModifierGroup);
          } catch (error) {
            console.log('error', error);
            const errorMessage = translate(
              'Product_CreateProductDialog_DuplicateSkuError'
            ) as string;
            if (error.message.includes('SKU')) {
              setFieldError('Sku', errorMessage);
            }
          }
        }}
        validate={(values) => {
          return validateModifierGroupForm(values, translate);
        }}
      >
        {({ handleSubmit, values }) => (
          <Form id="create-modifier-group-form" onSubmit={handleSubmit} className={classes.form}>
            <FormikInputField
              className={classes.field}
              fdKey={'create-modifier-group-name-field'}
              name="Name"
              label={translate('Name') as string}
            />
            <TextSearchField
              className={classes.search}
              clearAriaLabel={`${translate('Products_SearchBox_Clear')}`}
              dataFd="modifier-search"
              disableLabelAnimation
              label={`${translate('Search')}`}
              onChange={(value) => setSearchTerm(value)}
              placeholder={`${translate('Name')}`}
            />
            <ModifierSelector
              alreadySelectedModifiers={formValues?.Products}
              isLoading={isPending}
              data={data}
              onAddModifier={onAddModifier}
              onRemoveModifier={onRemoveModifier}
              onSetPage={(newPage) => setPage(newPage)}
              translate={translate}
            />
            <Box mb={2} mt={4}>
              <Typography variant="caption">
                <Translate id="Create_modifier_suggestion" />
              </Typography>
              <Button
                className={classes.createModifierButton}
                fdKey="create-new-modifier-button"
                onClick={handleCreateModifierClick}
                variant="secondary"
              >
                <Translate id="Create_new_modifier" />
              </Button>
            </Box>
            <Divider className={classes.divider} />
            <Typography variant="body1">
              <Translate id="SelectionType" />
            </Typography>
            <Box mb={4}>
              <ModifierGroupSelectionType
                minValue={values.MinSelectCount}
                maxValue={values.MaxSelectCount}
                onChange={handleMinMaxChange}
                totalModifiers={values.Products.length}
                translate={translate}
              />
            </Box>
            <FormikInputField
              className={classes.field}
              fdKey={'create-modifier-group-sku-field'}
              name="Sku"
              label="SKU"
            />
          </Form>
        )}
      </Formik>
      <div className={classes.actions}>
        <Divider />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.cancelButton}
            fdKey="create-modfier-group-cancel-button"
            onClick={onClose}
            variant="secondary"
          >
            <Translate id="Cancel" />
          </Button>
          <Button
            className={classes.createButton}
            form="create-modifier-group-form"
            disabled={formRef.current?.isSubmitting}
            type="submit"
            fdKey="create-modifier-group-confirm-button"
          >
            <Translate id="Create_modifier_group" />
          </Button>
        </div>
      </div>
    </div>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(CreateModifierGroupDrawerSection);
export { EnhancedComponent as CreateModifierGroupDrawerSection };
