import React, { ChangeEvent } from 'react';

import Switch from '@mui/material/Switch';
import { Translate } from 'react-localize-redux';

import FormItem from '../FormItem';

type Props = {
  canEdit: boolean;
  style?: string;
};

const IncludeAsap = ({ canEdit, style }: Props) => {
  return (
    <FormItem
      divider
      oneLine
      primary={<Translate id="Preorder_settings_asap_item_title" />}
      name="IncludeAsap"
    >
      {({ field, form }) => (
        <Switch
          className={style}
          value={field.value}
          color="default"
          inputProps={
            {
              'data-fd': 'pre_order_settings_includes_asap',
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
          checked={!!field.value}
          disabled={!canEdit}
          onChange={(e: ChangeEvent<HTMLInputElement>, value: boolean) => {
            form.setFieldValue(field.name, value);
          }}
        />
      )}
    </FormItem>
  );
};

export default IncludeAsap;
