import { formikValidate } from '../../helpers/validation';
import { ReactNode } from 'react';
import { ComponentProps } from '../Common';
import { EFieldType } from '../../models/EFieldType';
import { EValidationType } from '../../models/TValidationType';

export type ValidationItem<T = ComponentProps> = {
  type: EValidationType;
  expr: (props: T) => boolean;
  validateFn: (value, props: T) => ReactNode | undefined;
};

const validationsArray: Array<ValidationItem> = [
  {
    type: EValidationType.required,
    expr: (props) => !!props.required,
    validateFn: (val) => formikValidate.required(val),
  },
  {
    type: EValidationType.minLength,
    expr: (props) => props.minLength !== undefined,
    validateFn: (val, props) => formikValidate.minLength(props.minLength as number)(val),
  },
  {
    type: EValidationType.maxLength,
    expr: (props) => props.maxLength !== undefined,
    validateFn: (val, props) => formikValidate.maxLength(props.maxLength as number)(val),
  },
  {
    type: EValidationType.minValue,
    expr: (props) => props.min !== undefined,
    validateFn: (val, props) => formikValidate.min(props.min as number)(val),
  },
  {
    type: EValidationType.maxValue,
    expr: (props) => props.max !== undefined,
    validateFn: (val, props) => formikValidate.max(props.max as number)(val),
  },
  {
    type: EValidationType.email,
    expr: (props) => props.fieldType === EFieldType.Email,
    validateFn: (val) => formikValidate.email(val),
  },
  {
    type: EValidationType.phoneNumber,
    expr: (props) => props.fieldType === EFieldType.PhoneNumber,
    validateFn: (val) => formikValidate.phone(val, 'Store_phone_invalid_input'),
  },
];

export const getValidators = (props: ComponentProps): Array<ValidationItem> => {
  return validationsArray.filter((v) => v.expr(props));
};

export const validate = (validators, value, props: ComponentProps): ReactNode | undefined => {
  let result;

  validators.some((validationItem) => {
    if (validationItem.expr(props)) {
      result = validationItem.validateFn(value, props);
      return !!result;
    }
    return false;
  });

  return result;
};
