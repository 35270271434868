import React from 'react';

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import Home from './Home';

type Props = MappedProps & MappedDispatch & RouteComponentProps;

const HomeRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="home">
      <Switch>
        <Route path={`${path}`} component={Home} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {};
}
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  // TODO: hookup signalr events (if any)
});

export { HomeRoutes };
export default compose<Props, RouteComponentProps>(connect(mapStateToProps, mapDispatchToProps))(
  HomeRoutes
);
