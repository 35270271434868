import React, { useEffect, useState } from 'react';

import {
  CreateLocation,
  LocationAreaLocation,
  LocationAreaWithLocations,
  UpdateLocationArea,
} from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { type DropResult, DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../layouts/Notify/actions';
import { storeService } from '../../../../services/store.service';
import CreateLocationModal from './CreateLocationModal';
import DeleteLocationModal from './DeleteLocationModal';
import DeleteSectionModal from './DeleteSectionModal';
import EditNameModal from './EditNameModal';
import LocationListItem from './LocationListItem';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    flexDirection: 'column',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
    marginBottom: theme.spacing(2),
  },
  gridHeader: {
    width: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
  },
  gridIcons: {
    width: '50%',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
  gridExpanded: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  gridSearch: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  gridButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1.5),
  },
  listBox: {
    borderRadius: '5px',
    border: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    bgcolor: 'background.paper',
    maxHeight: 'calc(100vh * 0.6)',
    overflowY: 'auto',
  },
  listHeader: {
    backgroundColor: '#FFF',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2.5),
  },
  searchIcon: {
    color: 'rgb(0,0,0,0.4)',
  },
}));

type SectionDisplayProps = {
  locationArea: LocationAreaWithLocations;
  appId: string;
  storeId: string;
  canEdit: boolean;
  refetchLocationAreas: () => void;
  generateLocationArray: (
    locationArea: LocationAreaWithLocations,
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => CreateLocation[];
  translate: TranslateFunction;
  isTablePrefixEnabled: boolean;
};

type Props = SectionDisplayProps & MappedDispatch;

export const SectionDisplay = (props: Props) => {
  const {
    locationArea,
    appId,
    storeId,
    canEdit,
    refetchLocationAreas,
    generateLocationArray,
    closeNotifySaving,
    notifyError,
    notifySaving,
    notifySaved,
    translate,
    isTablePrefixEnabled,
  } = props;

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditSectionModal, setOpenEditSectionModal] = useState<boolean>(false);
  const [openCreateLocationModal, setOpenCreateLocationModal] = useState<boolean>(false);
  const [openEditLocationModal, setOpenEditLocationModal] = useState<boolean>(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationAreaLocation>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [locationsStateArray, setLocationsStateArray] = useState<
    LocationAreaLocation[] | undefined
  >(locationArea.Locations);

  const settingsOpen = Boolean(anchor);
  const styles = useStyles();

  const theme = useTheme();

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const isListItemDragDisabled = !!searchValue?.length || isMobile;

  useEffect(() => {
    setLocationsStateArray(locationArea.Locations);
  }, [locationArea.Locations]);

  const handleSettingsClose = () => {
    setAnchor(null);
  };

  const handleSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDeleteModal = () => {
    handleSettingsClose();
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleEditModal = () => {
    handleSettingsClose();
    setOpenEditSectionModal(!openEditSectionModal);
  };

  const handleCreateLocationModal = () => {
    setOpenCreateLocationModal(!openCreateLocationModal);
  };

  const handleDeleteLocationModal = (location: LocationAreaLocation) => {
    setSelectedLocation(location);
    setOpenDeleteLocationModal(!openDeleteLocationModal);
  };

  const handleEditLocationModal = (location: LocationAreaLocation) => {
    setSelectedLocation(location);
    setOpenEditLocationModal(!openEditLocationModal);
  };

  const onDeleteSection = async () => {
    try {
      const areaId = locationArea.LocationAreaId?.toString();
      const updateLocationArea: UpdateLocationArea = {
        IsDeleted: true,
        LocationAreaId: locationArea.LocationAreaId,
        LocationAreaName: locationArea.LocationAreaName,
      };
      notifySaving();
      await storeService.updateLocationArea(appId, parseInt(storeId), areaId, updateLocationArea);
      closeNotifySaving();
      notifySaved();
      setOpenDeleteModal(!openDeleteModal);
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const onEditSection = async (newName: string) => {
    try {
      const areaId = locationArea.LocationAreaId?.toString();
      const update: UpdateLocationArea = {
        IsDeleted: false,
        LocationAreaId: locationArea.LocationAreaId,
        LocationAreaName: newName,
      };
      notifySaving();
      await storeService.updateLocationArea(appId, parseInt(storeId), areaId, update);
      closeNotifySaving();
      notifySaved();
      setOpenEditSectionModal(!openEditSectionModal);
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const onCreateLocations = async (
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => {
    const locations: CreateLocation[] = generateLocationArray(
      locationArea,
      numberOfLocations,
      startingNumber,
      tablePrefix
    );

    try {
      notifySaving();
      await storeService.createLocations(
        locationArea.LocationAreaId,
        appId,
        parseInt(storeId),
        locations
      );
      closeNotifySaving();
      notifySaved();
      handleCreateLocationModal();
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const onDeleteLocation = async () => {
    try {
      notifySaving();
      const areaId = locationArea.LocationAreaId;
      await storeService.deleteLocation(
        selectedLocation?.LocationId ?? 0,
        areaId,
        appId,
        parseInt(storeId)
      );
      closeNotifySaving();
      notifySaved();
      setOpenDeleteLocationModal(false);
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const onEditLocation = async (newName: string) => {
    try {
      notifySaving();
      const areaId = locationArea.LocationAreaId;
      const create: CreateLocation = {
        LocationName: newName,
        LocationId: selectedLocation?.LocationId,
        DisplayOrder: selectedLocation?.DisplayOrder,
      };
      await storeService.updateLocation(
        areaId,
        selectedLocation?.LocationId ?? 0,
        appId,
        parseInt(storeId),
        create
      );
      closeNotifySaving();
      notifySaved();
      setOpenEditLocationModal(false);
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const dropListItem = async (result: DropResult) => {
    updateStateArrayOnDrop(result);

    if (!!locationsStateArray?.length && result.destination) {
      const locationToUpdate = locationsStateArray.find(
        (location) => location.LocationId === parseInt(result.draggableId)
      );
      const dropLocation = locationsStateArray[result.destination?.index];

      if (locationToUpdate && locationToUpdate.DisplayOrder !== dropLocation.DisplayOrder) {
        locationToUpdate.DisplayOrder = dropLocation.DisplayOrder;
        notifySaving();
        await storeService.updateLocation(
          locationArea.LocationAreaId,
          locationToUpdate.LocationId,
          appId,
          parseInt(storeId),
          locationToUpdate
        );
        closeNotifySaving();
        notifySaved();
        refetchLocationAreas();
      }
    }
  };

  const updateStateArrayOnDrop = (result: DropResult) => {
    if (!!locationsStateArray?.length && result.destination) {
      const newLocationsArray: LocationAreaLocation[] = JSON.parse(
        JSON.stringify(locationsStateArray)
      );
      const [reorderedItem] = newLocationsArray.splice(result.source.index, 1);
      newLocationsArray.splice(result.destination?.index, 0, reorderedItem);

      setLocationsStateArray(newLocationsArray);
    }
  };

  return (
    <>
      <Grid container className={styles.box}>
        <Grid container direction="row">
          <Grid item container className={styles.gridHeader}>
            <Typography variant="subtitle1">{locationArea.LocationAreaName}</Typography>
            <Typography variant="caption">
              {locationArea.Locations?.length + ' '}
              <Translate id={'tables'} />
            </Typography>
          </Grid>

          <Grid item container className={styles.gridIcons}>
            <IconButton
              id="locationArea-settings"
              aria-label="locationArea-settings"
              onClick={handleSettingsMenu}
              disabled={!canEdit}
            >
              <MoreVertIcon />
            </IconButton>
            <IconButton
              id="locationArea-expand"
              aria-label="locationArea-expand"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          </Grid>
        </Grid>

        {expanded && (
          <Grid container item direction="column">
            <Divider />
            <Grid container item spacing={2} className={styles.gridExpanded}>
              <Grid item className={styles.gridSearch}>
                <TextField
                  id="search-table-input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon className={styles.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  label={<Translate id="Search_table" />}
                  placeholder={translate('Name') as string}
                  variant="outlined"
                  fullWidth
                  value={searchValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleSearchInput(e);
                  }}
                  disabled={locationArea.Locations?.length === 0}
                />
              </Grid>
              <Grid item className={styles.gridButton}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  id="add-location-button"
                  onClick={handleCreateLocationModal}
                  disabled={!canEdit}
                >
                  <AddIcon className={styles.buttonIcon} />
                  <Translate id="Add_new_tables" />
                </Button>
              </Grid>
            </Grid>

            {!!locationsStateArray?.length && (
              <DragDropContext onDragEnd={dropListItem}>
                <Droppable droppableId="locationListItems">
                  {(provided) => (
                    <List
                      className={styles.listBox}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      subheader={
                        <ListSubheader disableGutters={true}>
                          <Typography variant="subtitle1" className={styles.listHeader}>
                            <Translate id={'Name'} />
                          </Typography>
                        </ListSubheader>
                      }
                    >
                      {locationsStateArray
                        .filter((location) => location.LocationName?.includes(searchValue))
                        .map((location, index) => {
                          return (
                            <Draggable
                              key={location.LocationId}
                              draggableId={location.LocationId.toString()}
                              index={index}
                              isDragDisabled={isListItemDragDisabled}
                            >
                              {(provided) => {
                                return (
                                  <LocationListItem
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    innerRef={provided.innerRef}
                                    key={location.LocationId}
                                    location={location}
                                    canEdit={canEdit}
                                    onClickDelete={handleDeleteLocationModal}
                                    onClickEdit={handleEditLocationModal}
                                    isDraggable={!isListItemDragDisabled}
                                  />
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>
        )}
      </Grid>

      <Menu
        open={settingsOpen}
        anchorEl={anchor}
        onClose={handleSettingsClose}
        id="locationArea-option"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <MenuItem key="edit" onClick={handleEditModal}>
          <EditIcon className={styles.menuIcon} />
          <Translate id="Location_section_edit" />
        </MenuItem>
        <MenuItem key="delete" onClick={handleDeleteModal}>
          <DeleteIcon className={styles.menuIcon} />
          <Translate id="Location_section_delete" />
        </MenuItem>
      </Menu>

      {openEditSectionModal && (
        <EditNameModal
          descriptionId="Location_section_edit_description"
          name={locationArea.LocationAreaName}
          onClose={() => setOpenEditSectionModal(false)}
          onConfirm={onEditSection}
          title="Location_section_edit"
        />
      )}

      {openDeleteModal && (
        <DeleteSectionModal onClose={() => setOpenDeleteModal(false)} onConfirm={onDeleteSection} />
      )}

      {openCreateLocationModal && (
        <CreateLocationModal
          onClose={handleCreateLocationModal}
          onConfirm={onCreateLocations}
          isTablePrefixEnabled={isTablePrefixEnabled}
          translate={translate}
        />
      )}

      {openDeleteLocationModal && (
        <DeleteLocationModal
          onConfirm={onDeleteLocation}
          onClose={() => setOpenDeleteLocationModal(false)}
        />
      )}

      {openEditLocationModal && (
        <EditNameModal
          descriptionId="Location_edit_description"
          name={selectedLocation?.LocationName}
          onClose={() => setOpenEditLocationModal(false)}
          onConfirm={onEditLocation}
          title="Location_edit"
        />
      )}
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(null, mapDispatchToProps)(SectionDisplay);
