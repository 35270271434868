import React from 'react';

import List from '@mui/material/List';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import { toKebabCase } from '../../../helpers/strings';
import type { RmsReportGroup } from './Reports';

type Props = MappedState & {
  reportGroup: RmsReportGroup;
};

const ReportList = (props: Props) => {
  const { translate, reportGroup, currentApp } = props;
  const { documentTitle, title, caption, reports } = reportGroup;

  const homeLink = `/${currentApp.AppId}/reports/dashboards?tab=rms`;
  const reportPath = `/${currentApp.AppId}/reports/rms`;

  return (
    <PageLayout
      title={translate(title)}
      caption={translate(caption)}
      documentTitle={documentTitle}
      toParent={homeLink}
    >
      <PaperContainer fluid>
        <List aria-label={translate(title) as string}>
          {reports.map((report, index) => (
            <ListItemLinkButton
              key={report.path}
              title={report.title}
              subtitle={report.subtitle}
              iconComponent={report.icon}
              link={`${reportPath}${toKebabCase(report.path)}`}
              isLast={index === reports.length - 1}
            />
          ))}
        </List>
      </PaperContainer>
    </PageLayout>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
  };
};

export default connect(mapStateToProps)(ReportList);
