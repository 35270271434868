import * as React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

export interface IPageTitleProps {
  preTranslatedTitleText?: string;
  translateId?: TranslationId;
}

const useStyles = makeStyles((theme: Theme) => ({
  betaTagStyles: {
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    borderRadius: '3px',
    color: '#ffffff',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0, 1),
    fontSize: '12px',
  },
}));

export const PageTitleWithBeta = ({
  preTranslatedTitleText = '',
  translateId = 'Title',
}: IPageTitleProps) => {
  const classes = useStyles();

  const titleText =
    preTranslatedTitleText === '' ? <Translate id={translateId} /> : preTranslatedTitleText;

  return (
    <>
      {titleText}
      <span className={classes.betaTagStyles}>
        <Translate id="Beta" />
      </span>
    </>
  );
};

export default PageTitleWithBeta;
