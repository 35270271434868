import React, { useCallback } from 'react';

import { OauthClientRedirectUri } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { type FieldProps, type FormikProps, Field, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import { LoadingButton } from '../../../ui/LoadingButton';

type Props = {
  items: OauthClientRedirectUri[];
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  submit: (changes) => Promise<void>;
  isLoading: boolean | undefined;
};
export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = () => {
  return {
    Uri: '',
  };
};

const OAuthAppCreateRedirectUriForm = (props: FormikProps<FormValues> & Props) => {
  const validate = useCallback(
    (uri: string) => {
      if (uri.length === 0) {
        return 'Required';
      }
      if (
        // eslint-disable-next-line no-useless-escape
        !/^(https:\/\/)?[a-z0-9]+([\-\.]{1,}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(uri)
      ) {
        return 'Uri_not_valid';
      }

      if (props.items.find((item) => item.Uri === `https://${uri}`)) {
        return 'Uri_is_duplicated';
      }

      return;
    },
    [props.items]
  );
  const { setDialogOpen, isDialogOpen } = props;
  const dialogContent = [
    <DialogContent key="dialog-content">
      <Field name="Uri" validate={validate}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          return (
            <TextField
              variant="standard"
              {...field}
              label={<Translate id="Redirect_uri" />}
              disabled={isSubmitting}
              error={showError}
              helperText={showError ? <Translate id={fieldError as 'Required'} /> : null}
              fullWidth
              type="url"
              inputProps={{ 'data-fd': 'Redirect_uri_uri' }}
              // Reset error when focusing the input field
              onFocus={(e) => {
                if (errors.Uri) {
                  form.setFieldError('Uri', '');
                }
              }}
              // Force lowercase on input
              onChange={(e) => form.setFieldValue('Uri', e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="body1" color="inherit">
                      https://
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      </Field>
    </DialogContent>,
    <DialogActions key="actions">
      <Button
        color="primary"
        data-fd="Add_redirect_uri_cancel"
        onClick={() => setDialogOpen(false)}
      >
        <Translate id="Cancel" />
      </Button>
      <LoadingButton
        color="primary"
        fdKey="Add_redirect_uri_create"
        onClick={(e) => props.handleSubmit()}
        disabled={props.isSubmitting}
        loading={props.isLoading}
      >
        <Translate id="Add" />
      </LoadingButton>
    </DialogActions>,
  ];

  return (
    <Form>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          props.resetForm();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogContent}
      </Dialog>
    </Form>
  );
};

export default withFormik<Props, FormValues>({
  displayName: 'OAuthAppCreateRedirectUriForm',
  mapPropsToValues: () => {
    return getDefaultFormState();
  },
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    const { Uri } = values;
    formikBag.props.submit(`https://${Uri}`).then(() => {
      formikBag.resetForm();
      formikBag.props.setDialogOpen(false);
    });
  },
})(OAuthAppCreateRedirectUriForm);
