import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from 'react-loading-skeleton';

const DetailsLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <List component="div">
      <ListItem component="div" ContainerComponent="div" alignItems="center">
        <ListItemAvatar>
          <Skeleton circle width={40} height={40} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton width={'60%'} height={22} />} />
      </ListItem>
    </List>
  );
};

export default DetailsLoading;
