import React, { useEffect, useState } from 'react';

import { AppConfigSalesChannel } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { FormSection } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7),
    },
  },
}));

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (updatedSetting: Partial<AppConfigSalesChannel>) => void;
  translate: TranslateFunction;
  value?: AppConfigSalesChannel.EmailRequestModeEnum;
};

export const RequestCustomersEmailSelectFilter = (props: Props) => {
  const { isDisabled, isLoading, onChange, translate, value } = props;
  const [selectedValue, setSelectedValue] = useState<AppConfigSalesChannel.EmailRequestModeEnum>(
    value || AppConfigSalesChannel.EmailRequestModeEnum.DoNotRequest
  );
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange({ EmailRequestMode: event.target.value });
  };

  return (
    <FormSection
      isLoading={isLoading}
      sectionTitle={translate('Request_customers_email') as string}
    >
      <div className={classes.selectContainer}>
        <TextField
          data-fd="set-customers-email"
          fullWidth
          disabled={isDisabled}
          label={translate('Preference') as string}
          onChange={handleChange}
          select
          value={selectedValue}
          variant="outlined"
        >
          <MenuItem value={AppConfigSalesChannel.EmailRequestModeEnum.DoNotRequest}>
            <Translate id="Do_not_request" />
          </MenuItem>

          <MenuItem value={AppConfigSalesChannel.EmailRequestModeEnum.Request}>
            <Translate id="Request" />
          </MenuItem>

          <MenuItem value={AppConfigSalesChannel.EmailRequestModeEnum.Require}>
            <Translate id="Require" />
          </MenuItem>
        </TextField>
      </div>
    </FormSection>
  );
};
