import React from 'react';

import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@fd/ui/atoms/Button';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { checkoutActions } from '../../../../actions/checkout.actions';
import { useTracking } from '../../../../services/amplitude/useTracking';
import { renderCurrency } from '../../../Finance/Payouts/helpers';
import { EntitlementPricing, PricingOccurrence } from '../data/PricingInformation';

const useStyles = makeStyles((theme: Theme) => ({
  pricing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
}));

export type FeaturePricingInformationProps = {
  entitlementPricing: EntitlementPricing;
  returnPathOverride?: string;
};

type Props = FeaturePricingInformationProps & MappedState & MappedDispatch;

const FeaturePricingInformation = ({
  appId,
  entitlementPricing,
  setBasketItems,
  returnPathOverride,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent, trackNavigationEvent } = useTracking();

  const bundle = entitlementPricing.bundles[0];
  const priceInformation = bundle.prices[0];
  const monthlyPrice =
    priceInformation.rucurring === PricingOccurrence.Yearly
      ? priceInformation.amount / 12
      : priceInformation.amount;

  const handleClick = async () => {
    const mainSubscriptionPriceId = bundle!.prices[0]!.priceId;
    const addOnPriceIds = bundle!.addOns.map((addOn) => addOn.priceId);
    setBasketItems(mainSubscriptionPriceId, addOnPriceIds, returnPathOverride);
    trackEvent('Clicked subscribe now');

    trackNavigationEvent('Navigating to checkout');
    history.push(`/${appId}/billing/checkout`);
  };

  return (
    <PaperContainer hasBorderOnMobile>
      <Grid container>
        <Grid item xs={12} sm md lg>
          <Typography variant="h6">
            <Translate id="Checkout_Pricing" />
          </Typography>
          <Typography variant="h3" component="p" className={classes.pricing}>
            {renderCurrency(
              entitlementPricing.currency,
              entitlementPricing.languageCode,
              monthlyPrice
            )}{' '}
            <Typography variant="caption" component="span" className={classes.lowercase}>
              /<Translate id="Month" />
            </Typography>
            {priceInformation.rucurring === PricingOccurrence.Yearly && (
              <Typography variant="caption" component="p">
                <Translate id="Billed_Annually" />
              </Typography>
            )}
          </Typography>
          <Button
            variant="primary"
            onClick={() => handleClick()}
            type="button"
            fdKey="subscribe-now"
          >
            <Translate id="Subscribe_Now" />
          </Button>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    setBasketItems: (priceId: string, addOns: string[], returnPathOverride: string | undefined) =>
      dispatch(
        checkoutActions.setBasketItems(
          {
            items: [priceId],
            addOns,
          },
          returnPathOverride
        )
      ),
  };
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId!,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturePricingInformation);
