import React, { useCallback, useState } from 'react';

import GetApp from '@mui/icons-material/GetApp';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { notifyError, NotifyProps } from '../../layouts/Notify/actions';
import Button from '../Button/Button';

type InnerProps = MappedDispatch;
type OuterProps = { onClick: (() => Promise<void>) | (() => void); isMobile: boolean };
type Props = InnerProps & OuterProps;

const DownLoadCsvPdf = ({ onClick, isMobile, notifyError }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      await onClick();
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    } catch (e) {
      setLoading(false);
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [onClick]);
  return (
    <>
      <Button
        color="primary"
        type="button"
        fdKey="Download-csv"
        onClick={handleClick}
        disabled={loading}
        variant="text"
      >
        <GetApp color="primary" style={{ fontSize: 15, margin: '0px 6px' }} />
        {isMobile ? 'CSV' : <Translate id="Download_CSV" />}
      </Button>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect(null, mapDispatchToProps)(DownLoadCsvPdf);
