import React from 'react';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Grid, { GridProps } from '@mui/material/Grid';

export type LoadingProps = GridProps & Pick<CircularProgressProps, 'size' | 'color'>;
function Loading({ size, color, ...props }: LoadingProps) {
  return (
    <Grid container justifyContent="center" alignItems="center" {...props}>
      <CircularProgress size={size} color={color} />
    </Grid>
  );
}

export default Loading;
