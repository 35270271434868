import React, { useEffect, useState } from 'react';

import { useQueryPendingMenuChangesSummaryHook } from '@fd/customHooks/useQueryPendingMenuChangesSummaryHook';
import { App, MenuSummary } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router';

import { StoreOption } from '@fd/ui/Filter/StoreFilterInfiniteScroll';

import { menusActions } from '../../actions/menus.actions';
import { closeAllOtherNotifications, ERROR_KEY, notifyError } from '../../layouts/Notify/actions';
import CreateMenuDialog from '../../layouts/Portal/CreateMenuDialog';
import CreateNewButton from '../../layouts/Portal/CreateNewButton';
import { isProductBasedMenusEnabled } from '../../selectors/app.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import { catalogChangesService } from '../../services/catalogChanges.service';
import PageLayout from '../../ui/Layout';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import MenusFilter from './components/filters/MenusFilter';
import { MenuCard } from './components/MenuCard';
import { PendingMenuChangesBanner } from './components/PendingMenuChangesBanner';
import { getUnassignedMenus } from './Menus.selectors';

const useStyles = makeStyles((theme: Theme) => ({
  bannerContainer: {
    marginBottom: theme.spacing(2),
  },
  createNewButtonContainer: {
    bottom: 24,
    position: 'fixed',
    right: 24,
  },
  menuCardList: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    listStyleType: 'none',
    margin: '-12px',
    padding: 0,
    width: 'calc(100% + 24px)',

    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid rgba(0, 0, 0, 0.0912)',
      margin: '0',
      width: '100%',
    },
  },
  menuCardListItem: {
    flexBasis: '50%',
    padding: '12px',

    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      padding: '0',
    },
  },
}));

type Props = MappedState & { dispatch: ThunkDispatch } & RouteComponentProps;

const Menus = (props: Props) => {
  const {
    currentApp,
    dispatch,
    history,
    isProductBasedMenusOn,
    menus,
    menusAppId,
    menusTimestamp,
    translate,
    unassignedMenus,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [searchStores, setSearchStores] = useState<StoreOption[]>([]);
  const [viewUnassignedMenus, setViewUnassignedMenus] = useState<boolean>(false);
  const [customSelectedStores, setCustomSelectedStores] = useState<StoreOption[] | undefined>(
    undefined
  );
  const classes = useStyles();

  const { data } = useQueryPendingMenuChangesSummaryHook(
    [catalogChangesService.getPendingMenuChangesSummaryQueryKey, currentApp.AppId],
    { appId: currentApp.AppId },
    isProductBasedMenusOn
  );

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_menus', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    const AppId = currentApp.AppId || 'global';
    if (AppId != menusAppId) {
      dispatch(menusActions.setMenusAppId(AppId));
      dispatch(menusActions.getMenus(AppId));
    }
  }, []);

  useEffect(() => {
    const AppId = currentApp.AppId || 'global';
    dispatch(menusActions.setMenusAppId(AppId));
    dispatch(menusActions.getMenus(AppId));
  }, [currentApp]);

  useEffect(() => {
    if (searchStores) {
      const newMenus = menus.filter((menu) => {
        return searchStores.some((store) => {
          return menu.StoreNames.includes(store.label);
        }, []);
      }, []);
      setFilteredMenus(newMenus);
    }

    if (viewUnassignedMenus) {
      setFilteredMenus(unassignedMenus);
      setCustomSelectedStores([]);
    } else {
      setCustomSelectedStores(undefined);
    }
  }, [searchStores, viewUnassignedMenus]);

  const navigateToMenu = (menuId: number) => {
    const url = `${window.location.pathname}/${menuId}`;
    history.push(url);
  };

  const createNewMenu = async () => {
    try {
      const AppId = currentApp.AppId || 'global';
      const menuId = await dispatch(menusActions.createMenu(AppId));
      await dispatch(menusActions.getMenus(AppId));
      navigateToMenu(menuId);
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };

  const getMenuCardChanges = (menuId?: number) => {
    if (menuId) {
      const change = data?.find((change) => change.MenuId === menuId);
      return change?.TotalPendingChanges || 0;
    }
    return 0;
  };

  const renderMenuListItems = (menus) => {
    return menus.map((menu: MenuSummary, index) => {
      const menuChanges = getMenuCardChanges(menu.MenuId);
      return (
        <li
          className={classes.menuCardListItem}
          key={`menucard-${menu.MenuId}`}
          aria-label={menu.Name}
        >
          <MenuCard
            AppId={currentApp.AppId}
            IsLast={index === menus.length - 1}
            key={menu.MenuId}
            MenuId={menu.MenuId}
            ModifiedTime={menu.ModifiedTime as unknown as Date}
            Name={menu.Name}
            ProductChanges={menuChanges}
            StoreNames={menu.StoreNames}
          />
        </li>
      );
    });
  };

  const renderContent = () => {
    if (menusTimestamp) {
      return <AppLoadingCircularProgress />;
    }
    const totalChanges = data?.reduce(function (prev, current) {
      if (current.TotalPendingChanges) {
        return prev + current.TotalPendingChanges;
      }
      return prev;
    }, 0);
    return (
      <>
        <MenusFilter
          customSelectedStores={customSelectedStores}
          setSearchStores={setSearchStores}
          setViewUnassignedMenus={setViewUnassignedMenus}
          viewUnassignedMenus={viewUnassignedMenus}
        />
        {isProductBasedMenusOn && data && data.length > 0 && (
          <div className={classes.bannerContainer}>
            <PendingMenuChangesBanner
              appId={currentApp.AppId || 'global'}
              numberOfChanges={totalChanges || 0}
              translate={translate}
            />
          </div>
        )}
        <ul className={classes.menuCardList}>
          {searchStores && filteredMenus.length > 0
            ? renderMenuListItems(filteredMenus)
            : renderMenuListItems(menus)}
        </ul>
      </>
    );
  };
  return (
    <PageLayout
      title={<Translate id="Menus" />}
      auditLogsFilter={{ type: 'EventType', value: 'menu.*' }}
    >
      {renderContent()}
      <Permissions allowed={[App.AppResourceSetEnum.CreateMenu]}>
        <div className={classes.createNewButtonContainer}>
          <CreateNewButton translateId="New_menu" onClick={() => setIsDialogOpen(true)} />
          {isDialogOpen && (
            <CreateMenuDialog
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              createNewMenu={createNewMenu}
            />
          )}
        </div>
      </Permissions>
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState) => {
    const { currentApp, menus } = state;
    const unassignedMenus = getUnassignedMenus(state);

    const hasUserAllStoreAccess =
      currentApp?.AppAccessLevel === App.AppAccessLevelEnum.Owner ||
      currentApp.AppAccessLevel === App.AppAccessLevelEnum.ManagedOwner;

    return {
      currentApp: currentApp,
      hasUserAllStoreAccess,
      isProductBasedMenusOn: isProductBasedMenusEnabled(state),
      menus: menus.menus,
      menusAppId: menus.menusAppId,
      menusTimestamp: menus.menusTimestamp,
      translate: getTranslate(state),
      unassignedMenus,
    };
  };
};

const EnhancedComponent = connect(mapStateToPropsFactory)(Menus);
export { EnhancedComponent as Menus };
