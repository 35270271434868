import * as React from 'react';

import EditOutlined from '@mui/icons-material/EditOutlined';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Button from '../../../../ui/Button/Button';

const styles = () =>
  createStyles({
    button: {
      justifyContent: 'flex-start',
      width: '100%',
      position: 'relative',
      border: '1px solid transparent',
      padding: 0,
      textTransform: 'none',
      borderRadius: 0,
      '&:hover': {
        border: '1px solid #4a90e2',
        backgroundColor: 'rgba(74, 144, 226, 0.1)',

        '& div': {
          visibility: 'visible',
        },
      },
      '& p, & span': {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        lineHeight: 1.1,
        textAlign: 'left',
        display: ' inline-block',
      },
    },
    caption: {
      visibility: 'hidden',
      height: 24,
      padding: '0 5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: -24,
      left: -1,
      background: '#4a90e2',
      color: '#fff',
      fill: '#fff',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    input: {
      borderBottom: '1px solid #4a90e2',
      '& .MuiInputBase-input': {
        padding: '2px 1px',
        background: 'rgba(74, 144, 226, 0.1)',
        // @ts-ignore TODO test whether this is needed
        // ...overrides.MuiTypography.h5,
        lineHeight: '23px',
      },
    },
    captionInput: {
      '& .MuiSelect-root': {
        // @ts-ignore TODO test whether this is needed
        // ...overrides.MuiTypography.caption,
        lineHeight: '25px',
        padding: 0,
      },
    },
  });

type Props = {
  textType?: 'title' | 'caption';
  type?: 'select';
  disabled?: boolean;
  labelClassName?: string;
  focusLabelClassName?: string;
  labelKey: string;
  labelTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  labelTooltip?: string;
  labelAppendix?: React.ComponentType<React.PropsWithChildren<unknown>>;
  labelOverlay?: React.ComponentType<React.PropsWithChildren<{}>> | null;
  inputKey: string;
  inputClassName?: string;
  buttonClassName?: string;
  inputProps?: {
    label: string;
    helperText?: string;
    error?: boolean;
  };
  value: string;
  changePropName: string;
  selectData?: Array<{ key: string; value: string }>;
  autoFocus?: boolean;
  disableButtonAutoFocus?: boolean;
  onChange?: (newValue: any) => any | Promise<any>;
  translate: (input: string) => string;
} & WithStyles<typeof styles>;

interface IState {
  initialValue: string;
  internalValue: string;
  isFocused: boolean;
  isInput: boolean;
  isStoreNameEmpty: boolean;
  errorText: string;
}

class EditableLabel extends React.Component<Props, IState> {
  public static defaultProps = {
    selectData: [],
  };

  private currentInput: any;

  public constructor(props) {
    super(props);
    this.state = {
      initialValue: this.props.value,
      internalValue: this.props.value,
      isFocused: false,
      isInput: false,
      isStoreNameEmpty: false,
      errorText: '',
    };
  }

  public componentDidUpdate(oldProps) {
    if (this.currentInput && this.currentInput.getDOMNode) {
      this.currentInput.getDOMNode().focus();
    }
    if (this.props.value != oldProps.value) {
      this.setState({
        initialValue: this.props.value,
        internalValue: this.props.value,
      });
    }
  }

  public render() {
    return this.state.isInput && !this.props.disabled ? this.renderField() : this.renderText();
  }

  private renderText = () => {
    const {
      translate,
      labelKey,
      labelClassName,
      labelOverlay: LabelOverlay,
      focusLabelClassName,
      labelTooltip,
      labelTooltipPlacement,
      labelAppendix: Appendix,
      autoFocus,
      classes,
      disableButtonAutoFocus,
      buttonClassName,
      textType,
    } = this.props;

    const label = (
      <Button
        fdKey={labelKey}
        data-fd={labelKey}
        onClick={this.onFocused}
        autoFocus={autoFocus && !disableButtonAutoFocus}
        onFocus={() => {
          this.setState({ isFocused: true });
        }}
        className={`${classes.button} ${buttonClassName}`}
        onBlur={() => {
          this.setState({ isFocused: false });
        }}
      >
        {LabelOverlay ? <LabelOverlay /> : null}
        <Typography
          variant={textType === 'caption' ? 'caption' : 'h5'}
          component={textType === 'caption' ? 'h3' : 'h2'}
          className={this.state.isFocused ? focusLabelClassName : labelClassName}
        >
          {this.state.internalValue}
          {Appendix && (
            <>
              {' '}
              <Appendix />
            </>
          )}
          <div className={classes.caption}>
            <EditOutlined />
            <div>{translate('Edit')}</div>
          </div>
        </Typography>
      </Button>
    );

    if (labelTooltip) {
      return (
        <Tooltip title={labelTooltip} placement={labelTooltipPlacement}>
          {label}
        </Tooltip>
      );
    } else {
      return label;
    }
  };

  private changeValue = (event) => {
    this.setState(
      { internalValue: event.target.value, isStoreNameEmpty: false, errorText: '' },
      () => {
        if (this.props.type === 'select') {
          this.onFocusLoss();
        }
      }
    );
  };

  private onFocused = () => {
    if (!this.props.disabled) {
      this.setState({ isInput: true });
    }
  };

  private onFocusLoss = () => {
    const { onChange, translate } = this.props;

    if (onChange && this.state.initialValue !== this.state.internalValue) {
      this.handleChange();
    }

    if (this.state.internalValue.length === 0) {
      this.setState({
        isStoreNameEmpty: true,
        isInput: true,
        errorText: translate('This_field_cannot_be_empty'),
      });
    } else {
      this.setState({ isInput: false });
      this.currentInput = undefined;
    }
  };

  private onKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        this.handleChange();
        this.setState({ isInput: false });
        this.currentInput = undefined;
        break;
      case 'Escape':
        this.setState({
          isInput: false,
          internalValue: this.state.initialValue,
        });
        this.currentInput = undefined;
        break;
    }
  };

  private handleChange() {
    const { changePropName, onChange, value: previousValue } = this.props;

    if (onChange) {
      const promise = onChange({ [changePropName]: this.state.internalValue });

      if (promise && promise instanceof Promise) {
        promise.catch(() =>
          this.setState({
            internalValue: previousValue,
          })
        );
      }
    }
  }

  private renderField = () => {
    const {
      inputKey,
      type,
      selectData,
      inputClassName = '',
      inputProps,
      classes,
      textType,
    } = this.props;

    return (
      <TextField
        variant="standard"
        fullWidth
        value={this.state.internalValue}
        onKeyDown={this.onKeyDown}
        onBlur={this.onFocusLoss}
        className={`${classes.input} ${
          textType === 'caption' ? classes.captionInput : ''
        } ${inputClassName}`}
        onChange={this.changeValue}
        margin="none"
        select={type === 'select'}
        autoFocus={this.props.autoFocus || false}
        error={this.state.isStoreNameEmpty}
        helperText={this.state.errorText}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{ 'data-fd': inputKey }}
        {...inputProps}
      >
        {selectData!.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    );
  };
}

function mapStateToProps(state) {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
}

export default compose<any, any>(withStyles(styles), connect(mapStateToProps))(EditableLabel);
