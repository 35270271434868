import React from 'react';

import { AppCompliance } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { MessageBanner } from '@fd/ui/atoms';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';

import { EFieldType } from '../../../models/EFieldType';
import { FkRadioGroup } from '../../../ui/FkRadioGroup/FkRadioGroup';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';

export type FormValues = {
  complianceType: AppCompliance.ComplianceTypeEnum;
};
export const getDefaultFormState = (props: InnerProps): FormValues => {
  return {
    complianceType: props.complianceType,
  };
};
type InnerProps = {
  translate: TranslateFunction;
  complianceType: AppCompliance.ComplianceTypeEnum;
  allowDisablingOfGdpr?: boolean;
  onSubmit: (values: FormValues) => void;
};
type OuterProps = {};
type Props = InnerProps & OuterProps;

const useStyles = makeStyles({
  banner: {
    borderRadius: '5px, 5px',
    width: '100%',
    backgroundColor: '#FFF2D5',
  },
  container: {
    paddingTop: 30,
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 24,
    marginBottom: 30,
  },
  formHeaderContainer: {
    marginBottom: 10,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  formHeader: {
    paddingTop: 16,
    paddingBottom: 18,
  },
  divider: {
    marginTop: '16px',
    width: '100%',
  },
});

const CookieSettingsForm = ({
  isSubmitting,
  handleSubmit,
  allowDisablingOfGdpr,
  dirty,
  translate,
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles();
  return (
    <Form>
      <PaperContainer fluid>
        {!allowDisablingOfGdpr && (
          <MessageBanner
            className={classes.banner}
            fdKey="gdpr-banner"
            icon={<InfoIcon color="#FFBC2C" />}
            message={<Typography variant="body2">{translate('GDPR_banner_required')}</Typography>}
          />
        )}
        <Grid container className={classes.container}>
          <PreventNavigation when={dirty} />

          <Grid container>
            <Grid item xs={12}>
              <FkRadioGroup
                RadioProps={{ color: 'primary' }}
                name={nameof<FormValues>('complianceType')}
                fieldType={EFieldType.Boolean}
                options={[
                  {
                    labelId: 'Default_banner',
                    descriptionId: 'Default_banner_description',
                    value: AppCompliance.ComplianceTypeEnum.Default as unknown as string,
                    disabled: !allowDisablingOfGdpr,
                  },
                  {
                    labelId: 'GDPR_complinat_banner',
                    descriptionId: 'GDPR_banner_description',
                    value: AppCompliance.ComplianceTypeEnum.GdprCompliance as unknown as string,
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
            <Grid item>
              <LoadingButton
                fdKey="save_cookie_settings"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                style={{ marginTop: 16, marginRight: 16 }}
              >
                <Translate id="Save" />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    </Form>
  );
};
export default withFormik<Props, FormValues>({
  displayName: 'CookieSettingsForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return getDefaultFormState(props);
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
    formikBag.setSubmitting(false);
  },
})(CookieSettingsForm);
