import { AccountDetail, SupportedCountry } from '@flipdish/api-client-typescript';

import { setGdprSelection, UpdateFailure } from '../actions/account.actions';
import { accountConstants } from '../constants/account.constants';
import { DeviceId, UUID } from '../helpers/utilities';

export interface AccountState extends AccountDetail {
  authorized: boolean;
  DeviceId?: string;
  SessionId?: string;
  error?: Error;
  ssoLoginError?: string;
  auth0LoginError?: string;
  gdpr?: Parameters<typeof setGdprSelection>[0];
  pinSent?: boolean;
  supportedCountries?: SupportedCountry[];
  TokenId?: string;
  updateFailure?: boolean;
  updatePasswordSuccess?: boolean;
  updateSuccess?: boolean;

  // TODO: move me somewhere... anywhere but here
  timeZones?: any;

  // DEPRECATED
  email?: string;
  submitting?: boolean;
}
const defaultState: AccountState = {
  authorized: false,
  DisplayTimesInUserLocalTimeZone: true,
  DeviceId: DeviceId(),
  SessionId: UUID(),
  Email: '',
  gdpr: undefined,
  IsSelfServeUser: false,
  IsVerified: false,
  Language: '',
  Name: '',
  SignupSteps: [],
  supportedCountries: [],
  TimeZoneInfoId: '',
  TokenId: '',
  updateSuccess: false,
};

const defaultError = 'Error_please_try_again_later';

const {
  LOGIN_AUTH0_FAILURE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_SUPPORTED_COUNTRIES,
} = accountConstants;

export function account(state = defaultState, action): AccountState {
  switch (action.type) {
    case UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        updateFailure: true,
        error: (action as UpdateFailure).payload,
      };
    case LOGIN_AUTH0_FAILURE:
      return {
        ...state,
        auth0LoginError: 'AUTH0_FAILURE',
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        authorized: true,
        SessionId: UUID(),
        updateFailure: false,
        updateSuccess: false,
        updatePasswordSuccess: false,
      };
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        authorized: false,
        error: action.payload,
      };
    case accountConstants.LOGOUT_FAILURE:
      return {
        ...defaultState,
      };
    case accountConstants.SETUP_SURVEY:
      return {
        ...state,
        ...action.account,
      };
    case accountConstants.SKIP_SIGNUP_STEP_REQUEST:
    case accountConstants.SKIP_SIGNUP_STEP_SUCCESS:
    case accountConstants.SKIP_SIGNUP_STEP_FAILURE:
    case accountConstants.ANSWER_SIGNUP_QUESTION_REQUEST:
    case accountConstants.ANSWER_SIGNUP_QUESTION_SUCCESS:
    case accountConstants.ANSWER_SIGNUP_QUESTION_FAILURE:
      return {
        ...state,
        ...action.account,
      };
    case accountConstants.SET_EMAIL:
      return {
        ...state,
        email: action.data,
      };
    case accountConstants.CLEAR_REDIRECT:
      return { ...state, error: undefined };
    case accountConstants.GET_LOCALIZED_TIMEZONES_SUCCESS:
    case accountConstants.GET_LOCALIZED_TIMEZONES_FAILURE:
      return { ...state, timeZones: action.timeZones };
    case GET_SUPPORTED_COUNTRIES:
      return {
        ...state,
        supportedCountries: action.payload,
      };

    case setGdprSelection.toString(): {
      return {
        ...state,
        gdpr: action.payload,
      };
    }

    default:
      return state;
  }
}

function getErrorMessage(error) {
  return (
    (error && error.response && error.response.body && error.response.body.Message) || defaultError
  );
}
