import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

const tableHeaderIds: TranslationId[] = [
  'Order-number-status',
  'Channel',
  'Requested for',
  'Store-name',
  'Order-amount',
  'Payment_status',
  'Customer',
];

const THeadStyles = () => {
  return createStyles({
    headCell: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '2',
      letterSpacing: 'normal',
      color: 'rgba(0, 0, 0, 0.54)',
      height: '56px',
      borderBottomColor: 'rgba(0,0,0,0.21)',
    },
    statusCell: {
      padding: '0 10px',
    },
  });
};

const THead: React.FC<React.PropsWithChildren<WithStyles<typeof THeadStyles>>> = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        {tableHeaderIds.map((header) => (
          <TableCell className={`${classes.headCell} ${classes.statusCell}`} key={header}>
            <Translate id={header} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(THeadStyles)(THead);
