import React from 'react';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import DeviceCreate from './Create/DeviceCreate';
import DeviceList from './DeviceList';
import DeviceEdit from './Edit/DeviceEdit';

type Props = RouteComponentProps;

const DeviceRoutes = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="devices">
      <Switch>
        <Route exact path={`${path}/new`} component={DeviceCreate} />
        <Route exact path={`${path}/:id`} component={DeviceEdit} />
        <Route path={`${path}`} component={DeviceList} />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(DeviceRoutes);
