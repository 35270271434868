import React, { SetStateAction, useEffect, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import debounce from 'lodash/debounce';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'recompose';

import { getPendingOrders, getStores, setPendingStoresFilter } from '../actions';
import { getLiveViewStores } from '../selectors';
import {
  Control,
  DropdownIndicator,
  LoadingIndicator,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  ValueContainer,
} from './SelectComponents';

const styles = (theme: Theme) => {
  return createStyles({
    input: {
      display: 'flex',
      padding: '8px 0 8px 8px',
      '& >div:nth-child(2) span': {
        display: 'none',
      },
      minHeight: 40,
      maxWidth: 430,
      height: '100%',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap' as any,
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
      height: '32px',
      borderRadius: '16px',
      backgroundColor: '#e0e0e0',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        border: 0,
      },
    },
    cssFocused: {},
    notchedOutline: {},
    menuPaper: {
      margin: '0 1%',
    },
  });
};

type InnerProps = MappedState & MappedDispatch & WithStyles<typeof styles>;
type OuterProps = {
  fromDate?: Date;
  setParentStores?: (storeIds: SetStateAction<any>) => void;
};
type Props = InnerProps & OuterProps;

const LiveViewStoreFilter: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    translate,
    classes,
    fetchStores,
    fromDate,
    setParentStores,
    stores,
    storesFilter,
    setStoreFilter,
    fetchPendingOrders,
    storesLoading,
  } = props;
  const [query, setQuery] = useState('');

  useEffect(() => {
    fetchStores(query);
  }, [query]);

  useEffect(() => {
    const storeIds = storesFilter.map((v: { value: number; label: string }) => v.value);
    setParentStores?.(storeIds);
    fetchPendingOrders(1, storeIds, fromDate);
  }, [storesFilter]);

  const handleInputChange = debounce((val: string) => {
    setQuery(val);
  }, 500);

  return (
    <Select
      classes={classes}
      placeholder={translate('All_stores').toString()}
      textFieldProps={{
        variant: 'outlined',
      }}
      components={{
        Control,
        DropdownIndicator,
        Menu,
        MultiValue,
        NoOptionsMessage,
        LoadingIndicator,
        ValueContainer,
        Option,
      }}
      options={stores}
      isLoading={storesLoading}
      onInputChange={handleInputChange}
      onChange={(vals: Array<{ label: string; value: number }>) => setStoreFilter(vals)}
      value={storesFilter}
      isMulti
      isClearable={false}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    stores: getLiveViewStores(state),
    storesLoading: state.liveViewStores.getStoresLoading,
    storesFilter: state.OrdersFilter.pendingStores,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchStores: (query?: string) => dispatch(getStores(query)),
  setStoreFilter: (stores: Array<{ label: string; value: number }>) =>
    dispatch(setPendingStoresFilter(stores)),
  fetchPendingOrders: (page: number, storeIds: number[], from?: Date) =>
    dispatch(getPendingOrders(page, storeIds, from)),
});

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withStyles(styles, {
    name: 'LiveViewStoreFilter',
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(LiveViewStoreFilter);

export default EnhancedComponent;
