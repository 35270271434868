import React from 'react';

import { Order } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { renderTotalAmount } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  itemsGrid: {
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '24px 16px 32px 16px',
      paddingRight: 0,
    },
  },
  paper: {
    boxShadow: 'none',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.5, 0, 1.25),
    textAlign: 'left',
  },
  headline5: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0px',
    fontSize: '18px',
    fontWeight: 500,
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  dividerLine: {
    listStyleType: 'none',
    margin: theme.spacing(1, 0),
  },
  listItem: {
    padding: '2px 0',
    listStyleType: 'none',
  },
  listItemText: {
    width: '100%',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  listItemTextRefund: {
    width: '100%',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: '#ff395f',
  },
  listItemExtrasText: {
    width: '100%',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginLeft: theme.spacing(4),
  },
  price: {
    textAlign: 'right',
    padding: '0',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  priceRefund: {
    textAlign: 'right',
    padding: '0',
    color: '#ff395f',
  },
  total: {
    textAlign: 'right',
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.56)',
    paddingRight: '0px',
    marginTop: '16px',
  },
  totalAmount: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '0',
  },
}));

const refundStates = [
  Order.OrderStateEnum.RejectedByFlipdish,
  Order.OrderStateEnum.RejectedByStore,
  Order.OrderStateEnum.RejectedAfterBeingAccepted,
  Order.OrderStateEnum.RejectedAutomatically,
  Order.OrderStateEnum.AcceptedAndRefunded,
];

type Props = { order: Order; languageCode: string; currency: string };

const OrderDetailsSummary: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const classes = useStyles();
  const { order, languageCode, currency } = props;

  return (
    <Paper square className={classes.paper}>
      {order && (
        <>
          <Typography variant="caption" className={classes.caption}>
            <Translate id="Delivery, discounts, tip" />
            <Divider component={'li'} className={classes.dividerLine} />
          </Typography>

          <List style={{ paddingTop: '0px' }}>
            {order.DeliveryType == Order.DeliveryTypeEnum.Delivery && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={<Translate id="Delivery" />}
                  classes={{
                    primary: classes.listItemText,
                  }}
                />
                <ListItemText
                  primary={
                    order.DeliveryAmount &&
                    order.DeliveryAmount.toLocaleString(languageCode, {
                      style: 'currency',
                      currency: currency,
                    })
                  }
                  classes={{
                    root: classes.price,
                    primary: classes.price,
                  }}
                  primaryTypographyProps={
                    {
                      'data-fd': 'order-details-delivery-amount',
                    } as any
                  }
                />
              </ListItem>
            )}
            {order.Voucher && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={order.Voucher.Name}
                  classes={{
                    primary: classes.listItemText,
                  }}
                />
                <ListItemText
                  primary={
                    order.Voucher.Amount &&
                    order.Voucher.Amount.toLocaleString(languageCode, {
                      style: 'currency',
                      currency: currency,
                    })
                  }
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            )}

            {order.TipAmount !== undefined && order.TipAmount > 0 && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={<Translate id="Tip" />}
                  classes={{
                    primary: classes.listItemText,
                  }}
                />
                <ListItemText
                  primary={order.TipAmount.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            )}

            {order.ServiceChargeAmount !== undefined && order.ServiceChargeAmount > 0 && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={<Translate id="Service_charge_title" />}
                  classes={{
                    primary: classes.listItemText,
                  }}
                />
                <ListItemText
                  primary={order.ServiceChargeAmount.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            )}

            {order.ProcessingFee !== undefined && order.ProcessingFee > 0 && (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={<Translate id="Processing-Fee" />}
                  classes={{
                    primary: classes.listItemText,
                  }}
                />
                <ListItemText
                  primary={order.ProcessingFee.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            )}

            {order.TotalTax && order.TotalTax > 0 ? (
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={<Translate id="Tax" />}
                  classes={{ primary: classes.listItemText }}
                />
                <ListItemText
                  primary={order.TotalTax.toLocaleString(languageCode, {
                    style: 'currency',
                    currency: currency,
                  })}
                  classes={{
                    primary: classes.price,
                    root: classes.price,
                  }}
                />
              </ListItem>
            ) : null}

            {order.OrderState &&
              refundStates.indexOf(order.OrderState) !== -1 &&
              order.RefundedAmount !== null && (
                <>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={<Translate id="Total-before-refund" />}
                      classes={{
                        primary: classes.listItemText,
                      }}
                    />
                    <ListItemText
                      primary={
                        order.Amount &&
                        order.Amount.toLocaleString(languageCode, {
                          style: 'currency',
                          currency: currency,
                        })
                      }
                      classes={{
                        primary: classes.price,
                        root: classes.price,
                      }}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={<Translate id="Refund" />}
                      classes={{
                        primary: classes.listItemTextRefund,
                      }}
                    />
                    <ListItemText
                      primary={
                        order.RefundedAmount &&
                        `(${order.RefundedAmount.toLocaleString(languageCode, {
                          style: 'currency',
                          currency: currency,
                        })})`
                      }
                      classes={{
                        primary: classes.priceRefund,
                        root: classes.price,
                      }}
                    />
                  </ListItem>
                </>
              )}
          </List>

          <Divider component={'li'} className={classes.dividerLine} />
          <Typography variant="h5" className={classes.total}>
            <span style={{ fontWeight: 'normal' }}>
              <Translate id="Total" />
            </span>{' '}
            <span className={classes.totalAmount}>
              {renderTotalAmount({
                amount: order.Amount,
                refundedAmount: order.RefundedAmount,
                languageCode,
                currency,
              })}
            </span>
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default OrderDetailsSummary;
