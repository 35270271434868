import React from 'react';

import { Translate } from 'react-localize-redux';

enum RejectionReason {
  VAT = 'Incorrect VAT Number',
  IBAN = 'Incorrect IBAN',
  SWIFT = 'Incorrect SWIFT',
}

type Props = {
  rejectionReason: string;
};

export const AssignRejectionReason: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { rejectionReason } = props;
  switch (rejectionReason) {
    case RejectionReason.VAT:
      return <Translate id="is_not_a_valid" data={{ data: 'VAT' }} />;
    case RejectionReason.IBAN:
      return <Translate id="is_not_a_valid" data={{ data: 'IBAN' }} />;
    case RejectionReason.SWIFT:
      return <Translate id="is_not_a_valid" data={{ data: 'SWIFT' }} />;
    default:
      return null;
  }
};
