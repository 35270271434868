import React, { ChangeEvent, useRef, useState } from 'react';

import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { getBackground } from '@fd/ui/utils/generateSrcSet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 114,
    width: 114,
    position: 'relative',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    marginTop: theme.spacing(3),
  },
  placeholder: {
    height: 114,
    width: 114,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#FAFAFA',
    color: 'rgba(0, 0, 0, 0.38)',
    border: '1px dashed rgba(0, 0, 0, 0.38)',
  },
  placeholderIcon: {
    width: '1.5em',
    height: '1.5em',
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    top: 0,
    left: 0,
    position: 'absolute',
    opacity: 0,
    overflow: 'hidden',
    fontSize: '1000px',
    textIndent: '9999px',
    cursor: 'pointer',
  },
  deleteButton: {
    marginLeft: theme.spacing(3),
  },
}));

type Props = {
  fdKey?: string;
  url?: string | null;
  onChange: (data?: File) => void;
  deleteBtn?: boolean;
};

const FileUpload = ({ onChange, fdKey, url, deleteBtn }: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const bgUrl = url
    ? getBackground({
        src: url,
        options: { height: 114, skip: !url || !!value, png: true },
      })
    : null;
  const inputFile = useRef<HTMLInputElement | null>(null);

  const buttonClick = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const imageIsPresent = url && (value || deleteBtn);

  return (
    <>
      <div className={classes.root} style={bgUrl ? { backgroundImage: `url(${bgUrl})` } : {}}>
        <label className={classes.label}>
          <input
            type="file"
            value={value}
            data-fd={fdKey}
            className={classes.input}
            ref={inputFile}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value);

              if (e.target.files) {
                const file = e.target.files[0];
                onChange(file);
              }
            }}
          />
          {!imageIsPresent && (
            <Box data-fd="image-placeholder" className={classes.placeholder}>
              <CropOriginalIcon className={classes.placeholderIcon} />
            </Box>
          )}
        </label>
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={() => buttonClick()} color="primary" variant="outlined">
          <Translate id="Add_image" />
        </Button>
        {imageIsPresent ? (
          <Button
            color="primary"
            variant="outlined"
            className={classes.deleteButton}
            onClick={() => {
              setValue('');
              onChange();
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default FileUpload;
