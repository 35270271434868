export const domainTag = 'frontend_portal_';

export const frontEndFlags = [
  'acceptInvitationNewFlowIsEnabled',
  'addStoresButton',
  'alertBannerInvoice',
  'createCampaignAndVoucherBtn',
  'deliveryLeadTime',
  'freeDeliveryFeeVoucher',
  'hideNextOptionSetButtonOnSkip',
  'internationalisedAddress',
  'isAuth0Enabled',
  'menuItemVoucher',
  'nestedModifiers',
  'orderFulfillmentStatusConfigurations',
  'orderFulfillmentStatusOrderDetails',
  'paygreenFeatures',
  'showBannerAlertForIntegratedMenus',
  'showBrandOrgSelector',
  'showBrandList',
  'showCustomerMessages',
  'showMenuEditor',
  'showPayoutsStoreOptions',
  'showPosInPayoutReport',
  'showPropertiesScreen',
  'showSalesChannelGroups',
  'showRMS',
  'showSalesChannels',
  'tablePrefix',
  'tippingCustom',
  'tippingReduceValues',
  'tippingRoundUp',
  'useInjectedRecaptchaKey',
  'useNewPayoutV3Report',
  'voucherChannel',
  'voucherDiscoverable',
  'voucherLimitAvailability',
  'voucherValidPeriod',
  'hideStoresPage',
  'loyaltyMaxDiscount',
] as const;

export type FrontEndFlag = (typeof frontEndFlags)[number];
