import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { ProductCreate } from './pages/ProductCreate';
import ProductEdit from './pages/ProductEdit/ProductEdit';
import { Products } from './pages/Products';

type Props = RouteComponentProps;

const ProductRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="product">
      <Switch>
        <Route exact path={`${path}`} component={Products} />
        <Route exact path={`${path}/create-product`} component={ProductCreate} />
        <Route exact path={`${path}/:productId`} component={ProductEdit} />
      </Switch>
    </ErrorBoundary>
  );
};

export default ProductRoutes;
