import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { range } from 'lodash';
import Skeleton from 'react-loading-skeleton';

import GridContainer from '../../../../ui/Layout/GridContainer';
import Spacer from '../../../../ui/Spacer';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  preview: {
    backgroundColor: '#ecf4fe',
  },
  switch: {
    float: 'right',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

const Header = () => <Skeleton height={20} />;

const Control = ({ width }: { width?: number }) => <Skeleton height={30} width={width} />;

const Caption = ({ rows }: { rows: number }) => (
  <>
    {range(rows).map((v) => (
      <Skeleton height={12} key={v} />
    ))}
  </>
);

const Wrapper = ({ children }: any) => (
  <Spacer size={24} variant="horizontal">
    {children}
  </Spacer>
);

const PreOrderSettingsSkeleton = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <GridContainer>
        <GridContainer item sm={12} className={classes.gridItem}>
          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <div className={classes.switch}>
                  <Control width={40} />
                </div>
              </Wrapper>
            </Grid>
          </GridContainer>
          <Divider />

          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
                <Caption rows={2} />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <Control />
              </Wrapper>
            </Grid>
          </GridContainer>
          <Divider />

          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
                <Caption rows={2} />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <Control />
              </Wrapper>
            </Grid>
          </GridContainer>
          <Divider />

          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <Control />
              </Wrapper>
            </Grid>
          </GridContainer>
          <Divider />

          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
                <Caption rows={4} />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <Control />
                <Control />
              </Wrapper>
            </Grid>
          </GridContainer>
          <Divider />

          <GridContainer>
            <Grid item xs={8} className={classes.gridItem}>
              <Wrapper>
                <Header />
                <Caption rows={1} />
              </Wrapper>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Wrapper>
                <Control />
              </Wrapper>
            </Grid>
          </GridContainer>

          <GridContainer pl={1.5}>
            <Wrapper>
              <Skeleton height={30} width={60} />
              <Skeleton height={30} width={60} />
            </Wrapper>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </Wrapper>
  );
};

export default PreOrderSettingsSkeleton;
