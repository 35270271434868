import React from 'react';

import { Metafield, MetafieldDefinition } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { CheckboxField } from '@fd/ui/atoms';
import { InputField } from '@fd/ui/atoms/InputField';
import { FormSection } from '@fd/ui/molecules/FormSection';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';

const useStyles = makeStyles((theme: Theme) => ({
  metafieldContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

export type CatalogMetafieldsFormSectionProps = {
  metafields: MetafieldDefinition[];
  onMetafieldChange: (key: string, newValue: any) => void;
  populatedMetafields?: Metafield[];
  translate: TranslateFunction;
};

export const CatalogMetafieldsFormSection = (
  props: CatalogMetafieldsFormSectionProps
): JSX.Element => {
  const { metafields, onMetafieldChange, populatedMetafields, translate } = props;
  const classes = useStyles();
  const renderMetafieldByType = (metafield: MetafieldDefinition) => {
    const metafieldObj = populatedMetafields?.find((x) => x.Key === metafield.Key);
    const defaultValue = metafieldObj?.Value;
    switch (metafield.ValueType) {
      case MetafieldDefinition.ValueTypeEnum.SingleLineString:
        return (
          <InputField
            defaultValue={defaultValue}
            fdKey={`metafield-${metafield.Key}-field`}
            label={metafield.Name}
            onChange={(e) => onMetafieldChange(metafield.Key, e.target.value)}
          />
        );
      case MetafieldDefinition.ValueTypeEnum.MultiLineString:
        return (
          <InputField
            defaultValue={defaultValue}
            fdKey={`metafield-${metafield.Key}-field`}
            label={metafield.Name}
            multiline
            maxRows={2}
            onChange={(e) => onMetafieldChange(metafield.Key, e.target.value)}
          />
        );
      case MetafieldDefinition.ValueTypeEnum.Boolean: {
        return (
          <CheckboxField
            ariaLabel={`${metafield.Key} ${translate('Products_IsAlcoholCheckbox') as string}`}
            checked={defaultValue === 'true'}
            fdKey={`metafield-${metafield.Key}-checkbox`}
            label={metafield.Name}
            onChange={(isChecked) => onMetafieldChange(metafield.Key, String(isChecked))}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderMetafields = () => {
    if (metafields) {
      return metafields.map((metafield) => {
        const toolTipMessage = metafield.Description;
        return (
          <Grid
            alignItems="center"
            className={classes.metafieldContainer}
            container
            direction="row"
            key={`${metafield.Key}-input`}
          >
            {toolTipMessage && (
              <Grid data-fd={`${metafield.Key}-info-icon`} item xs={1}>
                <Tooltip title={toolTipMessage} placement="left">
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={11}>
              {renderMetafieldByType(metafield)}
            </Grid>
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <FormSection sectionTitle={translate('Metafields') as string}>{renderMetafields()}</FormSection>
  );
};
