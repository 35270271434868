import * as React from 'react';

import { CreateProduct, Product } from '@flipdish/api-client-typescript';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import { menusService } from '../../../../../services/menus.service';
import PageLayout from '../../../../../ui/Layout';
import { metafieldsService } from '../../../../Settings/Metafields/metafields.service';
import { catalogProductsService } from '../../../services/catalogProducts.service';
import { ProductForm } from '../../components/ProductForm/ProductForm';

const ProductCreate = ({
  AppId,
  closeNotifySaving,
  hasUpdateProductPermission,
  history,
  isProductBasedMenusOn,
  location,
  notifyError,
  notifySaved,
  notifySaving,
  translate,
}: RouteComponentProps & MappedDispatch & MappedState) => {
  const searchParams = new URLSearchParams(location.search);
  const menuId = searchParams.get('menuId');
  const menuSectionId = searchParams.get('sectionId');

  const handleProductCreated = async ({ Name, CatalogItemId }: Product) => {
    if (menuId && menuSectionId) {
      const product = {
        CatalogItemId,
        CellLayoutType: 'Small',
      };
      await menusService.addCatalogItemToSection(Number(menuId), Number(menuSectionId), product);
    }
    redirectToNewPage(Name, CatalogItemId as string);
  };

  const redirectToNewPage = (Name: string, CatalogItemId: string) => {
    if (menuId) {
      history.push(`/${AppId}/menus/${menuId}#/`);
    } else {
      const productUrl = window.location.pathname.replace(
        'create-product',
        CatalogItemId as string
      );
      history.push({ pathname: productUrl, state: { productName: Name } });
    }
  };

  const handleSave = async (newProduct: CreateProduct) => {
    await mutateAsync(newProduct);
  };

  const metafieldsQuery = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, AppId, 'catalogItem'],
    queryFn: () => metafieldsService.getMetafieldsByEntity(AppId, 'catalogItem'),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (newProduct: CreateProduct) => {
      newProduct.ProductType = Product.ProductTypeEnum.Product;
      notifySaving();
      return catalogProductsService.createCatalogProduct(AppId, newProduct);
    },

    onSuccess: async (data) => {
      closeNotifySaving();
      notifySaved();
      await handleProductCreated(data.Data);
    },

    onError: (error: Error) => {
      closeNotifySaving();
      const errorMessage = error.message.includes('SKU already exists')
        ? 'Product_CreateProductDialog_DuplicateSkuError'
        : 'Error_please_try_again_later';
      notifyError({ message: errorMessage, translate: true });
    },
  });
  return isProductBasedMenusOn ? (
    <PageLayout
      strictToParent
      documentTitle="Products_CreateAProduct"
      toParent={`/${AppId}/products`}
      title={<Translate id="Products_CreateAProduct" />}
    >
      <ProductForm
        appId={AppId}
        canEdit={hasUpdateProductPermission}
        metafields={metafieldsQuery.data?.Data}
        onSaveChanges={handleSave}
        translate={translate}
      />
    </PageLayout>
  ) : null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'UpdateCatalogElements',
  ]);
  return {
    AppId: currentApp.AppId as string,
    hasUpdateProductPermission: getPermissionsSelector(state),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(ProductCreate);
export { EnhancedComponent as ProductCreate };
