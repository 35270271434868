import React from 'react';

import { BankAccountSummary } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import LinkButton from '../../../ui/Button/LinkButton';

const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    backgroundColor: '#ffbc2c',
    marginBottom: '16px',
    [theme.breakpoints.down(400)]: {
      paddingTop: '10px',
    },
  },
}));

type OuterProps = {
  account: {
    priority?: number;
    alertType?: string;
    bodyText?: {
      id: TranslationId;
      data: {};
    };
  } & BankAccountSummary;
};

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;
const AlertBankVerification = (props: Props) => {
  const { currentApp, account } = props;

  const getAlertBodyText = () => {
    if (account && account.bodyText) {
      return <Translate id={account.bodyText.id} data={account.bodyText.data} />;
    } else {
      return <Translate id="You_must_verify_some_of_your_banking_details_to_receive_payouts" />;
    }
  };
  const getAlertCTAText = () => {
    switch (account.alertType) {
      case 'rejectedAccount':
      case 'verifyAccount':
        return <Translate id="Go_to_banking" />;
      case 'editAccount':
        return <Translate id="Update_details" />;
      case 'cardPayment':
        return <Translate id="Verify_details" />;

      default:
        return <Translate id="Go_to_banking" />;
    }
  };
  const getLink = () => {
    switch (account.alertType) {
      case 'rejectedAccount':
      case 'verifyAccount':
        return `/${currentApp.AppId}/finance/bank-accounts`;
      case 'editAccount':
      case 'cardPayment':
        return `/${currentApp.AppId}/finance/bank-accounts/${account.Id}`;
      default:
        return `/${currentApp.AppId}/finance/bank-accounts`;
    }
  };

  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" className={classes.paperWrapper}>
        <Grid item xs={12}>
          <Toolbar>
            <Typography variant="body2" color="textPrimary">
              {getAlertBodyText()}
            </Typography>

            <div style={{ flexGrow: 1 }} />
            <Hidden smDown>
              <LinkButton
                variant="text"
                color="primary"
                to={getLink()}
                type="button"
                fdKey="Go_to_banking"
                style={{ letterSpacing: '1.25px' }}
              >
                {getAlertCTAText()}
              </LinkButton>
            </Hidden>
          </Toolbar>
        </Grid>

        <Hidden smUp>
          <Grid item xs={12}>
            <Box ml={1} mr={2} mb={1}>
              <LinkButton
                variant="text"
                color="primary"
                to={getLink()}
                type="button"
                fdKey="Go_to_banking"
                style={{ letterSpacing: '1.25px' }}
              >
                {getAlertCTAText()}
              </LinkButton>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
  currentApp: state.currentApp,
  bankAccounts: state.banking.accounts,
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, {}))(AlertBankVerification);
