import React, { useCallback } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import { type TablePaginationProps } from '@mui/material/TablePagination';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';

const styles = () =>
  createStyles({
    root: {
      flexShrink: 0,
    },
  });

const TablePaginationActions = ({
  classes,
  page,
  theme,
  count,
  rowsPerPage,
  onPageChange,
  className,
}: WithStyles<any, true> & TablePaginationProps) => {
  //#region Handlers
  const handleFirstPageButtonClick = useCallback(
    (event: any) => {
      onPageChange && onPageChange(event, 0);
    },
    [count, rowsPerPage, page]
  );
  const handleBackButtonClick = useCallback(
    (event: any) => {
      onPageChange && onPageChange(event, page - 1);
    },
    [count, rowsPerPage, page]
  );
  const handleNextButtonClick = useCallback(
    (event: any) => {
      onPageChange && onPageChange(event, page + 1);
    },
    [count, rowsPerPage, page]
  );
  const handleLastPageButtonClick = useCallback(
    (event: any) => {
      onPageChange && onPageChange(event, Math.ceil(count / rowsPerPage) - 1);
    },
    [count, rowsPerPage, page]
  );
  //#endregion

  return (
    <div className={`${classes.root} ${className}`}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0 || count === 0}
        aria-label="First Page"
        data-fd="first-page-btn"
      >
        {(theme as Theme).direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0 || count === 0}
        aria-label="Previous Page"
        data-fd="previous-page-btn"
      >
        {(theme as Theme).direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
        data-fd="next-page-btn"
      >
        {(theme as Theme).direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
        data-fd="last-page-btn"
      >
        {(theme as Theme).direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

const EnhancedComponent = withStyles(styles, {
  withTheme: true,
})(TablePaginationActions);

export { EnhancedComponent as TablePaginationActions };
