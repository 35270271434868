import React from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import CompleteMilestone from '../../../../assets/images/complete-milestone.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column',
    height: 'calc(100% - 16px)',
    color: '#05149E',
  },
  title: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '48px',
    margin: 0,
  },
  body: {
    fontSize: 16,
    lineHeight: '24px',
  },
  iconWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 140,
    padding: theme.spacing(2),
  },
}));

const Complete = ({ dismiss, index }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {index === 0 && (
        <>
          <div>
            <h2 className={classes.title}>
              <Translate id="Well_done_youve_completed_your_first_steps_and_are" />
            </h2>
            <div className={classes.body}>
              <p>
                <Translate id="You_have_completed_your_first_step_for_setting_up" />
              </p>
            </div>
          </div>
          <div className={classes.iconWrapper}>
            <img src={CompleteMilestone} className={classes.icon} />
          </div>
        </>
      )}

      <Button variant="contained" color="primary" fullWidth onClick={() => dismiss()}>
        <Translate id="Close" />
      </Button>
    </div>
  );
};

export default Complete;
