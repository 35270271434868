import { EFieldType } from '../../models/EFieldType';
import { TField } from '../../models/TField';
import { TFieldName } from '../../models/fields';

export type ComponentPropsEx = {
  name: string;
  fdKey?: string;
  disabled?: boolean;
  removeTopPadding?: boolean;
};

export type ComponentProps = ComponentPropsEx & TField;
export const getAutoFdKey = (type: EFieldType, name?: TFieldName) =>
  `fd-${type}-${name}`.toLowerCase();
