import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import NoCampaignsIcon from '../../../assets/images/illust_liveview_coffee_list.svg';
import GridContainer from '../../../ui/Layout/GridContainer';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    [breakpoints.up('lg')]: {
      padding: '32px 24px 40px',
    },
    [breakpoints.down('xl')]: {
      paddingRight: '12px',
    },
    [breakpoints.down('md')]: {
      padding: '8px 16px 40px',
    },
  },
  imageWrapper: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
    justifyContent: 'center',
  },
  title: {
    fontWeight: 500,
    color: '#3743B1',
    marginBottom: 8,
    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: 12,
    [breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  button: {
    marginTop: 16,
    marginBottom: 16,
  },
  image: {
    width: '100%',
    objectFit: 'contain',

    [breakpoints.up('md')]: {
      marginLeft: 56,
      marginRight: 56,
    },

    [breakpoints.down('sm')]: {
      width: '75%',
      marginBottom: 24,
    },
  },
  content: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));

type Props = {
  onCreateLoyaltyCampaign: () => void;
  onCreateRetentionCampaign: () => void;
};

const EmptyComponent: React.FC<React.PropsWithChildren<Props>> = ({
  onCreateLoyaltyCampaign,
  onCreateRetentionCampaign,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridContainer>
        <Grid item xs={12} sm={4} md={6} container className={classes.imageWrapper}>
          <img src={NoCampaignsIcon} className={classes.image} alt="No_data" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          container
          direction="column"
          className={classes.content}
          style={{ alignSelf: 'center' }}
        >
          <Typography variant="h3" className={classes.title}>
            <Translate id="No_campaigns" />
          </Typography>

          <Typography variant="subtitle1" className={classes.subtitle}>
            <Translate id="Create_loyalty_campaign_description" />
          </Typography>

          <Button
            data-fd="Create_loyalty_campaign"
            color="primary"
            variant="outlined"
            type="button"
            className={classes.button}
            onClick={onCreateLoyaltyCampaign}
          >
            <Translate id="Create_loyalty_campaign" />
          </Button>

          <Typography variant="subtitle1" className={classes.subtitle} style={{ paddingTop: 24 }}>
            <Translate id="Create_retention_campaign_description" />
          </Typography>

          <Button
            data-fd="Create_retention_campaign"
            color="primary"
            variant="outlined"
            type="button"
            className={classes.button}
            onClick={onCreateRetentionCampaign}
          >
            <Translate id="Create_retention_campaign" />
          </Button>
        </Grid>
      </GridContainer>
    </div>
  );
};

export default EmptyComponent;
