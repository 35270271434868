import React, { useEffect } from 'react';

import Hidden from '@mui/material/Hidden';
import { type Theme, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { loadEndOfDayReport, reset } from '../actions';
import { getReportData } from '../selectors';

const TCell = withStyles((theme: Theme) => ({
  body: {
    fontSize: 13,
  },
  head: {
    fontSize: 12,
  },
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}))(TableCell) as typeof TableCell;

type TRowProps = {
  header: TranslationId;
  loading: boolean;
  data?: {
    Cash?: number | string;
    PaidOnline?: number | string;
    Total?: number | string;
  };
};
const TRow = ({ header, loading, data = {} }: TRowProps) => (
  <>
    <Hidden smDown>
      <TableRow hover>
        <TCell component="th" scope="row">
          <Translate id={header} />
        </TCell>
        <TCell>{loading ? <Skeleton /> : data!.Cash}</TCell>
        <TCell>{loading ? <Skeleton /> : data!.PaidOnline}</TCell>
        <TCell style={{ fontWeight: 'bold' }}>{loading ? <Skeleton /> : data!.Total}</TCell>
      </TableRow>
    </Hidden>
    <Hidden smUp>
      <TableRow hover>
        <TCell colSpan={3} component="th" scope="row" variant="head">
          <Translate id={header} />
        </TCell>
      </TableRow>
      <TableRow hover>
        <TCell>{loading ? <Skeleton /> : data!.Cash}</TCell>
        <TCell>{loading ? <Skeleton /> : data!.PaidOnline}</TCell>
        <TCell style={{ fontWeight: 'bold' }}>{loading ? <Skeleton /> : data!.Total}</TCell>
      </TableRow>
    </Hidden>
  </>
);

const useStyles = makeStyles((theme: Theme) => ({
  noLastBorder: {
    '& > tbody > tr:last-child': {
      '& > td, & > th': {
        borderBottom: 'none',
      },
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 16,
      paddingTop: 32,
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

const CaptionRow = ({ data, withTitle }: { data: any; withTitle?: boolean }) => (
  <TableRow>
    {withTitle && (
      <TCell>
        <Translate id="Totals" />
      </TCell>
    )}
    <TCell variant="head" id="th_cash">
      {data && data.Currency === 'USD' ? <Translate id="Cash_incl_tax" /> : <Translate id="Cash" />}
    </TCell>
    <TCell variant="head" id="th_online">
      {data && data.Currency === 'USD' ? (
        <Translate id="Paid_online_incl_tax" />
      ) : (
        <Translate id="Paid_online" />
      )}
    </TCell>
    <TCell variant="head" id="th_total">
      {data && data.Currency === 'USD' ? (
        <Translate id="Total_incl_tax" />
      ) : (
        <Translate id="Total" />
      )}
    </TCell>
  </TableRow>
);

type Props = MappedState & MappedDispatch;

const ReportTable = (props: Props) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    return () => {
      props.resetState();
    };
  }, []);

  useEffect(() => {
    if (props.filter.storeId) {
      props.getReportData();
    }
  }, [props.filter]);

  const { isLoading, data } = props;

  return (
    <Table className={classes.noLastBorder}>
      <colgroup>
        <col span={1} style={{ width: '40%' }} />
        <col span={1} style={{ width: '20%' }} />
        <col span={1} style={{ width: '20%' }} />
        <col span={1} style={{ width: '20%' }} />
      </colgroup>

      <TableHead>
        <>
          <Hidden smDown>
            <CaptionRow data={data} withTitle />
          </Hidden>
          <Hidden smUp>
            <TableRow>
              <TCell className={classes.title} colSpan={3}>
                <Translate id="Totals" />
              </TCell>
            </TableRow>
            <CaptionRow data={data} />
          </Hidden>
        </>
      </TableHead>

      <TableBody>
        <TRow header={'Number_of_orders'} loading={isLoading} data={data && data.Totals.Count} />

        <TRow header={'Order_value'} loading={isLoading} data={data && data.Totals.Value} />

        <TRow
          header={'Order_value_excluding_delivery_fees'}
          loading={isLoading}
          data={data && data.Totals.NoFeesValue}
        />

        <TableRow>
          <TCell
            className={classes.title}
            component="th"
            scope="col"
            variant="head"
            colSpan={isMobileScreen ? 3 : 4}
          >
            <Translate id={'Collection'} />
          </TCell>
        </TableRow>

        <Hidden smUp>
          <CaptionRow data={data} />
        </Hidden>

        <TRow
          header={'Number_of_collection_orders'}
          loading={isLoading}
          data={data && data.Collection.Count}
        />

        <TRow
          header={'Collection_order_value'}
          loading={isLoading}
          data={data && data.Collection.Value}
        />

        <TableRow>
          <TCell
            className={classes.title}
            component="th"
            scope="col"
            variant="head"
            colSpan={isMobileScreen ? 3 : 4}
          >
            <Translate id={'Delivery'} />
          </TCell>
        </TableRow>

        <Hidden smUp>
          <CaptionRow data={data} />
        </Hidden>

        <TRow
          header={'Number_of_delivery_orders'}
          loading={isLoading}
          data={data && data.Delivery.Count}
        />

        <TRow
          header={'Delivery_order_value'}
          loading={isLoading}
          data={data && data.Delivery.Value}
        />
      </TableBody>
    </Table>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { reports } = state;
  const isLoading = reports.endOfDay.loading;
  const filter = state.reports.endOfDay.filter;
  const data = getReportData(state);
  return {
    isLoading,
    filter,
    data,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getReportData: () => {
    dispatch(loadEndOfDayReport());
  },
  resetState: () => {
    dispatch(reset());
  },
});

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(ReportTable);
