import { isEmpty } from 'lodash';

export const IEAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'IE') {
    return {
      line1: '',
      line2: '',
      line3: '',
      eirCode: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      line1: '',
      line2: storeAddress?.Line1 || '',
      line3: storeAddress?.City || '',
      eirCode: storeAddress?.Postcode || '',
    };
  }

  return storeAddress.AddressFields;
};
