import { capitalize, snakeCase } from 'lodash';
import moment from 'moment';
import * as qs from 'qs';

import { PrevPeriodProps } from './components/Filters/DateRangeSelector';
import {
  Bounds,
  CountProp,
  CountProps,
  DataProps,
  GranularityProps,
  PeriodPresetEnum,
} from './types';

type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

export const getQuery = (params: {
  Platform?: AppTypeEnum[];
  DeliveryType?: Array<'delivery' | 'collection'>;
  GraphGranularity?: GranularityProps;
  StoreId?;
  PeriodType: PeriodPresetEnum;
  MainPeriod;
  ComparePeriod?;
  IANATimezone?: string;
  Page?: number;
  PageSize?: number;
  ExportAsCSV?: boolean;
  Columns?;
  DeliveryZones?: number[];
}) => {
  const {
    Platform,
    DeliveryType,
    GraphGranularity,
    StoreId,
    PeriodType,
    MainPeriod,
    ComparePeriod,
    IANATimezone,
    Page,
    PageSize,
    ExportAsCSV,
    Columns,
    DeliveryZones,
  } = params;
  const CurrencyFilter = StoreId && !parseInt(StoreId, 10) ? StoreId : null;
  const queryParams = {
    Page: ExportAsCSV ? null : Page,
    PageSize: ExportAsCSV ? null : PageSize,
    Platform,
    DeliveryType,
    GraphGranularity,
    StoreId: CurrencyFilter ? null : StoreId,
    CurrencyFilter,
    ExportAsCSV,
    ColumnName: ExportAsCSV ? null : Columns,
    DeliveryZones,
    csvTimeOffsetInMinutes: -new Date().getTimezoneOffset(),
  };

  const query = qs.stringify(queryParams, {
    skipNulls: true,
    encodeValuesOnly: true,
    indices: false,
  });
  const excludedPeriodTypes = ['4thquarter', '3rdquarter', '2ndquarter', '1stquarter', 'custom'];
  const MainPeriodQuery =
    excludedPeriodTypes.indexOf(PeriodType) === -1
      ? `&MainPeriod.Period=${PeriodType}`
      : `&MainPeriod.Period=custom&MainPeriod.Start=${moment(MainPeriod.startDate).format(
          'YYYY-MM-DDT00:00:00'
        )}&MainPeriod.End=${moment(MainPeriod.endDate).add(1, 'd').format('YYYY-MM-DDT00:00:00')}`;

  const ComparePeriodQuery = getComparePeriodQuery(ComparePeriod);
  const tzQuery = IANATimezone ? `&UserTimezone=${IANATimezone}` : '';

  return `${query}${tzQuery}${MainPeriodQuery}${ComparePeriodQuery}`;
};

export const getComparePeriodQuery = (ComparePeriod: PrevPeriodProps) => {
  if (!ComparePeriod || ComparePeriod.value === 0 || !ComparePeriod.startDate) {
    return '&ComparePeriod.Period=none';
  }

  if (ComparePeriod.url) {
    return `&ComparePeriod.Period=${ComparePeriod.url}`;
  }

  const comparePeriodStart = moment(ComparePeriod.startDate).format('YYYY-MM-DDT00:00:00');
  const comparePeriodEnd = moment(ComparePeriod.endDate).add(1, 'd').format('YYYY-MM-DDT00:00:00');

  return `&ComparePeriod.Period=custom&ComparePeriod.Start=${comparePeriodStart}&ComparePeriod.End=${comparePeriodEnd}`;
};

export const getSortQuery = (sortBy, sortDirection) => {
  const query = qs.stringify(
    { sortBy, sortDirection },
    { skipNulls: true, encodeValuesOnly: true, indices: false }
  );
  return query ? `&${query}` : '';
};

export const getVoucherQuery = (StoreId, PeriodType, MainPeriod, Search, MaxResults) => {
  const params = { StoreId, Search, MaxResults };

  const query = qs.stringify(params, { skipNulls: true, encodeValuesOnly: true, indices: false });
  const excludedPeriodTypes = ['4thquarter', '3rdquarter', '2ndquarter', '1stquarter', 'custom'];
  const MainPeriodQuery =
    excludedPeriodTypes.indexOf(PeriodType) === -1
      ? `&MainPeriod.Period=${PeriodType}`
      : `&MainPeriod.Period=custom&MainPeriod.Start=${moment(MainPeriod.startDate).format(
          'YYYY-MM-DDT00:00:00'
        )}&MainPeriod.End=${moment(MainPeriod.endDate).format('YYYY-MM-DDT00:00:00')}`;

  return `${query}${MainPeriodQuery}`;
};

export const getStoreSearchQuery = (params: {
  query?: string;
  currency?: CurrencyEnum;
  maxPerStoreGroup: number;
}) => {
  return qs.stringify(params, { skipNulls: true, encodeValuesOnly: true, indices: false });
};

export const getDuration = (mainPeriod, granularity) => {
  switch (granularity) {
    case 'daily': {
      return { duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'day') + 1, offset: 'day' };
    }
    case 'hourly': {
      return {
        duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'hour') + 1,
        offset: 'hour',
      };
    }
    case 'weekly': {
      return { duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'week'), offset: 'week' };
    }
    case 'monthly': {
      return { duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'month'), offset: 'month' };
    }
    case 'yearly': {
      return { duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'year'), offset: 'year' };
    }
    default: {
      return { duration: mainPeriod.endDate.diff(mainPeriod.startDate, 'day') + 1, offset: 'day' };
    }
  }
};

type Offset = 'day' | 'hour' | 'week' | 'month' | 'year';
export const serializeOverviewData = (
  data,
  mainPeriod,
  comparePeriod,
  granularity,
  currentOnly,
  periodType
) => {
  const { duration, offset } = getDuration(mainPeriod, granularity);

  const sections = Object.keys(data);
  const current = [] as DataProps[];
  const previous = [] as DataProps[];

  for (let i = 0; i < sections.length; i++) {
    const section = Object.keys(data)[i];

    const DataCurrent =
      periodType === 'lifetime'
        ? data[section].current.map((d) => {
            return { ...d, value: d.value || 0 };
          })
        : Array.from(Array(duration).keys()).map((val, idx) => {
            const currentVal = data[section].current;

            const firstDate = currentVal.length ? currentVal[0].key : null;
            const nbValuesBefore = firstDate
              ? moment(firstDate, 'YYYY-MM-DDTHH:mm:ss[Z]').diff(
                  mainPeriod.startDate,
                  offset as Offset
                )
              : 0;

            const key = mainPeriod.startDate
              .clone()
              .add(val, offset as Offset)
              .format('YYYY-MM-DDTHH:mm:ss[Z]');
            const value =
              idx < nbValuesBefore
                ? 0
                : currentVal && currentVal[idx - nbValuesBefore]
                  ? currentVal[idx - nbValuesBefore].value || 0
                  : 0; // We do this to get the value from the correct index
            return {
              key,
              value,
            };
          });

    current.push({
      SectionTitle: section as TranslationId,
      Data: DataCurrent,
      Count: data[section].data.current,
      Type: section.includes('Size') || section.includes('Amount') ? 'Value' : 'Count',
    });

    if (!currentOnly) {
      const DataPrevious = Array.from(Array(duration).keys()).map((val, idx) => {
        const previousVal = data[section].previous;
        const firstDate = previousVal.length ? previousVal[0].key : null;
        const nbValuesBefore = firstDate
          ? moment(firstDate, 'YYYY-MM-DDTHH:mm:ss[Z]').diff(
              comparePeriod.startDate,
              offset as Offset
            )
          : 0;

        if (comparePeriod && comparePeriod.startDate) {
          const key = comparePeriod.startDate
            .clone()
            .add(val, offset as Offset)
            .format('YYYY-MM-DDTHH:mm:ss[Z]');

          const value =
            idx < nbValuesBefore
              ? 0
              : previousVal && previousVal[idx - nbValuesBefore]
                ? previousVal[idx - nbValuesBefore].value || 0
                : 0; // We do this to get the value from the correct index

          return {
            key,
            value,
          };
        } else {
          return { key: undefined, value: undefined };
        }
      });

      previous.push({
        SectionTitle: section as TranslationId,
        Data: DataPrevious,
        Count: data[section].data.previous,
        Type: section.includes('Size') || section.includes('Amount') ? 'Value' : 'Count',
      });
    }
  }

  return { current, previous };
};

export const serializeCategories = (
  data:
    | Reports.OrdersPerDeliveryType[]
    | Reports.OrdersPerPlatform[]
    | Reports.OrdersPerPaymentType[],
  currentOnly: boolean
): CountProps => {
  const current: CountProp[] = [];
  const previous: CountProp[] = [];

  for (const d of data) {
    const category = Object.keys(d)[0];
    current.push({
      key: d[category],
      count: d.data?.current,
      value: d.orderValue?.current,
    });
    if (!currentOnly) {
      previous.push({
        key: d[category],
        count: d.data?.previous,
        value: d.orderValue?.previous,
      });
    }
  }
  return { current, previous };
};

const mapZoomToPrecision = (zoom: number) => {
  if (zoom <= 8) {
    return 'lowest';
  } else if (zoom >= 18) {
    return 'highest4';
  } else {
    switch (zoom) {
      case 9: {
        return 'veryLow';
      }
      case 10: {
        return 'low';
      }
      case 11: {
        return 'mediumLow';
      }
      case 12: {
        return 'medium';
      }
      case 13: {
        // default zoom
        return 'mediumHigh';
      }
      case 14: {
        return 'high';
      }
      case 15: {
        return 'highest1';
      }
      case 16: {
        return 'highest2';
      }
      case 17: {
        return 'highest3';
      }
      default: {
        return 'medium';
      }
    }
  }
};

export const getBoundsQuery = (bounds: Bounds, zoom: number) => {
  const precision = mapZoomToPrecision(zoom);
  const TopLeftLat = bounds.nw.lat;
  const TopLeftLon = bounds.nw.lng;
  const BottomRightLat = bounds.se.lat;
  const BottomRightLon = bounds.se.lng;

  return bounds
    ? `&TopLeft.Lat=${TopLeftLat}&TopLeft.Lon=${TopLeftLon}&BottomRight.Lat=${BottomRightLat}&BottomRight.Lon=${BottomRightLon}&Precision=${precision}`
    : '';
};

export const fieldToTranslationId = (data: { [key: string]: string | number }[], field: string) => {
  if (data) {
    return data.map((d) => {
      return { ...d, [field]: capitalize(snakeCase(d[field] as string)) };
    });
  } else return [];
};

export const serializeOrdersData = (data: Reports.OrderListRow[]) => {
  if (data && data.length) {
    const updatedData = data.map((d) => {
      return {
        ...d,
        orderState: d.orderState === 'dispatched' ? 'AcceptedByRestaurant' : d.orderState,
      };
    });
    return updatedData;
  } else {
    return [];
  }
};

export const updateLanguageHeader = ({
  ExportAsCSV,
  LanguageCode,
}: {
  ExportAsCSV?: boolean;
  LanguageCode?: string;
}) =>
  (ExportAsCSV && LanguageCode ? { 'Accept-Language': LanguageCode } : {}) as Record<
    string,
    string
  >;
