import React, { useEffect, useMemo, useState } from 'react';

import 'react-dates/initialize';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../layouts/Portal/ErrorBoundary';
import { getLocale } from '../selectors/locale.selector';
import { Button } from '../ui/Button';
import MuiPickersUtilsProvider from '../ui/MuiPickersUtilsProvider';
import DateRangeSelectorWindowsHandler from './Reports/components/Filters/DateRangeSelectorWindowsHandler';
import { getPreviousPeriod, onPresetPeriodChange, presetRanges } from './Reports/helpers';

import './Reports/components/Filters/react_dates_overrides.css';
import 'react-dates/lib/css/_datepicker.css';

const useStyles = makeStyles((theme: Theme) => ({
  dateRangeMenuContainer: {
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    zIndex: 100,
    overflowX: 'hidden',
    maxHeight: 'calc(95vH - 176px)', // position top + margin + padding + page header - 5vh
    marginTop: 10,
    backgroundColor: 'white',
    padding: 16,
    maxWidth: 683,
    minWidth: 300,
    '&::-webkit-scrollbar': { display: 'none' },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vH - 50px)',
      width: '92vW',
      padding: 0,
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  container: {
    padding: 16,
    [theme.breakpoints.down('md')]: {
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  dateRangeSelection: {
    width: '100%',
  },
  selectDropdown: {
    marginTop: theme.spacing(2),
    zIndex: 3000,
  },
  input: {
    backgroundColor: '#ffffff',
    cursor: 'text',
  },
  inputSelection: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  cancelApplySection: {
    zIndex: 100,
    marginTop: 15,
    marginBottom: 10,
    padding: '0px 16px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      height: '10vH',
      alignContent: 'center',
      padding: '0px 16px',
      margin: 0,
    },
  },
  buttonLeft: {
    margin: '0 18px 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '0',
      width: 'calc(50% - 8px)',
      marginRight: '8px',
    },
  },
  buttonRight: {
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 8px)',
      marginLeft: '8px',
    },
  },
  compareToSection: {
    marginTop: 20,
  },
  keyboardIcon: {
    display: 'none',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type DateDefault = {
  value: number | undefined;
  url: string | undefined;
};

type DateOption = {
  label: string | undefined;
  value: number | undefined;
};

type PrevPeriodProps = {
  value: number;
  url?: string | undefined;
  startDate?: moment.Moment | undefined;
  endDate?: moment.Moment | undefined;
};

type DateRangeSelectorProps = {
  selectedDate: DateOption | undefined;
  setSelectedDate: (selectedDate) => void;
  selectedUrlDaterange: DateDefault | undefined;
  setSelectedUrlDaterange: (selectedUrlDaterange) => void;
  startDate?;
  setStartDate: (startDate) => void;
  endDate?;
  setEndDate: (endDate) => void;
  previousPeriod: PrevPeriodProps | undefined;
  setPreviousPeriod: (selectedDate) => void;
  customRange?;
  isCompare?: boolean;
  labelText?;
  translate: TranslateFunction;
  toggleDatePicker: () => void;
  onApplyDate: () => void;
  changeDateRange: (dates: any, preset: boolean | undefined) => void;
};

type InnerProps = MappedState & RouteComponentProps;
type OuterProps = DateRangeSelectorProps;
type Props = InnerProps & OuterProps;

const DateRangeSelector = (props: Props) => {
  const { location, history, activeLanguage } = props;
  const {
    selectedDate,
    setSelectedDate,
    selectedUrlDaterange,
    setSelectedUrlDaterange,
    previousPeriod,
    setPreviousPeriod,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    customRange,
    isCompare,
    labelText,
    toggleDatePicker,
    onApplyDate,
    changeDateRange,
    translate,
  } = props;
  const classes = useStyles();
  const dateRanges = customRange || presetRanges;
  const [focusedDateRange, setFocusedDateRange] = useState('startDate');

  useEffect(() => {
    //if !custom (anything over 0) and !prevDateCompare then apply date
    if (selectedDate) {
      if (!isCompare && selectedDate.value! > 0) {
        onApplyDate();
      }
    }
  }, [selectedUrlDaterange]);

  const toggleMenu = () => {
    toggleDatePicker();
  };

  const onFocusChange = (focusedInput) => {
    setFocusedDateRange(focusedInput || 'startDate');
  };

  const onPreviousPeriodChange = (e) => {
    const prev = getPreviousPeriod(e, startDate, endDate, selectedUrlDaterange!.url);
    if (prev) {
      setPreviousPeriod(prev);
    }
  };

  const handlePresetPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, url } = presetRanges[event.target.value];
    setSelectedUrlDaterange({ value, url });
    if (value === 17) {
      // Set previous period to None if user selects "Lifetime"
      setPreviousPeriod({
        value: 0,
        url: undefined,
        startDate: undefined,
        endDate: undefined,
      });
    }
    // if its not custom
    if (value !== 0) {
      const { start, end } = onPresetPeriodChange(Number(event.target.value));
      changeDateRange({ startDate: start, endDate: end }, true);
    }
  };

  //#region parsing
  const renderPeriodWithTranslation = (item) => {
    return translate(item.label, { period: item.period, year: item.year });
  };

  const getInitialMonth = () => {
    return moment(startDate.moment);
  };
  //#endregion

  //#region starting/ending dates input
  const handleStartDateChange = (start: moment.Moment) => {
    if (start && start.isValid()) {
      setSelectedUrlDaterange({ value: 0, url: 'custom' });
      setStartDate({ moment: start, isValid: true });
    }
    if (start.isAfter(endDate.moment, 'day')) {
      setEndDate({ ...endDate, isValid: false });
    }
  };

  const handleEndDateChange = (end: moment.Moment) => {
    if (!end.isValid()) {
      setEndDate({ moment: end, isValid: false });
    }
    if (end && end.isValid()) {
      setSelectedUrlDaterange({ value: 0, url: 'custom' });
      if (end.isBefore(startDate.moment, 'day')) {
        setEndDate({ moment: end, isValid: false });
      } else {
        setEndDate({ moment: end, isValid: true });
      }
    }
  };

  const onStartDateBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      !moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isValid()
    ) {
      setStartDate({ ...startDate, isValid: false });
    }
    if (
      moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isAfter(
        endDate.moment,
        'day'
      )
    ) {
      setEndDate({ ...endDate, isValid: false });
    } else {
      setEndDate({ ...endDate, isValid: true });
    }
  };

  const onEndDateBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      !moment(event.target.value, moment.localeData(activeLanguage).longDateFormat('L')).isValid()
    ) {
      setEndDate({ ...endDate, isValid: false });
    }
  };
  //#endregion

  const renderHelperText = useMemo(() => {
    if (startDate.moment.isAfter(endDate.moment, 'day')) {
      return props.translate('End_date_warning');
    }

    if (!endDate.isValid) {
      if (startDate.moment.isAfter(endDate.moment, 'day')) {
        return props.translate('End_date_warning');
      }
      return props.translate('Invalid_format');
    }
    return '';
  }, [startDate, endDate]);

  return (
    <ErrorBoundary identifier="date-range-selector">
      <DateRangeSelectorWindowsHandler handleClickOutside={toggleMenu}>
        <div className={classes.dateRangeMenuContainer}>
          <div className={classes.container}>
            <Grid container m={-1} width={`calc(100% + 16px)`}>
              <Grid item md={12} sm={12} xs={12} className={classes.gridItem}>
                <FormControl className={classes.dateRangeSelection}>
                  <InputLabel shrink id="date-range-label" variant="standard">
                    {labelText ? labelText : <Translate id="Date_range" />}
                  </InputLabel>
                  <MuiSelect
                    variant="standard"
                    id="date-range-picker-label"
                    value={selectedDate ? selectedDate.value : selectedUrlDaterange!.value}
                    onChange={handlePresetPeriodChange}
                    displayEmpty
                    className={classes.selectDropdown}
                  >
                    {dateRanges.map((pr, idx) => (
                      <MenuItem key={idx} value={pr.value}>
                        {renderPeriodWithTranslation(pr)}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              </Grid>
              <Grid
                className={clsx(classes.inputSelection, classes.gridItem)}
                item
                md={6}
                sm={6}
                xs={6}
              >
                <FormControl className={classes.inputSelection}>
                  <MuiPickersUtilsProvider>
                    <DatePicker
                      label={translate('Starting')}
                      value={startDate.moment}
                      slotProps={{
                        inputAdornment: { classes: { root: classes.keyboardIcon } },
                        textField: {
                          error: !startDate.isValid,
                          fullWidth: true,
                          helperText: !startDate.isValid ? translate('Invalid_format') : '',
                          variant: 'standard',
                          inputProps: {
                            onBlur: onStartDateBlur,
                            className: classes.input,
                            'data-fd': 'starting-date-input',
                            id: 'starting-date-input',
                          },
                        },
                        popper: { style: { zIndex: 9999 } },
                      }}
                      onChange={handleStartDateChange}
                      open={false}
                      format={moment.localeData(activeLanguage).longDateFormat('L')}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid
                className={clsx(classes.inputSelection, classes.gridItem)}
                item
                md={6}
                sm={6}
                xs={6}
              >
                <FormControl className={classes.inputSelection}>
                  <MuiPickersUtilsProvider>
                    <DatePicker
                      label={translate('Ending')}
                      value={endDate.moment}
                      slotProps={{
                        inputAdornment: { classes: { root: classes.keyboardIcon } },
                        textField: {
                          variant: 'standard',
                          helperText: renderHelperText,
                          error: !endDate.isValid,
                          inputProps: {
                            onBlur: onEndDateBlur,
                            className: classes.input,
                            'data-fd': 'ending-date-input',
                            id: 'ending-date-input',
                          },
                        },
                        popper: { style: { zIndex: 9999 } },
                      }}
                      onChange={handleEndDateChange}
                      open={false}
                      format={moment.localeData(activeLanguage).longDateFormat('L')}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6} className={classes.gridItem}>
                <DayPickerRangeController
                  minimumNights={0} // enables selecting same start and end dates
                  startDate={startDate.moment} // momentPropTypes.momentObj or null,
                  endDate={endDate.moment} // momentPropTypes.momentObj or null,
                  onDatesChange={changeDateRange} // PropTypes.func.isRequired,
                  focusedInput={focusedDateRange} // PropTypes.oneOf([startDate, endDate]) or null,
                  onFocusChange={onFocusChange} // PropTypes.func.isRequired,
                  initialVisibleMonth={getInitialMonth}
                  numberOfMonths={window.innerWidth < 768 ? 1 : 2} // PropTypes.func or null,
                  renderMonthElement={({ month }) =>
                    moment(month).locale(activeLanguage).format('MMMM YYYY')
                  }
                />
              </Grid>
            </Grid>
            {isCompare ? (
              <Grid item xs={12} sm={12} md={12} className={classes.compareToSection}>
                <FormControl className={classes.dateRangeSelection}>
                  <InputLabel shrink id="previous-period-label" variant="standard">
                    {translate('Previous_period_desc')}
                  </InputLabel>
                  <MuiSelect
                    variant="standard"
                    id="previous-period-label-dropdown"
                    value={previousPeriod!.value}
                    onChange={onPreviousPeriodChange}
                    displayEmpty
                    // This is disabled if user selects "Lifetime"
                    disabled={selectedUrlDaterange!.value === 17}
                    className={classes.selectDropdown}
                  >
                    <MenuItem value={0}>{translate('None')}</MenuItem>
                    <MenuItem value={10}>{translate('Previous_period')}</MenuItem>
                    <MenuItem value={20}>{translate('Last_year')}</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>
            ) : null}
          </div>

          <Grid container className={classes.cancelApplySection}>
            <Grid item className={classes.buttonLeft}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                fdKey="cancel-date-period-btn"
                onClick={toggleMenu}
              >
                {translate('Cancel')}
              </Button>
            </Grid>
            <Grid item className={classes.buttonRight}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                fdKey="apply-date-period-btn"
                onClick={onApplyDate}
                disabled={!startDate.isValid || !endDate.isValid}
              >
                {translate('Apply')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </DateRangeSelectorWindowsHandler>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    activeLanguage: getLocale(state),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps)
)(DateRangeSelector);
