import {
  Menu,
  MenuTaxDetails,
  MenuTaxRate,
  RestApiArrayResultMenuCheckpoint,
  RestApiArrayResultMenuElementEditResponse,
  RestApiArrayResultMenuTaxDetails,
} from '@flipdish/api-client-typescript';

import { menusConstants } from '../constants/menus.constants';
import {
  closeAllOtherNotifications,
  ERROR_KEY,
  HIDE_KEY,
  notifyError,
  notifyHide,
  notifySaved,
  notifySaving,
  notifyShow,
  SAVED_KEY,
  SAVING_KEY,
  SHOW_KEY,
} from '../layouts/Notify/actions';
import { menusService } from '../services/menus.service';
import { action } from './utils';

function setMenusAppId(appId: string) {
  return {
    type: menusConstants.SET_MENUS_APP_ID,
    payload: appId,
  };
}

function getMenus(appId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    try {
      const result = await menusService.getMenus(appId);
      if (getState().menus.menusTimestamp === timestamp) {
        dispatch(getMenusSuccess(result));
      }
    } catch (error) {
      console.log(error);
      if (getState().menus.menusTimestamp === timestamp) {
        dispatch(request(null));
      }
    }
  };

  function request(timestamp: number | null) {
    return {
      type: menusConstants.SET_MENUS_TIMESTAMP,
      payload: timestamp,
    };
  }
}

function getMenusSuccess(menus: Menu[]) {
  return {
    type: menusConstants.GET_MENUS_SUCCESS,
    payload: menus,
  };
}

function getMenu(menuId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    try {
      const result = await menusService.getMenu(menuId);
      if (getState().menus.menuTimestamp === timestamp) {
        dispatch(success(result));
      }
    } catch (error) {
      console.log(error);
      if (getState().menus.menuTimestamp === timestamp) {
        dispatch(request(null));
      }
    }
  };

  function request(timestamp: number | null) {
    return {
      type: menusConstants.SET_MENU_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(menu: Menu) {
    return {
      type: menusConstants.GET_MENU,
      payload: { ...menu, Taxes: { TaxRates: [], ShowTax: true, IncludeTax: true } },
    };
  }
}

function getMenuCheckpoints(menuId) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    return menusService.getMenuCheckpoints(menuId).then(
      (response) => {
        if (getState().menus.menuCheckpointsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().menus.menuCheckpointsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: menusConstants.SET_MENU_CHECKPOINTS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response: RestApiArrayResultMenuCheckpoint) {
    return {
      type: menusConstants.GET_MENU_CHECKPOINTS,
      payload: [...response.Data].reverse(),
    };
  }
}

function getMenuStores(menuId) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    return menusService.getMenuStores(menuId).then(
      (response) => {
        if (getState().menus.menuStoresTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().menus.menuStoresTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: menusConstants.SET_MENU_STORES_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response: { StoreNames: string[] }) {
    return {
      type: menusConstants.GET_MENU_STORES,
      payload: response.StoreNames,
    };
  }
}

function getMenuTaxDetails(menuId) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    return menusService.getMenuTaxDetails(menuId).then(
      (response) => {
        if (getState().menus.menuTaxDetailsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().menus.menuTaxDetailsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: menusConstants.SET_MENU_TAX_DETAILS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response: RestApiArrayResultMenuTaxDetails) {
    return {
      type: menusConstants.GET_MENU_TAX_DETAILS,
      payload: response,
    };
  }
}

const createMenu = (AppId: string) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch(closeAllOtherNotifications(SAVING_KEY));
  dispatch(notifySaving());
  const newMenu = await menusService.createMenu(AppId!);
  dispatch(closeAllOtherNotifications(SAVED_KEY));
  dispatch(notifySaved());
  return newMenu;
};

export const uploadMenu = (menu: FormData) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      const newMenu = await menusService.uploadMenu(appId, menu);
      const menus = await menusService.getMenus(appId);
      dispatch(getMenusSuccess(menus));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
      return newMenu;
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };
};

function showHideMenuItemsSuccess(response: RestApiArrayResultMenuElementEditResponse) {
  return {
    type: menusConstants.SHOW_HIDE_MENU_ITEMS_SUCCESS,
    payload: response.Data,
  };
}

const showHideMenuItems =
  (menuId: number, isAvailable: boolean, menuElements) => async (dispatch: ThunkDispatch) => {
    try {
      const bulkShowHideResponse = await menusService.menusShowHideBulkItems(
        menuId,
        isAvailable,
        menuElements
      );
      await dispatch(showHideMenuItemsSuccess(bulkShowHideResponse));
      if (isAvailable === false) {
        dispatch(closeAllOtherNotifications(HIDE_KEY));
        dispatch(notifyHide());
        return bulkShowHideResponse.Data;
      } else {
        dispatch(closeAllOtherNotifications(SHOW_KEY));
        dispatch(notifyShow());
        return bulkShowHideResponse.Data;
      }
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

const getBulkShowHideMenuList =
  (menuId: number, isAvailable: boolean) => async (dispatch: ThunkDispatch) => {
    try {
      const menuListResponse = await menusService.getBulkShowHideList(menuId, isAvailable);
      return menuListResponse;
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

const duplicateMenu =
  (menuId, menuName) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());
    const newMenu = await menusService.duplicateMenu(menuId, menuName);
    const menus = [...getState().menus.menus, newMenu.Data];
    await dispatch(getMenusSuccess(menus));
    dispatch(closeAllOtherNotifications(SAVED_KEY));
    dispatch(notifySaved());
    return newMenu.Data.MenuId;
  };

function remove(menuId) {
  return {
    type: menusConstants.DELETE_MENU,
    payload: menuId,
  };
}

const deleteMenu = (menuId) => async (dispatch: ThunkDispatch) => {
  try {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());
    await menusService.deleteMenu(menuId);
    dispatch(closeAllOtherNotifications(SAVED_KEY));
    dispatch(notifySaved());
    dispatch(remove(menuId));
  } catch (error) {
    dispatch(closeAllOtherNotifications(ERROR_KEY));
    if (error.response?.statusCode === 404) {
      dispatch(notifyError({ message: 'Menu_not_found' }));
    } else {
      dispatch(notifyError({ message: 'Something_went_wrong' }));
    }
  }
};

// #region rename
const { SET_MENU_NAME_SUCCESS } = menusConstants;

export type SetNameSuccess = ReturnType<typeof setNameSuccess>;
export const setNameSuccess = (menuId: number, name: string) =>
  action(SET_MENU_NAME_SUCCESS, { menuId, name });

const renameMenu = (menuId: number, name: string) => async (dispatch: ThunkDispatch) => {
  try {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());

    await menusService.renameMenu(menuId, name);
    dispatch(setNameSuccess(menuId, name));
    dispatch(closeAllOtherNotifications(SAVED_KEY));
    dispatch(notifySaved());
  } catch (error) {
    dispatch(closeAllOtherNotifications(ERROR_KEY));
    dispatch(notifyError(error));
    throw error;
  }
};
// #endregion

function lockMenu(menuId: number, locked: boolean) {
  return (dispatch) => {
    return menusService.lockMenu(menuId, locked).then(
      () => {
        dispatch({
          type: menusConstants.SET_LOCK,
          payload: locked,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function restoreMenuCheckpoint(menuId: number, checkpointId: number) {
  return (dispatch) => {
    return menusService.restoreMenuCheckpoint(menuId, checkpointId).then(
      () => {
        dispatch(getMenuCheckpoints(menuId));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function toggleMenuSectionBehaviour(menu: Menu, failureCallback: () => any) {
  return (dispatch: ThunkDispatch) => {
    const newMenuSectionBehaviour =
      menu.MenuSectionBehaviour === Menu.MenuSectionBehaviourEnum.ExpandMultiple
        ? Menu.MenuSectionBehaviourEnum.ExpandSingle
        : Menu.MenuSectionBehaviourEnum.ExpandMultiple;

    dispatch({
      type: menusConstants.SET_MENU_EXPAND_BEHAVIOUR,
      payload: newMenuSectionBehaviour,
    });

    return menusService.toggleMenuSectionBehaviour(menu).then(
      () => {},
      (error) => {
        dispatch({
          type: menusConstants.SET_MENU_EXPAND_BEHAVIOUR,
          payload: menu.MenuSectionBehaviour,
        });
        failureCallback();
        console.log(error);
      }
    );
  };
}

function includeMenuTax(taxType: MenuTaxDetails.TaxTypeEnum) {
  return (dispatch) => {
    dispatch({
      type: menusConstants.INCLUDE_MENU_TAX,
      payload: taxType,
    });

    /*return menusService.includeMenuTax(menuId, taxType).then(
      () => {
        successCallback();
      },
      (error) => {
        console.log(error);
        failureCallback();
        dispatch({
          type: menusConstants.INCLUDE_MENU_TAX,
          payload:
            taxType === MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice
              ? MenuTaxDetails.TaxTypeEnum.ExcludedFromBasePrice
              : MenuTaxDetails.TaxTypeEnum.IncludedInBasePrice,
        });
      }
    );*/
  };
}

function showMenuTax(showTax: boolean) {
  return (dispatch) => {
    dispatch({
      type: menusConstants.SHOW_MENU_TAX,
      payload: showTax,
    });

    /*return menusService.showMenuTax(menuId, showTax).then(
      () => {
        if (successCallback) {
          successCallback();
        }
      },
      (error) => {
        console.log(error);
        if (failureCallback) {
          failureCallback();
        }
        dispatch({
          type: menusConstants.SHOW_MENU_TAX,
          payload: !showTax,
        });
      }
    );*/
  };
}

function addMenuTaxes(
  menuId: number,
  newTaxRates: MenuTaxRate[],
  existingTaxRates: MenuTaxRate[],
  taxRatesToBeDeleted: number[],
  taxType: MenuTaxDetails.TaxTypeEnum | null,
  displayTax: boolean | null,
  successCallback: () => any,
  failureCallback: () => any
) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    const sendBatchedApis = () => {
      const additionQueue: any[] = [];

      for (const newTaxRate of newTaxRates) {
        if (newTaxRate.Name && newTaxRate.Rate) {
          additionQueue.push(menusService.addMenuTax(menuId, newTaxRate.Name, newTaxRate.Rate));
        }
      }

      const updateQueue: any[] = [];

      for (const existingTaxRate of existingTaxRates) {
        if (existingTaxRate.TaxRateId && existingTaxRate.Name && existingTaxRate.Rate) {
          updateQueue.push(
            menusService.updateMenuTax(
              menuId,
              existingTaxRate.TaxRateId,
              existingTaxRate.Name,
              existingTaxRate.Rate
            )
          );
        }
      }

      const deleteQueue: any[] = [];

      for (const taxRateToBeDeleted of taxRatesToBeDeleted) {
        deleteQueue.push(menusService.deleteMenuTax(menuId, taxRateToBeDeleted));
      }

      const apiQueue = [...additionQueue, ...updateQueue, ...deleteQueue];

      if (displayTax != null) {
        apiQueue.push(menusService.showMenuTax(menuId, displayTax));
      }
      Promise.all(apiQueue)
        .then(() => {
          successCallback();
          dispatch(request(null));
          dispatch(getMenuTaxDetails(menuId));
        })
        .catch(() => {
          failureCallback();
          dispatch(request(null));
          console.log('Error');
        });
    };

    if (taxType != null) {
      menusService
        .includeMenuTax(menuId, taxType)
        .then(() => {
          sendBatchedApis();
        })
        .catch(() => {
          failureCallback();
          dispatch(request(null));
          console.log('Error');
        });
    } else {
      sendBatchedApis();
    }
  };

  function request(timestamp) {
    return {
      type: menusConstants.SET_MENU_TAX_SAVING_TIMESTAMP,
      payload: timestamp,
    };
  }
}

export const menusActions = {
  getBulkShowHideMenuList,
  getMenus,
  getMenu,
  getMenuCheckpoints,
  getMenuStores,
  getMenuTaxDetails,
  duplicateMenu,
  createMenu,
  uploadMenu,
  deleteMenu,
  renameMenu,
  restoreMenuCheckpoint,
  lockMenu,
  setMenusAppId,
  showHideMenuItems,
  toggleMenuSectionBehaviour,
  includeMenuTax,
  showMenuTax,
  addMenuTaxes,
};
