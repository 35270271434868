export const UPDATE = `STORE_OPENING_HOURS_UPDATE`;
export const UPDATE_REQUEST = `STORE_OPENING_HOURS_UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `STORE_OPENING_HOURS_UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `STORE_OPENING_HOURS_UPDATE_FAILURE`;

export const LOAD_DELIVERY = `STORE_OPENING_HOURS_LOAD_DELIVERY`;
export const LOAD_DELIVERY_REQUEST = `STORE_OPENING_HOURS_LOAD_DELIVERY_REQUEST`;
export const LOAD_DELIVERY_SUCCESS = `STORE_OPENING_HOURS_LOAD_DELIVERY_SUCCESS`;
export const LOAD_DELIVERY_FAILURE = `STORE_OPENING_HOURS_LOAD_DELIVERY_FAILURE`;

export const LOAD_PICKUP = `STORE_OPENING_HOURS_LOAD_PICKUP`;
export const LOAD_PICKUP_REQUEST = `STORE_OPENING_HOURS_LOAD_PICKUP_REQUEST`;
export const LOAD_PICKUP_SUCCESS = `STORE_OPENING_HOURS_LOAD_PICKUP_SUCCESS`;
export const LOAD_PICKUP_FAILURE = `STORE_OPENING_HOURS_LOAD_PICKUP_FAILURE`;

export const RESET = `STORE_OPENING_HOURS_RESET`;
