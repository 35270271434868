export const ordersConstants = {
  CLEAR_ORDERS: 'CLEAR_ORDERS',
  CLEAR_ORDER: 'CLEAR_ORDER',
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',

  GET_ORDER: 'GET_ORDER',
  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',

  GET_ORDER_DELIVERY_INFO: 'GET_ORDER_DELIVERY_INFO',
  GET_ORDER_DELIVERY_INFO_REQUEST: 'GET_ORDER_DELIVERY_INFO_REQUEST',
  GET_ORDER_DELIVERY_INFO_SUCCESS: 'GET_ORDER_DELIVERY_INFO_SUCCESS',
  GET_ORDER_DELIVERY_INFO_FAILURE: 'GET_ORDER_DELIVERY_INFO_FAILURE',

  GET_LIVE_VIEW_ORDER: 'GET_LIVE_VIEW_ORDER',
  GET_LIVE_VIEW_ORDER_SUCCESS: 'GET_LIVE_VIEW_ORDER_SUCCESS',
  GET_LIVE_VIEW_ORDER_FAILURE: 'GET_ORDER_FAILURE',

  ACCEPT_ORDER: 'ACCEPT_ORDER',
  ACCEPT_ORDER_REQUEST: 'ACCEPT_ORDER_REQUEST',
  ACCEPT_ORDER_SUCCESS: 'ACCEPT_ORDER_SUCCESS',
  ACCEPT_ORDER_FAILURE: 'ACCEPT_ORDER_FAILURE',

  REJECT_ORDER: 'REJECT_ORDER',
  REJECT_ORDER_REQUEST: 'REJECT_ORDER_REQUEST',
  REJECT_ORDER_SUCCESS: 'REJECT_ORDER_SUCCESS',
  REJECT_ORDER_FAILURE: 'REJECT_ORDER_FAILURE',

  REFUND_ORDER: 'REFUND_ORDER',
  REFUND_ORDER_REQUEST: 'REFUND_ORDER_REQUEST',
  REFUND_ORDER_SUCCESS: 'REFUND_ORDER_SUCCESS',
  REFUND_ORDER_FAILURE: 'REFUND_ORDER_FAILURE',

  SET_ORDER_TIMESTAMP: 'SET_ORDER_TIMESTAMP',

  SET_ORDER_SEARCH_CODE_RESULTS: 'SET_ORDER_SEARCH_CODE_RESULTS',
  ORDER_SEARCH_ERROR: 'ORDER_SEARCH_ERROR',

  GET_ORDER_FULFILLMENT_STATUS: 'GET_ORDER_FULFILLMENT_STATUS',
  GET_ORDER_FULFILLMENT_STATUS_REQUEST: 'GET_ORDER_FULFILLMENT_STATUS_REQUEST',
  GET_ORDER_FULFILLMENT_STATUS_SUCCESS: 'GET_ORDER_FULFILLMENT_STATUS_SUCCESS',
  GET_ORDER_FULFILLMENT_STATUS_FAILURE: 'GET_ORDER_FULFILLMENT_STATUS_FAILURE',
};

export const rejectedOrderStatuses: Flipdish.Order['OrderState'][] = [
  'RejectedByStore',
  'RejectedByFlipdish',
  'RejectedAutomatically',
  'RejectedAfterBeingAccepted',
];

export const notRejectedOrderStatuses: Flipdish.Order['OrderState'][] = [
  'AcceptedAndRefunded',
  'Created',
  'AcceptedByRestaurant',
  'Cancelled',
  'Delivered',
  'ReadyToProcess',
  'PlacedCanBeCancelled',
  'Dispatched',
];
