import { TranslateFunction } from 'react-localize-redux';

import { OptionType } from '../ui/Select/Select';

const getEmailAndPhoneNumberOptions = (translate: TranslateFunction): OptionType[] => {
  return [
    { value: 0, label: translate('Always') as string },
    { value: 300, label: translate('If_not_accepted_after', { data: 5 }) as string },
    { value: 600, label: translate('If_not_accepted_after', { data: 10 }) as string },
  ].map((option: OptionType) => {
    if (option.value === 0) {
      return option;
    }
    const customTime = new Date(Number(option.value) * 1000).getMinutes();
    return {
      value: option.value,
      label: translate('If_not_accepted_after', { data: customTime }) as string,
    };
  });
};

// const { SecondsUntilNotification } = smsOrderNotifications;
// var option: Option | Option[] = options;
// // if there an orderNotification exists
// if (Object.keys(smsOrderNotifications).length) {
//   // satisfy typescript
//   if (Array.isArray(options)) {
//     // destructure so we are left with the option object
//     [option] = options.filter((o) => Number(o.value) === SecondsUntilNotification);
//     // if store has set a custom time before this feature was implemented we create custom time
//     if (!option && SecondsUntilNotification) {
//       let customTime = new Date(SecondsUntilNotification * 1000).getMinutes();
//       option = {
//         value: SecondsUntilNotification.toString(),
//         label: translate('If_not_accepted_after', { data: customTime }) as string,
//       };
//     }
//   }
// }

export const dictionary = {
  getEmailAndPhoneNumberOptions: getEmailAndPhoneNumberOptions,
};
