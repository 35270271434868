import React, { useEffect, useState } from 'react';

import { StoreHeader } from '@flipdish/api-client-typescript';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { mapStoreIdtoName } from '../../../Reports/helpers';
import { OrdersTableColumns } from '../../../Reports/types';
import { getCustomerDetailsTableData } from '../../Customers.actions';
import { customerDetailsTableMeta } from '../../CustomersMeta';
import { getColumnsFlipdishStaffOrDefault, getRouteSearch, setRouteSearch } from '../../helpers';
import { CustomerDetailsTable } from '../../types';
import CustomerDetailsOrdersTable from './CustomerDetailsOrdersTable';

const columnLocalStorageKey = 'fd-customer-mgmt-orders-columns';
const rowsPerPageLocalStorageKey = 'fd-customer-mgmt-orders-rows-per-page';
const orderLocalStorageKey = 'fd-customer-mgmt-column-order';
const orderByLocalStorageKey = 'fd-customer-mgmt-column-order-by';
const defaultTableColumns: Array<keyof Reports.OrderListRow> = [
  'orderId',
  'placedAtTime',
  'customerAmount',
  'orderState',
];

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {
  isFlipdishStaff: boolean;
  stores: StoreHeader[];
  storeFilter?: number[];
};
type Props = InnerProps & OuterProps & RouteComponentProps<{ customerId: string }>;

export const CustomersTable = ({
  AppId,
  customerDetailsTableData,
  customerDetailsTablePagination,
  getCustomerTable,
  history,
  isFlipdishStaff,
  languageCode,
  storeFilter,
  stores,
}: Props) => {
  //#region GET PARAMS FROM URL
  const params = getRouteSearch(history.location);

  const rowsPerPageLS = localStorage.getItem(rowsPerPageLocalStorageKey);
  const rowsAmount = params?.rows
    ? parseInt(params.rows, 10)
    : rowsPerPageLS
      ? parseInt(rowsPerPageLS, 10)
      : 5;

  const orderByLS = localStorage.getItem(orderByLocalStorageKey);
  const orderBy = params?.orderBy ? params.orderBy : orderByLS ? orderByLS : 'orderId';

  const orderLS = localStorage.getItem(orderLocalStorageKey);
  const order = params?.order ? params.order : orderLS ? (orderLS as 'asc' | 'desc') : 'desc';

  const pageNo = params?.page ? parseInt(params.page, 10) : 0;
  //#endregion

  const [hasNewColumns, setHasNewColumns] = useState(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [tableOrdersData, setTableOrdersData] = useState<undefined | CustomerDetailsTable[]>();
  const [tableOrdersColumns, setTableOrdersColumns] = useState<OrdersTableColumns[]>([]);
  const [loading, setLoading] = useState<boolean | undefined>();
  const [page, setPage] = useState<number>(pageNo);
  const [rowsPerPage, setRowsPerPage] = useState(rowsAmount);
  const [filterState, setFilterState] = useState({
    storeFilter,
    orderBy,
    order,
  });

  useEffect(() => {
    const params = {
      rows: rowsPerPage,
      page,
      orderBy: filterState.orderBy,
      order: filterState.order,
    };
    setRouteSearch(history, params);
  }, [rowsPerPage, page, filterState.orderBy, filterState.order]);

  useEffect(() => {
    if (customerDetailsTableData && firstRender) {
      setFirstRender(false);
    }
  }, [customerDetailsTableData]);

  useEffect(() => {
    const lsColumns = localStorage.getItem(columnLocalStorageKey);
    let columns = customerDetailsTableMeta;

    if (firstRender) {
      const defaultColumns = lsColumns ? JSON.parse(lsColumns) : defaultTableColumns;
      columns = columns.map((col) => {
        return {
          ...col,
          isVisible: defaultColumns.indexOf(col.columnName) !== -1,
        };
      });
    } else {
      // Handle refetch with new columns
      setHasNewColumns(true);
    }

    const colsByPermission = getColumnsFlipdishStaffOrDefault(columns, isFlipdishStaff);

    setTableOrdersColumns(colsByPermission);
  }, []);

  useEffect(() => {
    const rowsPerPage = localStorage.getItem(rowsPerPageLocalStorageKey);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage, 10));
    }
  }, []);

  useEffect(() => {
    if (customerDetailsTableData) {
      const data = mapStoreIdtoName(customerDetailsTableData, stores);
      setTableOrdersData(data);
    }
  }, [customerDetailsTableData, stores]);

  useEffect(() => {
    if (!loading) {
      const { order, orderBy, storeFilter } = filterState;
      const params = {
        order: order as 'asc' | 'desc',
        orderBy,
        page,
        rowsPerPage,
        storeFilter,
      };
      getTableData(params);
    }
  }, [page, rowsPerPage, filterState, hasNewColumns]);

  useEffect(() => {
    if (!loading) {
      setFilterState({ ...filterState, storeFilter });
      if (storeFilter) {
        setPage(0);
      }
    }
  }, [storeFilter]);

  const getTableData = async (params: {
    order: 'asc' | 'desc';
    orderBy: string;
    page: number;
    rowsPerPage: number;
    storeFilter?: number[];
  }) => {
    try {
      setLoading(true);
      const { order, orderBy, page, rowsPerPage, storeFilter } = params;
      const lsColumns = localStorage.getItem(columnLocalStorageKey);
      // Get visible columns from localstorage, only on mount
      const columnNames =
        !hasNewColumns && lsColumns
          ? JSON.parse(lsColumns)
          : tableOrdersColumns.filter((toc) => toc.isVisible).map((toc) => toc.columnName);
      await getCustomerTable(
        languageCode,
        order,
        orderBy,
        page,
        rowsPerPage,
        columnNames,
        storeFilter
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err instanceof Error ? err : new Error(err));
    }
  };

  const onSort = (orderBy: string, order: 'asc' | 'desc') => {
    if (orderBy) {
      setFilterState({ ...filterState, orderBy, order });
      setPage(page);
    }
  };

  const onRowClick = ({ row }: { row: Reports.OrderListRow }) => {
    history.push(`/${AppId}/orders/${row.orderId}`);
  };

  return (
    <CustomerDetailsOrdersTable
      AppId={AppId}
      columnOrder={order}
      columnOrderBy={orderBy}
      columnLocalStorageKey={columnLocalStorageKey}
      data={tableOrdersData}
      defaultColumns={defaultTableColumns}
      languageCode={languageCode}
      loading={loading}
      metadata={tableOrdersColumns}
      onRowClick={onRowClick}
      onSort={onSort}
      orderLocalStorageKey={orderLocalStorageKey}
      orderByLocalStorageKey={orderByLocalStorageKey}
      page={page}
      pagination={customerDetailsTablePagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      title="Orders"
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { customerDetailsTableData, customerDetailsTablePagination } = state.customers;
  return {
    AppId: state.currentApp.AppId as string,
    translate: getTranslate(state),
    customerDetailsTableData,
    customerDetailsTablePagination,
    languageCode: getActiveLanguage(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: RouteComponentProps<{ customerId: string }>
) => {
  const { customerId } = ownProps.match.params;
  return {
    getCustomerTable: (
      languageCode: string,
      order: 'asc' | 'desc',
      orderBy: string,
      page: number,
      rowsPerPage: number,
      columnNames: string[],
      storeFilter?: number[]
    ) =>
      dispatch(
        getCustomerDetailsTableData(
          customerId,
          languageCode,
          order,
          orderBy,
          page,
          rowsPerPage,
          columnNames,
          storeFilter
        )
      ),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomersTable);
