import React from 'react';

import Grid from '@mui/material/Grid';
import Skeleton from 'react-loading-skeleton';

export const StripeSkeletonLoader = () => (
  <Grid container data-fd="stripe-terminal-loader">
    <Grid item xs={12}>
      <Skeleton width={'100%'} height={45} />
    </Grid>

    <Grid item xs={12} style={{ paddingTop: 20, paddingRight: 12 }}>
      <Skeleton width={'50%'} height={15} />
    </Grid>
    <Grid item xs={12} style={{ marginBottom: 24 }}>
      <Skeleton width={'100%'} height={180} />
    </Grid>
  </Grid>
);
