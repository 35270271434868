import { getActiveLanguage } from 'react-localize-redux';
import { createSelector } from 'reselect';

const localeSelector = (state: AppState) => state.locale;
const availableLanguagesSelector = (state: AppState) => localeSelector(state).languages;
const activeLanguageSelector = (state: AppState) => localeSelector(state).activeLanguage;

export const languagesSelector = createSelector(availableLanguagesSelector, (languages) => {
  return Object.keys(languages);
});

/**
 * Returns user selected language with fallback
 * @example
 * activeLanguage === 'es-MX' returns ['es-MX', 'es', 'en']
 * activeLanguage === 'es' returns ['es', 'en']
 * activeLanguage === 'en' returns ['en']
 * @default
 * ['en']
 */
export const activeLanguagesSelector = createSelector(
  [activeLanguageSelector],
  (activeLanguage) => {
    if (activeLanguage === 'en') {
      return ['en'];
    }
    if (activeLanguage.includes('-')) {
      const fallbackBase = activeLanguage.split('-')[0];
      return fallbackBase === 'en'
        ? [activeLanguage, fallbackBase]
        : [activeLanguage, fallbackBase, 'en'];
    }
    return [activeLanguage, 'en'];
  }
);

const activeLanguage = (state: AppState) => getActiveLanguage(state.locale);
export const getLocale = createSelector([activeLanguage], (activeLanguage) => {
  if (activeLanguage === 'en') {
    return 'en-GB';
  } else return activeLanguage;
});
