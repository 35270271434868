import { Customer, StoreHeader } from '@flipdish/api-client-typescript';
import { createReducer } from 'redux-act';

import {
  getCustomerDataFailure,
  getCustomerDataRequest,
  getCustomerDataSuccess,
  getCustomerDetailsLoading,
  getCustomerDetailsSuccess,
  getCustomerDetailsTableSuccess,
  getCustomerOrderValuesDataSuccess,
  getCustomerOrderValuesLoading,
  getCustomerStatusLoading,
  getCustomerStatusSuccess,
  getStoreHeaders,
  resetCustomerList,
  resetCustomerOrderValues,
  setCustomerStatusSuccess,
  setDetailsStoreFilter,
  setListSearchFilter,
  setListStoreFilter,
} from './Customers.actions';
import { CustomerDetailsTable, CustomersTableData } from './types';

const defaultState = {
  customersTableData: undefined as undefined | CustomersTableData[],
  customersTablePagination: { page: 0, pageSize: 0, total: 0 },
  customerOrderValues: {
    totalOrders: 0 as number,
    orderValues: [] as Reports.OrderValue[],
    averageOrderValues: [] as Reports.OrderValue[],
    estimatedAnnualValues: [] as Reports.OrderValue[],
  },
  customerDetails: {
    customerId: 0,
    email: '',
    name: '',
    phoneNumber: '',
  } as Reports.CustomerDetail,
  customerJourney: {
    firstOrder: '',
    lastOrder: '',
    registrationDate: '',
  } as Reports.CustomerJourney,
  listStoreFilter: [] as number[],
  detailsStoreFilter: [] as number[],
  customerDetailsTableData: undefined as undefined | CustomerDetailsTable[],
  customerDetailsTablePagination: { page: 0, pageSize: 0, total: 0 },
  stores: [] as StoreHeader[],
  listSearchFilter: '',
  customerOrderValuesLoading: false,
  customerDetailsLoading: false,
  customerStatusLoading: false,
  customersTableLoading: true,
  customerStatus: {
    customerId: 0,
    RegistrationDate: undefined,
    CashOrdersEnabled: true,
    CardOrdersEnabled: true,
    MarketingEnabled: false,
  } as Customer,
};

const reducer = createReducer({}, defaultState);
reducer.on(getCustomerDataRequest, (state) => ({
  ...state,
  customersTableLoading: true,
}));

reducer.on(getCustomerDataSuccess, (state, payload) => ({
  ...state,
  customersTableLoading: false,
  customersTableData: payload.result,
  customersTablePagination: payload.pagination,
}));

reducer.on(getCustomerDataFailure, (state) => ({
  ...state,
  customersTableLoading: false,
}));

reducer.on(getCustomerOrderValuesDataSuccess, (state, payload) => {
  const { customerJourney, totalOrders, orderValues, averageOrderValues, estimatedAnnualValues } =
    payload;
  return {
    ...state,
    customerJourney: customerJourney!,
    customerOrderValues: {
      totalOrders: totalOrders!,
      orderValues: orderValues!,
      averageOrderValues: averageOrderValues!,
      estimatedAnnualValues: estimatedAnnualValues!,
    },
  };
});

reducer.on(getCustomerDetailsSuccess, (state, payload) => ({
  ...state,
  customerDetails: payload,
}));

reducer.on(setListStoreFilter, (state, payload) => {
  return {
    ...state,
    listStoreFilter: payload,
  };
});

reducer.on(setDetailsStoreFilter, (state, payload) => {
  return {
    ...state,
    detailsStoreFilter: payload,
  };
});

reducer.on(getCustomerDetailsTableSuccess, (state, payload) => ({
  ...state,
  customerDetailsTableData: payload.result,
  customerDetailsTablePagination: payload.pagination,
}));

reducer.on(getStoreHeaders, (state, payload) => ({
  ...state,
  stores: payload,
}));

reducer.on(resetCustomerOrderValues, (state) => {
  const { customerOrderValues, customerDetails, customerJourney, customerDetailsTableData } =
    defaultState;
  return {
    ...state,
    customerDetails,
    customerDetailsTableData,
    customerJourney,
    customerOrderValues,
  };
});

reducer.on(resetCustomerList, (state) => {
  return { ...state, customersTableLoading: true };
});

reducer.on(setListSearchFilter, (state, payload) => ({
  ...state,
  listSearchFilter: payload,
}));

reducer.on(getCustomerOrderValuesLoading, (state, payload) => ({
  ...state,
  customerOrderValuesLoading: payload,
}));
reducer.on(getCustomerDetailsLoading, (state, payload) => ({
  ...state,
  customerDetailsLoading: payload,
}));
reducer.on(getCustomerStatusSuccess, (state, payload) => ({
  ...state,
  customerStatus: payload,
}));
reducer.on(setCustomerStatusSuccess, (state, payload) => {
  const { CashOrdersEnabled, CardOrdersEnabled, MarketingEnabled } = payload;
  return {
    ...state,
    customerStatus: {
      ...state.customerStatus,
      CashOrdersEnabled,
      CardOrdersEnabled,
      MarketingEnabled,
    },
  };
});
reducer.on(getCustomerStatusLoading, (state, payload) => ({
  ...state,
  customerStatusLoading: payload,
}));
export default reducer;
