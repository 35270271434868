import { TableColumns } from '../../Finance/Payouts/types';

export const invoiceTableMeta: TableColumns[] = [
  {
    columnName: 'Number',
    translationId: 'Invoice_no',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'FinalisedAt',
    translationId: 'Payment_due_date',
    columnType: 'DynamicTableCell',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'Total',
    translationId: 'Payment_amount',
    columnType: 'DecimalWithCurrency',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'PdfLink',
    translationId: 'Action',
    columnType: 'InvoiceAction',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
];

export const invoiceDefaultColumns = ['Number', 'FinalisedAt', 'Total', 'PdfLink'];
