import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type InfoIconProps = {
  size?: 24 | 20 | 13;
  verticalAlign?: 'bottom' | 'text-top' | 'middle';
  color?: string;
};

const InfoIcon = React.forwardRef(
  ({ size, verticalAlign, color }: InfoIconProps, ref: React.RefObject<SVGSVGElement>) => {
    return (
      <InfoOutlinedIcon
        ref={ref}
        style={{
          fontSize: size ? size : 24,
          color: color ?? '#757575',
          verticalAlign: verticalAlign ? verticalAlign : 'bottom',
          cursor: 'pointer',
        }}
        aria-hidden
      />
    );
  }
);

export default InfoIcon;
