import React, { useEffect, useState } from 'react';

import {
  RestApiErrorResult,
  UpdateFulfillmentStatesConfiguration,
} from '@flipdish/api-client-typescript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { flagService } from '../../../services';
import { SkeletonLoader } from '../../../ui/atoms';
import PageLayout from '../../../ui/Layout';
import { EditableLabel } from '../../common/molecules';
import EditFulfillmentConfigurationForm from '../components/EditFulfillmentConfigurationForm';
import { orderFulfillmentStatusService } from '../services/orderFulfillmentStatus.service';

type Props = RouteComponentProps<{ fulfillmentConfigId: string }>;

const EditFulfillmentConfiguration = ({
  appId,
  isFulfillmentFlagOn,
  fulfillmentConfigId,
  notifyFormSaving,
  notifyFormSaved,
  notifyFormError,
  closeNotifyFormSaving,
  translate,
}: MappedDispatch & MappedState) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isArchiveDialogVisible, setIsArchiveDialogVisible] = useState(false);
  const [fulfillmentConfigName, setFulfillmentConfigName] = useState<string>('');

  const {
    data: fulfillmentConfig,
    isPending,
    isFetching,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['fulfillmentConfig', fulfillmentConfigId],
    queryFn: () =>
      orderFulfillmentStatusService.getFulfillmentConfiguration(appId, fulfillmentConfigId),

    retry: 2,
    enabled: isFulfillmentFlagOn,
  });

  useEffect(() => {
    if (isSuccess && fulfillmentConfig.Name && !fulfillmentConfigName) {
      setFulfillmentConfigName(fulfillmentConfig.Name);
    }
  }, [fulfillmentConfig, isSuccess]);

  useEffect(() => {
    if (isError) {
      notifyFormError({
        message: 'There_was_an_error_fetching_the_configuration_deta',
      });
      history.push(`/${appId}/orderFulfillmentStatus`);
    }
  }, [isError]);

  const { mutate: archiveMutation } = useMutation({
    mutationFn: () => {
      notifyFormSaving();
      return orderFulfillmentStatusService.deleteFulfillmentConfiguration(
        appId,
        fulfillmentConfigId
      );
    },
    onSuccess: () => {
      closeNotifyFormSaving();
      setIsArchiveDialogVisible(false);
      history.push(`/${appId}/orderFulfillmentStatus`);
      notifyFormSaved();
      queryClient.invalidateQueries({
        queryKey: [orderFulfillmentStatusService.getorderFulfillmentStatusListKey],
      });
    },
    onError: () => {
      closeNotifyFormSaving();
      notifyFormError({ message: 'Something_went_wrong' });
      setIsArchiveDialogVisible(false);
    },
  });

  const { mutate: saveMutation, isPending: isSaving } = useMutation({
    mutationFn: async (updatedConfiguration: UpdateFulfillmentStatesConfiguration) => {
      notifyFormSaving();
      const newConfiguration = { ...updatedConfiguration, Name: fulfillmentConfigName };

      queryClient.invalidateQueries({ queryKey: [fulfillmentConfigId] });
      await orderFulfillmentStatusService.updateFulfillmentConfiguration(
        appId,
        fulfillmentConfigId,
        newConfiguration
      );

      return newConfiguration;
    },
    onSuccess: () => {
      closeNotifyFormSaving();
      notifyFormSaved();
      history.push(`/${appId}/orderFulfillmentStatus`);
    },
    onError: (error: AxiosError) => {
      closeNotifyFormSaving();
      if (error.response?.data) {
        const apiErrorMessage = error.response.data as RestApiErrorResult;
        notifyFormError({ message: apiErrorMessage.Message });
      }
    },
  });

  const renderContent = () => {
    if (isPending || isFetching || isSaving || fulfillmentConfig === undefined) {
      return (
        <SkeletonLoader
          fdKey={'edit-fulfillment-config-skeleton-loader'}
          rows={[
            { height: '126px', width: '100%' },
            { height: '126px', width: '100%' },
            { height: '126px', width: '50%' },
          ]}
        />
      );
    }

    return (
      <EditFulfillmentConfigurationForm
        configuration={fulfillmentConfig}
        onSaveChanges={(config: UpdateFulfillmentStatesConfiguration) => {
          if (config.Name || fulfillmentConfigName) {
            saveMutation(config);
          } else {
            notifyFormError({ message: 'Configuration_name_is_required' });
          }
        }}
        isArchiveDialogVisible={isArchiveDialogVisible}
        setIsArchiveDialogVisible={setIsArchiveDialogVisible}
        onArchive={archiveMutation}
        translate={translate}
        loading={isPending || isFetching}
        configurationId={fulfillmentConfigId}
      />
    );
  };

  if (!isFulfillmentFlagOn) {
    return null;
  }

  const handleTitleChange = (changes: { Name: string }) => {
    setFulfillmentConfigName(changes.Name);
  };

  return (
    <PageLayout
      toParent={`/${appId}/orderFulfillmentStatus`}
      strictToParent
      documentTitle={'Edit_Order_Status_Configuration'}
      title={
        <EditableLabel
          documentTitle={'Configuration change'}
          labelKey={'Edit_order_status_config_name_label'}
          inputKey={'Edit_order_status_config_name_input'}
          value={fulfillmentConfigName}
          changePropName="Name"
          onChange={handleTitleChange}
        />
      }
    >
      {renderContent()}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { locale } = state;
  return {
    //@ts-ignore
    appId: props.match.params.appId,
    isFulfillmentFlagOn: flagService.isFlagOn(state, 'orderFulfillmentStatusConfigurations'),
    translate: getTranslate(locale),
    //@ts-ignore
    fulfillmentConfigId: props.match.params.configId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyFormSaving: () => dispatch(closeNotifySaving()),
  notifyFormError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyFormSaving: () => dispatch(notifySaving()),
  notifyFormSaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFulfillmentConfiguration);
