import React from 'react';

import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Select from '@fd/ui/Select/Select';

import { getOrdersSummary, ordersAction } from '../../../actions/order.action';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../WithRouteSearchParams';
import { setStateFilter } from '../actions';

const styles = () => ({
  select: {
    zIndex: 10,
    '& >div div div div div div': {
      position: 'absolute' as const,
      top: 0,
      left: 0,
      zIndex: 20,
      paddingLeft: '10px',
      overflow: 'hidden',
      width: '90%',
      height: '100%',
      '& >input': {
        width: '100%',
        height: '50px',
      },
    },
  },
});

type OrderFilterProps = { onChange: () => void };

export type Suggestions = { value: string; type: string; label: string };

type OrderFilterState = {
  query: string;
  suggestions: Suggestions[];
  stateFilter: Suggestions[];
  isLoading: boolean;
  orderId: string[];
};

type Props = OrderFilterProps &
  WithRouteSearchParamsProps<{ state: string }> &
  RouteComponentProps &
  WithStyles<typeof styles> &
  MappedDispatch &
  MappedState & { orderSearchCodeResults: Suggestions };

class OrderFilter extends React.Component<Props, OrderFilterState> {
  public handleSearch = debounce((query, { action }) => {
    if (action === 'input-change') {
      this.setState({ query, isLoading: true });
    }
  }, 500);

  private query = '';

  public constructor(props) {
    super(props);

    const { translate } = props;
    const suggestions = [
      {
        value: 'ReadyToProcess',
        type: 'status:ReadyToProcess',
        label: `${translate('Status')}:${translate('ReadyToProcess')}`,
      },
      {
        value: 'AcceptedByRestaurant',
        type: 'status:AcceptedByRestaurant',
        label: `${translate('Status')}:${translate('AcceptedByRestaurant')}`,
      },
      {
        value: 'Rejected',
        type: 'status:Rejected',
        label: `${translate('Status')}:${translate('Rejected')}`,
      },
    ];

    this.state = {
      query: '',
      suggestions,
      stateFilter: [],
      isLoading: false,
      orderId: [],
    };
  }

  public componentDidMount() {
    this.setFilterValues();
  }

  public componentDidUpdate(prevProps) {
    if (this.state.query !== this.query) {
      this.query = this.state.query;
      this.getOrderIDs();
    }

    if (!isEqual(prevProps.stateFilter, this.props.stateFilter)) {
      this.setFilterValues();
    }
  }

  public getOrderIDs = async () => {
    const { appId, orderIdSearch } = this.props;
    const AppId = appId ?? 'global';

    await orderIdSearch(AppId, [this.state.query]);
    this.setState({ isLoading: false });
  };

  public setFilterValues() {
    const filterValues = this.props.stateFilter;
    // @ts-ignore
    const filters = this.state.suggestions.filter((s) => filterValues.indexOf(s.value) !== -1);
    this.setState({ stateFilter: filters });
  }

  public filterForStatus = (stateF) => {
    return stateF.filter((value) => {
      return isNaN(value);
    });
  };

  public filterForId = (stateF) => {
    const { history, appId } = this.props;
    stateF = stateF.filter((value) => !isNaN(value));
    stateF[0] !== undefined && history.push(`/${appId}/orders/${stateF[0]}`);
  };

  public statusToFriendlyName = (stateF) => {
    const { setStateFilter, setSearch } = this.props;

    const statuses = ['AcceptedByRestaurant', 'ReadyToProcess', 'Cancelled', 'Rejected'];
    for (let i = 0; i < stateF.length; i++) {
      const isOrderStatus = statuses.some((arrVal) => stateF[i] === arrVal);
      if (isOrderStatus) {
        let newStateF: string[] = [];
        newStateF = stateF.map((status) => {
          return status === 'ReadyToProcess'
            ? 'Pending'
            : status === 'AcceptedByRestaurant'
              ? 'Accepted'
              : status;
        });
        setSearch(newStateF as any);
        const statusSearchOnly = this.filterForStatus(stateF);
        setStateFilter(statusSearchOnly);
      } else {
        this.filterForId(stateF);
      }
    }
  };

  public handleChange = (searchParams) => {
    const { setStateFilter, onChange, setSearch } = this.props;
    const stateF = searchParams.map((v) => v.value);
    this.statusToFriendlyName(stateF);
    if (stateF.length === 0) {
      setStateFilter(stateF);
      setSearch({ state: '' });
    }
    onChange();
  };

  public render() {
    const { classes, translate, orderSearchCodeResults } = this.props;
    const { isLoading } = this.state;

    const orderCodeSuggestions = {
      value: orderSearchCodeResults.toString(),
      type: `Order Id:${orderSearchCodeResults}`,
      label: `${translate('Order_id')}:${orderSearchCodeResults}`,
    };

    const allSuggestions = this.state.suggestions.concat(orderCodeSuggestions);

    return (
      <Select
        className={classes.select}
        dataFd="search-filter"
        isClearable
        dropdownIcon="search"
        isLoading={isLoading}
        isMulti
        onChange={this.handleChange}
        onInputChange={this.handleSearch}
        options={orderSearchCodeResults ? allSuggestions : this.state.suggestions}
        placeholder={translate('Type_state') as TranslationId}
        TextFieldProps={{
          inputProps: { maxLength: 10 },
          fdKey: 'search-dropdown',
          name: 'search-filter',
        }}
        value={this.state.stateFilter}
      />
    );
  }
}

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp, locale, OrdersFilter } = state;
  return {
    appId: currentApp.AppId,
    translate: getTranslate(locale),
    stateFilter: OrdersFilter.states,
    orderSearchCodeResults: OrdersFilter.orderSearchCodeResults,
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setStateFilter: (states) => {
    dispatch(setStateFilter(states));
  },
  orderIdSearch: (appId, query) => dispatch(ordersAction.setOrderSearchIDResults(appId, [query])),
  getOrdersSummary: (appId, page, limit) =>
    dispatch(
      getOrdersSummary({
        appId,
        page,
        limit,
      })
    ),
});

const EnhancedComponent = compose<Props, OrderFilterProps>(
  withStyles(styles, {
    name: 'OrdersFilter',
    withTheme: true,
  }),
  withRouteSearchParams({
    name: 'status',
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderFilter);

export default EnhancedComponent;
