import {
  CatalogProductsApiFactory,
  CreateProduct,
  UpdateProduct,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';
import { CatalogItemType } from '../types';

const catalogProductsApi = createApi(CatalogProductsApiFactory);

const baseURL = getApiEndPoint();
const newProductsApis = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const getProductsQueryKey = 'getProducts';
const getModifiersQueryKey = 'getModifiers';
const getModifiersForGroupCreationQueryKey = 'getModifiersForGroupCreation';
async function getCatalogProducts(
  appId: string,
  page: number,
  limit: number,
  searchTerm: string,
  types: CatalogItemType[] = []
) {
  try {
    const result = await catalogProductsApi.getProducts(appId, types, searchTerm, page, limit);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getProductByIdQueryKey = 'getProductById';
const getModifierByIdQueryKey = 'getModifierById';
async function getCatalogProductById(appId: string, itemId: string) {
  try {
    const result = await newProductsApis.get(`${appId}/catalog/products/${itemId}`);
    return result.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function createCatalogProduct(appId: string, item: CreateProduct) {
  try {
    const result = await catalogProductsApi.createProduct(appId, item);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateCatalogProduct(appId: string, itemId: string, item: UpdateProduct) {
  try {
    const result = await catalogProductsApi.updateProduct(appId, itemId, item);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function duplicateCatalogProduct(appId: string, itemId: string) {
  try {
    const result = await catalogProductsApi.duplicateProduct(appId, itemId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function archiveCatalogProduct(appId: string, itemId: string) {
  try {
    const result = await catalogProductsApi.archiveProduct(appId, itemId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const catalogProductsService = {
  archiveCatalogProduct,
  createCatalogProduct,
  duplicateCatalogProduct,
  getModifiersForGroupCreationQueryKey,
  getModifiersQueryKey,
  getProductsQueryKey,
  getCatalogProducts,
  getCatalogProductById,
  getProductByIdQueryKey,
  getModifierByIdQueryKey,
  updateCatalogProduct,
};
