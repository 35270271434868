import usePrevious from '@fd/customHooks/usePrevious';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

export const FormikScrollToError = () => {
  const { submitCount, errors } = useFormikContext();
  const prevSubmitCount = usePrevious(submitCount);
  if (!isEmpty(errors) && submitCount > 0 && submitCount !== prevSubmitCount) {
    // formik sorts the keys by the order the field is displayed
    const errorKeys = Object.keys(errors);
    const elementToScrollTo = document.getElementsByName(errorKeys[0])?.[0];
    //image containers can't be scrolled to currently
    elementToScrollTo?.scrollIntoView({ behavior: 'smooth' });
  }
  return null;
};
