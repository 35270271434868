import React, { ChangeEvent, useCallback, useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { QueryClient } from '@tanstack/react-query';
import { type FormikProps, withFormik } from 'formik';
import debounce from 'lodash/debounce';
import { compose, withProps } from 'recompose';

import { enqueueTranslateNotification, removeNotification } from '../../../actions/context.actions';
import { updatePromise } from '../../../actions/store.actions';
import { formikValidate } from '../../../helpers/validation';
import { Store } from '../types';
import withStoreByIdData, { WithStoreByIdData } from '../withStoreByIdData';

const update = (dispatch, storeId, storeGroupId, changes) => {
  dispatch(enqueueTranslateNotification('Auto_saving'));
  dispatch(updatePromise(storeId, storeGroupId, changes))
    .then(() => {
      dispatch(removeNotification());
    })
    .catch((err) => {
      dispatch(removeNotification());
    });
};

export interface IStorePhoneEditorProps {
  storeGroupId: number;
  storeId: number;
  className?: string;
  disabled?: boolean;
  queryClient?: QueryClient;
}

type FormValues = Pick<Store, 'PhoneNumber'>;
type Props = IStorePhoneEditorProps & FormikProps<FormValues> & WithStoreByIdData;
const StorePhoneEditor = compose<Props, IStorePhoneEditorProps>(
  withStoreByIdData,
  withProps(({ storeByIdData, ...other }: Props) => {
    const PhoneNumber = storeByIdData && storeByIdData.PhoneNumber;
    const initialValues = { PhoneNumber };
    return {
      ...other,
      initialValues,
    };
  }),
  withFormik<Props, FormValues>({
    displayName: 'StorePhoneEditor',
    enableReinitialize: true,
    mapPropsToValues(props) {
      return props.initialValues;
    },
    validate(values) {
      if (!values.PhoneNumber || values.PhoneNumber === '') {
        return {};
      }

      const error = formikValidate.phone(values.PhoneNumber, 'Store_phone_invalid_input');
      if (error) {
        return { PhoneNumber: error };
      }

      return {};
    },
    handleSubmit(values, formikBag) {
      const {
        props: { dispatch, storeId, storeGroupId },
      } = formikBag;

      update(dispatch, storeId, storeGroupId, values);
    },
  })
)((props: Props) => {
  const { errors, setFieldValue, disabled, dirty, handleSubmit, values } = props;
  const error = errors.PhoneNumber;

  const save = useMemo(() => debounce(handleSubmit, 1000), []);
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('PhoneNumber', e.target.value);
    save();
  }, []);

  return (
    <FormControl fullWidth error={!!error}>
      <TextField
        variant="standard"
        name="PhoneNumber"
        placeholder={disabled ? undefined : '+123456789'}
        autoComplete={'off'}
        value={values.PhoneNumber ? values.PhoneNumber : ''}
        fullWidth
        aria-describedby="component-error-text"
        disabled={disabled}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            'data-fd': 'store_phone_number',
          },
        }}
      />
      {dirty && error && <FormHelperText id="PhoneNumber-error-text">{error}</FormHelperText>}
    </FormControl>
  );
});

export default StorePhoneEditor;
