import { Menu } from '@flipdish/api-client-typescript';
import { useQuery } from '@tanstack/react-query';

import { menusService } from '../services/menus.service';

type Params = { menuId?: number; enabled: boolean; refetchOnMount?: boolean };

export function useMenu({ menuId = 0, enabled, refetchOnMount = false }: Params): {
  isLoading: boolean;
  data?: Menu;
  isError: boolean;
} {
  const { isLoading, data, isError } = useQuery({
    queryKey: ['getMenu', menuId],
    queryFn: () => menusService.getMenu(menuId),
    enabled: enabled,
    refetchOnMount: refetchOnMount,
  });
  return {
    isLoading,
    data,
    isError,
  };
}
