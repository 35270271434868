import React from 'react';

import { App, Voucher } from '@flipdish/api-client-typescript';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import {
  createVoucher,
  createVoucherRequestReset,
  vouchersActions,
} from '../../../actions/vouchers.actions';
import { flagService } from '../../../services/flagService';
import { useVoucherSettings } from '../hooks/useVoucherSettings';
import { getIsCreateLoading } from '../selectors';
import FreeDeliveryFeeForm from './components/FreeDeliveryFeeForm';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { dispatch: ThunkDispatch };
const FreeDeliveryFee = (props: Props) => {
  const {
    appId,
    createVoucher,
    errorMessage,
    getStats,
    getVoucher,
    isExtraStats,
    isLoading,
    isSaving,
    isSuccess,
    isVoucherChannelEnabled, // TODO - remove when voucherChannel is fully released
    isVoucherDiscoverableEnabled, // TODO - remove when voucherDiscoverable is fully released
    isVoucherLimitAvailabilityEnabled, // TODO - remove when voucherLimitAvailability is fully released
    isVoucherValidPeriodEnabled, // TODO - remove when voucherValidPeriod is fully released
    match,
    resetVoucher,
    translate,
    updateVoucher,
    voucher,
    vouchersRoute,
  } = props;

  const isEditMode = !!match.params.voucherId;

  useVoucherSettings({
    errorMessage,
    getStats,
    getVoucher,
    isExtraStats,
    isSaving,
    isSuccess,
    isVoucher: !!voucher,
    match,
    resetVoucher,
    translate,
  });

  if (isSuccess && vouchersRoute) {
    return <Redirect to={vouchersRoute} />;
  }

  return (
    <PageLayout
      documentTitle="Free_delivery_fee"
      toParent={`/${appId}/vouchers`}
      title={<Translate id="Free_delivery_fee" />}
    >
      <PaperContainer fluid>
        <FreeDeliveryFeeForm
          create={createVoucher}
          isEditMode={isEditMode}
          isLoading={isLoading}
          isVoucherChannelEnabled={isVoucherChannelEnabled} // TODO - remove when voucherChannel is fully released
          isVoucherDiscoverableEnabled={isVoucherDiscoverableEnabled} // TODO - remove when voucherDiscoverable is fully released
          isVoucherLimitAvailabilityEnabled={isVoucherLimitAvailabilityEnabled} // TODO - remove when voucherLimitAvailability is fully released
          isVoucherValidPeriodEnabled={isVoucherValidPeriodEnabled} // TODO - remove when voucherValidPeriod is fully released
          key={isEditMode && voucher ? voucher.VoucherId : 'form'}
          translate={translate}
          update={updateVoucher}
          voucher={voucher}
        />
      </PaperContainer>
    </PageLayout>
  );
};

const mapStateToProps = (state: AppState, ownProps) => {
  const { vouchers, currentApp, permissions } = state;
  const isSaving = getIsCreateLoading(state);

  const isSuccess = vouchers.addNew.request.success === true;
  const error = vouchers.addNew.request.error;
  const errorMessage = error ? error.message : undefined;

  const vouchersRoute = currentApp.AppId ? `/${currentApp.AppId}/vouchers` : undefined;
  const voucher: Voucher = ownProps.match.params.voucherId ? vouchers.voucher : null;
  const isExtraStats = permissions.some(
    (p) => p === App.AppResourceSetEnum.ViewVouchersStatistics.toString()
  );

  return {
    appId: currentApp.AppId,
    errorMessage,
    isExtraStats,
    isLoading: vouchers.voucherLoading,
    isSaving,
    isSuccess,
    isVoucherChannelEnabled: flagService.isFlagOn(state, 'voucherChannel'), // TODO - remove when voucherChannel is fully released
    isVoucherDiscoverableEnabled: flagService.isFlagOn(state, 'voucherDiscoverable'), // TODO - remove when voucherDiscoverable is fully released
    isVoucherLimitAvailabilityEnabled: flagService.isFlagOn(state, 'voucherLimitAvailability'), // TODO - remove when voucherLimitAvailability is fully released
    isVoucherValidPeriodEnabled: flagService.isFlagOn(state, 'voucherValidPeriod'), // TODO - remove when voucherValidPeriod is fully released
    match: ownProps.match,
    translate: getTranslate(state.locale),
    voucher,
    vouchersRoute,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  return {
    createVoucher: (voucher) => {
      return dispatch(createVoucher(voucher, true));
    },
    resetVoucher: () => {
      dispatch(createVoucherRequestReset());
    },
    getVoucher: (voucherId) => {
      dispatch(vouchersActions.getVoucher(voucherId));
    },
    getStats: (voucherId) => {
      dispatch(vouchersActions.getVoucherStatistics(voucherId));
    },
    updateVoucher: (voucher) => {
      return dispatch(
        vouchersActions.updateVoucher(
          {
            voucherId: parseInt(ownProps.match.params.voucherId),
            voucher,
          },
          true
        )
      );
    },
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(FreeDeliveryFee);

export default EnhancedComponent;
