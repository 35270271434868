import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { times } from 'lodash';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '40px',
    },
    gridColumnRight: {
      marginLeft: '-10px',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    mobile: {
      marginTop: '16px',
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },
    },
    title: {
      [theme.breakpoints.down('md')]: {
        marginBottom: '16px',
      },
    },
    deliveryInfo: {
      marginBottom: '8px',
    },
  })
);

export const CustomerPhoneSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.mobile}>
        <Skeleton width={'36%'} height={12} />
      </Grid>
      <Grid>
        <Skeleton width={'22%'} height={18} />
      </Grid>
    </>
  );
};

export const OrderDeliveryInfoSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.mobile}>
      {times(5, (key) => (
        <div key={key} className={classes.deliveryInfo}>
          <Skeleton height={24} />
        </div>
      ))}
    </Grid>
  );
};

export const PaymentAccountDescriptionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.mobile}>
        <Skeleton width={'36%'} height={12} />
      </Grid>
      <Grid>
        <Skeleton width={'22%'} height={18} />
      </Grid>
    </>
  );
};

export const DeliveryLocationSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'60%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'36%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'30%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'36%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const AdditionalDetailsSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      item
      xs={12}
      className={classes.container}
    >
      <Grid item md={2} xs={4} sm={2}>
        <Skeleton width={'70%'} height={24} />
      </Grid>
    </Grid>
  );
};

const DefaultSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'35%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'58%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid className={classes.title}>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'35%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'58%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'60%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'10%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'36%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'18%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'30%'} height={18} />
          </Grid>
          <Grid className={classes.mobile}>
            <Skeleton width={'36%'} height={12} />
          </Grid>
          <Grid>
            <Skeleton width={'22%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.container}>
        <Grid item md={5} xs={12} sm={5} className={classes.title}>
          <Skeleton width={'45%'} height={24} />
        </Grid>
        <Grid item md={7} xs={12} sm={7} className={classes.gridColumnRight}>
          <Grid>
            <Skeleton width={'90%'} height={18} />
          </Grid>
          <Grid className={classes.container}>
            <Skeleton width={'36%'} height={18} />
          </Grid>
        </Grid>
      </Grid>

      <DeliveryLocationSkeleton />
      <AdditionalDetailsSkeleton />
    </>
  );
};

export default DefaultSkeleton;
