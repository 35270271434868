import React, { useCallback, useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import SettingsIcon from '../../../assets/images/developer_section_settings.svg';
import { useLoadingAsIndicator } from '../../../custom-hooks/useAsIndicator';
import { notify, NotifyProps } from '../../../layouts/Notify/actions';
import { historyMinLength } from '../../../ui/BackButtonWrapper';
import Button from '../../../ui/Button/Button';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { DELETE_OAUTHAPP, deleteOAuthApp, getOAuthAppById, getOAuthAppSecretKey } from '../actions';
import OAuthAppCardLoading from '../components/OAuthAppCardLoading';
import OAuthAppDeleteDialog from '../components/OAuthAppDeleteDialog';
import OAuthAppCardInfo from './OAuthAppCardInfo';
import OAuthAppCardLinks from './OAuthAppCardLinks';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    flexGrow: 1,
  },
  wrapper: {
    marginBottom: 10,
  },
  avatar: {
    backgroundColor: '#72a9f9',
    borderRadius: '4px',
    height: '144px',
    width: '144px',
    marginRight: 32,
  },
  deleteButton: {
    marginLeft: spacing(-1),
    [breakpoints.only('sm')]: {
      marginLeft: spacing(2),
    },
    [breakpoints.only('xs')]: {
      marginLeft: spacing(1),
    },
  },
}));

type Props = MappedState & MappedDispatch & RouteComponentProps;
const OAuthAppDetails: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    isLoading,
    oauthApp,
    match,
    notify,
    translate,
    deleteOAuthApp,
    isDeleting,
    currentApp,
    history,
  } = props;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const copyHandler = useCallback((name: string) => {
    notify({
      message: `${name} ${translate('copied_to_clipboard')}`,
      variant: 'success',
    });
  }, []);
  const isDeleted = useLoadingAsIndicator(isDeleting);

  useEffect(() => {
    const { loadOAuthApp, match } = props;
    loadOAuthApp(match.params.OAuthAppId);
  }, []);
  useEffect(() => {
    const { loadSecretKey, match } = props;
    loadSecretKey(match.params.OAuthAppId);
  }, [props.oauthApp.OauthAppId]);
  useEffect(() => {
    if (isDeleted) {
      if (history.length >= historyMinLength) {
        history.goBack();
      } else {
        history.replace(`/${currentApp.AppId}/developers/apps`);
      }
    }
  }, [isDeleted]);

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/developers/apps`}
      title={translate('My_oauth_apps')}
      documentTitle="My_oauth_apps"
      caption={oauthApp.OauthAppName}
    >
      <PaperContainer className={classes.wrapper}>
        {isLoading ? (
          <OAuthAppCardLoading />
        ) : (
          <div className={classes.root}>
            <Grid container>
              <Hidden mdDown>
                <Grid item>
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <img src={oauthApp.LogoUri ? oauthApp.LogoUri : SettingsIcon} />
                  </Avatar>
                </Grid>
              </Hidden>
              <Grid item sm container>
                <OAuthAppCardInfo oauthApp={oauthApp} onCopy={copyHandler} />
                <OAuthAppCardLinks url={match.url} />
              </Grid>
            </Grid>
          </div>
        )}
      </PaperContainer>
      <Button
        fdKey="delete_oauth_app"
        color="secondary"
        onClick={() => {
          setDialogOpen(true);
        }}
        className={classes.deleteButton}
      >
        {translate('Delete_oauth_app')}
      </Button>
      <OAuthAppDeleteDialog
        appName={oauthApp.OauthAppName || ''}
        isDialogOpen={dialogOpen}
        submit={() => {
          deleteOAuthApp(oauthApp.OauthAppId);
        }}
        loading={isDeleting}
        setDialogOpen={setDialogOpen}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const {
    locale,
    loading,
    developers: { isLoading, oauthApp },
    currentApp,
  } = state;
  return {
    isLoading,
    oauthApp,
    currentApp,
    translate: getTranslate(locale),
    isDeleting: loading[DELETE_OAUTHAPP],
    match: ownProps.match,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadOAuthApp: (OAuthAppId) => {
    dispatch(getOAuthAppById(OAuthAppId));
  },
  loadSecretKey: (OAuthAppId) => {
    dispatch(getOAuthAppSecretKey(OAuthAppId));
  },
  deleteOAuthApp: (OAuthAppId) => {
    dispatch(deleteOAuthApp(OAuthAppId));
  },
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OAuthAppDetails);

export default EnhancedComponent;
