import type { Org } from '@flipdish/orgmanagement';

import type { SelectedProperty } from './rms.reducer';

export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_CURRENT_ORG = 'SET_CURRENT_ORG';

export const setSelectedProperty = (selectedProperty: SelectedProperty) => ({
  type: SET_SELECTED_PROPERTY,
  payload: selectedProperty,
});

export const setCurrentOrg = (currentOrg: Org) => ({
  type: SET_CURRENT_ORG,
  payload: currentOrg,
});
