export const getUtcDate = (date: string | Date): string | Date => {
  return date ? new Date((date as string).endsWith('Z') ? date : `${date}Z`) : date;
};

export const getExtraApiCallsToMake = ({
  Limit,
  MaxSafeLimit,
  TotalRecordCount,
}: {
  Limit: number;
  MaxSafeLimit: number;
  TotalRecordCount: number;
}) => {
  const initialApiCall = 1;
  const extraApiCalls =
    Math.trunc(Number(TotalRecordCount / Limit)) +
    (TotalRecordCount % Limit ? 1 : 0) -
    initialApiCall;

  return extraApiCalls < MaxSafeLimit ? extraApiCalls : MaxSafeLimit;
};
