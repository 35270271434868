import React, { useEffect, useState } from 'react';

import { Order } from '@flipdish/api-client-typescript';
import { cloneDeep, each, find, groupBy, isEqual, map, round, sortBy, values } from 'lodash';

import Items from './Items';

type Props = { order: Order; languageCode: string; currency: string };

const groupingItems = (items) => {
  const grouping = [{ data: items[0], count: 1 }];

  each(items, (item, index: number) => {
    if (index !== 0) {
      const found = find(grouping, (i) => {
        return isEqual(i.data.OrderItemOptions, item.OrderItemOptions);
      });
      if (found) {
        found.count++;
      } else {
        grouping.push({ data: item, count: 1 });
      }
    }
  });
  return grouping.map((item) => {
    if (item.count === 1) {
      return item.data;
    } else {
      const newItem = item.data;
      newItem.Name = `${item.count} x ${newItem.Name}`;
      // sometimes if the price is like '3.01' 3.01 * 9 = 27.089999999999996, so need to round it off
      newItem.Price = round(newItem.Price * item.count, 2);

      each(newItem.OrderItemOptions, (op) => (op.Price = op.Price * item.count));
      return newItem;
    }
  });
};
const groupOrderItems = (orderItems: Order['OrderItems']) => {
  const sortedMenuSection = sortBy(
    groupBy(orderItems, 'MenuSectionDisplayOrder'),
    'MenuSectionDisplayOrder'
  );
  const groupedMenuItems = map(sortedMenuSection, (item) => {
    return values(groupBy(item, 'MenuItemId'));
  });
  const groupedMenuItemsWithSameOptions = map(groupedMenuItems, (groupedItems) => {
    return map(groupedItems, (item) => groupingItems(item));
  });
  return groupedMenuItemsWithSameOptions;
};
const OrderItems: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { order, languageCode, currency } = props;

  const [groupedItems, setGroupedItems] = useState();

  useEffect(() => {
    setGroupedItems(groupOrderItems(cloneDeep(order.OrderItems)) as any);
  }, [order]);

  return <Items orderItems={groupedItems as any} languageCode={languageCode} currency={currency} />;
};

export default OrderItems;
