import React from 'react';

import Avatar from '@mui/material/Avatar';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconContainer: {
    backgroundColor: theme.palette.grey[300],
    height: theme.spacing(13.5),
    marginRight: theme.spacing(3),
    width: theme.spacing(13.5),
  },
  image: {
    height: 64,
    width: 64,
    marginRight: theme.spacing(3),
    borderRadius: 4,
  },
}));

export type PageHeaderProps = {
  heading: string | React.ReactElement;
  icon?: React.ReactElement;
  description: string | React.ReactElement;
  image?: string;
  fdKey?: string;
};

export const PageHeader = ({ heading, icon, description, image, fdKey }: PageHeaderProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} data-fd={fdKey}>
      <div className={classes.content}>
        {icon && (
          <Avatar data-fd="page-header-icon" className={classes.iconContainer}>
            {icon}
          </Avatar>
        )}
        {image && <img src={image} className={classes.image} data-fd="page-header-image" />}
        <div>
          <Typography variant="h4">{heading}</Typography>
          <div className={classes.description}>
            <Typography variant="caption">{description}</Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};
