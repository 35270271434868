import {
  BusinessHoursOverride,
  CurrencyData,
  HomeAction,
  StoreDataPoint,
} from '@flipdish/api-client-typescript';

import {
  DISMISS_SETUP_SUCCESS,
  DismissSetupSuccess,
  HOME_RESET,
  LOAD_SAVINGS_FAILURE,
  LOAD_SAVINGS_SUCCESS,
  LOAD_STORE_OVERRIDES_SUCCESS,
  LOAD_STORES_NET_SALES_SUCCESS,
  LoadSavingsFailure,
  LoadSavingsSuccess,
  LoadSetupConfigsFailure,
  LoadSetupConfigsSuccess,
  LoadStoreHourOverridesSuccess,
  LoadStoresNetSalesSuccess,
  REMOVE_STORE_OVERRIDE_SUCCESS,
  RemoveStoreHourOverrideSuccess,
  SetSelectedStores,
  SetStoresSearch,
  SETUP_CONFIG_FAILURE,
  SETUP_CONFIG_SUCCESS,
  STORE_SEARCH,
  STORE_SELECTION,
} from './actions';

type HomeDefaultState = {
  setupConfigs: {
    data: HomeAction[];
    isEmpty: boolean;
  };
  savings: {
    data: CurrencyData[];
    isEmpty: boolean;
  };
  stores: {
    order_index: number[];
    hasMore: boolean;
    nextPage: number;
    isEmpty: boolean;
    showSearch: boolean;
    overrides: {
      data: {
        [storeId: number]: BusinessHoursOverride[];
      };
    };
    error?: Error;
  };
  netSales: {
    data: {
      [storeId: number]: StoreDataPoint[];
    };
  };
};
const homeDefaultState: HomeDefaultState = {
  setupConfigs: {
    data: [],
    isEmpty: false,
  },
  savings: {
    data: [],
    isEmpty: false,
  },
  stores: {
    order_index: [],
    hasMore: false,
    nextPage: 0,
    overrides: {
      data: {},
    },
    showSearch: false,
    isEmpty: false,
  },
  netSales: {
    data: {},
  },
};

function homeReducer(state = homeDefaultState, action): HomeDefaultState {
  switch (action.type) {
    case HOME_RESET:
      return { ...homeDefaultState };

    case SETUP_CONFIG_SUCCESS:
      return setupConfigsSuccess(state, action);
    case SETUP_CONFIG_FAILURE:
      return setupConfigsFailure(state, action);

    case STORE_SELECTION:
      return setStoreSelection(state, action);
    case STORE_SEARCH:
      return setStoreSearch(state, action);

    case LOAD_STORE_OVERRIDES_SUCCESS:
      return setStoreOverrides(state, action);
    case REMOVE_STORE_OVERRIDE_SUCCESS: {
      const { meta } = action as RemoveStoreHourOverrideSuccess;
      const { [meta.storeId]: overrides, ...rest } = state.stores.overrides.data;
      const reducedOverrides = overrides.filter((o) => o.BusinessHoursOverrideId !== meta.bhoId);
      return {
        ...state,
        stores: {
          ...state.stores,
          overrides: {
            data: {
              ...rest,
              [meta.storeId]: reducedOverrides,
            },
          },
        },
      };
    }

    case LOAD_STORES_NET_SALES_SUCCESS: {
      const { meta, payload } = action as LoadStoresNetSalesSuccess;
      return {
        ...state,
        netSales: {
          ...state.netSales,
          data: {
            ...state.netSales.data,
            [meta.storeId]: payload,
          },
        },
      };
    }
    case LOAD_SAVINGS_SUCCESS:
      return setupSavingsSuccess(state, action);
    case LOAD_SAVINGS_FAILURE:
      return setupSavingsFailure(state, action);

    case DISMISS_SETUP_SUCCESS:
      return setupDismissSuccess(state, action);

    default:
      return state;
  }
}

export default homeReducer;

function setupConfigsSuccess(
  state: HomeDefaultState,
  action: LoadSetupConfigsSuccess
): HomeDefaultState {
  return {
    ...state,
    setupConfigs: {
      data: action.payload,
      isEmpty: action.payload.length === 0,
    },
  };
}
function setupConfigsFailure(
  state: HomeDefaultState,
  action: LoadSetupConfigsFailure
): HomeDefaultState {
  return {
    ...state,
    setupConfigs: {
      data: [],
      // will hide component
      isEmpty: true,
    },
  };
}

function setStoreSelection(state: HomeDefaultState, action: SetSelectedStores): HomeDefaultState {
  return {
    ...state,
    stores: {
      ...state.stores,
      order_index: action.payload.stores,
      isEmpty: action.payload.stores.length === 0,
      hasMore: action.payload.hasMore,
      nextPage: action.payload.nextPage,
    },
  };
}
function setStoreSearch(state: HomeDefaultState, action: SetStoresSearch): HomeDefaultState {
  return {
    ...state,
    stores: {
      ...state.stores,
      showSearch: action.payload.show,
    },
  };
}
function setStoreOverrides(
  state: HomeDefaultState,
  action: LoadStoreHourOverridesSuccess
): HomeDefaultState {
  return {
    ...state,
    stores: {
      ...state.stores,
      overrides: {
        data: {
          ...state.stores.overrides.data,
          [action.meta.storeId]: action.payload,
        },
      },
    },
  };
}

function setupSavingsSuccess(
  state: HomeDefaultState,
  action: LoadSavingsSuccess
): HomeDefaultState {
  return {
    ...state,
    savings: {
      data: action.payload,
      isEmpty: action.payload.length === 0,
    },
  };
}
function setupSavingsFailure(
  state: HomeDefaultState,
  action: LoadSavingsFailure
): HomeDefaultState {
  return {
    ...state,
    savings: {
      data: [],
      // will hide component
      isEmpty: true,
    },
  };
}

function setupDismissSuccess(
  state: HomeDefaultState,
  action: DismissSetupSuccess
): HomeDefaultState {
  const filteredDismissCard = state.setupConfigs.data.filter((card) => {
    return typeof action.payload === 'number'
      ? card.HomeActionId != action.payload
      : card.Action != action.payload;
  });
  return {
    ...state,
    setupConfigs: {
      data: filteredDismissCard,
      isEmpty: filteredDismissCard.length === 0,
    },
  };
}
