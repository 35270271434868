import { PayoutsApiFactory, PayoutsExportApiFactory } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { downloadCSVFile } from '../../../helpers/downloadCSV';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';
import { serializePayoutResponse, serializeSummaryResponse } from './helpers';
import { PayoutRequest, PayoutSummaryRequest } from './types';

const payoutApi = createApi(PayoutsApiFactory);
const baseURL = getApiEndPoint();

const payoutExportApi = createApi(PayoutsExportApiFactory);

const payoutDownloadApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

//#region downloadCSV
export async function exportPayoutsAsCSV({ appId, startDate, endDate, bankAccountIds, states }) {
  try {
    const response = await payoutExportApi.exportPayouts(
      appId,
      startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bankAccountIds,
      states
    );
    if (response) {
      //@ts-ignore
      const { Location, FileId } = response.Data;

      const downloadResponse = await payoutDownloadApi.get(`${appId}/${Location}`);
      const { FileUrl } = downloadResponse.data.Data;
      downloadCSVFile(FileUrl, FileId);
    }
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}

export async function exportSectionAsCSV({
  appId,
  bankAccountId,
  payoutId,
  payoutSection,
  storeIdsFilter,
}) {
  try {
    const requestPayoutCSV = () => {
      switch (payoutSection) {
        case 'orders':
          return payoutExportApi.exportPayoutOrders(appId, bankAccountId, payoutId, storeIdsFilter);
        case 'refunds':
          return payoutExportApi.exportPayoutRefunds(
            appId,
            bankAccountId,
            payoutId,
            storeIdsFilter
          );
        case 'chargebacks':
          return payoutExportApi.exportPayoutChargebacks(
            appId,
            bankAccountId,
            payoutId,
            storeIdsFilter
          );
        case 'otherCharges':
          return payoutExportApi.exportPayoutOtherCharges(
            appId,
            bankAccountId,
            payoutId,
            storeIdsFilter
          );
        case 'payoutByStore':
          return payoutExportApi.exportPayoutStores(appId, bankAccountId, payoutId, storeIdsFilter);
        default:
          return;
      }
    };

    const response = await requestPayoutCSV();
    if (response) {
      //@ts-ignore
      const { Location, FileId } = response.Data;

      const downloadResponse = await payoutDownloadApi.get(`${appId}/${Location}`);
      const { FileUrl } = downloadResponse.data.Data;
      downloadCSVFile(FileUrl, FileId);
    }
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
//#endregion

// #region getPayoutDetailsSummary
export async function getPayoutDetailsSummary({ appId, bankAccountId, payoutId }) {
  try {
    const response = await payoutApi.getPayout(appId, bankAccountId, payoutId);
    return response;
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
// #endregion

// #region getPayoutDetailsTableOrders
export async function getPayoutDetailsTableOrders({
  appId,
  bankAccountId,
  payoutId,
  page,
  limit,
  storeIdsFilter,
}) {
  try {
    const response = await payoutApi.getPayoutOrders(
      appId,
      bankAccountId,
      payoutId,
      page,
      limit,
      storeIdsFilter
    );
    return response;
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
// #endregion

// #region getPayoutDetailsTableRefund
export async function getPayoutDetailsTableRefund({
  appId,
  bankAccountId,
  payoutId,
  page,
  limit,
  storeIdsFilter,
}) {
  try {
    const response = await payoutApi.getPayoutRefunds(
      appId,
      bankAccountId,
      payoutId,
      page,
      limit,
      storeIdsFilter
    );
    return response;
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
// #endregion

// #region getPayoutDetailsTableChargebacks
export async function getPayoutDetailsTableChargebacks({
  appId,
  bankAccountId,
  payoutId,
  page,
  limit,
  storeIdsFilter,
}) {
  try {
    const response = await payoutApi.getPayoutChargebacks(
      appId,
      bankAccountId,
      payoutId,
      page,
      limit,
      storeIdsFilter
    );
    return response;
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
// #endregion

// #region getPayoutDetailsTableOtherCharges
export async function getPayoutDetailsTableOtherCharges({
  appId,
  bankAccountId,
  payoutId,
  page,
  limit,
  storeIdsFilter,
}) {
  try {
    const response = await payoutApi.getPayoutOtherCharges(
      appId,
      bankAccountId,
      payoutId,
      page,
      limit,
      storeIdsFilter
    );
    return response;
  } catch (incomingError) {
    const e = await mapServerError(incomingError);
    throw e;
  }
}
// #endregion

// #region getPayoutTableSummary
export async function getPayoutTableSummary({
  appId,
  startDate,
  endDate,
  bankAccountIds,
  states,
}: PayoutSummaryRequest) {
  try {
    const response = await payoutApi.getPayoutSummaries(
      appId,
      startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bankAccountIds,
      states
    );
    const serializedData = serializeSummaryResponse(response.Data);
    return serializedData;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getPayoutTableResults
export async function getPayoutTableResults({
  appId,
  languageCode,
  startDate,
  endDate,
  page,
  limit,
  bankAccountIds,
  states,
}: PayoutRequest) {
  try {
    const response = await payoutApi.getPayouts(
      appId,
      startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      page + 1,
      limit,
      bankAccountIds,
      states
    );
    const serializedData = serializePayoutResponse(response.Data, languageCode);
    return {
      result: serializedData,
      pagination: {
        page: response.Page,
        pageSize: response.Limit,
        total: response.TotalRecordCount,
      },
    };
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion
