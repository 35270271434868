import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import CustomersPage from './CustomersPage';
import CustomerDetails from './Details/CustomerDetails';

type Props = MappedProps & RouteComponentProps;

const CustomerRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    match: { path },
    canEdit,
  } = props;

  return (
    <ErrorBoundary identifier="customers">
      <Switch>
        {canEdit && <Route exact path={`${path}/:customerId`} component={CustomerDetails} />}
        <Route path={`${path}`} component={CustomersPage} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const canEdit = checkVisibility(
    state.permissions,
    [App.AppResourceSetEnum.ViewCustomerStatistics],
    []
  );
  return { canEdit };
};

export default compose<Props, RouteComponentProps>(connect(mapStateToProps))(CustomerRoutes);
