import { constants } from './constants';
import { AuditLog } from './service';

const {
  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,
  GET_LOG_FAILURE,
  GET_LOG_REQUEST,
  GET_LOG_SUCCESS,
} = constants;

export type Customer = {
  EmailAddress: string;
  Id: number;
  Name: string;
  PhoneNumber: string;
  PhoneNumberLocalFormat: string;
};

export type Location = {
  Coordinates: Flipdish.Coordinates;
  Building: string;
  DeliveryInstructions: string;
  PostCode: string;
  PrettyAddressString: string;
  Street: string;
  Town: string;
};

export type Printer = {
  LastPollTime: string;
  SerialNumber: string;
};

export type StoreAddress = {
  AddressId: number;
  City: string | null;
  Coordinates: Flipdish.Coordinates;
  CountryCode: string | null;
  DisplayForCustomer: string | null;
  Line1: string | null;
  Postcode: string | null;
};

export type State = {
  auditLogs: AuditLog[];
  auditLogsLoading: boolean;
  logLoading: boolean;
  log: AuditLog | null;
  Limit: number;
  Page: number;
  error: string | null;
  TotalRecordCount: number;
};

type DefaultState = typeof defaultState;
const defaultState: State = {
  auditLogs: [],
  auditLogsLoading: false,
  logLoading: true,
  log: null,
  Limit: 25,
  Page: 0,
  error: null,
  TotalRecordCount: 0,
};

export function auditLogs(state: State = defaultState, action): DefaultState {
  switch (action.type) {
    case GET_LOGS_REQUEST:
    case GET_LOG_REQUEST:
      return {
        ...state,
        auditLogsLoading: true,
      };

    case GET_LOGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        auditLogsLoading: false,
      };

    case GET_LOG_SUCCESS:
      return {
        ...state,
        log: action.payload,
        error: null,
        auditLogsLoading: false,
      };

    case GET_LOGS_FAILURE:
    case GET_LOG_FAILURE:
      return {
        ...state,
        error: String(action.payload),
        auditLogsLoading: false,
      };

    default:
      return state;
  }
}
