import {
  AnalyticsClientEvent,
  BankAccountCreatedEvent,
  BankAccountDeletedEvent,
  BankAccountUpdatedEvent,
  CustomerConsentUpdatedEvent,
  CustomerCreatedEvent,
  CustomerUpdatedEvent,
  DeliveryZoneCreatedEvent,
  DeliveryZoneDeletedEvent,
  DeliveryZoneUpdatedEvent,
  KioskBluetoothTerminalUnpairedEvent,
  KioskBluetoothTerminalUpdatedEvent,
  Order,
  OrderAcceptedEvent,
  OrderCreatedEvent,
  OrderRefundedEvent,
  OrderRejectedEvent,
  OrderSummary,
  PhoneCallEndedEvent,
  PhoneCallStartedEvent,
  StoreAddressUpdatedEvent,
  StoreBusinessHoursOverrideCreatedEvent,
  StoreBusinessHoursOverrideDeletedEvent,
  StoreCreatedEvent,
  StoreDeletedEvent,
  StoreGroupCreatedEvent,
  StoreGroupDeletedEvent,
  StoreGroupUpdatedEvent,
  StoreOpeningHoursUpdatedEvent,
  StoreUpdatedEvent,
  TeammateDeletedEvent,
  TeammateInviteAcceptedEvent,
  TeammateInviteSentEvent,
  TeammateUpdatedEvent,
  UserUpdatedEvent,
  VoucherCreatedEvent,
  VoucherDeletedEvent,
  VoucherUpdatedEvent,
} from '@flipdish/api-client-typescript';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import { getOrderByIdSuccess } from '../actions/order.action';
import { action } from '../actions/utils';
import { getKioskIds } from '../components/Kiosks/Kiosks.selectors';
import { getPendingOrdersSuccess } from '../components/Order/actions';
import { orderAccepted, orderCreated, orderRefunded, orderRejected } from './hub.actions';
import {
  accountEventConstants,
  analyticsEventConstants,
  bankEventConstants,
  customerEventConstants,
  kioskCardReaderConstants,
  phoneCallEventConstants,
  storeEventConstants,
  storeGroupEventConstants,
  teammateEventConstants,
  voucherEventConstants,
} from './hub.events';

//#region Orders
type StoreFilterMatcher = {
  currentAppId: string;
  orderAppId: string;
  storesFilter: Array<undefined | number>;
  order: Order;
};

const matchesStoresFilter = ({
  currentAppId,
  orderAppId,
  storesFilter,
  order,
}: StoreFilterMatcher) => {
  if (currentAppId === orderAppId || currentAppId === 'flipdish-global') {
    if (storesFilter?.length && order?.Store?.Id) {
      if (storesFilter.indexOf(order.Store.Id) === -1) {
        return false;
      }
    }

    return true;
  }

  return false;
};

const filterOrders = (
  oldOrders: OrderSummary[],
  newOrder: OrderSummary,
  stateFilters: OrderSummary.OrderStateEnum[],
  created?: boolean
) => {
  const orderIndex = oldOrders.findIndex(
    (order: OrderSummary) =>
      order.OrderId === newOrder.OrderId && newOrder.OrderState !== order.OrderState
  );

  let newOrders: OrderSummary[];
  const isOrderIncludedInFilter =
    !stateFilters.length ||
    (newOrder.OrderState && stateFilters.indexOf(newOrder.OrderState) !== -1);
  if (orderIndex >= 0) {
    newOrders = isOrderIncludedInFilter
      ? [...oldOrders.slice(0, orderIndex), newOrder, ...oldOrders.slice(orderIndex + 1)]
      : [...oldOrders.slice(0, orderIndex), ...oldOrders.slice(orderIndex + 1)];
  } else if (created && isOrderIncludedInFilter) {
    newOrders = [newOrder, ...oldOrders];
  } else {
    newOrders = oldOrders;
  }
  return uniqBy(newOrders, (order: OrderSummary) => order.OrderId);
};

export const utcDate = (date): Date => {
  return date ? new Date((date as string).endsWith('Z') ? date : `${date}Z`) : date;
};

const createNewOrder = (order: Order) => {
  const orderSummary: OrderSummary = {};

  orderSummary.Amount = order.Amount;
  orderSummary.CustomerName = (order.Customer && order.Customer.Name) || undefined;
  orderSummary.CustomerPhoneNumber = (order.Customer && order.Customer.PhoneNumber) || undefined;
  orderSummary.DeliveryType = order.DeliveryType;
  orderSummary.OrderId = order.OrderId;
  orderSummary.OrderState = order.OrderState;
  //@ts-ignore generated types set all Date types to strings
  orderSummary.RequestedForTime = utcDate(order.RequestedForTime);
  orderSummary.PaymentAccountType = order.PaymentAccountType || undefined;
  orderSummary.Channel = order.Channel;
  orderSummary.ChannelOrderDisplayId = order.ChannelOrderDisplayId;
  orderSummary.ChannelOrderId = order.ChannelOrderId;

  if (order.Store) {
    // REFACTOR TODO getIanaTimeZoneId(order.Store.StoreTimezone!);
    orderSummary.StoreIanaTimeZone = order.Store.StoreTimezone!;
    orderSummary.Currency = order.Store.Currency;
    orderSummary.StoreName = order.Store.Name;
  }
  return orderSummary;
};

const handleOrderEvent = (store, data, actionFunc, created?) => {
  const state = store.getState();

  const currentAppId = state.currentApp.AppId || '';
  const orderAppId = data.AppId;
  const storesFilter = state.OrdersFilter.stores;
  const order = data.Order;

  if (order && matchesStoresFilter({ currentAppId, orderAppId, storesFilter, order })) {
    const newOrder = createNewOrder(data.Order);
    const newOrders = filterOrders(
      state.orders.orders,
      newOrder,
      state.OrdersFilter.states,
      created
    );
    store.dispatch(actionFunc(newOrders));
    // Accepted Orders
    if (!created && data.Order.OrderId == state.orders.order.OrderId) {
      const updatedOrder: Order = {
        ...data.Order,
        AcceptedFor: utcDate(data.Order.AcceptedFor),
        RequestedForTime: utcDate(data.Order.RequestedForTime),
      };
      store.dispatch(getOrderByIdSuccess(updatedOrder, data.Order.OrderId));
    }
  }
};

const handlePendingOrderEvent = (store, data, isNewOrder: boolean) => {
  const state: AppState = store.getState();

  const currentAppId = state.currentApp.AppId || '';
  const orderAppId = data.AppId;
  const storesFilter = state.OrdersFilter.pendingStores.map(
    (store: { value: number; label: string }) => store.value
  );
  const order = data.Order;

  if (matchesStoresFilter({ currentAppId, orderAppId, storesFilter, order })) {
    let newOrders: OrderSummary[] = [];
    const state: AppState = store.getState();
    const pendingOrders = state.pendingOrders;

    if (isNewOrder) {
      const newOrder = createNewOrder(data.Order);
      newOrders = [newOrder, ...pendingOrders.orders];
    } else {
      newOrders = filter(pendingOrders.orders, (order) => order.OrderId !== data.Order.OrderId);
    }

    store.dispatch(
      getPendingOrdersSuccess({
        orders: uniqBy(newOrders, (order: OrderSummary) => order.OrderId),
        ordersCount: isNewOrder ? pendingOrders.ordersCount + 1 : pendingOrders.ordersCount - 1,
        concat: false,
      })
    );
  }
};
export const orderEventHandlers = {
  created: (store: any) => (data: OrderCreatedEvent) => {
    handleOrderEvent(store, data, orderCreated, true);
    handlePendingOrderEvent(store, data, true);
  },
  accepted: (store: any) => (data: OrderAcceptedEvent) => {
    handleOrderEvent(store, data, orderAccepted);
    handlePendingOrderEvent(store, data, false);
  },
  rejected: (store: any) => (data: OrderRejectedEvent) => {
    handleOrderEvent(store, data, orderRejected, false);
    handlePendingOrderEvent(store, data, false);
  },
  refunded: (store: any) => (data: OrderRefundedEvent) => {
    handleOrderEvent(store, data, orderRefunded);
  },
};
//#endregion

//#region Account
export const accountEventHandlers = {
  updated: (store: any) => (data: UserUpdatedEvent) => {
    store.dispatch({
      type: accountEventConstants.UPDATED,
      data,
    });
  },
};
//#endregion

//#region Customer
export const customerEventHandlers = {
  created: (store: any) => (data: CustomerCreatedEvent) => {
    store.dispatch({
      key: data.FlipdishEventId,
      type: customerEventConstants.CREATED,
      description: data.Description,
      ts: data.CreateTime,
      data: data.User,
    });
  },
  updated: (store: any) => (data: CustomerUpdatedEvent) => {
    store.dispatch({
      key: data.FlipdishEventId,
      type: customerEventConstants.UPDATED,
      description: data.Description,
      ts: data.CreateTime,
      data: data.User,
    });
  },
  consent_updated: (store: any) => (data: CustomerConsentUpdatedEvent) => {
    store.dispatch({
      key: data.FlipdishEventId,
      type: customerEventConstants.CONSENT_UPDATED,
      description: data.Description,
      ts: data.CreateTime,
      data: data.User,
    });
  },
};
//#endregion

//#region Analytics
export const analyticsEventHandlers = {
  client: (store: any) => (data: AnalyticsClientEvent) => {
    store.dispatch({
      type: analyticsEventConstants.CLIENT,
      data,
    });
  },
};
//#endregion

//#region Store
// #region store actions
const { CREATED: STORE_CREATED } = storeEventConstants;
export type StoreCreated = ReturnType<typeof storeCreated>;
export const storeCreated = (data: StoreCreatedEvent) => action(STORE_CREATED, data);

const { DELETED: STORE_DELETED } = storeEventConstants;
export type StoreDeleted = ReturnType<typeof storeDeleted>;
export const storeDeleted = (data: StoreDeletedEvent) => action(STORE_DELETED, data);

const { UPDATED: STORE_UPDATED } = storeEventConstants;
export type StoreUpdated = ReturnType<typeof storeUpdated>;
export const storeUpdated = (data: StoreUpdatedEvent) => action(STORE_UPDATED, data);

const { ADDRESS_UPDATED: STORE_ADDRESS_UPDATED } = storeEventConstants;
export type StoreAddressUpdated = ReturnType<typeof storeAddressUpdated>;
export const storeAddressUpdated = (data: StoreAddressUpdatedEvent) =>
  action(STORE_ADDRESS_UPDATED, data);

const { DELIVERY_ZONE_CREATED: STORE_DELIVERY_ZONE_CREATED } = storeEventConstants;
export type StoreDeliveryZoneCreated = ReturnType<typeof storeDeliveryZoneCreated>;
export const storeDeliveryZoneCreated = (data: DeliveryZoneCreatedEvent) =>
  action(STORE_DELIVERY_ZONE_CREATED, data);

const { DELIVERY_ZONE_UPDATED: STORE_DELIVERY_ZONE_UPDATED } = storeEventConstants;
export type StoreDeliveryZoneUpdated = ReturnType<typeof storeDeliveryZoneUpdated>;
export const storeDeliveryZoneUpdated = (data: DeliveryZoneUpdatedEvent) =>
  action(STORE_DELIVERY_ZONE_UPDATED, data);

const { DELIVERY_ZONE_DELETED: STORE_DELIVERY_ZONE_DELETED } = storeEventConstants;
export type StoreDeliveryZoneDeleted = ReturnType<typeof storeDeliveryZoneDeleted>;
export const storeDeliveryZoneDeleted = (data: DeliveryZoneDeletedEvent) =>
  action(STORE_DELIVERY_ZONE_DELETED, data);

const { OPENING_HOURS_UPDATED: STORE_OPENING_HOURS_UPDATED } = storeEventConstants;
export type StoreOpeningHoursUpdated = ReturnType<typeof storeOpeningHoursUpdated>;
export const storeOpeningHoursUpdated = (data: StoreOpeningHoursUpdatedEvent) =>
  action(STORE_OPENING_HOURS_UPDATED, data);

const { BUSINESS_HOURS_OVERRIDES_CREATED: STORE_BUSINESS_HOURS_OVERRIDES_CREATED } =
  storeEventConstants;
export type StoreOpeningHoursOverrideCreated = ReturnType<typeof storeOpeningHoursOverrideCreated>;
export const storeOpeningHoursOverrideCreated = (data: StoreBusinessHoursOverrideCreatedEvent) =>
  action(STORE_BUSINESS_HOURS_OVERRIDES_CREATED, data);

const { BUSINESS_HOURS_OVERRIDES_DELETED: STORE_BUSINESS_HOURS_OVERRIDES_DELETED } =
  storeEventConstants;
export type StoreOpeningHoursOverrideDeleted = ReturnType<typeof storeOpeningHoursOverrideDeleted>;
export const storeOpeningHoursOverrideDeleted = (data: StoreBusinessHoursOverrideDeletedEvent) =>
  action(STORE_BUSINESS_HOURS_OVERRIDES_DELETED, data);

// #endregion

export const storeEventHandlers = {
  created: (store: any) => (data: StoreCreatedEvent) => {
    store.dispatch(storeCreated(data));
  },
  deleted: (store: any) => (data: StoreDeletedEvent) => {
    store.dispatch(storeDeleted(data));
  },
  updated: (store: any) => (data: StoreUpdatedEvent) => {
    store.dispatch(storeUpdated(data));
  },
  addressUpdated: (store) => (data: StoreAddressUpdatedEvent) => {
    store.dispatch(storeAddressUpdated(data));
  },
  deliveryZoneCreated: (store: any) => (data: DeliveryZoneCreatedEvent) => {
    const state: AppState = store.getState();
    const hasStore = state.orm.FlipdishStore.items.includes(data.StoreId as any);
    if (!hasStore) {
      store.dispatch(storeDeliveryZoneCreated(data));
    }
  },
  deliveryZoneUpdated: (store: any) => (data: DeliveryZoneUpdatedEvent) => {
    const state: AppState = store.getState();
    const hasStore = state.orm.FlipdishStore.items.includes(data.StoreId as any);
    const isLocalEdit = state.deliveryZones.isLocalEdit;
    if (hasStore && !isLocalEdit) {
      store.dispatch(storeDeliveryZoneUpdated(data));
    }
  },
  deliveryZoneDeleted: (store: any) => (data: DeliveryZoneDeletedEvent) => {
    const state: AppState = store.getState();
    const hasStore = state.orm.FlipdishStore.items.includes(data.StoreId as any);
    if (hasStore) {
      store.dispatch(storeDeliveryZoneDeleted(data));
    }
  },
  openingHoursUpdated: (store: any) => (data: StoreOpeningHoursUpdatedEvent) => {
    store.dispatch(storeOpeningHoursUpdated(data));
  },
  openingHoursOverrideCreated: (store: any) => (data: StoreBusinessHoursOverrideCreatedEvent) => {
    store.dispatch(storeOpeningHoursOverrideCreated(data));
  },
  openingHoursOverrideDeleted: (store: any) => (data: StoreBusinessHoursOverrideDeletedEvent) => {
    store.dispatch(storeOpeningHoursOverrideDeleted(data));
  },
};

export const storeGroupEventHandlers = {
  created: (store: any) => (data: StoreGroupCreatedEvent) => {
    store.dispatch({
      type: storeGroupEventConstants.CREATED,
      data,
    });
  },
  deleted: (store: any) => (data: StoreGroupDeletedEvent) => {
    store.dispatch({
      type: storeGroupEventConstants.DELETED,
      data,
    });
  },
  updated: (store: any) => (data: StoreGroupUpdatedEvent) => {
    store.dispatch({
      type: storeGroupEventConstants.UPDATED,
      data,
    });
  },
};
//#endregion

//#region Teammates
export const teammateEventHandlers = {
  inviteSent: (store: any) => (data: TeammateInviteSentEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';
    store.dispatch({
      type: teammateEventConstants.INVITE_SENT,
      payload: data,
      appId: appId,
    });
  },
  inviteAccepted: (store: any) => (data: TeammateInviteAcceptedEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';
    store.dispatch({
      type: teammateEventConstants.INVITE_ACCEPTED,
      payload: data,
      appId: appId,
    });
  },
  deleted: (store: any) => (data: TeammateDeletedEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';
    store.dispatch({
      type: teammateEventConstants.DELETED,
      payload: data,
      appId: appId,
    });
  },
  updated: (store: any) => (data: TeammateUpdatedEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';
    store.dispatch({
      type: teammateEventConstants.UPDATED,
      payload: data,
      appId: appId,
    });
  },
};
//#endregion

//#region Phone
export const phoneCallEventHandlers = {
  started: (store: any) => (data: PhoneCallStartedEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';

    store.dispatch({
      type: phoneCallEventConstants.STARTED,
      payload: data,
      appId: appId,
    });
  },
  ended: (store: any) => (data: PhoneCallEndedEvent) => {
    const appId = store.getState().currentApp.AppId || 'global';

    store.dispatch({
      type: phoneCallEventConstants.ENDED,
      payload: data,
      appId: appId,
    });
  },
};
//#endregion

//#region Vouchers
export const voucherEventHandlers = {
  created: (store: any) => (data: VoucherCreatedEvent) => {
    store.dispatch({
      type: voucherEventConstants.CREATED,
      payload: data,
    });
  },
  deleted: (store: any) => (data: VoucherDeletedEvent) => {
    store.dispatch({
      type: voucherEventConstants.DELETED,
      payload: data,
    });
  },
  updated: (store: any) => (data: VoucherUpdatedEvent) => {
    store.dispatch({
      type: voucherEventConstants.UPDATED,
      payload: data,
    });
  },
};
//#endregion

// #region bank
export const bankEventHandlers = {
  created: (account: any) => (data: BankAccountCreatedEvent) => {
    account.dispatch({
      key: data.FlipdishEventId,
      type: bankEventConstants.CREATED,
      ts: data.CreateTime,
      data: data.BankAccount,
    });
  },
  updated: (account: any) => (data: BankAccountUpdatedEvent) => {
    account.dispatch({
      key: data.FlipdishEventId,
      type: bankEventConstants.UPDATED,
      ts: data.CreateTime,
      data: data.BankAccount,
    });
  },
  deleted: (account: any) => (data: BankAccountDeletedEvent) => {
    account.dispatch({
      key: data.FlipdishEventId,
      type: bankEventConstants.DELETED,
      ts: data.CreateTime,
      data: data.BankAccount,
    });
  },
};
// #endregion

//#region Kiosk Card Reader
const handleCardReaderEvent = ({
  data,
  eventType,
  store,
}: {
  data: KioskBluetoothTerminalUpdatedEvent | KioskBluetoothTerminalUpdatedEvent;
  eventType: 'UNPAIRED' | 'UPDATED';
  store;
}) => {
  const kiosksIds = getKioskIds(store.getState());
  if (kiosksIds.includes(data.DeviceId)) {
    store.dispatch({ type: kioskCardReaderConstants[eventType], payload: data });
  }
};

export const kioskCardReaderEventHandlers = {
  updated: (store: any) => (data: KioskBluetoothTerminalUpdatedEvent) => {
    handleCardReaderEvent({ data, eventType: 'UPDATED', store });
  },
  unpaired: (store: any) => (data: KioskBluetoothTerminalUnpairedEvent) => {
    handleCardReaderEvent({ data, eventType: 'UNPAIRED', store });
  },
};
//#endregion
