import React from 'react';

import { ComponentProps, getAutoFdKey } from '../Common';
import { FkField, TFkFieldProps } from '../FkField/FkField';
import { TextFieldEx, TextFieldExProps } from '../FkText/TextFieldEx';

// Fk - Formik. Warning!!! Don't extend from TextField props - all UI parts must be inside component
// Don't add value field!!! work only over formik.
type Props = TextFieldExProps & ComponentProps;

const MATCH_ONLY_NUMBERS_REG_EXP = /\d/g;
export const FkNumeric = (props: Props) => {
  const { fieldType, fieldName, removeTopPadding, ...rest } = props;

  const getFormatedValue = (value: string | undefined, mask: RegExp) => {
    const newValue = value && value.toString ? value.toString().match(mask) : '';
    return newValue ? newValue.join('') : '';
  };

  return (
    <FkField componentProps={props} removeTopPadding={removeTopPadding} showHelperText={true}>
      {({ field, fieldEx, form }: TFkFieldProps) => {
        const { isSubmitting } = form;
        const { fieldError, showError, isTouched } = fieldEx;

        return (
          <TextFieldEx
            {...field}
            {...rest}
            value={getFormatedValue(field.value, MATCH_ONLY_NUMBERS_REG_EXP)}
            fdKey={props.fdKey || getAutoFdKey(fieldType, fieldName)}
            disabled={isSubmitting || props.disabled}
            showError={showError}
            isTouched={isTouched}
            fieldError={fieldError}
            onChange={(e) => {
              const value = getFormatedValue(e.target.value, MATCH_ONLY_NUMBERS_REG_EXP);
              form.setFieldValue(props.name, value);
              const newEv = {
                ...e,
                target: {
                  ...e.target,
                  value,
                },
              };
              props.onChange && props.onChange(newEv);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              props.onBlur && props.onBlur(e);
            }}
          />
        );
      }}
    </FkField>
  );
};
