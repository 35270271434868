import React from 'react';

import { BankAccountSummary } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import VerifyStripeButton from './VerifyStripeButton';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '480px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },
  dialogHeader: {
    paddingTop: '28px',
    paddingBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '4px',
    },
  },
  dialogActions: {
    padding: '24px 24px 28px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12px',
    },
  },
}));

type Props = IVerifyModalProps;
export interface IVerifyModalProps {
  onClose: () => void;
  setDialogOpen: (state: boolean) => void;
  isDialogOpen: boolean;
  account: BankAccountSummary;
}

const VerifyYourDetailsModal = ({ isDialogOpen, setDialogOpen, onClose, account }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setDialogOpen(false);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogHeader}>
        <Translate id="verification_required_to_receive_payouts" />
      </DialogTitle>
      <DialogContent key="dialog-content">
        <Typography variant="subtitle1" color="textSecondary">
          <Translate id="We_use_stripe_a_global" />
        </Typography>
      </DialogContent>
      <DialogActions key="actions" className={classes.dialogActions}>
        <Hidden smDown>
          <Button
            color="primary"
            data-fd="I_will_do_this_later"
            onClick={() => {
              onClose();
              setDialogOpen(false);
            }}
          >
            <Translate id="I_will_do_this_later" />
          </Button>
          <Box>
            {account && account.StripeConnectedAccountInfo && (
              <VerifyStripeButton
                stripeAccount={account.StripeConnectedAccountInfo!}
                bankAccountId={account.Id}
              />
            )}
          </Box>
        </Hidden>
        <Hidden smUp>
          <Grid container direction="column">
            <Grid item>
              <Box m={'auto'} textAlign={'center'}>
                {account && account.StripeConnectedAccountInfo && (
                  <VerifyStripeButton
                    stripeAccount={account.StripeConnectedAccountInfo!}
                    bankAccountId={account.Id}
                  />
                )}
              </Box>
            </Grid>
            <Box mt={2}>
              <Grid item>
                <Button
                  fullWidth
                  color="primary"
                  data-fd="I_will_do_this_later"
                  onClick={() => {
                    onClose();
                    setDialogOpen(false);
                  }}
                >
                  <Translate id="I_will_do_this_later" />
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Hidden>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyYourDetailsModal;
