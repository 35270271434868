import { useEffect } from 'react';

import { Store } from '@flipdish/api-client-typescript';
import { useParams } from 'react-router-dom';

import { setSelected, storeActions } from '../actions/store.actions';
import { useAppDispatch } from './useAppDispatch';
import useQueryStoreIdAssociations from './useQueryStoreIdAssociations';

const useLoadStoreFromSalesChannelIdIntoReduxIntoRedux = (store?: Store) => {
  const dispatch = useAppDispatch();
  // get the sales channel id or storeId (storeId for backwards compatability)
  const { channelId, storeId: oldStoreId } = useParams<{ channelId: string; storeId: string }>();

  // if sales channel id fetch the store based on sales channel id association
  const { storeId: salesChannelStoreId, loadingStoreAssociation } = useQueryStoreIdAssociations({
    salesChannelId: channelId,
    enabled: !!channelId,
  });

  const storeId = (oldStoreId || salesChannelStoreId) ?? '';

  useEffect(() => {
    if (!loadingStoreAssociation && (!store?.StoreId || store?.StoreId !== storeId) && storeId) {
      // set the selected store and load into redux
      dispatch(setSelected({ storeId: Number(storeId) }));
      dispatch(storeActions.load(Number(storeId)));
    }
  }, [storeId, loadingStoreAssociation, store?.StoreId]);

  return {
    loadingStoreAssociation,
    storeId,
  };
};

export default useLoadStoreFromSalesChannelIdIntoReduxIntoRedux;
