import React from 'react';

import { useFormikContext } from 'formik';
import { TranslateFunction } from 'react-localize-redux';

import { SplitButton } from '@fd/ui/molecules';

const options: TranslationId[] = ['Save_only', 'Save_and_update_menus'];

type Props = {
  fdKey: string;
  disabled?: boolean;
  onSaveTypeChange: (index: number) => void;
  translate: TranslateFunction;
};

export function SaveCatalogChangesButton(props: Props) {
  const { fdKey, disabled, onSaveTypeChange, translate } = props;
  const { submitForm } = useFormikContext();
  return (
    <SplitButton
      fdKey={fdKey}
      disabled={disabled}
      dropdownButtonAriaLabel={translate('Select_save_type') as string}
      options={options}
      type="submit"
      onSelectChange={(index) => {
        onSaveTypeChange(index);
        submitForm();
      }}
    />
  );
}
