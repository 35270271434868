import React, { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Select from '@fd/ui/Select/Select';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../WithRouteSearchParams';
import { billingService } from '../billing.service';

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 6,
  },
}));

type Option = {
  value: string;
  label: string;
};

type InnerProps = MappedState & MappedDispatch & WithRouteSearchParamsProps<string>;
type OuterProps = {
  onChange: (selectedItems: string) => void;
};
type Props = InnerProps & OuterProps;

const SearchBySubscriptionFilter = (props: Props) => {
  const classes = useStyles();
  const { appId, translate, onChange, search, setSearch, notifyError } = props;

  const [valueItem, setValueItem] = useState<Option>();
  const [subscriptionsOption, setSubscriptionsOption] = useState<Option[]>();

  const {
    data: subscriptions,
    isPending,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: [billingService.getSubscriptionsForAppQueryKey, appId],
    queryFn: () => billingService.getSubscriptionsForApp(appId!),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isSuccess && subscriptions) {
      const getSubscriptionOptions = subscriptions.map(
        (s) =>
          ({
            label: s.SubscriptionId,
            value: s.SubscriptionId,
          }) as Option
      );
      setSubscriptionsOption(getSubscriptionOptions);
    }
  }, [isSuccess, subscriptions]);

  useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [isError]);

  useEffect(() => {
    if (subscriptions?.length && search) {
      setValueItem({
        label: search,
        value: search,
      });
      onChange(search);
    }
  }, [subscriptions, search]);

  useEffect(() => {
    if (subscriptions && valueItem) {
      const values = subscriptions
        .filter((s) => s.SubscriptionId !== valueItem.value)
        .map((sub) => ({
          label: sub.SubscriptionId,
          value: sub.SubscriptionId,
        }));
      setSubscriptionsOption(values);
    }
  }, [valueItem, subscriptions]);

  const setUrlParams = (value: string) => {
    setSearch(value);
  };

  const handleChange = (options: Option | null) => {
    setValueItem(options || undefined);
    setUrlParams(options?.value || '');
    onChange(options?.value || '');

    if (!options && subscriptions) {
      const values = subscriptions.map((sub) => ({
        label: sub.SubscriptionId,
        value: sub.SubscriptionId,
      }));
      setSubscriptionsOption(values);
    }
  };

  return (
    <Select
      className={classes.select}
      dataFd="Subscriptions-filter"
      isClearable
      isLoading={isPending || isFetching}
      onChange={handleChange}
      options={subscriptionsOption}
      placeholder={translate('All') as TranslationId}
      TextFieldProps={{
        fdKey: 'Subscriptions_filter',
        label: translate('Subscriptions'),
      }}
      value={valueItem}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId as string,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'subscriptionFilter',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchBySubscriptionFilter);
