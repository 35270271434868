// #region actionCreators
import { createAction, SimpleActionCreator } from 'redux-act';
import {
  notify,
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
} from '../layouts/Notify/actions';

export function action(type: string): Action;
export function action<P = undefined>(type: string, payload: P): ActionPayload<P>;
export function action<P = undefined, M = undefined>(
  type: string,
  payload: P,
  meta: M
): ActionPayloadMeta<P, M>;
/**
 * @description flux standard action factory
 * @example
 * ```
 * const add = (amount: number, meta?: MetaShape) => action('INCREMENT', amount, meta);
 * ```
 */
export function action<P = undefined, M = undefined>(type: string, payload?: P, meta?: M) {
  return { type, payload, meta };
}

export function actionError<P = undefined, E = undefined>(
  type: string,
  meta: P,
  payload: E
): ActionError<P, E>;
export function actionError<P = undefined, E = undefined>(type: string, meta?: P, payload?: E) {
  return { type, payload, error: true, meta };
}

// #endregion

export type TAsyncAction<TSuccess> = {
  request: SimpleActionCreator<void>;
  success: SimpleActionCreator<TSuccess>;
  failure: SimpleActionCreator<TFailure>;
};

export type TFailure = {
  error: string; // in future should be changed to TranslationId
};
export const createAsyncAction = <TSuccess = unknown>(
  actionName: string
): TAsyncAction<TSuccess> => {
  return {
    request: createAction<void>(`${actionName}_REQUEST`),
    success: createAction<TSuccess>(`${actionName}_SUCCESS`),
    failure: createAction<TFailure>(`${actionName}_FAILURE`),
  };
};
type TNotifyType = 'saving' | 'custom' | 'error';
type TNotifyOptions = {
  type: TNotifyType;
  message?: string;
  duration?: number;
};
type TDispatchOptions = {
  notifyOptions?: TNotifyOptions[];
};
export async function dispatchAsync<TSuccess>(
  dispatch: ThunkDispatch,
  action: TAsyncAction<TSuccess>,
  callback: () => Promise<TSuccess> | TFailure,
  options?: TDispatchOptions
) {
  const saving = options?.notifyOptions?.find((n) => n.type === 'saving');
  const custom = options?.notifyOptions?.find((n) => n.type === 'custom');
  // const error = options?.notifyOptions?.find((n) => n.type === 'error');
  try {
    if (saving || custom) {
      dispatch(notifySaving({ persist: true }));
    }
    dispatch(action.request());

    const result = await callback();
    dispatch(action.success(result as TSuccess));

    if (custom) {
      dispatch(closeNotifySaving());
      dispatch(
        notify({
          autoHideDuration: custom.duration ? custom.duration : 2000,
          variant: 'success',
          message: custom.message!,
          translate: true,
        })
      );
    }
    if (saving) {
      dispatch(closeNotifySaving());
      dispatch(notifySaved());
    }
  } catch (error) {
    dispatch(
      action.failure({
        error,
      })
    );

    if (saving || custom) {
      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message }));
    } else {
      dispatch(notifyError({ message: 'Something_went_wrong' }));
    }
  }
}

export const updateItemInArray = <T>(items: Array<T>, key: keyof T, newItem: T) => {
  const idx = items.findIndex((el) => el[key] === newItem[key]);
  assert(idx < 0, 'item not found');

  const newitems = [...items];
  newitems[idx] = {
    ...newitems[idx],
    ...newItem,
  };
  return newitems;
};
