import React, { useEffect, useState } from 'react';

import {
  StripeAccountLinkRequest,
  StripeConnectedAccountInfo,
} from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';

import { usePrevious } from '../../../../custom-hooks/usePrevious';
import { createStripeConnectedAccountLink } from '../banking.actions';

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    editBtn: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        backgroundColor: 'rgba(5, 20, 158, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.23)',
      },
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
);

export interface StripeAccount {
  bankAccountId?: number;
  stripeAccount: StripeConnectedAccountInfo;
}

type Props = StripeAccount & MappedState & MappedDispatch & RouteComponentProps;

const VerifyStripeButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const classes = useStyles();
  const { stripeAccount, setAccountLink, stripeUrl, bankAccountId, location, history } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const previousUrl = usePrevious(stripeUrl);

  useEffect(() => {
    if (previousUrl !== stripeUrl && stripeUrl !== '') {
      redirectToStripe(stripeUrl);
    }
  }, [stripeUrl]);

  const redirectToStripe = (stripeLink) => {
    window.location.href = stripeLink.Url;
  };

  const getReturnUrl = () => {
    const currentWindow = window.location.href;

    return bankAccountId
      ? currentWindow.replace('/bank-accounts/new', `/bank-accounts/${bankAccountId}`)
      : currentWindow;
  };

  const handleSubmit = () => {
    bankAccountId
      ? history.push(
          location.pathname.replace('/bank-accounts/new', `/bank-accounts/${bankAccountId}`)
        )
      : history.push(location.pathname);
    setAccountLink(stripeAccountLinkRequest);
  };

  const stripeAccountLinkRequest = {
    Collect: StripeAccountLinkRequest.CollectEnum.CurrentlyDue,
    ReturnUrl: getReturnUrl(),
    StripeId: stripeAccount.StripeId,
    Type: StripeAccountLinkRequest.TypeEnum.Onboarding,
  } as StripeAccountLinkRequest;

  return (
    <>
      <Button
        className={classes.editBtn}
        data-fd="Edit_stripe_account_details"
        onClick={() => setIsDialogOpen(true)}
        style={{ color: '#05149e' }}
        variant="outlined"
      >
        <Translate id="Edit_Account_Details" />
      </Button>

      <YesNoDialog
        noTextId={'Cancel'}
        onNo={() => setIsDialogOpen(false)}
        onYes={() => {
          setIsDialogOpen(true);
          handleSubmit();
        }}
        open={isDialogOpen}
        titleTextId={'Edit_Account_Details'}
        yesButtonVariant={'contained'}
        yesTextId={'Continue_to_Stripe'}
      >
        <Typography variant="body1">
          <Translate id="Updating_your_Stripe_details" />
        </Typography>
      </YesNoDialog>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    stripeUrl: state.banking.stripeLinkUrl,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setAccountLink: (stripeAccountLinkRequest) => {
      dispatch(createStripeConnectedAccountLink(stripeAccountLinkRequest));
    },
  };
}

export default compose<Props, StripeAccount>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyStripeButton);
