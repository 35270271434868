import { ChannelsApiFactory } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { createApi } from '../../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from '../../services/utils/serverErrorMapper';
import { GetSchemaResponse } from './types';
import { getApiEndPoint } from '../../helpers/apibase';

const baseURL = getApiEndPoint();

const photonEditorApi = axios.create({
  baseURL: 'https://editor-api.portal.flipdish.com/api',
  withCredentials: true,
});

const salesChannelStoresAPi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const api = createApi(ChannelsApiFactory);

const getStores = async (appId: string, channelId: number) => {
  try {
    const incomingMessage = await api.getStoresBySalesChannel(appId, channelId);
    return incomingMessage.Stores;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const assignStoresToAppType = async (appId: string, channelId: number, storeId: number) => {
  try {
    await salesChannelStoresAPi.post(`${appId}/channels/${channelId}/stores/${storeId}`);
    // Used to update the store visibility in the photon ecosystem
    await photonEditorApi.get(`/updateMetaData?appId=${appId}&type=store-visibility`);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

const deleteStoreFromAppType = async (appId: string, channelId: number, storeId: number) => {
  try {
    await salesChannelStoresAPi.delete(`${appId}/channels/${channelId}/stores/${storeId}`);
    // Used to update the store visibility in the photon ecosystem
    await photonEditorApi.get(`/updateMetaData?appId=${appId}&type=store-visibility`);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

const detachAllStoresFromSalesChannel = async (appId: string, channelId: number) => {
  try {
    await salesChannelStoresAPi.delete(`${appId}/channels/${channelId}/stores`);
    // Used to update the store visibility in the photon ecosystem
    await photonEditorApi.get(`/updateMetaData?appId=${appId}&type=store-visibility`);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e.response.data.error)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

const getSchema = async (appId: string): Promise<GetSchemaResponse | void> => {
  try {
    const result = await photonEditorApi.get(`/getSchema?appId=${appId}`);
    return result.data;
  } catch (e) {
    if (KNOWN_ERRORS.includes(e.response.data.error)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

export const salesChannelsServices = {
  assignStoresToAppType,
  getSchema,
  getStores,
  deleteStoreFromAppType,
  detachAllStoresFromSalesChannel,
};
