import React, { useCallback, useEffect, useState } from 'react';

import { Group, Product } from '@flipdish/api-client-typescript';
import Close from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TranslateFunction } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';
import LinkIconButton from '@fd/ui/Button/LinkIconButton';
import { DraggableCard } from '@fd/ui/molecules/DraggableCard';
import { DraggableExpansionPanel } from '@fd/ui/molecules/DraggableExpansionPanel';

import { generateSrcSet } from '../../utils';
import { DraggableItemList } from '../DraggableItemList';

const useStyles = makeStyles((theme: Theme) => ({
  controlsContainer: {
    display: 'flex',
  },
  image: {
    borderRadius: '5px',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    backgroundColor: '#e0e0e0',
    border: 'none',
    borderRadius: '5px',
    height: '40px',
    marginRight: theme.spacing(1),
    padding: 0,
    width: '40px',
  },
  imageAndTextContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  lastNestedCard: {
    borderBottomLeftRadius: theme.spacing(0),
    borderBottom: 'none',
  },
  listContainer: {
    width: '100%',
  },
  name: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  nestedCard: {
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    borderRight: 'none',
  },
  textContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  summaryContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export type DraggableGroupListProps = {
  appId: string;
  isEditable?: boolean;
  modifierGroups: Group[];
  onRemove: (index: number) => void;
  onUpdateOrder: (newOrder: Group[]) => void;
  parentId?: string;
  translate: TranslateFunction;
};
export const DraggableGroupList = (props: DraggableGroupListProps): JSX.Element => {
  const {
    appId,
    isEditable = true,
    modifierGroups,
    onRemove,
    onUpdateOrder,
    parentId = '',
    translate,
  } = props;
  const [list, setList] = useState<Group[]>(modifierGroups);
  const [shouldHighlight, setShouldHighlight] = useState<boolean>(true);
  const classes = useStyles();
  useEffect(() => {
    setList(modifierGroups);
  }, [modifierGroups]);

  const handleRemoveGroup = (e, id: string, index: number) => {
    e.stopPropagation();
    onRemove(index);
  };

  const moveCard = useCallback(
    debounce((dragIndex: number, hoverIndex: number) => {
      const dragCard = list[dragIndex];
      const hoverCard = list[hoverIndex];
      const newCards = [...list];

      newCards[dragIndex] = hoverCard;
      newCards[hoverIndex] = dragCard;
      setList(newCards);
      onUpdateOrder(newCards);
    }, 30),
    [list]
  );

  const renderGroupContents = (modifierGroup: Group) => {
    const groupItems = [] as Product[];
    modifierGroup.Products?.forEach((item) => {
      if (item.Product) {
        groupItems.push(item.Product);
      }
    });
    if (groupItems) {
      return (
        <div
          className={classes.listContainer}
          onMouseOver={() => setShouldHighlight(false)}
          onMouseLeave={() => setShouldHighlight(true)}
        >
          <DraggableItemList
            appId={appId}
            isEditable={false}
            items={groupItems}
            onRemove={() => {}}
            onUpdateOrder={() => {}}
            parentId={modifierGroup.CatalogItemId}
            translate={translate}
          />
        </div>
      );
    }
    return null;
  };

  const renderGroupSummary = (modifierGroup: Group, index: number) => {
    const subtext =
      modifierGroup.Products && modifierGroup.Products.length > 0
        ? translate('number_of_options', { count: modifierGroup.Products?.length })
        : '';
    const imageUrl = modifierGroup.ImageFileName
      ? generateSrcSet(modifierGroup.ImageFileName, 'xs')
      : undefined;

    return (
      <div className={classes.summaryContainer}>
        <div className={classes.imageAndTextContainer}>
          {imageUrl && (
            <Box className={classes.imageContainer}>
              <img className={classes.image} alt="" src={imageUrl} />
            </Box>
          )}
          <div className={classes.textContainer}>
            <Typography className={classes.name}>{modifierGroup.Name}</Typography>
            <Typography variant="caption">{subtext}</Typography>
          </div>
        </div>
        <div className={classes.controlsContainer}>
          {isEditable && (
            <IconButton
              data-fd={`remove-modifier-group-${modifierGroup.CatalogItemId}-button`}
              onClick={(e) => handleRemoveGroup(e, modifierGroup.CatalogItemId as string, index)}
            >
              <Close />
            </IconButton>
          )}
          <LinkIconButton
            fdKey={`modifier-group-${modifierGroup.CatalogItemId}-link-button`}
            label={translate('Open_in_new_tab') as string}
            onClick={(e) => {
              e.stopPropagation();
            }}
            rel="noopener noreferrer"
            target="_blank"
            to={`/${appId}/modifier-groups/${modifierGroup.CatalogItemId}`}
          >
            <OpenInNewIcon />
          </LinkIconButton>
        </div>
      </div>
    );
  };

  const renderModifierGroups = () => {
    return list.map((modifierGroup: Group, index) => {
      const cardStyles = clsx(
        !isEditable && classes.nestedCard,
        !isEditable && index === list.length - 1 && classes.lastNestedCard
      );
      return (
        <Grid key={`modifier-group-${modifierGroup.CatalogItemId}-${index}`} item xs={12}>
          {modifierGroup.Products &&
          modifierGroup.Products.length > 0 &&
          modifierGroup.Products[0].Product ? (
            <DraggableExpansionPanel
              className={cardStyles}
              disabled={list.length < 2 || !isEditable}
              highlightOnHover={shouldHighlight}
              index={index}
              move={moveCard}
              panelId={modifierGroup.CatalogItemId as string}
              summary={() => renderGroupSummary(modifierGroup, index)}
              type={`draggable-card-${parentId}`}
            >
              {renderGroupContents(modifierGroup)}
            </DraggableExpansionPanel>
          ) : (
            <DraggableCard
              cardId={modifierGroup.CatalogItemId as string}
              className={cardStyles}
              disabled={list.length < 2 || !isEditable}
              highlightOnHover={shouldHighlight}
              index={index}
              moveCard={moveCard}
              type={`draggable-card-${parentId}`}
            >
              {renderGroupSummary(modifierGroup, index)}
            </DraggableCard>
          )}
        </Grid>
      );
    });
  };

  return (
    <Grid container spacing={1}>
      <DndProvider backend={HTML5Backend}>{renderModifierGroups()}</DndProvider>
    </Grid>
  );
};
