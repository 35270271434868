import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { Menu } from './Menu';
import MenuBulkEditor from './MenuBulkEditor';
import MenuBulkReport from './MenuBulkReport';
import { Menus } from './Menus';

type Props = RouteComponentProps;

const MenuRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="menu">
      <Switch>
        <Route exact path={`${path}/bulk-edit/:menuId/report`} component={MenuBulkReport} />
        <Route exact path={`${path}/bulk-edit/:menuId`} component={MenuBulkEditor} />
        <Route
          exact
          path={`${path}/:menuId`}
          render={(props) => <Menu key={props.match.params['menuId']} />}
        />
        <Route exact path={`${path}`} component={Menus} />
      </Switch>
    </ErrorBoundary>
  );
};

export default MenuRoutes;
