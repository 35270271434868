import React from 'react';

import { Translate } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms/Typography';

import useContextualStyles from '../contextualStyle';
import AuditLogsWrapper from './AuditLogsWrapper';

export type AuditLogsButtonProps = {
  auditLogsFilter?: { type: string; value: string };
};

export const AuditLogsButton = ({ auditLogsFilter }: AuditLogsButtonProps) => {
  const classes = useContextualStyles();
  return (
    <AuditLogsWrapper auditLogsFilter={auditLogsFilter}>
      <Typography className={classes.Button} variant="caption">
        <Translate id={'Audit_logs'} />
      </Typography>
    </AuditLogsWrapper>
  );
};
