import React, { useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  RestApiPaginationResultStoreGroupExtended,
  StoreGroupBase,
  StoreHeader,
} from 'overrides/@flipdish/api-client-typescript/api';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import { FIVE_MINUTES } from '../../../helpers/timeConstants';
import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { getSalesChannelGroupKey, storeGroupService } from '../../../services/storegroup.service';
import { findStoreGroupId } from '../utils/findStoreGroupById';
import { getUpdatedStoreGroupData } from '../utils/getUpdatedStoreGroupData';
import useStoreToSalesChannelTransformer from '../utils/useStoreToSalesChannelTransformer';
import SalesChannelGroupForm, { type FormValues } from './components/SalesChannelGroupForm';

export type StoreHeadersEnhanced = Array<Required<StoreHeader> & { fromStoreGroupId: number }>;
type Props = MappedState & MappedDispatch;

const SalesChannelGroupDetails = ({
  currentBrandId,
  dispatchNotifySaved,
  dispatchNotifyError,
}: Props) => {
  const { salesChannelGroupId } = useParams<{ salesChannelGroupId: string }>();

  const queryClient = useQueryClient();

  const { data: salesChannelGroupsData, isLoading: isSalesChannelGroupsLoading } = useQuery({
    queryKey: [getSalesChannelGroupKey, currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
    staleTime: FIVE_MINUTES,
  });

  const data = useMemo(() => {
    return salesChannelGroupsData?.Data?.find(
      (d) => d.StoreGroupId === Number(salesChannelGroupId)
    );
  }, [salesChannelGroupsData, salesChannelGroupId]);

  const storeGroupStores = useMemo(() => {
    return (
      data?.StoreHeaders?.map((storeHeader) => ({
        name: storeHeader.Name,
        storeId: storeHeader.StoreId,
      })) ?? []
    );
  }, [data]);

  const storeIdAssociations = useStoreToSalesChannelTransformer();

  const associatedSalesChannels = useMemo(() => {
    return storeIdAssociations
      .filter((association) =>
        storeGroupStores.find((store) => store?.storeId === association?.storeId)
      )
      .map((association) => ({
        label: association?.salesChannelName ?? '',
        value: association?.storeId || 0,
        scId: association?.salesChannelId ?? '',
        storeGroupId:
          findStoreGroupId({ storeId: association?.storeId, salesChannelGroupsData }) ?? 0,
        isNonClearable: true,
      }));
  }, [storeIdAssociations, storeGroupStores]);

  const disassociatedSalesChannels = useMemo(() => {
    if (!storeGroupStores.length) {
      return storeIdAssociations.map((association) => ({
        label: association?.salesChannelName,
        value: association?.storeId,
        scId: association?.salesChannelId,
        storeGroupId: findStoreGroupId({ storeId: association?.storeId, salesChannelGroupsData }),
        isNonClearable: true,
      }));
    }
    return storeIdAssociations
      .filter(
        (association) => !storeGroupStores.some((store) => store?.storeId === association?.storeId)
      )
      .map((association) => ({
        label: association?.salesChannelName,
        value: association?.storeId,
        scId: association?.salesChannelId,
        storeGroupId: findStoreGroupId({ storeId: association?.storeId, salesChannelGroupsData }),
        isNonClearable: true,
      }));
  }, [storeIdAssociations, storeGroupStores]);

  const { mutateAsync: mutateAsyncUpdate } = useMutation({
    mutationFn: (data: { storeGroupId: number; storeGroup: StoreGroupBase }) => {
      return storeGroupService.update(data.storeGroupId, data.storeGroup);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: [getSalesChannelGroupKey, currentBrandId] });
      const previousData = queryClient.getQueryData([getSalesChannelGroupKey, currentBrandId]);
      queryClient.setQueryData(
        [getSalesChannelGroupKey, currentBrandId],
        (oldData: RestApiPaginationResultStoreGroupExtended) => {
          return {
            ...oldData,
            Data: oldData.Data.map((item) =>
              item.StoreGroupId === newData.storeGroupId ? { ...item, ...newData.storeGroup } : item
            ),
          };
        }
      );
      return { previousData };
    },
    onError: (e, newData, context) => {
      queryClient.setQueryData([getSalesChannelGroupKey, currentBrandId], context?.previousData);
    },
  });

  const { mutateAsync: mutateAsyncAssignStores } = useMutation({
    mutationFn: (data: {
      brandId: string;
      storeGroupId: number;
      storeHeaders: StoreHeadersEnhanced;
    }) => {
      const storeIds = data.storeHeaders.map((s) => s.StoreId);
      return storeGroupService.assignStores(data.brandId, data.storeGroupId, storeIds);
    },
    onMutate: async (newData) => {
      const previousData = queryClient.getQueryData<RestApiPaginationResultStoreGroupExtended>([
        getSalesChannelGroupKey,
        currentBrandId,
      ]);
      const updatedDataForCache = getUpdatedStoreGroupData({
        data: previousData,
        currentStoreGroupId: Number(salesChannelGroupId),
        selectedChips: newData.storeHeaders,
      });
      queryClient.setQueryData(
        [getSalesChannelGroupKey, currentBrandId],
        () => updatedDataForCache
      );
      return { previousData };
    },
    onError: (e, newData, context) => {
      queryClient.setQueryData([getSalesChannelGroupKey, currentBrandId], context?.previousData);
    },
  });

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        let responseSuccess;
        if (formValues.name !== data?.Name || formValues.currency?.value !== data?.Currency) {
          responseSuccess = await mutateAsyncUpdate({
            storeGroupId: Number(salesChannelGroupId),
            storeGroup: { Name: formValues.name, Currency: formValues.currency?.value },
          });
        }

        const hasNewStores = formValues.salesChannels?.some(
          (sc) => sc.storeGroupId !== Number(salesChannelGroupId)
        );
        if (currentBrandId && hasNewStores) {
          const storeHeaders: StoreHeadersEnhanced | undefined = formValues.salesChannels?.map(
            (sc) => {
              return {
                StoreId: sc.value,
                Name: sc.label,
                Category: null as any,
                fromStoreGroupId: sc.storeGroupId,
              };
            }
          );
          await mutateAsyncAssignStores({
            brandId: currentBrandId,
            storeGroupId: Number(salesChannelGroupId),
            storeHeaders: storeHeaders ?? [],
          });
          responseSuccess = true;
        }
        if (responseSuccess) {
          dispatchNotifySaved();
        }
      } catch (e) {
        dispatchNotifyError({ message: e.message, translate: true });
      }
    },
    [mutateAsyncUpdate, mutateAsyncAssignStores, salesChannelGroupId, currentBrandId, data]
  );

  return (
    <PageLayout
      documentTitle="Sales_channel_groups"
      title={data?.Name}
      userPermissions={'Owner'}
      toParent={`/${currentBrandId}/settings/sales-channel-groups/`}
      strictToParent
    >
      {isSalesChannelGroupsLoading ? (
        <CircularProgress />
      ) : (
        <SalesChannelGroupForm
          currency={{ label: data?.Currency, value: data?.Currency }}
          name={data?.Name ?? ''}
          salesChannels={associatedSalesChannels}
          options={[...associatedSalesChannels, ...disassociatedSalesChannels]}
          onSubmit={(formValues) => handleSubmit(formValues)}
        />
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentBrandId: state.currentApp.AppId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelGroupDetails);
