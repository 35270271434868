import React, { useRef } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as DndCore from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

const useStyles = makeStyles((theme: Theme) => ({
  dragging: {
    opacity: 0,
  },
}));

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export type DraggableProps = {
  className?: string;
  children;
  disabled?: boolean;
  id: string;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  type: string;
};
export const Draggable = (props: DraggableProps): JSX.Element => {
  const { className, children, disabled, id, index, onMove, type } = props;
  const ref = useRef(null);
  const classes = useStyles();

  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      //@ts-ignore
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as DndCore.XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    canDrag: !disabled,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { id, index },
    type,
  });

  drag(drop(ref));
  const containerStyles = clsx(className, isDragging && classes.dragging);
  return (
    <div className={containerStyles} ref={ref} data-handler-id={handlerId}>
      {children}
    </div>
  );
};
