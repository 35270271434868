import React, { useEffect, useState } from 'react';

import { OrderSummary } from '@flipdish/api-client-typescript';
import ClearIcon from '@mui/icons-material/Clear';
import VolumeIcon from '@mui/icons-material/VolumeUpOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import * as ordersActions from '../../../actions/order.action';
import NoOrderSvg from '../../../assets/images/illust_liveview_coffee_list.svg';
import soundfile from '../../../assets/sounds/flipdish-ringer.mp3';
import OfflineDetector from '../../../ui/OfflineDetector/OfflineDetector';
import Spacer from '../../../ui/Spacer';
import { getPendingOrders } from '../actions';
import OrderDetailsLoading from '../components/OrderDetailsLoading';
import OrdersListLoading from '../components/OrdersListLoading';
import OrderDetailsView from '../Details/DetailsView';
import OrderLiveViewList from './List';
import SnackBar from './SnackBar';
import StoreFilter from './StoreFilter';

type Props = { exit: () => void } & MappedDispatch & MappedState;
const audio = new Audio(soundfile);

const LiveView = (props: Props) => {
  const { fetchPendingOrders, orders, order, getOrder, pendingOrdersLoading, exit } = props;

  const [selectedOrder, setSelectedOrder] = useState<OrderSummary | null>(null);
  const [ordersPage, setOrdersPage] = useState(1);
  const [muteAudio, setMuteAudio] = useState(false);
  const [selectedStores, setSelectedStores] = useState();
  const fromDate = moment().subtract(6, 'months').format('YYYY-MM-DD') as unknown as Date;

  useEffect(() => {
    // the store filter component calls fetchPendingOrders on initalisation
    if (ordersPage !== 1) {
      fetchPendingOrders(ordersPage, selectedStores, fromDate);
    }
  }, [ordersPage]);

  useEffect(() => {
    setOrdersPage(1);
  }, [selectedStores]);

  useEffect(() => {
    if (orders.orders.length) {
      const selectedOrderStatusChanged =
        findIndex(orders.orders, ['OrderId', selectedOrder && selectedOrder.OrderId]) == -1;
      if (!selectedOrder || selectedOrderStatusChanged) {
        const order = orders.orders[0];
        if (order) {
          setSelectedOrder(order);
        }
      }
    }
  }, [orders]);

  useEffect(() => {
    if (selectedOrder) {
      getOrder(selectedOrder);
    }
  }, [selectedOrder]);

  audio.loop = orders.ordersCount > 0;
  useEffect(() => {
    if (orders.ordersCount && !muteAudio && !pendingOrdersLoading) {
      audio.play();
    }
    return () => audio.pause();
  }, [orders.ordersCount]);

  const setMute = () => {
    if (muteAudio === true) {
      setMuteAudio(false);
      audio.play();
    } else {
      setMuteAudio(true);
      audio.pause();
    }
  };

  const renderOrdersList = () => {
    if (orders.orders.length === 0 && !pendingOrdersLoading) {
      return (
        <Typography variant="subtitle1" align="center" style={{ marginTop: 20 }}>
          <Translate id="No-pending-orders" />
        </Typography>
      );
    } else if (pendingOrdersLoading && ordersPage === 1) {
      return <OrdersListLoading />;
    } else {
      return (
        <OrderLiveViewList
          loading={pendingOrdersLoading}
          setPage={setOrdersPage}
          page={ordersPage}
          orderList={orders.orders}
          selectedOrderId={selectedOrder}
          handleSelectedOrderChange={(orderSummary: OrderSummary) => setSelectedOrder(orderSummary)}
        />
      );
    }
  };

  const renderOrderDetail = () => {
    if (orders.orders.length === 0 && !pendingOrdersLoading) {
      return (
        <>
          <Grid justifyContent="flex-end" container>
            <Spacer size={24}>
              <Button onClick={exit}>
                <Typography variant="subtitle1" color="primary">
                  <Translate id="Close" />
                </Typography>
                <ClearIcon color="primary" />
              </Button>
            </Spacer>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '80%' }}>
            <Grid item md={6}>
              <img src={NoOrderSvg} alt="No orders" />
            </Grid>
            <Grid item md={6} container direction="column" style={{ alignSelf: 'center' }}>
              <Typography variant="h2" color="primary">
                <Translate id="No-pending-orders" />
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    } else if (order && order.OrderId && !pendingOrdersLoading) {
      return (
        <Paper>
          <Grid justifyContent="flex-end" container>
            <Spacer size={24}>
              <Button data-fd={'mute-sound-button'} onClick={() => setMute()}>
                <VolumeIcon color={muteAudio ? 'disabled' : 'primary'} />
              </Button>
              <Button onClick={exit}>
                <Typography variant="subtitle1" color="primary">
                  <Translate id="Close" />
                </Typography>
                <ClearIcon color="primary" />
              </Button>
            </Spacer>
          </Grid>
          <OrderDetailsView order={order} />
        </Paper>
      );
    } else {
      return <OrderDetailsLoading />;
    }
  };

  return (
    <Paper>
      <OfflineDetector heartbeat />
      <Grid container spacing={0}>
        <Grid item style={{ minWidth: 400 }}>
          <StoreFilter setParentStores={setSelectedStores} fromDate={fromDate} />
          {renderOrdersList()}
          <SnackBar />
        </Grid>
        <Grid item xs style={{ height: '100vh', overflow: 'auto', paddingLeft: 10 }}>
          {renderOrderDetail()}
        </Grid>
      </Grid>
    </Paper>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    orders: state.pendingOrders,
    order: orders.order,
    orderLoading: orders.orderLoading,
    pendingOrdersLoading: state.pendingOrders.getPendingOrdersLoading,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchPendingOrders: (page: number, storeIds?: number[], fromDate?: Date) =>
    dispatch(getPendingOrders(page, storeIds, fromDate)),
  getOrder: (orderSummary: OrderSummary) =>
    dispatch(ordersActions.getLiveViewOrderById(orderSummary)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveView);
