import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { FormattedCurrency } from '../../../../../../packages/react-intl';
import { dateTimeUtils } from '../../../../../selectors/localeDateTime.selector';
import InfoIcon from '../../../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../../../ui/Tooltip/Tooltip';
import { PayoutDetailSummary } from '../../types';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

type InnerProps = MappedState;
type OuterProps = {
  payoutDetailSummary: PayoutDetailSummary | undefined;
  loading?: boolean;
  currency?: CurrencyEnum;
  languageCode?: string;
};

type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3),
    textTransform: 'capitalize',
  },
  sectionTitle: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  statusPadding: {
    paddingTop: '8px',
  },
  paidStatus: {
    color: '#20C26E',
  },
  failedStatus: {
    color: '#FF2346',
  },
  pendingStatus: {
    color: '#757575',
  },
  icon: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
}));

const PayoutOverview = (props: Props) => {
  const { dtUtils, payoutDetailSummary, loading, currency, languageCode } = props;

  const classes = useStyles();

  const getPayoutsStatusText = (payoutStatus) => {
    switch (payoutStatus) {
      case 'Paid':
        return (
          <span className={classes.paidStatus}>
            <Translate id="Paid" />
          </span>
        );
      case 'Cancelled':
        return (
          <span className={classes.failedStatus}>
            <Translate id="Cancelled" />
          </span>
        );
      case 'Failed':
        return (
          <span className={classes.failedStatus}>
            <Translate id="Failed" />
          </span>
        );
      case 'InTransit':
        return (
          <span className={classes.pendingStatus}>
            <Translate id="In_transit" />
          </span>
        );
      case 'Pending_bank_account':
        return (
          <span className={classes.pendingStatus}>
            <Translate id="ReadyToProcess" />
          </span>
        );
      default:
        return '';
    }
  };

  if (loading || !payoutDetailSummary || !payoutDetailSummary.CreatedDate) {
    return (
      <div data-fd="payouts-skeleton-loader">
        <Skeleton width={'20%'} height={24} />
        <Paper className={classes.container}>
          <Skeleton height={190} />
        </Paper>
      </div>
    );
  }

  const payoutCreateDate =
    payoutDetailSummary.CreatedDate &&
    dtUtils.format(payoutDetailSummary.CreatedDate as any, dtUtils.LOCAL_DATE_FORMAT);

  const getPayoutDatePeriod = (payoutDetailSummary: PayoutDetailSummary) => {
    const subOneDayFromPeriodEndTime = subDays(
      new Date(payoutDetailSummary.PeriodEndTime as any),
      1
    );

    const startTime =
      payoutDetailSummary.PeriodStartTime &&
      dtUtils.format(payoutDetailSummary.PeriodStartTime as any, dtUtils.LOCAL_DATE_FORMAT);
    const endTime =
      payoutDetailSummary.PeriodEndTime &&
      dtUtils.format(subOneDayFromPeriodEndTime, dtUtils.LOCAL_DATE_FORMAT);

    const isSameDatePeriod = isSameDay(
      subOneDayFromPeriodEndTime,
      new Date(payoutDetailSummary.PeriodStartTime as any)
    );

    if (!isSameDatePeriod) {
      return `${startTime} - ${endTime}`;
    }
    return startTime;
  };

  return (
    <>
      <Typography variant="h5" className={classes.sectionTitle}>
        <Translate id={'Overview'} />
      </Typography>
      <Hidden smDown>
        <Paper className={classes.container}>
          <Grid container>
            <Grid item sm container>
              <Grid item xs container direction="column">
                <Typography variant="caption">
                  <Translate id={'Payout_date'} />
                </Typography>
                <Box pt={1}>
                  <Typography variant="h5" data-fd="overview-payout-date">
                    {payoutCreateDate}
                  </Typography>
                </Box>

                <Box mt={6.5}>
                  <Typography variant="caption">
                    <Translate id={'Bank_Account'} />
                  </Typography>
                  <Typography
                    className={classes.statusPadding}
                    data-fd="overview-bank-acc"
                    variant="h5"
                  >
                    {`${payoutDetailSummary.DestinationBank} **** ${payoutDetailSummary.DestinationAccount}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid item sm container>
              <Grid item xs container direction="column">
                <Typography variant="caption">
                  <Translate id={'Date_period'} />

                  <Tooltip
                    id="Date_payout_period_tooltip"
                    messageId="This_payout_includes_orders_that_were_accepted"
                    fdKey="Date_payout_period_tooltip"
                  >
                    <div className={classes.icon}>
                      <InfoIcon size={13} verticalAlign="text-top" />
                    </div>
                  </Tooltip>
                </Typography>

                <Box pt={1}>
                  <Typography variant="h5" data-fd="overview-date-period">
                    {getPayoutDatePeriod(payoutDetailSummary)}
                  </Typography>
                </Box>

                <Box mt={6.5}>
                  <Typography variant="caption">
                    <Translate id={'Amount'} />
                  </Typography>
                  <Box pt={1}>
                    <Typography variant="h5" data-fd="overview-amount">
                      <FormattedCurrency
                        value={payoutDetailSummary.Amount as number}
                        locales={languageCode}
                        currency={currency || 'EUR'}
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item sm container>
              <Grid container direction="column" justifyContent="flex-end" alignItems="flex-start">
                <Typography variant="caption">
                  <Translate id={'Status'} />
                </Typography>

                <Box pt={1}>
                  <Typography variant="h5" data-fd="overview-status">
                    {getPayoutsStatusText(payoutDetailSummary.PayoutStatus)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Hidden>

      <Hidden smUp>
        <Box mt={4} ml={2} mb={2} style={{ textTransform: 'capitalize' }}>
          <Typography variant="caption">
            <Translate id={'Payout_date'} />
          </Typography>
          <Typography variant="h5">{payoutCreateDate}</Typography>
          <Box pt={4}>
            <Typography variant="caption">
              <Translate id={'Date_period'} />
              <Tooltip
                id="Date_payout_period_tooltip"
                messageId="This_payout_includes_orders_that_were_accepted"
                fdKey="Date_payout_period_tooltip"
              >
                <div className={classes.icon}>
                  <InfoIcon size={13} verticalAlign="text-top" />
                </div>
              </Tooltip>
            </Typography>
            <Typography variant="h5">{getPayoutDatePeriod(payoutDetailSummary)}</Typography>
          </Box>

          <Box pt={4} pb={4}>
            <Typography variant="caption">
              <Translate id={'Bank_Account'} />
            </Typography>
            <Typography variant="h5">
              {`${payoutDetailSummary.DestinationBank} **** ${payoutDetailSummary.DestinationAccount}`}
            </Typography>
          </Box>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="caption">
                <Translate id={'Amount'} />
              </Typography>
              <Typography variant="h5">
                <FormattedCurrency
                  value={payoutDetailSummary.Amount as number}
                  locales={languageCode}
                  currency={currency || 'EUR'}
                />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption">
                <Translate id={'Status'} />
              </Typography>
              <Typography variant="h5">
                {getPayoutsStatusText(payoutDetailSummary.PayoutStatus)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </Hidden>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  dtUtils: dateTimeUtils(state),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, null))(PayoutOverview);
