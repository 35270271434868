import React, { useEffect } from 'react';

import { Product } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { Dialog } from '@fd/ui/molecules/Dialog';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';

import { priceRegExp } from '../../../../helpers/validation';
import { ProductTypeSelect } from '../../Products/components/ProductTypeSelect';
import { ProductFormValues } from '../../types';
import { generateSku, validateCatalogItemForm } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(1.25),
    maxWidth: '480px',
  },
  formField: {
    minHeight: '5rem',
  },
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  priceField: {
    width: '50%',
    marginTop: theme.spacing(2),
    minHeight: '5rem',
  },
  typeField: {
    marginBottom: theme.spacing(4),
  },
}));

export const getDefaultFormState = (defaultType: Product.ProductTypeEnum) => {
  return {
    Description: '',
    ProductType: defaultType,
    Name: '',
    Price: 0.0,
    Sku: generateSku('P'),
  };
};

export type CreateProductDialogProps = {
  defaultType: Product.ProductTypeEnum;
  onClose: () => void;
  open: boolean;
  onSubmit: (product: ProductFormValues) => void;
  translate: TranslateFunction;
};

const CreateProductDialog = (props: CreateProductDialogProps & FormikProps<ProductFormValues>) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    onClose,
    open,
    setFieldValue,
    resetForm,
    translate,
    values,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const handleCancelClick = () => {
    onClose();
  };

  const handleTypeChange = (type: Product.ProductTypeEnum) => {
    setFieldValue('ProductType', type);
    setFieldValue('Description', '');
  };

  return (
    <Dialog
      open={open}
      title={translate('Products_CreateAProduct') as string}
      actions={[
        <Button
          className={classes.cancelButton}
          key="create-product-cancel"
          fdKey="create-product-cancel"
          variant="text"
          onClick={handleCancelClick}
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          key="create-product-confirm"
          fdKey="create-product-confirm"
          form="create-product-form"
          type="submit"
          disabled={isSubmitting || !dirty || !isValid}
          onClick={handleSubmit}
        >
          <Translate id="Products_CreateProductDialog_CreateProduct" />
        </Button>,
      ]}
    >
      <Typography variant="caption">
        <Translate id="Products_CreateProductDialog_Description" />
      </Typography>
      <Form id="create-product-form" className={classes.form}>
        <ProductTypeSelect
          className={classes.typeField}
          label="Type"
          onChange={handleTypeChange}
          selectedValue={values.ProductType}
          translate={translate}
          variant="standard"
        />
        <FormikInputField
          autoFocus
          fdKey="create-product-form-name"
          name="Name"
          className={classes.formField}
          label={translate('Product_CreateProductDialog_Name') as string}
        />
        {values.ProductType === Product.ProductTypeEnum.Product && (
          <FormikInputField
            fdKey="create-product-form-desc"
            multiline
            name="Description"
            className={classes.formField}
            label={translate('Product_CreateProductDialog_Description') as string}
          />
        )}
        <FormikInputField
          fdKey="create-product-form-price"
          name="Price"
          className={classes.priceField}
          inputProps={{ inputMode: 'decimal', pattern: priceRegExp, step: '0.01' }}
          disableLabelAnimation
          placeholder="0.00"
          type="number"
          label={translate('Product_CreateProductDialog_SetPrice') as string}
          variant="outlined"
          onBlur={(e) => {
            const value = Number(e.target.value);
            const formatted = value.toFixed(2);
            setFieldValue('Price', formatted);
          }}
        />
        <FormikInputField
          fdKey="create-product-form-sku"
          name="Sku"
          className={classes.formField}
          label="SKU"
        />
      </Form>
    </Dialog>
  );
};

export default withFormik<CreateProductDialogProps, ProductFormValues>({
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.defaultType);
  },
  handleSubmit: async (values, formikBag) => {
    try {
      await formikBag.props.onSubmit(values);
      formikBag.props.onClose();
      formikBag.setSubmitting(false);
    } catch (error) {
      console.log('error', error);
      const errorMessage = formikBag.props.translate(
        'Product_CreateProductDialog_DuplicateSkuError'
      ) as string;
      error.message.includes('SKU') && formikBag.setFieldError('Sku', errorMessage);
      formikBag.setSubmitting(false);
    }
  },
  validate: async (values, { translate }) => {
    return validateCatalogItemForm(values, translate);
  },
})(CreateProductDialog);
