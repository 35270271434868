import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../../helpers/validation';
import TextField from '../../../../../ui/TextField/TextField';
import { countryCodeToCountry } from '../../banking.selectors';
import FormItemLayout from '../FormItemLayout';
import BankCountrySelectorFormField from './BankCountrySelectorFormField';
import FieldWrapper from './FieldWrapper';

const validate = (value: string, fieldName: string) => {
  return (
    formikValidate.required(value) || formikValidate.max_string_custom(100)(value.length, fieldName)
  );
};

const BankAddressFormFields = () => {
  const editView = window.location.pathname.endsWith('/edit');

  return (
    <FormItemLayout label={<Translate id="Bank_address" />}>
      <FieldWrapper>
        {!editView && (
          <BankCountrySelectorFormField
            name={'BankCountryCode'}
            fdKey={'Bank_address_country_code'}
          />
        )}
        {editView && (
          <Field name={'BankCountryCode'}>
            {({ field }: FieldProps) => {
              return (
                <TextField
                  defaultValue={countryCodeToCountry(field.value)}
                  fdKey={'Bank_address_country_code'}
                  label={<Translate id="Country" />}
                  fullWidth
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            }}
          </Field>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <Field name={'BankAddress'} validate={(value: string) => validate(value, 'Address')}>
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = errors[field.name] as string | undefined;
            const showError = editView
              ? !!fieldError
              : !!fieldError && (touched[field.name] as boolean | undefined);

            return (
              <TextField
                {...field}
                fdKey={'Bank_address'}
                label={<Translate id="Address" />}
                autoComplete="off"
                fullWidth
                multiline
                maxRows="3"
                disabled={isSubmitting}
                error={showError}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={showError ? fieldError : undefined}
                style={{ marginBottom: '8px' }}
              />
            );
          }}
        </Field>
      </FieldWrapper>
    </FormItemLayout>
  );
};

export default BankAddressFormFields;
