import * as React from 'react';

import { SupportedCountry } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { compose, setDisplayName } from 'recompose';

import { enqueueTranslateNotification } from '../../../actions/context.actions';
import { updateAddress } from '../../../actions/store.actions';
import { Store } from '../types';
import withStoreByIdData, { WithStoreByIdData } from '../withStoreByIdData';

export interface IStoreAddressEditorProps {
  storeGroupId: number;
  storeId: number;
  countryCode: string;
  className?: string;
  disabled?: boolean;
  supportedCountry?: SupportedCountry;
}

const styles = (theme: Theme) =>
  createStyles({
    saveButtonContainer: {
      marginTop: theme.spacing(8),
    },
  });

type FormData = Pick<Store['Address'], 'Line1' | 'Postcode' | 'City'>;
type Props = IStoreAddressEditorProps & WithStoreByIdData & WithStyles<typeof styles>;

const StoreAddressEditor = compose<Props, IStoreAddressEditorProps>(
  setDisplayName('StoreAddressEditor'),
  withStoreByIdData,
  withStyles(styles, {
    name: 'FdStoreAddressEditor',
    withTheme: true,
  })
)(
  class extends React.PureComponent<Props, FormData> {
    constructor(props: Props) {
      super(props);
      const { storeByIdData } = props;
      this.state = {
        Line1: storeByIdData?.Address?.Line1 || '',
        Postcode: storeByIdData?.Address?.Postcode || '',
        City: storeByIdData?.Address?.City || '',
      };
    }

    public componentDidUpdate(prevProps: Props) {
      if (!prevProps.storeByIdData && this.props.storeByIdData) {
        this.setState({
          Line1: this.props.storeByIdData.Address.Line1,
          Postcode: this.props.storeByIdData.Address.Postcode,
          City: this.props.storeByIdData.Address.City,
        });
      }
    }

    public render() {
      const { className, disabled, supportedCountry, classes } = this.props;
      return (
        <form onSubmit={this.onSubmit} className={className}>
          <Translate>
            {(translate) => (
              <>
                <TextField
                  variant="standard"
                  id="Line1"
                  name="Line1"
                  label={translate('Address')}
                  value={this.state.Line1}
                  onChange={this.onChange}
                  fullWidth
                  margin="dense"
                  style={{
                    marginTop: 0,
                  }}
                  autoFocus={this.state.Line1 === ''}
                  disabled={disabled}
                  InputProps={{
                    inputProps: {
                      'data-fd': 'address',
                    },
                  }}
                />

                <TextField
                  variant="standard"
                  id="City"
                  name="City"
                  label={translate('City')}
                  value={this.state.City}
                  onChange={this.onChange}
                  fullWidth
                  margin="dense"
                  disabled={disabled}
                  InputProps={{
                    inputProps: {
                      'data-fd': 'address_city',
                    },
                  }}
                />

                <TextField
                  variant="standard"
                  id="Postcode"
                  name="Postcode"
                  label={
                    supportedCountry && supportedCountry.PostCodeType! <= 1
                      ? translate('Postal_code')
                      : translate('ZIP')
                  }
                  value={this.state.Postcode}
                  onChange={this.onChange}
                  fullWidth
                  margin="dense"
                  disabled={disabled}
                  InputProps={{
                    inputProps: {
                      'data-fd': 'postCode',
                    },
                  }}
                />
                <Grid
                  container
                  className={classes.saveButtonContainer}
                  item
                  xs={12}
                  justifyContent="flex-end"
                >
                  {
                    <Button
                      color="primary"
                      variant="contained"
                      data-fd="legacy-address-save-button"
                      disabled={disabled}
                      type="submit"
                    >
                      {translate('Products_EditProductForm_SaveButtonTitle')}
                    </Button>
                  }
                </Grid>
              </>
            )}
          </Translate>
        </form>
      );
    }

    private onSubmit = (e: React.FormEvent) => {
      const { dispatch, storeId, storeGroupId, countryCode } = this.props;
      e.preventDefault();
      dispatch(enqueueTranslateNotification('Saving'));
      dispatch(
        updateAddress(storeId, storeGroupId, {
          ...this.state,
          CountryCode: countryCode,
        })
      );
    };
    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
  }
);

export default StoreAddressEditor;
