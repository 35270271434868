import * as FlipdishSignalR from '@flipdish/api-client-typescript-signalr';

import { getApiEndPoint } from '../../helpers/apibase';

const signalrLogging = process.env.NODE_ENV !== 'production';

const configuration = new FlipdishSignalR.SignalRConfiguration(
  getApiEndPoint(),
  undefined,
  signalrLogging
);

let signalrConnection: FlipdishSignalR.SignalR | undefined;

export { signalrConnection as Connection };

let hydraHeartbeatTimer: NodeJS.Timeout | undefined;
const setupHydraHeartbeat = () => {
  try {
    // @ts-ignore
    const cnn = signalrConnection!.constructor.ActiveConnection;
    cnn.stateChanged(({ oldState, newState }) => {
      if (oldState === 0 && newState === 1) {
        hydraHeartbeatTimer = setInterval(() => {
          if (cnn.state === 1) {
            cnn.proxies.authorizationhub.invoke('Heartbeat');
          }
        }, 60 * 1000);
      }
      if (newState === 4) {
        if (hydraHeartbeatTimer) {
          clearInterval(hydraHeartbeatTimer);
          hydraHeartbeatTimer = undefined;
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export function startConnection(): boolean {
  if (!signalrConnection) {
    signalrConnection = new FlipdishSignalR.SignalR(configuration);
    setupHydraHeartbeat();
    return signalrConnection.Start(() => {
      if (signalrConnection) {
        signalrConnection.AuthorizationHub.authenticate().then((authReply) => {
          console.log(authReply);
        });
      }
    });
  }
  return false;
}

export function closeConnection() {
  if (signalrConnection) {
    signalrConnection.Stop();
    signalrConnection = undefined;
  }
}

export function subscribe<T extends object>(
  connection: FlipdishSignalR.SignalR,
  hubName: string,
  eventName: string,
  eventHandler: (data: T) => void
) {
  if (connection) {
    if (connection[hubName]) {
      // The connection[hubName] object has a prototype object - this is created in the api client
      // This means you won't see the `On${eventName}` on the connection[hubName] object, it is inherited through the prototype
      if (connection[hubName][`On${eventName}`]) {
        connection[hubName][`On${eventName}`](eventHandler);
      }
    }
  }
}

export function unsubscribe(
  connection: FlipdishSignalR.SignalR,
  hubName: string,
  eventName: string
) {
  if (connection) {
    if (connection[hubName]) {
      if (connection[hubName][`Off${eventName}`]) {
        connection[hubName][`Off${eventName}`](undefined);
      }
    }
  }
}
