import React from 'react';
import { Translate, TranslatePlaceholderData } from 'react-localize-redux';

export interface ISubTitleProps {
  translateId: TranslationId;
  data?: TranslatePlaceholderData;
}

export default function SubPageTitle(props: ISubTitleProps) {
  const { translateId, data } = props;

  return <Translate id={translateId} data={data} />;
}
