import React from 'react';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '../../FormItemLayout';

type Props = {
  showValidType?: boolean;
};

const ValidForField = (props: Props) => {
  const { showValidType = true } = props;

  return (
    <FormItemLayout label={<Translate id="Voucher_valid_type" />} noSpace>
      <Grid container spacing={2}>
        {showValidType && (
          <Grid item xs={12} md={6}>
            <Field name={'ValidType'}>
              {({ field, form }: FieldProps) => {
                const onChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
                  const { value } = event.target;

                  if (value === 'delivery') {
                    form.setFieldValue('IsValidForPickupOrders', false, false);
                    form.setFieldValue('IsValidForDeliveryOrders', true, false);
                  }
                  if (value === 'collection') {
                    form.setFieldValue('IsValidForPickupOrders', true, false);
                    form.setFieldValue('IsValidForDeliveryOrders', false, false);
                  }
                  if (value === 'delivery & collection') {
                    form.setFieldValue('IsValidForPickupOrders', true, false);
                    form.setFieldValue('IsValidForDeliveryOrders', true, false);
                  }

                  field.onChange(event);
                };

                return (
                  <Select
                    {...field}
                    data-fd={'Valid_Type'}
                    disabled={form.isSubmitting}
                    fullWidth
                    onChange={onChange}
                  >
                    <MenuItem value={'delivery'}>
                      <Translate id="Delivery" />
                    </MenuItem>

                    <MenuItem value={'collection'}>
                      <Translate id="Collection" />
                    </MenuItem>

                    <MenuItem value={'delivery & collection'}>
                      <Translate id="Collection_and_delivery" />
                    </MenuItem>
                  </Select>
                );
              }}
            </Field>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Field name={'ValidPayment'}>
            {({ field, form }: FieldProps) => {
              const onChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
                const { value } = event.target;

                if (value === 'cash') {
                  form.setFieldValue('IsValidForOrdersPayedByCash', true, false);
                  form.setFieldValue('IsValidForOrdersPayedOnline', false, false);
                }
                if (value === 'pay online') {
                  form.setFieldValue('IsValidForOrdersPayedByCash', false, false);
                  form.setFieldValue('IsValidForOrdersPayedOnline', true, false);
                }
                if (value === 'pay online & cash') {
                  form.setFieldValue('IsValidForOrdersPayedByCash', true, false);
                  form.setFieldValue('IsValidForOrdersPayedOnline', true, false);
                }

                field.onChange(event);
              };

              return (
                <Select
                  {...field}
                  data-fd={'Valid_Payment'}
                  disabled={form.isSubmitting}
                  fullWidth
                  onChange={onChange}
                >
                  <MenuItem value={'pay online'}>
                    <Translate id="Pay_online" />
                  </MenuItem>

                  <MenuItem value={'cash'}>
                    <Translate id="Cash" />
                  </MenuItem>

                  <MenuItem value={'pay online & cash'}>
                    <Translate id="Pay_online_and_cash" />
                  </MenuItem>
                </Select>
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </FormItemLayout>
  );
};

export default ValidForField;
