import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Checkbox from '@fd/ui/Checkbox';

import { Typography } from '../Typography';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: theme.spacing(0),
  },
}));

export type CheckboxFieldProps = {
  ariaLabel: string;
  checked?: boolean;
  disabled?: boolean;
  fdKey: string;
  label: string;
  labelPlacement?: 'bottom' | 'top' | 'start' | 'end' | undefined;
  onChange: (isChecked: boolean) => void;
};

export const CheckboxField = (props: CheckboxFieldProps): JSX.Element => {
  const {
    ariaLabel,
    checked = false,
    disabled = false,
    fdKey,
    label,
    labelPlacement = 'end',
    onChange,
  } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.container}
      label={<Typography variant="body1">{label}</Typography>}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          dataFd={fdKey}
          color="primary"
          disabled={disabled}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          inputProps={{
            'aria-label': ariaLabel,
          }}
        />
      }
    />
  );
};
