import React, { useEffect } from 'react';

import usePrevious from '@fd/customHooks/usePrevious';
import { WebhookSubscription } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { type FieldProps, type FormikProps, Field, Form, withFormik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { generateString } from '../../../helpers/utilities';
import { LoadingButton } from '../../../ui/LoadingButton';

const styles = (theme: Theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(4),
      '& a': {
        textDecoration: 'none',
      },
    },
  });

type InnerProps = ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

export type OuterProps = {
  apiError?: string;
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  submit: (changes: WebhookSubscription) => Promise<void>;
  isLoading: boolean | undefined;
};

type Props = InnerProps & OuterProps;
export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = () => {
  return {
    Version: '1.0',
    CallbackUrl: '',
    VerifyToken: '',
    Enabled: true,
    EventNames: [],
  };
};

const validateCallbackUrl = (url: string) => {
  if (url.length === 0) {
    return 'Required';
  }
  if (
    // eslint-disable-next-line no-useless-escape
    !/^(https:\/\/)?[a-z0-9]+([\-\.]{1,}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url)
  ) {
    return 'Uri_not_valid';
  }
  return;
};

const validateVerifyToken = (token: string) => {
  if (token.length === 0) {
    return 'Required';
  }

  return;
};

export const DUPLICATE_URI_MESSAGE =
  'Sorry. The callback url has already been configured for a different webhook subscription for this user';
export const INVALID_URI_MESSAGE = 'Invalid URI';
const callbackField = 'CallbackUrl';

const OAuthAppCreateWebhookForm = (props: FormikProps<FormValues> & Props) => {
  const {
    setDialogOpen,
    resetForm,
    isDialogOpen,
    setFieldError,
    translate,
    classes,
    isLoading,
    apiError,
  } = props;
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    isDialogOpen === false && resetForm();
  }, [isDialogOpen]);

  useEffect(() => {
    if (prevIsLoading && !isLoading && apiError) {
      if (apiError === DUPLICATE_URI_MESSAGE) {
        setFieldError(callbackField, 'Callback_url_already_configured');
      } else if (apiError === INVALID_URI_MESSAGE) {
        setFieldError(callbackField, 'Uri_not_valid');
      }
    } else if (prevIsLoading && !isLoading && !apiError) {
      resetForm();
      setDialogOpen(false);
    }
  }, [apiError, isLoading]);

  const dialogContent = [
    <DialogContent key="dialog-content">
      <Field name="Version">
        {({ field, form }: FieldProps) => {
          const { isSubmitting, errors, touched } = form;
          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          return (
            <FormControl className={classes.row} fullWidth error={showError}>
              <InputLabel htmlFor="Webhook_verison">{translate('Version')}</InputLabel>
              <Select
                {...field}
                variant="standard"
                disabled={isSubmitting}
                onChange={(e: any) => form.setFieldValue('Version', e.target.value)}
                name="Webhook_verison"
                input={<Input fullWidth id="name-native-error" />}
              >
                <MenuItem value="1.0">1.0</MenuItem>
                <MenuItem value="2.0">2.0</MenuItem>
              </Select>
            </FormControl>
          );
        }}
      </Field>
      <Field name="CallbackUrl" validate={validateCallbackUrl}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          return (
            <TextField
              variant="standard"
              {...field}
              className={classes.row}
              disabled={isSubmitting}
              error={showError}
              label={translate('Callback_url')}
              fullWidth
              type="url"
              helperText={
                showError && fieldError
                  ? translate(fieldError, undefined, { missingTranslationMsg: fieldError })
                  : null
              }
              inputProps={{ 'data-fd': 'Webhook_callback_url' }}
              InputLabelProps={{ shrink: true }}
              // Reset error when focusing the input field
              onFocus={(e) => {
                if (errors.Uri) {
                  form.setFieldError('CallbackUrl', '');
                }
              }}
              onChange={(e) => form.setFieldValue('CallbackUrl', e.target.value)}
            />
          );
        }}
      </Field>
      <Field name="VerifyToken" validate={validateVerifyToken}>
        {({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);
          return (
            <TextField
              variant="standard"
              {...field}
              className={classes.row}
              disabled={isSubmitting}
              error={showError}
              label={translate('Verify_token')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ 'data-fd': 'Webhook_verify_token' }}
              // Reset error when focusing the input field
              onFocus={(e) => {
                if (errors.Uri) {
                  form.setFieldError('VerifyToken', '');
                }
              }}
              helperText={
                <>
                  {translate('Verify_token_helper_text')}{' '}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      form.setFieldValue('VerifyToken', generateString(16));
                    }}
                    data-fd="generate-verify-token"
                    href="#"
                  >
                    {translate('Generate')}
                  </a>
                </>
              }
              onChange={(e) => form.setFieldValue('VerifyToken', e.target.value)}
            />
          );
        }}
      </Field>
    </DialogContent>,
    <DialogActions key="actions">
      <Button color="primary" data-fd="Create_webhook_cancel" onClick={() => setDialogOpen(false)}>
        {translate('Cancel')}
      </Button>
      <LoadingButton
        color="primary"
        fdKey="Create_webhook_create"
        onClick={(e) => props.handleSubmit()}
        disabled={props.isSubmitting}
        loading={props.isLoading}
      >
        {translate('Create')}
      </LoadingButton>
    </DialogActions>,
  ];

  return (
    <Form>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          props.resetForm();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogContent}
      </Dialog>
    </Form>
  );
};

const mapStateToProps = ({ locale }) => ({
  translate: getTranslate(locale),
});

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps),
  withStyles(styles),
  withFormik<Props, FormValues>({
    displayName: 'OAuthAppCreateWebhookForm',
    mapPropsToValues: () => {
      return getDefaultFormState();
    },
    validateOnChange: false,
    handleSubmit: (values, formikBag) => {
      formikBag.props.submit(values);
      formikBag.setSubmitting(false);
    },
  })
)(OAuthAppCreateWebhookForm);
