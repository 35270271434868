import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import { isFlipdishGlobal } from '../../selectors/app.selector';

type Props = MappedProps & RouteComponentProps;

const BankingRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
    canCreate,
    isFlipdishGlobal,
  } = props;
  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`/:appId/finance/bank-accounts`} />
      <Redirect exact from={`${path}/:accountId`} to={`/:appId/finance/bank-accounts/:accountId`} />
      {canCreate && !isFlipdishGlobal && (
        <Redirect exact from={`${path}/new`} to={`/:appId/finance/bank-accounts/new`} />
      )}
      {canCreate && (
        <Redirect
          exact
          from={`${path}/:accountId/edit`}
          to={`/:appId/finance/bank-accounts/:accountId/edit`}
        />
      )}
    </Switch>
  );
};
type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const canCreate = checkVisibility(
    state.permissions,
    [App.AppResourceSetEnum.CreateBankAccounts],
    []
  );
  return {
    canCreate,
    isFlipdishGlobal: isFlipdishGlobal(state),
  };
}
export default compose<Props, RouteComponentProps>(connect(mapStateToProps))(BankingRoutes);
