import React from 'react';

import { BankAccountSummary, StripeConnectedAccount } from '@flipdish/api-client-typescript';
import Done from '@mui/icons-material/Done';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import NotInterested from '@mui/icons-material/NotInterested';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import Avatar from '@mui/material/Avatar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

const styles = () =>
  createStyles({
    successAvatar: {
      backgroundColor: '#52d699',
    },
    verifyAvatar: {
      backgroundColor: '#ffbc2c',
    },
    pendingAvatar: {
      backgroundColor: '#89b5fe',
    },
    rejectedAvatar: {
      backgroundColor: '#ff647e',
    },
  });

type Props = WithStyles<typeof styles> & IListItemContainerProps;

interface IListItemContainerProps {
  account: BankAccountSummary;
  bankStateEnum: BankAccountSummary.AccountStateEnum;
  stripeStateEnum: StripeConnectedAccount.AccountStatusEnum;
}

const ListItemIconStripe: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, account } = props;
  const bankStateEnum = BankAccountSummary.AccountStateEnum;
  const stripeStateEnum = StripeConnectedAccount.AccountStatusEnum;
  const bankState = account.AccountState;
  const stripeState = account.StripeConnectedAccountInfo
    ? account.StripeConnectedAccountInfo.AccountStatus
    : '';
  const businessType = account.BusinessType || '';

  const accountIcon = () => {
    switch (true) {
      case bankState === bankStateEnum.Unverified || stripeState === stripeStateEnum.Disabled:
      case stripeState === stripeStateEnum.Rejected:
        return (
          <Avatar className={classes.rejectedAvatar} data-fd="avatarNotInterested">
            <NotInterested />
          </Avatar>
        );
      case stripeState === stripeStateEnum.AdditionalInformationRequired:
      case stripeState === stripeStateEnum.Unverified:
      case stripeState === stripeStateEnum.UpdateExternalAccount:
      case businessType === '':
        return (
          <Avatar className={classes.verifyAvatar} data-fd="avatarReportProblemOutlined">
            <ReportProblemOutlined />
          </Avatar>
        );
      case bankState === bankStateEnum.AwatingVerification ||
        stripeState === stripeStateEnum.PendingVerification:
        return (
          <Avatar className={classes.pendingAvatar} data-fd="pendingAvatar">
            <InfoOutlined />
          </Avatar>
        );
      case bankState === bankStateEnum.Verified ||
        stripeState === stripeStateEnum.Enabled ||
        bankState === bankStateEnum.Grandfathered:
        return (
          <Avatar className={classes.successAvatar} data-fd="avatarDone">
            <Done />
          </Avatar>
        );
      default:
        return (
          <Avatar className={classes.verifyAvatar}>
            <ReportProblemOutlined />
          </Avatar>
        );
    }
  };
  return accountIcon();
};

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'AccountListIcon',
    withTheme: false,
  })
)(ListItemIconStripe);

export default EnhancedComponent;
