import { useQuery } from '@tanstack/react-query';

import { catalogChangesService } from '../services/catalogChanges.service';

export type PendingMenuChangesParamsProps = {
  appId?: string;
  catalogElementId?: string;
  limit: number;
  menuId?: number;
  page: number;
};

export const useQueryPendingMenuChangesHook = (
  queryKey: any,
  params: PendingMenuChangesParamsProps,
  enabled: boolean
) => {
  const { appId, menuId, catalogElementId, page, limit } = params;
  const { isLoading, data, isError, isFetched } = useQuery({
    queryKey: [queryKey],

    queryFn: () =>
      catalogChangesService.getPendingMenuChanges(
        appId as string,
        menuId,
        catalogElementId,
        page,
        limit
      ),
    gcTime: 1000 * 30,
    enabled: enabled,
  });

  return {
    isLoading,
    data,
    isError,
    isFetched,
  };
};
