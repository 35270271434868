import { useInfiniteQuery } from '@tanstack/react-query';

export type QueryKey = 'loadByAppId' | 'getSupportedApps' | 'getStoresByAppId';

export type FetchDataProps = {
  appId: string;
  query?: string;
  page?: number;
};

export const useInfiniteQueryStoreFilterHook = (
  fetchDataProps: FetchDataProps,
  fetchData: (props: FetchDataProps) => Promise<any>,
  queryKey: QueryKey
) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useInfiniteQuery({
    queryKey: [queryKey, fetchDataProps],
    queryFn: ({ pageParam }) => {
      const props = {
        ...fetchDataProps,
        page: pageParam,
      };
      return fetchData(props);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.Data.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  };
};
