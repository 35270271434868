import { LoyaltyCampaign, RetentionCampaign, StoreListItem } from '@flipdish/api-client-typescript';
import { createReducer } from 'redux-act';

import { updateItemInArray } from '../../actions/utils';
import { campaignSignalActions } from '../../signalr/hub.actions';
import { campaignActions } from './actions';

const defaultState = {
  retention: [] as RetentionCampaign[],
  loyalty: [] as LoyaltyCampaign[],
  stores: [] as StoreListItem[],
};
export type defaultCampaignsStateType = typeof defaultState;

export const ReducerCreator = (state = defaultState) => {
  const CampaignReducer = createReducer({}, state);

  CampaignReducer.on(campaignActions.getStoresListActions.success, (state, payload) => {
    return {
      ...state,
      stores: payload,
    };
  });

  CampaignReducer.on(campaignActions.getCampaignsActions.success, (state, payload) => {
    return {
      ...state,
      retention: payload[1],
      loyalty: payload[0],
    };
  });

  CampaignReducer.on(campaignActions.createLoyaltyCampaignActions.success, (state, payload) => {
    return {
      ...state,
      loyalty: [...state.loyalty, payload],
    };
  });

  CampaignReducer.on(campaignActions.modifyLoyaltyCampaignActions.success, (state, payload) => {
    return {
      ...state,
      loyalty: updateItemInArray(state.loyalty, 'CampaignId', payload),
    };
  });

  CampaignReducer.on(campaignActions.removeLoyaltyCampaignActions.success, (state, payload) => {
    return {
      ...state,
      loyalty: state.loyalty.filter(({ CampaignId }) => CampaignId !== payload),
    };
  });

  CampaignReducer.on(campaignActions.createRetainCampaignActions.success, (state, payload) => {
    return {
      ...state,
      retention: [...state.retention, payload],
    };
  });

  CampaignReducer.on(campaignActions.modifyRetainCampaignActions.success, (state, payload) => {
    return {
      ...state,
      retention: updateItemInArray(state.retention, 'CampaignId', payload),
    };
  });

  CampaignReducer.on(campaignActions.removeRetainCampaignActions.success, (state, payload) => {
    return {
      ...state,
      retention: state.retention.filter((f) => f.CampaignId !== payload),
    };
  });

  CampaignReducer.on(campaignSignalActions.loyaltyCampaignCreated, (state, payload) => {
    return {
      ...state,
      loyalty: payload.LoyaltyCampaign
        ? [...state.loyalty, payload.LoyaltyCampaign]
        : state.loyalty,
    };
  });

  CampaignReducer.on(campaignSignalActions.loyaltyCampaignUpdated, (state, payload) => {
    return {
      ...state,
      loyalty: payload.LoyaltyCampaign
        ? updateItemInArray(state.loyalty, 'CampaignId', payload.LoyaltyCampaign)
        : state.loyalty,
    };
  });

  CampaignReducer.on(campaignSignalActions.loyaltyCampaignDeleted, (state, payload) => {
    return {
      ...state,
      loyalty: state.loyalty.filter(
        ({ CampaignId }) => CampaignId !== payload.LoyaltyCampaign?.CampaignId
      ),
    };
  });

  CampaignReducer.on(campaignSignalActions.retentionCampaignCreated, (state, payload) => {
    return {
      ...state,
      retention: payload.RetentionCampaign
        ? [...state.loyalty, payload.RetentionCampaign]
        : state.retention,
    };
  });

  CampaignReducer.on(campaignSignalActions.retentionCampaignUpdated, (state, payload) => {
    return {
      ...state,
      retention: payload.RetentionCampaign
        ? updateItemInArray(state.retention, 'CampaignId', payload.RetentionCampaign)
        : state.retention,
    };
  });

  CampaignReducer.on(campaignSignalActions.retentionCampaignDeleted, (state, payload) => {
    return {
      ...state,
      retention: state.loyalty.filter(
        ({ CampaignId }) => CampaignId !== payload.RetentionCampaign?.CampaignId
      ),
    };
  });

  return CampaignReducer;
};

export default ReducerCreator();
