import React from 'react';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { compose, lifecycle, setDisplayName } from 'recompose';

import { resetState, setSelected, storeActions } from '../../actions/store.actions';
import * as storeGroupActions from '../../actions/storegroup.actions';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { StoreEvents } from '../../signalr/hub.actions';
import StoreDetails from './Store.routes';
import Stores from './Stores';
import { StoresRouteParams } from './types';

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: StoresRouteParams) => {
  const storeGroupId = Number(ownProps.match.params.storeGroupId);
  return (dispatch: ThunkDispatch, ownProps) => {
    return {
      setSelection: () => {
        dispatch(setSelected({ storeGroupId, storeId: 0 }));
      },
      initLoad: () => {
        dispatch((dispatch, getState) => {
          const state = getState();
          const { storeGroupId } = state.stores;

          const hasStoreGroup = !!state.orm.FlipdishStoreGroup.itemsById[storeGroupId as number];
          if (!hasStoreGroup) {
            const appId = state.currentApp.AppId as string;
            dispatch(storeGroupActions.loadById(appId, storeGroupId as number));
          }

          dispatch(storeActions.loadAll(1, 20));
        });
      },
      resetStoreState: () => {
        dispatch(resetState());
      },
      subscribe: () => {
        dispatch(StoreEvents.Subscribe.Created);
        dispatch(StoreEvents.Subscribe.Updated);
        dispatch(StoreEvents.Subscribe.Deleted);
        dispatch(StoreEvents.Subscribe.AddressUpdate);
      },
      unsubscribe: () => {
        dispatch(StoreEvents.Unsubscribe.Created);
        dispatch(StoreEvents.Unsubscribe.Updated);
        dispatch(StoreEvents.Unsubscribe.Deleted);
        dispatch(StoreEvents.Unsubscribe.AddressUpdate);
      },
    };
  };
};

type Props = StoresRouteParams;
const StoresRoutes = compose<Props & MappedDispatch, StoresRouteParams>(
  setDisplayName('StoresRoutes'),
  connect(null, mapDispatchToPropsFactory),
  lifecycle<MappedDispatch & StoresRouteParams, {}>({
    componentWillMount() {
      this.props.setSelection();
    },
    componentDidMount() {
      this.props.initLoad();
      this.props.subscribe();
    },
    componentWillUnmount() {
      this.props.resetStoreState();
      this.props.unsubscribe();
    },
  })
)(({ match }) => {
  return (
    <ErrorBoundary identifier="stores">
      <Switch>
        <Route exact path={`${match.path}`} component={Stores} />
        <Route
          path={`${match.path}/:storeId`}
          //@ts-ignore
          render={(props) => <StoreDetails {...props} key={props.match.params['storeId']} />}
        />
      </Switch>
    </ErrorBoundary>
  );
});

export default StoresRoutes;
