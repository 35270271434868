import React, { useEffect, useMemo, useState } from 'react';

import isArray from 'lodash/isArray';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import useDebouncedFn from '../../../../custom-hooks/useDebounce';
import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../../WithRouteSearchParams';
import { VoucherFilterProps, VoucherProps } from '../../types';
import Filter from './Filter';

type Props = MappedState & WithRouteSearchParamsProps<string>;

type FilterProps = {
  onChange: (vouchers: VoucherFilterProps[]) => void;
  onSearch: (search) => void;
  voucherSearchCodeResults: VoucherProps[];
};

const VoucherFilter = (props: Props & FilterProps) => {
  const {
    translate,
    onChange, // set params
    onSearch, // search vouchers
    voucherSearchCodeResults,
    setSearch,
  } = props;

  const [query, setQuery] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const option = suggestions.find((s) => s.value.toString() === props.search);
    if (option) {
      setQuery(option.toString());
    }
  }, []);

  useEffect(() => {
    useDebouncedFn(getVoucherCodes, 400);
  }, [query]);

  const getSelectedOptions = () => {
    if (props.search) {
      if (isArray(props.search)) {
        return props.search.map((voucher) => {
          return { label: voucher, value: null };
        });
      } else {
        return [{ label: props.search, value: null }];
      }
    }
    return undefined;
  };

  const getVoucherCodes = async () => {
    try {
      if (query) {
        onSearch(query);
        setLoading(false);
      }
    } catch (err) {
      console.log(new Error(err));
    }
  };

  const handleSearch = (value: string) => {
    setQuery(value);
    setLoading(!!value);
  };

  const setUrlParams = (params) => {
    const voucherParams = params.map((v) => v.label);
    setSearch(voucherParams);
  };

  const suggestions = useMemo(
    () =>
      voucherSearchCodeResults.map((result) => {
        return {
          value: result.voucherId,
          label: result.voucherCode,
        };
      }),
    [voucherSearchCodeResults]
  );

  return (
    <ErrorBoundary identifier="voucher-filter">
      <Filter
        label={translate('Vouchers') as string}
        options={suggestions}
        isLoading={isLoading}
        placeholder={translate('Type_code') as string}
        onInputChange={handleSearch}
        onChange={onChange}
        selectedValue={getSelectedOptions()}
        setUrlParams={setUrlParams}
      />
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  return {
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = compose<Props, FilterProps>(
  withRouteSearchParams({
    name: 'voucher',
  }),
  connect(mapStateToProps)
)(VoucherFilter);

export default EnhancedComponent;
