import React from 'react';

import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';
import { useInView } from 'react-intersection-observer';

import StoreCard, { IStoreDetailsCardProps } from './StoreDetailsCard';

const useStyles = makeStyles({
  item: {
    padding: 0,
    marginBottom: 24,
  },
});

const StoreCardListItem = (props: IStoreDetailsCardProps) => {
  const classes = useStyles();
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <div ref={ref}>
      <ListItem disableGutters className={classes.item}>
        <StoreCard {...props} inView={inView} />
      </ListItem>
    </div>
  );
};

export default StoreCardListItem;
