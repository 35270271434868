import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Box } from '@fd/ui/atoms/Box';
import { Typography } from '@fd/ui/atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  boxContents: {
    marginTop: theme.spacing(3),
    height: '236px',
  },
  noModifiersFoundTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));

export type NoMatchesFoundMessageProps = {
  message?: TranslationId;
};

export const NoMatchesFoundMessage = ({ message }: NoMatchesFoundMessageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box centeredContent className={classes.boxContents}>
      <Typography className={classes.noModifiersFoundTitle}>
        <Translate id="Modifier_NoModifiersFoundTitle" />
      </Typography>
      {message && (
        <Typography variant="caption">
          <Translate id={message} />
        </Typography>
      )}
    </Box>
  );
};
