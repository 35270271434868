import * as React from 'react';

import Info from '@mui/icons-material/InfoOutlined';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { compose } from 'recompose';

import Tooltip from '@fd/ui/Tooltip/Tooltip';

const styles = () =>
  createStyles({
    infoSmall: {
      width: '30px',
      height: '19px',
      color: 'rgba(0, 0, 0, 0.54);',
      overflow: 'visible',
      alignItems: 'inherit',
    },
    infoBig: {
      alignItems: 'center',
      width: '24px',
      height: '24px',
      color: 'rgba(0, 0, 0, 0.54);',
    },
  });

type Props = WithStyles<typeof styles> & InfoButtonprops;
export interface InfoButtonprops {
  size: string;
  id: string;
}

const InfoButton = compose<Props, InfoButtonprops>(
  withStyles(styles, {
    name: 'InfoButton',
    withTheme: true,
  })
)(({ id, classes, size }) => {
  const className = clsx({
    [classes.infoBig]: size === 'infoBig',
    [classes.infoSmall]: size === 'infoSmall',
  });

  return (
    <Tooltip fdKey="savings-info-button" messageId="calculated_by_comparing_comission" id={id}>
      <Info className={className} />
    </Tooltip>
  );
});

export default InfoButton;
