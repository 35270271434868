export function loading(state = {}, action) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|RESET)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  const errorName = requestName + '_ERROR';
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
    [errorName]: requestState === 'FAILURE' ? action.error : undefined,
  };
}
