import React from 'react';

import { AppConfigSalesChannel } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { EnableSwitch, Typography } from '@fd/ui/atoms';
import { FormSection } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7),
    },
    marginBottom: theme.spacing(5),
  },
  textContainer: {
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  value?: boolean;
  onChange: (updatedSetting: Partial<AppConfigSalesChannel>) => void;
  translate: TranslateFunction;
};

export const ShowRestaurantListForDelivery = ({
  isDisabled,
  isLoading,
  onChange,
  translate,
  value,
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ DisplayDeliveryRestaurantListScreen: event.target.checked });
  };

  return (
    <FormSection
      sectionTitle={translate('Show_restaurants_list_for_delivery') as string}
      showDivider={false}
      isLoading={isLoading}
    >
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variantMapping={{ caption: 'p' }} variant="caption">
            <Translate id="This_shows_all_stores_available_for_delivery" />
          </Typography>
        </div>
        <EnableSwitch
          fdKey="show_restaurant_list_for_delivery_toggle"
          onChange={handleChange}
          defaultChecked={value}
          disabled={isDisabled}
          inputProps={{
            'aria-label': translate('Show_restaurants_list_for_delivery') as string,
          }}
        />
      </div>
    </FormSection>
  );
};
