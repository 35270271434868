import React from 'react';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import EntitlementsOverview from './components/EntitlementsOverview';

type Props = RouteComponentProps;

const EntitlementsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="entitlements">
      <Switch>
        <Route exact path={`${path}`} component={EntitlementsOverview} />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(EntitlementsRoutes);
