import { CatalogGroupsApiFactory, CreateGroup, UpdateGroup } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const catalogGroupsApi = createApi(CatalogGroupsApiFactory);

const baseURL = getApiEndPoint();
const newCatalogGroupsApis = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const getModifierGroupsQueryKey = 'getModifierGroups';
const getModifiersGroupForProductQueryKey = 'getModifiersGroupForProduct';
async function getCatalogGroups(
  appId: string,
  page: number,
  limit: number,
  searchTerm: string,
  types
) {
  try {
    const result = await catalogGroupsApi.getGroups(appId, types, searchTerm, page, limit);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getModifierGroupByIdQueryKey = 'getModifierGroupById';
async function getCatalogGroupById(appId: string, id: string) {
  try {
    const result = await newCatalogGroupsApis.get(`${appId}/catalog/groups/${id}`);
    return result.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function createCatalogGroup(appId: string, newGroup: CreateGroup) {
  try {
    const result = await catalogGroupsApi.createGroup(appId, newGroup);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateCatalogGroup(appId: string, id: string, group: UpdateGroup) {
  try {
    const result = await catalogGroupsApi.updateGroup(appId, id, group);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function duplicateCatalogGroup(appId: string, groupId: string) {
  try {
    const result = await catalogGroupsApi.duplicateGroup(appId, groupId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function archiveCatalogGroup(appId: string, groupId: string) {
  try {
    const result = await catalogGroupsApi.archiveGroup(appId, groupId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const catalogGroupsService = {
  archiveCatalogGroup,
  createCatalogGroup,
  duplicateCatalogGroup,
  getModifierGroupsQueryKey,
  getModifierGroupByIdQueryKey,
  getModifiersGroupForProductQueryKey,
  getCatalogGroups,
  getCatalogGroupById,
  updateCatalogGroup,
};
