import React, { useEffect, useMemo, useState } from 'react';

import { Store } from '@flipdish/api-client-typescript';
import { Field, FieldProps } from 'formik';
import { debounce, find } from 'lodash';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Select from '@fd/ui/Select/Select';

import { useInfiniteQueryStoreFilterHook } from '../../../../custom-hooks/useInfiniteQuerySoreFilter';
import { formikValidate } from '../../../../helpers/validation';
import { loadByAppId } from '../../../../services/store.service';
import FormItemLayout from '../FormItemLayout';

const validateRequired = (value: number[]) => {
  if (!value || value.length === 0) {
    return formikValidate.required(value);
  }
};

type Props = {
  isDisabled?: boolean;
  selectedMenuId?: number;
};

export type StoreOption = {
  label: string;
  value: number;
  isDisabled?: boolean;
  isGroup?: boolean;
  valueEndAdornment?: Store.CurrencyEnum;
};

const MultiSelect = (fprops: FieldProps & MapStateToProps & Props) => {
  const { appId, field, form, isDisabled = false, selectedMenuId } = fprops;
  const { errors, touched } = form;
  const fieldError = errors[field.name] as string | undefined;
  const showError =
    !!fieldError && form.values.Stores.length === 0 && (touched[field.name] as boolean | undefined);

  const [isLoadingStores, setIsLoadingStores] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [storeOptions, setStoreOptions] = useState<StoreOption[]>([]);
  const [selectedStores, setSelectedStores] = useState<StoreOption[]>();

  const fetchDataProps = {
    appId,
    query: searchQuery,
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
    useInfiniteQueryStoreFilterHook(fetchDataProps, loadByAppId, 'loadByAppId');

  const parseStoreData = (storeData: Store[]) => {
    return storeData
      .filter((x) => {
        if (!selectedMenuId) {
          return true;
        }
        return x.MenuId === selectedMenuId;
      })
      .map((store) => ({
        label: store.Name as string,
        value: store.StoreId as number,
        valueEndAdornment: store.Currency,
      }));
  };

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
    if (data && data.pages) {
      const addNewStoreOptions = data.pages.reduce<StoreOption[]>((total, page) => {
        if (page && page.Data) {
          parseStoreData(page.Data).forEach((store) => {
            const isSameCurrencyOrDefault = form.values.Currency
              ? store.valueEndAdornment === form.values.Currency
              : true;
            if (isSameCurrencyOrDefault) {
              total.push(store);
            }
          });
        }
        return total;
      }, []);
      setIsLoadingStores(isPending);
      setStoreOptions(addNewStoreOptions);
    }
  }, [data?.pages, selectedStores, selectedMenuId]);

  const values = useMemo(() => {
    if (!field.value) {
      return [];
    }
    const storeHeaders = data?.pages.reduce(
      (agg, stores) => {
        if (stores && stores.Data) {
          parseStoreData(stores.Data).forEach((store) => {
            agg.push({
              value: store.value as number,
              label: store.label as string,
              valueEndAdornment: store.valueEndAdornment as Store.CurrencyEnum,
            });
          });
        }
        return agg;
      },
      [] as Array<{ value: number; label: string; valueEndAdornment: Store.CurrencyEnum }>
    );

    return field.value.map((ss) => find(storeHeaders, { value: ss }));
  }, [field.value, data]);

  const handleStoresChange = (values: StoreOption[]) => {
    const storeIds = values.map((storeOption) => storeOption.value);
    form.setFieldValue('Stores', storeIds);
    setSelectedStores(values);
    // set currency for selected selected store
    if (storeIds.length) {
      form.setFieldValue('Currency', values[0]?.valueEndAdornment);
    } else {
      form.setFieldValue('Currency', '');
    }
  };

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
      }, 500),
    []
  );

  return (
    <Select
      dataFd="stores-select-Stores"
      fieldError={showError ? fieldError : undefined}
      isClearable
      isDisabled={form.isSubmitting || isDisabled}
      isLoading={isPending || isLoadingStores || isFetchingNextPage}
      isMulti
      onChange={handleStoresChange}
      onFocus={() => setSearchQuery('')}
      options={storeOptions}
      setSearchQuery={onSearchQueryChange}
      TextFieldProps={{
        fdKey: 'stores-dropdown',
        name: 'selected-stores',
      }}
      value={values}
    />
  );
};
const MultiSelectConnected = connect<MapStateToProps>(mapStateToProps)(MultiSelect);

const StoresSelectField = (ownProps: Props) => {
  return (
    <FormItemLayout label={<Translate id="Stores" />}>
      <Field name={'Stores'} validate={validateRequired}>
        {(fieldProps: FieldProps) => {
          return <MultiSelectConnected {...fieldProps} {...ownProps} />;
        }}
      </Field>
    </FormItemLayout>
  );
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    appId: state.currentApp.AppId!,
  };
}
export default StoresSelectField;
