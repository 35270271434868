import React from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import PrintOutlined from '@mui/icons-material/PrintOutlined';
import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import Kiosk from '../../../assets/images/ico_kiosk.svg';
import Terminal from '../../../assets/images/ico_terminal.svg';

const useStyles = makeStyles({
  online: {
    backgroundColor: '#86e8bb',
  },
  offline: { backgroundColor: '#fb7c92' },
  possiblyOffline: { backgroundColor: 'orange' }, // TBC
});

type Props = {
  deviceType: HydraDeviceDetails.DeviceTypeEnum | undefined;
  deviceStatus: HydraDeviceDetails.StatusEnum | undefined;
};

const deviceIcon = (type) => {
  switch (type) {
    case 0:
    case HydraDeviceDetails.DeviceTypeEnum.Kiosk:
      return <img src={Kiosk} style={{ width: '24px' }} />;
    case 1:
    case HydraDeviceDetails.DeviceTypeEnum.Terminal:
      return <img src={Terminal} style={{ width: '24px' }} />;
    case 2:
    case HydraDeviceDetails.DeviceTypeEnum.LegacyPrinter:
      return <PrintOutlined />;
    default:
      return null;
  }
};

const ListItemIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { deviceType, deviceStatus } = props;
  const classes = useStyles();

  const className = clsx({
    [classes.online]: deviceStatus === HydraDeviceDetails.StatusEnum.Online,
    [classes.offline]: deviceStatus === HydraDeviceDetails.StatusEnum.Offline,
    [classes.possiblyOffline]: deviceStatus === HydraDeviceDetails.StatusEnum.PossiblyOffline,
  });

  return <Avatar className={className}>{deviceIcon(deviceType)}</Avatar>;
};

export default ListItemIcon;
