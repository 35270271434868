// #region TEMPORARY FIX, REPLACING MOMENT-TIMEZONE W DATE-FNS-TZ
import * as date_fns from 'date-fns';
import { bg, de, enGB, enUS, es, fi, fr, it, nl, pl, pt, tr, zhCN } from 'date-fns/locale';
import { format as formatTz, OptionsWithTZ, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';
import { createSelector } from 'reselect';

import { getIanaTimeZoneId } from '../helpers/timeZones';

const { format, parseISO, ...dateFns } = date_fns;

const locales = {
  bg,
  de,
  en: enGB,
  'en-gb': enGB,
  'en-ie': enGB,
  'en-us': enUS,
  es,
  'es-MX': es, // no support for Spanish Mexican in date-fns
  fi,
  fr,
  it,
  nl,
  pl,
  pt,
  tr,
  zh: zhCN,
};

export const getDateTimeLocale = createSelector(
  [(state) => state.locale.activeLanguage],
  (locale: string) => {
    const localeKey = locale.toLowerCase();
    return locales[localeKey];
  }
);

function getSystemTimeZone() {
  let systemIanaTimeZone: string | undefined;
  try {
    systemIanaTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {}
  const systemOffset = format(new Date(), 'x');
  return { systemIanaTimeZone, systemOffset };
}
function getUserTimeZone(userTimeZoneId: string) {
  const userIanaTimeZone: string | undefined = getIanaTimeZoneId(userTimeZoneId)!;
  let userOffset: string | undefined;
  if (userIanaTimeZone) {
    userOffset = formatTz(new Date(), 'x', { timeZone: userIanaTimeZone });
  }
  return {
    userIanaTimeZone,
    userOffset,
  };
}
export type DateTimeUtils = ReturnType<typeof dateTimeUtilsCombiner>;
const dateTimeUtilsCombiner = (
  locale: date_fns.Locale,
  displayTimesInUserLocalTimeZone: boolean,
  userTimeZoneId: string
) => {
  const { systemOffset, systemIanaTimeZone } = getSystemTimeZone();
  const { userOffset, userIanaTimeZone } = getUserTimeZone(userTimeZoneId);

  const formatWithLocale = (
    date: string | number | Date,
    formatStr = 'P p',
    props: OptionsWithTZ = {}
  ) => {
    switch (typeof date) {
      case 'string':
        return formatTz(parseISO(date as string), formatStr, {
          locale,
          ...props,
        });
      default:
        return formatTz(date, formatStr, {
          locale,
          ...props,
        });
    }
  };
  const formatRelativeWithLocale = (date: number | Date, baseDate: number | Date, options?: {}) => {
    return dateFns.formatRelative(date, baseDate, {
      locale,
      ...(options || {}),
    });
  };

  const formatDistanceToNowWithLocale = (
    date: number | Date,
    options?: { includeSeconds?: boolean; addSuffix?: boolean; locale?: Locale }
  ) => {
    switch (typeof date) {
      case 'string':
        return dateFns.formatDistanceToNow(parseISO(date as string), {
          locale,
          ...(options || {}),
        });
      default:
        return dateFns.formatDistanceToNow(date, {
          locale,
          ...(options || {}),
        });
    }
  };

  const isAmPm = /AM|PM/i.test(formatWithLocale(new Date(), 'p'));

  return {
    format: formatWithLocale,

    ...dateFns,
    formatRelative: formatRelativeWithLocale as typeof dateFns.formatRelative,
    formatDistanceToNow: formatDistanceToNowWithLocale,

    utcToZonedTime,
    zonedTimeToUtc,

    LOCAL_TIME_FORMAT: 'p',
    LOCAL_DATE_FORMAT: 'P',
    LOCAL_DATE_TIME_FORMAT: 'P p',

    systemOffset,
    systemIanaTimeZone,

    displayTimesInUserLocalTimeZone,
    userIanaTimeZone,
    userOffset,

    isAmPm,
  };
};
export const dateTimeUtils = createSelector(
  [
    getDateTimeLocale,
    (state) => !!state.account.DisplayTimesInUserLocalTimeZone,
    (state) => state.account.TimeZoneInfoId as string,
  ],
  dateTimeUtilsCombiner
);
export { dateTimeUtils as getDateTimeFormatter2 };

if (process.env.NODE_ENV === 'development') {
  (global as any).__dtUtils__ = dateTimeUtilsCombiner(
    enGB,
    false,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
}

type CalendarMonthMatrixProps = {
  year: number;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
};
export const getCalendarMonthMatrix = createSelector(
  [(state) => getDateTimeLocale(state), (_, p) => p.month, (_, p) => p.year],
  (locale, month, year) => {
    const date = new Date(year, month);

    const matrix = date_fns.eachWeekOfInterval(
      {
        start: date_fns.startOfMonth(date),
        end: date_fns.endOfMonth(date),
      },
      { weekStartsOn: 1, locale }
    );

    return matrix.map((weekDay) =>
      date_fns.eachDayOfInterval({
        start: date_fns.startOfISOWeek(weekDay),
        end: date_fns.endOfISOWeek(weekDay),
      })
    );
  }
);
// #endregion

//#region MOMENT
export interface IDateTimeFormatter {
  time(time: string, pattern?: string): moment.Moment;
  time(time: moment.Moment, pattern?: string): string;
  timeLocal(time: string, pattern?: string): moment.Moment;
  timeLocal(time: moment.Moment, pattern?: string): string;
  timeShortLocal(time: moment.Moment): string;
  timePeriod(timeA: moment.Moment, timeB: moment.Moment, pattern?): string;
  timePeriodBetweenLocal(timeA: moment.Moment, timeB: moment.Moment): string;
  timePeriodBetweenShortLocal(timeA: moment.Moment, timeB: moment.Moment): string;
}

export const getDateTimeFormatter = createSelector(
  [(state) => state.localeDateTime],
  (localeDateTime) => {
    const localTimeFormat = moment.localeData().longDateFormat('LT');
    const localTimeShortFormat = localTimeFormat.replace(/ /, ''); // remove space 'hh:mm A'=>'hh:mmA'

    const timeShortLocal = createTimeShortLocal(localTimeShortFormat);
    const timePeriodBetweenLocal = createTimePeriodBetweenLocal(timeLocal);
    const timePeriodBetweenShortLocal = createTimePeriodBetweenLocal(timeShortLocal);

    return {
      time,
      timeLocal,
      timeShortLocal,
      timePeriod,
      timePeriodBetweenLocal,
      timePeriodBetweenShortLocal,
    };
  }
);

function time(mTime: string, pattern?: string): moment.Moment;
function time(mTime: moment.Moment, pattern?: string): string;
function time(mTime: moment.Moment | string, pattern = 'HH:mm:ss'): moment.Moment | string {
  if (typeof mTime === 'string') {
    return moment(mTime, pattern, true);
  }
  return mTime.format(pattern);
}

function timeLocal(time: string, pattern?: string): moment.Moment;
function timeLocal(time: moment.Moment, pattern?: string): string;
function timeLocal(mTime: moment.Moment | string, pattern = 'LT') {
  if (typeof mTime === 'string') {
    const time = moment(mTime, [pattern, 'h:ma', 'h.ma', 'ha', 'H:m', 'H.m', 'H', 'HH:mm'], true);
    return time;
  }
  return mTime.format(pattern);
}

function createTimeShortLocal(format: string) {
  return function timeShortLocal(mTime: moment.Moment): string {
    return time(mTime, format) as string;
  };
}
function timePeriod(mTimeA: moment.Moment, mTimeB: moment.Moment, pattern = 'HH:mm:ss'): string {
  if (mTimeB.isBefore(mTimeA) || mTimeB.isSame(mTimeA)) {
    mTimeB.add(1, 'day');
  }
  return (moment.duration(mTimeB.diff(mTimeA)) as any).format(pattern, { trim: false });
}
function createTimePeriodBetweenLocal(formatter) {
  return function timePeriodBetweenLocal(startTime: moment.Moment, period: moment.Moment): string {
    return `${formatter(startTime)}-${formatter(
      startTime.add(period.hours(), 'hours').add(period.minutes(), 'minutes')
    )}`;
  };
}
//#endregion
