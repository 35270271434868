import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import Spacer from '../../../ui/Spacer';
import DetailsLoadingMobile from './DetailsLoading.mobile';
import Icon from './Icon';
import InfoButton from './InfoButton';

export interface ISavingDetailsProps {
  savings: string[];
}

const SavingsDetailsMobile: React.FC<React.PropsWithChildren<ISavingDetailsProps>> = (props) => {
  const { savings } = props;

  if (savings.length === 0) {
    return <DetailsLoadingMobile />;
  }

  return (
    <Spacer size={16} variant="horizontal">
      <Grid container className={HORIZONTAL_SPACE_CLASSNAME}>
        <Grid item xs={9}>
          <Typography
            variant="h4"
            display="inline"
            component="span"
            aria-describedby="savings-info-button"
          >
            {savings}
            <InfoButton size={'infoSmall'} id="savings-info-button" />
          </Typography>

          <Typography variant="h5" component="span">
            <Translate id="saved_since_partnering" />
          </Typography>
        </Grid>

        <Grid item xs={3} container direction="row-reverse">
          <Icon />
        </Grid>
      </Grid>
    </Spacer>
  );
};

export default SavingsDetailsMobile;
