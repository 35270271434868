import React from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import ListItemLink from '../../../ui/List/ListItemLink';
import ListItemIcon from '../../Devices/Components/DeviceListItemIcon';
import KioskListDetails from './KioskListDetails';

const useStyles = makeStyles({
  icon: {
    minWidth: 0,
  },
});

type KioskListItemProps = {
  kiosk: HydraDeviceDetails;
  kioskId: string | undefined;
  isLast: boolean;
};

type Props = KioskListItemProps & MappedProps;
const KioskListItem = ({ currentAppId, isLast, kioskId, kiosk }: Props) => {
  const classes = useStyles();

  if (!kiosk) {
    return null;
  }

  return (
    <ListItemLink
      divider={!isLast}
      button
      key={kioskId}
      fdKey={`${kioskId}`}
      to={`/${currentAppId}/sales-channels/kiosks/${kioskId}`}
      className={HORIZONTAL_SPACE_CLASSNAME}
      alignItems="center"
    >
      <ListItemAvatar>
        <ListItemIcon deviceStatus={kiosk.Status} deviceType={kiosk.DeviceType} />
      </ListItemAvatar>

      <KioskListDetails kiosk={kiosk} />
      <ListIcon className={classes.icon}>
        <KeyboardArrowRight color="action" />
      </ListIcon>
    </ListItemLink>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentAppId: state.currentApp.AppId,
});

export default connect(mapStateToProps)(KioskListItem);
