import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '../../../ui/atoms/Box';
import { GridContainer } from '../../../ui/atoms/GridContainer';
import { SkeletonLoader } from '../../../ui/atoms/SkeletonLoader';

const useStyles = makeStyles((theme: Theme) => ({
  fullLine: {
    marginTop: '8px',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type OrderFulfillmentStatusListLoadingSkeletonTypes = {
  width?: 12 | 6;
};

export const OrderFulfillmentStatusListLoadingSkeleton = ({
  width,
}: OrderFulfillmentStatusListLoadingSkeletonTypes): JSX.Element => {
  const classes = useStyles();

  const renderLoadingSkeleton = (): JSX.Element[] => {
    return Array.from(Array(6)).map((_, i) => (
      <Grid
        key={`skeleton-card-${i}`}
        item
        xs={12}
        sm={12}
        md={width || 6}
        className={classes.gridItem}
      >
        <Box>
          <SkeletonLoader
            fdKey={`skeleton-card-${i}`}
            rows={[
              { height: '34px', width: '100%' },
              { height: '34px', width: '100%', className: classes.fullLine },
            ]}
          />
        </Box>
      </Grid>
    ));
  };
  return <GridContainer width="md">{renderLoadingSkeleton()}</GridContainer>;
};
