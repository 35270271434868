import React, { useState } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import PageLayout from '@fd/ui/Layout';

import { TextSearchField } from '../../../../../ui/atoms/TextSearchField';
import { AppStoreAppsListLoadingSkeleton } from '../../../components/AppStoreAppsListLoadingSkeleton';
import { AppStoreAppsListNoResults } from '../../../components/AppStoreAppsListNoResults';
import { appStoreService } from '../../../services/appstore.service';
import { AppStoreAppsList } from '../../components/AppStoreAppsList';
import CreateAppStoreAppLink from '../../components/CreateAppStoreAppLink';

type AppStoreAppProps = {};

type Props = AppStoreAppProps & MappedState;

const DEFAULT_LIMIT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  searchField: {
    marginBottom: theme.spacing(3),
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const AppStoreApps = ({ appId, translate }: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, isPending } = useQuery({
    queryKey: [appStoreService.getDeveloperAppStoreAppsKey, searchTerm, page, DEFAULT_LIMIT],
    queryFn: () => appStoreService.getDeveloperAppStoreApps(searchTerm, page, DEFAULT_LIMIT),
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearchTerm = (value: string) => {
    setSearchTerm(value);
  };

  const renderContent = () => {
    if (isPending) {
      return <AppStoreAppsListLoadingSkeleton />;
    }
    if (data && data.Data) {
      if (data.Data.length === 0) {
        return <AppStoreAppsListNoResults />;
      }

      return (
        <AppStoreAppsList
          limit={data.Limit}
          onPageChange={handlePageChange}
          page={page}
          appStoreApps={data.Data}
          total={data.TotalRecordCount}
          appId={appId}
        />
      );
    }
    return <></>;
  };

  return (
    <PageLayout
      toParent={`/${appId}/developers`}
      documentTitle="App_store_apps"
      title={translate('App_store_apps')}
    >
      <Permissions allowed={['Owner']}>
        <TextSearchField
          className={classes.searchField}
          clearAriaLabel={`${translate('Search_for_App_Name')}`}
          dataFd="input-search"
          disableLabelAnimation={true}
          onChange={handleSearchTerm}
          placeholder={`${translate('Search_for_App_Name')}`}
          variant="outlined"
        />
        {renderContent()}
      </Permissions>
      <CreateAppStoreAppLink />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps)(AppStoreApps);
