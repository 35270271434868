export const orderEventConstants = {
  CREATED: 'OrderCreated',
  ACCEPTED: 'OrderAccepted',
  RATING_UPDATED: 'OrderRatingUpdated',
  REFUNDED: 'OrderRefunded',
  REJECTED: 'OrderRejected',
  TIP_UPDATED: 'OrderTipUpdated',
  DELIVERY_TRACKING_STATUS_UPDATED: 'OrderDeliveryTrackingStatusUpdated',
};

export const accountEventConstants = {
  UPDATED: 'AccountUpdated',
};

export const customerEventConstants = {
  CREATED: 'CustomerCreated',
  UPDATED: 'CustomerUpdated',
  CONSENT_UPDATED: 'CustomerConsentUpdated',
};

export const analyticsEventConstants = {
  CLIENT: 'AnalyticsClient',
};

export const bankEventConstants = {
  CREATED: 'BankAccountCreated',
  UPDATED: 'BankAccountUpdated',
  DELETED: 'BankAccountDeleted',
};

export const storeEventConstants = {
  CREATED: 'StoreCreated',
  UPDATED: 'StoreUpdated',
  DELETED: 'StoreDeleted',
  ADDRESS_UPDATED: 'StoreAddressUpdated',
  DELIVERY_ZONE_CREATED: 'DeliveryZoneCreated',
  DELIVERY_ZONE_UPDATED: 'DeliveryZoneUpdated',
  DELIVERY_ZONE_DELETED: 'DeliveryZoneDeleted',
  OPENING_HOURS_UPDATED: 'StoreOpeningHoursUpdated',
  BUSINESS_HOURS_OVERRIDES_CREATED: 'StoreBusinessHoursOverrideCreated',
  BUSINESS_HOURS_OVERRIDES_DELETED: 'StoreBusinessHoursOverrideDeleted',
};

export const storeGroupEventConstants = {
  CREATED: 'StoreGroupCreated',
  UPDATED: 'StoreGroupUpdated',
  DELETED: 'StoreGroupDeleted',
};

export const teammateEventConstants = {
  INVITE_SENT: 'TeammateInviteSent',
  INVITE_ACCEPTED: 'TeammateInviteAccepted',
  UPDATED: 'TeammateUpdated',
  DELETED: 'TeammateDeleted',
};

export const phoneCallEventConstants = {
  STARTED: 'PhoneCallStarted',
  ENDED: 'PhoneCallEnded',
};

export const voucherEventConstants = {
  CREATED: 'VoucherCreated',
  UPDATED: 'VoucherUpdated',
  DELETED: 'VoucherDeleted',
};

export const loyaltyCampaignConstants = {
  CREATED: 'LoyaltyCampaignCreated',
  UPDATED: 'LoyaltyCampaignUpdated',
  DELETED: 'LoyaltyCampaignDeleted',
};

export const retentionCampaignConstants = {
  CREATED: 'RetentionCampaignCreated',
  UPDATED: 'RetentionCampaignUpdated',
  DELETED: 'RetentionCampaignDeleted',
};

export const kioskCardReaderConstants = {
  UPDATED: 'KioskBluetoothTerminalUpdated',
  UNPAIRED: 'KioskBluetoothTerminalUnpaired',
};
