import { sumSummaryValue } from '../helpers';
import { PayoutStore } from '../types';

/**
 * Returns formatted order, refund, chargebacks & other charges summary values mapped to Translation Ids, width & mobile order.
 *
 * @param {PayoutStore} data Payout Stores in payoutDetailSummary from Backend
 * @param {number[] | number} storeIdsFilter store ids to filter data
 * @return {sectionItems[], showSection} Translation Ids, width & mobile order mapped to sectionItems. To render section is based on boolean showSection
 */
export const formatOrderSummary = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const result = {
    sectionItems: [
      {
        value: sumSummaryValue(
          data,
          'Revenue',
          'OnlineSalesFeesBaseAmount',
          storeIdsFilter
        ) as number,
        translationId: 'Online_revenue_excl_tips' as TranslationId,
        width: '33.33%',
        mobileOrder: 1,
      },
      {
        value: sumSummaryValue(
          data,
          'Revenue',
          'CashSalesFeesBaseAmount',
          storeIdsFilter
        ) as number,
        translationId: 'Cash_sales' as TranslationId,
        width: '33.33%',
        mobileOrder: 3,
      },
      {
        value: (sumSummaryValue(data, 'Revenue', 'OnlineSalesFeesBaseAmount', storeIdsFilter) +
          sumSummaryValue(data, 'Revenue', 'CashSalesFeesBaseAmount', storeIdsFilter)) as number,
        translationId: 'Total_sales' as TranslationId,
        width: '33.33%',
        mobileOrder: 5,
      },
      {
        value: (sumSummaryValue(data, 'FlipdishFees', 'OnlineSalesFees', storeIdsFilter) +
          sumSummaryValue(data, 'ThirdPartyFees', 'TotalThirdPartyFees', storeIdsFilter)) as number,
        translationId: 'Online_sales_fees' as TranslationId,
        width: '33.33%',
        mobileOrder: 2,
      },
      {
        value: sumSummaryValue(data, 'FlipdishFees', 'CashSalesFees', storeIdsFilter) as number,
        translationId: 'Cash_sales_fees' as TranslationId,
        width: '33.33%',
        mobileOrder: 4,
      },
      {
        value: (sumSummaryValue(data, 'FlipdishFees', 'TotalSalesFees', storeIdsFilter) +
          sumSummaryValue(data, 'ThirdPartyFees', 'TotalThirdPartyFees', storeIdsFilter)) as number,
        translationId: 'Total_fees' as TranslationId,
        width: '33.33%',
        mobileOrder: 6,
      },
    ],
    showSection:
      (sumSummaryValue(data, 'Revenue', 'TotalSalesIncludingTax', storeIdsFilter) as number) !==
        0 ||
      (sumSummaryValue(data, 'FlipdishFees', 'TotalSalesFees', storeIdsFilter) as number) !== 0,
  };
  return result;
};

export const formatRefundSummary = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const result = {
    sectionItems: [
      {
        value: sumSummaryValue(
          data,
          'RevenueAdjustments',
          'RefundsCount',
          storeIdsFilter
        ) as number,
        translationId: 'Number_of_refunds' as TranslationId,
        width: '33.33%',
        noCurrency: true,
      },
      {
        value: (sumSummaryValue(
          data,
          'RevenueAdjustments',
          'OnlineSalesRefundedAmount',
          storeIdsFilter
        ) +
          sumSummaryValue(
            data,
            'RevenueAdjustments',
            'CashSalesRefundedAmount',
            storeIdsFilter
          )) as number,
        translationId: 'Total_refunds_amount' as TranslationId,
        width: '33.33%',
      },
      {
        value: (sumSummaryValue(data, 'FlipdishFees', 'OnlineSalesRefundedFees', storeIdsFilter) +
          sumSummaryValue(data, 'FlipdishFees', 'CashSalesRefundedFees', storeIdsFilter)) as number,
        translationId: 'Refunded_fees' as TranslationId,
        width: '33.33%',
      },
    ],
    showSection:
      (sumSummaryValue(data, 'RevenueAdjustments', 'RefundsCount', storeIdsFilter) as number) !== 0,
  };
  return result;
};

export const formatChargebackSummary = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const result = {
    sectionItems: [
      {
        value: sumSummaryValue(data, 'Chargebacks', 'ChargebacksCount', storeIdsFilter) as number,
        translationId: 'Number_of_chargebacks' as TranslationId,
        width: '33.33%',
        noCurrency: true,
      },
      {
        value: sumSummaryValue(data, 'Chargebacks', 'ChargebackAmount', storeIdsFilter) as number,
        translationId: 'Chargeback_amount' as TranslationId,
        width: '33.33%',
      },
      {
        value: sumSummaryValue(
          data,
          'Chargebacks',
          'ChargebackRefundedFees',
          storeIdsFilter
        ) as number,
        translationId: 'Refunded_fees' as TranslationId,
        width: '33.33%',
      },
    ],
    showSection:
      (sumSummaryValue(data, 'Chargebacks', 'ChargebacksCount', storeIdsFilter) as number) !== 0,
  };
  return result;
};

export const formatOtherChargesSummary = (
  data: PayoutStore[],
  storeIdsFilter: number[] | number
) => {
  const result = {
    sectionItems: [
      {
        value: sumSummaryValue(data, 'OtherCharges', 'ChargesCount', storeIdsFilter) as number,
        translationId: 'Number_of_charges' as TranslationId,
        width: '50%',
        noCurrency: true,
      },
      {
        value: sumSummaryValue(data, 'OtherCharges', 'TotalOtherCharges', storeIdsFilter) as number,
        translationId: 'Total_charge_amount' as TranslationId,
        width: '50%',
      },
    ],
    showSection:
      (sumSummaryValue(data, 'OtherCharges', 'ChargesCount', storeIdsFilter) as number) !== 0,
  };
  return result;
};
