import { BankAccountSummary } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { AwaitingVerification, Unverified, Verified } from '../components/AccountStateIcons';

type BankAccounts = BankAccountSummary[] | undefined;
export const getAccounts = createSelector(
  [(state: AppState) => state.banking.accounts],
  (accounts) => accounts
);

export const getAccountDropdownOptions = createSelector([getAccounts], (accounts = []) => {
  return accounts.map((a) => ({
    label: a.AccountName,
    value: a.Id,
    Icon: getAccountStateIcon(a.AccountState!),
  }));
});

export const getAccountStateIcon = (accountState: BankAccountSummary.AccountStateEnum) => {
  switch (accountState) {
    case BankAccountSummary.AccountStateEnum.Verified:
    case BankAccountSummary.AccountStateEnum.Grandfathered:
      return Verified;
    case BankAccountSummary.AccountStateEnum.Unverified:
      return Unverified;
    case BankAccountSummary.AccountStateEnum.AwatingVerification:
      return AwaitingVerification;
    default:
      return null;
  }
};
