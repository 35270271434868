import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';
import init from './startup';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

init();

root.render(<App />);
