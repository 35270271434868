import React, { useEffect, useMemo, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../../layouts/Portal/ErrorBoundary';
import Select from '../../../../../ui/Select/Select';
import withRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../../../WithRouteSearchParams';
import { PayoutStatus } from '../../types';

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 5,
  },
}));

type Option = {
  label: string;
  value: PayoutStatus;
};

type InnerProps = MappedState & WithRouteSearchParamsProps<string[]>;
type OuterProps = {
  onChange: (selectedItems: PayoutStatus[]) => void;
};
type Props = InnerProps & OuterProps;

const PayoutsStatusFilter = (props: Props) => {
  const classes = useStyles();
  const { translate, setSearch, onChange, search } = props;
  const [selectedItems, setSelectedItems] = useState<Option[] | undefined>();

  useEffect(() => {
    if (search) {
      const optionsArray = [] as Option[];
      options.forEach((option) => {
        if (search.includes(option.value)) optionsArray.push(option);
      });

      setSelectedItems(optionsArray);
      handleChange(optionsArray);
    }
  }, []);

  const setUrlParams = (value: string[]) => {
    setSearch(value);
  };

  const handleChange = (options: Option[]) => {
    const optionValues = options.map((option: Option) => option.value);

    setSelectedItems(options);
    setUrlParams(optionValues);
    onChange(optionValues);
  };

  const options = useMemo(
    () =>
      [
        { value: 'Pending', label: translate('ReadyToProcess') as TranslationId },
        { value: 'Paid', label: translate('Paid') as TranslationId },
        { value: 'Failed', label: translate('Failed') as TranslationId },
        { value: 'Cancelled', label: translate('Cancelled') as TranslationId },
        { value: 'InTransit', label: translate('In_transit') as TranslationId },
      ] as Option[],
    [translate]
  );

  return (
    <ErrorBoundary identifier="payouts-states-filter">
      <Select
        className={classes.select}
        dataFd={'payouts-status-filter'}
        isClearable
        isMulti
        onChange={handleChange}
        options={options}
        placeholder={translate('All') as TranslationId}
        TextFieldProps={{
          fdKey: 'payouts-status-dropdown',
          label: translate('Status') as TranslationId,
          name: 'payouts-status-filter',
        }}
        value={selectedItems}
      />
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'status',
  }),
  connect(mapStateToProps)
)(PayoutsStatusFilter);
