import { isEmpty } from 'lodash';

export const USAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'US') {
    return {
      address: '',
      apartmentNumber: '',
      city: '',
      state: '',
      zipCode: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      address: storeAddress?.Line1 || '',
      apartmentNumber: '',
      city: storeAddress?.City || '',
      state: '',
      zipCode: storeAddress?.Postcode || '',
    };
  }
  return storeAddress.AddressFields;
};
