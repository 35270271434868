import React, { useMemo, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

const useStyles = makeStyles(() => ({
  clearButton: {
    padding: 2,
  },
  clearButtonPlaceholder: {
    padding: 2,
    width: 28,
  },
  inputField: {
    '& input[type=search]': {
      /* clears the 'X' from Internet Explorer */
      '&::-ms-clear': { display: 'none', width: 0, height: 0 },
      '&::-ms-reveal': { display: 'none', width: 0, height: 0 },
      /* clears the 'X' from Chrome */
      '&::-webkit-search-decoration': { display: 'none' },
      '&::-webkit-search-cancel-button': { display: 'none' },
      '&::-webkit-search-results-button': { display: 'none' },
      '&::-webkit-search-results-decoration': { display: 'none' },
    },
  },
}));

export type TextSearchFieldProps = {
  className?: string;
  clearAriaLabel: string;
  dataFd?: string;
  defaultValue?: string;
  disabled?: boolean;
  disableLabelAnimation?: boolean;
  label?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  variant?: 'outlined' | 'standard';
};

export const TextSearchField = (props: TextSearchFieldProps): JSX.Element => {
  const {
    className,
    clearAriaLabel,
    dataFd,
    disabled,
    disableLabelAnimation,
    label,
    onChange,
    placeholder,
    defaultValue = '',
    variant = 'outlined',
  } = props;
  const classes = useStyles();
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();

  const InputStyle = clsx(classes.inputField, className && className);

  const debounceOnChangeHandler = useMemo(
    () =>
      debounce((inputValue: string) => {
        onChange(inputValue);
      }, 500),
    []
  );

  const handleInputChange = (newInputValue: string) => {
    const clearButtonVisible = newInputValue !== '';
    setIsClearButtonVisible(clearButtonVisible);
    debounceOnChangeHandler(newInputValue);
  };

  const handleClearClick = () => {
    searchInputRef.current!.value = '';
    handleInputChange('');
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.charCode === 13 || e.key === 'Enter') {
      debounceOnChangeHandler.cancel();
      onChange(searchInputRef.current!.value);
    }
  };

  const renderSearchIcon = () => {
    return <SearchIcon aria-hidden="true" />;
  };

  const renderClearButton = () => {
    if (!isClearButtonVisible) {
      return <div className={classes.clearButtonPlaceholder} />;
    }
    return (
      <IconButton
        aria-label={clearAriaLabel}
        className={classes.clearButton}
        onClick={handleClearClick}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  return (
    <MuiTextField
      className={InputStyle}
      data-fd={dataFd}
      disabled={disabled}
      defaultValue={defaultValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {renderClearButton()}
            {renderSearchIcon()}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: disableLabelAnimation,
      }}
      inputRef={searchInputRef}
      label={label}
      onChange={(event) => handleInputChange(event.target.value)}
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      type="search"
      variant={variant}
    />
  );
};
