import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { Button } from '../../../ui/Button';
import YesNoDialog from '../../../ui/Dialog/YesNoDialog';

type Props = {
  handleReset: () => void;
};
const ReconfigureDomainSetup = (props: Props) => {
  const { handleReset } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Button fdKey="Reconfigure_domain_setup" color="secondary" onClick={() => setOpen(true)}>
        <Translate id="Reconfigure_domain_setup" />
      </Button>
      <div style={{ marginLeft: 8 }}>
        <Typography variant="caption">
          <Translate id="You_will_lose_the_current_settings" />
        </Typography>
      </div>

      {open && (
        <YesNoDialog
          titleTextId="Are_you_sure"
          open={open}
          onNo={() => setOpen(false)}
          onYes={() => handleReset()}
          yesTextId="reconfigure"
          yesButtonVariant="contained"
        >
          <Typography variant="body1">
            <Translate id={'reset_domain_warning'} />
          </Typography>
        </YesNoDialog>
      )}
    </>
  );
};
export default ReconfigureDomainSetup;
