import React from 'react';

import { AppStoreAppSummary } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Pagination } from '../../../ui/atoms/Pagination';
import { AppStoreAppCard } from './AppStoreAppCard';

const useStyles = makeStyles((theme: Theme) => ({
  sectionSpacingBelow: {
    marginBottom: theme.spacing(6),
  },
}));

export type AppStoreAppListProps = {
  usePagination: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange?: (newPageOffset: number) => void;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
  page?: number;
  appStoreApps: AppStoreAppSummary[];
  configuredAppStoreAppIds: string[];
  total?: number;
  appId: string;
};

export const AppStoreAppsList = ({
  usePagination,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
  page,
  appStoreApps,
  total,
  appId,
  configuredAppStoreAppIds,
}: AppStoreAppListProps): JSX.Element => {
  const classes = useStyles();

  const handlePageChange = (event: any, page: number): void => {
    if (onPageChange == undefined) {
      return;
    }

    onPageChange(page + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onRowsPerPageChange == undefined) {
      return;
    }

    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <Grid container data-fd="apps-list" spacing={2} className={classes.sectionSpacingBelow}>
      {appStoreApps.map((app) => {
        return (
          <Grid key={app.Id} item xs={12} sm={12} md={6} data-fd="apps-list">
            <AppStoreAppCard
              key={app.Id}
              appStoreApp={app}
              appId={appId}
              destinationPath={`/${appId}/appstore/${app.Id}`}
              isConfigured={configuredAppStoreAppIds.includes(app.Id!)}
            />
          </Grid>
        );
      })}
      {usePagination && (
        <Grid container item lg={12} direction={'row-reverse'}>
          <Pagination
            count={total as number}
            rowsPerPage={rowsPerPage as number}
            rowsPerPageOptions={rowsPerPageOptions as number[]}
            page={(page as number) - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </Grid>
  );
};
