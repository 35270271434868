import { checkoutConstants } from '../constants/checkout.constants';
import { Basket } from '../reducers/checkout.reducer';
import { action } from './utils';

const { CLEAR_BASKET_ITEMS, SET_BASKET_ITEMS } = checkoutConstants;

const clearBasket = () => action(CLEAR_BASKET_ITEMS);

export type SetBasketItems = ReturnType<typeof setBasketItems>;
const setBasketItems = (basket: Basket, returnPathOverride: string | undefined = undefined) =>
  action(SET_BASKET_ITEMS, {
    basket,
    returnPathOverride,
  });

export const checkoutActions = {
  clearBasket,
  setBasketItems,
};
