import {
  CreateFulfillmentStatesConfiguration,
  FulfillmentStateConfigurationApiFactory,
  FulfillmentStatesConfiguration,
  UpdateFulfillmentStatesConfiguration,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const fulfillmentStateConfigurationApi = createApi(FulfillmentStateConfigurationApiFactory);

const baseURL = getApiEndPoint();
const orderFulfillmentStatusApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const getorderFulfillmentStatusConfigListKey = 'getorderFulfillmentStatusConfigList';
async function getorderFulfillmentStatusConfigList(appId: string) {
  try {
    const response =
      await fulfillmentStateConfigurationApi.searchFulfillmentStatesConfigurations(appId);
    return response;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// #region getFulfillmentConfiguration
export async function getFulfillmentConfiguration(appId: string, configurationId: string) {
  try {
    const { data } = await orderFulfillmentStatusApi.get(
      `${appId}/fulfillment/configuration/states/${configurationId}`
    );

    return data.Data as FulfillmentStatesConfiguration;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getFulfillmentConfiguration
export async function getFulfillmentConfigurationTemplate(appId: string) {
  try {
    const { data } = await orderFulfillmentStatusApi.get(
      `${appId}/fulfillment/configuration/states_template`
    );

    return data.Data as FulfillmentStatesConfiguration;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region deleteFulfillmentConfiguration
export async function deleteFulfillmentConfiguration(appId: string, configurationId: string) {
  try {
    await fulfillmentStateConfigurationApi.deleteFulfillmentStatesConfig(appId, configurationId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updateFulfillmentConfiguration
export async function updateFulfillmentConfiguration(
  appId: string,
  configurationId: string,
  config: UpdateFulfillmentStatesConfiguration
) {
  try {
    const response = await fulfillmentStateConfigurationApi.updateFulfillmentStatesConfig(
      appId,
      configurationId,
      config
    );
    return response;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

async function createOrderFulfillmentStatusConfig(
  appId: string,
  config: CreateFulfillmentStatesConfiguration
) {
  try {
    const response = await fulfillmentStateConfigurationApi.createFulfillmentStatesConfig(
      appId,
      config
    );
    return response;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const orderFulfillmentStatusService = {
  deleteFulfillmentConfiguration,
  getorderFulfillmentStatusListKey: getorderFulfillmentStatusConfigListKey,
  getorderFulfillmentStatusList: getorderFulfillmentStatusConfigList,
  getFulfillmentConfiguration,
  getFulfillmentConfigurationTemplate,
  updateFulfillmentConfiguration,
  createOrderFulfillmentStatusConfig: createOrderFulfillmentStatusConfig,
};
