import React from 'react';

import { AppStoreAppSummary } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';

import { Pagination } from '../../../../ui/atoms/Pagination';
import { AppStoreAppCard } from '../../components/AppStoreAppCard';

export type AppStoreAppListProps = {
  limit: number;
  onPageChange: (newPageOffset: number) => void;
  page: number;
  appStoreApps: AppStoreAppSummary[];
  total: number;
  appId: string;
};

export const AppStoreAppsList = ({
  limit,
  onPageChange,
  page,
  appStoreApps,
  total,
  appId,
}: AppStoreAppListProps): JSX.Element => {
  const handlePageChange = (event: any, newPage: number) => {
    onPageChange(newPage + 1);
  };

  return (
    <Grid container data-fd="apps-list" spacing={2}>
      {appStoreApps.map((app) => {
        return (
          <Grid key={app.Id} item xs={12} sm={12} md={6} data-fd="apps-list">
            <AppStoreAppCard
              key={app.Id}
              appStoreApp={app}
              appId={appId}
              destinationPath={`/${appId}/appstore/developers/${app.Id}`}
              isConfigured={false}
            />
          </Grid>
        );
      })}
      <Grid container item lg={12} direction={'row-reverse'}>
        <Pagination
          count={total}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};
