import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { storeUnpublish } from '../../../actions/store.actions';
import LoadingButton from '../../../ui/LoadingButton';
import Tooltip from '../../../ui/Tooltip/Tooltip';
import { type CardData } from './DangerZoneList';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '228px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontWeight: 'normal',
  },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
}));

type InnerProps = MappedDispatch;
type OuterProps = {
  card: CardData;
  index: number;
  storeDetails: {
    appId: string | undefined;
    storeGroupId: number;
    storeName: string;
    storeId: number;
  };
  storePublished: boolean | undefined;
};
type Props = InnerProps & OuterProps;
const UnpublishStoreButton = (props: Props) => {
  const classes = useStyles();

  const {
    card,
    index,
    storeDetails: { storeId, storeName },
    storePublished,
    unpublishStore,
  } = props;
  const [open, setOpen] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [textField, setTextField] = useState<string>();
  const [isSubmitting, setSubmitting] = useState(false);

  const openDialog = () => {
    setOpen(true);
    setSubmitting(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (textField === storeName) {
        setSubmitting(true);
        await unpublishStore(storeId);
        setOpen(false);
      } else {
        setValidationError(true);
      }
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  return (
    <>
      <Tooltip
        messageId={'this_store_is_already_unpublished'}
        fdKey="tooltip"
        disableHoverListener={storePublished}
        disableTouchListener={storePublished}
      >
        <div>
          <Button
            data-fd={`${card.buttonText}_btn`}
            variant="outlined"
            className={classes.button}
            key={index}
            onClick={openDialog}
            disabled={!storePublished}
          >
            <Translate id={card.buttonText} />
          </Button>
        </div>
      </Tooltip>

      <Dialog open={open} onClose={closeDialog} aria-labelledby="Unpublish_Store">
        <DialogTitle id="Unpublish_Store">
          <Translate id="Unpublish_Store?" />
        </DialogTitle>
        <DialogContent style={{ maxWidth: '280px' }}>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              <Translate id="If_you_are_sure_you_want_to_unpublish" />{' '}
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{storeName}</span>
              {', '}
              <Translate id="please_type_in_the_name_of_the_store_to_confirm" />
            </Typography>
          </DialogContentText>

          <TextField
            variant="standard"
            inputProps={{ 'data-fd': 'unpublish-store-textfield' }}
            autoFocus
            disabled={isSubmitting || !storePublished}
            required
            placeholder={storeName}
            error={validationError}
            margin="none"
            style={{ width: '100%' }}
            onChange={(e) => setTextField(e.target.value)}
            helperText={validationError ? <Translate id="Store_name_does_not_match" /> : ''}
          />
        </DialogContent>
        <DialogActions className={classes.addPadding}>
          <Button
            data-fd={`cancel-unpublish-store`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-unpublish-store`}
            onClick={handleSubmit}
            color="secondary"
            disabled={isSubmitting || !storePublished}
            loading={isSubmitting}
          >
            <Translate id="Unpublish" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  unpublishStore: (storeId: number) => dispatch(storeUnpublish(storeId)),
});

export default compose<InnerProps, OuterProps>(connect(null, mapDispatchToProps))(
  UnpublishStoreButton
);
