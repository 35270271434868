import React from 'react';

import {
  FulfillmentStatesConfiguration,
  UpdateFulfillmentStatesConfiguration,
} from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { UseMutateFunction } from '@tanstack/react-query';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { TranslateFunction } from '../../../overrides/react-localize-redux';
import { Button } from '../../../ui/atoms';
import GridDivider from '../../AppStore/components/AppStoreAppConfigForm/GridDivider';
import AutomatedTransitionToggle from './fields/AutomatedTransitionToggle';
import StoreSelectField from './fields/StoresSelect';
import FulfillmentStatusTable from './table/FulfillmentStatusTable';

const useStyles = makeStyles((theme: Theme) => ({
  statusListTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(3),
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(3),
    },
  },
  basicCardContainer: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  statesContainer: {
    marginBottom: theme.spacing(4),
  },
  fieldContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  saveButtonContainer: {
    textAlign: 'end',
    marginTop: theme.spacing(6),
  },
  deleteButton: {
    marginRight: theme.spacing(2),
  },
  removeDialog: {
    whiteSpace: 'pre-line',
  },
}));

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (configuration: FulfillmentStatesConfiguration) => {
  return configuration;
};

export type AppStoreAppConfigFormProps = {
  configuration: FulfillmentStatesConfiguration;
  loading: boolean;
  translate: TranslateFunction;
  onSaveChanges: (data: UpdateFulfillmentStatesConfiguration) => any;
  configurationId: string;
  isArchiveDialogVisible: boolean;
  setIsArchiveDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onArchive: UseMutateFunction<void, unknown, void, unknown>;
};

const EditFulfillmentConfigurationForm = (props: AppStoreAppConfigFormProps & FormikProps<any>) => {
  const {
    handleSubmit,
    values,
    loading,
    configurationId,
    onArchive,
    isArchiveDialogVisible,
    setIsArchiveDialogVisible,
  } = props;
  const configuration = values as UpdateFulfillmentStatesConfiguration;

  const classes = useStyles();

  return (
    <>
      <YesNoDialog
        noTextId={'Cancel'}
        onNo={() => setIsArchiveDialogVisible(false)}
        onYes={onArchive}
        open={isArchiveDialogVisible}
        titleTextId={'Are_you_sure'}
        yesTextId={'Delete'}
        yesButtonVariant={'contained'}
      >
        <Typography variant="body1" className={classes.removeDialog}>
          <Translate id="Once_you_delete_this_configuration" />
        </Typography>
      </YesNoDialog>
      <Form>
        <PaperContainer fluid className={classes.basicCardContainer}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <StoreSelectField storeIds={configuration.StoreIds} />
          </Grid>
          <GridDivider />
          <Grid item xs={12} className={classes.fieldContainer}>
            <AutomatedTransitionToggle />
          </Grid>
        </PaperContainer>

        <Grid container className={classes.statesContainer}>
          <FulfillmentStatusTable loading={loading} tableData={configuration.States} />
        </Grid>

        <Grid
          container
          className={classes.saveButtonContainer}
          item
          xs={12}
          justifyContent="flex-end"
        >
          {configurationId !== 'fsc_global' && (
            <Button
              className={classes.deleteButton}
              fdKey="delete-configuration"
              variant="secondary"
              onClick={() => setIsArchiveDialogVisible(true)}
            >
              <Translate id="Delete" />
            </Button>
          )}

          <Button
            className={classes.button}
            fdKey="submit"
            variant="primary"
            onClick={() => handleSubmit()}
          >
            <Translate id={'Update'} />
          </Button>
        </Grid>
      </Form>
    </>
  );
};

const processForm = (formValues: FormValues): UpdateFulfillmentStatesConfiguration => {
  return {
    States: formValues.States,
    StoreIds: formValues.StoreIds,
    StoreSelectorType: formValues.StoreSelectorType,
    AutomaticTransitionsEnabled: formValues.AutomaticTransitionsEnabled,
  };
};

export default withFormik<AppStoreAppConfigFormProps, FormValues>({
  displayName: 'FulfillmentConfigurationForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.configuration);
  },
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.onSaveChanges(processForm(values));
    formikBag.setSubmitting(false);
  },
})(EditFulfillmentConfigurationForm);
