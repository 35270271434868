import React from 'react';

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { IconBanner } from '@fd/ui/atoms/IconBanner';
import { DescriptiveCard } from '@fd/ui/molecules/DescriptiveCard';

import { DiscoveryHeader } from '../DiscoveryHeader';

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
  },
  icon: {
    fontSize: '56px',
    color: '#646cbf',
  },
}));

export type DiscoveryProps = {
  translate: TranslateFunction;
};

export const Discovery = (props: DiscoveryProps) => {
  const { translate } = props;
  const classes = useStyles();

  return (
    <>
      <DiscoveryHeader translate={translate} />
      <Grid container direction="row" spacing={3}>
        <Grid className={classes.gridItem} item sm={4}>
          <DescriptiveCard
            banner={<IconBanner icon={<BorderColorIcon className={classes.icon} />} />}
            title="Frictionless_ordering"
            description="Frictionless_ordering_description"
          />
        </Grid>
        <Grid className={classes.gridItem} item sm={4}>
          <DescriptiveCard
            banner={<IconBanner icon={<LoyaltyOutlinedIcon className={classes.icon} />} />}
            title="Loyalty"
            description="Loyalty_description"
          />
        </Grid>
        <Grid className={classes.gridItem} item sm={4}>
          <DescriptiveCard
            banner={<IconBanner icon={<AddShoppingCartOutlinedIcon className={classes.icon} />} />}
            title="Easy_management"
            description="Easy_management_description"
          />
        </Grid>
      </Grid>
    </>
  );
};
