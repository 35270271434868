import React, { useState } from 'react';

import { Store, StoreBase } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, Typography } from '@fd/ui/atoms';
import { Dialog } from '@fd/ui/molecules';

import LoadingButton from '../../../ui/LoadingButton';
import { update } from '../StoreOrderSettings';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: theme.palette.grey[600],
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  autoAcceptProps: {
    ev: { target: { name: string; checked: boolean } };
    store: StoreBase;
    update: update;
  };
  onChecked: (event: React.ChangeEvent<HTMLInputElement>, store: Store, update: update) => void;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  translate: TranslateFunction;
};

const AutoAcceptModal = (props: Props) => {
  const classes = useStyles();
  const {
    autoAcceptProps: { ev, store, update },
    onChecked,
    openModal,
    translate,
  } = props;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const closeDialog = () => {
    openModal(false);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    onChecked(ev as React.ChangeEvent<HTMLInputElement>, store, update);
    openModal(false);
  };

  return (
    <Dialog
      open={true}
      onClose={closeDialog}
      title={translate('Disable_manual_order_acceptance') as string}
      actions={[
        <Button
          fdKey="cancel-autoAccept-toggle"
          key="cancel-autoAccept-toggle"
          onClick={closeDialog}
          variant="text"
          disabled={isSubmitting}
        >
          <Translate id="Cancel" />
        </Button>,
        <LoadingButton
          fdKey="confirm-autoAccept-toggle"
          key="confirm-autoAccept-toggle"
          onClick={handleSubmit}
          color="secondary"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          <Translate id="Disable_anyway" />
        </LoadingButton>,
      ]}
    >
      <Typography className={classes.description} variant="body1">
        <Translate id="Automatically_accept_orders_warning" />
      </Typography>
    </Dialog>
  );
};

export default AutoAcceptModal;
