import React, { useRef } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { InputField } from '@fd/ui/atoms/InputField';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

export type NoteDialogProps = {
  note?: string;
  open: boolean;
  onCancel: () => void;
  onSave: (newNote: string) => void;
  translate: TranslateFunction;
};

export const NoteDialog = (props: NoteDialogProps): JSX.Element => {
  const { note, open, onCancel, onSave, translate } = props;
  const noteInputRef = useRef<HTMLInputElement | null>(null);
  const titleKey = note !== '' ? 'Edit_note' : 'Add_note';
  const classes = useStyles();

  return (
    <Dialog
      title={translate(titleKey) as string}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel-edit-note"
          fdKey="cancel-edit-note"
          onClick={onCancel}
          variant="text"
        >
          {translate('Cancel')}
        </Button>,
        <Button
          key="note-save"
          fdKey="note-save"
          onClick={() => onSave(noteInputRef.current?.value || '')}
        >
          {translate('Save')}
        </Button>,
      ]}
    >
      <InputField
        autoFocus
        defaultValue={note}
        fdKey="note-input-field"
        label={translate('Details') as string}
        multiline
        inputRef={noteInputRef}
        maxRows={8}
        variant="standard"
      />
    </Dialog>
  );
};
