import React, { useEffect, useState } from 'react';

import type { Org } from '@flipdish/orgmanagement';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { setCurrentOrg } from '../rms.actions';
import OrgSelectorItem from './OrgSelectorItem';
import { useKeyboardNavigation } from './useKeyboardNavigation';

const StyledMessageContainer = styled('div')({
  textAlign: 'center',
});

type KeyPressType = { key: string };
type Props = MappedState & MappedDispatch;
type OutterProps = {
  toggleOrgDrawer: () => void;
  open: boolean;
  orgs: Org[];
  searchQuery: string;
};

const OrgSelectorItems = (props: Props & OutterProps) => {
  const [refs, setRefs] = useState<Array<React.RefObject<HTMLDivElement>>>([]);
  const [currentFocusedOrgIndex, setCurrentFocusedOrgIndex] = useState(-1);
  const {
    orgs,
    currentOrg,
    open,
    setCurrentOrganisation,
    toggleOrgDrawer,
    translate,
    searchQuery,
  } = props;

  const [keyPressed, setKeyPressed] = useState<KeyPressType>({ key: '' });

  const keyPressHandler = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'Escape') {
      e.preventDefault();
      setKeyPressed({ key });
    }
  };

  useEffect(() => {
    if (!open) {
      setCurrentFocusedOrgIndex(-1);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', keyPressHandler);
    }

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [open]);

  useEffect(() => {
    const refArray: Array<React.RefObject<HTMLDivElement>> = [];
    for (let i = 0; i < orgs.length; i++) {
      refArray.push(React.createRef());
    }
    setRefs(refArray);
  }, [orgs.length]);

  useKeyboardNavigation(
    orgs,
    refs,
    keyPressed,
    currentFocusedOrgIndex,
    setCurrentFocusedOrgIndex,
    toggleOrgDrawer
  );

  const itemSelected = (org: Org) => {
    setCurrentOrganisation(org);
    toggleOrgDrawer();
  };

  return (
    <>
      {orgs?.length ? (
        orgs.map((org: Org, index: number) => (
          <OrgSelectorItem
            handleItemClick={() => itemSelected(org)}
            key={`${org.orgId}_${index}`}
            dataFd={`org-selector-${org?.orgId}`}
            isActive={currentOrg?.orgId === org.orgId}
            imgUrl={org.logoImageUrl}
            imgName={org.name}
            primary={org.name}
            ref={refs[index]}
            secondary={org.orgId}
          />
        ))
      ) : (
        <StyledMessageContainer>
          <Typography variant="body1">
            {translate('No_orgs_found_for', { searchTerm: searchQuery })}
          </Typography>
        </StyledMessageContainer>
      )}
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentOrg: state.rms.currentOrg,
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setCurrentOrganisation: (organisation: Org) => dispatch(setCurrentOrg(organisation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgSelectorItems);
