import React, { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import { type HiddenProps } from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { type TablePaginationProps } from '@mui/material/TablePagination';
import makeStyles from '@mui/styles/makeStyles';

import TablePagination from '../../components/common/TablePagination/TablePagination';
import { DataTableBody } from './table-body';
import { TableHeaders } from './table-headers';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 4,
    border: '1px solid rgba(224, 224, 224, 1)',

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  },
  noLastBorder: {
    '& > tbody > tr:last-child': {
      '& > td, & > th': {
        borderBottom: 'none',
      },
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      paddingTop: 32,
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

export type TDataTableHeader<T> = {
  name: keyof T & string;
  label?: ReactNode;
  labelId?: TranslationId;
  width?: string;
  hiddenProps?: HiddenProps;
  renderDataCell?: (arg: TRenderDataCellArg<T>) => ReactNode;
};

export type TRenderDataCellArg<T> = {
  header: TDataTableHeader<T>;
  value: any;
  row: T;
};

export type TDataTableProps<T> = {
  uniqueFieldName: keyof T;
  headers: Array<TDataTableHeader<T>>;
  isLoading?: boolean;
  data: Array<T>;
  paginationProps?: TablePaginationProps;
};

export function DataTable<T>(props: TDataTableProps<T>) {
  const classes = useStyles();

  const { headers, paginationProps } = props;

  return (
    <Grid className={classes.container}>
      <Table className={classes.noLastBorder}>
        <TableHeaders headers={headers} />
        <DataTableBody {...props} />
      </Table>
      {paginationProps && <TablePagination component="div" {...paginationProps} />}
    </Grid>
  );
}
