import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from 'react-loading-skeleton';

const listSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const OrdersTableLoading = ({ showTHead }: { showTHead: boolean }) => {
  return (
    <Paper>
      <Table>
        {showTHead && (
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  height: '56px',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                  <Grid item md={2}>
                    <Skeleton width={'100%'} height={10} />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {listSkeletons.map((val: number) => (
            <TableRow key={val}>
              <TableCell
                style={{
                  height: '67px',
                }}
              >
                <Grid container spacing={0}>
                  <Grid item md={1}>
                    <Skeleton circle={true} height={40} width={40} />
                  </Grid>
                  <Grid item md={11} container justifyContent="center">
                    <div>
                      <Skeleton width={'100%'} height={10} />
                    </div>
                    <div>
                      <Skeleton width={'60%'} height={10} />
                    </div>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default OrdersTableLoading;
