import { CustomersApiFactory, CustomerUpdateModel } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { downloadCSVFile } from '../../helpers/downloadCSV';
import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';
import {
  getQuery,
  getSortQuery,
  serializeCustomerData,
  serializeCustomerOrderData,
  updateLanguageHeader,
} from './helpers';
import {
  CustomerManagementDetailsTableResponse,
  CustomerManagementResponse,
  TableProps,
} from './types';

const baseURL = getApiEndPoint();
const customerStatusApi = createApi(CustomersApiFactory);

const portalApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const customerReportApi = axios.create({
  baseURL: `${baseURL}/reporting/v1.0`,
  withCredentials: true,
});

export const isUserBetaTester = async () => {
  try {
    const response = await portalApi.get('/users/roles');
    const isBeta = response.data.Data.some((d) => d === 'BetaTester');
    return isBeta;
  } catch (err) {
    console.log(new Error(err));
  }
};

//#region Customer List
export const downloadCustomerListTableService = async ({
  AppId,
  Page,
  PageSize,
  searchTerm,
  stores,
  SortDirection,
  SortBy,
  languageCode,
}: TableProps) => {
  const queryParams = {
    Page,
    PageSize,
    StoreId: stores,
    searchTerm,
  };
  const query = getQuery(queryParams);
  const sortQuery = getSortQuery(SortBy, SortDirection);
  try {
    // FYI &exportAsCsv=true works only on direct links or inside <a> tag
    const response = await customerReportApi.get<{ Data: { FileUrl: string } }>(
      `/${AppId}/customermanagement?${query}${sortQuery}&exportAsCsv=true`,
      {
        headers: updateLanguageHeader({ exportAsCsv: true, languageCode }),
      }
    );

    downloadCSVFile(
      response.data.Data.FileUrl,
      `Customer report ${AppId} ${new Date().toISOString().slice(0, 16).replace(/[-:]/g, '_')}.csv`
    );
  } catch (incomingError) {
    console.log('getCustomerData Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};

export const getCustomerListTableService = async ({
  AppId,
  Page,
  PageSize,
  searchTerm,
  stores,
  SortDirection,
  SortBy,
}: TableProps) => {
  const queryParams = {
    Page,
    PageSize,
    StoreId: stores,
    searchTerm,
  };
  const query = getQuery(queryParams);
  const sortQuery = getSortQuery(SortBy, SortDirection);
  try {
    const response = await customerReportApi.get<CustomerManagementResponse['data']>(
      `/${AppId}/customermanagement?${query}${sortQuery}`
    );

    const { data } = response;
    const serializedData = serializeCustomerData(data.result);
    return { result: serializedData, pagination: data.pagination };
  } catch (incomingError) {
    console.log('getCustomerData Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};
//#endregion

//#region Customer Order Values
export const getCustomerOrderValues = async (
  AppId: string,
  customerId: string,
  stores?: number[]
) => {
  const queryParams = {
    customerId,
    StoreId: stores,
  };
  const query = getQuery(queryParams);
  try {
    const { data } = await customerReportApi.get(`/${AppId}/customermanagement/overview?${query}`);
    return data as Reports.CustomerOrdersOverview;
  } catch (incomingError) {
    console.log('getCustomerDetails Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};
//#endregion

//#region Customer Details Panel
export const getCustomerDetails = async (AppId: string, customerId: string) => {
  const queryParams = {
    customerId,
  };
  const query = getQuery(queryParams);
  try {
    const { data } = await customerReportApi.get(`/${AppId}/customermanagement/details?${query}`);
    return data as Reports.CustomerDetail;
  } catch (incomingError) {
    console.log('getCustomerDetails Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};

export const getCustomerMarketingPaymentStatus = async (AppId: string, customerId: number) => {
  try {
    const { Data } = await customerStatusApi.getCustomerById(AppId, customerId);
    return Data;
  } catch (incomingError) {
    console.log('getCustomerDetails Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};

export const setCustomerMarketingPaymentStatus = async (
  AppId: string,
  customerId: number,
  updateCustomer: CustomerUpdateModel
) => {
  try {
    const { Data } = await customerStatusApi.updateCustomerById(AppId, customerId, updateCustomer);
    return Data;
  } catch (incomingError) {
    console.log('setCustomerDetails Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};

//#endregion

//#region Customer Details Table
export const getCustomerDetailsTableService = async ({
  AppId,
  customerId,
  columnNames,
  languageCode,
  Page,
  PageSize,
  stores,
  SortDirection,
  SortBy,
}: TableProps) => {
  const queryParams = {
    columnNames,
    Page,
    PageSize,
    StoreId: stores,
  };
  const query = getQuery(queryParams);
  const sortQuery = getSortQuery(SortBy, SortDirection);

  try {
    const { data }: CustomerManagementDetailsTableResponse = await customerReportApi.get(
      `${AppId}/customermanagement/orders?customerId=${customerId}&${query}${sortQuery}`
    );
    const serializedData = serializeCustomerOrderData(data.result, languageCode!);
    return { result: serializedData, pagination: data.pagination };
  } catch (incomingError) {
    console.log('getCustomerData Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};
//#endregion

export const downloadCSVService = async (AppId: string, Guid: string) => {
  try {
    const resp = await customerReportApi.get<string>(`/${AppId}/csv/${Guid}`);
    return resp.data;
  } catch (incomingError) {
    console.log('downloadCSVService Error: ', incomingError);
    const e = await mapServerError(incomingError);
    throw e;
  }
};
