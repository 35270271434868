import { addValues, formatCurrency } from '../helpers';
import { PayoutSummary } from '../types';

/**
 * Returns formatted payout summary values mapped to Translation Ids, tooltips & hideLine.
 *
 * @param {PayoutSummary} data reduced totals for payoutDetailSummary from Backend
 * @param {string} currency for formatCurrency function
 * @param {string} languageCode for formatCurrency function
 * @return {[Revenue?, RevenueAdjustments?, FlipdishFees?, ThirdPartyFees?]} Translation Ids, tooltips & hideLine mapped to data values
 */
export const formatPayoutDetailsBreakdown = (
  data: PayoutSummary,
  currency: string,
  languageCode: string,
  translate: (id: TranslationId) => void
) => {
  const { FlipdishFees, PosRevenue, Revenue, RevenueAdjustments, ThirdPartyFees } = data;

  const tableData: {}[] = [];

  const showPosData =
    PosRevenue?.PosSalesAmount ||
    PosRevenue?.PosSalesTips ||
    RevenueAdjustments?.PosSalesRefundedAmount ||
    FlipdishFees?.PosSalesFees ||
    FlipdishFees?.PosSalesRefundedFees ||
    PosRevenue?.TotalPosRevenue;

  if (Revenue) {
    tableData.push({
      sectionTitle: 'Revenue',
      sectionTotalTitle: 'Total_revenue',
      sectionTotal: addValues(Revenue.TotalOnlineRevenue, PosRevenue?.TotalPosRevenue || 0),
      tooltipContent: 'Online_Revenue_tooltip',
      lineItems: [
        {
          lineTitle: 'Online_Sales',
          tooltipContent: 'Online_Sales_tooltip',
          lineTotal: Revenue.OnlineSalesAmount,
        },
        {
          lineTitle: 'Online_Sales_Tax',
          lineTotal: Revenue.OnlineSalesTax,
          tooltipContent: 'Sales_tax_on_accepted_online_orders_tooltip',
          hideLine: !Revenue.OnlineSalesTax || Revenue.OnlineSalesTax === 0,
        },
        {
          lineTitle: 'POS_Sales',
          tooltipContent: 'Pos_sales_tooltip',
          lineTotal: PosRevenue?.PosSalesAmount,
          hideLine: !showPosData,
        },
        {
          lineTitle: 'POS_Sales_Tax',
          tooltipContent: 'Sales_tax_on_pos_orders_tooltip',
          lineTotal: PosRevenue?.PosSalesTax,
          hideLine: !showPosData,
        },
        {
          lineTitle: 'Tips_on_POS_Sales',
          lineTotal: PosRevenue?.PosSalesTips,
          hideLine: !showPosData,
        },
        {
          lineTitle: 'Delivery_Charges_for_Online_Sales',
          lineTotal: Revenue.OnlineSalesDeliveryCharges,
        },
        {
          lineTitle: 'Tips_on_Online_Sales',
          lineTotal: Revenue.OnlineSalesTips,
        },
        {
          lineTitle: 'Service_charge_on_Online_Sales',
          lineTotal: Revenue.OnlineSalesServiceCharges,
          hideLine: !Revenue.OnlineSalesServiceCharges || Revenue.OnlineSalesServiceCharges === 0,
        },
      ],
    });
  }

  if (RevenueAdjustments) {
    tableData.push({
      sectionTitle: 'Revenue_Adjustments',
      sectionTotalTitle: 'Total_Revenue_Adjustments',
      sectionTotal: RevenueAdjustments.TotalOnlineRevenueAdjustments,
      lineItems: [
        {
          lineTitle: 'Refunds_on_Online_Sales',
          tooltipContent: 'Refunds_on_Online_Sales_tooltip',
          lineTotal: RevenueAdjustments.OnlineSalesRefundedAmount,
        },
        {
          lineTitle: 'Refunds_on_POS_Sales',
          tooltipContent: 'Pos_sales_refund_tooltip',
          lineTotal: RevenueAdjustments.PosSalesRefundedAmount,
          hideLine: !showPosData,
        },
        {
          lineTitle: 'PayGreen_Sales',
          tooltipContent: 'Total_sales_via_restaurant_vouchers_in_the_selecte',
          lineTotal: RevenueAdjustments.PayGreenSalesAmount,
          hideLine:
            !RevenueAdjustments.PayGreenSalesAmount || RevenueAdjustments.PayGreenSalesAmount === 0,
        },
        {
          lineTitle: 'PayGreen_Processing_Fee',
          tooltipContent: 'End_user_fees_charged_for_restaurant_vouchers',
          lineTotal: RevenueAdjustments.PayGreenProcessingFees,
          hideLine:
            !RevenueAdjustments.PayGreenProcessingFees ||
            RevenueAdjustments.PayGreenProcessingFees === 0,
        },
        {
          lineTitle: 'Cash_Fees_Charged_to_Customer',
          tooltipContent: 'Cash_Fees_Charged_to_Customer_tooltip',
          lineTotal: RevenueAdjustments.CustomerCashFees,
          hideLine:
            !RevenueAdjustments.CustomerCashFees || RevenueAdjustments.CustomerCashFees === 0,
        },
      ],
    });
  }

  if (FlipdishFees) {
    tableData.push({
      sectionTitle: 'Flipdish_Fees',
      sectionTotalTitle: 'Total_Flipdish_Fees',
      sectionTotal: FlipdishFees.TotalFees,
      lineItems: [
        {
          lineTitle: 'Online_Sales_Fees',
          lineTotal: FlipdishFees.OnlineSalesFees,
          stringData: {
            onlineSales: formatCurrency(
              Revenue?.OnlineSalesFeesBaseAmount ? Revenue.OnlineSalesFeesBaseAmount : 0,
              languageCode,
              currency
            ),
          },
        },
        {
          lineTitle: 'POS_Sales_Fees_on_$posSales_in_POS_Revenue_excl_Ti',
          lineTotal: FlipdishFees?.PosSalesFees,
          hideLine: !showPosData,
          stringData: {
            posSales: formatCurrency(PosRevenue?.PosSalesAmount || 0, languageCode, currency),
          },
        },
        {
          lineTitle: 'Cash_Sales_Fees',
          stringData: {
            cashSales: formatCurrency(
              Revenue?.CashSalesFeesBaseAmount ? Revenue.CashSalesFeesBaseAmount : 0,
              languageCode,
              currency
            ),
          },
          lineTotal: FlipdishFees.CashSalesFees,
          hideLine: !FlipdishFees.CashSalesFees || FlipdishFees.CashSalesFees === 0,
        },
        /* 
          Two different views depending if cash has value
            1. Refunded Fees on Refunded Sales (€10.00 Online)
            2. Refunded Fees on Refunded Sales (€10.00 Online & €10.00 Cash)
          */
        {
          lineTitle:
            !RevenueAdjustments?.CashSalesRefundedAmount ||
            RevenueAdjustments.CashSalesRefundedAmount === 0
              ? 'Refunded_Fees_on_Refunded_Sales_alt'
              : 'Refunded_Fees_on_Refunded_Sales',
          stringData:
            !RevenueAdjustments?.CashSalesRefundedAmount ||
            RevenueAdjustments.CashSalesRefundedAmount === 0
              ? {
                  onlineRefund: formatCurrency(
                    RevenueAdjustments?.OnlineSalesRefundedAmount
                      ? Math.abs(RevenueAdjustments.OnlineSalesRefundedAmount)
                      : 0,
                    languageCode,
                    currency
                  ),
                }
              : {
                  onlineRefund: formatCurrency(
                    RevenueAdjustments?.OnlineSalesRefundedAmount
                      ? Math.abs(RevenueAdjustments.OnlineSalesRefundedAmount)
                      : 0,
                    languageCode,
                    currency
                  ),
                  cashRefund: formatCurrency(
                    RevenueAdjustments?.CashSalesRefundedAmount
                      ? Math.abs(RevenueAdjustments.CashSalesRefundedAmount)
                      : 0,
                    languageCode,
                    currency
                  ),
                },
          lineTotal: addValues(
            FlipdishFees.OnlineSalesRefundedFees,
            FlipdishFees.CashSalesRefundedFees
          ),
        },
        {
          lineTitle: 'Refunded_fees_on_pos_sales',
          lineTotal: FlipdishFees?.PosSalesRefundedFees,
          hideLine: !showPosData,
          stringData: {
            posSalesRefund: formatCurrency(
              RevenueAdjustments?.PosSalesRefundedAmount
                ? Math.abs(RevenueAdjustments.PosSalesRefundedAmount)
                : 0,
              languageCode,
              currency
            ),
          },
        },
        {
          lineTitle: 'Vat',
          lineTotal: FlipdishFees.SalesFeesVat,
        },
      ],
    });
  }

  if (ThirdPartyFees && ThirdPartyFees.TotalThirdPartyFees !== 0) {
    tableData.push({
      sectionTitle: 'ThirdParty_Fees',
      sectionTotalTitle: 'Total_ThirdParty_Fees',
      sectionTotal: ThirdPartyFees.TotalThirdPartyFees,
      lineItems: [
        {
          lineTitle: 'DeliveryIntegration_Fee',
          tooltipContent: 'DeliveryIntegration_Fee_tooltip',
          lineTotal: ThirdPartyFees.DeliveryIntegrationFee,
        },
        {
          lineTitle: 'DeliveryTip_Fee',
          tooltipContent: 'DeliveryTip_Fee_tooltip',
          lineTotal: ThirdPartyFees.DeliveryTipFee,
        },
      ],
    });
  }

  return tableData;
};
