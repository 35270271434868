import React, { useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps, notifySaved } from '../../../layouts/Notify/actions';
import { getSalesChannelGroupKey, storeGroupService } from '../../../services/storegroup.service';
import { findStoreGroupId } from '../utils/findStoreGroupById';
import useStoreToSalesChannelTransformer from '../utils/useStoreToSalesChannelTransformer';
import CustomerMessageForm, { FormValues } from './components/CustomerMessageForm';

type Props = MappedState & MappedDispatch;
const CustomerMessageDetails = ({
  currentBrandId,
  dispatchNotifyError,
  dispatchNotifySaved,
}: Props) => {
  const { salesChannelGroupId } = useParams<{ salesChannelGroupId: string }>();

  const { data: salesChannelGroupsData, isPending: isSalesChannelGroupsLoading } = useQuery({
    queryKey: [getSalesChannelGroupKey, currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
  });

  const SalesChannelGroup = useMemo(() => {
    return salesChannelGroupsData?.Data?.find(
      (d) => d.StoreGroupId === Number(salesChannelGroupId)
    );
  }, [salesChannelGroupsData, salesChannelGroupId]);

  const storeGroupStores = useMemo(() => {
    return (
      SalesChannelGroup?.StoreHeaders?.map((storeHeader) => ({
        name: storeHeader.Name,
        storeId: storeHeader.StoreId,
      })) ?? []
    );
  }, [SalesChannelGroup]);

  const storeIdAssociations = useStoreToSalesChannelTransformer();

  const associatedSalesChannels = useMemo(() => {
    return storeIdAssociations
      .filter((association) =>
        storeGroupStores.find((store) => store?.storeId === association?.storeId)
      )
      .map((association) => ({
        label: association?.salesChannelName ?? '',
        value: association?.storeId || 0,
        scId: association?.salesChannelId ?? '',
        storeGroupId:
          findStoreGroupId({ storeId: association?.storeId, salesChannelGroupsData }) ?? 0,
        isNonClearable: true,
      }));
  }, [storeIdAssociations, storeGroupStores]);

  const handleSubmit = useCallback(
    async ({ deliveryMessage, collectionMessage }: FormValues) => {
      try {
        await storeGroupService.setCustomerMessages(Number(salesChannelGroupId), {
          DeliveryMenuMessage: deliveryMessage,
          PickupMenuMessage: collectionMessage,
        });
        dispatchNotifySaved();
      } catch (e) {
        dispatchNotifyError({ message: e.message });
      }
    },
    [salesChannelGroupId]
  );

  return (
    <PageLayout
      documentTitle="Customer_messages"
      title={SalesChannelGroup?.Name}
      userPermissions={'Owner'}
      toParent={`/${currentBrandId}/settings/customer-messages/`}
      strictToParent
    >
      {isSalesChannelGroupsLoading ? (
        <CircularProgress />
      ) : (
        <CustomerMessageForm
          salesChannels={associatedSalesChannels}
          collectionMessage={SalesChannelGroup?.PickupMenuMessage ?? ''}
          deliveryMessage={SalesChannelGroup?.DeliveryMenuMessage ?? ''}
          onSubmit={handleSubmit}
        />
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentBrandId: state.currentApp.AppId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMessageDetails);
