import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from 'react-loading-skeleton';

const listSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const OrdersTableLoadingMobile = () => {
  return (
    <Grid>
      <Divider
        style={{
          marginTop: '24px',
          marginBottom: '20px',
        }}
      />
      {listSkeletons.map((val: number) => (
        <Grid
          key={val}
          style={{
            height: '108px',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={2} alignItems="flex-start" container>
              <Skeleton circle={true} height={40} width={40} />
            </Grid>
            <Grid item xs={8}>
              <div>
                <Skeleton width={'30%'} height={15} />
              </div>
              <div>
                <Skeleton width={'50%'} height={10} />
              </div>
              <div>
                <Skeleton width={'70%'} height={10} />
              </div>
              <div>
                <Skeleton width={'100%'} height={10} />
              </div>
            </Grid>
            <Grid item xs={2} alignItems="flex-end" container>
              <div>
                <Skeleton width={'100%'} height={15} />
              </div>
              <div>
                <Skeleton width={'100%'} height={10} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default OrdersTableLoadingMobile;
