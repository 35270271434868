import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';
import {
  type UnpackICEWP,
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers,
} from 'recompose';

import { Button, Typography } from '@fd/ui/atoms';
import { Dialog } from '@fd/ui/molecules';

import { Store } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  copyDescription: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

type StoreOptionsState = UnpackICEWP<typeof useStoreOptionsState> & {
  onChange(e: React.ChangeEvent<{}>, value: string);
  onStoreSelect(e: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode);
};
const useStoreOptionsState = withStateHandlers(
  () => ({
    option: 'new',
    selectedId: 0,
  }),
  {
    onChange: (state) => (e, value: string) => ({
      ...state,
      option: value,
    }),
    onStoreSelect:
      (state) => (e: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => ({
        ...state,
        selectedId: Number(e.target.value),
      }),
  }
);

type Handlers = UnpackICEWP<typeof useHandlers>;
const useHandlers = withHandlers({
  handleSave:
    ({ onSave, stores, option, selectedId }: Props) =>
    () => {
      if (option === 'copy') {
        const storeId = selectedId || stores[0].StoreId;
        const { StoreId, Name, ...store } = stores.find((s) => s.StoreId === storeId)!;
        onSave(storeId);
      } else {
        onSave(/*new*/);
      }
    },
});

export type CreateNewDialogProps = {
  open: boolean;
  stores: Store[];
  onCancel();
  onSave(storeId?: number);
  translate: TranslateFunction;
};
type Props = CreateNewDialogProps & StoreOptionsState;
const CreateNewStoreDialog = ({
  open,
  onCancel,
  handleSave,
  stores,
  option,
  selectedId,
  onChange,
  onStoreSelect,
  translate,
}) => {
  const [displayCopyMessage, setDisplayCopyDataMessage] = React.useState(false);
  const classes = useStyles();

  const onChangeCopyControl = () => {
    setDisplayCopyDataMessage(!displayCopyMessage);
  };
  const selectedStoreId = selectedId || (stores && stores.length && stores[0].StoreId);
  const selectedStore = stores.find((store) => store.StoreId === selectedStoreId);

  return (
    <Dialog
      open={open}
      title={translate('Create_new_store') as string}
      actions={[
        <Button
          className={classes.cancelButton}
          key="cancel-button"
          fdKey="Cancel"
          onClick={onCancel}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button key="create-button" fdKey="Create" onClick={handleSave}>
          <Translate id="Create" />
        </Button>,
      ]}
    >
      <RadioGroup
        aria-label={translate('Create_new_store') as string}
        name="CreateNewStoreOptions"
        value={option}
        onChange={onChange}
      >
        <FormControlLabel
          control={<Radio color="primary" data-fd="create_new_radio" />}
          label={translate('Create_new') as string}
          onChange={onChangeCopyControl}
          value="new"
        />
        <FormControlLabel
          color="primary"
          control={<Radio color="primary" data-fd="copy_store_radio" />}
          label={translate('Copy_data_from') as string}
          onChange={onChangeCopyControl}
          value="copy"
        />
      </RadioGroup>

      <Select
        variant="standard"
        className={classes.copyDescription}
        value={selectedStoreId}
        fullWidth
        disabled={option !== 'copy'}
        onChange={onStoreSelect}
      >
        {stores.map((store) => (
          <MenuItem key={store.StoreId} value={store.StoreId}>
            {store.Name}
          </MenuItem>
        ))}
      </Select>
      {selectedStore && displayCopyMessage && (
        <Typography variant="caption">
          <Translate id="Copy_data_from_store_description" data={{ store: selectedStore.Name }} />
        </Typography>
      )}
    </Dialog>
  );
};

const EnhancedComponent = compose<Props & Handlers, CreateNewDialogProps>(
  setDisplayName('CreateNewStoreDialog'),
  useStoreOptionsState,
  useHandlers
)(CreateNewStoreDialog);

export { EnhancedComponent as CreateNewStoreDialog };
