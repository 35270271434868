import { useEffect, useState } from 'react';

import usePrevious from '@fd/customHooks/usePrevious';
import { MenuSection, MenuSectionItem } from '@flipdish/api-client-typescript';
import _debounce from 'lodash/debounce';

type Params = {
  menuSections?: MenuSection[];
  searchValue?: string;
};

type UseMenuSearch = {
  data: MenuSectionItem[];
  isDataEmpty: boolean;
  isSearchValueEmpty: boolean;
};

export const useMenuSearch = ({ menuSections = [], searchValue = '' }: Params): UseMenuSearch => {
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuSectionItem[]>([]);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const previousSearchValueLength = usePrevious(searchValue)?.length || 0;

  const isSearchValueEmpty = searchValue.length === 0;
  const isValidMenuSearch = !!menuSections.length && !isSearchValueEmpty;

  const checkIsEmptyAndSetMenuItems = (menuItems: MenuSectionItem[]): void => {
    const isArrayEmpty = menuItems.length === 0;
    setIsDataEmpty(isArrayEmpty);
    setFilteredMenuItems(menuItems);
  };

  const filterMenuSectionsAndItems = (): void => {
    if (isValidMenuSearch) {
      let newFilteredMenuItems: MenuSectionItem[] = [];

      // Expensive menu search - for loop is the most performant
      for (let i = 0; i < menuSections.length; i += 1) {
        const sectionFilteredMenuItems = filterMenuItems(menuSections[i].MenuItems || []);

        if (sectionFilteredMenuItems.length) {
          newFilteredMenuItems = newFilteredMenuItems.concat(sectionFilteredMenuItems);
        }
      }

      checkIsEmptyAndSetMenuItems(newFilteredMenuItems);
    } else {
      setIsDataEmpty(false);
      setFilteredMenuItems([]);
    }
  };

  const filterSubsetOfMenuItems = (): void => {
    const newFilteredMenuItems = filterMenuItems(filteredMenuItems);
    checkIsEmptyAndSetMenuItems(newFilteredMenuItems);
  };

  const filterMenuItems = (menuItems: MenuSectionItem[]): MenuSectionItem[] => {
    const newFilteredMenuItems: MenuSectionItem[] = [];

    // Expensive menu search - for loop is the most performant
    for (let j = 0; j < menuItems.length; j += 1) {
      // Do not include menu items with option sets
      if (menuItems[j].MenuItemOptionSets?.length) {
        continue;
      }

      const item = menuItems[j];
      const name = item.Name ? item.Name.toLowerCase() : '';
      const search = searchValue.toLowerCase();

      if (name.includes(search)) {
        newFilteredMenuItems.push(item);
      }
    }

    return newFilteredMenuItems;
  };

  const debouncedFilterSubsetMenuItems = _debounce(filterSubsetOfMenuItems, 200);
  const debouncedFilterAllMenuSections = _debounce(filterMenuSectionsAndItems, 200);

  useEffect(() => {
    const isFirstSearch = !isSearchValueEmpty && !previousSearchValueLength;
    // If the user has only typed one character, search the entire menu
    if (isFirstSearch) {
      return filterMenuSectionsAndItems();
    }

    const isCharacterRemoved = searchValue.length < previousSearchValueLength;
    // Search the entire menu on decremental searches, debounced
    if (isCharacterRemoved) {
      return debouncedFilterAllMenuSections();
    }

    const isIncrementalSearch = searchValue.length > previousSearchValueLength;
    // Search the subset of menu items on incremental searches, debounced
    if (isIncrementalSearch) {
      return debouncedFilterSubsetMenuItems();
    }

    return () => {
      // Clean up any ongoing debounced calls on update
      debouncedFilterAllMenuSections.cancel();
      debouncedFilterSubsetMenuItems.cancel();
    };
  }, [searchValue]);

  return {
    data: filteredMenuItems.slice(0, 20), // Only return the first 20 results for performance
    isDataEmpty,
    isSearchValueEmpty,
  };
};
