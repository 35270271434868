import { TableColumns } from '../types';

//#region OrderMeta
export const payoutDetailsOrderMeta: TableColumns[] = [
  {
    columnName: 'OrderId',
    translationId: 'Order_id',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'OrderDate',
    translationId: 'Order_date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'OrderType',
    translationId: 'Type',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 30,
  },
  {
    columnName: 'Sales',
    translationId: 'Sales',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'SalesTax',
    translationId: 'Sales_tax',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 45,
  },
  {
    columnName: 'DeliveryCharges',
    translationId: 'Delivery_charges',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
  {
    columnName: 'ServiceChargeAmount',
    translationId: 'Service_charge_title',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 60,
  },
  {
    columnName: 'Tips',
    translationId: 'Tips',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 70,
  },
  {
    columnName: 'Voucher',
    translationId: 'Voucher',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 80,
  },
  {
    columnName: 'FlatFeeExcludingVat',
    translationId: 'Flat_fee',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 90,
  },
  {
    columnName: 'PercentageFeeExcludingVat',
    translationId: 'Percentage_fee',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 100,
  },
  {
    columnName: 'Vat',
    translationId: 'Vat',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 110,
  },
  {
    columnName: 'TotalFees',
    translationId: 'Total_fees',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 120,
  },
  {
    columnName: 'CashFeeChargedToCustomer',
    translationId: 'Cash_fee_charged_to_customer',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 130,
  },
  {
    columnName: 'NetSales',
    translationId: 'Net_sales',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 140,
  },
];
//#endregion

//#region RefundMeta
export const payoutDetailsRefundMeta: TableColumns[] = [
  {
    columnName: 'OrderId',
    translationId: 'Order_id',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'OrderDate',
    translationId: 'Order_date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 5,
  },
  {
    columnName: 'RefundDate',
    translationId: 'Refund_date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'OrderType',
    translationId: 'Type',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 30,
  },
  {
    columnName: 'RefundedAmount',
    translationId: 'refundedAmount',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'RefundedFees',
    translationId: 'Refunded_fees',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
  {
    columnName: 'CashFeeRefundedToCustomer',
    translationId: 'Cash_fee_refunded_to_customer',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 60,
  },
];
//#endregion

//#region ChargebacksMeta
export const payoutDetailsChargebacksMeta: TableColumns[] = [
  {
    columnName: 'OrderId',
    translationId: 'Order_id',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'OrderDate',
    translationId: 'Order_date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'ChargebackDate',
    translationId: 'Chargeback_date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 30,
  },
  {
    columnName: 'ChargebackAmount',
    translationId: 'Chargeback_Amount',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'RefundedFees',
    translationId: 'Refunded_fees',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
];
//#endregion

//#region OtherChargesMeta
export const payoutDetailsOtherChargesMeta: TableColumns[] = [
  {
    columnName: 'ChargeId',
    translationId: 'Id',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'ChargeDate',
    translationId: 'Date',
    columnType: 'DateTime',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'ChargeType',
    translationId: 'Type',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 30,
  },
  {
    columnName: 'ChargeDescription',
    translationId: 'Description',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'Amount',
    translationId: 'Amount',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
];
//#endregion

//#region PayoutsByStoreMeta
export const payoutsByStoreMeta: TableColumns[] = [
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'OnlineSales',
    translationId: 'Online_sales',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 10,
  },
  {
    columnName: 'OnlineSalesTax',
    translationId: 'Online_sales_tax',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 20,
  },
  {
    columnName: 'OnlineDeliveryCharges',
    translationId: 'Online_delivery_charges',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 30,
  },
  {
    columnName: 'Tips',
    translationId: 'Tips',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 40,
  },
  {
    columnName: 'RefundsOnOnlineSales',
    translationId: 'Refunds_on_online_sales',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 50,
  },
  {
    columnName: 'CashFeesChargedToCustomer',
    translationId: 'Cash_fees_charged_to_customer',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 60,
  },
  {
    columnName: 'TotalFlipdishFees',
    translationId: 'Total_Flipdish_fees',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 70,
  },
  {
    columnName: 'TotalChargebacksCost',
    translationId: 'Total_chargebacks_cost',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 80,
  },
  {
    columnName: 'TotalOtherCharges',
    translationId: 'Total_other_charges',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 90,
  },
  {
    columnName: 'OpeningBalance',
    translationId: 'Opening_balance',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 100,
  },
  {
    columnName: 'ClosingBalance',
    translationId: 'Closing_balance',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: false,
    ordinal: 110,
  },
  {
    columnName: 'Amount',
    translationId: 'Payout_amount',
    columnType: 'Decimal',
    isSortable: false,
    isVisible: true,
    ordinal: 120,
  },
];

export const payoutsByStoreDefaultColumns = ['StoreName', 'Amount'];
//#endregion
