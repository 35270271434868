import { AddressApiFactory, GoogleAddress } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const addressApi = createApi(AddressApiFactory);

const newAddressApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const getGoogleAddressQueryKey = 'getGoogleAddressFormatted';

const getFormByCountryQueryKey = 'getFormByCountry';
async function getFormByCountry(countryCode: string, language?: string) {
  try {
    const result = await addressApi.formByCountry(countryCode, language);
    return result.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getCountriesQueryKey = 'getCountries';
async function getCountries(language?: string) {
  try {
    const response = await newAddressApi.get(`/address/countries?language=${language || 'en'}`);
    return response.data.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// #region getFormattedGoogleAddress
export async function getFormattedGoogleAddress(
  Results: google.maps.places.PlaceResult,
  Status: google.maps.GeocoderStatus,
  Language?: string
) {
  try {
    const googleObjectFormattedAddress = {
      Results: [Results],
      Status,
    } as unknown as GoogleAddress;

    const response = await newAddressApi.post(
      `/address/google?language=${Language || 'en'}`,
      googleObjectFormattedAddress
    );
    return response.data.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

export const addressService = {
  getCountriesQueryKey,
  getCountries,
  getFormByCountry,
  getFormByCountryQueryKey,
  getFormattedGoogleAddress,
  getGoogleAddressQueryKey,
};
