import moment from 'moment';
import { initialize, setActiveLanguage } from 'react-localize-redux';
import { Reducer } from 'redux';

export interface ILocaleDateTimeState {
  locale: string;
  time: {
    useMeridiem: boolean;
  };
}
const initialState = getState();

const initializeKey = initialize.toString();
const activeLanguageKey = setActiveLanguage.toString();

const reducerLocaleDateTime: Reducer<ILocaleDateTimeState> = (
  state: ILocaleDateTimeState = initialState,
  action: any
) => {
  switch (action.type) {
    case initializeKey:
      return localeInitState(state, action);
    case activeLanguageKey:
      return localeChangedState(state, action);
    default:
      return state;
  }
};

export default reducerLocaleDateTime;

function getState() {
  return {
    locale: moment.locale(),
    time: {
      useMeridiem: isTimeUsingMeridiem(),
    },
  };
}

function localeInitState(state, action: ReturnType<typeof initialize>) {
  let locale = 'en';
  if (action.payload.options && action.payload.options.defaultLanguage) {
    locale = getLocale(action.payload.options.defaultLanguage);
  }
  setMomentLocale(locale);
  return {
    ...state,
    ...getState(),
  };
}
function localeChangedState(state, action) {
  const locale = getLocale(action.payload);
  setMomentLocale(locale);
  return {
    ...state,
    ...getState(),
  };
}

function setMomentLocale(locale) {
  moment.locale(locale);
  return moment.locale() === locale;
}

function isTimeUsingMeridiem(locale?) {
  const localeData = moment.localeData(locale);
  return localeData.longDateFormat('LT').toLowerCase().indexOf('a') > -1;
}

function getLocale(baseLang: string) {
  if (navigator.languages) {
    const locale = navigator.languages.find((l) => l.indexOf(baseLang) >= 0);
    if (locale) {
      return locale;
    }
  }
  if (navigator.language && navigator.language.indexOf(baseLang) >= 0) {
    return navigator.language;
  }
  return baseLang;
}
