import React from 'react';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { compose, lifecycle, setDisplayName } from 'recompose';

import { setSelected } from '../../actions/store.actions';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import OpeningHourOverrides from '../OpeningHourOverrides/OpeningHourOverrides';
import OpeningHours from '../OpeningHours/OpeningHours';
import StoreBankingSettings from './Banking/StoreBankingSettings';
import DangerZoneList from './DangerZoneSettings/DangerZoneList';
import DeliverySettings from './DeliverySettings/DeliverySettings';
import StoreKioskSettings from './KioskSettings/KioskSettings';
import PreOrderSettings from './PreOrderSettings/components/PreOrderSettings';
import PreOrderSettingsList from './PreOrderSettings/PreOrderSettingsList';
import StoreServiceCharge from './ServiceCharge/StoreServiceCharge';
import { StoreCollectionSettings } from './StoreCollectionSettings';
import { StoreDetails } from './StoreDetails';
import StoreGeneralSettings from './StoreGeneralSettings';
import StoreOrderNotifications from './StoreOrderNotifications/StoreOrderNotifications';
import StoreOrderSettings from './StoreOrderSettings';
import TableSettings from './TableSettings/TableSettings';
import { StoreDetailsRouteParams } from './types';

export const RoutesForStores = ({ path, hideStoreDetails = false }) => (
  <>
    {!hideStoreDetails && <Route exact path={`${path}`} component={StoreDetails} />}
    <Route exact path={`${path}/general`} component={StoreGeneralSettings} />
    <Route exact path={`${path}/openinghours`} component={OpeningHours} />
    <Route exact path={`${path}/openinghouroverrides`} component={OpeningHourOverrides} />
    <Route exact path={`${path}/deliverysettings`} component={DeliverySettings} />
    <Route exact path={`${path}/collection`} component={StoreCollectionSettings} />
    <Route exact path={`${path}/ordersettings`} component={StoreOrderSettings} />
    <Route exact path={`${path}/ordernotifications`} component={StoreOrderNotifications} />
    <Route exact path={`${path}/preordersettings`} component={PreOrderSettingsList} />
    <Route exact path={`${path}/kiosksettings`} component={StoreKioskSettings} />
    <Route exact path={`${path}/servicecharge`} component={StoreServiceCharge} />
    <Route exact path={`${path}/bankingsettings`} component={StoreBankingSettings} />
    <Route exact path={`${path}/dangerzone`} component={DangerZoneList} />
    <Route
      exact
      path={`${path}/preordersettings/:type(collection|delivery)`}
      component={PreOrderSettings}
    />
    <Route exact path={`${path}/tablesettings`} component={TableSettings} />
  </>
);

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (_, ownProps: StoreDetailsRouteParams) => {
  const storeGroupId = Number(ownProps.match.params.storeGroupId);
  const storeId = Number(ownProps.match.params.storeId);
  return (dispatch: ThunkDispatch) => {
    return {
      // selected set in here
      setSelection: () => {
        dispatch(setSelected({ storeGroupId, storeId }));
      },
    };
  };
};

const StoreRoutes = compose<MappedDispatch & StoreDetailsRouteParams, StoreDetailsRouteParams>(
  setDisplayName('StoreRoutes'),
  connect(null, mapDispatchToPropsFactory),
  lifecycle<MappedDispatch & StoreDetailsRouteParams, {}>({
    componentWillMount() {
      this.props.setSelection();
    },
  })
)(({ match }) => {
  return (
    <ErrorBoundary identifier={'store-settings'}>
      <Switch>
        <RoutesForStores path={match.path} />
      </Switch>
    </ErrorBoundary>
  );
});

export default StoreRoutes;
