import React from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Towelie from '../../../../assets/images/towelie.png';
import EmptyComponent from '../../../../ui/EmptyComponent';

const useStyles = makeStyles(() => ({
  table: {
    display: 'block',
    padding: 0,
  },
  tableHeader: {
    paddingLeft: 32,
    display: 'inline-table',
    width: '100%',
  },
  tableCells: {
    height: 56,
    paddingRight: 10,
    paddingLeft: 32,
    minWidth: 130,
  },
  tableBody: {
    display: 'block',
    padding: '50px 48px 56px 72px',
  },
}));

type Props = {
  ariaTitle: TranslationId;
  columns: TranslationId[];
  emptyTitle: TranslationId;
  icon?: string;
  noLink?: boolean;
  subtitle: TranslationId;
  dataFd?: string;
};
const EmptyTable = ({
  ariaTitle,
  columns,
  dataFd,
  emptyTitle,
  icon = Towelie,
  noLink,
  subtitle,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Paper>
        <Table classes={{ root: classes.table }} aria-label={ariaTitle} data-fd={dataFd}>
          <TableHead classes={{ root: classes.tableHeader }}>
            <TableRow>
              {columns.map((col, idx) => (
                <TableCell
                  key={idx}
                  classes={{
                    root: classes.tableCells,
                  }}
                >
                  {<Translate id={col} />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            classes={{
              root: classes.tableBody,
            }}
          >
            <tr>
              <td>
                <EmptyComponent
                  title={emptyTitle}
                  subtitle={subtitle}
                  icon={icon}
                  noLink={noLink}
                />
              </td>
            </tr>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default EmptyTable;
