import {
  HydraDeviceDetails,
  HydraRegistrationRequest,
  HydraStoreData,
  RestApiResultHydraDeviceDetails,
} from '@flipdish/api-client-typescript';

import { action, actionError } from '../../actions/utils';
import {
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
} from '../../layouts/Notify/actions';
import { HydraRegistrationForm } from './Components/AddKioskForm';
import * as kioskService from './Kiosks.services';

export const KIOSKS_LOAD_REQUEST = 'KIOSKS_LOAD_REQUEST';
export const KIOSKS_LOAD_SUCCESS = 'KIOSKS_LOAD_SUCCESS';
export const KIOSKS_LOAD_FAILURE = 'KIOSKS_LOAD_FAILURE';

const updateKioskStores = (kiosk: HydraDeviceDetails) => action('KIOSKS_UPDATE_STORES', kiosk);

export function addKioskStore(deviceId: string, store: HydraStoreData) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const kiosk = state.kiosks.kiosks.find((k) => k.DeviceId === deviceId);

    if (kiosk && kiosk.StoreNames) {
      dispatch(updateKioskStores({ ...kiosk, StoreNames: [...kiosk.StoreNames, store] }));
    }
  };
}

export function removeKioskStore(deviceId: string, storeId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const kiosk = state.kiosks.kiosks.find((k) => k.DeviceId === deviceId);

    if (kiosk && kiosk.StoreNames) {
      dispatch(
        updateKioskStores({
          ...kiosk,
          StoreNames: [...kiosk.StoreNames.filter((s) => s.StoreId !== storeId)],
        })
      );
    }
  };
}

export type GetKiosksRequest = ReturnType<typeof getKiosksRequest>;
export const getKiosksRequest = (page: number, limit: number) =>
  action(KIOSKS_LOAD_REQUEST, { page, limit });

export type GetKiosksSuccess = ReturnType<typeof getKiosksSuccess>;
export const getKiosksSuccess = (response) => action(KIOSKS_LOAD_SUCCESS, response);

export function getKiosks(page: number, limit: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(getKiosksRequest(page, limit));
      const data = await kioskService.getKioskList(appId, 'Kiosk', page, limit);
      dispatch(getKiosksSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}
export const KIOSK_LOAD_SUCCESS = 'KIOSK_LOAD_SUCCESS';
export const getKioskSuccess = (response: RestApiResultHydraDeviceDetails) =>
  action(KIOSK_LOAD_SUCCESS, response);

export function getKiosk(deviceId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      const response = await kioskService.getKioskById(appId, 'Kiosk', deviceId);
      dispatch(getKioskSuccess(response));
    } catch (error) {
      console.log(error);
    }
  };
}

// #region create kiosk
export const KIOSK_CREATE = 'KIOSK_CREATE';
export const KIOSK_CREATE_REQUEST = 'KIOSK_CREATE_REQUEST';
export const KIOSK_CREATE_SUCCESS = 'KIOSK_CREATE_SUCCESS';
export const KIOSK_CREATE_FAILURE = 'KIOSK_CREATE_FAILURE';

export const createKioskRequest = () => action(KIOSK_CREATE_REQUEST);

export type CreateKioskSuccess = ReturnType<typeof createKioskSuccess>;
export const createKioskSuccess = (kiosk: HydraDeviceDetails) =>
  action(KIOSK_CREATE_SUCCESS, kiosk);

export const createKioskFailure = (error: Error, props) =>
  actionError(KIOSK_CREATE_FAILURE, props, error);

export function updateKioskList(kiosk: HydraDeviceDetails) {
  return async (dispatch: ThunkDispatch) => {
    dispatch(createKioskSuccess(kiosk));
  };
}

export function createKiosk(kiosk: HydraRegistrationForm, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    const hydraKiosk: HydraRegistrationRequest = {
      PinCode: kiosk.PinCode as number,
      StoreIds: kiosk.StoreIds.map((id) => Number(id)),
      DeviceName: kiosk.KioskName,
    };
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(createKioskRequest());
      await kioskService.createKiosk(appId, hydraKiosk);

      dispatch(closeNotifySaving());
      dispatch(notifySaved());
    } catch (error) {
      dispatch(createKioskFailure(error, appId));
      dispatch(closeNotifySaving());

      if (error.message === 'Invalid code') {
        dispatch(notifyError({ message: 'Invalid_code', translate: true }));
      } else {
        // We don't want to translate the error message because it can be any error that is not in the dictionary
        dispatch(notifyError({ message: error.message, translate: false }));
      }
      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region unregister kiosk
export const KIOSK_UNREGISTER = 'KIOSK_UNREGISTER';
export const KIOSK_UNREGISTER_REQUEST = 'KIOSK_UNREGISTER_REQUEST';
export const KIOSK_UNREGISTER_SUCCESS = 'KIOSK_UNREGISTER_SUCCESS';
export const KIOSK_UNREGISTER_FAILURE = 'KIOSK_UNREGISTER_FAILURE';

export const unregisterKioskRequest = () => action(KIOSK_UNREGISTER_REQUEST);

export type UnregisterKioskSuccess = ReturnType<typeof unregisterKioskSuccess>;
export const unregisterKioskSuccess = (id: string) => action(KIOSK_UNREGISTER_SUCCESS, id);

export const unregisterKioskFailure = (error: Error, props) =>
  actionError(KIOSK_UNREGISTER_FAILURE, props, error);

export function unregisterKiosk(id: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(unregisterKioskRequest());

      await kioskService.unregisterKiosk(appId, id);
      dispatch(unregisterKioskSuccess(id));
    } catch (error) {
      dispatch(notifyError({ message: error.message, translate: true }));
    }
  };
}
// #endregion

// #region set stores filter
export const SET_STORES_FILTER = 'KIOSK/SET_STORES_FILTER';
export type SetStoresFilter = ReturnType<typeof setStoresFilter>;
export const setStoresFilter = (stores: Array<{ label: string; value: number }>) =>
  action(SET_STORES_FILTER, { stores });
// #endregion

// #region kiosk settings
export const KIOSK_SETTINGS = 'KIOSK_SETTINGS';
export const KIOSK_SETTINGS_REQUEST = 'KIOSK_SETTINGS_REQUEST';
export const KIOSK_SETTINGS_SUCCESS = 'KIOSK_SETTINGS_SUCCESS';
export const KIOSK_SETTINGS_FAILURE = 'KIOSK_SETTINGS_FAILURE';

export const settingsKioskRequest = () => action(KIOSK_SETTINGS_REQUEST);

export type SettingsKioskSuccess = ReturnType<typeof settingsKioskSuccess>;
export const settingsKioskSuccess = () => action(KIOSK_SETTINGS_SUCCESS, {});

export const settingsKioskFailure = (error: Error, props) =>
  actionError(KIOSK_SETTINGS_FAILURE, props, error);

export function settingsKiosk() {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(settingsKioskRequest());

      await kioskService.getKioskSettings();
      dispatch(settingsKioskSuccess());
    } catch (error) {
      dispatch(notifyError({ message: error.message, translate: true }));
    }
  };
}
// #endregion

// #region assign emv to kiosk
export const ASSIGN_EMV_TO_KIOKS = 'ASSIGN_EMV_TO_KIOKS';
export const ASSIGN_EMV_TO_KIOKS_REQUEST = 'ASSIGN_EMV_TO_KIOKS_REQUEST';
export const ASSIGN_EMV_TO_KIOKS_SUCCESS = 'ASSIGN_EMV_TO_KIOKS_SUCCESS';
export const ASSIGN_EMV_TO_KIOKS_FAILURE = 'ASSIGN_EMV_TO_KIOKS_FAILURE';

export const assignEmvToKioskRequest = () => action(ASSIGN_EMV_TO_KIOKS_REQUEST);

export type AssignEmvToKioskSuccess = ReturnType<typeof assignEmvToKioskSuccess>;
export const assignEmvToKioskSuccess = (props) => action(ASSIGN_EMV_TO_KIOKS_SUCCESS, props);

export const assignEmvToKioskFailure = (error: Error, props) =>
  actionError(ASSIGN_EMV_TO_KIOKS_FAILURE, props, error);

export function assignEmv(hydraId: any, emvTerminalId: number, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(assignEmvToKioskRequest());
      await kioskService.assignEmv(appId, hydraId, emvTerminalId);

      dispatch(closeNotifySaving());
      dispatch(notifySaved());
    } catch (error) {
      dispatch(createKioskFailure(error, appId));

      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message, translate: true }));

      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

export const RESET_CARD_READER_EVENTS = 'RESET_CARD_READER_EVENTS';
export const resetKioskBluetoothTerminalUpdated = () => {
  return async (dispatch: ThunkDispatch) => {
    dispatch(action(RESET_CARD_READER_EVENTS));
  };
};
