import {
  LoadDeliveryFailure,
  LoadDeliveryRequest,
  LoadDeliverySuccess,
  UpdateSuccess,
} from '../actions/storeOpeningHours.actions';
import {
  BusinessHoursPeriod,
  BusinessHoursPeriods,
  DeliveryType,
} from '../components/OpeningHours/types';
import * as storeOpeningHoursConstants from '../constants/storeOpeningHours.constants';
import { storeEventConstants } from '../signalr/hub.events';

const {
  LOAD_DELIVERY_REQUEST,
  LOAD_DELIVERY_SUCCESS,
  LOAD_DELIVERY_FAILURE,
  LOAD_PICKUP_REQUEST,
  LOAD_PICKUP_SUCCESS,
  LOAD_PICKUP_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} = storeOpeningHoursConstants;

const { OPENING_HOURS_UPDATED } = storeEventConstants;

export type OpeningHoursState = Readonly<{
  events: Readonly<{
    [storeId: number]: Readonly<{
      lastUpdate: Date;
      lastEvent: Date;
    }>;
  }>;
  data: Readonly<{
    [storeId: number]: Readonly<{
      error?: Error | string;
      Delivery: Readonly<BusinessHoursPeriods>;
      Pickup: Readonly<BusinessHoursPeriods>;
    }>;
  }>;
  Delivery: {
    error?: Error | string;
    data?: Readonly<BusinessHoursPeriods>;
  };
  Pickup: {
    error?: Error | string;
    data?: Readonly<BusinessHoursPeriods>;
  };
}>;

const initialState: OpeningHoursState = {
  events: {},
  data: {},
  Delivery: {},
  Pickup: {},
};

const reducer = (state = initialState, action): OpeningHoursState => {
  switch (action.type) {
    case LOAD_DELIVERY_REQUEST:
      return loadAllRequest(state, action, 'Delivery');
    case LOAD_DELIVERY_SUCCESS:
      return loadAllSuccess(state, action, 'Delivery');
    case LOAD_DELIVERY_FAILURE:
      return loadAllError(state, action, 'Delivery');
    case LOAD_PICKUP_REQUEST:
      return loadAllRequest(state, action, 'Pickup');
    case LOAD_PICKUP_SUCCESS:
      return loadAllSuccess(state, action, 'Pickup');
    case LOAD_PICKUP_FAILURE:
      return loadAllError(state, action, 'Pickup');
    case UPDATE_REQUEST:
      return state;
    case UPDATE_SUCCESS:
      return updateSuccess(state, action);
    case OPENING_HOURS_UPDATED:
      return updateEvent(state, action);
    default:
      return state;
  }
};

export default reducer;

function loadAllRequest(
  state: OpeningHoursState,
  action: LoadDeliveryRequest,
  deliveryType: DeliveryType
): OpeningHoursState {
  const { storeId } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [storeId]: {
        ...state.data[storeId],
        [deliveryType]: {},
      },
    },
    [deliveryType]: {},
  };
}
function loadAllSuccess(
  state: OpeningHoursState,
  action: LoadDeliverySuccess,
  deliveryType: DeliveryType
): OpeningHoursState {
  const businessHoursPeriods = action.payload;
  const { storeId } = action.meta;
  const dictionary = businessHoursPeriods.reduce((dict, bhp: BusinessHoursPeriod) => {
    dict[bhp.DayOfWeek] = {
      DayOfWeek: bhp.DayOfWeek,
      StartTime: bhp.StartTime,
      Period: bhp.Period,
      StartTimeEarly: bhp.StartTimeEarly,
      PeriodEarly: bhp.PeriodEarly,
    };
    return dict;
  }, {});
  return {
    ...state,
    events: {
      ...state.events,
      [storeId]: {
        ...state.events[storeId],
        lastUpdate: new Date(),
      },
    },
    data: {
      ...state.data,
      [storeId]: {
        ...state.data[storeId],
        [deliveryType]: dictionary,
      },
    },
    [deliveryType]: { data: dictionary },
  };
}
function loadAllError(
  state: OpeningHoursState,
  action: LoadDeliveryFailure,
  deliveryType: DeliveryType
): OpeningHoursState {
  const { storeId } = action.meta;
  return {
    ...state,
    data: {
      ...state.data,
      [storeId]: {
        ...state.data[storeId],
        error: action.payload,
        [deliveryType]: {},
      },
    },
    [deliveryType]: { error: action.payload },
  };
}

function updateSuccess(state: OpeningHoursState, action: UpdateSuccess): OpeningHoursState {
  const { storeId, deliveryType, businessHoursPeriod: bhp } = action.payload;

  return {
    ...state,
    events: {
      ...state.events,
      [storeId]: {
        ...state.events[storeId],
        lastUpdate: new Date(),
      },
    },
    data: {
      ...state.data,
      [storeId]: {
        ...state.data[storeId],
        [deliveryType]: {
          ...state.data[storeId][deliveryType],
          [(bhp as BusinessHoursPeriod).DayOfWeek]: {
            DayOfWeek: bhp.DayOfWeek,
            StartTime: bhp.StartTime,
            Period: bhp.Period,
            StartTimeEarly: bhp.StartTimeEarly,
            PeriodEarly: bhp.PeriodEarly,
          },
        },
      },
    },
  };
}

function updateEvent(state: OpeningHoursState, action): OpeningHoursState {
  const { StoreId, CreateTime, BusinessHoursPeriod, DeliveryType } = action.payload;

  if (!state.data[StoreId]) {
    return state;
  }

  return {
    ...state,
    events: {
      ...state.events,
      [StoreId]: {
        ...state.events[StoreId],
        lastEvent: new Date(CreateTime),
      },
    },
    data: {
      ...state.data,
      [StoreId as string]: {
        ...state.data[StoreId],
        [DeliveryType]: {
          ...state.data[StoreId][DeliveryType],
          [action.payload.BusinessHoursPeriod.DayOfWeek]: BusinessHoursPeriod,
        },
      },
    },
  };
}
