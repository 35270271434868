import axios, { CancelTokenSource } from 'axios';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// #region getAuditLogs
export type Params = {
  page: number;
  limit: number;
  start?: string;
  end?: string;
  FlipdishEventId?: string;
};

let cancelToken: CancelTokenSource | null = null;
export const cancelErrorMessage = 'Canceled by user';

export type AuditLog =
  | Flipdish.AppCreatedEvent
  | Flipdish.AppUpdatedEvent
  | Flipdish.CustomerUpdatedEvent
  | Flipdish.CustomerConsentUpdatedEvent
  | Flipdish.StoreBusinessHoursOverrideCreatedEvent
  | Flipdish.StoreBusinessHoursOverrideDeletedEvent
  | Flipdish.UserUpdatedEvent
  | Flipdish.StoreUpdatedEvent;

type RestApiAuditLogs = Flipdish.RestApiPaginationResult<AuditLog>;
export const getAuditLogs = async (appId: string, params: Params) => {
  try {
    if (cancelToken) {
      cancelToken.cancel(cancelErrorMessage);
    }

    cancelToken = axios.CancelToken.source();
    const incomingMessage = await portalApi.get<RestApiAuditLogs>(`/api/v1.0/${appId}/auditlogs`, {
      params,
      cancelToken: cancelToken.token,
    });

    cancelToken = null;

    return incomingMessage.data;
  } catch (e) {
    if (e.message && e.message === cancelErrorMessage) {
      throw e;
    }

    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region getPushNotificationLogs
export type GetPushNotificationParams = {
  appId: string;
  page?: number;
  limit?: number;
  start?: string;
  end?: string;
};

export type RestApiPaginationResult<T> = {
  Data: T;
  Page: number;
  Limit: number;
  TotalRecordCount: number;
};

// #endregion

export const service = {
  getAuditLogs,
};
