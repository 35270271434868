import React, { useMemo, useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Title as SubPageTitle } from '../../../layouts/Portal/SubPage';
import { storeSelectors } from '../../../selectors';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import Legend from './Legend';

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeId }: Props) => {
  const envTimeZones = storeSelectors.envTimeZones(state, { storeId });
  return {
    envTimeZones,
    dtUtils: dateTimeUtils(state),
  };
};

type Props = { storeId: number };
const Title = compose<Props & MappedState, { storeId: number }>(connect(mapStateToProps))((
  props
) => {
  const { envTimeZones, dtUtils } = props;

  const [visible, setVisible] = useState(false);

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const title = useMemo(() => {
    if (!envTimeZones) {
      return null;
    }

    return <SubPageTitle translateId="Opening_hours_title" />;
  }, [envTimeZones, dtUtils]);

  return (
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      alignItems="center"
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item>{title}</Grid>
      {isSmall && (
        <Grid item>
          <IconButton
            aria-label="Info"
            onClick={() => {
              setVisible(true);
            }}
          >
            <InfoIcon color="inherit" fontSize="small" />
          </IconButton>
          <Dialog
            open={visible}
            onClose={() => {
              setVisible(false);
            }}
          >
            <div style={{ padding: '16px' }}>
              <Legend />
            </div>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
});

export default Title;
