import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SearchIcon from '@mui/icons-material/SearchOutlined';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import useKeyPress from '../../custom-hooks/useKeyPress';
import { formatFullDate, formatFullDateWithTime } from '../../helpers/dateFormats';
import RangeDatePicker, { DateValue } from '../../ui/RangeDatePicker/RangeDatePicker';

const regExp = /^(after|before)/;

export function generateFilter(key: string, value: any, withTime?: boolean): FilterValue {
  const type = `${key}:${value}`;
  const label = regExp.test(key)
    ? `${key}:${withTime ? formatFullDateWithTime(Number(value)) : formatFullDate(Number(value))}`
    : type;

  return {
    value,
    type,
    label,
  };
}

const getParamName = (key: string) => {
  if (key === 'after') {
    return 'start';
  }

  if (key === 'before') {
    return 'end';
  }

  if (key === 'Activity') {
    return 'EventType';
  }

  return key;
};

export const getParamsFromFilters = (filters: FilterValue[]) => {
  return filters.reduce((res, filter) => {
    const key = getParamName(filter.type.split(':')[0]);
    const value =
      key !== 'start' && key !== 'end'
        ? filter.value
        : new Date(Number(filter.value)).toISOString();
    const currentValue = res[key];

    if (currentValue) {
      if (Array.isArray(currentValue)) {
        currentValue.push(value);
      } else {
        res[key] = [currentValue, value];
      }
    } else {
      res[key] = value;
    }

    return res;
  }, {});
};

const createDateFilter = (
  value: moment.Moment,
  type: 'after' | 'before',
  withTime?: boolean
): FilterValue => ({
  value: value.valueOf(),
  type,
  label: `${type}:${withTime ? formatFullDateWithTime(value) : formatFullDate(value)}`,
});

const getFilterId = ({ type, value }: FilterValue): string => `${type}/${value}`;

const getDateValues = (filters: FilterValue[]): { after: DateValue; before: DateValue } => {
  const dateFilters = filters.filter((filter) => regExp.test(filter.type));
  const result = { after: null, before: null };

  return dateFilters && dateFilters.length
    ? dateFilters.reduce((res, filter) => {
        res[filter.type.split(':')[0]] = moment(Number(filter.value));
        return res;
      }, result)
    : result;
};

const getFiltersMap = (filters: FilterValue[]) => {
  return filters.reduce((res, filter) => {
    res[getFilterId(filter)] = true;

    return res;
  }, {});
};

const styles = ({
  palette: {
    primary: { main },
  },
}: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
    },
    wrapper: {
      borderRadius: '4px',
      position: 'relative',
      paddingRight: '14px',
      border: 'solid 1px rgba(25, 25, 25, 0.32)',
    },
    wrapperFocused: {
      borderColor: main,
    },
    title: {
      fontSize: '12px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      textTransform: 'capitalize',
    },
    titleFocused: {
      color: main,
    },
    searchIcon: {
      color: 'rgba(0,0,0,0.54)',
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translateY(-60%)',
      top: '50%',
      right: '12px',
    },
    dropdown: {
      width: '100%',
      position: 'absolute',
      top: '100%',
    },
    list: {
      maxHeight: '300px',
      overflow: 'auto',
      borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
    },
    listItem: {
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
      fontSize: '14px',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    modal: {
      width: '280px',
      padding: '24px',
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    input: {
      flexGrow: 1,
    },
    chip: {
      marginRight: '5px',
      marginBottom: '5px',
    },
    nestedChip: {
      marginRight: '5px',
      marginBottom: '5px',
      color: 'rgb(0, 0, 0, 0.6)',
    },
    buttonText: {
      color: main,
    },
    buttonsWrapper: {
      textAlign: 'right',
    },
    inputWrapper: {
      marginBottom: '19px',
    },
  });

export type FilterValue = {
  type: string;
  label: string;
  value: string | number | moment.Moment | string[];
};

type Props = InnerProps & OuterProps;

type InnerProps = MappedProps & WithStyles<typeof styles>;

type OuterProps = {
  value?: FilterValue[];
  options?: FilterValue[];
  placeholderOptions?: FilterValue[];
  isLoading?: boolean;
  placeholder?: string;
  dateFilter?: boolean;
  withTime?: boolean;
  onChange: (params: any) => void;
  onInputChange?: (newValue: string) => void;
  optionsFilterFn?: (option: FilterValue, searchValue: string) => boolean;
};

const Filter = ({
  classes,
  onChange,
  translate,
  options,
  isLoading,
  onInputChange,
  value: filters = [],
  placeholder,
  optionsFilterFn,
  dateFilter,
  withTime,
  placeholderOptions = [],
}: Props) => {
  const dateValues = useMemo(() => getDateValues(filters), [filters]);
  const [focused, setFocused] = useState(false);
  const [before, setBefore] = useState<DateValue | null>(dateValues.before);
  const [after, setAfter] = useState<DateValue | null>(dateValues.after);
  const [opened, setOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filtersMap, setFiltersMap] = useState<{ [key: string]: boolean }>(getFiltersMap(filters));
  const [dateRangeError, setDateRangeError] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const inputRef = useRef<HTMLInputElement>();
  const listRef = useRef<HTMLDivElement>(null);

  const openDropdown = () => setFocused(true);
  const closeDropdown = () => setFocused(false);
  const clickDropdownItem = (option: FilterValue) => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
    closeDropdown();
    addFilter(option);
  };

  const focusHandler = () => {
    openDropdown();
  };
  const blurHandler = () => {
    closeDropdown();
  };
  const updateSearchValue = (value: string) => {
    setSearchValue(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    updateSearchValue(value);
  };
  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      const { key } = event;

      if (key === 'Backspace' && !value && filters.length) {
        removeFilter();
      }
    }
  };
  const openModal = () => {
    setOpened(true);
    closeDropdown();
  };
  const closeModal = () => {
    setOpened(false);
  };
  const handleDatePickerChange = (startDate: DateValue | null, endDate: DateValue | null) => {
    setAfter(startDate);
    setBefore(endDate);
  };
  const setFiltersHandler = (filters: FilterValue[]) => {
    onChange(filters);
  };
  const removeFilter = (filter: FilterValue | undefined = filters[filters.length - 1]) => {
    const index = filters.indexOf(filter);

    if (index >= 0) {
      setFiltersMap({ ...filtersMap, [getFilterId(filter)]: false });
      setFiltersHandler([...filters.slice(0, index), ...filters.slice(index + 1)]);

      if (filter.type === 'after') {
        setAfter(null);
      }

      if (filter.type === 'before') {
        setBefore(null);
      }
    }
  };
  const addFilter = (filter: FilterValue) => {
    setFiltersHandler([...filters, filter]);
    setFiltersMap({ ...filtersMap, [getFilterId(filter)]: true });
    updateSearchValue('');
  };
  const addFilters = (newFilters: FilterValue[]) => {
    setFiltersHandler(
      newFilters.reduce(
        (res, filter) => {
          const type = filter.type.split(':')[0];

          return [...res.filter((item) => item.type.split(':')[0] !== type), filter];
        },
        [...filters]
      )
    );
  };

  const visibleOptions = useMemo(() => {
    return (options || []).filter((option) => {
      return (
        !filtersMap[getFilterId(option)] &&
        (!searchValue ||
          (searchValue &&
            option.label.toLowerCase().includes(searchValue.toLowerCase()) &&
            !optionsFilterFn) ||
          (optionsFilterFn && optionsFilterFn(option, searchValue)))
      );
    });
  }, [searchValue, options, filtersMap]);

  const selectableOptions = useMemo(() => {
    return visibleOptions.filter((option) => !Array.isArray(option.value));
  }, [visibleOptions]);

  const renderOption = (option: FilterValue, index?: number) => {
    const isNested = Array.isArray(option.value);
    const name = option.type.split(':')[0];

    return (
      <MenuItem
        className={classes.listItem}
        key={getFilterId(option)}
        selected={selectedIndex === index}
        onMouseEnter={
          isNested || index === undefined
            ? undefined
            : () => {
                setSelectedIndex(index);
              }
        }
        onClick={
          isNested || index === undefined
            ? undefined
            : () => {
                clickDropdownItem(generateFilter(option.type, option.value, withTime));
              }
        }
      >
        {isNested ? (
          <>
            {name}:
            {(option.value as string[]).map((item) => {
              return (
                <Chip
                  label={item}
                  key={item}
                  className={classes.nestedChip}
                  onClick={() => {
                    clickDropdownItem(generateFilter(name, item, false));
                  }}
                />
              );
            })}
          </>
        ) : (
          <span
            // dangerouslySetInnerHTML it is only used to display date label option, user has no input here
            dangerouslySetInnerHTML={{
              __html: option.label,
            }}
          />
        )}
      </MenuItem>
    );
  };

  const renderedPlaceholderOptions = useMemo(
    () => placeholderOptions.map((option) => renderOption(option)),
    [placeholderOptions]
  );

  const renderedOptions = useMemo(
    () => visibleOptions.map(renderOption),
    [visibleOptions, selectedIndex]
  );

  const renderedFilters = useMemo(() => {
    return filters.map((filter, ind) => (
      <Chip
        key={ind}
        className={classes.chip}
        label={filter.label}
        onDelete={() => {
          removeFilter(filter);
        }}
      />
    ));
  }, [filters]);

  const className = clsx(classes.wrapper, {
    [classes.wrapperFocused]: focused,
  });
  const titleClassName = clsx(classes.title, {
    [classes.title]: true,
    [classes.titleFocused]: focused,
  });
  const buttonClasses = {
    text: classes.buttonText,
  };

  useEffect(() => {
    if (withTime && after && before && after > before) {
      setDateRangeError(translate('End_date_should_be_after_start_date') as string);
    } else if (dateRangeError) {
      setDateRangeError(null);
    }
  }, [after, before, withTime]);

  const keyPressed = useKeyPress({
    el: window,
    keys: ['ArrowDown', 'ArrowUp', 'Enter'],
    active: focused,
  });

  useEffect(() => {
    if (keyPressed.key === 'ArrowDown' && selectedIndex < selectableOptions.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }

    if (keyPressed.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }

    if (keyPressed.key === 'Enter' && visibleOptions[selectedIndex]) {
      const option = visibleOptions[selectedIndex];

      clickDropdownItem(generateFilter(option.type, option.value, withTime));
    }
  }, [keyPressed]);

  useLayoutEffect(() => {
    const { current } = listRef;

    if (current) {
      const selectedItem = current.querySelector<HTMLLIElement>(
        `li:nth-child(${selectedIndex + 1})`
      );

      if (selectedItem) {
        const { top: listTop } = current.getBoundingClientRect();
        const { top } = selectedItem.getBoundingClientRect();

        if (listTop + current.offsetHeight < top + selectedItem.offsetHeight) {
          current.scrollTop = top + selectedItem.offsetHeight;
        } else if (listTop > top) {
          current.scrollTop = current.scrollTop - selectedItem.offsetHeight;
        }
      }
    }
  }, [selectedIndex]);

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={blurHandler}>
        <div>
          <fieldset className={className}>
            <legend className={titleClassName}>filters</legend>
            <div className={classes.content}>
              <div>{renderedFilters}</div>
              <div className={classes.input}>
                <InputBase
                  fullWidth
                  value={searchValue}
                  inputRef={inputRef}
                  onChange={changeHandler}
                  onKeyDown={keyDownHandler}
                  placeholder={filters.length ? '' : placeholder}
                  onFocus={focusHandler}
                />
              </div>
            </div>
            <SearchIcon className={classes.searchIcon} onClick={focusHandler} />
          </fieldset>
          {focused && (
            <div className={classes.dropdown}>
              <Paper>
                <div ref={listRef} className={classes.list}>
                  {!isLoading && renderedOptions.length
                    ? renderedOptions
                    : options &&
                      (placeholderOptions.length ? (
                        renderedPlaceholderOptions
                      ) : (
                        <MenuItem className={classes.listItem}>
                          {isLoading ? translate('Loading') : translate('No_option')}
                        </MenuItem>
                      ))}
                </div>
                {dateFilter && (
                  <MenuItem className={classes.listItem} onClick={openModal}>
                    {translate('Date_filter_title')}
                  </MenuItem>
                )}
              </Paper>
            </div>
          )}
        </div>
      </ClickAwayListener>
      {dateFilter && (
        <Dialog open={opened} onClose={closeModal}>
          <Paper className={classes.modal}>
            <RangeDatePicker
              startDate={after}
              endDate={before}
              onChange={handleDatePickerChange}
              format={withTime ? 'L LT' : undefined}
              withTime={withTime}
            />
            {dateRangeError && <FormHelperText error>{dateRangeError}</FormHelperText>}
            <div className={classes.buttonsWrapper}>
              <Button data-fd="dialog-cancel" classes={buttonClasses} onClick={closeModal}>
                {translate('Cancel')}
              </Button>
              <Button
                data-fd="dialog-submit"
                classes={buttonClasses}
                disabled={(!after && !before) || !!dateRangeError}
                onClick={() => {
                  const filters: FilterValue[] = [];

                  if (after) {
                    filters.push(createDateFilter(after, 'after', withTime));
                  }

                  if (before) {
                    filters.push(createDateFilter(before, 'before', withTime));
                  }

                  if (filters.length) {
                    addFilters(filters);
                  }

                  closeModal();
                }}
              >
                {translate('Set')}
              </Button>
            </div>
          </Paper>
        </Dialog>
      )}
    </div>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = ({ locale }) => ({
  translate: getTranslate(locale),
});

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps),
  withStyles(styles)
)(Filter);
