import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import debounce from 'lodash/debounce';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

const styles = () => ({
  input: {
    borderColor: '#000',
    borderRadius: '4px',
    display: 'flex',
    '& >div:nth-child(2) span': {
      display: 'none',
    },
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
  },
});

type FilterProps = {
  search: string;
  onChange: (query) => void;
  classes: any;
};

type FilterState = {
  searchTerm: string;
};

class OAuthAppsFilter extends React.Component<FilterProps, FilterState> {
  private debounceChange;
  public constructor(props) {
    super(props);
    this.state = { searchTerm: this.props.search };
  }

  public debouncedSearch = (query) => {
    if (this.debounceChange) {
      this.debounceChange.cancel();
    }
    this.debounceChange = debounce(() => {
      this.props.onChange(query);
    }, 500);
    this.debounceChange();
  };

  public handleSearch = (e) => {
    const { value } = e.target;
    this.setState({ searchTerm: value });
    this.debouncedSearch(value);
  };

  public clearSearch = () => {
    this.setState({ searchTerm: '' });
    this.props.onChange('');
  };

  public render() {
    const { classes, search } = this.props;
    return (
      <TextField
        value={this.state.searchTerm}
        className={classes.input}
        placeholder="Search for app name"
        fullWidth
        margin="dense"
        variant="outlined"
        onChange={this.handleSearch}
        inputProps={{ 'data-fd': 'Oauth_search_filter' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search ? (
                <IconButton onClick={this.clearSearch}>
                  <CancelIcon className={classes.searchIcon} />
                </IconButton>
              ) : (
                <SearchIcon className={classes.searchIcon} />
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    translate: getTranslate(state.locale),
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'OAuthAppsFilter',
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(OAuthAppsFilter);

export default EnhancedComponent;
