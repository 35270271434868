import React, { useEffect, useMemo, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalize, snakeCase } from 'lodash';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { compose } from 'recompose';

import { defaultBarTheme, errorBarTheme, getChartHeight, getMaxValue } from '../../helpers';
import { CountProp } from '../../types';
import ChartTitle from './ChartTitle';
import CustomTooltip from './CustomTooltip';

const CustomLabel = (args) => {
  const { x, y, count, width } = args;
  if (count) {
    const val = count.toFixed(0);
    /** Note: This is to offset the label so it is positioned after the bar
     * we use 8px * length so the gap between the bar and the label is
     * the same if the label reads "0" or "99999999"
     */
    const xOffset = x + width + val.length * 8;
    const yOffset = y + 8; // 8px vertical padding
    return (
      <text
        fontSize={11}
        x={xOffset}
        y={yOffset}
        fill="rgba(0,0,0,0.6)"
        textAnchor="end"
        dominantBaseline="central"
      >
        {count.toFixed(0)}
      </text>
    );
  } else return;
};

type DisplayType = 'desktop' | 'tablet' | 'mobile';

type OuterProps = {
  currency?: string;
  CurrentPeriod?: CountProp[];
  PreviousPeriod?: CountProp[];
  inView: boolean;
  barTheme?: 'default' | 'error'; // Color scheme of the barChart. Defaults to "default"
  title?: string;
  formatTickValue: (tick: number) => string;
  dateString: string;
};
type InnerProps = { translate: (text) => string; activeLanguage: string };
type Props = InnerProps & OuterProps;

const BarChart2: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    activeLanguage,
    barTheme = 'default',
    currency,
    CurrentPeriod,
    dateString,
    inView,
    PreviousPeriod,
    title,
    translate,
  } = props;
  const [maxValue, setMaxValue] = useState<number>();

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const getDisplayType = (): DisplayType => {
    if (isMobile) return 'mobile';
    if (isDesktop) return 'desktop';
    return 'tablet';
  };

  const displayType = getDisplayType();
  const barWidth = 16;
  const labelFontSize = 12;

  const chartHeight = useMemo(
    () =>
      getChartHeight(CurrentPeriod?.length as number, PreviousPeriod?.length as number, barWidth),
    [barWidth]
  );

  const theme = useMemo(
    () => (barTheme === 'default' ? defaultBarTheme : errorBarTheme),
    [barTheme]
  );

  useEffect(() => {
    if (inView) {
      getDataMaxValue(CurrentPeriod, PreviousPeriod);
    }
  }, [CurrentPeriod, PreviousPeriod, inView]);

  const getDataMaxValue = (currentPeriod?: CountProp[], previousPeriod?: CountProp[]) => {
    const value = getMaxValue(currentPeriod, previousPeriod);
    setMaxValue(value);
  };

  const maxValueW = maxValue && ((maxValue * 1.3) / 100) * 100;

  const serializeData = (
    currData?: CountProp[],
    prevData?: CountProp[]
  ):
    | { index: number; x: TranslationId; y?: number; z?: number; label: TranslationId }[]
    | undefined => {
    /**
     * We use a starting index to make sure incomplete series
     * start where they should on the graph
     */

    if (currData?.length) {
      return currData.map((d, i) => {
        const prevY = prevData && prevData.length ? prevData[i].count : null;
        const previousValue = prevData && prevData.length ? prevData[i].value : null;
        const translationId = capitalize(snakeCase(d.key)) as TranslationId;
        return {
          index: i,
          x: translationId,
          y: d.count, // y is specified by the dataKey on the Labelist - that's how it displays it
          z: prevY as number,
          label: translationId,
          currentValue: d.value,
          previousValue,
        };
      });
    }
  };

  const getTickFormat = (t) => {
    return translate(t.replace(/^\w/, (c) => c.toUpperCase()));
  };

  const chartData = useMemo(
    () => serializeData(CurrentPeriod, PreviousPeriod),
    [CurrentPeriod, PreviousPeriod]
  );

  if (maxValueW === undefined) {
    return <div />;
  }

  const barCategoryGap = 0;
  const horizontalPoints = Array.from(Array(CurrentPeriod?.length).keys()).map(
    (a) => a * (PreviousPeriod?.length ? 50 : 40) + 9
  );

  return (
    <div
      style={{
        width: '100%',
        margin: '16px 0',
        height: chartHeight + 32,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ChartTitle displayType={displayType} title={title} />
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={chartData}
          margin={{
            top: 8,
            right: 16,
            left: 16,
            bottom: 8,
          }}
          layout="vertical"
          barCategoryGap={barCategoryGap}
        >
          <XAxis
            scale="linear"
            tickLine={false}
            //@ts-ignore
            style={{
              fontSize: labelFontSize,
              fontFamily: 'Roboto',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: 'normal',
              letterSpacing: '0.2px',
              fill: 'rgba(0, 0, 0, 0.6)',
            }}
            type="number"
            dataKey="y"
            tickFormatter={(t) => (t > 0 && Number.isInteger(t) ? t : '')}
            domain={[0, maxValueW]}
          />
          <Tooltip
            wrapperStyle={{ zIndex: 1 }}
            cursor={{ fill: '#f2f2f2' }}
            content={
              <CustomTooltip
                currency={currency}
                section={title}
                dateString={dateString}
                translate={translate}
                activeLanguage={activeLanguage}
              />
            }
          />
          <CartesianGrid vertical={false} horizontalPoints={horizontalPoints} />
          <CartesianGrid vertical={false} />
          <YAxis
            tickLine={false}
            //@ts-ignore
            style={{
              fontSize: labelFontSize,
              fontFamily: 'Roboto',
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: 'normal',
              letterSpacing: '0.2px',
              fill: 'rgba(0, 0, 0, 0.6)',
            }}
            type="category"
            dataKey="x"
            mirror={true}
            axisLine={false}
            orientation="right"
            tickFormatter={getTickFormat}
          />
          <Bar dataKey="y" barSize={barWidth} minPointSize={3}>
            <LabelList dataKey="y" position="right" content={CustomLabel} />
            {chartData &&
              chartData.map((entry, index: number) => (
                <Cell
                  key={index}
                  fill={theme(index + 1).primary.fill}
                  stroke={theme(index + 1).primary.stroke}
                />
              ))}
          </Bar>

          {PreviousPeriod?.length ? (
            <Bar dataKey="z" barSize={barWidth} minPointSize={3} strokeDasharray="3 5">
              <LabelList dataKey="z" position="right" content={CustomLabel} />
              {chartData &&
                chartData.map((entry, index: number) => (
                  <Cell
                    key={index}
                    fill={theme(index + 1).secondary.fill}
                    stroke={theme(index + 1).secondary.stroke}
                  />
                ))}
            </Bar>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
    activeLanguage: getActiveLanguage(state.locale),
  };
};

export default compose<Props, OuterProps>(connect(mapStateToProps))(BarChart2);
