import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

import GenericTableTitle from './GenericTableTitle';

const useChartStyles = makeStyles(({ breakpoints }: Theme) => ({
  '@keyframes skeleton': {
    '0%': {
      backgroundPosition: '-200px 0',
    },
    '100%': {
      backgroundPosition: 'calc(200px + 100%) 0',
    },
  },
  root: {
    width: '100%',
    [breakpoints.down('md')]: {
      borderRadius: 0,
      boxShadow: 'none',
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    },
    padding: '8px',
    margin: '8px 0px 16px 0px',
  },
  chart: {
    margin: 8,
    height: 205,
    position: 'relative',
    [breakpoints.down('md')]: {
      height: 'auto',
      paddingTop: '32%',
    },
  },
  skeleton: {
    backgroundColor: '#eee',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'linear-gradient( 90deg, #eee, #f5f5f5, #eee )',
    animation: '1.2s ease-in-out 0s infinite normal none running $skeleton',
  },
  message: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '22px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

export const titleSkeleton = <Skeleton width={'30%'} height={22} />;

export const ReportTableSkeleton = ({
  loaded,
  title,
  subTitle,
  message = 'Report_no_data_available',
}: {
  title?: TranslationId;
  loaded?: boolean;
  subTitle?: string;
  message?: TranslationId;
}) => {
  const classes = useChartStyles();

  const renderHeader = () => {
    return <GenericTableTitle title={title} subTitle={subTitle} />;
  };

  return (
    <div>
      {renderHeader()}
      <Card className={classes.root}>
        {!loaded ? <CardHeader title={titleSkeleton} /> : null}
        <div className={`${classes.chart} ${loaded ? '' : classes.skeleton}`}>
          {loaded ? (
            <div className={classes.message}>
              <Translate id={message} />
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default ReportTableSkeleton;
