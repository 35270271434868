import React from 'react';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import TextField from '@fd/ui/TextField/TextField';
import { copyToClipboard } from '@fd/ui/utils';

import { type NotifyProps, notify, notifyError } from '../../../../../layouts/Notify/actions';

const StyedIconButton = styled(IconButton)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

const SettingsOrgId = ({
  dispatchNotifyError,
  dispatchNotifySaved,
  orgId,
  translate,
}: MappedProps & MappedDispatch) => {
  const handleOnClick = async (e) => {
    try {
      await copyToClipboard(e, 'orgId');
      dispatchNotifySaved(`${orgId} ${translate('copied_to_clipboard')}`);
    } catch (e) {
      dispatchNotifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  };
  return (
    <FormItemLayout label={<Translate id={'Organisation_ID'} />}>
      <TextField
        variant="standard"
        value={orgId ?? ''}
        fdKey={'orgId-field'}
        InputProps={{
          id: 'orgId',
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <StyedIconButton aria-label="copy orgId to clipboard">
                <CopyIcon fontSize={'small'} />
              </StyedIconButton>
            </InputAdornment>
          ),
        }}
        contentEditable={false}
        fullWidth
        style={{ paddingRight: 16 }}
        inputProps={{
          style: {
            paddingTop: 0,
            height: '1.1876em',
          },
        }}
        onClick={handleOnClick}
      />
    </FormItemLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: (savedStringKey: string) =>
    dispatch(notify({ message: savedStringKey, variant: 'success' })),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  orgId: state.rms.currentOrg?.orgId,
  translate: getTranslate(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsOrgId);
