import React, { useState } from 'react';

import { GroupReference, Product } from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Pagination } from '@fd/ui/atoms/Pagination';
import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import { ListBox } from '@fd/ui/molecules/ListBox';

import { flagService } from '../../../../../services';
import { ProductContainsGroupDialog } from '../ProductContainsGroupDialog';

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    marginLeft: theme.spacing(7),
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  disabledItem: {
    backgroundColor: theme.palette.grey[100],
  },
  itemTextContainer: {
    display: 'flex',
    flex: 1,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rightText: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    marginRight: theme.spacing(7),
  },
  rightTextMargin: {
    marginRight: theme.spacing(0),
  },
}));

export type CatalogItemAdditionBoxProps = {
  alreadyIncludedItems: Product[];
  isLoading?: boolean;
  limit: number;
  listItems?: Product[];
  onAddItem: (item: Product) => void;
  onPageChange: (selected) => void;
  page: number;
  total: number;
  translate: TranslateFunction;
};

const CatalogItemAdditionBox = (props: CatalogItemAdditionBoxProps & MappedState): JSX.Element => {
  const {
    alreadyIncludedItems,
    isLoading = false,
    limit,
    listItems = [],
    isNestedModifiersOn,
    onAddItem,
    onPageChange,
    page,
    total = 0,
    translate,
  } = props;
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handlePageChange = (event: any, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const hasGroups = (groups?: GroupReference[]) => {
    return groups && groups.length > 0;
  };

  const isIncluded = (id?: string) => {
    return id && alreadyIncludedItems.findIndex((x) => x.CatalogItemId === id) !== -1;
  };
  const isDisabled = (item: Product) => {
    if (isNestedModifiersOn) {
      return isIncluded(item.CatalogItemId);
    }
    return isIncluded(item.CatalogItemId) || (item.Groups && item.Groups.length > 0);
  };

  const getNotIncludedSubText = (type: Product.ProductTypeEnum) => {
    return type === Product.ProductTypeEnum.Modifier ? translate('Modifier') : translate('Product');
  };

  const getRightSideText = (item: Product) => {
    if (isIncluded(item.CatalogItemId)) {
      return translate('Already_in_group');
    }
    if (hasGroups(item.Groups) && !isNestedModifiersOn) {
      return translate('Contains_modifier_groups');
    }
    return item.Price.toFixed(2);
  };

  const renderListItems = () => {
    return listItems.map((item: Product, index) => {
      const itemDisabled = isDisabled(item);
      const containsGroups = hasGroups(item.Groups);
      const secondaryText = getNotIncludedSubText(item.ProductType);
      const rightSideText = getRightSideText(item);
      const listItemStyles = clsx(classes.listItem, itemDisabled && classes.disabledItem);
      const rightSideTextStyles = clsx(classes.rightText, itemDisabled && classes.rightTextMargin);
      return (
        <ListItem
          className={listItemStyles}
          key={item.CatalogItemId}
          divider={index < listItems.length - 1}
          {...(containsGroups && !isNestedModifiersOn && { onClick: () => setIsModalOpen(true) })}
        >
          <div className={classes.itemTextContainer}>
            <ListItemText primary={item.Name} secondary={secondaryText} />
            <ListItemText className={rightSideTextStyles} primary=" " secondary={rightSideText} />
          </div>
          {!itemDisabled && (
            <ListItemSecondaryAction>
              <IconButton
                data-fd={`add-item-${item.CatalogItemId}-button`}
                onClick={() => onAddItem(item)}
              >
                <Add className={classes.addIcon} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    });
  };

  return (
    <>
      <ProductContainsGroupDialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        translate={translate}
      />
      {isLoading ? (
        <SkeletonLoader
          fdKey="modifier-list-skeleton-loader"
          rows={[{ height: '340px', width: '100%' }]}
        />
      ) : (
        <ListBox
          noItemsMessage={translate('ModifierGroups_NoModifierGroupsMessage_Title') as string}
          renderListItems={renderListItems}
          isEmpty={listItems.length === 0}
        />
      )}
      <Pagination
        count={total}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={handlePageChange}
      />
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    isNestedModifiersOn: flagService.isFlagOn(state, 'nestedModifiers'),
  };
};

const EnhancedComponent = connect(mapStateToProps)(CatalogItemAdditionBox);
export { EnhancedComponent as CatalogItemAdditionBox };
