import React from 'react';

import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { setDrawerClosed } from '../../../actions/drawer.actions';
import checklistIcon from '../../../assets/images/checklist.svg';
import { isFlipdishGlobal } from '../../../selectors/app.selector';

type TutorialButtonProps = {
  tutorialIsOpen?: boolean;
  setTutorialIsOpen: (boolean) => void;
  hasNotification?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  notification: {
    position: 'absolute',
    backgroundColor: '#05149E',
    right: 5,
    top: 5,
    height: 8,
    width: 8,
    borderRadius: 4,
  },
}));

type Props = MappedDispatch & TutorialButtonProps & MappedState;

const TutorialButton = ({
  isFlipdishGlobal,
  tutorialIsOpen,
  setTutorialIsOpen,
  hasNotification,
  setDrawer,
}: Props) => {
  const classes = useStyles();

  if (isFlipdishGlobal) {
    return null;
  }

  return (
    <Permissions allowed={['Owner']}>
      <IconButton
        onClick={() => {
          setTutorialIsOpen(!tutorialIsOpen);
          setDrawer();
        }}
        data-fd="tutorial-button"
        className={classes.wrapper}
      >
        <img src={checklistIcon} alt="checklist-icon-for-tutorial-button" />
        {hasNotification && <div className={classes.notification}></div>}
      </IconButton>
    </Permissions>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    isFlipdishGlobal: isFlipdishGlobal(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setDrawer: () => dispatch(setDrawerClosed()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialButton);
