import React, { useEffect, useState } from 'react';

import {
  BankAccountCreate,
  BankAccountDetailsUpdateRequest,
  StoreGroupExtended,
} from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { compose } from 'recompose';

import { storeGroupActions } from '../../../../actions/storegroup.actions';
import { displayStripeFeature } from '../../../../selectors/app.selector';
import { getStoreGroups } from '../../../../selectors/storegroup.selector';
import Button from '../../../../ui/Button/Button';
import YesNoDialog from '../../../../ui/Dialog/YesNoDialog';
import { bankingSelectors, getAccountDetailsUrlFactory } from '../banking.selectors';
import { nonVATSupportedCountries } from '../constants';
import BankAddressFormFields from './fields/BankAddressFormFields';
import BankHolderAddressFormFields from './fields/BankHolderAddressFormFields';
import DynamicFormFields from './fields/DynamicFormFields';
import GridDivider from './GridDivider';
import PreventNavigation from './PreventNavigation';

export type EditAccountFormProps = {
  submit: (changes) => Promise<void>;
  onSubmitSuccess: (flag: boolean) => void;
  account: BankAccountCreate;
  accountId: number;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (
  props: EditAccountFormProps & MappedProps
): BankAccountDetailsUpdateRequest => {
  const { account } = props;

  const defaultState = {
    AccountName: account.AccountName || '',
    AccountHolderAddress: account.AccountHolderAddress || '',
    AccountHolderCountryCode: account.AccountHolderCountryCode || '',
    BankName: account.BankName || '',
    BankAddress: account.BankAddress || '',
    BankCountryCode: account.BankCountryCode || '',
    VatNumber: account.VatNumber || '',
    StoreIds: account.StoreIds || [],
    BusinessType: account.BusinessType || BankAccountCreate.BusinessTypeEnum.Company,
    PopulatedAccountFields: account.PopulatedAccountFields || [],
  };
  //@ts-ignore
  return defaultState;
};

type innerProps = MappedProps & MappedDispatch & FormikProps<FormValues>;
type outerProps = EditAccountFormProps;
type Props = innerProps & outerProps;

const EditAccountForm = (props: Props) => {
  const {
    loadAllForApp,
    selectedCountry,
    isSubmitting,
    isValid,
    handleSubmit,
    validateForm,
    countryFields,
  } = props;
  const [cancelEdit, setCancelEdit] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    isValid ? setOpen(true) : setOpen(false);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  useEffect(() => {
    loadAllForApp()
      .then(() => setIsReady(true))
      .then(() => validateForm());
  }, []);

  if (cancelEdit && props.bankingRoute) {
    return <Redirect to={props.bankingRoute as string} />;
  }
  return (
    <>
      <Form>
        <PreventNavigation when={props.dirty} />
        <Grid container>
          <Grid item xs={12}>
            <BankAddressFormFields />
          </Grid>
          <GridDivider />
          {isReady ? <DynamicFormFields countryFields={countryFields?.FieldDefinitions} /> : null}
          <GridDivider />
          <Grid item xs={12}>
            <BankHolderAddressFormFields
              nonVATCountry={nonVATSupportedCountries}
              country={selectedCountry as string}
            />
          </Grid>
          <GridDivider />
          <Grid item xs={12}>
            <Box mt={3}>
              <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    fdKey="btn-bank-account-edit-cancel"
                    variant="outlined"
                    onClick={() => setCancelEdit(true)}
                    disabled={isSubmitting}
                  >
                    <Translate id="Cancel" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={openDialog}
                    color="primary"
                    fdKey="btn-bank-account-edit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    <Translate id="Save_details" />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Form>
      <YesNoDialog
        open={open}
        onYes={handleSubmit}
        onNo={closeDialog}
        disabled={isSubmitting}
        yesTextId={'Confirm'}
        titleTextId={'Are_you_sure'}
        yesButtonVariant="contained"
      >
        <Typography variant="body1" color="textSecondary">
          <Translate id="You_are_about_to_update_your_bank_account_details" />
          <br />
          <Translate id="If_you_entered_incorrect_details_we_may_not_be_able_to_pay" />
        </Typography>
      </YesNoDialog>
    </>
  );
};

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
function mapStateToPropsFactory(state: AppState, ownProps: EditAccountFormProps) {
  const accountId = Number(ownProps.accountId);
  const selectedCountry = ownProps.account.BankCountryCode;
  const getAccountDetailsUrl = getAccountDetailsUrlFactory(accountId);
  const countryFields = bankingSelectors.getCountriesWithFieldDefinitions(
    selectedCountry as string,
    state
  );
  const storeGroups = getStoreGroups(state);
  const currency = storeGroups.length
    ? storeGroups[0].Currency
    : StoreGroupExtended.CurrencyEnum.EUR;
  return (state) => {
    const bankingRoute = getAccountDetailsUrl(state);
    return {
      bankingRoute,
      selectedCountry,
      displayStripeFeature: displayStripeFeature(state),
      storeGroups,
      currency,
      countryFields,
    };
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    loadAllForApp: () => dispatch(storeGroupActions.loadAllForApp({})),
  };
}

export default compose<innerProps, outerProps>(
  connect(mapStateToPropsFactory, mapDispatchToProps),
  withFormik<Props, FormValues>({
    displayName: 'EditAccountForm',
    mapPropsToValues: (props) => {
      return getDefaultFormState(props);
    },
    handleSubmit: (values, formikBag) => {
      const bAccount: BankAccountDetailsUpdateRequest = {
        ...values,
      };
      formikBag.props
        .submit(bAccount)
        .then(() => {
          formikBag.resetForm({ values: values }); // reset dirty state
          formikBag.props.onSubmitSuccess(true);
        })
        .catch((err) => {
          formikBag.setSubmitting(false);
        });
    },
    validate: (values, props) => {
      const errors: { [K in keyof BankAccountDetailsUpdateRequest]: string } = {};
      return errors;
    },
  })
)(EditAccountForm);
