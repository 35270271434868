import React from 'react';

import MuiTypography, {
  type TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';

export interface TypographyProps extends MuiTypographyProps {
  children: React.ReactNode;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'caption'
    | 'button'
    | 'inherit';
  className?: string;
}

export const Typography = (props: TypographyProps): JSX.Element => {
  const { children, className = '', variant = 'body1', ...rest } = props;
  return (
    <MuiTypography className={className} variant={variant} {...rest}>
      {children}
    </MuiTypography>
  );
};
