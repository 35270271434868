import { StoreHeader } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

//#region Details
const customerDetailsSelector = (state: AppState) => state.customers.customerDetails;

export const getCustomerDetailsSelector = createSelector(
  [customerDetailsSelector],
  (customerDetails) => customerDetails
);

export const customerDetailsLoadingSelector = (state: AppState) =>
  state.customers.customerDetailsLoading;

export const customerStatusSelector = (state: AppState) => state.customers.customerStatus;
export const customerStatusLoadingSelector = (state: AppState) =>
  state.customers.customerStatusLoading;
//#endregion

//#region Order values
const customerOrderValuesSelector = (state: AppState) => state.customers.customerOrderValues;

export const getCustomerOrderValuesSelector = createSelector(
  [customerOrderValuesSelector],
  (customerOrderValues) => customerOrderValues
);

export const customerOrderLoadingSelector = (state: AppState) =>
  state.customers.customerOrderValuesLoading;
//#endregion

//#region Timeline
const customerJourneySelector = (state: AppState) => state.customers.customerJourney;

export const getCustomerJourneySelector = createSelector(
  [customerJourneySelector],
  (customerJourney) => customerJourney
);
//#endregion

//#region Store Headers
const customerManagementStores = (state: AppState) => state.customers.stores;

export const getStoreHeadersList = createSelector([customerManagementStores], (storeHeaders) => {
  if (storeHeaders) {
    return storeHeaders;
  } else {
    return [] as StoreHeader[];
  }
});
//#region

//#region  loaders
export const currencyFilterLoadingSelector = (state: AppState) =>
  state.reports.orderReports.storeCurrencyFilterLoading;

export const customersTableLoadingSelector = (state: AppState) =>
  state.customers.customersTableLoading;
//#endregion
