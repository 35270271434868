import { get, isEmpty, isEqual, isObject, transform } from 'lodash';

export function patch(object, base) {
  return { ...base, ...object };
}

export function isObjectEmpty(object): boolean {
  return isEmpty(object);
}

export function getSafe(object, base, path) {
  const original = get(object, path);
  const safe = get(base, path);
  const willReturn = original !== undefined ? original : safe;
  return willReturn !== null ? willReturn : undefined;
}
