import constants from '../constants.json';
import loadPolyfills from './polyfills';

globalThis.nameof = <T>(name: keyof T): string => name as string;
globalThis.assert = (expr: boolean, msg: string): void => {
  if (!expr) {
    console.error('assert', msg);
  }
};

export default () => {
  if (console && console.log) {
    console.log(
      `Branch:${constants.GIT_BRANCH}\nHash:${constants.GIT_SHA}\nBuild No.:${constants.VERSION}`
    );
  }

  if (process.env.NODE_ENV === 'development') {
    if (/iPhone|iPad|iPod|Android|Windows Phone|webOS|BlackBerry/i.test(navigator.userAgent)) {
      window.onerror = (error) => {
        alert(error);
      };
    }
  }

  loadPolyfills();
};
