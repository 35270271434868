import React from 'react';

import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import Settings from '../../Settings/Organisation/Settings/Settings';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
import AddOrganisation from './AddOrganisation';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const Organization = lazyWithRetry(() => import('./Organization'));

const OrganizationRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  const baseUrlFranchisors = `configurations/franchisors`;
  const baseUrlSystem = `configurations/system`;
  return (
    <ErrorBoundary identifier="Organization-settings">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Organization />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/settings`}
          render={() => (
            <LazyComponent>
              <Settings />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Operating_Configuration" />}
                module={RMSModules.OPERATING_CONFIGURATION}
                url={`${baseUrlFranchisors}/${RMSModules.OPERATING_CONFIGURATION}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.LOCALIZATION_CONFIGURATION)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Localization_Configuration" />}
                module={RMSModules.LOCALIZATION_CONFIGURATION}
                url={`${baseUrlFranchisors}/${RMSModules.LOCALIZATION_CONFIGURATION}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.CHARGES_CONFIGURATION)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Charges_Configuration" />}
                module={RMSModules.CHARGES_CONFIGURATION}
                url={`${baseUrlFranchisors}/${RMSModules.CHARGES_CONFIGURATION}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.KITCHEN_STATION_PROFILES)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Kitchen_Station_Profiles" />}
                module={RMSModules.KITCHEN_STATION_PROFILES}
                url={`${baseUrlSystem}/${RMSModules.KITCHEN_STATION_PROFILES}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/terminology`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Terminology" />}
                module={RMSModules.NOMENCLATURES}
                url={`${baseUrlSystem}/${RMSModules.NOMENCLATURES}`}
              />
            </LazyComponent>
          )}
        />
        <Permissions allowed={['FlipdishStaff']}>
          <Route
            exact
            path={`${path}/${toKebabCase(RMSModules.LICENSE)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  includePropertyIdInUrl
                  title={<Translate id="License" />}
                  module={RMSModules.LICENSE}
                  url={`${baseUrlFranchisors}/properties/${RMSModules.LICENSE}`}
                />
              </LazyComponent>
            )}
          />
          <Route exact path={`${path}/add`} component={AddOrganisation} />
          <Route
            exact
            path={`${path}/${toKebabCase(RMSModules.DB_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="DB_Configuration" />}
                  module={RMSModules.DB_CONFIGURATION}
                  url={`${baseUrlFranchisors}/${RMSModules.DB_CONFIGURATION}`}
                />
              </LazyComponent>
            )}
          />
          <Route
            exact
            path={`${path}/${toKebabCase(RMSModules.API_INTEGRATIONS)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="API_Integration" />}
                  module={RMSModules.API_INTEGRATIONS}
                  url={`${baseUrlFranchisors}/${RMSModules.API_INTEGRATIONS}`}
                />
              </LazyComponent>
            )}
          />
          <Route
            exact
            path={`${path}/${toKebabCase(RMSModules.ADD_NEW_PROPERTY)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Add_Property" />}
                  module={RMSModules.ADD_NEW_PROPERTY}
                  url={`${baseUrlFranchisors}/properties/${RMSModules.ADD_NEW_PROPERTY}`}
                />
              </LazyComponent>
            )}
          />
        </Permissions>
      </Switch>
    </ErrorBoundary>
  );
};

export default OrganizationRoutes;
