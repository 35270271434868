import React from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import WelcomeBackground from '../../../assets/images/checklist-welcome-bg.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
    padding: theme.spacing(3),
    backgroundImage: 'url("' + WelcomeBackground + '")',
    color: '#FFFFFF',
    display: 'flex',
    flexFlow: 'column',
    height: 'calc(100% - 16px)',
  },
  contentWrapper: {
    flexGrow: 1,
  },
  title: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '48px',
    margin: 0,
  },
  body: {
    fontSize: 16,
    lineHeight: '24px',
  },
  buttonWrapper: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  button: {
    color: '#05149E',
    backgroundColor: '#FFFFFF',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
  },
}));

const Welcome = ({ dismiss }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <h2 className={classes.title}>
          <Translate id="Welcome_to_your_Flipdish_setup_configuration" />
        </h2>
        <div className={classes.body}>
          <p>
            <Translate id="This_setup_will_guide_you_through_the_steps_needed" />
          </p>
          <p>
            <Translate id="Once_the_tutorial_is_completed_you_will_be_able_to" />
          </p>
          <p>
            <Translate id="Enjoy" />
          </p>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button className={classes.button} onClick={() => dismiss()}>
          <Translate id="Lets_get_started" />
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
