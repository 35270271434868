import {
  CreatePayGreenConfigurationRequest,
  RestaurantVouchersPayGreenApiFactory,
  UpdatePayGreenConfigurationRequest,
} from '@flipdish/api-client-typescript';

import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const api = createApi(RestaurantVouchersPayGreenApiFactory);

const getPayConfig = 'listPayGreenConfigurations';

export async function getRestaurantVouchers(appId: string) {
  try {
    const incomingMessage = await api.getPayGreenConfigurations(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function createPaygreenConfiguration(
  appId: string,
  createPayGreenConfigurationRequest: CreatePayGreenConfigurationRequest
) {
  try {
    const incomingMessage = await api.createPayGreenConfiguration(
      appId,
      createPayGreenConfigurationRequest
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function updatePaygreenConfiguration(
  appId: string,
  paygreenConfigurationId: number,
  updatePayGreenConfigurationRequest: UpdatePayGreenConfigurationRequest
) {
  try {
    const incomingMessage = await api.updatePayGreenConfiguration(
      appId,
      paygreenConfigurationId,
      updatePayGreenConfigurationRequest
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function getElegibleStores(appId: string) {
  try {
    const incomingMessage = await api.getElegibleStores(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export const restaurantVouchersService = {
  getRestaurantVouchers,
  createPaygreenConfiguration,
  updatePaygreenConfiguration,
  getPayConfig,
  getElegibleStores,
};
