import React, { useRef } from 'react';

import Card from '@mui/material/Card';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import rearrange from '../../../assets/images/Rearrange.svg';
import { Draggable } from '../../atoms/Draggable';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    alignItems: 'start',
    backgroundColor: 'inherit',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  dragIcon: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  draggingCard: {
    opacity: 0,
  },
  highlightOnHover: {
    '&:hover': {
      backgroundColor: '#EAF2FF',
    },
  },
  isDraggable: {
    '&:hover': {
      cursor: 'grab',
    },
  },
}));

export type DraggableCardProps = {
  className?: string;
  cardId: string;
  children;
  disabled?: boolean;
  highlightOnHover?: boolean;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  type?: string;
};
export const DraggableCard = (props: DraggableCardProps): JSX.Element => {
  const {
    className,
    cardId,
    children,
    disabled,
    highlightOnHover = true,
    index,
    moveCard,
    type = 'draggable-card',
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const cardStyles = clsx(
    classes.card,
    !disabled && classes.isDraggable,
    highlightOnHover && classes.highlightOnHover,
    className && className
  );
  return (
    <Draggable disabled={disabled} id={cardId} index={index} onMove={moveCard} type={type}>
      <Card className={cardStyles} ref={ref} elevation={0}>
        {!disabled && <img data-fd="drag-icon" src={rearrange} className={classes.dragIcon} />}
        {children}
      </Card>
    </Draggable>
  );
};
