import React, { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { TranslateFunction } from 'react-localize-redux';

import Select from '@fd/ui/Select/Select';

const useStyles = makeStyles((theme: Theme) => ({
  countryField: {
    paddingBottom: theme.spacing(2),
  },
}));

export type CountrySelectorProps = {
  countries: { value: string; label: string }[];
  countryCode: string;
  disabled?: boolean;
  loading?: boolean;
  handleChange: (value: string) => void;
  translate: TranslateFunction;
  hideLabel?: boolean;
  textFieldStyle?: React.CSSProperties;
};

export const CountrySelector = ({
  countries,
  countryCode,
  disabled = false,
  loading = false,
  handleChange,
  translate,
  hideLabel = false,
  textFieldStyle = {},
}: CountrySelectorProps) => {
  const classes = useStyles();
  const [shrink, setShrink] = useState(false);

  return (
    <FormControl fullWidth className={classes.countryField}>
      <Select
        isLoading={loading}
        isDisabled={disabled}
        noErrorTranslate={false}
        dataFd={'country-selector'}
        isClearable={false}
        isMulti={false}
        onFocus={() => setShrink(true)}
        onChange={(country: { value: string; label: string }) => handleChange(country.value)}
        onBlur={() => {
          setShrink(false);
        }}
        options={countries}
        placeholder={''}
        TextFieldProps={{
          style: { paddingTop: '0.5em', ...textFieldStyle },
          FormHelperTextProps: {
            style: {
              alignSelf: 'flex-end',
            },
          },
          label: hideLabel ? '' : translate('Country'),
          InputLabelProps: {
            style: { paddingTop: '0.9em' },
            shrink: shrink || countryCode ? true : false,
          },
          fdKey: 'country-select',
          name: 'country-select',
        }}
        value={countries.find((country) => country.value === countryCode)}
        variant={'standard'}
      />
    </FormControl>
  );
};
