import { call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { appsActions, SetPanaceaVanityUrl } from '../actions/apps.actions';
import services from '../components/WebsiteAndApp/service';
import { appsConstants } from '../constants/apps.constants';
import { store } from '../helpers/store';
import { notifySaved } from '../layouts/Notify/actions';
import { permissionsSelector } from '../selectors/permissions.selector';

export function* updatePanaceaVanityUrlSaga(action) {
  if (action.selectedApp) {
    try {
      yield takeLatest('RRP_ADD_PERMISSION', function* () {
        const hasPermissions = yield select(
          permissionsSelector.hasPermissionFactory(['Owner', 'ManagedOwner', 'FlipdishStaff'])
        );

        if (!hasPermissions) {
          return;
        }
        yield put({ type: appsConstants.GET_APP_PANACEA_VANITY_URL_REQUEST });
        yield race({
          task: call(setPanaceaVanityUrlSaga, action.selectedApp.AppId),
          cancel: take(appsConstants.SELECTED_APP_CHANGED),
        });
      });
    } catch (e) {
      yield put(appsActions.getPanaceaVanityUrlFailure(e));
    }
  }
}

export function* setNewPanaceaVanityUrlSaga({ payload: { appId, url } }: SetPanaceaVanityUrl) {
  try {
    yield race({
      task: call(setPanaceaVanityUrlSaga, appId, url),
      cancel: take(appsConstants.SELECTED_APP_CHANGED),
    });
    // @ts-ignore
    yield store.dispatch(notifySaved());
  } catch (e) {
    yield put(appsActions.setPanaceaVanityUrlFailure(e));
  }
}

export function* setPanaceaVanityUrlSaga(appId: string, url?: string) {
  if (url) {
    yield call(services.setPanaceaUrl, appId, url);
    yield put(appsActions.setPanaceaVanityUrlSuccess(url));
  } else {
    const { Data } = yield call(services.getPanaceaUrl, appId);
    yield put(appsActions.getPanaceaVanityUrlSuccess(Data));
  }
}

export function* currentAppSaga() {
  yield takeEvery(appsConstants.SELECTED_APP_CHANGED, updatePanaceaVanityUrlSaga);
  yield takeEvery(appsConstants.SET_APP_PANACEA_VANITY_URL_REQUEST, setNewPanaceaVanityUrlSaga);
}
