import memoize from 'fast-memoize';
import React from 'react';

//#region caching state
const cache = {};
type Args = Parameters<typeof Intl.NumberFormat>;
/**
 * caches Intl.NumberFormat instances for later usage
 * @description
 * more info on Performance section: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 */
const getNumberFormat = memoize((...args: Args) => new Intl.NumberFormat(...args), {
  cache: {
    create() {
      return {
        has(key) {
          return key in cache;
        },
        get(key) {
          return cache[key];
        },
        set(key, value) {
          cache[key] = value;
        },
      };
    },
  },
  strategy: memoize.strategies.variadic,
});
//#endregion

/**
 * Number formatting
 * @param value number to format
 * @param locales to which locale to format to
 * @param options formatting options
 *
 * @example
 * formatNumber(1000, ['en-IE', 'en'])
 */
export function formatNumber(value: number, locales: Args[0], options?: Args[1]) {
  try {
    return getNumberFormat(locales, options).format(value);
  } catch (error) {
    process.env.NODE_ENV === 'development' && console.error(error);
    return String(value);
  }
}

export type FormattedNumberProps = {
  value: number;
  locales?: string | string[];
} & {
  children?(formattedValue: string): React.ReactElement | null;
} & Intl.NumberFormatOptions;
/**
 * Number formatting
 * @example
 * <FormattedNumber
 *   value={1000}
 *   locales={['en-IE', 'en']}
 * />
 *
 * // OR
 * <FormattedNumber value={1000}>
 *   {(formattedValue)=> (<span>{formattedValue}</span>)}
 * </FormattedNumber>
 */
const FormattedNumber: React.FC<FormattedNumberProps> = (props) => {
  const { value, children, locales, ...formatProps } = props;

  const formattedValue = formatNumber(value, locales, formatProps);

  if (typeof children === 'function') {
    return children(formattedValue);
  }

  return <>{formattedValue}</>;
};

export { FormattedNumber };
