import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { type Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import InfoIcon from '@fd/ui/Tooltip/InfoIcon';
import Tooltip from '@fd/ui/Tooltip/Tooltip';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    paddingLeft: '15px',
    [breakpoints.down('md')]: {
      paddingRight: '24px',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  cardSwitchLabel: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  cardText: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.38px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  cardTextResponsive: {
    marginTop: '11px',
    marginBottom: '12px',
  },
  storesResponsiveText: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#25ca75',
    },
    '&$checked + $track': {
      backgroundColor: '#25ca75',
    },
  },
  checked: {},
  track: {},
})(Switch);

export interface IControlProps {
  toggleEditLock: () => any;
  editLock: boolean;
}

type Props = IControlProps & MappedState;

const MenuControlsSecondColumnResponsive = (props: Props) => {
  const classes = useStyles();
  const { editLock, menu, menuStores, permissions, toggleEditLock, translate } = props;
  const menuLockDisabled =
    permissions.findIndex((permission) => permission === 'UpdateMenuLock') === -1;

  return (
    <div className={classes.root}>
      <FormGroup>
        <div className={classes.flexCenter}>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={editLock}
                value="editLockingSwitch"
                onChange={toggleEditLock}
                data-fd="menu-toggle-edit-lock"
                disabled={menuLockDisabled}
              />
            }
            aria-describedby="Lock_menu_editing_tooltip"
            label={editLock ? translate('Locked_for_editing') : translate('Lock_for_editing')}
            classes={{ label: classes.cardSwitchLabel }}
          />
          <Tooltip
            id="Lock_menu_editing_tooltip"
            messageId="Lock_menu_editing_tooltip"
            fdKey="Lock_menu_editing_tooltip"
          >
            <div>
              <InfoIcon size={20} />
            </div>
          </Tooltip>
        </div>
        {menu && menu.ModifiedTime ? (
          <Typography className={clsx(classes.cardText, classes.cardTextResponsive)}>
            {`${translate('Last_modified')} ${moment(menu.ModifiedTime).format(
              'MM/DD/YYYY hh:mm'
            )}`}
          </Typography>
        ) : null}
        <Typography className={classes.storesResponsiveText}>{menuStores.join(', ')}</Typography>
      </FormGroup>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale, menus, permissions } = state;

  return {
    translate: getTranslate(locale),
    menu: menus.menu,
    menuStores: menus.menuStores,
    permissions,
  };
}

const EnhancedComponent = connect(mapStateToProps)(MenuControlsSecondColumnResponsive);
export { EnhancedComponent as SecondColumn };
