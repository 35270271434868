import React from 'react';

import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import TextField from '@fd/ui/TextField/TextField';

import { formikValidate } from '../../../../../../helpers/validation';

type TextInputProps = {
  fieldName: string;
  label?: string;
  placeholder: string;
  multiline?: boolean;
  helperText?: string;
  isUrl?: boolean;
  withoutLayout?: boolean;
  optional?: boolean;
  error?: string;
  beenTouched?: boolean;
  isRegex?: boolean;
  fdKey?: string;
  maxCharLength?: boolean;
  isEmail?: boolean;
};

const TextInput = ({
  fieldName,
  label,
  placeholder,
  multiline,
  helperText,
  isUrl,
  withoutLayout,
  optional,
  error,
  beenTouched,
  isRegex,
  fdKey,
  maxCharLength,
  isEmail,
}: TextInputProps) => {
  const validate = (value: string) => {
    if (!optional && (!value || !value.length)) {
      return <Translate id="Required" />;
    }
    if (maxCharLength && value.length > 100) {
      return formikValidate.max(100)(value.length);
    }

    if (value && isUrl) {
      const isNotValidUrl = formikValidate.url(value);
      if (isNotValidUrl) {
        return isNotValidUrl;
      }
    }

    if (value && isEmail) {
      const isNotValidEmail = formikValidate.email(value);
      if (isNotValidEmail) {
        return isNotValidEmail;
      }
    }

    return;
  };

  const getFieldValue = (
    fieldPropertyName: string,
    formikContainer: FormikTouched<any> | FormikErrors<any>
  ) => {
    const properties = fieldPropertyName.split('.');
    let container: boolean | undefined | object = formikContainer;

    properties.forEach((fieldProperty) => {
      if (container == undefined) {
        return;
      }
      container = container[fieldProperty];
    });

    return container;
  };

  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError =
          error || (getFieldValue(field.name, errors) as unknown as string | undefined);
        const showError =
          !!fieldError &&
          (beenTouched || (getFieldValue(field.name, touched) as unknown as boolean | undefined));

        return (
          <FormItemLayout label={label} mdProportion={withoutLayout ? '12x12' : undefined}>
            <TextField
              {...field}
              value={field.value || ''}
              fdKey={fdKey || `appstore_config_input_${fieldName}`}
              autoComplete="off"
              disabled={isSubmitting}
              error={showError}
              helperText={showError ? fieldError : helperText}
              fullWidth
              type="text"
              multiline={multiline}
              label={placeholder}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default TextInput;
