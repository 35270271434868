import React, { cloneElement, Fragment, ReactElement, ReactNode, useState } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { ReactComponent as SuccessCheckIcon } from '../../../../assets/images/success-check.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(2),
  },
  headerContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  headerContainerCompleted: {
    background: 'rgba(32, 194, 110, 0.1)',
  },
  headerIcon: {
    paddingRight: 20,
    color: 'rgba(0, 0, 0, 0.58)',
  },
  completedIcon: {
    marginTop: theme.spacing(1),
    color: '#20C26E',
  },
  expandedButton: {
    height: 30,
  },
  titleContainer: {
    flexGrow: 1,
    minHeight: 70,
  },
  title: {
    margin: 0,
    marginBottom: 4,
    fontWeight: 500,
    fontSize: 16,
  },
  items: {
    listStyle: 'none',
    padding: 0,
  },
  hidden: {
    display: 'none',
  },
}));

type TutorialSectionProps = {
  isActive?: boolean;
  title: ReactElement | string;
  subtitle: ReactElement | string;
  children: ReactElement[] | ReactElement;
  icon: ReactNode;
  sectionCompleted?: boolean;
  setSectionCompleted?: (number, boolean) => void;
  expanded?: boolean;
  setExpanded?: (boolean) => void;
};

export const TutorialSection = ({
  isActive,
  title,
  subtitle,
  children,
  icon,
  sectionCompleted,
  setSectionCompleted,
  expanded,
  setExpanded,
}: TutorialSectionProps) => {
  const classes = useStyles();
  const tutorialItemElements = Array.isArray(children) ? children : [children];
  const [itemsCompleted, setItemsCompleted] = useState(
    tutorialItemElements.map((_tutorialItemElement) => undefined)
  );

  const setItemCompleted = (index, isCompleted) => {
    setItemsCompleted((prevItemsCompleted) => {
      const newItemsCompleted = [...prevItemsCompleted];
      newItemsCompleted[index] = isCompleted;
      return newItemsCompleted;
    });
  };

  const sectionIsComplete = itemsCompleted.filter((itemComplete) => !itemComplete).length === 0;
  if (sectionIsComplete !== sectionCompleted) {
    setSectionCompleted && setSectionCompleted(sectionIsComplete, isActive);
  }

  return (
    <div className={classes.container}>
      <div
        className={clsx({
          [classes.headerContainer]: true,
          [classes.headerContainerCompleted]: sectionCompleted,
        })}
      >
        <div className={classes.headerIcon}>
          {icon}
          {sectionCompleted && (
            <div className={classes.completedIcon} data-fd={`${title}-complete-icon`}>
              <SuccessCheckIcon color="#20C26E" />
            </div>
          )}
        </div>
        <div className={classes.titleContainer}>
          <h3 className={classes.title}>{title}</h3>
          <span>{subtitle}</span>
        </div>
        <IconButton
          aria-label="toggle"
          className={classes.expandedButton}
          data-fd="tutorial-section-toggle-button"
          component={'button'}
          size="small"
          onClick={() => setExpanded && setExpanded(!expanded)}
        >
          {!expanded && <KeyboardArrowDown />}
          {expanded && <KeyboardArrowUp />}
        </IconButton>
      </div>

      <Collapse
        in={expanded}
        className={isActive ? undefined : classes.hidden}
        timeout={isActive ? 'auto' : 0}
      >
        <Divider />
        <ul className={classes.items}>
          {tutorialItemElements.map((tutorialItemElement, index) => (
            <Fragment key={index}>
              {cloneElement(tutorialItemElement, {
                setItemCompleted: (isCompleted) => setItemCompleted(index, isCompleted),
              })}
            </Fragment>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

export default TutorialSection;
