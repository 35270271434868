import React, { useRef } from 'react';

import { Field } from '@flipdish/api-client-typescript';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, FieldArray, Form, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import SelectField from '../fields/MuiSelectField';
import TextInput from '../fields/TextInput';
import { Dialog } from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: '10px',
    maxWidth: '480px',
  },
  formField: {
    minHeight: '5rem',
  },
  cancelButton: {
    marginRight: '10px',
  },
  selectOptionField: {
    marginRight: 15,
  },
  deleteOptionButton: {
    marginTop: 40,
  },
}));

export type ConfigurationFieldDialogProps = {
  onClose: () => void;
  onSave: (position: number, data: any) => void;
  open: boolean;
  translate: TranslateFunction;
  fieldGroup: any;
  position: number;
  isNew: boolean;
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
const getDefaultFormState = (fieldGroup, position) => {
  if (fieldGroup.Fields && fieldGroup.Fields[position]) {
    return fieldGroup.Fields[position];
  }
  return {
    Name: '',
    Key: '',
    Description: '',
    Tooltip: '',
    DefaultValue: '',
    ValidValues: [],
    FieldType: 'Text',
  };
};

const ConfigurationFieldDialog = (props: ConfigurationFieldDialogProps & FormikProps<any>) => {
  const { onClose, open, translate, handleSubmit, isNew, values, errors, touched } = props;
  const classes = useStyles();
  const addOptionButtonRef = useRef<HTMLButtonElement>(null);

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    handleSubmit();
  };

  return (
    <Form>
      <Dialog
        open={open}
        title={(isNew ? translate('Create_field') : translate('Update_field')) as string}
        actions={[
          <Button
            className={classes.cancelButton}
            key="create-config-field-cancel"
            data-fd="create-config-field-cancel"
            color="primary"
            variant="text"
            onClick={handleCancelClick}
          >
            <Translate id="Cancel" />
          </Button>,
          <Button
            key="create-config-field-confirm"
            data-fd="create-config-field-confirm"
            type="submit"
            onClick={handleSaveClick}
            color="primary"
            variant="contained"
          >
            {isNew ? translate('Create') : translate('Save')}
          </Button>,
        ]}
      >
        <Typography variant="caption">
          {translate('Enter_the_information_for_the_field')}
        </Typography>
        <TextInput
          fieldName="Name"
          placeholder={translate('Name') as string}
          withoutLayout={true}
          fdKey="field-name-config-input"
        />
        <TextInput
          fieldName="Key"
          placeholder="Key"
          withoutLayout={true}
          fdKey="field-key-config-input"
        />
        <TextInput
          fieldName="Description"
          placeholder={translate('Description') as string}
          multiline
          withoutLayout={true}
          fdKey="field-description-config-input"
        />
        <TextInput
          fieldName="Tooltip"
          placeholder={translate('Tool_tip_text') as string}
          multiline
          withoutLayout={true}
          optional={true}
        />
        <SelectField
          fieldName="FieldType"
          placeholder={translate('Field_type') as string}
          optionsEnum={Field.FieldTypeEnum}
          translate={translate}
          withoutLayout={true}
        />
        {values.FieldType === Field.FieldTypeEnum.Text && (
          <TextInput
            fieldName="ValidationRegex"
            placeholder={translate('Validation_regex') as string}
            withoutLayout={true}
            optional={true}
            isRegex={true}
          />
        )}
        {values.FieldType === Field.FieldTypeEnum.Select && (
          <>
            <FieldArray
              name="ValidValues"
              render={(arrayHelpers) => (
                <div>
                  {values.ValidValues.map((option, index) => (
                    <Grid container key={index}>
                      <Grid item className={classes.selectOptionField}>
                        <TextInput
                          fieldName={`ValidValues[${index}].Name`}
                          placeholder={translate('Option_label') as string}
                          withoutLayout={true}
                          error={errors && errors.ValidValues && errors.ValidValues[index]?.Name}
                          beenTouched={
                            touched && touched.ValidValues && touched.ValidValues[index]?.Name
                          }
                        />
                      </Grid>
                      <Grid>
                        <TextInput
                          fieldName={`ValidValues[${index}].Code`}
                          placeholder={translate('Option_value') as string}
                          withoutLayout={true}
                          error={errors && errors.ValidValues && errors.ValidValues[index]?.Code}
                          beenTouched={
                            touched && touched.ValidValues && touched.ValidValues[index]?.Code
                          }
                        />
                      </Grid>
                      <Grid>
                        <IconButton
                          onClick={() => arrayHelpers.remove(index)}
                          className={classes.deleteOptionButton}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    ref={addOptionButtonRef}
                    onClick={() => {
                      arrayHelpers.push({ Code: '', Name: '' });
                      setTimeout(
                        () => addOptionButtonRef!.current!.scrollIntoView({ behavior: 'smooth' }),
                        100
                      );
                    }}
                  >
                    <Translate id="Add_option" />
                  </Button>
                </div>
              )}
            />
          </>
        )}
      </Dialog>
    </Form>
  );
};

export default withFormik<ConfigurationFieldDialogProps, FormValues>({
  displayName: 'ConfigurationFieldDialog',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.fieldGroup, props.position);
  },
  handleSubmit: async (values, formikBag) => {
    formikBag.props.onSave(formikBag.props.position, values);
    formikBag.props.onClose();
  },
})(ConfigurationFieldDialog);
