import React, { useEffect, useRef, useState } from 'react';

import {
  Group,
  MetafieldDefinition,
  PendingMenuChanges,
  Product,
  ProductReference,
} from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, CheckboxField, MessageBanner, Switch, Typography } from '@fd/ui/atoms';
import { FormikInputField } from '@fd/ui/molecules/FormikInputField';
import { FormSection } from '@fd/ui/molecules/FormSection';

import PreventNavigation from '../../../../Finance/Banking/components/PreventNavigation';
import { HelpdeskButton } from '../../../../HelpdeskButton';
import { AddModifierDrawer } from '../../../components/AddModifierDrawer';
import { CatalogMetafieldsFormSection } from '../../../components/CatalogMetafieldsFormSection';
import { DraggableItemList } from '../../../components/DraggableItemList';
import { ImagerySection } from '../../../components/ImagerySection';
import { InternalNoteFormSection } from '../../../components/InternalNoteFormSection';
import { ModifierGroupSelectionType } from '../../../components/ModifierGroupSelectionType';
import { PendingMenuChangesBanner } from '../../../components/PendingMenuChangesBanner';
import { SaveCatalogChangesButton } from '../../../components/SaveCatalogChangesButton';
import { INTERNAL_NOTES_METAFIELD_KEY } from '../../../constants';
import { catalogImagesService } from '../../../services/catalogImages.service';
import { GroupFormValues } from '../../../types';
import { generateSku, setMetafield, validateModifierGroupForm } from '../../../utils';
import { CannotRemoveModifierDialog } from '../CannotRemoveModifierDialog';

const useStyles = makeStyles((theme: Theme) => ({
  allowRepeatField: {
    marginTop: theme.spacing(3),
  },
  addModifierButton: {
    width: '164px',
    marginTop: theme.spacing(3),
  },
  checkboxDescription: {
    display: 'block',
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  formContent: {
    paddingBottom: theme.spacing(3),
  },
  field: {
    minHeight: '5rem',
    marginTop: theme.spacing(1),
  },
  modifierListText: {
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: {
    marginTop: theme.spacing(14),
    paddingRight: theme.spacing(3),
  },
}));

export type ModifierGroupFormProps = {
  AppId: string;
  canEdit: boolean;
  isLoading?: boolean;
  isUpdateForm?: boolean;
  metafields?: MetafieldDefinition[];
  modifierGroup?: Group;
  onSave: (modifierGroup: GroupFormValues, saveType?: number) => Promise<void>;
  pendingMenuChanges?: PendingMenuChanges[];
  translate: TranslateFunction;
};

export const ModifierGroupForm = (props: ModifierGroupFormProps) => {
  const {
    AppId,
    canEdit,
    isLoading = false,
    isUpdateForm = false,
    metafields,
    modifierGroup,
    onSave,
    pendingMenuChanges = [],
    translate,
  } = props;
  const formRef = useRef<any>(null);
  const [modifiers, setModifiers] = useState<Product[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [saveType, setSaveType] = useState<number>(0);
  const isReadOnly = !canEdit || modifierGroup?.IsArchived;
  const classes = useStyles();
  const showInternalNotes =
    metafields &&
    metafields?.findIndex((metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY) !== -1;
  const customMetafields =
    metafields && metafields?.filter((metafield) => metafield.Key !== INTERNAL_NOTES_METAFIELD_KEY);

  const getInternalNote = (metafields): string => {
    const noteField = metafields.find(
      (metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY
    );
    return noteField?.Value || '';
  };

  const setMetafieldValue = (key: string, value: string) => {
    const groupMetafields = formRef.current?.values.Metafields || [];
    const metafieldIndex = groupMetafields.findIndex((metafield) => metafield.Key === key);
    if (metafieldIndex !== -1 && value != '') {
      formRef.current?.setFieldValue(`Metafields[${metafieldIndex}].Value`, value);
    } else {
      const updatedMetafields = setMetafield(key, value, groupMetafields);
      formRef.current?.setFieldValue('Metafields', updatedMetafields);
    }
  };

  useEffect(() => {
    if (modifierGroup) {
      setModifiers(getModifiers(modifierGroup.Products));
    }
  }, [modifierGroup]);

  const getModifiers = (productRefs?: ProductReference[]) => {
    if (!productRefs || productRefs.length == 0) {
      return [];
    }
    const modifiers: Product[] = [];
    productRefs.forEach((productRef) => {
      if (productRef.Product) {
        modifiers.push(productRef.Product);
      }
    });
    return modifiers;
  };

  const setMinMax = (min: number, max: number) => {
    formRef.current?.setFieldValue('MinSelectCount', min);
    formRef.current?.setFieldValue('MaxSelectCount', max);
  };

  const onAddModifier = (newModifier: Product) => {
    setModifiers([...modifiers, newModifier]);
    const newItemRef = {
      CatalogItemId: newModifier.CatalogItemId,
      ProductType: newModifier.ProductType,
    };
    formRef.current.setFieldValue('Products', [...formRef.current?.values.Products, newItemRef]);
  };

  const onRemoveModifier = (id: string) => {
    if (formRef.current?.values.MinSelectCount === modifiers.length) {
      setIsDialogOpen(true);
    } else {
      const maxCount = formRef.current?.values.MaxSelectCount;
      if (maxCount === modifiers.length) {
        formRef.current?.setFieldValue('MaxSelectCount', maxCount - 1);
      }
      const currentIndex = modifiers.findIndex((x) => x.CatalogItemId == id);
      const newModifiers = [...modifiers];
      newModifiers.splice(currentIndex, 1);
      setModifiers(newModifiers);
      const itemRefIndex = formRef.current?.values.Products.findIndex((x) => x.CatalogItemId == id);
      const newItemRefs = [...formRef.current?.values.Products];
      newItemRefs.splice(itemRefIndex, 1);
      formRef.current?.setFieldValue('Products', newItemRefs);
    }
  };

  const handleNewModifierOrder = (newOrder: Product[]) => {
    formRef.current?.setFieldValue('Products', newOrder);
  };

  return (
    <>
      <CannotRemoveModifierDialog
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        translate={translate}
      />
      <AddModifierDrawer
        AppId={AppId}
        onClose={() => setIsDrawerOpen(false)}
        onAddModifier={onAddModifier}
        open={isDrawerOpen}
        currentModifiers={modifiers}
        translate={translate}
      />
      <Paper>
        {pendingMenuChanges.length > 0 && (
          <PendingMenuChangesBanner
            appId={AppId}
            catalogElementId={modifierGroup?.CatalogItemId as string}
            changesList={pendingMenuChanges}
            translate={translate}
          />
        )}
        {modifierGroup?.IsArchived && (
          <MessageBanner
            fdKey="modifier-group-archived-banner"
            title={translate('This_modifier_group_is_archived') as string}
            message={translate('Archived_modifier_groups_cannot_be_updated') as string}
          />
        )}
        <Formik
          enableReinitialize={isUpdateForm}
          innerRef={formRef}
          initialValues={{
            AutogenerateDisplayText: modifierGroup?.AutogenerateDisplayText || false,
            ImageFileName: modifierGroup?.ImageFileName,
            Name: modifierGroup?.Name || '',
            Metafields: modifierGroup?.Metafields || [],
            MinSelectCount: modifierGroup?.MinSelectCount || 0,
            MaxSelectCount:
              modifierGroup?.MaxSelectCount || modifierGroup?.MaxSelectCount === null
                ? modifierGroup?.MaxSelectCount
                : 1,
            Sku: modifierGroup?.Sku || generateSku('MG'),
            Products: modifierGroup?.Products || [],
          }}
          onSubmit={async (values, { setFieldError, setSubmitting }) => {
            try {
              await onSave(values, saveType);
              setSubmitting(false);
            } catch (error) {
              const errorMessage = translate(
                'Product_CreateProductDialog_DuplicateSkuError'
              ) as string;
              error.message.includes('SKU already exists') && setFieldError('Sku', errorMessage);
              setSubmitting(false);
            }
          }}
          validate={(values) => {
            return validateModifierGroupForm(values, translate);
          }}
        >
          {({ dirty, isSubmitting, values }) => (
            <Form className={classes.formContent}>
              <PreventNavigation when={dirty && !isSubmitting} />
              <FormSection sectionTitle={translate('Products_EditProductForm_Basic') as string}>
                <FormikInputField
                  className={classes.field}
                  disabled={isSubmitting}
                  fdKey={'create-modifier-group-form-name'}
                  isLoading={isLoading}
                  name="Name"
                  label={translate('Products_EditProductForm_EnterProductName') as string}
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
                <CheckboxField
                  ariaLabel={translate('ModifierGroup_DisplayText_Checkbox_AriaLabel') as string}
                  checked={values.AutogenerateDisplayText}
                  disabled={isReadOnly}
                  fdKey="autogenerate-display-text-checkbox"
                  label={translate('ModifierGroup_DisplayText_Checkbox') as string}
                  onChange={(value) =>
                    formRef.current?.setFieldValue('AutogenerateDisplayText', value)
                  }
                />
                <Typography className={classes.checkboxDescription} variant="caption">
                  <Translate id="ModifierGroup_DisplayText_Description" />
                </Typography>
              </FormSection>
              {showInternalNotes && (
                <InternalNoteFormSection
                  onNoteChange={(newValue) =>
                    setMetafieldValue(INTERNAL_NOTES_METAFIELD_KEY, newValue)
                  }
                  text={getInternalNote(values.Metafields)}
                  translate={translate}
                />
              )}
              <FormSection sectionTitle={translate('Products_EditProductForm_Imagery') as string}>
                <ImagerySection
                  appId={AppId}
                  description={
                    <>
                      <Typography variant="caption">
                        {translate('ModifierGroup_Form_ImageDescription')}
                      </Typography>
                      <HelpdeskButton label="portalhelp:/products/images" />
                    </>
                  }
                  imageFileName={values.ImageFileName}
                  isLoading={isLoading}
                  onImageRemoved={() => formRef.current?.setFieldValue('ImageFileName', '')}
                  onImageUploaded={(name: string) =>
                    formRef.current?.setFieldValue('ImageFileName', name)
                  }
                  translate={translate}
                  disabled={isReadOnly}
                  uploadImage={catalogImagesService.uploadCatalogImage}
                />
              </FormSection>
              <FormSection sectionTitle={translate('ModifiersPreferences') as string}>
                <Grid container direction="column">
                  <Typography variant="body1">
                    <Translate id="SelectModifiers" />
                  </Typography>
                  <Typography className={classes.modifierListText} variant="caption">
                    <Translate id="SelectModifiers_Description" />
                  </Typography>
                  {modifiers.length > 0 && (
                    <DraggableItemList
                      appId={AppId}
                      items={modifiers}
                      onRemove={onRemoveModifier}
                      onUpdateOrder={handleNewModifierOrder}
                      translate={translate}
                    />
                  )}
                  <Button
                    className={classes.addModifierButton}
                    disabled={isReadOnly || isSubmitting}
                    fdKey={'add-modifier-button'}
                    onClick={() => setIsDrawerOpen(true)}
                    variant="secondary"
                  >
                    <Add />
                    <Translate id="AddModifier" />
                  </Button>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="body1">
                      <Translate id="SelectionType" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ModifierGroupSelectionType
                      minValue={values.MinSelectCount}
                      maxValue={values.MaxSelectCount}
                      onChange={setMinMax}
                      totalModifiers={values.Products.length}
                      translate={translate}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection
                sectionTitle={translate('Products_EditProductForm_Identification') as string}
              >
                <FormikInputField
                  className={classes.field}
                  disabled={isSubmitting}
                  fdKey={'create-modifier-groupform-sku'}
                  isLoading={isLoading}
                  name="Sku"
                  label="SKU"
                  InputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </FormSection>
              {customMetafields && customMetafields.length > 0 && (
                <CatalogMetafieldsFormSection
                  metafields={customMetafields}
                  onMetafieldChange={(key, value) => setMetafieldValue(key, value)}
                  populatedMetafields={values.Metafields}
                  translate={translate}
                />
              )}
              <Grid
                container
                className={classes.saveButtonContainer}
                item
                xs={12}
                justifyContent="flex-end"
              >
                {isUpdateForm ? (
                  <SaveCatalogChangesButton
                    fdKey="modifier-group-save-button"
                    disabled={!dirty}
                    onSaveTypeChange={(newSaveType) => setSaveType(newSaveType)}
                    translate={translate}
                  />
                ) : (
                  <Button fdKey="modifier-group-save-button" disabled={!dirty} type="submit">
                    <Translate id="Products_EditProductForm_SaveButtonTitle" />
                  </Button>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};
