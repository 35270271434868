import React, { ChangeEvent, ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import Radio, { type RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { type SelectProps } from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { ComponentProps } from '../Common';
import { FkField, TFkFieldProps } from '../FkField/FkField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(-1.25),
  },
  label: {
    width: '100%',
  },
  description: {
    paddingLeft: theme.spacing(5.25),
    marginTop: theme.spacing(-1),
  },
}));

// Fk - Formik. Warning!!! Don't extend from SelectProps props - all UI parts must be inside component
// Don't add value field!!! work only over formik.
export type RadioOption = {
  value: string;
  labelId?: TranslationId;
  descriptionId?: TranslationId;
  disabled?: boolean;
};

type Props = {
  onChange?: SelectProps['onChange'];
  ariaLabel?: string;
  options: Array<RadioOption>;
  renderEndAdornment?: (optionValue: string, value: string) => ReactNode;
  RadioProps?: RadioProps;
} & ComponentProps;

export const FkRadioGroup = (props: Props) => {
  const {
    name,
    options,
    ariaLabel,
    onChange,
    disabled,
    renderEndAdornment,
    RadioProps = {},
  } = props;
  const classes = useStyles();

  return (
    <FkField componentProps={props} removeTopPadding>
      {({ field, form }: TFkFieldProps) => {
        const { isSubmitting } = form;
        return (
          <RadioGroup
            className={classes.root}
            name={name}
            aria-label={ariaLabel}
            value={field.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e, e.target.value);
              form.setFieldValue(field.name, e.target.value);
            }}
          >
            {options.map((option) => (
              <div key={option.value}>
                <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap">
                  <Radio
                    {...RadioProps}
                    id={option.value}
                    disabled={isSubmitting || disabled || option.disabled}
                    value={option.value}
                    color="primary"
                  />
                  <label htmlFor={option.value} className={classes.label}>
                    <span>
                      {renderEndAdornment ? (
                        renderEndAdornment(option.value, field.value)
                      ) : option.labelId ? (
                        <Translate id={option.labelId} />
                      ) : (
                        option.value
                      )}
                    </span>
                  </label>
                </Grid>
                {option.descriptionId && (
                  <div className={classes.description}>
                    <Typography variant="caption">
                      <Translate id={option.descriptionId} />
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </RadioGroup>
        );
      }}
    </FkField>
  );
};
