import React, { ReactElement, useEffect } from 'react';

import ArrowForward from '@mui/icons-material/ArrowForward';
import Check from '@mui/icons-material/Check';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  checkIcon: {
    display: 'flex',
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.12)',
  },
  checkIconCompleted: {
    color: '#20C26E',
  },
  link: {
    flexGrow: 1,
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: '8px',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    '&[href]:hover': {
      backgroundColor: '#EAF2FF',
    },
    '&:not([href]):hover': {
      cursor: 'not-allowed',
    },
  },
  name: {
    flexGrow: 1,
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.58)',
  },
}));

type TutorialItemProps = {
  name: ReactElement | string;
  href?: string;
  isComplete: boolean | undefined;
  setItemCompleted?: (boolean) => void;
  externalHref?: string;
  setIsOpen: (boolean) => void;
};

export const TutorialItem = ({
  name,
  setItemCompleted,
  href,
  externalHref,
  isComplete,
  setIsOpen,
}: TutorialItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setItemCompleted && setItemCompleted(isComplete);
  }, [isComplete]);

  const content = () => (
    <>
      <div className={classes.name}>{name}</div>
      <ArrowForward className={classes.arrowIcon} />
    </>
  );

  return (
    <li className={classes.container}>
      <div
        className={clsx({
          [classes.checkIcon]: true,
          [classes.checkIconCompleted]: isComplete,
        })}
      >
        <Check />
      </div>
      {!!externalHref && (
        <a className={classes.link} href={externalHref} rel="noopener noreferrer" target="_blank">
          {content()}
        </a>
      )}
      {!!href && (
        <Link
          to={{
            pathname: href,
          }}
          className={classes.link}
          onClick={isMobile ? () => setIsOpen(false) : undefined}
        >
          {content()}
        </Link>
      )}
      {!href && !externalHref && <a className={classes.link}>{content()}</a>}
    </li>
  );
};

export default TutorialItem;
