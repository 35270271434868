import React, { useCallback, useEffect, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, withRouter } from 'react-router';

import { Button } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';
import { Dialog } from '../Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  promptText: {
    color: theme.palette.grey[700],
  },
}));

export type RouterPromptProps = {
  message: string;
  onOk: () => boolean;
  onCancel: () => boolean;
  title: string;
  show: boolean;
};
const RouterPrompt = (props: RouterPromptProps & RouteComponentProps) => {
  const { history, message, onOk, onCancel, title, show } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (show) {
      history.block((prompt) => {
        if (history.location.pathname === prompt.pathname) return 'false';
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, show]);

  const handleConfirm = useCallback(async () => {
    if (onOk && (await Promise.resolve(onOk()))) {
      history.block(() => {});
      history.push(currentPath);
    }
  }, [currentPath, history, onOk]);

  const handleCancel = useCallback(async () => {
    if (onCancel && (await Promise.resolve(onCancel()))) {
      history.block(() => {});
      history.push(currentPath);
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Dialog
      title={title}
      open={showPrompt}
      actions={[
        <Button
          className={classes.cancelButton}
          key="navigate-cancel"
          fdKey="navigate-cancel"
          variant="text"
          onClick={handleCancel}
        >
          <Translate id="Cancel" />
        </Button>,
        <Button key="navigate-confirm" fdKey="navigate-confirm" onClick={handleConfirm}>
          <Translate id="OK" />
        </Button>,
      ]}
    >
      <Typography className={classes.promptText} variant="body2">
        {message}
      </Typography>
    </Dialog>
  ) : null;
};
const EnhancedComponent = withRouter(RouterPrompt);
export { EnhancedComponent as RouterPrompt };
