import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import LinkButton from '../../../ui/Button/LinkButton';
import { displayCustomerNameForOrder } from '../helpers';

const styles = (theme: Theme) =>
  createStyles({
    additionalDetailsGrid: {
      margin: '0',
    },
    paper: {
      padding: '0',
      textAlign: 'left',
      color: theme.palette.text.secondary,
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: { margin: '24px 16px 32px 16px' },
    },
    paperNotes: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      boxShadow: 'none',
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderBottom: '1px solid rgba(0, 0, 0, 0.19)',
      [theme.breakpoints.down('md')]: {
        borderTop: '1px solid rgba(0, 0, 0, 0.6)',
        paddingBottom: '0',
      },
    },
    paperBackground: {
      wordBreak: 'break-all',
      backgroundColor: '#ecf4fe',
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        padding: '16px',
      },
    },
    caption: {
      color: 'rgba(0, 0, 0, 0.6)',
      marginBottom: '8px',
    },
    note: {
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '1.5',
      fontSize: '16px',
      letterSpacing: '0.5px',
    },
    customer: {
      color: 'rgba(0, 0, 0, 0.6)',
      lineHeight: '1.5',
      fontSize: '16px',
      letterSpacing: '0.5px',
      wordBreak: 'break-word',
    },
    button: {
      justifyContent: 'left',
      textTransform: 'initial',
      textAlign: 'left',
      userSelect: 'text',
      marginTop: 30,
      padding: 0,
      display: 'block',
      overflowWrap: 'break-word',
      lineHeight: '1.43',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  });

export type AdditionalDetailsProps = {
  appId?: string;
  canViewCustomers: boolean;
  chefNote?: string;
  customerId?: number;
  deliveryNote?: string;
  email: string;
  name: string;
  showCustomerInfo: boolean;
};

type Props = WithStyles<typeof styles> & AdditionalDetailsProps;
const AdditionalDetails = compose<Props, AdditionalDetailsProps>(
  withStyles(styles, {
    name: 'AdditionalDetails',
    withTheme: true,
  })
)((props) => {
  const {
    appId,
    canViewCustomers,
    chefNote,
    classes,
    customerId,
    deliveryNote,
    email,
    name,
    showCustomerInfo,
  } = props;

  const customerName = name?.length > 150 ? displayCustomerNameForOrder(name, 150) : name;

  return (
    <>
      {(chefNote || deliveryNote) && (
        <Paper square className={classes.paperNotes}>
          <Grid container direction="column">
            {chefNote && (
              <Grid item className={classes.paperBackground}>
                <Typography variant="caption" className={classes.caption}>
                  <Translate id="Chef_note" />
                </Typography>
                <Typography className={classes.note}>{chefNote}</Typography>
              </Grid>
            )}

            {deliveryNote && (
              <Box component="span" mt={3}>
                <Grid item className={classes.paperBackground}>
                  <Typography variant="caption" className={classes.caption}>
                    <Translate id="Delivery note" />
                  </Typography>
                  <Typography className={classes.note}>{deliveryNote}</Typography>
                </Grid>
              </Box>
            )}
          </Grid>
        </Paper>
      )}

      {showCustomerInfo && (
        <Paper square className={classes.paper}>
          <Typography variant="caption" className={classes.caption}>
            <Translate id="Customer info" />
          </Typography>
          <Typography className={classes.customer}>{customerName}</Typography>
          <Typography className={classes.customer}>{email}</Typography>

          {customerId && canViewCustomers && (
            <LinkButton
              to={`/${appId}/customers/${customerId}`}
              variant="text"
              color="primary"
              classes={{ root: classes.button }}
              fdKey={'view-customer'}
            >
              <Translate>{(translate: any) => translate('View customer') as string}</Translate>
            </LinkButton>
          )}
        </Paper>
      )}
    </>
  );
});

export default withStyles(styles)(AdditionalDetails);
