import { Teammate } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { teammatesConstants } from '../../constants/teammates.constants';
import { createLoadingSelector } from '../../selectors/loading.selector';

const { GET_TEAMMATES } = teammatesConstants;

export const getIsTeammatesLoading = createLoadingSelector([GET_TEAMMATES]);

const selectTeammates = (state: AppState) => state.teammates.teammates;
const selectPermissions = (state: AppState) => state.permissions;
const selectAccountEmail = (state: AppState) => state.account.Email!;
const selectCurrentAppAccessLevel = (state: AppState) => state.currentApp.AppAccessLevel!;

export const getTeammatesLoading = (state: AppState) => state.teammates.teammateLoading;
export const getTeammatesTimestamp = (state: AppState) => state.teammates.teammatesTimestamp;

export const getTeammate = createSelector(
  [selectTeammates, (state, props) => props.match.params.teammateId],
  (teammates, teammateId) => {
    return teammates.find((teammate: Teammate) => teammate.TeammateId === teammateId);
  }
);

export const getCurrentUser = createSelector(
  [selectTeammates, selectAccountEmail, selectCurrentAppAccessLevel, selectPermissions],
  (teammates, accountEmail, currentAppAccessLevel, permissions) => {
    const defaultCurrentUser = {
      Email: accountEmail,
      AppAccessLevel: currentAppAccessLevel,
      HasAccessToAllStores: permissions.includes('Owner' || 'ManagedOwner'),
    };

    return (
      teammates.find((teammate: Teammate) => teammate.Email === accountEmail) || defaultCurrentUser
    );
  }
);
