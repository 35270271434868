import React, { ReactNode } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../ui/Button';

type StyleProps = { type?: 'phone' };
const useStyles = makeStyles(() => ({
  buttons: ({ type }: StyleProps) => {
    switch (type) {
      case 'phone': {
        return {
          justifyContent: 'left',
          textTransform: 'initial',
          textAlign: 'left',
          userSelect: 'text',
          fontSize: 18,
          fontWeight: 500,
          padding: 0,
          '&:hover': {
            backgroundColor: 'white',
          },
        };
      }
      default:
        return {
          justifyContent: 'left',
          textTransform: 'initial',
          textAlign: 'left',
          userSelect: 'text',
          padding: 0,
          display: 'block',
          overflowWrap: 'break-word',
          lineHeight: '1.43',
          fontWeight: 'normal',
          '&:hover': {
            backgroundColor: 'white',
            textDecoration: 'underline',
          },
        };
    }
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

type LinkButtonProps = {
  children: ReactNode;
  fdKey: string;
  hrefType?: 'tel' | 'mailto';
  href?: string | null;
  type?: 'phone';
};

type ActionButtonProps = {
  children: ReactNode;
  fdKey: string;
  onClick?: () => void;
  type?: 'phone';
};

type ReadOnlyTextProps = {
  placeholder?: 'blank' | 'dash';
  type?: 'phone';
};

type RenderTextButtonProps = {
  loading: boolean;
};

const ActionButton: React.FC<React.PropsWithChildren<ActionButtonProps>> = ({
  children,
  fdKey,
  onClick,
  type,
}) => {
  const classes = useStyles({ type });

  return (
    <Button
      fdKey={fdKey}
      classes={{ root: classes.buttons }}
      variant="text"
      color="primary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = ({
  fdKey,
  href,
  hrefType,
  children,
  type,
}: LinkButtonProps) => {
  const classes = useStyles({ type });

  return (
    <Button
      fdKey={fdKey}
      href={hrefType ? `${hrefType}:${href}` : `${href}`}
      classes={{ root: classes.buttons }}
      variant="text"
      color="primary"
    >
      {children}
    </Button>
  );
};

const Loading: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <>{children ? children : <Skeleton width={180} height={20} />}</>;
};

const ReadOnlyText: React.FC<React.PropsWithChildren<ReadOnlyTextProps>> = ({
  placeholder,
  type,
}) => {
  const classes = useStyles({ type });

  return <span className={classes.placeholder}>{placeholder === 'blank' ? '\u00A0' : '-'}</span>;
};

type Props = RenderTextButtonProps & LinkButtonProps & ActionButtonProps & ReadOnlyTextProps;
const RenderTextButton = ({
  type,
  children,
  fdKey,
  href,
  hrefType,
  onClick,
  placeholder = 'dash',
  loading,
}: Props) => {
  return (
    <Loading>
      {loading ? undefined : onClick && children ? (
        <ActionButton fdKey={fdKey} onClick={onClick} type={type}>
          {children}
        </ActionButton>
      ) : href ? (
        <LinkButton fdKey={fdKey} href={href} hrefType={hrefType} type={type}>
          {children}
        </LinkButton>
      ) : (
        <ReadOnlyText placeholder={placeholder} type={type} />
      )}
    </Loading>
  );
};

export default RenderTextButton;
