import { StoreGroup } from '@flipdish/api-client-typescript';

const currency = {
  AED: {
    Currency: 'AED',
    Symbol: 'د.إ',
    Code: '784',
    Name: 'UAE Dirham',
    Countries: 'UAE',
  },
  AFN: {
    Currency: 'AFN',
    Symbol: 'Af',
    Code: '971',
    Name: 'Afghani',
    Countries: 'Afghanistan',
  },
  ALL: {
    Currency: 'ALL',
    Symbol: 'L',
    Code: '008',
    Name: 'Lek',
    Countries: 'Albania',
  },
  AMD: {
    Currency: 'AMD',
    Symbol: 'Դ',
    Code: '051',
    Name: 'Armenian Dram',
    Countries: 'Armenia',
  },
  AOA: {
    Currency: 'AOA',
    Symbol: 'Kz',
    Code: '973',
    Name: 'Kwanza',
    Countries: 'Angola',
  },
  ARS: {
    Currency: 'ARS',
    Symbol: '$',
    Code: '032',
    Name: 'Argentine Peso',
    Countries: 'Argentina',
  },
  AUD: {
    Currency: 'AUD',
    Symbol: '$',
    Code: '036',
    Name: 'Australian Dollar',
    Countries: 'Australia, Kiribati, Coconut Islands, Nauru, Tuvalu',
  },
  AWG: {
    Currency: 'AWG',
    Symbol: 'ƒ',
    Code: '533',
    Name: 'Aruban Guilder/Florin',
    Countries: 'Aruba',
  },
  AZN: {
    Currency: 'AZN',
    Symbol: 'ман',
    Code: '944',
    Name: 'Azerbaijanian Manat',
    Countries: 'Azerbaijan',
  },
  BAM: {
    Currency: 'BAM',
    Symbol: 'КМ',
    Code: '977',
    Name: 'Konvertibilna Marka',
    Countries: 'Bosnia and Herzegovina',
  },
  BBD: {
    Currency: 'BBD',
    Symbol: '$',
    Code: '052',
    Name: 'Barbados Dollar',
    Countries: 'Barbados',
  },
  BDT: {
    Currency: 'BDT',
    Symbol: '৳',
    Code: '050',
    Name: 'Taka',
    Countries: 'Bangladesh',
  },
  BGN: {
    Currency: 'BGN',
    Symbol: 'лв',
    Code: '975',
    Name: 'Bulgarian Lev',
    Countries: 'Bulgaria',
  },
  BHD: {
    Currency: 'BHD',
    Symbol: 'ب.د',
    Code: '048',
    Name: 'Bahraini Dinar',
    Countries: 'Bahrain',
  },
  BIF: {
    Currency: 'BIF',
    Symbol: '₣',
    Code: '108',
    Name: 'Burundi Franc',
    Countries: 'Burundi',
  },
  BMD: {
    Currency: 'BMD',
    Symbol: '$',
    Code: '060',
    Name: 'Bermudian Dollar',
    Countries: 'Bermuda',
  },
  BND: {
    Currency: 'BND',
    Symbol: '$',
    Code: '096',
    Name: 'Brunei Dollar',
    Countries: 'Brunei, Singapore',
  },
  BOB: {
    Currency: 'BOB',
    Symbol: 'Bs.',
    Code: '068',
    Name: 'Boliviano',
    Countries: 'Bolivia',
  },
  BRL: {
    Currency: 'BRL',
    Symbol: 'R$',
    Code: '986',
    Name: 'Brazilian Real',
    Countries: 'Brazil',
  },
  BSD: {
    Currency: 'BSD',
    Symbol: '$',
    Code: '044',
    Name: 'Bahamian Dollar',
    Countries: 'Bahamas',
  },
  BTN: {
    Currency: 'BTN',
    Symbol: '',
    Code: '064',
    Name: 'Ngultrum',
    Countries: 'Bhutan',
  },
  BWP: {
    Currency: 'BWP',
    Symbol: 'P',
    Code: '072',
    Name: 'Pula',
    Countries: 'Botswana',
  },
  BYR: {
    Currency: 'BYR',
    Symbol: 'Br',
    Code: '974',
    Name: 'Belarussian Ruble',
    Countries: 'Belarus',
  },
  BZD: {
    Currency: 'BZD',
    Symbol: '$',
    Code: '084',
    Name: 'Belize Dollar',
    Countries: 'Belize',
  },
  CAD: {
    Currency: 'CAD',
    Symbol: '$',
    Code: '124',
    Name: 'Canadian Dollar',
    Countries: 'Canada',
  },
  CDF: {
    Currency: 'CDF',
    Symbol: '₣',
    Code: '976',
    Name: 'Congolese Franc',
    Countries: 'Congo (Kinshasa)',
  },
  CHF: {
    Currency: 'CHF',
    Symbol: '₣',
    Code: '756',
    Name: 'Swiss Franc',
    Countries: 'Lichtenstein, Switzerland',
  },
  CLP: {
    Currency: 'CLP',
    Symbol: '$',
    Code: '152',
    Name: 'Chilean Peso',
    Countries: 'Chile',
  },
  CNY: {
    Currency: 'CNY',
    Symbol: '¥',
    Code: '156',
    Name: 'Yuan',
    Countries: 'China',
  },
  COP: {
    Currency: 'COP',
    Symbol: '$',
    Code: '170',
    Name: 'Colombian Peso',
    Countries: 'Colombia',
  },
  CRC: {
    Currency: 'CRC',
    Symbol: '₡',
    Code: '188',
    Name: 'Costa Rican Colon',
    Countries: 'Costa Rica',
  },
  CUP: {
    Currency: 'CUP',
    Symbol: '$',
    Code: '192',
    Name: 'Cuban Peso',
    Countries: 'Cuba',
  },
  CVE: {
    Currency: 'CVE',
    Symbol: '$',
    Code: '132',
    Name: 'Cape Verde Escudo',
    Countries: 'Cape Verde',
  },
  CZK: {
    Currency: 'CZK',
    Symbol: 'Kč',
    Code: '203',
    Name: 'Czech Koruna',
    Countries: 'Czech Republic',
  },
  DJF: {
    Currency: 'DJF',
    Symbol: '₣',
    Code: '262',
    Name: 'Djibouti Franc',
    Countries: 'Djibouti',
  },
  DKK: {
    Currency: 'DKK',
    Symbol: 'kr',
    Code: '208',
    Name: 'Danish Krone',
    Countries: 'Denmark',
  },
  DOP: {
    Currency: 'DOP',
    Symbol: '$',
    Code: '214',
    Name: 'Dominican Peso',
    Countries: 'Dominican Republic',
  },
  DZD: {
    Currency: 'DZD',
    Symbol: 'د.ج',
    Code: '012',
    Name: 'Algerian Dinar',
    Countries: 'Algeria',
  },
  EGP: {
    Currency: 'EGP',
    Symbol: '£',
    Code: '818',
    Name: 'Egyptian Pound',
    Countries: 'Egypt',
  },
  ERN: {
    Currency: 'ERN',
    Symbol: 'Nfk',
    Code: '232',
    Name: 'Nakfa',
    Countries: 'Eritrea',
  },
  ETB: {
    Currency: 'ETB',
    Symbol: '',
    Code: '230',
    Name: 'Ethiopian Birr',
    Countries: 'Ethiopia',
  },
  EUR: {
    Currency: 'EUR',
    Symbol: '€',
    Code: '978',
    Name: 'Euro',
    Countries:
      'Akrotiri and Dhekelia, Andorra, Austria, Belgium, Cypr…tugal, San-Marino, Slovakia, Slovenia, Spain, Vatican',
  },
  FJD: {
    Currency: 'FJD',
    Symbol: '$',
    Code: '242',
    Name: 'Fiji Dollar',
    Countries: 'Fiji',
  },
  FKP: {
    Currency: 'FKP',
    Symbol: '£',
    Code: '238',
    Name: 'Falkland Islands Pound',
    Countries: 'Falkland Islands',
  },
  GBP: {
    Currency: 'GBP',
    Symbol: '£',
    Code: '826',
    Name: 'Pound Sterling',
    Countries: 'Alderney, British Indian Ocean Territory, Great Britain, Isle of Maine',
  },
  GEL: {
    Currency: 'GEL',
    Symbol: 'ლ',
    Code: '981',
    Name: 'Lari',
    Countries: 'Georgia, South Ossetia',
  },
  GHS: {
    Currency: 'GHS',
    Symbol: '₵',
    Code: '936',
    Name: 'Cedi',
    Countries: 'Ghana',
  },
  GIP: {
    Currency: 'GIP',
    Symbol: '£',
    Code: '292',
    Name: 'Gibraltar Pound',
    Countries: 'Gibraltar',
  },
  GMD: {
    Currency: 'GMD',
    Symbol: 'D',
    Code: '270',
    Name: 'Dalasi',
    Countries: 'Gambia',
  },
  GNF: {
    Currency: 'GNF',
    Symbol: '₣',
    Code: '324',
    Name: 'Guinea Franc',
    Countries: 'Guinea',
  },
  GTQ: {
    Currency: 'GTQ',
    Symbol: 'Q',
    Code: '320',
    Name: 'Quetzal',
    Countries: 'Guatemala',
  },
  GYD: {
    Currency: 'GYD',
    Symbol: '$',
    Code: '328',
    Name: 'Guyana Dollar',
    Countries: 'Guyana',
  },
  HKD: {
    Currency: 'HKD',
    Symbol: '$',
    Code: '344',
    Name: 'Hong Kong Dollar',
    Countries: 'Hong Kong',
  },
  HNL: {
    Currency: 'HNL',
    Symbol: 'L',
    Code: '340',
    Name: 'Lempira',
    Countries: 'Honduras',
  },
  HRK: {
    Currency: 'HRK',
    Symbol: 'Kn',
    Code: '191',
    Name: 'Croatian Kuna',
    Countries: 'Croatia',
  },
  HTG: {
    Currency: 'HTG',
    Symbol: 'G',
    Code: '332',
    Name: 'Gourde',
    Countries: 'Haiti',
  },
  HUF: {
    Currency: 'HUF',
    Symbol: 'Ft',
    Code: '348',
    Name: 'Forint',
    Countries: 'Hungary',
  },
  IDR: {
    Currency: 'IDR',
    Symbol: 'Rp',
    Code: '360',
    Name: 'Rupiah',
    Countries: 'Indonesia',
  },
  ILS: {
    Currency: 'ILS',
    Symbol: '₪',
    Code: '376',
    Name: 'New Israeli Shekel',
    Countries: 'Israel, Palestine',
  },
  INR: {
    Currency: 'INR',
    Symbol: '₹',
    Code: '356',
    Name: 'Indian Rupee',
    Countries: 'Bhutan, India',
  },
  IQD: {
    Currency: 'IQD',
    Symbol: 'ع.د',
    Code: '368',
    Name: 'Iraqi Dinar',
    Countries: 'Iraq',
  },
  IRR: {
    Currency: 'IRR',
    Symbol: '﷼',
    Code: '364',
    Name: 'Iranian Rial',
    Countries: 'Iran',
  },
  ISK: {
    Currency: 'ISK',
    Symbol: 'Kr',
    Code: '352',
    Name: 'Iceland Krona',
    Countries: 'Iceland',
  },
  JMD: {
    Currency: 'JMD',
    Symbol: '$',
    Code: '388',
    Name: 'Jamaican Dollar',
    Countries: 'Jamaica',
  },
  JOD: {
    Currency: 'JOD',
    Symbol: 'د.ا',
    Code: '400',
    Name: 'Jordanian Dinar',
    Countries: 'Jordan',
  },
  JPY: {
    Currency: 'JPY',
    Symbol: '¥',
    Code: '392',
    Name: 'Yen',
    Countries: 'Japan',
  },
  KES: {
    Currency: 'KES',
    Symbol: 'Sh',
    Code: '404',
    Name: 'Kenyan Shilling',
    Countries: 'Kenya',
  },
  KGS: {
    Currency: 'KGS',
    Symbol: '',
    Code: '417',
    Name: 'Som',
    Countries: 'Kyrgyzstan',
  },
  KHR: {
    Currency: 'KHR',
    Symbol: '៛',
    Code: '116',
    Name: 'Riel',
    Countries: 'Cambodia',
  },
  KPW: {
    Currency: 'KPW',
    Symbol: '₩',
    Code: '408',
    Name: 'North Korean Won',
    Countries: 'North Korea',
  },
  KRW: {
    Currency: 'KRW',
    Symbol: '₩',
    Code: '410',
    Name: 'South Korean Won',
    Countries: 'South Korea',
  },
  KWD: {
    Currency: 'KWD',
    Symbol: 'د.ك',
    Code: '414',
    Name: 'Kuwaiti Dinar',
    Countries: 'Kuwait',
  },
  KYD: {
    Currency: 'KYD',
    Symbol: '$',
    Code: '136',
    Name: 'Cayman Islands Dollar',
    Countries: 'Cayman Islands',
  },
  KZT: {
    Currency: 'KZT',
    Symbol: '〒',
    Code: '398',
    Name: 'Tenge',
    Countries: 'Kazakhstan',
  },
  LAK: {
    Currency: 'LAK',
    Symbol: '₭',
    Code: '418',
    Name: 'Kip',
    Countries: 'Laos',
  },
  LBP: {
    Currency: 'LBP',
    Symbol: 'ل.ل',
    Code: '422',
    Name: 'Lebanese Pound',
    Countries: 'Lebanon',
  },
  LKR: {
    Currency: 'LKR',
    Symbol: 'Rs',
    Code: '144',
    Name: 'Sri Lanka Rupee',
    Countries: 'Sri Lanka',
  },
  LRD: {
    Currency: 'LRD',
    Symbol: '$',
    Code: '430',
    Name: 'Liberian Dollar',
    Countries: 'Liberia',
  },
  LSL: {
    Currency: 'LSL',
    Symbol: 'L',
    Code: '426',
    Name: 'Loti',
    Countries: 'Lesotho',
  },
  LYD: {
    Currency: 'LYD',
    Symbol: 'ل.د',
    Code: '434',
    Name: 'Libyan Dinar',
    Countries: 'Libya',
  },
  MAD: {
    Currency: 'MAD',
    Symbol: 'د.م.',
    Code: '504',
    Name: 'Moroccan Dirham',
    Countries: 'Morocco',
  },
  MDL: {
    Currency: 'MDL',
    Symbol: 'L',
    Code: '498',
    Name: 'Moldavian Leu',
    Countries: 'Moldova',
  },
  MGA: {
    Currency: 'MGA',
    Symbol: '',
    Code: '969',
    Name: 'Malagasy Ariary',
    Countries: 'Madagascar',
  },
  MKD: {
    Currency: 'MKD',
    Symbol: 'ден',
    Code: '807',
    Name: 'Denar',
    Countries: 'Macedonia',
  },
  MMK: {
    Currency: 'MMK',
    Symbol: 'K',
    Code: '104',
    Name: 'Kyat',
    Countries: 'Myanmar (Burma)',
  },
  MNT: {
    Currency: 'MNT',
    Symbol: '₮',
    Code: '496',
    Name: 'Tugrik',
    Countries: 'Mongolia',
  },
  MOP: {
    Currency: 'MOP',
    Symbol: 'P',
    Code: '446',
    Name: 'Pataca',
    Countries: 'Macao',
  },
  MRO: {
    Currency: 'MRO',
    Symbol: 'UM',
    Code: '478',
    Name: 'Ouguiya',
    Countries: 'Mauritania',
  },
  MUR: {
    Currency: 'MUR',
    Symbol: '₨',
    Code: '480',
    Name: 'Mauritius Rupee',
    Countries: 'Mauritius',
  },
  MVR: {
    Currency: 'MVR',
    Symbol: 'ރ.',
    Code: '462',
    Name: 'Rufiyaa',
    Countries: 'Maldives',
  },
  MWK: {
    Currency: 'MWK',
    Symbol: 'MK',
    Code: '454',
    Name: 'Kwacha',
    Countries: 'Malawi',
  },
  MXN: {
    Currency: 'MXN',
    Symbol: '$',
    Code: '484',
    Name: 'Mexican Peso',
    Countries: 'Mexico',
  },
  MYR: {
    Currency: 'MYR',
    Symbol: 'RM',
    Code: '458',
    Name: 'Malaysian Ringgit',
    Countries: 'Malaysia',
  },
  MZN: {
    Currency: 'MZN',
    Symbol: 'MTn',
    Code: '943',
    Name: 'Metical',
    Countries: 'Mozambique',
  },
  NAD: {
    Currency: 'NAD',
    Symbol: '$',
    Code: '516',
    Name: 'Namibia Dollar',
    Countries: 'Namibia',
  },
  NGN: {
    Currency: 'NGN',
    Symbol: '₦',
    Code: '566',
    Name: 'Naira',
    Countries: 'Nigeria',
  },
  NIO: {
    Currency: 'NIO',
    Symbol: 'C$',
    Code: '558',
    Name: 'Cordoba Oro',
    Countries: 'Nicaragua',
  },
  NOK: {
    Currency: 'NOK',
    Symbol: 'kr',
    Code: '578',
    Name: 'Norwegian Krone',
    Countries: 'Norway',
  },
  NPR: {
    Currency: 'NPR',
    Symbol: '₨',
    Code: '524',
    Name: 'Nepalese Rupee',
    Countries: 'Nepal',
  },
  NZD: {
    Currency: 'NZD',
    Symbol: '$',
    Code: '554',
    Name: 'New Zealand Dollar',
    Countries: 'Cook Islands, New Zealand, Niue, Pitcairn Island',
  },
  OMR: {
    Currency: 'OMR',
    Symbol: 'ر.ع.',
    Code: '512',
    Name: 'Rial Omani',
    Countries: 'Oman',
  },
  PAB: {
    Currency: 'PAB',
    Symbol: 'B/.',
    Code: '590',
    Name: 'Balboa',
    Countries: 'Panama',
  },
  PEN: {
    Currency: 'PEN',
    Symbol: 'S/.',
    Code: '604',
    Name: 'Nuevo Sol',
    Countries: 'Peru',
  },
  PGK: {
    Currency: 'PGK',
    Symbol: 'K',
    Code: '598',
    Name: 'Kina',
    Countries: 'Papua New Guinea',
  },
  PHP: {
    Currency: 'PHP',
    Symbol: '₱',
    Code: '608',
    Name: 'Philippine Peso',
    Countries: 'Philippines',
  },
  PKR: {
    Currency: 'PKR',
    Symbol: '₨',
    Code: '586',
    Name: 'Pakistan Rupee',
    Countries: 'Pakistan',
  },
  PLN: {
    Currency: 'PLN',
    Symbol: 'zł',
    Code: '985',
    Name: 'PZloty',
    Countries: 'Poland',
  },
  PYG: {
    Currency: 'PYG',
    Symbol: '₲',
    Code: '600',
    Name: 'Guarani',
    Countries: 'Paraguay',
  },
  QAR: {
    Currency: 'QAR',
    Symbol: 'ر.ق',
    Code: '634',
    Name: 'Qatari Rial',
    Countries: 'Qatar',
  },
  RON: {
    Currency: 'RON',
    Symbol: 'L',
    Code: '946',
    Name: 'Leu',
    Countries: 'Romania',
  },
  RSD: {
    Currency: 'RSD',
    Symbol: 'din',
    Code: '941',
    Name: 'Serbian Dinar',
    Countries: 'Kosovo, Serbia',
  },
  RUB: {
    Currency: 'RUB',
    Symbol: 'р.',
    Code: '643',
    Name: 'Russian Ruble',
    Countries: 'Russia, South Ossetia',
  },
  RWF: {
    Currency: 'RWF',
    Symbol: '₣',
    Code: '646',
    Name: 'Rwanda Franc',
    Countries: 'Rwanda',
  },
  SAR: {
    Currency: 'SAR',
    Symbol: 'ر.س',
    Code: '682',
    Name: 'Saudi Riyal',
    Countries: 'Saudi Arabia',
  },
  SBD: {
    Currency: 'SBD',
    Symbol: '$',
    Code: '090',
    Name: 'Solomon Islands Dollar',
    Countries: 'Solomon Islands',
  },
  SCR: {
    Currency: 'SCR',
    Symbol: '₨',
    Code: '690',
    Name: 'Seychelles Rupee',
    Countries: 'Seychelles',
  },
  SDG: {
    Currency: 'SDG',
    Symbol: '£',
    Code: '938',
    Name: 'Sudanese Pound',
    Countries: 'Sudan',
  },
  SEK: {
    Currency: 'SEK',
    Symbol: 'kr',
    Code: '752',
    Name: 'Swedish Krona',
    Countries: 'Sweden',
  },
  SGD: {
    Currency: 'SGD',
    Symbol: '$',
    Code: '702',
    Name: 'Singapore Dollar',
    Countries: 'Brunei, Singapore',
  },
  SHP: {
    Currency: 'SHP',
    Symbol: '£',
    Code: '654',
    Name: 'Saint Helena Pound',
    Countries: 'Ascension Island, Saint Helena, Tristan da Cunha',
  },
  SLL: {
    Currency: 'SLL',
    Symbol: 'Le',
    Code: '694',
    Name: 'Leone',
    Countries: 'Sierra Leone',
  },
  SOS: {
    Currency: 'SOS',
    Symbol: 'Sh',
    Code: '706',
    Name: 'Somali Shilling',
    Countries: 'Somalia',
  },
  SRD: {
    Currency: 'SRD',
    Symbol: '$',
    Code: '968',
    Name: 'Suriname Dollar',
    Countries: 'Suriname',
  },
  STD: {
    Currency: 'STD',
    Symbol: 'Db',
    Code: '678',
    Name: 'Dobra',
    Countries: 'Sao Tome and Principe',
  },
  SYP: {
    Currency: 'SYP',
    Symbol: 'ل.س',
    Code: '760',
    Name: 'Syrian Pound',
    Countries: 'Syria',
  },
  SZL: {
    Currency: 'SZL',
    Symbol: 'L',
    Code: '748',
    Name: 'Lilangeni',
    Countries: 'Swaziland',
  },
  THB: {
    Currency: 'THB',
    Symbol: '฿',
    Code: '764',
    Name: 'Baht',
    Countries: 'Thailand',
  },
  TJS: {
    Currency: 'TJS',
    Symbol: 'ЅМ',
    Code: '972',
    Name: 'Somoni',
    Countries: 'Tajikistan',
  },
  TMT: {
    Currency: 'TMT',
    Symbol: 'm',
    Code: '934',
    Name: 'Manat',
    Countries: 'Turkmenistan',
  },
  TND: {
    Currency: 'TND',
    Symbol: 'د.ت',
    Code: '788',
    Name: 'Tunisian Dinar',
    Countries: 'Tunisia',
  },
  TOP: {
    Currency: 'TOP',
    Symbol: 'T$',
    Code: '776',
    Name: 'Pa’anga',
    Countries: 'Tonga',
  },
  TRY: {
    Currency: 'TRY',
    Symbol: '₤',
    Code: '949',
    Name: 'Turkish Lira',
    Countries: 'North Cyprus, Turkey',
  },
  TTD: {
    Currency: 'TTD',
    Symbol: '$',
    Code: '780',
    Name: 'Trinidad and Tobago Dollar',
    Countries: 'Trinidad and Tobago',
  },
  TWD: {
    Currency: 'TWD',
    Symbol: '$',
    Code: '901',
    Name: 'Taiwan Dollar',
    Countries: 'Taiwan',
  },
  TZS: {
    Currency: 'TZS',
    Symbol: 'Sh',
    Code: '834',
    Name: 'Tanzanian Shilling',
    Countries: 'Tanzania',
  },
  UAH: {
    Currency: 'UAH',
    Symbol: '₴',
    Code: '980',
    Name: 'Hryvnia',
    Countries: 'Ukraine',
  },
  UGX: {
    Currency: 'UGX',
    Symbol: 'Sh',
    Code: '800',
    Name: 'Uganda Shilling',
    Countries: 'Uganda',
  },
  USD: {
    Currency: 'USD',
    Symbol: '$',
    Code: '840',
    Name: 'US Dollar',
    Countries:
      'American Samoa, British Indian Ocean Territory, Brit…lands, United States of America, US Virgin Islands',
  },
  UYU: {
    Currency: 'UYU',
    Symbol: '$',
    Code: '858',
    Name: 'Peso Uruguayo',
    Countries: 'Uruguay',
  },
  UZS: {
    Currency: 'UZS',
    Symbol: '',
    Code: '860',
    Name: 'Uzbekistan Sum',
    Countries: 'Uzbekistan',
  },
  VEF: {
    Currency: 'VEF',
    Symbol: 'Bs F',
    Code: '937',
    Name: 'Bolivar Fuerte',
    Countries: 'Venezuela',
  },
  VND: {
    Currency: 'VND',
    Symbol: '₫',
    Code: '704',
    Name: 'Dong',
    Countries: 'Vietnam',
  },
  VUV: {
    Currency: 'VUV',
    Symbol: 'Vt',
    Code: '548',
    Name: 'Vatu',
    Countries: 'Vanuatu',
  },
  WST: {
    Currency: 'WST',
    Symbol: 'T',
    Code: '882',
    Name: 'Tala',
    Countries: 'Samoa',
  },
  XAF: {
    Currency: 'XAF',
    Symbol: '₣',
    Code: '950',
    Name: 'CFA Franc BCEAO',
    Countries:
      'Benin, Burkina Faso, Cameroon, Central African Republ…inea, Gabon, Guinea-Bissau, Mali, Niger, Senegal, Togo',
  },
  XCD: {
    Currency: 'XCD',
    Symbol: '$',
    Code: '951',
    Name: 'East Caribbean Dollar',
    Countries:
      'Anguilla, Antigua and Barbuda, Dominica, Grenada, Mont…nd Nevis, Saint Lucia, Saint Vincent and Grenadine',
  },
  XOF: {
    Currency: 'XOF',
    Symbol: 'F', // standard is CFA but 1 customer says it should be F (nw, we'll come back here later)
    Code: '952',
    Name: 'Franc CFA',
    Countries: 'Benin, Burkina Faso, Ivory Coast, Guinea-Bissau, Mali, Niger, Senegal, Togo',
  },
  XPF: {
    Currency: 'XPF',
    Symbol: '₣',
    Code: '953',
    Name: 'CFP Franc',
    Countries: 'French Polynesia, New Caledonia, Wallis and Futuna',
  },
  YER: {
    Currency: 'YER',
    Symbol: '﷼',
    Code: '886',
    Name: 'Yemeni Rial',
    Countries: 'Yemen',
  },
  ZAR: {
    Currency: 'ZAR',
    Symbol: 'R',
    Code: '710',
    Name: 'Rand',
    Countries: 'Lesotho, Namibia, South Africa',
  },
  ZMW: {
    Currency: 'ZMW',
    Symbol: 'ZK',
    Code: '967',
    Name: 'Zambian Kwacha',
    Countries: 'Zambia',
  },
  ZWL: {
    Currency: 'ZWL',
    Symbol: '$',
    Code: '932',
    Name: 'Zimbabwe Dollar',
    Countries: 'Zimbabwe',
  },
};

/**
 * @deprecated use fd-react-intl formatCurrency or FormattedCurrency
 */
export const getSymbol = (currencyCode: StoreGroup.CurrencyEnum | string) => {
  const c = currency[StoreGroup.CurrencyEnum[currencyCode]];
  return (c && c.Symbol) || currencyCode;
};
