import React from 'react';

import MuiListItem, { type ListItemProps } from '@mui/material/ListItem';
import { type LinkProps, Link } from 'react-router-dom';

// TODO: test if button props is needed here or if its just a type issue
type IListItemLinkProps = ListItemProps & LinkProps & { fdKey: string } & { button?: boolean };
const ListItem = MuiListItem as React.ComponentType<
  React.PropsWithChildren<ListItemProps & LinkProps>
>;

const ListItemLink: React.FC<React.PropsWithChildren<IListItemLinkProps>> = ({
  fdKey,
  ...rest
}) => {
  // no Link for external URL
  return <ListItem {...rest} data-fd={fdKey} {...(rest.to ? { component: Link } : {})} />;
};

export default ListItemLink;
