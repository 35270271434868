import React, { useMemo, useState } from 'react';

import NoSsr from '@mui/material/NoSsr';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash.debounce';
import RsSelect, { components as rsComponents } from 'react-select';
import { SelectComponents } from 'react-select/lib/components';
import { ControlProps } from 'react-select/lib/components/Control';
import { Props as RsProps } from 'react-select/lib/Select';
import { InputActionMeta } from 'react-select/lib/types';

import {
  ClearIndicator,
  DropdownIndicator,
  inputComponent,
  LoadingMessage,
  NoOptionsMessage,
  Option,
  SingleValue,
} from '@fd/ui/Select/Select';
import TextField, { TextFieldProps } from '@fd/ui/TextField/TextField';

export type OptionType = {
  label: string;
  value: any;
};

const useStyles = makeStyles(() => ({
  input: {
    display: 'flex',
  },
  field: {
    padding: 0,
  },
  helperText: {
    textAlign: 'right',
  },
}));

export const Control = (props: ControlProps<OptionType>) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps },
  } = props;
  const classes = useStyles();

  return (
    <TextField
      data-fd={TextFieldProps.fdKey}
      fullWidth
      className={classes.field}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      {...TextFieldProps}
    />
  );
};

export const components: SelectComponents<OptionType> = {
  ...rsComponents,
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorSeparator: () => null,
  LoadingMessage,
  NoOptionsMessage,
  Option,
  SingleValue,
};

export type AutoCompleteFieldProps = RsProps<OptionType> & {
  dataFd?: string;
  fieldError?: {} | string | undefined;
  isLoading?: boolean;
  options?: OptionType[];
  onSelectChange: (value) => void;
  onInputTextChange: (value: string) => void;
  placeholder?: string;
  TextFieldProps: TextFieldProps;
  variant?: 'outlined' | 'standard';
};

export const AutoCompleteField = (props: AutoCompleteFieldProps) => {
  const classes = useStyles();
  const [input, setInput] = useState<string>('');
  const { TextFieldProps, value, ...rest } = props;
  const { dataFd, isLoading, onInputTextChange, onSelectChange, options, variant } = rest;

  const debounceOnChangeHandler = useMemo(
    () =>
      debounce((inputValue: string) => {
        onInputTextChange(inputValue);
      }, 500),
    []
  );

  const handleSelectChange = (option?: OptionType) => {
    if (option) {
      onSelectChange(option.value);
    } else {
      setInput('');
      onInputTextChange('');
    }
  };

  const handleInputTextChange = (newInputValue: string) => {
    setInput(newInputValue);
    debounceOnChangeHandler(newInputValue);
  };

  const handleInputChange = (value: string, action: InputActionMeta) => {
    if (action.action === 'input-change') {
      handleInputTextChange(value);
    } else if (action.action === 'set-value') {
      setInput(value);
    }
  };

  return (
    <NoSsr>
      <RsSelect
        classes={classes}
        closeMenuOnSelect
        components={components}
        dataFd={dataFd}
        inputId="react-select"
        inputValue={input}
        isClearable
        isLoading={isLoading}
        options={options}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        placeholder=""
        TextFieldProps={{
          variant: variant ? variant : 'outlined',
          InputLabelProps: {
            htmlFor: 'react-select',
            shrink: true,
          },
          style: { zIndex: 0 },
          ...TextFieldProps,
        }}
        value={value}
        {...rest}
      />
    </NoSsr>
  );
};
