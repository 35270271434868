import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { getOrderById, resetOrderState } from '../../../actions/order.action';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { AppLoadingCircularProgress } from '../../AppLoadingCircularProgress';
import OrderDeliveryMap from '../components/OrderDeliveryMap';
import DetailsCaption from './DetailsCaption';
import DetailsTitle from './DetailsTitle';
import OrderDetailsView from './DetailsView';

type MatchParams = {
  orderId: string;
};

type Props = MappedState & MappedDispatch & RouteComponentProps<MatchParams>;

const OrderDetails: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { currentApp, getOrderById, order, orderLoading, resetOrderState, translate } = props;

  const { orderId } = useParams<MatchParams>();

  useEffect(() => {
    const orderIdParsed = parseInt(orderId, 10);

    getOrderById(orderIdParsed);
  }, []);

  // @ts-ignore
  useEffect(() => {
    // prevent stale state

    return () => resetOrderState();
  }, []);

  return (
    <PageLayout
      auditLogsFilter={{ type: 'OrderId', value: `${orderId}` }}
      title={<DetailsTitle order={order} />}
      documentTitle="Order"
      caption={<DetailsCaption order={order} translate={translate} />}
      toParent={`/${currentApp.AppId}/orders`}
    >
      <>
        <PaperContainer fluid>
          {orderLoading ? (
            <div style={{ height: '100vh', width: '100vh', paddingTop: '10%' }}>
              <AppLoadingCircularProgress />
            </div>
          ) : order && order.OrderId ? (
            <OrderDetailsView order={order} />
          ) : (
            <EmptyComponent title={'Whoops'} subtitle={'No_order_found'} noLink />
          )}
        </PaperContainer>

        {!orderLoading && order && order.OrderId && <OrderDeliveryMap orderId={order.OrderId} />}
      </>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    order: orders.order,
    orderLoading: orders.orderLoading,
    currentApp: state.currentApp,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOrderById: (id: number) => dispatch(getOrderById(id, true)),
  resetOrderState: () => dispatch(resetOrderState()),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,

  connect(mapStateToProps, mapDispatchToProps)
)(OrderDetails);

export default EnhancedComponent;
