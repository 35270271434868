import axios from 'axios';
import moment from 'moment';

import { getApiEndPoint } from '../../../helpers/apibase';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// #region getEndOfDayReport

export async function getEndOfDayReport({
  storeId,
  date = new Date(),
}: {
  storeId: number;
  date?: Date;
}) {
  try {
    const msg = await portalApi.get(
      `/api/v1.0/stores/${encodeURIComponent(storeId.toString())}/endofdayreport`,
      {
        params: {
          date: moment(date).format('YYYY-MM-DD'),
        },
      }
    );

    return msg.data.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion
