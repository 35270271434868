import {
  CreateMetafieldDefinition,
  MetafieldDefinitionsApiFactory,
  UpdateMetafieldDefinition,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();
const metafieldsApi = createApi(MetafieldDefinitionsApiFactory);

const newMetafieldApis = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

async function createMetafieldDefinition(
  appId: string,
  entity,
  definition: CreateMetafieldDefinition
) {
  try {
    const result = await metafieldsApi.createMetafieldDefinition(appId, entity, definition);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getMetafieldEntitiesQueryKey = 'getMetafieldEntities';
async function getMetafieldEntitities(appId: string) {
  try {
    const result = await newMetafieldApis.get(`${appId}/metafields/definitions`);
    return result.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getMetafieldsByEntityQueryKey = 'getMetafieldsByEntity';
async function getMetafieldsByEntity(appId: string, entity) {
  try {
    const result = await metafieldsApi.getMetafieldDefinitions(appId, entity);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getMetafieldByKeyQueryKey = 'getMetafieldsByKey';
async function getMetafieldByKey(appId: string, entity, key: string) {
  try {
    const result = await metafieldsApi.getMetafieldDefinitionByKey(appId, entity, key);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getOwnerEntityRecommendationsQueryKey = 'getOwnerEntityRecommendations';
async function getOwnerEntityRecommendations(appId: string, entity, searchTerm: string) {
  try {
    const result = await metafieldsApi.getOwnerEntityRecommendations(appId, entity, searchTerm);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateMetafieldDefinition(
  appId: string,
  entity,
  key: string,
  definition: UpdateMetafieldDefinition
) {
  try {
    const result = await metafieldsApi.updateMetafieldDefinition(appId, entity, key, definition);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const metafieldsService = {
  createMetafieldDefinition,
  getMetafieldsByEntity,
  getMetafieldsByEntityQueryKey,
  getMetafieldByKey,
  getMetafieldByKeyQueryKey,
  getMetafieldEntitities,
  getMetafieldEntitiesQueryKey,
  getOwnerEntityRecommendations,
  getOwnerEntityRecommendationsQueryKey,
  updateMetafieldDefinition,
};
