import React, { useEffect, useMemo, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Translate } from '../../../../../overrides/react-localize-redux';
import { type Period, getTimesPerInterval } from './OrderCapacity';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiInputAdornment-positionEnd': {
        whiteSpace: 'nowrap',
      },
    },
    margin: {
      marginTop: '16px',
    },
    textField: {
      margin: 0,
    },
    textFontSize: {
      fontSize: '16px',
    },
  })
);

type Props = {
  action: 'add' | 'edit';
  period: Period;
  localDayNames: Array<{ label: string; value: string }>;
  interval: number;
  onChange(period: Period): void;
};

const SetPeriodForm = ({ period, localDayNames, interval, onChange }: Props) => {
  const classes = useStyles();
  const [newPeriod, setNewPeriod] = useState<Period>(() => ({ ...period }));

  // const hasError = startTime.getTime() > endTime.getTime();

  useEffect(() => {
    onChange(newPeriod);
  }, [newPeriod]);

  const periodOptions = useMemo(() => {
    // TODO missing AM/PM here
    const periodsPerInterval = getTimesPerInterval(15);
    return periodsPerInterval;
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          fullWidth
          select
          value={newPeriod.day}
          inputProps={{
            'data-fd': 'order_capacity_day',
          }}
          onChange={(e) => {
            setNewPeriod((p) => ({
              ...p,
              day: e.target.value as Period['day'],
            }));
          }}
        >
          {localDayNames.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          fullWidth
          select
          value={`${('0' + newPeriod.start.hour).slice(-2)}:${('0' + newPeriod.start.minute).slice(-2)}`}
          inputProps={{
            'data-fd': 'order_capacity_start_time',
          }}
          onChange={(e) => {
            const [hour, minute] = e.target.value.split(':');
            setNewPeriod((p) => ({
              ...p,
              start: {
                hour: parseInt(hour, 10),
                minute: parseInt(minute, 10),
              },
            }));
          }}
        >
          {periodOptions.map((p) => (
            <MenuItem key={p} value={p}>
              {p}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          fullWidth
          select
          value={`${('0' + newPeriod.end.hour).slice(-2)}:${('0' + newPeriod.end.minute).slice(-2)}`}
          inputProps={{
            'data-fd': 'order_capacity_end_time',
          }}
          onChange={(e) => {
            const [hour, minute] = e.target.value.split(':');
            setNewPeriod((p) => ({
              ...p,
              end: {
                hour: parseInt(hour, 10),
                minute: parseInt(minute, 10),
              },
            }));
          }}
        >
          {periodOptions.map((p) => (
            <MenuItem key={p} value={p}>
              {p}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} className={classes.margin}>
        <FormControl className={clsx(classes.root, classes.textField)}>
          <Translate>
            {(translate) => (
              <Input
                id="standard-adornment-weight"
                // placeholder={<Translate id="Default" />}
                value={newPeriod.orders || ''}
                onChange={(e) => {
                  setNewPeriod((p) => ({
                    ...p,
                    orders: parseInt(e.target.value, 10) || 0,
                  }));
                }}
                endAdornment={
                  <InputAdornment component="div" position="end">
                    <Typography variant="caption" className={classes.textFontSize}>
                      {`${translate('Orders')} / ${translate('minutes_amount', {
                        mins: interval,
                      })}`}
                    </Typography>
                  </InputAdornment>
                }
                aria-describedby="input-orders-amount"
                inputProps={{
                  'aria-label': `${translate('Orders')} / ${translate('minutes_amount', {
                    mins: interval,
                  })}`,
                  'data-fd': `order_minutes_amount`,
                }}
              />
            )}
          </Translate>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SetPeriodForm;
