import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const GridDivider = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

export default GridDivider;
