import React, { useEffect } from 'react';

import { BankAccountSummary, StripeConnectedAccount } from '@flipdish/api-client-typescript';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { useTracking } from '../../../../services/amplitude/useTracking';
import StoreListTruncated from '../../../StoreListTruncated';
import { AssignRejectionReason } from './RejectionReason';

const useStyles = makeStyles(() => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemRejected: {
    color: '#ff395f',
  },
  listItemAlert: {
    color: '#d96300',
  },
  listItemVerified: {
    color: '#33aa73',
  },
  listItemButton: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '&:focus': {
      backgroundColor: '#eaf2ff',
    },
  },
  bankingHeader: {
    lineHeight: '0.5px',
  },
  storesTruncated: {
    color: '#05149e',
    fontSize: '12px',
    padding: 0,
    textTransform: 'capitalize',
  },
  infoSmall: {
    width: '13px',
    height: '13px',
    color: 'rgba(0, 0, 0, 0.54)',
    overflow: 'visible',
    alignItems: 'center',
    backgroundColor: 'inherit',
    marginLeft: '2px',
    verticalAlign: 'middle',
  },
}));

export interface BankingAccount {
  account: BankAccountSummary;
}

type Props = BankingAccount;
const AccountDetails: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { account } = props;
  const bankStateEnum = BankAccountSummary.AccountStateEnum;
  const stripeStateEnum = StripeConnectedAccount.AccountStatusEnum;
  const bankAccountState = account.AccountState || {};
  const stripeAccountState = account.StripeConnectedAccountInfo
    ? account.StripeConnectedAccountInfo.AccountStatus
    : {};
  const businessType = account.BusinessType || '';
  const accountName = account.AccountName || '';
  const accountRejectReason = account.RejectionReason || '';
  const accountStoreNames = account.StoreNames || '';
  const iban = account.Iban || {};
  const classes = useStyles();

  const getStatusfromAccountState = () => {
    switch (true) {
      case bankAccountState === bankStateEnum.Unverified ||
        stripeAccountState === stripeStateEnum.Disabled:
        return stripeStateEnum.Disabled;
      case stripeAccountState === stripeStateEnum.Rejected:
        return stripeStateEnum.Rejected;
      case (bankAccountState === bankStateEnum.Verified ||
        bankAccountState === bankStateEnum.AwatingVerification ||
        bankAccountState === bankStateEnum.Grandfathered) &&
        stripeAccountState === stripeStateEnum.UpdateExternalAccount:
        return stripeStateEnum.UpdateExternalAccount;
      case stripeAccountState === stripeStateEnum.AdditionalInformationRequired:
        return stripeStateEnum.AdditionalInformationRequired;
      case stripeAccountState === stripeStateEnum.Unverified || businessType === '':
        return stripeStateEnum.Unverified;
      case stripeAccountState === stripeStateEnum.PendingVerification ||
        bankAccountState === bankStateEnum.AwatingVerification:
        return stripeStateEnum.PendingVerification;
      case bankAccountState === bankStateEnum.Verified ||
        stripeAccountState === stripeStateEnum.Enabled ||
        bankAccountState === bankStateEnum.Grandfathered:
        return stripeStateEnum.Enabled;
      default:
        return stripeStateEnum.PendingVerification;
    }
  };

  const getTitleClass = () => {
    return bankAccountState === bankStateEnum.Unverified ||
      stripeAccountState === stripeStateEnum.Disabled
      ? classes.listItemRejected
      : '';
  };

  const getbankingSubtitle = () => {
    const accountDetailsStatus = getStatusfromAccountState();

    switch (accountDetailsStatus) {
      case stripeStateEnum.Disabled:
      case stripeStateEnum.Rejected:
        return (
          <>
            <Typography color="secondary" variant="caption">
              {accountRejectReason && (
                <AssignRejectionReason rejectionReason={accountRejectReason} />
              )}
            </Typography>
            <br />
            <Typography color="secondary" variant="caption">
              <Translate id="Please_try_to_add_the_bank_account_again" />
            </Typography>
          </>
        );
      case stripeStateEnum.PendingVerification:
        return (
          <Typography variant="caption">
            <Translate id="Pending_verification_we_will_notify_you" />
          </Typography>
        );
      case stripeStateEnum.AdditionalInformationRequired:
        return (
          <Typography variant="caption" className={classes.listItemAlert}>
            <Translate id="You_must_provide_additional_information" />
          </Typography>
        );
      case stripeStateEnum.Unverified:
        return (
          <Typography variant="caption" className={classes.listItemAlert}>
            <Translate id="verification_required_to_receive_payouts" />
          </Typography>
        );
      case stripeStateEnum.UpdateExternalAccount:
        return (
          <Typography variant="caption" className={classes.listItemAlert}>
            <Translate id="Please_update_your_banking_details_for_this_account" />
          </Typography>
        );
      case stripeStateEnum.Enabled:
        return (
          <Typography variant="caption" className={classes.listItemVerified}>
            <Translate id="Verification_complete" />
          </Typography>
        );
      default:
        return null;
    }
  };

  const { trackEvent } = useTracking();

  useEffect(() => {
    if (typeof stripeAccountState !== 'object' && stripeAccountState) {
      trackEvent('portal_stripe_status', {
        status: `${stripeAccountState}`,
      });
    }
  }, []);

  return (
    <ListItemText
      primary={
        <div className={classes.bankingHeader} data-fd="account-details-list">
          <Typography variant="subtitle1" className={getTitleClass()}>
            {accountName}
          </Typography>
          <Typography variant="caption" className={getTitleClass()}>
            <>{iban} </>
          </Typography>
          {accountStoreNames && (
            <StoreListTruncated
              storeNames={accountStoreNames}
              textPrefix={true}
              variant={'caption'}
              btnFontSize={'12px'}
              minWidth={'small'}
              color="textSecondary"
            />
          )}
        </div>
      }
      secondary={getbankingSubtitle()}
    />
  );
};

export default AccountDetails;
