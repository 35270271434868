import React, { useCallback } from 'react';

import MuiListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { useMutation } from '@tanstack/react-query';
import { connect } from 'react-redux';

import { notify, notifyError } from '../../../../layouts/Notify/actions';
import { storeService } from '../../../../services/store.service';
import Switch from '../../../../ui/Switch/Switch';
import * as actions from '../actions';

type RequireCustomerName = { RequireCustomerName: boolean };
type RequireCustomerPhoneNumber = { RequireCustomerPhoneNumber: boolean };
type RequestCustomerPhoneNumber = { RequestCustomerPhoneNumber: boolean };
type RequestTableNumber = { RequestTableNumber: boolean };
type OfferDineInOrTakeawayOption = { OfferDineInOrTakeawayOption: boolean };
type RequiredProps = { PhysicalRestaurantId: number; KioskSettingId: number };
export type PartialStoreKioskSetting = (
  | RequireCustomerName
  | RequestTableNumber
  | OfferDineInOrTakeawayOption
  | RequireCustomerPhoneNumber
  | RequestCustomerPhoneNumber
) &
  RequiredProps;

export type ListItemProps = {
  data: FlipdishPrivate.StoreKioskSetting;
  divider: boolean;
  valueResolver(data: FlipdishPrivate.StoreKioskSetting): boolean;
  onChange(data: FlipdishPrivate.StoreKioskSetting): PartialStoreKioskSetting;
};
type InnerProps = MappedDispatch;

const ListItem = (props: ListItemProps & InnerProps) => {
  const {
    data,
    divider,
    valueResolver,
    onChange,
    updateState,
    undoUpdateState,
    notifySaved,
    notifyError,
  } = props;

  const handleChange = useCallback(async () => {
    const obj = onChange(data);
    updateState(obj);
    mutate(obj);
  }, [onChange, data]);

  const { isPending, mutate } = useMutation({
    mutationFn: (obj: PartialStoreKioskSetting) =>
      storeService.updateKioskSettings(obj.PhysicalRestaurantId, obj),

    onSuccess: () => {
      notifySaved();
    },

    onError: (_data, obj) => {
      undoUpdateState(obj);
      notifyError();
    },
  });

  return (
    <MuiListItem key={`${data.KioskSettingId}`} disableGutters divider={divider}>
      <ListItemText id={`switch-${data.KioskName}`} primary={data.KioskName} />

      <ListItemSecondaryAction>
        <Switch
          edge="end"
          checked={valueResolver(data)}
          onChange={handleChange}
          disabled={isPending}
          inputProps={{ 'aria-labelledby': `switch-${data.KioskName}` }}
          fdKey={`switch-${data.KioskName}`}
        />
      </ListItemSecondaryAction>
    </MuiListItem>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: () => dispatch(notifyError({ message: 'Modification_failed', translate: true })),
  notifySaved: () =>
    dispatch(
      notify({
        message: 'Successfully_modified' as TranslationId,
        variant: 'success',
        translate: true,
      })
    ),
  updateState: (config: PartialStoreKioskSetting) => {
    dispatch(actions.updateStoreKioskSetting(config));
  },
  undoUpdateState: (config: PartialStoreKioskSetting) => {
    // just revert boolean value we passing
    const undoConfig = Object.entries(config).reduce((agg, [key, val]) => {
      if (typeof val == 'boolean') {
        agg[key] = !val;
      } else {
        agg[key] = val;
      }
      return agg;
    }, {} as PartialStoreKioskSetting);
    dispatch(actions.updateStoreKioskSetting(undoConfig));
  },
});

export default connect(null, mapDispatchToProps)(ListItem);
