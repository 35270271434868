import * as React from 'react';

import { OrderSummary } from '@flipdish/api-client-typescript';
import Avatar from '@mui/material/Avatar';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { compose } from 'recompose';

import DeliveryIconOrderIngest from '../../../assets/images/ico_delivery.svg';
import PickupIcon from '../../../assets/images/ico_pickup.svg';
import ExternalIcon from './ExternalIcon';

const styles = () =>
  createStyles({
    root: {
      '& .MuiAvatar-root': {
        overflow: 'visible',
      },
    },
    oval: {
      padding: '10px',
      width: '40px',
      height: '40px',
      margin: '0px 16px 0px 0',
      borderRadius: '50%',
      float: 'left',
    },
    shape: {
      width: '24px',
      height: '24px',
      float: 'left',
      color: '#ffffff',
      fontSize: '13px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.85',
      letterSpacing: 'normal',
    },
    iconColorReady: {
      backgroundColor: '#deacfa',
    },
    iconColorRejected: {
      backgroundColor: '#fb7c92',
    },
    iconColorAccepted: {
      backgroundColor: '#86e8bb',
    },
    bigOval: {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
    },
  });

type Props = WithStyles<typeof styles> & Iconprops;
export interface Iconprops {
  deliveryType?: OrderSummary.DeliveryTypeEnum;
  OrderState?: OrderSummary.OrderStateEnum;
  size: string;
  channelOrder?: boolean;
}

const Icon = compose<Props, Iconprops>(
  withStyles(styles, {
    name: 'IconStatus',
    withTheme: true,
  })
)((props) => {
  const { classes } = props;

  const className = clsx(props.size === 'small' ? classes.oval : classes.bigOval, {
    [classes.iconColorReady]: props.OrderState === OrderSummary.OrderStateEnum.ReadyToProcess,
    [classes.iconColorAccepted]:
      props.OrderState === OrderSummary.OrderStateEnum.AcceptedByRestaurant ||
      props.OrderState === OrderSummary.OrderStateEnum.Dispatched ||
      props.OrderState === OrderSummary.OrderStateEnum.AcceptedAndRefunded,
    [classes.iconColorRejected]:
      props.OrderState === OrderSummary.OrderStateEnum.RejectedByFlipdish ||
      props.OrderState === OrderSummary.OrderStateEnum.RejectedByStore ||
      props.OrderState === OrderSummary.OrderStateEnum.RejectedAfterBeingAccepted ||
      props.OrderState === OrderSummary.OrderStateEnum.Cancelled ||
      props.OrderState === OrderSummary.OrderStateEnum.RejectedAutomatically,
  });

  const getExternalIconColour = () => {
    switch (props.OrderState) {
      case OrderSummary.OrderStateEnum.ReadyToProcess:
        return '#deacfa';
      case OrderSummary.OrderStateEnum.AcceptedByRestaurant:
      case OrderSummary.OrderStateEnum.Dispatched:
      case OrderSummary.OrderStateEnum.AcceptedAndRefunded:
        return '#86e8bb';
      default:
        return '#fb7c92';
    }
  };

  const orderIcon = () => {
    let icon: JSX.Element;
    switch (props.deliveryType) {
      case OrderSummary.DeliveryTypeEnum.Delivery:
        icon = <img src={DeliveryIconOrderIngest} className={classes.shape} />;
        break;
      case OrderSummary.DeliveryTypeEnum.Pickup:
        icon = <img src={PickupIcon} className={classes.shape} />;
        break;
      default:
        return null;
    }

    if (props.channelOrder) {
      icon = <ExternalIcon icon={icon} colour={getExternalIconColour()} />;
    }

    return icon;
  };

  return (
    <div className={classes.root}>
      <Avatar className={className}>{orderIcon()}</Avatar>
    </div>
  );
});

export default Icon;
