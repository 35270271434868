import { CreateVoucher, Store, UpdateVoucher } from '@flipdish/api-client-typescript';

import { getById } from '../components/Vouchers/services';
import { vouchersConstants } from '../constants/vouchers.constants';
import { loadByAppId as loadByAppIdService } from '../services/store.service';
import { vouchersService } from '../services/vouchers.service';
import { action, actionError } from './utils';

export const vouchersActions = {
  getVouchers,
  getVoucher,
  getVoucherStatistics,
  updateVoucher,
  setVoucherSearchParams,
  setVoucherSearchCustom,
  setVoucherSearchCodeResults,
  setVouchersAppId,
  createVoucher,
  loadVoucherStores,
};

// For vouchers on the vouchers screen
function setVouchersAppId(appId) {
  return {
    type: vouchersConstants.SET_VOUCHERS_APP_ID,
    payload: appId,
  };
}

function getVouchers(
  appId,
  pageIndex?,
  pageSize?,
  concat?,
  searchCodes?: string[],
  statusSearch?: Array<'Valid' | 'Expired' | 'Used'>,
  typeSearch?: Array<'PercentageDiscount' | 'LumpDiscount' | 'AddItem' | 'CreditNote'>,
  subTypeSearch?: Array<'Custom'>,
  storeIds?: number[],
  channelRestrictions?: Array<'Ios' | 'Android' | 'Web' | 'Kiosk' | 'Pos' | 'Google'>
) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(setTimestamp(timestamp));

    if (!concat) {
      dispatch(clearAndRequest());
    } else {
      dispatch(request());
    }

    return vouchersService
      .getVouchers(
        appId,
        pageIndex,
        pageSize,
        searchCodes,
        statusSearch,
        typeSearch,
        subTypeSearch,
        storeIds,
        channelRestrictions
      )
      .then(
        (vouchers) => {
          if (getState().vouchers.vouchersTimestamp === timestamp) {
            dispatch(setTimestamp(null));
          }

          if (concat) {
            dispatch(concatSuccess(vouchers));
          } else {
            dispatch(success(vouchers));
          }
        },
        (error) => {
          console.log(error);

          if (getState().vouchers.vouchersTimestamp === timestamp) {
            dispatch(setTimestamp(null));
          }

          dispatch(failure());
        }
      );
  };

  function setTimestamp(timestamp) {
    return {
      type: vouchersConstants.SET_VOUCHERS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function clearAndRequest() {
    return {
      type: vouchersConstants.CLEAR_VOUCHERS,
    };
  }

  function request() {
    return {
      type: vouchersConstants.GET_VOUCHERS,
    };
  }

  function failure() {
    return {
      type: vouchersConstants.GET_VOUCHERS_FAILURE,
    };
  }

  function success(response) {
    return {
      type: vouchersConstants.GET_VOUCHERS_SUCCESS,
      payload: response,
    };
  }

  function concatSuccess(response) {
    return {
      type: vouchersConstants.GET_VOUCHERS_SUCCESS_CONCAT,
      payload: response,
    };
  }
}

function setVoucherSearchCodeResults(appId, codes) {
  return (dispatch) => {
    return vouchersService.getVouchers(appId, 1, undefined, codes).then(
      (vouchers) => {
        dispatch(success(vouchers));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function success(response) {
    return {
      type: vouchersConstants.SET_VOUCHER_SEARCH_CODE_RESULTS,
      payload: response.Data,
    };
  }
}

function setVoucherSearchCustom(custom) {
  return {
    type: vouchersConstants.SET_VOUCHER_SEARCH_CUSTOM,
    payload: custom,
  };
}

function setVoucherSearchParams(voucherSearchParams) {
  return {
    type: vouchersConstants.SET_VOUCHER_SEARCH_PARAMS,
    payload: voucherSearchParams,
  };
}

// Region update voucher
const { SET_VOUCHER_LOADING, UPDATE_VOUCHER_SUCCESS, UPDATE_VOUCHER_FAILURE } = vouchersConstants;

export type UpdateVoucherRequest = ReturnType<typeof updateVoucherRequest>;
export const updateVoucherRequest = (loading: boolean) => action(SET_VOUCHER_LOADING, { loading });

export type UpdateVoucherSuccess = ReturnType<typeof updateVoucherSuccess>;
export const updateVoucherSuccess = (voucherId: number, voucher: any) =>
  action(UPDATE_VOUCHER_SUCCESS, { voucherId, voucher });

export type UpdateVoucherFailure = ReturnType<typeof updateVoucherFailure>;
export const updateVoucherFailure = (error: Error, voucherId: number, voucher: UpdateVoucher) =>
  actionError(UPDATE_VOUCHER_FAILURE, { voucherId, voucher }, error);

export function updateVoucher(
  props: {
    voucherId: number;
    voucher: UpdateVoucher;
    percentDiscountDetails?: { percentValue: number; maxDiscountAmount: number };
    lumpValue?: number;
    maxDiscountAmount?: number;
  },
  throwError = false
) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updateVoucherRequest(true));
      const result = await vouchersService.updateVoucher(
        props.voucherId,
        props.voucher,
        props.percentDiscountDetails,
        props.lumpValue
      );

      dispatch(updateVoucherSuccess(props.voucherId, result));
    } catch (error) {
      dispatch(updateVoucherFailure(error, props.voucherId, props.voucher));
      if (throwError) {
        throw error;
      }
    }
  };
}
// End region

function getVoucher(voucherId) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    return getById({ voucherId }).then(
      (response) => {
        if (getState().vouchers.voucherTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().vouchers.voucherTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: vouchersConstants.SET_VOUCHER_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: vouchersConstants.GET_VOUCHER,
      payload: response,
    };
  }
}

function getVoucherStatistics(voucherId) {
  return (dispatch, getState) => {
    const timestamp = Date.now();

    dispatch(request(timestamp));

    return vouchersService.getVoucherStatistics(voucherId).then(
      (response) => {
        if (getState().vouchers.voucherStatisticsTimestamp === timestamp) {
          dispatch(success(response));
        }
      },
      (error) => {
        console.log(error);
        if (getState().vouchers.voucherStatisticsTimestamp === timestamp) {
          dispatch(request(null));
        }
      }
    );
  };

  function request(timestamp) {
    return {
      type: vouchersConstants.SET_VOUCHER_STATISTICS_TIMESTAMP,
      payload: timestamp,
    };
  }

  function success(response) {
    return {
      type: vouchersConstants.GET_VOUCHER_STATISTICS,
      payload: response.Data,
    };
  }
}

// Region create voucher
const {
  CREATE_VOUCHER_REQUEST,
  CREATE_VOUCHER_SUCCESS,
  CREATE_VOUCHER_FAILURE,
  CREATE_VOUCHER_REQUEST_RESET,
} = vouchersConstants;

export type CreateVoucherRequest = ReturnType<typeof createVoucherRequest>;
export const createVoucherRequest = (appId: string, props: CreateVoucher) =>
  action(CREATE_VOUCHER_REQUEST, { appId, props });

export type CreateVoucherSuccess = ReturnType<typeof createVoucherSuccess>;
export const createVoucherSuccess = (appId: string, props: CreateVoucher, voucher: CreateVoucher) =>
  action(CREATE_VOUCHER_SUCCESS, { appId, props });

export type CreateVoucherFailure = ReturnType<typeof createVoucherFailure>;
export const createVoucherFailure = (error: Error, appId: string, props: CreateVoucher) =>
  actionError(CREATE_VOUCHER_FAILURE, { appId, props }, error);

export type CreateVoucherRequestReset = ReturnType<typeof createVoucherRequestReset>;
export const createVoucherRequestReset = () => action(CREATE_VOUCHER_REQUEST_RESET);

export function createVoucher(props: CreateVoucher, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(createVoucherRequest(appId, props));
      const result = await vouchersService.create(appId, props);
      dispatch(createVoucherSuccess(appId, result, props));
    } catch (error) {
      dispatch(createVoucherFailure(error, appId, props));
      if (throwError) {
        throw error;
      }
    }
  };
}
// End region

// Region load voucher stores
export type LoadByAppIdProps = {
  appId: string;
  query?: string;
  page?: number;
  limit?: number;
};

const { LOAD_VOUCHER_STORES_REQUEST, LOAD_VOUCHER_STORES_SUCCESS, LOAD_VOUCHER_STORES_FAILURE } =
  vouchersConstants;

export type LoadVoucherStoresRequest = ReturnType<typeof loadVoucherStoresRequest>;
export const loadVoucherStoresRequest = (props: LoadByAppIdProps) =>
  action(LOAD_VOUCHER_STORES_REQUEST, undefined, props);

export type LoadVoucherStoresSuccess = ReturnType<typeof loadVoucherStoresSuccess>;
export const loadVoucherStoresSuccess = (
  stores: Store[],
  props: LoadByAppIdProps & { totalCount: number }
) => action(LOAD_VOUCHER_STORES_SUCCESS, stores, props);

export type LoadVoucherStoresFailure = ReturnType<typeof loadVoucherStoresFailure>;
export const loadVoucherStoresFailure = (error: Error, props: LoadByAppIdProps) =>
  actionError(LOAD_VOUCHER_STORES_FAILURE, props, error);

export function loadVoucherStores(props: { page?: number; limit?: number; query?: string } = {}) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const meta = {
      appId,
      ...props,
    };
    try {
      dispatch(loadVoucherStoresRequest(meta));
      const paginatedResult = await loadByAppIdService(meta);
      if (paginatedResult) {
        const { Data, Page, Limit, TotalRecordCount } = paginatedResult;
        dispatch(
          loadVoucherStoresSuccess(Data, {
            ...meta,
            page: Page,
            limit: Limit,
            totalCount: TotalRecordCount,
          })
        );
      }
    } catch (error) {
      dispatch(loadVoucherStoresFailure(error, meta));
      throw error;
    }
  };
}
// End region
