import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../helpers/validation';
import TextField from '../../../../ui/TextField/TextField';
import FormItemLayout from '../../../Finance/Banking/components/FormItemLayout';
import PinExpansionPanel from '../PinExpansionPanel';

const validatePinField = (value: number) => {
  return formikValidate.pin(6)(value.toString().length);
};

const KioskPinField = () => (
  <FormItemLayout label={<Translate id="Pin_code" />}>
    <Field name={'PinCode'} validate={validatePinField}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <TextField
            {...field}
            fdKey={'Kiosk_pin'}
            fullWidth
            type="number"
            disabled={isSubmitting}
            error={showError}
            inputProps={{ form: { autoComplete: 'off' } }}
            helperText={showError ? fieldError : null}
          />
        );
      }}
    </Field>
    <PinExpansionPanel DeviceType={'kiosk'} />
  </FormItemLayout>
);
export default KioskPinField;
