import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

type Props = {
  editMode?: boolean;
  onCancel: () => void;
  onRemove?: () => void;
};

const ActionButtonGroup = ({ onCancel, editMode = false, onRemove }: Props) => (
  <Box mt={2} mb={-1}>
    <Grid container justifyContent="space-between" wrap="nowrap">
      {editMode && (
        <Grid item>
          <Permissions allowed={[App.AppResourceSetEnum.DeleteCampaignsConfigurations]}>
            <Button color="secondary" onClick={onRemove} data-fd="campaign-remove">
              <Translate id="Remove" />
            </Button>
          </Permissions>
        </Grid>
      )}

      <Grid container justifyContent="flex-end">
        <Button color="primary" onClick={onCancel} data-fd="campaign-cancel">
          <Translate id="Cancel" />
        </Button>
        <Button color="primary" type="submit" data-fd="campaign-create">
          <Translate id={editMode ? 'Modify' : 'Create'} />
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default ActionButtonGroup;
