import React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

import Spacer from '../../../ui/Spacer';

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    width: '100%',

    [breakpoints.down('md')]: {
      borderRadius: 0,
      boxShadow: 'none',
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
}));
const useChartStyles = makeStyles(({ breakpoints }: Theme) => ({
  '@keyframes skeleton': {
    '0%': {
      backgroundPosition: '-200px 0',
    },
    '100%': {
      backgroundPosition: 'calc(200px + 100%) 0',
    },
  },
  root: {
    width: '100%',
  },
  chart: {
    margin: 8,
    height: 205,
    position: 'relative',
    [breakpoints.down('md')]: {
      height: 'auto',
      paddingTop: '32%',
    },
  },
  skeleton: {
    backgroundColor: '#eee',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'linear-gradient( 90deg, #eee, #f5f5f5, #eee )',
    animation: '1.2s ease-in-out 0s infinite normal none running $skeleton',
  },
  hidden: {
    visibility: 'hidden',
  },
  message: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '22px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

export const titleSkeleton = <Skeleton width={'60%'} height={22} />;
export const subHeaderSkeleton = <Skeleton width={'50%'} height={18} />;
export const headerActionSkeleton = <Skeleton circle width={42} height={42} />;
export const cardActionsSkeleton = (
  <Spacer size={8}>
    <Skeleton width={160} height={36} />
  </Spacer>
);
export const ChartSkeleton = ({ loaded }: { loaded?: boolean }) => {
  const classes = useChartStyles();

  return (
    <div className={classes.root}>
      <Spacer size={8} className={loaded ? classes.hidden : ''}>
        <Skeleton width={'50%'} height={22} />
      </Spacer>
      <div className={`${classes.chart} ${loaded ? '' : classes.skeleton}`}>
        {loaded ? (
          <div className={classes.message}>
            <Translate id="Your_sales_will_appear_here" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const StoreDetailsCardLoading = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={titleSkeleton}
        subheader={subHeaderSkeleton}
        action={headerActionSkeleton}
      />

      <Hidden mdDown>
        <Divider />
      </Hidden>
      <ChartSkeleton />
      <CardActions>{cardActionsSkeleton}</CardActions>
    </Card>
  );
};

export default StoreDetailsCardLoading;
