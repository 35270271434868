import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, FormikHelpers } from 'formik';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/Button';
import { FkNumeric } from '@fd/ui/FkNumeric/FkNumeric';
import FkSelect from '@fd/ui/FkSelect/FkSelect';
import { FkText } from '@fd/ui/FkText/FkText';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import { ModalContent } from '@fd/ui/Modal/ModalContent';
import { OptionType } from '@fd/ui/Select/Select';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { FIELDS } from '../../../models/fields';
import { EntitlementOverrideRequest } from '../../../overrides/@flipdish/subscriptions-api-client-typescript/api';
import { upsertEntitlementOverrides } from '../../../services/entitlements.service';
import { translateEntitlementEnums } from '../utils/entitlementEnumTranslateUtils';

interface Values {
  featureCode: string;
  quantity: number;
  reasonCode: EntitlementOverrideRequest.ReasonCodeEnum;
  reasonText?: string;
}

type Props = {
  refreshEntitlements: () => void;
} & MappedState &
  MappedDispatch;

const AddEntitlementOverrideModal = ({
  refreshEntitlements,
  appId,
  closeNotifySaving,
  notifyError,
  notifySaved,
  notifySaving,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const reasonCodeOptions: OptionType[] = useMemo(
    () => [
      {
        value: EntitlementOverrideRequest.ReasonCodeEnum.Legacy,
        label: translateEntitlementEnums(
          EntitlementOverrideRequest.ReasonCodeEnum.Legacy.toString()
        ) as string,
      },
      {
        value: EntitlementOverrideRequest.ReasonCodeEnum.Temporary,
        label: translateEntitlementEnums(
          EntitlementOverrideRequest.ReasonCodeEnum.Temporary.toString()
        ) as string,
      },
    ],
    []
  );

  const { mutateAsync, isPending: isUpdatingEntitlements } = useMutation({
    mutationFn: (request: EntitlementOverrideRequest) => {
      notifySaving();
      return upsertEntitlementOverrides(appId, request);
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      setModalOpen(false);
      refreshEntitlements();
    },
    onError: (error) => {
      closeNotifySaving();
      notifyError({ message: 'Something_went_wrong', translate: true });
    },
  });

  return (
    <>
      <Modal
        titleTextId="Add_entitlement_override"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="800px"
      >
        <Formik
          initialValues={{
            featureCode: '',
            quantity: 1,
            reasonCode: EntitlementOverrideRequest.ReasonCodeEnum.Legacy,
            reasonText: '',
          }}
          onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            await mutateAsync({
              appId,
              featureCode: values.featureCode,
              quantity: Number(values.quantity),
              reasonCode: values.reasonCode,
              reasonText: values.reasonText,
            });
            setSubmitting(false);
          }}
        >
          <Form>
            <ModalContent>
              <Divider />

              <FormItemLayout
                bottomBorder
                labelId="Entitlement_feature_code"
                mdProportion="12x12"
                noWrap
                removeRootPaddings
                smProportion="12x12"
              >
                <FkText name="featureCode" {...FIELDS['EntitlementFeatureCode']} />
              </FormItemLayout>

              <FormItemLayout
                bottomBorder
                labelId="Qty"
                descriptionId="Entitlement_quantity_description"
                mdProportion="12x12"
                noWrap
                removeRootPaddings
                smProportion="12x12"
              >
                <FkNumeric name="quantity" {...FIELDS['EntitlementFeatureQuantity']} />
              </FormItemLayout>

              <Box pt={2}>
                <FormItemLayout
                  bottomBorder
                  labelId="Entitlement_reason_code"
                  mdProportion="12x12"
                  removeRootPaddings
                  noWrap
                  smProportion="12x12"
                >
                  <Box pb={2}>
                    <FkSelect
                      {...FIELDS['EntitlementReasonCode']}
                      menuPlacement="auto"
                      name={'reasonCode'}
                      options={reasonCodeOptions}
                      removeTopPadding
                      variant="standard"
                    />
                  </Box>
                </FormItemLayout>
              </Box>

              <FormItemLayout
                bottomBorder
                labelId="Entitlement_reason"
                mdProportion="12x12"
                noWrap
                removeRootPaddings
                smProportion="12x12"
              >
                <FkText name="reasonText" {...FIELDS['EntitlementReasonText']} />
              </FormItemLayout>
            </ModalContent>

            <ModalActions>
              <Box mt={4} mb={1}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    fdKey="entitlement-override-create"
                    disabled={isUpdatingEntitlements}
                  >
                    <Translate id="Add" />
                  </Button>
                </Grid>
              </Box>
            </ModalActions>
          </Form>
        </Formik>
      </Modal>

      <Button fdKey="add-override-button" variant="contained" onClick={() => setModalOpen(true)}>
        <Translate id="Add_override" />
      </Button>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving()),
  notifySaved: () => dispatch(notifySaved()),
  closeNotifySaving: () => dispatch(closeNotifySaving()),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId!,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEntitlementOverrideModal);
