import { RestApiResultAppConfigSalesChannel } from '@flipdish/api-client-typescript';
import { useQuery } from '@tanstack/react-query';

import { mobileAppsService } from '../components/SalesChannels/MobileApps/mobileApps.services';

export const getSalesChannelConfigQueryKey = 'salesChannelConfig';
export function useQuerySalesChannelConfigHook(
  appId: string,
  enabled: boolean,
  refetchOnMount = false
): {
  isLoading: boolean;
  data?: RestApiResultAppConfigSalesChannel;
  isError: boolean;
  isFetched: boolean;
} {
  const { isLoading, data, isError, isFetched } = useQuery({
    queryKey: [getSalesChannelConfigQueryKey, appId],
    queryFn: () => mobileAppsService.getSalesChannelConfig(appId),
    gcTime: 1000 * 30,
    enabled: enabled,
    refetchOnMount: refetchOnMount,
  });
  return {
    isLoading,
    data,
    isError,
    isFetched,
  };
}
