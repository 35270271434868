import { createReducer } from 'redux-act';

import {
  deleteStoreLogo,
  setStoreKioskLogo,
  setStoreKioskSettings,
  updateStoreKioskSetting,
} from './actions';

export type StoreKioskSettingsState = typeof defaultState;
const defaultState = {
  settings: [] as FlipdishPrivate.StoreKioskSetting[],
  logoUrl: undefined as string | undefined,
};

// #region reducer
const reducer = createReducer({}, defaultState);

reducer.on(setStoreKioskSettings, (state, payload) => ({
  ...state,
  settings: payload,
}));

reducer.on(updateStoreKioskSetting, (state, payload) => {
  const { KioskSettingId } = payload;
  const settings = state.settings.map((s) => {
    if (s.KioskSettingId === KioskSettingId) {
      return {
        ...s,
        ...payload,
      };
    }
    return s;
  });

  return {
    ...state,
    settings,
  };
});

reducer.on(setStoreKioskLogo, (state, payload) => ({
  ...state,
  logoUrl: payload,
}));

reducer.on(deleteStoreLogo, (state) => ({
  ...state,
  logoUrl: '',
}));
// #endregion

export default reducer;
