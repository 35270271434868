import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { connect } from 'react-redux';

import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from '../../components/Finance/Payouts/payouts.actions';
import { TableColumns } from '../../components/Finance/Payouts/types';
import OrderReportTable from '../../components/Reports/components/Tables/OrderReportTable';
import EmptyTable from './EmptyTable';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

type FetchDataProps = {
  appId: string;
  filter?: any;
  limit: number;
  orderBy: 'asc' | 'desc';
  page: number;
};

export type TableQueryProps<Data> = {
  columnLocalStorageKey?: string;
  currency?: CurrencyEnum;
  defaultTableColumns?: string[];
  downloadCSV?: (() => Promise<void>) | (() => void);
  emptyTableTitle?: TranslationId;
  fetchData(props: FetchDataProps): Promise<Data | undefined>;
  filter?: any;
  languageCode: string;
  metadata: TableColumns[];
  onRowClick?: Function;
  pageLocalStorageKey?: string;
  preSelectedTableColumns?: string[];
  rowsPerPageLocalStorageKey?: string;
  showColumnSelector?: boolean;
  showTable?: boolean;
  storageKey: string;
  title?: TranslationId;
  urlId?: string;
  userTimezone?: string;
};

const scrollToTable = (urlId: string) => {
  const el = document.getElementById(urlId);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
};

export function TableQuery<Data extends Flipdish.RestApiPaginationResult<Data>>(
  props: TableQueryProps<Data> & MappedProps
) {
  const {
    appId,
    columnLocalStorageKey,
    currency,
    defaultTableColumns,
    downloadCSV,
    emptyTableTitle,
    fetchData,
    filter,
    languageCode,
    metadata,
    onRowClick,
    pageLocalStorageKey,
    preSelectedTableColumns,
    rowsPerPageLocalStorageKey,
    showColumnSelector,
    showTable,
    storageKey,
    title,
    urlId,
  } = props;
  const hasUrlHash = urlId && window.location.hash === `#${urlId}`;
  const [ref, inView] = useInView({ triggerOnce: true });
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [limit, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('asc'); // should be {ColId: 'asc' | 'desc'} if we can sort on multiple columns
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState(metadata);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (columnLocalStorageKey) {
      const lsColumns = localStorage.getItem(columnLocalStorageKey);
      const tableColumns =
        defaultTableColumns && preSelectedTableColumns
          ? [...defaultTableColumns, ...preSelectedTableColumns]
          : defaultTableColumns;
      const defaultColumns = lsColumns ? JSON.parse(lsColumns) : tableColumns;

      const columns = metadata.map((col) => {
        return {
          ...col,
          isVisible: defaultColumns.indexOf(col.columnName) !== -1,
        };
      });
      setColumns(columns);
    }
    if (rowsPerPageLocalStorageKey) {
      const lsRowsPerPage = Number(localStorage.getItem(rowsPerPageLocalStorageKey));
      const defaultRowsPerPage = lsRowsPerPage ? lsRowsPerPage : DEFAULT_ROWS_PER_PAGE;
      setRowsPerPage(defaultRowsPerPage);
    }
    if (pageLocalStorageKey) {
      const lsPage = Number(localStorage.getItem(pageLocalStorageKey));
      const defaultPage = lsPage ? lsPage : DEFAULT_PAGE;
      setPage(defaultPage);
    }

    // Timeout to allow time for filters to apply from URL and still count as isFirstRender=true
    const timer = setTimeout(() => {
      setIsFirstRender(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const { isLoading, data, isError, isFetched, isFetching } = useQuery({
    queryKey: [storageKey, appId, page, limit, orderBy, filter],
    queryFn: () => fetchData({ appId, page, limit, filter, orderBy }),
    enabled: inView,
    placeholderData: keepPreviousData,
    gcTime: 5 * 60 * 1000,
  });

  const dataResult = data && data.Data;
  const isPageTimesRowOverTotal = data && data.TotalRecordCount - page * limit < 0;

  useEffect(() => {
    if (isPageTimesRowOverTotal) {
      resetPage();
    }
  }, [isPageTimesRowOverTotal]);

  useEffect(() => {
    if (!isFirstRender) {
      resetPage();
    }
  }, [limit, orderBy, filter]);

  useEffect(() => {
    setLoading(isLoading || !isFetched || isFetching);
  }, [isLoading, isFetching, isFetched]);

  useEffect(() => {
    if (urlId && hasUrlHash && !loading) {
      scrollToTable(urlId);
    }
  }, [location.hash, loading]);

  const pagination = useMemo(
    () => ({
      page,
      pageSize: limit,
      total: data && data.TotalRecordCount ? data.TotalRecordCount : undefined,
    }),
    [data]
  );

  const resetPage = () => {
    setPage(0);
    if (pageLocalStorageKey) {
      localStorage.setItem(pageLocalStorageKey, '0');
    }
  };

  const onSort = (orderBy, order) => {
    setOrderBy(orderBy);
  };

  if (showTable === false) return null;

  if (!isLoading && isError) {
    return <EmptyTable ariaTitle={title} emptyTableTitle={'Something_went_wrong'} noLink />;
  }

  if (emptyTableTitle && dataResult && !dataResult.length && !isFetching) {
    return (
      <Box pt={3}>
        <EmptyTable ariaTitle={title} emptyTableTitle={emptyTableTitle as TranslationId} noLink />
      </Box>
    );
  }

  return (
    <div ref={ref}>
      <OrderReportTable
        AppId={appId}
        columnLocalStorageKey={columnLocalStorageKey}
        currency={currency}
        data={dataResult}
        defaultColumns={defaultTableColumns}
        downloadCSV={downloadCSV}
        inView={inView || !!hasUrlHash}
        languageCode={languageCode}
        loading={loading}
        metadata={columns}
        setMetadata={setColumns}
        onAppRowClick={onRowClick}
        onSort={onSort}
        page={page}
        pagination={pagination}
        rowsPerPage={limit}
        rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
        pageLocalStorageKey={pageLocalStorageKey}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        showColumnSelector={showColumnSelector}
        title={title}
        urlId={urlId}
      />
    </div>
  );
}
type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId as string,
  };
};
export default connect(mapStateToProps)(TableQuery);
