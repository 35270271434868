import * as React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { vouchersActions } from '../../../actions/vouchers.actions';
import { generalConstants } from '../../../constants/general.constants';
import { flagService } from '../../../services';
import Filter from '../../common/Filter';

class VoucherSelect extends React.Component<any, any> {
  public loadThrottle: Subscription;

  private query = '';

  public constructor(props) {
    super(props);

    this.state = {
      query: '',
      isLoading: false,
    };
  }

  public componentDidUpdate(prevProps) {
    if (this.state.query !== this.query) {
      this.query = this.state.query;
      this.doDelayedSearch();
    }
  }

  public doDelayedSearch() {
    if (this.loadThrottle) {
      this.loadThrottle.unsubscribe();
    }
    this.loadThrottle = interval(500).pipe(take(1)).subscribe(this.getVoucherCodes);
  }

  public getVoucherCodes = () => {
    const { dispatch, currentApp } = this.props;

    const AppId = currentApp.AppId || 'global';

    if (this.state.query) {
      dispatch(vouchersActions.setVoucherSearchCodeResults(AppId, [this.state.query])).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  public handleSearch = (query: string) => {
    this.setState({ query, isLoading: !!query });
  };

  public handleVouchersChange = (searchParams) => {
    const { dispatch, currentApp, voucherSearchCustom } = this.props;

    const AppId = currentApp.AppId || 'global';

    const searchCodes: string[] = [];
    const statusSearch: Array<'Valid' | 'Expired' | 'Used'> = [];
    const typeSearch: Array<'PercentageDiscount' | 'LumpDiscount' | 'AddItem' | 'CreditNote'> = [];
    const subTypeSearch: Array<'Custom'> = [];
    const channelRestrictions: Array<'Ios' | 'Android' | 'Web' | 'Kiosk' | 'Pos' | 'Google'> = [];

    const search: Array<{ [key: string]: string }> = [];

    for (const param of searchParams) {
      if (param.type.includes('status')) {
        statusSearch.push(param.value);
        search.push({ status: param.value });
      } else if (param.type.includes('code')) {
        searchCodes.push(param.value);
        search.push({ code: param.value });
      } else if (param.type.includes('type')) {
        typeSearch.push(param.value);
        search.push({ type: param.value });
      } else if (param.type.includes('channel')) {
        channelRestrictions.push(param.value);
        search.push({ channel: param.value });
      }
    }

    if (voucherSearchCustom) {
      subTypeSearch.push('Custom');
      search.push({ custom: 'true' });
    } else {
      search.push({ custom: 'false' });
    }

    this.props.setSearch(search);

    dispatch(vouchersActions.setVoucherSearchParams(searchParams));

    dispatch(
      vouchersActions.getVouchers(
        AppId,
        1,
        generalConstants.VOUCHERS_PAGE_SIZE,
        false,
        searchCodes,
        statusSearch,
        typeSearch,
        subTypeSearch,
        undefined,
        channelRestrictions
      )
    );
  };

  public render() {
    const { isVoucherChannelOn, searchParams, translate, voucherSearchCodeResults } = this.props;
    const { isLoading } = this.state;

    const statusSuggestions = [
      {
        value: 'Valid',
        type: 'status',
        label: `${translate('Status_lowercase')}:${translate('Valid_lowercase')}`,
      },
      {
        value: 'Expired',
        type: 'status',
        label: `${translate('Status_lowercase')}:${translate('Expired_lowercase')}`,
      },
      {
        value: 'Used',
        type: 'status',
        label: `${translate('Status_lowercase')}:${translate('Used_lowercase')}`,
      },
    ];
    const typeSuggestions = [
      {
        value: 'PercentageDiscount',
        type: 'type',
        label: `${translate('Type_lowercase')}:${translate('Percentagediscount')}`,
      },
      {
        value: 'LumpDiscount',
        type: 'type',
        label: `${translate('Type_lowercase')}:${translate('Lumpdiscount')}`,
      },
      {
        value: 'AddItem',
        type: 'type',
        label: `${translate('Type_lowercase')}:${translate('Additem')}`,
      },
      {
        value: 'CreditNote',
        type: 'type',
        label: `${translate('Type_lowercase')}:${translate('Creditnote')}`,
      },
    ];
    const codeSuggestions = voucherSearchCodeResults.map((result) => {
      return {
        value: result.Code,
        type: `code:${result.Code}`,
        label: `${translate('Code_lowercase')}:${result.Code}`,
      };
    });
    const channelSuggestions = isVoucherChannelOn
      ? [
          {
            value: 'Ios',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:ios`,
          },
          {
            value: 'Android',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:android`,
          },
          {
            value: 'Web',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:${translate('Web_lowercase')}`,
          },
          {
            value: 'Kiosk',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:${translate('Kiosk_lowercase')}`,
          },
          {
            value: 'Pos',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:pos`,
          },
          {
            value: 'Google',
            type: 'channel',
            label: `${translate('Channel_lowercase')}:google`,
          },
        ]
      : [];

    const suggestions = [
      ...statusSuggestions,
      ...typeSuggestions,
      ...codeSuggestions,
      ...channelSuggestions,
    ];

    return (
      <Filter
        value={searchParams}
        options={suggestions}
        isLoading={isLoading}
        placeholder={translate('Type_code')}
        onInputChange={this.handleSearch}
        onChange={this.handleVouchersChange}
      />
    );
  }
}

function mapStateToProps(state) {
  const { locale, vouchers, currentApp } = state;

  return {
    currentApp,
    isVoucherChannelOn: flagService.isFlagOn(state, 'voucherChannel'),
    searchParams: vouchers.voucherSearchParams,
    translate: getTranslate(locale),
    voucherSearchCodeResults: vouchers.voucherSearchCodeResults,
    voucherSearchCustom: vouchers.voucherSearchCustom,
  };
}

const EnhancedComponent = connect(mapStateToProps)(VoucherSelect);

export { EnhancedComponent as VoucherSelect };
