import React, { useEffect, useState } from 'react';

import { OAuthApp } from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import FdLoader from '../../../assets/images/fd-loader.gif';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../WithRouteSearchParams';
import { createOAuthApp, getOAuthApps } from '../actions';
import OAuthAppCard from '../components/OAuthAppCard';
import OAuthAppCardLoading from '../components/OAuthAppCardLoading';
import OAuthAppCreateForm from '../components/OAuthAppCreateForm';
import OAuthAppListFilter from './OAuthAppListFilter';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  button: {
    margin: spacing(1),
    color: 'white',
    backgroundColor: '#ff0046',
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
  extendedIcon: {
    marginRight: spacing(1),
  },
  noResultsMsg: {
    padding: `${spacing(1)} ${spacing(2)}`,
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  imgWrapper: {
    width: '50vw',
    height: '50vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    [breakpoints.down('sm')]: {
      left: '35%',
    },
  },
  img: {
    height: '96px',
    width: '96px',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

type Props = MappedState & MappedDispatch & WithRouteSearchParamsProps<string>;

const OAuthAppList: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const classes = useStyles();
  const { oauthApps, isLoading, translate, search, loadAll, currentApp } = props;
  useEffect(() => {
    loadAll(search);
  }, []);

  const apps: OAuthApp[] = oauthApps == undefined ? [] : oauthApps;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const renderLoading = isLoading && !apps.length && !search;
  const renderEmptyList = !apps.length && !search;
  const renderSearchLoading = isLoading && search ? true : false;
  const searchTerm = search ? search : '';

  const header = (
    <GridContainer>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <OAuthAppListFilter onChange={loadAll} search={searchTerm} />
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      fluid
      header={header}
      documentTitle="My_oauth_apps"
      toParent={`/${currentApp.AppId}/developers`}
      title={translate('My_oauth_apps')}
      actions={() => (
        <Fab
          variant="extended"
          color="secondary"
          aria-label={translate('Add_new') as string}
          className={classes.button}
          onClick={() => setDialogOpen(true)}
          data-fd="oauthapp-add"
        >
          <AddIcon className={classes.extendedIcon} />
          {translate('Add_new')}
        </Fab>
      )}
    >
      <GridContainer>
        {renderLoading ? (
          <OAuthAppCardLoading />
        ) : renderEmptyList ? (
          <EmptyComponent
            title="Empty_oauth_app_list_header"
            subtitle="Empty_oauth_app_list_subheader"
          />
        ) : (
          <>
            {!apps.length && (
              <Typography variant="subtitle1" className={classes.noResultsMsg}>
                {translate('No_results')}
              </Typography>
            )}
            {apps.map((app, index) => (
              <OAuthAppCard App={app} key={index} />
            ))}
          </>
        )}
      </GridContainer>

      {renderSearchLoading && (
        <div className={classes.imgWrapper}>
          <img src={FdLoader} className={classes.img} />
        </div>
      )}
      <OAuthAppCreateForm
        setDialogOpen={setDialogOpen}
        isDialogOpen={isDialogOpen}
        submit={props.createOAuthApp}
        isLoading={props.isLoading}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const {
    developers: { oauthApps, isLoading },
    locale,
  } = state;
  return {
    currentApp: state.currentApp,
    translate: getTranslate(locale),
    oauthApps,
    isLoading,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => ({
  loadAll: (oauthAppName) => {
    dispatch(getOAuthApps(oauthAppName === '*' ? null : oauthAppName));
    ownProps.setSearch(oauthAppName);
  },
  createOAuthApp: (app) => {
    return dispatch(createOAuthApp(app, true));
  },
});

export default compose(
  withRouteSearchParams({
    name: 'oauthAppName',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(OAuthAppList);
