import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '480px',
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

export type ProductContainsGroupDialogProps = {
  onClose: () => void;
  open: boolean;
  translate: TranslateFunction;
};

export const ProductContainsGroupDialog = (props: ProductContainsGroupDialogProps): JSX.Element => {
  const { onClose, open, translate } = props;

  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      title={translate('Product_contains_a_modifier_group') as string}
      open={open}
      actions={[
        <Button key="ok" fdKey="dialog-ok-button" onClick={onClose}>
          <Translate id="OK" />
        </Button>,
      ]}
    >
      <Typography className={classes.dialogText} variant="body2">
        <Translate id="Product_contains_group_message" />
      </Typography>
    </Dialog>
  );
};
