import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import find from 'lodash/find';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 76,
  },
  customMins: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  open: boolean;
  mins: Array<{ default: boolean; mins: number }>;
  handleAccept: (mins: number) => void;
  handleClose: () => void;
};

const AcceptDialogMobile = (props: Props) => {
  const { open, handleAccept, handleClose, mins } = props;
  const classes = useStyles();
  const defaultValue = find(mins, { default: true });
  const [newMins, setNewMins] = useState<string>(
    (defaultValue && defaultValue.mins.toString()) || ''
  );

  const [customMins, setCustomMins] = useState<string>('');

  const [error, setError] = useState<{ error: boolean; message: React.ReactElement | null }>({
    error: false,
    message: null,
  });

  const dialogContainer = document.getElementsByClassName('fullscreen-enabled')[0];

  return (
    <Dialog
      container={dialogContainer ? dialogContainer : document.body}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <RadioGroup
          aria-label="mins"
          name="mins"
          value={newMins}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMins(e.target.value)}
        >
          {mins.map((mins) => (
            <FormControlLabel
              key={mins.mins}
              value={mins.mins.toString()}
              control={<Radio color="secondary" />}
              label={<Translate id="minutes_amount" data={{ mins: mins.mins }} />}
            />
          ))}
          <FormControlLabel
            key="custom"
            value="custom"
            control={<Radio color="secondary" />}
            label={
              <span className={classes.customMins}>
                +
                <TextField
                  variant="standard"
                  className={classes.textField}
                  margin="normal"
                  value={customMins}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setError({ error: false, message: null });
                    setNewMins('custom');
                    setCustomMins(e.target.value);
                  }}
                  type="number"
                  error={error.error}
                  helperText={error.message}
                />
                <Translate id="mins" />
              </span>
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setError({ error: false, message: null });
            handleClose();
          }}
          color="primary"
        >
          <Translate id="Cancel" />
        </Button>
        <Button
          onClick={() => {
            if (newMins == 'custom' && customMins != '') {
              handleAccept(parseInt(customMins, 10));
              handleClose();
            } else if (newMins != '') {
              handleAccept(parseInt(newMins, 10));
              handleClose();
            } else {
              setError({ error: true, message: <Translate id="Invalid_input" /> });
            }
          }}
          color="primary"
        >
          <Translate id="Accept" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptDialogMobile;
