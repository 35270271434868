import * as React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { accountActions } from '../../actions/account.actions';
import Loading from '../Loading';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  return (state: AppState, ownProps) => {
    const { account } = state;
    return {
      account,
    };
  };
};
type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps) => {
  return (dispatch: ThunkDispatch, ownProps) => {
    return {
      logout: async () => {
        try {
          await dispatch(accountActions.logout());
        } catch (error) {
          console.error('Error dispatching accountActions.logout', error);
        }
      },
    };
  };
};

type Props = MappedState & MappedDispatch;
const Logout = compose<Props, {}>(connect(mapStateToPropsFactory, mapDispatchToPropsFactory))(
  class extends React.Component<Props, {}> {
    public componentDidMount() {
      if (this.props.account.authorized) {
        this.props.logout();
      }
    }

    public render() {
      return <Loading fullscreen />;
    }
  }
);

export default Logout;
