import React from 'react';

import Link from '@mui/material/Link';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { lowerCase } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
}));

type InnerProps = ReturnType<typeof mapStateToProps>;
type OuterProps = {
  id: TranslationId;
  underlinedLink?: boolean;
};
type Props = OuterProps & InnerProps;

const PolicyLink: React.FC<
  React.PropsWithChildren<{
    children: React.ReactNode;
    className?: string;
    href: string;
    id: TranslationId;
  }>
> = ({ children, href, className, id }) => {
  const accessibilelink = 'cookie_policy_link_' + id;

  return (
    <Link
      aria-label={id}
      className={className}
      data-fd={accessibilelink}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      underline="hover"
    >
      {children}
    </Link>
  );
};

const CookiePolicyLink: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { id, translate, underlinedLink = true } = props;
  const classes = useStyles();

  const text = translate(id) as string;
  const cookiePolicyText = translate('Cookie_policy') as string;

  const PLACEHOLDER = '{0}';

  const { text0, text1 } = React.useMemo(() => {
    const [text0, text1] = text.split(PLACEHOLDER);

    return {
      text1,
      text0,
    };
  }, [text]);

  const className = underlinedLink ? classes.link : undefined;
  const href = translate('Cookie_Policy_Url') as string;

  return (
    <Typography className={classes.body} variant="body2">
      {text0}
      <PolicyLink id={id} className={className} href={href}>
        {lowerCase(cookiePolicyText)}
      </PolicyLink>
      {text1}
    </Typography>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};

export default connect<InnerProps>(mapStateToProps)(CookiePolicyLink);
