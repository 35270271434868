import React, { ChangeEvent, useRef } from 'react';

import { Button } from '../../atoms/Button';

export type UploadFileButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  fdKey: string;
  fileType?: string;
  onFileChange: (file: FileList | null) => void;
  variant?: 'primary' | 'secondary' | 'text' | 'rounded';
};

export const UploadFileButton = (props: UploadFileButtonProps): JSX.Element => {
  const {
    children,
    disabled = false,
    fdKey,
    fileType = 'image/*',
    onFileChange,
    variant = 'secondary',
  } = props;
  const ref = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFileChange(e.target.files);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.charCode === 13) {
      ref?.current?.click();
    }
  };

  const handleClick = () => {
    ref?.current?.click();
  };

  return (
    <Button
      onKeyPress={handleKeyPress}
      onClick={handleClick}
      disabled={disabled}
      fdKey={fdKey}
      variant={variant}
      component="button"
    >
      {children}
      <input ref={ref} accept={fileType} hidden onChange={handleFileChange} type="file" />
    </Button>
  );
};
