import { formatCurrency } from 'fd-react-intl';
import { History } from 'history';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import { DateTimeUtils } from '../../selectors/localeDateTime.selector';
import { OrdersTableColumns } from '../Reports/types';
import { CurrencyEnum } from './components/Tables/CustomersDataTable';
import { CustomerDetailsTable, CustomersTableData, TableProps } from './types';

const formatCurrencyWithFractions = (
  value: number | string,
  languageCode: string,
  currency: string
) => {
  const numVal = typeof value === 'string' ? parseFloat(value) : value;
  return formatCurrency(numVal, languageCode, {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getStoreParams = (location) => {
  type storeValue = { store?: string | string[] };
  const values: storeValue = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  if (!isEmpty(values.store)) {
    let storesFilter: number[] = [];
    if (isArray(values.store)) {
      storesFilter = values.store.map((v) => parseInt(v, 10));
    } else if (values.store) {
      storesFilter.push(parseInt(values.store, 10));
    }
    return storesFilter;
  } else {
    return;
  }
};

export type Params = {
  rows?: string;
  page?: string;
  orderBy?: string;
  order?: 'asc' | 'desc';
  store?: CurrencyEnum | number[];
  searchTerm?: string;
};
export const getRouteSearch = (location): Params | undefined => {
  if (location.search !== '') {
    const searchProps = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return searchProps || {};
  }
  return undefined;
};

export const setRouteSearch = (
  history: History,
  params: { [key: string]: string | number | number[] | undefined }
) => {
  const currentSearch = getRouteSearch(history.location);
  const search = qs.stringify(
    {
      ...currentSearch,
      ...params,
    },
    { skipNulls: true, encodeValuesOnly: true, indices: false }
  );
  const newLocation = Object.assign({}, history.location, { search });
  history.replace(newLocation);
};

export const getColumnsFlipdishStaffOrDefault = (
  cols: OrdersTableColumns[],
  isFlipdishStaff: boolean
) => {
  return isFlipdishStaff ? cols : cols.filter((c) => c.columnName !== 'appId');
};

//#region Services helpers
export const getQuery = (queryParams: {
  Page?: number;
  PageSize?: number;
  StoreId?: number[] | string;
  searchTerm?: string;
  customerId?: string;
  exportAsCsv?: boolean;
}) => {
  const { exportAsCsv, Page, PageSize, StoreId } = queryParams;
  // if we pass a storeId then currencyFilter is null
  const CurrencyFilter = StoreId && typeof StoreId !== 'string' ? null : StoreId;
  // storeId will be empty if we have currencyFilter else it will be storeId
  const params = {
    ...queryParams,
    StoreId: CurrencyFilter ? null : StoreId,
    CurrencyFilter,
    Page: exportAsCsv ? null : Page,
    PageSize: exportAsCsv ? null : PageSize,
    csvTimeOffsetInMinutes: -new Date().getTimezoneOffset(),
  };

  const query = qs.stringify(params, {
    skipNulls: true,
    encodeValuesOnly: true,
    indices: false,
  });
  return query;
};

export const getSortQuery = (
  SortBy: TableProps['SortBy'],
  SortDirection: TableProps['SortDirection']
) => {
  const query = qs.stringify(
    { SortBy, SortDirection },
    { skipNulls: true, encodeValuesOnly: true, indices: false }
  );
  return query ? `&${query}` : '';
};

export const updateLanguageHeader = ({
  exportAsCsv,
  languageCode,
}: {
  exportAsCsv?: boolean;
  languageCode?: string;
}) =>
  (exportAsCsv && languageCode ? { 'Accept-Language': languageCode } : {}) as Record<
    string,
    string
  >;

// Customer List Table
export const serializeCustomerData = (data: Reports.CustomerListItem[]): CustomersTableData[] => {
  return data.map((d) => {
    return {
      ...d,
      customer: {
        mainText: d.phoneNumber!,
        subtext: d.name!,
        link: d.customerId?.toString(),
      },
    };
  });
};

export const serializeCustomerOrderData = (
  data: CustomerDetailsTable[],
  languageCode: string
): CustomerDetailsTable[] => {
  if (data && data.length) {
    const updatedData = data.map((d) => {
      return {
        ...d,
        customerAmount: formatCurrencyWithFractions(d.customerAmount, languageCode, d.currency),
        subtotal: formatCurrencyWithFractions(d.subtotal, languageCode, d.currency),
        tipAmount: formatCurrencyWithFractions(d.tipAmount, languageCode, d.currency),
        deliveryCharge: formatCurrencyWithFractions(d.deliveryCharge, languageCode, d.currency),
        netAmount: formatCurrencyWithFractions(d.netAmount, languageCode, d.currency),
        refundAmount: formatCurrencyWithFractions(d.refundAmount, languageCode, d.currency),
        tipAmountTax: formatCurrencyWithFractions(d.tipAmountTax, languageCode, d.currency),
        deliveryChargeTax: formatCurrencyWithFractions(
          d.deliveryChargeTax,
          languageCode,
          d.currency
        ),
        subtotalTax: formatCurrencyWithFractions(d.subtotalTax, languageCode, d.currency),
        totalTax: formatCurrencyWithFractions(d.totalTax, languageCode, d.currency),
        customerFee: formatCurrencyWithFractions(d.customerFee, languageCode, d.currency),
        customerFeeReclaim: formatCurrencyWithFractions(
          d.customerFeeReclaim,
          languageCode,
          d.currency
        ),
        storeFee: formatCurrencyWithFractions(d.storeFee, languageCode, d.currency),
        storeFeeIncludingVat: formatCurrencyWithFractions(
          d.storeFeeIncludingVat,
          languageCode,
          d.currency
        ),
        orderState: d.orderState === 'dispatched' ? 'AcceptedByRestaurant' : d.orderState,
      };
    });
    return updatedData;
  } else {
    return data;
  }
};

export type LoadingCombined = {
  data: CustomersTableData[] | undefined;
  dataLoading?: boolean;
  currency: CurrencyEnum | undefined;
  currencyFilterLoading: boolean;
};
export const loadingCombined = ({
  data,
  dataLoading,
  currency,
  currencyFilterLoading,
}: LoadingCombined) => {
  return (isEmpty(data) && !dataLoading) || (!currency && !currencyFilterLoading);
};

export const storeIdOrCurrencyFilter = (
  storeIds: number[] | CurrencyEnum,
  currency: CurrencyEnum
): number[] | CurrencyEnum => {
  return Array.isArray(storeIds) && storeIds.length && typeof storeIds[0] === 'number'
    ? storeIds
    : currency;
};

export const isTableLoading = (
  currencyFilterLoading: boolean,
  currency?: CurrencyEnum
): boolean => {
  if (currencyFilterLoading) {
    return true;
  } else if (!currency && !currencyFilterLoading) {
    return true;
  } else {
    return false;
  }
};

//#endregion

//#region Customer details
export type FormattedTimeLine = ReturnType<typeof formattedTimeLine>;
export const formattedTimeLine = (
  customerTimeLine: Reports.CustomerJourney,
  dtUtils: DateTimeUtils
) => {
  if (customerTimeLine) {
    const { firstOrder, registrationDate, lastOrder } = customerTimeLine;
    const {
      format,
      formatDistanceToNow,
      userIanaTimeZone,
      utcToZonedTime,
      LOCAL_DATE_TIME_FORMAT,
    } = dtUtils;
    const formatDate = (date: string | undefined) => {
      if (date) {
        return {
          date: format(utcToZonedTime(date, userIanaTimeZone), LOCAL_DATE_TIME_FORMAT),
          timeAgo: formatDistanceToNow(new Date(date), {
            addSuffix: true,
          }),
        };
      } else {
        return { date: '-', timeAgo: '-' };
      }
    };

    const timeline = {
      firstOrder: formatDate(firstOrder!),
      registrationDate: formatDate(registrationDate!),
      lastOrder: formatDate(lastOrder!),
    };

    return timeline;
  } else {
    return {
      firstOrder: { date: '-', timeAgo: '-' },
      registrationDate: { date: '-', timeAgo: '-' },
      lastOrder: { date: '-', timeAgo: '-' },
    };
  }
};
//#endregion
