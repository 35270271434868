import React, { useEffect, useState } from 'react';

import { Product } from '@flipdish/api-client-typescript';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Drawer } from '@fd/ui/atoms/Drawer';
import { Typography } from '@fd/ui/atoms/Typography';

import { DrawerSection } from '../../types';
import { AddModifierToGroup } from '../AddModifierToGroup';
import { CreateModifier } from '../CreateModifier';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  container: {
    height: '100%',
  },
}));

export type AddModifierDrawerProps = {
  AppId: string;
  currentModifiers: Product[];
  translate: TranslateFunction;
  open: boolean;
  onAddModifier: (modifier: Product) => void;
  onClose: () => void;
};

export const AddModifierDrawer = (props: AddModifierDrawerProps): JSX.Element => {
  const { AppId, currentModifiers, onAddModifier, onClose, open, translate } = props;
  const classes = useStyles();
  const [drawerSection, setDrawerSection] = useState<DrawerSection>('add_modifiers');
  const title = drawerSection === 'add_modifiers' ? 'AddModifiersToGroup' : 'New_modifier';

  useEffect(() => {
    if (open) {
      setDrawerSection('add_modifiers');
    }
  }, [open]);

  const handleNewModifierCreated = (newModifier: Product) => {
    onAddModifier(newModifier);
    setDrawerSection('add_modifiers');
  };

  const renderDrawerContent = () => {
    if (drawerSection === 'add_modifiers') {
      return (
        <AddModifierToGroup
          AppId={AppId}
          currentModifiers={currentModifiers}
          translate={translate}
          onAddModifier={onAddModifier}
          onClose={onClose}
          onCreateModifierClick={() => setDrawerSection('create_modifier')}
        />
      );
    }
    return (
      <CreateModifier
        AppId={AppId}
        onClose={() => setDrawerSection('add_modifiers')}
        onModifierCreated={handleNewModifierCreated}
        translate={translate}
      />
    );
  };

  return (
    <Drawer
      onClose={onClose}
      open={open}
      header={
        <div className={classes.headerContainer}>
          <Typography variant="h5">
            <Translate id={title} />
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      }
    >
      {renderDrawerContent()}
    </Drawer>
  );
};
