import React from 'react';

import { App, UpdateMetafieldDefinition } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router-dom';

import { Typography } from '@fd/ui/atoms/Typography';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import { getEntityTranslationKey } from '../../../../../services/utils/stringConverters';
import PageLayout from '../../../../../ui/Layout';
import { MetafieldForm } from '../../components/MetafieldForm';
import { metafieldsService } from '../../metafields.service';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
  },
  listTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

type Props = RouteComponentProps<{ ownerEntity: string; definitionKey: string }>;

const EditMetafield = ({
  appId,
  hasUpdateMetafieldPermission,
  hasViewMetafieldPermission,
  isMetafieldsOn,
  closeNotifySaving,
  definitionKey,
  notifyError,
  notifySaved,
  notifySaving,
  ownerEntity,
  translate,
}: MappedState & MappedDispatch) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const entityName = getEntityTranslationKey(ownerEntity);

  const { data, isLoading } = useQuery({
    queryKey: [metafieldsService.getMetafieldByKeyQueryKey, appId, entityName, definitionKey],
    queryFn: () => metafieldsService.getMetafieldByKey(appId, entityName, definitionKey),
    enabled: isMetafieldsOn && hasViewMetafieldPermission,
  });

  const { mutateAsync } = useMutation({
    mutationFn: (updatedDefinition: UpdateMetafieldDefinition) => {
      notifySaving();
      return metafieldsService.updateMetafieldDefinition(
        appId,
        entityName,
        definitionKey,
        updatedDefinition
      );
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      queryClient.invalidateQueries({
        queryKey: [metafieldsService.getMetafieldByKeyQueryKey, appId, entityName, definitionKey],
      });
    },

    onError: () => {
      closeNotifySaving();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return isMetafieldsOn ? (
    <PageLayout
      documentTitle="Edit_definition"
      strictToParent={true}
      title={data?.Data?.Name || ''}
      toParent={`/${appId}/settings/metafields/${ownerEntity}`}
    >
      <Permissions allowed={[App.AppResourceSetEnum.ViewMetafieldDefinitions]}>
        <div className={classes.container}>
          <MetafieldForm
            appId={appId}
            canEdit={hasUpdateMetafieldPermission}
            definition={data?.Data}
            isEditForm
            isLoading={isLoading}
            onSaveChanges={mutateAsync}
            ownerEntity={entityName}
            translate={translate}
          />
        </div>
      </Permissions>
    </PageLayout>
  ) : (
    <Typography>
      <Translate id="Coming_soon" />
    </Typography>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const getUpdatePermissionsSelector = permissionsSelector.hasPermissionFactory([
    'UpdateMetafieldDefinitions',
  ]);
  const getViewPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'ViewMetafieldDefinitions',
  ]);
  return {
    appId: state.currentApp.AppId as string,
    hasUpdateMetafieldPermission: getUpdatePermissionsSelector(state),
    hasViewMetafieldPermission: getViewPermissionsSelector(state),
    isMetafieldsOn: isProductBasedMenusEnabled(state),
    definitionKey: props.match.params.definitionKey,
    ownerEntity: props.match.params.ownerEntity,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(EditMetafield);
export { EnhancedComponent as EditMetafield };
