import React from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    paddingBottom: theme.spacing(4),
  },
}));

type DeleteSectionModalProps = {
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteLocationModal = (props: DeleteSectionModalProps) => {
  const { onClose, onConfirm } = props;
  const styles = useStyles();
  return (
    <Modal open={true} titleTextId={'Location_delete'}>
      <ModalContent>
        <Typography variant="subtitle1" className={styles.subTitle}>
          <Translate id="Location_delete_description" />
        </Typography>
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonAction={onClose}
          leftButtonTranslationId="Cancel"
          rightButtonAction={onConfirm}
          rightButtonTranslationId="Confirm"
          fdKey="delete-location-modal-buttons"
        />
      </ModalActions>
    </Modal>
  );
};

export default DeleteLocationModal;
