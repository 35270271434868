import React, { useEffect, useState } from 'react';

import GenericTable from '../../../../ui/GenericTable/GenericTable';
import { isTableLoading, Params } from '../../helpers';
import {
  CustomerManagementPagination,
  CustomersTableColumns,
  CustomersTableData,
} from '../../types';
import EmptyTable from './EmptyTable';

export const EMPTY_TABLE_COLUMNS: TranslationId[] = [
  'Customer',
  'Order_count',
  'Total_order_value',
  'Average_order_value',
  'Last_activity_time',
];

export type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type InnerProps = {};
type OuterProps = {
  AppId: string;
  currency?: CurrencyEnum;
  currencyFilterLoading: boolean;
  currentSearch: Params | undefined;
  customersTableLoading: boolean;
  data?: CustomersTableData[];
  downloadCSV?: () => Promise<void>;
  languageCode: string;
  loading?: boolean;
  metadata: CustomersTableColumns[];
  columnOrder: 'asc' | 'desc';
  columnOrderBy: string;
  onSort: (sortKey: string, sortDirection: 'asc' | 'desc') => void;
  orderLocalStorageKey: string;
  orderByLocalStorageKey: string;
  page?: number;
  pagination?: CustomerManagementPagination;
  rowsPerPage?: number;
  rowsPerPageLocalStorageKey?: string;
  setPage?: (n) => void;
  setRowsPerPage?: (n) => void;
  title: TranslationId;
  onRowClick: Function;
};
type Props = InnerProps & OuterProps;
const CustomersDataTable: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    AppId,
    columnOrder,
    columnOrderBy,
    currency,
    currencyFilterLoading,
    currentSearch,
    customersTableLoading,
    data,
    downloadCSV,
    languageCode,
    loading,
    metadata,
    onSort,
    orderLocalStorageKey,
    orderByLocalStorageKey,
    page,
    pagination,
    rowsPerPage,
    rowsPerPageLocalStorageKey,
    setPage,
    setRowsPerPage,
    title,
    onRowClick,
  } = props;

  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: CustomersTableColumns }>();
  const [order, setOrder] = useState<'asc' | 'desc'>(columnOrder);
  const [orderBy, setOrderBy] = useState(columnOrderBy);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem(orderLocalStorageKey, order);
  }, [order]);

  useEffect(() => {
    localStorage.setItem(orderByLocalStorageKey, orderBy);
  }, [orderBy]);

  useEffect(() => {
    if (loading && !isLoaded) {
      setLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    if (isLoaded) {
      onSort(orderBy, order);
    }
  }, [orderBy, order]);

  useEffect(() => {
    setColumns(
      new Set(
        metadata
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      metadata.reduce<{ [key: string]: CustomersTableColumns }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
  }, [metadata]);

  // if we are finished loading table data and loading currency data and we have neither AND a store is selected
  // show 'No customers' message for that store
  if (
    (!customersTableLoading && data && !data.length) ||
    (!currencyFilterLoading && !currency && currentSearch && currentSearch.store)
  ) {
    return (
      <EmptyTable
        ariaTitle={title}
        columns={EMPTY_TABLE_COLUMNS}
        dataFd={'No-customers'}
        emptyTitle={'No_customers'}
        noLink
        subtitle={'No_customers_message'}
      />
    );
  }

  // if we are finished loading the currency data and we have no stores selected show the'No stores selected' message
  if (!currencyFilterLoading && currentSearch && !currentSearch.store) {
    return (
      <EmptyTable
        ariaTitle={title}
        columns={EMPTY_TABLE_COLUMNS}
        dataFd={'No-store-selected'}
        emptyTitle={'Select_store(s)'}
        noLink
        subtitle={'Choose_a_store_or_group_of_stores_to_view_customers'}
      />
    );
  }

  const onSortKeyPressed = (key) => {
    if (meta![key].isSortable) {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
    }
  };

  const setAllColumns = (col) => {
    setColumns(col);
  };

  return (
    <GenericTable
      AppId={AppId}
      columns={columns}
      currency={currency}
      data={data}
      downloadCSV={downloadCSV}
      languageCode={languageCode}
      loading={isTableLoading(currencyFilterLoading, currency)}
      meta={meta}
      metadata={metadata}
      onSortKeyPressed={onSortKeyPressed}
      onRowClick={onRowClick}
      order={order}
      orderBy={orderBy}
      page={page}
      pagination={pagination}
      rowsPerPage={rowsPerPage}
      rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      rowsPerPageOptions={[50, 100, 250, 500]}
      setColumns={setAllColumns}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      showTimeAgo={true}
    />
  );
};

export default CustomersDataTable;
