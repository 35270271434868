import React, { useEffect, useMemo, useState } from 'react';

import { WebhookSubscription } from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import DisableIcon from '@mui/icons-material/CancelOutlined';
import EnableIcon from '@mui/icons-material/CheckCircleOutline';
import EventNamesIcon from '@mui/icons-material/CodeOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import LogsIcon from '@mui/icons-material/ViewListOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fab from '@mui/material/Fab';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { once } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import { useLoadingAsIndicator } from '../../../custom-hooks/useAsIndicator';
import { usePagination } from '../../../custom-hooks/useFilterablePagination';
import { notify, NotifyProps } from '../../../layouts/Notify/actions';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { copyToClipboard } from '../../../ui/utils';
import { Loader } from '../../common/Loader';
import {
  CREATE_WEBHOOK,
  createWebhook,
  createWebhookEventName,
  DELETE_WEBHOOK,
  deleteWebhook,
  deleteWebhookEventName,
  getAllWebhookEventNames,
  getOAuthAppById,
  getWebhooks,
  GetWebhooksParams,
  resetWebhookError,
  updateWebhook,
} from '../actions';
import OAuthAppCreateWebhookForm from './OAuthAppCreateWebhookForm';
import OAuthAppWebhookDialog from './OAuthAppWebhookDialog';
import OAuthAppWebhookEeventsDialog from './OAuthAppWebhookEeventsDialog';
import OAuthAppWebhooksList from './OAuthAppWebhooksList';
import OAuthAppWebhooksMobileList from './OAuthAppWebhooksMobileList';

const useStyles = makeStyles((theme: Theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#ff0046',
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
  row: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  cell: {
    wordBreak: 'break-all',
  },
  list: {
    fontSize: '12px',
    lineHeight: 1.33,
    padding: 0,
    margin: `0 0 ${theme.spacing(2)}`,
    borderTop: '1px solid rgb(224, 224, 224)',
  },
  listItem: {
    display: 'flex',
    padding: `20px ${theme.spacing(2)} 12px`,
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  uri: {
    fontSize: '16px',
    lineHeight: 1.5,
    marginBottom: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subList: {
    margin: 0,
    '& dt': {
      float: 'left',
    },
  },
  copy: {
    position: 'relative',
    cursor: 'pointer',
    '& span:nth-child(2)': {
      display: 'none',
      color: '#05149e',
    },
    '&:hover': {
      '& span:nth-child(1)': {
        visibility: 'hidden',
      },
      '& span:nth-child(2)': {
        position: 'absolute',
        top: '50%',
        left: theme.spacing(3),
        transform: 'translateY(-50%)',
        display: 'block',
      },
      [theme.breakpoints.down('md')]: {
        '& span:nth-child(2)': {
          left: 0,
        },
      },
    },
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  icon: {
    marginRight: '12px',
    color: 'rgba(0, 0, 0, 0.54);',
  },
}));

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ OAuthAppId: string }>;

const mapStateToProps = (state: AppState) => {
  const {
    currentApp,
    developers: { oauthApp, webhooks, isLoading },
    locale,
  } = state;

  return {
    currentApp,
    oauthApp,
    webhooks,
    isLoading,
    isDeleting: state.loading[DELETE_WEBHOOK],
    isCreating: state.loading[CREATE_WEBHOOK],
    translate: getTranslate(locale),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  const {
    match: {
      params: { OAuthAppId },
    },
  } = ownProps;
  return {
    loadWebhooks: (params: GetWebhooksParams) => dispatch(getWebhooks(OAuthAppId, params)),
    loadOAuthApp: () => dispatch(getOAuthAppById(OAuthAppId)),
    createWebhook: (webhook: WebhookSubscription) => dispatch(createWebhook(OAuthAppId, webhook)),
    updateWebhook: (webhook: WebhookSubscription) => dispatch(updateWebhook(OAuthAppId, webhook)),
    deleteWebhook: (id: number) => dispatch(deleteWebhook(OAuthAppId, id)),
    getAllEventNames: once(() => dispatch(getAllWebhookEventNames())),
    createWebhookEventName: (id: number, eventName: string) =>
      dispatch(createWebhookEventName(OAuthAppId, id, eventName)),
    deleteWebhookEventName: (id: number, eventName: string) =>
      dispatch(deleteWebhookEventName(OAuthAppId, id, eventName)),
    notify: (data: NotifyProps) => dispatch(notify(data)),
    dispatchResetWebhookError: () => dispatch(resetWebhookError()),
  };
};

export default compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(function ({
  oauthApp,
  isLoading,
  webhooks: { Data, isProcessing, allEventNames, TotalRecordCount, apiError },
  loadOAuthApp,
  loadWebhooks,
  deleteWebhook,
  createWebhook,
  updateWebhook,
  translate,
  notify,
  currentApp,
  dispatchResetWebhookError,
  getAllEventNames,
  createWebhookEventName,
  deleteWebhookEventName,
  match: {
    params: { OAuthAppId },
  },
  isDeleting,
  isCreating,
  history,
}: Props) {
  const classes = useStyles();
  const isWebhookDeleted = useLoadingAsIndicator(isDeleting);
  const isWebhookCreated = useLoadingAsIndicator(isCreating);
  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } = usePagination(history);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [isEventNamesDialogOpen, setEventNamesDialogOpen] = useState(false);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedWebhookId, setSelectedWebhookId] = useState<number | null>(null);
  const selectedWebhook = useMemo(
    () => Data.find((item) => item.Id === selectedWebhookId),
    [selectedWebhookId, Data]
  );

  const copyHandler = (id: number) => {
    copyToClipboard(null, `Webhook_Callback_url${id}`);
    notify({
      message: `${translate('Callback_url')} ${translate('copied_to_clipboard')}`,
      variant: 'success',
    });
  };

  useEffect(() => {
    if (!isProcessing && apiError) {
      notify({
        message: translate('Something_went_wrong') as string,
        variant: 'error',
      });
      dispatchResetWebhookError();
    }
  }, [apiError, isProcessing]);

  useEffect(() => {
    loadOAuthApp();
  }, []);

  useEffect(() => {
    loadWebhooks({
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  useEffect(() => {
    // only reload if save/update was successful
    if ((isWebhookCreated || isWebhookDeleted) && !apiError) {
      loadWebhooks({
        page: page + 1,
        limit: rowsPerPage,
      });
    }
  }, [isWebhookDeleted, isWebhookCreated]);

  const handleClick = (e, item) => {
    setAnchorEl(e.target);
    setActionsOpen(true);
    setSelectedWebhookId(item.Id);
  };
  const handleClose = () => setActionsOpen(false);

  return (
    <>
      <PageLayout
        fluid
        documentTitle="Webhooks"
        toParent={`/${currentApp.AppId}/developers/${oauthApp.OauthAppId}`}
        title={translate('Webhooks')}
        caption={oauthApp.OauthAppName}
        actions={() => (
          <Fab
            variant="extended"
            color="secondary"
            aria-label={translate('Add_new') as string}
            className={classes.button}
            onClick={() => setCreateDialogOpen(true)}
            data-fd="oauthapp-add"
          >
            <AddIcon className={classes.extendedIcon} />
            {translate('Add_new')}
          </Fab>
        )}
      >
        <PaperContainer fluid>
          {isLoading ? (
            <Loader />
          ) : Data.length ? (
            <>
              <OAuthAppWebhooksList
                data={Data}
                rowsPerPage={rowsPerPage}
                page={page}
                copyHandler={copyHandler}
                handleClick={handleClick}
                TotalRecordCount={TotalRecordCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <OAuthAppWebhooksMobileList
                data={Data}
                rowsPerPage={rowsPerPage}
                page={page}
                copyHandler={copyHandler}
                handleClick={handleClick}
                TotalRecordCount={TotalRecordCount}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <EmptyComponent
              title="Empty_webhooks_list_header"
              subtitle="Empty_webhooks_list_subheader"
            />
          )}
        </PaperContainer>
      </PageLayout>
      <OAuthAppCreateWebhookForm
        apiError={apiError}
        setDialogOpen={setCreateDialogOpen}
        isDialogOpen={isCreateDialogOpen}
        submit={createWebhook}
        isLoading={isProcessing}
      />
      <OAuthAppWebhookDialog
        isLoading={isProcessing}
        isDialogOpen={isDeleteDialogOpen}
        title="Delete_webhook_dialog_title"
        description="Delete_webhook_dialog_description"
        submitText="Delete"
        setDialogOpen={setDeleteDialogOpen}
        submit={() => {
          if (selectedWebhook && selectedWebhook.Id) {
            deleteWebhook(selectedWebhook.Id);
            setDeleteDialogOpen(false);
          }
        }}
      />
      <OAuthAppWebhookDialog
        isLoading={isProcessing}
        isDialogOpen={isUpdateDialogOpen}
        title={
          selectedWebhook && selectedWebhook.Enabled
            ? 'Disable_webhook_dialog_title'
            : 'Enable_webhook_dialog_title'
        }
        description={
          selectedWebhook && selectedWebhook.Enabled
            ? 'Disable_webhook_dialog_description'
            : 'Enable_webhook_dialog_description'
        }
        submitText={selectedWebhook && selectedWebhook.Enabled ? 'Disable' : 'Enable'}
        setDialogOpen={setUpdateDialogOpen}
        submit={() => {
          if (selectedWebhook) {
            setUpdateDialogOpen(false);
            updateWebhook({
              ...selectedWebhook,
              Enabled: !selectedWebhook.Enabled,
            });
          }
        }}
      />
      {selectedWebhook && (
        <OAuthAppWebhookEeventsDialog
          isLoading={isProcessing}
          isDialogOpen={isEventNamesDialogOpen}
          data={selectedWebhook}
          eventNames={allEventNames}
          onCreate={createWebhookEventName}
          onDelete={deleteWebhookEventName}
          onClose={() => {
            setEventNamesDialogOpen(false);
          }}
        />
      )}

      <Popper open={isActionsOpen} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) =>
          selectedWebhook ? (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem
                      data-fd="menu_item_event_names"
                      onClick={() => {
                        getAllEventNames();
                        setEventNamesDialogOpen(true);
                      }}
                    >
                      <EventNamesIcon fontSize="small" className={classes.icon} />{' '}
                      {translate('Event_names')}
                    </MenuItem>
                    <MenuItem data-fd="menu_item_logs">
                      <Link
                        className={classes.link}
                        to={`/${currentApp.AppId}/developers/${OAuthAppId}/${selectedWebhook.Id}/webhook_logs`}
                      >
                        <LogsIcon fontSize="small" className={classes.icon} /> {translate('Logs')}
                      </Link>
                    </MenuItem>
                    <MenuItem
                      data-fd="menu_item_status"
                      onClick={() => {
                        setUpdateDialogOpen(true);
                      }}
                    >
                      {selectedWebhook.Enabled ? (
                        <DisableIcon fontSize="small" className={classes.icon} />
                      ) : (
                        <EnableIcon fontSize="small" className={classes.icon} />
                      )}{' '}
                      {translate(selectedWebhook.Enabled ? 'Disable' : 'Enable')}
                    </MenuItem>
                    <MenuItem
                      data-fd="menu_item_delete"
                      onClick={() => {
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <DeleteIcon fontSize="small" className={classes.icon} /> {translate('Delete')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          ) : null
        }
      </Popper>
    </>
  );
});
