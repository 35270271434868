import React from 'react';

import Grid from '@mui/material/Grid';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import AssignStoresField from '@fd/ui/Filter/AssignStoresField';

import Button from '../../../ui/Button/Button';
import Spacer from '../../../ui/Spacer';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';
import KioskNameField from './fields/KioskNameField';
import KioskPinField from './fields/KioskPinField';

export type HydraRegistrationForm = {
  KioskName: string;
  StoreIds: string[];
  PinCode?: number | '';
};

export type KioskFormProps = {
  initialValues?: HydraRegistrationForm;
  submit: (changes) => Promise<void>;
  onSubmitSuccess: (flag: boolean) => void;
};
export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (
  initialValues: HydraRegistrationForm = {
    KioskName: '',
    StoreIds: [],
    PinCode: '',
  }
): HydraRegistrationForm => {
  return {
    KioskName: initialValues.KioskName || '',
    StoreIds: initialValues.StoreIds || [],
    PinCode: initialValues.PinCode || '',
  };
};

type Props = FormikProps<FormValues> & KioskFormProps;

const AddKioskForm = (props: Props) => {
  return (
    <Form>
      <PreventNavigation when={props.dirty} />

      <Grid container>
        <Grid item xs={12}>
          <KioskNameField />
        </Grid>

        <Grid item xs={12}>
          <AssignStoresField
            dataFd="kiosk-store-select"
            enableShrink
            fieldLabel="Store(s)"
            fieldName="StoreIds"
            placeholder=""
          />
        </Grid>

        <Grid item xs={12}>
          <KioskPinField />
        </Grid>

        <Grid item xs={12}>
          <Spacer size={16}>
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  fdKey="btn-kiosk-create"
                  variant="contained"
                  disabled={props.isSubmitting}
                >
                  <Translate id="Add_kiosk" />
                </Button>
              </Grid>
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<KioskFormProps, FormValues>({
  displayName: 'AddKioskForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.initialValues);
  },
  handleSubmit: (values, formikBag) => {
    const newKiosk: HydraRegistrationForm = {
      ...values,
    };
    formikBag.props
      .submit(newKiosk)
      .then(() => {
        formikBag.resetForm({ values: values });
        formikBag.props.onSubmitSuccess(true);
      })
      .catch(() => {
        formikBag.setSubmitting(false);
        // if(err.message)
      });
  },
})(AddKioskForm);
