import React from 'react';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { AppStoreAppConfig } from './Consumers/pages/AppStoreAppConfig';
import { AppStoreAppDetails } from './Consumers/pages/AppStoreAppDetails';
import { AppStoreApps } from './Consumers/pages/AppStoreApps';
import AppStoreOAuthStatus from './Consumers/pages/AppStoreOAuthStatus';
import { AppStoreApps as DeveloperAppStoreApps } from './Developers/pages/AppStoreApps';
import { ManageAppStoreApp } from './Developers/pages/ManageAppStoreApp';

type Props = RouteComponentProps;

const AppStoreRoutes = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="app-store-app">
      <Switch>
        <Route exact path={`${path}`} component={AppStoreApps} />
        <Route exact path={`${path}/developers/`} component={DeveloperAppStoreApps} />
        <Route exact path={`${path}/developers/new`} component={ManageAppStoreApp} />
        <Route exact path={`${path}/developers/:appStoreAppId`} component={ManageAppStoreApp} />
        <Route exact path={`${path}/:appStoreAppId`} component={AppStoreAppDetails} />
        <Route
          exact
          path={`${path}/:appStoreAppId/config/:configId`}
          component={AppStoreAppConfig}
        />
        <Route exact path={`${path}/oauth/:appStoreAppId/result`} component={AppStoreOAuthStatus} />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(AppStoreRoutes);
