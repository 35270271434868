import { addValues, subtractValues } from '../helpers';
import { PayoutSummary } from '../types';

/**
 * Returns formatted payout summary values mapped to Translation Ids & tooltips.
 *
 * @param {PayoutSummary} data reduced totals for payoutDetailSummary from Backend
 * @return {[Revenue?, RevenueAdjustments?, FlipdishFees?, ThirdPartyFees? OtherCharges?, Balance?, Amount?]} Translation Ids, tooltips mapped to data values
 */
export const formatPayoutDetailsSummaryNew = (data: PayoutSummary) => {
  const {
    Amount,
    Balance,
    Chargebacks,
    FlipdishFees,
    OtherCharges,
    PosRevenue,
    Revenue,
    RevenueAdjustments,
    ThirdPartyFees,
  } = data;

  const tableData: {}[] = [];

  const getRevenueTotal = () => {
    if (Revenue && PosRevenue) {
      return addValues(Revenue.TotalOnlineRevenue, PosRevenue.TotalPosRevenue);
    } else if (Revenue) {
      return Revenue.TotalOnlineRevenue;
    }
    return 0;
  };

  tableData.push({
    sectionTitle: 'Payout_Calculation',
    sectionTotalTitle: 'Total_Payout',
    sectionTotal: Amount,
    lineItems: [
      {
        lineTitle: 'Total_revenue',
        lineTotal: getRevenueTotal(),
      },
      {
        lineTitle: 'Revenue_Adjustments',
        lineTotal:
          RevenueAdjustments?.TotalOnlineRevenueAdjustments !== undefined
            ? RevenueAdjustments.TotalOnlineRevenueAdjustments
            : 0,
        tooltipContent: 'Refunds_payments_or_chargebacks_that_occurred_in_t',
      },
      {
        lineTitle: 'Flipdish_Fees',
        lineTotal: FlipdishFees ? FlipdishFees.TotalFees : 0,
      },
      {
        lineTitle: 'Chargebacks',
        lineTotal:
          Chargebacks?.TotalChargebackCost !== undefined ? Chargebacks.TotalChargebackCost : 0,
        hideLine:
          !Chargebacks || !Chargebacks.TotalChargebackCost || Chargebacks.TotalChargebackCost === 0,
      },
      {
        lineTitle: 'Other_Charges',
        lineTotal:
          OtherCharges?.TotalOtherCharges !== undefined ? OtherCharges.TotalOtherCharges : 0,
        hideLine:
          !OtherCharges || !OtherCharges.TotalOtherCharges || OtherCharges.TotalOtherCharges === 0,
      },
      {
        lineTitle: 'Accrued_adjustments',
        lineTotal: Balance ? subtractValues(Balance?.OpeningBalance, Balance.ClosingBalance) : 0,
        tooltipContent: 'Refunds_payments_or_chargebacks_for_orders_that_ha',
      },
      {
        lineTitle: 'ThirdParty_Fees',
        lineTotal:
          ThirdPartyFees?.TotalThirdPartyFees !== undefined
            ? ThirdPartyFees.TotalThirdPartyFees
            : 0,
        hideLine:
          !ThirdPartyFees ||
          !ThirdPartyFees.TotalThirdPartyFees ||
          ThirdPartyFees.TotalThirdPartyFees === 0,
      },
    ],
  });

  return tableData;
};
