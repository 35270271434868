import React, { useCallback, useEffect, useState } from 'react';

import 'wicket';
import 'wicket/wicket-gmap3';
import { App } from '@flipdish/api-client-typescript';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';

import { deliveryZonesConstants } from '../../constants/deliveryzones.constants';
import { GMAP_API_KEY, libraries } from '../../constants/map.constant';
import { createLoadingSelector } from '../../selectors/loading.selector';
import { createDeliveryZoneIdsSelector } from '../../selectors/storeDeliveryZone.selector';
import MapItem from './components/MapItem';
import { IDeliveryZone } from './types';

export interface MapProps {
  storeId: number;
  mapCenter: google.maps.LatLng | google.maps.LatLngLiteral;
  isSmallScreen: boolean;
  onChange: (changes: IDeliveryZone) => void;
  onSelect: (deliveryZoneId: number) => void;
}
type Props = MapProps & MappedState;

const GMap = ({
  storeId,
  mapCenter,
  zoneIds,
  onChange,
  onSelect,
  isSmallScreen,
  canEdit,
  createEventFired,
}: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY ?? '',
    libraries,
  });

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    map?.panTo(mapCenter);
  }, [createEventFired]);

  const getCenterFromMapRef = () => {
    const getCenter = map?.getCenter();
    const lat = getCenter?.lat();
    const lng = getCenter?.lng();
    return lat && lng ? { lat, lng } : mapCenter;
  };

  const options = {
    minZoom: 6,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  return (
    <>
      {isLoaded && (
        <div style={{ height: '100%' }}>
          <GoogleMap
            center={getCenterFromMapRef()}
            mapContainerStyle={{ height: '100%' }}
            onLoad={onMapLoad}
            options={canEdit ? options : { ...options, gestureHandling: 'none' }}
            onUnmount={onMapUnmount}
            zoom={isSmallScreen ? 11 : 12}
          >
            <Marker position={mapCenter} />

            {zoneIds.map((id: number, idx) => {
              return (
                <MapItem
                  key={id}
                  storeId={storeId}
                  deliveryZoneId={id}
                  index={idx}
                  onSelect={onSelect}
                  onChange={onChange}
                  disabled={!canEdit}
                />
              );
            })}
          </GoogleMap>
        </div>
      )}
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  const loadingSelector = createLoadingSelector([deliveryZonesConstants.DELIVERY_ZONE_CREATE]);
  const zoneIdsSelector = createDeliveryZoneIdsSelector(ownProps.storeId);

  return (state: AppState) => {
    const canEdit = state.permissions.some(
      (p) => p === App.AppResourceSetEnum.UpdateDeliveryZones.toString()
    );

    return {
      createEventFired: loadingSelector(state),
      zoneIds: zoneIdsSelector(state),
      canEdit,
    };
  };
};

export default connect(mapStateToPropsFactory)(GMap);
