import React, { useMemo } from 'react';

import { Order } from '@flipdish/api-client-typescript';
import Phone from '@mui/icons-material/Phone';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getIanaTimeZoneId } from '../../../helpers/timeZones';
import { storeSelectors } from '../../../selectors';
import DateTimeZone from '../../../ui/DateTime/DateTime';
import {
  CustomerPhoneSkeleton,
  DeliveryLocationSkeleton,
  PaymentAccountDescriptionSkeleton,
} from '../components/OrderDetailsLoading';
import { renderPaymentStatus, renderTotalAmount } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  detailsGridTop: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  detailsGrid: {
    marginTop: 0,
    marginRight: theme.spacing(3),
    marginBottom: '30px',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(2),
      marginBottom: '23px',
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  caption: {
    display: 'inline-block',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: theme.spacing(1),
  },
  captionHide: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  deliveryGrid: {
    paddingBottom: '0',
  },
  deliveryMapGrid: {
    padding: '0',
    marginTop: theme.spacing(0.5),
    marginBottom: '23px',
  },
  headline5: {
    color: 'rgba(0, 0, 0, 0.87)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headline5Delivery: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      marginBottom: theme.spacing(1),
    },
  },
  textStyle1: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.56)',
  },
  textStylePhone: {
    textDecoration: 'none',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      padding: '10px 0',
    },
    color: '#05149e',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      letterSpacing: '1.25px',
    },
  },
  textStyleRefunded: {
    color: 'rgba(0, 0, 0, 0.56)',
    textTransform: 'lowercase',
  },
  textStylePaid: {
    color: '#25c974',
    textTransform: 'lowercase',
  },
  textStyleUnPaid: {
    color: '#ff395f',
    textTransform: 'lowercase',
  },
  link: {
    fontSize: '12px',
    letterSpacing: '0.4px',
  },
  phoneIcon: {
    display: 'none',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      fontSize: '14px',
      width: '18px',
      height: '18px',
      verticalAlign: 'text-bottom',
    },
  },
  viewMapText: {
    display: 'none',
    fontWeight: 'normal',
    [theme.breakpoints.down('md')]: {
      textTransform: 'lowercase',
      fontSize: '14px',
      letterSpacing: '0.4px',
      display: 'inline',
    },
  },
  hideMe: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  distanceMobile: {
    display: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 'normal',
    },
  },
  distanceMeters: {
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 'normal',
    },
  },
  customerGrid: {
    paddingTop: '10px',
    paddingBottom: '21px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '14px',
    },
  },
  timePaymentGrid: {
    paddingTop: '10px',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '22px',
    },
  },
}));

// DISTANCE CALC
const haversine_distance = (coords1, coords2, currency) => {
  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(coords2.Latitude - coords1.Latitude);
  const dLong = rad(coords2.Longitude - coords1.Longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(coords1.Latitude)) *
      Math.cos(rad(coords2.Latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  if (currency === 'USD' || currency === 'GBP') {
    const resultMiles = d / 1600;
    return `${resultMiles.toFixed(2)}m`;
  } else {
    const resultKM = d / 1000;
    return `${resultKM.toFixed(2)}km`;
  }
};

type Props = {
  order: Order;
  languageCode: string;
  currency: string;
  storeIdOrTimeZone?: string | number;
};

const GeneralInfo = (props: Props & MappedState) => {
  const classes = useStyles();
  const { order, languageCode, currency, storeIanaTZ } = props;

  const requestedOrAcceptedForTime = useMemo(() => {
    return (
      <DateTimeZone
        dataFd="Requested_for_local_store_time"
        value={
          order.AcceptedFor
            ? (order.AcceptedFor as unknown as Date)
            : (order.RequestedForTime! as unknown as Date)
        }
        ianaTimeZone={storeIanaTZ}
      >
        {({
          dtUtils: { utcToZonedTime, userIanaTimeZone, format, isToday, isYesterday },
          value,
        }) => {
          const time = value ? format(value, 'p') : '';
          const date = value ? format(value, 'EEE d MMM') : '';

          return (
            <>
              <div>
                <Typography
                  variant="h5"
                  className={classes.headline5}
                  style={{ display: 'inline' }}
                >
                  {time} {isToday(value) && <Translate id="Today" />}{' '}
                  {isYesterday(value) && <Translate id="Yesterday" />}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ color: 'rgba(0,0,0,0.56)', display: 'inline' }}
                >{` ${date}`}</Typography>
              </div>

              <div style={{ marginTop: '4px', display: 'inline-block' }} />
              <Typography variant="caption" aria-describedby="Store_timezone_tooltip">
                <Translate id={order.AcceptedFor ? 'Requested for' : 'Placed_at'} />

                {order.AcceptedFor
                  ? order.RequestedForTime &&
                    ` ${format(
                      utcToZonedTime(order.RequestedForTime, userIanaTimeZone),
                      'p EEE d MMM'
                    )}`
                  : order.PlacedTime &&
                    ` ${format(utcToZonedTime(order.PlacedTime, userIanaTimeZone), 'p EEE d MMM')}`}
              </Typography>
            </>
          );
        }}
      </DateTimeZone>
    );
  }, [order.RequestedForTime, order.AcceptedFor, storeIanaTZ]);

  const phoneNumber =
    'Customer' in order ? (order.Customer ? order.Customer.PhoneNumber : '') : null;
  const dataFdPrefix = `order_${order.OrderId}_`;

  return (
    <>
      <Grid container className={classes.detailsGridTop}>
        <Grid item xs={7} sm={4} className={classes.timePaymentGrid}>
          <Paper square className={classes.paper}>
            <Typography variant="caption" className={classes.caption}>
              <Translate id={order.AcceptedFor ? 'Accepted_for' : 'Requested for'} />
            </Typography>
            {requestedOrAcceptedForTime}
          </Paper>
        </Grid>
        <Grid item xs={5} sm={4} className={classes.timePaymentGrid}>
          <Paper square className={classes.paper}>
            <Typography variant="caption" className={classes.caption}>
              <Translate id="Amount" />
              {' / '}
              <Translate id="payment" />
            </Typography>

            <Typography variant="h5" className={classes.headline5}>
              {renderTotalAmount({
                amount: order.Amount,
                refundedAmount: order.RefundedAmount,
                languageCode,
                currency,
              })}{' '}
              {renderPaymentStatus(order, classes, `${dataFdPrefix}payment_status`)}{' '}
              {'PaymentAccountDescription' in order ? (
                <Hidden smDown>
                  <Typography variant="caption" component="span">
                    (
                    {order.PaymentAccountDescription &&
                      order.PaymentAccountDescription.toLowerCase()}
                    )
                  </Typography>
                </Hidden>
              ) : null}
            </Typography>
            {'PaymentAccountDescription' in order ? (
              <Hidden smUp>
                <Typography variant="h5" className={classes.headline5}>
                  <Typography variant="caption" component="span">
                    (
                    {order.PaymentAccountDescription &&
                      order.PaymentAccountDescription.toLowerCase()}
                    )
                  </Typography>
                </Typography>
              </Hidden>
            ) : (
              <PaymentAccountDescriptionSkeleton />
            )}
          </Paper>
        </Grid>

        <Grid item sm={4} xs={12} className={classes.customerGrid}>
          <Paper square className={classes.paper}>
            {phoneNumber === null ? (
              <CustomerPhoneSkeleton />
            ) : (
              <>
                {(order as Order).AppType === Order.AppTypeEnum.Kiosk ? null : (
                  <>
                    <Typography variant="caption" className={classes.caption}>
                      <Translate>
                        {(translate: any) => translate('Customer phone') as string}
                      </Translate>
                    </Typography>
                    <Hidden smDown>
                      <Typography variant="h5" className={classes.textStylePhone}>
                        {phoneNumber}
                      </Typography>
                    </Hidden>
                    <Hidden smUp>
                      <Typography variant="h5" className={classes.textStylePhone}>
                        <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }}>
                          <Phone className={classes.phoneIcon} />
                          &nbsp;
                          {phoneNumber}
                        </a>
                      </Typography>
                    </Hidden>
                  </>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {'DeliveryLocation' in order ? (
        <>
          {order.DeliveryType &&
            order.DeliveryType === Order.DeliveryTypeEnum.Delivery &&
            order.DeliveryLocation && (
              <Grid container className={classes.detailsGrid}>
                <Grid item sm={8} xs={12} className={classes.deliveryGrid}>
                  <Paper square className={classes.paper}>
                    <Typography variant="caption" className={classes.caption}>
                      <Translate id="Delivery address" />
                    </Typography>

                    <Typography variant="h5" className={classes.headline5Delivery}>
                      {order.DeliveryLocation.PrettyAddressString}
                    </Typography>
                    {order.DeliveryLocation.Coordinates && (
                      <Typography className={classes.link}>
                        <Link
                          href={`https://www.google.com/maps/search/?api=1&query=${order.DeliveryLocation.Coordinates.Latitude},${order.DeliveryLocation.Coordinates.Longitude}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          underline="none"
                        >
                          <span className={classes.hideMe}>
                            <Translate id="View map" />
                          </span>
                        </Link>
                      </Typography>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4} className={classes.deliveryMapGrid}>
                  <Paper square className={classes.paper}>
                    <Typography variant="caption" className={classes.captionHide}>
                      <span>
                        <Translate id="Delivery distance" />
                      </span>
                    </Typography>
                    {order.DeliveryLocation.Coordinates && (
                      <Typography variant="h5" className={classes.headline5Delivery}>
                        <Link
                          href={`https://www.google.com/maps/search/?api=1&query=${order.DeliveryLocation.Coordinates.Latitude},${order.DeliveryLocation.Coordinates.Longitude}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          underline="none"
                          className={classes.viewMapText}
                        >
                          <Translate id="View map" />
                          &nbsp;
                        </Link>
                        {order.Store && (
                          <>
                            <span className={classes.distanceMeters}>
                              {haversine_distance(
                                order.Store.Coordinates,
                                order.DeliveryLocation.Coordinates,
                                order.Store.Currency
                              )}
                            </span>
                            <span className={classes.distanceMobile}>
                              &nbsp;
                              <Translate id="from restaurant" />
                            </span>{' '}
                          </>
                        )}
                      </Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            )}
        </>
      ) : (
        <DeliveryLocationSkeleton />
      )}
    </>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeIdOrTimeZone }: Props) => {
  let storeIanaTZ: string | undefined;
  switch (typeof storeIdOrTimeZone) {
    case 'number':
      // KNOWN BUG: store maybe not loaded
      storeIanaTZ = storeSelectors.timeZone(state, { storeId: storeIdOrTimeZone });
      break;
    case 'string':
      storeIanaTZ = getIanaTimeZoneId(storeIdOrTimeZone);
      break;
    default:
      break;
  }

  return {
    storeIanaTZ,
  };
};

export default compose<Props & MappedState, Props>(connect(mapStateToProps))(GeneralInfo);
