import { StoresApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const storesApi = createApi(StoresApiFactory);

export type GetStoresByAppIdProps = {
  appId: string;
  query?: string;
  page?: number;
  limit?: number;
};

export async function getStoresByAppId(props: GetStoresByAppIdProps) {
  try {
    const { appId, query, page, limit } = props;
    const incomingMessage = await storesApi.getStoresByAppId(appId, query, undefined, page, limit);
    return incomingMessage;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
