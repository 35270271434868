import React, { useEffect, useMemo, useState } from 'react';

import { CustomerUpdateModel } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import StoreFilterInfiniteScroll from '@fd/ui/Filter/StoreFilterInfiniteScroll';
import PageLayout from '@fd/ui/Layout';

import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import MainCard from '../components/Cards/MainCard';
import SideCard from '../components/Cards/SideCard';
import TimeLine from '../components/Cards/TimeLine';
import MarketingModal from '../components/Modal/MarketingModal';
import SuspiciousActivityModal from '../components/Modal/SuspiciousActivityModal';
import CustomerDetailsTable from '../components/Tables/CustomerDetailsTable';
import {
  getCustomerDetail,
  getCustomerStatus,
  getStoreHeaderList,
  resetCustomerOrderValues,
  setCustomerStatus,
} from '../Customers.actions';
import {
  customerDetailsLoadingSelector,
  customerOrderLoadingSelector,
  customerStatusLoadingSelector,
  customerStatusSelector,
  getCustomerDetailsSelector,
  getCustomerJourneySelector,
  getStoreHeadersList,
} from '../Customers.selectors';
import { FormattedTimeLine, formattedTimeLine, getStoreParams } from '../helpers';
import { CustomerStatus } from '../types';

type InnerProps = MappedState & MappedDispatch;
type OuterProps = RouteComponentProps<{ customerId: string }>;
type Props = InnerProps & OuterProps;
const CustomerDetailsPage = (props: Props) => {
  const {
    activeLanguage,
    currentApp,
    customerDetails,
    customerDetailsLoading,
    customerJourney,
    customerOrderValuesLoading,
    customerStatus,
    customerStatusLoading,
    dtUtils,
    getCustomerDetails,
    getCustomerStatus,
    getStoreHeaders,
    isFlipdishStaff,
    location,
    resetCustomerOrderValues,
    setCustomerStatus,
    storeHeaders,
    match: {
      params: { customerId },
    },
  } = props;
  const { name, phoneNumber } = customerDetails;
  const { CardOrdersEnabled, CashOrdersEnabled, MarketingEnabled } = customerStatus;

  const [marketingModalOpen, setMarketingModalOpen] = useState<boolean>(false);
  const [suspiciousActivityModalOpen, setSuspiciousActivityModalOpen] = useState<boolean>(false);

  const storeParams = useMemo(() => getStoreParams(location), [location]);

  useEffect(() => {
    return () => {
      resetCustomerOrderValues();
    };
  }, []);

  useEffect(() => {
    getCustomerDetails();
    getCustomerStatus();
    getStoreHeaders();
  }, []);

  const theme = useTheme();
  const isMobileOrTablet = !useMediaQuery(theme.breakpoints.up('md'));

  const timeLine: FormattedTimeLine = useMemo(
    () => formattedTimeLine(customerJourney, dtUtils),
    [customerJourney]
  );

  const handleCloseModal = (modal: 'marketing' | 'suspicious') => {
    if (modal === 'marketing') {
      setMarketingModalOpen(!marketingModalOpen);
    } else if (modal === 'suspicious') {
      setSuspiciousActivityModalOpen(!SuspiciousActivityModal);
    }
  };
  const handleSaveMarketing = () => {
    setCustomerStatus({ ...customerStatus, MarketingEnabled: !MarketingEnabled });
    setMarketingModalOpen(!marketingModalOpen);
  };

  const handleSaveSuspicious = ({ isCardEnabled, isCashEnabled }: CustomerStatus) => {
    if (CardOrdersEnabled === isCardEnabled && CashOrdersEnabled === isCashEnabled) {
      setSuspiciousActivityModalOpen(!suspiciousActivityModalOpen);
      return;
    } else {
      setCustomerStatus({
        ...customerStatus,
        CardOrdersEnabled: isCardEnabled,
        CashOrdersEnabled: isCashEnabled,
      });
    }
    setSuspiciousActivityModalOpen(!suspiciousActivityModalOpen);
  };

  return (
    <PageLayout
      documentTitle={'Customers'}
      title={name ? name : phoneNumber}
      toParent={`/${currentApp.AppId}/customers`}
    >
      <Grid container>
        <Grid item md={8} xs={12} style={{ paddingRight: isMobileOrTablet ? 0 : 24 }}>
          {isMobileOrTablet && (
            <div style={{ marginBottom: 0, padding: 16 }}>
              <StoreFilterInfiniteScroll isMulti />
            </div>
          )}
          <Box pl={isMobileOrTablet ? 2 : 0} pb={2}>
            <Typography variant="h5">
              <Translate id="Overview" />
            </Typography>
          </Box>
          <MainCard
            activeLanguage={activeLanguage}
            customerId={customerId}
            loading={customerOrderValuesLoading}
            storeFilter={storeParams}
          />

          {!isMobileOrTablet && (
            <>
              <Box pb={2}>
                <Typography variant="h5">
                  <Translate id="Journey" />
                </Typography>
              </Box>
              <TimeLine customerTimeLine={timeLine} loading={customerOrderValuesLoading} />
            </>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          {!isMobileOrTablet && (
            <div style={{ marginBottom: 24, marginTop: 40, padding: 0 }}>
              <StoreFilterInfiniteScroll isMulti />
            </div>
          )}
          <SideCard
            customerDetails={customerDetails}
            customerDetailsLoading={customerDetailsLoading}
            customerStatus={customerStatus}
            customerStatusLoading={customerStatusLoading}
            isFlipdishStaff={isFlipdishStaff}
            marketingModalOpen={marketingModalOpen}
            setMarketingModalOpen={setMarketingModalOpen}
            suspiciousActivityModalOpen={suspiciousActivityModalOpen}
            setSuspiciousActivityModalOpen={setSuspiciousActivityModalOpen}
          />
          {isMobileOrTablet && (
            <>
              <TimeLine customerTimeLine={timeLine} loading={customerOrderValuesLoading} />
              <CustomerDetailsTable
                stores={storeHeaders}
                storeFilter={storeParams}
                isFlipdishStaff={isFlipdishStaff}
              />
            </>
          )}
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12}>
            <CustomerDetailsTable
              stores={storeHeaders}
              storeFilter={storeParams}
              isFlipdishStaff={isFlipdishStaff}
            />
          </Grid>
        )}
      </Grid>
      {marketingModalOpen && (
        <MarketingModal
          onCancel={handleCloseModal}
          onContinue={handleSaveMarketing}
          open={marketingModalOpen}
        />
      )}
      {suspiciousActivityModalOpen && (
        <SuspiciousActivityModal
          CardOrdersEnabled={CardOrdersEnabled}
          CashOrdersEnabled={CashOrdersEnabled}
          onCancel={handleCloseModal}
          onContinue={handleSaveSuspicious}
          open={suspiciousActivityModalOpen}
        />
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: OuterProps) => {
  const { customerId } = ownProps.match.params;
  return {
    getCustomerDetails: () => dispatch(getCustomerDetail(customerId)),
    getCustomerStatus: () => dispatch(getCustomerStatus(customerId)),
    getStoreHeaders: () => dispatch(getStoreHeaderList()),
    resetCustomerOrderValues: () => dispatch(resetCustomerOrderValues()),
    setCustomerStatus: (updatedCustomer: CustomerUpdateModel) =>
      dispatch(setCustomerStatus(customerId, updatedCustomer)),
  };
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['FlipdishStaff']);
  return (state: AppState) => {
    return {
      activeLanguage: getActiveLanguage(state.locale),
      customerDetails: getCustomerDetailsSelector(state),
      customerDetailsLoading: customerDetailsLoadingSelector(state),
      customerOrderValuesLoading: customerOrderLoadingSelector(state),
      customerJourney: getCustomerJourneySelector(state),
      customerStatus: customerStatusSelector(state),
      customerStatusLoading: customerStatusLoadingSelector(state),
      currentApp: state.currentApp,
      dtUtils: dateTimeUtils(state),
      isFlipdishStaff: getPermissionsSelector(state),
      storeHeaders: getStoreHeadersList(state),
    };
  };
};
export default compose<InnerProps, OuterProps>(connect(mapStateToPropsFactory, mapDispatchToProps))(
  CustomerDetailsPage
);
