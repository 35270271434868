import React from 'react';

import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, Typography } from '@fd/ui/atoms';
import { Dialog } from '@fd/ui/molecules/Dialog';

export type CannotRemoveModifierDialogProps = {
  onClose: () => void;
  open: boolean;
  translate: TranslateFunction;
};

export const CannotRemoveModifierDialog = (props: CannotRemoveModifierDialogProps): JSX.Element => {
  const { onClose, open, translate } = props;

  return (
    <Dialog
      title={translate('Cannot_remove_modifier') as string}
      open={open}
      actions={[
        <Button key="ok-button" fdKey="ok-button" onClick={() => onClose()}>
          <Translate id="OK" />
        </Button>,
      ]}
    >
      <Typography variant="caption">
        <Translate id="Cannot_remove_modifiers_message" />
      </Typography>
    </Dialog>
  );
};
