import React, { ChangeEvent } from 'react';

import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';
import { type FieldProps, type FieldValidator, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../helpers/validation';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import TextField from '../../../../ui/TextField/TextField';
import FieldWrapper from '../../../Finance/Banking/components/fields/FieldWrapper';

const legalFields: Array<{
  label: TranslationId;
  name: keyof TermsAndPolicyData;
  validate?: FieldValidator;
}> = [
  { label: 'Terms_and_Conditions', name: 'TermsAndConditionsUrl' },
  { label: 'Privacy_Policy', name: 'PrivacyPolicyUrl' },
  { label: 'Cookie_policy', name: 'CookiePolicyUrl' },
];

const validate = (value: string) => {
  return formikValidate.required(value);
};

const PolicyDocuments = () => {
  return (
    <FormItemLayout
      bottomBorder
      label={<Translate id="Policy_documents" />}
      description={<Translate id="Policy_documents_description" />}
    >
      {legalFields.map(({ label, name }, idx) => {
        return (
          <FieldWrapper key={name}>
            <Field name={name} validate={(value: string) => validate(value)}>
              {({ field, form }: FieldProps) => {
                const { errors, touched, isSubmitting } = form;
                const fieldError = errors[field.name] as string | undefined;
                const showError = !!fieldError && (touched[field.name] as boolean | undefined);

                return (
                  <TextField
                    {...field}
                    fdKey={name}
                    autoComplete="off"
                    fullWidth
                    disabled={isSubmitting}
                    error={showError}
                    helperText={showError ? fieldError : undefined}
                    FormHelperTextProps={{
                      children: ' ', // reserves line height for error message
                      style: { textAlign: 'right' },
                    }}
                    label={<Translate id={label} />}
                    InputProps={{ inputProps: { style: { height: '1.1876em' } } }}
                    InputLabelProps={{
                      shrink: true,
                      style: { lineHeight: 1, overflow: 'visible' },
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                  />
                );
              }}
            </Field>
          </FieldWrapper>
        );
      })}
    </FormItemLayout>
  );
};

export default PolicyDocuments;
