import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { type FieldProps, Field } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

import { addressService } from '../../../../../services/address.service';
import { CountrySelector } from '../../../../DynamicAddress/Components/CountrySelector/CountrySelector';

const useStyles = makeStyles(() => ({
  label: { paddingTop: 0 },
}));

const SettingsCountrySelector = () => {
  const language = useSelector((state: AppState) => state.locale.activeLanguage);
  const translate = useSelector((state: AppState) => getTranslate(state));
  const classes = useStyles();

  const { data, isPending } = useQuery({
    queryKey: [addressService.getCountriesQueryKey, language],
    queryFn: () => addressService.getCountries(language),
  });

  return (
    <FormItemLayout
      label={translate('Country')}
      removeRootPaddings
      alignItems="center"
      labelClass={classes.label}
    >
      <Field name={'countryCode'}>
        {({ field, form }: FieldProps) => {
          return (
            <CountrySelector
              loading={isPending}
              translate={translate}
              disabled={isPending || form.isSubmitting}
              hideLabel
              countries={
                data?.map((country) => ({
                  label: country?.Label,
                  value: country?.Value,
                })) || []
              }
              countryCode={field.value}
              handleChange={(value) => form.setFieldValue('countryCode', value)}
              textFieldStyle={{ paddingRight: '16px' }}
            />
          );
        }}
      </Field>
    </FormItemLayout>
  );
};

export default SettingsCountrySelector;
