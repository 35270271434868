import React, { useEffect } from 'react';

import { OrderDeliveryInformationBase } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import * as ordersActions from '../../../actions/order.action';
import { OrderDeliveryInfoSkeleton } from './OrderDetailsLoading';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(1),
    padding: '0',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: { margin: '24px 16px 32px 16px' },
  },
  deliveryInfoText: {
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  button: {
    padding: 0,
    overflowWrap: 'break-word',
    lineHeight: '1.43',
    fontWeight: 'normal',
    justifyContent: 'left',
    textTransform: 'initial',
    textAlign: 'left',
    userSelect: 'text',
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '8px',
  },
  errorText: {
    color: '#ff395f',
    overflowWrap: 'break-word',
  },
  successText: {
    color: '#25c974',
  },
  pendingText: {
    color: '#05149e',
  },
  paperNotes: {
    padding: '20px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    backgroundColor: '#ecf4fe',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid rgba(0, 0, 0, 0.6)',
      padding: '16px',
    },
  },
  errorNotes: {
    padding: '20px',
    textAlign: 'left',
    color: theme.palette.error.main,
    boxShadow: 'none',
    backgroundColor: '#FF486B33',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid rgba(0, 0, 0, 0.6)',
      padding: '16px',
    },
  },
}));

type InnerProps = MappedState & MappedDispatch;

type OuterProps = {
  orderId: number;
};

type Props = InnerProps & OuterProps;

const OrderDeliveryInfo = (props: Props) => {
  const {
    orderId,
    getDeliveryInfoForOrderById,
    orderDeliveryInfo,
    orderDeliveryFailed,
    orderDeliveryInfoLoading,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    getDeliveryInfoForOrderById(orderId);
  }, [orderId]);

  if (orderDeliveryFailed || (!orderDeliveryInfoLoading && !orderDeliveryInfo?.Status)) {
    return null;
  }

  const deliveryStatusTextClasses = (
    status: OrderDeliveryInformationBase.StatusEnum,
    classes: ReturnType<typeof useStyles>
  ) => {
    return clsx({
      [classes.successText]: status === OrderDeliveryInformationBase.StatusEnum.Delivered,
      [classes.pendingText]:
        status === OrderDeliveryInformationBase.StatusEnum.Accepted ||
        status === OrderDeliveryInformationBase.StatusEnum.Carrying ||
        status === OrderDeliveryInformationBase.StatusEnum.OnTheWay ||
        status === OrderDeliveryInformationBase.StatusEnum.ArrivedAtLocation ||
        status === OrderDeliveryInformationBase.StatusEnum.None ||
        status === OrderDeliveryInformationBase.StatusEnum.Unaccepted ||
        status === OrderDeliveryInformationBase.StatusEnum.Unassigned,
      [classes.errorText]: status === OrderDeliveryInformationBase.StatusEnum.CannotDeliver,
    });
  };

  return orderDeliveryInfo?.Status ? (
    <Paper square className={classes.paper}>
      <Typography variant="caption" className={classes.caption}>
        <Translate id="Delivery_info" />
      </Typography>

      <Typography className={classes.deliveryInfoText}>
        <Translate id="Status" />:{' '}
        <Typography
          component="span"
          className={deliveryStatusTextClasses(orderDeliveryInfo.Status, classes)}
        >
          {orderDeliveryInfo.Status}
        </Typography>
      </Typography>

      {orderDeliveryInfo.ErrorMessage && (
        <Paper square className={classes.errorNotes}>
          <Typography className={classes.errorText}>{orderDeliveryInfo.ErrorMessage}</Typography>
        </Paper>
      )}

      <Typography className={classes.deliveryInfoText}>
        <Translate id="Provider" />: {orderDeliveryInfo.IntegrationName}
      </Typography>

      {orderDeliveryInfo.ExternalReferenceId && (
        <Typography className={classes.deliveryInfoText}>
          <Translate id="Reference" />: {orderDeliveryInfo.ExternalReferenceId}
        </Typography>
      )}

      {orderDeliveryInfo.DeliveryStatusNotes && (
        <Paper square className={classes.paperNotes}>
          <Typography variant="caption" className={classes.caption}>
            <Translate id="Driver_note" />
          </Typography>
          <Typography className={classes.deliveryInfoText}>
            {orderDeliveryInfo.DeliveryStatusNotes}
          </Typography>
        </Paper>
      )}

      {orderDeliveryInfo.TrackUrl && (
        <Button
          href={orderDeliveryInfo?.TrackUrl}
          target="_blank"
          rel="noreferrer noopener"
          color="primary"
          classes={{ root: classes.button }}
        >
          <Translate id="Tracking_link" />
        </Button>
      )}
    </Paper>
  ) : (
    <OrderDeliveryInfoSkeleton />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { orders } = state;
  return {
    orderDeliveryInfo: orders.orderDeliveryInfo,
    orderDeliveryFailed: orders.orderDeliveryInfoFailed,
    orderDeliveryInfoLoading: orders.orderDeliveryInfoLoading,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getDeliveryInfoForOrderById: (id: number) =>
    dispatch(ordersActions.getDeliveryInfoForOrderById(id)),
});

const EnhancedComponent = compose<Props, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderDeliveryInfo);

export default EnhancedComponent;
