import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MenusIcon from '@mui/icons-material/FastfoodOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StoresIcon from '@mui/icons-material/StoreOutlined';

import WebsiteSettingsIcon from '../../../../assets/images/website-settings.svg';

export const SectionLogo = ({ name }) => {
  switch (name) {
    case 'customize_your_store_title':
      return <StoresIcon />;
    case 'setup_services_title':
      return <AccessTimeIcon />;
    case 'create_your_menu_title':
      return <MenusIcon />;
    case 'get_paid_title':
      return <AttachMoneyIcon />;
    case 'test_web_site_and_order_title':
      return <img src={WebsiteSettingsIcon} />;
    case 'look_around_title':
      return <InfoOutlinedIcon />;
    default:
      return null;
  }
};

AttachMoneyIcon;
