import { TableColumns } from '../../Finance/Payouts/types';

export const upcomingInvoiceTableMeta: TableColumns[] = [
  {
    columnName: 'Product',
    translationId: 'Description',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'Qty',
    translationId: 'Qty',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'UnitPrice',
    translationId: 'Unit_price',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'AmountIncTax',
    translationId: 'Amount_inc_tax',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
];

export const upcomingInvoiceNoTaxTableMeta: TableColumns[] = [
  {
    columnName: 'Product',
    translationId: 'Description',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'Qty',
    translationId: 'Qty',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'UnitPrice',
    translationId: 'Unit_price',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
  {
    columnName: 'Amount',
    translationId: 'Amount',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
];

export const upcomingInvoiceNoTaxColumns = ['Product', 'Qty', 'UnitPrice', 'Amount'];

export const upcomingInvoiceTaxColumns = ['Product', 'Qty', 'UnitPrice', 'Tax', 'AmountIncTax'];
