import React, { useEffect, useMemo, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import isArray from 'lodash/isArray';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import Select from '../../../../ui/Select/Select';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../../../WithRouteSearchParams';

const useStyles = makeStyles(() => ({
  select: {
    zIndex: 5,
  },
}));

type Option = {
  value: 'Android' | 'Ios' | 'Kiosk' | 'PwaAndroid' | 'PwaIos' | 'Web';
  label: string;
};

type InnerProps = MappedState & WithRouteSearchParamsProps<Option['value'][] | Option['value']>;
type OuterProps = {
  onChange: (selectedItem: Option['value'][]) => void;
};
type Props = InnerProps & OuterProps;

const PlatformFilter = (props: Props) => {
  const classes = useStyles();
  const { translate, setSearch, onChange, search } = props;
  const [selectedOptions, setSelectedOptions] = useState<Option[]>();

  useEffect(() => {
    if (search) {
      if (isArray(search)) {
        const defaultOptions = search
          .map((platform) => options.find((o) => o.value === platform))
          .filter((option) => {
            return option !== undefined;
          }) as Option[];

        handleChange(defaultOptions);
      } else {
        const defaultOption = options.find((o) => o.value === search);
        if (defaultOption) {
          handleChange([defaultOption]);
        }
      }
    }
  }, []);

  const setUrlParams = (type: Option['value'][]) => {
    setSearch(type);
  };

  const handleChange = (values: Option[]) => {
    setSelectedOptions(values);

    const items = values.map((v) => v.value);
    setUrlParams(items);
    onChange(items);
  };

  const options = useMemo(
    () =>
      [
        { value: 'Ios', label: translate('Ios') as TranslationId },
        { value: 'Android', label: translate('Android') as TranslationId },
        { value: 'Web', label: translate('Web') as TranslationId },
        { value: 'Kiosk', label: translate('Kiosk') as TranslationId },
        // { value: 'Pos', label: translate('Pos') as TranslationId }, // THIS IS NOT USED FOR NOW
        // { value: 'TelephoneCall', label: translate('TelephoneCall') as TranslationId }, // THIS IS NOT USED FOR NOW
        // { value: 'Sms', label: translate('Sms') as TranslationId }, // THIS IS NOT USED FOR NOW
        { value: 'PwaAndroid', label: translate('PwaAndroid') as TranslationId },
        { value: 'PwaIos', label: translate('PwaIos') as TranslationId },
      ] as Option[],
    [translate]
  );

  return (
    <ErrorBoundary identifier="platform-filter">
      <Select
        className={classes.select}
        dataFd={'platform-filter'}
        isClearable
        isMulti
        onChange={handleChange}
        options={options}
        placeholder={translate('All') as TranslationId}
        TextFieldProps={{
          fdKey: 'platform-dropdown',
          label: translate('Platform') as TranslationId,
          name: 'platform-filter',
        }}
        value={selectedOptions}
      />
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'platform',
  }),
  connect(mapStateToProps)
)(PlatformFilter);
