import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { LoadingButton } from '../../../ui/LoadingButton';

const useStyles = makeStyles({
  addPadding: { paddingTop: '24px', paddingBottom: '16px', paddingRight: '24px' },
  root: { paddingTop: '24px', paddingBottom: '0px' },
});

type Props = {
  id: string;
  setDialogOpen: (state: boolean) => void;
  isDialogOpen: boolean;
  submit: () => void;
  isLoading: boolean | undefined;
};

export default function UnregisterDeviceModal({
  isDialogOpen,
  isLoading,
  setDialogOpen,
  submit,
  id,
}: Props) {
  const classes = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle classes={{ root: classes.root }}>
        <Translate id="Unregister_device_title" />
      </DialogTitle>

      <DialogContent key="dialog-content" style={{ maxWidth: '280px' }}>
        <Typography variant="subtitle1" color="textSecondary">
          <Translate id="Unregister_device_description" data={{ id }} />
        </Typography>
      </DialogContent>
      <DialogActions key="actions" className={classes.addPadding}>
        <Button
          color="primary"
          data-fd="unregister_device_cancel"
          onClick={() => setDialogOpen(false)}
        >
          <Translate id="Cancel" />
        </Button>
        <LoadingButton
          color="secondary"
          fdKey="unregister_device_confirm"
          onClick={() => {
            submit();
            setDialogOpen(false);
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          <Translate id="Unregister" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
