import {
  BankAccountApiFactory,
  BankAccountCreate,
  BankAccountDetailsUpdateRequest,
  BankAccountSummary,
  StoresApiFactory,
  StripeAccountLinkRequest,
  StripeCustomConnectApiFactory,
} from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const bankingApi = createApi(BankAccountApiFactory);
const stripeApi = createApi(StripeCustomConnectApiFactory);

// #region load
export async function getBankAccounts(appId: string) {
  try {
    const incomingMessage = await bankingApi.getBankAccounts(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region loadDetails

export async function getBankAccountById(appId: string, accountId: number) {
  try {
    const incomingMessage = await bankingApi.getBankAccountById(accountId, appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region createBankAccount
export async function createBankAccount(appId: string, account: BankAccountCreate) {
  try {
    const incomingMessage = await bankingApi.createBankAccount(appId, account);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getFieldDefinitions
export async function getFieldDefinitions(appId: string) {
  try {
    const incomingMessage = await bankingApi.getFieldDefinitions(appId);
    return incomingMessage.Data.DefinitionsPerCountry;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getFieldDefinitions
export async function getCountriesWithFieldDefinitions(appId: string) {
  try {
    const incomingMessage = await bankingApi.getCountriesWithFieldDefinitions(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region  updateBankAccount
export async function updateBankAccount(
  appId: string,
  id: number,
  account: BankAccountDetailsUpdateRequest
) {
  try {
    const incomingMessage = await stripeApi.updateBankAccountDetails(appId, id, account);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region createBankAccountAndConnectedAccount
export async function createBankAccountAndConnectedAccount(
  appId: string,
  account: BankAccountCreate
) {
  try {
    const incomingMessage = await stripeApi.createBankAccountAndConnectedAccount(appId, account);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region setBankAccountBusinessType
export async function setBankAccountBusinessType(
  appId: string,
  account: {
    BankAccountId: number;
    BusinessType: BankAccountSummary.BusinessTypeEnum;
  }
) {
  try {
    const incomingMessage = await stripeApi.setBankAccountBusinessType(
      appId,
      account.BankAccountId!,
      account.BusinessType!.toString()
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region createStripeConnectedAccountLink
export async function createStripeConnectedAccountLink(
  appId: string,
  stripeAccountLinkRequest: StripeAccountLinkRequest
) {
  try {
    const incomingMessage = await stripeApi.createStripeConnectedAccountLink(
      appId,
      stripeAccountLinkRequest.StripeId!,
      stripeAccountLinkRequest
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region deleteBankAccount
export async function removeBankAccount(appId: string, accountId: number) {
  try {
    await bankingApi.deleteBankAccount(appId, accountId);
    return;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region attachBankAccountToStore
export async function attachBankAccountToStore(appId: string, accountId: number, storeId: number) {
  try {
    await bankingApi.attachBankAccountToStore(appId, accountId, storeId);
    return;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region updateBankAccountState
export async function updateBankAccountState(
  appId: string,
  accountId: number,
  state: string,
  reason: string
) {
  try {
    await bankingApi.updateBankAccountState(appId, accountId, state, reason);
    return;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region get stores
const storesApi = createApi(StoresApiFactory);

export type GetStoresByAppIdProps = {
  appId: string;
  query?: string;
  salesChannelType?: any;
  page?: number;
  limit?: number;
};

export async function getStoresByAppId(props: GetStoresByAppIdProps) {
  try {
    const { appId, query, salesChannelType, page, limit } = props;
    const incomingMessage = await storesApi.getStoresByAppId(
      appId,
      query,
      salesChannelType,
      page,
      limit
    );
    return incomingMessage;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region updatePayoutSchedule
export async function updatePayoutSchedule(
  appId: string,
  stripeConnectedAccountId: string,
  interval: string
) {
  try {
    await stripeApi.updatePayoutSchedule(appId, stripeConnectedAccountId, interval);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

export const bankingServices = {
  attachBankAccountToStore,
  createBankAccount,
  createBankAccountAndConnectedAccount,
  createStripeConnectedAccountLink,
  getBankAccountById,
  getBankAccounts,
  getCountriesWithFieldDefinitions,
  getFieldDefinitions,
  getStoresByAppId,
  removeBankAccount,
  setBankAccountBusinessType,
  updateBankAccount,
  updateBankAccountState,
  updatePayoutSchedule,
};
