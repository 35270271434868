import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { usePagination } from '../../../custom-hooks/useFilterablePagination';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import {
  createAccessToken,
  getOAuthAppAuthTokens,
  getOAuthAppById,
  revokeAccessToken,
} from '../actions';
import OAuthAppAccessTokenDialog from '../components/OAuthAppAccessTokenDialog';
import OAuthAppAuthTokens from '../components/OAuthAppAuthTokens';
import OAuthAppAuthTokensMobile from '../components/OAuthAppAuthTokensMobile';
import OAuthAppCardLoading from '../components/OAuthAppCardLoading';
import OAuthAppDeleteItemDialog from '../components/OAuthAppDeleteItemDialog';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(2),
    color: 'white',
    backgroundColor: '#ff0046',
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
  buttonProgress: {
    color: '#3949ab',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    width: '100%',
  },
}));

type OAuthAppAuthTokensProps = {
  page: number;
  count: number;
  rowsPerPage: number;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OAuthAppAuthTokensProps &
  RouteComponentProps;

const rowPerPageOptions: number[] = [25, 50, 100, 200];

const TokenList = (props: Props) => {
  const {
    oauthApp,
    authTokens,
    translate,
    loadOAuthApp,
    loadAuthTokens,
    isLoading,
    createAccessToken,
    revokeAccessToken,
    history,
    newAuthToken,
    currentApp,
    count,
  } = props;

  const classes = useStyles();
  const isFirstRender = useRef(true);
  const { params, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    usePagination(history);

  useEffect(() => {
    loadOAuthApp();
    loadAuthTokens(params);
  }, []);

  useEffect(() => {
    setDeleteDialogOpen(false);
  }, [authTokens]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (newAuthToken) {
        setCreateDialogOpen(true);
      }
    }
  }, [newAuthToken]);

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const [selectedTokenKey, setSelectedTokenKey] = useState<string | undefined>();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    loadAuthTokens({
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/developers/${oauthApp.OauthAppId}`}
      title={translate('Authorization_tokens')}
      documentTitle="Authorization_tokens"
      caption={oauthApp.OauthAppName}
      actions={() => (
        <Fab
          disabled={isLoading}
          variant="extended"
          color="secondary"
          aria-label={translate('Request_token') as string}
          className={classes.button}
          onClick={() => createAccessToken()}
          data-fd="auth-token-request"
        >
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          <AddIcon />
          {translate('Request_token')}
        </Fab>
      )}
    >
      <PaperContainer fluid>
        {!authTokens.length ? (
          isLoading ? (
            <OAuthAppCardLoading />
          ) : (
            <EmptyComponent
              title="Empty_auth_tokens_list_header"
              subtitle="Empty_auth_tokens_list_subheader"
            />
          )
        ) : (
          <>
            <Hidden mdDown>
              <OAuthAppAuthTokens
                authTokens={authTokens}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                setSelectedTokenKey={setSelectedTokenKey}
                rowPerPageOptions={rowPerPageOptions}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                setDialogOpen={setDeleteDialogOpen}
              />
            </Hidden>

            <Hidden mdUp>
              <OAuthAppAuthTokensMobile
                authTokens={authTokens}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                setSelectedTokenKey={setSelectedTokenKey}
                rowPerPageOptions={rowPerPageOptions}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                setDialogOpen={setDeleteDialogOpen}
              />
            </Hidden>

            <OAuthAppAccessTokenDialog
              setDialogOpen={setCreateDialogOpen}
              isDialogOpen={isCreateDialogOpen}
              accessToken={newAuthToken as string}
            />

            <OAuthAppDeleteItemDialog
              setDialogOpen={setDeleteDialogOpen}
              isDialogOpen={isDeleteDialogOpen}
              submit={() => revokeAccessToken(selectedTokenKey)}
              isLoading={isLoading}
              title="Delete_access_token"
              description="Confirm_access_token_deletion"
              submitText="Revoke"
            />
          </>
        )}
      </PaperContainer>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  const {
    match: {
      params: { OAuthAppId },
    },
  } = ownProps;
  return {
    loadAuthTokens: (params) => {
      dispatch(getOAuthAppAuthTokens(OAuthAppId, params));
    },
    loadOAuthApp: () => {
      dispatch(getOAuthAppById(OAuthAppId));
    },
    createAccessToken: () => {
      dispatch(createAccessToken(OAuthAppId));
    },
    revokeAccessToken: (key) => {
      dispatch(revokeAccessToken(key, OAuthAppId));
    },
  };
};

const mapStateToProps = (state: AppState) => {
  const {
    developers: { oauthApp, authTokens, isLoading, newAuthToken },
    locale,
  } = state;

  return {
    count: authTokens.TotalRecordCount,
    oauthApp,
    currentApp: state.currentApp,
    authTokens: authTokens.Data,
    isLoading,
    translate: getTranslate(locale),
    newAuthToken,
  };
};

export default compose<Props, OAuthAppAuthTokensProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TokenList);
