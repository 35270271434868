import React, { useMemo } from 'react';

import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikBag, type FormikProps, Form, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { dictionary } from '../../../helpers/dictionary';
import { NotifyProps, SAVED_KEY } from '../../../layouts/Notify/actions';
import { FIELDS } from '../../../models/fields';
import FkPhoneNumber from '../../../ui/FkPhoneNumber/FkPhoneNumber';
import FkSelect from '../../../ui/FkSelect/FkSelect';
import FormItemLayout from '../../../ui/Forms/FormItemLayout';
import LoadingButton from '../../../ui/LoadingButton';
import InfoIcon from '../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../ui/Tooltip/Tooltip';
import SmsTable from './SmsTable';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    marginBottom: 10,
    marginLeft: 26,
  },
  formContainer: {
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 26,
  },
  tableContainer: {
    paddingLeft: 26,
    paddingRight: 16,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formHeader: {
    paddingTop: 24,
    display: 'inline-block',
  },
  formHeaderHelpText: {
    marginBottom: 18,
  },
  summaryRoot: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  tooltip: {
    backgroundColor: '#F8F8F8',
    cursor: 'pointer',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
  },
  icon: {
    display: 'inline',
    marginLeft: 6,
  },
}));

export const getDefaultFormState = (StoreId) => {
  return {
    SmsContact: '',
    SmsWhen: 0,
    StoreId: StoreId || null,
  };
};

export type FormValues = ReturnType<typeof getDefaultFormState>;
type InnerProps = {};
type OuterProps = {
  deleteNotification: (
    storeId: number,
    details: StoreOrderNotificationContactDetails
  ) => Promise<void>;
  smsOrderNotifications: StoreOrderNotificationContactDetails[];
  submit: (storeId: number, details: StoreOrderNotificationContactDetails) => Promise<void>;
  StoreId?: number;
  translate: TranslateFunction;
  onRemove: (id: any) => void;
  notify: (props: NotifyProps) => void;
};
type Props = InnerProps & OuterProps;

const SmsOrderNotificationsForm = ({
  handleSubmit,
  isSubmitting,
  isValid,
  smsOrderNotifications,
  translate,
  onRemove,
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles();
  const translatedOptions = useMemo(() => dictionary.getEmailAndPhoneNumberOptions(translate), []);
  return (
    <>
      <Grid item xs={12} className={classes.headerContainer}>
        <Typography
          variant="h5"
          className={classes.formHeader}
          aria-describedby="Notify_by_SMS_tooltip"
        >
          <Translate id="Notify_me_of_orders_SMS" />
          <Tooltip
            fdKey="sms-notifications-tooltip"
            titleId="Notify_by_SMS"
            messageId="Notify_by_SMS_tooltip"
            id="Notify_by_SMS_tooltip"
          >
            <div className={classes.icon}>
              <InfoIcon size={24} />
            </div>
          </Tooltip>
        </Typography>
        <FormHelperText className={classes.formHeaderHelpText}>
          <Translate id="Please_note_that_there_is_a_charge" />
        </FormHelperText>
        <Divider />
      </Grid>

      <Form style={{ width: '100%' }}>
        <Grid container className={classes.formContainer}>
          <Grid item xs={12} style={{ paddingRight: 16 }}>
            <FormItemLayout label={<Translate id="Add_phone_number" />} removeRootPaddings={true}>
              <FkPhoneNumber
                {...FIELDS['NotificationPhoneNumber']}
                name={nameof<FormValues>('SmsContact')}
                fdKey="phone-number-text"
              />
            </FormItemLayout>

            <Hidden mdDown={true}>
              <Divider />
            </Hidden>
          </Grid>
          <Grid item xs={12} style={{ paddingRight: 16 }}>
            <FormItemLayout label={<Translate id="When" />} removeRootPaddings={true}>
              <FkSelect
                {...FIELDS['NotificationPhoneNumberWhen']}
                name={nameof<FormValues>('SmsWhen')}
                fdKey="sms-duration-dropdown"
                options={translatedOptions}
                variant="standard"
              />
            </FormItemLayout>
          </Grid>
          <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
            <Grid item>
              <LoadingButton
                fdKey="Sms_save_btn"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                disabled={!isValid}
                style={{ marginTop: 16, marginRight: 16, width: 130 }}
              >
                <Translate id="Add" />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      {smsOrderNotifications.length ? (
        <Grid className={classes.tableContainer}>
          <SmsTable onRemove={onRemove} smsOrderNotifications={smsOrderNotifications} />
        </Grid>
      ) : null}
    </>
  );
};

export default withFormik<Props, FormValues>({
  displayName: 'SmsOrderNotificationsForm',
  enableReinitialize: true,
  mapPropsToValues: ({ StoreId }: Props) => getDefaultFormState(StoreId),
  handleSubmit: (values, formikBag: FormikBag<Props, FormValues>) => {
    const orderNotificationDetails: StoreOrderNotificationContactDetails = {
      StoreId: values.StoreId,
      ContactType: StoreOrderNotificationContactDetails.ContactTypeEnum.Sms,
      ContactDetail: values.SmsContact.replace(/ /g, ''),
      SecondsUntilNotification: values.SmsWhen,
    };

    formikBag.props
      .submit(values.StoreId, orderNotificationDetails)
      .then((res) => {
        formikBag.setSubmitting(false);
        const { notify, translate } = formikBag.props;
        formikBag.resetForm();

        notify({
          key: SAVED_KEY,
          message: translate('Phone_number_added') as string,
          variant: 'success',
        });
        return res;
      })
      .catch((e) => console.error(e));
  },
})(SmsOrderNotificationsForm);
