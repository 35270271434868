import React from 'react';

import { CURRENCY_SYMBOL_OVERRIDES, ZERO_DECIMAL_CURRENCIES } from './currencyConstants';
import { formatNumber, FormattedNumber, FormattedNumberProps } from './FormattedNumber';

type Args = Parameters<typeof Intl.NumberFormat>;
export type FormatCurrencyOptions = Intl.NumberFormatOptions & { currency: string };
/**
 * Currency formatting
 * @param value number to format
 * @param locales to which locale to format to
 * @param options formatting options
 *
 * @example
 * formatCurrency(1000, ['en-IE', 'en'], { currency: 'EUR' })
 */
export function formatCurrency(value: number, locales: Args[0], options: FormatCurrencyOptions) {
  if (ZERO_DECIMAL_CURRENCIES.includes(options.currency)) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  }

  let formatted = formatNumber(value, locales, { style: 'currency', ...options });

  if (CURRENCY_SYMBOL_OVERRIDES[options.currency]) {
    formatted = CURRENCY_SYMBOL_OVERRIDES[options.currency](formatted);
  }

  return formatted;
}

export type FormattedCurrencyProps = {
  currency: string;
} & FormattedNumberProps;
/**
 * Currency formatting
 * @example
 * <FormattedCurrency
 *   value={1000}
 *   locales={['en-IE', 'en']}
 *   currency="EUR"
 * />
 *
 * // OR
 * <FormattedCurrency>
 *   {(formattedValue)=> (<>Show me the moneeeyyyyy {formattedValue}</>)}
 * </FormattedCurrency>
 */
const FormattedCurrency: React.FC<FormattedCurrencyProps> = (props) => {
  return <FormattedNumber {...props} />;
};

FormattedCurrency.defaultProps = {
  style: 'currency',
};

export { FormattedCurrency };
