import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { SkeletonLoader } from '@fd/ui/atoms';

import { Typography } from '../../atoms/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    padding: theme.spacing(3),
    width: '100%',
  },
  description: {
    color: theme.palette.grey[700],
    fontSize: '12px',
    lineHeight: '16px',
    whiteSpace: 'pre-line',
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '18px',
  },
  titleContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  blueCard: {
    backgroundColor: '#EAF2FF',
    border: 'none',
  },
}));

export type DescriptiveCardProps = {
  banner?: React.ReactElement;
  children?: React.ReactNode;
  description?: TranslationId;
  isLoading?: boolean;
  subtitle?: TranslationId;
  title?: TranslationId;
  showBlueCard?: boolean;
  fdKey?: string;
};

export const DescriptiveCard = (props: DescriptiveCardProps): JSX.Element => {
  const {
    banner,
    children,
    description,
    isLoading = false,
    subtitle,
    title,
    showBlueCard,
    fdKey,
  } = props;

  const classes = useStyles();

  return isLoading ? (
    <div className={classes.container}>
      <SkeletonLoader
        fdKey="descriptive-card-loader"
        rows={[
          { height: '112px', width: '100%' },
          { height: '24px', width: '50%', className: classes.titleContainer },
          { height: '20px', width: '100%' },
          { height: '20px', width: '100%' },
        ]}
      />
    </div>
  ) : (
    <div className={clsx(classes.container, showBlueCard ? classes.blueCard : '')} data-fd={fdKey}>
      {banner}
      {title && (
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h3">
            <Translate id={title} />
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle} variant="subtitle1">
              <Translate id={subtitle} />
            </Typography>
          )}
        </div>
      )}
      {description && (
        <div>
          <Typography className={classes.description} variant="body2">
            <Translate id={description} />
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
};
