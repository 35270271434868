import debounce from 'lodash/debounce';
import memoizeOne from 'memoize-one';
import { Omit, OptionsObject } from 'notistack';
import {
  getTranslate,
  Options as TranslateOptions,
  TranslatePlaceholderData,
} from 'react-localize-redux';

import { action } from '../../actions/utils';
import Loading from './components/Loading';
import Saving from './components/Saving';

// #region enqueue
export const ENQUEUE_NOTIFICATION = 'NOTIFICATIONS/ENQUEUE';
export type EnqueueNotify = ReturnType<typeof enqueueNotify>;
export type EnqueueNotifyProps = { message: string | React.ReactNode } & OptionsObject;
export const enqueueNotify = (props: EnqueueNotifyProps) => action(ENQUEUE_NOTIFICATION, props);
// #endregion

// #region dequeue
export const DEQUEUE_NOTIFICATION = 'NOTIFICATIONS/DEQUEUE';
export type DequeueNotify = ReturnType<typeof dequeueNotify>;
export const dequeueNotify = (key: string | number) => action(DEQUEUE_NOTIFICATION, { key });
// #endregion

// #region close
export const ENQUEUE_CLOSE_NOTIFICATION = 'NOTIFICATIONS/CLOSE/ENQUEUE';
export type EnqueueCloseNotify = ReturnType<typeof enqueueCloseNotify>;
export const enqueueCloseNotify = (key: string | number) =>
  action(ENQUEUE_CLOSE_NOTIFICATION, { key });

export const DEQUEUE_CLEAN_CLOSE_NOTIFICATION = 'NOTIFICATIONS/CLOSE/DEQUEUE';
export type DequeueCloseNotify = ReturnType<typeof dequeueCloseNotify>;
export const dequeueCloseNotify = (key: string | number) =>
  action(DEQUEUE_CLEAN_CLOSE_NOTIFICATION, { key });
// #endregion

// #region notify
export type NotifyProps = {
  message: string;
  translate?: boolean;
  translateData?: TranslatePlaceholderData;
  translateOptions?: TranslateOptions;
  // Notistack has a type called translate, so we need to remove it from the props
} & Omit<OptionsObject, 'translate'>;

export const notify = (props: NotifyProps) => {
  return (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    if (props.key) {
      const isDuplicate = state.notify.queue.some((n) => n.key === props.key);
      if (isDuplicate) {
        return;
      }
    }
    if (props.translate) {
      const translateFn = getTranslate(state.locale);
      const { translate, translateData, translateOptions, ...rest } = props;
      // @ts-ignore
      rest.message = translateFn(props.message, translateData, translateOptions) as string;
      if (!rest.key) {
        rest.key = Date.now();
      }
      const action = enqueueNotify({
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        ...rest,
      });
      dispatch(action);
    } else {
      const { translate, ...rest } = props;

      const action = enqueueNotify({
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        key: Date.now(),
        ...rest,
      });
      dispatch(action);
    }
  };
};

export const close = (key: string | number) => {
  return (dispatch: ThunkDispatch) => {
    if (key) {
      dispatch(enqueueCloseNotify(key));
    }
  };
};
// #endregion

// #region helpers

// #region loading
export const LOADING_KEY = '$$LOADING$$';
const loading = memoizeOne(() => Loading());
const closeNotifyLoadingDebounce = debounce((dispatch: ThunkDispatch) => {
  dispatch(enqueueCloseNotify(LOADING_KEY));
}, 0.5 * 1000);

type NotifyLoadingProps = Omit<
  NotifyProps,
  'variant' | 'message' | 'key' | 'translate' | 'translateData' | 'translateOptions'
> & {
  loadingStringKey?: string;
};

export const notifyLoading = (props: NotifyLoadingProps = {}) => {
  return (dispatch: ThunkDispatch) => {
    if (props.persist) {
      closeNotifyLoadingDebounce.cancel();
    }

    const action: EnqueueNotifyProps = {
      ...props,
      key: LOADING_KEY,
      message: loading(),
      variant: 'default',
      disableWindowBlurListener: true,
      preventDuplicate: true,
    };
    dispatch(enqueueNotify(action));
  };
};
export const closeNotifyLoading = () => {
  return (dispatch: ThunkDispatch) => {
    closeNotifyLoadingDebounce(dispatch);
  };
};
// #endregion

// #region error
export const isInfoMessageRegExp = /\s/g;
export const INFO_KEY = '$$INFO$$';

export const closeNotifyInfo = () => (dispatch: ThunkDispatch) =>
  dispatch(enqueueCloseNotify(INFO_KEY));

export const notifyInfo = (props: Omit<NotifyProps, 'variant'>) => {
  const { message, translate } = props;

  return notify({
    key: INFO_KEY,
    ...props,
    message: typeof message !== 'string' ? String(message) : message,
    translate: translate,
    variant: 'info',
  });
};
//#endregion

// #region saving
export const SAVING_KEY = '$$SAVING$$';
const saving = memoizeOne((loadingStringKey) => Saving(loadingStringKey));
const closeNotifySavingDebounce = debounce((dispatch: ThunkDispatch) => {
  dispatch(enqueueCloseNotify(SAVING_KEY));
}, 0.5 * 1000);

export const notifySaving = (props: NotifyLoadingProps = {}) => {
  return (dispatch: ThunkDispatch) => {
    if (props.persist) {
      closeNotifySavingDebounce.cancel();
    }
    const action: EnqueueNotifyProps = {
      ...props,
      ariaAttributes: { 'aria-describedby': 'client-snackbar' },
      key: SAVING_KEY,
      message: saving(props.loadingStringKey),
      variant: 'default',
      disableWindowBlurListener: true,
      preventDuplicate: true,
    };
    dispatch(enqueueNotify(action));
  };
};
export const closeNotifySaving = () => {
  return (dispatch: ThunkDispatch) => {
    closeNotifySavingDebounce(dispatch);
  };
};

export const SAVED_KEY = '$$SAVED$$';
export const closeNotifySaved = () => (dispatch: ThunkDispatch) =>
  dispatch(enqueueCloseNotify(SAVED_KEY));
export const notifySaved = (
  props: Omit<NotifyProps, 'variant' | 'message'> & {
    savedStringKey?: string;
  } = {}
) => {
  return notify({
    autoHideDuration: 2000,
    ...props,
    key: SAVED_KEY,
    message: props.savedStringKey || 'Successfully_saved_changes',
    translate: true,
    variant: 'success',
  });
};
// #endregion

export const HIDE_KEY = '$$HIDE$$';
export const closeNotifyHide = () => (dispatch: ThunkDispatch) =>
  dispatch(enqueueCloseNotify(HIDE_KEY));
export const notifyHide = (props: Omit<NotifyProps, 'variant' | 'message'> = {}) => {
  return notify({
    ...props,
    key: HIDE_KEY,
    message: 'Item_successfully_hidden',
    translate: true,
    variant: 'success',
  });
};

export const SHOW_KEY = '$$SHOW$$';
export const closeNotifyShow = () => (dispatch: ThunkDispatch) =>
  dispatch(enqueueCloseNotify(SHOW_KEY));
export const notifyShow = (props: Omit<NotifyProps, 'variant' | 'message'> = {}) => {
  return notify({
    ...props,
    key: SHOW_KEY,
    message: 'Item_successfully_shown',
    translate: true,
    variant: 'success',
  });
};

// #region error
export const isErrorMessageRegExp = /\s/g;
export const ERROR_KEY = '$$ERROR$$';

export const closeNotifyError = () => (dispatch: ThunkDispatch) =>
  dispatch(enqueueCloseNotify(ERROR_KEY));

export const notifyError = (props: Omit<NotifyProps, 'variant'>) => {
  const { message, translate } = props;

  return notify({
    ...props,
    key: ERROR_KEY,
    message: typeof message !== 'string' ? String(message) : message,
    translate:
      translate || (translate !== false && (isErrorMessageRegExp.test(message) ? undefined : true)),
    variant: 'error',
  });
};

export const CLOSE_OTHER_NOTIFICATIONS = 'CLOSE_OTHER_NOTIFICATIONS';

export const closeAllOtherNotifications = (key: string) =>
  action(CLOSE_OTHER_NOTIFICATIONS, { key });
// #endregion
