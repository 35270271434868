import React from 'react';

import { Order, Reject } from '@flipdish/api-client-typescript';
import Hidden from '@mui/material/Hidden';
import { connect } from 'react-redux';

import * as ordersActions from '../../../actions/order.action';
import OrderActionButtons from './ActionButtons';
import OrderActionButtonsMD from './ActionButtonsMD';
import OrderActionButtonsSM from './ActionButtonsSM';

const acceptMinsCollection = [
  { default: false, mins: 5 },
  { default: true, mins: 10 },
  { default: false, mins: 20 },
  { default: false, mins: 30 },
  { default: false, mins: 45 },
];
const acceptMinsDelivery = [
  { default: false, mins: 30 },
  { default: true, mins: 45 },
  { default: false, mins: 60 },
  { default: false, mins: 90 },
];

const deliveryTimeTitle = 'Estimated-delivery-time';
const collectionTimeTitle = 'Estimated-prep-time';

type Props = MappedDispatch & { deliveryType: Order.DeliveryTypeEnum };

const PendingOrderActions: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { acceptOrder, rejectOrder, deliveryType } = props;

  return (
    <>
      <Hidden lgDown>
        <OrderActionButtons
          deliveryType={deliveryType}
          acceptMinsCollection={acceptMinsCollection}
          acceptMinsDelivery={acceptMinsDelivery}
          acceptOrder={acceptOrder}
          rejectOrder={rejectOrder}
          deliveryTimeTitle={deliveryTimeTitle}
          collectionTimeTitle={collectionTimeTitle}
        />
      </Hidden>
      <Hidden lgUp smDown>
        <OrderActionButtonsMD
          deliveryType={deliveryType}
          acceptMinsCollection={acceptMinsCollection}
          acceptMinsDelivery={acceptMinsDelivery}
          acceptOrder={acceptOrder}
          rejectOrder={rejectOrder}
        />
      </Hidden>
      <Hidden smUp>
        <OrderActionButtonsSM
          deliveryType={deliveryType}
          acceptMinsCollection={acceptMinsCollection}
          acceptMinsDelivery={acceptMinsDelivery}
          acceptOrder={acceptOrder}
          rejectOrder={rejectOrder}
        />
      </Hidden>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  acceptOrder: (mins?: number) => {
    dispatch(ordersActions.acceptOrder(mins));
  },
  rejectOrder: (rejectReason: string) =>
    dispatch(ordersActions.rejectOrder(Reject.RejectReasonEnum[rejectReason])),
});

export default connect(null, mapDispatchToProps)(PendingOrderActions);
