import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';

type Props = {
  fieldError?: string;
  showError?: boolean;
  touched: boolean;
  value: string;
};
/**
 * @description Use this with Formik - the touched field state is not available immediately because ```form.setFieldValue``` is async
 * @description https://github.com/formium/formik/issues/529#issuecomment-571294217
 */
const useRenderValidText = ({ fieldError, showError, touched, value }: Props) => {
  const [validTextElement, setvalidTextElement] = useState<JSX.Element | string | undefined>('');

  useEffect(() => {
    if (!showError && touched && value) {
      setvalidTextElement(
        <span style={{ color: '#1dc798' }}>
          <Translate id="Valid" />
        </span>
      );
    } else if (showError) {
      setvalidTextElement(fieldError);
    } else setvalidTextElement('');
  }, [fieldError, value, showError, touched]);

  return validTextElement;
};

export default useRenderValidText;
