import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { FabButtonLink } from '@fd/ui/FabButtonLink';
import Spacer from '@fd/ui/Spacer';

import { RESTAURANT_VOUCHERS_URL } from '../constants';

type AddNewLinkButtonProps = {
  fdKey: string;
  to?: string;
  text: TranslationId;
};

export const AddNewLinkButton = ({ to, text, fdKey }: AddNewLinkButtonProps) => {
  if (!to) {
    return null;
  }

  return (
    <Fab
      variant="extended"
      color="primary"
      to={to}
      data-fd={fdKey}
      component={FabButtonLink}
      style={{ marginBottom: '20px', position: 'relative', marginLeft: '10px' }}
    >
      <AddIcon />
      <Spacer size={8} variant="vertical" />
      <Translate id={text} />
    </Fab>
  );
};

export const AddRestaurantVoucherLink = ({ toUrl }: { toUrl?: string }) => (
  <AddNewLinkButton fdKey="Add_PayGreen_account" text="Add_PayGreen_account" to={toUrl} />
);

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  const toUrl = RESTAURANT_VOUCHERS_URL.getAddNewRestaurantVoucherUrl(currentApp.AppId);
  return {
    toUrl,
  };
};

export default compose<MappedState, {}>(connect(mapStateToProps))((props) => (
  <Permissions allowed={[App.AppResourceSetEnum.CreateBankAccounts]}>
    <AddRestaurantVoucherLink {...props} />
  </Permissions>
));
