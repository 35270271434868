import { datadogLogs } from '@datadog/browser-logs';

import constants from '../../constants.json';

const isDataDogRequired = (): boolean => {
  return !window.location.host.includes('portal.flipdishdev.com');
};
export const initialise = (): void => {
  if (isDataDogRequired() && process.env.VITE_DATADOG_AUTH_TOKEN) {
    // Details of implementation: https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters

    const env =
      constants.ENV_NAME === 'production'
        ? window.location.hostname.endsWith('.portal.flipdishdev.com')
          ? window.location.hostname.replace('.portal.flipdishdev.com', '')
          : 'production'
        : constants.ENV_NAME;

    datadogLogs.init({
      beforeSend: (log) => {
        // eslint-disable-next-line no-param-reassign
        log.user = {};
        return true;
      },
      clientToken: process.env.VITE_DATADOG_AUTH_TOKEN,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      service: 'web-portal',
      site: 'us3.datadoghq.com',
      telemetrySampleRate: 0,
      version: constants.VERSION,
      env,
    });
  }

  window.fdlogger = logger;
};

export type LogTypes = 'debug' | 'error' | 'info' | 'log' | 'warn';

const sendLog = (mode: LogTypes, message: string, meta): void => {
  const wlID = window.location.pathname.split('/')?.[1];
  const payload = {
    version: constants.VERSION,
    wlID,
    message,
    meta,
  };

  if (isDataDogRequired() && process.env.VITE_DATADOG_AUTH_TOKEN) {
    datadogLogs.logger[mode](message, { payload });
  } else {
    // eslint-disable-next-line no-console
    console[mode](message, payload);
  }
};

type LoggerType = {
  [key in LogTypes]: (message: string, data?: object) => void;
};

export const logger: LoggerType = {
  debug: (message: string, data?: object): void => sendLog('debug', message, data),
  error: (message: string, data?: object): void => sendLog('error', message, data),
  info: (message: string, data?: object): void => sendLog('info', message, data),
  log: (message: string, data?: object): void => sendLog('log', message, data),
  warn: (message: string, data?: object): void => sendLog('warn', message, data),
};
