import React from 'react';

import List from '@mui/material/List';
import { useQuery } from '@tanstack/react-query';
import { type TranslateFunction, Translate } from 'react-localize-redux';

import EmptyComponent from '@fd/ui/EmptyComponent';
import ErrorComponent from '@fd/ui/ErrorComponent';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';
import LoadingListItem from '@fd/ui/List/LoadingListItem';

import EmptyIcon from '../../../../assets/images/empty_store_list.svg';
import {
  getSalesChannelGroupKey,
  storeGroupService,
} from '../../../../services/storegroup.service';

export type CustomerMessageListProps = {
  ariaLabel: TranslationId;
  orgId?: string;
  currentBrandId?: string;
  translate: TranslateFunction;
};

const CustomerMessageList = ({
  ariaLabel,
  orgId,
  currentBrandId,
  translate,
}: CustomerMessageListProps) => {
  const {
    data: salesChannelGroup,
    isLoading,
    error,
  } = useQuery({
    queryKey: [getSalesChannelGroupKey, currentBrandId],
    queryFn: () => storeGroupService.getAllForApp({ appId: currentBrandId ?? '' }),
    enabled: !!currentBrandId,
  });

  if (isLoading) {
    return <LoadingListItem />;
  }
  if (error) {
    return <ErrorComponent title="Something_went_wrong" />;
  }

  if (!salesChannelGroup?.Data?.length) {
    return (
      <EmptyComponent
        title="No_sales_channel_group_found"
        titleData={{ orgId: orgId ?? '' }}
        icon={EmptyIcon}
        noLink
      />
    );
  }

  const customSubtitle = (totalSalesChannels?: number) => {
    if (totalSalesChannels === undefined) return undefined;
    if (totalSalesChannels > 1)
      return <Translate id="num_sales_channels_selected" data={{ number: totalSalesChannels }} />;
    if (totalSalesChannels === 1) return <Translate id="one_sales_channel_selected" />;
    return <Translate id="No_sales_channels_added_yet" />;
  };

  return (
    <List aria-label={translate(ariaLabel) as string}>
      {salesChannelGroup?.Data?.map((sc, idx) => (
        <ListItemLinkButton
          customSubtitle={customSubtitle(sc.TotalStores)}
          customTitle={sc.Name}
          isLast={idx === (salesChannelGroup?.Data?.length || 0) - 1}
          key={sc.StoreGroupId}
          link={`/${currentBrandId}/settings/customer-messages/${sc.StoreGroupId}`}
        />
      ))}
    </List>
  );
};

export default CustomerMessageList;
