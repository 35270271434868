import React from 'react';

import { AppExternalServices } from '@flipdish/api-client-typescript/private/api';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LoadingButton from '@fd/ui/LoadingButton';

import { emptySpaceRegExp } from '../../../helpers/validation';
import GoogleAnalyticsCodeField from './Fields/GoogleAnalyticsCodeField';

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (trackingCodes: AppExternalServices) => {
  return {
    GoogleAnalyticsCode: trackingCodes.GoogleAnalyticsCode || '',
    FacebookPixelCode: trackingCodes.FacebookPixelCode || '',
  };
};

type InnerProps = { isLoading?: boolean };
type OuterProps = {
  trackingCodes: AppExternalServices;
  submit: (trackingCodes: AppExternalServices) => Promise<void>;
};
type Props = InnerProps & OuterProps;

const useStyles = makeStyles({
  container: {
    paddingTop: 30,
    paddingRight: 0,
    paddingBottom: 24,
    paddingLeft: 24,
    marginBottom: 30,
  },
  formHeaderContainer: {
    marginBottom: 10,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  formHeader: {
    paddingTop: 16,
    paddingBottom: 18,
  },
  field: {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
});

const GoogleAnalyticsForm = ({
  isSubmitting,
  handleSubmit,
  isLoading,
}: Props & FormikProps<FormValues>) => {
  const classes = useStyles();
  return (
    <Form>
      <PaperContainer fluid>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.formHeaderContainer}>
            <Typography variant="h5" className={classes.formHeader}>
              <Translate id="Google_analytics" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <GoogleAnalyticsCodeField />
          </Grid>
          <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
            <Grid item>
              <LoadingButton
                disabled={isLoading}
                fdKey="GoogleAnalyticsCode_btn"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                style={{ marginTop: 16, marginRight: 16 }}
              >
                <Translate id="Save" />
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    </Form>
  );
};

export default withFormik<Props, FormValues>({
  displayName: 'GoogleAnalyticsCodeForm',
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.trackingCodes);
  },
  handleSubmit: (values, formikBag) => {
    const GoogleAnalyticsCode = values['GoogleAnalyticsCode']
      .split(',')
      .filter((val) => !emptySpaceRegExp.test(val))
      .join(',');
    formikBag.props
      .submit({
        ...values,
        GoogleAnalyticsCode,
      })
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.setFieldTouched('GoogleAnalyticsCode', true);
      });
  },
})(GoogleAnalyticsForm);
