import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import isArray from 'lodash/isArray';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps, Redirect, withRouter } from 'react-router';
import { compose } from 'recompose';

import LinkButton from '@fd/ui/Button/LinkButton';
import TableQuery from '@fd/ui/GenericTable/TableQuery';
import PageLayout from '@fd/ui/Layout';
import DynamicAlertBanner from '@fd/ui/molecules/DynamicAlertBanner/DynamicAlertBanner';
import SummaryOverview from '@fd/ui/Summary/SummaryOverview';
import SummarySection from '@fd/ui/Summary/SummarySection';

import PageTitleWithBeta from '../../../../../layouts/Portal/PageTitleWithBeta';
import { flagService } from '../../../../../services/flagService';
import {
  payoutDetailsChargebacksMeta,
  payoutDetailsOrderMeta,
  payoutDetailsOtherChargesMeta,
  payoutDetailsRefundMeta,
  payoutsByStoreDefaultColumns,
  payoutsByStoreMeta,
} from '../../data/PayoutDetailsMeta';
import {
  formatChargebackSummary,
  formatOrderSummary,
  formatOtherChargesSummary,
  formatRefundSummary,
} from '../../DataHelper/PayoutSummarySectionData';
import {
  filterByStoreIds,
  formatPayoutByStores,
  getBalanceAccount,
  getSummaryTotals,
  getSummaryTotalsBreakdown,
  getSummaryTotalsNew,
  mapTableDataToPagination,
} from '../../helpers';
import { getPayoutDetailsSummaryData, getPayoutSummaryCSV } from '../../payouts.actions';
import {
  getPayoutDetailsTableChargebacks,
  getPayoutDetailsTableOrders,
  getPayoutDetailsTableOtherCharges,
  getPayoutDetailsTableRefund,
} from '../../payouts.service';
import {
  FetchDataProps,
  PayoutDetailSummary,
  PayoutStore,
  PayoutSummary,
  SectionData,
  StoreOption,
  SummarySectionData,
} from '../../types';
import PayoutBalanceAccount from './PayoutBalanceAccount';
import PayoutDetailsEmptyState from './PayoutDetailsEmptyState';
import PayoutDetailsFilters from './PayoutDetailsFilters';
import PayoutOverview from './PayoutOverview';

type InnerProps = MappedState & MappedDispatch & RouteComponentProps<{ payoutId: string }>;
type OuterProps = {};
type Props = InnerProps & OuterProps;

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

const useStyles = makeStyles((theme: Theme) => ({
  customBanner: {
    marginTop: theme.spacing(2),
  },
  v3ReportingButton: {
    marginBottom: theme.spacing(1),
  },
  v3ReportingButtonContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const PayoutDetails = (props: Props) => {
  const {
    appId,
    getPayoutSummaryData,
    history,
    languageCode,
    location,
    payoutDetailsSummaryLoading,
    payoutDetailSummary,
    payoutId,
    requestDownloadCSV,
    showPayoutsStoreOptions,
    translate,
  } = props;
  const classes = useStyles();
  const [currency, setCurrency] = useState<CurrencyEnum>('EUR');
  const [storeIds, setStoreIds] = useState<number[] | number>();
  const [storeIdsFilter, setStoreIdsFilter] = useState<number[] | number>();
  const [storeOptions, setStoreOptions] = useState<StoreOption[]>();

  //#region summary state
  const [payoutSummary, setPayoutSummary] = useState<PayoutSummary[]>();
  const [payoutBreakdown, setPayoutBreakdown] = useState<PayoutSummary[]>();
  const [payoutBalanceAccount, setPayoutBalanceAccount] = useState<PayoutSummary[]>();
  const [orderSummary, setOrderSummary] = useState<SummarySectionData>();
  const [refundSummary, setRefundSummary] = useState<SummarySectionData>();
  const [chargebackSummary, setChargebackSummary] = useState<SummarySectionData>();
  const [otherChargesSummary, setOtherChargesSummary] = useState<SummarySectionData>();
  //#endregion

  const hasMultiplePayoutStores = isArray(storeIds) && storeIds?.length > 1;

  const getBankAccountFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const bankAccountId = params.get('bankAccountId');
    return Number(bankAccountId);
  };
  const bankAccountId = getBankAccountFromUrl();

  if (!bankAccountId) {
    return <Redirect to={`/${appId}/finance/payouts/`} />;
  }
  const onSelectStore = (ids: number[] | number) => {
    return setStoreIdsFilter(ids);
  };

  const onSetStoreOptions = (payoutStores: PayoutStore[]) => {
    const storesArray = [] as StoreOption[];
    {
      showPayoutsStoreOptions
        ? payoutStores.forEach((store) => {
            if (store.StoreId)
              storesArray.push({
                label: store.StoreName as string,
                value: String(store.StoreId),
              });
          })
        : payoutStores.forEach((store) => {
            if (store.StoreId && store.Amount && store.Amount > 0)
              storesArray.push({
                label: store.StoreName as string,
                value: String(store.StoreId),
              });
          });
    }

    setStoreOptions(storesArray);
  };

  const filterQueryParams = useMemo(
    () => ({ payoutId, bankAccountId, storeIdsFilter }),
    [payoutId, bankAccountId, storeIdsFilter]
  );

  useEffect(() => {
    if (payoutDetailSummary && payoutDetailSummary.PayoutId === payoutId) {
      const storeIdsArray = payoutDetailSummary.PayoutStores?.reduce(
        (result: number[] = [], store) => {
          if (showPayoutsStoreOptions) {
            if (store.StoreId) result.push(store.StoreId);
            return result;
          } else {
            if (store.StoreId && store.Amount && store.Amount > 0) result.push(store.StoreId);
            return result;
          }
        },
        []
      );
      setCurrency(payoutDetailSummary.Currency);
      setStoreIds(storeIdsArray);
      onSetStoreOptions(payoutDetailSummary.PayoutStores as PayoutStore[]);
      //get all relevant storeIds from payoutDetailSummary if no store is in url
      if (!location.search.includes('store')) onSelectStore(storeIdsArray as number[]);
    }
  }, [payoutDetailSummary]);

  useEffect(() => {
    if (payoutDetailSummary && payoutDetailSummary.PayoutId) {
      setPayoutSummary(
        getSummaryTotalsNew(
          payoutDetailSummary as PayoutDetailSummary,
          storeIdsFilter as number[] | number
        )
      );
      setPayoutBalanceAccount(
        getBalanceAccount(
          payoutDetailSummary as PayoutDetailSummary,
          storeIdsFilter as number[] | number
        )
      );
      setPayoutBreakdown(
        getSummaryTotalsBreakdown(
          payoutDetailSummary as PayoutDetailSummary,
          storeIdsFilter as number[] | number,
          currency,
          languageCode,
          translate
        )
      );

      setOrderSummary(
        formatOrderSummary(
          payoutDetailSummary.PayoutStores as PayoutStore[],
          storeIdsFilter as number[] | number
        )
      );
      setRefundSummary(
        formatRefundSummary(
          payoutDetailSummary.PayoutStores as PayoutStore[],
          storeIdsFilter as number[] | number
        )
      );
      setChargebackSummary(
        formatChargebackSummary(
          payoutDetailSummary.PayoutStores as PayoutStore[],
          storeIdsFilter as number[] | number
        )
      );
      setOtherChargesSummary(
        formatOtherChargesSummary(
          payoutDetailSummary.PayoutStores as PayoutStore[],
          storeIdsFilter as number[] | number
        )
      );
    }
  }, [storeIdsFilter]);

  useEffect(() => {
    getPayoutSummaryData(bankAccountId, payoutId);
  }, [payoutId]);

  const getTablePayoutsByStore = async (props: FetchDataProps) => {
    const filteredData = filterByStoreIds(
      payoutDetailSummary?.PayoutStores as PayoutStore[],
      storeIdsFilter as number[] | number
    );
    const formattedData = formatPayoutByStores(filteredData, props.limit);
    return {
      Data: formattedData[props.page],
      Page: props.page,
      Limit: props.limit,
      TotalRecordCount: filteredData.length,
    };
  };

  const getTableOrders = async (props: FetchDataProps) => {
    const data = await getPayoutDetailsTableOrders({
      ...props.filter,
      ...props,
    });
    return mapTableDataToPagination(data, payoutDetailSummary?.PayoutStores as PayoutStore[]);
  };

  const getTableRefunds = async (props: FetchDataProps) => {
    const data = await getPayoutDetailsTableRefund({
      ...props.filter,
      ...props,
    });
    return mapTableDataToPagination(data, payoutDetailSummary?.PayoutStores as PayoutStore[]);
  };

  const getTableChargebacks = async (props: FetchDataProps) => {
    const data = await getPayoutDetailsTableChargebacks({
      ...props.filter,
      ...props,
    });
    return mapTableDataToPagination(data, payoutDetailSummary?.PayoutStores as PayoutStore[]);
  };

  const getTableOtherCharges = async (props: FetchDataProps) => {
    const data = await getPayoutDetailsTableOtherCharges({
      ...props.filter,
      ...props,
    });
    return mapTableDataToPagination(data, payoutDetailSummary?.PayoutStores as PayoutStore[]);
  };

  const downloadCSVOrders = () => {
    requestDownloadCSV(bankAccountId, payoutId, 'orders', storeIdsFilter);
  };

  const downloadCSVRefunds = () => {
    requestDownloadCSV(bankAccountId, payoutId, 'refunds', storeIdsFilter);
  };

  const downloadCSVChargebacks = () => {
    requestDownloadCSV(bankAccountId, payoutId, 'chargebacks', storeIdsFilter);
  };

  const downloadCSVOtherCharges = () => {
    requestDownloadCSV(bankAccountId, payoutId, 'otherCharges', storeIdsFilter);
  };

  const downloadCSVPayoutByStore = () => {
    requestDownloadCSV(bankAccountId, payoutId, 'payoutByStore', storeIdsFilter);
  };

  const payoutDetailsSectionData = useMemo(
    () => [
      {
        title: 'Payouts_Orders_Title',
        summaryData: orderSummary,
        downloadCSV: downloadCSVOrders,
        fetchTableData: getTableOrders,
        metadata: payoutDetailsOrderMeta,
        storageKey: 'fd-payout-details-orders-table',
        rowsPerPageLocalStorageKey: 'fd-payout-details-orders-table-rows',
        urlId: 'Orders',
      },
      {
        title: 'Payouts_Refunds_Title',
        summaryData: refundSummary,
        downloadCSV: downloadCSVRefunds,
        fetchTableData: getTableRefunds,
        metadata: payoutDetailsRefundMeta,
        storageKey: 'fd-payout-details-refunds-table',
        rowsPerPageLocalStorageKey: 'fd-payout-details-refunds-table-rows',
        urlId: 'Refunds',
      },
      {
        title: 'Chargebacks',
        summaryData: chargebackSummary,
        downloadCSV: downloadCSVChargebacks,
        fetchTableData: getTableChargebacks,
        metadata: payoutDetailsChargebacksMeta,
        storageKey: 'fd-payout-details-chargebacks-table',
        rowsPerPageLocalStorageKey: 'fd-payout-details-chargebacks-table-rows',
        urlId: 'ChargeBacks',
      },
      {
        title: 'Other_Charges',
        summaryData: otherChargesSummary,
        downloadCSV: downloadCSVOtherCharges,
        fetchTableData: getTableOtherCharges,
        metadata: payoutDetailsOtherChargesMeta,
        storageKey: 'fd-payout-details-other-charges-table',
        rowsPerPageLocalStorageKey: 'fd-payout-details-other-charges-table-rows',
      },
    ],
    [orderSummary, refundSummary, chargebackSummary, otherChargesSummary]
  );

  const onRowClick = ({ row, hashId }: { row: Flipdish.PayoutOrder; hashId: string }) => {
    if (hashId) {
      window.location.hash = hashId;
    }
    history.push(`/${appId}/orders/${row.OrderId}`);
  };

  const isEqualOrAfter = (date: Date, dateToCompare: Date) => {
    return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);
  };

  const backURLSearchParams = () => {
    const params = new URLSearchParams(location.search);
    params.delete('store');
    params.delete('bankAccountId');
    return params.toString();
  };

  const renderSections = (sectionData: SectionData[]) => {
    return sectionData.map((section, index) => {
      return (
        <div key={index} id={section.urlId}>
          <SummarySection
            currency={currency}
            loading={payoutDetailsSummaryLoading}
            summaryData={section.summaryData || undefined}
            title={section.title}
          />
          {section.summaryData?.showSection && (
            <TableQuery
              currency={currency}
              downloadCSV={section.downloadCSV}
              fetchData={section.fetchTableData}
              filter={filterQueryParams}
              languageCode={languageCode}
              metadata={section.metadata}
              onRowClick={onRowClick}
              rowsPerPageLocalStorageKey={section.rowsPerPageLocalStorageKey}
              storageKey={section.storageKey}
              urlId={section.urlId}
            />
          )}
        </div>
      );
    });
  };

  const PayoutDetailsIssueMessage = () => {
    return (
      <div className={classes.customBanner}>
        <DynamicAlertBanner
          backgroundColor="yellow"
          bannerText={'We_cannot_show_this_payout_for_this_store_as_the_s'}
          showButton={false}
        />
      </div>
    );
  };

  const renderPayoutDetails = () => {
    return payoutDetailSummary?.PayoutStores?.length ? (
      <>
        <PayoutDetailsFilters
          storeOptions={storeOptions || undefined}
          onSelectStore={onSelectStore}
          loading={payoutDetailsSummaryLoading}
        />
        <SummaryOverview
          languageCode={languageCode}
          currency={currency}
          summaryData={payoutSummary || undefined}
          loading={payoutDetailsSummaryLoading}
          title={'Summary'}
        />
        <>
          <PayoutBalanceAccount
            languageCode={languageCode}
            currency={currency}
            payoutBalanceData={payoutBalanceAccount || undefined}
            payoutDetailSummary={payoutDetailSummary}
          />
          <SummaryOverview
            languageCode={languageCode}
            currency={currency}
            summaryData={payoutBreakdown || undefined}
            loading={payoutDetailsSummaryLoading}
            title={'Breakdown'}
          />
        </>
        <TableQuery
          title="Payouts_by_store"
          fetchData={getTablePayoutsByStore}
          currency={currency}
          downloadCSV={downloadCSVPayoutByStore}
          languageCode={languageCode}
          filter={filterQueryParams}
          storageKey={'fd-payout-details-payouts-by-store-table'}
          columnLocalStorageKey={'fd-payout-details-payouts-by-store-table-columns'}
          rowsPerPageLocalStorageKey={'fd-payout-details-payouts-by-store-table-rows'}
          metadata={payoutsByStoreMeta}
          showTable={hasMultiplePayoutStores}
          showColumnSelector
          defaultTableColumns={payoutsByStoreDefaultColumns}
        />
        {renderSections(payoutDetailsSectionData as SectionData[])}
      </>
    ) : (
      <PayoutDetailsIssueMessage />
    );
  };

  return (
    <PageLayout
      title={<PageTitleWithBeta translateId="Payout_Details" />}
      toParent={`../../payouts?${backURLSearchParams()}`}
      strictToParent={true}
      documentTitle="Payout_Details"
    >
      {/* Not localising this as it will be removed when the V3 report takes over */}
      <Permissions allowed={['FlipdishStaff']}>
        <Box className={classes.v3ReportingButtonContainer}>
          <LinkButton
            color="primary"
            to={`/${appId}/finance/payoutsv3/${payoutId}?bankAccountId=${bankAccountId}`}
            type="button"
            variant="contained"
            fdKey="payout-details-v3-reporting-button"
            className={classes.v3ReportingButton}
          >
            Check out the new version!
          </LinkButton>
          <br />
          <Typography variant="caption" component="p">
            *Flipdish Staff Only
          </Typography>
        </Box>
      </Permissions>
      <PayoutOverview
        payoutDetailSummary={payoutDetailSummary}
        loading={payoutDetailsSummaryLoading}
        currency={currency}
        languageCode={languageCode}
      />

      {isEqualOrAfter(
        new Date(payoutDetailSummary?.CreatedDate as any),
        new Date(payoutDetailSummary?.CutoffDate as any)
      ) ? (
        <>{renderPayoutDetails()}</>
      ) : (
        <PayoutDetailsEmptyState
          payoutDetailSummary={payoutDetailSummary}
          loading={payoutDetailsSummaryLoading}
        />
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  const { locale } = initialState;
  const { payoutId } = ownProps.match.params;
  return (state: AppState) => {
    const { payoutDetailSummary, payoutDetailsSummaryLoading } = state.payouts;
    return {
      appId: state.currentApp.AppId as string,
      languageCode: getActiveLanguage(state.locale),
      payoutDetailsSummaryLoading,
      payoutDetailSummary,
      payoutId: Number(payoutId),
      translate: getTranslate(locale),
      showPayoutsStoreOptions: flagService.isFlagOn(state, 'showPayoutsStoreOptions'),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getPayoutSummaryData: (bankAccountId: number, payoutId: number) =>
    dispatch(getPayoutDetailsSummaryData(bankAccountId, payoutId)),
  requestDownloadCSV: (
    bankAccountId: number,
    payoutId: number,
    payoutSection: string,
    storeIdsFilter?: number[] | number
  ) => dispatch(getPayoutSummaryCSV(bankAccountId, payoutId, payoutSection, storeIdsFilter)),
});

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToProps)
)(PayoutDetails);
