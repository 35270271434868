import React, { useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { withRouter } from 'react-router-dom';
import { compose, setDisplayName, UnpackICEWP, withHandlers, withStateHandlers } from 'recompose';

import { enqueueTranslateNotification, removeNotification } from '../../../actions/context.actions';
import { storeActions } from '../../../actions/store.actions';
import CreateNewButton from '../../../layouts/Portal/CreateNewButton';
import { CreateStoreGroupDialog } from '../../StoreGroups/components/CreateStoreGroupDialog';
import withStoreGroupData, {
  StoreGroupDataComponentProps,
} from '../../StoreGroups/withStoreGroupData';
import { StoresRouteParams } from '../types';
import withStoresData from '../withStoresData';
import { CreateNewStoreDialog } from './CreateNewStoreDialog';

type UnpackType<T> = T extends React.ComponentType<React.PropsWithChildren<infer P>> ? P : T;
type Data = UnpackType<ReturnType<typeof withStoresData>>;
type AddStoreDialogState = UnpackICEWP<typeof useAddStoreDialogState> & {
  dialogOn(): boolean;
  dialogOff(): boolean;
};
// TODO: use redux & action
const useAddStoreDialogState = withStateHandlers(
  () => ({
    storeDialog: false,
  }),
  {
    dialogOn: () => () => ({
      storeDialog: true,
    }),
    dialogOff: () => () => ({
      storeDialog: false,
    }),
  }
);
const getDefaultStore = (translate, storesData, storeDefaultLanguage) => ({
  Name: `${translate('New_store') as string} ${storesData.length + 1}`,
  StaffLanguage: storeDefaultLanguage,
});
type Handlers = UnpackICEWP<typeof useHandlers>;
const useHandlers = withHandlers({
  onCreate:
    ({ dialogOn, storesDataIsEmpty, save, translate, storesData, storeDefaultLanguage }: Props) =>
    () => {
      if (!storesDataIsEmpty) {
        dialogOn();
      } else {
        save(getDefaultStore(translate, storesData, storeDefaultLanguage));
      }
    },
  handleSave:
    ({
      translate,
      storesData,
      storeDefaultLanguage,
      save,
      copy,
      dialogOff,
    }: Props & MappedState & MappedDispatch) =>
    (copiedObject = undefined) => {
      const changes = getDefaultStore(translate, storesData, storeDefaultLanguage);
      if (copiedObject) {
        copy(copiedObject).then(dialogOff);
      } else {
        save(changes).then(dialogOff);
      }
    },
});

type Props = StoreGroupDataComponentProps & AddStoreDialogState & Data & StoresRouteParams;
const CreateNewStoreButton = ({
  appId,
  dialogOff,
  handleSave,
  onCreate,
  storeDialog,
  storeGroupeTotalCount,
  storesData,
  translate,
}) => {
  const [storeGroupDialog, setStoreGroupDialog] = useState<boolean>(false);

  const openCloseDialog = () => {
    if (storeGroupDialog === false) {
      setStoreGroupDialog(true);
    } else setStoreGroupDialog(false);
  };

  const handleStoreGroupCreated = (newStoreGroupId) => {
    dialogOff();
    //TODO: refactor routing so that navigating to new group stores doesnt require
    window.location.href = `/${appId}/storegroups/${newStoreGroupId}/stores`;
  };

  return (
    <div>
      <Permissions allowed={[App.AppResourceSetEnum.CreateStores]}>
        <Box mb={1.6} display="flex" justifyContent="flex-end">
          <CreateNewButton translateId="New_store" onClick={onCreate} />
        </Box>
        <CreateNewStoreDialog
          open={storeDialog}
          stores={storesData}
          onCancel={dialogOff}
          onSave={handleSave}
          translate={translate}
        />
        {storeGroupeTotalCount === 1 && (
          <CreateNewButton translateId="New_store_group" onClick={openCloseDialog} />
        )}

        <CreateStoreGroupDialog
          open={storeGroupDialog}
          handleClose={openCloseDialog}
          onStoreGroupCreated={handleStoreGroupCreated}
        />
      </Permissions>
    </div>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  return (state: AppState, ownProps: Props) => {
    const translate = getTranslate(state.locale);

    return {
      appId: state.currentApp.AppId as string,
      translate,
      storeDefaultLanguage:
        (state.currentApp &&
          state.currentApp.AvailableAppLanguages &&
          state.currentApp.AvailableAppLanguages.length &&
          state.currentApp.AvailableAppLanguages[0].LanguageId) ||
        'en',
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: Props) => {
  const storeGroupId = Number(ownProps.match.params.storeGroupId);
  return (dispatch: ThunkDispatch, ownProps: Props) => {
    return {
      save: (store) => {
        dispatch(enqueueTranslateNotification('Auto_saving'));
        return (
          dispatch(storeActions.create({ storeGroupId, store }))
            // @ts-ignore
            .then(() => {
              dispatch(removeNotification());
            })
            .catch((err) => {
              dispatch(removeNotification());
            })
        );
      },
      copy: (storeId) => {
        dispatch(enqueueTranslateNotification('Auto_saving'));
        return (
          dispatch(storeActions.copy({ storeId, storeGroupId }))
            // @ts-ignore
            .then(() => {
              dispatch(removeNotification());
            })
            .catch((err) => {
              dispatch(removeNotification());
            })
        );
      },
    };
  };
};

const EnhancedComponent = compose<Props & MappedState & MappedDispatch & Handlers, {}>(
  setDisplayName('CreateNewStoreButton'),
  withRouter,
  withStoresData,
  withStoreGroupData,
  useAddStoreDialogState,
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory),
  useHandlers
)(CreateNewStoreButton);
export { EnhancedComponent as CreateNewButton };
