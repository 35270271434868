import * as React from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { useQueryPendingMenuChangesHook } from '../../../../../custom-hooks/useQueryPendingMenuChangesHook';
import {
  closeNotifySaving,
  notify,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import { catalogChangesService } from '../../../../../services/catalogChanges.service';
import PageLayout from '../../../../../ui/Layout';
import { metafieldsService } from '../../../../Settings/Metafields/metafields.service';
import { catalogGroupsService } from '../../../services/catalogGroup.service';
import { GroupFormValues } from '../../../types';
import { ModifierGroupForm } from '../../components/ModifierGroupForm';

type Props = RouteComponentProps<{ modifierId: string }>;

const ModifierGroupEdit = ({
  AppId,
  closeNotifySaving,
  hasUpdateProductPermission,
  history,
  isProductBasedMenusOn,
  location,
  modifierGroupId,
  notify,
  notifyError,
  notifySaved,
  notifySaving,
  translate,
}: Props & MappedState & MappedDispatch) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [catalogGroupsService.getModifierGroupByIdQueryKey, AppId, modifierGroupId],
    queryFn: () => catalogGroupsService.getCatalogGroupById(AppId, modifierGroupId),
    refetchOnWindowFocus: true,
  });

  React.useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
      history.replace(`/${AppId}/modifier-groups`);
    }
  }, [isError]);

  const handleSave = async (updatedModifierGroup: GroupFormValues, saveType: number) => {
    if (saveType === 0) {
      await saveMutation.mutateAsync(updatedModifierGroup);
    } else {
      await saveAndPublishMutation.mutateAsync(updatedModifierGroup);
    }
  };

  const showError = (errorMessage: string) => {
    closeNotifySaving();
    notifyError({ message: errorMessage, translate: true });
  };

  const showUpdateError = (error: Error) => {
    const errorMessage = error.message.includes('SKU already exists')
      ? 'Product_CreateProductDialog_DuplicateSkuError'
      : 'Error_please_try_again_later';
    showError(errorMessage);
  };

  const metafieldsQuery = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, AppId, 'catalogGroup'],
    queryFn: () => metafieldsService.getMetafieldsByEntity(AppId, 'catalogGroup'),
  });

  const pendingMenuChangesQuery = useQueryPendingMenuChangesHook(
    [catalogChangesService.getPendingMenuChangesQueryKey, AppId, modifierGroupId],
    { appId: AppId, catalogElementId: modifierGroupId, page: 1, limit: 50 },
    true
  );
  const queryClient = useQueryClient();

  const saveMutation = useMutation({
    mutationFn: (updatedGroup: GroupFormValues) => {
      notifySaving();
      return catalogGroupsService.updateCatalogGroup(AppId, modifierGroupId, updatedGroup);
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      queryClient.invalidateQueries({
        queryKey: [catalogGroupsService.getModifierGroupByIdQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesQueryKey],
      });
    },

    onError: showUpdateError,
  });

  const saveAndPublishMutation = useMutation({
    mutationFn: (updatedGroup: GroupFormValues) => {
      notifySaving();
      return catalogGroupsService.updateCatalogGroup(AppId, modifierGroupId, updatedGroup);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [catalogGroupsService.getModifierGroupByIdQueryKey],
      });
      publishMutation.mutate();
    },

    onError: showUpdateError,
  });

  const publishMutation = useMutation({
    mutationFn: () => {
      return catalogChangesService.publishPendingMenuChanges(AppId, modifierGroupId, []);
    },

    onSuccess: () => {
      closeNotifySaving();
      notify({
        message: translate('Successfully_updated_menus') as string,
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesQueryKey],
      });
    },

    onError: () => {
      showError('Error_please_try_again_later');
    },
  });

  return isProductBasedMenusOn ? (
    <PageLayout
      documentTitle={'Edit_modifier_group'}
      strictToParent={true}
      toParent={`/${AppId}/modifier-groups`}
      // @ts-ignore
      title={location.state?.productName || data?.Data?.Name}
    >
      <ModifierGroupForm
        AppId={AppId}
        isUpdateForm
        canEdit={hasUpdateProductPermission}
        isLoading={isPending}
        metafields={metafieldsQuery.data?.Data}
        modifierGroup={data?.Data}
        onSave={handleSave}
        pendingMenuChanges={pendingMenuChangesQuery.data}
        translate={translate}
      />
    </PageLayout>
  ) : null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { currentApp, locale } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'UpdateCatalogElements',
  ]);
  return {
    AppId: currentApp.AppId as string,
    hasUpdateProductPermission: getPermissionsSelector(state),
    translate: getTranslate(locale),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    //@ts-ignore
    modifierGroupId: props.match.params.modifierGroupId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(ModifierGroupEdit);
export { EnhancedComponent as ModifierGroupEdit };
