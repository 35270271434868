import React from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';

const useStyles = makeStyles((theme: Theme) => ({
  arrowContainer: {
    minWidth: 0,
  },
  iconContainer: {
    marginRight: theme.spacing(2),
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  skeletonListItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export type NavigationListItem = {
  icon?: React.ReactElement;
  key: string;
  primaryText: string | React.ReactElement;
  secondaryText?: string | React.ReactElement;
  url: string;
};

export type NavigationListProps = {
  isLoading?: boolean;
  items: NavigationListItem[];
};

export const NavigationList = ({ isLoading, items }: NavigationListProps) => {
  const classes = useStyles();

  const renderSkeletonItems = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <ListItem className={classes.skeletonListItem} divider key={`skeleton-row-${i}`}>
          <SkeletonLoader fdKey={`skeleton-name-${i}`} rows={[{ height: '24px', width: '30%' }]} />
        </ListItem>
      );
    }
    return rows;
  };

  const renderItems = () => {
    return (
      <>
        {items.map((item, index) => {
          const { icon, key, primaryText, secondaryText, url } = item;
          return (
            <ListItem
              className={classes.listItem}
              component={Link}
              divider={index + 1 < items.length}
              key={key}
              to={url}
            >
              {icon && <div className={classes.iconContainer}>{icon}</div>}
              <ListItemText primary={primaryText} secondary={secondaryText} />
              <ListItemIcon className={classes.arrowContainer}>
                <KeyboardArrowRight data-fd={`${key}-arrow`} color="action" />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </>
    );
  };

  return (
    <Paper>
      <List disablePadding>{isLoading ? renderSkeletonItems() : renderItems()}</List>
    </Paper>
  );
};
