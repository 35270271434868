import React from 'react';

import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { type Theme } from '@mui/material/styles';
import { type SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

import LinkButton from '../../../ui/Button/LinkButton';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(2),
    },
    paddingTitle: {
      padding: '32px',
    },
    link: {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginLeft: 0,
        marginBottom: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    linkText: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      color: '#05149e',
      textTransform: 'none',
    },
  });

export interface IStoreCardLinkButtonProps {
  to: string;
  fdKey: string;
  icon: React.ComponentType<React.PropsWithChildren<SvgIconProps>>;
  children: React.ReactElement;
}
type Props = WithStyles<typeof styles> & IStoreCardLinkButtonProps;

const StoreCardLinkButton = ({ children, to, fdKey, icon: Icon, classes }: Props) => (
  <LinkButton to={to} fdKey={fdKey} className={classes.link} variant="text">
    <Icon color="action" className={classes.icon} />
    <Typography variant="h6" className={classes.linkText}>
      {children}
    </Typography>
    <ArrowRightIcon color="primary" />
  </LinkButton>
);

export default compose<Props, IStoreCardLinkButtonProps>(withStyles(styles))(StoreCardLinkButton);
