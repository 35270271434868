import React from 'react';

import { SubscriptionSummary } from '@flipdish/api-client-typescript';
import CreditCard from '@mui/icons-material/CreditCard';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';

import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import Spacer from '@fd/ui/Spacer';

import SubscriptionListItemDetails from './SubscriptionListItemDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
    '&:focus': {
      backgroundColor: '#eaf2ff',
    },
  },
  avatar: {
    backgroundColor: '#EAF2FF',
    border: `1px`,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1.8),
  },
}));

type SubscriptionListItemProps = {
  isLast: boolean;
  subscription: SubscriptionSummary;
  appId?: string;
  overdue: boolean;
};

type Props = SubscriptionListItemProps;
const SubscriptionListItem = ({ isLast, subscription, appId, overdue }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <ListItem
      onClick={() => history.push(`/${appId}/billing/subscriptions/${subscription.SubscriptionId}`)}
      disableGutters
      divider={!isLast}
      alignItems="center"
      button
      className={`${classes.root} ${HORIZONTAL_SPACE_CLASSNAME} ${
        isMobile || (!isMobile && !isLast) ? classes.paddingBottom : ''
      }`}
      component={Box as any}
      hover="false"
      key={subscription.SubscriptionId}
    >
      <Avatar data-fd={`${subscription.SubscriptionId}-avatar`} className={classes.avatar}>
        <CreditCard />
      </Avatar>
      <SubscriptionListItemDetails subscription={subscription} overdue={overdue} />
      <Hidden mdDown>
        <ListItemSecondaryAction>
          <Spacer size={8}>
            <KeyboardArrowRight
              data-fd={`${subscription.SubscriptionId}-right-arrow`}
              color="action"
            />
          </Spacer>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItem>
  );
};

export default SubscriptionListItem;
