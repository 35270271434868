import { isEmpty } from 'lodash';

export const PTAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'PT') {
    return {
      street: '',
      number: '',
      floor: '',
      postalCode: '',
      city: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      street: storeAddress?.Line1 || '',
      number: '',
      floor: '',
      postalCode: storeAddress?.Postcode || '',
      city: storeAddress?.City || '',
    };
  }

  return storeAddress.AddressFields;
};
