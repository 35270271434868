import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import Spacer from '../../../ui/Spacer';
import DetailsLoading from './DetailsLoading';
import Icon from './Icon';
import InfoButton from './InfoButton';

export interface ISavingDetailsProps {
  savings: string[];
}

const SavingsDetails: React.FC<React.PropsWithChildren<ISavingDetailsProps>> = (props) => {
  const { savings } = props;

  if (savings.length === 0) {
    return <DetailsLoading />;
  }

  return (
    <List component="div">
      <ListItem
        component="div"
        className={HORIZONTAL_SPACE_CLASSNAME}
        ContainerComponent="div"
        alignItems="center"
        disableGutters
      >
        <ListItemAvatar>
          <Icon />
        </ListItemAvatar>

        <ListItemText
          primary={
            <>
              <Typography
                variant="h4"
                display="inline"
                component="span"
                aria-describedby="savings-info-button"
              >
                {savings}
              </Typography>
              <Typography variant="h5" display="inline" component="span">
                <Translate id="saved_since_partnering" />
              </Typography>
            </>
          }
        />

        <ListItemSecondaryAction>
          <Spacer size={24}>
            <InfoButton size={'infoBig'} id="savings-info-button" />
          </Spacer>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

export default SavingsDetails;
