import React, { useState } from 'react';

import { Group } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { TextSearchField } from '@fd/ui/atoms/TextSearchField';
import { Typography } from '@fd/ui/atoms/Typography';

import { catalogGroupsService } from '../../services/catalogGroup.service';
import { AdditionBox } from '../AdditionBox';

const DEFAULT_LIMIT = 50;
const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    width: '408px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 9,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  closeButton: {
    width: '100%',
  },
  createModifierGroupButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  search: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

export type AddModifierGroupProps = {
  AppId: string;
  currentModifierGroups: Group[];
  onAddGroup: (group: Group) => void;
  onClose: () => void;
  onCreateModifierGroupClick: () => void;
  translate: TranslateFunction;
};

export const AddModifierGroup = (props: AddModifierGroupProps): JSX.Element => {
  const {
    AppId,
    currentModifierGroups,
    onAddGroup,
    onClose,
    onCreateModifierGroupClick,
    translate,
  } = props;
  const classes = useStyles();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data, isPending } = useQuery({
    queryKey: [
      catalogGroupsService.getModifiersGroupForProductQueryKey,
      AppId,
      page,
      DEFAULT_LIMIT,
      searchTerm,
    ],

    queryFn: () =>
      catalogGroupsService.getCatalogGroups(AppId, page, DEFAULT_LIMIT, searchTerm, [
        'ModifierGroup',
      ]),
  });

  const handleSearchTerm = (value: string) => {
    setPage(1);
    setSearchTerm(value);
  };

  return (
    <>
      <Box ml={2} mr={2}>
        <TextSearchField
          className={classes.search}
          clearAriaLabel={`${translate('Products_SearchBox_Clear')}`}
          dataFd="modifier-group-search"
          disableLabelAnimation
          label={`${translate('Search')}`}
          onChange={handleSearchTerm}
          placeholder={`${translate('Name')}`}
        />
        <AdditionBox
          alreadyIncludedItems={currentModifierGroups}
          canAddIncludedItems
          isLoading={isPending}
          limit={DEFAULT_LIMIT}
          listItems={data?.Data}
          noItemsMessage={translate('ModifierGroups_NoModifierGroupsMessage_Title') as string}
          onAddItem={onAddGroup}
          onPageChange={setPage}
          page={page}
          title={translate('Modifier_Groups') as string}
          total={data?.TotalRecordCount || 0}
          translate={translate}
        />
        <Typography variant="caption">
          <Translate id="Create_modifier_group_suggestion" />
        </Typography>
        <Button
          className={classes.createModifierGroupButton}
          fdKey="create-new-modifier-group-button"
          onClick={onCreateModifierGroupClick}
          variant="secondary"
        >
          <Translate id="ModifierGroup_NewModifierGroup" />
        </Button>
      </Box>
      <div className={classes.actions}>
        <Divider />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.closeButton}
            fdKey="close-drawer-button"
            onClick={onClose}
            variant="secondary"
          >
            <Translate id="Close" />
          </Button>
        </div>
      </div>
    </>
  );
};
