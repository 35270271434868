import { AppCompliance, IndexPage } from '@flipdish/api-client-typescript';
import {
  AppExternalServices,
  AppSocialMediaLinks,
} from '@flipdish/api-client-typescript/private/api';
import { createReducer } from 'redux-act';

import { appsConstants } from '../../constants/apps.constants';
import {
  DELETE_WEBSITE_SETTINGS_IMAGE_FAILURE,
  DELETE_WEBSITE_SETTINGS_IMAGE_SUCCESS,
  GET_APP_EMBED_STATE_FAILURE,
  GET_APP_EMBED_STATE_SUCCESS,
  GET_DNS_STATUS_SUCCESS,
  GET_SMS_RESTAURANT_NAME_SUCCESS,
  GET_TRACKING_CODES_FAILURE,
  GET_TRACKING_CODES_SUCCESS,
  GET_WEBSITE_SETTINGS_FAILURE,
  GET_WEBSITE_SETTINGS_REQUEST,
  GET_WEBSITE_SETTINGS_SUCCESS,
  RESET_DNS_RECORDS,
  RESET_WEBSITE_STATUS_FIELDS,
  SET_APP_CONFIG_FAILURE,
  SET_APP_CONFIG_REQUEST,
  SET_APP_CONFIG_SUCCESS,
  SET_HOSTNAME_APP_CONFIG_FAILURE,
  SET_HOSTNAME_APP_CONFIG_REQUEST,
  SET_HOSTNAME_APP_CONFIG_SUCCESS,
  SET_SMS_RESTAURANT_NAME_SUCCESS,
  SET_TRACKING_CODES_SUCCESS,
  SET_WEBSITE_SETTINGS_SUCCESS,
  UPLOAD_WEBSITE_SETTINGS_IMAGE_FAILURE,
  UPLOAD_WEBSITE_SETTINGS_IMAGE_SUCCESS,
  webSiteAndAppActions,
} from './actions';

const getRecordStatus = (
  item: DNSRecord,
  action: { payload: { IsAReady: boolean; IsApiCNameReady: boolean; IsCNameReady: boolean } }
) => {
  if (item.Type === 'CNAME' && item.Host === 'www') {
    return action.payload.IsCNameReady;
  }
  if (item.Type === 'CNAME' && item.Host === 'flipdish-api') {
    return action.payload.IsApiCNameReady;
  } else {
    return action.payload.IsAReady;
  }
};

export type WebsiteState = IndexPage;

export type DNSRecord = {
  Host?: string;
  Type: 'A' | 'CNAME';
  Target: string;
  Status: boolean;
};

export enum RequestStatus {
  'SUCCESS' = 'SUCCESS',
  'ERROR' = 'ERROR',
}

type WebsiteSettingsState = {
  settings: WebsiteState;
  dns: DNSRecord[];
  isEmbed: boolean;
  hasSubdomain: boolean;
  error?: any;
  pageLoading: boolean;
  trackingCodes: AppExternalServices;
  smsRestaurantName: string;
  socialMediaLinks: AppSocialMediaLinks;
  complianceType?: AppCompliance.ComplianceTypeEnum;
  allowDisablingOfGdpr?: boolean;
  setHostnameRequestStatus?: RequestStatus;
};

const initialState: WebsiteSettingsState = {
  settings: {
    Testimonials: [],
    Images: [],
    AboutSectionEnabled: false,
    AboutSectionTitle: '',
    AboutSectionLeftTitle: '',
    AboutSectionLeftBody: '',
    AboutSectionRightTitle: '',
    AboutSectionRightBody: '',
    OpeningHoursEnabled: false,
    MenuPreviewEnabled: false,
    GalleryEnabled: false,
    TestimonialsEnabled: false,
    ContactFormEnabled: false,
    ContactFormEmail: '',
    MapEnabled: false,
  },
  dns: [
    {
      Host: '@',
      Type: 'A',
      Target: '20.54.91.94',
      Status: false,
    },
    {
      Host: 'flipdish-api',
      Type: 'CNAME',
      Target: 'websites-api.flipdish.com',
      Status: false,
    },
    {
      Host: 'www',
      Type: 'CNAME',
      Target: 'websites.flipdish.com',
      Status: false,
    },
  ],
  isEmbed: false,
  hasSubdomain: false,
  error: undefined,
  pageLoading: false,
  trackingCodes: { GoogleAnalyticsCode: '', FacebookPixelCode: '' },
  socialMediaLinks: {
    FacebookUrl: '',
    FourSquareUrl: '',
    TwitterUrl: '',
    YelpUrl: '',
    TripAdvisorUrl: '',
    InstagramUrl: '',
    PintrestUrl: '',
  },
  smsRestaurantName: '',
};

const websiteAndAppReducer = (
  state: WebsiteSettingsState = initialState,
  action
): WebsiteSettingsState => {
  let images;

  switch (action.type) {
    //#region website settings
    case GET_WEBSITE_SETTINGS_SUCCESS:
    case SET_WEBSITE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };

    case GET_WEBSITE_SETTINGS_REQUEST:
      return initialState;

    case UPLOAD_WEBSITE_SETTINGS_IMAGE_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          Images: Array.isArray(action.payload)
            ? [...state.settings.Images!, ...action.payload]
            : [...state.settings.Images!, action.payload],
        },
      };

    case DELETE_WEBSITE_SETTINGS_IMAGE_SUCCESS:
      images = state.settings.Images || [];

      return {
        ...state,
        settings: {
          ...state.settings,
          Images: images.filter((image) =>
            Array.isArray(action.payload)
              ? !action.payload.includes(image.ImageId)
              : image.ImageId !== action.payload
          ),
        },
      };
    //#endregion website settings
    //#region hostname
    case RESET_DNS_RECORDS:
      return {
        ...state,
        dns: initialState.dns,
      };
    case SET_HOSTNAME_APP_CONFIG_REQUEST:
      return {
        ...state,
        pageLoading: true,
      };

    case SET_HOSTNAME_APP_CONFIG_SUCCESS:
      return {
        ...state,
        dns: action.payload.dnsRecords,
        isEmbed: action.payload.isEmbed,
        hasSubdomain: action.payload.hasSubdomain,
        pageLoading: false,
        setHostnameRequestStatus: RequestStatus.SUCCESS,
      };
    case SET_HOSTNAME_APP_CONFIG_FAILURE: {
      return {
        ...state,
        pageLoading: false,
        setHostnameRequestStatus: RequestStatus.ERROR,
      };
    }
    case SET_APP_CONFIG_REQUEST:
      return { ...state, pageLoading: true };
    case RESET_WEBSITE_STATUS_FIELDS:
      return { ...state, setHostnameRequestStatus: undefined };
    case SET_APP_CONFIG_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        setHostnameRequestStatus: undefined,
      };
    case SET_APP_CONFIG_FAILURE:
      return { ...state, pageLoading: false, setHostnameRequestStatus: undefined };

    case appsConstants.SELECTED_APP_CHANGED:
      return {
        ...state,
      };

    case GET_DNS_STATUS_SUCCESS:
      return {
        ...state,
        dns: state.dns.map((item) => ({
          ...item,
          Status: getRecordStatus(item, action) || false,
        })),
      };

    case GET_APP_EMBED_STATE_SUCCESS:
      return {
        ...state,
        isEmbed: action.payload,
      };
    //#endregion
    //#region web&app settings
    case GET_TRACKING_CODES_SUCCESS:
      return {
        ...state,
        trackingCodes: action.payload,
      };

    case SET_TRACKING_CODES_SUCCESS:
      return {
        ...state,
        trackingCodes: action.payload,
      };
    case GET_SMS_RESTAURANT_NAME_SUCCESS:
      return {
        ...state,
        smsRestaurantName: action.payload,
      };

    case SET_SMS_RESTAURANT_NAME_SUCCESS:
      return {
        ...state,
        smsRestaurantName: action.payload,
      };

    //#endregion
    //#region errors
    case GET_WEBSITE_SETTINGS_FAILURE:
    case DELETE_WEBSITE_SETTINGS_IMAGE_FAILURE:
    case UPLOAD_WEBSITE_SETTINGS_IMAGE_FAILURE:
    case GET_TRACKING_CODES_FAILURE:
    case GET_APP_EMBED_STATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    //#endregion

    default:
      return reducer(state, action);
  }
};

const reducer = createReducer({}, initialState);
reducer.on(webSiteAndAppActions.getCompliance.success, (state, payload) => {
  return {
    ...state,
    complianceType: payload.ComplianceType,
    allowDisablingOfGdpr: payload.AllowDisablingOfGdpr,
  };
});
reducer.on(webSiteAndAppActions.setCompliance.success, (state, payload) => {
  return {
    ...state,
    complianceType: payload.ComplianceType,
  };
});

export default websiteAndAppReducer;
