import React, { ReactNode } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { logger } from '../../services/loggerService';
import ErrorScreen from './ErrorScreen';

type State = {
  hasError: boolean;
  error?: Error;
  errorInfo?: any;
};

type Props = {
  children: ReactNode;
  identifier: string | ReactNode;
};

class ErrorBoundary extends React.Component<Props & MappedProps, State> {
  public constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // log error to datadog
    const { identifier } = this.props;
    logger.error(`${identifier?.toString()} Error Boundary`, {
      message: error?.message,
      stack: errorInfo?.componentStack,
    });
  }

  public render() {
    if (this.state.hasError) {
      // Error path
      return <ErrorScreen translate={this.props.translate} />;
    }

    return this.props.children;
  }
}

const mapStateToProps = ({ locale }) => ({
  translate: getTranslate(locale),
});
type MappedProps = ReturnType<typeof mapStateToProps>;

export default compose<MappedProps, Props>(connect(mapStateToProps))(ErrorBoundary);
