import { useQuery } from '@tanstack/react-query';

import { catalogChangesService } from '../services/catalogChanges.service';

export type PendingMenuChangesSummaryParamsProps = {
  appId?: string;
};

export const useQueryPendingMenuChangesSummaryHook = (
  queryKey: any,
  params: PendingMenuChangesSummaryParamsProps,
  enabled: boolean
) => {
  const { appId } = params;
  const {
    isPending: isLoading,
    data,
    isError,
    isFetched,
  } = useQuery({
    queryKey: [queryKey],
    queryFn: () => catalogChangesService.getPendingMenuChangesSummary(appId as string),
    gcTime: 1000 * 30,
    enabled: enabled,
  });

  return {
    isLoading,
    data,
    isError,
    isFetched,
  };
};
