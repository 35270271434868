import { action } from './utils';

// #region notification
// const {
// } = constants;

export const enqueueNotification = (message: string) =>
  action('ENQUEUE_NOTIFICATION', { message });

export const enqueueTranslateNotification = (translateId: string) =>
  action('ENQUEUE_NOTIFICATION', { translateId });

export const removeNotification = () => action('REMOVE_NOTIFICATION');

// #endregion
