import { InvoicesApiFactory, SubscriptionsApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';
import { InvoiceRequest } from './types';

const api = createApi(SubscriptionsApiFactory);

const invoiceApi = createApi(InvoicesApiFactory);

// #region getSubscriptionsForApp
const getSubscriptionsForAppQueryKey = 'getSubscriptionsForApp';
export async function getSubscriptionsForApp(
  appId: string,
  excludeNotOwnedSubscriptions?: boolean,
  storeIds?: number[],
  showCancelledSubscriptions?: boolean
) {
  try {
    const incomingMessage = await api.getSubscriptionsForApp(
      appId,
      excludeNotOwnedSubscriptions,
      storeIds,
      !showCancelledSubscriptions
    );

    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getSubscriptionById
const getSubscriptionByIdQueryKey = 'getSubscriptionsForApp';
export async function getSubscriptionById(appId: string, subscriptionId: string) {
  try {
    const incomingMessage = await api.getSubscriptionById(appId, subscriptionId);

    return incomingMessage?.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getSubscriptionById
const getSubscriptionsInvoicesQueryKey = 'getSubscriptionsInvoices';
export async function getBillingInvoices({
  appId,
  subscriptionId,
  limit,
  pageId,
  excludeNotOwnedInvoices,
  dateFrom,
  dateTo,
  invoiceNumber,
  storeId,
}: InvoiceRequest) {
  try {
    const incomingMessage = await invoiceApi.listSubscriptionInvoices(
      appId,
      subscriptionId,
      limit,
      pageId,
      excludeNotOwnedInvoices,
      dateFrom as unknown as string,
      dateTo as unknown as string,
      invoiceNumber,
      storeId
    );

    return incomingMessage;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region getInvoices
export async function getInvoices({
  appId,
  subscriptionId,
  limit,
  pageId,
  excludeNotOwnedInvoices,
  dateFrom,
  dateTo,
  invoiceNumber,
}: InvoiceRequest) {
  try {
    const incomingMessage = await invoiceApi.listSubscriptionInvoices(
      appId,
      subscriptionId,
      limit,
      pageId,
      excludeNotOwnedInvoices,
      dateFrom as unknown as string,
      dateTo as unknown as string,
      invoiceNumber
    );

    return incomingMessage?.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

export const billingService = {
  getInvoices,
  getBillingInvoices,
  getSubscriptionById,
  getSubscriptionByIdQueryKey,
  getSubscriptionsForApp,
  getSubscriptionsForAppQueryKey,
  getSubscriptionsInvoicesQueryKey,
};
