import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Select from '@fd/ui/Select/Select';

import { translateAppStoreEnums } from '../../../../utils/appStoreEnumTranslateUtils';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginTop: -16,
  },
}));

export type SelectFieldProps = {
  fieldName: string;
  label: string;
  placeholder: string;
  optionsEnum: any;
  tooltip: string;
  isMulti?: boolean;
};

type Option = { value: string; label: string };

const SelectField = ({
  fieldName,
  label,
  optionsEnum,
  placeholder,
  tooltip,
  isMulti,
}: SelectFieldProps) => {
  const classes = useStyles();
  const validate = (value: string) => {
    if (!value) {
      return <Translate id="Required" />;
    }
  };

  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, submitCount } = form;
        const fieldError = errors[field.name] as string | undefined;

        const options: Option[] = Object.keys(optionsEnum).map((option) => ({
          value: option,
          label: translateAppStoreEnums(option) as string,
        }));

        let selectedValue;
        if (isMulti) {
          const preSelectedValues = field.value;
          if (preSelectedValues !== undefined) {
            selectedValue = options.filter((option: Option) =>
              preSelectedValues?.includes(option.value)
            );
          }
        } else {
          selectedValue = options.find((option: Option) => option.value === field.value);
        }

        const showError = !!fieldError && ((submitCount > 0) as boolean | undefined);

        const handleChange = (
          fieldName: string,
          option: Option[] | Option,
          shouldValidate: boolean
        ) => {
          if (Array.isArray(option)) {
            const options = option.map((o) => o.value);
            return form.setFieldValue(fieldName, options, shouldValidate);
          }

          return form.setFieldValue(fieldName, option.value, shouldValidate);
        };

        return (
          <FormItemLayout
            label={label}
            showTooltip={!!tooltip}
            tooltipOptions={{
              fdKey: `appstore_config_tooltip_${fieldName}`,
              labelText: tooltip,
            }}
          >
            <div className={classes.selectWrapper}>
              <Select
                isMulti={isMulti}
                dataFd={`app-select-filter-${fieldName}`}
                options={options}
                placeholder={placeholder}
                TextFieldProps={{
                  fdKey: `app-select-dropdown-${fieldName}`,
                  variant: 'standard',
                }}
                value={selectedValue}
                onChange={(option: Option[] | Option) => handleChange(fieldName, option, false)}
                fieldError={showError ? fieldError : undefined}
                checkMultiInputsPadding={false}
                noErrorTranslate={true}
              />
            </div>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default SelectField;
