import React from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import ListItemLink from '../../../ui/List/ListItemLink';
import Spacer from '../../../ui/Spacer';
import { getDeviceDetailsUrlFactory } from '../Device.selectors';
import DeviceListDetails from './DeviceListDetails';
import ListItemIcon from './DeviceListItemIcon';

const useStyles = makeStyles({
  icon: {
    minWidth: 'auto',
    marginRight: -8,
  },
});

type DeviceListItemProps = {
  device: HydraDeviceDetails;
  deviceId: string | undefined;
  isLast: boolean;
  deviceType: HydraDeviceDetails.DeviceTypeEnum | undefined;
};

type Props = DeviceListItemProps & MappedProps;
const DeviceListItem = (props: Props) => {
  const { detailsUrl, isLast, deviceId, device } = props;
  const classes = useStyles();

  if (!device || !detailsUrl) {
    return null;
  }

  return (
    <ListItemLink
      divider={!isLast}
      button
      disableGutters
      className={HORIZONTAL_SPACE_CLASSNAME}
      key={deviceId}
      fdKey={`${deviceId}`}
      to={detailsUrl}
      alignItems="center"
    >
      <ListItemAvatar>
        <ListItemIcon deviceType={device.DeviceType} deviceStatus={device.Status} />
      </ListItemAvatar>

      <DeviceListDetails device={device} />
      <ListIcon className={classes.icon}>
        <Spacer size={8}>
          <KeyboardArrowRight color="action" />
        </Spacer>
      </ListIcon>
    </ListItemLink>
  );
};

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
function mapStateToPropsFactory(state, props: DeviceListItemProps) {
  const getDeviceDetailsUrl = getDeviceDetailsUrlFactory(props.deviceId);
  return (state: AppState) => {
    const detailsUrl = getDeviceDetailsUrl(state);

    return {
      detailsUrl,
    };
  };
}

export default compose<Props, DeviceListItemProps>(connect(mapStateToPropsFactory))(DeviceListItem);
