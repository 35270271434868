import React, { useEffect } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Collapse from '@mui/material/Collapse';
import Hidden from '@mui/material/Hidden';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PaperContainer from '../../../ui/Layout/PaperContainer';
import Spacer from '../../../ui/Spacer';
import { loadSavings } from '../actions';
import { getSavingsFormated } from '../selectors';
import SavingsDetails from './Details';
import SavingsDetailsMobile from './Details.mobile';

type Props = MappedState & MappedDispatch;

const Savings: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { savings, isOpen, loadSavingData } = props;
  useEffect(() => {
    if (isOpen) {
      loadSavingData();
    }
  }, [isOpen]);

  return (
    <Collapse in={isOpen}>
      <PaperContainer fluid>
        <Hidden mdDown>
          <SavingsDetails savings={savings} />
        </Hidden>

        <Hidden mdUp>
          <SavingsDetailsMobile savings={savings} />
        </Hidden>
      </PaperContainer>
      <Spacer variant="horizontal" size={24} />
    </Collapse>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const currentApp = state.currentApp;
  const savings = getSavingsFormated(state);
  const isEmpty = state.home.savings.isEmpty;

  return {
    savings,
    isOpen:
      !isEmpty &&
      currentApp.AppId !== 'flipdish-global' &&
      currentApp.AppAccessLevel !== App.AppAccessLevelEnum.StoreStaff,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadSavingData: () => {
    dispatch(loadSavings());
  },
});

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(Savings);
