import React, { ChangeEvent } from 'react';

import { TermsAndPolicyData } from '@flipdish/api-client-typescript/private/api';
import { type FieldProps, type FieldValidator, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../helpers/validation';
import FormItemLayout from '../../../../ui/Forms/FormItemLayout';
import TextField from '../../../../ui/TextField/TextField';
import FieldWrapper from '../../../Finance/Banking/components/fields/FieldWrapper';

const legalFields: Array<{
  label: TranslationId;
  name: keyof TermsAndPolicyData;
  validate?: FieldValidator;
}> = [
  { label: 'Company_or_sole_trader_name', name: 'CompanyOrSoleTraderName' },
  { label: 'Trading_name', name: 'TradingName' },
  { label: 'Company_address', name: 'CompanyAddress' },
  { label: 'Company_registration_number', name: 'CompanyRegistrationNumber' },
  {
    label: 'Contact_email',
    name: 'ContactEmailAddress',
    validate: (value: string) => {
      if (!value) {
        return;
      }
      return formikValidate.email(value);
    },
  },
];

const BusinessInformationFields = () => {
  return (
    <FormItemLayout
      bottomBorder
      label={<Translate id="Business_information" />}
      description={<Translate id="Business_information_description" />}
    >
      {legalFields.map(({ label, name, validate }, idx) => {
        return (
          <FieldWrapper key={name}>
            <Field name={name} validate={validate}>
              {({ field, form }: FieldProps) => {
                const { errors, touched, isSubmitting } = form;
                const fieldError = errors[field.name] as string | undefined;
                const showError = !!fieldError && (touched[field.name] as boolean | undefined);

                return (
                  <TextField
                    {...field}
                    fdKey={name}
                    autoComplete="off"
                    fullWidth
                    disabled={isSubmitting}
                    error={showError}
                    helperText={showError ? fieldError : undefined}
                    FormHelperTextProps={{
                      children: ' ', // reserves line height for error message
                      style: { textAlign: 'right' },
                    }}
                    InputProps={{ inputProps: { style: { height: '1.1876em' } } }}
                    label={<Translate id={label} />}
                    InputLabelProps={{
                      shrink: true,
                      style: { lineHeight: 1, overflow: 'visible' },
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue(field.name, e.target.value);
                    }}
                  />
                );
              }}
            </Field>
          </FieldWrapper>
        );
      })}
    </FormItemLayout>
  );
};

export default BusinessInformationFields;
