import React, { useEffect } from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { SubscriptionSummary } from 'overrides/@flipdish/api-client-typescript/api';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@fd/ui/atoms';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import Spacer from '@fd/ui/Spacer';

import { useTracking } from '../../../../services/amplitude/useTracking';

const useStyles = makeStyles((theme: Theme) => ({
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    minWidth: 'auto',
    marginRight: -8,
  },
}));

export type SubscriptionPickerProps = {
  subscriptions?: SubscriptionSummary[];
  handleSubscriptionSelect: (subscriptionId: string) => void;
  appId: string;
};

const SubscriptionPicker = ({
  handleSubscriptionSelect,
  subscriptions,
  appId,
}: SubscriptionPickerProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent, trackNavigationEvent } = useTracking();

  useEffect(() => {
    trackEvent('Loaded subscription picker');
  }, []);

  const handleNewSubscriptionClick = () => {
    trackNavigationEvent('Navigate to new susbcription', { reason: 'User action' });
    history.push(`/${appId}/billing/checkout/new-subscription`);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        className={classes.spacingBottom}
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">
            <Translate id="Checkout_Pick_subscription_or_create_new" />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="primary"
            onClick={() => handleNewSubscriptionClick()}
            type="button"
            fdKey="create-new-subscription-button"
          >
            <Translate id="Checkout_create_new_subscription" />
          </Button>
        </Grid>
      </Grid>
      <PaperContainer fluid>
        <List>
          {subscriptions?.map((subscription, index) => (
            <ListItemButton
              alignItems="center"
              divider={!(index === subscriptions.length - 1)}
              onClick={() => {
                handleSubscriptionSelect(subscription.SubscriptionId);
              }}
              key={subscription.SubscriptionId}
              data-fd={`subscription-${subscription.SubscriptionId}`}
            >
              <ListItemText
                primary={<Typography>{subscription.Name}</Typography>}
                secondary={
                  <>
                    <Typography variant="caption" component="p">
                      {subscription.User}
                    </Typography>
                    {subscription.DefaultPaymentDescription && (
                      <Typography variant="caption" component="p">
                        <Translate id="Payment_method" /> {subscription.DefaultPaymentDescription}
                      </Typography>
                    )}
                  </>
                }
              />

              <ListIcon className={classes.icon}>
                <Spacer size={8}>
                  <KeyboardArrowRight color="action" />
                </Spacer>
              </ListIcon>
            </ListItemButton>
          ))}
        </List>
      </PaperContainer>
    </>
  );
};

export default SubscriptionPicker;
