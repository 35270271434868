export type SkipResponse = {
  skipMe: boolean;
};

type Result<R> = Promise<R | undefined> | SkipResponse;

function createSkipAbleRequest<A, R>(fn: Function): (a: A) => Result<R>;
function createSkipAbleRequest<A1, A2, R>(fn: Function): (a1: A1, a2: A2) => Result<R>;
function createSkipAbleRequest<A1, A2, A3, R>(fn: Function): (a1: A1, a2: A2, a3: A3) => Result<R>;
function createSkipAbleRequest<A1, A2, A3, A4, R>(
  fn: Function
): (a1: A1, a2: A2, a3: A3, a4: A4) => Result<R>;
function createSkipAbleRequest<A1, A2, A3, A4, A5, R>(
  fn: Function
): (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => Result<R>;

// wrapper returns fake response to make possible to ignore this response and don't do stuff
function createSkipAbleRequest(fn) {
  let requests: boolean[] = [];

  return async (...args) => {
    const index = requests.length;

    requests.push(false);

    // eslint-disable-next-line prefer-spread
    const result = await fn.apply(null, args);

    if (requests[index] !== undefined) {
      requests[index] = true;
      // if all request are done
      if (requests.every((item) => item)) {
        requests = [];
      }
    }

    // if request is not the last one returns empty response
    // you need to handle it in order to avoid unnecessary dispatching actions
    if (requests.length && index !== requests.length - 1) {
      return { skipMe: true };
    }

    // only the last is returned as it is
    return result;
  };
}

export { createSkipAbleRequest };
