import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms/Button';
import { Typography } from '@fd/ui/atoms/Typography';
import { Dialog } from '@fd/ui/molecules/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
}));

export type ArchiveMenuDialogProps = {
  isNotAssignedToStore: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  translate: TranslateFunction;
};

export const ArchiveMenuDialog = (props: ArchiveMenuDialogProps): JSX.Element => {
  const { isNotAssignedToStore, open, onCancel, onConfirm, translate } = props;
  const messageId = isNotAssignedToStore
    ? 'Are_you_sure_you_want_to_archive'
    : 'Still_have_stores_using_menu';
  const classes = useStyles();

  const getDialogButtons = () => {
    if (isNotAssignedToStore) {
      return [
        <Button
          className={classes.cancelButton}
          key="cancel-archive"
          fdKey="delete-menu-cancel"
          onClick={onCancel}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button key="confirm-archive" fdKey="delete-menu-confirm" onClick={onConfirm}>
          <Translate id="Archive" />
        </Button>,
      ];
    }
    return [
      <Button fdKey="got-it-menu-confirm" key="got-it-button" onClick={onCancel}>
        <Translate id="Got_it" />
      </Button>,
    ];
  };

  const buttons = getDialogButtons();
  return (
    <Dialog title={translate('Archive_menu') as string} open={open} actions={buttons}>
      <Typography className={classes.dialogText} variant="body2">
        <Translate id={messageId} />
      </Typography>
    </Dialog>
  );
};
