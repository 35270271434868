export const accountConstants = {
  CREATE: 'ACCOUNT_CREATE',
  CREATE_ERROR: 'ACCOUNT_CREATE_ERROR',
  CREATE_RESET: 'ACCOUNT_CREATE_RESET',

  UPDATE: 'ACCOUNT_UPDATE',
  UPDATE_REQUEST: 'ACCOUNT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ACCOUNT_UPDATE_FAILURE',

  LOGIN: 'ACCOUNT_LOGIN',
  LOGIN_AUTH0_FAILURE: 'LOGIN_AUTH0_FAILURE',
  LOGOUT_FAILURE: 'ACCOUNT_LOGOUT_FAILURE',

  GET_ACCOUNT_REQUEST: 'ACCOUNT_LOAD_REQUEST',
  GET_ACCOUNT_SUCCESS: 'ACCOUNT_LOAD_SUCCESS',
  GET_ACCOUNT_FAILURE: 'ACCOUNT_LOAD_FAILURE',

  SET_EMAIL: 'ACCOUNT_SET_EMAIL',

  SET_REFERRER: 'SET_REFERRER',

  SETUP_SURVEY: 'ACCOUNT_SETUP_SURVEY',

  SKIP_SIGNUP_STEP: 'ACCOUNT_SKIP_SIGNUP_STEP',
  SKIP_SIGNUP_STEP_REQUEST: 'ACCOUNT_SKIP_SIGNUP_STEP_REQUEST',
  SKIP_SIGNUP_STEP_SUCCESS: 'ACCOUNT_SKIP_SIGNUP_STEP_SUCCESS',
  SKIP_SIGNUP_STEP_FAILURE: 'ACCOUNT_SKIP_SIGNUP_STEP_FAILURE',

  ANSWER_SIGNUP_QUESTION: 'ACCOUNT_ANSWER_SIGNUP_QUESTION',
  ANSWER_SIGNUP_QUESTION_REQUEST: 'ACCOUNT_ANSWER_SIGNUP_QUESTION_REQUEST',
  ANSWER_SIGNUP_QUESTION_SUCCESS: 'ACCOUNT_ANSWER_SIGNUP_QUESTION_SUCCESS',
  ANSWER_SIGNUP_QUESTION_FAILURE: 'ACCOUNT_ANSWER_SIGNUP_QUESTION_FAILURE',
  ANSWER_SIGNUP_QUESTION_ERROR: 'ACCOUNT_ANSWER_SIGNUP_QUESTION_ERROR',
  ANSWER_SIGNUP_QUESTION_RESET: 'ACCOUNT_ANSWER_SIGNUP_QUESTION_RESET',

  GET_LOCALIZED_TIMEZONES: 'GET_LOCALIZED_TIMEZONES',
  GET_LOCALIZED_TIMEZONES_REQUEST: 'GET_LOCALIZED_TIMEZONES_REQUEST',
  GET_LOCALIZED_TIMEZONES_SUCCESS: 'GET_LOCALIZED_TIMEZONES_SUCCESS',
  GET_LOCALIZED_TIMEZONES_FAILURE: 'GET_LOCALIZED_TIMEZONES_FAILURE',

  GET_SUPPORTED_COUNTRIES: 'GET_SUPPORTED_COUNTRIES',

  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
};
