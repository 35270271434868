import * as React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { updatePromise } from '../../../actions/store.actions';
import { getSelectedStore } from '../../../selectors/store.selector';
import { EditableLabel } from '../../common/molecules';
import { StoreDetailsRouteParams } from '../types';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState) => {
    const data = getSelectedStore(state);
    const canEdit = state.permissions.some(
      (p) => p === App.AppResourceSetEnum.UpdateStoresName.toString()
    );
    return {
      storeName: (data && data.Name) || '',
      canEdit,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: Props) => {
  const { storeId, storeGroupId } = ownProps.match.params;
  return (dispatch: ThunkDispatch) => {
    return {
      update: (changes) => {
        dispatch(updatePromise(Number(storeId), Number(storeGroupId), changes));
      },
    };
  };
};

type Props = StoreDetailsRouteParams;
export const Title = compose<Props & MappedState & MappedDispatch, {}>(
  setDisplayName('StoreTitle'),
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
)(({ storeName, update, canEdit }) => {
  return (
    <EditableLabel
      labelKey={'Store_name_label'}
      inputKey={'Store_name_input'}
      value={storeName}
      changePropName="Name"
      onChange={update}
      disabled={!canEdit}
    />
  );
});

export default Title;
