import React, { useEffect, useState } from 'react';

import { Product, ProductReference } from '@flipdish/api-client-typescript';
import { TranslateFunction } from 'react-localize-redux';

import { GetItemResponse } from '../../../types';
import { AdditionBox } from '../../AdditionBox';
import { SelectedBox } from '../../SelectedBox';

export type ModifierSelectorProps = {
  isLoading: boolean;
  data?: GetItemResponse;
  alreadySelectedModifiers?: ProductReference[];
  onAddModifier: (newModifier: Product) => void;
  onRemoveModifier: (modiferId: string) => void;
  onSetPage: (page: number) => void;
  translate: TranslateFunction;
};

export const ModifierSelector = (props: ModifierSelectorProps): JSX.Element => {
  const {
    isLoading,
    data,
    alreadySelectedModifiers = [],
    onAddModifier,
    onRemoveModifier,
    onSetPage,
    translate,
  } = props;
  const [selectedModifiers, setSelectedModifiers] = useState<Product[]>([]);

  useEffect(() => {
    if (alreadySelectedModifiers) {
      const modifiers: Product[] = [];
      alreadySelectedModifiers.forEach((productRef) => {
        if (productRef.Product) {
          modifiers.push(productRef.Product);
        }
      });
      setSelectedModifiers(modifiers);
    }
  }, []);

  const onAddSelectedModifier = (selectedModifier: Product) => {
    const newSelectedModifiers = [...selectedModifiers, selectedModifier];
    setSelectedModifiers(newSelectedModifiers);
    onAddModifier(selectedModifier);
  };

  const handleRemoveSelectedModifier = (id: string) => {
    const currentIndex = selectedModifiers.findIndex((x) => x.CatalogItemId == id);
    const newSelectedModifiers = [...selectedModifiers];
    newSelectedModifiers.splice(currentIndex, 1);
    setSelectedModifiers(newSelectedModifiers);
    onRemoveModifier(id);
  };

  return (
    <>
      <AdditionBox
        alreadyIncludedItems={selectedModifiers}
        isLoading={isLoading}
        limit={data?.Limit || 0}
        listItems={data?.Data || []}
        noItemsMessage={translate('ModifierGroups_NoModifierGroupsMessage_Title') as string}
        onAddItem={onAddSelectedModifier}
        onPageChange={onSetPage}
        page={data?.Page || 1}
        title={translate('Modifiers') as string}
        total={data?.TotalRecordCount || 0}
        translate={translate}
      />
      <SelectedBox
        noneSelectedMessage={`${translate('No_modifiers_added_for_selection')}`}
        onRemove={handleRemoveSelectedModifier}
        title={`${translate('Selected_modifiers_for_group')}`}
        selectedItems={selectedModifiers}
      />
    </>
  );
};
