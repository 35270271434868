import React, { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import Hidden from '@mui/material/Hidden';
import makeStyles from '@mui/styles/makeStyles';
import GoogleMapReact from 'google-map-react';
import flatten from 'lodash/flatten';
import { Translate } from 'react-localize-redux';
import * as wicket from 'wicket';

import { deliveryZonesColors } from '../../../../constants/deliveryzones.constants';
import { DEFAULT_MAP_CENTER, GMAP_API_KEY } from '../../../../constants/map.constant';
import debounceFn from '../../../../custom-hooks/useDebounce';
import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import HiddenFeature from '../../../HiddenFeature';
import { Bounds, DeliveryZone, IDeliveryZone } from '../../types';
import StoreZoneSection from './StoreZoneSection';

const Wkt = wicket.Wkt;

const useStyles = makeStyles(() => ({
  heatMapContainer: {
    position: 'relative',
    height: 500,
    width: '100%',
    overflow: 'hidden',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  deliveryZoneSelector: {
    position: 'absolute',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 3,
    width: 230,
    margin: 10,
    zIndex: 1,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  dropdownIcon: {
    padding: 6,
    float: 'right',
    cursor: 'pointer',
  },
  dropdownContent: {
    maxHeight: 320,
    overflow: 'auto',
  },
}));

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];

type StoreOption = {
  allStores: boolean;
  coordinates: { Latitude: number; Longitude: number };
  currency: CurrencyEnum;
  disabled: boolean;
  hidden: boolean;
  label: string;
  value: number;
};

type Props = {
  datapoints: Position[];
  setBounds: (bounds: Bounds, zoom: number) => void;
  storesBounds: { bounds: Bounds | null; stores: StoreOption[] };
  wlMapCenter?: { Latitude?: number; Longitude?: number };
  storeZoneOptions?: IDeliveryZone<DeliveryZone[]>;
  currency?: CurrencyEnum;
  languageCode: string;
  setSelectedZoneIds: (zones: number[]) => void;
  selectedZoneIds?: number[];
};

type Position = {
  lat: number;
  lng: number;
  weight?: number;
};

type Shape = {
  [key: string]: {
    checked: boolean;
    shape: google.maps.Polygon | google.maps.Polygon[] | any;
  };
};

export const HeatMap = (props: Props) => {
  const classes = useStyles();
  const {
    datapoints,
    setBounds,
    storesBounds,
    wlMapCenter,
    storeZoneOptions,
    currency,
    languageCode,
    setSelectedZoneIds,
    selectedZoneIds,
  } = props;
  const mapCenter = {
    lat: wlMapCenter?.Latitude ? wlMapCenter?.Latitude : DEFAULT_MAP_CENTER.lat,
    lng: wlMapCenter?.Longitude ? wlMapCenter?.Longitude : DEFAULT_MAP_CENTER.lng,
  };
  const [displayZoneOptions, setDisplayZoneOptions] = useState(false);
  const [gMap, setGMap] = useState<google.maps.Map | null>(null);
  const [allShapes, setAllShapes] = useState<Shape>({});
  const [heatmapData, setHeatmapData] = useState<google.maps.visualization.HeatmapLayer>();

  useEffect(() => {
    if (gMap && datapoints) {
      setHeatmap(gMap, datapoints);
    }
  }, [datapoints]);

  useEffect(() => {
    if (gMap && storesBounds.bounds) {
      const bounds = new google.maps.LatLngBounds();
      Object.keys(storesBounds.bounds).map((key) =>
        bounds.extend(
          new google.maps.LatLng(storesBounds.bounds![key].lat, storesBounds.bounds![key].lng, true)
        )
      );
      gMap.fitBounds(bounds);
    }
  }, [storesBounds.bounds, gMap]);

  const setHeatmap = (map: google.maps.Map, data: Position[]) => {
    try {
      if (heatmapData) {
        heatmapData.setMap(null);
        setHeatmapData(heatmapData);
      }
      const heatmap = new google.maps.visualization.HeatmapLayer({
        data: data.map((point) => ({
          location: new google.maps.LatLng(point.lat, point.lng, true),
          weight: point.weight,
        })) as any,
        dissipating: true,
        radius: 36,
        opacity: 0.65,
        maxIntensity: 0.75,
      });
      heatmap.setMap(map);
      setHeatmapData(heatmap);
    } catch (err) {
      console.log(new Error(err));
    }
  };

  const createDeliveryZone = (
    type: 'polygon' | 'multipolygon',
    shape: string,
    color: string,
    map: google.maps.Map
  ) => {
    if (shape) {
      const polyOptions: google.maps.PolygonOptions = {
        fillColor: color,
        fillOpacity: 0.4,
        strokeOpacity: 0.5,
        strokeWeight: 1,
      };
      const wkt = new Wkt();
      wkt.read(shape);

      const polyData = wkt.toObject(polyOptions);
      if (type === 'polygon') {
        polyData.setMap(map);
      } else {
        polyData.forEach(function (polygon) {
          polygon.setMap(map);
        });
      }
      return polyData;
    }
    return;
  };

  const setSelectedPolygon = (checked: boolean, zone: DeliveryZone) => {
    if (checked) {
      setSelectedZoneIds([...selectedZoneIds!, zone.id]);
    } else {
      const newSelectedZoneIds = selectedZoneIds || [];
      setSelectedZoneIds(newSelectedZoneIds.filter((sz) => sz !== zone.id));
    }
  };

  const onLoadGoogleMap = ({ map }) => {
    setGMap(map);
    setHeatmap(map, datapoints);
  };

  const toggleDisplay = () => {
    setDisplayZoneOptions(!displayZoneOptions);
  };

  const setBoundsDebounced = ({ bounds, zoom }) => {
    debounceFn(() => setBounds(bounds, zoom), 500);
  };

  const onChange = ({ zoom, bounds }) => {
    setBoundsDebounced({ bounds, zoom });
  };

  const getCoordsFromPaths = (paths, minCoords, maxCoords) => {
    paths.forEach(function (path) {
      path.forEach(function (latlng) {
        if (!minCoords.lat) {
          minCoords.lat = latlng.lat();
          maxCoords.lat = latlng.lat();
          minCoords.lng = latlng.lng();
          maxCoords.lng = latlng.lng();
        }
        if (latlng.lat() < minCoords.lat) {
          minCoords.lat = latlng.lat();
        }
        if (latlng.lat() > maxCoords.lat) {
          maxCoords.lat = latlng.lat();
        }
        if (latlng.lng() < minCoords.lng) {
          minCoords.lng = latlng.lng();
        }
        if (latlng.lng() > maxCoords.lng) {
          maxCoords.lng = latlng.lng();
        }
      });
    });
    return { min: minCoords, max: maxCoords };
  };

  const fitSelectedZonesBounds = (shapes: Shape, map: google.maps.Map) => {
    let minCoords = { lat: 0, lng: 0 };
    let maxCoords = { lat: 0, lng: 0 };
    Object.keys(shapes).map((shape) => {
      const polygon = shapes[shape].shape;
      if (!Array.isArray(polygon)) {
        const { min, max } = getCoordsFromPaths(polygon.getPaths(), minCoords, maxCoords);
        minCoords = min;
        maxCoords = max;
      } else {
        polygon.map((poly) => {
          const { min, max } = getCoordsFromPaths(poly.getPaths(), minCoords, maxCoords);
          minCoords = min;
          maxCoords = max;
        });
      }
    });

    const sw = new google.maps.LatLng(minCoords.lat, minCoords.lng);
    const ne = new google.maps.LatLng(maxCoords.lat, maxCoords.lng);
    const bounds = new google.maps.LatLngBounds(sw, ne);
    map.fitBounds(bounds, 0);
  };

  const handlePolygons = () => {
    if (storeZoneOptions && Array.isArray(selectedZoneIds)) {
      const shapeIdsToRemove = Object.keys(allShapes).filter(
        (f) => selectedZoneIds.indexOf(parseInt(f, 10)) === -1
      );
      shapeIdsToRemove.map((s) => {
        if (Array.isArray(allShapes[s].shape)) {
          allShapes[s].shape.map((s) => s.setMap(null));
        } else {
          allShapes[s].shape.setMap(null);
        }
        delete allShapes[s];
      });

      const newSelectedZoneIds = selectedZoneIds.filter(
        (zoneId) => Object.keys(allShapes).indexOf(zoneId.toString()) === -1
      );
      const selectedZones = flatten(
        Object.keys(storeZoneOptions).map((zones) =>
          storeZoneOptions[zones].filter((zone) => newSelectedZoneIds.indexOf(zone.id) !== -1)
        )
      ).reduce((obj, item) => {
        const shape = item.wellKnownText;
        const type = item.locationDefinition.type;
        const count = deliveryZonesColors.length;
        const itemIndex = selectedZoneIds.indexOf(item.id);
        const color = deliveryZonesColors[itemIndex % count].fillColor;
        const newShape = createDeliveryZone(type, shape, color, gMap!);
        const zoneState = { checked: true, shape: newShape };

        obj[item.id] = zoneState;
        return obj;
      }, {});

      const newShapes = { ...allShapes, ...selectedZones };
      setAllShapes(newShapes);
    }
  };

  useEffect(() => handlePolygons(), [selectedZoneIds]);

  useEffect(() => {
    // Set bounds to fit all selected zones
    if (Object.keys(allShapes).length && gMap) {
      fitSelectedZonesBounds(allShapes, gMap);
    }
  }, [allShapes]);

  const selectAllDeliveryZones = (event: React.ChangeEvent<HTMLInputElement>) => {
    const zoneOptions = storeZoneOptions || {};
    if (event.target.checked) {
      const allZoneIds = flatten(Object.keys(zoneOptions).map((zones) => zoneOptions[zones])).map(
        (zone) => zone.id
      );
      setSelectedZoneIds(allZoneIds);
    } else {
      setSelectedZoneIds([]);
    }
  };

  const isAllZonesChecked = React.useMemo(() => {
    return allShapes && storeZoneOptions
      ? Object.keys(allShapes).length ===
          flatten(Object.keys(storeZoneOptions).map((zones) => storeZoneOptions[zones])).length
      : false;
  }, [allShapes, storeZoneOptions]);

  return (
    <ErrorBoundary identifier="heat-map">
      <div>
        <div className={classes.heatMapContainer}>
          <Hidden lgDown>
            <HiddenFeature>
              {storeZoneOptions ? (
                <div className={classes.deliveryZoneSelector}>
                  <Checkbox
                    color="default"
                    checked={isAllZonesChecked}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onClick={(event) => selectAllDeliveryZones(event as any)}
                  />
                  <span>
                    <Translate id="All_areas" />
                  </span>
                  <div className={classes.dropdownIcon} onClick={toggleDisplay}>
                    {displayZoneOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </div>
                  {displayZoneOptions ? (
                    <div className={classes.dropdownContent}>
                      <StoreZoneSection
                        storeZoneOptions={storeZoneOptions}
                        currency={currency}
                        allShapes={allShapes}
                        setSelectedPolygon={setSelectedPolygon}
                        languageCode={languageCode}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </HiddenFeature>
          </Hidden>

          <GoogleMapReact
            onGoogleApiLoaded={onLoadGoogleMap}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: GMAP_API_KEY as string }}
            defaultCenter={mapCenter}
            defaultZoom={13}
            options={{
              fullscreenControl: false,
              gestureHandling: 'cooperative',
            }}
            heatmapLibrary={true}
            onChange={onChange}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
