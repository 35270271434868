import React from 'react';

import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  paperWrapper: {
    backgroundColor: '#ffbc2c',
    marginBottom: '16px',
  },
}));

const AlertBankDetails = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.paperWrapper}>
      <Toolbar>
        <Typography variant="body2" color="textPrimary">
          <Translate id="We_are_unable_to_make_payouts_to_this_bank_account" />
        </Typography>
      </Toolbar>
    </Grid>
  );
};

export default AlertBankDetails;
