import { useQuery } from '@tanstack/react-query';

import { tutorialService } from '../tutorial.service';

const getTutorialData = async (appId, storeId, milestoneId) => {
  const response = await tutorialService.getTutorial(appId, storeId, milestoneId);

  const tutorialResponseData = response.Items.RootItems[0];
  const parsedData = {
    BankAccountId: tutorialResponseData.BankAccountId,
    MenuId: tutorialResponseData.MenuId,
    Status: tutorialResponseData.Status,
    Items: [
      {
        ...tutorialResponseData,
      },
    ],
  };

  return parsedData;
};

export default function useTutorialData(appId, storeId, milestoneId, hasPermissions: boolean) {
  return useQuery({
    queryKey: [tutorialService.getTutorialKey, appId, storeId, milestoneId],
    queryFn: () => getTutorialData(appId, storeId, milestoneId),
    enabled: !!(milestoneId && storeId && appId && hasPermissions),
  });
}
