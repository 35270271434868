import React, { ReactNode } from 'react';

import { Voucher } from '@flipdish/api-client-typescript';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import formatCurrencyWithFractions from '../../helpers/currencyFormatter';
import { activeLanguagesSelector } from '../../selectors/locale.selector';
import { dateTimeUtils } from '../../selectors/localeDateTime.selector';
import NavLink from '../../ui/NavLink/NavLink';
import { formatOpeningHour } from '../common/formatTime';
import { AuditLog } from './service';

const getEventType = (name: string) => {
  if (name.includes('order.tip.updated')) {
    return 'accepted';
  }

  if (!name.includes('order.created') && name.includes('created')) {
    return 'added';
  }

  return name.split('.').slice(-1)[0];
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const translate = getTranslate(state.locale);
  const userLocales = activeLanguagesSelector(state);
  return {
    translate,
    dtUtils: dateTimeUtils(state),
    userLocales,
  };
};

const styles = () =>
  createStyles({
    root: {
      padding: '0 10px',
    },
    title: {
      fontSize: '13px',
    },
    description: {
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.6)',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  });

type InnerProps = WithStyles<any, any> & MappedProps;

type OuterProps = {
  data: AuditLog;
  classes?: { [key: string]: string };
};

type Props = InnerProps & OuterProps;

// #region links
type AppLinkProps = { appId: string; appName?: string };
const AppLink = ({ appId, appName }: AppLinkProps) =>
  appId === '~' ? (
    <span>{`${appName || appId}`}</span>
  ) : (
    <NavLink to={`/${appId}/home`}>{appName || appId}</NavLink>
  );

type CatalogGroupLinkProps = {
  appId: string;
  groupName: string;
  groupId: string;
};
const CatalogGroupLink = ({ appId, groupName, groupId }: CatalogGroupLinkProps) => {
  return <NavLink to={`/${appId}/modifier-groups/${groupId}`}>{groupName || groupId}</NavLink>;
};

type CatalogItemLinkProps = {
  appId: string;
  itemName: string;
  itemId: string;
  itemType: 'Modifier' | 'Product';
};
const CatalogItemLink = ({ appId, itemName, itemId, itemType }: CatalogItemLinkProps) => {
  const typeString = itemType === 'Modifier' ? 'modifiers' : 'products';
  return <NavLink to={`/${appId}/${typeString}/${itemId}`}>{itemName || itemId}</NavLink>;
};

type MenuLinkProps = {
  appId: string;
  menuId: number;
  menuName?: string;
};
const MenuLink = ({ appId, menuId, menuName }: MenuLinkProps) => {
  return <NavLink to={`/${appId}/menus/${menuId}`}>{menuName || menuId}</NavLink>;
};

type StoreGroupsLinkProps = {
  appId: string;
};
const StoreGroupsLink = ({ appId }: StoreGroupsLinkProps) => (
  <NavLink to={`/${appId}/storegroups`}>{appId}</NavLink>
);

type StoreGroupLinkProps = {
  appId: string;
  storeGroupId?: number;
  storeGroupName?: string;
};
const StoreGroupLink = ({ appId, storeGroupId, storeGroupName }: StoreGroupLinkProps) => {
  return storeGroupId ? (
    <NavLink to={`/${appId}/storegroups/${storeGroupId}/stores`}>
      {storeGroupName || storeGroupId}
    </NavLink>
  ) : null;
};

type OrderLinkProps = {
  appId: string;
  orderId: number;
};
const OrderLink = ({ appId, orderId }: OrderLinkProps) =>
  orderId ? <NavLink to={`/${appId}/orders/${orderId}`}>{orderId}</NavLink> : null;

type StoreLinkProps = {
  appId: string;
  storeGroupId?: number;
  storeId?: number;
  storeName?: string;
};
const StoreLink = ({ appId, storeGroupId, storeId, storeName }: StoreLinkProps) => {
  if (!!storeId && !storeGroupId) {
    return <>{storeId}</>;
  }

  return !!storeGroupId && !!storeId ? (
    <NavLink to={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}`}>
      {storeName || storeId}
    </NavLink>
  ) : null;
};

type VoucherLinkProps = {
  appId: string;
  voucherId: number;
  voucherName?: string;
};
const VoucherLink = ({ appId, voucherId, voucherName }: VoucherLinkProps) =>
  Voucher ? (
    <NavLink to={`/${appId}/vouchers/${voucherId}`}>{voucherName || voucherId}</NavLink>
  ) : null;

// type TeammateLinkProps = {
//   appId: string;
//   userId: string;
//   userName: string;
// };
// const TeammateLink = ({ appId, userId, userName }: TeammateLinkProps) =>
//   userId ? <NavLink to={`/${appId}/teammates/${userId}`}>{userName}</NavLink> : null;
// #endregion

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type DisplayAmountProps = {
  amount?: number | string;
  currency?: CurrencyEnum;
  locale: string | string[];
};
const DisplayAmount = ({ amount, currency, locale }: DisplayAmountProps) => {
  if (amount === undefined || currency === undefined) {
    return <>{amount}</>;
  }

  return <>{formatCurrencyWithFractions(amount, locale, { currency: String(currency) })}</>;
};

const getHydraDeviceType = (
  EventType: 'assigned' | 'unassigned',
  HydraStatus?: Flipdish.HydraStatus
): TranslationId => {
  if (HydraStatus) {
    // UserType is an enum but BE returns a number
    const deviceType = HydraStatus.UserType as unknown as number;
    switch (deviceType) {
      case 0:
        return `Kiosk_${EventType}` as TranslationId;
      case 1:
        return `Terminal_${EventType}` as TranslationId;
      case 2:
        return `Legacy_printer_${EventType}` as TranslationId;
      default:
        return `hydra.${EventType}_title` as TranslationId;
    }
  } else return `hydra.${EventType}_title` as TranslationId;
};

type RenderTitleProps = {
  data: Props['data'];
  translate: Props['translate'];
};
export const renderTitle = ({ data, translate }: RenderTitleProps): ReactNode | null => {
  const { EventName } = data;
  switch (EventName) {
    case 'cardreaders.kiosk.bluetooth.updated': {
      const { BluetoothTerminalStatus } = data as Flipdish.KioskBluetoothTerminalUpdatedEvent;

      if (BluetoothTerminalStatus?.Status) {
        return translate(
          `Kiosk_Bluetooth_card_reader_${BluetoothTerminalStatus?.Status}` as TranslationId
        );
      }
      return translate(`${EventName}_title` as TranslationId);
    }

    case 'hydra.assigned': {
      const { HydraStatus } = data as Flipdish.HydraAssignedEvent;
      const deviceType = getHydraDeviceType('assigned', HydraStatus);
      return translate(deviceType);
    }
    case 'hydra.unassigned': {
      const { HydraStatus } = data as Flipdish.HydraUnAssignedEvent;
      const deviceType = getHydraDeviceType('unassigned', HydraStatus);
      return translate(deviceType);
    }
    case 'externalevent.store': {
      const { Description } = data as Flipdish.ExternalStoreEvent;
      // No language translation because this description is from an external system
      return Description;
    }
    case 'menu.bulk.edit': {
      return translate('Menu_bulk_edit');
    }
    case 'order.accepted':
    case 'order.created':
    case 'order.dispatched':
    case 'order.refunded':
    case 'order.rejected':
    case 'order.tip.updated': {
      const { Order } = data as Flipdish.OrderCreatedEvent;
      return (
        Order && (
          <>
            {Order.DeliveryType && translate(String(Order.DeliveryType) as 'Delivery')}{' '}
            {translate(getEventType(EventName), undefined, {
              missingTranslationMsg: EventName,
            })}
          </>
        )
      );
    }

    case 'store.updated': {
      const { Store } = data as Flipdish.StoreUpdatedEvent;
      if (Store && Store.IsArchived) {
        return translate('Store_archived');
      } else {
        return translate(`${EventName}_title`, undefined, { missingTranslationMsg: EventName });
      }
    }

    case 'order.fulfillment.status.updated': {
      const { StatusName } = data as Flipdish.OrderFulfillmentStatusUpdatedEvent;
      const textTranslation = translate(
        'audit_log_order_fulfillment_status_updated_title'
      ) as string;

      const replaced = textTranslation.replace('{StatusName}', StatusName as string);
      return replaced;
    }

    default: {
      const translatedText = translate(`${EventName}_title`, undefined, {
        missingTranslationMsg: `${EventName}`,
      });
      return translatedText && translatedText !== 'undefined' ? translatedText : EventName;
    }
  }
};

const renderContent = ({ data, translate, dtUtils, userLocales }: Props): ReactNode | null => {
  const { EventName, User, AppId } = data;

  switch (EventName) {
    case 'app.created':
    case 'app.updated': {
      const { AppName } = data as Flipdish.AppUpdatedEvent | Flipdish.AppCreatedEvent;
      return <AppLink appId={AppId!} appName={AppName} />;
    }

    case 'cardreaders.kiosk.bluetooth.updated': {
      const { BluetoothTerminalStatus } = data as Flipdish.KioskBluetoothTerminalUpdatedEvent;
      if (BluetoothTerminalStatus?.BatteryLevel) {
        return translate('Battery_level', { batteryLevel: BluetoothTerminalStatus.BatteryLevel });
      }
      return translate('Battery_level', { batteryLevel: 0 });
    }

    case 'campaign.loyalty.created':
    case 'campaign.loyalty.deleted':
    case 'campaign.loyalty.updated':
    case 'campaign.retention.created':
    case 'campaign.retention.deleted':
    case 'campaign.retention.updated': {
      const { StoreId } = data as
        | Flipdish.LoyaltyCampaignCreatedEvent
        | Flipdish.RetentionCampaignCreatedEvent;
      return (
        User && (
          <>
            {translate(EventName.includes('retention') ? 'Retention' : 'Loyalty')}{' '}
            {translate('campaign')} {getEventType(EventName)} {translate('for')} {translate('user')}{' '}
            {User.UserName} - {translate('phone')}: {User.UserPhoneNumber} {translate('for')}{' '}
            {translate('store')} <StoreLink appId={AppId!} storeId={StoreId} />
          </>
        )
      );
    }

    case 'catalog.item.created':
    case 'catalog.item.updated':
    case 'catalog.item.archived': {
      const { AppId, CatalogItem } = data as Flipdish.CatalogItemUpdatedEvent;
      return (
        CatalogItem && (
          <>
            {EventName === 'catalog.item.archived' ? (
              `${CatalogItem.Name}`
            ) : (
              <CatalogItemLink
                appId={AppId as string}
                itemId={CatalogItem.CatalogItemId as string}
                itemName={CatalogItem.Name}
                itemType={CatalogItem.ItemType}
              />
            )}{' '}
            {translate(getEventType(EventName) as TranslationId)} {translate('for')}:{' '}
            <AppLink appId={AppId!} />
          </>
        )
      );
    }

    case 'catalog.group.created':
    case 'catalog.group.updated':
    case 'catalog.group.archived': {
      const { AppId, Group } = data as Flipdish.CatalogGroupUpdatedEvent;
      return (
        Group && (
          <>
            {EventName === 'catalog.group.archived' ? (
              `${Group.Name}`
            ) : (
              <CatalogGroupLink
                appId={AppId as string}
                groupId={Group.CatalogItemId as string}
                groupName={Group.Name as string}
              />
            )}{' '}
            {translate(getEventType(EventName) as TranslationId)} {translate('for')}:{' '}
            <AppLink appId={AppId!} />
          </>
        )
      );
    }
    case 'customer.consent.updated': {
      const { Enabled } = data as Flipdish.CustomerConsentUpdatedEvent;
      return (
        User && (
          <>
            {User.UserName} - {User.UserPhoneNumber}{' '}
            {translate(Enabled ? 'opted_in_to_marketing' : 'opted_out_of_marketing')}
          </>
        )
      );
    }

    case 'customer.created':
    case 'customer.updated': {
      return (
        User && (
          <>
            {User.UserPhoneNumber} - {User.UserName} {translate('created_for')} {translate('app')}{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }
    case 'externalevent.store': {
      const { StoreId, OrderId } = data as Flipdish.ExternalStoreEvent;
      return (
        <>
          {translate('Store_ID')}: <StoreLink appId={AppId!} storeId={StoreId} />{' '}
          <OrderLink appId={AppId!} orderId={OrderId!} />
        </>
      );
    }
    case 'menu.bulk.edit': {
      const { MenuId, MenuName, InstanceCount, Item, IsAvailable } =
        data as Flipdish.MenuBulkEditEvent;
      const ActionString = translate(IsAvailable ? 'shown' : 'hidden') as string;
      if (InstanceCount && Item && MenuId) {
        return (
          <>
            {translate('number_of_instances_hidden_or_shown', {
              InstanceCount: InstanceCount,
              Item: Item,
              ActionString: ActionString,
            })}{' '}
            <MenuLink appId={AppId!} menuId={MenuId} menuName={MenuName} />
          </>
        );
      }
      break;
    }
    case 'menu.created': {
      const { Menu } = data as Flipdish.MenuUpdatedEvent;
      return (
        Menu && (
          <>
            {Menu.Name ? (
              <MenuLink appId={AppId!} menuId={Menu.MenuId!} menuName={Menu.Name} />
            ) : (
              ''
            )}
            {Menu.Name ? ' - ' : ''}
            <MenuLink appId={AppId!} menuId={Menu.MenuId!} /> {translate('created_for_app')}{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }
    case 'menu.async_creation.completed': {
      const { Menu } = data as Flipdish.MenuAsyncCreationCompletedEvent;
      return (
        Menu && (
          <>
            {Menu.Name ? (
              <MenuLink appId={AppId!} menuId={Menu.MenuId!} menuName={Menu.Name} />
            ) : (
              ''
            )}
            {Menu.Name ? ' - ' : ''}
            <MenuLink appId={AppId!} menuId={Menu.MenuId!} /> {translate('created_for_app')}{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }
    case 'menu.updated': {
      const { Menu } = data as Flipdish.MenuUpdatedEvent;
      return (
        Menu && (
          <>
            {Menu.Name ? (
              <MenuLink appId={AppId!} menuId={Menu.MenuId!} menuName={Menu.Name} />
            ) : (
              ''
            )}
            {Menu.Name ? ' - ' : ''}
            <MenuLink appId={AppId!} menuId={Menu.MenuId!} /> {translate('updated_for_app')}{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }

    case 'menu.option_set.created':
    case 'menu.option_set.deleted':
    case 'menu.option_set.updated': {
      const { MenuItemOptionSet, MenuId } = data as Flipdish.MenuItemOptionSetUpdatedEvent;
      return (
        MenuItemOptionSet && (
          <>
            {MenuItemOptionSet.Name}
            {MenuItemOptionSet.Name ? ' - ' : ''}
            {translate('menu')}
            {': '}
            <MenuLink appId={AppId!} menuId={MenuId!} />, {translate('app')}:{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }

    case 'menu.option_set_item.created':
    case 'menu.option_set_item.deleted':
    case 'menu.option_set_item.updated': {
      const { MenuItemOptionSetItem, MenuId } = data as Flipdish.MenuItemOptionSetItemUpdatedEvent;
      return (
        MenuItemOptionSetItem && (
          <>
            {MenuItemOptionSetItem.Name} {getEventType(EventName)} {translate('to')}{' '}
            {translate('on')} {translate('menu')}:
            <MenuLink appId={AppId!} menuId={MenuId!} />, {translate('app')}:{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }

    case 'menu.section.created':
    case 'menu.section.deleted':
    case 'menu.section.updated': {
      const { MenuSection, MenuId } = data as Flipdish.MenuSectionUpdatedEvent;
      return (
        MenuSection && (
          <>
            {MenuSection.Name} {getEventType(EventName)} {translate('to')} {translate('menu')}:{' '}
            <MenuLink appId={AppId!} menuId={MenuId!} />, {translate('app')}:{' '}
            <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }

    case 'menu.section_item.created':
    case 'menu.section_item.deleted':
    case 'menu.section_item.updated': {
      const { MenuSectionItem, MenuId } = data as Flipdish.MenuSectionItemUpdatedEvent;
      return (
        MenuSectionItem && (
          <>
            {MenuSectionItem.Name} {getEventType(EventName)} {translate('to')} {translate('menu')}:{' '}
            <MenuLink appId={AppId!} menuId={MenuId!} />, app: <StoreGroupsLink appId={AppId!} />
          </>
        )
      );
    }

    case 'order.accepted':
    case 'order.created':
    case 'order.dispatched':
    case 'order.rejected': {
      const { Order } = data as Flipdish.OrderCreatedEvent;
      return (
        Order && (
          <>
            <OrderLink appId={AppId!} orderId={Order.OrderId!} />,{' '}
            <DisplayAmount
              amount={Order.Amount}
              currency={Order.Store ? Order.Store.Currency : undefined}
              locale={userLocales}
            />
            , {Order.PaymentAccountType}
          </>
        )
      );
    }

    case 'order.refunded': {
      const { Order } = data as Flipdish.OrderRefundedEvent;
      return (
        Order && (
          <>
            <OrderLink appId={AppId!} orderId={Order.OrderId!} />,{' '}
            <DisplayAmount
              amount={Order.Amount}
              currency={Order.Store ? Order.Store.Currency : undefined}
              locale={userLocales}
            />
            , {Order.PaymentAccountType}, {translate('app')}: <AppLink appId={AppId!} />
          </>
        )
      );
    }

    case 'phone_call.ended': {
      const { PhoneCall } = data as Flipdish.PhoneCallEndedEvent;
      return (
        PhoneCall && (
          <>
            {PhoneCall.CallerNumber} call
            {PhoneCall.StoreName && (
              <>
                {' '}
                {translate('on')} {translate(EventName.includes('started') ? 'to' : 'with')}
              </>
            )}{' '}
            {PhoneCall.TimeOfCallLocal && (
              <>
                {translate('at')}{' '}
                {dtUtils.format(
                  new Date(PhoneCall.TimeOfCallLocal),
                  dtUtils.LOCAL_DATE_TIME_FORMAT
                )}
                .{' '}
              </>
            )}
            {translate('Call_length')} {PhoneCall.CallLengthInSeconds} {translate('seconds')}
          </>
        )
      );
    }

    case 'phone_call.started': {
      const { PhoneCall } = data as Flipdish.PhoneCallStartedEvent;
      return (
        PhoneCall && (
          <>
            {PhoneCall.CallerNumber} {translate('started_call')}{' '}
            {PhoneCall.StoreName && translate('to')} {PhoneCall.StoreName} {translate('at')}{' '}
            {PhoneCall.TimeOfCallLocal &&
              dtUtils.format(new Date(PhoneCall.TimeOfCallLocal), dtUtils.LOCAL_DATE_TIME_FORMAT)}
          </>
        )
      );
    }

    case 'printer.assigned_to_store':
    case 'printer.turned_off':
    case 'printer.turned_on':
    case 'printer.unassigned_from_store': {
      const { Printer, StoreGroupId, StoreId } = data as
        | Flipdish.PrinterAssignedToStoreEvent
        | Flipdish.PrinterUnassignedFromStoreEvent;
      return (
        Printer && (
          <>
            {translate('Store_ID')}:{' '}
            <StoreLink appId={AppId!} storeGroupId={StoreGroupId} storeId={StoreId} />,{' '}
            {translate('Serial_number')}: {Printer.SerialNumber}
          </>
        )
      );
    }

    case 'store.address.updated': {
      const { StoreGroupId, StoreId } = data as Flipdish.StoreAddressUpdatedEvent;
      return (
        <>
          {translate('store')}:
          <StoreLink appId={AppId!} storeGroupId={StoreGroupId} storeId={StoreId} />
        </>
      );
    }

    case 'store.business_hours_override.created':
    case 'store.business_hours_override.deleted': {
      const { BusinessHoursOverride, StoreGroupId, StoreId } =
        data as Flipdish.StoreBusinessHoursOverrideCreatedEvent;
      return (
        BusinessHoursOverride && (
          <>
            {translate('for')} {translate('store')}{' '}
            <StoreLink appId={AppId!} storeGroupId={StoreGroupId} storeId={StoreId} />.{' '}
            {BusinessHoursOverride.Type} {translate('to')}{' '}
            {BusinessHoursOverride.DeliveryType &&
              translate(String(BusinessHoursOverride.DeliveryType) as 'Delivery')}{' '}
            {translate('from')}{' '}
            {dtUtils.format(
              new Date(BusinessHoursOverride.StartTime),
              dtUtils.LOCAL_DATE_TIME_FORMAT
            )}{' '}
            {translate('to')}{' '}
            {dtUtils.format(
              new Date(BusinessHoursOverride.EndTime),
              dtUtils.LOCAL_DATE_TIME_FORMAT
            )}
          </>
        )
      );
    }

    case 'store.menu.assigned': {
      const { StoreId, MenuId } = data as Flipdish.StoreMenuAssignedEvent;
      return (
        StoreId &&
        MenuId && (
          <>
            {translate('Store')} <StoreLink appId={AppId!} storeId={StoreId} />{' '}
            {translate('assigned_menu')} <MenuLink appId={AppId!} menuId={MenuId} />
          </>
        )
      );
    }

    case 'store.opening_hours.updated': {
      const { BusinessHoursPeriod, BusinessHoursPeriodOld, DeliveryType, StoreGroupId, StoreId } =
        data as Flipdish.StoreOpeningHoursUpdatedEvent;

      return (
        BusinessHoursPeriod &&
        BusinessHoursPeriodOld && (
          <>
            {translate('for')} {translate('store')}{' '}
            <StoreLink appId={AppId!} storeGroupId={StoreGroupId} storeId={StoreId} />.{' '}
            {translate('hours_changed', {
              Day: BusinessHoursPeriodOld.DayOfWeek!.toString(),
              // @ts-ignore // BACKEND-BUG
              DeliveryType: DeliveryType === 0 ? 'Delivery' : 'Collection',
            })}
            {translate('from')}{' '}
            {formatOpeningHour(
              // @ts-ignore
              BusinessHoursPeriodOld,
              dtUtils,
              translate
            )}{' '}
            {translate('to')}{' '}
            {formatOpeningHour(
              // @ts-ignore
              BusinessHoursPeriod,
              dtUtils,
              translate
            )}
          </>
        )
      );
    }

    case 'store.created':
    case 'store.deleted':
    case 'store.updated': {
      const { Store, StoreId } = data as Flipdish.StoreCreatedEvent;
      return (
        <>
          {Store && Store.IsArchived ? (
            <span>
              {translate('store')}: {Store.StoreId}
            </span>
          ) : (
            <span>
              {translate('store')}:{' '}
              {
                <StoreLink
                  appId={AppId!}
                  storeGroupId={Store && Store.StoreGroupId}
                  storeId={StoreId}
                />
              }
            </span>
          )}
        </>
      );
    }

    case 'store_group.created':
    case 'store_group.deleted':
    case 'store_group.updated': {
      const { StoreGroup, StoreGroupId } = data as Flipdish.StoreGroupCreatedEvent;
      return (
        StoreGroup && (
          <>
            {translate('store')}:{' '}
            <StoreGroupLink
              appId={AppId!}
              storeGroupId={StoreGroupId}
              storeGroupName={StoreGroup.Name}
            />{' '}
            {getEventType(EventName)}
            (id: <StoreGroupLink appId={AppId!} storeGroupId={StoreGroupId} />)
          </>
        )
      );
    }

    case 'teammate.deleted':
    case 'teammate.updated':
    case 'teammate.invite.sent': {
      const { Teammate } = data as Flipdish.TeammateUpdatedEvent;
      return Teammate && <>{Teammate.Email}</>;
    }

    case 'voucher.created':
    case 'voucher.deleted':
    case 'voucher.updated': {
      const { Voucher, VoucherId } = data as Flipdish.VoucherCreatedEvent;
      return (
        Voucher && (
          <>
            {Voucher.VoucherType} {translate('Voucher')} {getEventType(EventName)}{' '}
            {translate('Code')}:{' '}
            <VoucherLink appId={AppId!} voucherId={VoucherId!} voucherName={Voucher.Code} />,{' '}
            {Voucher.Description && (
              <>
                {' '}
                {translate('Description')}: {Voucher.Description}
              </>
            )}
            , id: <VoucherLink appId={AppId!} voucherId={VoucherId!} />
          </>
        )
      );
    }

    case 'order.fulfillment.status.updated': {
      const { AppId, OrderId } = data as Flipdish.OrderFulfillmentStatusUpdatedEvent;
      return <OrderLink appId={AppId!} orderId={OrderId!} />;
    }

    default:
      return (
        <>
          <AppLink appId={data.AppId!} />
        </>
      );
  }
};

const LogContent = (props: Props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>{renderTitle(props)}</div>
      <div className={classes.description}>{renderContent(props)}</div>
    </div>
  );
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  withStyles(styles),
  connect(mapStateToProps)
)(LogContent);

export { EnhancedComponent as LogContent };
