import React, { useEffect, useState } from 'react';

import { Store, StoreHeader } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useInfiniteQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ActionTypes } from 'react-select/lib/types';
import { compose } from 'recompose';

import withRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../components/WithRouteSearchParams';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { loadByAppIdHeaders, loadById } from '../../services/store.service';
import Select from '../Select/Select';

type StyleProps = {
  zIndex?: number;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  select: {
    zIndex: (props) => props.zIndex ?? 99,
  },
}));

export type StoreOption = {
  label: string;
  value: string;
  isDisabled?: boolean;
  isGroup?: boolean;
  valueEndAdornment?: Store.CurrencyEnum | string;
};

export type StoreOptions = {
  stores: StoreOption[] | StoreOption;
  hasSetInitialStores?: boolean;
};

export type SelectAction = {
  action: ActionTypes;
  removedValue?: StoreOption;
  option?: StoreOption;
  values?: StoreOption[];
};

export type StoreFilterProps = {
  customStoreOptions?: StoreOption[];
  doNotAllowLastChipToBeRemoved?: boolean;
  hasAllStoresOption?: boolean;
  isClearable?: boolean;
  isCustomLoading?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  label?: TranslationId;
  maxChips?: number;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onSelectStore?: (
    selectedStores: number[] | number | undefined,
    storeOptions?: StoreOption[]
  ) => void;
  placeholder?: TranslationId;
  preSelectedStores?: StoreOption[];
  setSelectAction?: React.Dispatch<React.SetStateAction<SelectAction>>;
  useCustomStoreOptions?: boolean;
  useLoadingSkeleton?: boolean;
  variant?: 'outlined' | 'standard';
  showAllStoresAndLater?: boolean;
  zIndex?: number;
  passEmptyForAllStoreIds?: boolean;
  parentSelectedStores?: StoreOption[];
};

type InnerProps = ReturnType<typeof mapStateToProps> &
  WithRouteSearchParamsProps<string[] | string>;
type OuterProps = StoreFilterProps;
type Props = InnerProps & OuterProps;

const StoreFilter = (props: Props) => {
  const classes = useStyles({ zIndex: props.zIndex });
  const {
    appId,
    customStoreOptions,
    doNotAllowLastChipToBeRemoved,
    hasAllStoresOption,
    isClearable = true,
    isCustomLoading,
    isDisabled = false,
    isMulti,
    label,
    maxChips,
    menuPlacement,
    onSelectStore,
    placeholder,
    preSelectedStores,
    search,
    setSearch,
    setSelectAction,
    translate,
    useCustomStoreOptions,
    useLoadingSkeleton,
    variant,
    showAllStoresAndLater,
    passEmptyForAllStoreIds = false,
    parentSelectedStores,
  } = props;

  const [isLoadingStores, setIsLoadingStores] = useState<boolean>(false);

  const [isOneStoreOption, setIsOneStoreOption] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [storeOptions, setStoreOptions] = useState<StoreOption[]>([]);
  const [selectedStores, setSelectedStores] = useState<StoreOptions>({
    stores: [],
    hasSetInitialStores: false,
  });

  const allStoresOption = {
    label: showAllStoresAndLater
      ? (translate('All_stores_later') as TranslationId)
      : (translate('All_stores') as TranslationId),
    value: 'all',
    isGroup: true,
  };

  useEffect(() => {
    if (preSelectedStores?.length) {
      setSelectedStores({ stores: preSelectedStores, hasSetInitialStores: true });
    }
  }, [preSelectedStores]);

  useEffect(() => {
    if (!useCustomStoreOptions) {
      getStoresHeaders();
    }

    if (search) {
      if (isMulti) {
        const urlStoreIds = Array.isArray(search) ? search.map(Number) : [Number(search)];
        onSelectStore && onSelectStore(urlStoreIds);
      } else {
        onSelectStore && onSelectStore(Number(search));
      }
    }
  }, [appId]);

  useEffect(() => {
    if (customStoreOptions) {
      const isOneStore = !preSelectedStores?.length && customStoreOptions.length === 1;

      setIsOneStoreOption(isOneStore);
      setStoreOptions(customStoreOptions);

      if (!selectedStores.hasSetInitialStores && search) {
        const storesArray = [] as StoreOption[];
        customStoreOptions.forEach((store) => {
          if (search.includes(store.value)) {
            storesArray.push(store);
          }
        });

        return setSelectedStores({
          stores: storesArray,
          hasSetInitialStores: true,
        });
      }

      if (isMulti && !search && !isOneStore && !preSelectedStores?.length && hasAllStoresOption) {
        return setSelectedStores({
          stores: [allStoresOption],
          hasSetInitialStores: false,
        });
      }
    }
  }, [customStoreOptions]);

  const parseStoreHeaders = (storeHeaders: StoreHeader[]) => {
    return storeHeaders.map((header) => ({
      label: header.Name as string,
      value: String(header.StoreId),
    }));
  };

  const parseStore = (store: Store) => {
    return {
      label: store.Name,
      value: String(store.StoreId),
    };
  };

  const setUrlParams = (storeIds: string[] | string) => {
    setSearch(storeIds);
  };

  const getInitialSelectedStores = async (search: string[] | string) => {
    if (Array.isArray(search)) {
      {
        /* TODO: Replace loadById stores request with request for fetching store headers by Id (no API yet) */
      }
      const storeIdsRequest = search.map((id) => loadById(parseInt(id, 10)));
      const storesData = await Promise.all(storeIdsRequest);
      return storesData.map((store) => parseStore(store));
    }
    const storeData = await loadById(parseInt(search, 10));

    return [parseStore(storeData)];
  };

  // #region useInfiniteQuery
  const { data, isPending, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['loadStoresByAppIdHeaders', appId, searchQuery],
    queryFn: ({ pageParam }) => loadByAppIdHeaders({ appId, query: searchQuery, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  useEffect(() => {
    if (data && data.pages) {
      const addNewStoreOptions = data.pages.reduce<StoreOption[]>((total, page) => {
        if (page) {
          parseStoreHeaders(page).forEach((store) => total.push(store));
        }

        return total;
      }, []);
      setIsLoadingStores(isPending);
      setStoreOptions(addNewStoreOptions);
    }
  }, [data?.pages]);

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (hasScrollBar && hasNextPage && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchNextPage();
    }
  };
  //#endregion

  const getStoresHeaders = async (query?: string, page?: number, limit?: number) => {
    if (appId) {
      setIsLoadingStores(true);
      try {
        const storeHeaders = await loadByAppIdHeaders({
          appId,
          query,
          page,
          limit,
        });

        const newStores = storeHeaders ? parseStoreHeaders(storeHeaders) : [];
        const getStoreIds = newStores?.map((v) => Number(v.value));
        const isOneStore = newStores.length === 1;
        setStoreOptions(newStores as StoreOption[]);
        setIsOneStoreOption(isOneStore);
        isOneStore && onSelectStore && onSelectStore(getStoreIds, newStores as StoreOption[]);

        if (!selectedStores.hasSetInitialStores && search) {
          const selectedStores = await getInitialSelectedStores(search);
          setSelectedStores({
            stores: selectedStores as StoreOption[],
            hasSetInitialStores: true,
          });
        }

        if (isMulti && !search && !isOneStore && !preSelectedStores?.length && hasAllStoresOption) {
          setSelectedStores({
            stores: [allStoresOption],
            hasSetInitialStores: false,
          });
        }

        setIsLoadingStores(false);
      } catch (e) {
        console.log(e);
        setIsLoadingStores(false);
      }
    }
  };

  const handleSelectedAction = ({ action, option, removedValue, values }: SelectAction) => {
    if (setSelectAction) {
      switch (action) {
        case 'select-option': {
          setSelectAction({ action, option, values });
          break;
        }
        case 'remove-value': {
          setSelectAction({ action, removedValue, values });
          break;
        }
        default:
          break;
      }
    }
  };

  const handleStoresChange = (values: StoreOption[] | StoreOption, action: SelectAction) => {
    setSearchQuery('');
    handleSelectedAction({ ...action, values: values as StoreOption[] });
    const allStoreIds = passEmptyForAllStoreIds
      ? undefined
      : storeOptions?.map((v) => Number(v.value));
    const isEmptyArray = Array.isArray(values) && !values.length;

    if (doNotAllowLastChipToBeRemoved && isMulti && isEmptyArray) {
      return;
    }

    if (!values || isEmptyArray) {
      setSelectedStores({
        stores: values,
        hasSetInitialStores: selectedStores.hasSetInitialStores,
      });

      setUrlParams([]);
      // default is all stores if multi select and nothing if single select
      if (isMulti) {
        return onSelectStore && onSelectStore(allStoreIds as number[]);
      } else {
        return onSelectStore && onSelectStore(undefined);
      }
    }

    if (Array.isArray(values)) {
      const latestValue = values[values.length - 1];
      if (latestValue.value === 'all') {
        setSelectedStores({
          stores: [allStoresOption],
          hasSetInitialStores: selectedStores.hasSetInitialStores,
        });

        setUrlParams([]);
        return onSelectStore && onSelectStore(allStoreIds as number[]);
      }

      // remove "All stores" from selected values if other value is selected
      const storeOptions = values.filter((store) => store.value !== 'all');
      setSelectedStores({
        stores: storeOptions,
        hasSetInitialStores: selectedStores.hasSetInitialStores,
      });
      const storeIds = storeOptions.map((v) => v.value);
      setUrlParams(storeIds);
      return onSelectStore && onSelectStore(storeIds.map(Number), storeOptions);
    } else {
      setSelectedStores({
        stores: values,
        hasSetInitialStores: selectedStores.hasSetInitialStores,
      });

      setUrlParams([values.value]);
      return onSelectStore && onSelectStore(Number(values.value));
    }
  };

  const getOptions = () => {
    if (
      isMulti &&
      storeOptions &&
      !isOneStoreOption &&
      (!preSelectedStores?.length || hasAllStoresOption)
    ) {
      return [allStoresOption, ...storeOptions];
    }

    return storeOptions;
  };

  const getPlaceholderText = () => {
    if (isOneStoreOption && storeOptions && storeOptions[0].label) {
      return storeOptions[0].label;
    }

    if (placeholder) {
      return translate(placeholder) as TranslationId;
    }

    return translate('All_stores') as TranslationId;
  };

  const getLabelText = () => {
    if (label) {
      return translate(label) as TranslationId;
    }

    if (isOneStoreOption) {
      return translate('Store') as TranslationId;
    }

    return translate('Stores') as TranslationId;
  };

  if (useLoadingSkeleton && (isCustomLoading || !storeOptions)) {
    return (
      <Box data-fd="store-filter-skeleton">
        <Skeleton width={'50%'} height={40} />
      </Box>
    );
  }

  return (
    <ErrorBoundary identifier="store-filter-infinite-scroll">
      <Box onScroll={handleScroll}>
        <Select
          className={classes.select}
          dataFd="stores-filter-infinite"
          isClearable={isClearable}
          isDisabled={isDisabled || isOneStoreOption}
          isLoading={isCustomLoading || isLoadingStores || isFetchingNextPage}
          isMulti={isMulti}
          maxChips={maxChips}
          menuPlacement={menuPlacement}
          onChange={handleStoresChange}
          options={getOptions()}
          onFocus={() => setSearchQuery('')}
          placeholder={getPlaceholderText()}
          setSearchQuery={setSearchQuery}
          TextFieldProps={{
            fdKey: 'stores-dropdown',
            label: getLabelText(),
            name: 'stores-filter',
          }}
          value={parentSelectedStores || selectedStores.stores}
          variant={variant}
        />
      </Box>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId!,
  };
};

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'store',
  }),
  connect(mapStateToProps)
)(StoreFilter);
