import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

const useChartStyles = makeStyles(({ breakpoints }: Theme) => ({
  '@keyframes skeleton': {
    '0%': {
      backgroundPosition: '-200px 0',
    },
    '100%': {
      backgroundPosition: 'calc(200px + 100%) 0',
    },
  },
  root: {
    width: '100%',
    [breakpoints.down('md')]: {
      borderRadius: 0,
      boxShadow: 'none',
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    },
    padding: '8px',
    margin: '8px 0px',
  },
  noShadowRoot: {
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    padding: '8px',
    margin: '8px 0px',
  },
  header: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '0px 0px 0px 18px',
  },
  chart: {
    margin: 8,
    height: 205,
    position: 'relative',
    [breakpoints.down('md')]: {
      height: 'auto',
      paddingTop: '32%',
    },
  },
  skeleton: {
    backgroundColor: '#eee',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'linear-gradient( 90deg, #eee, #f5f5f5, #eee )',
    animation: '1.2s ease-in-out 0s infinite normal none running $skeleton',
  },
  message: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    fontSize: '22px',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

export const titleSkeleton = <Skeleton width={'30%'} height={22} />;

const ReportChartSkeleton = ({
  loaded,
  title,
  noCard,
}: {
  loaded?: boolean;
  title?: TranslationId;
  noCard?: boolean;
}) => {
  const classes = useChartStyles();

  return (
    <Card className={noCard ? classes.noShadowRoot : classes.root}>
      {!loaded ? (
        <CardHeader title={titleSkeleton} />
      ) : (
        title && (
          <Typography className={classes.header}>
            <Translate id={title} />
          </Typography>
        )
      )}
      <div className={`${classes.chart} ${loaded ? '' : classes.skeleton}`}>
        {loaded ? (
          <div className={classes.message}>
            <Translate id="Report_no_data_available" />
          </div>
        ) : null}
      </div>
    </Card>
  );
};

ReportChartSkeleton.defaultProps = {
  noCard: false,
};

export default ReportChartSkeleton;
