import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { type FieldProps, Field } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Select, { OptionType } from '@fd/ui/Select/Select';

import FieldWrapper from '../../../Banking/components/fields/FieldWrapper';
import { restaurantVouchersService } from '../../RestaurantVouchers.service';

type OuterProps = {
  dataFd?: string;
  description?: TranslationId;
  enableShrink?: boolean;
  fieldLabel: TranslationId;
  fieldName: string;
  placeholder?: string;
  showValueEndAdornment?: boolean;
};

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const RestaurantVoucherAssignStoresField: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { appId, dataFd, description, enableShrink, fieldLabel, fieldName, placeholder } = props;
  const [storeSelectValue, setStoreSelectValue] = useState<OptionType[]>();
  const [storeOptions, setStoreOptions] = useState<OptionType[]>();
  const [shrink, setShrink] = useState(false);

  const { data, isFetching, isPending } = useQuery({
    queryKey: ['getElegibleStoresByAppId', appId],

    queryFn: () => restaurantVouchersService.getElegibleStores(appId),
  });

  useEffect(() => {
    if (data) {
      const total: OptionType[] = [];
      data.forEach((store) =>
        total.push({
          label: store.Name as string,
          value: String(store.StoreId),
          isDisabled: false,
        })
      );
      setStoreOptions(total);
    }
  }, [data]);

  const validate = (value: string[]) => {
    if (value.length === 0 || value === undefined) {
      return <Translate id="Please_select_a_store" />;
    }
  };

  return (
    <FormItemLayout
      label={<Translate id={fieldLabel} />}
      description={description && <Translate id={description} />}
    >
      <Box>
        <FieldWrapper>
          <Field name={fieldName} validate={validate}>
            {({ field, form }: FieldProps) => {
              const { errors, touched, isSubmitting } = form;
              const fieldError = errors[field.name] as string | undefined;
              const showError = !!fieldError && (touched[field.name] as boolean | undefined);

              const handleSelectedStoreChange = (values: OptionType[]) => {
                setStoreSelectValue(values);
                const storeId = values.map((v) => v.value);

                setShrink(true);
                form.setFieldValue(fieldName, storeId, false);
                form.errors.fieldName = undefined;
              };

              return (
                <Select
                  {...field}
                  dataFd={dataFd}
                  fieldError={showError ? fieldError : undefined}
                  isClearable
                  isSearchable={false}
                  isDisabled={isSubmitting}
                  isLoading={isPending || isFetching}
                  isMulti
                  menuPlacement="auto"
                  onFocus={() => {
                    setShrink(true);
                  }}
                  onChange={(values: OptionType[]) => {
                    handleSelectedStoreChange(values);
                    form.errors.Store = undefined;
                  }}
                  options={storeOptions}
                  placeholder={placeholder}
                  TextFieldProps={{
                    fdKey: 'stores-dropdown',
                    name: 'selected-stores',
                    label: <Translate id={'Select_store(s)'} />,
                    InputLabelProps: { shrink: enableShrink ? shrink : true },
                  }}
                  value={storeSelectValue}
                  variant="standard"
                />
              );
            }}
          </Field>
        </FieldWrapper>
      </Box>
    </FormItemLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId as string,
  };
};

const EnhancedComponent = connect(mapStateToProps)(RestaurantVoucherAssignStoresField);

export default EnhancedComponent;
