import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory({
    /* Empty callback to block the default browser prompt */
    getUserConfirmation: () => {},
  }),
  selectRouterState: (state: any) => state.routing.router,
});

export { createReduxHistory, routerMiddleware, routerReducer };
