import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';

import { DateTimeUtils } from '../selectors/localeDateTime.selector';

export const timeFormat = 'HH:mm';
export const onlyTimeFormat = 'LT';
export const fullDateFormat = 'L';
export const fullDateWithTimeFormat = 'L LT';

type AppDate = string | number | moment.Moment | Date;

export const formatFullDate = (date: AppDate) => moment(date).format(fullDateFormat);

export const formatFullDateWithTime = (date: AppDate) =>
  moment(date).format(fullDateWithTimeFormat);

export const formatCalendarDate = (
  dtUtils: DateTimeUtils,
  date: Date,
  translate: TranslateFunction
): string => {
  if (dtUtils.isToday(date)) {
    return ` ${translate('Today')} ${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`;
  }
  if (dtUtils.isYesterday(date)) {
    return ` ${translate('Yesterday')} ${dtUtils.format(date, dtUtils.LOCAL_TIME_FORMAT)}`;
  }
  return ` ${dtUtils.format(date)}`;
};
