import React, { useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { Link } from 'react-router-dom';

import { Typography } from '@fd/ui/atoms/Typography';

import Tag from '../../../common/Tag';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    position: 'absolute' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  menuOptionsItem: {
    padding: '4px 12px 4px 12px',
  },
  menuOptionsIcon: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '12px',
  },
  menuOptionsText: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export type MenuOptionsProps = {
  appId: string;
  menuId?: number;
  onArchive: () => void;
  onDuplicate: () => void;
};

export const MenuOptions = (props: MenuOptionsProps) => {
  const { appId, menuId, onArchive, onDuplicate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleArchiveClick = () => {
    handleCloseOptions();
    onArchive();
  };

  const handleDuplicateClick = () => {
    handleCloseOptions();
    onDuplicate();
  };

  return (
    <>
      <IconButton
        aria-label="settings"
        data-fd={`${menuId}-settings-button`}
        onClick={handleOpenOptions}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-options"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseOptions}
      >
        <Permissions allowed={[App.AppResourceSetEnum.CreateMenu]}>
          <MenuItem
            className={classes.menuOptionsItem}
            onClick={handleDuplicateClick}
            data-fd="duplicate-menu"
          >
            <FileCopyIcon className={classes.menuOptionsIcon} />
            <Typography variantMapping={{ body1: 'p' }} className={classes.menuOptionsText}>
              <Translate id="Duplicate" />
            </Typography>
          </MenuItem>
          <MenuItem
            className={classes.menuOptionsItem}
            onClick={handleArchiveClick}
            data-fd="delete-menu"
          >
            <ArchiveIcon className={classes.menuOptionsIcon} />
            <Typography variantMapping={{ body1: 'p' }} className={classes.menuOptionsText}>
              <Translate id="Archive" />
            </Typography>
          </MenuItem>
        </Permissions>
        <Permissions allowed={[App.AppResourceSetEnum.UpdateMenuItemsHideTemporarily]}>
          <MenuItem className={classes.menuOptionsItem} data-fd="show-hide-items">
            <Link to={'/' + appId + '/menus/bulk-edit/' + menuId} className={classes.link} />
            <VisibilityOutlinedIcon className={classes.menuOptionsIcon} />
            <Typography variantMapping={{ body1: 'p' }} className={classes.menuOptionsText}>
              <Translate id="show_hide_items" />
            </Typography>
            <Tag tagName="Beta" />
          </MenuItem>
        </Permissions>
      </Menu>
    </>
  );
};
