export const itemHref = (name, appId, store, tutorialData) => {
  return {
    contact_and_location_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/general`,
    opening_hours_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/openinghours`,
    brand_logo_and_color_title: `/${appId}/settings/general`,

    delivery_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/deliverysettings`,
    collection_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/collection`,

    menu_title: tutorialData.Items[0].MenuId
      ? `/${appId}/menus/${tutorialData.Items[0].MenuId}`
      : null,

    bank_account_setup_title: `/${appId}/finance/bank-accounts`,

    verify_your_payment_method_title: tutorialData.Items[0].BankAccountId
      ? `/${appId}/finance/bank-accounts/${tutorialData.Items[0].BankAccountId}`
      : null,

    assign_bank_account_to_store_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/bankingsettings`,

    accept_or_reject_order_title: `/${appId}/orders`,

    close_store_temporarily_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/openinghouroverrides`,

    pre_order_settings_title: `/${appId}/storegroups/${store?.StoreGroupId}/stores/${store?.StoreId}/preordersettings`,

    generate_eod_report_title: `/${appId}/reports/endofday`,
  }[name];
};

export const getTranslationString = (stringLabel: string) => {
  const returnedString = stringLabel ? stringObj[stringLabel] : '';
  return returnedString || stringLabel;
};

const stringObj = {
  start_taking_orders_title: 'start_taking_orders_title',
  start_taking_orders_description: '',
  customize_your_store_title: 'customize_your_store_title',
  customize_your_store_description: 'customize_your_store_description',
  contact_and_location_title: 'contact_and_location_title',
  opening_hours_title: 'opening_hours_title',
  brand_logo_and_color_title: 'brand_logo_and_color_title',
  brand_logo_and_color_description: '',
  setup_services_title: 'setup_services_title',
  setup_services_description: 'setup_services_description',
  delivery_title: 'delivery_title',
  collection_title: 'collection_title',
  create_your_menu_title: 'create_your_menu_title',
  create_your_menu_description: 'create_your_menu_description',
  menu_title: 'menu_title',
  get_paid_title: 'get_paid_title',
  get_paid_description: 'get_paid_description',
  bank_account_setup_title: 'bank_account_setup_title',
  verify_your_payment_method_title: 'verify_your_payment_method_title',
  assign_bank_account_to_store_title: 'assign_bank_account_to_store_title',
  test_web_site_and_order_title: 'test_web_site_and_order_title',
  test_web_site_and_order_description: 'test_web_site_and_order_description',
  place_cash_order_title: 'place_cash_order_title',
  accept_or_reject_order_title: 'accept_or_reject_order_title',
  look_around_title: 'look_around_title',
  look_around_description: 'look_around_description',
  close_store_temporarily_title: 'close_store_temporarily_title',
  pre_order_settings_title: 'pre_order_settings_title',
  generate_eod_report_title: 'generate_eod_report_title',
};
