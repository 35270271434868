import React, { useEffect } from 'react';

import {
  StripeAccountLinkRequest,
  StripeConnectedAccountInfo,
} from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { usePrevious } from '../../../../custom-hooks/usePrevious';
import { createStripeConnectedAccountLink } from '../banking.actions';

export interface StripeAccount {
  stripeAccount: StripeConnectedAccountInfo;
  bankAccountId?: number;
}

type Props = StripeAccount & MappedState & MappedDispatch & RouteComponentProps;

const VerifyStripeButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { stripeAccount, setAccountLink, stripeUrl, bankAccountId, location, history } = props;
  const previousUrl = usePrevious(stripeUrl);

  const redirectToStripe = (stripeLink) => {
    window.location.href = stripeLink.Url;
  };

  const getReturnUrl = () => {
    const currentWindow = window.location.href;

    return bankAccountId
      ? currentWindow.replace('/bank-accounts/new', `/bank-accounts/${bankAccountId}`)
      : currentWindow;
  };

  const handleSubmit = () => {
    bankAccountId
      ? history.push(
          location.pathname.replace('/bank-accounts/new', `/bank-accounts/${bankAccountId}`)
        )
      : history.push(location.pathname);
    setAccountLink(stripeAccountLinkRequest);
  };

  const stripeAccountLinkRequest = {
    StripeId: stripeAccount.StripeId,
    ReturnUrl: getReturnUrl(),
    Collect: StripeAccountLinkRequest.CollectEnum.CurrentlyDue,
    Type: StripeAccountLinkRequest.TypeEnum.Onboarding,
  } as StripeAccountLinkRequest;

  useEffect(() => {
    if (previousUrl !== stripeUrl && stripeUrl !== '') redirectToStripe(stripeUrl);
  }, [stripeUrl]);

  return (
    <Button
      onClick={handleSubmit}
      color="primary"
      data-fd="Verify_with_stripe_button"
      style={{ letterSpacing: '1.25px' }}
      variant="contained"
    >
      <Translate id="Verify_with_stripe" />
    </Button>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    stripeUrl: state.banking.stripeLinkUrl,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setAccountLink: (stripeAccountLinkRequest) => {
      dispatch(createStripeConnectedAccountLink(stripeAccountLinkRequest));
    },
  };
}

export default compose<Props, StripeAccount>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyStripeButton);
