import React, { ReactNode } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';

import { icon } from '../../components/WebsiteAndApp/helpers';
import { ComponentPropsEx } from '../Common';
import TextField, { TextFieldProps } from '../TextField/TextField';

const useStyles = makeStyles({
  input: {
    lineHeight: '24px',
    paddingBottom: 9,
    height: '1.1876em',
  },
  endAdorment: {
    paddingBottom: 9,
  },
  helperText: {
    display: 'none',
  },
});
// Ex - Extended. Warning!!! Don't extend from TextField props - all UI parts must be inside component
export type TextFieldExProps = {
  value?: string;
  placeholder?: string;
  onBlur?: TextFieldProps['onBlur'];
  onChange?: TextFieldProps['onChange'];
  renderEndAdornmen?: () => ReactNode;
  fieldError?: string;
  showError?: boolean;
  isTouched?: boolean;
} & ComponentPropsEx;

export const TextFieldEx = (props: TextFieldExProps) => {
  const {
    showError,
    isTouched,
    /* eslint-disable */
    fieldError,
    renderEndAdornmen,
    /* eslint-enable */
    ...rest
  } = props;
  const classes = useStyles();

  const renderEndAdormentElement = (
    showError: boolean | undefined,
    isTouched: boolean | undefined,
    value
  ): ReactNode => {
    if (showError && isTouched) {
      return icon(showError, isTouched, value);
    }

    if (props.renderEndAdornmen) {
      return (
        <InputAdornment className={classes.endAdorment} position="end">
          {props.renderEndAdornmen()}
        </InputAdornment>
      );
    }

    return null;
  };

  return (
    <TextField
      {...rest}
      autoComplete="off"
      fdKey={props.fdKey as string}
      fullWidth
      error={showError}
      FormHelperTextProps={{
        children: ' ', // reserves line height for error message
        className: classes.helperText,
        style: { textAlign: 'right' },
      }}
      InputProps={{
        classes: { input: classes.input },
        endAdornment: renderEndAdormentElement(showError, isTouched, props.value),
      }}
      onChange={(e) => {
        props.onChange && props.onChange(e);
      }}
      onBlur={(e) => {
        props.onBlur && props.onBlur(e);
      }}
    />
  );
};
