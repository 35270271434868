import { BulkEditTableData } from '../types';

export const filterBulkHideList = (bulkHideList: BulkEditTableData[], searchParam: string) => {
  const filteredArray = bulkHideList.filter((listItem) => {
    const normalisedName = listItem.MenuElementName?.toLowerCase();
    const normalisedSearchParam = searchParam?.toLowerCase();
    if (normalisedName?.includes(normalisedSearchParam)) {
      return listItem;
    }
  });
  return filteredArray;
};
