import { SetBasketItems } from '../actions/checkout.actions';
import { checkoutConstants } from '../constants/checkout.constants';

export type Basket = {
  items: string[];
  addOns: string[];
};

const { CLEAR_BASKET_ITEMS, SET_BASKET_ITEMS } = checkoutConstants;

export interface State {
  basket: Basket;
  returnPath?: string;
}
const defaultState: State = {
  basket: {
    items: [],
    addOns: [],
  },
  returnPath: undefined,
};

export function checkout(state = defaultState, action): State {
  switch (action.type) {
    case CLEAR_BASKET_ITEMS: {
      return {
        ...state,
        basket: {
          items: [],
          addOns: [],
        },
        returnPath: undefined,
      };
    }
    case SET_BASKET_ITEMS: {
      const { payload } = action as SetBasketItems;
      return {
        ...state,
        basket: payload.basket,
        returnPath: payload.returnPathOverride || state.returnPath,
      };
    }
    default:
      return state;
  }
}
