import React, { useEffect, useState } from 'react';

import { orderBy as sort } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import ErrorBoundary from '../../../../layouts/Portal/ErrorBoundary';
import { mapStoreIdtoName, renderPeriodString } from '../../helpers';
import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  getTableDataRejectedOrdersByStore,
} from '../../OrderReport.actions';
import { rejectionsByStoreMeta } from '../../OrdersReport/OrdersMetaData';
import { getStoreHeadersList } from '../../OrdersReport/selectors';
import { PeriodPresetEnum, PeriodProps } from '../../types';
import OrderReportTable from './OrderReportTable';
import { RejectionsByStore } from './types';

type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];
type Props = {
  currentPeriodFilter: PeriodProps;
  storeIds: number[] | string[];
  deliveryTypes: Array<'delivery' | 'collection'>;
  platforms: AppTypeEnum[];
  periodType: PeriodPresetEnum;
  vouchers: string;
  userTimezone: string;
  currency?: CurrencyEnum;
  languageCode: string;
} & MappedProps &
  MappedDispatch;

const rowsPerPageLocalStorageKey = 'fd-reporting-rejected-orders-by-store-rows-per-page';

export const RejectedOrdersByStoreTable = (props: Props) => {
  const {
    stores,
    rejectionsByStoreTable,
    getTableDataRejectedOrdersByStore,
    pagination,
    currentPeriodFilter,
    storeIds,
    deliveryTypes,
    platforms,
    periodType,
    AppId,
    vouchers,
    userTimezone,
    currency,
    languageCode,
  } = props;
  const [ref, inView] = useInView({ triggerOnce: true });
  const [tableRejectionsByStoreData, setTableRejectionsByStoreData] = useState<
    RejectionsByStore[] | undefined
  >([]);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = useState<boolean | undefined>();

  useEffect(() => {
    if (inView && rejectionsByStoreTable) {
      setTableRejectionsByStoreData(mapStoreIdtoName(rejectionsByStoreTable, stores));
    }
  }, [rejectionsByStoreTable, stores, inView]);

  useEffect(() => {
    if (inView && currency) {
      getTableData({
        c: currentPeriodFilter,
        t: periodType,
        s: storeIds,
        d: deliveryTypes,
        p: platforms,
        v: vouchers,
        pg: page,
        r: rowsPerPage,
      });
    }
  }, [
    page,
    rowsPerPage,
    currentPeriodFilter,
    periodType,
    storeIds,
    deliveryTypes,
    platforms,
    vouchers,
    inView,
  ]);

  useEffect(() => {
    const rowsPerPage = localStorage.getItem(rowsPerPageLocalStorageKey);
    if (rowsPerPage) {
      setRowsPerPage(parseInt(rowsPerPage, 10));
    }
  }, [inView]);

  const getTableData = async (params: {
    c: PeriodProps;
    t: PeriodPresetEnum;
    s: number[] | string[];
    d: Array<'delivery' | 'collection'>;
    p: AppTypeEnum[];
    v: string;
    pg?: number;
    r?: number;
  }) => {
    try {
      setLoading(true);
      const { c, t, s, d, p, v, pg, r } = params;

      await getTableDataRejectedOrdersByStore(pg, r, c, t, s, d, p, v, userTimezone);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err instanceof Error ? err : new Error(err));
    }
  };

  const onSort = (orderBy, order) => {
    if (orderBy) {
      const orderedItems = sort(tableRejectionsByStoreData, orderBy, order);
      setTableRejectionsByStoreData(orderedItems);
    }
  };

  return (
    <ErrorBoundary identifier="rejected-orders-by-store-table">
      <div ref={ref}>
        <OrderReportTable
          inView={inView}
          title={'Rejections_by_store'}
          subTitle={renderPeriodString(currentPeriodFilter)}
          data={tableRejectionsByStoreData}
          metadata={rejectionsByStoreMeta}
          pagination={pagination}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          onSort={onSort}
          AppId={AppId}
          loading={loading}
          currency={currency}
          languageCode={languageCode}
          rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
        />
      </div>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const stores = getStoreHeadersList(state);
  const { rejectionsByStoreTable, rejectionsByStoreTablePagination } = state.reports.orderReports;
  return {
    AppId: state.currentApp.AppId as string,
    translate: getTranslate(state),
    stores,
    rejectionsByStoreTable,
    pagination: rejectionsByStoreTablePagination,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getTableDataRejectedOrdersByStore: (
    page,
    pageSize,
    period,
    type,
    stores,
    deliveryTypes,
    platforms,
    vouchers,
    userTimezone?
    // columns?,
    // orderBy?,
    // order?,
    // csv?
  ) =>
    dispatch(
      getTableDataRejectedOrdersByStore(
        page,
        pageSize,
        period,
        type,
        stores,
        deliveryTypes,
        platforms,
        vouchers,
        userTimezone
        // columns?,
        // orderBy?,
        // order?,
        // csv?
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectedOrdersByStoreTable);
