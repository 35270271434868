import React, { ReactNode } from 'react';

import GridContainer from './GridContainer';

type Props = {
  children: ReactNode;
};

export default function FiltersContainer(props: Props) {
  const { children } = props;

  return <GridContainer>{children}</GridContainer>;
}
