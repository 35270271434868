import React from 'react';

import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import Towelie from '../../assets/images/towelie.png';
import EmptyComponent from '../EmptyComponent';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    display: 'block',
    padding: theme.spacing(0),
  },
  tableBody: {
    display: 'block',
    padding: theme.spacing(6.25, 6, 7, 9),
  },
}));

type Props = {
  ariaTitle?: TranslationId;
  emptyTableTitle: TranslationId;
  icon?: string;
  noLink?: boolean;
  subtitle?: TranslationId;
  subtitleData?: object;
};

const EmptyTable = ({
  ariaTitle,
  emptyTableTitle,
  icon = Towelie,
  noLink,
  subtitle,
  subtitleData,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Paper>
        <Table classes={{ root: classes.table }} aria-label={ariaTitle || emptyTableTitle}>
          <TableBody
            classes={{
              root: classes.tableBody,
            }}
          >
            <tr>
              <td>
                <EmptyComponent
                  title={emptyTableTitle}
                  subtitle={subtitle}
                  subtitleData={subtitleData}
                  icon={icon}
                  noLink={noLink}
                />
              </td>
            </tr>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default EmptyTable;
