import React, { useState } from 'react';

import {
  OnboardingConfigUpdate,
  OnboardingItemUpdate,
  Store,
} from '@flipdish/api-client-typescript';
import { useMutation } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { tutorialMilestoneId } from '../../../constants/tutorial.constants';
import { myFlipdish } from '../../../helpers/externalLinks';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import useTutorialData from '../hooks/useTutorialData';
import { tutorialService } from '../tutorial.service';
import Dismiss from './Dismiss';
import { getTranslationString, itemHref } from './helpers';
import { TutorialItem } from './Item';
import { TutorialMilestone } from './Milestone';
import TutorialMilestoneComplete from './Milestone/Complete';
import { TutorialProgress } from './Progress';
import { TutorialSection } from './Section';
import SectionLoadingSkeleton from './Section/LoadingSkeleton';
import { SectionLogo } from './Section/Logo';
import Welcome from './Welcome';

type TutorialChecklistProps = {
  setIsOpen: (boolean) => void;
  setHasNotification: (boolean) => void;
  tutorialStore: Store;
  IsWelcomeScreenEnabled: boolean;
};

type Props = TutorialChecklistProps & MappedState & MappedDispatch;

const TutorialChecklist = (props: Props) => {
  const {
    app,
    closeNotifySaving,
    IsWelcomeScreenEnabled,
    notifyError,
    notifySaved,
    notifySaving,
    setHasNotification,
    setIsOpen,
    tutorialStore,
    hasOnBoardingPermissions,
  } = props;

  const [milestoneExpanded, setMilestoneExpanded] = useState<number | undefined>(undefined);
  const [welcomeDismissed, setWelcomeDismissed] = useState(false);
  const [milestoneCompleteDisplayed, setMilestoneCompleteDisplayed] = useState<number | undefined>(
    undefined
  );

  const { mutateAsync } = useMutation({
    mutationFn: ({ IsEnabled, IsWelcomeScreenEnabled, ConfigType }: OnboardingConfigUpdate) => {
      notifySaving();
      return tutorialService.updateOnboardingConfig(app.AppId!, {
        IsEnabled,
        IsWelcomeScreenEnabled,
        ConfigType,
      });
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
    },

    onError: () => {
      closeNotifySaving();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const tutorialData = useTutorialData(
    app.AppId,
    tutorialStore.StoreId,
    tutorialMilestoneId,
    hasOnBoardingPermissions
  ).data;

  if (!tutorialData) {
    return (
      <>
        {[...Array(5)].map((_, i) => (
          <SectionLoadingSkeleton key={i} />
        ))}
      </>
    );
  }

  if (milestoneCompleteDisplayed !== undefined) {
    return (
      <TutorialMilestoneComplete
        index={milestoneCompleteDisplayed}
        dismiss={() => setMilestoneCompleteDisplayed(undefined)}
      />
    );
  }

  const dismissWelcome = async () => {
    await mutateAsync({
      IsEnabled: true,
      IsWelcomeScreenEnabled: false,
      ConfigType: OnboardingConfigUpdate.ConfigTypeEnum.Tutorial,
    });
    setWelcomeDismissed(true);
  };

  if (IsWelcomeScreenEnabled && !welcomeDismissed) {
    return <Welcome dismiss={dismissWelcome} />;
  }

  const dismissTutorial = async () => {
    await mutateAsync({
      IsEnabled: false,
      IsWelcomeScreenEnabled: false,
      ConfigType: OnboardingConfigUpdate.ConfigTypeEnum.Tutorial,
    });
    setHasNotification(false);
    setIsOpen(false);
  };

  if (milestoneExpanded === undefined) {
    const firstIncompleteMilestoneIndex = tutorialData.Items.findIndex(
      (milestone) => milestone.Status !== OnboardingItemUpdate.StatusEnum.Completed
    );
    if (firstIncompleteMilestoneIndex !== -1) {
      setMilestoneExpanded(firstIncompleteMilestoneIndex);
    }
  }

  const websiteUrl = app.HostName ? `https://${app.HostName}` : myFlipdish(app.panaceaVanityUrl);
  return (
    <>
      <TutorialProgress tutorialData={tutorialData} storeData={tutorialStore} />
      {tutorialData?.Items?.map((milestone, index) => (
        <TutorialMilestone
          key={milestone.Title}
          name={getTranslationString(milestone.Title)}
          expanded={milestoneExpanded === index}
          setExpanded={() => setMilestoneExpanded(milestoneExpanded === index ? -1 : index)}
          onMilestoneComplete={() => setMilestoneCompleteDisplayed(index)}
        >
          {milestone.Items.map((section) => (
            <TutorialSection
              title={<Translate id={getTranslationString(section.Title) as TranslationId} />}
              subtitle={
                <Translate id={getTranslationString(section.Description) as TranslationId} />
              }
              icon={<SectionLogo name={getTranslationString(section.Title)} />}
              key={section.Title}
            >
              {section.Items.map((item) => (
                <TutorialItem
                  name={<Translate id={getTranslationString(item.Title) as TranslationId} />}
                  href={
                    itemHref(
                      getTranslationString(item.Title),
                      app.AppId,
                      tutorialStore,
                      tutorialData
                    ) || undefined
                  }
                  externalHref={
                    getTranslationString(item.Title) === 'place_cash_order_title'
                      ? websiteUrl
                      : undefined
                  }
                  isComplete={item.Status === OnboardingItemUpdate.StatusEnum.Completed}
                  key={getTranslationString(item.Title)}
                  setIsOpen={setIsOpen}
                />
              ))}
            </TutorialSection>
          ))}
        </TutorialMilestone>
      ))}
      <Dismiss dismiss={() => dismissTutorial()} />
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state: AppState) => {
  const getOnBoardingPermissions = permissionsSelector.hasPermissionFactory([
    'Owner',
    'FlipdishStaff',
  ]);
  return (state: AppState) => {
    return {
      app: state.currentApp,
      hasOnBoardingPermissions: getOnBoardingPermissions(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToPropsFactory, mapDispatchToProps)(TutorialChecklist);
