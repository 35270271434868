import { TableColumns } from '../../Finance/Payouts/types';

export const productTableCheckoutMeta: TableColumns[] = [
  {
    columnName: 'Product',
    translationId: 'Product',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 0,
  },
  {
    columnName: 'Qty',
    translationId: 'Qty',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 10,
  },
  {
    columnName: 'Amount',
    translationId: 'Amount',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 20,
  },
];

export const productTableMeta: TableColumns[] = productTableCheckoutMeta.concat([
  {
    columnName: 'PaymentType',
    translationId: 'paymentAccountType',
    columnType: 'String',
    isSortable: false,
    isVisible: true,
    ordinal: 40,
  },
  {
    columnName: 'Actions',
    translationId: 'Action', // TODO: Change this to use plural
    columnType: 'Unknown',
    isSortable: false,
    isVisible: true,
    ordinal: 50,
  },
]);
