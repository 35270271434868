import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const FoodSafety = lazyWithRetry(() => import('./FoodSafety'));

const FoodSafetyRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  const baseUrl = `modules/foodSafety`;
  return (
    <ErrorBoundary identifier="FoodSafety">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <FoodSafety />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.TASK_TYPES)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Task_Types" />}
                module={RMSModules.TASK_TYPES}
                url={`${baseUrl}/${RMSModules.TASK_TYPES}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.TASKS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Tasks" />}
                module={RMSModules.TASKS}
                url={`${baseUrl}/${RMSModules.TASKS}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.INCIDENT_TYPES)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Incident_Types" />}
                module={RMSModules.INCIDENT_TYPES}
                url={`${baseUrl}/${RMSModules.INCIDENT_TYPES}`}
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.INCIDENT_LOGS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Incident_Logs" />}
                module={RMSModules.INCIDENT_LOGS}
                url={`${baseUrl}/${RMSModules.INCIDENT_LOGS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.TASK_LOGS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Task_Logs" />}
                module={RMSModules.TASK_LOGS}
                url={`${baseUrl}/${RMSModules.TASK_LOGS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.STOCK_ITEM_COOKING_TEMPERATURE_LOGS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Stock_Item_Cooking_Temperature_Logs" />}
                module={RMSModules.STOCK_ITEM_COOKING_TEMPERATURE_LOGS}
                url={`${baseUrl}/${RMSModules.STOCK_ITEM_COOKING_TEMPERATURE_LOGS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.STOCK_ITEM_STORAGE_TEMPERATURE_LOGS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Stock_Item_Storage_Temperature_Logs" />}
                module={RMSModules.STOCK_ITEM_STORAGE_TEMPERATURE_LOGS}
                url={`${baseUrl}/${RMSModules.STOCK_ITEM_STORAGE_TEMPERATURE_LOGS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.APPLIANCE_TEMPERATURE_LOGS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Appliance_Temperature_Logs" />}
                module={RMSModules.APPLIANCE_TEMPERATURE_LOGS}
                url={`${baseUrl}/${RMSModules.APPLIANCE_TEMPERATURE_LOGS}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.APPLIANCE)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.FOOD_SAFETY}
                title={<Translate id="Appliances" />}
                module={RMSModules.APPLIANCE}
                url={`${baseUrl}/${RMSModules.APPLIANCE}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default FoodSafetyRoutes;
