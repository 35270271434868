import React, { useEffect } from 'react';

import { EFieldType } from '../../models/EFieldType';
import { ComponentProps, getAutoFdKey } from '../Common';
import { FkField, TFkFieldProps } from '../FkField/FkField';
import { TextFieldEx, TextFieldExProps } from '../FkText/TextFieldEx';

// Fk - Formik. Warning!!! Don't extend from TextField props - all UI parts must be inside component
// Don't add value field!!! work only over formik.
type Props = TextFieldExProps & ComponentProps;

export const FkPhoneNumber = (props: Props) => {
  const { fieldType, fieldName, ...rest } = props;
  useEffect(
    () =>
      assert(
        props.fieldType === EFieldType.PhoneNumber,
        'Use wrong control or wrong config (phone number)'
      ),
    []
  );

  return (
    <FkField componentProps={props} showHelperText={true}>
      {({ field, fieldEx, form }: TFkFieldProps) => {
        const { isSubmitting } = form;
        const { fieldError, showError, isTouched } = fieldEx;

        return (
          <TextFieldEx
            {...field}
            {...rest}
            placeholder={'+123456789'}
            fdKey={props.fdKey || getAutoFdKey(fieldType, fieldName)}
            disabled={isSubmitting || props.disabled}
            showError={showError}
            isTouched={isTouched}
            fieldError={fieldError}
            onChange={(e) => {
              form.setFieldValue(props.name, e.target.value);
              props.onChange && props.onChange(e);
            }}
            onBlur={(e) => {
              field.onBlur(e);
              props.onBlur && props.onBlur(e);
            }}
          />
        );
      }}
    </FkField>
  );
};

export default FkPhoneNumber;
