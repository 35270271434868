import React from 'react';

import { AppStoreAppSummary } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import PaperContainer from '../../../ui/Layout/PaperContainer';
import generateSrcSet from '../../../ui/utils/generateSrcSet';
import Tag from '../../common/Tag';
import { translateAppStoreEnums } from '../utils/appStoreEnumTranslateUtils';

const useStyles = makeStyles((theme: Theme) => ({
  appStoreAppCardWrapper: {
    flexGrow: 1,
  },
  appStoreAppCard: {
    minHeight: 120,
    '&:hover': { background: '#EAF2FF' },
    cursor: 'pointer',
    position: 'relative' as any,
  },
  appStoreAppTitleSection: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    position: 'absolute' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  appIcon: {
    alignItems: 'center',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 60,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
    },
  },
  categoryChip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  topDataBox: {
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
  },
  elementData: {
    marginTop: theme.spacing(1),
  },
  parentDataGrid: {
    display: 'flex',
  },
}));

export type AppStoreAppCardProps = {
  appStoreApp: AppStoreAppSummary;
  appId: string;
  destinationPath: string;
  isConfigured: boolean;
};

export const AppStoreAppCard = ({
  appStoreApp,
  destinationPath,
  isConfigured,
}: AppStoreAppCardProps) => {
  const classes = useStyles();
  const appStoreAppDescription = () => {
    if (appStoreApp.Description.length > 47) {
      return appStoreApp.Description.substring(0, 47) + '...';
    }
    return appStoreApp.Description;
  };

  const appStoreAppName = () => {
    if (appStoreApp.Name.length > 35) {
      return appStoreApp.Name.substring(0, 35) + '...';
    }
    return appStoreApp.Name;
  };
  const AppStoreAppIcon = () => {
    return (
      <Box className={classes.appIcon}>
        {appStoreApp.Logo && (
          <img
            style={{
              width: 60,
              height: 60,
              borderRadius: '10%',
            }}
            srcSet={generateSrcSet(appStoreApp.Logo, {
              width: 60,
              png: true,
            })}
            src={appStoreApp.Logo}
            data-fd={`app_store_logo_${appStoreApp.Id}`}
          />
        )}
      </Box>
    );
  };

  const getVerificationStatusTranslationId = (
    verificationStatus: AppStoreAppSummary.VerificationStatusEnum
  ) => {
    switch (verificationStatus) {
      case AppStoreAppSummary.VerificationStatusEnum.Draft:
        return 'Draft' as TranslationId;
      case AppStoreAppSummary.VerificationStatusEnum.Submitted:
        return 'Submitted' as TranslationId;
    }
  };

  return (
    <Grid className={classes.appStoreAppCardWrapper}>
      <PaperContainer hasBorderOnMobile className={classes.appStoreAppCard}>
        <Link
          to={{
            pathname: destinationPath,
          }}
          className={classes.link}
        />
        <Box className={classes.parentDataGrid}>
          <Grid container>
            <Grid>
              <AppStoreAppIcon />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs className={classes.topDataBox}>
                  <Typography variant="h5">{appStoreAppName()}</Typography>
                  <Typography variant="caption">
                    <Translate id="Built_by" />: {appStoreApp.DeveloperName}
                  </Typography>
                  <Grid>
                    <Typography variant="caption">{appStoreAppDescription()}</Typography>
                  </Grid>
                  <Grid className={classes.elementData}>
                    {appStoreApp.Categories.map((category) => (
                      <Chip
                        label={translateAppStoreEnums(String(category))}
                        variant="outlined"
                        size="small"
                        className={classes.categoryChip}
                        key={category}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {isConfigured && (
                  <span data-fd={`app_store_installed_badge_${appStoreApp.Id}`}>
                    <Tag tagName={'Installed'} showGreenTag />
                  </span>
                )}
                {appStoreApp.VerificationStatus !==
                  AppStoreAppSummary.VerificationStatusEnum.Verified && (
                  <Tag
                    tagName={
                      getVerificationStatusTranslationId(
                        appStoreApp.VerificationStatus
                      ) as TranslationId
                    }
                    showGreyTag
                  />
                )}
                {appStoreApp.Internal === true && <Tag tagName={'AppStore_Internal'} showGreyTag />}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PaperContainer>
    </Grid>
  );
};
