import React, { useRef } from 'react';

import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

import { Box } from '../Box';
import { SkeletonLoader } from '../SkeletonLoader';
import { Typography } from '../Typography';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: '10px',
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorColor: {
    color: '#e53935',
  },
  errorBorder: {
    borderColor: '#e53935',
  },
  small: {
    width: '88px',
    height: '88px',
  },
  large: {
    width: '266.67px',
    height: '160px',
  },
  imgIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
  },
}));

export type ImageContainerProps = {
  className?: string;
  disabled?: boolean;
  isError?: boolean;
  fdKey: string;
  srcSet?: string;
  isLoading?: boolean;
  title?: string;
  variant: string;
  onFileDrop: (file) => void;
};

export const ImageContainer = (props: ImageContainerProps) => {
  const classes = useStyles();
  const {
    className,
    disabled = false,
    fdKey,
    srcSet,
    isLoading = false,
    onFileDrop,
    title,
    variant = 'large',
    isError = false,
  } = props;

  const containerStyle = clsx(
    variant === 'small' && classes.small,
    variant === 'large' && classes.large
  );

  const imgContainerStyles = isError
    ? clsx(classes.box, containerStyle, classes.errorBorder)
    : clsx(classes.box, containerStyle);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles) {
        onFileDrop(acceptedFiles);
      }
    },
  });

  const handleKeyPress = (e) => {
    const isSpace = e.key === 'Space' || e.charCode === 32;
    // space causes scroll when focused on image container
    isSpace && e.preventDefault();
    if (e.key === 'Enter' || e.charCode === 13 || isSpace) {
      ref?.current?.click();
    }
  };

  const handleClick = (e) => {
    ref?.current?.click();
  };

  const ref = useRef<HTMLInputElement | null>(null);
  return (
    <div onClick={handleClick} onKeyPress={handleKeyPress} className={className}>
      {title && (
        <Typography className={isError ? classes.errorColor : ''} variant="caption">
          {title}
        </Typography>
      )}
      {isLoading ? (
        <SkeletonLoader
          fdKey={`${fdKey}-loader`}
          rows={[{ height: '100%', width: '100%', className: containerStyle }]}
        />
      ) : (
        <Box
          {...getRootProps()}
          bordered={!srcSet}
          borderType="dashed"
          centeredContent
          className={imgContainerStyles}
          data-fd={fdKey}
        >
          {srcSet ? (
            <img className={classes.image} alt="" srcSet={srcSet} />
          ) : (
            <CropOriginalIcon data-fd="imageIcon" fontSize="large" className={classes.imgIcon} />
          )}
          <input data-fd={`${fdKey}-input`} disabled={disabled} {...getInputProps()} ref={ref} />
        </Box>
      )}
    </div>
  );
};
