import React, { useState } from 'react';

import { StoreGroup, StoreGroupBase } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import withRouteSearchParams from '../../WithRouteSearchParams';

const currencyData: Array<{ key: string; value: StoreGroup.CurrencyEnum }> = ((
  passedEnum
): Array<{ key: string; value: StoreGroup.CurrencyEnum }> => {
  const data: Array<{ key: string; value: StoreGroup.CurrencyEnum }> = [];
  // tslint:disable-next-line
  for (const x in passedEnum) {
    data.push({ key: x, value: x as unknown as StoreGroupBase.CurrencyEnum });
  }
  return data;
})(StoreGroup.CurrencyEnum);

type InnerProps = MappedState;
type OuterProps = {
  onChange: (value: string) => void;
};
type Props = InnerProps & OuterProps;

const StoreGroupCurrencyFilter = (props: Props) => {
  const { onChange, translate } = props;

  const [currency, setCurrency] = useState<string>(currencyData[0].key);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
    onChange(event.target.value);
  };

  return (
    <ErrorBoundary identifier="store-group-currency-filter">
      <TextField
        select
        label={translate('Currency') as TranslationId}
        value={currency}
        onChange={handleChange}
        helperText=""
        variant="standard"
        fullWidth
        inputProps={{
          'data-fd': 'store-group-currency',
        }}
        style={{ zIndex: 9999 }}
      >
        {currencyData.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(
  withRouteSearchParams({
    name: 'store_group_currency',
  }),
  connect(mapStateToProps)
)(StoreGroupCurrencyFilter);
