import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CircleIcon = () => {
  return (
    <SvgIcon
      viewBox="-1 -1 30 30"
      style={{
        width: '30px',
        height: '30px',
        marginRight: '12px',
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M23.8994,23.8994 C18.4324,29.3674 9.5674,29.3674 4.1004,23.8994 C-1.3666,18.4324 -1.3666,9.5674 4.1004,4.1004 C9.5674,-1.3666 18.4324,-1.3666 23.8994,4.1004 C29.3674,9.5674 29.3674,18.4324 23.8994,23.8994"
          fill="#E7E8F5"
        />
        <path
          d="M23.8994,23.8994 C18.4324,29.3674 9.5674,29.3674 4.1004,23.8994 C-1.3666,18.4324 -1.3666,9.5674 4.1004,4.1004 C9.5674,-1.3666 18.4324,-1.3666 23.8994,4.1004 C29.3674,9.5674 29.3674,18.4324 23.8994,23.8994 Z"
          stroke="#9CA1D0"
          strokeWidth="1.077"
        />
        <path
          d="M25.3232,5.5054 C24.5422,6.2864 23.2752,6.2864 22.4942,5.5054 C21.7142,4.7244 21.7142,3.4584 22.4942,2.6774 C23.2752,1.8964 24.5422,1.8964 25.3232,2.6774 C26.1032,3.4584 26.1032,4.7244 25.3232,5.5054"
          fill="#9CA1D0"
        />
        <path
          d="M15.4141,15.4141 C14.6331,16.1951 13.3671,16.1951 12.5861,15.4141 C11.8051,14.6331 11.8051,13.3671 12.5861,12.5861 C13.3671,11.8051 14.6331,11.8051 15.4141,12.5861 C16.1951,13.3671 16.1951,14.6331 15.4141,15.4141"
          fill="#9CA1D0"
        />
        <g transform="translate(14.000000, 5.000000)">
          <path d="M8.874,0.1265 L-1.77635684e-15,9.0005" fill="#E7E8F5" />
          <path d="M8.874,0.1265 L-1.77635684e-15,9.0005" stroke="#9CA1D0" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default CircleIcon;
