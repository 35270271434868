import {
  END_OF_DAY_REPORT_RESET,
  END_OF_DAY_REPORT_SET_FILTER,
  LOAD_END_OF_DAY_REPORT_FAILURE,
  LOAD_END_OF_DAY_REPORT_REQUEST,
  LOAD_END_OF_DAY_REPORT_SUCCESS,
  LoadEndOfDayReportSuccess,
  setFilter,
} from './actions';

type EndOfDayDefaultState = {
  loading: boolean;
  filter: {
    storeId?: number;
    date?: Date;
  };
  data?: any;
};
const defaultState: EndOfDayDefaultState = {
  loading: true,
  filter: {},
};

function endOfDayReducer(state = defaultState, action): EndOfDayDefaultState {
  switch (action.type) {
    case END_OF_DAY_REPORT_RESET:
      return { ...defaultState };

    case END_OF_DAY_REPORT_SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...(action as setFilter).payload,
        },
      };
    }

    case LOAD_END_OF_DAY_REPORT_REQUEST:
      return { ...state, loading: true };

    case LOAD_END_OF_DAY_REPORT_SUCCESS: {
      const { payload } = action as LoadEndOfDayReportSuccess;

      return {
        ...state,
        data: payload,
        loading: false,
      };
    }

    case LOAD_END_OF_DAY_REPORT_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default endOfDayReducer;
