import React from 'react';

import { Order } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { displayCustomerNameForOrder } from '../helpers';

type Props = {
  order: Order;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingRight: theme.spacing(2),
  },
  customerName: {
    wordBreak: 'break-word',
  },
}));

const DetailsTitle = (props: Props) => {
  const { order } = props;
  const classes = useStyles();

  if (order && !order.OrderId) {
    return null;
  }

  const orderState = () => {
    const displayedOrderState =
      order.OrderState === Order.OrderStateEnum.Dispatched
        ? Order.OrderStateEnum.AcceptedByRestaurant
        : order.OrderState;
    return <Translate id={String(displayedOrderState) as TranslationId} />;
  };

  const orderType = () => {
    if (order.DeliveryType === Order.DeliveryTypeEnum.Pickup && order.PickupLocationType) {
      if (order.PickupLocationType === Order.PickupLocationTypeEnum.TakeOut) {
        return (
          <Translate>
            {(translate: any) => (translate(order.DeliveryType) as string).toLowerCase()}
          </Translate>
        );
      } else {
        return (
          <Translate>
            {(translate: any) => (translate(order.PickupLocationType) as string).toLowerCase()}
          </Translate>
        );
      }
    } else {
      return (
        <Translate>
          {(translate: any) => (translate(order.DeliveryType) as string).toLowerCase()}
        </Translate>
      );
    }
  };

  const orderId = () => {
    return `#${order.OrderId}`;
  };

  const localOrderId = () => {
    return order.AppType === Order.AppTypeEnum.Kiosk ? (
      <>
        {' '}
        <Typography component="span" variant="h5" color="textSecondary">
          <Translate id="collection_id" />
          {': '}
        </Typography>
        {`#${order.LocalOrderId}`}
      </>
    ) : null;
  };

  const name = () => {
    return order.Customer && order.Customer.Name ? (
      <>
        {', '}
        <Typography component="span" variant="h5" color="textSecondary">
          <Translate>{(translate: any) => (translate('Name') as string).toLowerCase()}</Translate>
          {': '}
        </Typography>
        <Box component="span" className={classes.customerName}>
          {order.Customer.Name.length > 150
            ? displayCustomerNameForOrder(order.Customer.Name, 150)
            : order.Customer.Name}
        </Box>
      </>
    ) : null;
  };

  const location = () => {
    return order.PickupLocationType === Order.PickupLocationTypeEnum.TableService ? (
      <>
        {', '}
        <Typography component="span" variant="h5" color="textSecondary">
          <Translate id="at" />{' '}
          <Translate>
            {(translate: any) => (translate(order.TableServiceCatagory) as string).toLowerCase()}
          </Translate>
          {': '}
        </Typography>
        {order.DropOffLocation}
      </>
    ) : null;
  };

  return (
    <Box component="span" className={classes.container}>
      {orderState()} {orderType()} {orderId()}
      {localOrderId()}
      {name()}
      {location()}
    </Box>
  );
};

export default DetailsTitle;
