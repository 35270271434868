import React, { useEffect } from 'react';

import { useKeyPress, useMouseDown } from '../../../../custom-hooks';

const ESCAPE = 'Escape';

type Props = {
  handleClickOutside: () => void;
  children: any;
};
const DateRangeSelectorWindowsHandler = ({ handleClickOutside, children }: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isRefInTarget = (e) => containerRef.current?.contains(e.target);

  const handleClick = (e: MouseEvent) => {
    const isOptionMenu = (e) => e.target!.nodeName == 'LI';

    if (!isRefInTarget(e) && !isOptionMenu(e)) {
      handleClickOutside();
    }
  };

  const callback = React.useCallback(handleClick, []);
  useMouseDown(callback);
  const keyPressed = useKeyPress({
    el: window,
    keys: [ESCAPE],
    active: true,
  });

  useEffect(() => {
    if (keyPressed.key === ESCAPE) {
      handleClickOutside();
    }
  }, [keyPressed]);

  return (
    <div id="select-container" ref={containerRef}>
      {children}
    </div>
  );
};

export default DateRangeSelectorWindowsHandler;
