import { action, actionError } from '../../../actions/utils';
import { notifyError } from '../../../layouts/Notify/actions';
import * as services from './services';

// #region reset
export const END_OF_DAY_REPORT_RESET = 'END_OF_DAY_REPORT_RESET';

export const reset = () => action(END_OF_DAY_REPORT_RESET);
// #endregion

// #region setFilter
export const END_OF_DAY_REPORT_SET_FILTER = 'END_OF_DAY_REPORT_SET_FILTER';

export type setFilter = ReturnType<typeof setFilter>;
export const setFilter = (filter?: { storeId?: number; date?: Date }) =>
  action(END_OF_DAY_REPORT_SET_FILTER, filter);
// #endregion

// #region getEndOfDayReport

export const LOAD_END_OF_DAY_REPORT = 'LOAD_END_OF_DAY_REPORT';
export const LOAD_END_OF_DAY_REPORT_REQUEST = 'LOAD_END_OF_DAY_REPORT_REQUEST';
export const LOAD_END_OF_DAY_REPORT_SUCCESS = 'LOAD_END_OF_DAY_REPORT_SUCCESS';
export const LOAD_END_OF_DAY_REPORT_FAILURE = 'LOAD_END_OF_DAY_REPORT_FAILURE';

type LoadEndOfDayReportProps = { storeId: number; date?: Date };

export type LoadEndOfDayReportRequest = ReturnType<typeof loadEndOfDayReportRequest>;
export const loadEndOfDayReportRequest = (props: LoadEndOfDayReportProps) =>
  action(LOAD_END_OF_DAY_REPORT_REQUEST, props);

export type LoadEndOfDayReportSuccess = ReturnType<typeof loadEndOfDayReportSuccess>;
export const loadEndOfDayReportSuccess = (loadSavingData, props: LoadEndOfDayReportProps) =>
  action(LOAD_END_OF_DAY_REPORT_SUCCESS, loadSavingData, props);

export type LoadEndOfDayReportFailure = ReturnType<typeof loadEndOfDayReportFailure>;
export const loadEndOfDayReportFailure = (error: Error, props: LoadEndOfDayReportProps) =>
  actionError(LOAD_END_OF_DAY_REPORT_FAILURE, props, error);

export function loadEndOfDayReport() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const filter = getState().reports.endOfDay.filter;
    if (!filter.storeId) {
      console.error(new Error(`Missing 'storeId' in 'state.reports.endOfDay'!`));
      return;
    }

    try {
      dispatch(loadEndOfDayReportRequest(filter as LoadEndOfDayReportProps));

      const result = await services.getEndOfDayReport(filter as LoadEndOfDayReportProps);
      dispatch(loadEndOfDayReportSuccess(result, filter as LoadEndOfDayReportProps));
    } catch (error) {
      dispatch(loadEndOfDayReportFailure(error, filter as LoadEndOfDayReportProps));

      dispatch(
        notifyError({
          message: error.message,
        })
      );
    }
  };
}
// #endregion
