import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import Switch from '../../ui/Switch/Switch';

type Props = {
  id: TranslationId;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
  name: string;
};

const CookiePolicyTitle: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { id, name, onChange, disabled = false, checked = false } = props;

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle2">
        <Translate id={id} />
      </Typography>

      <Switch name={name} disabled={disabled} checked={checked} onChange={onChange} fdKey={id} />
    </Box>
  );
};

export default CookiePolicyTitle;
