import React, { useCallback } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-timezone';

import DateTimePicker from '../DateTimePicker/DateTimePicker';

export type DateValue = moment.Moment | null;
type Props = {
  startDate: DateValue;
  endDate: DateValue;
  startLabel?: string;
  endLabel?: string;
  format?: string;
  withTime?: boolean;
  onChange: (from: DateValue, to: DateValue) => void;
};

const RangeDatePicker = ({
  startDate = null,
  endDate = null,
  startLabel = 'From',
  endLabel = 'To',
  format = 'L',
  onChange,
  withTime,
}: Props) => {
  const handleStartDateChange = useCallback(
    (date: any) => {
      const newDate = withTime ? moment(date) : moment(date).hour(0).minute(0).second(0);
      onChange(newDate, endDate);
    },
    [endDate]
  );
  const handleEndDateChange = useCallback(
    (date: any) => {
      const newDate = withTime ? moment(date) : moment(date).hour(23).minute(59).second(59);

      onChange(startDate, newDate);
    },
    [startDate]
  );
  const now = moment();

  return withTime ? (
    <>
      <DateTimePicker
        slotProps={{
          textField: {
            variant: 'standard',
            label: startLabel,
            inputProps: { placeholder: '' },
          },
          popper: { style: { zIndex: 9999 } },
        }}
        maxDate={endDate || now}
        value={startDate}
        format={format}
        onChange={handleStartDateChange}
      />
      <DateTimePicker
        slotProps={{
          textField: {
            variant: 'standard',
            label: endLabel,
            inputProps: { placeholder: '' },
          },
          popper: { style: { zIndex: 9999 } },
        }}
        minDate={startDate || undefined}
        maxDate={now}
        value={endDate}
        format={format}
        onChange={handleEndDateChange}
      />
    </>
  ) : (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        slotProps={{
          textField: { variant: 'standard', label: startLabel, inputProps: { placeholder: '' } },
          popper: { style: { zIndex: 9999 } },
        }}
        maxDate={endDate || now}
        value={startDate}
        format={format}
        onChange={handleStartDateChange}
      />
      <DatePicker
        slotProps={{
          textField: {
            variant: 'standard',
            label: endLabel,
            inputProps: { placeholder: '' },
          },
          popper: { style: { zIndex: 9999 } },
        }}
        minDate={startDate}
        maxDate={now}
        value={endDate}
        format={format}
        onChange={handleEndDateChange}
      />
    </LocalizationProvider>
  );
};

export default RangeDatePicker;
