import React from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import List from '@mui/material/List';

import ListItemMobile from './ListItemMobile';

export interface OuterProps {
  teammates: FlipdishAPI.Teammate[];
  resendInvite: (teammate: FlipdishAPI.Teammate, event: React.MouseEvent<HTMLElement>) => void;
  invitePending: (teammate: FlipdishAPI.Teammate) => boolean;
  mapAppAccessLevel: (
    appAccessLevel: FlipdishAPI.App.AppAccessLevelEnum | undefined
  ) => JSX.Element;
}

type Props = OuterProps;

export const ListMobile = (props: Props): JSX.Element => {
  const { teammates, resendInvite, invitePending, mapAppAccessLevel } = props;
  return (
    <List>
      {teammates.map((teammate, index) => {
        return (
          <ListItemMobile
            key={index}
            teammate={teammate}
            resendInvite={resendInvite}
            invitePending={invitePending}
            mapAppAccessLevel={mapAppAccessLevel}
          />
        );
      })}
    </List>
  );
};
