import React, { useCallback, useMemo } from 'react';

import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { DataTable, TDataTableHeader } from '../../../ui/DataTable';
import {
  getNotificationsWithLabel,
  StoreOrderNotificationContactDetailsWithLabel,
} from './EmailTable';

type InnerProps = MappedState;
type OuterProps = {
  onRemove: (notification: StoreOrderNotificationContactDetails) => void;
  smsOrderNotifications: StoreOrderNotificationContactDetails[];
};
type Props = InnerProps & OuterProps;

const SmsTable = ({ translate, onRemove, smsOrderNotifications }: Props) => {
  const notifications = useMemo<StoreOrderNotificationContactDetailsWithLabel[]>(
    () => getNotificationsWithLabel(translate, smsOrderNotifications),
    [smsOrderNotifications]
  );

  const tableHeaders = useMemo(() => {
    const result: Array<TDataTableHeader<StoreOrderNotificationContactDetailsWithLabel>> = [
      {
        labelId: 'Phone_number',
        name: 'ContactDetail',
        renderDataCell: ({ value, row }) => (
          <>
            <Box style={{ lineHeight: '1.85' }}>{value}</Box>
            <Hidden smUp={true}>
              <Box style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{row.whenLabel}</Box>
            </Hidden>
          </>
        ),
      },
      {
        labelId: 'When',
        name: 'whenLabel',
        hiddenProps: { mdDown: true },
        width: '60%',
        renderDataCell: ({ value }) => <Box style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{value}</Box>,
      },
      {
        labelId: 'Remove',
        width: '10%',
        name: 'removeColumn',
        renderDataCell: (arg) => (
          <Box style={{ textAlign: 'center' }}>
            <IconButton
              style={{ padding: 4 }}
              onClick={() => handleDeleteClick(arg)}
              color="inherit"
            >
              <DeleteOutlineIcon fontSize={'small'} />
            </IconButton>
          </Box>
        ),
      },
    ];
    return result;
  }, []);

  const handleDeleteClick = useCallback((arg: any) => {
    onRemove(arg.row);
  }, []);

  return (
    <DataTable<StoreOrderNotificationContactDetailsWithLabel>
      uniqueFieldName="StoreOrderContactDetailId"
      headers={tableHeaders}
      data={notifications}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(SmsTable);
