import React from 'react';

import Hidden from '@mui/material/Hidden';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Translate } from 'react-localize-redux';

import { type TDataTableHeader } from './index';
import { StyledTableCell } from './table-cell';

export function TableHeaders<T>({ headers }: { headers: Array<TDataTableHeader<T>> }) {
  const collgroups = headers.map((header) => {
    return (
      <Hidden key={header.name} {...header.hiddenProps}>
        <col span={1} style={{ width: header.width }} />
      </Hidden>
    );
  });

  const tableHeaders = headers.map((header) => {
    assert(!(header.labelId && header.label), "can't use labelId and label together");
    const headerElement = header.labelId ? (
      <Translate id={header.labelId} />
    ) : header.label ? (
      header.label
    ) : null;
    return (
      <Hidden key={header.name} {...header.hiddenProps}>
        <StyledTableCell key={header.name} variant="head">
          {headerElement}
        </StyledTableCell>
      </Hidden>
    );
  });

  return (
    <>
      <colgroup>{collgroups}</colgroup>
      <TableHead>
        <TableRow>{tableHeaders}</TableRow>
      </TableHead>
    </>
  );
}
