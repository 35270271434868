export enum EFieldType {
  Text = 'text',
  Email = 'email',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Boolean = 'Boolean',
  Array = 'Array',
  PhoneNumber = 'PhoneNumber',
  RadioGroup = 'RadioGroup',
}
