import React, { useState } from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import Button from '../../../ui/Button/Button';
import TablePagination from '../../common/TablePagination/TablePagination';

type OAuthAppAuthTokensProps = {
  authTokens: FlipdishAPI.OAuthTokenModel[];
  count: number;
  page: number;
  rowsPerPage: number;
  setSelectedTokenKey: (value: string | undefined) => void;
  rowPerPageOptions: number[];
  handleChangePage: (e, value: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  setDialogOpen: (value: boolean) => void;
};

type Props = OAuthAppAuthTokensProps;

const OAuthAppAuthTokens = (props: Props) => {
  const {
    authTokens,
    count,
    page,
    rowsPerPage,
    setSelectedTokenKey,
    rowPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    setDialogOpen,
  } = props;

  const [hoveredRow, setHoveredRow] = useState<number | null>();

  return (
    <>
      <Table>
        <colgroup>
          <col width="35%" />
          <col width="45%" />
          <col width="20%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell align="left" component="th">
              <Typography color="textSecondary">
                <Translate id="User" />
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="textSecondary">
                <Translate id="Expiry" />
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton disabled disableRipple>
                <DeleteOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody onMouseLeave={() => setHoveredRow(null)}>
          {authTokens.map((token, index) => (
            <TableRow hover key={index} onMouseEnter={(e) => setHoveredRow(index)}>
              <TableCell component="th" align="left">
                <Typography variant="body1">{token.SubjectId}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">
                  {moment(token.Expiry).format('DD/MM/YYYY HH:mm')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  fdKey="revoke-token-text-icon"
                  size="small"
                  color="primary"
                  style={{ visibility: hoveredRow === index ? 'visible' : 'hidden' }}
                  onMouseEnter={() => {
                    setSelectedTokenKey(token.Key);
                  }}
                  onClick={() => setDialogOpen(true)}
                >
                  <DeleteOutlined fontSize="small" />
                  <Typography color="primary" variant="button">
                    <Translate id="Revoke" />
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default compose<Props, OAuthAppAuthTokensProps>()(OAuthAppAuthTokens);
