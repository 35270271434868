import { OrdersTableColumns } from '../types';

export const orderByStoreMeta: OrdersTableColumns[] = [
  {
    columnName: 'store',
    translationId: 'Store_name',
    isVisible: true,
    ordinal: 0,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'orderAmount',
    translationId: 'Order_amount',
    isVisible: true,
    ordinal: 20,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'acceptedOrders',
    translationId: 'Accepted_orders',
    isVisible: true,
    ordinal: 30,
    isSortable: true,
    columnType: 'Integer',
  },
  {
    columnName: 'averageOrderSize',
    translationId: 'Average_order_size',
    isVisible: true,
    ordinal: 40,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'rejectedOrders',
    translationId: 'Rejected_orders',
    isVisible: true,
    ordinal: 50,
    isSortable: false,
    columnType: 'Integer',
  },
  {
    columnName: 'rejectedOrderAmount',
    translationId: 'Rejected_order_amount',
    isVisible: true,
    ordinal: 60,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'refundedOrders',
    translationId: 'Refunded_orders',
    isVisible: true,
    ordinal: 70,
    isSortable: false,
    columnType: 'Integer',
  },
  {
    columnName: 'refundedOrderAmount',
    translationId: 'Refunded_order_amount',
    isVisible: true,
    ordinal: 70,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'deliveryTaxAmount',
    translationId: 'Delivery_tax_amount',
    isVisible: true,
    ordinal: 80,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'foodTaxAmount',
    translationId: 'Food_tax_amount',
    isVisible: true,
    ordinal: 90,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'tipTaxAmount',
    translationId: 'Tip_tax_amount',
    isVisible: true,
    ordinal: 100,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'totalTaxAmount',
    translationId: 'Total_tax_amount',
    isVisible: true,
    ordinal: 110,
    isSortable: false,
    columnType: 'Decimal',
  },
];

export const ordersMeta: OrdersTableColumns[] = [
  {
    columnName: 'orderId',
    translationId: 'Order_id',
    isVisible: true,
    ordinal: 0,
    isSortable: false,
    columnType: 'String',
  },
  //#region App/Store info
  {
    columnName: 'appId',
    translationId: 'AppId',
    isVisible: false,
    ordinal: 10,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'storeId',
    translationId: 'Store_ID',
    isVisible: false,
    ordinal: 30,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'storeName',
    translationId: 'Store_name',
    isVisible: false,
    ordinal: 32,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region Customer info
  {
    columnName: 'customerId',
    translationId: 'User_id',
    isVisible: false,
    ordinal: 40,
    isSortable: true,
    columnType: 'Integer',
  },
  {
    columnName: 'customerName',
    translationId: 'Customer_name',
    isVisible: false,
    ordinal: 50,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region order payment/status/time info
  {
    columnName: 'orderState',
    translationId: 'Order_status',
    isVisible: true,
    ordinal: 60,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'orderType',
    translationId: 'Delivery_collection',
    isVisible: false,
    ordinal: 70,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'channel',
    translationId: 'Channel',
    isVisible: false,
    ordinal: 80,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountType',
    translationId: 'Payment_account_type',
    isVisible: false,
    ordinal: 90,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountDescription',
    translationId: 'Payment_account_description',
    isVisible: false,
    ordinal: 110,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'placedAtTimeLocal',
    translationId: 'Placed_at_time_local',
    isVisible: false,
    ordinal: 130,
    isSortable: false,
    columnType: 'DateTime',
  },
  {
    columnName: 'requestedForTimeLocal',
    translationId: 'Requested_for_time_local',
    isVisible: false,
    ordinal: 150,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'acceptedAtTimeLocal',
    translationId: 'Accepted_at_time_local',
    isVisible: false,
    ordinal: 170,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'currency',
    translationId: 'Currency',
    isVisible: false,
    ordinal: 190,
    isSortable: false,
    columnType: 'CurrencyCode',
  },
  //#endregion
  //#region amounts
  {
    columnName: 'customerAmount',
    translationId: 'Customer_amount',
    isVisible: false,
    ordinal: 200,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'subtotal',
    translationId: 'Subtotal',
    isVisible: false,
    ordinal: 210,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'tipAmount',
    translationId: 'Tip_amount',
    isVisible: false,
    ordinal: 220,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'deliveryCharge',
    translationId: 'Delivery_amount',
    isVisible: false,
    ordinal: 230,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'netAmount',
    translationId: 'Net_amount',
    isVisible: true,
    ordinal: 240,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'refundAmount',
    translationId: 'Refund_amount',
    isVisible: false,
    ordinal: 250,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'tipAmountTax',
    translationId: 'Tip_amount_tax',
    isVisible: false,
    ordinal: 260,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'deliveryChargeTax',
    translationId: 'Delivery_charge_tax',
    isVisible: false,
    ordinal: 270,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'subtotalTax',
    translationId: 'Subtotal_tax',
    isVisible: false,
    ordinal: 280,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'totalTax',
    translationId: 'Total_tax',
    isVisible: false,
    ordinal: 290,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'customerFee',
    translationId: 'Customer_fee',
    isVisible: false,
    ordinal: 310,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'customerFeeReclaim',
    translationId: 'Customer_fee_reclaim',
    isVisible: false,
    ordinal: 320,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'storeFee',
    translationId: 'Store_fee',
    isVisible: false,
    ordinal: 330,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'storeFeeIncludingVat',
    translationId: 'Store_fees_vat',
    isVisible: false,
    ordinal: 340,
    isSortable: true,
    columnType: 'Decimal',
  },
  //#endregion
  //#region location/notes
  {
    columnName: 'customerLocation',
    translationId: 'Customer_location',
    isVisible: false,
    ordinal: 350,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'deliveryLocation',
    translationId: 'Delivery_location',
    isVisible: false,
    ordinal: 360,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'building',
    translationId: 'Building',
    isVisible: false,
    ordinal: 370,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'street',
    translationId: 'Street',
    isVisible: false,
    ordinal: 380,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'town',
    translationId: 'Town',
    isVisible: false,
    ordinal: 390,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'customerOrderNotes',
    translationId: 'Customer_order_notes',
    isVisible: false,
    ordinal: 400,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
];

export const rejectedOrdersMeta: OrdersTableColumns[] = [
  {
    columnName: 'orderId',
    translationId: 'Order_id',
    isVisible: true,
    ordinal: 0,
    isSortable: false,
    columnType: 'String',
  },
  //#region App/Store info
  {
    columnName: 'appId',
    translationId: 'AppId',
    isVisible: false,
    ordinal: 10,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'storeId',
    translationId: 'Store_ID',
    isVisible: false,
    ordinal: 30,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'storeName',
    translationId: 'Store_name',
    isVisible: true,
    ordinal: 32,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region Customer info
  {
    columnName: 'customerId',
    translationId: 'User_id',
    isVisible: false,
    ordinal: 40,
    isSortable: true,
    columnType: 'Integer',
  },
  {
    columnName: 'customerName',
    translationId: 'Customer_name',
    isVisible: false,
    ordinal: 50,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
  //#region order payment/status/time info
  {
    columnName: 'orderState',
    translationId: 'Order_status',
    isVisible: true,
    ordinal: 60,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'orderType',
    translationId: 'Delivery_collection',
    isVisible: false,
    ordinal: 70,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'channel',
    translationId: 'Channel',
    isVisible: false,
    ordinal: 80,
    isSortable: true,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountType',
    translationId: 'Payment_account_type',
    isVisible: false,
    ordinal: 90,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'paymentAccountDescription',
    translationId: 'Payment_account_description',
    isVisible: false,
    ordinal: 110,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'placedAtTimeLocal',
    translationId: 'Placed_at_time_local',
    isVisible: false,
    ordinal: 130,
    isSortable: false,
    columnType: 'DateTime',
  },
  {
    columnName: 'requestedForTimeLocal',
    translationId: 'Requested_for_time_local',
    isVisible: false,
    ordinal: 150,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'rejectedAtTimeLocal',
    translationId: 'Rejected_at_time_local',
    isVisible: false,
    ordinal: 170,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'rejectedReason',
    translationId: 'Reason_for_rejection',
    isVisible: true,
    ordinal: 180,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'currency',
    translationId: 'Currency',
    isVisible: false,
    ordinal: 190,
    isSortable: false,
    columnType: 'CurrencyCode',
  },
  //#endregion
  //#region amounts
  {
    columnName: 'customerAmount',
    translationId: 'Customer_amount',
    isVisible: false,
    ordinal: 200,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'subtotal',
    translationId: 'Subtotal',
    isVisible: false,
    ordinal: 210,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'tipAmount',
    translationId: 'Tip_amount',
    isVisible: false,
    ordinal: 220,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'deliveryCharge',
    translationId: 'Delivery_amount',
    isVisible: false,
    ordinal: 230,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'netAmount',
    translationId: 'Net_amount',
    isVisible: true,
    ordinal: 240,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'refundAmount',
    translationId: 'Refund_amount',
    isVisible: false,
    ordinal: 250,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'tipAmountTax',
    translationId: 'Tip_amount_tax',
    isVisible: false,
    ordinal: 260,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'deliveryChargeTax',
    translationId: 'Delivery_charge_tax',
    isVisible: false,
    ordinal: 270,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'subtotalTax',
    translationId: 'Subtotal_tax',
    isVisible: false,
    ordinal: 280,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'totalTax',
    translationId: 'Total_tax',
    isVisible: false,
    ordinal: 290,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'customerFee',
    translationId: 'Customer_fee',
    isVisible: false,
    ordinal: 310,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'customerFeeReclaim',
    translationId: 'Customer_fee_reclaim',
    isVisible: false,
    ordinal: 320,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'storeFee',
    translationId: 'Store_fee',
    isVisible: false,
    ordinal: 330,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'storeFeeIncludingVat',
    translationId: 'Store_fees_vat',
    isVisible: false,
    ordinal: 340,
    isSortable: true,
    columnType: 'Decimal',
  },
  //#endregion
  //#region location/notes
  {
    columnName: 'customerLocation',
    translationId: 'Customer_location',
    isVisible: false,
    ordinal: 350,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'deliveryLocation',
    translationId: 'Delivery_location',
    isVisible: false,
    ordinal: 360,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'building',
    translationId: 'Building',
    isVisible: false,
    ordinal: 370,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'street',
    translationId: 'Street',
    isVisible: false,
    ordinal: 380,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'town',
    translationId: 'Town',
    isVisible: false,
    ordinal: 390,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'customerOrderNotes',
    translationId: 'Customer_order_notes',
    isVisible: false,
    ordinal: 400,
    isSortable: false,
    columnType: 'String',
  },
  //#endregion
];

export const rejectionsByStoreMeta: OrdersTableColumns[] = [
  {
    columnName: 'orderCount',
    translationId: 'Number_of_rejected_orders',
    isVisible: true,
    ordinal: 10,
    isSortable: true,
    columnType: 'Integer',
  },
  {
    columnName: 'orderAmount',
    translationId: 'Total_revenue_lost',
    isVisible: true,
    ordinal: 20,
    isSortable: true,
    columnType: 'Decimal',
  },
  {
    columnName: 'mostCommonReason',
    translationId: 'Most_common_reason',
    isVisible: true,
    ordinal: 30,
    isSortable: false,
    columnType: 'TranslatableString',
  },
  {
    columnName: 'store',
    translationId: 'Store_name',
    isVisible: true,
    ordinal: 0,
    isSortable: false,
    columnType: 'TranslatableString',
  },
];

export const payoutOrdersMeta: OrdersTableColumns[] = [
  {
    columnName: 'OrderId',
    translationId: 'Order_id',
    isVisible: true,
    ordinal: 0,
    isSortable: true,
    columnType: 'String',
  },
  {
    columnName: 'PlacedAtTime',
    translationId: 'Order_date',
    isVisible: true,
    ordinal: 1,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'ModifiedAtTime',
    translationId: 'Refund_date',
    isVisible: true,
    ordinal: 2,
    isSortable: true,
    columnType: 'DateTime',
  },
  {
    columnName: 'StoreName',
    translationId: 'Store_name',
    isVisible: true,
    ordinal: 3,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'Type',
    translationId: 'Type',
    isVisible: true,
    ordinal: 4,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'RefundedAmount',
    translationId: 'Refunded_amount',
    isVisible: true,
    ordinal: 5,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'RefundedFees',
    translationId: 'Refunded_fees',
    isVisible: true,
    ordinal: 6,
    isSortable: false,
    columnType: 'Decimal',
  },
  {
    columnName: 'RefundType',
    translationId: 'Refund_type',
    isVisible: true,
    ordinal: 7,
    isSortable: false,
    columnType: 'String',
  },
  {
    columnName: 'CashFeeRefundedToCustomer',
    translationId: 'Cash_fee_refunded_to_customer',
    isVisible: true,
    ordinal: 8,
    isSortable: false,
    columnType: 'Decimal',
  },
];
