import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import GridDivider from '../GridDivider';
import IncludeDeliveryFeeField from './components/IncludeDeliveryFeeField';
import LimitAvailabilityField from './components/LimitAvailabilityField';
import TakesPriorityField from './components/TakesPriorityField';
import ValidForField from './components/ValidForField';
import ValidityPeriodsField from './components/ValidityPeriodsField';
import ValidOnOrdersOverField from './components/ValidOnOrdersOverField';

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    padding: theme.spacing(1, 2),

    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  details: {
    padding: theme.spacing(0, 6),
  },
}));

type Props = {
  automaticallyApply: boolean;
  isMenuItemDiscount?: boolean;
  isVoucherLimitAvailabilityEnabled: boolean; // TODO - remove when voucherLimitAvailability is fully released
  isVoucherValidPeriodEnabled: boolean; // TODO - remove when voucherValidPeriod is fully released
  showValidType?: boolean;
  translate: TranslateFunction;
};

// fix Mui forgotten muiName prop
// @ts-ignore
ExpansionPanelDetails.muiName = 'ExpansionPanelDetails';

const AdvancedOptionsFormItems = ({
  automaticallyApply,
  isMenuItemDiscount = false,
  isVoucherLimitAvailabilityEnabled, // TODO - remove when voucherLimitAvailability is fully released
  isVoucherValidPeriodEnabled, // TODO - remove when voucherValidPeriod is fully released
  showValidType = true,
  translate,
}: Props) => {
  const classes = useStyles();

  return (
    <ExpansionPanel elevation={0}>
      <ExpansionPanelSummary
        className={classes.summary}
        data-fd="show-advanced-options"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>
          <Translate id="Show_advanced_options" />
        </Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={classes.details}>
        <Grid container>
          <Grid item xs={12}>
            <TakesPriorityField disabled={!automaticallyApply} />
          </Grid>

          <GridDivider />

          <Grid item xs={12}>
            <ValidOnOrdersOverField />
          </Grid>

          <GridDivider />

          {isVoucherValidPeriodEnabled && (
            <>
              <Grid item xs={12}>
                <ValidityPeriodsField translate={translate} />
              </Grid>

              <GridDivider />
            </>
          )}

          {isVoucherLimitAvailabilityEnabled && (
            <>
              <Grid item xs={12}>
                <LimitAvailabilityField />
              </Grid>

              <GridDivider />
            </>
          )}

          {!isMenuItemDiscount && (
            <>
              <Grid item xs={12}>
                <IncludeDeliveryFeeField />
              </Grid>

              <GridDivider />
            </>
          )}

          <Grid item xs={12}>
            <ValidForField showValidType={showValidType} />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AdvancedOptionsFormItems;
