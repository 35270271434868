import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import Select from 'react-select';

const styles = (theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
      '& >div:nth-child(2) span': {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },

      height: 40,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap' as any,
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      paddingBottom: '6px',
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      fontSize: '16px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    placeholder: {
      position: 'absolute' as any,
      height: '24px',
      fontSize: '16px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.87)',
      [theme.breakpoints.down('md')]: {
        left: 0,
      },
    },
    menuPaper: {
      position: 'absolute' as any,
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    select: {
      marginTop: theme.spacing(1),
      paddingTop: '4px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
      },
    },
    closeIcon: {
      color: 'rgba(0,0,0,0.54)',
    },
  });

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-fd={`bank-account-${props.data.label}`}
    >
      {props.data.Icon ? (
        <>
          <ListItemIcon>
            <props.data.Icon />
          </ListItemIcon>
          <ListItemText primary={props.children} />
        </>
      ) : (
        props.children
      )}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer} data-fd="bank-account-select">
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menuPaper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const customComponents = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

export interface IProps {
  options?: any[];
  onChange?: any;
  onInputChange?: any;
  value?: any;
  dataFd: string;
  disabled?: boolean;
  isLoading?: boolean;
}
export type Props = WithStyles<typeof styles> & IProps;

const SingleSelect = (props: Props) => {
  const { classes, options, value, dataFd, disabled, onChange, onInputChange, isLoading } = props;
  return (
    <Select
      isSearchable={true}
      className={classes.select}
      classes={classes}
      options={options}
      components={customComponents}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={''}
      isClearable={false}
      dataFd={dataFd}
      isDisabled={disabled}
      isLoading={isLoading}
    />
  );
};

export default withStyles(styles, {
  name: 'FdSingleSelect',
  withTheme: true,
})(SingleSelect);
