import React from 'react';

import Loading from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const Loader = () => (
  <Grid key="loader" container justifyContent="center" style={{ marginTop: 10 }} data-fd="loader">
    <Loading />
  </Grid>
);

export { Loader };
