import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { compose } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { getOAuthAppById, getOAuthAppSecretKey } from './actions/index';
import OAuthAppAuthTokens from './components/OAuthAppAuthTokens';
import OAuthAppRedirectUris from './components/OAuthAppRedirectUris';
import OAuthAppWebhooks from './components/OAuthAppWebhooks';
import OAuthAppDetails from './Details/OAuthAppDetails';
import WebhookLogs from './WebhookLogs/WebhookLogs';

type Props = MappedState & MappedDispatch & RouteComponentProps;

const DevelopersDetailsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  useEffect(() => {
    const { match, loadOAuthApp, loadSecretKey } = props;
    if (match.params.OAuthAppId) {
      loadOAuthApp(match.params.OAuthAppId);
      loadSecretKey(match.params.OAuthAppId);
    }
  }, []);

  return (
    <ErrorBoundary identifier="o-auth-app-details">
      <Switch>
        <Route exact path={`${path}`} component={OAuthAppDetails} />
        <Route path={`${path}/redirect_uris`} component={OAuthAppRedirectUris} />
        <Route path={`${path}/authorization_tokens`} component={OAuthAppAuthTokens} />
        <Route path={`${path}/webhooks`} component={OAuthAppWebhooks} />
        <Route exact path={`${path}/:WebhookSubscriptionId/webhook_logs`} component={WebhookLogs} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  return {
    match: ownProps.match,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadOAuthApp: (OAuthAppId) => {
    dispatch(getOAuthAppById(OAuthAppId));
  },
  loadSecretKey: (OAuthAppId) => {
    dispatch(getOAuthAppSecretKey(OAuthAppId));
  },
});

const EnhancedComponent = compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DevelopersDetailsRoutes);

export default EnhancedComponent;
