import { Store } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { STORE_LOAD_ALL } from '../constants/store.constants';
import { selectDbStores } from './db.selector';
import { createLoadingSelector } from './loading.selector';
import { DateTimeUtils, dateTimeUtils } from './localeDateTime.selector';
import { createOrmSelector } from './selector';

const selectItemOrderIndex = (state: AppState) =>
  state.stores.search ? state.stores.search.item_order_index : state.stores.item_order_index;

const selectSelectedStoreId = (state: AppState) =>
  state.stores.selection && state.stores.selection.storeId
    ? state.stores.selection.storeId
    : undefined;

export const getIsStoresLoading = createLoadingSelector([STORE_LOAD_ALL]);

export const getStores = createSelector(
  [selectItemOrderIndex, selectDbStores],
  (itemsOrder, stores) => {
    return itemsOrder.map((id) => stores[id]);
  }
);

export const getSelectedStoreId = createSelector([selectSelectedStoreId], (storeId) => {
  return storeId;
});

export const getSelectedStore = createSelector(
  [selectSelectedStoreId, selectDbStores],
  (storeId, stores) => {
    return storeId ? stores[storeId] : undefined;
  }
);

export type SelectedStoreTimezone = string | undefined;
export const getSelectedStoreTimezone = createSelector(
  [selectSelectedStoreId, selectDbStores],
  (storeId, stores) => {
    return storeId && stores[storeId] ? stores[storeId].IanaTimeZone : undefined;
  }
);

// #region

export const store = createSelector(
  [(state, props) => selectDbStores(state)[props.storeId]],
  (store) => store
);

export const timeZone = createSelector(
  [(state, props) => selectDbStores(state)[props.storeId]],
  (store) => (store ? store.IanaTimeZone : undefined)
);

const envTimeZonesCombiner = (storeIanaTZ: string, dtUtils: DateTimeUtils) => {
  if (!storeIanaTZ) {
    return;
  }

  const now = new Date();

  let storeOffset: string | undefined;
  if (storeIanaTZ) {
    storeOffset = dtUtils.format(now, 'x', { timeZone: storeIanaTZ });
  }

  return {
    system: {
      offset: dtUtils.systemOffset,
      ianaTimeZone: dtUtils.systemIanaTimeZone,
      isUserSame: dtUtils.userOffset === dtUtils.systemOffset,
      isStoreSame: storeOffset == dtUtils.systemOffset,
    },
    user: {
      offset: dtUtils.userOffset,
      ianaTimeZone: dtUtils.userIanaTimeZone,
      isStoreSame: storeOffset == dtUtils.userOffset,
      isSystemSame: dtUtils.systemOffset === dtUtils.userOffset,
    },
    store: {
      offset: storeOffset,
      ianaTimeZone: storeIanaTZ,
      isUserSame: dtUtils.userOffset === storeOffset,
      isSystemSame: dtUtils.systemOffset === storeOffset,
    },
  };
};
export const envTimeZones = createSelector(
  [(state, props) => timeZone(state, props), (state) => dateTimeUtils(state)],
  envTimeZonesCombiner
);
// #endregion

export const storeSelector = createOrmSelector<Store[]>((session) => {
  return session.FlipdishStore.all().toRefArray();
});

export const createStoreSelectorById = (storeId: number | string) =>
  createOrmSelector<Store | undefined>((session) => {
    const store = session.FlipdishStore.withId(storeId as any);
    if (store != null) {
      return store.ref;
    }
    return undefined;
  });

export const storesByStoreGroupIdSelector = createSelector(
  [(state) => state.orm.FlipdishStore.itemsById, (state, props) => props],
  (storeTable, storeGroupId) => {
    return Object.keys(storeTable).reduce<Store[]>((agg, storeId) => {
      if (storeTable[storeId].StoreGroupId === storeGroupId) {
        agg.push(storeTable[storeId]);
      }
      return agg;
    }, []);
  }
);
export const createStoreSelectorByStoreGroupId = (storeGroupId: number | string) =>
  createOrmSelector<Store[]>((session) => {
    return session.FlipdishStore.all()
      .filter((s) => s.StoreGroupId === storeGroupId)
      .toRefArray();
  });

export const createStoreMapCenterSelector = (storeId: number | string) =>
  createOrmSelector<{ lat: number; lng: number } | undefined>((session) => {
    const store = session.FlipdishStore.withId(storeId as any);
    if (store != null && store.Address && store.Address.Coordinates) {
      return {
        lat: store.Address.Coordinates.Latitude,
        lng: store.Address.Coordinates.Longitude,
      };
    }
    return undefined;
  });

export const getStoreOrderNotificationsSMSSelector = (state: AppState) => {
  return state.stores.smsOrderNotifications;
};

export const getStoreOrderNotificationsEmailSelector = (state: AppState) => {
  return state.stores.emailOrderNotifications;
};
