import React from 'react';

import { PreOrderConfig, StoreOrderCapacityConfig } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Form, withFormik } from 'formik';
import moment from 'moment-timezone';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../../helpers/validation';
import LoadingButton from '../../../../ui/LoadingButton';
import PreventNavigation from '../../../Finance/Banking/components/PreventNavigation';
import AlwaysAppearOpen from './Fields/AlwaysAppearOpen';
import IncludeAsap from './Fields/IncludeAsap';
import IntervalMinutes from './Fields/IntervalMinutes';
import LeadTimeMinutes from './Fields/LeadTimeMinutes';
import MaxOrderAheadDays from './Fields/MaxOrderAheadDays';
import OrderCapacityFormItems from './Fields/OrderCapacity';
import PreOrderTimeDisplayType from './Fields/PreOrderTimeDisplayType';
import RequireExplicitSelectAlways from './Fields/RequireExplicitSelectAlways';
import FormItem from './FormItem';

enum CutOffSelectValues {
  disabled,
  sameDay,
  previousDay,
}

const serverTimeFormat = 'HH:mm';
const timeFormat = 'LT';

const CutOffFieldNamesMap = {
  [CutOffSelectValues.sameDay]: 'CutOffTimeCurrentDayBasic',
  [CutOffSelectValues.previousDay]: 'CutOffTimePreviousDayBasic',
};

const getCutOffSelectValue = (values: PreOrderConfig): CutOffSelectValues => {
  if (values.CutOffTimeCurrentDayBasic) {
    return CutOffSelectValues.sameDay;
  }

  if (values.CutOffTimePreviousDayBasic) {
    return CutOffSelectValues.previousDay;
  }

  return CutOffSelectValues.disabled;
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
  form: {
    paddingBottom: '22px',
  },
  content: {
    paddingRight: spacing(3),
    marginBottom: '32px',
  },
  switcher: {
    float: 'right',
  },
  formControl: {
    marginBottom: '22px',
  },
  textWrapper: {
    padding: `${spacing(2)} 0 36px`,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  controls: {
    display: 'flex',
    paddingTop: spacing(4),
  },
  disableButton: {
    marginLeft: -8,
  },
  submitButton: {
    marginLeft: 'auto',
  },
}));

export type FormValues = PreOrderConfig & {
  CutOffSelectValue: CutOffSelectValues;
  CutOffTimeCurrentDayBasic: moment.Moment | null;
  CutOffTimePreviousDayBasic: moment.Moment | null;

  OrderCapacity?: StoreOrderCapacityConfig;
};

type Props = {
  data: PreOrderConfig;
  onSubmit: (data: PreOrderConfig) => void;
  onSubmitOrderCapacity: (data: StoreOrderCapacityConfig, onSuccess: () => void) => void;
  onDisable: () => void;
  loading: boolean;
  disabling: boolean;
  canEdit: boolean;
  appId: string;
  storeId: string;
  type: 'delivery' | 'collection';
  orderCapacityFeature: boolean;
};

export default withFormik<Props, FormValues>({
  // @ts-ignore
  mapPropsToValues: ({ data }) => {
    if (data) {
      const {
        IncludeAsap,
        CutOffTimeCurrentDayBasic,
        CutOffTimePreviousDayBasic,
        LeadTimeMinutes,
        IntervalMinutes,
        MaxOrderAheadDays,
        PreOrderTimeDisplayType,
        AlwaysAppearOpen,
        RequireExplicitSelectAlways,
      } = data;

      return {
        IncludeAsap,
        CutOffTimeCurrentDayBasic:
          CutOffTimeCurrentDayBasic && moment(CutOffTimeCurrentDayBasic, serverTimeFormat),
        CutOffTimePreviousDayBasic:
          CutOffTimePreviousDayBasic && moment(CutOffTimePreviousDayBasic, serverTimeFormat),
        LeadTimeMinutes,
        IntervalMinutes,
        MaxOrderAheadDays,
        PreOrderTimeDisplayType,
        CutOffSelectValue: getCutOffSelectValue(data),
        AlwaysAppearOpen,
        RequireExplicitSelectAlways,
      };
    }
    return data || {};
  },
  mapPropsToStatus: () => ({ isOrderCapacityOn: false }),
  handleSubmit: (
    { CutOffSelectValue, OrderCapacity, ...data }: FormValues,
    { props: { onSubmit, onSubmitOrderCapacity }, resetForm }
  ) => {
    const formattedData: PreOrderConfig = {
      ...data,
      CutOffTimeCurrentDayBasic:
        data.CutOffTimeCurrentDayBasic && data.CutOffTimeCurrentDayBasic.format(serverTimeFormat),
      CutOffTimePreviousDayBasic:
        data.CutOffTimePreviousDayBasic && data.CutOffTimePreviousDayBasic.format(serverTimeFormat),
    };

    onSubmit(formattedData);

    if (OrderCapacity) {
      onSubmitOrderCapacity(
        OrderCapacity,
        // TEMPORARY find better solution
        () => {
          resetForm();
        }
      );
    }
  },
  enableReinitialize: true,
})(({
  appId,
  storeId,
  type,
  values,
  dirty,
  submitForm,
  onDisable,
  loading,
  disabling,
  canEdit,
  status,
  setStatus,
  orderCapacityFeature,
}) => {
  const classes = useStyles();

  return (
    <Form className={classes.form}>
      <PreventNavigation when={dirty} />

      <IncludeAsap canEdit={canEdit} style={classes.switcher} />
      <AlwaysAppearOpen canEdit={canEdit} style={classes.switcher} />
      <RequireExplicitSelectAlways
        isCollection={type === 'collection'}
        canEdit={canEdit}
        style={classes.switcher}
      />
      <LeadTimeMinutes canEdit={canEdit} />
      <IntervalMinutes canEdit={canEdit} hasPeriods={status.isOrderCapacityOn} />

      {orderCapacityFeature && (
        <>
          <OrderCapacityFormItems
            appId={appId}
            storeId={storeId}
            type={type}
            intervalMinutes={values.IntervalMinutes}
            setHasPeriods={(hasPeriod) => {
              setStatus({ isOrderCapacityOn: hasPeriod });
            }}
          />
          <Divider />
        </>
      )}

      <MaxOrderAheadDays canEdit={canEdit} />

      <FormItem
        divider
        primary={<Translate id="Preorder_settings_cutoff_time_item_title" />}
        secondary={<Translate id="Preorder_settings_cutoff_time_item_helper_text" />}
        name="CutOffSelectValue"
        validate={
          values.CutOffSelectValue !== CutOffSelectValues.disabled
            ? (value) => {
                return formikValidate.required(values[CutOffFieldNamesMap[value]]);
              }
            : undefined
        }
      >
        {({ field, form }) => (
          <>
            <FormControl fullWidth className={classes.formControl}>
              <Select
                variant="standard"
                disabled={!canEdit}
                value={field.value}
                SelectDisplayProps={{
                  // @ts-ignore
                  'data-fd': 'pre_order_settings_cut_off_select_value',
                }}
                inputProps={{
                  'data-fd': 'pre_order_settings_cut_off_select_value_input',
                }}
                onChange={(e) => {
                  const value = Number(e.target.value);

                  if (value === CutOffSelectValues.sameDay) {
                    form.setFieldValue('CutOffTimePreviousDayBasic', null);
                  }

                  if (value === CutOffSelectValues.previousDay) {
                    form.setFieldValue('CutOffTimeCurrentDayBasic', null);
                  }

                  if (value === CutOffSelectValues.disabled) {
                    form.setFieldValue('CutOffTimePreviousDayBasic', null);
                    form.setFieldValue('CutOffTimeCurrentDayBasic', null);
                  }

                  form.setFieldValue('CutOffSelectValue', value);
                }}
              >
                <MenuItem
                  value={CutOffSelectValues.disabled}
                  data-fd="pre_order_settings_cut_off_disabled"
                >
                  {<Translate id="Disabled" />}
                </MenuItem>
                <MenuItem
                  value={CutOffSelectValues.sameDay}
                  data-fd="pre_order_settings_cut_off_same_day_cutoff"
                >
                  {<Translate id="Same_day_cutoff" />}
                </MenuItem>
                <MenuItem
                  value={CutOffSelectValues.previousDay}
                  data-fd="pre_order_settings_cut_off_previous_day_cutoff"
                >
                  {<Translate id="Previous_day_cutoff" />}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  ampm={false}
                  disabled={
                    // @ts-ignore after updating formik to v2, we have problem with this field
                    !canEdit || field.value === CutOffSelectValues.disabled
                  }
                  onChange={(value) => {
                    // @ts-ignore
                    form.setFieldValue(CutOffFieldNamesMap[field.value], value);
                  }}
                  onOpen={() => {
                    // @ts-ignore
                    form.setFieldValue(CutOffFieldNamesMap[field.value], moment());
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      inputProps: { placeholder: '' },
                    },
                    popper: { style: { zIndex: 9999 } },
                    // @ts-ignore
                    actionBar: { 'data-fd': 'pre_order_settings_cut_off_time_picker_action_bar' },
                  }}
                  // @ts-ignore
                  value={values[CutOffFieldNamesMap[field.value]] || null}
                  format={timeFormat}
                />
              </LocalizationProvider>
              {/* @ts-ignore */}
              {form.errors && field.value !== CutOffSelectValues.disabled ? (
                // @ts-ignore
                <FormHelperText error>{form.errors[field.name]}</FormHelperText>
              ) : null}
            </FormControl>
          </>
        )}
      </FormItem>

      <PreOrderTimeDisplayType canEdit={canEdit} />

      <div className={classes.controls}>
        <LoadingButton
          disabled={!canEdit}
          fdKey="disable-pre-ordering"
          color="secondary"
          className={classes.disableButton}
          loading={disabling}
          onClick={onDisable}
        >
          {<Translate id="Disable" />}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!canEdit}
          className={classes.submitButton}
          fdKey="submit-pre-order-setings"
          loading={loading}
          onClick={submitForm}
        >
          {<Translate id="Save" />}
        </LoadingButton>
      </div>
    </Form>
  );
});
