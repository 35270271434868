import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles({
  overriddenInfo: {
    // color defined in https://cookbook.flipdish.com/85a996f8d/p/87cfc3-toast/b/8809dd/i/148078121
    backgroundColor: '#212121 !important',
  },
});

const NotificationProvider = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SnackbarProvider
      classes={{ variantInfo: classes.overriddenInfo }}
      dense={isSmallScreen ? true : false}
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
