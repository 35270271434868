import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Box } from '../Box';
import { SkeletonLoader } from '../SkeletonLoader';
import { Typography } from '../Typography';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: '8px',
    padding: theme.spacing(3),
    width: '100%',
  },
  contentContainer: {
    marginTop: theme.spacing(1),
  },
}));

export type DashboardCardProps = {
  children?: any;
  isLoading?: boolean;
  title: string;
};

export const DashboardCard = ({ isLoading, children, title }: DashboardCardProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.box} paddingLeft={3}>
      <Typography variant="body1">{title}</Typography>
      <div className={classes.contentContainer}>
        {isLoading ? (
          <SkeletonLoader
            fdKey="dashboard-card-loader"
            rows={[{ height: '40px', width: '100%' }]}
          />
        ) : (
          children
        )}
      </div>
    </Box>
  );
};
