import React from 'react';

import { Subscription } from '@flipdish/api-client-typescript';
import { type Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { FormattedCurrency } from 'fd-react-intl';

export const subscriptionPrice = (
  nextInvoiceAmount?: number,
  currency?: Subscription.CurrencyEnum,
  languageCode?: string,
  variant?: 'inherit' | Variant
) => (
  <Typography variant={variant ?? 'subtitle1'}>
    <FormattedCurrency
      value={nextInvoiceAmount as number}
      //@ts-ignore
      locales={languageCode}
      currency={currency?.toString() || 'EUR'}
    />
  </Typography>
);
