import React, { useState } from 'react';

import { Reject } from '@flipdish/api-client-typescript';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { capitalize, snakeCase } from 'lodash';
import { Translate } from 'react-localize-redux';

const styles = (theme: Theme) => {
  return createStyles({
    rejectContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rejectBtn: {
      padding: '9px 15px',
      [theme.breakpoints.down('sm')]: { padding: 0, width: 156, height: 40 },
      fontSize: '16px',
      letterSpacing: '1.4px',
      color: '#ff395f',
      border: '1px solid #ff395f',
      '&:hover': {
        border: '1px solid #ff395f',
        backgroundColor: 'initial',
      },
    },
    rejectMenuItem: {
      color: '#ff395f',
    },
  });
};

const enumToArray = (myEnum) => {
  const arrayOfEnums: string[] = [];
  for (const item in myEnum) {
    if (isNaN(Number(item))) {
      arrayOfEnums.push(capitalize(snakeCase(item)));
    }
  }
  return arrayOfEnums;
};

type Props = {
  handleRejectOrder: (reason: string) => void;
} & WithStyles<typeof styles>;

const RejectButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, handleRejectOrder } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <div className={classes.rejectContainer}>
      <Button
        data-fd="reject"
        variant="outlined"
        className={classes.rejectBtn}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Translate id="Reject" />
        <ArrowDropDown />
      </Button>
      <Menu
        disablePortal
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {enumToArray(Reject.RejectReasonEnum).map((reason, index) => (
          <MenuItem
            onClick={() => {
              handleRejectOrder(reason);
              setAnchorEl(null);
            }}
            data-fd={`reject_with_reason_${reason}`}
            className={classes.rejectMenuItem}
            key={index}
          >
            <Translate id={`${reason}` as TranslationId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withStyles(styles)(RejectButton);
