import { type Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';

export const StyledTableCell = withStyles((theme: Theme) => ({
  body: {
    fontSize: 13,
  },
  head: {
    fontSize: 12,
  },
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}))(TableCell) as typeof TableCell;
