import React from 'react';

import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate, validate } from '../../../../helpers/validation';
import TextField from '../../../../ui/TextField/TextField';
import FormItemLayout from '../../../Finance/Banking/components/FormItemLayout';

const validateNameField = (value: string) => {
  if (value.length === 0) {
    return validate.required(value);
  }
  if (value.length > 100) {
    return formikValidate.max(100)(value.length);
  }
  return false;
};

const DeviceNameField = () => (
  <FormItemLayout label={<Translate id="Terminal_name" />}>
    <Field name={'DeviceName'} validate={validateNameField}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <TextField
            {...field}
            fdKey={'Terminal_name'}
            autoComplete="off"
            fullWidth
            disabled={isSubmitting}
            error={showError}
            helperText={showError ? fieldError : <Translate id={'Use_unique_name'} />}
          />
        );
      }}
    </Field>
  </FormItemLayout>
);
export default DeviceNameField;
