import React, { useState } from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { formatDistanceToNow } from 'date-fns';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { capitalizeFirst } from '../../helpers/utilities';
import { dateTimeUtils } from '../../selectors/localeDateTime.selector';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import ListItemLink from '../../ui/List/ListItemLink';

const useStyles = makeStyles({
  invite: {
    display: 'flex',
    flexDirection: 'column' as any,
    justifyContent: 'center',
  },
  inviteText: {
    fontSize: '40px',
    color: '#3743b1',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
  },
  inviteSubtext: {
    fontSize: '20px',
    color: 'rgba(0,0,0,0,0.54)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
    '&:hover': {
      backgroundColor: '#ece6f7',
    },
    borderTop: '1px solid rgba(0,0,0,0.2088)',
  },
  rowLink: {
    cursor: 'pointer',
  },
  cell: {
    borderBottomColor: 'rgba(0,0,0,0.21)',
  },
  bodyCell: {
    fontSize: '13px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.85',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 0,
    height: '100%',
  },
  cellLink: {
    textDecoration: 'none',
    color: 'inherit',
    height: '100%',
    outline: 0,
  },
  lastStatusPadding: {
    paddingLeft: '5px',
  },
  inviteResent: {
    cursor: 'pointer',
    marginLeft: '5px',
    color: '#27bf79',
  },
  secondaryAction: {
    paddingRight: '0',
  },
});

type Props = ITeammatesListProps;
export interface ITeammatesListProps {
  resendInvite: (teammate: FlipdishAPI.Teammate, event: React.MouseEvent<HTMLElement>) => void;
  invitePending: (teammate: FlipdishAPI.Teammate) => boolean;
  mapAppAccessLevel: (
    appAccessLevel: FlipdishAPI.App.AppAccessLevelEnum | undefined
  ) => JSX.Element;
  teammate: FlipdishAPI.Teammate;
}

function ListItemMobile({
  canEditPermission,
  currentApp,
  dtUtils,
  invitePending,
  mapAppAccessLevel,
  resendInvite,
  teammate,
  translate,
}: Props & MappedState) {
  const classes = useStyles();
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);

  const appId = currentApp != null ? currentApp.AppId : 'global';

  function handleCloseMenu() {
    setanchorEl(null);
  }

  function handleOpenMenu(event) {
    setanchorEl(event.currentTarget);
  }

  return (
    <ListItemLink
      divider
      button
      to={('/' + appId + '/teammates/' + teammate.TeammateId) as string}
      fdKey={teammate.TeammateId!}
      disableGutters
      className={`${classes.cellLink} ${HORIZONTAL_SPACE_CLASSNAME}`}
    >
      <ListItemText
        primary={teammate.Email}
        secondary={
          <>
            {mapAppAccessLevel(teammate.AppAccessLevel)}
            <Typography component={'span'} className={classes.bodyCell}>
              {translate('Last_active')}:
              {invitePending(teammate) ? (
                <span className={classes.lastStatusPadding}>
                  {teammate.LastActivity
                    ? capitalizeFirst(
                        formatDistanceToNow(
                          new Date(
                            dtUtils.utcToZonedTime(teammate.LastActivity, dtUtils.userIanaTimeZone)
                          )
                        )
                      )
                    : translate('Never')}
                </span>
              ) : (
                <span className={classes.lastStatusPadding}>
                  {teammate.LastActivity
                    ? capitalizeFirst(
                        formatDistanceToNow(
                          new Date(
                            dtUtils.utcToZonedTime(teammate.LastActivity, dtUtils.userIanaTimeZone)
                          )
                        )
                      )
                    : translate('Never')}
                  {canEditPermission &&
                  teammate.InvitationStatus ===
                    FlipdishAPI.Teammate.InvitationStatusEnum.Pending ? (
                    <span className={classes.inviteResent}>{translate('Invite_resent')}</span>
                  ) : null}
                </span>
              )}
            </Typography>
          </>
        }
      />

      {invitePending(teammate) && canEditPermission ? (
        <ListItemSecondaryAction>
          <IconButton
            aria-label="More"
            // aria-owns={open ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="Resend_invite-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              key={'Resend_invite'}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                resendInvite(teammate, event);
                handleCloseMenu();
              }}
            >
              <Translate id="Resend_invite" />
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      ) : null}
    </ListItemLink>
  );
}

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp, teammates, locale } = state;

  const meEmail = state.account.Email;
  const canEditPermission = state.permissions.some(
    (p) => p === FlipdishAPI.App.AppResourceSetEnum.EditTeammates.toString()
  );

  return {
    canEditPermission,
    currentApp,
    dtUtils: dateTimeUtils(state),
    meEmail,
    teammates: teammates.teammates,
    teammatesTimestamp: teammates.teammatesTimestamp,
    translate: getTranslate(locale),
  };
}

const EnhancedComponent = connect(mapStateToProps)(ListItemMobile);

export default EnhancedComponent;
