import React, { useState } from 'react';

import { type Variant } from '@mui/material/styles/createTypography';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Button from '../ui/Button/Button';

const useStyles = makeStyles({
  storesTruncated: {
    color: '#05149e',
    fontSize: '12px',
    textTransform: 'lowercase',
    letterSpacing: '0.38px',
    verticalAlign: 'initial',
    lineHeight: 1.43,
    padding: 0,
  },
});

type BtnSize = '12px' | '14px' | '16px';
type minWidth = 'small' | 'large';

type Props = {
  storeNames: string[];
  textPrefix: boolean;
  variant: Variant;
  btnFontSize: BtnSize;
  minWidth?: minWidth;
  color: TypographyProps['color'];
};

const storeListTruncated = (props: Props) => {
  const { storeNames, textPrefix, btnFontSize, variant, minWidth, color } = props;
  const [viewAllStores, setViewAllStores] = useState(false);
  const classes = useStyles();

  const btnMinWidth = () => {
    switch (minWidth) {
      case 'small':
        return '56px';
      case 'large':
        return '70px';
      default:
        return '64px';
    }
  };

  const showAllStores = (storeNames: string[]) => {
    if (storeNames && storeNames.length) {
      return (
        <Typography variant={variant} color={color} component="span" style={{ display: 'block' }}>
          {textPrefix && (
            <>
              <Translate id={'Associated_stores'} />
              {': '}
            </>
          )}
          {storeNames.join(', ')}
        </Typography>
      );
    } else {
      return null;
    }
  };

  const storesTruncated = (storeNames: string[]) => {
    return (
      <Typography
        variant={variant}
        component="span"
        style={{ display: 'block' }}
        color="textPrimary"
      >
        {textPrefix && (
          <>
            <Translate id={'Associated_stores'} />
            {': '}
          </>
        )}
        {storeNames!.slice(0, 3).join(', ')}
        <Button
          fdKey={'show-all-stores'}
          onClick={(e) => {
            e.preventDefault();
            setViewAllStores(true);
          }}
          className={classes.storesTruncated}
          style={{ fontSize: btnFontSize, minWidth: btnMinWidth() }}
        >
          <Translate id={'More_stores'} data={{ stores: storeNames.length - 3 }} />
        </Button>
      </Typography>
    );
  };

  return viewAllStores
    ? showAllStores(storeNames)
    : storeNames!.length > 3
      ? storesTruncated(storeNames)
      : showAllStores(storeNames);
};

export default storeListTruncated;
