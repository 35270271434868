import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { storePublish } from '../../../actions/store.actions';
import { getIsOwner } from '../../../selectors/app.selector';
import Tooltip from '../../../ui/Tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    letterSpacing: '1.25px',
    backgroundColor: '#dceafd',
    wordBreak: 'normal',
  },
  text: {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingRight: '16px',
    [theme.breakpoints.down(480)]: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  btn: {
    letterSpacing: '1.25px',
    paddingLeft: '16px',
    paddingRight: '16px',
    minWidth: 'initial',
  },
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontWeight: 'normal',
  },
}));
type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {
  storeId: number;
  canPublishStore: boolean;
  inView?: boolean;
};
type Props = InnerProps & OuterProps;
const StoreUnpublished = (props: Props) => {
  const { canPublishStore, inView, isOwner, publishStore, storeId } = props;
  const classes = useStyles();

  if (!storeId || !inView) {
    return null;
  }

  const handleSubmit = () => {
    publishStore(storeId, canPublishStore);
  };

  return (
    <AppBar
      enableColorOnDark
      position="static"
      component="div"
      color="inherit"
      className={classes.appBar}
      elevation={0}
      square
    >
      <Toolbar>
        <Typography variant="h5" color="inherit" className={classes.text}>
          <Translate id="Store_is_in_draft_mode" />
        </Typography>

        <div style={{ flexGrow: 1 }} />
        <Tooltip
          fdKey={'tooltip-publish-store'}
          messageId={'Only_an_owner_can_publish_or_unpublish_a_store'}
          disableHoverListener={isOwner}
          disableTouchListener={isOwner}
        >
          <div>
            <Button
              data-fd="publish_store_btn"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.btn}
              disabled={!isOwner}
            >
              <Translate id="Publish" />
            </Button>
          </div>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  publishStore: (storeId: number, canPublishStore: boolean) => {
    dispatch(storePublish(storeId, canPublishStore));
  },
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const isOwner = getIsOwner(state);
  return {
    isOwner,
  };
};

export default compose<Props, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  StoreUnpublished
);
