import React, { useEffect } from 'react';

import { OnboardingItemUpdate, Store } from '@flipdish/api-client-typescript';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { tutorialMilestoneId } from '../../../constants/tutorial.constants';
import { isFlipdishGlobal } from '../../../selectors/app.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import {
  GetOnboardingConfigs,
  getOnboardingConfigsQueryKey,
} from '../../OnboardingV2/onboarding.service';
import { TutorialChecklist } from '../Checklist';
import useTutorialData from '../hooks/useTutorialData';

type TutorialDrawerProps = {
  isOpen?: boolean;
  setIsOpen: (boolean) => void;
  drawerWidth: number;
  hasNotification?: boolean;
  setHasNotification: (boolean) => void;
  tutorialStore: Store;
};

const TutorialDrawer = ({
  appId,
  drawerWidth,
  hasNotification,
  hasOnBoardingPermissions,
  isFlipdishGlobal,
  isOpen,
  setHasNotification,
  setIsOpen,
  tutorialStore,
}: TutorialDrawerProps & MappedState) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'fixed',
    },
    drawer: {
      width: '100%',
      overflow: 'hidden',

      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
    drawerOpen: {
      borderRight: 'none',
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        height: 'auto',
        bottom: 0,
        top: 65,
      },
      right: 0,
    },
    drawerClose: {
      [theme.breakpoints.up('sm')]: {
        marginTop: 65,
        right: -drawerWidth,
      },

      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
    },
    drawerContentWrapper: {
      width: '100%',
      flexDirection: 'column',
      display: 'flex',
      height: '100vh',
      minHeight: '-webkit-fill-available',
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 65px)',
        width: drawerWidth,
      },
    },
    headerContainer: {
      margin: '24px auto 20px 16px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    header: {
      fontSize: 18,
      flexGrow: 1,
    },
    headerClose: {
      marginRight: 32,
      marginTop: -1,
      height: 30,
    },
    title: {
      fontSize: 24,
      lineHeight: '32px',
      margin: '28px 0 11px 0',
    },
    contentContainer: {
      padding: theme.spacing(2),
      flex: 1,
      overflowX: 'scroll',
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { data } = useTutorialData(
    appId,
    tutorialStore.StoreId,
    tutorialMilestoneId,
    hasOnBoardingPermissions
  );

  useEffect(() => {
    setIsOpen(undefined);
  }, [appId]);

  const queryClient = useQueryClient();

  const onboardingConfigs = queryClient.getQueryData<GetOnboardingConfigs>([
    getOnboardingConfigsQueryKey,
    appId ?? '',
  ]);

  const onboardingConfig = React.useMemo(() => {
    return onboardingConfigs?.Data.find((cfg) => cfg.ConfigType === 'Tutorial');
  }, [onboardingConfigs]);

  if (isFlipdishGlobal) {
    return null;
  }

  if (onboardingConfig && isOpen === undefined) {
    setIsOpen(onboardingConfig.IsEnabled);
  }

  if (
    data?.Status !== OnboardingItemUpdate.StatusEnum.Completed &&
    onboardingConfig?.IsEnabled &&
    hasNotification === undefined
  ) {
    setHasNotification(true);
  }

  const renderContent = () => {
    return (
      <div className={classes.drawerContentWrapper} data-fd="tutorial-content">
        <div className={classes.headerContainer}>
          <Typography variant="h5" className={classes.header}>
            <Translate id="Tutorial" />
          </Typography>
          <IconButton
            aria-label="Back"
            className={classes.headerClose}
            data-fd="tutorial-drawer-close-button"
            component={'button'}
            size="small"
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.contentContainer}>
          <TutorialChecklist
            tutorialStore={tutorialStore}
            setIsOpen={setIsOpen}
            setHasNotification={setHasNotification}
            IsWelcomeScreenEnabled={!!onboardingConfig?.IsWelcomeScreenEnabled}
          />
        </div>
      </div>
    );
  };

  return (
    <Permissions allowed={['Owner']}>
      <Drawer
        variant={matches ? 'permanent' : 'temporary'}
        anchor="right"
        classes={{
          root: classes.root,
          paper: clsx({
            [classes.drawer]: true,
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
        PaperProps={{ elevation: isOpen ? 2 : 0 }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div data-fd="tutorial-drawer">{isOpen && renderContent()}</div>
      </Drawer>
    </Permissions>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getOnBoardingPermissions = permissionsSelector.hasPermissionFactory([
    'Owner',
    'FlipdishStaff',
  ]);

  return (state: AppState) => {
    return {
      appId: state.currentApp.AppId!,
      isFlipdishGlobal: isFlipdishGlobal(state),
      hasOnBoardingPermissions: getOnBoardingPermissions(state),
    };
  };
};

export default connect(mapStateToPropsFactory)(TutorialDrawer);
