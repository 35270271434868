import React from 'react';

import { Product } from '@flipdish/api-client-typescript';
import Remove from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { ListBox } from '@fd/ui/molecules/ListBox';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  removeIcon: {
    color: theme.palette.primary.main,
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export type SelectedBoxProps = {
  selectedItems: Product[];
  noneSelectedMessage: string;
  onRemove: (selectedId: string) => void;
  title: string;
};

export const SelectedBox = (props: SelectedBoxProps): JSX.Element => {
  const { noneSelectedMessage, onRemove, selectedItems, title } = props;
  const classes = useStyles();

  const renderListItems = () => {
    return selectedItems.map((item, index) => {
      return (
        <ListItem
          className={classes.listItem}
          key={item.CatalogItemId}
          divider={index < selectedItems.length - 1}
        >
          <ListItemText primary={item.Name} secondary={item.Price?.toFixed(2)} />
          <ListItemSecondaryAction>
            <IconButton
              data-fd={`remove-selected-item-${index}`}
              onClick={() => onRemove(item.CatalogItemId as string)}
            >
              <Remove className={classes.removeIcon} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };
  return (
    <ListBox
      noItemsMessage={noneSelectedMessage}
      title={title}
      renderListItems={renderListItems}
      isEmpty={selectedItems.length === 0}
    />
  );
};
