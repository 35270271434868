import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAccountDetails } from '../../../actions/account.actions';
import { notifyError } from '../../../layouts/Notify/actions';
import { accountRoutesConst } from '../../../routes/account.routes';
import {
  getFlipdishAuthorizationToken,
  getFlipdishAuthorizationTokenKey,
} from '../../../services/auth.service';
import Loading from '../../Loading';
import { executePostLoginRedirect } from './utils';

type Props = MappedDispatch & MappedState;
const Auth0LoginCallback = ({
  auth0LoginError,
  dispatchNotifyError,
  dispatchGetAccountDetails,
}: Props) => {
  const history = useHistory();

  // Extract authorization code from the query string
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const code = params.get('code');
  const callbackState = params.get('state');
  const error = params.get('error');
  const errorDescription = params.get('error_description');

  const onError = (errormessage?: string) => {
    history.push(accountRoutesConst.Login);
    dispatchNotifyError(errormessage);
  };

  useEffect(() => {
    const sessionState = sessionStorage.getItem('fdauth0state');
    sessionStorage.removeItem('fdauth0state');

    if (!callbackState || !sessionState || callbackState !== sessionState) {
      console.log('state error');
      onError();
      return;
    }

    if (error || errorDescription) {
      onError(error + ': ' + errorDescription);
      return;
    }

    if (!code) {
      console.log('Missing Authorization Code.');
      onError();
      return;
    }
  }, [code]);

  const { isError, isSuccess } = useQuery({
    queryKey: [getFlipdishAuthorizationTokenKey, code],
    queryFn: () => getFlipdishAuthorizationToken(code!),
    enabled: !!code,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatchGetAccountDetails();
      executePostLoginRedirect();
    } else if (isError) {
      dispatchNotifyError();
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (auth0LoginError) {
      onError();
    }
  }, [auth0LoginError]);

  return <Loading fullscreen />;
};

type MappedState = ReturnType<ReturnType<typeof mapStateToProps>>;
const mapStateToProps = () => {
  return (state: AppState) => {
    const { account } = state;
    return {
      auth0LoginError: account.auth0LoginError,
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchGetAccountDetails: () => dispatch(getAccountDetails()),
  dispatchNotifyError: (errormessage?: string) => {
    if (errormessage) {
      dispatch(notifyError({ message: errormessage, translate: false }));
    } else {
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth0LoginCallback);
