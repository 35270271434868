import { TField } from '../TField';
import { EFieldType } from '../EFieldType';

export type TNotificationFieldNames =
  | 'NotificationEmailWhen'
  | 'NotificationPhoneNumberWhen'
  | 'NotificationEmail'
  | 'NotificationPhoneNumber';

export const NOTIFICATION_FIELDS: Record<TNotificationFieldNames, TField> = {
  ['NotificationEmailWhen']: {
    fieldName: 'NotificationEmailWhen',
    required: true,
    fieldType: EFieldType.Number,
  },
  ['NotificationPhoneNumberWhen']: {
    fieldName: 'NotificationPhoneNumberWhen',
    required: true,
    fieldType: EFieldType.Number,
  },
  ['NotificationEmail']: {
    fieldName: 'NotificationEmail',
    required: true,
    fieldType: EFieldType.Email,
  },
  ['NotificationPhoneNumber']: {
    fieldName: 'NotificationPhoneNumber',
    required: true,
    fieldType: EFieldType.PhoneNumber,
  },
};
