import {
  BusinessHoursOverride,
  CurrencyData,
  HomeAction,
  StoreDataPoint,
} from '@flipdish/api-client-typescript';

import { loadByAppId } from '../../actions/store.actions';
import { action, actionError } from '../../actions/utils';
import { notifyError } from '../../layouts/Notify/actions';
import { getStoreNetSales } from '../../services/store.service';
import {
  loadAll as loadOverrides,
  remove as removeOverride,
} from '../../services/storeOpeningHourOverrides.service';
import * as services from './services';

export const HOME_RESET = 'HOME_RESET';
export const reset = () => action(HOME_RESET);

// #region setupConfigs

export const SETUP_CONFIG = 'SETUP_CONFIG';
export const SETUP_CONFIG_REQUEST = 'SETUP_CONFIG_REQUEST';
export const SETUP_CONFIG_SUCCESS = 'SETUP_CONFIG_SUCCESS';
export const SETUP_CONFIG_FAILURE = 'SETUP_CONFIG_FAILURE';

export type LoadSetupConfigsRequest = ReturnType<typeof loadSetupConfigsRequest>;
export const loadSetupConfigsRequest = () => action(SETUP_CONFIG_REQUEST);

export type LoadSetupConfigsSuccess = ReturnType<typeof loadSetupConfigsSuccess>;
export const loadSetupConfigsSuccess = (setupConfigs: HomeAction[]) =>
  action(SETUP_CONFIG_SUCCESS, setupConfigs);

export type LoadSetupConfigsFailure = ReturnType<typeof loadSetupConfigsFailure>;
export const loadSetupConfigsFailure = (error: Error) =>
  actionError(SETUP_CONFIG_FAILURE, undefined, error);

export function loadSetupConfigs() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(loadSetupConfigsRequest());
      const result = await services.loadSetupConfigs(getState().currentApp.AppId!);
      // Temporary until api stops sending us this action
      const setupConfigs = result.filter((res) => {
        res.ActionKey !== 'welcome_to_new_portal_action';
      });
      dispatch(loadSetupConfigsSuccess(setupConfigs));
    } catch (error) {
      dispatch(loadSetupConfigsFailure(error));
    }
  };
}
// #endregion

// #region dismissSetupConfig

export const DISMISS_SETUP = 'DISMISS_SETUP';
export const DISMISS_SETUP_REQUEST = 'DISMISS_SETUP_REQUEST';
export const DISMISS_SETUP_SUCCESS = 'DISMISS_SETUP_SUCCESS';
export const DISMISS_SETUP_FAILURE = 'DISMISS_SETUP_FAILURE';

export type DismissSetupRequest = ReturnType<typeof dismissSetupRequest>;
export const dismissSetupRequest = (homeActionCardId: number) =>
  action(DISMISS_SETUP_REQUEST, homeActionCardId);

export type DismissSetupSuccess = ReturnType<typeof dismissSetupSuccess>;
export const dismissSetupSuccess = (homeActionCardId: number | string) =>
  action(DISMISS_SETUP_SUCCESS, homeActionCardId);

export type DismissSetupFailure = ReturnType<typeof dismissSetupFailure>;
export const dismissSetupFailure = (error: Error, homeActionCardId: number) =>
  actionError(DISMISS_SETUP_FAILURE, homeActionCardId, error);

export function dismiss(homeActionCardId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      const appId = getState().currentApp.AppId as string;

      dispatch(dismissSetupRequest(homeActionCardId));

      await services.dismiss(appId, homeActionCardId);
      dispatch(dismissSetupSuccess(homeActionCardId));
    } catch (error) {
      dispatch(dismissSetupFailure(error, homeActionCardId));
    }
  };
}
// #endregion

// #region stores

export const STORE_SELECTION = 'HOME_STORE_SELECTION';
export type SetSelectedStores = ReturnType<typeof setSelectedStores>;
export const setSelectedStores = (props: {
  stores: number[];
  hasMore: boolean;
  nextPage: number;
}) => action(STORE_SELECTION, props);

export const STORE_SEARCH = 'HOME_STORE_SEARCH';
export type SetStoresSearch = ReturnType<typeof setStoresSearch>;
export const setStoresSearch = (props: { show: boolean }) => action(STORE_SEARCH, props);

export function loadStores(
  props: { page?: number; limit?: number; query?: string } = { page: 1, limit: 3 }
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const paginatedResult = await dispatch(loadByAppId(props));
    if (!paginatedResult) {
      return;
    }
    const { Data, TotalRecordCount, Page } = paginatedResult;
    let stores = Data.map((s) => s.StoreId as number);

    if (Page > 1) {
      stores = getState().home.stores.order_index.concat(stores);
    }

    const hasMore = TotalRecordCount > stores.length;
    const nextPage = hasMore ? Page + 1 : 0;

    dispatch(setSelectedStores({ stores, hasMore, nextPage }));
    dispatch(setStoresSearch({ show: hasMore || !!props.query }));
  };
}

export function loadNextStores(props: { limit?: number; query?: string } = { limit: 3 }) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const nextPage = getState().home.stores.nextPage;
    if (nextPage > 1) {
      dispatch(
        loadStores({
          page: nextPage,
          limit: props.limit,
          query: props.query,
        })
      );
    }
  };
}
// #endregion

// #region stores graph data
export const LOAD_STORES_NET_SALES_REQUEST = 'LOAD_STORES_NET_SALES_REQUEST';
export const LOAD_STORES_NET_SALES_SUCCESS = 'LOAD_STORES_NET_SALES_SUCCESS';
export const LOAD_STORES_NET_SALES_FAILURE = 'LOAD_STORES_NET_SALES_FAILURE';

export type LoadStoresNetSalesRequest = ReturnType<typeof loadStoresNetSalesRequest>;
export const loadStoresNetSalesRequest = () => action(LOAD_STORES_NET_SALES_REQUEST);

export type LoadStoresNetSalesSuccess = ReturnType<typeof loadStoresNetSalesSuccess>;
export const loadStoresNetSalesSuccess = (storeId: number, netSales: StoreDataPoint[]) =>
  action(LOAD_STORES_NET_SALES_SUCCESS, netSales, { storeId });

export type LoadStoresNetSalesFailure = ReturnType<typeof loadStoresNetSalesFailure>;
export const loadStoresNetSalesFailure = (error: Error) =>
  actionError(LOAD_STORES_NET_SALES_FAILURE, undefined, error);

export function loadStoresNetSales(storeId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    if (state.home.netSales.data[storeId]) {
      return;
    }

    try {
      dispatch(loadStoresNetSalesRequest());
      const result = await getStoreNetSales(appId, [storeId]);
      const data = result.length ? result[0].Data! : [];
      dispatch(loadStoresNetSalesSuccess(storeId, data));
    } catch (error) {
      dispatch(loadStoresNetSalesFailure(error));
    }
  };
}
// #endregion
// #region stores hours overrides
export const LOAD_STORE_OVERRIDES = 'LOAD_STORE_OVERRIDES';
export const LOAD_STORE_OVERRIDES_REQUEST = 'LOAD_STORE_OVERRIDES_REQUEST';
export const LOAD_STORE_OVERRIDES_SUCCESS = 'LOAD_STORE_OVERRIDES_SUCCESS';
export const LOAD_STORE_OVERRIDES_FAILURE = 'LOAD_STORE_OVERRIDES_FAILURE';

export type LoadStoreHourOverridesRequest = ReturnType<typeof loadStoreHourOverridesRequest>;
export const loadStoreHourOverridesRequest = (storeId: number) =>
  action(LOAD_STORE_OVERRIDES_REQUEST, { storeId });

export type LoadStoreHourOverridesSuccess = ReturnType<typeof loadStoreHourOverridesSuccess>;
export const loadStoreHourOverridesSuccess = (
  storeId: number,
  setupConfigs: BusinessHoursOverride[]
) => action(LOAD_STORE_OVERRIDES_SUCCESS, setupConfigs, { storeId });

export type LoadStoreHourOverridesFailure = ReturnType<typeof loadStoreHourOverridesFailure>;
export const loadStoreHourOverridesFailure = (storeId: number, error: Error) =>
  actionError(LOAD_STORE_OVERRIDES_FAILURE, { storeId }, error);

export function loadStoreHourOverrides(storeId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      if (getState().home.stores.overrides.data[storeId]) {
        return;
      }

      dispatch(loadStoreHourOverridesRequest(storeId));
      const { Data } = await loadOverrides({ storeId, after: new Date() });

      dispatch(loadStoreHourOverridesSuccess(storeId, Data));
    } catch (error) {
      dispatch(loadStoreHourOverridesFailure(storeId, error));
    }
  };
}
// #endregion
// #region remove override
export const REMOVE_STORE_OVERRIDE_REQUEST = 'REMOVE_STORE_OVERRIDE_REQUEST';
export const REMOVE_STORE_OVERRIDE_SUCCESS = 'REMOVE_STORE_OVERRIDE_SUCCESS';
export const REMOVE_STORE_OVERRIDE_FAILURE = 'REMOVE_STORE_OVERRIDE_FAILURE';

export type RemoveStoreHourOverrideRequest = ReturnType<typeof removeStoreHourOverrideRequest>;
export const removeStoreHourOverrideRequest = (props: { storeId: number; bhoId: number }) =>
  action(REMOVE_STORE_OVERRIDE_REQUEST, undefined, props);

export type RemoveStoreHourOverrideSuccess = ReturnType<typeof removeStoreHourOverrideSuccess>;
export const removeStoreHourOverrideSuccess = (props: { storeId: number; bhoId: number }) =>
  action(REMOVE_STORE_OVERRIDE_SUCCESS, undefined, props);

export type RemoveStoreHourOverrideFailure = ReturnType<typeof removeStoreHourOverrideFailure>;
export const removeStoreHourOverrideFailure = (props, error: Error) =>
  actionError(REMOVE_STORE_OVERRIDE_FAILURE, props, error);

export function removeStoreHourOverride(storeId: number, bhoId: number, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(removeStoreHourOverrideRequest({ storeId, bhoId }));
      await removeOverride(storeId, bhoId);
      dispatch(removeStoreHourOverrideSuccess({ storeId, bhoId }));
      return;
    } catch (error) {
      dispatch(removeStoreHourOverrideFailure({ storeId, bhoId }, error));
      dispatch(notifyError({ message: error.message, translate: true }));
      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region loadSavingData

export const LOAD_SAVINGS = 'LOAD_SAVINGS';
export const LOAD_SAVINGS_REQUEST = 'LOAD_SAVINGS_REQUEST';
export const LOAD_SAVINGS_SUCCESS = 'LOAD_SAVINGS_SUCCESS';
export const LOAD_SAVINGS_FAILURE = 'LOAD_SAVINGS_FAILURE';

export type LoadSavingsRequest = ReturnType<typeof loadSavingsRequest>;
export const loadSavingsRequest = () => action(LOAD_SAVINGS_REQUEST);

export type LoadSavingsSuccess = ReturnType<typeof loadSavingsSuccess>;
export const loadSavingsSuccess = (loadSavingData: CurrencyData[]) =>
  action(LOAD_SAVINGS_SUCCESS, loadSavingData);

export type LoadSavingsFailure = ReturnType<typeof loadSavingsFailure>;
export const loadSavingsFailure = (error: Error) =>
  actionError(LOAD_SAVINGS_FAILURE, undefined, error);

export function loadSavings() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(loadSavingsRequest());

      const result = await services.loadSavings(getState().currentApp.AppId!);
      dispatch(loadSavingsSuccess((result.TotalMoneySaved || []).filter((res) => res.Value !== 0)));
    } catch (error) {
      dispatch(loadSavingsFailure(error));
    }
  };
}
// #endregion
