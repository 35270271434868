import React, { useEffect, useMemo, useRef } from 'react';

import { InputBaseComponentProps } from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';

import { usePrevious } from '../../../custom-hooks/usePrevious';
import { stripHTML } from '../../../helpers/stripHTML';

function getSelection() {
  if (window.getSelection) {
    const selection = window.getSelection();

    if (selection && selection.getRangeAt) {
      const range = selection.getRangeAt(0);
      const selectedObj = window.getSelection();
      let rangeCount = 0;

      if (selectedObj && selectedObj.anchorNode && selectedObj.anchorNode.parentNode) {
        const childNodes = selectedObj.anchorNode.parentNode.childNodes as any;

        if (childNodes) {
          for (const el of childNodes) {
            if (el == selectedObj.anchorNode) {
              break;
            }
            if (el.outerHTML) {
              rangeCount += el.outerHTML.length;
            } else if (el.nodeType == 3) {
              rangeCount += el.textContent.length;
            }
          }
        }
      }

      return {
        selection: selection,
        position: range.startOffset + rangeCount,
      };
    }
  }
  return null;
}

function createRange(node, chars, range?: any) {
  let currentRange;

  if (!range && node) {
    currentRange = document.createRange();
    currentRange.selectNode(node);
    currentRange.setStart(node, 0);
  } else {
    currentRange = range;
  }

  if (chars.count === 0) {
    currentRange.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        currentRange.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (const el of node.childNodes) {
        currentRange = createRange(el, chars, currentRange);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return currentRange;
}

function setCurrentCursorPosition(chars, el) {
  if (chars >= 0) {
    const selection = window.getSelection();

    const range = createRange(el.parentNode, { count: chars });

    if (range) {
      range.collapse(false);
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    outline: 'none',
    wordBreak: 'break-all',
    minHeight: '19px',
  },
  excessText: {
    color: '#ff395f',
    background: '#ffeaed',
  },
});

const emptyStringRegExp = /^\s+$/;

const HighlightedTextLimit = ({
  value,
  onChange,
  onFocus,
  onBlur,
  name,
  maxLength = 40,
  ...rest
}: InputBaseComponentProps & { maxLength: number }) => {
  const classes = useStyles();
  const formattedValue = useMemo<string>(() => {
    const mainText = value.slice(0, maxLength);
    const excessText = value.slice(maxLength);

    if (!mainText) {
      return '';
    }

    return `${stripHTML(mainText)}<em class="${classes.excessText}">${stripHTML(excessText)}</em>`;
  }, [value]);
  const hasSelection = useRef(false);
  const currentPosition = useRef<number>(value.length);
  const previousValue = usePrevious(value);
  const rootEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rootEl.current && value) {
      const diff = hasSelection.current
        ? 0
        : value.length - (previousValue ? previousValue.length : 0);
      const newPosition = Math.min(currentPosition.current + diff, value.length);

      setCurrentCursorPosition(newPosition, rootEl.current);
    }
  }, [value]);
  const changeHandler = (e) => {
    const text = e.target.innerText as string;
    const value = emptyStringRegExp.test(text) ? '' : text;

    if (onChange) {
      // @ts-ignore
      onChange(value);
    }
  };
  const handleNewCursorPosition = () => {
    const currentSelection = getSelection();
    if (currentSelection) {
      const { selection, position } = currentSelection;

      const selectionEl =
        selection.anchorNode && selection.anchorNode.nodeType === Node.TEXT_NODE
          ? selection.anchorNode.parentNode
          : selection.anchorNode;
      let isSelection = false;

      if (selection.anchorOffset !== selection.focusOffset) {
        isSelection = true;
      } else if (
        selection.anchorOffset === selection.focusOffset &&
        selection.anchorNode !== selection.focusNode
      ) {
        isSelection = true;
      }

      currentPosition.current = selectionEl !== rootEl.current ? maxLength + position : position;
      hasSelection.current = isSelection;
    }
  };

  return (
    <div
      data-fd={rest['data-fd']}
      // @ts-ignore
      onFocus={() => onFocus(name)}
      // @ts-ignore
      onBlur={() => onBlur(name)}
      ref={rootEl}
      onClick={handleNewCursorPosition}
      onKeyDown={handleNewCursorPosition}
      className={classes.root}
      // @ts-ignore
      onInput={changeHandler}
      contentEditable={!rest.disabled}
      // dangerouslySetInnerHTML we are striping HTML tags here so this dangerouslySetInnerHTML is not an issue
      dangerouslySetInnerHTML={{ __html: formattedValue }}
    />
  );
};

export default HighlightedTextLimit;
