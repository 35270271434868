import React, { memo } from 'react';

import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';

import ExternalIconArrow from '../../../assets/images/ico_external.svg';

const useStyles = (colour: string) =>
  makeStyles(() => ({
    root: {
      position: 'relative',
    },
    avatar: {
      height: 16,
      width: 16,
      backgroundColor: colour,
    },
    borderDiv: {
      position: 'absolute',
      right: -15,
      top: -16,
      border: '3px solid white',
      borderRadius: '50%',
    },
  }));

type Props = {
  icon: JSX.Element;
  colour: string;
};

const ExternalIcon = ({ icon, colour }: Props) => {
  const classes = useStyles(colour)();

  return (
    <div className={classes.root}>
      {icon}
      <div className={classes.borderDiv}>
        <Avatar className={classes.avatar}>
          <img src={ExternalIconArrow} />
        </Avatar>
      </div>
    </div>
  );
};

export default memo(ExternalIcon);
