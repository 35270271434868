import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';

import useFilterablePagination from '../../custom-hooks/useFilterablePagination';
import useParseFilters from '../../custom-hooks/useParseFilters';
import { onlyDigitsRegExp } from '../../helpers/validation';
import EmptyComponent from '../../ui/EmptyComponent';
import ErrorComponent from '../../ui/ErrorComponent';
import FiltersContainer from '../../ui/Layout/FiltersContainer';
import Filter, { FilterValue } from '../common/Filter';
import { Loader } from '../common/Loader';
import TablePagination from '../common/TablePagination/TablePagination';
import { getAuditLogs } from './actions';
import { LogItem, MobileLogItem } from './LogItem';
import { AuditLog, Params } from './service';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  list: {
    borderTop: '1px solid rgb(224, 224, 224)',
  },
  empty: {
    [breakpoints.down('md')]: {
      padding: `0 ${spacing(2)}`,
    },
  },
  placeholderText: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  gridItem: {
    padding: spacing(1.5),
    marginBottom: spacing(3),
    [breakpoints.down('md')]: {
      padding: spacing(2),
      paddingBottom: spacing(3),
      marginBottom: spacing(1),
    },
  },
}));

const mapStateToProps = (state: AppState) => {
  const { auditLogs, Limit, Page, TotalRecordCount, auditLogsLoading, error } = state.auditLogs;
  return {
    error,
    auditLogsLoading,
    page: Page,
    logs: auditLogs,
    rowsPerPage: Limit,
    count: TotalRecordCount,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getAuditLogs: (params: Params) => dispatch(getAuditLogs(params)),
});

const rowPerPageOptions: number[] = [25, 50, 100, 200];

const mappedFilterTypeNames = {
  EventType: 'Activity',
};

const mappedFilterLabelNames = {
  StoreId: 'Store Id',
  OrderId: 'Order Id',
  MenuId: 'Menu Id',
  CampaignId: 'Campaign Id',
  UserName: 'User Name',
  UserId: 'User Id',
  UserEmail: 'User Email',
  VoucherCode: 'Voucher Code',
  StoreName: 'Store Name',
  EventType: 'Activity',
};

function addPartialEventTypes(events: string[]): string[] {
  const result: string[] = [];

  events.forEach((event) => {
    const levels = event.split('.').slice(0, -1);

    levels.reduce((prefix, level) => {
      const option = prefix ? `${prefix}.${level}.*` : `${level}.*`;

      if (!result.includes(option)) {
        result.push(option);
      }

      if (prefix) {
        return `${prefix}.${level}`;
      } else {
        return level;
      }
    }, '');
  });

  return [...result, ...events];
}

const EventTypes = addPartialEventTypes([
  'app.created',
  'app.updated',
  'appstore.configuration.created',
  'appstore.configuration.updated',
  'appstore.configuration.deleted',
  'bankaccount.assigned',
  'bankaccount.created',
  'bankaccount.deleted',
  'bankaccount.updated',
  'campaign.created',
  'campaign.edited',
  'campaign.loyalty.created',
  'campaign.loyalty.deleted',
  'campaign.loyalty.updated',
  'campaign.retention.created',
  'campaign.retention.deleted',
  'campaign.retention.updated',
  'campaign.status.updated',
  'cardreaders.kiosk.bluetooth.initiatepairingmode',
  'cardreaders.kiosk.bluetooth.updated',
  'catalog.group.archived',
  'catalog.group.created',
  'catalog.group.updated',
  'catalog.item.archived',
  'catalog.item.created',
  'catalog.item.updated',
  'customer.consent.updated',
  'customer.created',
  'customer.updated',
  'externalevent.store',
  'hydra.assigned',
  'hydra.conection_status_changed',
  'hydra.request_reset',
  'hydra.setting_changed',
  'hydra.store.assigned',
  'hydra.store.unassigned',
  'hydra.unassigned',
  'kiosk.bluetooth.pairing.mode',
  'kiosk.bluetooth.terminal.paired',
  'kiosk.bluetooth.terminal.unpaired',
  'kiosk.bluetooth.terminal.updated',
  'menu_checkpoint.created',
  'menu.bulk.edit',
  'menu.created',
  'menu.async_creation.completed',
  'menu.option_set_item.created',
  'menu.option_set_item.deleted',
  'menu.option_set_item.updated',
  'menu.option_set.created',
  'menu.option_set.deleted',
  'menu.option_set.updated',
  'menu.section_item.created',
  'menu.section_item.deleted',
  'menu.section_item.updated',
  'menu.section.created',
  'menu.section.deleted',
  'menu.section.updated',
  'menu.updated',
  'menu.uploaded',
  'order.accepted',
  'order.capacity.updated',
  'order.created',
  'order.dispatched',
  'order.fulfillment.status.updated',
  'order.rating.updated',
  'order.refunded',
  'order.rejected',
  'order.tip.updated',
  'phone_call.ended',
  'phone_call.started',
  'printer.assigned_to_store',
  'printer.turned_off',
  'printer.turned_on',
  'printer.unassigned_from_store',
  'push_notification.deleted',
  'push_notification.scheduled',
  'push_notification.sent',
  'sms.received',
  'store_group.created',
  'store_group.deleted',
  'store_group.updated',
  'store.address.updated',
  'store.archived',
  'store.business_hours_override.created',
  'store.business_hours_override.deleted',
  'store.created',
  'store.deleted',
  'store.delivery_zone.created',
  'store.delivery_zone.deleted',
  'store.delivery_zone.updated',
  'store.fee_config.updated',
  'store.kiosk_setting.updated',
  'store.logo.created',
  'store.logo.deleted',
  'store.logo.updated',
  'store.menu.assigned',
  'store.opening_hours.updated',
  'store.order.capacity.updated',
  'store.preorder_config.updated',
  'store.published',
  'store.unarchived',
  'store.unpublished',
  'store.updated',
  'stripecustomconnectedaccount.updated',
  'teammate.deleted',
  'teammate.invite.accepted',
  'teammate.invite.sent',
  'teammate.updated',
  'user.created',
  'user.deleted',
  'user.login',
  'user.updated',
  'voucher.created',
  'voucher.deleted',
  'voucher.updated',
  'terminal.created',
  'terminal.updated',
  'website.vanityUrl.updated',
  'telephony_config.updated',
  'channel.stores.updated',
]);

const getOptionValue = (
  value: string,
  name: string,
  filters: { [key: string]: string | string[] }
): string | string[] => {
  if (name === 'EventType') {
    return EventTypes.reduce<string[]>((res, item) => {
      if (
        item.includes(value.toLowerCase()) &&
        !(filters.Activity && Array.isArray(filters.Activity)
          ? filters.Activity.some((filter) => filter === item)
          : filters.Activity === item)
      ) {
        res.push(item);
      }

      return res;
    }, []);
  }

  return value;
};

const addOption = ({
  name,
  value,
  filters,
  options,
}: {
  name: string;
  value: string;
  filters: {
    [key: string]: string;
  };
  options: FilterValue[];
}) => {
  if ((name === 'EventType' && !EventTypes.includes(value)) || !filters[name]) {
    const finalValue = getOptionValue(value, name, filters);
    if (finalValue && finalValue.length) {
      options.push({
        type: name,
        value: getOptionValue(value, name, filters),
        label: `${mappedFilterLabelNames[name] || name}: ${value}`,
      });
    }
  }
};

const getSuggestionsList = (value: string, params: any) => {
  const options: FilterValue[] = [];
  const { filter: filters = [] } = params;

  if (onlyDigitsRegExp.test(value)) {
    addOption({ name: 'StoreId', value, filters, options });
    addOption({ name: 'OrderId', value, filters, options });
    addOption({ name: 'UserId', value, filters, options });
    addOption({ name: 'MenuId', value, filters, options });
    addOption({ name: 'CampaignId', value, filters, options });
  }

  if (value.includes('@')) {
    addOption({ name: 'UserEmail', value, filters, options });
  } else if (value.length) {
    addOption({ name: 'UserName', value, filters, options });
    addOption({ name: 'VoucherCode', value, filters, options });
    // addOption({ name: 'StoreName', value, filters, options });
    addOption({ name: 'EventType', value, filters, options });
  }

  return options;
};

type Props = {
  page: number;
  count: number;
  logs: AuditLog[];
  rowsPerPage: number;
  auditLogsLoading: boolean;
  translate: (input: string) => string;
} & MappedDispatch &
  RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

function AuditLogs({
  auditLogsLoading,
  translate,
  count,
  logs,
  getAuditLogs,
  history,
  error,
}: Props) {
  const {
    page,
    rowsPerPage,
    filters,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    params,
  } = useFilterablePagination(history, true, [mappedFilterLabelNames.EventType]);
  const [options, setOptions] = useState<FilterValue[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  const parsedFilters = useParseFilters(filters);

  const renderItems = useCallback(
    (isMobile = false) => {
      return logs.map((log, i) =>
        isMobile ? <MobileLogItem key={i} data={log} /> : <LogItem key={i} data={log} />
      );
    },
    [logs]
  );

  const loadData = () => {
    getAuditLogs({
      page: page + 1,
      limit: rowsPerPage,
      ...parsedFilters,
    });
  };

  useEffect(loadData, [page, rowsPerPage, parsedFilters]);

  useEffect(() => {
    setOptions(getSuggestionsList(searchValue, params));
  }, [searchValue, params]);

  const handleSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchValue(value);
      }, 300),
    []
  );

  const placeholderOptions: FilterValue[] = useMemo(() => {
    const value = translate('Enter_query') as string;

    return [
      'StoreId',
      'OrderId',
      // 'StoreName',
      'MenuId',
      'CampaignId',
      'UserId',
      'UserName',
      'UserEmail',
      'VoucherCode',
      'EventType',
    ].map((name) => ({
      value,
      type: `${mappedFilterTypeNames[name] || name}:${value}`,
      label: `${mappedFilterLabelNames[name] || name}: <span class=${
        classes.placeholderText
      }>${value}</span>`,
    }));
  }, []);

  return (
    <>
      <FiltersContainer>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Filter
            dateFilter
            withTime
            placeholder={translate('Filter_by_date_and_time')}
            value={filters}
            onChange={handleFilter}
            options={options}
            placeholderOptions={placeholderOptions}
            onInputChange={handleSearch}
          />
        </Grid>
      </FiltersContainer>
      {auditLogsLoading ? (
        <Loader />
      ) : error ? (
        <div className={classes.empty}>
          <ErrorComponent title="Something_went_wrong" action={loadData} />
        </div>
      ) : logs.length ? (
        <>
          <Hidden mdDown>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{translate('What')}</TableCell>
                    <TableCell>{translate('Who')}</TableCell>
                    <TableCell>{translate('When')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderItems()}</TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={rowPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.list}>{renderItems(true)} </div>
            <TablePagination
              component="div"
              labelRowsPerPage=""
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Hidden>
        </>
      ) : (
        <div className={classes.empty}>
          <EmptyComponent
            title="Empty_audit_logs_header"
            subtitle="Empty_audit_logs_subheader"
            noLink
          />
        </div>
      )}
    </>
  );
}

const EnhancedComponent = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuditLogs);

export { EnhancedComponent as AuditLogs };
