import React, { useEffect, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Translate } from 'react-localize-redux';

import { isErrorMessageRegExp } from '../layouts/Notify/actions';
import { ServerError } from '../models/serverErrors';
import { useErrorAsIndicator, useLoadingAsIndicator } from './useAsIndicator';
import { usePrevious } from './usePrevious';

type APISnackBarOptions = {
  loading?: boolean;
  loadingMessage?: string;
  success?: boolean;
  successMessage?: TranslationId;
  failure?: boolean;
  failureMessage?: string | Error | ServerError | null;
  autoSaving?: boolean;
  autoSavingMessage?: string;
};

export const useRequestSnackBarIndicators = (loading: boolean, error?: string | Error) => {
  const failure = useErrorAsIndicator(error);
  const successAutoSaving = useLoadingAsIndicator(loading);
  const success = successAutoSaving && !error;

  return {
    success,
    failure,
  };
};

const useRequestSnackBar = function ({
  loading,
  success,
  failure,
  autoSaving,
  failureMessage,
  successMessage,
}: APISnackBarOptions) {
  const previousLoading = usePrevious(loading);
  const previousAutoSaving = usePrevious(autoSaving);
  const previousSuccess = usePrevious(success);
  const previousFailure = usePrevious(failure);
  const previousSnack = useRef<null | string | number>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const closePreviousSnack = () => {
    if (previousSnack.current) {
      closeSnackbar(previousSnack.current);
    }
  };

  useEffect(() => {
    if (previousLoading !== loading || previousAutoSaving !== autoSaving) {
      closePreviousSnack();
      if (loading || autoSaving) {
        previousSnack.current = enqueueSnackbar(
          <span id="auto_save_notification">
            <CircularProgress size={20} style={{ color: '#fff', marginRight: '12px' }} />
            <Translate>
              {(translate) => {
                if (loading) {
                  return translate('Loading') + '...';
                }
                if (autoSaving) {
                  return translate('Auto_saving') + '...';
                }
                return '';
              }}
            </Translate>
          </span>,
          {
            persist: true,
            ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          }
        );
      }
    }

    if (success && success !== previousSuccess) {
      closePreviousSnack();
      previousSnack.current = enqueueSnackbar(
        successMessage ? <Translate id={successMessage} /> : 'Success',
        {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000,
          resumeHideDuration: 1000,
        }
      );
    }

    if (failure && failure !== previousFailure) {
      closePreviousSnack();
      const errorText =
        failureMessage && typeof failureMessage === 'string'
          ? failureMessage
          : failureMessage instanceof ServerError
            ? failureMessage.message
            : undefined;
      const shouldTranslate =
        failureMessage && (errorText ? !isErrorMessageRegExp.test(errorText) : false);

      enqueueSnackbar(
        shouldTranslate ? (
          <Translate id={errorText as TranslationId} />
        ) : (
          String(failureMessage || 'Error')
        ),
        {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 3000,
          resumeHideDuration: 1000,
        }
      );
    }
  }, [loading, success, failure, autoSaving]);
};

export default useRequestSnackBar;
