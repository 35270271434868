import React from 'react';

import { OrderSummary } from '@flipdish/api-client-typescript';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TBodyRow from './TBodyRow';
import THead from './THead';

const OrderListTable: React.FC<
  React.PropsWithChildren<{
    orders: OrderSummary[];
  }>
> = ({ orders }) => {
  return (
    <Table>
      <THead />
      <TableBody>
        {orders.map((order: OrderSummary) => (
          <TBodyRow key={order.OrderId} order={order} />
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderListTable;
