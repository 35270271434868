import { parse as parseQuery } from 'qs';

import constants from '../constants.json';
import { localStorage } from './storage';

let API_BASE: string;
if (process.env.VITE_API_BASE) {
  API_BASE = process.env.VITE_API_BASE;
} else {
  API_BASE = constants.PORTAL_API_BASE_URL;
}
let SUBSCRIPTIONS_API_BASE: string;
if (process.env.VITE_SUBSCRIPTIONS_API_BASE) {
  SUBSCRIPTIONS_API_BASE = process.env.VITE_SUBSCRIPTIONS_API_BASE;
} else {
  SUBSCRIPTIONS_API_BASE = constants.PORTAL_SUBSCRIPTIONS_API_BASE_URL;
}
let IDENTITY_API_BASE;
if (process.env.VITE_PORTAL_IDENTITY_BASE_URL) {
  IDENTITY_API_BASE = process.env.VITE_PORTAL_IDENTITY_BASE_URL;
} else {
  // Default to the same as API_BASE
  IDENTITY_API_BASE = API_BASE;
}

export { API_BASE };

export function getApiEndPoint() {
  // tslint:disable-next-line:variable-name
  const API_BASE_storage = localStorage.getItem('flipdish-api-endpoint');
  // tslint:disable-next-line:variable-name
  const API_BASE_query = parseQuery(window.location.search.substring(1));
  // tslint:disable-next-line:variable-name

  if ('fd-api' in API_BASE_query) {
    if (API_BASE_query['fd-api']) {
      localStorage.setItem('flipdish-api-endpoint', API_BASE_query['fd-api'] as any);
    } else if (API_BASE_storage) {
      localStorage.removeItem('flipdish-api-endpoint');
    }
  }
  return localStorage.getItem('flipdish-api-endpoint') || API_BASE;
}

// TODO: Replace URL with dynamic url once ready
export const getSubscriptionsApiEndPoint = () => {
  // tslint:disable-next-line:variable-name
  const API_BASE_storage = localStorage.getItem('flipdish-subscriptions-api-endpoint');

  // tslint:disable-next-line:variable-name
  const API_BASE_query = parseQuery(window.location.search.substring(1));

  if ('fd-subscriptions-api' in API_BASE_query) {
    if (API_BASE_query['fd-subscriptions-api']) {
      localStorage.setItem(
        'flipdish-subscriptions-api-endpoint',
        API_BASE_query['fd-subscriptions-api'] as any
      );
    } else if (API_BASE_storage) {
      localStorage.removeItem('flipdish-subscriptions-api-endpoint');
    }
  }
  return localStorage.getItem('flipdish-subscriptions-api-endpoint') || SUBSCRIPTIONS_API_BASE;
};

export const getIdentityApiEndpoint = () => {
  return localStorage.getItem('identity-api-endpoint') ?? IDENTITY_API_BASE;
};

export const setIdentifyApiEndpoint = () => {
  // tslint:disable-next-line:variable-name
  const API_BASE_query = parseQuery(window.location.search.substring(1));

  if ('fd-identity-api' in API_BASE_query) {
    if (API_BASE_query['fd-identity-api']) {
      localStorage.setItem('identity-api-endpoint', API_BASE_query['fd-identity-api'] as any);
    } else {
      localStorage.removeItem('identity-api-endpoint');
    }
  }
};

// #region @flipdish/api-client-typescript response Date FIXES

export function tryFixApiWrongDateParsingPaginationResult<R>(
  responseObj: { body: Flipdish.RestApiPaginationResult<any> },
  dateProps: Array<keyof R>,
  defaultValue: Flipdish.RestApiPaginationResult<R>
): Flipdish.RestApiPaginationResult<R> {
  try {
    const { Data, Limit, Page, TotalRecordCount } = responseObj.body;

    const data = Data.map((obj) => {
      const result = {} as R;
      const overrides = dateProps.reduce(
        (agg, prop) => {
          if (obj[prop] && typeof obj[prop] === 'string') {
            // TODO: check if contains zone offset
            const utcDate = new Date(
              (obj[prop] as string).endsWith('Z') ? obj[prop] : `${obj[prop]}Z`
            );
            agg[prop] = utcDate;
          }
          return agg;
        },
        {} as { [key in keyof R]: Date }
      );

      Object.assign(result, obj, overrides);
      return result;
    });

    return {
      Limit,
      Page,
      TotalRecordCount,
      Data: data,
    } as Flipdish.RestApiPaginationResult<R>;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    return defaultValue;
  }
}
export function tryFixApiWrongDateParsing<R>(
  responseObj: { body: any },
  dateProps: Array<keyof R>,
  defaultValue: R
): R {
  try {
    let rawData = responseObj.body;
    if (rawData.Data) {
      rawData = rawData.Data;
    }

    const result = {} as R;

    const overrides = dateProps.reduce(
      (agg, prop) => {
        if (rawData[prop] && typeof rawData[prop] === 'string') {
          // TODO: check if contains zone offset
          const utcDate = new Date(
            (rawData[prop] as string).endsWith('Z') ? rawData[prop] : `${rawData[prop]}Z`
          );
          agg[prop] = utcDate;
        }
        return agg;
      },
      {} as { [key in keyof R]: Date }
    );

    Object.assign(result, rawData, overrides);

    return result;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    return defaultValue;
  }
}

// #endregion
