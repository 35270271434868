import React, { useState } from 'react';

import { Order } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import { amber } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import round from 'lodash/round';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import InfoIcon from '../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../ui/Tooltip/Tooltip';
import CancelOrderControl from './CancelOrderControl';
import NotifyCustomerControl from './NotifyCustomerControl';

const styles = (theme: Theme) => {
  return createStyles({
    refundAmount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 220,
    },

    textField: {
      width: 200,
    },
    radioButtons: {
      height: 24,
      marginTop: 12,
    },
    notifyCustomer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    warningText: {
      color: amber[700],
      width: 200,
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    dialogActions: {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
    },
  });
};

const calculateRefundableAmount = (totalAmount: number, refundedAmount: number) => {
  return round(totalAmount - refundedAmount, 2);
};
type Props = {
  open: boolean;
  handleRefundOrder: (
    refundAmount: number,
    notifyCustomerRefund: boolean,
    cancelOrder: boolean
  ) => void;
  handleClose: () => void;
} & WithStyles<typeof styles> &
  MappedState;

const RefundDialog: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, open, handleRefundOrder, handleClose, translate, order } = props;

  const [amount, setAmount] = useState('');
  const [isAmountDisabled, setIsAmountDisabled] = useState(false);
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [cancelOrder, setCancelOrder] = useState(false);

  const [error, setError] = useState<{ error: boolean; message: React.ReactElement | null }>({
    error: false,
    message: null,
  });

  // only remove processing fee when it's a cash order
  const refundableAmount = calculateRefundableAmount(
    order.Amount ? order.Amount : 0,
    order.RefundedAmount ? order.RefundedAmount : 0
  );

  const handleCancelOrder = (cancelOrderChecked: boolean) => {
    setAmount(refundableAmount.toFixed(2));
    setCancelOrder(cancelOrderChecked);
    if (cancelOrderChecked) {
      setIsAmountDisabled(true);
    } else {
      setIsAmountDisabled(false);
    }
  };

  const handleAmountChagne = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ error: false, message: null });

    const amount = e.target.value;
    setAmount(amount);
  };

  const handleExit = () => {
    setAmount('');
    setIsAmountDisabled(false);
    setNotifyCustomer(true);
    setCancelOrder(false);
  };

  const getRefundDialogButtonText = (): JSX.Element => {
    if (order.Amount === 0) {
      return <Translate id="Cancel" />;
    }

    return <Translate id="Refund" />;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogContent>
        <div className={classes.refundAmount}>
          <TextField
            variant="standard"
            className={classes.textField}
            label={<Translate id="Refund amount" />}
            margin="normal"
            type="number"
            autoFocus
            disabled={isAmountDisabled}
            error={error.error}
            helperText={error.message}
            placeholder={`${translate('Refund-amount-placeholder')} ${refundableAmount.toFixed(2)}`}
            inputProps={{
              'data-fd': 'refund-amount',
            }}
            value={amount}
            onChange={handleAmountChagne}
            aria-describedby="Notify-customer-sms-tooltip"
          />
        </div>
        <Grid container alignItems="center">
          <CancelOrderControl handleChange={handleCancelOrder} val={cancelOrder} />
        </Grid>
        {order.PaymentAccountType !== Order.PaymentAccountTypeEnum.Cash && (
          <Grid container alignItems="center">
            <NotifyCustomerControl handleChange={setNotifyCustomer} val={notifyCustomer} />
            <Tooltip
              messageId="Notify-customer-sms-tooltip"
              fdKey="Notify-customer-sms-tooltip"
              id="Notify-customer-sms-tooltip"
            >
              <div>
                <InfoIcon size={13} verticalAlign="text-top" />
              </div>
            </Tooltip>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.dialogActions}
        >
          <Grid item>
            <Button
              data-fd="refund_dialog_cancel"
              onClick={() => {
                setError({ error: false, message: null });
                handleClose();
              }}
              color="primary"
            >
              <Translate id="Close" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-fd="refund_dialog_submit"
              onClick={() => {
                const refundAmount = parseFloat(amount);
                if (!amount || refundAmount < 0) {
                  setError({ error: true, message: <Translate id="Invalid_input" /> });
                } else if (refundAmount > refundableAmount) {
                  setError({
                    error: true,
                    message: (
                      <Translate>
                        {(translate) =>
                          `${translate('Refund-amount-error')} ${refundableAmount.toFixed(2)}`
                        }
                      </Translate>
                    ),
                  });
                } else {
                  handleRefundOrder(refundAmount, notifyCustomer, cancelOrder);
                  handleClose();
                }
              }}
              color="primary"
            >
              {getRefundDialogButtonText()}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
    order: state.orders.order,
  };
};

const EnhancedComponent = compose<Props, any>(
  withStyles(styles, {
    name: 'refundDialog',
  }),
  connect(mapStateToProps)
)(RefundDialog);

export default EnhancedComponent;
