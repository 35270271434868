import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Snackbar, { type SnackbarProps } from '@mui/material/Snackbar';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose, defaultProps, setDisplayName } from 'recompose';

import BackButtonWrapper from '../../ui/BackButtonWrapper';
import ErrorBoundary from './ErrorBoundary';
import SlideTransition from './SlideTransition';
import SubPageTitle, { ISubTitleProps as SubTitleProps } from './SubPageTitle';

export { SubPageTitle as Title };
export type ISubTitleProps = SubTitleProps;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '16px',
      paddingTop: '48px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    paper: {
      [theme.breakpoints.up('sm')]: {
        padding: '24px 0 0 56px',
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: '16px',
      },
    },
    backButton: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '-48px',
      },
    },
    content: {
      paddingLeft: '16px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '0',
      },
    },
    autoSave: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    snackBar: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
  });

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  return (state: AppState, ownProps) => {
    return {
      notification: state.context.notification,
    };
  };
};

export interface ISubPageProps {
  rootClassName?: string;
  className?: string;
  title?: React.ComponentType<React.PropsWithChildren<{}>> | TranslationId;
  loading?: boolean;
  autoSaving?: boolean;
  SnackbarOptions?: Partial<SnackbarProps>;
  header?: React.ComponentType<React.PropsWithChildren<{}>>;
  backUrl?: string;
  actions?: React.ComponentType<React.PropsWithChildren<{}>>;
  children: React.ReactNode;
}
type Props = WithStyles<typeof styles> & RouteComponentProps<{}>;
export default compose<ISubPageProps & Props & MappedState, ISubPageProps>(
  setDisplayName('SubPage'),
  withStyles(styles, {
    name: 'FdSubPage',
    withTheme: true,
  }),
  withRouter,
  defaultProps({
    SnackbarOptions: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      ContentProps: {
        'aria-describedby': 'auto_save_notification',
        style: { minWidth: '160px' },
      },
      disableWindowBlurListener: true,
    }, // as SnackbarProps
  }),
  connect(mapStateToPropsFactory)
)(({
  children,
  title: TitleComponent,
  classes,
  rootClassName,
  className,
  loading,
  autoSaving,
  SnackbarOptions,
  header: HeaderComponent,
  notification,
  backUrl,
  actions: ActionsComponent,
}) => {
  return (
    <div className={clsx(classes.root, rootClassName)}>
      <Hidden mdDown implementation="js">
        {HeaderComponent ? <HeaderComponent /> : null}
      </Hidden>
      <Paper className={clsx(classes.paper, className)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.titleWrapper}>
              <BackButtonWrapper backUrl={backUrl} className={classes.backButton}>
                {TitleComponent ? (
                  typeof TitleComponent === 'string' ? (
                    <SubPageTitle translateId={TitleComponent as TranslationId} />
                  ) : (
                    <TitleComponent />
                  )
                ) : null}
              </BackButtonWrapper>
            </div>
            <Divider />
          </Grid>

          <Hidden smUp implementation="js">
            {HeaderComponent ? (
              <Grid item xs={12}>
                <HeaderComponent />
                <Divider />
              </Grid>
            ) : null}
          </Hidden>

          <Grid item xs={12} className={classes.content}>
            <ErrorBoundary identifier="sub-page">{children}</ErrorBoundary>
          </Grid>
          <Grid item>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={notification.show || loading || autoSaving || false}
              TransitionComponent={SlideTransition}
              classes={{
                root: classes.snackBar,
              }}
              {...SnackbarOptions}
              message={
                <span id="auto_save_notification" className={classes.autoSave}>
                  <CircularProgress size={20} style={{ color: '#fff', marginRight: '12px' }} />
                  <Translate>
                    {(translate) => {
                      if (notification.show) {
                        return notification.translateId
                          ? translate(notification.translateId)
                          : notification.message;
                      }
                      if (loading) {
                        return translate('Loading') + '...';
                      }
                      if (autoSaving) {
                        return translate('Auto_saving') + '...';
                      }
                      return '';
                    }}
                  </Translate>
                </span>
              }
            />
          </Grid>
        </Grid>
      </Paper>
      {ActionsComponent && <ActionsComponent />}
    </div>
  );
});
