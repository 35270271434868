import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

export interface Props {
  className?: string;
  color?: 'blue' | 'green' | 'grey' | 'purple' | 'yellow';
  label: TranslationId;
  size?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    alignSelf: 'center',
    borderRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    margin: 0,
  },
  blue: {
    backgroundColor: '#05149E',
    color: theme.palette.common.white,
  },
  green: {
    backgroundColor: '#20C26E',
    color: theme.palette.common.white,
  },
  grey: {
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    color: theme.palette.common.white,
  },
  purple: {
    backgroundColor: '#DEACFA',
    color: theme.palette.common.black,
  },
  yellow: {
    backgroundColor: '#FFBC2C',
    color: theme.palette.common.black,
  },
  small: {
    fontSize: '12px',
    padding: theme.spacing(0.2, 1),
  },
  medium: {
    fontSize: '13px',
    padding: theme.spacing(0.5, 2),
  },
  large: {
    fontSize: '20px',
    padding: theme.spacing(0.5, 2),
  },
}));

const Tag = (props: Props) => {
  const { className = '', color = 'blue', size = 'small', label } = props;
  const classes = useStyles();

  const tagStyles = clsx(
    'TagStyles',
    classes.root,
    color === 'blue' && classes.blue,
    color === 'green' && classes.green,
    color === 'grey' && classes.grey,
    color === 'purple' && classes.purple,
    color === 'yellow' && classes.yellow,
    size === 'small' && classes.small,
    size === 'medium' && classes.medium,
    size === 'large' && classes.large,
    className
  );

  return (
    <span className={tagStyles}>
      <Translate id={label} />
    </span>
  );
};

export default Tag;
