import React, { useEffect, useRef, useState } from 'react';

import {
  Group,
  GroupReference,
  MetafieldDefinition,
  PendingMenuChanges,
  Product,
} from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { isNumber } from 'lodash';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Button, CheckboxField, InputField, MessageBanner, Typography } from '@fd/ui/atoms';
import { FormikInputField, FormSection } from '@fd/ui/molecules';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';

import PreventNavigation from '../../../../Finance/Banking/components/PreventNavigation';
import { HelpdeskButton } from '../../../../HelpdeskButton';
import HelpDrawer from '../../../../HelpDrawer/HelpDrawer';
import { AddModifierGroupDrawer } from '../../../components/AddModifierGroupDrawer';
import { CatalogMetafieldsFormSection } from '../../../components/CatalogMetafieldsFormSection';
import { DraggableGroupList } from '../../../components/DraggableGroupList';
import { ImagerySection } from '../../../components/ImagerySection';
import { InternalNoteFormSection } from '../../../components/InternalNoteFormSection';
import { PendingMenuChangesBanner } from '../../../components/PendingMenuChangesBanner';
import { SaveCatalogChangesButton } from '../../../components/SaveCatalogChangesButton/SaveCatalogChangesButton';
import { INTERNAL_NOTES_METAFIELD_KEY } from '../../../constants';
import { catalogImagesService } from '../../../services/catalogImages.service';
import {
  generateSku,
  getLowestModifierPrice,
  getModifierGroupThatSetsPrice,
  setMetafield,
  validateCatalogItemForm,
} from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  addModifierGroupButton: {
    width: '215px',
    marginTop: theme.spacing(3),
  },
  banner: {
    borderRadius: '5px',
    width: '100%',
  },
  formContent: {
    paddingBottom: theme.spacing(3),
  },
  field: {
    minHeight: '5rem',
    marginTop: theme.spacing(1),
  },
  groupListText: {
    marginBottom: theme.spacing(2),
  },
  moreLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: '#061c9d',
    cursor: 'pointer',
  },
  priceField: {
    width: '200px',
    minHeight: '5rem',
  },
  productId: {
    alignSelf: 'center',
    marginTop: theme.spacing(3),
    marginBottm: theme.spacing(2),
  },
  saveButtonContainer: {
    marginTop: theme.spacing(14),
    paddingRight: theme.spacing(3),
  },
}));

export type EditFormProps = {
  appId: string;
  canEdit: boolean;
  isUpdateForm?: boolean;
  isLoading?: boolean;
  metafields?: MetafieldDefinition[];
  onSaveChanges: (updatedProduct, saveType: number) => Promise<void>;
  pendingMenuChanges?: PendingMenuChanges[];
  product?: Product;
  translate: TranslateFunction;
};

export const ProductForm = (props: EditFormProps): JSX.Element => {
  const {
    appId,
    canEdit,
    isUpdateForm = false,
    isLoading = false,
    metafields,
    onSaveChanges,
    pendingMenuChanges = [],
    product,
    translate,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [modifierGroups, setModifierGroups] = useState<Group[]>([]);
  const [productPriceFromModifier, setProductPriceFromModifier] = useState<number | null>(null);
  const [productPrice, setProductPrice] = useState<number | null>(null);
  const formRef = useRef<any>(null);
  const [saveType, setSaveType] = useState<number>(0);
  let initialName = '';
  if (!props.isUpdateForm) {
    initialName = new URLSearchParams(location.search).get('name') || '';
  }
  const classes = useStyles();
  const disabled = !canEdit || product?.IsArchived;
  const showInternalNotes =
    metafields &&
    metafields?.findIndex((metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY) !== -1;
  const customMetafields = metafields?.filter(
    (metafield) => metafield.Key !== INTERNAL_NOTES_METAFIELD_KEY
  );

  useEffect(() => {
    if (product) {
      setModifierGroups(getModifierGroups(product.Groups));
      setProductPrice(product.Price);
    }
  }, [product]);

  useEffect(() => {
    let price: number | null = null;
    if (productPrice === 0) {
      const modifierThatSetsPrice = getModifierGroupThatSetsPrice(modifierGroups);
      price = getLowestModifierPrice(modifierThatSetsPrice);
    }
    setProductPriceFromModifier(price);
  }, [modifierGroups, productPrice]);

  const onAddModifierGroup = (newGroup: Group) => {
    setModifierGroups([...modifierGroups, newGroup]);
    const newSubProduct = {
      CatalogItemId: newGroup.CatalogItemId as string,
      GroupType: GroupReference.GroupTypeEnum.ModifierGroup,
    } as GroupReference;
    formRef.current?.setFieldValue('Groups', [...formRef.current?.values.Groups, newSubProduct]);
  };

  const getModifierGroups = (groupRefs?: GroupReference[]) => {
    if (!groupRefs || groupRefs.length == 0) {
      return [];
    }
    const modifierGroups: Group[] = [];
    groupRefs.forEach((groupRef) => {
      if (groupRef.Group) {
        modifierGroups.push(groupRef.Group);
      }
    });
    return modifierGroups;
  };

  const onRemoveModifierGroup = (index: number) => {
    const currentIndex = index;
    const newModifierGroups = [...modifierGroups];
    newModifierGroups.splice(currentIndex, 1);
    setModifierGroups(newModifierGroups);
    const groupRefsIndex = index;
    const newSubProducts = [...formRef.current?.values.Groups];
    newSubProducts.splice(groupRefsIndex, 1);
    formRef.current?.setFieldValue('Groups', newSubProducts);
  };

  const handleUpdateGroupOrder = (newOrder: Group[]) => {
    setModifierGroups(newOrder);
    formRef.current?.setFieldValue('Groups', newOrder);
  };

  const getInternalNote = (metafields): string => {
    const noteField = metafields.find(
      (metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY
    );
    return noteField?.Value || '';
  };

  const setMetafieldValue = (key: string, value: string) => {
    const metafieldIndex = formRef.current?.values.Metafields?.findIndex(
      (metafield) => metafield.Key === key
    );
    if (metafieldIndex !== -1 && value != '') {
      formRef.current?.setFieldValue(`Metafields[${metafieldIndex}].Value`, value);
    } else {
      const updatedMetafields = setMetafield(key, value, formRef.current?.values.Metafields);
      formRef.current?.setFieldValue('Metafields', updatedMetafields);
    }
  };

  const renderFromPriceMessaging = (price: number) => {
    return (
      <MessageBanner
        className={classes.banner}
        fdKey="from-price-banner"
        icon={<InfoIcon />}
        message={
          <>
            {translate('Modifier_group_from_price', {
              price: price.toFixed(2),
            })}
            <HelpDrawer label="portalhelp:products-from-pricing">
              <span className={classes.moreLink}>{translate('Learn_more')}</span>
            </HelpDrawer>
          </>
        }
      ></MessageBanner>
    );
  };

  return (
    <>
      <AddModifierGroupDrawer
        AppId={appId}
        currentModifierGroups={modifierGroups}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        onAddModifierGroup={onAddModifierGroup}
        translate={translate}
      />
      <Paper>
        {pendingMenuChanges.length > 0 && (
          <PendingMenuChangesBanner
            appId={appId}
            catalogElementId={product?.CatalogItemId as string}
            changesList={pendingMenuChanges}
            translate={translate}
          />
        )}
        {product?.IsArchived && (
          <MessageBanner
            fdKey="product-archived-banner"
            title={translate('Products_EditProductForm_ArchiveBannerTitle') as string}
            message={translate('Products_EditProductForm_ArchiveBannerMessage') as string}
          />
        )}
        <Formik
          enableReinitialize={isUpdateForm}
          innerRef={formRef}
          initialValues={{
            Alcohol: props.product?.Alcohol || false,
            Description: props.product?.Description || '',
            Groups: props.product?.Groups || [],
            ImageFileName: props.product?.ImageFileName || '',
            Metafields: props.product?.Metafields || [],
            Name: props.product?.Name || initialName,
            Price: props.product?.Price?.toFixed(2) || 0,
            Sku: props.product?.Sku || generateSku('P'),
          }}
          onSubmit={async (values, { setFieldError, setSubmitting }) => {
            try {
              const changes = {
                ...values,
                Price: Number(values.Price),
              };
              await onSaveChanges(changes, saveType);
              setSubmitting(false);
            } catch (error) {
              console.log('error', error);
              const errorMessage = translate(
                'Product_CreateProductDialog_DuplicateSkuError'
              ) as string;
              error.message.includes('SKU already exists') && setFieldError('Sku', errorMessage);
              setSubmitting(false);
            }
          }}
          validate={(values) => {
            return validateCatalogItemForm(values, translate);
          }}
        >
          {({ dirty, isSubmitting, setFieldValue, values, setFieldTouched }) => (
            <Form className={classes.formContent}>
              <PreventNavigation when={dirty && !isSubmitting} />
              <FormSection
                sectionTitle={translate('Products_EditProductForm_NamingAndDescription') as string}
              >
                <FormikInputField
                  isLoading={isLoading}
                  className={classes.field}
                  fdKey={'edit-product-form-name'}
                  name="Name"
                  label={translate('Products_EditProductForm_EnterProductName') as string}
                  InputProps={{
                    readOnly: disabled,
                  }}
                />
                <FormikInputField
                  isLoading={isLoading}
                  className={classes.field}
                  fdKey={'edit-product-form-desc'}
                  name="Description"
                  multiline
                  label={translate('Products_EditProductForm_EnterProductDescription') as string}
                  InputProps={{
                    readOnly: disabled,
                  }}
                />
              </FormSection>
              {showInternalNotes && (
                <InternalNoteFormSection
                  onNoteChange={(newValue) =>
                    setMetafieldValue(INTERNAL_NOTES_METAFIELD_KEY, newValue)
                  }
                  text={getInternalNote(values.Metafields)}
                  translate={translate}
                />
              )}
              <FormSection sectionTitle={translate('Products_EditProductForm_Pricing') as string}>
                <FormikInputField
                  className={classes.priceField}
                  disableLabelAnimation
                  fdKey="edit-product-form-price"
                  InputProps={{
                    readOnly: disabled,
                  }}
                  inputProps={{ inputMode: 'decimal', pattern: '^d*.?d*$', step: 'any' }}
                  isLoading={isLoading}
                  label={translate('Products_EditProductForm_DefaultPrice') as string}
                  name="Price"
                  onBlur={(e) => {
                    //custom onBlur overwrites default func that sets touched onBlur
                    setFieldTouched('Price', true);
                    const value = Number(e.target.value);
                    setProductPrice(value);
                    const formatted = value.toFixed(2);
                    setFieldValue('Price', formatted);
                  }}
                  type="number"
                  variant="outlined"
                />
                {isNumber(productPriceFromModifier) &&
                  renderFromPriceMessaging(productPriceFromModifier)}
              </FormSection>
              <FormSection sectionTitle={translate('Products_EditProductForm_Imagery') as string}>
                <ImagerySection
                  appId={appId}
                  description={
                    <>
                      <Typography variant="caption">
                        {translate('Products_EditProductForm_ImageryDescription')}
                      </Typography>
                      <HelpdeskButton label="portalhelp:/products/images" />
                    </>
                  }
                  disabled={disabled}
                  imageFileName={values.ImageFileName}
                  isLoading={isLoading}
                  onImageRemoved={() => setFieldValue('ImageFileName', '')}
                  onImageUploaded={(name: string) => setFieldValue('ImageFileName', name)}
                  showLarge
                  translate={translate}
                  uploadImage={catalogImagesService.uploadCatalogImage}
                />
              </FormSection>
              <FormSection sectionTitle={translate('Add_Modifiers') as string}>
                <Grid container direction="column">
                  <Typography className={classes.groupListText} variant="caption">
                    <Translate id="Add_modifier_group_description" />
                  </Typography>
                  {modifierGroups.length > 0 && (
                    <DraggableGroupList
                      appId={appId}
                      modifierGroups={modifierGroups}
                      onRemove={onRemoveModifierGroup}
                      onUpdateOrder={handleUpdateGroupOrder}
                      parentId={product?.CatalogItemId}
                      translate={translate}
                    />
                  )}
                  <Button
                    className={classes.addModifierGroupButton}
                    disabled={!canEdit}
                    fdKey={'add-modifier-group-button'}
                    onClick={() => setIsDrawerOpen(true)}
                    variant="secondary"
                  >
                    <Add />
                    <Translate id="Add_modifier_group" />
                  </Button>
                </Grid>
              </FormSection>
              <FormSection
                sectionTitle={translate('Products_EditProductForm_Identification') as string}
              >
                <Typography variant="caption">
                  {translate('Products_EditProductForm_IdentificationDescription') as string}
                </Typography>
                <Grid container item spacing={3}>
                  {isUpdateForm && (
                    <>
                      <Grid item className={classes.productId} xs={6}>
                        <Typography variant="body2">
                          {translate('Flipdish_ID') as string}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.productId} xs={6}>
                        <InputField
                          disabled
                          fdKey="flipdish-id-field"
                          isLoading={isLoading}
                          value={product?.CatalogItemId}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <FormikInputField
                      isLoading={isLoading}
                      className={classes.field}
                      fdKey={'edit-product-form-sku'}
                      name="Sku"
                      label="SKU"
                      InputProps={{
                        readOnly: disabled,
                      }}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FormSection sectionTitle={translate('Products_EditProductForm_Advanced') as string}>
                <CheckboxField
                  ariaLabel={translate('Products_IsAlcoholCheckbox') as string}
                  checked={values.Alcohol}
                  disabled={disabled}
                  fdKey="product-alcohol-checkbox"
                  label={translate('Products_EditProductForm_IsAlcohol') as string}
                  onChange={(isChecked) => setFieldValue('Alcohol', isChecked)}
                />
              </FormSection>
              {customMetafields && customMetafields.length > 0 && (
                <CatalogMetafieldsFormSection
                  metafields={customMetafields}
                  onMetafieldChange={(key, value) => setMetafieldValue(key, value)}
                  populatedMetafields={values.Metafields}
                  translate={translate}
                />
              )}
              <Grid
                container
                className={classes.saveButtonContainer}
                item
                xs={12}
                justifyContent="flex-end"
              >
                {!disabled &&
                  (isUpdateForm ? (
                    <SaveCatalogChangesButton
                      fdKey="edit_product_save_button"
                      disabled={!dirty}
                      onSaveTypeChange={(newSaveType) => setSaveType(newSaveType)}
                      translate={translate}
                    />
                  ) : (
                    <Button fdKey="edit_product_save_button" disabled={!dirty} type="submit">
                      <Translate id="Products_EditProductForm_SaveButtonTitle" />
                    </Button>
                  ))}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};
