import React, { useEffect, useState } from 'react';

import { AppConfigSalesChannel } from '@flipdish/api-client-typescript';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Typography } from '@fd/ui/atoms';
import { FormSection } from '@fd/ui/molecules';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7),
    },
  },
  textContainer: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  isDisabled?: boolean;
  isLoading: boolean;
  onChange: (updatedSetting: Partial<AppConfigSalesChannel>) => void;
  translate: TranslateFunction;
  value?: AppConfigSalesChannel.AddressEntryTypeEnum;
};

export const CustomerAddressFormatSelectFilter = (props: Props) => {
  const { isDisabled, isLoading, onChange, translate, value } = props;
  const [customerAddressLayout, setCustomerAddressLayout] =
    useState<AppConfigSalesChannel.AddressEntryTypeEnum>(
      value || AppConfigSalesChannel.AddressEntryTypeEnum.MapFirst
    );
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      setCustomerAddressLayout(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setCustomerAddressLayout(event.target.value);
    onChange({ AddressEntryType: event.target.value });
  };

  return (
    <FormSection
      sectionTitle={translate('Customer_address_format') as string}
      isLoading={isLoading}
    >
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography variantMapping={{ caption: 'p' }} variant="caption">
            <Translate id="iOS_and_Android_apps" />
          </Typography>
        </div>
        <TextField
          data-fd="set-customer-address-layout"
          disabled={isDisabled}
          fullWidth
          label={translate('Format') as string}
          onChange={handleChange}
          select
          value={customerAddressLayout}
          variant="outlined"
        >
          <MenuItem value={AppConfigSalesChannel.AddressEntryTypeEnum.MapFirst}>
            <Translate id="Map_and_text_entry" />
          </MenuItem>

          <MenuItem value={AppConfigSalesChannel.AddressEntryTypeEnum.TextEntryManualAllowed}>
            <Translate id="Text_entry_only_with_customer_edits_allowed" />
          </MenuItem>

          <MenuItem value={AppConfigSalesChannel.AddressEntryTypeEnum.TextEntryManualDisallowed}>
            <Translate id="Text_entry_only_without_customer_edits" />
          </MenuItem>
        </TextField>
      </div>
    </FormSection>
  );
};
