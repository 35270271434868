import { Model } from 'redux-orm';
import { CreateSuccess, LoadByIdSuccess, LoadSuccess } from '../actions/storegroup.actions';
import * as storeGroupConstants from '../constants/storegroup.constants';
import { storeGroupEventConstants } from '../signalr/hub.events';

export default class FlipdishStoreGroup extends Model<any> {}
FlipdishStoreGroup.modelName = 'FlipdishStoreGroup';
FlipdishStoreGroup.fields = {};
FlipdishStoreGroup.options = () => ({
  idAttribute: 'StoreGroupId',
});
FlipdishStoreGroup.reducer = (action, ormStoreGroup, session) => {
  const { type } = action;
  const storeGroupId = action.storeGroupId || (action.payload && action.payload.storeGroupId);
  const storeGroup = action.storeGroup || (action.payload && action.payload.storeGroup);

  switch (type) {
    case storeGroupConstants.STORE_GROUP_CREATE_SUCCESS:
      ormStoreGroup.upsert({
        ...(action as CreateSuccess).payload.storeGroup,
        AppId: (action as CreateSuccess).payload.appId,
      });
      break;
    case storeGroupConstants.STORE_GROUP_UPDATE_SUCCESS:
      ormStoreGroup.withId(storeGroupId).update(storeGroup);
      break;
    case storeGroupConstants.STORE_GROUP_REMOVE_SUCCESS:
      ormStoreGroup.withId(storeGroupId).delete();
      break;

    case storeGroupConstants.STORE_GROUP_GET_ALL_SUCCESS: {
      const storeGroups = (action as LoadSuccess).payload;
      const { appId } = (action as LoadSuccess).meta;
      storeGroups.map((sg) => ormStoreGroup.upsert({ ...sg, AppId: appId }));
      break;
    }

    case storeGroupConstants.STORE_GROUP_GET_SUCCESS: {
      const sg = (action as LoadByIdSuccess).payload;
      const { appId } = (action as LoadByIdSuccess).meta;
      ormStoreGroup.upsert({ ...sg, AppId: appId });
      break;
    }
    case storeGroupEventConstants.CREATED:
      ormStoreGroup.upsert(action.data.StoreGroup);
      break;
    case storeGroupEventConstants.UPDATED:
      if (ormStoreGroup.withId(action.data.StoreGroupId)) {
        ormStoreGroup.withId(action.data.StoreGroupId).update(action.data.StoreGroup);
      }
      break;
    case storeGroupEventConstants.DELETED:
      if (ormStoreGroup.withId(action.data.StoreGroupId)) {
        ormStoreGroup.withId(action.data.StoreGroupId).delete();
      }
      break;
  }
  return undefined;
};
