import React, { useEffect } from 'react';

import { PushNotificationRequest } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import marketingRetain from '../../assets/images/illust_marketing_retain.png';
import PushNotesPlaceholderImage from '../../assets/images/Push Placeholder Web.png';
import { useLoadingAsIndicator } from '../../custom-hooks/useAsIndicator';
import useRequestSnackBar from '../../custom-hooks/useRequestSnackBar';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import PageLayout from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import PlaceholderPage from '../../ui/PlaceholderPage';
import {
  GET_PUSH_NOTIFICATIONS,
  getPushNotifications,
  SEND_PUSH_NOTIFICATION,
  sendPushNotification,
} from './actions';
import PushNotificationsForm from './components/PushNotificationsForm';
import PushNotificationsList from './components/PushNotificationsList';

const updateLoadingSelector = createLoadingSelector([SEND_PUSH_NOTIFICATION]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([GET_PUSH_NOTIFICATIONS]);
const updateLoadingErrorSelector = createLoadingErrorSelectorFactory([SEND_PUSH_NOTIFICATION]);

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paper: {
    marginBottom: spacing(4),
  },
}));

type Props = IPushNotificationsProps & MapStateToProps & MappedDispatch;

export interface IPushNotificationsProps {
  submit: (changes) => Promise<void>;
}

const limit = 25;

const PushNotifications = (props: Props) => {
  const {
    notifications,
    autoSaving,
    loadingError,
    autoSavingError,
    error,
    savingError,
    getLogs,
    sendNotification,
    totalRecords,
    pushNotesEnabled,
  } = props;
  const successAutoSaving = useLoadingAsIndicator(autoSaving);
  const success = successAutoSaving && !autoSavingError;
  const classes = useStyles();

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_push_notification', {
      action: 'logged_in',
    });
  }, []);

  useRequestSnackBar({
    success,
    autoSaving,
    failure: loadingError || autoSavingError,
    failureMessage: error || savingError,
  });

  if (!pushNotesEnabled) {
    return (
      <PlaceholderPage
        btnText="Learn_more"
        cardImage={marketingRetain}
        cardText={['marketing_retain_1', 'marketing_retain_2']}
        helpdeskLink="https://www.flipdish.com/ie/resources/blog/push-notifications-to-get-more-online-food-orders"
        placeholderImage={PushNotesPlaceholderImage}
        title="Push_Notifications"
      />
    );
  }

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'push_notification.*' }}
      documentTitle="Push_Notifications"
      title={<Translate id="Push_Notifications" />}
    >
      <PaperContainer className={classes.paper}>
        <Box mb={1}>
          <Typography variant="subtitle2" color="textSecondary">
            <Translate id="are_you_promoting_voucher_code" />
          </Typography>
        </Box>
        <PushNotificationsForm onSubmit={sendNotification} saving={autoSaving} />
      </PaperContainer>
      <PushNotificationsList
        data={notifications}
        loadData={getLogs}
        hasMore={notifications.length !== totalRecords}
      />
    </PageLayout>
  );
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const appId = state.currentApp.AppId;
  const {
    pushNotifiactions: { data: notifications, error, savingError, totalRecords },
    currentApp: { HasAndroidApp, HasIosApp },
  } = state;

  return {
    error,
    appId,
    notifications,
    savingError,
    totalRecords,
    autoSaving: updateLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    autoSavingError: updateLoadingErrorSelector(state),
    pushNotesEnabled: HasAndroidApp || HasIosApp,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    getLogs: (page: number) => {
      return dispatch(getPushNotifications(page, limit));
    },
    sendNotification: (notification: PushNotificationRequest) =>
      dispatch(sendPushNotification(notification)),
  };
};

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(PushNotifications);
