import { PushNotificationResponse } from '@flipdish/api-client-typescript';
import { uniqBy } from 'lodash';

import {
  DELETE_PUSH_NOTIFICATION_FAILURE,
  DELETE_PUSH_NOTIFICATION_REQUEST,
  DELETE_PUSH_NOTIFICATION_SUCCESS,
  GET_PUSH_NOTIFICATIONS_FAILURE,
  GET_PUSH_NOTIFICATIONS_REQUEST,
  GET_PUSH_NOTIFICATIONS_SUCCESS,
  GetPushNotificationsSuccess,
  SEND_PUSH_NOTIFICATION_FAILURE,
  SEND_PUSH_NOTIFICATION_REQUEST,
  SEND_PUSH_NOTIFICATION_SUCCESS,
  UPDATE_PUSH_NOTIFICATION_SUCCESS,
} from './actions';

type DefaultState = {
  data: PushNotificationResponse[];
  totalRecords: number;
  page: number;
  error: string | null;
  deletingError: string | null;
  savingError: string | null;
};

const defaultState: DefaultState = {
  data: [],
  error: null,
  totalRecords: 0,
  page: 1,
  deletingError: null,
  savingError: null,
};

const getDate = (date: Date | undefined) => (date ? new Date(date).getTime() : -1);

const sortItems = (items: PushNotificationResponse[]) => {
  const sortedItems = [...items].sort(
    (a, b) =>
      getDate(b.ScheduledTime as unknown as Date) - getDate(a.ScheduledTime as unknown as Date)
  );
  return sortedItems;
};

const mergeRecords = (
  state: DefaultState,
  action: { payload: { page?: number; data: PushNotificationResponse[] } }
): PushNotificationResponse[] => {
  const currentItems = state.data;
  const newItems = action.payload.data;

  return uniqBy(
    [...(action.payload.page === 1 ? [] : currentItems), ...newItems],
    (item) => item.ScheduledPushNotificationId
  );
};

function pushNotificationsReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case GET_PUSH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        error: null,
        totalRecords: (action as GetPushNotificationsSuccess).payload.totalRecords,
        page: (action as GetPushNotificationsSuccess).payload.page,
        data: mergeRecords(state, action),
      };
    }

    case GET_PUSH_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case GET_PUSH_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }

    case SEND_PUSH_NOTIFICATION_FAILURE: {
      return {
        ...state,
        savingError: action.payload,
      };
    }

    case SEND_PUSH_NOTIFICATION_REQUEST: {
      return {
        ...state,
        savingError: null,
      };
    }

    case SEND_PUSH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        totalRecords: state.totalRecords + 1,
        data: sortItems([action.payload, ...state.data]),
        savingError: null,
      };
    }

    case DELETE_PUSH_NOTIFICATION_REQUEST: {
      return {
        ...state,
        deletingError: null,
      };
    }

    case DELETE_PUSH_NOTIFICATION_FAILURE: {
      return {
        ...state,
        deletingError: action.payload,
      };
    }

    case DELETE_PUSH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        deletingError: null,
        totalRecords: state.totalRecords - 1,
        data: state.data.filter((item) => item.ScheduledPushNotificationId !== action.payload),
      };
    }

    case UPDATE_PUSH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: state.data.reduce<PushNotificationResponse[]>((res, item) => {
          if (item.ScheduledPushNotificationId === action.payload.ScheduledPushNotificationId) {
            res.push(action.payload);
          } else {
            res.push(item);
          }

          return res;
        }, []),
      };
    }

    default:
      return state;
  }
}

export default pushNotificationsReducer;
