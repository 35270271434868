import React, { ChangeEvent, ReactNode, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import useIsMounted from '../../custom-hooks/useIsMounted';

const useStyles = makeStyles({
  label: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileInput: {
    top: 0,
    left: 0,
    position: 'absolute',
    opacity: 0,
    overflow: 'hidden',
    fontSize: '1000px',
    textIndent: '9999px',
  },
});

export type ControlProps = {
  reset: () => void;
};

type Props = {
  fdKey: string;
  className?: string;
  multiple?: boolean;
  disabled?: boolean;
  children?: ((data: ControlProps) => ReactNode) | ReactNode;
  onChange: (data: FileList | null, reset: () => void) => void;
};

export default function CustomFileInput(props: Props) {
  const classes = useStyles();
  const { onChange, children, className = '', fdKey, multiple, disabled } = props;
  const isMounted = useIsMounted();
  const [value, setValue] = useState('');

  const reset = () => {
    if (isMounted()) {
      setValue('');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
    onChange(e.target.files, reset);
  };

  return (
    <label className={`${classes.label} ${className}`}>
      {typeof children === 'function' ? children({ reset }) : children}
      <input
        value={value}
        type="file"
        multiple={multiple}
        data-fd={fdKey}
        disabled={disabled}
        className={classes.fileInput}
        onChange={handleInputChange}
      />
    </label>
  );
}
