import { formatCurrency } from 'fd-react-intl';
import { createSelector } from 'reselect';

import { activeLanguagesSelector } from '../../../selectors/locale.selector';

const reportDataSelector = (state: AppState) => state.reports.endOfDay.data;

// const permissionsSelector = (state: AppState) => state.permissions;
// export const showHiddenFeatureSelector = createSelector(
//   [permissionsSelector],
//   (permissions) => {
//     return permissions.some((p) => p === 'ShowHiddenFeatures');
//   }
// );

// #region getReportData

type ReportData = ReturnType<typeof getReportDataCombiner>;
const getReportDataCombiner = (data, locale) => {
  if (!data) {
    return;
  }

  const currencyFormatter = (value: number | string) => {
    const numVal = typeof value === 'string' ? parseFloat(value) : value;
    return formatCurrency(numVal, locale, {
      currency: data.Currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return {
    StoreId: data.StoreId as number,
    StoreName: data.StoreName as string,
    Currency: data.Currency as string,
    StartTime: data.StartTime as string,
    EndTime: data.EndTime as string,
    Totals: {
      Count: {
        Cash: data.CashOrderCount as number,
        PaidOnline: data.PaidOnlineOrderCount as number,
        Total: data.OrderCount as number,
      },
      Value: {
        Cash: currencyFormatter(data.CashOrderValue),
        PaidOnline: currencyFormatter(data.PaidOnlineOrderValue),
        Total: currencyFormatter(data.OrderValue),
      },
      NoFeesValue: {
        Cash: currencyFormatter(data.CashOrderValueExcludingDeliveryFees),
        PaidOnline: currencyFormatter(data.PaidOnlineOrderValueExcludingDeliveryFees),
        Total: currencyFormatter(data.OrderValueExcludingDeliveryFees),
      },
    },
    Collection: {
      Count: {
        Cash: data.CollectionCashOrderCount as number,
        PaidOnline: data.CollectionPaidOnlineOrderCount as number,
        Total: data.CollectionOrderCount as number,
      },
      Value: {
        Cash: currencyFormatter(data.CollectionCashOrderValue),
        PaidOnline: currencyFormatter(data.CollectionPaidOnlineOrderValue),
        Total: currencyFormatter(data.CollectionOrderValue),
      },
    },
    Delivery: {
      Count: {
        Cash: data.DeliveryCashOrderCount as number,
        PaidOnline: data.DeliveryPaidOnlineOrderCount as number,
        Total: data.DeliveryOrderCount as number,
      },
      Value: {
        Cash: currencyFormatter(data.DeliveryCashOrderValue),
        PaidOnline: currencyFormatter(data.DeliveryPaidOnlineOrderValue),
        Total: currencyFormatter(data.DeliveryOrderValue),
      },
    },
  };
};

export const getReportData = createSelector(
  [reportDataSelector, activeLanguagesSelector],
  getReportDataCombiner
);
// #endregion
