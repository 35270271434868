import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { GridContainer } from '../../../ui/atoms/GridContainer';
import { SkeletonLoader } from '../../../ui/atoms/SkeletonLoader';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  fullLine: {
    marginTop: '8px',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

export const AppStoreAppDetailsLoadingSkeleton = (): JSX.Element => {
  const classes = useStyles();

  const renderLoadingSkeleton = () => {
    return (
      <Grid item xs={12} className={classes.gridItem}>
        <SkeletonLoader
          fdKey="skeleton-card"
          rows={[
            { height: '80px', width: '100%', className: classes.fullLine },
            { height: '80px', width: '100%', className: classes.fullLine },
            { height: '80px', width: '100%', className: classes.fullLine },
          ]}
        />
      </Grid>
    );
  };
  return <GridContainer width="md">{renderLoadingSkeleton()}</GridContainer>;
};
