import React, { useEffect, useMemo, useState } from 'react';

import { HydraStoreData } from '@flipdish/api-client-typescript/private/api';
import Grid from '@mui/material/Grid';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import StoreFilter, { SelectAction } from '@fd/ui/Filter/StoreFilter';

import { notify, notifyError } from '../../../../layouts/Notify/actions';
import { addKioskStore, removeKioskStore } from '../../Kiosks.actions';
import { getStoresAttachedToKioskFactory } from '../../Kiosks.selectors';
import { kioskServices } from '../../Kiosks.services';
import KioskDetailSection from '../KioskDetailSection';
import InvalidStoresActions from './InvalidStoresActions';

type InnerProps = MappedState & MappedDispatch;
type OuterProps = { appId: string; deviceId?: string };
type Props = InnerProps & OuterProps;

export const kioskLocalStorageKeys = {
  isExpanded: 'kioskStoreValidation_isExpanded',
  page: 'kioskStoreValidation_page',
  rows: 'kioskStoreValidation_rows',
};

const KioskStoresEdit: React.FC<Props> = (props: Props) => {
  const {
    addStoreToKiosk,
    appId,
    deviceId,
    removeStoreFromKiosk,
    showNotificationSuccess,
    showNotificationError,
    storesAssignedToKiosk,
  } = props;

  const [selectAction, setSelectAction] = useState<SelectAction>();

  //#region Assign Stores
  const {
    data: storeHeaderData,
    isError,
    isPending,
  } = useQuery({
    queryKey: ['kioskServices.getStoresByAppId', appId, deviceId],
    queryFn: () => kioskServices.getStoreHeaders(appId),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isError) {
      showNotificationError();
    }
  }, [isError]);

  const stores = useMemo(() => {
    if (Array.isArray(storeHeaderData) && storesAssignedToKiosk) {
      const unassignedStores = storeHeaderData
        .filter(
          (all) => !storesAssignedToKiosk.some((assigned) => assigned.StoreId === all.StoreId)
        )
        .map((s) => ({
          label: s.Name as string,
          value: s.StoreId?.toString() as string,
        }));

      const assignedStores = storesAssignedToKiosk.map((s) => ({
        label: s.StoreName as string,
        value: s.StoreId?.toString() as string,
      }));

      return { assignedStores, unassignedStores };
    }
    return { assignedStores: [], unassignedStores: [] };
  }, [storeHeaderData, storesAssignedToKiosk]);

  const kioskAssignedStoreIds = useMemo(() => {
    return stores?.assignedStores.map((s) => s.value as string);
  }, [stores]);

  const assignStoreToKiosk = useMutation({
    mutationFn: ({ storeId, storeName }: { storeId: number; storeName?: string }) =>
      kioskServices.assignStoreToKiosk({ appId, deviceId, storeId }),

    onSuccess: (_, { storeId, storeName }) => {
      showNotificationSuccess('Kiosk_successfully_assigned_store');
      addStoreToKiosk(deviceId!, { StoreId: storeId, StoreName: storeName });
      setPage(0);
      localStorage.setItem(kioskLocalStorageKeys.page, '0');
    },

    onError: () => {
      showNotificationError();
    },
  });

  const unassignStoreToKiosk = useMutation({
    mutationFn: ({ storeId }: { storeId: number }) =>
      kioskServices.unassignStoreFromKiosk({ appId, deviceId, storeId }),

    onSuccess: (_, { storeId }) => {
      showNotificationSuccess('Kiosk_successfully_unassigned_store');
      removeStoreFromKiosk(deviceId!, storeId);
      setPage(0);
      localStorage.setItem(kioskLocalStorageKeys.page, '0');
    },

    onError: () => {
      showNotificationError();
    },
  });

  useEffect(() => {
    if (selectAction) {
      switch (selectAction.action) {
        case 'select-option':
          assignStoreToKiosk.mutate({
            storeId: Number(selectAction.option?.value),
            storeName: selectAction.option?.label,
          });
          break;
        case 'remove-value':
          selectAction.values?.length
            ? unassignStoreToKiosk.mutate({
                storeId: Number(selectAction.removedValue?.value),
              })
            : showNotificationError('Kiosk_must_have_at_least_one_store_assigned_to_it');
          break;
        default:
          break;
      }
    }
  }, [selectAction]);
  //#endregion

  //#region Store Validation
  const localStoragePage = Number(localStorage.getItem(kioskLocalStorageKeys.page));
  const localStorageRow = Number(localStorage.getItem(kioskLocalStorageKeys.rows));

  const [isInitialStoreValidationDone, setInitialStoreValidationDone] = useState(false);
  const [page, setPage] = useState(localStoragePage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(localStorageRow || 5);

  const handleOutOfBoundsRequest = () => {
    localStorage.setItem(kioskLocalStorageKeys.page, '0');
    setPage(0);
  };

  const {
    data: validationData,
    isFetchedAfterMount,
    isSuccess: isValidationSuccess,
    isError: isValidationError,
    isFetched: isValidationFetched,
  } = useQuery({
    queryKey: [
      kioskServices.getStoreValidationsQueryKey,
      appId,
      kioskAssignedStoreIds,
      page,
      rowsPerPage,
    ],

    queryFn: () => {
      return kioskServices.getStoreValidations({
        appId,
        page: page + 1,
        limit: rowsPerPage,
        storeIds: kioskAssignedStoreIds,
      });
    },

    refetchOnMount: 'always',
    enabled: !!appId && !!kioskAssignedStoreIds,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (isValidationSuccess && !validationData?.Data?.length) {
      handleOutOfBoundsRequest();
    }
  }, [isValidationSuccess, validationData]);

  useEffect(() => {
    if (isValidationError) {
      console.error('Validation error occurred');
    }
  }, [isValidationError]);

  useEffect(() => {
    if (isFetchedAfterMount && !isInitialStoreValidationDone) {
      setInitialStoreValidationDone(true);
    }
  }, [isFetchedAfterMount, isInitialStoreValidationDone]);

  const totalRecords = useMemo(() => validationData?.TotalRecordCount || 0, [validationData]);

  //#endregion

  return (
    <KioskDetailSection
      alignChildren="bottom"
      containerDivider={false}
      description="Kiosk_assign_stores_caption"
      isLoading={isPending}
      isFullWidthDescription
      title="Assign_stores"
    >
      <StoreFilter
        customStoreOptions={stores?.unassignedStores}
        doNotAllowLastChipToBeRemoved
        disableSetSearch
        isClearable={false}
        isCustomLoading={isPending}
        isMulti
        preSelectedStores={stores?.assignedStores}
        setSelectAction={setSelectAction}
        useCustomStoreOptions
        useLoadingSkeleton
        variant="standard"
      />

      {!isValidationFetched && !isInitialStoreValidationDone && (
        <Grid item style={{ marginTop: 21, width: '100%' }}>
          <Skeleton width={'100%'} height={60} />
        </Grid>
      )}

      {validationData?.Data && validationData.Data.length > 0 && (
        <InvalidStoresActions
          data={validationData?.Data}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          totalRecords={totalRecords}
        />
      )}
    </KioskDetailSection>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;

const mapStateToPropsFactory = (state: AppState, ownProps: OuterProps) => {
  const getkioskStores = getStoresAttachedToKioskFactory(ownProps.deviceId);

  return (state: AppState) => {
    return {
      storesAssignedToKiosk: getkioskStores(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  addStoreToKiosk: (deviceId: string, store: HydraStoreData) =>
    dispatch(addKioskStore(deviceId, store)),
  removeStoreFromKiosk: (deviceId: string, storeId: number) =>
    dispatch(removeKioskStore(deviceId, storeId)),
  showNotificationError: (message?: TranslationId) =>
    dispatch(
      notifyError({
        message: message || 'Error_please_try_again_later',
        translate: true,
        autoHideDuration: 1000,
        disableWindowBlurListener: true,
      })
    ),
  showNotificationSuccess: (message: TranslationId) => {
    dispatch(
      notify({
        message,
        variant: 'success',
        translate: true,
        autoHideDuration: 1000,
        disableWindowBlurListener: true,
      })
    );
  },
});

export default connect(mapStateToPropsFactory, mapDispatchToProps)(KioskStoresEdit);
