import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { Button } from '@fd/ui/atoms';

import { createVoucher } from '../../../actions/vouchers.actions';
import { useInfiniteQueryStoreFilterHook } from '../../../custom-hooks/useInfiniteQuerySoreFilter';
import { getStoresByAppId } from '../../Order/services';
import { campaignActionsAsync } from '../actions';

type Props = MappedState & MappedDispatch;

export const DefaultCampaignAndVoucher = (props: Props) => {
  const { appId, addLoyaltyCampaign, addRetentionCampaign, addPercentageVoucher, translate } =
    props;

  const [storeOptions, setStoreOptions] = useState();

  //#region Get Stores list
  const fetchDataProps = {
    appId,
  };
  const { data: getStores } = useInfiniteQueryStoreFilterHook(
    fetchDataProps,
    getStoresByAppId,
    'loadByAppId'
  );
  useEffect(() => {
    if (getStores?.pages) {
      const addNewStoreOptions = getStores.pages.reduce((total, page) => {
        page.Data.forEach((store) => total.push(store.StoreId));
        return total;
      }, []);
      setStoreOptions(addNewStoreOptions);
    }
  }, [getStores?.pages]);

  //#endregion
  const loyaltyCampaignPayload = {
    OrdersBeforeReceivingVoucher: '9',
    PercentDiscountAmount: null,
    RoundingStrategy: 0,
    ShouldIncludeOrdersWithLoyaltyVoucher: true,
    VoucherValidPeriodDays: 10,
    IncludeDeliveryFee: false,
    AutoApplyResultingVouchers: true,
    IncludeExistingOrders: false,
    IsEnabled: true,
    StoreIds: storeOptions,
  };

  const retentionCampaignPayload = {
    AutoApplyResultingVouchers: true,
    IncludeDeliveryFee: false,
    IncludeExistingOrders: false,
    IsEnabled: true,
    NotifyCustomerAfterMinutes: 40320,
    PercentDiscountAmount: 20,
    StoreIds: storeOptions,
    VoucherValidPeriodDays: 7,
  };

  const voucherStartDate = moment().format('YYYY-MM-DDT00:00:00+01:00');

  const percentageVoucherPayload = {
    Code: `${translate('NEW20')}`,
    Description: `${translate('20_discount_on_first_order_for_New_Users')}`,
    ExpiryDate: '2100-01-01T23:59:59+00:00',
    IncludeDeliveryFee: false,
    IsAutomaticallyApplied: true,
    IsEnabled: true,
    IsValidForDeliveryOrders: true,
    IsValidForFirstOrderOnly: true,
    IsValidForOrdersPayedByCash: true,
    IsValidForOrdersPayedOnline: true,
    IsValidForPickupOrders: true,
    IsValidOncePerCustomer: true,
    IsValidOnlyOnce: false,
    PercentDiscountDetails: {
      PercentageDiscount: 20,
    },
    StartDate: voucherStartDate,
    Stores: storeOptions,
    TakesPriority: true,
    ValidOnOrdersOver: 0,
    VoucherType: 'PercentageDiscount',
  };

  return (
    <Permissions allowed={['FlipdishStaff']}>
      <Box pt={3} pb={2}>
        <Button
          variant="primary"
          onClick={() => {
            addLoyaltyCampaign(loyaltyCampaignPayload);
            addRetentionCampaign(retentionCampaignPayload);
            addPercentageVoucher(percentageVoucherPayload);
          }}
          fdKey={'create_campaign_and_voucher_btn'}
        >
          {translate('Create_default_campaign_and_voucher') as string}
        </Button>
      </Box>
    </Permissions>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  addLoyaltyCampaign: (values) => dispatch(campaignActionsAsync.createLoyaltyCampaign(values)),
  addRetentionCampaign: (values) => dispatch(campaignActionsAsync.createRetainCampaign(values)),
  addPercentageVoucher: (values) => dispatch(createVoucher(values)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId!,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultCampaignAndVoucher);
