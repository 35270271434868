import { formatCurrency, FormatCurrencyOptions } from 'fd-react-intl';

export default (
  value: number | string,
  languageCode: string | string[],
  options: FormatCurrencyOptions
) => {
  const numVal = typeof value === 'string' ? parseFloat(value) : value;
  return formatCurrency(numVal, languageCode, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });
};
