import React from 'react';

import { OAuthApp } from '@flipdish/api-client-typescript';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';

const useStyles = makeStyles((theme: Theme) => ({
  appCard: {
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    '&:hover': {
      background: '#eaf2ff',
    },
    cursor: 'pointer',
    height: '100px',
    position: 'relative' as any,
  },
  appCardHeader: {
    alignItems: 'flex-start',
    paddingLeft: '16px',
    height: '50px',
  },
  appCardHeaderTitle: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  appCardSubheader: {
    fontSize: '12px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  appCardContent: {
    height: '50px',
    paddingTop: 0,
    paddingLeft: '72px',
    paddingBottom: 0,
    paddingRight: '60px',
  },
  appCardContentText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  link: {
    textDecoration: 'none',
    position: 'absolute' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  avatar: {
    backgroundColor: '#72a9f9',
    borderRadius: '3px',
  },
  deviceLink: {
    display: 'block',
    textDecoration: 'none',

    '&:hover': {
      background: '#eaf2ff',
    },
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = {
  App: OAuthApp;
};

export default function OAuthAppCard(props: Props) {
  const { App } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
      <Hidden mdDown>
        <Card className={classes.appCard}>
          <Link
            to={`${App.OauthAppId}`}
            className={classes.link}
            data-fd={`app-card-${App.OauthAppName}`}
          />
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe" className={classes.avatar}>
                <SettingsIcon />
              </Avatar>
            }
            title={App.OauthAppName}
            classes={{
              root: classes.appCardHeader,
              title: classes.appCardHeaderTitle,
              subheader: classes.appCardSubheader,
            }}
          />
          <CardContent className={classes.appCardContent}>
            <Typography
              component="p"
              className={clsx(classes.appCardSubheader, classes.appCardContentText)}
            >
              OAuth App ID: {App.OauthAppId}
            </Typography>

            <Typography
              component="p"
              className={clsx(classes.appCardSubheader, classes.appCardContentText)}
            >
              Owner User ID: {App.OwnerUserId}
            </Typography>
          </CardContent>
        </Card>
      </Hidden>
      <Hidden mdUp>
        <Link
          to={`${App.OauthAppId}`}
          className={`${classes.deviceLink} ${HORIZONTAL_SPACE_CLASSNAME}`}
        >
          <Typography variant="subtitle1">{App.OauthAppName}</Typography>
          <Typography
            component="p"
            className={clsx(classes.appCardSubheader, classes.appCardContentText)}
          >
            OAuth App ID: {App.OauthAppId}
          </Typography>
          <Typography
            component="p"
            className={clsx(classes.appCardSubheader, classes.appCardContentText)}
          >
            Owner User ID: {App.OwnerUserId}
          </Typography>
        </Link>
      </Hidden>
    </Grid>
  );
}
