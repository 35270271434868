import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';

const styles = (theme: Theme) => {
  return createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 272,
    },
  });
};

type Props = {
  open: boolean;
  labelText: TranslationId;
  handleAccept: (mins: number) => void;
  handleClose: () => void;
} & WithStyles<typeof styles>;
const AcceptDialog: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes, open, handleAccept, handleClose, labelText } = props;
  const [mins, setMins] = useState<string>('');
  const [error, setError] = useState<{ error: boolean; message: React.ReactElement | null }>({
    error: false,
    message: null,
  });
  return (
    <Dialog open={open} disablePortal>
      <DialogContent>
        <TextField
          variant="standard"
          className={classes.textField}
          label={<Translate id={labelText} />}
          margin="normal"
          value={mins}
          onChange={(e) => {
            setError({ error: false, message: null });
            setMins(e.target.value);
          }}
          type="number"
          autoFocus
          error={error.error}
          helperText={error.message}
          data-fd="Estimated Prep Time (minutes)"
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-fd="Cancel"
          onClick={() => {
            setError({ error: false, message: null });
            handleClose();
          }}
          color="primary"
        >
          <Translate id="Cancel" />
        </Button>
        <Button
          data-fd="Accept"
          onClick={() => {
            if (mins != '') {
              handleAccept(parseInt(mins, 10));
              handleClose();
            } else {
              setError({ error: true, message: <Translate id="Invalid_input" /> });
            }
          }}
          color="primary"
        >
          <Translate id="Accept" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AcceptDialog);
