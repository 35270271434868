import { BusinessHoursOverride } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

export const createOpeningHoursSelector = (storeId: number) =>
  createSelector([(state) => state.openingHourOverrides[storeId]], (openingHourOverrides) => {
    if (!Object.keys(openingHourOverrides || {}).length) {
      return;
    }
    return openingHourOverrides;
  });

export const createOpeningHoursByIdSelector = (storeId: number, id: string) =>
  createSelector(
    [(state) => state.openingHours[storeId] && state.openingHours[storeId][id]],
    (openingHourOverride) => {
      return openingHourOverride;
    }
  );

// #region
function parseBusinessHoursOverride(o: BusinessHoursOverride) {
  return {
    id: o.BusinessHoursOverrideId!,
    start: o.StartTime,
    end: o.EndTime,
    type: (o.DeliveryType === BusinessHoursOverride.DeliveryTypeEnum.Delivery
      ? 'Delivery'
      : 'Pickup') as Required<Flipdish.BusinessHoursOverride>['DeliveryType'],
    closedAllowPreOrders: o.Type === BusinessHoursOverride.TypeEnum.ClosedAllowPreOrders,
  };
}
function groupOverridesByType(dbOverrides: { [id: number]: BusinessHoursOverride }) {
  const overrides = Object.values(dbOverrides).reduce<{
    Open: Array<ReturnType<typeof parseBusinessHoursOverride>>;
    Close: Array<ReturnType<typeof parseBusinessHoursOverride>>;
  }>(
    (agg, o) => {
      const override = parseBusinessHoursOverride(o);
      if (o.Type === BusinessHoursOverride.TypeEnum.Open) {
        agg.Open.push(override);
      } else {
        agg.Close.push(override);
      }
      return agg;
    },
    { Open: [], Close: [] }
  );

  return overrides;
}
export const overrides = createSelector(
  [(state, props) => state.openingHourOverrides[props.storeId]],
  (dbOverrides) => {
    if (!dbOverrides) {
      return;
    }
    return groupOverridesByType(dbOverrides);
  }
);
// #endregion
