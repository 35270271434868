import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  label: {
    color: 'rgba(0,0,0,0.87)',
    letterSpacing: 'normal',
    fontWeight: 500,
    lineHeight: 1.33,
    backgroundColor: '#ffbc2c',
    position: 'absolute',
    bottom: '89%',
    paddingTop: '4px',
    paddingRight: '8px',
    paddingBottom: '4px',
    paddingLeft: '8px',
  },
}));

const StoreUnpublishedLabel = () => {
  const classes = useStyles();
  return (
    <div className={classes.label}>
      <Translate id="Unpublished" />
    </div>
  );
};

export default StoreUnpublishedLabel;
