import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import StoreFilter, { StoreOption } from '@fd/ui/Filter/StoreFilter';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 2, 4.5, 2),
    },
  },
}));

type InnerProps = MappedProps;
type OuterProps = {
  storeOptions: StoreOption[] | undefined;
  onSelectStore: (storeIds: number | number[]) => void;
  loading: boolean;
};
type Props = InnerProps & OuterProps;

const PayoutDetailsFilters = (props: Props) => {
  const { storeOptions, onSelectStore, loading } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <StoreFilter
        customStoreOptions={storeOptions}
        isCustomLoading={loading}
        isMulti
        onSelectStore={onSelectStore}
        useCustomStoreOptions
        useLoadingSkeleton
      />
    </Grid>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(PayoutDetailsFilters);
