import React, { useEffect } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { App } from '@flipdish/api-client-typescript';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import * as storeConstants from '../../../constants/store.constants';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import ArchiveStoreButton from './ArchiveStoreButton';
import UnpublishStoreButton from './UnpublishStoreButton';

export type CardData = {
  cardHeader: TranslationId;
  doThisIf: TranslationId[];
  itWillMean: TranslationId[];
  buttonText: TranslationId;
  divider: boolean;
};
const cardData: CardData[] = [
  {
    cardHeader: 'Unpublish_this_store',
    doThisIf: [
      'store_not_ready_to_accept_orders_yet',
      "You're_closing_for_renovations",
      'You_will_want_to_re-open',
    ],
    itWillMean: [
      'store_will_no_longer_be_visible_on_your_website_or_apps',
      "Customers_will_not_be_able_to_order_from_this_store_while_it's_unpublished",
      'The_store_will_still_be_visible_in_your_Flipdish_Portal',
    ],
    buttonText: 'Unpublish_store',
    divider: true,
  },
  {
    cardHeader: 'Archive_this_store',
    doThisIf: [
      'store_was_created_in_error_or_closed_permanently',
      "You'll_never_want_to_receive_orders_for_this_store_in_the_future",
    ],
    itWillMean: [
      'Store_will_no_longer_be_here_in_your_Flipdish_Portal',
      'store_will_no_longer_be_visible_on_your_website_or_apps',
      'Customers_no_longer_order_from_store_forever',
      'Orders_and_audit_logs_related_to_this_store_will_be_kept',
    ],
    buttonText: 'Archive_store',
    divider: false,
  },
];

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down(813)]: { borderTop: '1px solid #dbdcdf' },
    [theme.breakpoints.up('md')]: { maxWidth: '916px' },
    [theme.breakpoints.up('lg')]: { maxWidth: '780px' },
  },
  gridContainer: {
    [theme.breakpoints.down(640)]: { padding: '16px' },
    [theme.breakpoints.up('sm')]: { padding: '24px 24px 0 24px' },
  },
  cardHeader: {
    marginBottom: '16px',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '16px',
    padding: 0,
    marginBottom: '16px',
  },
  listHeader: {
    fontWeight: 500,
    marginBottom: '4px',
  },
  listItem: {
    display: 'list-item',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    lineHeight: '20px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.25px',
  },
  actionsGrid: {
    textAlign: 'end',
    [theme.breakpoints.down(640)]: {
      marginBottom: '8px',
    },
  },
}));

type Props = MappedProps;
const DangerZoneList = (props: Props) => {
  const classes = useStyles();
  const { appId, loading, store, storeName, storeDetails, storePublished } = props;
  const loadingDependencies = loading || !store;
  const theme = useTheme();
  useLoadStoreFromSalesChannelIdIntoRedux(store);
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_dangerZone', {
      action: 'logged_in',
    });
  }, []);

  return (
    <PageLayout
      title={<Translate id="Danger_zone_title" />}
      documentTitle="Store_Danger_Zone"
      caption={storeName}
      toParent={
        store ? `/${appId.AppId}/storegroups/${store.StoreGroupId}/stores/${store.StoreId}` : ''
      }
    >
      {loadingDependencies ? (
        <CircularProgress style={{ margin: '10px' }} size={50} />
      ) : (
        <>
          {cardData.map((card, index) => {
            return (
              <Card
                className={classes.container}
                square
                elevation={isMobile ? 0 : 2}
                style={card.divider ? { borderBottom: '1px solid lightgray' } : {}}
                key={index}
              >
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={12} sm={7} md={8} lg={8} style={{ marginBottom: '24px' }}>
                    <Typography variant="h5" className={classes.cardHeader}>
                      <Translate id={card.cardHeader} />
                    </Typography>
                    <Typography variant="body2" className={classes.listHeader}>
                      <Translate id="Do_this_if" />
                    </Typography>
                    <List classes={{ root: classes.list }} dense>
                      {card.doThisIf.map((text, index) => {
                        return (
                          <ListItem classes={{ root: classes.listItem }} disableGutters key={index}>
                            <ListItemText
                              primary={<Translate id={text} />}
                              classes={{
                                root: classes.listItemText,
                                primary: classes.listItemText,
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                    <Typography variant="body2" className={classes.listHeader}>
                      <Translate id="It_will_mean" />
                    </Typography>
                    <List classes={{ root: classes.list }} dense>
                      {card.itWillMean.map((text, index) => {
                        return (
                          <ListItem classes={{ root: classes.listItem }} disableGutters key={index}>
                            <ListItemText
                              primary={<Translate id={text} />}
                              classes={{
                                root: classes.listItemText,
                                primary: classes.listItemText,
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} lg={4} classes={{ root: classes.actionsGrid }}>
                    {card.cardHeader === 'Unpublish_this_store' ? (
                      <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
                        <UnpublishStoreButton
                          card={card}
                          index={index}
                          storeDetails={storeDetails}
                          storePublished={storePublished}
                        />
                      </Permissions>
                    ) : card.cardHeader === 'Archive_this_store' ? (
                      <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
                        <ArchiveStoreButton card={card} index={index} storeDetails={storeDetails} />
                      </Permissions>
                    ) : null}
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </>
      )}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const store = getSelectedStore(state);
  const storeId = (store && store.StoreId) || 0;
  const storeName = (store && store.Name) || '';
  const storeGroupId = (store && store.StoreGroupId) || 0;
  const appId = state.currentApp;
  const storePublished = store && store.IsPublished;

  const storeDetails = { appId: appId.AppId, storeName, storeId, storeGroupId };

  return {
    appId,
    loading: initialLoadingSelector(state),
    store,
    storeDetails,
    storeGroupId,
    storeId,
    storeName,
    storePublished,
  };
};
export default connect(mapStateToProps)(DangerZoneList);
