import {
  AppStoreApiFactory,
  AppStoreAppConfiguration,
  AppStoreConfigurationsApiFactory,
  AppStoreDeveloperApiFactory,
  AppStoreEntitlementsApiFactory,
  CreateAppStoreApp,
  ExecuteConfigurationActionRequest,
  UpdateAppStoreApp,
  UpdateAppStoreAppConfiguration,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../../../helpers/apibase';
import { createApi } from '../../../helpers/utilities';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();
const appStoreApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

// Flipdish API Client
const mainApi = createApi(AppStoreApiFactory);

const configurationApi = createApi(AppStoreConfigurationsApiFactory);

const developersApi = createApi(AppStoreDeveloperApiFactory);

const entitlementsApi = createApi(AppStoreEntitlementsApiFactory);

// Main
const getAppStoreAppsKey = 'getApps';
async function getAppStoreApps(
  query: string,
  page: number,
  limit: number,
  showOnlyVerified: boolean
) {
  try {
    const result = await mainApi.getAppStoreApps(
      query.trim(),
      page,
      limit,
      undefined,
      showOnlyVerified
    );
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getDeveloperAppStoreAppsKey = 'getAppsByDeveloper';
async function getDeveloperAppStoreApps(query: string, page: number, limit: number) {
  try {
    const result = await mainApi.getAppStoreApps(query.trim(), page, limit, true, false);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getAppStoreAppByIdKey = 'getAppById';
async function getAppStoreAppById(appStoreAppId: string, appId: string | undefined = undefined) {
  try {
    const result = await mainApi.getAppStoreApp(appStoreAppId, appId);
    return result.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// Configurations
const getMyAppStoreAppsKey = 'getMyAppStoreApps';
async function getMyAppStoreApps(appId: string) {
  try {
    const result = await configurationApi.getConfiguredApps(appId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getLightSpeedOAuthResponseStatusKey = 'getOAuthResponseStatus';
async function getLightSpeedOAuthResponseStatus(
  appStoreAppId: string,
  state: string | null,
  code: string | null
) {
  try {
    // Unable to use typings here as the query params are not typed and could be anything at all
    const result = await appStoreApi.get(`appstore/oauthresponse/${appStoreAppId}/responsecode`, {
      params: { state, code },
    });
    return result.data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getAppStoreAppConfigsByIdKey = 'getAppStoreAppConfigsById';
async function getAppStoreAppConfigsById(appId: string, appStoreAppId: string) {
  try {
    const result = await configurationApi.getConfiguredAppSingleApp(appId, appStoreAppId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const createAppStoreAppConfigKey = 'getAppStoreAppConfig';
async function createAppStoreAppConfig(appId: string, appStoreAppId: string) {
  try {
    const result = await configurationApi.createAppStoreConfig(appId, appStoreAppId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getAppStoreAppConfigByIdKey = 'getAppById';
async function getAppStoreAppConfigById(appId: string, appStoreAppId: string, configId: string) {
  try {
    const result = await configurationApi.getAppStoreConfig(appId, appStoreAppId, configId);
    return result.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateAppStoreAppConfig(
  appId: string,
  appStoreAppId: string,
  configId: string,
  config: UpdateAppStoreAppConfiguration
) {
  try {
    await configurationApi.updateAppStoreConfig(appId, appStoreAppId, configId, config);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function deleteAppStoreConfig(appId: string, appStoreAppId: string, configId: string) {
  try {
    await configurationApi.deleteAppStoreConfig(appId, appStoreAppId, configId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function executeConfigurationAction(
  appId: string,
  appStoreAppId: string,
  configId: string,
  body: ExecuteConfigurationActionRequest
) {
  try {
    const result = await configurationApi.executeConfigurationAction(
      appId,
      appStoreAppId,
      configId,
      body
    );
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// Developers
async function getAppStoreAppExternalFunctionSigningKey(appStoreAppId: string, oauthAppId: string) {
  try {
    const result = await developersApi.getExternalFunctionSigningKey(oauthAppId, appStoreAppId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function createAppStoreApp(config: CreateAppStoreApp, oauthAppId: string) {
  try {
    const result = await developersApi.createAppStoreApp(oauthAppId, config);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateAppStoreApp(
  appStoreAppId: string,
  config: UpdateAppStoreApp,
  oauthAppId: string
) {
  try {
    await developersApi.updateAppStoreApp(oauthAppId, appStoreAppId, config);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function updateAppStoreAppVerification(
  appStoreAppId: string,
  verificationStatus: AppStoreAppConfiguration.VerificationStatusEnum,
  oauthAppId: string
) {
  try {
    const result = await developersApi.appVerificationUpdate(
      oauthAppId,
      appStoreAppId,
      verificationStatus as any
    );
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function addAppStoreAppLogo(appStoreAppId: string, image: File, oauthAppId: string) {
  try {
    const formData = new FormData();
    formData.append('file', image);
    const result = await appStoreApi.post(
      `oauthclients/${oauthAppId}/appstore/apps/${appStoreAppId}/logo`,
      formData
    );
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

async function deleteAppStoreApp(appStoreAppId: string, oauthAppId: string) {
  try {
    const result = await developersApi.deleteAppStoreApp(oauthAppId, appStoreAppId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const getAppStoreAppEntitlementsKey = 'getAppStoreAppEntitlements';
async function getAppStoreAppEntitlements(appId: string, appStoreAppId: string) {
  try {
    const result = await entitlementsApi.getAppStoreAppEntitlements(appId, appStoreAppId);
    return result.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const appStoreService = {
  executeConfigurationAction,
  getMyAppStoreAppsKey,
  getMyAppStoreApps,
  getAppStoreApps,
  getAppStoreAppsKey,
  getAppStoreAppConfigsById,
  getAppStoreAppConfigsByIdKey,
  getAppStoreAppById,
  getAppStoreAppByIdKey,
  createAppStoreAppConfigKey,
  createAppStoreAppConfig,
  getAppStoreAppConfigByIdKey,
  getAppStoreAppConfigById,
  getAppStoreAppExternalFunctionSigningKey,
  updateAppStoreAppConfig,
  getDeveloperAppStoreApps,
  getDeveloperAppStoreAppsKey,
  createAppStoreApp,
  updateAppStoreApp,
  addAppStoreAppLogo,
  updateAppStoreAppVerification,
  deleteAppStoreApp,
  deleteAppStoreConfig,

  getLightSpeedOAuthResponseStatusKey,
  getLightSpeedOAuthResponseStatus,

  getAppStoreAppEntitlements,
  getAppStoreAppEntitlementsKey,
};
