import { Store } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { STORE_LOAD_BY_APP } from '../../constants/store.constants';
import { selectDbStores } from '../../selectors/db.selector';
import { createLoadingSelector } from '../../selectors/loading.selector';

type ItemOrderIndex = ReturnType<typeof selectItemOrderIndex>;
const selectItemOrderIndex = (state: AppState) =>
  state.stores.search ? state.stores.search.item_order_index : state.stores.item_order_index;

const selectFilterStores = (state: AppState) => state.OrdersFilter.stores;
export const getIsStoresLoading = createLoadingSelector([STORE_LOAD_BY_APP]);

export const getStores = createSelector(
  [selectItemOrderIndex, selectDbStores],
  (itemsOrder, stores) => {
    return itemsOrder.map((id) => stores[id]);
  }
);

export const getFilterStores = createSelector(
  [selectFilterStores, selectDbStores],
  (itemsOrder, stores) => {
    return itemsOrder.map((id) => {
      const s = stores[id];
      if (s) {
        return {
          value: s.StoreId as number,
          label: s.Name as string,
        };
      }

      return {
        value: id,
        label: String(id),
      };
    });
  }
);

export const getFilterStoreSuggestions = createSelector([getStores], (stores) => {
  if (stores) {
    return stores.map((s) => ({
      value: s.StoreId as number,
      label: s.Name as string,
    }));
  } else {
    return [];
  }
});

const liveViewStores = (state: AppState) => state.liveViewStores.stores;
export const getLiveViewStores = createSelector([liveViewStores], (stores: Store[]) => {
  if (stores) {
    return stores.map((store) => {
      return { value: store.StoreId, label: store.Name };
    });
  } else {
    return [];
  }
});
