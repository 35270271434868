import React, { useEffect, useState } from 'react';

import { App, BusinessHoursOverride, Store } from '@flipdish/api-client-typescript';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose, setDisplayName } from 'recompose';
import { createSelector } from 'reselect';

import * as actions from '../../actions/storeOpeningHourOverrides.actions';
import {
  DateTimeUtils,
  dateTimeUtils,
  getCalendarMonthMatrix,
  getDateTimeFormatter2,
} from '../../selectors/localeDateTime.selector';
import * as storeSelectors from '../../selectors/store.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import * as selectors from '../../selectors/storeOpeningHourOverrides.selector';
import { Calendar } from '../../ui/Calendar';
import OpenCloseFormDialog from './components/OpenCloseDialog';
import RemoveDialog from './components/RemoveDialog';

const createScheduleEventsSelector = (storeId) => {
  const overridesSelector = selectors.createOpeningHoursSelector(storeId);
  const storeSelector = storeSelectors.createStoreSelectorById(storeId);

  return createSelector(
    [
      overridesSelector,
      (state) => getTranslate(state.locale),
      storeSelector,
      getDateTimeFormatter2,
    ],
    (overrides, translate, store: Store, dt: DateTimeUtils) => {
      if (!store || !overrides) {
        return undefined;
      }

      return Object.keys(overrides || {}).map((id) => {
        // @ts-ignore
        const bho: BusinessHoursOverride = overrides[id];

        const deliveryType =
          BusinessHoursOverride.DeliveryTypeEnum[
            bho.DeliveryType as BusinessHoursOverride.DeliveryTypeEnum
          ];
        const type = BusinessHoursOverride.TypeEnum[bho.Type as BusinessHoursOverride.TypeEnum];
        const text = `${translate(deliveryType as 'Delivery')} ${(
          translate(type === 'Open' ? 'Opened' : 'Closed') as string
        ).toLowerCase()}`;

        // use local store time (dhtmlx-calendar doesn't know anything about time zones)
        const start = dt.utcToZonedTime(bho.StartTime, store.IanaTimeZone!);
        const end = dt.utcToZonedTime(bho.EndTime, store.IanaTimeZone!);

        const event = {
          refId: bho.BusinessHoursOverrideId,
          start_date: start,
          end_date: end,
          text,
          type,
        };

        return event;
      });
    }
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  const storeId = Number(ownProps.storeId);
  const overridesSelector = selectors.createOpeningHoursSelector(storeId);
  const eventsSelector = createScheduleEventsSelector(storeId);
  return (state: AppState) => {
    const overrides = overridesSelector(state);
    const events = eventsSelector(state);
    const store = getSelectedStore(state);
    const preOrderEnabled = store && store.PreOrderEnabled;
    const preOrderDelivery = store && store.PreOrderDeliveryEnabled;
    const preOrderPickup = store && store.PreOrderPickupEnabled;
    const locale = state.locale.activeLanguage;

    const canEdit = state.permissions.some(
      (p) => p === App.AppResourceSetEnum.EditStoresOpeningHoursOverride.toString()
    );

    const getCalendarMatrix = (year, month) => getCalendarMonthMatrix(state, { year, month });

    const storeTz = storeSelectors.timeZone(state, { storeId });

    return {
      overrides,
      events,
      translate: getTranslate(state.locale),
      locale,
      canEdit,
      preOrderEnabled,
      preOrderPickup,
      preOrderDelivery,
      dtUtils: dateTimeUtils(state),
      getCalendarMatrix,
      storeTz,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: Props) => {
  return (dispatch, ownProps: Props) => {
    const { storeGroupId, storeId } = ownProps;
    return {
      load: (after = new Date(), page = 1, limit = 100) => {
        dispatch(
          actions.loadAll(Number(storeGroupId), Number(storeId), after, {
            page,
            limit,
          })
        );
      },
    };
  };
};

export interface IOpeningHourOverridesCalendar {
  storeGroupId: number | string;
  storeId: number | string;
}
type Props = IOpeningHourOverridesCalendar;
const OpeningHourOverridesCalendar = (props: Props & MappedState & MappedDispatch) => {
  const {
    overrides,
    events,
    storeId,
    translate,
    locale,
    canEdit,
    preOrderDelivery,
    preOrderEnabled,
    preOrderPickup,
  } = props;

  const [dialog, setDialog] = useState(false);
  const [startDate, setStartDate] = useState(() => new Date());
  const [endDate, setEndDate] = useState(() => new Date());
  const [toRemove, setToRemove] = useState<BusinessHoursOverride>();

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() as 0;
    const firstDayInCalendar = props.getCalendarMatrix(year, month)[0][0];
    props.load(firstDayInCalendar, 1, 100);
  }, []);

  const onPaginationChange = (prevDate: Date, nextDate: Date) => {
    if (!props.dtUtils.isSameMonth(prevDate, nextDate)) {
      const year = nextDate.getFullYear();
      const month = nextDate.getMonth() as 0;
      const firstDayInCalendar = props.getCalendarMatrix(year, month)[0][0];
      props.load(firstDayInCalendar, 1, 100);
    }
  };

  const dialogOff = () => {
    setDialog(false);
  };
  const dialogSave = (data) => {
    dialogOff();
  };
  const dialogOn = (date: Date, endDate?: Date) => {
    const { dtUtils, storeTz } = props;
    if (!storeTz) {
      console.error(new Error(`'storeTimeZone' missing!`));
      return;
    }

    let start: Date = date;
    let end: Date = endDate || date;
    if (dtUtils.differenceInSeconds(date, dtUtils.startOfDay(date)) === 0) {
      const now = dtUtils.utcToZonedTime(new Date(), storeTz);
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      start = dtUtils.set(date, { hours, minutes, seconds });
    }

    if (!endDate) {
      end = dtUtils.addHours(start, 1);
    }

    start = dtUtils.zonedTimeToUtc(start, storeTz);
    end = dtUtils.zonedTimeToUtc(end, storeTz);

    setStartDate(start);
    setEndDate(end);
    setDialog(true);
  };

  const removeDialogOn = (id: number) => {
    if (props.overrides && props.overrides[id]) {
      setToRemove(props.overrides[id]);
    }
  };
  const removeDialogOff = () => {
    setToRemove(undefined);
  };

  return (
    <div>
      <Calendar
        events={events}
        onCellClick={dialogOn}
        dayText={translate('Day')}
        weekText={translate('Week')}
        monthText={translate('Month')}
        todayText={translate('Today')}
        locale={locale}
        onEventClick={removeDialogOn}
        onPaginationChange={onPaginationChange}
        disabled={!canEdit}
      />

      <Permissions allowed={[App.AppResourceSetEnum.EditStoresOpeningHoursOverride]}>
        {dialog && (
          <OpenCloseFormDialog
            storeId={storeId}
            onSave={dialogSave}
            onCancel={dialogOff}
            startDate={startDate}
            endDate={endDate}
            preOrderEnabled={preOrderEnabled}
            preOrderPickup={preOrderPickup}
            preOrderDelivery={preOrderDelivery}
          />
        )}

        <RemoveDialog
          open={!!toRemove && !!overrides}
          storeId={Number(storeId)}
          businessHoursOverride={toRemove!}
          onSave={removeDialogOff}
          onCancel={removeDialogOff}
        />
      </Permissions>
    </div>
  );
};

const EnhancedComponent = compose<
  Props & MappedState & MappedDispatch,
  IOpeningHourOverridesCalendar
>(
  setDisplayName('OpeningHourOverridesCalendar'),
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
)(OpeningHourOverridesCalendar);

export default EnhancedComponent;
