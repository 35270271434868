import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Typography } from '../Typography';

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  banner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  default: {
    backgroundColor: '#dceafd',
  },
  error: {
    backgroundColor: '#FADBE2',
  },
  icon: {
    alignItems: 'center',
    color: theme.palette.grey[600],
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '8px',
  },
  success: {
    backgroundColor: '#D2F3E2',
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  warning: {
    backgroundColor: ' #FFF2D5',
  },
}));

export type MessageBannerProps = {
  children?: any;
  className?: string;
  fdKey: string;
  icon?: React.ReactElement;
  rounded?: boolean;
  title?: string;
  message?: string | React.ReactElement;
  titleClassName?: string;
  variant?: 'default' | 'success' | 'warning' | 'error';
};

export const MessageBanner = ({
  children,
  className,
  fdKey,
  icon,
  rounded = false,
  title,
  message,
  titleClassName = '',
  variant = 'default',
}: MessageBannerProps): JSX.Element => {
  const classes = useStyles();

  const getVariantClass = () => {
    switch (variant) {
      case 'error':
        return classes.error;
      case 'success':
        return classes.success;
      case 'warning':
        return classes.warning;
      default:
        return classes.default;
    }
  };
  const variantClass = getVariantClass();
  const bannerStyles = clsx(
    classes.banner,
    variantClass,
    rounded && classes.rounded,
    className || ''
  );
  return (
    <div data-fd={fdKey} className={bannerStyles}>
      <div className={classes.messageContainer}>
        <div className={classes.icon}>{icon}</div>
        {title && (
          <Typography className={titleClassName} variant="body1">
            {title}
          </Typography>
        )}
        {message && <Typography variant="caption">{message}</Typography>}
      </div>
      {children && <div className={classes.actionsContainer}>{children}</div>}
    </div>
  );
};
