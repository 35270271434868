import React, { useEffect, useState } from 'react';

import { Field, FieldProps } from 'formik';
import { getCountrySpecifications } from 'ibantools';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formikValidate } from '../../../../../helpers/validation';
import { setCountry } from '../../banking.actions';
import { excludedCountries, nonIBANSupportedCountries } from '../../constants';
import FieldWrapper from './FieldWrapper';
import SingleSelect from './SingleSelect';

type CountrySpec = {
  value: string;
  label: string;
};

export const countries = Object.entries(getCountrySpecifications())
  .reduce<CountrySpec[]>((agg, [key, spec]) => {
    if (excludedCountries.includes(key)) {
      return agg;
    }
    if (spec.IBANRegistry || nonIBANSupportedCountries.includes(key)) {
      agg.push({
        value: key,
        label: spec.name,
      });
    }
    return agg;
  }, [])
  .sort((a, b) => a.label.localeCompare(b.label));

const validate = (value: string) => {
  return formikValidate.required(value);
};

export type CountrySelectorFormFieldProps = {
  name: string;
  fdKey: string;
};

type Props = CountrySelectorFormFieldProps & MappedDispatch;

const BankCountrySelectorFormField = (props: Props) => {
  const { name, fdKey, setSelectedCountry } = props;
  return (
    <FieldWrapper>
      <Field
        name={name}
        validate={validate}
        component={({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          // TODO extract to component
          const [selected, setSelected] = useState(() => {
            if (field.value) {
              return countries.find((c) => c.value === field.value);
            }
            return undefined;
          });

          useEffect(() => {
            setSelectedCountry(selected);
          }, [selected]);

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          const handleBankCountryChanges = (idk) => {
            form.setFieldValue(field.name, idk.value, false);
            setSelected(idk);
          };

          return (
            <SingleSelect
              {...field}
              value={selected}
              onChange={handleBankCountryChanges}
              options={countries}
              dataFd={fdKey}
              // @ts-expect-error: PRJSP-445
              label={<Translate id="Country" />}
              fullWidth
              disabled={isSubmitting}
              error={showError}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={showError ? fieldError : undefined}
              margin="none"
            />
          );
        }}
      />
    </FieldWrapper>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setSelectedCountry: (country) => {
      country === undefined
        ? dispatch(setCountry({ value: '', label: '' }))
        : dispatch(setCountry(country));
    },
  };
}

export default compose<Props, CountrySelectorFormFieldProps>(connect(null, mapDispatchToProps))(
  BankCountrySelectorFormField
);
