import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, Field } from 'formik';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Select from '@fd/ui/Select/Select';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginTop: -16,
  },
}));

export type SelectFieldBooleanProps = {
  fieldName: string;
  label: string;
  trueLabel: string;
  falseLabel: string;
  optionValues: any;
  tooltip: string;
};

type Option = { value: string; label: string };

const SelectFieldBoolean = ({
  fieldName,
  label,
  trueLabel,
  falseLabel,
  optionValues,
  tooltip,
}: SelectFieldBooleanProps) => {
  const classes = useStyles();
  const validate = (value: string) => {};

  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, submitCount } = form;
        const fieldError = errors[field.name] as string | undefined;

        const options: Option[] = [
          { value: 'true', label: trueLabel },
          { value: 'false', label: falseLabel },
        ];

        let selectedValue;
        selectedValue = options.find(
          (option: Option) => option.value === (field.value ? 'true' : 'false')
        );

        const showError = !!fieldError && ((submitCount > 0) as boolean | undefined);

        const handleChange = (fieldName: string, option: Option) => {
          return form.setFieldValue(fieldName, option.value == 'true', false);
        };

        return (
          <FormItemLayout
            label={label}
            showTooltip={!!tooltip}
            tooltipOptions={{
              fdKey: `appstore_config_tooltip_${fieldName}`,
              labelText: tooltip,
            }}
          >
            <div className={classes.selectWrapper}>
              <Select
                isMulti={false}
                dataFd={`app-select-filter-${fieldName}`}
                options={options}
                TextFieldProps={{
                  fdKey: `app-select-dropdown-${fieldName}`,
                  variant: 'standard',
                }}
                value={selectedValue}
                onChange={(option: Option) => handleChange(fieldName, option)}
                fieldError={showError ? fieldError : undefined}
                checkMultiInputsPadding={false}
                noErrorTranslate={true}
              />
            </div>
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default SelectFieldBoolean;
