import options from '../options/legal';

export const getCountryCode = (countryCode) => {
  // console.log({ countryCode });
  return {
    hasLaw: options.hasLaw.indexOf(countryCode) >= 0,
    // revokable: options.revokable.indexOf(countryCode) >= 0,
    // explicitAction: options.explicitAction.indexOf(countryCode) >= 0,
  };
};
