import React, { useMemo, useRef } from 'react';

import { Polygon as GPolygon } from '@react-google-maps/api';
import * as wicket from 'wicket';

import { IMapShapeProps } from '../types';

const Wkt = wicket.Wkt;

export const serialize = (polygon: google.maps.Polygon[]) => {
  const wkt = new Wkt();
  wkt.fromObject(polygon);
  return wkt.write();
};

export const parse = (wellKnownText: string) => {
  const wkt = new Wkt();
  wkt.read(wellKnownText);
  return wkt.toObject() as google.maps.Polygon[];
};

export const isEqual = (a: google.maps.Polygon, b: google.maps.Polygon) => {
  if (a.getPaths().getLength() !== b.getPaths().getLength()) {
    return false;
  }
  return a
    .getPaths()
    .getArray()
    .every((mvca, mvcaIdx) => {
      if (mvca.getArray().length !== b.getPaths().getAt(mvcaIdx).getArray().length) {
        return false;
      }
      return mvca.getArray().every((l, i) => l.equals(b.getPaths().getAt(mvcaIdx).getAt(i)));
    });
};

const MultiPolygon = ({ zone, onChange, onSelect, disabled }: IMapShapeProps) => {
  const { features: initialFeatures, initFeatures } = useMemo(() => {
    try {
      return {
        features: parse(zone.WellKnownText as string), // !!!MUTABLE!!!
        initFeatures: zone.wkt.toObject() as google.maps.Polygon[],
      };
    } catch (error) {
      console.error(error);
      return {
        polygon: undefined,
        initPolygon: undefined,
      };
    }
  }, [zone]);

  const featuresRef = useRef(initialFeatures);
  return (
    <>
      {featuresRef.current?.map((polygon, idx) => {
        return (
          <GPolygon
            key={(zone.Id as number) + '.' + idx}
            onClick={disabled ? undefined : onSelect}
            onMouseUp={
              disabled
                ? undefined
                : () => {
                    if (initFeatures && featuresRef.current) {
                      if (!isEqual(polygon, initFeatures[idx])) {
                        onChange(serialize(featuresRef?.current));
                      }
                    }
                  }
            }
            paths={polygon.getPaths()}
            options={zone.options}
          />
        );
      })}
    </>
  );
};

export default MultiPolygon;
