import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import CodeView from '../../../ui/CodeView';
import ErrorComponent from '../../../ui/ErrorComponent';
import PageLayout from '../../../ui/Layout';
import FullWidthContainer from '../../../ui/Layout/FullWidthContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { Loader } from '../../common/Loader';
import { getAuditLog } from '../actions';
import { renderTitle } from '../LogContent';
import { LogDate } from '../LogDate';
import { LogUser } from '../LogUser';

const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  translate: getTranslate(state.locale),
  data: state.auditLogs.log,
  error: state.auditLogs.error,
  isLoading: state.auditLogs.auditLogsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => ({
  getLog: () =>
    dispatch(
      getAuditLog({
        page: 1,
        limit: 1,
        FlipdishEventId: ownProps.match.params.id,
      })
    ),
});

const useStyles = makeStyles(({ palette, breakpoints }: Theme) => ({
  list: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',

    '& dt': {
      float: 'left',
      width: '114px',
      color: palette.text.secondary,
    },
    '& dd': {
      margin: '0 0 28px 124px',
    },
    [breakpoints.down('md')]: {
      '& dd': {
        margin: '0 0 16px',
      },
      '& dt': {
        float: 'none',
        width: '100%',
        marginBottom: '4px',
      },
    },
  },
}));

type MappedProps = ReturnType<typeof mapStateToProps>;
type DispatchedProps = ReturnType<typeof mapDispatchToProps>;
type Props = MappedProps & DispatchedProps;

const AuditLogDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(function AuditLogDetails(props: Props) {
  const classes = useStyles();
  const { translate, getLog, data, isLoading, currentApp, error } = props;

  useEffect(() => {
    getLog();
  }, []);

  return (
    <PageLayout
      title={data ? renderTitle({ data, translate }) : ''}
      documentTitle={'Audit_logs'}
      toParent={`/${currentApp.AppId}/logs`}
    >
      {error && !isLoading ? (
        <ErrorComponent title="Something_went_wrong" action={getLog} />
      ) : (
        <Grid container spacing={0} direction="column" justifyContent="space-between">
          <Grid item xs={12}>
            {isLoading ? (
              <Loader />
            ) : (
              data && (
                <PaperContainer>
                  <dl className={classes.list}>
                    <dt>{translate('When')}</dt>
                    <dd>
                      <LogDate date={data.CreateTime as string} translate={translate} />
                    </dd>
                    <dt>{translate('Who')}</dt>
                    <dd>
                      <LogUser data={data} />
                    </dd>
                    <dt>{translate('Event_name')}</dt>
                    <dd>
                      {/* ts-ignore because AuditLog is a union type and will only allow shared props to be checked here and Store is only available on one of its types */}
                      {/* @ts-ignore */}
                      {data.Store && data.Store.IsArchived ? 'store.archived' : data.EventName}
                    </dd>
                  </dl>
                  <FullWidthContainer>
                    <CodeView
                      inputId="log-details-0000"
                      copyToClipboard
                      name={translate('Log_details') as string}
                      data={data}
                    />
                  </FullWidthContainer>
                </PaperContainer>
              )
            )}
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
});

export { AuditLogDetails };
