import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { gdpr } from '../../helpers/gdpr';
import CookieModal from '../CookiePreferences/CookieModal';

export type ProfileCookieProps = { translate: TranslateFunction };

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  text: {
    marginTop: '20px',
    height: '16px',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    opacity: 0.6,
    [breakpoints.down('md')]: {
      marginTop: '23px',
      marginBottom: '4px',
    },
  },
  container: {
    display: 'flex',
    // alignItems: "left",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  cookieButton: {
    margin: '0px 0px 12px 0px',
    padding: '4px 0px 4px 0px',
    minHeight: '24px',
    maxHeight: '24px',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    textTransform: 'none',
    [breakpoints.down('md')]: {
      marginLeft: '16px',
      padding: '4px 0px 4px 0px',
    },
  },
}));

const ProfileCookie = () => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);

  const updateCookieSettings = (e) => {
    e.preventDefault();
    setIsOpenModal(true);
  };

  const handleDone = () => {
    setIsOpenModal(false);
  };

  const isGDPREnabled = gdpr.isGDPREnabled();
  if (isGDPREnabled === 'false') {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Typography variant="caption" className={classes.text}>
        <Translate id="Cookie_settings" />
      </Typography>
      <Button
        color="primary"
        className={classes.cookieButton}
        onClick={updateCookieSettings}
        data-fd="btn-manage-cookie-settings"
      >
        <Translate id="Manage_settings" />
      </Button>
      <CookieModal handleDone={handleDone} isOpenModal={isOpenModal} />
    </Box>
  );
};

export default ProfileCookie;
