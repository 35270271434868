import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { notifyError, NotifyProps } from '../../../../layouts/Notify/actions';

const useStyles = makeStyles((theme: Theme) => ({
  pricing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  usageBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export type FeaturePricingInformationProps = {
  entitlementCode: string;
  getUsage?: () => Promise<{ curentUsage: number; entitlementQuantity: number }>;
};

type Props = FeaturePricingInformationProps & MappedState & MappedDispatch;

const FeatureUsage = ({ appId, entitlementCode, dispatchNotifyError, getUsage }: Props) => {
  const classes = useStyles();

  const { isPending, data, isError } = useQuery({
    queryKey: ['featureUsage', appId, entitlementCode],

    queryFn: async () => {
      if (!getUsage) {
        return;
      }
      const { curentUsage, entitlementQuantity } = await getUsage();

      return {
        maxUsageQuantity: entitlementQuantity,
        currentUsage: curentUsage,
      };
    },
  });

  useEffect(() => {
    if (isError) {
      dispatchNotifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [isError]);

  const getContent = () => {
    if (isPending || !data) {
      return <SkeletonLoader fdKey="skeleton-card" rows={[{ height: '80px', width: '100%' }]} />;
    }

    const { maxUsageQuantity, currentUsage } = data;
    const percentageUsed = (currentUsage / maxUsageQuantity) * 100;

    return (
      <>
        <Typography variant="h5">
          {currentUsage}/{maxUsageQuantity}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={percentageUsed}
          className={classes.usageBar}
          data-fd="usage-bar"
        />
        {/* <Typography variant="caption" component="span">
          An additional license is ready for use
        </Typography> */}
      </>
    );
  };

  return (
    <PaperContainer hasBorderOnMobile>
      <Grid container>
        <Grid item xs={12} sm md lg>
          <Typography variant="h6">
            <Translate id="FeatureUsage_Usage_Title" />
          </Typography>
          {getContent()}
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  };
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId!,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureUsage);
