import React, { useEffect } from 'react';

import { PushNotificationRequest, PushNotificationResponse } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { type FieldProps, type FormikProps, Field, Form, withFormik } from 'formik';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

import { usePrevious } from '../../../custom-hooks/usePrevious';
import { fullDateFormat, timeFormat } from '../../../helpers/dateFormats';
import { getTimezoneOffset } from '../../../helpers/timeZones';
import { formikValidate } from '../../../helpers/validation';
import Button from '../../../ui/Button/Button';
import GridContainer from '../../../ui/Layout/GridContainer';
import TextField from '../../../ui/TextField/TextField';
import MessageAndEmojiFormField from './MessageAndEmojiFormField';

const parseTime = (time: string): { hours: number; minutes: number } => {
  const parsed = time.split(':');

  return {
    hours: Number(parsed[0]),
    minutes: Number(parsed[1]),
  };
};

const getScheduleValues = (initialDate?: string | Date) => {
  const date = initialDate ? moment(initialDate) : moment();

  return {
    scheduledTime: date.format(timeFormat),
    scheduledDate: date,
  };
};

const validateDate = (value: any) => {
  return formikValidate.required(value);
};

const validateTime = (value: any, date: moment.Moment) => {
  const isEmpty = formikValidate.required(value);

  if (isEmpty) {
    return isEmpty;
  }

  const time = parseTime(value);
  const minDate = moment();
  const dateWithTime = date.clone();
  dateWithTime.hours(time.hours);
  dateWithTime.minutes(time.minutes);
  minDate.minutes(minDate.minutes());
  minDate.seconds(0);

  return formikValidate.minTime(dateWithTime, minDate, timeFormat);
};

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  submitButton: {
    marginBottom: spacing(4),

    [breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (props: OuterProps) => {
  if (props.data) {
    const { Title, Message, ScheduledTime, ScheduledPushNotificationId } = props.data;

    return {
      Title,
      Message,
      ScheduledTime,
      isScheduled: true,
      ScheduledPushNotificationId,
      ...getScheduleValues(ScheduledTime),
    };
  }

  return {
    Title: '',
    Message: '',
    isScheduled: false,
    ScheduledTime: new Date().toISOString(),
    ...getScheduleValues(),
  };
};

type OuterProps = {
  data?: PushNotificationResponse;
  saving?: boolean;
  onSubmit: (data: PushNotificationRequest) => void;
};

type Props = FormikProps<FormValues> & OuterProps;

export default withFormik<OuterProps, FormValues, {}>({
  displayName: 'PushNotifications',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props);
  },
  validateOnChange: true,
  validateOnBlur: false,
  handleSubmit: (values, formikBag) => {
    if (values.isScheduled) {
      const time = parseTime(values.scheduledTime);

      values.scheduledDate.hours(time.hours);
      values.scheduledDate.minutes(time.minutes);
    }

    const notification: PushNotificationRequest = {
      Title: values.Title,
      Message: values.Message,
      // @ts-ignore
      ScheduledPushNotificationId: values.ScheduledPushNotificationId,
      // @ts-ignore
      ScheduledTime: !values.isScheduled
        ? getScheduleValues().scheduledDate.toISOString()
        : values.scheduledDate.toISOString(),
    };

    formikBag.props.onSubmit(notification);
  },
})((props: Props) => {
  const classes = useStyles();
  const {
    saving,
    resetForm,
    data,
    setFieldValue,
    values: { scheduledTime, scheduledDate, isScheduled },
  } = props;
  const previousSaving = usePrevious(saving);

  useEffect(() => {
    if (previousSaving && !saving) {
      resetForm();
    }
  }, [saving, previousSaving]);

  return (
    <Form>
      <GridContainer>
        <Grid item xs={12} className={classes.gridItem}>
          <Box mb={2}>
            <MessageAndEmojiFormField disabled={saving} />
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <Box mb={2}>
            <Field name="isScheduled">
              {(fProps: FieldProps) => {
                const { field, form } = fProps;

                return (
                  <FormControlLabel
                    control={
                      <Switch
                        color="default"
                        checked={field.value}
                        value="scheduled"
                        onChange={() => {
                          if (!field.value) {
                            const dateValues = getScheduleValues();
                            scheduledDate.set(dateValues.scheduledDate.toObject());
                            setFieldValue('scheduledTime', dateValues.scheduledTime);
                          }
                          form.setFieldValue(field.name, !field.value);
                        }}
                        data-fd="push_notification_schedule_togle"
                      />
                    }
                    label={<Translate id="Schedule_push_for_later" />}
                  />
                );
              }}
            </Field>
          </Box>
        </Grid>

        {isScheduled ? (
          <>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Box mb={2}>
                <FormControl fullWidth>
                  <Field name="scheduledDate" validate={validateDate}>
                    {({ field, form }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            format={fullDateFormat}
                            onChange={(date) => {
                              form.setFieldValue(field.name, date);
                            }}
                            slotProps={{
                              textField: {
                                variant: 'standard',
                                inputProps: { 'data-fd': 'push_notification_schedule_date' },
                              },
                              popper: { style: { zIndex: 9999 } },
                            }}
                            minDate={moment()}
                            value={field.value || null}
                            label={<Translate id="Delivery_date" />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  </Field>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Box mb={3}>
                <FormControl fullWidth>
                  <Field
                    name="scheduledTime"
                    validate={(value) => validateTime(value, scheduledDate)}
                  >
                    {({ field, form }) => {
                      const { errors, touched } = form;
                      const fieldError = errors[field.name] as string | undefined;
                      const showError =
                        !!fieldError && (touched[field.name] as boolean | undefined);

                      return (
                        <TextField
                          {...field}
                          value={scheduledTime}
                          label={<Translate id="Delivery_time" />}
                          InputLabelProps={{ shrink: true }}
                          error={showError}
                          helperText={
                            showError ? (
                              fieldError
                            ) : (
                              <Translate
                                id="In_your_timezone"
                                data={{ offset: getTimezoneOffset() }}
                              />
                            )
                          }
                          fdKey="push_notification_schedule_time"
                          type="time"
                          onChange={(e) => {
                            field.onChange(e);
                            form.setFieldTouched(field.name, true);
                          }}
                        />
                      );
                    }}
                  </Field>
                </FormControl>
              </Box>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} className={classes.gridItem}>
          <Grid container justifyContent="flex-end" alignContent="center">
            <Grid item>
              <Button
                type="submit"
                color="primary"
                fdKey="btn-push-notifications-send"
                variant="contained"
                className={classes.submitButton}
                disabled={saving}
              >
                {data && data.ScheduledPushNotificationId ? (
                  <Translate id="Update" />
                ) : isScheduled ? (
                  <Translate id="Schedule" />
                ) : (
                  <Translate id="Send" />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>
    </Form>
  );
});
