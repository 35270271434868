import { HydraApiFactory, HydraRegistrationRequest } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const api = createApi(HydraApiFactory);

// #region get device list
export async function getDeviceList(
  appId: string,
  deviceType,
  pageIndex: number,
  pageSize: number
) {
  try {
    const incomingMessage = await api.getAttachedDevices(appId, deviceType, pageIndex, pageSize);
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region create Terminal
export async function createTerminal(appId: string, terminal: HydraRegistrationRequest) {
  try {
    const incomingMessage = await api.register(appId, terminal);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region unregister terminal
export async function unregisterTerminal(appId: string, deviceId: string) {
  try {
    await api.unAssign(appId, deviceId);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion
