import React, { useState } from 'react';

import { EmvTerminalWithAssignments } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, Redirect, withRouter } from 'react-router';
import { compose } from 'recompose';

import useRequestSnackBar, {
  useRequestSnackBarIndicators,
} from '../../../custom-hooks/useRequestSnackBar';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import PageLayout from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../ui/LoadingButton';
import GridDivider from '../../AppStore/components/AppStoreAppConfigForm/GridDivider';
import TelemetryButton from '../../ContextualButton/Telemetry/TelemetryButton';
import FormItemLayout from '../../Finance/Banking/components/FormItemLayout';
import AddPaymentTerminalForm from '../Components/AddPaymentTerminalForm';
import CashPaymentOption from '../Components/CashPaymentOption';
import KioskAppearanceSettings from '../Components/KioskAppearanceSettings';
import KioskStoresEdit from '../Components/KioskStoresEdit/KioskStoresEdit';
import UnregisterKioskModal from '../Components/UnregisterKioskModal';
import { getKiosk, KIOSK_UNREGISTER, unregisterKiosk } from '../Kiosks.actions';
import StripeTerminal from '../StripeTerminal/StripeTerminal';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

const unregisteringSelector = createLoadingSelector([KIOSK_UNREGISTER]);

type Props = MappedState & RouteComponentProps<{ id: string }> & MappedDispatch;

const KioskEdit = (props: Props) => {
  const {
    appId,
    error,
    getKioskDetails,
    hasStripePairingPermission,
    kiosk,
    processing,
    unregister,
    unregisteringKioskComplete,
    showHiddenFeatures,
  } = props;
  const classes = useStyles();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [EMVTerminalsAssignedToApp, setEMVTerminalsAssignedToApp] = useState<
    EmvTerminalWithAssignments[]
  >([]);
  const parentUrl = `/${appId}/sales-channels/kiosks`;
  const { success, failure } = useRequestSnackBarIndicators(processing, error);

  useRequestSnackBar({
    success,
    failure,
    failureMessage: error,
    autoSaving: processing,
  });

  if (unregisteringKioskComplete) {
    return <Redirect to={parentUrl} />;
  }

  if (!kiosk) {
    getKioskDetails();
  }
  if (kiosk) {
    const { DeviceId, DeviceName, HydraConfigId } = kiosk;

    return (
      <PageLayout
        auditLogsFilter={{ type: 'EventType', value: 'hydra.*' }}
        contextButtons={[<TelemetryButton key={DeviceId!} kioskId={DeviceId!} />]}
        title={DeviceName}
        toParent={parentUrl}
        documentTitle="Kiosk"
      >
        <PaperContainer>
          <GridContainer>
            <Grid item xs={12} className={classes.gridItem}>
              <FormItemLayout label={<Translate id="Stores" />}>
                <KioskStoresEdit appId={appId!} deviceId={DeviceId} />
              </FormItemLayout>
              <Divider style={{ marginBottom: 24 }} />
              <FormItemLayout label={<Translate id="Payment_methods" />}>
                <CashPaymentOption
                  appId={appId!}
                  deviceId={DeviceId}
                  EMVTerminalsAssignedToApp={EMVTerminalsAssignedToApp}
                />

                {showHiddenFeatures ? (
                  <AddPaymentTerminalForm
                    appId={appId!}
                    deviceId={DeviceId!}
                    EMVTerminalsAssignedToApp={EMVTerminalsAssignedToApp}
                    hydraConfigId={HydraConfigId!}
                    setEMVTerminalsAssignedToApp={setEMVTerminalsAssignedToApp}
                  />
                ) : (
                  hasStripePairingPermission && (
                    <>
                      <StripeTerminal appId={appId!} deviceId={DeviceId} />
                    </>
                  )
                )}
              </FormItemLayout>
            </Grid>
            <GridDivider />

            <Grid item xs={12} className={classes.gridItem}>
              <FormItemLayout label={<Translate id="Appearance" />}>
                <KioskAppearanceSettings appId={appId!} deviceId={DeviceId!} />
              </FormItemLayout>
            </Grid>

            <GridDivider />
            <Grid item xs={8} className={classes.gridItem}>
              <LoadingButton
                fdKey="unregister_kiosk"
                color="secondary"
                onClick={() => setDialogOpen(true)}
                loading={processing}
              >
                <Translate id="Unregister_kiosk" />
              </LoadingButton>
            </Grid>
          </GridContainer>
        </PaperContainer>

        {kiosk?.DeviceId && (
          <UnregisterKioskModal
            id={DeviceId}
            isDialogOpen={isDialogOpen}
            setDialogOpen={setDialogOpen}
            isLoading={processing}
            submit={() => {
              unregister();
            }}
          />
        )}
      </PageLayout>
    );
  } else return <></>;
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelectorBluetooth = permissionsSelector.hasPermissionFactory([
    'InitiateBluetoothPairingMode',
  ]);
  const getPermissionsSelectorHiddenFeatures = permissionsSelector.hasPermissionFactory([
    'ShowHiddenFeatures',
  ]);
  return (state: AppState, ownProps: RouteComponentProps<{ id: string }>) => ({
    appId: state.currentApp.AppId,
    error: state.kiosks.error,
    hasStripePairingPermission: getPermissionsSelectorBluetooth(state),
    kiosk: state.kiosks.kiosks.find((item) => item.DeviceId === ownProps.match.params.id),
    processing: unregisteringSelector(state),
    showHiddenFeatures: getPermissionsSelectorHiddenFeatures(state),
    unregisteringKioskComplete: state.kiosks.unregisteringKioskComplete,
  });
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: RouteComponentProps<{ id: string }>
) => {
  return {
    getKioskDetails: () => {
      dispatch(getKiosk(ownProps.match.params.id));
    },
    unregister: () => {
      if (ownProps.match.params.id !== undefined) {
        dispatch(unregisterKiosk(ownProps.match.params.id));
      }
    },
  };
};

export default compose<Props, Props>(
  connect(mapStateToPropsFactory, mapDispatchToProps),
  withRouter
)(KioskEdit);
