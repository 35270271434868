import React from 'react';

import { AppStoreApp } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '../../../../ui/Forms/FormItemLayout';

export type ExternalInstructionsProps = {
  appStoreApp: AppStoreApp;
};

const useStyles = makeStyles(() => ({
  instructions: {
    whiteSpace: 'pre-wrap',
    marginTop: 4,
    marginBottom: 24,
  },
  button: {
    marginTop: 4,
    marginBottom: 24,
  },
}));

const ExternalInstructions = ({ appStoreApp }: ExternalInstructionsProps) => {
  const classes = useStyles();

  return (
    <FormItemLayout label={<Translate id="How_to_get_setup" />}>
      {appStoreApp.SetupInstructions && (
        <Typography variant="body2" className={classes.instructions}>
          {appStoreApp.SetupInstructions}
        </Typography>
      )}
      {appStoreApp.ExternalSetupLink && (
        <a href={appStoreApp.ExternalSetupLink} target="_blank" rel="noopener noreferrer">
          <Button color="primary" className={classes.button} variant="outlined">
            <Translate id="Go_to_setup" />
          </Button>
        </a>
      )}
    </FormItemLayout>
  );
};

export default ExternalInstructions;
