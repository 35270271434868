import { Order, OrderSummary, Store } from '@flipdish/api-client-typescript';

import { action, actionError } from '../../actions/utils';
import { getOrders, getOrdersSummary } from '../../services/order.service';
import { OrderEvents } from '../../signalr/hub.actions';
import { getStoresByAppId } from './services';

export const SET_STORE_FILTER = 'ORDER/SET_STORE_FILTER';
export type SetStoreFilter = ReturnType<typeof setStoreFilter>;
export const setStoreFilter = (filter: { stores: number[] }) => action(SET_STORE_FILTER, filter);

export const SET_CHANNEL_FILTER = 'ORDER/SET_CHANNEL_FILTER';
export type SetChannelFilter = ReturnType<typeof setChannelFilter>;
export const setChannelFilter = (filter: { channels: string[] }) =>
  action(SET_CHANNEL_FILTER, filter);

export type OrderStatesType = Array<
  'Created' | 'ReadyToProcess' | 'AcceptedByRestaurant' | 'Cancelled' | 'Rejected'
>;

export const SET_STATE_FILTER = 'ORDER/SET_STATE_FILTER';
export type SetStateFilter = ReturnType<typeof setStateFilter>;
export const setStateFilter = (filter: OrderStatesType) => {
  return action(SET_STATE_FILTER, { states: filter });
};

export const SET_ORDERS_PAGE = 'ORDER/SET_ORDERS_PAGE';
export type SetOrdersPage = ReturnType<typeof setOrdersPage>;
export const setOrdersPage = (page: number) => {
  return action(SET_ORDERS_PAGE, { page });
};

export const SET_PENGING_STORES_FILTER = 'ORDER/SET_PENGING_STORES_FILTER';
export type SetPengingStoresFilter = ReturnType<typeof setPendingStoresFilter>;
export const setPendingStoresFilter = (pendingStores: Array<{ label: string; value: number }>) =>
  action(SET_PENGING_STORES_FILTER, { pendingStores });

export const unSubscribeOrderUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    dispatch(OrderEvents.Unsubscribe.Accepted);
    dispatch(OrderEvents.Unsubscribe.Created);
    dispatch(OrderEvents.Unsubscribe.Refunded);
    dispatch(OrderEvents.Unsubscribe.Rejected);
  };
};

export const subscribeOrderUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    dispatch(OrderEvents.Subscribe.Accepted);
    dispatch(OrderEvents.Subscribe.Created);
    dispatch(OrderEvents.Subscribe.Refunded);
    dispatch(OrderEvents.Subscribe.Rejected);
  };
};

export const PENDING_ORDERS_REQUEST = 'ORDER/PENDING_ORDERS_REQUEST';
export type PendingOrdersRequest = ReturnType<typeof getPendingOrdersRequest>;
export const getPendingOrdersRequest = () => action(PENDING_ORDERS_REQUEST, {});

export const PENDING_ORDERS_SUCCESS = 'ORDER/PENDING_ORDERS_SUCCESS';
export type PendingOrderSuccess = ReturnType<typeof getPendingOrdersSuccess>;
export const getPendingOrdersSuccess = (payload: {
  orders: OrderSummary[];
  ordersCount: number;
  concat: boolean;
}) => action(PENDING_ORDERS_SUCCESS, payload);

export const PENDING_ORDER_FAILURE = 'ORDER/PENDING_ORDER_FAILURE';
export type PendingOrderFailure = ReturnType<typeof getPendingOrdersFailure>;
export const getPendingOrdersFailure = (error: Error) =>
  actionError(PENDING_ORDER_FAILURE, {}, error);

export const getPendingOrders = (page, storeIds?: number[], from?: Date) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getPendingOrdersRequest());
      const state = getState();
      const appId = state.currentApp.AppId;
      if (appId) {
        const pagination = await getOrdersSummary({
          appId,
          page,
          limit: 20,
          orderStates: ['ReadyToProcess'],
          physicalRestaurantId: storeIds,
          from,
        });
        dispatch(
          getPendingOrdersSuccess({
            concat: page === 1 ? false : true,
            orders: pagination.Data,
            ordersCount: pagination.TotalRecordCount,
          })
        );
      } else {
        dispatch(getPendingOrdersFailure(new Error('App id is undefined')));
      }
    } catch (error) {
      dispatch(getPendingOrdersFailure(error));
    }
  };
};

export const ACCEPTED_ORDERS_REQUEST = 'ORDER/ACCEPTED_ORDERS_REQUEST';
export type AcceptedOrdersRequest = ReturnType<typeof getAcceptedOrdersRequest>;
export const getAcceptedOrdersRequest = () => action(ACCEPTED_ORDERS_REQUEST, {});

export const ACCEPTED_ORDERS_SUCCESS = 'ORDER/ACCEPTED_ORDERS_SUCCESS';
export type AcceptedOrderSuccess = ReturnType<typeof getAcceptedOrdersSuccess>;
export const getAcceptedOrdersSuccess = (payload: {
  orders: Order[];
  ordersCount: number;
  concat: boolean;
}) => action(ACCEPTED_ORDERS_SUCCESS, payload);

export const ACCEPTED_ORDER_FAILURE = 'ORDER/ACCEPTED_ORDER_FAILURE';
export type AcceptedOrderFailure = ReturnType<typeof getAcceptedOrdersFailure>;
export const getAcceptedOrdersFailure = (error: Error) =>
  actionError(ACCEPTED_ORDER_FAILURE, {}, error);

export const getAcceptedOrders = (page, storeIds?: number[]) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getAcceptedOrdersRequest());
      const state = getState();
      const appId = state.currentApp.AppId;
      if (appId) {
        const pagination = await getOrders({
          physicalRestaurantId: storeIds,
          orderStates: ['AcceptedByRestaurant', 'Dispatched'],
          appId,
          page,
          limit: 20,
        });
        dispatch(
          getAcceptedOrdersSuccess({
            concat: page === 1 ? false : true,
            orders: pagination.Data,
            ordersCount: pagination.TotalRecordCount,
          })
        );
      } else {
        dispatch(getAcceptedOrdersFailure(new Error('App id is undefined')));
      }
    } catch (error) {
      dispatch(getAcceptedOrdersFailure(error));
    }
  };
};

export const GET_STORES_REQUEST = 'ORDER/GET_STORES_REQUEST';
export type GetStoresRequest = ReturnType<typeof getPendingOrdersRequest>;
export const getStoresRequest = () => action(GET_STORES_REQUEST, {});

export const GET_STORES_SUCCESS = 'ORDER/GET_STORES_SUCCESS';
export type GetStoresSuccess = ReturnType<typeof getStoresSuccess>;
export const getStoresSuccess = (payload: { stores: Store[] }) =>
  action(GET_STORES_SUCCESS, payload);

export const GET_STORES_FAILURE = 'ORDER/GET_STORES_FAILURE';
export type GetStoresFailure = ReturnType<typeof getStoresFailure>;
export const getStoresFailure = (error: Error) => actionError(GET_STORES_FAILURE, {}, error);

export const getStores = (query?: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getStoresRequest());
      const state = getState();
      const appId = state.currentApp.AppId;
      if (appId) {
        const stores = await getStoresByAppId({
          appId,
          page: 1,
          limit: 20,
          query,
        });
        dispatch(
          getStoresSuccess({
            stores: stores.Data,
          })
        );
      } else {
        dispatch(getStoresFailure(new Error('App id is undefined')));
      }
    } catch (error) {
      dispatch(getStoresFailure(error));
    }
  };
};
