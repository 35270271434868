import React, { useCallback, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import useFilterablePagination from '../../../custom-hooks/useFilterablePagination';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import FullWidthContainer from '../../../ui/Layout/FullWidthContainer';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Filter from '../../common/Filter';
import { Loader } from '../../common/Loader';
import TablePagination from '../../common/TablePagination/TablePagination';
import { getWebhookLogs } from '../actions';

const rowPerPageOptions: number[] = [25, 50, 100, 200];

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    fontSize: '12px',
    lineHeight: 1.33,
    padding: 0,
    margin: `0 0 ${theme.spacing(2)}`,
  },
  listItem: {
    display: 'flex',
    padding: `20px ${theme.spacing(2)} 12px`,
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  mainItemTitle: {
    fontSize: '16px',
    lineHeight: 1.5,
    marginBottom: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subList: {
    margin: 0,
    '& dt': {
      float: 'left',
    },
  },
  cell: {
    wordBreak: 'break-all',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type MappedProps = ReturnType<typeof mapStateToProps>;

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = MappedProps &
  MappedDispatch &
  RouteComponentProps<{ OAuthAppId: string; WebhookSubscriptionId: string }>;

const mapStateToProps = (state: AppState) => {
  const {
    locale,
    developers: {
      webhookLogs: { Data, TotalRecordCount: count },
      isLoading,
    },
  } = state;

  return {
    Data,
    count,
    isLoading,
    translate: getTranslate(locale),
    currentApp: state.currentApp,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getWebhookLogs: (
    oauthAppId: string,
    webhookSubscriptionId: number,
    start: string,
    end: string,
    page?: number,
    limit?: number
  ) => dispatch(getWebhookLogs(oauthAppId, webhookSubscriptionId, start, end, page, limit)),
});

export default compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(function ({
  Data,
  translate,
  history,
  count,
  currentApp,
  getWebhookLogs,
  isLoading,
  match: {
    params: { OAuthAppId, WebhookSubscriptionId },
  },
}: Props) {
  const { page, rowsPerPage, filters, handleChangePage, handleChangeRowsPerPage, handleFilter } =
    useFilterablePagination(history, false);
  const classes = useStyles();

  const getDate = useCallback(
    (key: 'after' | 'before') => {
      const filter = filters.find((filter) => filter.type.includes(key));

      return filter
        ? moment(Number(filter.value)).format()
        : key === 'before'
          ? moment().format()
          : '2010-01-01T12:00';
    },
    [filters]
  );

  useEffect(() => {
    getWebhookLogs(
      OAuthAppId,
      Number(WebhookSubscriptionId),
      getDate('after'),
      getDate('before'),
      page + 1,
      rowsPerPage
    );
  }, [page, rowsPerPage, filters]);
  const header = (
    <GridContainer>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <Filter
          value={filters}
          dateFilter
          withTime
          placeholder={translate('Filter_by_date_and_time') as string}
          onChange={handleFilter}
        />
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      fluid
      header={header}
      documentTitle="Webhook_logs"
      title={translate('Webhook_logs')}
      toParent={`/${currentApp.AppId}/developers/${OAuthAppId}/webhooks`}
    >
      {isLoading ? (
        <Loader />
      ) : Data.length ? (
        <PaperContainer>
          <Hidden mdDown>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate('Webhook_ID')}</TableCell>
                  <TableCell>{translate('Status')}</TableCell>
                  <TableCell>{translate('Event_name')}</TableCell>
                  <TableCell>{translate('Created')}</TableCell>
                  <TableCell>{translate('Triggered')}</TableCell>
                  <TableCell>{translate('Duration')}</TableCell>
                  <TableCell>{translate('Retry_count')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Data.map((log, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell className={classes.cell}>{log.FlipdishWebhookId}</TableCell>
                      <TableCell className={classes.cell}>{log.HttpResponseStatusCode}</TableCell>
                      <TableCell className={classes.cell}>{log.WebhookEventName}</TableCell>
                      <TableCell className={classes.cell}>{log.EventCreated}</TableCell>
                      <TableCell className={classes.cell}>{log.WebhookTriggered}</TableCell>
                      <TableCell className={classes.cell}>{log.Duration}</TableCell>
                      <TableCell className={classes.cell}>{log.RetryCount}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Hidden>
          <Hidden mdUp>
            <FullWidthContainer>
              <ul className={classes.list}>
                {Data.map((log, ind) => {
                  return (
                    <li className={`${classes.listItem} ${HORIZONTAL_SPACE_CLASSNAME}`} key={ind}>
                      <span>{translate('Event_name')}</span>
                      <span className={classes.mainItemTitle}>{log.WebhookEventName}</span>
                      <dl className={classes.subList}>
                        <dt>{translate('Status')}:</dt>
                        <dd>{log.HttpResponseStatusCode}</dd>
                        <dt>{translate('Created')}:</dt>
                        <dd>{log.EventCreated}</dd>
                        <dt>{translate('Triggered')}:</dt>
                        <dd>{log.WebhookTriggered}</dd>
                        <dt>{translate('Duration')}:</dt>
                        <dd>{log.Duration}</dd>
                        <dt>{translate('Retry_count')}:</dt>
                        <dd>{log.RetryCount}</dd>
                      </dl>
                    </li>
                  );
                })}
              </ul>
              <TablePagination
                component="div"
                labelRowsPerPage=""
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </FullWidthContainer>
          </Hidden>
        </PaperContainer>
      ) : (
        <EmptyComponent title="No_webhook_logs_message" noLink />
      )}
    </PageLayout>
  );
});
