import React, { useState } from 'react';

import { Channel, OrderSummary, Reject } from '@flipdish/api-client-typescript';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import Swipeout from 'rc-swipeout';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

import * as ordersActions from '../../../actions/order.action';
import { DateTime } from '../../../ui/DateTime/DateTime';
import AcceptDialogMobile from '../components/AcceptDialogMobile';
import Icon from '../components/Icon';
import { displayCustomerNameForOrder, renderPaymentStatus } from '../helpers';
import RejectDialogMobile from './RejectDialogMobile';

import 'rc-swipeout/assets/index.css';

const styles = (theme: Theme) =>
  createStyles({
    textFieldFiltersResponsive: {
      width: '100%',
      margin: '35px auto 0 !important',
    },
    textFieldStoresResponsive: {
      width: '100%',
      margin: '28px auto 23px',
    },
    searchIcon: {
      color: 'rgba(0,0,0,0.54)',
      cursor: 'pointer',
    },
    responsiveTable: {
      borderTop: '1px solid rgba(0,0,0,0.2088)',
      paddingTop: '8px',
    },
    responsiveCell: {
      padding: '12px 16px',
      display: 'flex',
    },

    responsiveCellContents: {
      paddingRight: '8px',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '0%',
    },
    responsiveContents: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: '0.3px',
      color: 'rgba(0, 0, 0, 0.6)',
      wordBreak: 'break-word',
    },
    responsiveDiv: {
      position: 'relative',
    },
    responsiveAmount: {
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.87)',
      position: 'absolute',
      right: '0',
    },
    responsiveCode: {
      fontSize: '18px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    responsiveDescription: {
      fontSize: '12px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.33,
      color: 'rgba(0, 0, 0, 0.6)',
      position: 'absolute',
      right: '0',
      top: '17px',
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
    },
    orderReady: {
      // opacity: '0.12',
      backgroundColor: 'rgba(222, 172, 250,0.12)',
    },
    orderRejected: {
      color: '#fff',
      backgroundColor: 'rgba(255, 215, 223,0.12)',
    },
    orderAccepted: {
      color: '#fff',
      backgroundColor: '#fff',
    },
    button: {
      margin: theme.spacing(1),
      fontFamily: 'Roboto',
      textAlign: 'justify',
      fontSize: '12px',
      fontStretch: 'normal',
      lineHeight: '5.33',
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      verticalAlign: 'middle',
      color: 'white',
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rowLink: {
      textDecoration: 'none',
    },
    textStyleRefunded: {
      textTransform: 'capitalize',
    },
  });

const acceptMinsCollection = [
  { default: false, mins: 5 },
  { default: true, mins: 10 },
  { default: false, mins: 20 },
  { default: false, mins: 30 },
  { default: false, mins: 45 },
];
const acceptMinsDelivery = [
  { default: false, mins: 30 },
  { default: true, mins: 45 },
  { default: false, mins: 60 },
  { default: false, mins: 90 },
];

type Props = WithStyles<typeof styles> & MappedDispatch & MappedState & RouteComponentProps;

const OrderListMobile: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { orders, classes, acceptOrder, rejectOrder, translate, match, permissions, languageCode } =
    props;

  const url = match.url.replace(/\/$/, '');
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [orderId, setOrderId] = useState<number>();
  const [deliveryType, setDeliveryType] = useState<OrderSummary.DeliveryTypeEnum>();

  const renderListItem = (order: OrderSummary, index: number) => {
    const dataFdPrefix = `order_${order.OrderId}_`;

    const customerName =
      order.CustomerName && order.CustomerName?.length > 150
        ? displayCustomerNameForOrder(order.CustomerName, 150)
        : order.CustomerName;

    return (
      <Link
        to={`${url}/${order.OrderId}`}
        className={classes.rowLink}
        key={index}
        data-fd="list-item"
      >
        <div
          data-fd={`${dataFdPrefix}status`}
          className={clsx(classes.responsiveCell, classes.row, {
            [classes.orderReady]: order.OrderState === OrderSummary.OrderStateEnum.ReadyToProcess,
            [classes.orderAccepted]:
              order.OrderState === OrderSummary.OrderStateEnum.AcceptedAndRefunded ||
              order.OrderState === OrderSummary.OrderStateEnum.AcceptedByRestaurant ||
              order.OrderState === OrderSummary.OrderStateEnum.Dispatched,
            [classes.orderRejected]:
              order.OrderState === OrderSummary.OrderStateEnum.RejectedByFlipdish ||
              order.OrderState === OrderSummary.OrderStateEnum.RejectedByStore ||
              order.OrderState === OrderSummary.OrderStateEnum.RejectedAfterBeingAccepted ||
              order.OrderState === OrderSummary.OrderStateEnum.Cancelled ||
              order.OrderState === OrderSummary.OrderStateEnum.RejectedAutomatically,
          })}
        >
          <Icon
            size={'small'}
            deliveryType={order.DeliveryType}
            OrderState={order.OrderState}
            channelOrder={order.Channel?.Source === Channel.SourceEnum.External}
          />
          <div className={classes.responsiveCellContents}>
            <Typography variant="h4" component="h4" className={classes.responsiveCode}>
              #{order.OrderId}
            </Typography>
            <div className={classes.responsiveContents} data-fd={`${dataFdPrefix}requested_date`}>
              <DateTime>
                {({ dtUtils }) => {
                  const parsedDateTime = {
                    date: order.RequestedForTime ? dtUtils.format(order.RequestedForTime, 'P') : '',
                    time: order.RequestedForTime ? dtUtils.format(order.RequestedForTime, 'p') : '',
                  };

                  return `${parsedDateTime.time} ${parsedDateTime.date}`;
                }}
              </DateTime>{' '}
              (<Translate id="Requested for" />)
              <Typography variant="h6" component="h6" className={classes.responsiveContents}>
                {order.StoreName}
              </Typography>
              <Typography variant="h6" component="h6" className={classes.responsiveContents}>
                <span data-fd={`${dataFdPrefix}phone`}>{order.CustomerPhoneNumber}</span>{' '}
                {customerName}
              </Typography>
              <Typography variant="h6" component="h6" className={classes.responsiveContents}>
                {translate(
                  `${order.Channel?.TranslationKey}_${order.Channel?.Source}` as TranslationId
                )}
                {order.Channel?.Source === Channel.SourceEnum.External &&
                  order.ChannelOrderDisplayId &&
                  ` #${order.ChannelOrderDisplayId}`}
              </Typography>
            </div>
          </div>
          <div className={classes.responsiveDiv}>
            <div className={classes.responsiveAmount} data-fd={`${dataFdPrefix}amount`}>
              {order.Amount &&
                order.Amount.toLocaleString(languageCode, {
                  style: 'currency',
                  currency: order.Currency!.toString(),
                })}
            </div>
            <Typography variant="h6" component="h6" className={classes.responsiveDescription}>
              {renderPaymentStatus(order, classes, `${dataFdPrefix}payment_status`)}
            </Typography>
          </div>
        </div>
      </Link>
    );
  };

  const renderListItemWithSwipe = (order: OrderSummary, index: number) => {
    return (
      // @ts-expect-error Swipeout does not support React 18's types
      <Swipeout
        key={index}
        left={[
          {
            text: (
              <Button className={classes.button}>
                <CheckIcon className={classes.leftIcon} /> Accept
              </Button>
            ),
            onPress: () => {
              setIsRejectDialogOpen(false);
              setIsAcceptDialogOpen(true);
            },
            style: { backgroundColor: '#5ee0a5', width: '120px' },
            className: 'custom-class-1',
          },
        ]}
        right={[
          {
            text: (
              <Button className={classes.button}>
                Reject <CloseIcon className={classes.rightIcon} />
              </Button>
            ),
            onPress: () => {
              setIsAcceptDialogOpen(false);
              setIsRejectDialogOpen(true);
            },
            style: { backgroundColor: '#ff617f', width: '120px' },
            className: 'custom-class-2',
          },
        ]}
        autoClose
        onOpen={() => {
          setOrderId(order.OrderId);
          setDeliveryType(order.DeliveryType);
        }}
      >
        {renderListItem(order, index)}
      </Swipeout>
    );
  };

  return (
    <div className={classes.responsiveTable}>
      <AcceptDialogMobile
        open={isAcceptDialogOpen}
        mins={
          deliveryType === OrderSummary.DeliveryTypeEnum.Pickup
            ? acceptMinsCollection
            : acceptMinsDelivery
        }
        handleAccept={(mins: number) => acceptOrder(mins, orderId!)}
        handleClose={() => setIsAcceptDialogOpen(false)}
      />
      <RejectDialogMobile
        open={isRejectDialogOpen}
        rejectOrder={(reason: Reject.RejectReasonEnum) => rejectOrder(orderId!, reason)}
        handleClose={() => setIsRejectDialogOpen(false)}
      />

      {orders.map((order: Required<OrderSummary>, index) => {
        if (
          order.OrderState === OrderSummary.OrderStateEnum.ReadyToProcess &&
          permissions.includes('UpdateOrdersAccept')
        ) {
          return renderListItemWithSwipe(order, index);
        } else {
          return renderListItem(order, index);
        }
      })}
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    orders: state.orders.orders,
    translate: getTranslate(state.locale),
    permissions: state.permissions,
    languageCode: getActiveLanguage(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  acceptOrder: (mins: number, id: number) => {
    dispatch(ordersActions.acceptOrder(mins, id));
  },
  rejectOrder: (id: number, reason: Reject.RejectReasonEnum) =>
    dispatch(ordersActions.rejectOrder(reason, id)),
});

const EnhancedComponent = compose<Props, {}>(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderListMobile);

export default EnhancedComponent;
