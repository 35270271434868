import React, { useEffect, useState } from 'react';

import { AppStoreApp, ValidationErrorResult } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { UseMutateFunction } from '@tanstack/react-query';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import { ExternalLink } from '@fd/ui/atoms/ExternalLink';
import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import TextField from '@fd/ui/TextField/TextField';

import { FormikScrollToError } from '../../../../../layouts/Portal/FormikScrollToError';
import GridDivider from '../../../components/AppStoreAppConfigForm/GridDivider';
import ConfigurationGroups from './ConfigurationGroups';
import ConfigurationFieldDialog from './dialogs/ConfigurationFieldDialog';
import ConfigurationGroupDialog from './dialogs/ConfigurationGroupDialog';
import LogoField from './fields/LogoField';
import OauthAppField from './fields/OauthAppField';
import SelectField from './fields/SelectField';
import SelectFieldBoolean from './fields/SelectFieldBoolean';
import TextInput from './fields/TextInput';

type StyleProps = {
  isDeleteButton?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  field: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 24,
  },
  actionsContainer: {
    padding: theme.spacing(4),
  },
  enableButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
    justifyContent: (props) => (props.isDeleteButton ? 'inherit' : 'end'),
  },
  alignEnd: {
    textAlign: 'end',
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  helpdeskHelperText: {
    float: 'left',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(41),
    },
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(41),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (appStoreApp?: AppStoreApp): Partial<AppStoreApp> => {
  return (
    appStoreApp || {
      Name: '',
      Description: '',
      Details: '',
      DeveloperName: '',
      SetupInstructions: '',
      ExternalSetupLink: '',
      ExternalFunctionActionUrl: '',
      Support: {
        PrivacyPolicy: '',
        TermsOfUse: '',
        SupportEmail: '',
        CompanyWebsite: '',
        LearnMore: '',
        InstallationMessage: '',
      },
    }
  );
};
export type AppStoreAppFormProps = {
  appStoreApp?: AppStoreApp;
  translate: TranslateFunction;
  onSaveChanges: (updatedAppStoreAppConfig: any) => any;
  appStoreFormErrors?: ValidationErrorResult[];
  deleteAppStoreApp?: UseMutateFunction<any, any, any>;
  signingKey?: { Data: string };
  getSigningKey?: () => void;
  signingKeyLoading?: boolean;
};

export type OpenConfigurationField = {
  fieldGroup: any;
  position: number;
};

const AppStoreAppForm = (props: AppStoreAppFormProps & FormikProps<any>) => {
  const {
    appStoreApp,
    translate,
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    appStoreFormErrors,
    setFieldError,
    deleteAppStoreApp,
    signingKey,
    getSigningKey,
    signingKeyLoading,
  } = props;
  const isDeleteButton = !!(appStoreApp?.Id && appStoreApp?.OAuthAppId && deleteAppStoreApp);
  const classes = useStyles({ isDeleteButton });
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  useEffect(() => {
    appStoreFormErrors?.forEach((err) => {
      setFieldError(err.FieldName!, err.Errors!.join('. '));
    });
  }, [appStoreFormErrors]);
  const [configurationGroupDialogOpen, setConfigurationGroupDialogOpen] = useState<number | null>(
    null
  );
  const [configurationFieldDialogOpen, setConfigurationFieldDialogOpen] =
    useState<OpenConfigurationField | null>(null);

  const Actions = () => {
    const save = (updateVerificationStatus: AppStoreApp.VerificationStatusEnum | null = null) => {
      if (updateVerificationStatus) {
        setFieldValue('VerificationStatus', updateVerificationStatus, false);
      }
      handleSubmit();
    };

    const getButtonText = () => {
      if (AppStoreApp.VerificationStatusEnum.Draft === appStoreApp?.VerificationStatus) {
        return <Translate id="Save_and_submit" />;
      }
      return <Translate id="Save_as_draft" />;
    };
    const getButtonVerificationStatus = () => {
      if (AppStoreApp.VerificationStatusEnum.Draft === appStoreApp?.VerificationStatus) {
        return AppStoreApp.VerificationStatusEnum.Submitted;
      }
      return AppStoreApp.VerificationStatusEnum.Draft;
    };

    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <>
        <YesNoDialog
          noTextId={'Cancel'}
          onNo={() => setIsDeleteDialogVisible(false)}
          onYes={() => {
            deleteAppStoreApp?.({ Id: appStoreApp?.Id, OAuthAppId: appStoreApp?.OAuthAppId });
            setIsDeleteDialogVisible(false);
          }}
          open={isDeleteDialogVisible}
          titleTextId={'Delete_app_store_app'}
          yesTextId={'Delete'}
        >
          <Typography variant="body1">
            <Translate id="Are_you_sure_you_want_to_delete_the_app" />
          </Typography>
        </YesNoDialog>
        <Grid
          container
          className={classes.enableButtonContainer}
          item
          xs={12}
          justifyContent="space-between"
        >
          {isDeleteButton && (
            <Grid item xs={12} sm={4}>
              <Button
                onClick={() => setIsDeleteDialogVisible(true)}
                fullWidth={isMobile}
                color="secondary"
                variant="contained"
                className={classes.button}
                disabled={isSubmitting}
                data-fd="delete-app"
              >
                <Translate id="Delete" />
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={8} className={classes.alignEnd}>
            <Button
              fullWidth={isMobile}
              onClick={() => save()}
              color="primary"
              className={classes.button}
              disabled={isSubmitting}
            >
              <Translate id="Save" />
            </Button>
            <Button
              fullWidth={isMobile}
              onClick={() => save(getButtonVerificationStatus())}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isSubmitting}
            >
              {getButtonText()}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderLogoField = () => {
    if (appStoreApp) {
      return <LogoField logoUrl={appStoreApp.Logo!} key={appStoreApp.Logo} />;
    }

    return <LogoField />;
  };

  const hasActionButton = values.FieldGroups?.some((group) =>
    group?.Fields?.some((field) => field?.FieldType === 'ActionButton')
  );

  const newFieldGroupPosition = values.FieldGroups ? values.FieldGroups.length : 0;
  const openFieldGroupPosition =
    configurationGroupDialogOpen === null ? -1 : configurationGroupDialogOpen;

  useEffect(() => {
    // clean up external url field if hidden
    !hasActionButton && setFieldValue('ExternalFunctionActionUrl', null);
  }, [hasActionButton]);
  const renderContent = () => {
    return (
      <>
        <Grid item xs={12} className={classes.field}>
          <OauthAppField isDisabled={!!appStoreApp?.OAuthAppId} />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectField
            fieldName="TeammateAppAccessLevel"
            label={translate('Permissions_selector') as string}
            placeholder={translate('Select_teammate_permission') as string}
            optionsEnum={AppStoreApp.TeammateAppAccessLevelEnum}
            tooltip={''}
          />
          <FormHelperText className={classes.helpdeskHelperText}>
            {translate('Different_access_levels_for_each_role') as string} {''}
            <ExternalLink href="https://support.flipdish.com/hc/en-us/articles/4403412059281-What-are-the-teammates-permissions-">
              <Translate id="helpdesk" />
            </ExternalLink>
          </FormHelperText>
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <TextInput
            fieldName="Name"
            label={translate('Basic') as string}
            placeholder={translate('Name') as string}
            helperText={translate('App_name_help') as string}
          />
          <TextInput
            fieldName="Description"
            placeholder={translate('Description') as string}
            multiline
            helperText={translate('Max_length_100') as string}
            maxCharLength
          />
          <TextInput fieldName="Details" placeholder={translate('Details') as string} multiline />
          <TextInput fieldName="DeveloperName" placeholder={translate('Developed_by') as string} />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          {renderLogoField()}
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectField
            fieldName="StoreSelectorType"
            label={translate('Store_selector_type') as string}
            placeholder={translate('Select_store_selector_type') as string}
            tooltip={translate('Store_selector_type_tooltip') as string}
            optionsEnum={AppStoreApp.StoreSelectorTypeEnum}
          />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectField
            fieldName="ConfigurationType"
            label={translate('Configuration_type') as string}
            placeholder={translate('Select_configuration_type') as string}
            tooltip={translate('Configuration_type_tooltip') as string}
            optionsEnum={AppStoreApp.ConfigurationTypeEnum}
          />
          {values.ConfigurationType === AppStoreApp.ConfigurationTypeEnum.ExternalLink && (
            <>
              <TextInput
                fieldName="SetupInstructions"
                placeholder={translate('Setup_instructions') as string}
                multiline
              />
              <TextInput
                fieldName="ExternalSetupLink"
                placeholder={translate('External_setup_link') as string}
                isUrl={true}
              />
            </>
          )}
          {values.ConfigurationType === AppStoreApp.ConfigurationTypeEnum.FlipdishHosted && (
            <>
              <FormItemLayout label={<Translate id="Configuration_fields" />}>
                <ConfigurationGroups
                  fieldName="FieldGroups"
                  values={values}
                  setFieldValue={setFieldValue}
                  setConfigurationGroupDialogOpen={setConfigurationGroupDialogOpen}
                  setConfigurationFieldDialogOpen={setConfigurationFieldDialogOpen}
                  translate={translate}
                  isSubmitting={isSubmitting}
                />

                <Button
                  variant="outlined"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => setConfigurationGroupDialogOpen(newFieldGroupPosition)}
                >
                  <Translate id="Add_a_configuration_group" />
                </Button>
              </FormItemLayout>
              {hasActionButton && (
                <>
                  <TextInput
                    fieldName="ExternalFunctionActionUrl"
                    label={translate('Action_button_configuration') as string}
                    placeholder={translate('External_function_url') as string}
                    isUrl
                  />
                  {values?.Id && (
                    <FormItemLayout label="">
                      {signingKey?.Data ? (
                        <TextField
                          value={signingKey.Data}
                          fdKey={`signingKeyField`}
                          disabled={true}
                          fullWidth
                          type="text"
                          label={translate('External_function_signature_key')}
                        />
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={isSubmitting || signingKeyLoading}
                          onClick={() => getSigningKey?.()}
                        >
                          {translate('Reveal_secret_key')}
                        </Button>
                      )}
                    </FormItemLayout>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectFieldBoolean
            fieldName="IsPaid"
            label={translate('AppStoreApp_IsPaid') as string}
            tooltip={translate('AppStoreApp_IsPaid_tooltip') as string}
            trueLabel={translate('AppStoreApp_IsPaid_Paid') as string}
            falseLabel={translate('AppStoreApp_IsPaid_Free') as string}
            optionValues={[
              { label: translate('AppStoreApp_IsPaid_Paid') as string, value: 'true' },
              { label: translate('AppStoreApp_IsPaid_Free') as string, value: 'false' },
            ]}
          />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectField
            fieldName="Categories"
            label={translate('Categories_type') as string}
            placeholder={translate('Choose_categories') as string}
            tooltip={translate('Choose_categories_tooltip') as string}
            optionsEnum={AppStoreApp.CategoriesEnum}
            isMulti
          />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <SelectField
            fieldName="Countries"
            label={translate('Supported_countries') as string}
            placeholder={translate('Choose_countries') as string}
            tooltip={translate('Supported_countries_tooltip') as string}
            optionsEnum={AppStoreApp.CountriesEnum}
            isMulti
          />
        </Grid>
        <GridDivider />
        <Grid item xs={12} className={classes.field}>
          <TextInput
            fieldName="Support.PrivacyPolicy"
            label={translate('Support') as string}
            placeholder={translate('Privacy_Policy') as string}
            isUrl
          />
          <TextInput
            fieldName="Support.TermsOfUse"
            placeholder={translate('Terms_of_Use') as string}
            isUrl
          />
          <TextInput
            fieldName="Support.CompanyWebsite"
            placeholder={translate('Company_Website') as string}
            isUrl
          />
          <TextInput
            fieldName="Support.LearnMore"
            placeholder={translate('Learn_more') as string}
            isUrl
          />
          <TextInput
            fieldName="Support.InstallationMessage"
            placeholder={translate('Installation_Message') as string}
          />
          <TextInput
            fieldName="Support.SupportEmail"
            placeholder={translate('Support_email_address') as string}
            isEmail
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Form>
        <FormikScrollToError />
        <PaperContainer fluid>
          <Grid container>{renderContent()}</Grid>
          <Permissions allowed={['Owner']}>
            <Grid
              container
              className={classes.actionsContainer}
              item
              xs={12}
              justifyContent="flex-end"
            >
              {Actions()}
            </Grid>
          </Permissions>
        </PaperContainer>
      </Form>

      {openFieldGroupPosition !== null && (
        <ConfigurationGroupDialog
          open={configurationGroupDialogOpen !== null}
          onClose={() => setConfigurationGroupDialogOpen(null)}
          translate={translate}
          onSave={(position, data) =>
            setFieldValue(
              `FieldGroups[${position}]`,
              Object.assign(data, {
                Fields:
                  values.FieldGroups && values.FieldGroups[position]
                    ? values.FieldGroups[position].Fields
                    : [],
              }),
              false
            )
          }
          appStoreApp={values}
          position={openFieldGroupPosition!}
          key={openFieldGroupPosition!}
          isNew={openFieldGroupPosition === newFieldGroupPosition}
        />
      )}
      {configurationFieldDialogOpen !== null && (
        <ConfigurationFieldDialog
          open={configurationFieldDialogOpen !== null}
          onClose={() => setConfigurationFieldDialogOpen(null)}
          translate={translate}
          onSave={(position, data) => {
            const fieldGroup = configurationFieldDialogOpen.fieldGroup;
            const fieldGroupIndex = values.FieldGroups.indexOf(fieldGroup);
            fieldGroup.Fields[position] = data;

            setFieldValue(`FieldGroups[${fieldGroupIndex}]`, fieldGroup, false);
          }}
          fieldGroup={configurationFieldDialogOpen!.fieldGroup}
          position={configurationFieldDialogOpen!.position}
          key={JSON.stringify(configurationFieldDialogOpen)}
          isNew={
            configurationFieldDialogOpen!.position ===
            configurationFieldDialogOpen!.fieldGroup.Fields.length
          }
        />
      )}
    </>
  );
};

const processForm = (appStoreApp, formValues) => {
  const data = Object.assign({}, formValues);
  delete data.Logo;
  if (data.FieldGroups) {
    data.FieldGroups.forEach((fieldGroup, groupIndex) => {
      fieldGroup.Position = groupIndex + 1;
      if (fieldGroup.Fields) {
        fieldGroup.Fields.forEach((field, fieldIndex) => {
          field.Position = fieldIndex + 1;
        });
      }
    });
  } else {
    data.FieldGroups = [];
  }

  if (appStoreApp) {
    data.Id = appStoreApp.Id.toString();
  }

  if (data.ConfigurationType === AppStoreApp.ConfigurationTypeEnum.FlipdishHosted) {
    data.SetupInstructions = null;
    data.ExternalSetupLink = null;
  }

  return data;
};

const processLogo = (logo) => {
  if (typeof logo === 'string') {
    return;
  }

  return logo;
};

export default withFormik<AppStoreAppFormProps, FormValues>({
  displayName: 'AppStoreAppForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.appStoreApp);
  },
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.onSaveChanges({
      updatedAppStoreApp: processForm(formikBag.props.appStoreApp || null, values),
      logo: processLogo(values.Logo),
    });
    formikBag.setSubmitting(false);
  },
})(AppStoreAppForm);
