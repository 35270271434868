import React, { useCallback } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { STORE_LOAD, STORE_LOAD_ALL } from '../../../constants/store.constants';
import { STORE_GROUP_GET } from '../../../constants/storegroup.constants';
import { getSelectedStore } from '../../../selectors/store.selector';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { Loader } from '../../common/Loader';
import { TutorialNotifier } from '../../Tutorial/Notifier';
import LinkItem from './components/LinkItem';

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  selectedApp: state.currentApp,
  store: getSelectedStore(state),
  loading:
    state.loading[STORE_GROUP_GET] || state.loading[STORE_LOAD_ALL] || state.loading[STORE_LOAD],
});

export default connect(mapStateToProps)(({ store, loading }: MapStateToProps) => {
  useLoadStoreFromSalesChannelIdIntoRedux(store);
  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/preordersettings', '');
    history.push(parent);
  }, []);
  const location = useLocation();
  return (
    <PageLayout
      strictToParent
      toParent={backToParent}
      caption={(store && store.Name) || ''}
      title={<Translate id="Pre_order_settings_title" />}
    >
      {loading ? (
        <Box my={2}>
          <Loader />
        </Box>
      ) : store ? (
        <PaperContainer fluid>
          <LinkItem
            disabled={!store.PreOrderPickupEnabled}
            fdKey="preorder-for-collection"
            url={`${location?.pathname}/collection`}
            title={'Preorder_for_collection'}
          />
          <LinkItem
            noDivider
            disabled={!store.PreOrderDeliveryEnabled}
            fdKey="preorder-for-delivery"
            url={`${location?.pathname}/delivery`}
            title={'Preorder_for_delivery'}
          />
          {store.StoreId && (
            <TutorialNotifier
              storeId={store.StoreId}
              onboardingItemId={602}
              status={'Completed' as OnboardingItemUpdate}
            />
          )}
        </PaperContainer>
      ) : null}
    </PageLayout>
  );
});
