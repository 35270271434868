import { isEmpty } from 'lodash';

export const ESAddressMapper = (storeAddress) => {
  if (storeAddress?.CountryCode !== 'ES') {
    return {
      street: '',
      number: '',
      floor: '',
      postalCode: '',
      province: '',
      city: '',
    };
  }

  if (isEmpty(storeAddress?.AddressFields)) {
    return {
      street: storeAddress?.Line1 || '',
      number: '',
      floor: '',
      postalCode: storeAddress?.Postcode || '',
      province: '',
      city: storeAddress?.City || '',
    };
  }
  return storeAddress.AddressFields;
};
