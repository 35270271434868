import React from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import ReportList from './ReportList';
import { Data } from './Reports';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

type Props = MappedState & RouteComponentProps;

const ReportRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { translate, currentApp } = props;
  const reportPath = `/${currentApp.AppId}/reports/rms`;

  return (
    <ErrorBoundary identifier="rms-reports">
      <Switch>
        {Data.flatMap((group) =>
          group.reports.map((report) => (
            <Route exact key={group.path} path={`/:appId/reports/rms${toKebabCase(report.path)}`}>
              <LazyComponent>
                <RMSPage
                  strictToParent={`${reportPath}${group.path}`}
                  toParent={`${reportPath}${group.path}`}
                  subscription={group.subscription}
                  title={translate(report.title)}
                  url={`reports${report.path}`}
                  module={`reports${report.path}`}
                  includePropertyIdInUrl
                />
              </LazyComponent>
            </Route>
          ))
        )}

        {Data.map((group) => (
          <Route key={group.path} path={`/:appId/reports/rms${group.path}`}>
            <LazyComponent>
              <ReportList reportGroup={group} />
            </LazyComponent>
          </Route>
        ))}
      </Switch>
    </ErrorBoundary>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
  };
};

export default connect(mapStateToProps)(ReportRoutes);
