import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import useContextualStyles from '../contextualStyle';

type GenericContextualButtonProps = {
  path: string;
  buttonText: string;
  useReactRouter?: boolean;
};

const GenericContextualButton = (props: GenericContextualButtonProps) => {
  const { path, buttonText, useReactRouter = true } = props;
  const classes = useContextualStyles();

  return (
    <div>
      <Link
        underline="none"
        data-fd="Generic_Contextual_Button"
        component={useReactRouter ? RouterLink : 'a'}
        href={!useReactRouter ? path : undefined}
        to={useReactRouter ? path : undefined}
        target={!useReactRouter ? '_blank' : ''}
        rel={!useReactRouter ? 'noopener noreferrer' : ''}
      >
        <Typography className={classes.Button} variant="caption">
          {buttonText}
        </Typography>
      </Link>
    </div>
  );
};

export default GenericContextualButton;
