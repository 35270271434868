import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import NewTabIcon from '@mui/icons-material/OpenInNewOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { setDrawerClosed, setDrawerOpen } from '../../actions/drawer.actions';
import { helpDrawerService } from './helpdrawer.service';
import { getLabel, sanitizeHTML } from './helpers';

const drawerWidth = 408;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      left: 'calc(100% - 408px) !important',
      top: '65px !important',
    },
  },
  drawer: {
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },

    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  drawerContentWrapper: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    height: '100vh',
    minHeight: '-webkit-fill-available',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 65px)',
      width: drawerWidth,
    },
  },
  headerContainer: {
    margin: '24px auto 20px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  header: {
    fontSize: 18,
    flexGrow: 1,
  },
  headerClose: {
    marginRight: 32,
    marginTop: -1,
    height: 30,
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    margin: '28px 0 11px 0',
  },
  defaultLanguageWarning: {
    backgroundColor: '#ffbc2c',
    fontSize: 16,
    padding: 12,
    borderRadius: theme.shape.borderRadius,
    margin: '24px 0',
  },
  externalLink: {
    fontSize: 13,
    textDecoration: 'none',
    marginBottom: 11,
    display: 'inline-block',
    color: theme.palette.primary.main,
  },
  iconLink: {
    fontSize: 14,
    marginLeft: 6,
    verticalAlign: 'middle',
  },
  content: {
    padding: '0 16px 16px 16px',
    flex: 1,
    overflowX: 'scroll',

    '& img, & iframe': {
      padding: '5px 0',
      maxWidth: '100%',
      objectFit: 'contain',
    },
  },
  drawerFooter: {
    padding: '12px 0 12px 0',
    textAlign: 'center',
  },
  drawerFooterButton: {
    width: 'calc(100% - 32px)',
  },
}));

const DEFAULT_LANGUAGE = 'en-us';

const languageFromLocale = (locale) => {
  const language = locale.activeLanguage.toLowerCase();
  if (language === 'en') {
    return DEFAULT_LANGUAGE;
  }
  if (language === 'es-mx') {
    return 'es';
  }
  return language;
};

type HelpDrawerProps = {
  label?: string;
  children?: React.ReactNode;
  externalIsOpen?: boolean;
  externalSetIsOpen?: (boolean) => void;
};

type Props = RouteComponentProps & MappedState & MappedDispatch & HelpDrawerProps;

const HelpDrawer = ({
  isHelpDrawerOpen,
  match,
  locale,
  label,
  children,
  externalIsOpen,
  externalSetIsOpen,
  setDrawer,
  setDrawerClose,
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const closeHelpDrawerWhenTutorialOpens = () => {
    if (!isHelpDrawerOpen && isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    closeHelpDrawerWhenTutorialOpens();
  }, [isHelpDrawerOpen]);

  const oneHour = 1000 * 60 * 60;

  const helpLabel = label || getLabel(match);

  const userLanguage = languageFromLocale(locale);
  const portalHomeUrl = `${helpDrawerService.baseURL}/hc/${userLanguage}`;

  const getArticles = async () => {
    const localizedRequest = await helpDrawerService.getHelpContent(helpLabel, userLanguage);
    if (localizedRequest && localizedRequest.articles && localizedRequest.articles.length) {
      return { response: localizedRequest, contentLanguage: userLanguage };
    }
    const englishRequest = await helpDrawerService.getHelpContent(helpLabel, DEFAULT_LANGUAGE);
    return { response: englishRequest, contentLanguage: DEFAULT_LANGUAGE };
  };

  const { data } = useQuery({
    queryKey: [helpDrawerService.getHelpContentKey, helpLabel, userLanguage],
    queryFn: () => getArticles(),
    staleTime: oneHour,
  });

  const onClose = () => {
    if (isOpen) {
      setDrawerClose();
      setIsOpen(false);
    }
    if (externalSetIsOpen !== undefined) {
      externalSetIsOpen(false);
    }
  };

  if (data && data.response && data.response.articles && data.response.articles.length) {
    const article = data.response.articles[0];
    const renderHelp = () => {
      return (
        <div className={classes.drawerContentWrapper}>
          <div className={classes.headerContainer}>
            <Typography variant="h5" className={classes.header}>
              <Translate id="Flipdish_support_guide" />
            </Typography>
            <IconButton
              aria-label="Back"
              className={classes.headerClose}
              data-fd="help-drawer-close-button"
              component={'button'}
              size="small"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />

          <div className={classes.content}>
            {userLanguage !== data.contentLanguage && data.contentLanguage === DEFAULT_LANGUAGE && (
              <>
                <div className={classes.defaultLanguageWarning}>
                  <Translate id="Helpdesk_drawer_default_language_warning" />
                </div>
                <Divider />
              </>
            )}
            <Typography variant="h5" className={classes.title}>
              {article.title}
            </Typography>
            <a
              className={classes.externalLink}
              href={article.html_url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={0}
            >
              <Translate id="Open_in_new_tab" />
              <NewTabIcon className={classes.iconLink} />
            </a>

            <div
              // dangerouslySetInnerHTML data is already sanitized at this point
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(article.body),
              }}
            />
          </div>
          <Divider />
          <div className={classes.drawerFooter}>
            <a href={portalHomeUrl} target="_blank" rel="noopener noreferrer">
              <Button
                data-fd="helpdesk-home-button"
                variant="contained"
                className={classes.drawerFooterButton}
                color="primary"
                tabIndex={0}
              >
                <Translate id="Helpdesk_home" />
              </Button>
            </a>
          </div>
        </div>
      );
    };

    return (
      <>
        {externalIsOpen === undefined && (
          <a
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setDrawer();
                setIsOpen(true);
              }
            }}
            onClick={() => {
              setIsOpen(true);
              setDrawer();
            }}
            tabIndex={0}
          >
            {children}
          </a>
        )}
        <Drawer
          disableEnforceFocus
          variant={'temporary'}
          anchor="right"
          classes={{
            root: classes.root,
            paper: clsx({
              [classes.drawer]: true,
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          PaperProps={{ elevation: isOpen || externalIsOpen ? 2 : 0 }}
          open={isOpen || externalIsOpen}
          onClose={() => onClose()}
          hideBackdrop={true}
        >
          {renderHelp()}
        </Drawer>
      </>
    );
  }

  return null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    locale: locale,
    isHelpDrawerOpen: state.drawer.helpDrawerOpen,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    setDrawer: () => dispatch(setDrawerOpen()),
    setDrawerClose: () => dispatch(setDrawerClosed()),
  };
}

export default compose<Props, HelpDrawerProps>(
  setDisplayName('HelpDrawer'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HelpDrawer);
