import { AppConfigSalesChannel, MobileAppsApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../../../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from '../../../services/utils/serverErrorMapper';

const api = createApi(MobileAppsApiFactory);

const getSalesChannelConfig = async (appId: string) => {
  try {
    const result = await api.getAppConfigSalesChannel(appId);
    return result;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

const updateSalesChannelConfig = async (
  appId: string,
  updatedConfig: Partial<AppConfigSalesChannel>
) => {
  try {
    await api.updateAppConfigSalesChannel(appId, updatedConfig);
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
};

export const mobileAppsService = {
  getSalesChannelConfig,
  updateSalesChannelConfig,
};
