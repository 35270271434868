import React from 'react';

import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router';

type Props = RouteComponentProps;

const OldWebsiteAndAppRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <Switch>
      <Redirect
        exact
        path={`${path}/live-editor`}
        to={`/:appId/sales-channels/website/live-editor`}
      />
      <Redirect
        exact
        path={`${path}/analytics-and-social`}
        to={`/:appId/sales-channels/website/analytics-and-social`}
      />
      <Redirect
        exact
        path={`${path}/domain-setup`}
        to={`/:appId/sales-channels/website/domain-setup`}
      />

      <Redirect
        exact
        path={`${path}/cookie-management`}
        to={`/:appId/sales-channels/website/cookie-management`}
      />

      <Redirect
        exact
        from={`${path}/settings/cookie-settings`}
        to={`/:appId/sales-channels/website/cookie-management`}
      />

      <Redirect
        exact
        from={`/:appId/website/domain-setup`}
        to={`/:appId/sales-channels/website/domain-setup`}
      />

      <Redirect exact from={`${path}/settings/cookie-settings`} to={`${path}/cookie-management`} />
      <Redirect exact from={`${path}/settings/general`} to={`/:appId/settings/general`} />
      <Redirect exact from={`${path}/settings`} to={`/:appId/settings/general`} />
    </Switch>
  );
};

export default withRouter(OldWebsiteAndAppRoutes);
