import React, { useEffect, useMemo, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { SelectAction } from '@fd/ui/Filter/StoreFilter';

import { notify, notifyError } from '../../../layouts/Notify/actions';
import StoreFilterInfiniteScroll from '../../../ui/Filter/StoreFilterInfiniteScroll';
import { kioskServices } from '../../Kiosks/Kiosks.services';
import { salesChannelsServices } from '../salesChannels.services';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}));

type InnerProps = MappedDispatch;
type OuterProps = {
  appId: string;
  appType?: TranslationId;
  description?: React.ReactNode;
  channelId: number;
  showTitle?: boolean;
  isDisabled?: boolean;
  translate: TranslateFunction;
};
type Props = InnerProps & OuterProps;

const AssignStoresVisibility = (props: Props) => {
  const {
    appId,
    appType,
    channelId,
    description,
    isDisabled = false,
    notifySaved,
    notifyError,
    showTitle = true,
    translate,
  } = props;
  const [selectAction, setSelectAction] = useState<SelectAction>();
  const classes = useStyles();

  const { data: getStoreHeaders, isError } = useQuery({
    queryKey: ['kioskServices.getStoresByAppId', appId],
    queryFn: () => kioskServices.getStoreHeaders(appId),
    enabled: !!appId,
  });
  useEffect(() => {
    if (isError) {
      notifyError();
    }
  }, [isError]);

  const {
    data: getAssignedStores,
    isPending,
    isError: isErrorChannels,
  } = useQuery({
    queryKey: ['salesChannelsServices.getStores', appId, channelId],
    queryFn: () => salesChannelsServices.getStores(appId, channelId),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isErrorChannels) {
      notifyError();
    }
  }, [isErrorChannels]);

  const [getAlreadyAssignedStores, setGetAlreadyAssignedStores] = useState(getAssignedStores || []);

  const stores = useMemo(() => {
    if (getStoreHeaders && getAlreadyAssignedStores) {
      const unassignedStores = getStoreHeaders
        .filter((all) => !getAlreadyAssignedStores.some((assigned) => assigned === all.StoreId))
        .map((s) => {
          return {
            label: s.Name!,
            value: s.StoreId?.toString()!,
          };
        });

      const assignedStores = getAlreadyAssignedStores.map((s) => {
        return { label: s.Name!, value: s.StoreId?.toString()! };
      });
      return { assignedStores, unassignedStores };
    }
  }, [getStoreHeaders, getAlreadyAssignedStores]);

  const assignStoresToAppType = useMutation({
    mutationFn: ({ storeId }: { storeId: number }) =>
      salesChannelsServices.assignStoresToAppType(appId, channelId, storeId),

    onSuccess: () => {
      notifySaved('Kiosk_successfully_assigned_store');
    },

    onError: () => {
      notifyError();
    },
  });

  const unassignStoreFromAppType = useMutation({
    mutationFn: ({ storeId }: { storeId: number }) =>
      salesChannelsServices.deleteStoreFromAppType(appId, channelId, storeId),

    onSuccess: () => {
      notifySaved('Kiosk_successfully_unassigned_store');
    },

    onError: () => {
      notifyError();
    },
  });

  const unassignAllStoresFromAppType = useMutation({
    mutationFn: () => salesChannelsServices.detachAllStoresFromSalesChannel(appId, channelId),

    onSuccess: () => {
      notifySaved('Successfully_assigned_all_stores');
    },

    onError: () => {
      notifyError();
    },
  });

  useEffect(() => {
    if (getAssignedStores) {
      setGetAlreadyAssignedStores(getAssignedStores);
    }
  }, [getAssignedStores, channelId]);

  useEffect(() => {
    if (selectAction) {
      const latestValue =
        selectAction.values && selectAction.values[selectAction.values.length - 1];
      switch (selectAction.action) {
        case 'select-option':
          if (latestValue && latestValue.value === 'all') {
            unassignAllStoresFromAppType.mutateAsync();
          } else {
            assignStoresToAppType.mutateAsync({
              storeId: Number(selectAction.option?.value),
            });
          }
          break;
        case 'remove-value':
          if (latestValue && latestValue.value === 'all') {
            unassignAllStoresFromAppType.mutateAsync();
          } else {
            selectAction.values?.length
              ? unassignStoreFromAppType.mutateAsync({
                  storeId: Number(selectAction.removedValue?.value),
                })
              : notifyError('You_should_have_at_least_one_store_assigned');
          }
          break;
        default:
          break;
      }
    }
  }, [selectAction]);

  return (
    <>
      {showTitle && (
        <Typography variant="subtitle1">
          {appType ? (
            <>
              <Translate id={appType} /> {lowerCase(translate('Stores_visibility') as string)}
            </>
          ) : (
            <Translate id={'Stores_visibility'} />
          )}
        </Typography>
      )}
      <Typography variant="caption" component="p">
        {description}
      </Typography>
      <div className={classes.container}>
        <StoreFilterInfiniteScroll
          label={'Select_store(s)'}
          customStoreOptions={stores?.unassignedStores}
          doNotAllowLastChipToBeRemoved
          isClearable={false}
          isMulti
          isCustomLoading={isPending}
          isDisabled={isDisabled}
          showAllStoresAndLater
          preSelectedStores={stores?.assignedStores}
          hasAllStoresOption
          placeholder={stores?.assignedStores.length === 0 ? 'All_stores_later' : 'Stores'}
          setSelectAction={setSelectAction}
          useCustomStoreOptions
          useLoadingSkeleton
          variant="standard"
          zIndex={9}
        />
      </div>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (message?: TranslationId) =>
    dispatch(
      notifyError({
        message: message || 'Error_please_try_again_later',
        translate: true,
      })
    ),
  notifySaved: (message: TranslationId) => {
    dispatch(
      notify({
        message,
        variant: 'success',
        translate: true,
      })
    );
  },
});

export default connect(null, mapDispatchToProps)(AssignStoresVisibility);
