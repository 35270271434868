import * as React from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { compose } from 'recompose';

const styles = ({ spacing }: Theme) =>
  createStyles({
    select: {
      padding: '20px 16px 0 16px',
      margin: '0 -4px',
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
    input: {
      display: 'flex',
      padding: '8px 0 8px 8px',
      '& >div:nth-child(2) span': {
        display: 'none',
      },
    },
    noOptionsMessage: {
      padding: `${spacing(1)} ${spacing(2)}`,
      fontSize: '16px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    arrowDropDownIcon: {
      color: 'rgba(0,0,0,0.54)',
      cursor: 'pointer',
    },
    menuPaper: {
      position: 'absolute' as any,
      zIndex: 1,
      marginTop: spacing(1),
      left: 0,
      right: 0,
    },
  });

function DropdownIndicator(props) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDown className={props.selectProps.classes.arrowDropDownIcon} />
      </components.DropdownIndicator>
    )
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menuPaper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-fd={`checkpoint-${props.data.value}`}
    >
      {props.children}
    </MenuItem>
  );
}

const customComponents = {
  Control,
  DropdownIndicator,
  Menu,
  NoOptionsMessage,
  Option,
};

class MenuCheckpointsSelect extends React.Component<any, any> {
  public constructor(props) {
    super(props);

    this.state = {
      selectedCheckpoint: null,
    };
  }

  public componentDidMount() {
    const { selectedCheckpoint, menuCheckpoints, translate } = this.props;

    if (selectedCheckpoint && menuCheckpoints.length > 0) {
      const isFirst = menuCheckpoints[0].MenuCheckpointId === selectedCheckpoint.MenuCheckpointId;

      this.setState({
        selectedCheckpoint: {
          value: selectedCheckpoint.MenuCheckpointId,
          label: `${moment(selectedCheckpoint.Time).format('MM/DD/YYYY hh:mm')}${
            isFirst ? ` (${translate('Current')})` : ''
          }`,
        },
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps) {
    const { selectedCheckpoint, menuCheckpoints, translate } = newProps;

    if (selectedCheckpoint != this.props.selectedCheckpoint && menuCheckpoints.length > 0) {
      const isFirst = menuCheckpoints[0].MenuCheckpointId === selectedCheckpoint.MenuCheckpointId;

      this.setState({
        selectedCheckpoint: {
          value: selectedCheckpoint.MenuCheckpointId,
          label: `${moment(selectedCheckpoint.Time).format('MM/DD/YYYY hh:mm')}${
            isFirst ? ` (${translate('Current')})` : ''
          }`,
        },
      });
    }
  }

  public handleCheckpointChange = (checkpoint) => {
    this.props.handleCheckpointChange(checkpoint.value);
  };

  public render() {
    const { theme, classes, menuCheckpoints, translate } = this.props;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const suggestions = menuCheckpoints.map((checkpoint, index) => {
      return {
        value: checkpoint.MenuCheckpointId,
        label: `${moment(checkpoint.Time).format('MM/DD/YYYY hh:mm')}${
          index === 0 ? ` (${translate('Current')})` : ''
        }`,
      };
    });

    return (
      <Select
        className={classes.select}
        classes={classes}
        textFieldProps={{
          variant: 'outlined',
        }}
        styles={selectStyles}
        components={customComponents}
        value={this.state.selectedCheckpoint}
        options={suggestions}
        onChange={this.handleCheckpointChange}
      />
    );
  }
}

function mapStateToProps(state: AppState) {
  const { locale, menus } = state;

  return {
    translate: getTranslate(locale),
    menuCheckpoints: menus.menuCheckpoints,
  };
}

const EnhancedComponent = compose<any, any>(
  withStyles(styles, {
    name: 'MenuCheckpointsSelect',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(MenuCheckpointsSelect);

export { EnhancedComponent as MenuCheckpointsSelect };
