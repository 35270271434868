import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import moment from 'moment';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { getCellBackgroundColor } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '95vW',
    marginTop: '16px',
    padding: '0px 16px',
    [theme.breakpoints.down('md')]: {
      marginTop: '8px',
      padding: '0px 8px',
      width: '100vW',
    },
  },
  chartTitle: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    textAlign: 'center' as any,
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
  },
  time: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    textAlign: 'center' as any,
    [theme.breakpoints.down('md')]: {
      fontSize: '8px',
    },
  },
  cell: {
    height: '36px',
    [theme.breakpoints.down('md')]: {
      height: '24px',
    },
  },
  flexCell: {
    flexGrow: 1,
    flexBasis: 0,
  },
  rightCell: {
    margin: '0px 8px',
    padding: '8px 0px 8px 8px',
    width: '85px',
    textAlign: 'right' as any,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '24px',
      margin: '0px',
    },
  },
  divider: {
    borderTop: 'solid 1px rgba(0,0,0,0.2)',
  },
  legend: {
    padding: '16px 101px 16px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px 0px 0px',
    },
  },
  legendRight: {
    height: '12px',
    backgroundImage: 'linear-gradient(to right, #86aaf2, #7d67d0)',
  },
  legendLeft: {
    height: '12px',
    backgroundImage: 'linear-gradient(to right, #d6e7fd, #86aaf2)',
  },
  legendLabels: {
    marginTop: '4px',
  },
  leftAlign: {
    textAlign: 'left' as any,
  },
  rightAlign: {
    textAlign: 'right' as any,
  },
  popup: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  popupTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  gridRow: {
    padding: '12px 24px',
  },
}));

const useStylesArrow = makeStyles(() => ({
  tooltip: {
    position: 'relative',
    background: '#fff !important',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
  },
  arrow: {
    marginBottom: '-1em',
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    marginBottom: '-1.75em',
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#ffffff transparent transparent transparent`,
      },
    },
  },
}));

const getMockedData = (periodStart) => {
  return Array.from(Array(168).keys()).map((val, idx) => {
    return {
      Date: moment(periodStart).add(idx, 'h').format('YYYY-MM-DDTHH:mm:ss'),
      Count: Math.round(Math.random() * 20),
    };
  });
};

const timeRow = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

type Props = {
  translate: (text: string) => string;
};

const data = getMockedData(moment().startOf('week'));

const BusyTimesHeatmap = (props: Props) => {
  const { translate } = props;
  const styles = useStyles();
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = useState(null);

  const days: string[] = [];
  for (let d = 0; d < 7; d++) {
    days.push(moment(data[0].Date).add(d, 'd').format('dddd'));
  }

  const max = Math.max(data.reduce((max, p) => (p.Count > max ? p.Count : max), 0));

  const busiestDate = moment(data.reduce((max, p) => (p.Count > max.Count ? p : max)).Date).format(
    'dddd, Do MMMM'
  );

  const quietestDate = moment(data.reduce((min, p) => (p.Count < min.Count ? p : min)).Date).format(
    'dddd, Do MMMM'
  );

  const renderTitle = (i, j) => {
    return (
      <div>
        <Typography className={clsx(styles.popupTitle, styles.popup)}>
          {data[24 * i + j].Count === 0 || data[24 * i + j].Count === 1
            ? `${data[24 * i + j].Count} ${translate('Order')}`
            : `${data[24 * i + j].Count} ${translate('Orders')}`}
        </Typography>
        <Typography className={styles.popup}>
          {moment(data[24 * i + j].Date).format('dddd HH:mm')}
        </Typography>
        <Typography className={styles.popup}>3 stores closed</Typography>
        <Typography className={styles.popup}>2 stores open</Typography>
      </div>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6} className={styles.gridRow}>
        <Typography variant="caption">
          <Translate id={'Busiest_date'} />
        </Typography>
        <Typography variant="h5">{busiestDate}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={styles.gridRow}>
        <Typography variant="caption">
          <Translate id={'Busiest_time_on_average_across_stores'} />
        </Typography>
        <Typography variant="h5">6pm - 7pm, 44 orders</Typography>
      </Grid>

      <Grid item xs={12} sm={6} className={styles.gridRow}>
        <Typography variant="caption">
          <Translate id={'Quietest_day'} />
        </Typography>
        <Typography variant="h5">{quietestDate}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={styles.gridRow}>
        <Typography variant="caption">
          <Translate id={'Quietest_time_on_average_across_stores'} />
        </Typography>
        <Typography variant="h5">3pm - 4pm, 12 orders</Typography>
      </Grid>
      <div className={styles.root}>
        <div>
          <Typography className={styles.chartTitle}>
            <Translate id={'Busy_and_quiet_times'} />
          </Typography>
          {days.map((day, i) => {
            return (
              <div className={styles.row} key={day}>
                {timeRow.map((time, j) => {
                  return (
                    <Tooltip
                      key={j}
                      enterTouchDelay={10}
                      leaveTouchDelay={500}
                      placement="top"
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: 'arrow',
                              options: {
                                enabled: Boolean(arrowRef),
                                element: arrowRef,
                              },
                            },
                          ],
                        },
                      }}
                      title={
                        <React.Fragment>
                          {renderTitle(i, j)}
                          <span className={arrow} ref={setArrowRef as any} />
                        </React.Fragment>
                      }
                      classes={classes}
                    >
                      <div
                        className={clsx(styles.cell, styles.flexCell)}
                        style={{
                          background: getCellBackgroundColor(data[24 * i + j].Count, max),
                        }}
                      />
                    </Tooltip>
                  );
                })}
                <Typography
                  variant="h6"
                  className={clsx(styles.label, styles.cell, styles.rightCell, styles.divider)}
                >
                  {day.slice(0, 3)}
                </Typography>
              </div>
            );
          })}
          <div className={styles.row}>
            <Hidden mdDown>
              {timeRow.map((time) => (
                <Typography variant="h6" key={time} className={clsx(styles.time, styles.flexCell)}>
                  {time}
                </Typography>
              ))}
            </Hidden>
            <Hidden mdUp>
              <Typography
                variant="h6"
                className={clsx(styles.label, styles.flexCell, styles.leftAlign)}
              >
                0
              </Typography>
              <Typography variant="h6" className={clsx(styles.label, styles.flexCell)}>
                12
              </Typography>
              <Typography
                variant="h6"
                className={clsx(styles.label, styles.flexCell, styles.rightAlign)}
              >
                23
              </Typography>
            </Hidden>
            <div className={styles.rightCell} />
          </div>
          <div className={styles.legend}>
            <div className={styles.row}>
              <div className={clsx(styles.flexCell, styles.legendLeft)} />
              <div className={clsx(styles.flexCell, styles.legendRight)} />
            </div>
            <div className={clsx(styles.row, styles.legendLabels)}>
              <Typography
                variant="h6"
                className={clsx(styles.label, styles.flexCell, styles.leftAlign)}
              >
                0 {translate('Order')}
              </Typography>
              <Typography
                variant="h6"
                className={clsx(styles.label, styles.flexCell, styles.rightAlign)}
              >
                {Math.ceil(max) === 1
                  ? `${Math.ceil(max)} ${translate('Order')}`
                  : `${Math.ceil(max)} ${translate('Orders')}`}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(BusyTimesHeatmap);
