import React from 'react';

import MaterialTablePagination, { type TablePaginationProps } from '@mui/material/TablePagination';

import { TablePaginationActions } from '../../../components/common/TablePagination/TablePaginationActions';

export const Pagination = (props: TablePaginationProps) => {
  return (
    <MaterialTablePagination
      component="div"
      labelRowsPerPage=""
      rowsPerPageOptions={[]}
      // @ts-ignore
      ActionsComponent={TablePaginationActions}
      {...props}
    />
  );
};
