import {
  Order,
  OrderDeliveryInformationBase,
  OrderFulfillmentStatusWithConfigurationActions,
  OrderSummary,
  Reject,
} from '@flipdish/api-client-typescript';

import { ordersConstants } from '../constants/order.constants';
import {
  closeAllOtherNotifications,
  notify,
  notifyError,
  SAVED_KEY,
  SAVING_KEY,
} from '../layouts/Notify/actions';
import * as orderService from '../services/order.service';
import { orderFulfillmentService } from '../services/order.service';
import { storeActions } from './store.actions';
import { action, actionError } from './utils';

// #region set order search ID results
export const ordersAction = { setOrderSearchIDResults };
const { SET_ORDER_SEARCH_CODE_RESULTS } = ordersConstants;

function setOrderSearchIDResults(appId, codes) {
  const query = Number(codes.join());
  return async (dispatch: ThunkDispatch) => {
    try {
      const order = await orderService.getOrderById(query);
      dispatch(success(order.OrderId));
    } catch (error) {
      return; // server returns 500
    }
  };

  function success(response) {
    return {
      type: SET_ORDER_SEARCH_CODE_RESULTS,
      payload: response,
    };
  }
}
// #endregion

// #region getOrdersSummary
const { GET_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE } = ordersConstants;

type GetOrderSummaryProps = orderService.GetOrdersSummary & {
  concat?: boolean;
};

export type GetOrdersSummaryRequest = ReturnType<typeof getOrdersSummaryRequest>;
export const getOrdersSummaryRequest = (props: GetOrderSummaryProps) =>
  action(GET_ORDERS_REQUEST, props);

export type GetOrdersSummarySuccess = ReturnType<typeof getOrdersSummarySuccess>;
export const getOrdersSummarySuccess = (
  orders: OrderSummary[],
  totalCount: number,
  page: number,
  limit: number,
  props: GetOrderSummaryProps
) => action(GET_ORDERS_SUCCESS, { orders, totalCount, page, limit }, props);

export type GetOrdersSummaryFailure = ReturnType<typeof getOrdersSummaryFailure>;
export const getOrdersSummaryFailure = (error: Error, props: GetOrderSummaryProps) =>
  actionError(GET_ORDERS_FAILURE, props, error);

type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];

type OrderStatus = Array<
  | 'Created'
  | 'ReadyToProcess'
  | 'AcceptedByRestaurant'
  | 'Cancelled'
  | 'RejectedByStore'
  | 'RejectedByFlipdish'
  | 'RejectedAutomatically'
  | 'RejectedAfterBeingAccepted'
  | 'Dispatched'
>;
type OrderStatesType = Array<
  'Created' | 'ReadyToProcess' | 'AcceptedByRestaurant' | 'Cancelled' | 'Rejected'
>;

const rejectedStatuses: OrderStatus = [
  'RejectedByStore',
  'RejectedByFlipdish',
  'RejectedAutomatically',
  'RejectedAfterBeingAccepted',
];

const acceptedStatuses: OrderStatus = ['AcceptedByRestaurant', 'Dispatched'];

const matchExtraStatus = (states: OrderStatesType) => {
  let orderStates: OrderStatus = [];
  states.forEach((state) => {
    if (state != 'Rejected') {
      if (state === 'AcceptedByRestaurant') {
        orderStates = [...orderStates, ...acceptedStatuses];
      } else {
        orderStates.push(state);
      }
    } else {
      orderStates = [...orderStates, ...rejectedStatuses];
    }
  });
  return orderStates;
};

const createChannelsEnumList = (channels: string[]) => {
  const channelsEnumList: AppTypeEnum[] = [];
  channels.forEach((x) => channelsEnumList.push(OrderSummary.AppTypeEnum[x]));
  return channelsEnumList;
};

export function getOrdersSummary({ concat, ...props }: GetOrderSummaryProps) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getOrdersSummaryRequest({ concat, ...props }));
      const state = getState();
      const response = await orderService.getOrdersSummary({
        physicalRestaurantId: state.OrdersFilter.stores,
        channels: createChannelsEnumList(state.OrdersFilter.channels),
        orderStates: matchExtraStatus(state.OrdersFilter.states),
        ...props,
      });
      dispatch(
        getOrdersSummarySuccess(
          response.Data,
          response.TotalRecordCount,
          response.Page,
          response.Limit,
          { concat, ...props }
        )
      );
    } catch (error) {
      dispatch(getOrdersSummaryFailure(error, { concat, ...props }));
    }
  };
}
// #endregion

// #region getOrderById
const { GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE } = ordersConstants;

export type GetOrderRequest = ReturnType<typeof getOrderByIdRequest>;
export const getOrderByIdRequest = (orderId: number) => action(GET_ORDER_REQUEST, orderId);

export type GetOrderSuccess = ReturnType<typeof getOrderByIdSuccess>;
export const getOrderByIdSuccess = (order: Order, orderId: number) =>
  action(GET_ORDER_SUCCESS, order, orderId);

export type GetOrderFailure = ReturnType<typeof getOrderByIdFailure>;
export const getOrderByIdFailure = (error: Error, orderId: number) =>
  actionError(GET_ORDER_FAILURE, orderId, error);

export function getOrderById(orderId: number, loadDependencies?: boolean) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getOrderByIdRequest(orderId));

      const order = await orderService.getOrderById(orderId);
      dispatch(getOrderByIdSuccess(order, orderId));

      try {
        const storeId = order.Store!.Id;
        if (loadDependencies && storeId && !getState().orm.FlipdishStore.itemsById[storeId]) {
          dispatch(storeActions.load(storeId));
        }
      } catch (error) {}
    } catch (error) {
      dispatch(getOrderByIdFailure(error, orderId));
    }
  };
}
// #endregion

// #region getLiveViewOrderById
const { GET_LIVE_VIEW_ORDER_SUCCESS, GET_LIVE_VIEW_ORDER_FAILURE } = ordersConstants;

export type GetLiveViewOrderSuccess = ReturnType<typeof getLiveViewOrderByIdSuccess>;
export const getLiveViewOrderByIdSuccess = (order: OrderSummary, orderId: number) =>
  action(GET_LIVE_VIEW_ORDER_SUCCESS, order, orderId);

export type GetLiveViewOrderFailure = ReturnType<typeof getLiveViewOrderByIdFailure>;
export const getLiveViewOrderByIdFailure = (error: Error, orderId: number) =>
  actionError(GET_LIVE_VIEW_ORDER_FAILURE, orderId, error);

export function getLiveViewOrderById(orderSummary: OrderSummary) {
  return async (dispatch: ThunkDispatch) => {
    if (orderSummary.OrderId) {
      try {
        dispatch(getLiveViewOrderByIdSuccess(orderSummary, orderSummary.OrderId));

        const order = await orderService.getOrderById(orderSummary.OrderId);
        dispatch(getOrderByIdSuccess(order, orderSummary.OrderId));
      } catch (error) {
        dispatch(getLiveViewOrderByIdFailure(error, orderSummary.OrderId));
      }
    }
  };
}
// #endregion

// #region acceptOrder
const { ACCEPT_ORDER_REQUEST, ACCEPT_ORDER_SUCCESS, ACCEPT_ORDER_FAILURE } = ordersConstants;

export type AcceptOrderRequest = ReturnType<typeof acceptOrderRequest>;
export const acceptOrderRequest = () => action(ACCEPT_ORDER_REQUEST, {});

export type AcceptOrderSuccess = ReturnType<typeof acceptOrderSuccess>;
export const acceptOrderSuccess = (orderId: number) => action(ACCEPT_ORDER_SUCCESS, { orderId });

export type AcceptOrderFailure = ReturnType<typeof acceptOrderFailure>;
export const acceptOrderFailure = (error: Error) => actionError(ACCEPT_ORDER_FAILURE, {}, error);

export function acceptOrder(estimatedMinutesForDelivery?: number, id?: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(
        notify({
          message: 'Accepting',
          translate: true,
          variant: 'default',
        })
      );
      dispatch(acceptOrderRequest());
      const orderId = id !== undefined ? id : getState().orders.order.OrderId;
      if (orderId) {
        await orderService.acceptOrder({ orderId, estimatedMinutesForDelivery });
        dispatch(closeAllOtherNotifications(SAVED_KEY));
        dispatch(acceptOrderSuccess(orderId));
        dispatch(
          notify({
            message: 'Order-accepted',
            translate: true,
            variant: 'success',
          })
        );
      } else {
        dispatch(acceptOrderFailure(new Error('OrderId is undefined')));
        dispatch(
          notify({
            message: 'Order-accepted-failed',
            translate: true,
            variant: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(acceptOrderFailure(error));
      dispatch(notifyError({ message: error.message }));
    }
  };
}
// #endregion

// #region rejectOrder
const { REJECT_ORDER_REQUEST, REJECT_ORDER_SUCCESS, REJECT_ORDER_FAILURE } = ordersConstants;

export type RejectOrderRequest = ReturnType<typeof rejectOrderRequest>;
export const rejectOrderRequest = () => action(REJECT_ORDER_REQUEST, {});

export type RejectOrderSuccess = ReturnType<typeof rejectOrderSuccess>;
export const rejectOrderSuccess = (orderId: number) => action(REJECT_ORDER_SUCCESS, { orderId });

export type RejectOrderFailure = ReturnType<typeof rejectOrderFailure>;
export const rejectOrderFailure = (error: Error) => actionError(REJECT_ORDER_FAILURE, {}, error);

export function rejectOrder(
  rejectReason: Reject.RejectReasonEnum,
  id?: number,
  notifyCustomerRefund?: boolean
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(
        notify({
          message: 'Rejecting',
          translate: true,
          variant: 'default',
        })
      );
      dispatch(rejectOrderRequest());
      const orderId = id !== undefined ? id : getState().orders.order.OrderId;
      const doNotNotifyCustomer =
        typeof notifyCustomerRefund === 'boolean' ? !notifyCustomerRefund : false;
      if (orderId) {
        await orderService.rejectOrder({ orderId, rejectReason, doNotNotifyCustomer });
        dispatch(closeAllOtherNotifications(SAVED_KEY));
        dispatch(rejectOrderSuccess(orderId));
        dispatch(
          notify({
            message: 'Order-rejected',
            translate: true,
            variant: 'success',
          })
        );
      } else {
        dispatch(rejectOrderFailure(new Error('OrderId is undefined')));
        dispatch(
          notify({
            message: 'Order-rejected-failed',
            translate: true,
            variant: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(rejectOrderFailure(error));
      dispatch(notifyError({ message: error.message }));
    }
  };
}
// #endregion

// #region refundOrder
const { REFUND_ORDER_REQUEST, REFUND_ORDER_SUCCESS, REFUND_ORDER_FAILURE } = ordersConstants;

export type RefundOrderRequest = ReturnType<typeof refundOrderRequest>;
export const refundOrderRequest = () => action(REFUND_ORDER_REQUEST, {});

export type RefundOrderSuccess = ReturnType<typeof refundOrderSuccess>;
export const refundOrderSuccess = (refundAmount: number) =>
  action(REFUND_ORDER_SUCCESS, refundAmount);

export type RefundOrderFailure = ReturnType<typeof refundOrderFailure>;
export const refundOrderFailure = (error: Error) => actionError(REFUND_ORDER_FAILURE, {}, error);

export function refundOrder(refundAmount: number, notifyCustomerRefund: boolean) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(
        notify({
          message: 'Refunding',
          translate: true,
          variant: 'default',
        })
      );
      dispatch(refundOrderRequest());
      const orderId = getState().orders.order.OrderId;
      if (orderId) {
        await orderService.refundOrder({
          orderId,
          refundAmount,
          notifyCustomer: notifyCustomerRefund,
        });
        dispatch(closeAllOtherNotifications(SAVED_KEY));
        dispatch(refundOrderSuccess(refundAmount));
        dispatch(
          notify({
            message: 'Order-refunded',
            translate: true,
            variant: 'success',
          })
        );
      } else {
        dispatch(refundOrderFailure(new Error('OrderId is undefined')));
        dispatch(
          notify({
            message: 'Order-refunded-failed',
            translate: true,
            variant: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(refundOrderFailure(error));
      dispatch(notifyError({ message: error.message }));
    }
  };
}
// #endregion

// #region getOrderById
const {
  GET_ORDER_DELIVERY_INFO_REQUEST,
  GET_ORDER_DELIVERY_INFO_SUCCESS,
  GET_ORDER_DELIVERY_INFO_FAILURE,
} = ordersConstants;

export type GetDeliveryInfoForOrderByIdRequest = ReturnType<
  typeof getDeliveryInfoForOrderByIdRequest
>;
export const getDeliveryInfoForOrderByIdRequest = () => action(GET_ORDER_DELIVERY_INFO_REQUEST);

export type GetDeliveryInfoForOrderByIdSuccess = ReturnType<
  typeof getDeliveryInfoForOrderByIdSuccess
>;
export const getDeliveryInfoForOrderByIdSuccess = (deliveryInfo: OrderDeliveryInformationBase) =>
  action(GET_ORDER_DELIVERY_INFO_SUCCESS, deliveryInfo);

export type GetDeliveryInfoForOrderByIdFailure = ReturnType<
  typeof getDeliveryInfoForOrderByIdFailure
>;
export const getDeliveryInfoForOrderByIdFailure = () =>
  actionError(GET_ORDER_DELIVERY_INFO_FAILURE, null, null);

export function getDeliveryInfoForOrderById(orderId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    if (getState().orders.orderDeliveryInfoLoading) return;

    try {
      dispatch(getDeliveryInfoForOrderByIdRequest());

      const deliveryInfo = await orderService.getOrderDeliveryInfo(orderId);
      dispatch(getDeliveryInfoForOrderByIdSuccess(deliveryInfo));
    } catch (error) {
      dispatch(getDeliveryInfoForOrderByIdFailure());
    }
  };
}
// #endregion

// #region getOrderFulfilmmentStatus
const {
  GET_ORDER_FULFILLMENT_STATUS_REQUEST,
  GET_ORDER_FULFILLMENT_STATUS_SUCCESS,
  GET_ORDER_FULFILLMENT_STATUS_FAILURE,
} = ordersConstants;

export type GetFulfillmentStatusForOrderByIdRequest = ReturnType<
  typeof getFulfillmentStatusForOrderByIdRequest
>;
export const getFulfillmentStatusForOrderByIdRequest = () =>
  action(GET_ORDER_FULFILLMENT_STATUS_REQUEST);

export type GetFulfillmentStatusForOrderByIdSuccess = ReturnType<
  typeof getFulfillmentStatusForOrderByIdSuccess
>;
export const getFulfillmentStatusForOrderByIdSuccess = (
  fulfillmentStatus: OrderFulfillmentStatusWithConfigurationActions
) => action(GET_ORDER_FULFILLMENT_STATUS_SUCCESS, fulfillmentStatus);

export type GetFulfillmentStatusForOrderByIdFailure = ReturnType<
  typeof getFulfillmentStatusForOrderByIdFailure
>;
export const getFulfillmentStatusForOrderByIdFailure = () =>
  actionError(GET_ORDER_FULFILLMENT_STATUS_FAILURE, null, null);

export function getFulfillmentStatusForOrderById(orderId: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    if (getState().orders.orderFulfillmentStatusLoading) return;

    try {
      dispatch(getFulfillmentStatusForOrderByIdRequest());

      const fulfillmentStatus = await orderFulfillmentService.getOrderFulfillmentState(orderId);
      dispatch(getFulfillmentStatusForOrderByIdSuccess(fulfillmentStatus));
    } catch (error) {
      dispatch(getFulfillmentStatusForOrderByIdFailure());
    }
  };
}
// #endregion

export const resetOrderState = () => action(ordersConstants.CLEAR_ORDER);
