import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import ReactSwipe from 'react-swipe';
import { compose } from 'recompose';

import { Button } from './Button/Button';
import PageLayout from './Layout';

const useStyles = makeStyles((theme: Theme) => ({
  pageLayout: {
    position: 'relative',
  },
  cardRoot: {
    width: 510,
    position: 'absolute',
    transform: 'translate(-50%)',
    top: 121,
    left: '55.5%',
    zIndex: 1300,
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      width: 280,
      top: '16%',
      left: '50%',
    },
  },
  cardMediaRoot: {
    margin: '0 auto',
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      objectFit: 'contain',
      width: 233,
      height: 'auto',
      marginBottom: 22,
    },
  },
  btn: {
    width: 174,
    height: 36,
  },
  dots: {
    width: 8,
    height: 8,
    margin: '0 2px',
    borderRadius: '50%',
  },
}));

const renderDots = (activeText: boolean, style: string) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 24,
      }}
    >
      <div style={{ backgroundColor: activeText ? '#05149e' : '#bfd9fc' }} className={style} />
      <div style={{ backgroundColor: activeText ? '#bfd9fc' : '#05149e' }} className={style} />
    </div>
  );
};

type OuterProps = {
  btnText: TranslationId;
  cardImage: string;
  cardText: TranslationId[];
  helpdeskLink: string;
  placeholderImage: string;
  title: TranslationId;
};
type InnerProps = MappedState;
type Props = InnerProps & OuterProps;
const PlaceholderPage = ({
  btnText,
  cardImage,
  cardText,
  helpdeskLink,
  placeholderImage,
  title,
  translate,
}: Props) => {
  const classes = useStyles();
  const [activeText, setActiveText] = useState<boolean>(true);
  return (
    <PageLayout
      title={<Translate id={title} />}
      documentTitle={title}
      className={classes.pageLayout}
    >
      <div>
        <img src={placeholderImage} width="100%" alt={translate(title) as string} />

        <Card classes={{ root: classes.cardRoot }}>
          <CardMedia
            component="img"
            alt={translate(title) as string}
            image={cardImage}
            classes={{ root: classes.cardMediaRoot }}
          />
          {/* MOBILE */}
          <Hidden smUp>
            <CardContent style={{ padding: 0 }}>
              <ReactSwipe
                swipeOptions={{
                  startSlide: activeText ? 0 : 1,
                  continuous: false,
                  callback() {
                    setActiveText(!activeText);
                  },
                }}
              >
                {cardText.map((text, idx) => (
                  <Typography
                    key={idx}
                    variant="caption"
                    color="textSecondary"
                    align="center"
                    paragraph
                  >
                    <Translate id={text} />
                  </Typography>
                ))}
              </ReactSwipe>
              {renderDots(activeText, classes.dots)}
            </CardContent>
          </Hidden>
          {/* DESKTOP */}
          <Hidden smDown>
            <CardContent style={{ padding: 0 }}>
              {cardText.map((text, idx) => (
                <Typography
                  key={idx}
                  variant="body2"
                  color="textPrimary"
                  align="center"
                  style={{ marginBottom: idx === 0 ? 18 : 0 }}
                >
                  <Translate id={text} />
                </Typography>
              ))}
            </CardContent>
          </Hidden>
          {/* BUTTON */}
          <CardActions style={{ marginTop: 24, justifyContent: 'center', padding: 0 }}>
            <Button
              fdKey={btnText}
              classes={{ root: classes.btn }}
              color="primary"
              variant="contained"
              href={helpdeskLink}
              //@ts-ignore
              rel="noopener noreferrer"
              // @ts-ignore
              target="_blank"
            >
              <Translate id={btnText} />
            </Button>
          </CardActions>
        </Card>
      </div>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
  };
};
export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(PlaceholderPage);
