import React from 'react';

import { BankAccountDetail } from '@flipdish/api-client-typescript';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { Button } from '../../../../ui/Button';
import { LoadingButton } from '../../../../ui/LoadingButton';
import { updateBankAccountState } from '../banking.actions';

const useStyles = makeStyles(() =>
  createStyles({
    approveBtn: {
      marginLeft: '16px',
      backgroundColor: '#36d88e',
      '&:hover': { backgroundColor: '#36c88e' },
    },
  })
);

interface StaffVerifyButtons {
  accountId: number;
}
type Props = StaffVerifyButtons &
  mapDispatchToProps &
  FormikProps<{ action: 'approve' | 'disapprove' | undefined; radioValue: string }>;

const BankAccountVerifyButtons = (props: Props) => {
  const { setFieldValue, isSubmitting } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState('Incorrect VAT Number');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Form>
        <Button
          disabled={isSubmitting}
          fdKey={'disapprove-account'}
          variant="outlined"
          color="secondary"
          type="button"
          onClick={openDialog}
        >
          <Translate id="disapprove" />
        </Button>
        <Button
          disabled={isSubmitting}
          fdKey={'approve-account'}
          variant="contained"
          color="primary"
          type="submit"
          className={classes.approveBtn}
          onClick={() => {
            setFieldValue('action', 'approve');
          }}
        >
          <Translate id="approve" />
        </Button>
      </Form>

      <Dialog open={open} onClose={closeDialog} aria-labelledby="disapprove">
        <DialogTitle id="Reject_bank_account">
          <Translate id="Reject_bank_account" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Form>
              <FormLabel component="legend">
                <Translate id="Reason_for_rejection" />
              </FormLabel>
              <RadioGroup
                aria-label="Reason for Rejection"
                name="rejectionReason"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Incorrect VAT Number"
                  control={<Radio data-fd="incorrect-vat-number" color="secondary" />}
                  label={<Translate id="Incorrect_VAT_Number" />}
                />
                <FormControlLabel
                  value="Incorrect IBAN"
                  control={<Radio data-fd="incorrect-iban" color="secondary" />}
                  label={<Translate id="Incorrect_IBAN" />}
                />
                <FormControlLabel
                  value="Incorrect SWIFT"
                  control={<Radio data-fd="incorrect-swift" color="secondary" />}
                  label={<Translate id="Incorrect_SWIFT" />}
                />
              </RadioGroup>
              <DialogActions>
                <Button
                  variant={'text'}
                  fdKey={`cancel-reject-account`}
                  onClick={closeDialog}
                  color="primary"
                  disabled={isSubmitting}
                >
                  <Translate id="Cancel" />
                </Button>
                <LoadingButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  fdKey={'reject-account'}
                  color="secondary"
                  type="submit"
                  variant="text"
                  onClick={() => {
                    setFieldValue('action', 'disapprove');
                    setFieldValue('radioValue', value);
                  }}
                >
                  <Translate id="Reject" />
                </LoadingButton>
              </DialogActions>
            </Form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

type mapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch, props: StaffVerifyButtons) {
  const { accountId } = props;
  const acceptedReason = '';
  return {
    disapprove: (val: string) =>
      dispatch(
        updateBankAccountState({
          accountId,
          accountState: BankAccountDetail.AccountStateEnum.Unverified,
          reason: val,
        })
      ),
    approve: () =>
      dispatch(
        updateBankAccountState({
          accountId,
          accountState: BankAccountDetail.AccountStateEnum.Verified,
          reason: acceptedReason,
        })
      ),
  };
}

const BankAccountVerify = compose<Props, StaffVerifyButtons>(
  connect(null, mapDispatchToProps),
  withFormik<Props, { action: 'approve' | 'disapprove' | undefined; radioValue: string }>({
    displayName: 'RemoveBankAccount',
    mapPropsToValues: () => ({
      action: undefined,
      radioValue: '',
    }),
    handleSubmit: (values, formikBag) => {
      if (values.action === 'approve') {
        return formikBag.props.approve().catch((err) => {
          formikBag.setSubmitting(false);
        });
      } else {
        return formikBag.props.disapprove(values.radioValue).catch((err) => {
          formikBag.setSubmitting(false);
        });
      }
    },
  })
)(BankAccountVerifyButtons);

const StaffVerifyAccount = (props: StaffVerifyButtons) => (
  <Permissions allowed={['FlipdishStaff']}>
    <BankAccountVerify {...props} />
  </Permissions>
);

export default StaffVerifyAccount;
