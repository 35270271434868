import { AnalyticsClientEvent } from '@flipdish/api-client-typescript';
import { analyticsEventConstants } from '../../signalr/hub.events';

const initialState = {
  events: new Array<{
    event: string;
    data: AnalyticsClientEvent;
  }>()
};

export function analyticsHub(state = initialState, action) {
  const { events } = state;
  const { type, data } = action;
  switch (action.type) {
    case analyticsEventConstants.CLIENT:
      return { ...state, events: [...events, { event: type, data }] };
  }
  return state;
}
