import * as React from 'react';

import { Product, UpdateProduct } from '@flipdish/api-client-typescript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { useQueryPendingMenuChangesHook } from '../../../../../custom-hooks/useQueryPendingMenuChangesHook';
import {
  closeNotifySaving,
  notify,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import { catalogChangesService } from '../../../../../services/catalogChanges.service';
import PageLayout from '../../../../../ui/Layout';
import { metafieldsService } from '../../../../Settings/Metafields/metafields.service';
import { catalogProductsService } from '../../../services/catalogProducts.service';
import { EditModifierForm } from '../../components/EditModifierForm';
import { ProductForm } from '../../components/ProductForm/ProductForm';

type Props = RouteComponentProps<{ productId: string }>;

const ProductEdit = ({
  AppId,
  closeNotifySaving,
  hasUpdateProductPermission,
  history,
  isProductBasedMenusOn,
  location,
  notify,
  notifyError,
  notifySaved,
  notifySaving,
  productId,
  translate,
}: Props & MappedDispatch & MappedState) => {
  const handleSave = async (updatedProduct: UpdateProduct, saveType: number) => {
    if (saveType === 0) {
      await saveMutation.mutateAsync(updatedProduct);
    } else {
      await saveAndPublishMutation.mutateAsync(updatedProduct);
    }
  };

  const showError = (errorMessage: string) => {
    closeNotifySaving();
    notifyError({ message: errorMessage, translate: true });
  };

  const showUpdateError = (error: Error) => {
    const errorMessage = error.message.includes('SKU already exists')
      ? 'Product_CreateProductDialog_DuplicateSkuError'
      : 'Error_please_try_again_later';
    showError(errorMessage);
  };

  const { data, isPending, isError } = useQuery({
    queryKey: [catalogProductsService.getProductByIdQueryKey, AppId, productId],
    queryFn: () => catalogProductsService.getCatalogProductById(AppId, productId),
    refetchOnWindowFocus: true,
  });
  React.useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
      history.replace(`/${AppId}/products`);
    }
  }, [isError]);

  const metafieldsQuery = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, AppId, 'catalogItem'],
    queryFn: () => metafieldsService.getMetafieldsByEntity(AppId, 'catalogItem'),
  });

  const pendingMenuChangesQuery = useQueryPendingMenuChangesHook(
    [catalogChangesService.getPendingMenuChangesQueryKey, AppId, productId],
    { appId: AppId, catalogElementId: productId, page: 1, limit: 50 },
    true
  );

  const queryClient = useQueryClient();
  const saveMutation = useMutation({
    mutationFn: (updatedProduct: UpdateProduct) => {
      notifySaving();
      return catalogProductsService.updateCatalogProduct(AppId, productId, updatedProduct);
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      queryClient.invalidateQueries({
        queryKey: [catalogProductsService.getProductByIdQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesQueryKey],
      });
    },

    onError: showUpdateError,
  });

  const saveAndPublishMutation = useMutation({
    mutationFn: (updatedProduct: UpdateProduct) => {
      notifySaving();
      return catalogProductsService.updateCatalogProduct(AppId, productId, updatedProduct);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [catalogProductsService.getProductByIdQueryKey],
      });
      publishMutation.mutate();
    },

    onError: showUpdateError,
  });

  const publishMutation = useMutation({
    mutationFn: () => {
      return catalogChangesService.publishPendingMenuChanges(AppId, productId, []);
    },

    onSuccess: () => {
      closeNotifySaving();
      notify({
        message: translate('Successfully_updated_menus') as string,
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: [catalogChangesService.getPendingMenuChangesQueryKey],
      });
    },

    onError: () => {
      showError('Changes_saved_but_failed_to_update_menus');
    },
  });

  const renderForm = () => {
    if (data?.Data?.ProductType === Product.ProductTypeEnum.Modifier) {
      return (
        <EditModifierForm
          appId={AppId}
          canEdit={hasUpdateProductPermission}
          isLoading={isPending}
          metafields={metafieldsQuery.data?.Data}
          modifier={data?.Data}
          onSaveChanges={handleSave}
          pendingMenuChanges={pendingMenuChangesQuery.data}
          translate={translate}
        />
      );
    }
    return (
      <ProductForm
        appId={AppId}
        canEdit={hasUpdateProductPermission}
        isLoading={isPending}
        isUpdateForm
        metafields={metafieldsQuery.data?.Data}
        onSaveChanges={handleSave}
        pendingMenuChanges={pendingMenuChangesQuery.data}
        product={data?.Data}
        translate={translate}
      />
    );
  };

  return isProductBasedMenusOn ? (
    <PageLayout
      strictToParent
      documentTitle={'Edit_product'}
      toParent={`/${AppId}/products`}
      // @ts-ignore
      title={location.state?.productName || data?.Data?.Name}
    >
      {renderForm()}
    </PageLayout>
  ) : null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { currentApp, locale } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'UpdateCatalogElements',
  ]);
  return {
    AppId: currentApp.AppId as string,
    hasUpdateProductPermission: getPermissionsSelector(state),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    productId: props.match.params.productId,
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit);
