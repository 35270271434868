import React, { useState } from 'react';

import SmileyFace from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { BaseEmoji, Picker } from 'emoji-mart';
import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import { formikValidate } from '../../../helpers/validation';
import TextField from '../../../ui/TextField/TextField';
import HighlightedTextLimit from './HighlightedTextLimit';

import 'emoji-mart/css/emoji-mart.css';

const validateMessage = (value: string) => {
  return formikValidate.required(value);
};
const MessageAndEmojiFormField = (props) => {
  const [openEmojiPicker, setOpenEmojiPicker] = useState(true);

  const handleClose = () => {
    setOpenEmojiPicker(true);
  };

  return (
    <Field name={'Message'} validate={validateMessage}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);
        const isBiggerThan40 = field.value.length > 40;

        return (
          <TextField
            {...field}
            onChange={(value) => {
              form.setFieldValue(field.name, value);
              form.setFieldTouched(field.name, true);
            }}
            fdKey={'Push_Notifications_Message'}
            label={<Translate id="Message" />}
            autoComplete="off"
            fullWidth
            multiline
            disabled={isSubmitting}
            error={showError}
            helperText={
              showError || isBiggerThan40 ? (
                <Grid component="span" container justifyContent="space-between" direction="row">
                  <Grid component="span" item style={{ maxWidth: '80%' }}>
                    <Typography component="span" color="error" variant="caption">
                      {isBiggerThan40 ? <Translate id={'Length_bigger_40'} /> : fieldError}
                    </Typography>
                  </Grid>
                  <Grid component="span" item>
                    {field.value.length && (
                      <Typography component="span" color="error" variant="caption">
                        -{field.value.length - 40}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  align="right"
                  component="span"
                  display="block"
                  color="textSecondary"
                  variant="caption"
                >
                  {field.value.length}
                </Typography>
              )
            }
            margin="normal"
            InputProps={{
              inputComponent: HighlightedTextLimit,
              endAdornment: (
                <InputAdornment position="end">
                  {openEmojiPicker ? (
                    <IconButton
                      edge="end"
                      aria-label="Toggle emoji picker"
                      onClick={() => setOpenEmojiPicker(false)}
                      disabled={props.disabled}
                    >
                      <SmileyFace />
                    </IconButton>
                  ) : (
                    <Dialog open={!openEmojiPicker} onClose={handleClose}>
                      <Picker
                        style={{
                          zIndex: 100,
                          width: '100%',
                        }}
                        title="Pick emoji"
                        emoji="point_up"
                        showSkinTones={false}
                        onClick={() => setOpenEmojiPicker(true)}
                        onSelect={(emoji: BaseEmoji) => {
                          form.setFieldValue('Message', field.value + emoji.native);
                        }}
                      />
                    </Dialog>
                  )}
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    </Field>
  );
};

export default MessageAndEmojiFormField;
