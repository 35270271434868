import React, { ChangeEvent } from 'react';

import { App, StoreGroup } from '@flipdish/api-client-typescript';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputAdornment from '@mui/material/InputAdornment';
import MuiListItem from '@mui/material/ListItem';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import memoize from 'memoize-one';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import TextField from '@fd/ui/TextField/TextField';

import { deliveryZonesColors, disabledColor } from '../../../constants/deliveryzones.constants';
import { getSymbol as getCurrencySymbol } from '../../../helpers/currency';
import { createDeliveryZoneByIdSelector } from '../../../selectors/storeDeliveryZone.selector';
import Spacer from '../../../ui/Spacer';
import { DeliveryZoneView, IDeliveryZone, IDeliveryZoneListOptions } from '../types';
import { ListMoreMenu } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInputAdornment-positionStart': {
      whiteSpace: 'nowrap',
    },
  },
  listItemSmallScreen: {
    paddingRight: 0,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  textFieldLabel: {
    fontSize: '12px',
    letterSpacing: ' 0.4px',
    lineHeight: '1.33',
    fontWeight: 'normal',
    fontStretch: 'normal',
    transform: 'translate(0, 1.5px)',
  },
  textFieldLabelDisabled: {
    color: 'rgba(0, 0, 0, 0.6)',
    opacity: 0.5,
  },
  textFieldInput: {
    height: '24px',
    padding: '0',
    margin: '0 0 1px 0',
    fontSize: '16px',
    lineHeight: '1.5',
    letterSpacing: ' 0.2px',
    fontWeight: 'normal',
    fontStretch: 'normal',
  },
  textFieldInputDisabled: {
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.5,
  },
  avatar: {
    fontSize: '18px',
  },
  avatarDisabled: {
    opacity: 0.5,
  },
  moreMenu: {
    minWidth: '44px',
    height: '42px',
    marginLeft: '-8px',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '-12px',
    },
  },
  feeChargedToStore: {
    fontStyle: 'italic',
  },
}));

const createDeliveryZoneViewSelector = () =>
  memoize(
    (
      zone: IDeliveryZone,
      selected: boolean,
      color: string
    ): DeliveryZoneView<IDeliveryZoneListOptions> => {
      return {
        ...zone,
        selected,
        options: {
          fillColor: color,
        },
      };
    }
  );

export type ListItemPropsType = {
  storeId?: number;
  deliveryZoneId: number;
  index: number;
  isSmallScreen: boolean;
  currency: StoreGroup.CurrencyEnum;
  onChange(changes: IDeliveryZone): void;
  onSelect(deliveryZoneId: number): void;
  onRemove(deliveryZoneId: number): void;
};

const ListItem = ({
  zone,
  translate,
  ordinal,
  onChange,
  onRemove,
  currency,
  isSmallScreen,
  onSelect,
  canEdit,
}: ListItemPropsType & MappedState) => {
  const classes = useStyles();
  if (!zone) {
    return <></>;
  }

  const isDisabled = !zone.IsEnabled;
  const currencySymbol = getCurrencySymbol(currency);

  const listItemClasses = clsx(isSmallScreen ? classes.listItemSmallScreen : '');

  const customAvatarStyle: any = { backgroundColor: zone.options?.fillColor };
  const avatarClasses = clsx(classes.avatar, { [classes.avatarDisabled]: isDisabled });

  const textFieldInputClasses = clsx(classes.textFieldInput, {
    [classes.textFieldInputDisabled]: isDisabled,
  });
  const textFieldInputAdornmentClasses = clsx({ [classes.textFieldInputDisabled]: isDisabled });
  const textFieldInputLableClasses = clsx(classes.textFieldLabel, {
    [classes.textFieldLabelDisabled]: isDisabled,
  });

  const handleItemSelect = () => {
    onSelect(zone.Id);
  };

  const handleItemChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (zone[name] !== value) {
      onChange({ ...zone, [name]: value ? Number(value) : undefined });
    }
  };

  return (
    <MuiListItem
      role={undefined}
      className={listItemClasses}
      selected={zone.selected}
      onClick={handleItemSelect}
      data-fd="delivery_zones_form_list_item"
      disableGutters
    >
      <form className={classes.form}>
        <Grid container>
          <Grid item>
            <Hidden smDown>
              <Spacer size={8} variant="vertical">
                <Avatar style={customAvatarStyle} className={avatarClasses}>
                  {isDisabled ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : zone.selected ? (
                    <CheckIcon fontSize="small" />
                  ) : (
                    ordinal
                  )}
                </Avatar>
              </Spacer>
            </Hidden>
          </Grid>

          <Grid item xs>
            <Spacer size={8} variant="vertical">
              <TextField
                variant="standard"
                fdKey="list_item_name"
                className={classes.root}
                label={
                  <Typography noWrap variant="caption" color="textSecondary">
                    {translate('Minimum_amount')}
                  </Typography>
                }
                name="MinimumDeliveryOrderAmount"
                type="number"
                margin="none"
                fullWidth
                value={zone.MinimumDeliveryOrderAmount}
                onChange={handleItemChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={textFieldInputAdornmentClasses}>
                      {currencySymbol}
                    </InputAdornment>
                  ),
                  disableUnderline: !zone.selected,
                  classes: {
                    input: textFieldInputClasses,
                  },
                  inputProps: {
                    min: '0',
                    'data-fd': 'delivery_zones_form_list_item_minimum_amount',
                    step: (zone.MinimumDeliveryOrderAmount as number) % 1 !== 0 ? '0.01' : '1',
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: textFieldInputLableClasses,
                  },
                }}
                disabled={(zone.Id as number) < 0 || !canEdit}
              />
            </Spacer>
          </Grid>

          <Grid item xs>
            <Spacer size={8} variant="vertical">
              <TextField
                fdKey="list_item_delivery_fee"
                variant="standard"
                className={classes.root}
                label={
                  <Typography noWrap variant="caption" color="textSecondary">
                    {translate('Delivery_fee')}
                  </Typography>
                }
                name="DeliveryFee"
                type="number"
                margin="none"
                fullWidth
                value={zone.DeliveryFee}
                onChange={handleItemChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={textFieldInputAdornmentClasses}>
                      {currencySymbol}
                    </InputAdornment>
                  ),
                  disableUnderline: !zone.selected,
                  classes: {
                    input: textFieldInputClasses,
                  },
                  inputProps: {
                    min: '0',
                    'data-fd': 'delivery_zones_form_list_item_delivery_fee',
                    step: (zone.DeliveryFee as number) % 1 !== 0 ? '0.01' : '1',
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: textFieldInputLableClasses,
                  },
                }}
                disabled={(zone.Id as number) < 0 || !canEdit}
              />
              {zone.FeeChargedToStore > 0 && (
                <Typography
                  variant="caption"
                  data-fd="list_item_delivery_fee_charged_to_store"
                  className={classes.feeChargedToStore}
                >
                  {`(${currencySymbol}${zone.FeeChargedToStore.toFixed(2)} ${translate('DeliveryZone_Fee_CostToYou')})`}
                </Typography>
              )}
            </Spacer>
          </Grid>
          <Grid item>
            <ListMoreMenu
              className={classes.moreMenu}
              disabled={!canEdit}
              index={ordinal}
              onChange={onChange}
              onRemove={onRemove}
              translate={translate}
              visible={zone.selected}
              zone={zone}
            />
          </Grid>
        </Grid>
      </form>
    </MuiListItem>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToProps>>;
const mapStateToProps = (_initialState, { deliveryZoneId }: ListItemPropsType) => {
  const zoneSelector = createDeliveryZoneByIdSelector(deliveryZoneId);
  const viewSelector = createDeliveryZoneViewSelector();

  return (state: AppState, { index }: ListItemPropsType) => {
    const zone = zoneSelector(state) as IDeliveryZone;
    const selected = state.deliveryZones.selectedId === zone.Id;
    const count = deliveryZonesColors.length;
    const color = zone.IsEnabled
      ? deliveryZonesColors[index % count].fillColor
      : disabledColor.fillColor;

    const viewZone = viewSelector(zone, selected, color);

    const canEdit = state.permissions.some(
      (permission) => permission === App.AppResourceSetEnum.UpdateDeliveryZones.toString()
    );

    return {
      zone: viewZone,
      ordinal: index + 1,
      translate: getTranslate(state.locale),
      canEdit,
    };
  };
};

export default connect(mapStateToProps)(ListItem);
