import React, { ChangeEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import LoadingButton from '../../../ui/LoadingButton';
import TextField from '../../../ui/TextField/TextField';

const mapStateToProps = (state) => ({ translate: getTranslate(state.locale) });

type Props = {
  appName: string;
  setDialogOpen: (change) => void;
  isDialogOpen: boolean;
  loading: boolean;
  submit: (changes) => void;
} & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(function ({
  isDialogOpen,
  setDialogOpen,
  submit,
  appName,
  loading,
  translate,
}: Props) {
  const [value, setValue] = useState('');
  const isDisabled = value !== appName && value !== 'Delete';

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent key="dialog-content">
        <Typography variant="h6">{translate('Delete_oauth_app_dialog_title')}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {translate('Delete_aouth_app_dialog_description', { appName: appName })}
        </Typography>
        <FormControl fullWidth>
          <TextField
            fdKey="deleting_app_name"
            value={value}
            placeholder={
              translate('Delete_oauth_app_input_placeholder', { appName: appName }) as string
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions key="actions">
        <Button color="primary" data-fd="Delete_cancel" onClick={() => setDialogOpen(false)}>
          {translate('Cancel')}
        </Button>
        <LoadingButton
          color="primary"
          fdKey="Delete_confirm"
          onClick={submit}
          loading={loading}
          disabled={isDisabled}
        >
          {translate('Remove')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
