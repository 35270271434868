import React, { useEffect, useState } from 'react';

import {
  AppStoreAppConfiguration,
  RestApiErrorResult,
  ValidationErrorResult,
} from '@flipdish/api-client-typescript';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { RedirectData } from 'components/AppStore/types';
import { History } from 'history';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  closeNotifyError,
  closeNotifyInfo,
  closeNotifySaving,
  notifyError,
  notifyInfo,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import PageLayout from '../../../../../ui/Layout';
import { AppStoreAppConfigForm } from '../../../components/AppStoreAppConfigForm';
import { AppStoreAppDetailsLoadingSkeleton } from '../../../components/AppStoreAppDetailsLoadingSkeleton';
import { appStoreService } from '../../../services/appstore.service';

type AppStoreAppConfigProps = {
  history: History;
};

type Props = RouteComponentProps<{ appStoreAppId: string; configId: string }>;

const AppStoreAppConfig = ({
  appId,
  appStoreAppId,
  history,
  configId,
  translate,
  closeNotifySaving,
  dispatchNotifyInfo,
  notifyError,
  notifySaved,
  notifySaving,
}: MappedDispatch & MappedState & AppStoreAppConfigProps) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [formErrors, setFormErrors] = useState<ValidationErrorResult[] | undefined>(undefined);
  const [redirectData, setRedirectData] = useState<RedirectData | null>(null);
  const queryClient = useQueryClient();

  const { data, isPending } = useQuery({
    queryKey: [appStoreService.getAppStoreAppConfigByIdKey, appId, appStoreAppId, configId],
    queryFn: () => appStoreService.getAppStoreAppConfigById(appId, appStoreAppId, configId),
  });

  useEffect(() => {
    if (data) {
      setIsEnabled(data.IsEnabled);
    }
  }, [data]);

  const { mutate } = useMutation({
    mutationFn: (updatedAppStoreAppConfig: AppStoreAppConfiguration) => {
      notifySaving();
      return appStoreService.updateAppStoreAppConfig(
        appId,
        appStoreAppId,
        configId,
        updatedAppStoreAppConfig
      );
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      //Commenting this line of code until to know if this is definitely being used.
      // setIsEnabled(JSON.parse(serviceResponse.data).IsEnabled);
      queryClient.invalidateQueries({ queryKey: [appStoreService.getAppStoreAppConfigByIdKey] });
    },

    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        notifyError(error);
        setFormErrors(apiErrorMessage.Errors);
      }
    },
  });

  const deleteAppConfiguration = useMutation({
    mutationFn: () => {
      notifySaving();
      return appStoreService.deleteAppStoreConfig(appId, appStoreAppId, configId);
    },

    onSuccess: () => {
      closeNotifySaving();
      history.replace(`/${appId}/appstore/${appStoreAppId}`);
    },

    onError: (error: AxiosError) => {
      closeNotifySaving();
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        notifyError(error);
        setFormErrors(apiErrorMessage.Errors);
      }
    },
  });

  const processDynamicButtonClickResponse = (data, variables) => {
    if (data?.ErrorMessage) {
      notifyError({ message: data.ErrorMessage, translate: false });
    } else if (data?.InfoMessage) {
      dispatchNotifyInfo({ message: data.InfoMessage, translate: false });
    }
    if (data?.RedirectUrl) {
      setRedirectData({ url: data.RedirectUrl, fieldName: variables.fieldName });
    }
  };

  const { mutate: onDyanmicButtonClick, isPending: processingClick } = useMutation({
    mutationFn: (variables: { key: string; fieldName: string }) => {
      return appStoreService.executeConfigurationAction(appId, appStoreAppId, configId, {
        Key: variables.key,
        Action: 'click',
      });
    },

    onSuccess: (data, variables) => {
      processDynamicButtonClickResponse(data?.Data, variables);
    },

    onError: (error: AxiosError) => {
      if (error instanceof AxiosError) {
        notifyError(error);
      }
    },
  });

  const renderContent = () => {
    if (isPending) {
      return <AppStoreAppDetailsLoadingSkeleton />;
    }
    if (data) {
      return (
        <AppStoreAppConfigForm
          appStoreFormErrors={formErrors}
          appStoreApp={data}
          onDyanmicButtonClick={onDyanmicButtonClick}
          redirectData={redirectData}
          setRedirectData={setRedirectData}
          isDynamicButtonLoading={processingClick}
          deleteAppConfiguration={deleteAppConfiguration.mutate}
          translate={translate}
          onSaveChanges={mutate}
          isEnabled={isEnabled}
        />
      );
    }
  };

  return (
    <PageLayout
      toParent={`/${appId}/appstore/${appStoreAppId}`}
      documentTitle="App_Store"
      title={(data && data.Name) || ''}
    >
      {renderContent()}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  const { currentApp, locale } = state;
  return {
    appId: currentApp.AppId!,
    translate: getTranslate(locale),
    appStoreAppId: props.match.params.appStoreAppId,
    configId: props.match.params.configId,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => {
    dispatch(closeNotifyError());
    setTimeout(() => {
      dispatch(notifyError(data));
    }, 100);
  },
  dispatchNotifyInfo: (data: NotifyProps) => {
    dispatch(closeNotifyInfo());
    setTimeout(() => {
      dispatch(notifyInfo(data));
    }, 100);
  },
  notifySaving: () => dispatch(notifySaving()),
  notifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppStoreAppConfig);
