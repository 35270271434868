import React, { cloneElement, Fragment, useState } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import CompleteMilestone from '../../../../assets/images/complete-milestone-green.svg';
import IncompleteMilestone from '../../../../assets/images/incomplete-milestone.svg';

const useStyles = makeStyles((theme: Theme) => ({
  titleOuterWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  titleInnerWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  icon: {
    marginRight: 8,
  },
  title: {
    display: 'inline-block',
    fontWeight: 'normal',
    fontSize: 16,
  },
  expandedButton: {
    height: 30,
  },
  hidden: {
    display: 'none',
  },
}));

const Milestone = ({ name, expanded, setExpanded, children, onMilestoneComplete }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [milestoneComplete, setMilestoneComplete] = useState(false);
  const tutorialSectionElements = Array.isArray(children) ? children : [children];
  const [sectionsCompleted, setSectionsCompleted] = useState(
    tutorialSectionElements.map((tutorialSectionElement) => undefined)
  );
  const [sectionExpanded, setSectionExpanded] = useState<number | undefined>(0);

  const setSectionCompleted = (index, isCompleted, isActive) => {
    setSectionsCompleted((prevSectionsCompleted) => {
      const newSectionsCompleted = [...prevSectionsCompleted];
      newSectionsCompleted[index] = isCompleted;
      return newSectionsCompleted;
    });

    const newSectionsCompleted = [...sectionsCompleted];
    newSectionsCompleted[index] = isCompleted;
    setSectionsCompleted(newSectionsCompleted);

    if (isCompleted) {
      const uncompletedSectionIndex = sectionsCompleted.findIndex(
        (isComplete, i) => !isComplete && index !== i
      );

      setTimeout(
        () => {
          if (uncompletedSectionIndex === -1) {
            setSectionExpanded(undefined);
            setMilestoneComplete(true);

            if (isActive) {
              onMilestoneComplete();
            }
          } else {
            setSectionExpanded(uncompletedSectionIndex);
          }
        },
        isActive ? 1000 : 0
      );
    }
  };

  if (!isActive) {
    setTimeout(() => setIsActive(true), 100);
  }

  return (
    <>
      <div className={classes.titleOuterWrapper}>
        <div className={classes.titleInnerWrapper}>
          {milestoneComplete && <img src={CompleteMilestone} className={classes.icon} />}
          {!milestoneComplete && <img src={IncompleteMilestone} className={classes.icon} />}
          <h3 className={classes.title}>
            <Translate id={name as TranslationId} />
          </h3>
        </div>
        <IconButton
          aria-label="toggle"
          className={classes.expandedButton}
          data-fd="tutorial-section-toggle-button"
          component={'button'}
          size="small"
          onClick={() => setExpanded && setExpanded(!expanded)}
        >
          {!expanded && <KeyboardArrowDown />}
          {expanded && <KeyboardArrowUp />}
        </IconButton>
      </div>
      <Collapse
        in={expanded}
        className={isActive ? undefined : classes.hidden}
        timeout={isActive ? 'auto' : 0}
      >
        {expanded &&
          tutorialSectionElements.map((tutorialSectionElement, index) => (
            <Fragment key={index}>
              {cloneElement(tutorialSectionElement, {
                setSectionCompleted: (isCompleted, isActive) =>
                  setSectionCompleted(index, isCompleted, isActive),
                setExpanded: () =>
                  setSectionExpanded(sectionExpanded === index ? undefined : index),
                sectionCompleted: sectionsCompleted[index],
                expanded: sectionExpanded === index,
                isActive: isActive,
              })}
            </Fragment>
          ))}
      </Collapse>
    </>
  );
};

export default Milestone;
