import React, { useEffect, useState } from 'react';

import { useMenu } from '@fd/customHooks/useMenu';
import { Field, FieldProps } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import Select, { OptionType } from '@fd/ui/Select/Select';

import FormItemLayout from '../../../components/FormItemLayout';
import { useMenuSearch } from './hooks/useMenuSearch';

type Props = {
  isDisabled?: boolean;
  menuId?: number;
  translate: TranslateFunction;
};

type MenuItemOption = { label: string; value: string };

const PromotionalMenuItemField = ({ isDisabled = false, menuId, translate }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<MenuItemOption[]>([]);

  const { data: menu } = useMenu({ menuId, enabled: !!menuId && !isDisabled });

  const { data: menuItemsData } = useMenuSearch({
    menuSections: menu?.MenuSections,
    searchValue,
  });

  useEffect(() => {
    const newOptions = menuItemsData.map((menuItem) => ({
      label: menuItem.Name || '',
      value: menuItem.PublicId || '',
    }));
    setOptions(newOptions);
  }, [JSON.stringify(menuItemsData)]);

  return (
    <Field name={'PromotionAwardMenuItemPublicIds'}>
      {({ field, form }: FieldProps) => {
        const { isSubmitting } = form;

        const getValues = () => {
          return form.values.PromotionAwardMenuItemPublicIds?.map((id: string) => ({
            label: id,
            value: id,
          }));
        };

        const handleOnChange = (values: OptionType[]) => {
          form.setFieldValue(
            'PromotionAwardMenuItemPublicIds',
            values.map((value) => value.value)
          );
        };

        return (
          <FormItemLayout
            description={<Translate id="Promotional_menu_item_description" />}
            label={<Translate id="Promotional_menu_item" />}
          >
            <Select
              {...field}
              dataFd={'voucher-promotional-menu-item'}
              fullWidth
              isClearable
              isDisabled={!form.values.Menu || isSubmitting}
              isMulti
              onChange={handleOnChange}
              options={options}
              placeholder={translate('Search_menu') as string}
              setSearchQuery={setSearchValue}
              TextFieldProps={{
                fdKey: 'voucher-promotional-menu-item-dropdown',
                name: 'selected-voucher-promotional-menu-item',
                value: searchValue,
              }}
              value={getValues()}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default PromotionalMenuItemField;
