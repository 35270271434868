import React, { useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Checkbox from '../Checkbox';
import { GenericTableColumns } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'stretch',
    },
    removeOutline: {
      '&:focus': { outline: 'none' },
    },
    popper: {
      '&:focus': { outline: 'none' },
      zIndex: theme.zIndex.modal,
    },
  })
);

type OuterProps = {
  onChange: (colName: string, checked: boolean) => void;
  metadata: GenericTableColumns[];
  defaultColumns: string[];
  selected: Set<string>;
  isMobile: boolean;
};

const ColumnSelector = (props: OuterProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { selected, metadata, defaultColumns, onChange, isMobile } = props;

  const handleSelectorToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectorClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleCheckboxClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
    checked: boolean
  ) => {
    event.preventDefault();
    onChange(value, !checked);
  };

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleSelectorToggle}
        color="primary"
        type="button"
        data-fd="open-column-selector"
      >
        <SettingsOutlinedIcon style={{ fontSize: 15 }} />
        {!isMobile && (
          <>
            <Translate id="Columns" />
            <ArrowDropDownIcon style={{ paddingLeft: 9 }} />
          </>
        )}
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{ height: 252, overflowY: 'auto' }}
              classes={{ root: classes.removeOutline }}
            >
              <ClickAwayListener onClickAway={handleSelectorClose}>
                <MenuList
                  autoFocus={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  classes={{ root: classes.removeOutline }}
                >
                  {metadata
                    .filter((m) => defaultColumns.indexOf(m.columnName) === -1)
                    .map((m, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleCheckboxClick(event, m.columnName, selected.has(m.columnName))
                          }
                        >
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  dataFd={`${m.columnName}-column-checkbox`}
                                  checked={selected.has(m.columnName)}
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                  color="default"
                                />
                              }
                              label={<Translate id={m.translationId as TranslationId} />}
                            />
                          </FormGroup>
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ColumnSelector;
