import OrdersRedirect from '../components/Order/components/Redirect';
import Portal from '../layouts/Portal/Portal';
import { firstTimeSetupRoutesV2, publicRoutes } from './account.routes';

const privateRoutes: (RoutePropsExtended | RedirectPropsExtended)[] = [
  {
    name: 'orders.redirect',
    group: 'private',
    path: '/orders',
    component: OrdersRedirect,
    exact: true,
  },

  {
    name: 'portal',
    group: 'private',
    path: '/:appId?',
    component: Portal,
  },
];

const exportedRoutes = {
  publicRoutes,
  privateRoutes,
  firstSetupRoutesV2: firstTimeSetupRoutesV2,
};

export default exportedRoutes;

export const defaultRoutingRoutesState: (RoutePropsExtended | RedirectPropsExtended)[] = [
  ...publicRoutes,
  ...privateRoutes,
  ...firstTimeSetupRoutesV2,
];
