import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Switch from '@fd/ui/Switch/Switch';

import FormItemLayout from '../../FormItemLayout';

const TakesPriorityField = ({ disabled = false }) => (
  <Field name={'TakesPriority'}>
    {({ field, form: { isSubmitting } }: FieldProps) => {
      return (
        <FormItemLayout
          description={<Translate id="Takes_priority_description" />}
          label={<Translate id="Takes_priority" />}
          noSpace
        >
          <Switch
            name={field.name}
            value={field.value}
            checked={field.value}
            onChange={field.onChange}
            fdKey={'Takes_priority'}
            disabled={isSubmitting || disabled}
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default TakesPriorityField;
