import React from 'react';

import { DisplayType } from '../../types';

type Props = {
  title?: string;
  displayType: DisplayType;
};

const ChartTitle = ({ title, displayType }: Props) => {
  return title ? (
    <div
      style={{
        padding: `0px ${displayType === 'mobile' ? '8px' : '20px'}`,
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.71',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.87)',
      }}
    >
      {title}
    </div>
  ) : null;
};

export default ChartTitle;
