import React from 'react';

import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import type { SalesChannel } from '@flipdish/orgmanagement';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import LazyComponent from '@fd/ui/LazyComponent';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';
import Tabs from '@fd/ui/Tabs';

import WebsiteSettingsIcon from '../../../assets/images/website-edit.svg';
import { lazyWithRetry } from '../../../helpers/utilities';
import { flagService } from '../../../services/flagService';
import FlipdishDevicesTab from '../components/FlipdishDevicesTab';

const SalesChannelList = lazyWithRetry(() => import('../../RMS/SalesChannels/SalesChannelList'));

const StyledPaperContainer = styled(PaperContainer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));

const Kiosk = ({ translate, selectedProperty, currentApp, showSalesChannels }: MappedState) => {
  const history = useHistory();
  const handleChannelClick = (salesChannel: SalesChannel) => {
    history.push(
      `/${currentApp?.AppId}/sales-channels/kiosk/property/${selectedProperty?.id}/sales-channel/${salesChannel.salesChannelId}`
    );
  };
  const tabItems = [
    ...(showSalesChannels
      ? [
          {
            label: translate('Sales_Channels') as string,
            component: (
              <LazyComponent>
                <SalesChannelList
                  filterByType={[SalesChannelType.KIOSK, SalesChannelType.FLIPDISH_KIOSK]}
                  onChannelClick={handleChannelClick}
                  hideFilter={false}
                />
              </LazyComponent>
            ),
            queryString: 'sales-channels',
          },
        ]
      : []),
    {
      label: translate('Devices') as string,
      component: (
        <LazyComponent>
          <FlipdishDevicesTab />
        </LazyComponent>
      ),
      queryString: 'devices',
    },
    {
      label: translate('Settings') as string,
      component: (
        <StyledPaperContainer fluid>
          <List aria-label={translate('Website') as string}>
            <ListItemLinkButton
              borderBottom="none"
              title="Kiosk_home_screen_customisation"
              subtitle="Add_images_to_the_Kiosk_home_screen"
              link={`/${currentApp?.AppId}/sales-channels/kiosks/homescreen-customisation`}
              icon={WebsiteSettingsIcon}
            />
          </List>
        </StyledPaperContainer>
      ),
      queryString: 'settings',
    },
  ];
  return (
    <PageLayout documentTitle={'Kiosk'} title={translate('Kiosk')}>
      <Tabs tabItems={tabItems} />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state?.locale),
  selectedProperty: state.rms?.selectedProperty,
  currentApp: state.currentApp,
  showSalesChannels: flagService.isFlagOn(state, 'showSalesChannels'),
});
export default connect(mapStateToProps)(Kiosk);
