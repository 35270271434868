import * as React from 'react';

import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, setDisplayName, UnpackICEWP, withHandlers } from 'recompose';

import { flagService } from '../../../services/flagService';
import MapCard from '../../StoreGroups/components/MapCard';
import { Store } from '../types';
import withStoreByIdData, { IWithStoreByIdDataProps } from '../withStoreByIdData';
import StoreUnpublishedLabel from './StoreUnpublishedLabel';

type UnpackType<T> = T extends React.ComponentType<React.PropsWithChildren<infer P>> ? P : T;
type Data = UnpackType<ReturnType<typeof withStoreByIdData>> & {
  useDynamicAddress?: boolean;
};

export const Caption = ({ storeByIdData, useDynamicAddress = false }: Data) => {
  const address = storeByIdData?.Address;
  const legacyDisplayForCustomer = address?.DisplayForCustomer;
  // if flag on and store hasn't been saved using dynamic form use legacy format
  const dynamicAddress = address?.SingleLineDisplay ?? address?.DisplayForCustomer;
  return (
    <span data-fd="store_card_address_caption">
      {useDynamicAddress ? dynamicAddress : legacyDisplayForCustomer}
    </span>
  );
};

type Handlers = UnpackICEWP<typeof useHandlers>;
const useHandlers = withHandlers(() => {
  return {
    toStoreById:
      ({ history, match, storeId }: Props) =>
      () => {
        history.push(`${match.url}/${storeId}`);
      },
  };
});

const mapProps = memoize((storeByIdData: Store | undefined) => {
  if (storeByIdData) {
    const { Coordinates, DisplayForCustomer: dfc } = storeByIdData.Address;
    if (Coordinates) {
      const { Latitude: lat, Longitude: lng } = Coordinates;
      return `zoom=9&center=${lat},${lng}&markers=${lat},${lng}${
        dfc ? '&center=' + encodeURIComponent(dfc) : ''
      }`;
    }
  }
  return undefined;
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  return {
    internationalisedAddressConfig,
  };
};

type Props = IWithStoreByIdDataProps & RouteComponentProps;
const StoreGroupCard = compose<
  Props & Exclude<Data, 'useDynamicAddress'> & Handlers & MappedState,
  IWithStoreByIdDataProps
>(
  setDisplayName('StoreGroupCard'),
  withRouter,
  withStoreByIdData,
  useHandlers,
  connect(mapStateToProps)
)(({ storeByIdData, storeId, match, internationalisedAddressConfig }) => {
  const useDynamicAddress = internationalisedAddressConfig?.affectedCountries?.includes(
    storeByIdData?.Address?.CountryCode
  );
  return (
    <MapCard
      fdKey={`store_${storeByIdData && storeByIdData.Name}`}
      title={(storeByIdData && storeByIdData.Name) || ''}
      href={`${match.url}/${storeId}`}
      mapQueryProps={mapProps(storeByIdData)}
    >
      {storeByIdData && !storeByIdData.IsPublished && <StoreUnpublishedLabel />}
      <Caption
        useDynamicAddress={useDynamicAddress}
        storeId={storeId}
        storeByIdData={storeByIdData}
      />
    </MapCard>
  );
});

export default StoreGroupCard;
