import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

interface YesNoDialogProps {
  disabled?: boolean;
  noButtonVariant?: 'contained' | 'outlined' | 'text';
  noTextId?: TranslationId;
  onNo(): void;
  onYes(): void;
  open: boolean;
  overflow?: string;
  titleText?: string;
  titleTextId?: TranslationId;
  yesButtonVariant?: 'contained' | 'outlined' | 'text';
  yesTextId?: TranslationId;
}

type StyleProps = {
  isYesButtonText?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  title: {
    padding: '24px 24px 12px',
  },
  content: {
    padding: '0 24px',
  },
  modalButton: {
    letterSpacing: 'normal',
  },
  noButton: {
    marginRight: (props) => (props.isYesButtonText ? '0' : '24px'),
  },
}));

const YesNoDialog: React.FC<React.PropsWithChildren<YesNoDialogProps>> = (props) => {
  const {
    children,
    disabled,
    noButtonVariant = 'text',
    noTextId = 'Cancel',
    onNo,
    onYes,
    open,
    titleText,
    overflow = 'initial',
    titleTextId,
    yesButtonVariant = 'text',
    yesTextId = 'Save',
  } = props;
  const classes = useStyles({ isYesButtonText: yesButtonVariant === 'text' });
  const padding =
    noButtonVariant !== 'text' || yesButtonVariant !== 'text' ? '24px' : '24px 16px 24px 24px';

  return (
    <>
      {open && (
        <Dialog
          aria-labelledby={titleTextId ? 'yesNo-dialog-title' : undefined}
          data-fd="yesNoDialog"
          onClose={onNo}
          open={open}
        >
          {(titleTextId || titleText) && (
            <DialogTitle className={classes.title} id="yesNo-dialog-title">
              <Typography variant="h5" component="span">
                {titleTextId ? <Translate id={titleTextId} /> : titleText}
              </Typography>
            </DialogTitle>
          )}
          <DialogContent className={classes.content} style={{ overflow }}>
            {children}
          </DialogContent>
          <DialogActions style={{ padding }}>
            <Button
              onClick={onNo}
              color="primary"
              data-fd="no_button"
              className={clsx(classes.modalButton, classes.noButton)}
              variant={noButtonVariant}
            >
              <Translate id={noTextId} />
            </Button>
            <Button
              onClick={onYes}
              color="primary"
              data-fd="yes_button"
              disabled={disabled}
              className={classes.modalButton}
              variant={yesButtonVariant}
            >
              <Translate id={yesTextId} />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default YesNoDialog;
export { DialogContentText };
