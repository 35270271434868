import * as React from 'react';

import { CreateGroup, Group } from '@flipdish/api-client-typescript';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import PageLayout from '../../../../../ui/Layout';
import { metafieldsService } from '../../../../Settings/Metafields/metafields.service';
import { catalogGroupsService } from '../../../services/catalogGroup.service';
import { GroupFormValues } from '../../../types';
import { ModifierGroupForm } from '../../components/ModifierGroupForm';

const CreateModifierGroup = ({
  AppId,
  closeNotifySaving,
  isProductBasedMenusOn,
  history,
  notifyError,
  notifySaved,
  notifySaving,
  translate,
}: MappedState & MappedDispatch & RouteComponentProps) => {
  const redirectModifierGroupDetailPage = ({ Name, CatalogItemId }: Group) => {
    const modiifierGroupUrl = window.location.pathname.replace(
      'create-modifier-group',
      CatalogItemId as string
    );
    history.push({ pathname: modiifierGroupUrl, state: { productName: Name } });
  };

  const handleCreate = async (newGroup) => {
    await mutateAsync(newGroup);
  };

  const metafieldsQuery = useQuery({
    queryKey: [metafieldsService.getMetafieldsByEntityQueryKey, AppId, 'catalogGroup'],
    queryFn: () => metafieldsService.getMetafieldsByEntity(AppId, 'catalogGroup'),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (newModifierGroup: GroupFormValues) => {
      notifySaving();
      const newGroup: CreateGroup = {
        ...newModifierGroup,
        GroupType: Group.GroupTypeEnum.ModifierGroup,
      };
      return catalogGroupsService.createCatalogGroup(AppId, newGroup);
    },

    onSuccess: (data) => {
      closeNotifySaving();
      notifySaved();
      redirectModifierGroupDetailPage(data.Data);
    },

    onError: (error: Error) => {
      closeNotifySaving();
      const errorMessage = error.message.includes('SKU already exists')
        ? 'Product_CreateProductDialog_DuplicateSkuError'
        : 'Error_please_try_again_later';
      notifyError({ message: errorMessage, translate: true });
    },
  });
  return isProductBasedMenusOn ? (
    <PageLayout
      documentTitle={'CreateNewModifierGroup'}
      strictToParent={true}
      toParent={`/${AppId}/modifier-groups`}
      title={<Translate id="CreateNewModifierGroup" />}
    >
      <ModifierGroupForm
        AppId={AppId}
        canEdit
        metafields={metafieldsQuery.data?.Data}
        onSave={handleCreate}
        translate={translate}
      />
    </PageLayout>
  ) : null;
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    AppId: currentApp.AppId as string,
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateModifierGroup);
export { EnhancedComponent as CreateModifierGroup };
