import React from 'react';

import { type Theme } from '@mui/material/styles';
import { type TableCellProps } from '@mui/material/TableCell';
import MaterialTablePagination, {
  type TablePaginationProps as MuiTablePaginationProps,
} from '@mui/material/TablePagination';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';

import { TablePaginationActions } from './TablePaginationActions';

// Extend the TablePaginationProps to include the omitted properties
interface TablePaginationProps extends Omit<MuiTablePaginationProps, 'classes' | 'component'> {
  classes?: TableCellProps['classes'];
  component?: React.ElementType<any>;
}

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 16,
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
      },
    },
    select: {
      order: 2,
      [theme.breakpoints.down('md')]: {
        '& + span': {
          position: 'absolute',
          left: '16px',
        },
      },
    },
    selectIcon: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    selectLabel: {
      order: 4,
      [theme.breakpoints.down('md')]: {
        display: 'inline',
      },
    },
    displayedRows: {
      order: 4,
      [theme.breakpoints.down('md')]: {
        display: 'inline',
      },
    },
    selectRoot: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    spacer: {
      '& + span': { order: 1 },
      order: 3,
      flex: '1 1 100%',
    },
    menuItem: {
      '&:hover': {
        backgroundColor: '#EAF2FF',
      },
    },
    actions: {
      order: 5,
      marginRight: 10,
      marginLeft: '10px !important',
    },
  });

type Props = TablePaginationProps & WithStyles<typeof styles>;

export default withStyles(styles)((props: Props) => {
  return (
    <MaterialTablePagination
      {...props}
      component={props.component || 'div'}
      // @ts-ignore
      ActionsComponent={props.ActionsComponent || TablePaginationActions}
      count={props.count}
      onPageChange={props.onPageChange}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
    />
  );
});
