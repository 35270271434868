import React, { ChangeEvent, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import axios from 'axios';
import { debounce } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { GMAP_API_KEY } from '../../../constants/map.constant';

const mapStateToProps = (state) => ({
  translate: getTranslate(state.locale),
});

type MappedProps = ReturnType<typeof mapStateToProps>;

type Coordinate = {
  lat: number;
  lng: number;
};

type Props = {
  onSearch: (result: Coordinate) => void;
} & MappedProps;

export default connect(mapStateToProps)(function ({ onSearch, translate }: Props) {
  const [value, setValue] = useState('');
  let cancelToken;

  const search = useMemo(
    () =>
      debounce((value: string) => {
        if (value) {
          if (cancelToken) {
            cancelToken.cancel();
          }

          cancelToken = axios.CancelToken.source();
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=${GMAP_API_KEY}`,
              {
                cancelToken: cancelToken.token,
              }
            )
            .then((res) => {
              if (res.data && res.data.results && res.data.results.length) {
                const result = res.data.results[0];

                if (result.geometry && result.geometry.location) {
                  onSearch(result.geometry.location);
                }
              }
            });
        }
      }, 1000),
    []
  );

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    search(value);
  };

  return (
    <TextField
      variant="standard"
      placeholder={translate('Search') as string}
      value={value}
      onChange={changeHandler}
    />
  );
});
