import React from 'react';

import { AppStoreApp, AppStoreAppConfigurationSummary } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import ListItemLink from '../../../ui/List/ListItemLink';
import Spacer from '../../../ui/Spacer';
import generateSrcSet from '../../../ui/utils/generateSrcSet';

const useStyles = makeStyles(() => ({
  appIcon: {
    alignItems: 'center',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 40,
    marginRight: 20,
  },
  icon: {
    minWidth: 'auto',
    marginRight: -8,
  },
  detailText: {
    display: 'block',
  },
}));

export type AppStoreAppCardProps = {
  appStoreApp: AppStoreAppConfigurationSummary;
  appId: string;
  isLast: boolean;
};

export const AppStoreAppConfigCard = ({ appStoreApp, appId, isLast }: AppStoreAppCardProps) => {
  const classes = useStyles();

  const AppStoreAppIcon = () => {
    return (
      <Box className={classes.appIcon}>
        {appStoreApp.Logo && (
          <img
            style={{
              width: 60,
              height: 60,
              borderRadius: '10%',
            }}
            srcSet={generateSrcSet(appStoreApp.Logo, {
              width: 60,
              png: true,
            })}
            src={appStoreApp.Logo}
            data-fd={`app_store_logo_${appStoreApp.Id}`}
          />
        )}
      </Box>
    );
  };

  const StoresDescription = () => {
    const numberToShow = 3;
    const othersCount = appStoreApp.Stores.length - numberToShow;

    if (appStoreApp.StoreSelectorType === AppStoreApp.StoreSelectorTypeEnum.None) {
      return (
        <Typography variant="subtitle1" className={classes.detailText}>
          <Translate id="All_Stores_including_new" />
        </Typography>
      );
    }

    if (appStoreApp.Stores.length === 0) {
      return (
        <Typography variant="subtitle1" className={classes.detailText}>
          <Translate id="No_stores" />
        </Typography>
      );
    }

    return (
      <Typography variant="subtitle1">
        {appStoreApp.Stores.slice(0, numberToShow)
          .map((store) => store.Name)
          .join(', ')}{' '}
        {othersCount === 1 && <Translate id="and_1_other" />}
        {othersCount > 1 && <Translate id="and_count_others" data={{ count: othersCount }} />}
      </Typography>
    );
  };

  return (
    <ListItemLink
      button
      to={`/${appId}/appstore/${appStoreApp.AppStoreAppId}/config/${appStoreApp.Id}`}
      alignItems="center"
      fdKey={appStoreApp.AppStoreAppId}
      divider={!isLast}
    >
      <ListItemAvatar>
        <AppStoreAppIcon />
      </ListItemAvatar>

      <ListItemText
        primary={<StoresDescription />}
        secondary={
          <Typography variant="caption" className={classes.detailText}>
            <>
              {appStoreApp.IsEnabled && <Translate id="Enabled" />}
              {appStoreApp.IsEnabled || <Translate id="Disabled" />}
            </>
          </Typography>
        }
      />

      <ListIcon className={classes.icon}>
        <Spacer size={8}>
          <KeyboardArrowRight color="action" />
        </Spacer>
      </ListIcon>
    </ListItemLink>
  );
};
