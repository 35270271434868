import React from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formatCalendarDate } from '../../../helpers/dateFormats';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { enumMapping } from '../Device.selectors';

type OuterProps = { device: HydraDeviceDetails };
type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const DeviceListDetails = (props: Props) => {
  const { device, dtUtils, translate } = props;
  const localUserTime =
    device &&
    device.LastPollUtc &&
    dtUtils.utcToZonedTime(device.LastPollUtc, dtUtils.userIanaTimeZone);

  return (
    <ListItemText
      primary={
        <Typography variant="subtitle1">
          <Translate id={enumMapping(device.DeviceType)} />
          {enumMapping(device.DeviceType) ===
            HydraDeviceDetails.DeviceTypeEnum.Terminal.toString() &&
            device.DeviceName &&
            `, ${device.DeviceName}`}
        </Typography>
      }
      secondary={
        <>
          {device.StoreNames &&
            device.StoreNames.map((store, index) => {
              if (store.StoreName !== null) {
                return (
                  <Typography key={store.StoreId} variant="caption">
                    {`${store.StoreName}, `}
                  </Typography>
                );
              } else {
                return null;
              }
            })}

          {device.Status && (
            <Typography variant="caption">
              <Translate id={`${device.Status}` as 'Online'} />
            </Typography>
          )}
          {localUserTime && (
            <Typography variant="caption">
              {', '}
              <Translate id={'Last_polled'} />
              {formatCalendarDate(dtUtils, localUserTime, translate)}
            </Typography>
          )}
          <Typography variant="caption" style={{ display: 'block' }}>
            <Translate id={'Device_ID'} />: {` ${device.DeviceId}`}
          </Typography>
          {device.SerialNumber && (
            <Typography variant="caption" style={{ display: 'block' }}>
              SN: {` ${device.SerialNumber}`}
            </Typography>
          )}
          {device.Version && (
            <Typography variant="caption" style={{ display: 'block' }}>
              <Translate id={'Version'} />: {` ${device.Version}`}
            </Typography>
          )}
        </>
      }
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  dtUtils: dateTimeUtils(state),
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, null))(DeviceListDetails);
