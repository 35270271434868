import React from 'react';

import { StripeConnectedAccountInfo } from '@flipdish/api-client-typescript';
import { useMutation } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../../layouts/Notify/actions';
import { bankingServices } from '../../../../../services/banking.service';
import { Button } from '../../../../../ui/Button';

export type OuterProps = {
  appId: string;
  stripeAccount: StripeConnectedAccountInfo;
  getBankAccountDetails: () => void;
};

type Props = OuterProps & MappedDispatch;

const ManualAccountVerification = (props: Props) => {
  const {
    appId,
    closeNotifySaving,
    getBankAccountDetails,
    notifyError,
    notifySaved,
    notifySaving,
    stripeAccount,
  } = props;
  const { StripeId, PayoutScheduleInterval } = stripeAccount;

  const isPayoutScheduleIntervalManual =
    PayoutScheduleInterval &&
    PayoutScheduleInterval === StripeConnectedAccountInfo.PayoutScheduleIntervalEnum.Manual;

  const { mutateAsync: approvePayoutSchedule, isPending } = useMutation({
    mutationFn: () => {
      notifySaving();

      return bankingServices.updatePayoutSchedule(appId, StripeId!, 'Weekly');
    },

    onSuccess: () => {
      getBankAccountDetails();
      closeNotifySaving();
      notifySaved();
    },

    onError: (error) => {
      closeNotifySaving();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const { mutateAsync: disapprovePayoutSchedule, isPending: isLoadingDisapprove } = useMutation({
    mutationFn: () => {
      notifySaving();

      return bankingServices.updatePayoutSchedule(appId, StripeId!, 'Manual');
    },

    onSuccess: () => {
      getBankAccountDetails();
      closeNotifySaving();
      notifySaved();
    },

    onError: (error) => {
      closeNotifySaving();
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  return (
    <>
      {isPayoutScheduleIntervalManual ? (
        <Button
          disabled={isPending || isLoadingDisapprove}
          fdKey={'approve-for-payouts'}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => approvePayoutSchedule()}
        >
          <Translate id="Approve_for_payouts" />
        </Button>
      ) : (
        <Button
          disabled={isLoadingDisapprove || isPending}
          fdKey={'disapprove-for-payouts'}
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => disapprovePayoutSchedule()}
        >
          <Translate id="Disapprove_for_payouts" />
        </Button>
      )}
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(ManualAccountVerification);
export { EnhancedComponent as ManualAccountVerification };
