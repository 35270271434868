import React from 'react';

import { StoreGroupExtended } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { createStoreGroupSelectorById } from '../../selectors/storegroup.selector';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: IWithStoreGroupByIdDataProps) => {
  const storeGroupId = Number(ownProps.storeGroupId);
  const storeGroupSelector = createStoreGroupSelectorById(storeGroupId);
  return (state: AppState) => {
    const data = storeGroupSelector(state) as Required<StoreGroupExtended> | undefined;
    const storeCount = data ? data.TotalStores : undefined;
    return {
      storeGroupByIdData: data,
      storeGroupByIdDataStoreCount: storeCount,
    };
  };
};

type UnpackType<T> = T extends React.ComponentType<React.PropsWithChildren<infer P>> ? P : T;
export type WithStoreGroupByIdData = UnpackType<ReturnType<typeof withStoreGroupByIdData>> & {
  dispatch: ThunkDispatch;
};
export interface IWithStoreGroupByIdDataProps {
  storeGroupId: number;
}

const withStoreGroupByIdData = <P extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<P & MappedState>>
) =>
  compose<P & MappedState, P & MappedState & IWithStoreGroupByIdDataProps>(
    setDisplayName('WithStoreGroupByIdData'),
    connect(mapStateToPropsFactory, null)
  )(Component);

export default withStoreGroupByIdData;
