import React from 'react';

import { Translate } from 'react-localize-redux';
import { compose } from 'recompose';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { AddRestaurantVoucherForm } from '../components/AddRestaurantVoucherForm';

type Props = {};
const RestaurantVoucherCreate = (props: Props) => {
  return (
    <PageLayout
      toParent="./"
      title={<Translate id="Add_PayGreen_account" />}
      documentTitle="Add_PayGreen_account"
    >
      <PaperContainer>
        <AddRestaurantVoucherForm />
      </PaperContainer>
    </PageLayout>
  );
};

export default compose<Props, {}>()(RestaurantVoucherCreate);
