import React, { useEffect } from 'react';

import Paper from '@mui/material/Paper';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedCurrency } from 'fd-react-intl';
import isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getPayoutSummaryData } from '../payouts.actions';
import { PayoutStatus, PayoutSummaryItem, PayoutSummaryParams, PeriodProps } from '../types';

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {
  accountIds: number[] | undefined;
  currentPeriodFilter: PeriodProps;
  payoutStates: PayoutStatus[] | undefined;
};

type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.18)',
      borderRadius: '0px',
    },
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  summaryContainer: {
    width: '50%',
    padding: '24px 0 24px 24px',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0 16px 16px',
    },
  },
  totalAmount: {
    padding: '8px 0 4px 0',
    [theme.breakpoints.down('md')]: {
      padding: '4px 0 4px 0',
      fontSize: '16px',
    },
  },
  skeletonPaper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

const renderSummarySection = (classes, payoutTableSummary, languageCode) => {
  const summaryFields = payoutTableSummary.map(function (row: PayoutSummaryItem, index: number) {
    const pendingCount = row.pending?.totalCount || 0;
    const completeCount = row.complete?.totalCount || 0;
    if (isEmpty(row)) {
      return;
    }
    return (
      <div className={classes.containerRow} key={index}>
        <div className={classes.summaryContainer}>
          <Typography variant="caption">
            <Translate id={'Total_amount_paid'} />
          </Typography>
          <Typography variant="h5" className={classes.totalAmount} data-fd="total-amount-paid">
            <FormattedCurrency
              value={row.complete?.totalAmount || 0}
              locales={languageCode}
              currency={row.currency}
            />
          </Typography>
          <Typography variant="caption" data-fd="total-pending">
            <Translate data={{ totalPayments: completeCount }} id={'Total_payments'} />
          </Typography>
        </div>
        <div className={classes.summaryContainer}>
          <Typography variant="caption">
            <Translate id={'Total_amount_pending'} />
          </Typography>
          <Typography variant="h5" className={classes.totalAmount} data-fd="total-amount-pending">
            <FormattedCurrency
              value={row.pending?.totalAmount || 0}
              locales={languageCode}
              currency={row.currency}
            />
          </Typography>
          <Typography variant="caption" data-fd="total-payments">
            <Translate data={{ totalPayments: pendingCount }} id={'Total_payments'} />
          </Typography>
        </div>
      </div>
    );
  });
  return summaryFields;
};

const TableSummary = (props: Props) => {
  const {
    accountIds,
    currentPeriodFilter,
    getSummaryData,
    languageCode,
    payoutStates,
    payoutSummaryLoading,
    payoutTableSummary,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const payoutSummaryParams = {
      bankAccountIds: accountIds,
      endDate: currentPeriodFilter.endDate,
      states: payoutStates,
      startDate: currentPeriodFilter.startDate,
    } as PayoutSummaryParams;
    getPayoutSummary(payoutSummaryParams);
  }, [accountIds, currentPeriodFilter, payoutStates]);

  const getPayoutSummary = async (payoutSummaryParams: PayoutSummaryParams) => {
    try {
      getSummaryData(payoutSummaryParams);
    } catch (err) {
      console.log(new Error(err));
    }
  };

  return (
    <>
      {payoutSummaryLoading ? (
        <>
          <Paper className={classes.skeletonPaper}>
            <Skeleton height={76} />
          </Paper>
        </>
      ) : (
        <Paper elevation={isMobile ? 0 : 1} className={classes.container}>
          {renderSummarySection(classes, payoutTableSummary, languageCode)}
        </Paper>
      )}
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { payoutTableSummary, payoutSummaryLoading } = state.payouts;
  return {
    languageCode: getActiveLanguage(state.locale),
    payoutTableSummary,
    payoutSummaryLoading,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getSummaryData: (payoutSummaryParams: PayoutSummaryParams) =>
    dispatch(getPayoutSummaryData(payoutSummaryParams)),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  TableSummary
);
