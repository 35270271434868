import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import Button from '../../../../ui/Button/Button';
import { LoadingButton } from '../../../../ui/LoadingButton';

const useStyles = makeStyles({
  zeroPadding: { paddingTop: 0, paddingBottom: 0 },
  zeroMargin: { marginTop: 0, marginBottom: 0 },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
});

interface IAccountRemoveDialog {
  storeNames?: string[];
  handleRemove: () => Promise<void>;
}

type Props = IAccountRemoveDialog & FormikProps<{}>;
const RemoveBankAccountButton = (props: Props) => {
  const { storeNames, handleSubmit, isSubmitting } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const hasStores = storeNames && storeNames.length > 0;

  return (
    <Permissions allowed={[App.AppResourceSetEnum.UpdateBankAccounts]}>
      <Button variant={'text'} fdKey={`remove-account`} color="secondary" onClick={openDialog}>
        <Translate id="Remove_account" />
      </Button>

      <Dialog open={open} onClose={closeDialog} aria-labelledby="Delete_bank_account">
        <DialogTitle id="Delete_bank_account">
          <Translate id="Delete_bank_account" />
        </DialogTitle>

        <DialogContent>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              {hasStores ? (
                <Translate id="bank_account_is_currently_assigned" />
              ) : (
                <Translate id="Pressing_delete_will_remove" />
              )}
            </Typography>

            {hasStores && (
              <List dense>
                {storeNames!.map((storeName, index) => (
                  <ListItem disableGutters className={classes.zeroPadding} key={index}>
                    <ListItemText
                      className={`${classes.zeroPadding} ${classes.zeroMargin}`}
                      primary={storeName}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            <Typography variant="body1" color="textSecondary">
              {hasStores ? (
                <Translate id="cannot_transfer_money_until_bank_account_reassigned" />
              ) : (
                <>
                  {' '}
                  <Translate id="this_bank_account_for_good" />
                </>
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.addPadding}>
          <Button
            variant={'text'}
            fdKey={`cancel-remove-account`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-delete-account`}
            onClick={() => {
              handleSubmit();
            }}
            color="secondary"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Translate id="Delete" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Permissions>
  );
};

export default compose<Props, IAccountRemoveDialog>(
  withFormik<IAccountRemoveDialog, {}>({
    displayName: 'RemoveBankAccount',
    handleSubmit: (values, formikBag) => {
      return formikBag.props.handleRemove().catch((err) => {
        formikBag.setSubmitting(false);
      });
    },
  })
)(RemoveBankAccountButton);
