import { TranslateFunction } from 'react-localize-redux';

import { RestaurantVoucherFormValues } from './RestaurantVouchers/components/AddRestaurantVoucherForm';
import { RESTAURANT_VOUCHERS_MAX_LENGTH } from './RestaurantVouchers/constants';

export const validateRestaurantVoucherForm = (
  values,
  translate: TranslateFunction
): Partial<RestaurantVoucherFormValues> => {
  const { PayGreenId, PayGreenPrivateKey, Name } = values;

  const errors: Partial<RestaurantVoucherFormValues> = {};
  if (!PayGreenId) {
    errors.PayGreenId = translate('Required') as string;
  }
  if (PayGreenId.length > RESTAURANT_VOUCHERS_MAX_LENGTH) {
    errors.PayGreenId = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: RESTAURANT_VOUCHERS_MAX_LENGTH,
    }) as string;
  }
  if (!PayGreenPrivateKey) {
    errors.PayGreenPrivateKey = translate('Required') as string;
  }
  if (PayGreenPrivateKey.length > RESTAURANT_VOUCHERS_MAX_LENGTH) {
    errors.PayGreenPrivateKey = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: RESTAURANT_VOUCHERS_MAX_LENGTH,
    }) as string;
  }
  if (!Name) {
    errors.Name = translate('Required') as string;
  }
  if (Name.length > RESTAURANT_VOUCHERS_MAX_LENGTH) {
    errors.Name = translate('Max_fieldname_length_exceeded', {
      fieldName: '',
      max: RESTAURANT_VOUCHERS_MAX_LENGTH,
    }) as string;
  }

  return errors;
};
