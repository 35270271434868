import React, { useEffect } from 'react';

import { BluetoothTerminalStatus } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { connect } from 'react-redux';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { KioskCardReaderEvents } from '../../../signalr/hub.actions';
import KioskDetailSection from '../Components/KioskDetailSection';
import { resetKioskBluetoothTerminalUpdated } from '../Kiosks.actions';
import { kioskSelectors } from '../Kiosks.selectors';
import { kioskServices } from '../Kiosks.services';
import { StripeSkeletonLoader } from './StripeSkeletonLoader';
import StripeTerminalDetails from './StripeTerminalDetails';
import StripeTerminalPairingButton from './StripeTerminalPairingButton';
import StripeTerminalRemoveButton from './StripeTerminalRemoveButton';
import StripeTerminalSerialNumber from './StripeTerminalSerialNumber';

type InnerProps = MappedState & MappedDispatch;
type OuterProps = {
  appId: string;
  deviceId?: string;
};
type Props = InnerProps & OuterProps;

const StripeTerminal = ({
  appId,
  deviceId,
  kioskBluetoothTerminalUpdated,
  kioskBluetoothTerminalUnpaired,
  notifyError,
  resetKioskBluetoothTerminalUpdated,
  subscribeToKioskCardReaderEvents,
  unsubscribeFromKioskCardReaderEvents,
}: Props) => {
  const queryClient = useQueryClient();

  const { data, isPending, isError } = useQuery({
    queryKey: [kioskServices.getStripeTerminalDetailsQueryKey, appId, deviceId],
    queryFn: () => kioskServices.getStripeTerminalBluetoothStatus(appId, deviceId),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_getting_Stripe_Terminal_details', translate: true });
    }
  }, [isError]);

  useEffect(() => {
    subscribeToKioskCardReaderEvents();
    return () => {
      unsubscribeFromKioskCardReaderEvents();
    };
  }, []);

  useEffect(() => {
    return () => {
      resetKioskBluetoothTerminalUpdated();
    };
  }, []);

  useEffect(() => {
    if (kioskBluetoothTerminalUpdated && Object.keys(kioskBluetoothTerminalUpdated).length) {
      queryClient.setQueryData(
        ['getStripeTerminalDetails', appId, deviceId],
        kioskBluetoothTerminalUpdated
      );
    }
  }, [kioskBluetoothTerminalUpdated]);

  useEffect(() => {
    // BT events are also used by non BT card readers too
    if (kioskBluetoothTerminalUnpaired) {
      queryClient.setQueryData(['getStripeTerminalDetails', appId, deviceId], undefined);
    }
  }, [kioskBluetoothTerminalUnpaired]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <KioskDetailSection
          alignChildren="bottom"
          description={'Accept_chip_pin_and_contactless_payments_and_more'}
          isLoading={isPending}
          title="Card_reader"
        >
          {isPending ? (
            <StripeSkeletonLoader />
          ) : (
            <>
              <StripeTerminalSerialNumber
                serialNumber={data?.SerialNumber}
                deviceType={data?.DeviceType}
              />
              {data && (
                <StripeTerminalDetails
                  appId={appId}
                  deviceId={deviceId}
                  stripeTerminalDetails={data}
                />
              )}
            </>
          )}
        </KioskDetailSection>

        <KioskDetailSection
          alignChildren="right"
          description={'Connect_card_reader_info'}
          isLoading={isPending}
          title="Connect_card_reader"
        >
          <StripeTerminalPairingButton appId={appId} deviceId={deviceId} loading={isPending} />
        </KioskDetailSection>

        {data?.DeviceType && (
          <KioskDetailSection
            alignChildren="right"
            description={
              data?.DeviceType === BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X
                ? 'Disconnect_card_reader_info'
                : 'Unregister_the_currently_connected_reader'
            }
            isLoading={isPending}
            title={
              data?.DeviceType === BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X
                ? 'Disconnect_card_reader'
                : 'Unregister_card_reader'
            }
          >
            <StripeTerminalRemoveButton
              appId={appId}
              deviceId={deviceId}
              loading={isPending}
              readerId={data?.ReaderId}
              terminalType={data?.DeviceType}
            />
          </KioskDetailSection>
        )}
      </Grid>
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  kioskBluetoothTerminalUpdated: kioskSelectors.getKioskBluetoothTerminalUpdated(state),
  kioskBluetoothTerminalUnpaired: kioskSelectors.getKioskBluetoothTerminalUnpaired(state),
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
    resetKioskBluetoothTerminalUpdated: () => dispatch(resetKioskBluetoothTerminalUpdated()),
    subscribeToKioskCardReaderEvents: () => {
      dispatch(KioskCardReaderEvents.Subscribe.Updated);
      dispatch(KioskCardReaderEvents.Subscribe.Unpaired);
    },
    unsubscribeFromKioskCardReaderEvents: () => {
      dispatch(KioskCardReaderEvents.Unsubscribe.Updated);
      dispatch(KioskCardReaderEvents.Unsubscribe.Unpaired);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeTerminal);
