import React, { useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { notify, notifyError } from '../../../../layouts/Notify/actions';
import { storeService } from '../../../../services/store.service';
import * as actions from '../actions';
import { PartialStoreKioskSetting } from './ListItem';

export type OptionsProps = {
  ariaLabel?: TranslationId;
  defaultValueResolver: (data: FlipdishPrivate.StoreKioskSetting) => string;
  disabledValueResolver: (data: FlipdishPrivate.StoreKioskSetting) => boolean;
  onChange: (s: FlipdishPrivate.StoreKioskSetting) => PartialStoreKioskSetting;
  radioButtonLabels: Array<{ label: TranslationId; value: string }>;
  storeKioskSettings?: FlipdishPrivate.StoreKioskSetting;
};

type Props = MappedDispatch & OptionsProps;

const Options = ({
  ariaLabel,
  defaultValueResolver,
  disabledValueResolver,
  notifyError,
  notifySaved,
  onChange,
  radioButtonLabels,
  storeKioskSettings,
  undoUpdateState,
  updateState,
}: Props) => {
  const handleChange = useCallback(() => {
    if (storeKioskSettings) {
      const obj = onChange(storeKioskSettings);
      updateState(obj);
      mutate(obj);
    }
  }, [storeKioskSettings, onChange]);

  const { mutate } = useMutation({
    mutationFn: (obj: PartialStoreKioskSetting) =>
      storeService.updateKioskSettings(obj.PhysicalRestaurantId, obj),

    onSuccess: () => {
      notifySaved();
    },

    onError: (_data, obj) => {
      undoUpdateState(obj);
      notifyError();
    },
  });

  return (
    <FormControl
      component="fieldset"
      style={{
        marginBottom: 24,
      }}
    >
      <RadioGroup
        aria-label={ariaLabel}
        defaultValue={defaultValueResolver(storeKioskSettings!)}
        name={ariaLabel}
        onChange={handleChange}
      >
        {radioButtonLabels.map(({ label, value }, idx) => (
          <FormControlLabel
            control={<Radio data-fd={label} color={'primary'} />}
            disabled={disabledValueResolver(storeKioskSettings!)}
            key={idx}
            label={
              <Typography variant="body2">
                <Translate id={label} />
              </Typography>
            }
            value={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: () => dispatch(notifyError({ message: 'Modification_failed', translate: true })),
  notifySaved: () =>
    dispatch(
      notify({
        message: 'Successfully_modified' as TranslationId,
        variant: 'success',
        translate: true,
      })
    ),
  updateState: (config: PartialStoreKioskSetting) => {
    dispatch(actions.updateStoreKioskSetting(config));
  },
  undoUpdateState: (config: PartialStoreKioskSetting) => {
    // just revert boolean value we passing
    const undoConfig = Object.entries(config).reduce((agg, [key, val]) => {
      if (typeof val == 'boolean') {
        agg[key] = !val;
      } else {
        agg[key] = val;
      }
      return agg;
    }, {} as PartialStoreKioskSetting);
    dispatch(actions.updateStoreKioskSetting(undoConfig));
  },
});

export default connect(null, mapDispatchToProps)(Options);
