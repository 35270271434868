import React from 'react';

import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { type LinkProps, Link } from 'react-router-dom';

type ILinkIconButtonProps = IconButtonProps & LinkProps & { fdKey: string; label: string };
const ButtonLink = IconButton as React.ComponentType<React.PropsWithChildren<any>>;

const LinkIconButton: React.FC<React.PropsWithChildren<ILinkIconButtonProps>> = ({
  fdKey,
  label,
  ...rest
}) => {
  return <ButtonLink {...rest} data-fd={fdKey} component={Link} aria-label={label} />;
};

export default LinkIconButton;
