import { OAuthApp as OAuthClient } from '@flipdish/api-client-typescript';

import { action, actionError } from '../../../actions/utils';
import { notify } from '../../../layouts/Notify/actions';
import { developersService } from '../../../services/developers.service';

// Region getOAuthApps
export const GET_OAUTHAPPS_REQUEST = 'GET_OAUTHAPPS_REQUEST';
export const GET_OAUTHAPPS_SUCCESS = 'GET_OAUTHAPPS_SUCCESS';
export const GET_OAUTHAPPS_FAILURE = 'GET_OAUTHAPPS_FAILURE';

export type GetOAuthAppsRequest = ReturnType<typeof getOAuthAppsRequest>;
export const getOAuthAppsRequest = (oauthAppName?: string) =>
  action(GET_OAUTHAPPS_REQUEST, { oauthAppName, isLoading: true });

export type GetOAuthAppsSuccess = ReturnType<typeof getOAuthAppsSuccess>;
export const getOAuthAppsSuccess = (oauthApps: OAuthClient[], oauthAppName?: string) =>
  action(GET_OAUTHAPPS_SUCCESS, { oauthAppName, oauthApps, isLoading: false });

export type GetOAuthAppsFailure = ReturnType<typeof getOAuthAppsFailure>;
export const getOAuthAppsFailure = (error: Error, oauthAppName?: string) =>
  actionError(GET_OAUTHAPPS_FAILURE, { oauthAppName, isLoading: false }, { error });

export function getOAuthApps(oauthAppName?: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppsRequest(oauthAppName));
      const result = await developersService.getOauthApps(appId, oauthAppName);
      setTimeout(() => {
        dispatch(getOAuthAppsSuccess(result, oauthAppName));
      }, 750);
    } catch (error) {
      dispatch(getOAuthAppsFailure(error, oauthAppName));
    }
  };
}
// End region

// Region getOAuthAppById
export const GET_OAUTHAPP_BY_ID_REQUEST = 'GET_OAUTHAPP_BY_ID_REQUEST';
export const GET_OAUTHAPP_BY_ID_SUCCESS = 'GET_OAUTHAPP_BY_ID_SUCCESS';
export const GET_OAUTHAPP_BY_ID_FAILURE = 'GET_OAUTHAPP_BY_ID_FAILURE';

export type GetOAuthAppByIdRequest = ReturnType<typeof getOAuthAppByIdRequest>;
export const getOAuthAppByIdRequest = (appId: string, oauthAppId: string) =>
  action(GET_OAUTHAPP_BY_ID_REQUEST, { appId, oauthAppId, isLoading: true });

export type GetOAuthAppByIdSuccess = ReturnType<typeof getOAuthAppByIdSuccess>;
export const getOAuthAppByIdSuccess = (oauthApp: OAuthClient) =>
  action(GET_OAUTHAPP_BY_ID_SUCCESS, { oauthApp, isLoading: false });

export type GetOAuthAppByIdFailure = ReturnType<typeof getOAuthAppByIdFailure>;
export const getOAuthAppByIdFailure = (error: Error) =>
  actionError(GET_OAUTHAPP_BY_ID_FAILURE, { isLoading: true }, { error });

export function getOAuthAppById(clientId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppByIdRequest(appId, clientId));
      const result = await developersService.getOauthAppById(clientId, appId);
      dispatch(getOAuthAppByIdSuccess(result));
    } catch (error) {
      dispatch(getOAuthAppByIdFailure(error));
    }
  };
}
// End region

// Region getSecretKey
export const GET_OAUTHAPP_SECRET_KEY_REQUEST = 'GET_OAUTHAPP_SECRET_KEY_REQUEST';
export const GET_OAUTHAPP_SECRET_KEY_SUCCESS = 'GET_OAUTHAPP_SECRET_KEY_SUCCESS';
export const GET_OAUTHAPP_SECRET_KEY_FAILURE = 'GET_OAUTHAPP_SECRET_KEY_FAILURE';

export type GetOAuthAppSecretKeyRequest = ReturnType<typeof getOAuthAppSecretKeyRequest>;
export const getOAuthAppSecretKeyRequest = (appId: string, clientId: string) =>
  action(GET_OAUTHAPP_SECRET_KEY_REQUEST, { appId, clientId });

export type GetOAuthAppSecretKeySuccess = ReturnType<typeof getOAuthAppSecretKeySuccess>;
export const getOAuthAppSecretKeySuccess = (appId: string, clientId: string, secretKey: string) =>
  action(GET_OAUTHAPP_SECRET_KEY_SUCCESS, { appId, clientId, secretKey });

export type GetOAuthAppSecretKeyFailure = ReturnType<typeof getOAuthAppSecretKeyFailure>;
export const getOAuthAppSecretKeyFailure = (error: Error, clientId: string) =>
  action(GET_OAUTHAPP_SECRET_KEY_FAILURE, error, clientId);

export function getOAuthAppSecretKey(clientId: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getOAuthAppSecretKeyRequest(appId, clientId));
      const result = await developersService.getOauthAppSecretKey(clientId, appId);
      dispatch(getOAuthAppSecretKeySuccess(appId, clientId, result));
    } catch (error) {
      dispatch(getOAuthAppSecretKeyFailure(error, clientId));
    }
  };
}
// End region

// Region createOAuthApp
export const CREATE_OAUTHAPP_REQUEST = 'CREATE_OAUTHAPP_REQUEST';
export const CREATE_OAUTHAPP_SUCCESS = 'CREATE_OAUTHAPP_SUCCESS';
export const CREATE_OAUTHAPP_FAILURE = 'CREATE_OAUTHAPP_FAILURE';

export type CreateOAuthAppRequest = ReturnType<typeof createOAuthAppRequest>;
export const createOAuthAppRequest = (appId: string, oauthApp: OAuthClient) =>
  action(CREATE_OAUTHAPP_REQUEST, { appId, oauthApp, isLoading: true });

export type CreateOAuthAppSuccess = ReturnType<typeof createOAuthAppSuccess>;
export const createOAuthAppSuccess = () => action(CREATE_OAUTHAPP_SUCCESS, { isLoading: true });

export type CreateOAuthAppFailure = ReturnType<typeof createOAuthAppFailure>;
export const createOAuthAppFailure = (error: Error) =>
  actionError(CREATE_OAUTHAPP_FAILURE, { isLoading: false }, { error });

export function createOAuthApp(oauthApp: OAuthClient, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(createOAuthAppRequest(appId, oauthApp));
      await developersService.createOauthApp(oauthApp, appId);
      dispatch(createOAuthAppSuccess());
      dispatch(notify({ message: 'Success', variant: 'success' }));
      dispatch(getOAuthApps());
    } catch (error) {
      dispatch(createOAuthAppFailure(error));
      if (throwError) {
        throw error;
      }
    }
  };
}
// End region

// Region deleteOAuthApp
export const DELETE_OAUTHAPP = 'DELETE_OAUTHAPP';
export const DELETE_OAUTHAPP_REQUEST = `${DELETE_OAUTHAPP}_REQUEST`;
export const DELETE_OAUTHAPP_SUCCESS = `${DELETE_OAUTHAPP}_SUCCESS`;
export const DELETE_OAUTHAPP_FAILURE = `${DELETE_OAUTHAPP}_FAILURE`;

export type DeleteOAuthAppRequest = ReturnType<typeof deleteOAuthAppRequest>;
export const deleteOAuthAppRequest = (oauthAppId: string) =>
  action(DELETE_OAUTHAPP_REQUEST, oauthAppId);

export type DeleteOAuthAppSuccess = ReturnType<typeof deleteOAuthAppSuccess>;
export const deleteOAuthAppSuccess = () => action(DELETE_OAUTHAPP_SUCCESS);

export type DeleteOAuthAppFailure = ReturnType<typeof deleteOAuthAppFailure>;
export const deleteOAuthAppFailure = (error: Error) =>
  actionError(DELETE_OAUTHAPP_FAILURE, { isLoading: false }, { error });

export function deleteOAuthApp(oauthAppId: string, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(deleteOAuthAppRequest(oauthAppId));
      await developersService.deleteOauthApp(oauthAppId, appId);
      dispatch(deleteOAuthAppSuccess());
      dispatch(notify({ message: 'Success', variant: 'success' }));
    } catch (error) {
      dispatch(deleteOAuthAppFailure(error));
      if (throwError) {
        throw error;
      }
    }
  };
}
// End region
