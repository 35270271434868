import {
  BusinessHoursOverride,
  HomeAction,
  Store,
  StoreDataPoint,
} from '@flipdish/api-client-typescript';
import { formatCurrency } from 'fd-react-intl';
import memoizeOne from 'memoize-one';
import moment from 'moment-timezone';
import { getActiveLanguage, getTranslate, Language, TranslateFunction } from 'react-localize-redux';
import { createSelector } from 'reselect';

import { STORE_LOAD_BY_APP } from '../../constants/store.constants';
import { createLoadingSelector } from '../../selectors/loading.selector';
import { DateTimeUtils, dateTimeUtils } from '../../selectors/localeDateTime.selector';

const accountStateSelector = (state: AppState) => state.account;

// #region getTranslate
const translateSelector = (state: AppState) => getTranslate(state.locale);
// #endregion

const permissionsSelector = (state: AppState) => state.permissions;
export const showHiddenFeatureSelector = createSelector([permissionsSelector], (permissions) => {
  return permissions.some((p) => p === 'ShowHiddenFeatures');
});

// #region getWelcomeTitle
export const getWelcomeTitle = createSelector(
  [accountStateSelector, translateSelector],
  (account, translate) => {
    if (!account) {
      return '';
    }
    const accountName = () => {
      if (account.Name && account.Name.length > 47) {
        return account?.Name?.substring(0, 47) + '...';
      }
      return account.Name;
    };

    if (account.Email !== account.Name && !!account.Name) {
      return `${translate('Welcome')} ${accountName()}`;
    }

    return translate('Welcome') as string;
  }
);
// #endregion

// #region getSetupConfigs
const sentenceCase = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const getSetupConfigs = createSelector(
  [(state: AppState) => state.home.setupConfigs],
  (setupConfigs) => {
    return setupConfigs.data.reduce<{ cards: HomeAction[]; topCard?: HomeAction }>(
      (agg, cfg, idx) => {
        if (cfg.TitleKey === 'website_link_title') {
          agg.topCard = {
            ...cfg,
            ActionKey: sentenceCase(cfg.ActionKey),
            TitleKey: sentenceCase(cfg.TitleKey),
            DescriptionKey: sentenceCase(cfg.DescriptionKey),
          };
        } else {
          agg.cards.push({
            ...cfg,
            ActionKey: sentenceCase(cfg.ActionKey),
            TitleKey: sentenceCase(cfg.TitleKey),
            DescriptionKey: sentenceCase(cfg.DescriptionKey),
          });
        }

        return agg;
      },
      { cards: [], topCard: undefined }
    );
  }
);

// #endregio

// #region getStores

export const getIsGetStoresLoading = createLoadingSelector([STORE_LOAD_BY_APP]);

// const dbStoreIndexSelector = (state: AppState) => state.orm.FlipdishStore.items;
const dbStoresSelector = (state: AppState) => state.orm.FlipdishStore.itemsById;
const storeIndexSelector = (state: AppState) => state.home.stores.order_index;
const storeByIdSelectorFactory =
  (storeId: number) =>
  (state: AppState): Store | undefined =>
    state.orm.FlipdishStore.itemsById[storeId];

export const getStores = createSelector(
  [storeIndexSelector, dbStoresSelector],
  (storeIds, stores) => {
    return storeIds.map((storeId) => stores[storeId]);
  }
);

export const getStoreByIdFactory = (storeId: number) =>
  createSelector([dbStoresSelector], (stores) => {
    return stores[storeId];
  });

const netSalesFactory = (storeId: number) => (state: AppState) => state.home.netSales.data[storeId];

const getDatesBetween = memoizeOne((startDate: string, endDate: string) => {
  const dates: Array<{ key: string; Date: moment.Moment }> = [];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push({
      key: currDate.format(),
      Date: currDate.clone(),
    });

    currDate.add(1, 'days');
  }

  return dates;
});

export type StoreWithNetSales = ReturnType<typeof getStoreWithNetSales>;
const getStoreWithNetSales = (store?: Store, sales?: StoreDataPoint[], lang?: Language) => {
  if (!store || !sales || !sales.length || !lang) {
    return;
  }

  const startDate = moment().add(-14, 'days').format('YYYY-MM-DDT00:00:00');
  const endDate = moment().format('YYYY-MM-DDT00:00:00');
  const dates = getDatesBetween(startDate, endDate);

  const formattedSales = dates.map((d) => {
    // Safari and Chrome parse the dates differently,
    // So in safari the time is an hour ahead
    const anHour = 3600000; // 1000 * 60 * 60

    const s = sales.find((s) => Math.abs(moment(s.Day).diff(d.Date)) <= anHour) || {
      Day: d.Date.toDate(),
      Value: 0,
    };

    return {
      ...s,
      Date: d.Date,
    };
  });
  const total = formattedSales.reduce((agg, s) => agg + s.Value!, 0);

  const prevWeek = formattedSales.slice(0, 7);
  const prevWeekMax = Math.max(...prevWeek.map((s) => s.Value!));
  const prevWeekTotal = prevWeek.reduce((agg, s) => agg + s.Value!, 0);

  const currWeek = formattedSales.slice(7, 14);
  const currWeekMax = Math.max(...currWeek.map((s) => s.Value!));
  const currWeekTotal = currWeek.reduce((agg, s) => agg + s.Value!, 0);

  const formatCurrencyNoFractions = (value: number) =>
    formatCurrency(value, lang, {
      currency: store.Currency!.toString(),
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  return {
    ...store,
    NetSales: {
      Total: total,
      CurrWeek: currWeek,
      CurrWeekMax: currWeekMax,
      CurrWeekTotal: currWeekTotal,
      PrevWeek: prevWeek,
      PrevWeekMax: prevWeekMax,
      PrevWeekTotal: prevWeekTotal,
      formatCurrency: formatCurrencyNoFractions,
    },
  };
};
export const getStoreByIdFactoryWithNetSales = (storeId: number) => {
  const storeSelected = getStoreByIdFactory(storeId);
  const netSales = netSalesFactory(storeId);
  return createSelector(
    [storeSelected, netSales, (state: AppState) => getActiveLanguage(state.locale)],
    getStoreWithNetSales
  );
};
// #endregion
// #region getStoreOverrides
const MAX_SHOWN_OVERRIDES = 2;

type OverridesState = ReturnType<ReturnType<typeof overridesSelectorFactory>>;
const overridesSelectorFactory = (storeId: number) => (state: AppState) =>
  state.home.stores.overrides.data[storeId];

type StoreOverridesCombined = ReturnType<typeof combineStoreOverrides>;
const combineStoreOverrides = (
  store: Store | undefined,
  overrides: OverridesState,
  dtUtils: DateTimeUtils
) => {
  if (!store || !overrides) {
    return [];
  }

  const now = new Date();
  const active = overrides.filter(
    (o) =>
      dtUtils.isAfter(now, o.StartTime as unknown as Date) &&
      dtUtils.isBefore(now, o.EndTime as unknown as Date)
  );
  if (!active.length) {
    return [];
  }

  return active.slice(0, MAX_SHOWN_OVERRIDES).map((o) => {
    const untilTime = dtUtils.format(
      dtUtils.utcToZonedTime(o.EndTime, store.IanaTimeZone!),
      dtUtils.isSameDay(now, o.EndTime as unknown as Date)
        ? dtUtils.LOCAL_TIME_FORMAT
        : dtUtils.LOCAL_DATE_TIME_FORMAT
    );

    return {
      storeId: store.StoreId as number,
      storeName: store.Name as string,
      storeTimeZone: store.IanaTimeZone as string,
      bhoId: o.BusinessHoursOverrideId as number,
      start: o.StartTime,
      end: o.EndTime,
      isDelivery: o.DeliveryType === BusinessHoursOverride.DeliveryTypeEnum.Delivery,
      isClosed: o.Type !== BusinessHoursOverride.TypeEnum.Open,
      untilTime,
    };
  });
};

export const getStoreOverridesByIdFactory = (storeId: number) => {
  const storeByIdSelector = storeByIdSelectorFactory(storeId);
  const overridesSelector = overridesSelectorFactory(storeId);
  return createSelector(
    [storeByIdSelector, overridesSelector, dateTimeUtils],
    combineStoreOverrides
  );
};

// #endregion

// #region getSavingsFormated
type SavingsState = ReturnType<typeof savingsSelector>;
const savingsSelector = (state: AppState) => state.home.savings.data;
const languageSelector = (state: AppState) => state.account.Language as string;
export const getSavingsFormated = createSelector(
  [savingsSelector, languageSelector, translateSelector],
  (savings, language, translate) => {
    return savings
      .filter((sd) => sd.Value !== 0)
      .map((curr, idx) => {
        const count = savings.length;
        const isFirst = idx === 0;
        const isLast = idx === count - 1;
        return `
          ${isFirst ? '' : isLast ? ' and ' : ', '}
          ${curr.Value!.toLocaleString(language, {
            style: 'currency',
            currency: curr.Currency!.toString(),
          })}
      `;
      });
  }
);

// #endregion
