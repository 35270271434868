import React, { useRef } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import rearrange from '../../../assets/images/Rearrange.svg';
import { Draggable } from '../../atoms/Draggable';

const useStyles = makeStyles((theme: Theme) => ({
  expandedDetails: {
    padding: 0,
  },
  expansionIcon: {
    marginRight: 0,
  },
  expansionPanel: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
    backgroundColor: 'inherit',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    boxShadow: 'none',
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0),
    width: '100%',
  },
  expansionPanelRoot: {
    marginTop: theme.spacing(1),
  },
  expansionPanelSummary: {
    padding: 0,
    margin: 0,
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0,
    },
  },
  dragIcon: {
    marginRight: theme.spacing(1),
  },
  draggingCard: {
    opacity: 0,
  },
  highlightOnHover: {
    '&:hover': {
      backgroundColor: '#EAF2FF',
    },
  },
  isDraggable: {
    '&:hover': {
      cursor: 'grab',
    },
  },
  panelSummaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summaryContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export type DraggableExpansionPanelProps = {
  className?: string;
  children;
  disabled?: boolean;
  highlightOnHover?: boolean;
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  summary;
  panelId: string;
  type?: string;
};

export const DraggableExpansionPanel = (props: DraggableExpansionPanelProps) => {
  const {
    className,
    children,
    disabled,
    highlightOnHover = true,
    index,
    move,
    summary,
    type = 'draggable-card',
    panelId,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const expansionPanelStyle = clsx(
    classes.expansionPanel,
    !disabled && classes.isDraggable,
    highlightOnHover && classes.highlightOnHover,
    className && className
  );
  return (
    <Draggable
      className={className}
      disabled={disabled}
      id={panelId}
      index={index}
      onMove={move}
      type={type}
    >
      <Accordion
        className={expansionPanelStyle}
        ref={ref}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          classes={{
            root: classes.expansionPanelSummary,
            content: classes.panelSummaryContent,
            // TODO: Does not exist on MUI 5
            // expandIcon: classes.expansionIcon,
          }}
          expandIcon={<ExpandMoreIcon data-fd={`show-contents-${panelId}-button`} />}
          // TODO: Does not exist on MUI 5
          // IconButtonProps={{ 'data-fd': `group-${panelId}-toggle` }}
          aria-controls={`panel-${panelId}-contents`}
        >
          {!disabled && <img data-fd="drag-icon" src={rearrange} className={classes.dragIcon} />}
          {summary()}
        </AccordionSummary>
        <AccordionDetails className={classes.expandedDetails}>{children}</AccordionDetails>
      </Accordion>
    </Draggable>
  );
};
